<div class="stats">
  <app-iot-device-live-stats [pingRtd]="pingRtd" [packetLoss]="packetLoss" [medianAlert]="medianAlert"
    [medianWarning]="medianWarning" [packetLossAlert]="packetLossAlert" [packetLossWarning]="packetLossWarning">
  </app-iot-device-live-stats>
  <hr />
  <app-iot-device-historic-ping [worstRtd]="worstRtd" [medianRtd]="medianRtd" [bestRtd]="bestRtd"
    [packetLossRtd]="packetLossRtd" [iotDeviceDetails]="deviceHisoryParams"></app-iot-device-historic-ping>
  <hr />
  <app-iot-device-last-event [latestEventsMins]="latestEventsMins" [latestEvents24h]="latestEvents24h">
  </app-iot-device-last-event>
  <hr />
  <app-iot-device-latest-event [deviceHisoryParams]="deviceHisoryParams"></app-iot-device-latest-event>
  <app-iot-device-notes [eventEmitter]="eventEmitter" [deviceID]="deviceID" [notes]="notes"></app-iot-device-notes>
</div>