<app-new-user-registration-invite
  *ngIf="loginEnv"
  (data)="registerEmitEvent($event)"
  [registerInfo]="registerInfo"
></app-new-user-registration-invite>
<app-old-user-registration-invite
  *ngIf="!loginEnv"
  (data)="registerEmitEvent($event)"
  [registerInfo]="registerInfo"
></app-old-user-registration-invite>
