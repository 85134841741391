import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'videoIncidentsFilter'
})
export class VideoIncidentsFilterPipe implements PipeTransform {

    transform(items: any[], searchObject, currentChart: string, applyChange: boolean): any {
        let data = [];
        let startDateObject = new Date();
        let endDateObject = new Date();

        if (currentChart === '7d') {
            startDateObject = new Date(searchObject.startDate.year, searchObject.startDate.month - 1, searchObject.startDate.day, searchObject.startTime.hour, searchObject.startTime.minute)
            endDateObject = new Date(searchObject.endDate.year, searchObject.endDate.month - 1, searchObject.endDate.day, searchObject.endTime.hour, searchObject.endTime.minute)
        } else {
            startDateObject = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), searchObject.startTime.hour, searchObject.startTime.minute)
            endDateObject = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), searchObject.endTime.hour, searchObject.endTime.minute)
        }
        if (items !== undefined) {
            items.forEach(d => {
                let res1 = true;
                let res2 = true;
                let res3 = true;
                let res4 = true;
                let res5 = true;
                if (searchObject.searchValue.length > 0) {
                    res1 = d.totalSectionData.jitter.toString().includes(searchObject.searchValue);
                    res2 = d.totalSectionData.latency.toString().includes(searchObject.searchValue);
                    res3 = d.totalSectionData.packetloss.toString().includes(searchObject.searchValue);
                    res4 = d.totalSectionData.mbps.toString().includes(searchObject.searchValue);
                }
                if (searchObject.searchIncident.length > 0) {
                    res5 = d.incident_id === null ? false: d.incident_id.toString().toLowerCase().includes( (searchObject.searchIncident+'').toLowerCase());
                }
                let res6 = false;
                if (new Date(d.timestamp).getTime() > startDateObject.getTime() && new Date(d.timestamp).getTime() <= endDateObject.getTime()) res6 = true;
                if ((res1 || res2 || res3 || res4) && res5 && res6) data.push(d);
            });
        }
        return data;
    }
}