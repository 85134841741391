import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-audio-site-list-panel',
  templateUrl: './audio-site-list-panel.component.html',
  styleUrls: ['./audio-site-list-panel.component.scss']
})
export class AudioSiteListPanelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
