<img class="space-button" *ngIf="isShowDomotzIntegration" src="assets/img/audio/Spaces_button.png" (click)="openPopup(84)">
<div class="mt-5" *ngIf="!isShowDomotzIntegration"></div>
<div class="iot-lite-main-menu">
  <div class="iot-lite-main-menu__left-container">
    <div class="iot-lite-main-menu__list-items iot-lite-main-menu__list-items--active" (click)="selectActivePanel(1)"
      [class.active]="activePanel === 1">
      enable service
    </div>

    <div class="iot-lite-main-menu__list-items iot-lite-main-menu__list-items--active"
      [ngClass]="{'iot-lite-main-menu__disable': !iotLiteEnabled}" (click)="selectActivePanel(2)"
      [class.active]="activePanel === 2">
      list of all device
    </div>

    <div class="iot-lite-main-menu__list-items iot-lite-main-menu__list-items--active"
      [ngClass]="{'iot-lite-main-menu__disable': !iotLiteEnabled}" (click)="selectActivePanel(3)"
      [class.active]="activePanel === 3"
      *ngIf="isShowDomotzIntegration">
      list of all spaces
    </div>

    <div class="iot-lite-main-menu__switch-toggle">
      <div class="iot-lite-main-menu__switch-toggle-label">
        IoT Service
      </div>
      <div class="iot-lite-main-menu__switch" [ngClass]="{ 'disable': disableToggle }">
        <div>disabled</div>
        <label class="switch">
          <input type="checkbox" [checked]="!!iotLiteEnabled" (click)="setEnabled($event)" />
          <span class="slider round"></span>
        </label>
        <div>enabled</div>
      </div>

      <div class="iot-lite-main-menu__switch-toggle-label">
        IoT Notification
      </div>
      <div class="iot-lite-main-menu__switch" [ngClass]="{ 'disable': !iotLiteEnabled || disableToggle }">
        <div class="">disabled</div>
        <label class="switch">
          <input type="checkbox" [checked]="iotLiteAlertsEnabled" (click)="setAlertEnabled()" />
          <span class="slider round"></span>
        </label>
        <div>enabled</div>
      </div>
    </div>

  </div>
  <div class="iot-lite-main-menu__right-container">
    <div *ngIf="activePanel === 1" class="components_item">
      <app-iot-lite-add-device [iotLiteEnabled]="iotLiteEnabled"></app-iot-lite-add-device>

    </div>
    <div *ngIf="activePanel === 2" class="components_item">
      <app-iot-lite-device-list [iotLiteEnabled]="iotLiteEnabled"></app-iot-lite-device-list>
    </div>

    <div *ngIf="activePanel === 3" class="components_item">
      <app-iot-space-list [isDomotz]="false"></app-iot-space-list>
    </div>
  </div>
</div>