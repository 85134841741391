import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { IntegratorService } from '../services/integrator.service';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private http: HttpClient, private integrator: IntegratorService) {}

  //server global variable;
  server_url: string = environment.serverUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  //API Calls

  //GET Request to get All Nstifications
  apiGetNotificationList(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
      user_id: userId,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/notifications/getList', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }

  //This function Marks Notificaiton as Read on the server
  markNotificationAsRead(companyId, notificationId): Observable<Object> {
    let postData = {
      company_id: companyId,
      notification_id: notificationId
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/notifications/markRead', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }

  //This function Marks All Notificaiton as Seen on the server
  markAllNotificationsAsSeen(companyId): Observable<Object> {
    let postData = {
      company_id: companyId,
    };

    return this.http
			.post<any>(this.server_url + '/api/v1/notifications/markAllAsSeen', postData, this.httpOptions)
  }

  //This function deletes a single notification  from the server
  deleteNotification(companyId, notificationId): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
      notification_id: notificationId,
      user_id: userId,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/notifications/delete', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }

  //This function deletes all notifications from the server
  deleteAllNotifications(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
      user_id: userId,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/notifications/deleteAll', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }

  getNotificationEmailList() {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: companyId,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/notifications/getEmailList',
      postData,
      this.httpOptions
    );
  }

  disableSlackNotificatios() {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: companyId,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/slack/disable',
      postData,
      this.httpOptions
    );
  }
}
