<div class="collector-info">
  <div class="collector-info__row">
    <h4>Site name</h4>
    <input type="text" class="form-control" placeholder="Site Name" [ngModel]="siteName" readonly />
    <h4>Host name</h4>
    <input type="text" class="form-control" placeholder="Host Name" [ngModel]="hostName" readonly />
    <h4>Site type</h4>
    <div class="collector-info__icon"><img [src]="iconAddSite" /> local collector</div>
  </div>

  <div class="collector-info__row">
    <h4>Last data connect</h4>
    <input
      type="text"
      class="form-control"
      placeholder="Last data"
      [ngModel]="lastDataConnect"
      readonly
    />
    <h4>Network</h4>
    <div class="collector-info__icon"><img [src]="networkImg" />ethernet</div>
    <h4>Version</h4>
    <div>{{ version }}</div>
  </div>

  <div class="collector-info__row">
    <h4>CPU usage</h4>
    <progress-bar [progress]="cpuUsage" [color]="clrGreen"> </progress-bar>

    <h4>Memory utilization</h4>
    <p>{{ memoryUsed }} GB free of {{ memoryTotal }} GB</p>

    <progress-bar [progress]="memoryStatus" [color]="clrGreen"> </progress-bar>
  </div>

  <div class="collector-info__row">
    <div class="collector-info__icon">
      <img [src]="driveImg" alt="" />
      <div *ngFor="let items of hdDetails">
        HDD {{ items.drive }} {{ items.free }} GB free of {{ items.total }} GB
        <progress-bar [progress]="items.percent" [color]="items.percent != 100 ? clrGreen : clrRed">
        </progress-bar>
      </div>
    </div>
  </div>
</div>
