import { AuthService } from 'src/app/services/auth.service'
import { resolve } from 'url'

import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'

import { environment } from '../../../../environments/environment'
// tslint:disable-next-line: max-line-length
import { WebportalProtocolConfigDialogComponent } from '../../../iot/dialogs/webportal-protocol-config-dialog/webportal-protocol-config-dialog.component'
import { GetIotService } from '../../../services/get-iot.service'

@Component({
  selector: 'app-video-alldevices-list-table',
  templateUrl: './video-alldevices-list-table.component.html',
  styleUrls: ['./video-alldevices-list-table.component.scss'],
})
export class VideoAlldevicesListTableComponent implements OnInit {
  constructor(
    private router: Router,
    public Auth: AuthService,
    private GetIot: GetIotService,
    public dialog: MatDialog
  ) { }

  @Input() videoDevicesListData: MatTableDataSource<any>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isDomotzEnabled: boolean = false;
  displayedColumns: string[] =
    this.Auth.accessLevel == 'SUPERADMIN'
      ? [
        'company',
        'name',
        'health',
        'qos',
        'site',
        'livembps',
        'oncall',
        'calender',
        'model',
        'ip',
      ]
      : ['name', 'health', 'qos', 'site', 'livembps', 'oncall', 'calender', 'model', 'ip'];
  Math: any = Math;
  isShowDomotzIntegration = environment.config.domotzIntegration;


  ngOnInit(): void {
    if (this.isShowDomotzIntegration) {
      this.getDomotzAccountByCompanyId();
    }
  }

  getDomotzAccountByCompanyId = () => {
    this.GetIot.getDomotzAccountByCompanyId().subscribe((data: any) => {
      const { enabled } = data;
      this.isDomotzEnabled = enabled === 1;
      // // console.log('isDomotzEnabled: ', this.isDomotzEnabled);
    });
  };

  applyFilter(filterValue: string) {
    if (filterValue == '') this.videoDevicesListData.filter = '-'.trim().toLowerCase();
    else this.videoDevicesListData.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {
    this.videoDevicesListData.filter = '-'.trim().toLocaleLowerCase();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.videoDevicesListData.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'company': {
          return item.company_name.toLocaleLowerCase();
        }
        case 'name': {
          return item.room_name.toLocaleLowerCase();
        }
        case 'health': {
          return item.health;
        }
        case 'qos': {
          return item.qos;
        }
        case 'site': {
          return item.site_name.toLocaleLowerCase();
        }
        case 'calender': {
          return item.scheduled_meetings;
        }
        case 'livembps': {
          return item.total_mbps;
        }
        case 'oncall': {
          return item.oncall;
        }
        case 'model': {
          return item.model;
        }
        case 'ip': {
          return item.ipv4;
        }
        default:
          return item[property];
      }
    };
    this.videoDevicesListData.sort = this.sort;
  }

  clickRow(row) {
    this.router.navigate(['video/site/', row.site_id, 'videodevice', row.video_device_id]);
  }

  openRemoteIp = async (ipv4, mac) => {
    const url = `http://${ipv4}`;
    const iotDevice: any = await this.getIotDeviceIpAndMacMatch(ipv4, mac);
    if (this.isDomotzEnabled && this.checkIotIsMonitored(iotDevice)) {
      const iotDeviceId = iotDevice.iot_device_id;

      const webportal = this.dialog.open(WebportalProtocolConfigDialogComponent, {
        data: {
          iotDeviceId,
        },
      });
    } else {
      window.open(url);
    }
  };

  checkIotIsMonitored = (iot) => {
    if (iot) {
      return iot.is_monitored === 1;
    }
    return false;
  };

  getIotDeviceIpAndMacMatch = async (ip, mac) => {
    return new Promise((resolve) => {
      this.GetIot.getIotDeviceIpAndMacMatch(ip, mac).subscribe((data: any) => {
        const [response] = data;
        resolve(response);
      });
    });
  };
}
