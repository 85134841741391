import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../services/auth.service'
import { GetIotService } from '../../../services/get-iot.service'

@Component({
	selector: 'app-logitech-add-device-dialog',
	templateUrl: './logitech-add-device-dialog.component.html',
	styleUrls: ['./logitech-add-device-dialog.component.scss'],
})
export class LogitechAddDeviceDialogComponent {
	collectorID: any = ''
	deviceIP: any
	iotDevicePorts: any
	deviceRoomName: any
	deviceName: any = ''
	devicePassword: any = ''
	deviceFlag: any
	siteId: any
	iotIp: any
	iotName: any
	showMsg30Sec: boolean = false
	setTimer: any
	actionCount: any = 0
	aDeviceIsFound: any = false

	showSuccessMsg: any = false
	showSuccessMsgIot: any = false
	successMessage: any = 'Device Successfully Added'

	errorMsg: any = 'Device Not Found'
	showErrMsg: any = false
	showErrorMessageIot: any = false
	errorMessage: any = 'Could not find device. Please try again.'

	errorMessageIot: any = 'Could not find Ports. Please try again.'

	showSearching: any = false
	showSearchingIot: any = false

	showAddDevice: any = false
	showAddDeviceIot: any = false
	showErrorMessage: any = false
	closeButtonImage: any = './assets/img/iot/delete-btn.png'

	deviceModel: any
	serialNumber: any
	deviceMac: any
	softwareRelease: any
	activeMic: any
	activeCamera: any
	isLoading: any = false
	iotDeviceIP: any
	iotDeviceMac: any
	iotDeviceName: any
	iotDeviceMake: any
	devicePorts: any[] = []
	devicePortRename: any
	discoverIpFromIot: any = false
	deviceActionId: any
	iotPortsHolder: any
	firmware: any
	iotDeviceIPFound: any = false
	isDomotzIntegration = environment.config.domotzIntegration
	activeTap: number = 0
	activeRemote: number = 0

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		private Auth: AuthService,
		private dialogRef: MatDialogRef<LogitechAddDeviceDialogComponent>,
		private getIot: GetIotService
	) {
		if (data) {
			const config = { ...data.config }
			this.deviceIP = config.deviceIp
			this.collectorID = config.collector_id
			this.devicePassword = config.devicePassword
			this.deviceName = config.deviceName
			this.deviceFlag = config.deviceFlag
			this.deviceRoomName = config.deviceRoomName
			this.iotIp = config.deviceIp
			this.iotName = config.iotName
			this.siteId = config.siteId

			console.log('deviceIP: ', this.deviceIP)

			if (this.collectorID && this.deviceIP) {
				this.discoverDevice()
			} else {
				//we can say no device was found
			}
		}
	}
	getDeviceUserName = name => (this.deviceFlag ? 'Logitech' : name)

	getMediaType = () => (this.deviceFlag ? 'audio' : 'logitech')

	getMediaTypeAgain = () => (this.deviceFlag ? 'audio' : 'video')

	discoverDevice = () => {
		const params = {
			collector_id: this.collectorID,
			site_id: this.siteId,
			username: this.getDeviceUserName(this.deviceName),
			password: this.devicePassword,
			room_name: this.deviceRoomName,
			type: this.getMediaType(),
			ip: this.deviceIP,
		}
		this.Auth.findDevice(params).subscribe(
			(data: any) => {
				let [res] = data.response
				if (res.status == 'OK') {
					this.deviceActionId = res.action_id
					this.checkStatus(res.action_id)
					this.showSearching = true
				} else {
					this.errorMsg = res.message
					this.showErrMsg = true
				}
			},
			err => {
				this.showSearching = false
			}
		)
	}

	checkStatus = action_id => {
		console.log('action_id: ', action_id)
		this.Auth.postDeviceActionId({ action_id }).subscribe(actionData => {
			console.log('postDeviceActionId: ', actionData)
			console.log('actionCount: ', this.actionCount)
			if (this.actionCount < 0) {
				this.actionCount = 0
			}
			if (this.actionCount == 120) {
				//resets field if device not found
				if (!this.aDeviceIsFound) {
					console.log('reset!')
					this.resetFields()
					this.showErrMsg = true
				} else {
					this.resetFieldsForIot()
					this.showErrorMessageIot = true
					this.iotIp = 0
				}

				return
			}
			if (actionData.response[0].status !== 'COMPLETE' && actionData.response[0].status !== 'FAILED') {
				const rk = this
				this.setTimer = setTimeout(function () {
					rk.checkStatus(action_id)
				}, 1000)
				this.actionCount++
				if (this.actionCount == 30) {
					this.showMsg30Sec = true

					// Since total time of waiting is 120 seconds and after 30 we show It iss taking longer then usual
					setTimeout(() => {
						this.showMsg30Sec = false
					}, 70 * 1000)
				}
			} else if (actionData.response[0].status === 'FAILED') {
				this.resetFields()
				const [newData] = actionData.response
				this.errorMsg = 'Could not find device. Please try again.'
				if (newData.results.message != 'device not found') {
					this.errorMsg = newData.results.message
				}
				this.showErrMsg = true
				return
			} else if (actionData.response[0].status === 'COMPLETE') {
				console.log('COMPLETE!!!')
				const [newData] = actionData.response
				if (newData?.results?.settings && !Object.keys(newData.results.settings).length) {
					this.errorMsg = 'Could not find device. Please try again.'
					this.showErrMsg = true
					this.showSearching = false
					return
				}
				if (!this.discoverIpFromIot) {
					//for audio
					this.resetFields()
					this.showAddDevice = true
					this.aDeviceIsFound = true
					this.setData(newData.results)
					if (this.iotIp && !this.iotDeviceIPFound) {
						this.discoverIotDevice(this.iotIp)
					} else {
						console.log('no iot ip is present')
						return
					}
				} else {
					this.resetFieldsForIot()
					console.log('iot IP found')
					this.setDataIot(newData.results)
					return
				}
			}
		})
	}

	resetFieldsForIot = () => {
		this.actionCount = 0
		this.showSearchingIot = false
		this.showErrorMessageIot = false
		this.showSearchingIot = false
		this.showAddDeviceIot = false
		this.showMsg30Sec = false
	}

	resetFields = () => {
		this.actionCount = 0
		this.showSearching = false
		this.showErrorMessage = false
		this.showAddDevice = false
		this.showMsg30Sec = false
	}

	iotHostName: any

	setDataIot = params => {
		const res2 = JSON.parse(params.results)
		const {
			nmap: { host },
		} = res2
		console.log('res2: ', res2)
		if (host) {
			if (host.ports) {
				if (host.ports.port) {
					if (host.ports.port.length > 1) {
						this.iotDeviceIP = this.getDeviceIp(host)
						this.iotDeviceMac = this.getDeviceMac(host)
						this.iotDeviceName = `${this.deviceRoomName} VISUAL+`
						this.iotDeviceMake = this.getDeviceMake(host)
						this.iotHostName = this.getHostname(host)
						this.devicePorts = host.ports.port.map(e => {
							return {
								portid: e.portid,
								portname: e.service.name,
								is_monitored: 1,
								devicePortRename: '',
							}
						})
					} else {
						// console.log('only 1 port')
						this.devicePorts = []
					}
				} else {
					this.devicePorts = []
				}
			} else {
				this.devicePorts = []
			}
			this.showAddDeviceIot = true
		} else {
			//display device not found
			this.resetFieldsForIot()
			this.iotIp = 0
			this.showErrorMessageIot = true
		}
	}

	getDeviceIp = (host: any) => {
		if (Array.isArray(host.address)) {
			if (host.address[0].addr) {
				return host.address[0].addr
			}
		} else {
			if (host.address.addr) {
				return host.address.addr
			}
		}
		return 'null'
	}

	getDeviceMac = (host: any) => {
		if (Array.isArray(host.address)) {
			if (host.address[1].addr) {
				return host.address[1].addr
			}
		}
		return 'null'
	}

	getHostname = (host: any) => {
		if (host.hostnames) {
			if (host.hostnames.hostname) {
				if (host.hostnames.hostname.name) {
					return host.hostnames.hostname.name
				}
			}
		}
		return 'null'
	}

	getDeviceMake = (host: any) => {
		//check if its in the address
		if (Array.isArray(host.address)) {
			if (host.address[1].vendor) {
				return host.address[1].vendor
			}
		}
		return 'Unknown'
	}

	discoverIotDevice = iotIp => {
		//edit later
		console.log('discoverIotDevice: ', iotIp)
		this.Auth.getNmap(this.collectorID, iotIp, 'add', this.siteId).subscribe(
			data => {
				let [res] = data.response
				if (res.status == 'OK') {
					this.iotDeviceIPFound = true
					this.discoverIpFromIot = true
					this.checkStatus(res.action_id)
					this.showSearchingIot = true
				} else {
					if(res?.message?.includes('already registered')){
						this.errorMessageIot = 'This device is currently registered in IoT Devices. Proceeding will relocate the device to Video Devices.'
					} else this.errorMessageIot = 'Could not find Ports. Please try again.'
					this.showErrorMessageIot = true
					this.iotIp = 0
				}
			},
			err => {
				this.showSearching = false
				this.errorMessageIot = 'Could not find Ports. Please try again.'
				this.showErrorMessageIot = true
			}
		)
	}

	setData = params => {
		if (params && params.settings && params.settings.deviceInfo) {
			const { deviceInfo, peripherals } = params.settings
			const { deviceModel, serialNumber, ethernetMac, firmwareVersion, hostName } = deviceInfo
			this.deviceModel = this.checkValueIfEmpty(deviceModel)
			this.serialNumber = this.checkValueIfEmpty(serialNumber)
			this.deviceMac = this.checkValueIfEmpty(ethernetMac)
			this.firmware = this.checkValueIfEmpty(firmwareVersion)
			this.deviceName = this.checkValueIfEmpty(hostName)
			if (peripherals?.length) {
				for (let i = 0; i < peripherals.length; i++) {
					if (peripherals[i].type == 'REMOTE') {
						this.activeRemote = peripherals[i].batteryLevel === -1 ? 0 : 1
					} else if (peripherals[i].type == 'TAP') {
						this.activeTap++
					}
				}
			}
		}
	}

	getDeviceName = name => {
		if (name.ComponentList) {
			const [, res] = name.ComponentList
			if (res) {
				return res.componentSerialNumber
			}
		}
		return
	}

	getAudioCamCount = cam => {
		return cam ? 1 : 0
	}

	getAudioSerial = serial => {
		if (serial.ComponentList) {
			const [res] = serial.ComponentList
			if (res) {
				return res.componentSerialNumber
			}
		}
		return
	}

	checkValueIfEmpty = value => (value ? value : '--')

	closeDialog = () => {
		this.isLoading = false
		this.dialogRef.close(true)
	}

	cancelAddDevice = () => {
		this.showAddDevice = false
	}

	addDevice = () => {
		//video and audio
		this.Auth.sendAddDeviceData({
			action_id: this.deviceActionId,
			type: this.getMediaTypeAgain(),
		}).subscribe((data: any) => {
			// console.log('addDevice: ', data)
			this.resetFields()
			this.resetFieldsForIot()
			this.showSuccessMsg = true
			this.aDeviceIsFound = false
		})
	}

	videoDeviceId: any
	iotDeviceId: any

	addDeviceAndLink = () => {
		this.Auth.sendAddDeviceData({
			action_id: this.deviceActionId,
			type: this.getMediaTypeAgain(),
		}).subscribe((data: any) => {
			const [res] = data.response
			this.videoDeviceId = res.deviceId

			console.log(' this.videoDeviceId: ', this.videoDeviceId)
			console.log(' this.res: ', res)

			this.resetFields()
			//after adding the audio we can add iot
			this.resetFieldsForIot()
			this.addIotDeviceAndLink()
			// this.showSuccessMsg = true;
		})
	}
	onChangeMonitoring = (event, item) => {
		var value = 0
		if (event?.target?.checked) {
			value = 1
		}
		item.is_monitored = value
	}
	addIotDeviceAndLink = () => {
		let devicePortsNewName: any = this.devicePorts.map(e => {
			return {
				...e,
				portname: !e.devicePortRename.trim() ? e.portname : e.devicePortRename,
			}
		})

		devicePortsNewName = JSON.stringify(devicePortsNewName)
		this.getIot
			.iotLiteAdd(
				this.collectorID,
				this.deviceRoomName,
				this.deviceName,
				this.iotIp,
				devicePortsNewName,
				this.iotDeviceMac,
				'8',
				this.iotDeviceMake,
				this.siteId,
				true
			)
			.subscribe((data: any) => {
				console.log('this.data: ', data)
				const res = data.response
				this.iotDeviceId = res.iot_id
				if (this.videoDeviceId && this.iotDeviceId) {
					this.linkIotToVideoDevice()
				} else {
					this.showErrorMessageIot = true
					this.errorMessageIot = 'Both Device & Ports are Added but Device Linking failed'
				}
			})
	}

	linkIotToVideoDevice = () => {
		this.getIot.iotLiteLinkDevice(this.iotDeviceId, this.videoDeviceId, 'video').subscribe(
			(data: any) => {
				console.log('linkIotToVideoDevice: ', data)
				this.successMessage = 'Both Device & Ports are Successfully Added and Linked'
				this.showSuccessMsg = true
				this.iotIp = 0
			},
			error => {
				// this.displayMessage = 'Someting went wrong when linking IoT Domotz Device.';
				//another error
				this.showErrorMessageIot = true
				console.log('showErrorMessageIot: ', this.showErrorMessageIot)
				this.errorMessageIot = 'Both Device & Ports are Added but Device Linking failed'
			}
		)
	}
}
