<div class="zoom-users">
  <div infinite-scroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="500" [scrollWindow]="false" (scrolled)="onScroll()" style="overflow: auto; height: 280px;">
    <table class="table table-stripped">
      <thead>
        <tr class="black">
          <th scope="col">Client</th>
          <th scope="col">User Name</th>
          <th scope="col">User Type</th>
          <th scope="col">Email</th>
          <th scope="col">Status</th>
          <th scope="col">
            <div style="width: 100px;">
              Desktop Client Status
            </div>
          </th>
          <th scope="col">VIP Alerts</th>
          <th scope="col">Desktop Client</th>
          <th scope="col">Link</th>
          <!--   <th scope="col">Updates</th> -->
        </tr>
      </thead>
      <tbody>
        <tr scope="row" *ngFor="let items of zoomUserList">
          <td>
            <div *ngIf="items.plugin_active === null">
              --
            </div>
            <img *ngIf="items.plugin_active !== null" src="{{ getPluginIcon(items.plugin_active) }}" alt="" />
          </td>

          <td>
            {{ items.assigned_user }}
          </td>

          <td>
            <div>
              <span *ngIf="items.alerts === 1" class="zoom-users__vip">VIP</span>
              <span *ngIf="items.alerts !== 1">--</span>
            </div>
          </td>

          <td>{{ items.email }}</td>

          <td>
            <div class="zoom-users__inner-text">
              <div class="test-class">
                <img src="{{ getPhoneCallStatus(items.liveMeetings) }}" alt="" />
              </div>
              <div *ngIf="items.liveMeetings === 1" class="tooltip">
                User is on a call
              </div>
            </div>
          </td>

          <td>
            <div class="zoom-users__status {{ getDesktopStatus(items.plugin_status) }}"></div>
          </td>

          <td>
            <div>
              <label class="switch" [ngClass]="{ 'disable-checkbox': items.plugin_id === null}">
                <input #alertsChangedZoomUsers type="checkbox"
                  (change)="alertsChange(alertsChangedZoomUsers.checked, items.zoom_user_id)"
                  [checked]="items.alerts" />
                <span class="slider round"> </span>
              </label>
            </div>
          </td>

          <td>
            <div>
              <label class="switch" [ngClass]="{ 'disable-checkbox': items.plugin_active === null }">
                <input #desktopClientACheckboxx type="checkbox"
                  (change)="desktopClientChange(desktopClientACheckboxx.checked, items.zoom_user_id)"
                  [checked]="items.zoom_plugin_enabled" />
                <span class="slider round"> </span>
              </label>
            </div>
          </td>

          <td>
            <div (click)="openDesktopLinkModal(items)" [ngClass]="{ 'disable-checkbox': items.plugin_status == -1 }">
              <img src="{{ getLinkIcon(items) }}">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="zoom-users__loader-img">
      <img *ngIf="showLoader" [src]="loader" />
    </div>
  </div>
</div>