import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core'
import { SearchService } from 'src/app/services/search.service'
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
@Component({
  selector: 'search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  quadrantClasses = {
    video: 'widget-26-job-category bg-soft-warning',
    audio: 'widget-26-job-category bg-soft-success',
    iot: 'widget-26-job-category bg-soft-danger',
    cloud: 'widget-26-job-category bg-soft-info',
  };
  @ViewChild('allResults', { static: true }) allResults: TemplateRef<any>
  beingFetched = false
  results: any = {};
  isShown = false;
  videos: any[]
  iot: any[]
  sites: any[]
  user: any[]
  user_only: any[] = [];
  rooms_only: any[] = [];
  audio: any[]
  query: string = '';
  totalResultLength: number
  showResultEmpty: boolean = false;
  isMatchRoomVideos: boolean = false;
  isMatchMacVideos: boolean = false;

  constructor(private _search: SearchService, private modal: MatDialog) { }

  ngOnInit() {
    this._search.isSearchProgress().subscribe((status) => {
      this.beingFetched = status
    })
    this._search.searchResultsObs().subscribe((results) => {
      this.results = results
      this.videos = results.video
      this.user = results.user
      this.sites = results.site
      this.iot = results.iot
      this.audio = results.audio
      this.query = results.query
      this.user_only = []
      this.rooms_only = []
      for (let i = 0; i < this.user.length; i++) {
        if (this.user[i].is_room == 1 && this.user[i].is_monitored == 1) {
          this.rooms_only.push(this.user[i])
        }
        else if (this.user[i].is_room == 0 && this.user[i].zoom_plugin_enabled == 1) {
          this.user_only.push(this.user[i])
        } else {

        }
      }
      this.totalResultLength = this.videos.length + this.audio.length + this.iot.length + this.rooms_only.length + this.sites.length + this.user_only.length
      if (this.totalResultLength == 0) {
        this.showResultEmpty = true
      } else {
        this.showResultEmpty = false
      }

    })
    this._search.showResults.asObservable().subscribe((shown) => {
      this.isShown = shown
    })
  }
  openDialog() {
    const dialogRef: MatDialogRef<any> = this.modal.open(this.allResults)
    dialogRef.afterClosed().subscribe(result => {
      
    })
    this.isShown = false

  }
  closeModal() {
    this._search.showResults.next(false)
  }
  ngOnDestroy() {
  }
}