import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { GetIotService } from '../../services/get-iot.service';
import { RefreshApiService } from '../../services/refresh-api.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog'
import { LinkDeviceSpaceDialogComponent } from '../dialogs/link-device-space-dialog/link-device-space-dialog.component'

export interface DeviceListInterface {
  agent_id: number;
  display_name: string;
  iot_device_id: number;
  ip_address: string;
  make: string;
  model: string;
  ping_rtd: number;
  status: string;
  no_of_spaces: number;
}

@Component({
  selector: 'app-iot-device-list',
  templateUrl: './iot-device-list.component.html',
  styleUrls: ['./iot-device-list.component.scss'],
})
export class IotDeviceListComponent implements OnInit, OnDestroy {
  iotDeviceList: any = [];
  @Input() displayOnlyMonitored: any;
  @Input() mspDomotzEnabled: any;

  /* pagination variables */
  pageSizeSelect: any[] = [];
  totalPageLimit = 500;
  paginateOjb: any = {
    currentPage: 1,
    endIndex: 4,
    pageSize: 0,
    pages: [],
    startIndex: 0,
    totalItems: 0,
    totalPages: 0,
  };
  totalCount: number;
  pageSelected: any = 0;
  searchKey: any = 'null';
  shouldShowLoaderBtn: Boolean = false;
  filterListner: Subscription;
  refreshData: Boolean;
  displayedColumns: any[] = [
    'display_name',
    'spaces',
    'make',
    'ip_address',
    'mac_address',
    'agent_name',
    'status',
    'is_monitored',
    'link_to_space'
  ];
  dataSource: MatTableDataSource<DeviceListInterface>;
  loader = environment.config.dynamicImage.loader;

  constructor(private getIot: GetIotService, 
              private refreshApiService: RefreshApiService,
              private dialog: MatDialog
  ) {
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if (m) {
        this.refreshData = m;
        this.getApiData();
      }
    });
  }
  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  getApiData() {
    if (this.refreshData) {
      this.refreshtIotDeviceList();
    }
  }

  ngOnInit() {
    this.getIotDeviceList();
    if(this.displayOnlyMonitored){
      this.displayedColumns.pop()
    }
  }

  getIotDeviceList = () => {
    this.shouldShowLoaderBtn = true;
    this.getIot
      .getAllIotDevices(
        this.searchKey,
        this.totalPageLimit,
        this.paginateOjb.startIndex,
        this.displayOnlyMonitored
      )
      .subscribe((data) => {
        this.iotDeviceList = data;

        // console.log('this.iotDeviceList: ', this.iotDeviceList)
        this.dataSource = new MatTableDataSource(this.iotDeviceList);
        this.shouldShowLoaderBtn = false;
      });
  };

  refreshtIotDeviceList = () => {
    this.getIot
      .getAllIotDevices(
        this.searchKey,
        this.totalPageLimit,
        this.paginateOjb.startIndex,
        this.displayOnlyMonitored
      )
      .subscribe((data) => {
        this.iotDeviceList = data;
        this.dataSource = new MatTableDataSource(this.iotDeviceList);
      });
  };

  getStatusColor = ({ status, agent_status }) => {
    if (agent_status === 'OFFLINE') {
      return ' ';
    } else {
      if (status === 'ONLINE') {
        return 'iot-device-list__status-color--green';
      } else {
        return 'iot-device-list__status-color--red';
      }
    }
  };

  setIsMonitored = (_id, _flag) => {
    const flag = this.setFlag(_flag);
    this.getIot.setIotDeviceIsMonitored(_id, flag).subscribe(
      (data) => { },
      (error) => { }
    );
  };

  setFlag = (flag) => (flag ? 0 : 1);

  searchData = (key) => {
    this.searchKey = key;
    // console.log(key)
    // this.refreshtIotDeviceList();
  };
  linkUnlinkSpace(row){
    const dialog = this.dialog.open(LinkDeviceSpaceDialogComponent,{
      panelClass: 'custom-dialog-container',
      data:{
        ...row,
        isDomotz:true
      }
    })
    dialog.componentInstance.refresh.subscribe((x)=>{
      const device_id = row.iot_device_id || row.iot_device_id
        const index = this.iotDeviceList.findIndex((x)=>(x.iot_device_id || x.iot_id) == device_id)
        this.iotDeviceList[index].spaces = x;
    })
  }
  getSpaceName(spaces){
    if(spaces){
      return JSON.parse(spaces).map((x)=>x.space_name)
    }
    return "--"
  }
}
