import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchService } from 'src/app/services/search.service';
import { FormGroup, FormGroupName } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ToastContainerDirective } from 'ngx-toastr';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  searching = false;
  searchQuadrants: string[] = [
    'all',
    'video',
    'audio',
    'iot',
    'cloud',
    'site'
  ]
  selectedQuadrant: string = this.searchQuadrants[0];
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;
  constructor(private _search: SearchService, private toastrService: ToastrService) { }

  ngOnInit() {
      this.toastrService.overlayContainer = this.toastContainer;
    this._search.isSearchProgress().subscribe((status) => {
      console.log('Search value changed', status);
      this.searching = status;
    });
  }

  search(query, quadrant) {
    console.log('Search object', query);
    console.log("selected group", quadrant);
    if ((query || '').trim().length == 0) {
      this.toastrService.error("Please provide your search text", '', {
         closeButton: false 
      })
    } else {
      this._search.search(query.trim(), quadrant);
    }    
  }
}
