<div class="logitech-tap-panel-container">
  <h5>Logitech Tap</h5>
  <div class="logitech-tap-image-and-text">
    <img src="{{ tapIcon }}" alt="logitech tap panel">
    <div class="logitech-tap-text">
      <div>
        <h6>Status</h6>
        <p>{{status}}</p>
      </div>
      <div>
        <h6>Serial Number</h6>
        <p>{{serialNumber}}</p>
      </div>
    </div>
  </div>
</div>