<div class="progress">
  <div *ngIf="isCursorPointer == true" class="progress-bar mustPrint" role="progressbar" aria-valuenow="100"
    aria-valuemin="0" aria-valuemax="100"></div>
  <div *ngIf="isCursorPointer == false" class="progress-bar mustPrint" role="progressbar" aria-valuenow="100"
    aria-valuemin="0" aria-valuemax="100" style="cursor: default !important;"></div>
    
</div>

<ng-container>
  <div *ngIf="showBlackPointer" class="pnt-group bar-move" [style.left]="val + '%'">
    <div class="pnt">
      <img src="../../../../assets/img/shared/black-pointer-sm.png" />
    </div>
    <div class="pnt-n" *ngIf="checkStatus() != 0; else elseBlock">{{ displayValue | wholeNumber }}</div>
    <ng-template #elseBlock>
      <div class="pnt-n">
        0
      </div>
    </ng-template>
  </div>
  <div *ngIf="!showBlackPointer" style="margin-bottom: 10px;"></div> 
</ng-container>