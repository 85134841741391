import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-video-logitech-uptime',
  templateUrl: './video-logitech-uptime.component.html',
  styleUrls: ['./video-logitech-uptime.component.scss']
})
export class VideoLogitechUptimeComponent implements OnChanges {
  @Input() logitechData: any


  days: number = 0;
  hours: number = 0;
  minutes: number = 0;

  wifiMac: any
  isAutoDate: any
  ntpServer: any
  is24HoursFormat: any
  currentTime: any
  defaultNtpServer: any
  
  timeZoneId: any;
  timeZoneName: any;
  
  constructor() { }

  ngOnInit(): void {
  }
  
   
  ngOnChanges(changes: SimpleChanges) {
    if (changes.logitechData && this.logitechData) {
      this.setData()
    }
  }
  
  
  setData = () => {
    const { settings: {
      dateAndTime: { currentTime },
      regionalSettings: {timeZoneId, timeZoneName }
      
      },
  
  }= this.logitechData
    
   
    this.timeZoneId = timeZoneId || 'N/A';
    this.timeZoneName = timeZoneName || 'N/A';;
    
      
     this.currentTime =  new Date(currentTime).toLocaleString(undefined, {
    timeZone: timeZoneId
  })  || '--:--:--'
        
    // this.wifiMac = wifiMac;
    // this.isAutoDate = isAutoDate;
    // this.defaultNtpServer = defaultNtpServer;
    // this.ntpServer = ntpServer;
    // this.is24HoursFormat = is24HoursFormat;
    // this.currentTime = currentTime;
  }

}
