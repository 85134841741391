import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { environment } from '../../../environments/environment'
import { GetCollectorService } from 'src/app/services/get-collector.service'
export enum PathPingColor {
	'GOOD' = '#91d05b',
	'WARNING' = '#ffc107',
	'ERROR' = '#eb3b24',
	'BAD' = 'grey'
}
@Component({
	selector: 'app-collector-path-test',
	templateUrl: './collector-path-test.component.html',
	styleUrls: ['./collector-path-test.component.scss'],
})
export class CollectorPathTestComponent implements OnInit, OnChanges {
	@Input() collectorDetails: any

	expandPanel: boolean = false
	get PathPingerEnum() {
		return PathPingColor
	}
	sliderUpImg: string = '../../../../../assets/img/vs_img/slide-up-black.png'
	sliderDownImg: string = '../../../../../assets/img/vs_img/slide-down-black.png'

	origIp: string = ''
	destIp: string = ''

	hops: any[] = []

	gotOutput: boolean = false
	inProgress: boolean = false

	pathStatus: string = 'N/A'

	loader: string = environment.config.dynamicImage.loader

	constructor(private collector: GetCollectorService) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (!changes.collectorDetails || !this.collectorDetails) return
		this.setData()
	}

	setData = () => {
		const { ip_address } = this.collectorDetails
		this.origIp = ip_address
	}

	run = async () => {
		if (this.destIp === '' || !this.isIpValid(this.destIp)) return

		const { collector_id } = this.collectorDetails

		this.hops = []
		this.gotOutput = false
		this.inProgress = true
		this.expandPanel = false

		const result = await this.collector.pathPing(collector_id, this.destIp).toPromise()

		this.pathStatus = result.data.every(h => +h.packetLoss === 0) ? 'Good' : this.pathStatus
		this.pathStatus = result.data.some(h => +h.packetLoss > 0) ? 'Warning' : this.pathStatus
		this.pathStatus = result.data.some(h => +h.packetLoss > 5) ? 'Alert' : this.pathStatus

		this.hops = result.data
		this.gotOutput = true
		this.inProgress = false
		this.expandPanel = true
	}

	isIpValid = (ip: string) => {
		const ipPattern = new RegExp('^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$')
		return ipPattern.test(ip)
	}
}
