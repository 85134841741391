<div class="console">
  <div class="console__title">
    <h4>Console</h4>
  </div>
  <div class="console__input_text">Origination IP: <span>10.1.0.23</span></div>
  <div class="console__input_text">
    Destination IP: <span><input type="text" /></span>
  </div>
  <div class="console__btn_ctr">
    <div class="console__btn_item">
      <input type="checkbox" value="ping" />
      <label for="ping"> <span class="ping-label"> Ping </span> </label><br />
    </div>
    <div class="console__btn_item">
      <button>
        Run
      </button>
    </div>
  </div>
  <div class="console__output">
    <div class=""></div>
  </div>
</div>