<div class="counter">
  <p>
    Total # of devices are <strong>{{ totalIotLiteDeviceCount }}</strong>
  </p>
</div>
<div class="iot-device-list">
  <input
    #iotInputText
    (keyup)="searchData(iotInputText.value)"
    placeholder="Search..."
    class="input-class"
  />

  <div class="mat-elevation-z8 child mat-table">
    <table class="data-table" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="mac_address">
        <th mat-header-cell *matHeaderCellDef><strong>Mac Address</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.mac_address | displayEmpty }}
        </td>
      </ng-container>
      
      <ng-container matColumnDef="spaces">
        <th mat-header-cell *matHeaderCellDef><strong>Space Name</strong></th>
        <td mat-cell *matCellDef="let row">{{ getSpaceName(row.spaces)}}</td>
      </ng-container>

      <ng-container matColumnDef="iot_name">
        <th mat-header-cell *matHeaderCellDef><strong>Device Name</strong></th>
        <td mat-cell *matCellDef="let row">
          <div>
            <!-- <div class="text-center iot-device-list__status-color {{ getStatusColor(row) }}"></div> -->
            {{ row.iot_name | displayEmpty }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="iot_ip">
        <th mat-header-cell *matHeaderCellDef><strong>IP Address</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.iot_ip | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="device_id">
        <th mat-header-cell *matHeaderCellDef><strong>Device ID</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.device_id | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="collector_id">
        <th mat-header-cell *matHeaderCellDef><strong>Collector ID</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.collector_id | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="make">
        <th mat-header-cell *matHeaderCellDef><strong>Make</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.make | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="quadrant_group">
        <th mat-header-cell *matHeaderCellDef>
          <strong>Quadrant Group</strong>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.quadrant_group | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="is_monitored">
        <th mat-header-cell *matHeaderCellDef><strong>Monitoring</strong></th>
        <td mat-cell *matCellDef="let row">
          <div class="checkbox_switch">
            <label class="switch">
              <input
                type="checkbox"
                [checked]="row.is_monitored === 1"
                (click)="setIsMonitored(row.iot_id, row.is_monitored)"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="link_to_space">
        <th mat-header-cell *matHeaderCellDef><strong>Link to Space</strong></th>
        <td mat-cell *matCellDef="let row">
          <div (click)="linkUnlinkSpace(row)">
            <i class="fa fa-link fa-rotate-90 fa-lg" style="color: #2d76f9;" *ngIf="!row.spaces"></i>
            <i class="fa fa-unlink fa-rotate-90 fa-lg" style="color: #2d76f9;" *ngIf="row.spaces"></i>
          </div>
        </td>
      </ng-container>
      
      <!-- <ng-container matColumnDef="display_name">
                <th mat-header-cell *matHeaderCellDef class="device-name-style">
                    <strong>Device Name</strong>
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.display_name | displayEmpty }}
                </td>
            </ng-container>

            <ng-container matColumnDef="make">
                <th mat-header-cell *matHeaderCellDef><strong>Make</strong></th>
                <td mat-cell *matCellDef="let row">{{ row.make | displayEmpty }}</td>
            </ng-container>

            <ng-container matColumnDef="ip_address">
                <th mat-header-cell *matHeaderCellDef><strong>IP</strong></th>
                <td mat-cell *matCellDef="let row">{{ row.ip_address | displayEmpty }}</td>
            </ng-container>

            <ng-container matColumnDef="mac_address">
                <th mat-header-cell *matHeaderCellDef><strong>MAC</strong></th>
                <td mat-cell *matCellDef="let row">{{ row.mac_address | displayEmpty }}</td>
            </ng-container>


            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef><strong>Device Status</strong></th>
                <td mat-cell *matCellDef="let row">
                    <div>
                        <div class="text-center iot-device-list__status-color {{ getStatusColor(row) }}"></div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="is_monitored">
                <th mat-header-cell *matHeaderCellDef><strong>Monitoring</strong></th>
                <td mat-cell *matCellDef="let row">
                    <div class="checkbox_switch">
                        <label class="switch">
                            <input type="checkbox" [checked]="row.is_monitored === 1"
                                (click)="setIsMonitored(row.iot_device_id, row.is_monitored)" />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </td>
            </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [colSpan]="displayedColumns.length">
          No data found in table
        </td>
      </tr>
    </table>
  </div>
  <div *ngIf="shouldShowLoaderBtn" class="loader-child">
    <img src="{{ loader }}" alt="" />
  </div>
</div>
