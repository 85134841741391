<div style="padding-top: 10px;">
    <input #searchData (keyup)="applyFilter(searchData.value)" placeholder="Search..."
        style="width:100%; padding-left:21px; height:35px;font-size: 15px;border-bottom: 1px solid lightgray !important;">
    <div class="example-container mat-elevation-z8" style="background-color:white">

        <table mat-table [dataSource]="audioSitesListData" matSort class="mat-elevation-z8">


            <ng-container matColumnDef="logo" style="padding-left:5px; padding-right:5px;">
                <th mat-header-cell *matHeaderCellDef style="color:black; font-weight: bold; ">
                </th>
                <td mat-cell *matCellDef="let element">
                    <app-table-sitetype-icon [value]="element.virtual?element.virtual:0"></app-table-sitetype-icon>
                </td>
            </ng-container>

            <ng-container *ngIf="this.Auth.accessLevel == 'SUPERADMIN'" matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold; ">Company
                </th>
                <td mat-cell *matCellDef="let element">{{element.company_name}}</td>
            </ng-container>


            <ng-container matColumnDef="site" style="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold; ">Site
                </th>
                <td mat-cell *matCellDef="let element">{{element.site_name}} </td>
            </ng-container>

            <ng-container matColumnDef="health" tyle="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold; ">Health
                </th>
                <td mat-cell *matCellDef="let element">
                    <app-table-health-icon [value]="element.site_health"
                        style="text-align: center;"></app-table-health-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="impacted" style="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold;">Impacted
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                    {{element.impacted>0?element.impacted:"--"}}
                </td>
            </ng-container>

            <ng-container matColumnDef="devices" style="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold;">Devices
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">{{element.all_devices}}</td>
            </ng-container>

            <ng-container matColumnDef="oncall" style="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold; ">On Call
                </th>
                <td mat-cell *matCellDef="let element">
                    <app-table-oncall-icon [value]="element.on_call > 0 ? 1 : 0"
                        style="text-align: center;"></app-table-oncall-icon>
                </td>
            </ng-container>


            <ng-container matColumnDef="mos" style="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold; ">MOS
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">{{element.total_mos == null ? '--' :
                    element.total_mos}}</td>
            </ng-container>

            <ng-container matColumnDef="totalcalls" style="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold; ">Total
                    Calls(60mins)
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                    {{element.total_calls>0?element.total_calls:"--"}}</td>
            </ng-container>

            <ng-container matColumnDef="totalincidents" style="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold; ">Total
                    Incidents(60mins)
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                    {{element.total_incidents>0?element.total_incidents:"--"}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="clickRow(row)"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" [colSpan]="displayedColumns.length">No data found in table</td>
            </tr>
        </table>
    </div>
</div>