import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; 
import { Router } from '@angular/router';  
import { GetAudioService } from '../../../services/get-audio.service';
import { TableFilterService } from '../../../services/table-filter.service';
import { Subscription } from 'rxjs/Subscription';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-audio-all-devices-list',
  templateUrl: './audio-all-devices-list.component.html',
  styleUrls: ['./audio-all-devices-list.component.scss']
})
export class AudioAllDevicesListComponent implements OnInit {
  @Input() audioDevicesListData: MatTableDataSource<any>;
  loading: boolean = false;
  filterListner: Subscription;

  // Settings and Parameters for the the Smart Filter Tables
  // audioDeviceTableSettings = this.getAudioDataService.audioDeviceTableSettings;

  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private getAudioDataService: GetAudioService,  
    private tableFilterService: TableFilterService
  ) { 
    this.filterListner = this.tableFilterService.listen().subscribe((m:any) => {
      this.onFilter(m);
    });
  }

  ngOnInit() {
    this.onLoadFilter();
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  // This function handles the routing for when a table row is clicked
  onClickTable(event){
    let device = event.data;
    this.router.navigate(['audio/device/',device.device_id]);
  }

  onFilter(event) {
    const query: string = event[0];
    const cat: string = event[1];
    const pausedQuery: string = '0'
    const modifier: string = event[2] || "";
  }

  onLoadFilter() {
    if (this.router.url == '/audio/impacteddevices') {
      let arr = ['31', 'health']
      this.onFilter(arr);
    } else if (this.router.url == '/audio/warningdevices') {
      let arr = ['76', 'health', "midHealth"]
      this.onFilter(arr);
    } else if (this.router.url == '/audio/pauseddevices') {
      let arr = ['1', 'paused']
      this.onFilter(arr);
    } else if (this.router.url == '/audio/healthydevices') {
      let arr = ['76', 'health', 'onlyHealthy']
      this.onFilter(arr);
    }
  }
}
