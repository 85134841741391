import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { LinkDesktopPluginHostDialogComponent } from '../../iot/dialogs/link-desktop-plugin-host-dialog/link-desktop-plugin-host-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { GetTeamsService } from '../../services/get-teams.service';
import { GetCloudService } from 'src/app/services/get-cloud.service';
import { TeamsUserDeleteUserComponent } from '../dialogs/teams-user-delete-user/teams-user-delete-user.component';

// import { ConsoleReporter } from 'jasmine';
// declare var $: any;

@Component({
  selector: 'app-teams-users',
  templateUrl: './teams-users.component.html',
  styleUrls: ['./teams-users.component.scss'],
})
export class TeamsUsersComponent implements OnInit, OnChanges {
  @Output() sendPopupType: EventEmitter<any> = new EventEmitter<any>();
  @Input() searchKey: any;
  @Input() teamsUserList: any = [];
  @Input() hostToLink: any;
  @Input() totalUserCount: any;
  @Input() showLoader: any;
  @Input() totalTeamsUser: any;
  @Input() spaceStatus: any;
  
  @ViewChild('scrollBarElement', { static: true }) public scrollBarElement: ElementRef;
  loader = environment.config.dynamicImage.loader;
  desktopIcon = environment.config.dynamicImage.desktopIcon;
  shouldShowTh: boolean = false;

  constructor(
    public dialog: MatDialog,
    private getTeams: GetTeamsService,
    private getCloudUserService: GetCloudService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.teamsUserList && this.teamsUserList) {
      if (this.teamsUserList.length !== 0) {
        this.setData();
      }
    }

    // // console.log('this.teamsUserList.length: ', this.teamsUserList.length)
  }

  removeScrollEvent = () => {
    this.scrollBarElement.nativeElement.removeEventListener("scroll", this.myScroller);
  }

  scrollEvent = () => {
    this.scrollBarElement.nativeElement.addEventListener('scroll', this.myScroller);
  }
  myScroller() {
    const triggerHeight = this.scrollBarElement.nativeElement.scrollTop + this.scrollBarElement.nativeElement.clientHeight;
    if (Math.trunc(triggerHeight) == this.scrollBarElement.nativeElement.scrollHeight) {
      this.sendPopupType.emit('loadMoreUsers');
      this.removeScrollEvent();
    }
  }
  setData = () => {
    this.scrollEvent();
    if (this.teamsUserList.length >= this.totalTeamsUser || this.searchKey !== '') {
      this.removeScrollEvent();
    }
    let checkTeamsUserList = this.teamsUserList.filter(e => e.plugin_id == null);
    this.shouldShowTh = checkTeamsUserList.length > 0 ? true : false;
    this.teamsUserList = this.teamsUserList.map((e) => {
      return {
        ...e,
        assigned_user: this.combineName(e),
        email: e.email !== null ? e.email : '--',
      };
    });
  };

  combineName = ({ firstname, lastname }) => {
    return firstname === null && lastname === null ? '--' : `${firstname} ${lastname}`;
  };

  getPhoneCallStatus = (status) => {
    if (status === 1) {
      return '../../../assets/img/vs_img/green-phone.png';
    }

    return '../../../assets/img/vs_img/black-phone.png';
  };

  getDesktopStatus = (status) => {
    if (status === 1) {
      return 'teams-users__status--green';
    }

    if (status === -1) {
      return 'teams-users__status--red';
    }
    return '';
  };

  getLinkIcon = (params) => {
    const { plugin_active } = params;
    return plugin_active !== null ? './assets/img/vs_img/teams_unlink.png' : './assets/img/vs_img/teams_link.png';

  };



  getPluginIcon = (plugin_active) => {
    if (plugin_active !== null) {
      return this.desktopIcon;
    }
    return '--';
  };

  alertsChange(e, id) {
    if (e.currentTarget.checked) {
      this.getCloudUserService.enableAlerts(id).subscribe((data) => {
      });
    } else {
      this.getCloudUserService.disableAlerts(id).subscribe((data) => { });
    }
  }

  desktopClientChange(bool, zoom_user_id) {
    this.getCloudUserService.setEnableDesktopPlugin(zoom_user_id, bool).subscribe((data) => {
    });
  }

  openDesktopLinkModal = (items) => {
    const config = this.hostToLink;
    const webportal = this.dialog.open(LinkDesktopPluginHostDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        config,
        user: items,
        sendPopupType: this.sendPopupType,
      },
    });
  };

  openDeleteTeamsUser = (zoom_user_id, assignedUser) => {
    const webportal = this.dialog.open(TeamsUserDeleteUserComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        zoom_user_id,
        assignedUser,
        sendPopupType: this.sendPopupType,
      },
    });
  };
}
