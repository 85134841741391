<div class="teams-admin">
    <div class="teams-admin__left-container">
        <button class="teams-admin__btn" (click)="showRebootPCFunc(true)">
            Reboot PC
        </button>
        <button *ngIf="!isTeamsRoom" class="teams-admin__btn" (click)="showRebootZoomFunc(true)">
            Restart Zoom
        </button>
        <button *ngIf="isTeamsRoom" class="teams-admin__btn" (click)="showRebootTeamsFunc(true)">
            Restart Teams
        </button>
    </div>

    <div class="teams-admin__right-container">

        <div *ngIf="showRebootPC" class="teams-admin__panel">
            <div class="teams-admin__panel-title">
                Are you sure you want to reboot your computer?
            </div>

            <div *ngIf="showMessage" class="teams-admin__success-message">
                {{ message }}
            </div>


            <div class="teams-admin__panel-button-container">
                <button class=" teams-admin__btn" (click)="rebootPc()">
                    Confirm
                </button>
                <button class="teams-admin__btn" (click)="showRebootPC = false">
                    Cancel
                </button>
            </div>
        </div>

        <div *ngIf="showRebootZoom" class="teams-admin__panel">
            <div class="teams-admin__panel-title">
                Are you sure you want to reboot zoom?
            </div>

            <div *ngIf="showMessage" class="teams-admin__success-message">
                {{ message }}
            </div>


            <div class="teams-admin__panel-button-container">
                <button class=" teams-admin__btn" (click)="restartZoom()">
                    Confirm
                </button>
                <button class="teams-admin__btn" (click)="showRebootZoom = false">
                    Cancel
                </button>
            </div>
        </div>
        <div *ngIf="showRebootTeams" class="teams-admin__panel">
            <div class="teams-admin__panel-title">
                Are you sure you want to reboot teams?
            </div>

            <div *ngIf="showMessage" class="teams-admin__success-message">
                {{ message }}
            </div>


            <div class="teams-admin__panel-button-container">
                <button class=" teams-admin__btn" (click)="restartTeams()">
                    Confirm
                </button>
                <button class="teams-admin__btn" (click)="showRebootTeams = false">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>