import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-video-remote',
  templateUrl: './video-remote.component.html',
  styleUrls: ['./video-remote.component.scss']
})
export class VideoRemoteComponent implements OnInit, OnChanges {

  @Input() logitechData: any;
  @Input() isDevicePaused: boolean;
  onlineIcon: string = '../../../../assets/img/touch_icon/logitech_remote_online.png';
  warningIcon: string = '../../../../assets/img/touch_icon/logitech_remote_warning.png';
  offlineIcon:string = '../../../../assets/img/touch_icon/logitech_remote_offline.png';
  pauseIcon:string = '../../../../assets/img/touch_icon/logitech_remote_pause.png';
  touchIcon: string = this.offlineIcon;
  displayName: any;
  displayMac: any;
  displayFirmware: any;
  displayBatteryLevel: any;
  
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if((changes.logitechData && this.logitechData) || changes.isDevicePaused) {
      this.setData();
    }
  }
  
  setData = () => {
    // console.log('VideoTouchPanelLogitech: ', this.logitechData)
    const { settings: { peripherals } } = this.logitechData
    
    if(peripherals) {
      if(peripherals.length > 0) {
        const [getdisplay] = peripherals.filter( e => e.type == 'REMOTE')
        this.displayName = getdisplay.name
        this.displayMac = getdisplay.macAddress
        this.displayBatteryLevel = getdisplay.batteryLevel
        // if( this.displayBatteryLevel == -1) {
        //   this.touchIcon = this.warningIcon
        //   } else {
        //   this.touchIcon = this.warningIcon
        
        if (this.isDevicePaused)
          this.touchIcon = this.pauseIcon
        else if(this.displayBatteryLevel >= 65)
          this.touchIcon = this.onlineIcon        
        else if(this.displayBatteryLevel >= 21 &&  this.displayBatteryLevel < 65)
          this.touchIcon = this.warningIcon
        else if(this.displayBatteryLevel < 21)
          this.touchIcon = this.offlineIcon
        
        // this.touchIcon = this.onlineIcon;
        // this.displayPowerSaving = getTap.tapCustomOrientation
      }
    
    }
    
  }

}
