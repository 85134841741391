import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { GetIotService } from '../../services/get-iot.service';
import { IotLiteAddDeviceWithPortsDialogComponent } from '../dialogs/iot-lite-add-device-with-ports-dialog/iot-lite-add-device-with-ports-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-iot-lite-add-device',
  templateUrl: './iot-lite-add-device.component.html',
  styleUrls: ['./iot-lite-add-device.component.scss']
})

export class IotLiteAddDeviceComponent implements OnInit, OnChanges {
  @Input() iotLiteEnabled: any
  siteList: any = [];
  deviceMake: any;
  collectorID: any = "";
  siteId: any = "";
  iotType: any = "";

  deviceName: any;
  deviceIP: any;
  devicePorts: any = [];
  setTimer: any

  actionDeviceName: any = '';
  actionDeviceIP: any = '';
  showSuccessMsg: any = false;
  showSiteListErrMsg: any = false;

  showAddDevice: any = false;
  disableSelectAndSubmit: any = false;
  collectorLatestVersion: any = '';
  isIpAddressInvalid: boolean = false

  selectData: any = [
    { id: 1, value: '../../../assets/img/iot/monitor.png', type: 'DISPLAY' },
    { id: 2, value: '../../../assets/img/iot/server.png', type: 'SERVER' },
    { id: 3, value: '../../../assets/img/iot/switch.png', type: 'NETWORK' },
    { id: 4, value: '../../../assets/img/iot/browser.png', type: 'ACCESS POINT' },
    { id: 5, value: '../../../assets/img/iot/pdu.png', type: 'POWER' },
    { id: 6, value: '../../../assets/img/iot/wifi.png', type: 'WEB' },
    { id: 7, value: '../../../assets/img/iot/cam.png', type: 'CAMERA' },
    { id: 8, value: '../../../assets/img/iot/phonetablet.png', type: 'DEVICE' },
    { id: 9, value: '../../../assets/img/iot/cloud.png', type: 'CLOUD' },
  ];

  iotTypeList: any = [
    { id: 1, value: 'DISPLAY' },
    { id: 2, value: 'SERVER' },
    { id: 3, value: 'NETWORK' },
    { id: 4, value: 'ACCESS POINT' },
    { id: 5, value: 'POWER' },
    { id: 6, value: 'WEB' },
    { id: 7, value: 'CAMERA' },
    { id: 8, value: 'DEVICE' },
    { id: 9, value: 'CLOUD' },
  ];

  disableAllFields: any = false;


  constructor(
    private Auth: AuthService,
    private GetIot: GetIotService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.collectorLatestVersion = '2.1.0';
    this.getSiteList();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.iotLiteEnabled) {
      if (this.iotLiteEnabled) {
        this.checkIfIotDisabled();
      } else {
        this.disableAllFields = true;
      }
    }
  }

  validateIpAddress(): boolean {
    // Regular expression to validate IP address format
    const ipRegex = /^(?!255)(?!0)\d{1,3}\.\d{1,3}\.\d{1,3}\.(?!255)(?!0)\d{1,3}$/;

    if (!ipRegex.test(this.actionDeviceIP)) {

      this.isIpAddressInvalid = true;
      return true;
    }
    else {
      this.isIpAddressInvalid = false;
      return false;
    }
  }

  checkIfIotDisabled = () => {
    this.disableAllFields = true;
    if (this.iotLiteEnabled) {
      this.disableAllFields = false;
    }
    // console.log('disableAllFields: ',this.disableAllFields)
  }

  getSiteList = () => {
    this.Auth.getAllSiteList().subscribe((data) => {
      let res = data.response;
      this.siteList = res.map(e => {
        return {
          collector_id: e.collector.collector_id,
          version: e.collector.version,
          status: e.collector_status,
          site_name: e.site_name,
          site_id: e.site_id,
        }
      }).filter(e => e.status === 1)
      // console.log('res: ',res)
      // console.log('this.siteList: ',this.siteList)
      if (this.siteList.length < 1) {
        this.showSiteListErrMsg = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

  siteSelectorEvent = (event: any) => {
    const siteId = this.siteId;
    // console.log('this.siteId: ', this.siteId)
    const [siteDetails] = this.siteList.filter(e => e.site_id == siteId);
    console.log(siteDetails)
    const { version, collector_id } = siteDetails;
    this.disableSelectAndSubmit = this.collectorLatestVersion > version;
    this.collectorID = collector_id;
  }

  checkIfEmptyField = (params) => {
    return params == '';
  }

  actionDeviceIPEmpty: any = false;
  actionDeviceNameEmpty: any = false;
  iotTypeEmpty: any = false
  errorCount: any = 0;

  //improve this T_T
  validation = () => {
    if (this.checkIfEmptyField(this.collectorID)) {
      this.disableSelectAndSubmit = true;
      this.errorCount++;
    } else {
      this.disableSelectAndSubmit = false;
      //this.errorCount--;
    }

    if (this.checkIfEmptyField(this.actionDeviceIP)) {
      this.actionDeviceIPEmpty = true;
      this.errorCount++;
    } else {
      this.actionDeviceIPEmpty = false;
      // this.errorCount--;
    }

    if (this.checkIfEmptyField(this.actionDeviceName)) {
      this.actionDeviceNameEmpty = true;
      this.errorCount++;
    } else {
      this.actionDeviceNameEmpty = false;
      // this.errorCount--;

    }

    if (this.checkIfEmptyField(this.iotType)) {
      this.iotTypeEmpty = true;
      this.errorCount++;
    } else {
      this.iotTypeEmpty = false;
      //this.errorCount--;
    }

    if (this.validateIpAddress()) {
      this.actionDeviceIPEmpty = true;
      this.errorCount++;
    } else {
      this.actionDeviceIPEmpty = false;
      // this.errorCount--;
    }

    if (this.errorCount > 0) {
      return true;
    }
  }

  openAddIotLiteDeviceDialog = () => {
    if (this.validation()) {
      this.errorCount = 0;
    } else {
      const config = {
        collector_id: this.collectorID,
        actionDeviceIP: this.actionDeviceIP,
        actionDeviceName: this.actionDeviceName,
        iot_type: this.iotType,
        site_id: this.siteId
      };

      this.dialog.open(IotLiteAddDeviceWithPortsDialogComponent, {
        panelClass: 'custom-dialog-container',
        data: {
          config,
        },
      });
    }
  }

}
