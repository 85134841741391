import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root',
})
export class PopupTypeService {
  loader: boolean = false;
  private subject = new Subject<any>();

  sendPopupType(message: number, data?: any, isEnabled: boolean = true) {
    if (isEnabled) {
      if (typeof data === 'function') {
        const dataWithFunc = {
          'popUpEmitter': data,
        };

        const testSubject = {
          popupId: message,
          data: dataWithFunc,
          ...dataWithFunc,
          isEnabled,
        };
        this.subject.next(testSubject);
      } else {
        const t = {
          popupId: message,
          data: data,
          ...data,
          isEnabled,
        };
        this.subject.next(t);
      }
    }
  }

  clearPopupType() {
    this.subject.next();
  }

  getPopupType(): Observable<any> {
    return this.subject.asObservable();
  }
}
