import { GetPluginService } from 'src/app/services/get-plugin.service'

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'

import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'
import { PopupTypeService } from '../../services/popupType.service'
import { ZoomPluginActionService } from 'src/app/services/zoom-plugin-action.service'

@Component({
  selector: 'app-teams-admin',
  templateUrl: './teams-admin.component.html',
  styleUrls: ['./teams-admin.component.scss']
})
export class TeamsAdminComponent implements OnInit {
  @Output() showLinkSiteEvent = new EventEmitter<boolean>();
  @Input() data: any;
  showDocuments: any = false;
  showLinkToSite: any = false;
  showConfirmation: any = false;
  showSnapshotPanel: boolean = false;
  showFlushDNS: boolean = false;
  showClearTeamCache: boolean = false;
  teamsDetails: any;
  pluginId: any;
  snapshotMessage: string;
  showSnapshotLoader: boolean = false;
  docPath: string = environment.serverUrl + '/api/v1/files/get-file/';
  isRefresh: boolean = false;
  constructor(
    private Auth: AuthService,
    private getPluginService: GetPluginService,
    private ds: PopupTypeService,
    public zoomPluginAction: ZoomPluginActionService,

  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && this.data) {
      this.setData();


    }
  }

  setData = () => {
    this.getSiteList();
    // // console.log('this: ', this.data)
    const { details } = this.data;
    this.pluginId = details.plugin_id;

  }

  resetFields = () => {
    this.showDocuments = false;
    this.showLinkToSite = false;
    this.showConfirmation = false;
    this.showSnapshotPanel = false;
    this.showClearTeamCache = false;
    this.showFlushDNS = false;
  }

  showPanel = (panel) => {
    this.resetFields();
    if (panel === 1) {
      this.showDocuments = true;
    }

    if (panel === 2) {
      this.showLinkToSite = true;
      this.showConfirmation = true;
    }

    if (panel === 3) {
      this.showSnapshotPanel = true;
      this.takeSnapshot()
    }

    if (panel === 4){
      this.isRefresh = !this.isRefresh;
      this.showFlushDNS = true;
    }

    if (panel === 5){
      this.isRefresh = !this.isRefresh;
      this.showClearTeamCache = true;
    }
  }

  siteList: any;
  showSiteListErrMsg: any = false;
  errorMessage: any = 'Could not find device. Please try again.';

  siteId: any;
  getSiteList = () => {
    this.Auth.getAllSiteList().subscribe((data) => {
      let res = data.response;
      // console.log('res',res)

      this.siteList = res.map(e => {
        return {
          collector_id: e.collector.collector_id,
          status: e.collector_status,
          site_name: e.site_name,
          site_id: e.site_id,
        }
      }).filter(e => e.status === 1)

      if (this.siteList) {

        this.siteId = this.siteList.length > 0 ? this.siteList[0].site_id : 0;
        // console.log(this.siteId)
      }

      if (this.siteList.length < 1) {
        this.showSiteListErrMsg = true;
      }
    });
  }

  siteMessage: any;
  dCollector: String = environment.config.dynamicText.dCollector;
  dPlugin: String = environment.config.dynamicText.dPlugin;

  isLoading: boolean = false;
  shouldShowSiteMessage: boolean = false;
  firmwareSuccessMessageClass: any;
  isDoneLinkingSite: boolean = false;
  setTimer: any;
  alertsTriggerData: any;



  linkRoomToSite = () => {
    this.siteMessage = '';
    const siteId = this.siteId;
    const ipAddress = this.data.ipAddress;
    // this.isShowPluginDownload = false;
    // this.zoomPluginAction.visibility_one_loader = true;

    const siteInfo = this.siteList.find((s) => {
      return s.site_id === parseInt(siteId);
    });

    // console.log('ipAddress: ', ipAddress)
    // console.log('this.data: ', this.data)

    try {
      this.isLoading = true;
      if (siteInfo.collector_status === -1) {
        this.siteMessage = `Site ${this.dCollector} is offline.`;
        // this.isLoading = false;
        this.shouldShowSiteMessage = true;
        this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
        this.resetSiteMessage();
        throw "collector is offline";
      } else if (ipAddress) {
        const pluginId = this.pluginId; // pluigin id
        const serviceType = 'teams'

        this.getPluginService.linkPluginToSiteUpdate(siteId, pluginId, serviceType).subscribe((data) => {

          // console.log('linkPluginToSiteUpdate: ', data)
          if (data.response[0].status === 1) {
            this.setShowLinkSite(false); // for the button
            this.siteMessage = `Successful in Site ${this.dCollector} linking `;
            this.shouldShowSiteMessage = true;
            this.firmwareSuccessMessageClass = ' admin__confirm-message--green'
            this.isDoneLinkingSite = true;
            this.resetSiteMessage()
            this.setTimer = setTimeout(() => {
              this.ds.sendPopupType(0);
              this.isLoading = false; 
            }, 3000);

          } else {
            this.siteMessage = `${this.dPlugin} does not exist.`;
            this.shouldShowSiteMessage = true;
            this.firmwareSuccessMessageClass = ' admin__confirm-message--red';
            this.resetSiteMessage();
            throw "plugin does not exist"
          }
        });

      } else {

        this.siteMessage = 'Room has no IP address.';
        this.shouldShowSiteMessage = true;
        this.firmwareSuccessMessageClass = ' admin__confirm-message--red'
        this.resetSiteMessage()
        //this.isLoading = false;
        this.setTimer = setTimeout(() => {
        this.ds.sendPopupType(0);
        this.isLoading = false; 
        }, 3000);

        throw "room has no ip address"
      }
    } catch (error) {
      console.log(error);

    } finally {
      this.isLoading = false;
    }


  };

  setShowLinkSite(value: boolean) {
    this.showLinkSiteEvent.emit(value);
  }

  getComputerIp(deviceIp: string): string {
    const len = deviceIp.length;
    let searhKey = 'Computer';
    if (len > 0) {
      const cPosition = deviceIp.search(searhKey);
      if (cPosition >= 0) {
        const startIndex = cPosition + searhKey.length + 2;
        const ips = deviceIp.substring(startIndex).split(';');
        return ips[0].trim();
      }

      searhKey = 'Digital Signage Only';
      const dPosition = deviceIp.search(searhKey);
      if (dPosition >= 0) {
        const startIndex = cPosition + searhKey.length + 2;
        const ips = deviceIp.substring(startIndex).split(';');
        return ips[0].trim();
      }
      return '';
    }
    return '';
  }


  resetSiteMessage = () => {
    this.setTimer = setTimeout(() => {
      this.shouldShowSiteMessage = false;
    }, 3000);
    // this.zoomPluginAction.visibility_one_loader = false;
  }

  takeSnapshot = () => {
    const actionParams = {};
    const actionName = 'TakeImagefromWebCam';
    this.snapshotMessage = `<h5>Capturing Snapshot<h5/>`
    this.toggleSnapshotLoader(true)
    
    this.getPluginService.createAction(this.pluginId, actionParams, actionName).subscribe(result => {
      if (result.status === 200) {
        this.zoomPluginAction.checkActionStatus(result.response[0].action_id, this.processActionResults)
        this.zoomPluginAction.setActionCount();
      }
      else {
        this.snapshotMessage = result?.response[0]?.message || `<h5>Something went wrong. Please try again later</h5>`
        this.toggleSnapshotLoader(false)
      }
    }, err => {
      this.snapshotMessage = `<h5>Something went wrong. Please try again later</h5>`
      this.toggleSnapshotLoader(false)
    });
  }

  processActionResults = (status, results) => {
    if (status === 'COMPLETE')
      this.uploadDeviceSnapshotToS3(results)
    else {
      this.snapshotMessage = `<h5>Something went wrong. Please try again later</h5>`
      this.toggleSnapshotLoader(false)
    }
  }

  uploadDeviceSnapshotToS3 = (results) => {
    if (results?.result?.status == "Failed") {
      this.snapshotMessage = results?.result?.message;
      this.toggleSnapshotLoader(false);
      return;
    }
    const { details: { company_id }, device_id } = this.data;
    this.snapshotMessage = `<h5>Snapshot successfully captured. Please wait while we securely upload it to the Document Centre</h5>`
    this.getPluginService.uploadSnapshotToS3(company_id, device_id, results?.result?.data).subscribe(resp => {
      if (resp && resp.response) {
        this.snapshotMessage = `<h5>Snapshot successfully uploaded to the Document Centre</h5>
        You can download it here: <a href="${this.docPath+resp?.response?.file_id+'/'+1}">${resp?.response?.file_name}</a>`
        this.toggleSnapshotLoader(false);
      }
      else {
        this.snapshotMessage = `<h5>Something went wrong. Please try again later</h5>`
        this.toggleSnapshotLoader(false);
      }
    }, err => {
      this.snapshotMessage = `<h5>Something went wrong. Please try again later</h5>`
      this.toggleSnapshotLoader(false)
    })
  }

  toggleSnapshotLoader = (show) => {
		// this.zoomPluginAction.visibility_one_loader = show;
		this.showSnapshotLoader = show
	}

}
