<div class="reports-video-conf-stats">


  <div class="reports-video-conf-stats__left-panel">


    <h3>Sites</h3>

    <div class="reports-video-conf-stats__left-panel-item">

      <div class="reports-video-conf-stats__left-panel-item-text">
        <h1 class="text-center report-bold">{{videoConferSite?.total_sites}}</h1>
        <h4 class="text-center">Total sites</h4>
      </div>

      <div class="reports-video-conf-stats__text-circle-container">
        <div class="reports-video-conf-stats__text-circle">
          <h1 class="text-center report-bold">{{videoConferSite?.impacted_sites?.impacted}}</h1>
          <div class="reports-video-conf-stats__circle-container">
            <div *ngIf="videoConferSite?.impacted_sites?.difference <= -100"
              class="report-oval float-right text-success">
              <h5 class="text-center report-oval-number">{{videoConferSite?.impacted_sites?.difference}}</h5>
            </div>
            <div
              *ngIf="videoConferSite?.impacted_sites?.difference < 0 && videoConferSite?.impacted_sites?.difference >-100"
              class="report-circle float-right text-success">
              <h5 class="text-center report-circle-number">{{videoConferSite?.impacted_sites?.difference}}</h5>
            </div>
            <div
              *ngIf="videoConferSite?.impacted_sites?.difference > 0 && videoConferSite?.impacted_sites?.difference < 100"
              class="report-circle float-right text-danger">
              <h5 class="text-center report-circle-number">+{{videoConferSite?.impacted_sites?.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite?.impacted_sites?.difference > 100" class="report-oval float-right text-danger">
              <h5 class="text-center report-oval-number">+{{videoConferSite?.impacted_sites?.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite?.impacted_sites?.difference == 0" class="report-circle float-right text-dark">
              <h5 class="text-center report-circle-number">--</h5>
            </div>
          </div>

        </div>
        <h4 class="text-center">Impacted</h4>
      </div>
    </div>

    <div class="reports-video-conf-stats__border-bottom"></div>


    <h4>Conference Rooms</h4>

    <div class="reports-video-conf-stats__left-panel-item">

      <div class="reports-video-conf-stats__left-panel-item-text">
        <h1 class="text-center report-bold">{{videoConferSite?.total_rooms}}</h1>

        <h4 class="text-center">Total rooms</h4>
      </div>

      <div class="reports-video-conf-stats__text-circle-container">
        <div class="reports-video-conf-stats__text-circle">
          <h1 class="text-center report-bold">{{videoConferSite?.impacted_rooms.impacted}}</h1>
          <div class="reports-video-conf-stats__circle-container">
            <div *ngIf="videoConferSite.impacted_rooms.difference <=-100" class="report-oval float-right text-success">
              <h5 class="text-center report-oval-number">{{videoConferSite.impacted_rooms.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite.impacted_rooms.difference <0 && videoConferSite.impacted_rooms.difference >-100"
              class="report-circle float-right text-success">
              <h5 class="text-center report-circle-number">{{videoConferSite.impacted_rooms.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite.impacted_rooms.difference>0 && videoConferSite.impacted_rooms.difference  < 100"
              class="report-circle float-right text-danger">
              <h5 class="text-center report-circle-number">+{{videoConferSite.impacted_rooms.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite.impacted_rooms.difference>=100" class="report-oval float-right text-danger">
              <h5 class="text-center report-oval-number">+{{videoConferSite.impacted_rooms.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite.impacted_rooms.difference==0" class="report-circle float-right text-dark">
              <h5 class="text-center report-circle-number">--</h5>
            </div>
          </div>

        </div>
        <h4 class="text-center">Impacted</h4>
      </div>
    </div>

    <div class="reports-video-conf-stats__border-bottom"></div>


    <!-- AXE FIX -->


    <h4>Registration</h4>

    <div class="reports-video-conf-stats__left-panel-item">

      <div class="reports-video-conf-stats__left-panel-item-text">
        <h1 class="text-center report-bold">{{videoConferSite.total_huddle_rooms?videoConferSite?.total_huddle_rooms:0}}
        </h1>

        <h4 class="text-center">Total incidents</h4>
      </div>

      <div class="reports-video-conf-stats__text-circle-container">
        <div class="reports-video-conf-stats__text-circle">
          <h1 class="text-center report-bold">
            {{videoConferSite.impacted_huddle_rooms?videoConferSite?.impacted_huddle_rooms.impacted:0}}</h1>
          <div class="reports-video-conf-stats__circle-container">
            <div *ngIf="videoConferSite.impacted_rooms.difference <=-100" class="report-oval float-right text-success">
              <h5 class="text-center report-oval-number">{{videoConferSite.impacted_rooms.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite.impacted_rooms.difference <0 && videoConferSite.impacted_rooms.difference >-100"
              class="report-circle float-right text-success">
              <h5 class="text-center report-circle-number">{{videoConferSite.impacted_rooms.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite.impacted_rooms.difference>0 && videoConferSite.impacted_rooms.difference  < 100"
              class="report-circle float-right text-danger">
              <h5 class="text-center report-circle-number">+{{videoConferSite.impacted_rooms.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite.impacted_rooms.difference>=100" class="report-oval float-right text-danger">
              <h5 class="text-center report-oval-number">+{{videoConferSite.impacted_rooms.difference}}</h5>
            </div>
            <div *ngIf="videoConferSite.impacted_rooms.difference==0" class="report-circle float-right text-dark">
              <h5 class="text-center report-circle-number">--</h5>
            </div>
          </div>

        </div>
        <h4 class="text-center" style="opacity: 0;">Impacted</h4>
      </div>
    </div>




  </div>

  <!-- LEFT -->


  <div class="reports-video-conf-stats__right-panel">

    <h4 class="reports-video-conf-stats__right-label">Total number of incidents by type</h4>

    <div class="reports-video-conf-stats__right-panel-item">
      <div class="reports-video-conf-stats__right-panel-item-text">
        <h2>Camera(s)</h2>
      </div>



      <div class="reports-video-conf-stats__right-panel-item-text-circle">

        <div class="reports-video-conf-stats__right-panel-item-text">
          <h1>0</h1>
        </div>

        <div class="reports-video-conf-stats__right-panel-item-text reports-video-conf-stats__circle-incidents">
          <h5>--</h5>
        </div>

      </div>

    </div>

    <div class="reports-video-conf-stats__right-panel-item">
      <div class="reports-video-conf-stats__right-panel-item-text">
        <h2>Mic(s)</h2>
      </div>

      <div class="reports-video-conf-stats__right-panel-item-text-circle">

        <div class="reports-video-conf-stats__right-panel-item-text">
          <h1>0</h1>
        </div>

        <div class="reports-video-conf-stats__right-panel-item-text reports-video-conf-stats__circle-incidents">
          <h5>--</h5>
        </div>

      </div>
    </div>

    <div class="reports-video-conf-stats__right-panel-item">
      <div class="reports-video-conf-stats__right-panel-item-text">
        <h2>Speaker(s)</h2>
      </div>

      <div class="reports-video-conf-stats__right-panel-item-text-circle">

        <div class="reports-video-conf-stats__right-panel-item-text">
          <h1>0</h1>
        </div>

        <div class="reports-video-conf-stats__right-panel-item-text reports-video-conf-stats__circle-incidents">
          <h5>--</h5>
        </div>

      </div>
    </div>

    <div class="reports-video-conf-stats__right-panel-item">
      <div class="reports-video-conf-stats__right-panel-item-text">
        <h2>Network</h2>
      </div>

      <div class="reports-video-conf-stats__right-panel-item-text-circle">

        <div class="reports-video-conf-stats__right-panel-item-text">
          <h1>0</h1>
        </div>

        <div class="reports-video-conf-stats__right-panel-item-text reports-video-conf-stats__circle-incidents">
          <h5>--</h5>
        </div>

      </div>
    </div>

    <div class="reports-video-conf-stats__border-bottom"></div>


    <h4 class="reports-video-conf-stats__right-label">Wellness Check total incidents</h4>


    <div class="reports-video-conf-stats__right-panel-item">
      <div class="reports-video-conf-stats__right-panel-item-text">
        <img src="../../../../assets/img/vs_img/reports-wellness.png" alt="">
      </div>

      <div class="reports-video-conf-stats__right-panel-item-text-circle">

        <div class="reports-video-conf-stats__right-panel-item-text">
          <h1>0</h1>
        </div>

        <div class="reports-video-conf-stats__right-panel-item-text reports-video-conf-stats__circle-incidents">
          <h5>--</h5>
        </div>

      </div>
    </div>


  </div>