<div class="video-last-disconnect">
  <div class="video-last-disconnect__traingle-container">
    <img [src]="
          videoDeviceData?.oncall != 0 ||
          videoDeviceData?.lastcall == 'Normal' ||
          videoDeviceData?.lastcall == 'NORMAL' ||
          videoDeviceData?.lastcall == 'Normal Clearing' ||
          videoDeviceData?.lastcall == 'Normal call clear'
            ? '../../../assets/img/triangle_green.png'
            : '../../../assets/img/triangle_red.png'
        " />
  </div>

  <div class="video-last-disconnect__status-container">
    <h5>Last Call Disconnect</h5>
    <div *ngIf="videoDeviceData?.oncall == 1">
      Currently on a call
    </div>
    <div *ngIf="videoDeviceData?.oncall == 0">
      {{ videoDeviceData?.lastcall }}
    </div>
  </div>

</div>

<!-- new component -->
<!-- <div class="row">
      <div class="col-4">
        <span class="table-health-triangle">
          <img class="last-call-tri" [src]="
            videoDeviceData?.oncall != 0 ||
            videoDeviceData?.lastcall == 'Normal' ||
            videoDeviceData?.lastcall == 'NORMAL' ||
            videoDeviceData?.lastcall == 'Normal Clearing' ||
            videoDeviceData?.lastcall == 'Normal call clear'
              ? '../../../assets/img/triangle_green.png'
              : '../../../assets/img/triangle_red.png'
          " />
        </span>
      </div>
      <div class="col-8">
        <div>
          <h5>Last Call Disconnect</h5>
          <p style="margin-bottom: 0px;" *ngIf="videoDeviceData?.oncall == 1">
            Currently on a call
          </p>
          <p style="margin-bottom: 0px;" *ngIf="videoDeviceData?.oncall == 0">
            {{ videoDeviceData?.lastcall }}
          </p>
        </div>
      </div>
    </div> -->