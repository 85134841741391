import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-tooltip-stats',
  templateUrl: './tooltip-stats.component.html',
  styleUrls: ['./tooltip-stats.component.scss']
})
export class TooltipStatsComponent implements OnInit {
  @Input() userName: any;
  @Input() audioInputJitter: any;
  @Input() audioInputPacketLoss: any;
  @Input() audioOutputJitter: any;
  @Input() audioOutputPacketLoss: any;
  @Input() videoInputJitter: any;
  @Input() videoInputPacketLoss: any;
  @Input() videoInputFrameRate: any;
  @Input() videoOutputJitter: any;
  @Input() videoOutputPacketLoss: any;
  @Input() videoOutputFrameRate: any;
  @Input() contentInputJitter: any;
  @Input() contentInputPacketLoss: any;
  @Input() contentOutputJitter: any;
  @Input() contentOutputPacketLoss: any;
  calculatedMbps: number; 
  id: any

  constructor() { }

    nullCheck(value){
      return value? value : '--'
    }
  
    // printTime(t) {
    //   if (isNaN(t)) {
    //     let a = moment(t)
    //     return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mma');
    //   } else {
    //     let a = moment.unix(t)      
    //     return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mma');
    //   }
    // }
    ngOnInit(){
      // send message to subscribers via observable subject
    }
}
