import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetIotService } from '../../../services/get-iot.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-iot-domotz-dialog-component',
  templateUrl: './add-iot-domotz-dialog-component.component.html',
  styleUrls: ['./add-iot-domotz-dialog-component.component.scss'],
})
export class AddIotDomotzDialogComponentComponent {
  activeDevice: any = {
    active: null,
    id: null,
  };
  config: any;
  isLoading = false;
  isShowConnectingText = false;
  btnLoader: string = environment.config.dynamicImage.loader;
  iotDomotzList: any = [];
  iotDomotzToLinkDevice: any = [];
  quadrantDeviceId: number;
  quadrant: number;
  quadrantDeviceSiteId: number;
  roomName: string;
  displayMessage: string = '';
  iotEvent: EventEmitter<any>;
  searchKey: string = '';
  setTimer: any


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AddIotDomotzDialogComponentComponent>,
    private getIot: GetIotService
  ) {
    if (data) {
      const config = { ...data.config };
      this.iotDomotzList = [...config.iotDomotzList].map((e) => {
        return {
          ...e,
          selected: 0,
        };
      });

      // console.log('iotDomotzList: ',this.iotDomotzList)

      

      this.quadrantDeviceId = config.device_id;
      this.quadrant = config.quadrant;
      this.quadrantDeviceSiteId = config.site_id;
      this.roomName = config.room_name;
      this.iotEvent = config.iotEvent;
    }
  }

  getIotDeviceNameAndId = (iot_device_id, device_name = {}) => {
    const newObj = [iot_device_id];
    const index = this.checkIfIotDeviceIsOnList(iot_device_id);
    if (index !== -1) {
      const removedId = this.iotDomotzToLinkDevice.splice(index, 1);
      this.updateIotDomotzList();
    } else {
      this.iotDomotzToLinkDevice = this.iotDomotzToLinkDevice.concat(newObj);
      this.updateIotDomotzList();
    }

    this.displayMessage = '';
  };

  updateIotDomotzList = () => {
    this.iotDomotzList = this.iotDomotzList.map((e) => {
      return {
        ...e,
        selected: this.checkIfIotDeviceIsOnList(e.iot_device_id) !== -1 ? 1 : 0,
      };
    });
  };

  getIotDeviceSelectedListStatus = (status) => {
    return status === 1
      ? '../../../assets/img/iot/add-btn.png'
      : '../../../assets/img/iot/modal-btn.png';
  };

  checkIfIotDeviceIsOnList = (iot_device_id) => {
    return this.iotDomotzToLinkDevice.indexOf(iot_device_id);
  };

  onCancel = () => {
    if (!this.isLoading) {
      this.dialogRef.close(true);
    }
  };

  linkDevice = () => {
    this.isLoading = true;
    this.displayMessage = '';
    if (this.iotDomotzToLinkDevice.length !== 0) {
      for (let i = 0; i < this.iotDomotzToLinkDevice.length; i++) {
        this.linkIotDomotzDevice(this.iotDomotzToLinkDevice[i]);
      }
    } else {
      this.isLoading = false;
      this.displayMessage = 'Please select IoT Domotz Device.';
    }
  };

  linkIotDomotzDevice = (iot_device_id) => {
    this.getIot
      .setIotDeviceLink(
        this.quadrantDeviceId,
        this.roomName,
        this.getDeviceType(this.quadrant),
        iot_device_id,
        this.quadrantDeviceSiteId
      )
      .subscribe(
        (data: any) => {
          this.refreshData(this.quadrant);
         this.setTimer = setTimeout(() => {
            this.displayMessage = 'Linking IoT Domotz Device successful.';
            this.isLoading = false;
            this.onCancel();
          }, 2000);
        },
        (error) => {
          this.displayMessage = 'Someting went wrong when linking IoT Domotz Device.';
          this.isLoading = false;
        }
      );
  };

  getDeviceType = (quadrant) => {
    if (quadrant === 1) {
      return 'video';
    }
    if (quadrant === 2) {
      return 'audio';
    }

    return 'cloud';
  };

  refreshData = (quadrant) => {
    if (quadrant === 1) {
      this.iotEvent.emit('refreshVideoData');
      return;
    }
    if (quadrant === 2) {
      this.iotEvent.emit('refreshAudioData');
      return;
    }
    if (quadrant === 3) {
      this.iotEvent.emit('refreshCloudData');
      return;
    }
  };

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

}
