<div class="chart-historic-ping-status">
  <div class="chart-historic-ping-status__head">
    <div class="chart-historic-ping-status__head__print">
      <i class="fa fa-print fa-2x menu-icon" (click)="print()"></i>
    </div>
    <div class="chart-historic-ping-status__head__options">

      <button class="chart-historic-ping-status__head__options__btn" (click)="set60m()"
        [ngClass]="{ btnpressed: activeBtn == 1 }">
        60m
      </button>
      <button class="chart-historic-ping-status__head__options__btn" (click)="set24h()"
        [ngClass]="{ btnpressed: activeBtn == 2 }">
        24h
      </button>
      <button class="chart-historic-ping-status__head__options__btn" (click)="set7d()"
        [ngClass]="{ btnpressed: activeBtn == 3 }">
        7d
      </button>
      <!-- 
      <div class="chart-historic-ping-status__head__options__switch">
        <label class="chart-historic-ping-status__head__options__switch__label">List</label>
        <label class="switch">
          <input type="checkbox" [(ngModel)]="isDisplayChart" (click)="switchDisplayType()" />
          <span class="slider round"></span>
        </label>
        <label class="chart-historic-ping-status__head__options__switch__label">Chart</label>
      </div> -->
    </div>
  </div>

  <div class="chart-historic-ping-status__body">

    <!-- <canvas [hidden]="!isDisplayChart" id="myChartSite" [width]="canvasWidth" [height]="canvasHeight"></canvas> -->

    <canvas id="myChartSite" width="1150px" height="350px" style="padding-left: 150px"></canvas>

    <div *ngIf="!isDisplayChart" id="historic-ping-status-list"
      class="chart-historic-ping-status__body__table__container">
      <table mat-table [dataSource]="dataSource" class="chart-historic-ping-status__body__table">
        <ng-container matColumnDef="median">
          <th mat-header-cell *matHeaderCellDef><strong>Median RTD</strong></th>
          <td mat-cell *matCellDef="let row">
            {{ row.median }}
          </td>
        </ng-container>

        <ng-container matColumnDef="min">
          <th mat-header-cell *matHeaderCellDef><strong>Best RTD</strong></th>
          <td mat-cell *matCellDef="let row">
            {{ row.min }}
          </td>
        </ng-container>

        <ng-container matColumnDef="max">
          <th mat-header-cell *matHeaderCellDef><strong>Worst RTD</strong></th>
          <td mat-cell *matCellDef="let row">
            {{ row.max }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lost_packet_count">
          <th mat-header-cell *matHeaderCellDef><strong>Packet Loss</strong></th>
          <td mat-cell *matCellDef="let row">
            {{ row.lost_packet_count }}
          </td>
        </ng-container>

        <ng-container matColumnDef="timestamp">
          <th mat-header-cell *matHeaderCellDef><strong>Timestamp</strong></th>
          <td mat-cell *matCellDef="let row">
            {{ row.timestamp }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>