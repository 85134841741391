import { Component, Input, SimpleChanges, OnChanges } from "@angular/core";

@Component({
  selector: "app-btn-recording",
  templateUrl: "./btn-recording.component.html",
  styleUrls: ["./btn-recording.component.scss"]
})
export class BtnRecordingComponent implements OnChanges {
  @Input() count: number;
  @Input() label: string = "Not-Active";
  @Input() color: string;
  @Input() backcolorbtn: any;
  @Input() isbutton: string;
  @Input() active: boolean;
  @Input() activeN: any;
  @Input() activeBtn: number;

  prevcount: string;

  constructor() {}

  activeSelection = 0;
  setActive(n) {
    if (this.activeBtn == n) {
      this.activeBtn = 0;
    } else {
      this.activeBtn = n;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.count.currentValue != changes.count.previousValue) {
      // // console.log(changes.count.currentValue + ", pre" + changes.count.previousValue)
      // 1 second delay to display the first set of values
      // setTimeout(() => {
      this.backcolorbtn = "btnbacklite";
      // // console.log(this.backcolorbtn + "backcolorbtn")
      //   }, 100);
      setTimeout(() => {
        this.backcolorbtn = "btnbacklite-default";
        // // console.log(this.backcolorbtn + "backcolorbtn")
      }, 2500);
    } else {
      setTimeout(() => {
        this.backcolorbtn = "btnbacklite-default";
        // // console.log(this.backcolorbtn + "backcolorbtn")
      }, 1000);
    }
  }
}
