import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { NumericDictionary } from 'lodash';

@Component({
  selector: 'app-iot-summary-live-stats',
  templateUrl: './iot-summary-live-stats.component.html',
  styleUrls: ['./iot-summary-live-stats.component.scss'],
})
export class IotSummaryLiveStatsComponent implements OnInit, OnChanges {
  @Input() agentSummaryList;
  @Input() showIotLiteSite;

  impactedAgents: Number;
  impactedSites: Number;
  mbpsUp: Number;
  mbpsDown: Number;

  constructor() {}


  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.agentSummaryList && this.agentSummaryList) {
      this.setLiveStats(this.agentSummaryList);
      // console.log('agentSummaryList: ',this.agentSummaryList)
      // console.log(this.showIotLiteSite)
    }
  }

  setLiveStats = (data) => {
    const { all_impacted_agents, mbps_down, mbps_up } = data;
    this.mbpsUp = mbps_up;
    this.mbpsDown = mbps_down;
    this.impactedAgents = all_impacted_agents;

    this.impactedSites = 0; //  for now
  };
}
