import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-reports-cover-sheet',
  templateUrl: './reports-cover-sheet.component.html',
  styleUrls: ['./reports-cover-sheet.component.scss']
})
export class ReportsCoverSheetComponent implements OnInit {
  @Input() fromdate: any;
  @Input() todate: any;
  @Input() siteTitle: any;
  @Input() siteSubTitle: any;
  @Input() siteName: any;
  @Input() deviceName: any;
  @Input() companyInfo: any;
  @Input() letterGrade: any;
  @Input() isAllList: boolean = false;
  @Input() isAllDeviceList: boolean = false;
  @Input() isSingleDevice: boolean = false;
  months:any = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July ',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  dashboardLogo = environment.config.dynamicImage.dashboardLogo;

  constructor() { }

  ngOnInit() {
  }

}
