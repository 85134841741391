<div class="register" [ngStyle]="visibilityBackground">
  <div class="register__col">
    <div class="register__box" *ngIf="isLinkValid">
      <img [src]="dashboardLogo" />

      <div class="register__container-input-sm">
        <input
          class="{{ formControl.firstname.style }} register__input-sm"
          type="text"
          placeholder="firstname"
          value="register.firstname"
          [(ngModel)]="register.firstname"
          (keyup)="keyEvent($event, 'firstname')"
        />

        <input
          class="{{ formControl.lastname.style }}  register__input-sm"
          type="text"
          placeholder="lastname"
          value="register.lastname"
          [(ngModel)]="register.lastname"
          (keyup)="keyEvent($event, 'lastname')"
        />
      </div>

      <div>
        <input
          disabled="true"
          class="default"
          type="text"
          placeholder="email"
          value="register.email"
          [(ngModel)]="register.email"
        />
      </div>

      <div>
        <input
          disabled="true"
          class="default"
          type="text"
          placeholder="company"
          value="register.company"
          [(ngModel)]="register.company"
        />
      </div>

      <div class="register__container-input-sm">
        <div class="register__input-sm">
          <input
            class="{{ formControl.password.style }}"
            type="password"
            placeholder="password"
            value="register.password"
            [(ngModel)]="register.password"
            (keyup)="keyEvent($event, 'password')"
          />

          <div *ngIf="formControl.password.flag" class="error-msg">
            {{ formControl.password.message }}
          </div>
        </div>
        <div class="register__input-sm">
          <input
            class="{{ formControl.confirm.style }} "
            type="password"
            placeholder="confirm password"
            value="register.confirm"
            [(ngModel)]="register.confirm"
            (keyup)="keyEvent($event, 'confirm')"
          />

          <div *ngIf="formControl.confirm.flag" class="error-msg">
            {{ formControl.confirm.message }}
          </div>
        </div>
      </div>

      <div class="register__terms">
        <input
          class="{{ formControl.checkbox.style }}"
          type="checkbox" #checkboxInput
          [(ngModel)]="register.checkbox"
          (click)="checkboxEvent(checkboxInput.checked)"
        />
        <a
          href="{{ links.terms }}"
          target="_blank"
          [ngClass]="{
            'error': formControl.checkbox.style == 'error'
          }"
        >
          I agree to the terms and conditions.</a
        >
      </div>

      <button
        [ngClass]="{
          'disable-btn': disableBtn
        }"
        class="register__btn-primary"
        (click)="validateForm()"
      >
        Create Account
      </button>

      <div class="register__to-login">
        <div>You already have and acount?</div>
        <a routerLink="/login"> Click here to login</a>
      </div>
    </div>

    <div class="register__box" *ngIf="!isLinkValid">
      <img [src]="dashboardLogo" />
      <div class="register__invalid-link">
        Invalid Link
      </div>
      <button routerLink="/login" class="register__btn-primary">
        Return to Login
      </button>
    </div>
  </div>
</div>
