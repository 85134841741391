<div class="incident-messages-dialog">
	<div class="incident-messages-dialog__header">
		<div>Messages</div>
		<div (click)="closeDialog()" class="incident-messages-dialog__header-close-button">
			<img src="./assets/img/iot/delete-btn.png" alt="" />
		</div>
	</div>

	<div class="incident-messages-dialog__body m-0 p-3" mat-dialog-content>
		<div class="text-left mb-2" *ngFor="let message of messages; index as idx">
			<div class="media text-left rounded-lg p-3">
				<i class="fa fa-user-circle-o fa-2x mr-2" aria-hidden="true"></i>
				<div class="media-body">
					<h6 class="mb-1">{{ message.user?.first_name }} {{ message.user?.last_name }}</h6>
					<span *ngIf="message.message">{{ message.message }}</span>
					<ng-container *ngFor="let attachment of message.attachments">
						<!-- <p>{{ attachment }}</p> 
						<i class="fa fa-download" aria-hidden="true"></i> -->
						<p>
							<a [href]="attachment.file_path">{{ attachment.file_name }}</a>
							<!-- <a [href]="attachment">{{ attachment }}</a> -->
						</p>
					</ng-container>
				</div>
				<i
					class="fa fa-pencil m-2 pointer"
					aria-hidden="true"
					(click)="onEditMessage(message, idx)"
					*ngIf="user?.accesslevel === 'ADMIN' || user?.user_id === message.user?.user_id"
				></i>
			</div>
			<div class="mx-3 text-secondary">
				<span> Created at: {{ message.created_at | dateTimeZone : 'MM-DD-YYYY hh:mma' }} </span>
				<span *ngIf="message.edited_by">
					<span class="font-weight-bold mx-2">|</span>
					Edited by: {{ message.edited_by?.first_name }} {{ message.edited_by?.last_name }}</span
				>
				<span *ngIf="message.edited_at">
					<span class="font-weight-bold mx-2">|</span>
					Edited at: {{ message.edited_at | dateTimeZone : 'MM-DD-YYYY hh:mma' }}</span
				>
			</div>
		</div>
		<div *ngIf="messages?.length === 0" class="text-center m-5">
			It seems quiet here. To contribute to the discussion, type your message in the input field and click 'Send.'
		</div>
	</div>

	<div class="incident-messages-dialog__footer p-3">
		<div class="form-row">
			<div class="col-9">
				<div class="input-group mb-2">
					<textarea type="text" class="form-control" [(ngModel)]="message"></textarea>
					<div class="input-group-append pointer" (click)="fileInput.click()">
						<div class="input-group-text">
							<i class="fa fa-paperclip" aria-hidden="true"></i>
						</div>
					</div>
				</div>
				<input
					type="file"
					name="attachments"
					ngModel
					required
					#fileInput
					(change)="onAttachFile($event)"
					multiple
					hidden
				/>
				<div *ngFor="let file of newFiles; index as idx">
					<small class="d-block">
						<i class="fa fa-paperclip" aria-hidden="true"></i>
						{{ file.name }}
						<i class="fa fa-trash" aria-hidden="true" (click)="onRemoveNewAttachedFile(idx)"></i>
					</small>
				</div>
				<div *ngFor="let attachment of editedMessage?.attachments; index as idx">
					<small class="d-block">
						<i class="fa fa-paperclip" aria-hidden="true"></i>
						{{ attachment.file_name }}
						<i class="fa fa-trash" aria-hidden="true" (click)="onRemoveEditedMessageFile(attachment, idx)"></i>
					</small>
				</div>
				<p class="text-danger my-3" *ngIf="errorMessage">{{ errorMessage }}</p>
			</div>
			<div class="col-3">
				<button class="btn btn-block" (click)="onSendOrUpdateMessage()">{{ editedMessage ? 'Update' : 'Send' }}</button>
				<button class="btn btn-block" (click)="onCancelEditing()" *ngIf="editedMessage">Cancel</button>
			</div>
		</div>
	</div>
</div>
