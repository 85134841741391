import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { GetIotService } from '../../services/get-iot.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

export interface AgentListInterface {
  agent_name: string;
  ip: string;
  mac: string;
  device_count: number;
  impacted_devices: number;
  avg_mbps_down: number;
  avg_mbps_up: number;
  company_name: string;
}

@Component({
  selector: 'app-iot-summary-agent-list',
  templateUrl: './iot-summary-agent-list.component.html',
  styleUrls: ['./iot-summary-agent-list.component.scss'],
})
export class IotSummaryAgentListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[];

  dataSource: MatTableDataSource<AgentListInterface>;
  agentList: any = [];
  pageSizeSelect: any[] = [];
  totalPageLimit = 100;
  paginateOjb: any = {
    currentPage: 1,
    endIndex: 4,
    pageSize: 0,
    pages: [],
    startIndex: 0,
    totalItems: 0,
    totalPages: 0,
  };
  totalCount: number;
  pageSelected: any = 0;
  searchKey: String = 'null';
  refreshData: Boolean;
  @Input() filterRoute: String;
  greenIcon = '../../../assets/img/triangle_green.png';
  redIcon = '../../../assets/img/triangle_red.png';

  constructor(private getIot: GetIotService, public Auth: AuthService, private router: Router) {
    this.displayedColumns = this.isShowCompanyName()
      ? [
        'company_name',
        'agent_name',
        'status',
        'ip',
        'mac',
        'device_count',
        'impacted_devices',
        'avg_mbps_down',
        'avg_mbps_up',
      ]
      : [
        'agent_name',
        'status',
        'ip',
        'mac',
        'device_count',
        'impacted_devices',
        'avg_mbps_down',
        'avg_mbps_up',
      ];
  }

  ngOnInit() {
    this.getAllIotAgentSummaryList();
  }
  getAllIotAgentSummaryList = () => {
    this.getIot
      .getAllIotAgentSummaryList(
        this.searchKey,
        this.totalPageLimit,
        this.paginateOjb.startIndex,
        this.filterRoute
      )
      .subscribe((data) => {
        this.agentList = data;
        // // console.log('this.agentList: ', this.agentList)
        this.dataSource = new MatTableDataSource(this.agentList);
        this.dataSource.sort = this.sort;
      });
  };

  searchData = (key) => {
    this.searchKey = key;
    this.getAllIotAgentSummaryList();
  };

  goToAgentDevices(agentId, agentName) {
    this.router.navigate(['iot/agentdevices/', agentId, agentName]);
  }

  isShowCompanyName = () => {
    return this.Auth.accessLevel === 'SUPERADMIN';
  };
}
