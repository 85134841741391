import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-token-login-direct-access',
  templateUrl: './token-login-direct-access.component.html',
  styleUrls: ['./token-login-direct-access.component.scss'],
})
export class TokenLoginDirectAccessComponent implements OnInit {
  token: any;
  deviceId: number;
  deviceType: string;

  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.token = params['token'];
      this.deviceId = params['id'];
      this.deviceType = params['type'];
    });
  }

  ngOnInit() {
    this.login();
  }

  login() {
    localStorage.clear();
    sessionStorage.clear();
    this.Auth.loginDirectAccess(this.token, this.deviceType).subscribe((data) => {
      if (data.response.token) {
        localStorage.setItem('token', data.response.token);
        const token = localStorage.getItem('token');
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(token);
        const expirationDate = helper.getTokenExpirationDate(token);
        if (!helper.isTokenExpired(token)) {
          // redirect to Admin homepage
          const routeUrl = `/direct-access/${this.deviceType}/${this.deviceId}`;
          this.router.navigate([routeUrl]);
        } else {
          // console.log('You need to be logged in to access the page');
        }
      } else {
        // console.log('You need to be logged in to access the page');
        this.router.navigate(['/login']);
      }
    });
  }
}
