<div class="desktop-client">
  <div *ngIf="isDoneGettingCount" style="overflow: auto; height: 280px;">
    <table class="table table-stripped">
      <thead>
        <tr class="black">
          <th scope="col">Host name</th>
          <th scope="col">Assigned User</th>
          <th scope="col">MAC Address</th>
          <th scope="col">Host IP</th>
          <th scope="col">Cloud Service</th>
          <th scope="col">
            <div style="width: 100px;">
              Desktop Client Status
            </div>
          </th>
          <th scope="col">VIP Alerts</th>
          <th scope="col">Desktop Client</th>
          <th scope="col">Link</th>
          <!--   <th scope="col">Updates</th> -->
        </tr>
      </thead>
      <tbody>
        <tr scope="row" *ngFor="let item of desktopClientList | mainMenuFilter: searchKey:'desktopPlugin'">
          <td>
            {{ item.host_name | displayEmpty }}
          </td>

          <td>
            {{ getAssignedUser(item.assigned_user) }}
          </td>

          <td>
            {{ item.mac_address | displayEmpty }}
          </td>

          <td>
            {{ item.ip_address | displayEmpty }}
          </td>

          <td>
            <img src="{{ getPluginTypeImage(item.plugin_type) }}" alt="" />
          </td>

          <td>
            <div class="desktop-client__status {{ getDesktopStatus(item.plugin_status) }}"></div>
          </td>

          <td>
            <div>
              <label class="switch" [ngClass]="{ 'disable-checkbox': item.zoom_user_id === null }">
                <input #checkboxAlertsChangeVSMenu type="checkbox" (change)="alertsChange(checkboxAlertsChangeVSMenu.checked, item.zoom_user_id)"
                  [checked]="item.alerts" />
                <span class="slider round"> </span>
              </label>
            </div>
          </td>

          <td>
            <div>
              <label class="switch" [ngClass]="{ 'disable-checkbox': item.zoom_user_id === null }">
                <input #checkboxAlertsChangeVSMenuDesktop type="checkbox" (change)="desktopClientChange(checkboxAlertsChangeVSMenuDesktop.checked, item.zoom_user_id)"
                  [checked]="item.plugin_enabled" />
                <span class="slider round"> </span>
              </label>
            </div>
          </td>
          <td>
            <div class="desktop-client__link" [ngClass]="{ 'disable-checkbox': item.plugin_status == -1 }">
              <img *ngIf="item.service_type === null || !item.is_link_zoom" src="./assets/img/vs_img/zoom_link.png"
                (click)="openDesktopLinkModal(item, 'zoom', 'link')" alt="Link Zoom">
              <img *ngIf="item.is_link_zoom && item.service_type === 'zoom'" src="./assets/img/vs_img/zoom_unlink.png"
                (click)="openDesktopLinkModal(item, 'zoom', 'unlink')" alt="Unlink Zoom">
              <img *ngIf="item.service_type === null || !item.is_link_teams" src="./assets/img/vs_img/teams_link.png"
                (click)="openDesktopLinkModal(item, 'teams', 'link')" alt="Link Teams">
              <img *ngIf="item.is_link_teams && item.service_type === 'teams'"
                (click)="openDesktopLinkModal(item, 'teams', 'unlink')" src="./assets/img/vs_img/teams_unlink.png"
                alt="Unlink Teams">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="!isDoneGettingCount">
    <img src="{{ btnLoader }}" alt="" class="desktop-loader" />
  </div>
</div>