<div class="row">
  <div class="col-12">
    <h5>Live Stats</h5>
  </div>
  <div class="col-4 btn-col pl-0 pr-1">
    <app-btn-reg-stats
      [count]="videoSitesData?.impacted_devices"
      label="Impacted"
      (click)="setFilter('impacted')"
      [ngClass]="{ 'outlineBtn': activeFilter == 'impacted' }"
      color="gray"
    ></app-btn-reg-stats>
  </div>
  <div class="col-4 btn-col pl-0 pr-1">
    <app-btn-reg-stats
      [count]="videoSitesData?.on_call"
      label="On Call"
      (click)="setFilter('oncall')"
      [ngClass]="{ 'outlineBtn': activeFilter == 'oncall' }"
      color="gray"
    ></app-btn-reg-stats>
  </div>
  <div class="col-4 btn-col pl-0 pr-1">
    <app-btn-bitrate
      [count]="calMbps()"
      label="Live Mbps"
      color="gray"
      isbutton="not"
    ></app-btn-bitrate>
  </div>
</div>
<div class="row">
  <div class="col-12 pl-0 pr-1">
    <hr />
  </div>
</div>
<div class="row">
  <div class="col-12 pl-0 pr-1">
    <h5>Today</h5>
    <app-btn-long-meetings
      [isButton]="isButton"
      (click)="ds.sendPopupType(35, { meetings: videoSitesData?.meetings })"
      [count]="videoSitesData?.scheduled_meetings"
      color="gray"
    ></app-btn-long-meetings>
  </div>
</div>
<div class="row">
  <div class="col-12 pl-0 pr-1">
    <hr />
  </div>
</div>
<div class="row">
  <div class="col-12">
    <h5>Company Information</h5>
  </div>
  <div
    *ngIf="isVirtualSite != 1 && videoSitesData?.physical_sites_count > 1; else disableQosButton"
    class="col-4 pl-0 pr-1"
  >
    <app-btn-qos (click)="ds.sendPopupType(3, siteId)"></app-btn-qos>
  </div>
  <ng-template #disableQosButton>
    <div class="col-4 pl-0 pr-1">
      <app-btn-qos-disabled class="Disabled" label="Qos Not Avaliable"></app-btn-qos-disabled>
    </div>
  </ng-template>

  <div class="col-4 pl-0 pr-1">
    <app-btn-reg-stats
      [count]="videoSitesData?.infrastructure"
      label="Infrastructure"
      color="gray"
      isbutton="not"
    ></app-btn-reg-stats>
  </div>
  <div class="col-4 pl-0 pr-1">
    <app-btn-reg-stats
      [count]="videoSitesData?.total_devices"
      label="Devices"
      color="gray"
      isbutton="not"
    ></app-btn-reg-stats>
  </div>
</div>
