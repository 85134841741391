import * as Chart from 'chart.js'
import * as moment from 'moment-timezone'
import { Subscription } from 'rxjs'

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'

import { GetIotService } from '../../../services/get-iot.service'
import { PrintDataService } from '../../../services/print-data.service'
import { RefreshApiService } from '../../../services/refresh-api.service'

export interface historicPingStatusInterface {
  median: number;
  min: number;
  max: number;
  lost_packet_count: number;
  timestamp: string;
}
@Component({
  selector: 'app-chart-historic-ping-status',
  templateUrl: './chart-historic-ping-status.component.html',
  styleUrls: ['./chart-historic-ping-status.component.scss'],
})
export class ChartHistoricPingStatusComponent implements OnInit, OnChanges {
  @Input() deviceId: number;
  @Input() agentId: number;
  @Input() accountUrl: string;
  @Input() apiKey: string;
  @Input() paused: number;
  @Input() dateRange: number;
  @Input() viewType: string;
  @Input() popupState: any;
  dataSource: MatTableDataSource<historicPingStatusInterface>;
  
  displayedColumns: any[] = [
    'median',
    'min',
    'max',
    'lost_packet_count',
    'timestamp',
  ];
  
  historicPingStatusList: any = [];
  isDisplayChart = true;
  HOUR_RANGE = 1;
  DAY_RANGE = 2;
  WEEK_RANGE = 3;
  MEDIAN_VIEW = 'medianRtd';
  BEST_VIEW = 'bestRtd';
  WORST_VIEW = 'worstRtd';
  PACKET_VIEW = 'packetRtd';
  MEDIAN_TEXT = 'Median RTD';
  BEST_TEXT = 'Best RTD';
  WORST_TEXT = 'Worst RTD';
  PACKETLOSS_TEXT = 'Packetloss';
  isHideMedianView = true;
  isHideBestView = true;
  isHideWorstView = true;
  isHidePacketlossView = true;
  filterListner: Subscription;
  canvas: HTMLCanvasElement;
  canvasWidth: number = 1150;
  canvasHeight: number = 300;
  ctx: any;
  historicPingStatusChart: any;
  dateTo: String;
  dateFrom: String;

  constructor(
    private getIot: GetIotService,
    private refreshApiService: RefreshApiService,
    private printData: PrintDataService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.popupState.currentValue == 'open') {
      console.log('open')
      this.filterListner = this.refreshApiService
        .refreshAPIListen()
        .subscribe((m: any) => {
          if (m) {
            this.setDateRangeValues();
            this.getHistoricPingStatustData();
          }
        });
      this.setView(this.viewType);
      this.init();
    } else {
    
    console.log('here')
      this.filterListner.unsubscribe();
      if (this.isDisplayChart) {
        this.isHideMedianView = true;
        this.isHideBestView = true;
        this.isHideWorstView = true;
        this.isHidePacketlossView = true;
        setTimeout(() => {
          this.historicPingStatusChart.reset();
          this.historicPingStatusChart.destroy();
        }, 500);
      }
    }
  }

  initializeChart = () => {
    this.canvas = document.getElementById(
      'historic-ping-status'
    ) as HTMLCanvasElement;
    this.canvas.width = this.canvasWidth;
    this.canvas.height = this.canvasHeight;
    this.ctx = this.canvas.getContext('2d');
    const legendClick = Chart.defaults.global.legend.onClick;
    const chartOptions = {
      responsive: false,
      display: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0,
        },
      },
      legend: {
        onClick: (e, legendItem) => {
          const index = legendItem.datasetIndex;
          const ci = this.historicPingStatusChart;
          
          
          const meta = ci.getDatasetMeta(index);
          meta.hidden =
            meta.hidden === null ? !ci.data.datasets[index].hidden : null;
          this.setView(legendItem.text);
          ci.update();
        },
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, chart) {
            const d = new Date(tooltipItem[0].label);
            return d.toLocaleTimeString();
          },
          beforeLabel: function (tooltipItem, chart) {
            switch (tooltipItem.datasetIndex) {
              case 0:
                return this.MEDIAN_TEXT;
              case 1:
                return this.BEST_TEXT;
              case 2:
                return this.WORST_TEXT;
              case 3:
                return this.PACKETLOSS_TEXT;
            }
          },
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
            ticks: {
              callback: (value, index, values) => {
                if (this.dateRange === this.HOUR_RANGE) {
                  return moment(value).format('LT');
                }
                if (this.dateRange === this.WEEK_RANGE) {
                  return moment(value).format('LLL');
                }
                return moment(value).format('LLL');
              },
            },
          },
        ],
      },
    };

    const chartData = {
      datasets: [
        {
          label: this.MEDIAN_TEXT,
          data: [],
          backgroundColor: ['rgb(0, 128, 255, .5'],
          pointBackgroundColor: 'rgb(184, 58, 36, 0)',
          pointBorderColor: 'rgb(184, 58, 36, 0)',
          borderWidth: 0,
          stepped: true,
          hidden: this.isHideMedianView,
        },
        {
          label: this.BEST_TEXT,
          data: [],
          backgroundColor: ['rgb(3, 173, 20, .5'],
          pointBackgroundColor: 'rgb(184, 58, 36, 0)',
          pointBorderColor: 'rgb(184, 58, 36, 0)',
          borderWidth: 0,
          hidden: this.isHideBestView,
        },
        {
          label: this.WORST_TEXT,
          data: [],
          backgroundColor: ['rgb(188, 71, 3, .5'],
          pointBackgroundColor: 'rgb(184, 58, 36, 0)',
          pointBorderColor: 'rgb(184, 58, 36, 0)',
          borderWidth: 0,
          hidden: this.isHideWorstView,
        },
        {
          label: this.PACKETLOSS_TEXT,
          data: [],
          pointBackgroundColor: 'rgb(184, 58, 36, 0)',
          pointBorderColor: 'rgb(184, 58, 36, 0)',
          backgroundColor: 'rgb(255, 184, 0, .5)',
          borderWidth: 0,
          hidden: this.isHidePacketlossView,
        },
      ],
      // labels: this.getYaxis(),
    };

    const chartConfig = {
      type: 'line',
      data: chartData,
      options: chartOptions,
    };

    this.historicPingStatusChart = new Chart(this.ctx, chartConfig);
    
    console.log('this.historicPingStatusChart: ', this.historicPingStatusChart)
    
  };

  getHistoricPingStatustData = () => {
    if (this.paused === 0) {
      this.getIot
        .getDeviceRTDHistory(
          this.deviceId,
          this.agentId,
          this.accountUrl,
          this.apiKey,
          this.dateFrom,
          this.dateTo
        )
        .subscribe((data) => {
        
          this.historicPingStatusList = data;
          
          
          if (this.isDisplayChart) {
            // chart data
            this.setChartData(this.historicPingStatusList);
          } else {
            // list data
            this.dataSource = new MatTableDataSource(
              this.historicPingStatusList
            );
          }
        });
    }
  };

  setChartData = (rtdList) => {
    const medianList = [];
    const bestList = [];
    const worstList = [];
    const packetLostList = [];

    const timeLabels = [];
    for (let i = 0; i < rtdList.length; i++) {
      const { min, max, median, lost_packet_count, timestamp } = rtdList[i];
      medianList.unshift(parseFloat(median));
      bestList.unshift(parseFloat(min));
      worstList.unshift(parseFloat(max));
      packetLostList.unshift(parseFloat(lost_packet_count));
      timeLabels.unshift(timestamp);
    }

    this.historicPingStatusChart.reset();
    this.historicPingStatusChart.data.labels = timeLabels;
    this.historicPingStatusChart.data.datasets[0].data = medianList;
    this.historicPingStatusChart.data.datasets[1].data = bestList;
    this.historicPingStatusChart.data.datasets[2].data = worstList;
    this.historicPingStatusChart.data.datasets[3].data = packetLostList;
    if (rtdList.length === 0) {
      this.historicPingStatusChart.data.labels = this.getYaxis();
    }
    this.historicPingStatusChart.update({ duration: 1000 });
  };

  setDateRangeValues = () => {
    const dateNow = moment().utcOffset(0, false).format();
    switch (this.dateRange) {
      case this.HOUR_RANGE:
        this.dateTo = moment(dateNow)
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        this.dateFrom = moment(dateNow)
          .subtract(1, 'hours')
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        break;
      case this.DAY_RANGE:
        this.dateTo = moment(dateNow)
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        this.dateFrom = moment(dateNow)
          .subtract(1, 'days')
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        break;
      case this.WEEK_RANGE:
        this.dateTo = moment(dateNow)
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        this.dateFrom = moment(dateNow)
          .subtract(7, 'days')
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        break;
      default:
        this.dateRange = this.HOUR_RANGE;
        this.dateTo = moment(dateNow)
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        this.dateFrom = moment(dateNow)
          .subtract(1, 'hours')
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        break;
    }
  };

  switchDateRange = (range) => {
    this.dateRange = range;
    this.init();
  };

  switchDisplayType = () => {
    this.isDisplayChart = !this.isDisplayChart;
    this.init();
  };

  init = () => {
    this.setDateRangeValues();
    if (this.isDisplayChart) {
      this.initializeChart();
    } else {
      this.historicPingStatusChart.destroy();
    }

    this.getHistoricPingStatustData();
  };

  print = () => {
    if (this.isDisplayChart) {
      this.printData.printCanvas(
        'historic-ping-status',
        'Historic Ping Status'
      );
    } else {
      this.printData.printToPdf(
        'historic-ping-status-list',
        'Historic Ping Status'
      );
    }
  };

  setView = (viewType) => {
    switch (viewType) {
      case this.MEDIAN_VIEW:
        this.isHideMedianView = false;
        break;
      case this.WORST_VIEW:
        this.isHideWorstView = false;
        break;
      case this.BEST_VIEW:
        this.isHideBestView = false;
        break;
      case this.PACKET_VIEW:
        this.isHidePacketlossView = false;
      case this.MEDIAN_TEXT:
        this.isHideMedianView = !this.isHideMedianView;
        break;
      case this.WORST_TEXT:
        this.isHideWorstView = !this.isHideWorstView;
        break;
      case this.BEST_TEXT:
        this.isHideBestView = !this.isHideBestView;
        break;
      case this.PACKETLOSS_TEXT:
        this.isHidePacketlossView = !this.isHidePacketlossView;
        break;
    }
  };

  getYaxis() {
    if (this.dateRange === this.HOUR_RANGE) {
      return this.getLast60MinYaxis();
    }

    if (this.dateRange === this.DAY_RANGE) {
      return this.getLast24HoursYaxis();
    }

    if (this.dateRange === this.WEEK_RANGE) {
      return this.getLast7DaysYaxis();
    }
  }

  getLast60MinYaxis = () => {
    const timeSeries = [];
    const dateNow = moment().utcOffset(0, true).format();
    timeSeries.unshift(moment(dateNow).format().split('Z')[0]);
    for (let i = 1; i <= 20; i++) {
      timeSeries.unshift(
        moment(dateNow)
          .subtract(i * 3, 'minutes')
          .format()
          .split('+')[0]
      );
    }

    return timeSeries;
  };

  getLast24HoursYaxis = () => {
    const timeSeries = [];
    const dateNow = moment().utcOffset(0, true).format();
    timeSeries.unshift(moment(dateNow).format().split('Z')[0]);
    for (let i = 1; i <= 24; i++) {
      timeSeries.unshift(
        moment(dateNow).subtract(i, 'hours').format().split('+')[0]
      );
    }

    return timeSeries;
  };

  getLast7DaysYaxis = () => {
    const timeSeries = [];
    const dateNow = moment().utcOffset(0, true).format();
    timeSeries.unshift(moment(dateNow).format().split('Z')[0]);
    for (let i = 1; i <= 14; i++) {
      timeSeries.unshift(
        moment(dateNow)
          .subtract(i * 12, 'hours')
          .format()
          .split('+')[0]
      );
    }

    return timeSeries;
  };
}
