<div class="row">
  <div class="col-6">
    <table class="a" style="width:100%;">
      <!-- <tr>
        <th>Participants</th>
        <th>Device</th>
      </tr> -->
      <tr>
        <td><img src="../../../assets/img/cloud/icon_screen_phone.png" /></td>
        <td
          [ngStyle]="{
            color:
              computerCheck(device) == 'Not Detected' ||
              computerCheck(device) == 'Offline'
                ? 'red'
                : 'black'
          }"
        >
          {{ computerCheck(device) }}
        </td>
      </tr>
      <tr>
        <td><img src="../../../assets/img/cloud/icon_camera.png" /></td>
        <td
          [ngStyle]="{
            color: deviceCheck(camera) == 'Not Detected' ? 'red' : 'black'
          }"
        >
          {{ deviceCheck(camera) }}
        </td>
      </tr>
      <tr>
        <td><img src="../../../assets/img/cloud/icon_microphone.png" /></td>
        <td
          [ngStyle]="{
            color: deviceCheck(microphone) == 'Not Detected' ? 'red' : 'black'
          }"
        >
          {{ deviceCheck(microphone) }}
        </td>
      </tr>
      <tr>
        <td><img src="../../../assets/img/cloud/icon_volume.png" /></td>
        <td
          [ngStyle]="{
            color: deviceCheck(speaker) == 'Not Detected' ? 'red' : 'black'
          }"
        >
          {{ deviceCheck(speaker) }}
        </td>
      </tr>
    </table>
  </div>
  <div class="col-6 vl">
    <table class="a" style="width:100%;">
      <!-- <tr>
          <th>Participants</th>
          <th>Device</th>
        </tr> -->
      <tr *ngIf="!ipAddress">
        <td><strong>IP:</strong></td>
        <td>
          &nbsp;&nbsp;
          <span class="px-2 text-uppercase connect-type-text">--</span>
          &nbsp;&nbsp;
        </td>
      </tr>
      <tr *ngIf="ipAddress">
        <td colspan="2">
          <strong style="vertical-align: top">IP:</strong>
          <span
            class="pl-2"
            style="font-size: 13px; width:150px; word-wrap:break-word; display:inline-block;"
            >&nbsp;&nbsp;{{ findIpDetails(ipAddress, "Computer") }}</span
          >
        </td>
      </tr>
      <tr>
        <ng-container *ngIf="connectionType">
          <td><strong>Connection Type:</strong></td>
          <td>
            <span
              class="px-2 text-uppercase bg-success text-light connect-type-text"
              style="word-break: break-all"
              >{{ nullCheck(connectionType) }}</span
            >
          </td>
        </ng-container>
        <ng-container *ngIf="!connectionType">
          <td><strong>Connection Type:</strong></td>
          <td>
            &nbsp;&nbsp;
            <span class="px-2 text-uppercase connect-type-text">--</span>
            &nbsp;&nbsp;
          </td>
        </ng-container>
      </tr>
      <tr>
        <td><strong>Network Type:</strong></td>
        <td>
          &nbsp;&nbsp;
          <span
            class="px-2 text-uppercase connect-type-text"
            *ngIf="!networkType"
            >--</span
          >
          <img
            *ngIf="networkType && networkType !== 'Wifi'"
            src="../../../assets/img/icon_ethernet_02.png"
          />
          <img
            *ngIf="networkType && networkType == 'Wifi'"
            src="../../../assets/img/icon_wifi.png"
          />
          &nbsp;&nbsp;
        </td>
      </tr>
    </table>
  </div>
</div>
