import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  loginEnv: any;
  showPoweredBy = environment.config.showPoweredBy;
  links = environment.config.showPoweredBy;
  backgroundImage = environment.config.dynamicImage.bgImage;
  dashboardLogo = environment.config.dynamicImage.dashboardLogo;

  visibilityBackground = {
    'background-image': 'url(' + this.backgroundImage + ')',
    'background-repeat': 'no-repeat',
    'background-size': 'cover',
    height: '100vh',
  };

  loginLink: string;
  id: string;
  resetEmail: any = {
    value: '',
    success: false,
    flag: false,
  };
  resetSent: boolean = false;

  constructor(
    private Auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private fb: FormBuilder
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }


  ngOnInit() {
    this.getResetInfo();
    this.loginLink = this.Auth.isAuthenticated() ? '/logout' : '/login';
    this.setData();

  }

  setData = () => {
    if (environment.config.title === 'VisibilityOne'
      || environment.config.title === 'vCare'
      || environment.config.title === 'Vision Point'
      || environment.config.title === 'Videlio'
      || environment.config.title === 'DEKOM') {
      this.loginEnv = true
    } else {
      this.loginEnv = false;
    }
  }

  getResetInfo = () => {
    this.Auth.getResetCodeInfo(this.id).subscribe((data: any) => {
      const [response] = data.response;
      if (response.status === 'Error') {
        this.resetEmail = {
          value: response.message,
          success: false,
          flag: false,
        };
      } else {
        this.resetEmail = {
          value: response.email,

          success: false,

          flag: true,
        };
      }
    });
  };

  resetPassword = (password) => {
    // if (this.getInfoData.response[0].status === 'Ok') {
    const resetData = {
      password: password,
      code: this.id,
      email: this.resetEmail.value,
    };

    this.Auth.changePassword(resetData).subscribe((data) => {
      // console.log(data);
      if (data['response'][0].data.status.toLowerCase() === 'ok') {
        this.resetEmail = {
          value: '',
          success: true,
          flag: false,
        };
      } else if (data['response'][0].data.status.toLowerCase() === 'failure') {
        this.resetEmail = {
          value: '',
          success: false,
          flag: false,
        };
      }
    });
  };

  resetEmitEvent = ({ password }) => {
    this.resetPassword(password);
  };
}
