<div class="polycom-add-device">
    <div class="polycom-add-device__left-column">


        <!-- <div class="polycom-add-device__switch-toggle">
            <div class="polycom-add-device__switch">
                <div>Video</div>
                <label class="switch">
                    <input type="checkbox" [checked]="deviceFlag" (click)="toggleSwitch($event)" />
                    <span class="slider round"></span>
                </label>
                <div>Audio</div>
            </div>
        </div> -->

        <div class="polycom-add-device-select">
            <select [(ngModel)]="siteId" id="index" class="form-control polycom-add-device__select-input-style"
                [ngClass]="{'polycom-add-device__select-input-style--disable-select': disableSelectAndSubmit ,
                    'polycom-add-device__disable': !logitechMonitor  }"
                (change)="siteSelectorEvent($event)" required>
                <option value="" selected disabled>Select a site</option>
                <option *ngFor="let item of siteList; index as n" value="{{item.site_id}}">
                    {{ item.site_name }}</option>
            </select>
        </div>

        <div *ngIf="showSiteListErrMsg" class="polycom-add-device__error-message">
            Error: No Online Collectors
        </div>

        <div *ngIf="disableSelectAndSubmit" class="polycom-add-device__error-message">
            Please use collector version {{ collectorLatestVersion}} or >
        </div>

        <div class="polycom-add-device__container">
            <input [ngClass]="{'polycom-add-device__select-input-style--disable-input': deviceRoomNameEmpty ,
                    'polycom-add-device__disable': !logitechMonitor }"
                class="polycom-add-device__input form-control" [(ngModel)]="deviceRoomName" type="text"
                placeholder="Room Name" />
        </div>

        <div class="polycom-add-device__container">
            <input [ngClass]="{'polycom-add-device__select-input-style--disable-input': deviceIpEmpty ,
                    'polycom-add-device__disable': !logitechMonitor }"
                class="polycom-add-device__input form-control" [(ngModel)]="deviceIp" type="text"
                placeholder="Device IP" />
        </div>

        <!-- <div *ngIf="deviceFlag" class="polycom-add-device__container">
            <input [ngClass]="{'polycom-add-device__disable': disableAllFields}"
                class="polycom-add-device__input form-control" [(ngModel)]="iotName" type="text"
                placeholder="VISUAL+ Name ( Optional )" />

            <div *ngIf="disableAllFields" class="polycom-add-device__error-message">
                Please enable IoT
            </div>
        </div> -->
        <!-- 
        <div *ngIf="deviceFlag" class="polycom-add-device__container">
            <input [ngClass]="{'polycom-add-device__disable': disableAllFields}"
                class="polycom-add-device__input form-control" [(ngModel)]="iotIp" type="text"
                placeholder="VISUAL+ ( Optional )" />

            <div *ngIf="disableAllFields" class="polycom-add-device__error-message">
                Please enable IoT
            </div>
        </div> -->

        <div *ngIf="!deviceFlag" class="polycom-add-device__container">
            <input [ngClass]="{'polycom-add-device__select-input-style--disable-input': deviceAdminEmpty,
                    'polycom-add-device__disable': !logitechMonitor }"
                class="polycom-add-device__input form-control" [(ngModel)]="deviceName" type="text"
                placeholder="Admin Name" />
        </div>

        <div class="polycom-add-device__container">
            <input [ngClass]="{'polycom-add-device__select-input-style--disable-input': devicePasswordEmpty ,
                    'polycom-add-device__disable': !logitechMonitor  }"
                class="polycom-add-device__input form-control" [(ngModel)]="devicePassword" type="password"
                placeholder="Device Password" />
        </div>

        <div class="polycom-add-device__btn" [ngClass]="{'polycom-add-device__disable': disableSelectAndSubmit || !logitechMonitor}" (click)="openPolycomAddDeviceDialog()">
            <button >Discover Device</button>
        </div>
    </div>

</div>