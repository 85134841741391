<div class="cloud-all-user-list">
  <!-- <input #searchDataInput (keyup)="searchEvent(searchDataInput.value)" placeholder="Search..." class="input-class" [ngClass]="{ 'cloud-all-user-list__button--disabled': userTotalCount == null
  }" [disabled]="userTotalCount == null
  " /> -->
  <input [(ngModel)]="userSearch" placeholder="Search..." class="input-class" name="userSearch" id="userSearchs"
    [ngClass]="{ 'cloud-all-user-list__button--disabled': userTotalCount == null}" [disabled]="userTotalCount == null"
    (ngModelChange)="this.userSearchUpdate.next($event)" />


  <div id="cloud-all-user-list" #cloudUsersScrollElement class="mat-elevation-z8 child">
    <table class="data-table" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row" title="{{row.email? row.email: ''}}">{{ row.name }}</td>
      </ng-container>

      <!-- <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let row">{{ row.email }}</td>
      </ng-container> -->

      <ng-container matColumnDef="health">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Health</th>
        <td mat-cell *matCellDef="let row">
          <app-table-health-icon [value]="row.health" [serviceType]="row.service"> </app-table-health-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="qos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Qos</th>
        <td mat-cell *matCellDef="let row">
          <app-table-qos-icon [value]="row.qos"></app-table-qos-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="livembps">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Live Mbps</th>
        <td mat-cell *matCellDef="let row">
          {{ row.livembps }}
        </td>
      </ng-container>

      <ng-container matColumnDef="oncall">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>On Call</th>
        <td mat-cell *matCellDef="let row">
          <app-table-zoom-on-call [value]="row.oncall"></app-table-zoom-on-call>
        </td>
      </ng-container>

      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
        <td mat-cell *matCellDef="let row">
          {{ row.duration }}
        </td>
      </ng-container>

      <ng-container matColumnDef="calendar">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Calendar</th>
        <td mat-cell *matCellDef="let row">
          {{ row.calendar }}
        </td>
      </ng-container>

      <ng-container matColumnDef="service">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Service</th>
        <td mat-cell *matCellDef="let row">
          <!-- {{ row.service }}   -->
          <div class="service-image">
            <img src="{{ getServiceImage(row.service) }}" alt="" />
          </div>
        </td>
      </ng-container>

      <ng-container *ngIf="Auth.accessLevel === 'SUPERADMIN'" matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
        <td mat-cell *matCellDef="let row">
          {{ row.company }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="clickRow(row)"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [colSpan]="displayedColumns.length">No data found in table</td>
      </tr>
    </table>
  </div>
  <!-- remove? -->
  <!-- <div class="paginator-cnt" *ngIf="totalCount > totalPageLimit">
    <div class="paginator-child">
      <div>
        {{ paginateOjb.startIndex + 1 }} - {{ paginateOjb.endIndex + 1 }} of {{ totalCount }}
      </div>
      <div class="button-cnt">
        <div (click)="prev()" [ngClass]="{ 'disable-button': paginateOjb.currentPage === 1 }">
          <img src="../../../../assets/img/prev_arrow.png" alt="" />
        </div>
        <div (click)="next()" [ngClass]="{ 'disable-button': paginateOjb.currentPage === paginateOjb.totalPages }">
          <img src="../../../../assets/img/next_arrow.png" alt="" />
        </div>
      </div>
    </div>
  </div> -->


  <div *ngIf="userTotalCount > 500" class="cloud-all-user-list__separator">
    <!-- <button [disabled]="showLoader" [ngClass]="{ 'cloud-all-user-list__button--disabled':showLoader }"
      (click)="loadMoreUsers()" class="cloud-all-user-list__button--text">
      Load More
      <img *ngIf="showLoader" [src]="loader" />
    </button> -->

    <!-- <div class="cloud-all-user-list__total-page">User Count: {{ showLoader ? '--' : searchkey === '' ? totalPageLimit :
      totalSearchCount }}</div> -->


    <div class="cloud-all-user-list__total-page">User Count:
      <span class="cloud-all-user-list__loader-img" *ngIf="showLoader">
        <img *ngIf="showLoader" [src]="loader" />
      </span>
      <span *ngIf="!showLoader">
        {{ searchkey === '' ? totalUserCount :
        totalSearchCount }}
      </span>
    </div>
  </div>
</div>