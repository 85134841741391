<div class="new-update-ch-2">
	<div class="tbl-ctn">
		<table class="table table-hover table-sm bg-white">
			<thead>
				<tr>
					<th>{{ dCollector }} Version</th>
					<th>Release Date</th>
					<th>Size</th>
					<th>Release Notes</th>
					<th>Scheduled Date & Time</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						{{ collectorVersionData?.version | displayEmpty }}
					</td>
					<td>
						{{ collectorVersionData?.stamp | displayEmpty }}
					</td>
					<td>
						{{ collectorVersionData?.file_size | displayEmpty }}
					</td>
					<td>
						{{ collectorVersionData?.release_notes | displayEmpty }}
					</td>
					<td>
						{{ collectorVersionData?.update_schedule | dateTimeZone }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>

	<div class="schedule-ctn">
		<div class="schedule-ctn-1">
			<h4>Reschedule Date</h4>
			<input type="datetime-local" class="form-control" id="schedule-date" [(ngModel)]="dateVal" />
			<div *ngIf="showDateErrMsg" class="{{ dateMsgStatus }}-message">{{ dateMsg }}</div>
		</div>
		<div class="schedule-ctn-2">
			<h4>Reschedule Time</h4>
			<div class="p-ctn">
				<p>Time zone is defined by</p>
				<p>the local time on the device</p>
				<p>that the collector is install on</p>
			</div>

			<div class="btn-ctn" (click)="updateCollectorSchedule()" [ngClass]="{ disable: scheduleButtonDisabled }">
				Schedule
			</div>
		</div>
	</div>
</div>
