import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-btn-api',
  templateUrl: './btn-api.component.html',
  styleUrls: ['./btn-api.component.scss']
})
export class BtnApiComponent implements OnInit {
  @Input() count: number;
  @Input() label: string = "API";
  @Input() status: string = "Status";
  @Input() color: string;
  @Input() backcolorbtn: any;
  @Input() isbutton: string;
  prevcount: string;

  constructor() { }

  ngOnInit() {
  }

}
