import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VideoHelperService {
  private temperatureGoodImagePath = '../../../../assets/img/teleportivity_img/tempgood.png';
  private temperatureWarningImagePath = '../../../../assets/img/teleportivity_img/tempwarning.png';
  private temperatureBadImagePath = '../../../../assets/img/teleportivity_img/tempalert.png';
  private temperatureNoneImagePath = '../../../../assets/img/teleportivity_img/tempblack.png';
  constructor() {}

  setTemperatureValue = (temperatureValue, temperatureTriggers) => {
    const { alert, warning } = temperatureTriggers;
    let temperature = temperatureValue;
    let temperatureImage = '';
    let temeratureMsg = '--';

    if (temperatureValue === null) {
      temperature = '--';
      temperatureImage = this.temperatureNoneImagePath;
      temeratureMsg = '--';
    } else if (temperatureValue <= 54) {
      temperatureImage = this.temperatureGoodImagePath;
      temeratureMsg = 'NORMAL';
    } else if (temperatureValue >= 55 && temperatureValue <= 64) {
      temperatureImage = this.temperatureWarningImagePath;
      temeratureMsg = 'WARNING';
    } else if (temperatureValue >= 65) {
      temperatureImage = this.temperatureBadImagePath;
      temeratureMsg = 'HIGH';
    } else {
      temperatureImage = this.temperatureNoneImagePath;
      temeratureMsg = '--';
    }

    return {
      temperature,
      temperatureImage,
      temeratureMsg,
    };
  };

  getIsDisplayTemperature = (deviceType) => {
    let isDisplay = false;
    switch (deviceType) {
      case 'cisco':
        isDisplay = true;
        break;
      case 'hdx':
        isDisplay = true;
        break;
      default:
        isDisplay = false;
        break;
    }
    return isDisplay;
  };
}
