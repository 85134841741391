<div class="container-fluid" style="padding-top:60px;padding-bottom: 200px;padding-left:10%;">
  <div class="row upper-row">
    <div class="col-12">
      <div class="row">
        <div class="col-3 pl-5">
          <img src="../../assets/img/slack_logo.png@1X.png" alt="zoom logo" style="width:120px;" />
        </div>
        <!-- <div class="col-2">
                <div class="retract">Retract</div>
                <div class="consent">API</div>
                <div class="enabled" style="position: relative;top:-18px;left:67px;">Consent</div>
              <label class="switch" style="position: relative;top: -45px;">
              <input type="checkbox" checked>
              <span class="slider round"></span>           
              </label>
              <div class="paused">paused</div>
              <div class="enabled">enabled</div>
            </div>
            <div class="col-2 col-offset-5">
              <div class="status-green text-center">Status</div>
            </div> -->

      </div>
    </div>
  </div>
  <div *ngIf="isADMIN" class="row lower-row ">
    <div class="col-12 d-flex justify-content-center center">
      <ng-container *ngIf="!slackAvailable">
        <a>
          <img
            (click)="openNewWindow('https://slack.com/oauth/authorize?client_id=' + clientID + '&scope=incoming-webhook,team:read,channels:history,groups:history,im:history,mpim:history&redirect_uri=' + dbserver + '/api/v1/slackapp/install?cid=' + companyId)"
            alt="Add to Slack" height="80" width="278" src="https://platform.slack-edge.com/img/add_to_slack@2x.png" />
        </a>
      </ng-container>
      <!-- <form role="form" class="form-inline" >
          <div class="form-group">
            <label for="exampleInputEmail1">
            </label>
            <input type="password" value="************"  class="form-control" id="exampleInputEmail1" />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">
            </label>
            <input type="password" value="************"  class="form-control" id="exampleInputPassword1" />
          </div>
          <button type="button" class="btn btn-primary btn1" style="width:90px important;">
            Save
          </button>
          <button type="button" class="btn btn-primary btn2" style="width:90px important;">
            Manage
          </button>
          <button type="button" class="fa fa-question"></button>
        </form> -->
    </div>
  </div>
  <div *ngIf="!isADMIN" class="row lower-row justify-content-center">
    <p style="text-align: center; font-size: 20px; color: red!important">You do not access rights to this feature.</p>
  </div>
  <div class="row upper-row">
    <div class="col-12">
      <div class="row justify-content-center mt-2">
        <ng-container *ngIf='slackAvailable'>
          <div class="col-4 d-flex justify-content-center">
            <button (click)="disableNotifications()" class="btn btn-default float-right">Disable</button>
          </div>
        </ng-container>
        <!-- <div class="col-3 pl-5">
                <img src="../../assets/img/zoom_logo.png@1X.png" alt="zoom"/>
            </div> -->
        <!-- <div class="api-btn">API</div>
            <div class="col-2">
              <label class="switch">
              <input type="checkbox" checked>
              <span class="slider round"></span>           
              </label>
              <div class="retract">Retract</div>
              <div class="consent">Consent</div>
            </div> -->
        <!-- <div class="col-2 col-offset-5">
              <div class="status-green text-center">Status</div>
            </div> -->
      </div>
    </div>
  </div>
  <!-- <div class="row lower-row ">
      <div class="col-12 center">
          <div class="row btm-row ">- GDPH Compliance -</div>
        <form role="form" class="form-inline" >
          <button type="button" class="btn btn-primary">
            Delete User Data
          </button>
          <button type="button" class="btn btn-primary">
            Download User Data
          </button>
          <a href="" alt="privacy policy" class="policy">Privacy Policy</a>
        </form>
      </div>
    </div> -->
  <!-- <div class="row btm-row ">GDPH Compliance</div> -->
</div>