import { BehaviorSubject, Subject } from 'rxjs'
import { Observable } from 'rxjs/Observable'
// import jwt from 'jsonwebtoken';
import { map } from 'rxjs/operators'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../environments/environment'
import { TableCogIconComponent } from '../components/table-icons/table-cog-icon/table-cog-icon.component'
import { TableHealthIconComponent } from '../components/table-icons/table-icon/table-health-icon.component'
import { TableOnCallIconComponent } from '../components/table-icons/table-oncall-icon/table-oncall-icon.component'
import { TableQosIconComponent } from '../components/table-icons/table-qos-icon/table-qos-icon.component'
import { TableSiteTypeIconComponent } from '../components/table-icons/table-sitetype-icon/table-sitetype-icon.component'
import { IntegratorService } from '../services/integrator.service'

@Injectable({
  providedIn: 'root',
})
export class GetVideoService {
  constructor(
    private http: HttpClient,
    private integrator: IntegratorService
  ) {}
  public showFullNumbers: Subject<Boolean> = new BehaviorSubject(false);

  //server global variable;
  server_url: string = environment.serverUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
  };

  httpOptions2 = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
  };

  private handleError(error: Response | any) {
    return Observable.throw(error);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private extractVideoDeviceDetails(res: any) {
    const body = res;

    if (body.response[0]) {
      try {
        //something wrong with the password this is a temporary fix
        const helper = new JwtHelperService();
        body.response[0].password = helper.decodeToken(
          body.response[0].password
        );
      } catch (error) {
        // // console.log(error);
      }
    }
    return body || {};
  }
  //API Calls
  ngOnInit() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
  }

  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    return decodedToken.company_id;
  };

  // GET Request for Video Summary
  getVideoDeviceSummary(company_id: any): Observable<Object> {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    if (this.integrator.isIntegrator()) {
      company_id.company_id = company_id.company_id * -1;
    }
    return this.http
      .post<any>(
        this.server_url + '/api/v1/video/getSummary',
        company_id,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //GET Request for Company Info
  apiGetVideoCompanyInfo(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: companyId,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/company/getCompanyInfo',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //GET Request for Company Info for Specific Site
  apiGetVideoCompanySiteInfo(siteId): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    let postData = {
      company_id: companyId,
      site_id: siteId,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/company/getSiteInfo',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //GET Request for All Video Site Stats Summary
  apiGetVideoSitesSummary(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/video/getAllSiteSummary',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //GET Request for All Video Site Detailed List
  apiGetAllVideoSites(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/video/getAllSites',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //GET Request for Video Device Stats Summary by Site
  apiGetVideoSiteDeviceSummary(siteId): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
      site_id: siteId,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/video/getDeviceSummaryBySite',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //GET Request for Video Device Detailed Device List by Site
  apiGetVideoSiteDevicebySite(siteId): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
      site_id: siteId,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/video/getDevicesBySite',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //GET Request for All Video Device Detailed List
  apiGetAllVideoDevices(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/video/getAllDevices',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //GET Request for All Video Device Stats Summary
  apiGetVideoDeviceSummary(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/video/getDeviceSummary',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //GET Request for Video Device Detailed Stats Summary
  apiGetVideoDeviceDetails(deviceId: any): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
      device_id: deviceId,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/video/getDeviceDetails',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractVideoDeviceDetails));
  }

  //This Pauses a specific Video Device
  pauseVideoDevice = (device_id, flag): Observable<Object> => {
    const params = {
      device_id,
      company_id: this.getCompanyId(),
      flag,
    };

    const endpoint = flag ? 'pauseDevice' : 'unpauseDevice';

    return this.http
      .post<any>(
        this.server_url + `/api/v1/video/${endpoint}`,
        params,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  };

  //This Route Wakes Ups a specific Video Device
  wakeupVideoDevice(deviceId: any): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
      device_id: deviceId,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/video/wakeDevice',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //This Route Reboots a specific Video Device
  rebootVideoDevice(deviceId: any): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
      device_id: deviceId,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/video/rebootDevice',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  getQOSSiteList(siteId: any) {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
      site_id: siteId,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/video/getQOSSiteList',
      postData,
      this.httpOptions
    );
    // .map(this.extractData)
    // .catch(this.handleError);
  }
  pauseQosSite(siteId: any) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
      site_id: siteId,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/video/pauseSiteOos',
      postData,
      this.httpOptions
    );
  }
  unPauseQosSite(siteId: any) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
      site_id: siteId,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/video/unpauseSiteOos',
      postData,
      this.httpOptions
    );
  }
  getQOSSiteActionId(siteId: any, remoteSiteId: any, params: any) {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
      site_id: parseInt(siteId),
      remote_site_id: parseInt(remoteSiteId),
      params: params,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/video/siteQOS',
      postData,
      this.httpOptions
    );
    // .map(this.extractData)
    // .catch(this.handleError);
  }

  //This Route Rescans a for Video Device
  rescanDeviceData = (device_id: any) => {
    const params = {
      company_id: this.getCompanyId(),
      device_id,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/video/syncDevice',
      params,
      this.httpOptions
    );
    // .catch(this.handleError);
  };

  // Load the JSON file for the Site Panel
  fetchVideoDevices(): Observable<Object> {
    return this.http.get('/assets/db/video/video_devices.json');
  }

  fetchVideoSites(): Observable<Object> {
    return this.http.get('/assets/db/video/sites.json');
  }

  fetchVideoSummary(): Observable<Object> {
    return this.http.get('/assets/db/video/company.json');
  }

  updateAlertTriggers(postData): Observable<Object> {
    return this.http.post<any>(
      this.server_url + '/api/v1/video/updateTriggers',
      postData,
      this.httpOptions
    );
  }

  resetAlertTriggers(postData): Observable<Object> {
    return this.http.post<any>(
      this.server_url + '/api/v1/video/resetTriggers',
      postData,
      this.httpOptions
    );
  }

  noData = (cellData) => {
    if (!cellData) {
      return '--';
    }
    return cellData;
  };

  getDeviceLoginActionId(user: any, userPassword: any, deviceId: any) {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    let postData = {
      company_id: companyId,
      username: user,
      password: userPassword,
      device_id: deviceId,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/video/deviceLoginTest',
      postData,
      this.httpOptions
    );
    // .map(this.extractData)
    // .catch(this.handleError);
  }

  // This route gets the action id for updateing device login
  updateDeviceLogin(actionId: any) {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: companyId,
      action_id: actionId,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/video/updateDeviceLogin',
      postData,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  muteAndUnmuteButton(actionId: any, videoId: any) {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: companyId,
      device_id: videoId,
      isMuted: actionId,
    };
    // console.log('postData: ', postData);
    return this.http.post<any>(
      this.server_url + '/api/v1/video/mute',
      postData,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  videoCreateAction(label, action_name, user_id, params) {
    //Grabs Token from Local Storage

    let postData = {
      label,
      action_name,
      user_id,
      params,
    };

    // console.log(postData)

    return this.http
      .post<any>(
        this.server_url + '/api/v1/video/createAction',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  updateActionStatus(postData) {
    //Grabs Token from Local Storage
    return this.http.post<any>(
      this.server_url + '/api/v1/video/updateActionStatus',
      postData,
      this.httpOptions
    );
  }

  // data : {
  //   "label": "Update firmware",
  //   "action_name": "update-firmware",
  //   "user_id": 793,
  //   "params": {
  //     "ip"
  //   }
  // }

  // Table Settings for Filter Table

  // Video Sites List Settings
  videoSitesTableSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: false,
    },
    columns: {
      virtual: {
        title: '',
        type: 'custom',
        renderComponent: TableSiteTypeIconComponent,
      },
      site_name: {
        title: 'Site',
        class: 'filter-test',
      },
      health: {
        title: 'Health',
        type: 'custom',
        renderComponent: TableHealthIconComponent,
      },
      impacted: {
        title: 'Impacted',
      },
      infrastructure: {
        title: 'Infrast.',
      },
      devices: {
        title: 'Devices',
      },
      oncall: {
        title: 'On Call',
        type: 'custom',
        renderComponent: TableOnCallIconComponent,
      },
      scheduled_meetings: {
        title: 'Scheduled Meetings',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
      bitrate: {
        title: 'Live Mbps',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          var roundnum = instance / (1024 * 1024);
          return Math.round(roundnum * 100) / 100;
        },
      },
    },
  };

  videoSitesTableSettingsSuperAdmin = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: true,
      perPage: 50,
    },
    columns: {
      virtual: {
        title: '',
        type: 'custom',
        renderComponent: TableSiteTypeIconComponent,
      },
      company_name: {
        title: 'Company',
        class: 'filter-test',
      },
      site_name: {
        title: 'Site',
        class: 'filter-test',
      },

      health: {
        title: 'Health',
        type: 'custom',
        renderComponent: TableHealthIconComponent,
      },
      impacted: {
        title: 'Impacted',
      },
      infrastructure: {
        title: 'Infrast.',
      },
      devices: {
        title: 'Devices',
      },
      oncall: {
        title: 'On Call',
        type: 'custom',
        renderComponent: TableOnCallIconComponent,
      },
      scheduled_meetings: {
        title: 'Scheduled Meetings',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
      bitrate: {
        title: 'Live Mbps',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          var roundnum = instance / (1024 * 1024);
          return Math.round(roundnum * 100) / 100;
        },
      },
    },
  };

  // Video Sites List Settings
  videoSitesRoomsTableSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: false,
    },
    columns: {
      site_name: {
        title: 'Name',
        class: 'filter-test',
      },
      health: {
        title: 'Health',
        type: 'custom',
        renderComponent: TableHealthIconComponent,
      },
      impacted: {
        title: 'Impacted',
      },
      infrastructure: {
        title: 'Infrastructure ',
      },
      devices: {
        title: 'Devices',
      },
      oncall: {
        title: 'On Call',
        type: 'custom',
        renderComponent: TableOnCallIconComponent,
      },
      scheduled_meetings: {
        title: 'Scheduled Meetings',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
      bitrate: {
        title: 'Live Mbps',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
    },
  };

  // Video Devices List Settings
  videoDeviceTableSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: false,
    },
    columns: {
      room_name: {
        title: 'Name',
        class: 'filter-test',
      },
      health: {
        title: 'Health',
        type: 'custom',
        renderComponent: TableHealthIconComponent,
      },
      qos: {
        title: 'Qos',
        type: 'custom',
        renderComponent: TableQosIconComponent,
      },
      site_name: {
        title: 'Site',
      },
      total_mbps: {
        title: 'Live Mbps',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          var roundnum = instance / (1024 * 1024);
          return Math.round(roundnum * 100) / 100;
        },
      },
      oncall: {
        title: 'On Call',
        type: 'custom',
        renderComponent: TableOnCallIconComponent,
      },
      scheduled_meetings: {
        title: 'Calender',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance + ' meeting(s)';
        },
      },
      model: {
        title: ' Model',
      },
      ipv4: {
        title: 'IP Address',
        type: 'custom',
        renderComponent: TableCogIconComponent,
      },
    },
  };
  videoDeviceTableSettingsSuperAdmin = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: true,
      perPage: 50,
    },
    columns: {
      company_name: {
        title: 'Company',
        class: 'filter-test',
      },
      room_name: {
        title: 'Name',
        class: 'filter-test',
      },

      health: {
        title: 'Health',
        type: 'custom',
        renderComponent: TableHealthIconComponent,
      },
      qos: {
        title: 'Qos',
        type: 'custom',
        renderComponent: TableQosIconComponent,
      },

      total_mbps: {
        title: 'Live Mbps',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          var roundnum = instance / (1024 * 1024);
          return Math.round(roundnum * 100) / 100;
        },
      },
      oncall: {
        title: 'On Call',
        type: 'custom',
        renderComponent: TableOnCallIconComponent,
      },
      scheduled_meetings: {
        title: 'Calender',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance + ' meeting(s)';
        },
      },
      model: {
        title: ' Model',
      },
      ipv4: {
        title: 'IP Address',
        type: 'custom',
        renderComponent: TableCogIconComponent,
      },
    },
  };

  // axe
}
