import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { PopupTypeService } from '../../services/popupType.service';


@Component({
  selector: 'app-iot-lite-device-last-event',
  templateUrl: './iot-lite-device-last-event.component.html',
  styleUrls: ['./iot-lite-device-last-event.component.scss']
})
export class IotLiteDeviceLastEventComponent implements OnInit {
  @Input() latestEventsMins: any = '--';
  @Input() latestEvents24h: any = '--';
  @Input() deviceID: any = '--';
  @Input() deviceName: any = '--';

  message: any = '--';
  timestamp: any = '--';

  constructor(
    public ds: PopupTypeService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.latestEventsMins) {
      if (this.latestEventsMins) {
        const { timestamp, message } = this.latestEventsMins;
        this.message = message;
        this.timestamp = timestamp;
      }
    }
  }

  openIotDeviceHealthHistory = () => {
    const data = {
      iot_id: this.deviceID,
      iot_name: this.deviceName,
      days: 1
    };
    this.ds.sendPopupType(74, {
      data,
    });
  };
}
