<div class="document-center">
  <!-- <div *ngIf="true" class="document-center__loader">
    <img src="{{ loader }}" alt="" />
  </div> -->
  <nav aria-label="breadcrumb" class="position-relative">
    <ol class="breadcrumb">
      <i class="fa fa-arrow-left pr-2 pt-1" (click)="removePath()" *ngIf="foldersPath.length>0"></i>
      <li class="breadcrumb-item active" *ngIf="!foldersPath.length">/</li>
      <li class="breadcrumb-item" [ngClass]="i==foldersPath.length-1?'active':''"
        *ngFor="let path of foldersPath;let i=index;">{{path.file_name}}</li>
    </ol>
    <div class="custom-control custom-switch d-flex align-items-center p-0 m-0">
      <!-- <input type="checkbox" class="custom-control-input" id="customSwitch2" (change)="onChange()" [checked]="isFiles">
      <label class="custom-control-label" for="customSwitch2">Show Files</label> -->
      <div class="form-check pr-2">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
          (click)="onChange(false)">
        <label class="form-check-label" for="flexRadioDefault1">
          Folders & Files
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
          (click)="onChange(true)" checked>
        <label class="form-check-label" for="flexRadioDefault2">
          Files
        </label>
      </div>
    </div>
    <div class="btn-group pull-right">
      <button class="btn btn-white" type="button" (click)="onDisplayModeChange(false)"
        [ngClass]="{'btn-primary active': displayMode}">
        <i class="fa fa-list"></i>
      </button>
      <button class="btn btn-white" type="button" (click)="onDisplayModeChange(true)"
        [ngClass]="{'btn-primary active': !displayMode}">
        <i class="fa fa-th"></i>
      </button>
    </div>
  </nav>
  <div class="document-center__header-container">
    <!-- <div class="document-center__header-container__upload" alt="Upload Document" title="Upload Document"
      (click)="openDocumentDialog(propData, 'upload')">
      <i class="fa fa-upload"></i>Upload
    </div> -->
    <div ngbDropdown class="file-dropdown">
      <div class="document-center__header-container__upload w-100" id="dropdownBasic1" ngbDropdownToggle>
        <i class="fa fa-plus text-center pr-2"></i> <strong>New</strong>
      </div>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <span role="button" tabindex="0" ngbDropdownItem (click)="openDocumentDialog(propData, 'upload')"> <i
            class="fa fa-file pr-2"></i>File</span>
        <span role="button" tabindex="1" ngbDropdownItem (click)="openDocumentDialog(propData, 'folder')"> <i
            class="fa fa-folder pr-2"></i>Folder</span>
        <!-- <button ngbDropdownItem (click)="openDocumentDialog(propData, 'upload')">File</button>
        <button ngbDropdownItem (click)="openDocumentDialog(propData, 'upload')">Folder</button> -->
      </div>
    </div>
    <div class="document-center__header-container__stat" *ngIf="!isFiles">
      <strong>Folders:</strong> {{ totalFolders }}
    </div>
    <div class="document-center__header-container__stat">
      <strong>Files:</strong> {{ fileCount }}
    </div>
    <div class="document-center__header-container__stat">
      <strong>Storage:</strong> {{ storageLimit | documentFileSize }}
    </div>
    <div class="document-center__header-container__stat">
      <strong>Used:</strong> {{ totalDocumentSize | documentFileSize }}
    </div>
    <div class="document-center__header-container__stat">
      <strong>Available:</strong> {{ availableStorage | documentFileSize }}
    </div>
    <div *ngIf="shouldShowLoader" class="document-center__header-container__loader">
      <img src="{{ loader }}" alt="" />
    </div>
    <div class="document-center__header-container__right-container">
      <div class="document-center__header-container__right-container__search">
        <i class="fa fa-search"></i>
        <input #searchDataInput (keyup)="searchData(searchDataInput.value)" placeholder="Search..." />
        <i class="fa fa-times pointer" (click)="onCancelSearch()"
          [ngClass]="{'text-hide' : !searchDataInput.value}"></i>
      </div>
    </div>
  </div>

  <div class="document-center__table" *ngIf="!displayMode">
    <table class="data-table" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="file_type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let doc">
          <img [src]="doc.file_type | fileTypeIcon" [alt]="doc.file_type" />
        </td>
      </ng-container>

      <ng-container matColumnDef="file_name">
        <th mat-header-cell *matHeaderCellDef>Document Name</th>
        <td mat-cell *matCellDef="let doc">
          <!-- {{ doc.file_name}} -->
          <span class="folder-link" (click)="openFolder(doc);"
            *ngIf="doc.file_type== 'folder';else elseBlock">{{doc.file_name | renameFoldersFiles}}</span>
          <ng-template #elseBlock>
            <a [href]="docPath+doc.document_id+'/'+0" target="_blank">{{ doc.file_name | renameFoldersFiles}}</a>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="file_size">
        <th mat-header-cell *matHeaderCellDef>Size</th>
        <td mat-cell *matCellDef="let doc">
          {{ doc.file_size | documentFileSize }}
        </td>
      </ng-container>

      <ng-container matColumnDef="file_owner">
        <th mat-header-cell *matHeaderCellDef>File Owner</th>
        <td mat-cell *matCellDef="let doc">
          {{ doc.file_owner }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef>Uploaded On</th>
        <td mat-cell *matCellDef="let doc">
          <!-- {{ convertTimeStamp(doc.date_created) }} -->
          {{ doc.date_created | dateTimeZone : 'MM-DD-YYYY hh:mma' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="document_preview">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let doc">
          <span *ngIf="doc.file_type == 'folder';else elseBlock">
            <i alt="Delete Folder" title="Delete Document" class="fa fa-trash"
              (click)="openDocumentDialog(doc, 'dfolder')"></i>
          </span>
          <ng-template #elseBlock>
            <i class="fa fa-share-square" alt="Share Document" title="Share Document"
              (click)="openDocumentDialog(doc, 'share')"></i>

            <a [href]="docPath+doc.document_id+'/'+1" rel="noopener noreferrer" download>
              <i class="fa fa-download" alt="Download Document" title="Download Document"></i>
            </a>

            <i alt="Delete Document" title="Delete Document" class="fa fa-trash"
              (click)="openDocumentDialog(doc, 'delete')"></i>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div class="container p-0 m-0" *ngIf="displayMode">
    <div class="row gridd">
      <div class="col-md-4 mb-2 pl-0" *ngFor="let doc of dataSource.data">
        <div class="card">
          <div class="card-header">
            <img [src]="doc.file_type | fileTypeIcon" [alt]="doc.file_type" class="pr-2" />
            <span class="folder-link" (click)="openFolder(doc);"
              *ngIf="doc.file_type== 'folder';else elsePart">{{doc.file_name| renameFoldersFiles}}</span>
            <ng-template #elsePart>
              <a [href]="docPath+doc.document_id+'/'+0" target="_blank">{{ doc.file_name| renameFoldersFiles }}</a>
            </ng-template>
            <div ngbDropdown class="d-inline-block dots">
              <i class="fa fa-ellipsis-v cursor-pointer" id="dropdownBasic1" ngbDropdownToggle></i>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <span class="pl-2 cursor-pointer" *ngIf="doc.file_type == 'folder';else elseBlock">
                  <i alt="Delete Folder" title="Delete Document" class="fa fa-trash"
                    (click)="openDocumentDialog(doc, 'dfolder')"></i> Delete
                </span>
                <ng-template #elseBlock>
                  <div class="d-flex flex-column">
                    <span class="pl-2 d-flex align-items-center cursor-pointer"
                      (click)="openDocumentDialog(doc, 'share')">
                      <i class="fa fa-share-square" alt="Share Document" title="Share Document"></i> &nbsp;Share
                    </span>

                    <span class="pl-2 d-flex align-items-center cursor-pointer">
                      <a [href]="docPath+doc.document_id+'/'+1" rel="noopener noreferrer" download>
                        <i class="fa fa-download" alt="Download Document" title="Download Document"></i>&nbsp;Download
                      </a>
                    </span>

                    <span class="pl-2 d-flex align-items-center cursor-pointer"
                      (click)="openDocumentDialog(doc, 'delete')">
                      <i alt="Delete Document" title="Delete Document" class="fa fa-trash"></i>&nbsp;Delete
                    </span>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="card-body text-center">
            <img class="body-img" [src]="doc.file_type | fileTypeIcon" [alt]="doc.file_type" />
          </div>
          <div class="card-footer">
            {{ doc.date_created | dateTimeZone : 'MM-DD-YYYY hh:mma' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>