<table class="participants-table" style="width:100%;">
  <thead>
    <tr>
      <th>Participants</th>
      <th>Device</th>
      <!-- <th>QoS</th>           hide as per frankies request -->
      <th>Join Time</th>
    </tr>
  </thead>

  <tbody *ngIf="liveParticipants?.length > 0">
    <tr *ngFor="let participant of liveParticipants; index as n;">
      <td>
        <a class="hasTooltip">
          {{participant?.user_name}}
          <span class="tooltiptext">
            <!-- nullCheck each level in API -->
            <app-tooltip-stats [userName]="participant?.user_name"
              [audioInputJitter]="participant?.qos_data? nullCheck(participant?.qos_data.audio_input.jitter) : 0"
              [audioInputPacketLoss]="participant?.qos_data?nullCheck(participant?.qos_data.audio_input.avg_loss) : 0"
              [audioOutputJitter]="participant?.qos_data? nullCheck(participant?.qos_data.audio_output.jitter) : 0"
              [audioOutputPacketLoss]="participant?.qos_data? nullCheck(participant?.qos_data.audio_output.avg_loss) : 0"
              [videoInputJitter]="participant?.qos_data? nullCheck(participant?.qos_data.video_input.jitter) : 0"
              [videoInputPacketLoss]="participant?.qos_data? nullCheck(participant?.qos_data.video_input.avg_loss) : 0"
              [videoInputFrameRate]="participant?.qos_data? nullCheck(participant?.qos_data.video_input.frame_rate) : 0"
              [videoOutputJitter]="participant?.qos_data? nullCheck(participant?.qos_data.video_output.jitter) : 0"
              [videoOutputPacketLoss]="participant?.qos_data? nullCheck(participant?.qos_data.video_output.avg_loss) : 0"
              [videoOutputFrameRate]="participant?.qos_data? nullCheck(participant?.qos_data.video_output.frame_rate) : 0"
              [contentInputJitter]="participant?.qos_data?nullCheck(participant?.qos_data.as_input.jitter) : 0"
              [contentInputPacketLoss]="participant?.qos_data ? nullCheck(participant?.qos_data.as_input.avg_loss) : 0"
              [contentOutputJitter]="participant?.qos_data ? nullCheck(participant?.qos_data.as_output.jitter) : 0"
              [contentOutputPacketLoss]="participant?.qos_data? nullCheck(participant?.qos_data.as_output.avg_loss) : 0">
            </app-tooltip-stats>
          </span>
        </a>
      </td>
      <td>{{participant?.device}}</td>
      <!-- <td *ngIf="isCloudUser"><img
          src="../../../assets/img/{{participant?.img_src? participant?.img_src: 'triangle_green.png'}}"></td>
      <td *ngIf="!isCloudUser"><img [src]="qosState(participant?.qos)"></td>                hide as per frankies request-->
      <!-- <td>{{printTime(participant.join_time)}}</td> -->
      <td>{{participant.join_time | dateTimeZone : 'hh:mma'}}</td>
    </tr>
    <!-- 
<tr>
    <td>
        Pete Rose
      </td> <td>PSTN</td> <td><img src="../../../assets/img/green-triangle.png"></td> <td>10:07 am</td>
  </tr>
  <tr>
    <td>Henrry Sted</td> <td>iOS</td> <td><img src="../../../assets/img/green-triangle.png"></td> <td>10:00 am</td>
  </tr>
  <tr>
    <td>Licon Parker</td> <td>Web</td> <td><img src="../../../assets/img/green-triangle.png"></td> <td>9:58 am</td>
  </tr>
  -->
  </tbody>
  <h5 *ngIf="!liveParticipants" class="text-center">No Live Participants</h5>
</table>