import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-video-last-disconnect',
  templateUrl: './video-last-disconnect.component.html',
  styleUrls: ['./video-last-disconnect.component.scss']
})
export class VideoLastDisconnectComponent implements OnInit, OnChanges {
  @Input() videoDeviceData: any;
  
  constructor() { }

  ngOnInit(): void {
  }
  
  	ngOnChanges(changes: SimpleChanges) {
		if (changes.videoDeviceData && this.videoDeviceData) {
			// console.log('videoDeviceData last call: ', this.videoDeviceData)
			this.setData()
		}
	}
	
	setData = () => {
	
	}


}
