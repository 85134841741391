<div class="info-chartSite chart-container">
    <div style="text-align: center">
        <!-- <button (click)="set60m();" [disabled]="currentChart=='60m'">
            60m
        </button>
        <button (click)="set24h();" [disabled]="currentChart=='24h'">
            24h
        </button>
        <button (click)="set7d();" [disabled]="currentChart=='7d'">
            7d
        </button> -->
    </div>
    <canvas id="myChartSite" style=" width:350px" height="220px"></canvas>
    <!-- <div style="font-size: .5em">*** Chart contains sample data</div> -->
</div>
