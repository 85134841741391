<div style="padding-top: 7%;">
    <input #applyFilterInputt (keyup)="applyFilter(applyFilterInputt.value)" placeholder="Search..."
        style="width:100%; padding-left:21px; height:35px;font-size: 15px;border-bottom: 1px solid lightgray !important;">
    <div class="example-container mat-elevation-z8" style="background-color:white">

        <table mat-table [dataSource]="videoSitesListData" matSort class="mat-elevation-z8">


            <ng-container matColumnDef="logo" style="padding-left:5px; padding-right:5px;">
                <th mat-header-cell *matHeaderCellDef style="color:black; font-weight: bold; ">
                </th>
                <td mat-cell *matCellDef="let element">
                    <app-table-sitetype-icon [value]="element.virtual?element.virtual:0"></app-table-sitetype-icon>
                </td>
            </ng-container>

            <ng-container *ngIf="this.Auth.accessLevel == 'SUPERADMIN'" matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold; ">Company
                </th>
                <td mat-cell *matCellDef="let element">{{element.company_name}}</td>
            </ng-container>


            <ng-container matColumnDef="site" style="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold; ">Site
                </th>
                <td mat-cell *matCellDef="let element">{{element.site_name}} </td>
            </ng-container>

            <ng-container matColumnDef="health" tyle="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold; ">Health
                </th>
                <td mat-cell *matCellDef="let element">
                    <app-table-health-icon [value]="element.health" style="text-align: center;"></app-table-health-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="impacted" style="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold;">Impacted
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                    {{element.impacted>0?element.impacted:"--"}}
                </td>
            </ng-container>

            <ng-container matColumnDef="infrast" style="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold;">Infrast.
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">{{element.infrastructure}}</td>
            </ng-container>

            <ng-container matColumnDef="devices" style="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold;">Devices
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">{{element.devices}}</td>
            </ng-container>

            <ng-container matColumnDef="oncall" style="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold; ">On Call
                </th>
                <td mat-cell *matCellDef="let element">
                    <app-table-oncall-icon [value]="element.oncall" style="text-align: center;"></app-table-oncall-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="calendar" style="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold; ">Scheduled
                    meetings
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                    {{ element.scheduled_meetings == null || element.scheduled_meetings == 0 ?
                    "--" : element.scheduled_meetings}}</td>
            </ng-container>

            <ng-container matColumnDef="livembps" style="padding-left:5px;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:black; font-weight: bold; ">Live Mbps
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                    {{element.bitrate?(element.bitrate/(1024*1024)|twoDecimals):"--"}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="clickRow(row)"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" [colSpan]="displayedColumns.length">No data found in table</td>
            </tr>
        </table>
    </div>
</div>