import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import * as moment from 'moment-timezone';


@Component({
  selector: 'app-cloud-live-call-list',
  templateUrl: './cloud-live-call-list.component.html',
  styleUrls: ['./cloud-live-call-list.component.scss']
})
export class CloudLiveCallListComponent implements OnInit {
  @Input() liveParticipants: any;
  @Input() liveQosTrigger: any;
  qosTriggers = {
    bitrate: 100000,
    packetloss: 3,
  }
  isCloudUser: boolean = false;
  constructor() { }


  nullCheck(value) {
    return value ? value : '--'
  }

  // printTime(t) {
  //   if (isNaN(t)) {
  //     let a = moment(t)
  //     return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mma');
  //   } else {
  //     let a = moment.unix(t)
  //     return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mma');
  //   }
  // }

  qosState(value) {
    return value > 0 ? "../../../assets/img/triangle_green.png" : "../../../assets/img/triangle_red.png"
  }
  qosStateNew(value) {
    let img_src = "triangle_green.png";
    if (value) {
      let { total_bitrate, total_avg_loss } = value;
      let flag = 0;

      if (total_bitrate) {
        if (total_bitrate <= this.qosTriggers.bitrate && total_bitrate > 0) {
          flag = 1;
        }
      }

      if (total_avg_loss) {
        if (total_avg_loss >= this.qosTriggers.packetloss) {
          flag = 1;
        }
      }

      if (flag) {
        img_src = "triangle_red.png";
      }
    }
    return img_src;
  }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.liveQosTrigger && this.liveQosTrigger) {
      this.isCloudUser = true;
      this.qosTriggers.bitrate = this.liveQosTrigger.bitrate.warning;
      this.qosTriggers.packetloss = this.liveQosTrigger.packetsloss.warning;
    }
    if (changes.liveParticipants && this.liveParticipants) {
      for (let lpIndex in this.liveParticipants) {
        this.liveParticipants[lpIndex].img_src = this.liveParticipants[lpIndex].qos_data ? this.qosStateNew(this.liveParticipants[lpIndex].qos_data) : "triangle_green.png";
      }
    }
  }
}
