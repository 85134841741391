import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { GetUsStatesService } from '../../../../app/services/get-us-states.service';
import { AlertService } from 'src/app/_alert';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  links = environment.config.links;
  backgroundImage = environment.config.dynamicImage.bgImage;
  loginEnv: any;
  environmentTitle = environment.config.title;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  // disableSubmit: boolean = false;
  shouldShowFreeTrial: boolean = false;
  message: any = { text: 'Email address not found', flag: false };
  visibilityBackground = {
    'background-image': 'url(' + this.backgroundImage + ')',
    'background-repeat': 'no-repeat',
    'background-size': 'cover',
    height: '86vh',
  };
  beingRegistrationFormSubmitted = false;
  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private getStates: GetUsStatesService,
    protected alertService: AlertService
  ) { }
  ngOnInit() {
    this.setData();

  }

  setData = () => {
    if (environment.config.title === 'VisibilityOne'
      || environment.config.title === 'vCare'
      || environment.config.title === 'Vision Point'
      || environment.config.title === 'Videlio'
      || environment.config.title === 'DEKOM') {
      this.loginEnv = true
      this.shouldShowFreeTrial = true;
    } else {
      this.loginEnv = false;
    }
  }

  registerUser = async (companyname, firstname, lastname, email, phone, password) => {
    try{
      this.beingRegistrationFormSubmitted = true;    
      let emailToPlatformAdmins = this.environmentTitle === 'VisibilityOne';
      const user = {
        data: {
          user: {
            firstname,
            lastname,
            email,
            phone,
            password,
          },
          company: {
            companyname,
            phone,
          },
          emailToPlatformAdmins: emailToPlatformAdmins,
        },
      };

      let responseObj: any = await this.Auth.createVSOneUser(user).toPromise();
      if(!responseObj?.response){
        throw responseObj.error || "Something went wrong while registration";
      }
      if ([200, 'OK'].includes(responseObj.status)) {
        this.router.navigate(["login"], {
          queryParams: {
            message: "Registered successfully. Please login",
            flag: true,
            type: 1,
          },
        });
      } else {
        if (responseObj.status === 'Error') {
          this.setMessageForLogin(responseObj.response[0]);
        }
        throw "Something went wrong while registration";
      }      
    }catch(error){
      if(typeof error == 'string'){
        this.alertService.error(error, this.options);
      }else{
        this.alertService.error("Something went wrong while registration", this.options);
      }
    }finally{
      this.beingRegistrationFormSubmitted = false;

    }
  };

  registerEmitEvent = ({ company, firstname, lastname, email, phone, password }) => {
    this.registerUser(company, firstname, lastname, email, phone, password);
  };

  setMessageForLogin = ({ message }) => {
    if (message) {
      this.message = {
        text: message,
        flag: true,
      };
    }
  };
}
