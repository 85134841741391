import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { GetTeamsService } from '../../services/get-teams.service';
import * as moment from 'moment-timezone';
// import { ConsoleReporter } from 'jasmine';

// declare var $: any;

@Component({
  selector: 'app-teams-past-meetings-and-calls',
  templateUrl: './teams-past-meetings-and-calls.component.html',
  styleUrls: ['./teams-past-meetings-and-calls.component.scss'],
})
export class TeamsPastMeetingsAndCallsComponent implements OnInit, OnChanges {
  @Input() deviceId: any;

  testarr: any = [
    {
      duration: '0:00:17',
      participant_count: 2,
      start_time: "2021-05-18T08:32:15.000Z",
      uuid: "8ca8cdb7-e307-4e0f-8dbe-0508614fded3"
    },
  ];
  searchKey: any;
  teamsMeetings: any;
  meetingDetails: any;
  sortFLag: boolean = false;

  deviceClass: any = '';
  systemClass: any = '';
  connectivityClass: any = '';
  networkClass: any = '';
  participantClass: any = 'teams-past-meetings__button-item--active';
  panelToShow: any = 'participants';
  currentParticipant: any;
  shouldShowParticipants: boolean = false;
  // shouldShowParticipants: boolean = true;
  loader = environment.config.dynamicImage.loader;
  shouldShowLoader: boolean = true;
  shouldShowLoader2: boolean = true;

  mainAudioStream: any;
  desktopSharingStream: any;
  hostName: any;
  participants: any;
  mainAudioConnectivity: any;
  appSharingConnectivity: any;

  constructor(private getTeams: GetTeamsService) { }

  ngOnInit() {
    // this.getTeamMeetingDetails('3b02c725-499a-4a4e-97fb-6d279c586a5a')
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.deviceId && this.deviceId) {
      this.getTeamsMeetings();
      //for testing
      // this.getTeamMeetingDetails('7da7a482-d68f-49f1-a15d-3d4235d278fa') // group
      // this.getTeamMeetingDetails('f0f4666e-ee16-4562-96fb-9e36966fdd32')
    }
  }


  getTeamsMeetings = () => {
    this.shouldShowLoader = true;
    this.getTeams.getTeamsMeetings(this.deviceId).subscribe(
      (data: any) => {
        // // console.log('getTeamsMeetings: ', data)
        this.teamsMeetings = data.filter(e => e != null).sort(this.getSortOrderDesc('start_time'));
        this.shouldShowLoader = false;
      },
      (err) => { }
    );
  };



  checkForUnavailable = (params) => (params || params !== null ? params : "Data isn't available");

  sortStamp = () => {
    this.sortFLag = !this.sortFLag;
    if (this.sortFLag) {
      this.teamsMeetings = this.teamsMeetings.sort(this.getSortOrderAsc('start_time'));
    } else {
      this.teamsMeetings = this.teamsMeetings.sort(this.getSortOrderDesc('start_time'));
    }
  };



  selectButton = (params) => {
    this.mainAudioStream = [];
    this.desktopSharingStream = [];

    this.panelToShow = params;
    this.returnClassToDefault();
    if (params == 'device') {
      this.deviceClass = 'teams-past-meetings__button-item--active';

    }
    if (params == 'system') {
      this.systemClass = 'teams-past-meetings__button-item--active';
    }
    if (params == 'connectivity') {
      this.connectivityClass = 'teams-past-meetings__button-item--active';
      // // console.log(this.currentParticipant)
      this.appSharingConnectivity = this.getAppSharingConn(this.currentParticipant.streams.filter(e => e.label === "applicationsharing-video"), this.meetingDetails.type);
      this.mainAudioConnectivity = this.getMainAudioConn(this.currentParticipant.streams.filter(e => e.label === "main-audio"), this.meetingDetails.type);

      //onsole.log("appSharing:" + JSON.stringify(this.appSharingConnectivity));
      //// console.log("main Audio: " + JSON.stringify(this.mainAudioConnectivity));
      if (this.isArrayEmpty(this.appSharingConnectivity)) {
        //// console.log("App sharing has data: " + this.appSharingConnectivity);
      } else {
        //// console.log("App sharing has no data:" + this.appSharingConnectivity);
      }
    }
    if (params == 'network') {
      this.networkClass = 'teams-past-meetings__button-item--active';
      this.desktopSharingStream = this.getDesktopSharingStream(this.currentParticipant.streams.filter(e => e.label === "applicationsharing-video"), this.meetingDetails.type);
      this.mainAudioStream = this.getMainAudioStream(this.currentParticipant.streams.filter(e => e.label === "main-audio"), this.meetingDetails.type);


    }
    if (params == 'participants') {
      this.participantClass = 'teams-past-meetings__button-item--active';
    }
  }



  isArrayEmpty = (array) => {
    if (array == null || array.length == 0) {
      return false;
    }
    return true;
  }

  returnClassToDefault = () => {
    this.deviceClass = '';
    this.systemClass = '';
    this.connectivityClass = '';
    this.networkClass = '';
    this.participantClass = '';
  }

  getSortOrderAsc = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  getSortOrderDesc = (prop) => {
    return function (a, b) {
      if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] > b[prop]) {
        return -1;
      }
      return 0;
    };
  };



  checkActiveParticipant = (name) => {
    return this.currentParticipant.displayName === name ? 'teams-past-meetings__participants-clickable--active' : ' ';
  }

  getCurrentParticipant = (participant) => {
    // console.log('getCurrentParticipant');
    this.currentParticipant = participant;
  }

  checkForModalities = (arr = []) => {
    if (arr.length > 0) {
      return arr.join(', ')
    }
    return "--"
  }

  displayMeetingList = () => {
    this.shouldShowParticipants = false;
    this.getParticipantList();
    this.panelToShow = 'participants';
    this.participantClass = 'teams-past-meetings__button-item--active';
    this.returnClassToDefault();
    this.resetMeetingDetailsView()

  }

  resetMeetingDetailsView = () => {
    // console.log('resetMeetingDetailsView: ')
    this.currentParticipant = [];
    this.participantClass = 'teams-past-meetings__button-item--active';

  }

  getParticipantList = () => {

  }



  convertStreams = (streams, addons, type = '') => {
    if (streams === null) {
      return "Data isn't available";
    }

    if (streams === 0) {
      return '0' + ' ' + addons
    }

    if (type == 'packetloss') {
      return Math.round(streams * 10000) / 100 + ' ' + addons
    }

    const matchString = streams.match(/[0-9.]/g).join('')
    return matchString * Math.pow(10, 3) + ' ' + addons
  }

  convertTimeStamp = (time) => {
    const newTime = moment(time).format('MMMM Do YYYY, h:mm:ss a')
    return newTime
  }

  convertTimeDifference = (time) => {
    if (time === 0 || time === null) {
      return '--';
    }
    const a = moment();
    const b = moment(time);
    const days = a.diff(b, 'days');
    const hours = a.diff(b, 'hours');
    const minutes = a.diff(b, 'minutes');
    if (days !== 0) {
      return days + 'days ago'
    }

    if (hours !== 0) {
      return hours + ' hours ago'
    }

    if (minutes !== 0) {
      return minutes + ' minutes ago'
    }

  }

  convertSeconds = (d) => {
    if (d === 0) {
      return '--'
    }
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h : 0
    var mDisplay = m > 0 ? m : 0
    var sDisplay = s > 0 ? s : 0
    return this.formatSeconds(hDisplay) + ":" + this.formatSeconds(mDisplay) + ":" + this.formatSeconds(sDisplay);
  }

  formatSeconds = (time) => {
    return time === 0 ? "00" : time > 9 ? time : "0" + time;
  }

  checkMainAudioLabel = (displayName, type, otherCaller = '') => {
    if (type == '1') {
      return `Network stream from ${displayName} to ${this.getOtherParticipant(displayName)}`
    } else {
      //group has two strip
      if (otherCaller === 'service') {
        return `Network stream from Teams to ${displayName}`
      } else {
        return `Network stream from ${displayName} to  Teams`
      }
    }
  }

  getOtherParticipant = (participant) => {
    const [user] = this.participants.filter(e => e !== participant)
    return user
  }

  checkDesktopSharingStream = (displayName, type, otherCaller = '') => {
    if (type == '1') {
      return `Network stream from ${displayName} to ${this.getOtherParticipant(displayName)} (desktop sharing)`
    }
    else {
      if (otherCaller === 'service') {
        return `Network stream from Teams to ${displayName}  (desktop sharing)`
      } else {
        return `Network stream from ${displayName} to  Teams  (desktop sharing)`
      }
    }


  }

  getMainAudioStream = (stream, type) => {

    if (type == "2") {
      if (this.isArrayEmpty(stream)) {
        return stream[0].streams;
      }
    } else if (type == "1") {
      if (this.isArrayEmpty(stream)) {
        if (this.currentParticipant.displayName === this.hostName) {
          return stream[0].streams.filter(e => e.streamDirection === "callerToCallee");
        } else {
          return stream[0].streams.filter(e => e.streamDirection === "calleeToCaller");
        }
      }

    }

    return []

  }

  getDesktopSharingStream = (stream, type) => {

    if (type == "2") {
      if (this.isArrayEmpty(stream)) {
        return stream[0].streams;
      }
    } else if (type == "1") {
      if (this.isArrayEmpty(stream)) {
        if (this.currentParticipant.displayName === this.hostName) {
          return stream[0].streams.filter(e => e.streamDirection === "callerToCallee");
        } else {
          return stream[0].streams.filter(e => e.streamDirection === "calleeToCaller");
        }
      }

    }

    return []

  }

  getTeamMeetingDetails = (id) => {
    this.resetMeetingDetailsView();
    this.shouldShowParticipants = true;
    this.shouldShowLoader2 = true;
    this.meetingDetails = [];
    this.getTeams.getTeamMeetingDetails(id).subscribe(
      (data: any) => {
        // console.log('getTeamMeetingDetails: ', data)
        const [details] = data.map(e => {
          return {
            ...e,
            participants: JSON.parse(e.participants)
          }
        });

        this.meetingDetails = details
        // console.log(details);
        this.getCurrentParticipant(details.participants[0])
        this.shouldShowLoader2 = false;
        this.hostName = details.host;
        this.participants = details.participants.map(e => e.displayName)

        // // console.log('participants', this.participants)

      },
      (err) => { }
    );
  };

  getStreams = (streams: any = [], type, participantType = 'caller') => {

    let ret;

    if (type === 'avgRoundTrip') {
      return this.convertStreams(streams.averageRoundTripTime, 'ms');
    }
    if (type === 'maxRtt') {
      return this.convertStreams(streams.maxRoundTripTime, 'ms');
    }
    if (type === 'avgJitter') {
      return this.convertStreams(streams.averageJitter, 'ms');
    }
    if (type === 'maxJitter') {
      return this.convertStreams(streams.maxJitter, 'ms');
    }
    if (type === 'avgPacketLoss') {
      return this.convertStreams(streams.averagePacketLossRate, '%', 'packetloss');
    }
    if (type === 'maxPacketLoss') {
      return this.convertStreams(streams.maxPacketLossRate, '%', 'packetloss');
    }

    if (type === 'connectionType') {
      if (this.mainAudioConnectivity.connectionType) {
        if (this.mainAudioConnectivity.connectionType === "wired") {
          ret = "Ethernet";
        } else {
          ret = this.mainAudioConnectivity.connectionType;
        }
      } else {
        ret = "--";
      }
      return ret;
    }
    if (type === 'driverDescription') {
      ret = this.mainAudioConnectivity.wifiVendorDriver ? this.mainAudioConnectivity.wifiVendorDriver : "--";
      return ret;
    }
    if (type === 'driverVersion') {
      ret = this.mainAudioConnectivity.wifiMicrosoftDriverVersion ? this.mainAudioConnectivity.wifiMicrosoftDriverVersion : "--";
      return ret;
    }
    if (type === 'signalStrength') {
      ret = this.mainAudioConnectivity.wifiSignalStrength ? this.mainAudioConnectivity.wifiSignalStrength : "--";
      return ret;
    }

  }

  getMainAudioConn = (stream, type) => {
    if (type == "2") {
      if (this.isArrayEmpty(stream)) {

        return stream[0].callerNetwork;
      }
    } else if (type == "1") {
      if (this.isArrayEmpty(stream)) {
        if (this.currentParticipant.displayName === this.hostName) {
          return stream[0].callerNetwork;
        } else {
          return stream[0].calleeNetwork;
        }
      }
    }
    return []

  }

  getAppSharingConn = (stream, type) => {

    if (type == "2") {
      if (this.isArrayEmpty(stream)) {
        return stream[0].callerNetwork;
      }
    } else if (type == "1") {
      if (this.isArrayEmpty(stream)) {

        if (this.currentParticipant.displayName === this.hostName) {
          return stream[0].callerNetwork;
        } else {
          return stream[0].calleeNetwork;
        }
      }

    }
    return []

  }



  //class end
}
