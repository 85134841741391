import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-btn-total-talk',
  templateUrl: './btn-total-talk.component.html',
  styleUrls: ['./btn-total-talk.component.scss']
})
export class BtnTotalTalkComponent implements OnChanges {
  @Input() countHrs: number = 12;
  @Input() labelHrs: string = "HRS";
  @Input() countMin: number = 32;
  @Input() labelMin: string = "MIN";
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.countHrs) {
      this.countHrs = changes.countHrs.currentValue
    }
    if (changes.countMin) {
      this.countMin = changes.countMin.currentValue
    }
  }

}
