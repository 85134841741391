import { HttpClient } from '@angular/common/http';
import * as Chart from 'chart.js';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-chart-video-packetloss',
  templateUrl: './chart-video-packetloss.component.html',
  styleUrls: ['./chart-video-packetloss.component.scss']
})
export class ChartVideoPacketlossComponent implements AfterViewInit {

  canvas: any;
  ctx: any;
  myChartSite: any;
  currentChart = '24h';
  id: Number = 0;
  chartApiData: any;
  loading: Boolean = false;

  chartData = {
    datasets: [
      {
        label: 'Total Loss',
        data: [],
        backgroundColor: ['rgb(0, 128, 255, .5'],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)', //red
        borderWidth: 0,
        stepped: true,
      },
      {
        label: 'RX-Loss',
        data: [],
        backgroundColor: ['rgb(3, 173, 20, .5'],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)', //red
        borderWidth: 0,
      },
      {
        label: 'TX-Loss',
        data: [],
        backgroundColor: ['rgb(188, 71, 3, .5'],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)', //red
        borderWidth: 0,
      },
    ],
  };

  constructor(
    private route: ActivatedRoute,
    public http: HttpClient,
    private Auth: AuthService,
  ) {
    this.route.params.subscribe((params) => {
      if (+params['id'] == this.id) {
      } else {
        this.id = +params['id'];
        if (!this.loading) {
          this.fetchChartData();
        }
      }
    });
  }


  ngAfterViewInit() {
    this.canvas = document.getElementById('myChartSitez');
    this.ctx = this.canvas.getContext('2d');
    this.myChartSite = new Chart(this.ctx, {
      type: 'line',
      // data: this.data24h,
      options: {
        responsive: true,
        display: true,
        fontSize: '6px',
        padding: '0px',
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0, // disables bezier curves
          },
        },
      },
    });
    this.currentChart = '24h';
  }

  fetchChartData() {
    this.loading = true;
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const deviceDetails = {
      company_id: decodedToken.company_id,
      device_id: this.id,
      duration: 1,
    };

    this.Auth.getPacketlossChartData(deviceDetails).subscribe((data) => {
      let packetloss = data['response'][0].packetloss_total;
      packetloss.sort(this.custom_sort);
      let packetloss_tx = data['response'][0].packetloss_tx;
      packetloss_tx.sort(this.custom_sort);
      let packetloss_rx = data['response'][0].packetloss_rx;
      packetloss_rx.sort(this.custom_sort);

      this.chartData.datasets[0].data = packetloss;
      this.chartData.datasets[1].data = packetloss_tx;
      this.chartData.datasets[2].data = packetloss_rx;
      this.loadChart();
      this.loading = false;
    });

  }

  custom_sort(a, b) {
    return new Date(a.x).getTime() - new Date(b.x).getTime();
  }


  loadChart() {
    if (this.myChartSite) {
      this.myChartSite.reset();
      this.myChartSite.data.datasets = this.chartData.datasets;
      this.myChartSite.options.scales = {
        xAxes: [
          {
            type: 'time',
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      };
      this.myChartSite.update({ duration: 1000 });
    }
  }
}
