import { trigger, state, style, transition,
  animate, group, query, stagger, keyframes
} from '@angular/animations';

export const PopupMembers = [
    trigger('popupMembers', [
      state('close', style({
        'max-height': '0%',
        display: 'none'
      })),
      state('open', style({
        'max-height': '100%'
      })),
      transition('close => open', animate('500ms ease-in-out')),
      transition('open => close', animate('200ms ease-in-out'))
    ]),
]