import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GetVideoService } from '../../services/get-video.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-cloud-recordings',
  templateUrl: './cloud-recordings.component.html',
  styleUrls: ['./cloud-recordings.component.scss'],
})
export class CloudRecordingsComponent implements OnInit, OnChanges {
  meetingsResponseData: any;
  @Input() popData: any;
  @Input() popupState: any;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.popData && this.popData) {
      this.setData();
    }
  }

  // printTime(t) {
  //   if (isNaN(t)) {
  //     let a = moment(t);
  //     return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
  //   } else {
  //     let a = moment.unix(t);
  //     return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
  //   }
  // }

  // printEndTime(t) {
  //   if (isNaN(t)) {
  //     let a = moment(t);
  //     return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mma');
  //   } else {
  //     let a = moment.unix(t);
  //     return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mma');
  //   }
  // }

  printDuration(t) {
    if (t > 60) {
      let y = moment.duration(t, 'minutes').asHours();
      return Math.round(y * 100) / 100 + ' hours';
    } else {
      return t + ' minutes';
    }
  }

  openNewWindow(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }

  setData = () => {
    const { deviceId, meetings } = this.popData;
    this.meetingsResponseData = meetings || [];
  };
}
