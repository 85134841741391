import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-vis-one-popup-two',
  templateUrl: './vis-one-popup-two.component.html',
  styleUrls: ['./vis-one-popup-two.component.scss'],
})
export class VisOnePopupTwoComponent implements OnInit, OnChanges {
  constructor(private Auth: AuthService) {}
  dateVal: any = '';
  dateMsg: String = 'Error: Date Invalid';
  dateMsgStatus: String = 'error';
  scheduleButtonDisabled: Boolean = false;
  showDateErrMsg: Boolean = false;
  dCollector: String = environment.config.dynamicText.dCollector;

  @Input() popUpCollectorData: any;
  @Input() collectorVersionData: any;

  ngOnInit() {
    this.setDateLabel();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.popUpCollectorData) {
      this.setDateLabel();
    }
  }

  setDateLabel = () => {
    const { date } = this.popUpCollectorData;
    if (date && date.trim().length !== 0) {
      const momentData = moment(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format();
      this.dateVal = momentData.split(':')[0] + ':' + momentData.split(':')[1];
    }
  };

  updateCollectorSchedule() {
    const uDate = (<HTMLInputElement>document.getElementById('schedule-date')).value;
    const dateArr = new Date(uDate).toString().split(' ');

    const validate = this.validateForm(dateArr, true, new Date(uDate) > new Date());

    if (validate) {
      this.dateMsgStatus = 'success';
      this.dateMsg = 'Success: Update Sucess';
      this.showDateErrMsg = true;
      const sDate = uDate.split('T');
      const scheduleDate = moment(sDate[0] + ' ' + sDate[1])
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format();
      this.Auth.setCollectorUpdateVersionSchedule(
        this.popUpCollectorData.id,
        scheduleDate
      ).subscribe((data) => {
        this.dateMsgStatus = 'success';
        this.dateMsg = 'Success: Update Sucess';
        this.showDateErrMsg = true;
        this.scheduleButtonDisabled = true;
        setTimeout(() => {
          this.showDateErrMsg = false;
          this.scheduleButtonDisabled = false;
        }, 2000);
      });
    }
  }

  validateForm = (date: any, bool: any, dateCompare: any) => {
    this.showDateErrMsg = false;
    this.dateMsgStatus = 'error';

    let count = 0;
    if (date[0] === 'Invalid') {
      this.dateMsg = 'Error: Date Invalid';

      this.showDateErrMsg = true;
      count++;
    } else {
      if (!dateCompare) {
        this.showDateErrMsg = true;
        this.dateMsg = 'Error: Date must be after Date today';
        count++;
      }
    }
    return count === 0;
  };
}
