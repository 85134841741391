<div class="row pl-3 pr-2">
		<div class="col-2 pl-0 pr-1">
			<span class="sm-font py-2 pl-2 pb-2">
				<img src="./assets/img/teams_logo.png@124.png" alt="Windows Teams" style="height:20px;width:20px">
				Certified
			</span>
			<app-btn-reg-stats [count]="audioDeviceData?.ti_cert" label='Not Certified' color='gray'></app-btn-reg-stats>
		</div>
		<div class="col-3 vl">
			<span class="sm-font py-2 pl-2 pb-2">Firmware Update(s) Avl.</span>
			<div class="row">
				<div class="col-6 pl-1 pr-1">
				<app-btn-audio-firmware-devices [count]="audioDeviceData?.firmware_update" label="Updates" device="jabra"></app-btn-audio-firmware-devices>
				</div>
				<div class="col-6 pl-0 pr-1">
				<app-btn-audio-firmware-devices [count]="audioDeviceData?.firmware_update" label="Updates" device="plan"></app-btn-audio-firmware-devices>
				</div>
			</div>
		</div>
			<div class="col-4 vl pl-0 pr-1">
				<span class="sm-font py-2 pl-2 pb-2">Life Total Talk Time</span>
			<app-btn-total-talk></app-btn-total-talk>
		</div>
		<div class="col-3 vl pl-1 pr-1">
			<span class="sm-font py-2 pl-2 pb-2">bluetooth signal</span>
			<app-btn-bluetooth [bluetooth]="audioDeviceData?.bluetooth" label="Updates"></app-btn-bluetooth>
		</div>
</div>
<!-- bottom list -->
<div class="row justify-content-end mb-3">
		<!-- <div class="col-6">
			<input #search class="search mt-2 form-control" type="text" placeholder="Search..." 
			(keydown.enter)="onSearch(search.value)"
			/>
		</div> -->
</div>
		<!-- <app-filter-table 
			[tableSettings]="audioDeviceUserTableSettings"
			[tableSeedData]="audioDeviceData"
			[clickEvent]="onClickTable"
		>
		</app-filter-table>		 -->