import { Subscription } from 'rxjs'
import { AuthService } from 'src/app/services/auth.service'
import { IncidentType } from 'src/app/services/incident-messages.service'
import { PrintDataService } from 'src/app/services/print-data.service'

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { IncidentMessagesComponent } from '../../../src/app/popups/dialog/incident-messages/incident-messages.component'

@Component({
	selector: 'app-video-logitech-incidents',
	templateUrl: './video-logitech-incidents.component.html',
	styleUrls: ['./video-logitech-incidents.component.scss'],
})
export class VideoLogitechIncidentsComponent implements OnInit, OnChanges {
	@Input() data: any
	@Input() popupState: any
	isShowTableData: boolean = true
	shouldShowLoaderBtn: boolean = false
	filterListner: Subscription

	incidentsData = []
	constructor(private Auth: AuthService, private printData: PrintDataService, private dialog: MatDialog) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (this.data && changes.data) {
			this.getData()
		}
	}

	getData = () => {
		this.shouldShowLoaderBtn = true

		this.Auth.getDeviceIncidentList({
			video_device_id: this.data.deviceId,
			htype: 'logitech',
		}).subscribe((data: any) => {
			if (data.data.length < 1) {
				this.isShowTableData = false
			}

			function getEventsNew(i) {
				return Object.keys(i.events).map(e => ({
					trigger: i.events[e].message,
					pulls: i.events[e].polls,
					last_pull: i.events[e].lastPollStamp,
					end_time: i.events[e].endStamp,
					start_date: i.events[e].startStamp,
				}))
			}

			this.incidentsData = data.data.map(i => ({
				incident_id: i.video_incident_id,
				actioned: i.actioned,
				start_time: i.start_time,
				end_time: i.end_time,
				event_details: getEventsNew(i),
			}))

			this.shouldShowLoaderBtn = false
		})
	}

	printIncidentsToPdf = () => {
		this.printData.printToPdf('incident-history-list-container', 'Incident Report')
	}

	onShowMessages = (incidentId, idx) => {
		const dialogRef = this.dialog.open(IncidentMessagesComponent, {
			panelClass: 'custom-dialog-container',
			data: {
				incidentId,
				incidentType: IncidentType.VIDEO,
			},
			width: '80%',
			height: 'auto',
		})

		dialogRef.afterClosed().subscribe(isActioned => {
			if (isActioned) {
				this.incidentsData[idx].actioned = 1
			}
		})
	}
}
