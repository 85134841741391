import {
  Component,
  OnInit,
  OnChanges,
  ViewChild,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatInput } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TableFilterService } from 'src/app/services/table-filter.service';
import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, map, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-cloud-all-users-list-table',
  templateUrl: './cloud-all-users-list-table.component.html',
  styleUrls: ['./cloud-all-users-list-table.component.scss'],
})
export class CloudAllUsersListTableComponent implements OnInit, OnChanges,OnDestroy {
  constructor(private router: Router, public Auth: AuthService) { }

  @Input() cloudUsersListData: MatTableDataSource<any>;
  @Input() cloudUserListDataLen: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  destroy$: Subject<boolean> = new Subject<boolean>();
  observable : any;

  
  displayedColumns: string[] =
    this.Auth.accessLevel === 'SUPERADMIN'
      ? [
        'company',
        'name',
        'health',
        'qos',
        'livembps',
        'oncall',
        'duration',
        'calender',
        'service',
      ]
      : ['name', 'health', 'qos', 'livembps', 'oncall', 'duration', 'calender', 'service'];
  Math: any = Math;

  pageSizeOption: any[] = [200];

  filteredValues = {
    name: '',
    health: '',
    qos: '',
    livembps: '',
    oncall: '',
    duration: '',
    calender: '',
    service: '',
  };

  ngOnInit(): void { 
    console.log("On init")
    this.observable = fromEvent(document.querySelector("#cloud-all-user-list tbody"), 'scroll');
    const subscriber = this.observable
    .pipe(
      throttleTime(300 /* ms */),
      map((data) => {
        console.log('throttle');
        console.log(window.pageYOffset);
        return data;
      }),
      debounceTime(350)
    )
    .takeUntil(this.destroy$)
    .subscribe(
      (x) => {
        const listsContainerElement = document.querySelector("#cloud-all-user-list tbody")
        var a = listsContainerElement.scrollTop;

        var b = listsContainerElement.scrollHeight - listsContainerElement.clientHeight;
        
        var c = a / b;

        console.log("Percentage #" ,  c , x );
      },
      (err) => {
        console.log('Error: %s', err);
      },
      () => {
        console.log('Completed');
      }
    );
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }
  applyFilter(filterValue: string) {
    if (filterValue === '') {
      this.cloudUsersListData.filter = '-'.trim().toLowerCase();
    } else {
      this.cloudUsersListData.filter = filterValue.trim().toLowerCase();
    }
  }

  ngAfterViewInit() {
    this.cloudUsersListData.filter = '-'.trim().toLocaleLowerCase();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cloudUsersListData.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'company': {
          return item.company_name.toLocaleLowerCase();
        }
        case 'name': {
          return item.firstname.toLocaleLowerCase();
        }
        case 'health': {
          return item.health;
        }
        case 'qos': {
          return item.qos;
        }
        case 'calender': {
          return item.scheduledMeetings;
        }
        case 'livembps': {
          return item.total_bitrate;
        }
        case 'duration': {
          return item.duration;
        }
        case 'oncall': {
          return item.liveMeetings;
        }
        default:
          return item[property];
      }
    };
    this.cloudUsersListData.sort = this.sort;
    this.cloudUsersListData.paginator = this.paginator;
    if (changes.cloudUserListDataLen) {
      if (this.cloudUserListDataLen) {
        this.setPageOption(this.cloudUserListDataLen);
      }
    }
  }

  setPageOption = (len) => {
    if (len > 200) {
      const arr1 = [];
      for (let i = 200; i < len; i = i * 2) {
        arr1.push(i);
      }
      if (arr1[arr1.length - 1] < len) {
        arr1.push(arr1[arr1.length - 1] + 200);
      }
    }
  };

  clickRow(row) {
    this.router.navigate(['cloud/user/', row.zoom_user_id]);
  }
}