import { Component, OnInit } from '@angular/core';
import { GetCloudService } from '../../../services/get-cloud.service';
import { Router } from '@angular/router';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-zoom-user-list',
  templateUrl: './zoom-user-list.component.html',
  styleUrls: ['./zoom-user-list.component.scss'],
})
export class ZoomUserListComponent implements OnInit {
  clourUserList: any;
  faIconPhone: any = faPhone;
  constructor(private getCloudDataService: GetCloudService, private router: Router) {}

  ngOnInit() {
    this.fetchApiCloudUsers();
  }

  fetchApiCloudUsers = () => {
    this.getCloudDataService
      .fetchApiCloudUsers({
        list_limit: '30',
        off_set: '0',
        search_key: ' ',
        isImpactedUsers: this.router.url === '/cloud/impactedvipuser',
      })
      .subscribe((data) => {
        this.clourUserList = data.response;
        // console.log('fetchApiCloudUsers: ', data);
      });
  };
}
