<div class="cloud-user-stats">
  <app-cloud-user-live-stats [cloudUserLivestats]="cloudUserLivestats" [isNotButton]="isNotButton">
  </app-cloud-user-live-stats>
  <hr />
  <app-cloud-user-path-detection [cloudUserStats]="cloudUserStats"></app-cloud-user-path-detection>
  <app-cloud-live-call-list [liveQosTrigger]="liveQosTrigger" [liveParticipants]="liveParticipants">
  </app-cloud-live-call-list>
  <hr />
  <app-cloud-user-device-health [cloudUserStats]="cloudUserStats"></app-cloud-user-device-health>
  <hr />
  <app-cloud-user-utilization [cloudUserUtilization]="cloudUserUtilization" [isNotButton]="isNotButton"
    [hasAccess]="hasAccess"></app-cloud-user-utilization>
  <hr />
  <app-cloud-user-notes [notes]="cloudUserStats.notes" [zoom_user_id]="cloudUserStats.zoom_user_id">
  </app-cloud-user-notes>
</div>