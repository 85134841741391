<div class="view-document">
  <div *ngIf="shouldShowLoader" class="view-document__loader">
    <img [src]="loader" alt="" />
  </div>
  <iframe
    [style.height.px]="iframeHeight"
    *ngIf="!isOnlyDownloadable && !shouldShowLoader && !isError"
    [src]="documentUrl | safe: 'resourceUrl'"
  ></iframe>
  <div
    class="view-document__message"
    *ngIf="!shouldShowLoader && isError"
    [style.height.px]="iframeHeight"
  >
    <img [src]="logo" />
    <label>{{ errorMessage }}</label>
  </div>
</div>
