import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTeamsMeetings'
})
export class FilterTeamsMeetingsPipe implements PipeTransform {

  transform(items: any[], searchText: string, paramType: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    searchText = !searchText ? searchText : searchText.toLocaleLowerCase();

    return items.filter((e) => {
      return this.searchKeyPerColumn(e, searchText, paramType);
    });
  }

  searchKeyPerColumn = (items = {}, searchText, paramType) => {
    if (paramType === 'meetings') {
      // console.log(items)
      return (
        items['uuid'].toLocaleLowerCase().includes(searchText) 
        // items['ip_address'].toLocaleLowerCase().includes(searchText) ||
        // items['audio_device_count'].toString().includes(searchText) ||
        // items['cloud_rooms_count'].toString().includes(searchText) ||
        // items['cloud_rooms_count'].toString().includes(searchText) ||
        // items['iot_domotz_count'].toString().includes(searchText) ||
        // items['video_device_count'].toString().includes(searchText)
      );
    }
  }
}
