<div class="video-device-panel">
  <div *ngIf="dataError" style="height: 9cm;">
    <div style="text-align: center; font-size: 225%; background-color: red;">
      Error - no device data
    </div>
    <div style="height: 9cm;"></div>
  </div>

  <div *ngIf="!dataError" class="video-device-panel__panel-container">

    <div class="video-device-panel__panel-header">
      <app-panel-tools [PopupTitle]="PopupTitle"></app-panel-tools>
      <div
        *ngIf="videoDeviceData?.online == 0 && (videoDeviceData?.collector_status == 1 || videoDeviceData?.collector_status == 2)"
        id="printablediv" class="video-device-panel__banner-danger">
        Device is offline. The information below is from the last successful sync at {{ syncTime }}
      </div>

      <div *ngIf=" videoDeviceData?.collector_status==-1" class="video-device-panel__banner-secondary">
        {{ dCollector }} is offline. The information below is from the last successful sync at
        {{ syncTime }}
      </div>

      <div *ngIf="videoDeviceData?.site_paused == 1" class="video-device-panel__banner-secondary">
        The site is paused. The information below is from the last successful sync at {{ syncTime }}
      </div>

      <div *ngIf="videoDeviceData?.device_type == 'cisco' && videoDeviceData?.cisco_paused != '0'"
        class="video-device-panel__banner-secondary">
        Cisco devices are paused. The information below is from the last successful sync at
        {{ syncTime }}
      </div>

      <div *ngIf="videoDeviceData?.device_type == 'polycom' && videoDeviceData?.polycom_paused != '0'"
        class="video-device-panel__banner-secondary">
        Polycom devices are paused.The information below is from the last successful sync at
        {{ syncTime }}
      </div>
    </div>

    <div class="video-device-panel__panel-body">
      <div class="video-device-panel__panel-left">
        <app-video-device-info [videoDeviceData]="videoDeviceData" [videoDeviceType]="videoDeviceType"
          [monitoredPorts]="monitoredPorts" [unmonitoredPorts]="unmonitoredPorts"
          [iotDevicePortsDetails]=iotDevicePortsDetails (videoEvent)="onVideoEvent($event)"></app-video-device-info>
      </div>

      <div class="video-device-panel__panel-right">
        <div class="video-device-panel__health-container">
          <div *ngIf="showWellnessComponent" (click)="showWellnessCheck()" class="incident-button">
            Wellness
          </div>
          <div (click)="openHealthHistory()" class="incident-button">
            Incidents
          </div>
          <app-system-health (click)="openHealthHistory()" [isCursorPointer]="true"
            [healthNum]="videoDeviceData?.paused == 1 ? 0 : videoDeviceData?.health"></app-system-health>
        </div>
        <app-video-device-stats [iotDeviceDetails]="iotDeviceDetails"
          [videoDeviceData]="videoDeviceData"></app-video-device-stats>
      </div>

    </div>

  </div>
</div>