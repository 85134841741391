<div class="row right-panel">
  <div class="col-12">
    <app-panel-tools [PopupTitle]="PopupTitle"></app-panel-tools>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-6 pl-1">
        <app-video-alldevices-info [videoSitesSummary]="videositesInfo"></app-video-alldevices-info>
      </div>
      <div class="col-6">
        <div class="col-12 px-0">
          <app-system-health [healthNum]="videoSitesSummary?.health"></app-system-health>
        </div>
        <app-video-alldevices-stats
          [videoSitesSummary]="videoSitesSummary"
        ></app-video-alldevices-stats>
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-1 filter-table">
        <app-video-alldevices-list
          [videoDevicesListData]="videoDevicesListData"
        ></app-video-alldevices-list>
        <!-- <app-video-alldevices-list
          [videoDevicesListData]="videoDevicesListData"
          [videoListData]="videoDeviceData"
        ></app-video-alldevices-list> -->
      </div>
    </div>

    <div *ngIf="loading" style="position: absolute; top: 50%; z-index: 7; width: 100%;">
      <div class="loader-icon">
        <!-- <img [src]="loader" /> -->
      </div>
    </div>
  </div>
</div>
