import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class CheckDeviceStatusService {
  constructor() {}

  isOnline = (online: number, pause: number, collectorStatus: number) => {
    return online === 1 && pause === 0 && collectorStatus === 1;
  };

  isSleep = (online: number, pause: number, collectorStatus: number) => {
    return online === -1 && pause === 0 && collectorStatus === 1;
  };

  isOffline = (online: number, pause: number, collectorStatus: number) => {
    return online === 0 && pause === 0 && collectorStatus === 1;
  };

  isCollectorOffline = (collectorStatus: number) => {
    return collectorStatus === -1;
  };

  isPaused = (pause: number, collectorStatus: number) => {
    return pause === 1 && collectorStatus === 1;
  };
}
