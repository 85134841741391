<div class="agent_table">
  <input #iotInputText2 (keyup)="searchData(iotInputText2.value)" placeholder="Search..." class="input-class" />

  <div class="mat-elevation-z8 child mat-table">
    <table class="data-table" mat-table [dataSource]="dataSource" matSort>
      <ng-container *ngIf="isShowCompanyName()" matColumnDef="company_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Company</strong>
        </th>
        <td mat-cell *matCellDef="let row">{{ row.company_name | displayEmpty }}</td>
      </ng-container>
      <ng-container matColumnDef="agent_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><strong>Agent</strong></th>
        <td mat-cell *matCellDef="let row" (click)="goToAgentDevices(row.agent_id, row.agent_name)">
          {{ row.agent_name | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <img src="{{ row.status === 'ONLINE' ? greenIcon : redIcon }}" />
        </td>
      </ng-container>

      <ng-container matColumnDef="ip">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><strong>IP</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.ip | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="mac">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><strong>MAC</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.mac | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="device_count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><strong>Devices</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.device_count | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="impacted_devices">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><strong>Impacted</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.impacted_devices | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="avg_mbps_down">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><strong>Mbps Download</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.avg_mbps_down | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="avg_mbps_up">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><strong>Mbps Upload</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.avg_mbps_up | displayEmpty }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [colSpan]="displayedColumns.length">No data found in table</td>
      </tr>
    </table>
  </div>
</div>
