import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { AuthService } from 'src/app/services/auth.service'

@Component({
	selector: 'app-logitech-alert-panel',
	templateUrl: './logitech-alert-panel.component.html',
	styleUrls: ['./logitech-alert-panel.component.scss'],
})
export class LogitechAlertPanelComponent implements OnInit, OnChanges {
	@Input() data: any

	logitech_data = null

	offline = true
	packetloss = true
	ports = true
	tap = true
	remote = true

	setTimer: any

	showSucessMessage: any = false

	constructor(private api: AuthService) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.data && this.data) {
			this.setData()
		}
	}

	setData = () => {
		this.logitech_data = this.data?.logitech_data || null

		const alerts = this.logitech_data?.alerts || null

		this.offline = alerts?.offline === undefined ? true : Boolean(alerts.offline)
		this.packetloss = alerts?.packetloss === undefined ? true : Boolean(alerts.packetloss)
		this.ports = alerts?.ports === undefined ? true : Boolean(alerts.ports)
		this.tap = alerts?.tap === undefined ? true : Boolean(alerts.tap)
		this.remote = alerts?.remote === undefined ? true : Boolean(alerts.remote)

		console.log({ logitech_data: this.logitech_data, alerts })
	}

	setAlert = flag => {
		let params = {
			offline: 1,
			packetloss: 1,
			ports: 1,
			tap: 1,
			remote: 1,
		}

		if (flag) {
			params = {
				offline: +this.offline,
				packetloss: +this.packetloss,
				ports: +this.ports,
				tap: +this.tap,
				remote: +this.remote,
			}
		}

		const { video_device_id } = this.data

		this.api.setLogitechAlerts(params, video_device_id).subscribe(e => {
			this.showSucessMessage = true
			this.setTimer = setTimeout(() => {
				this.showSucessMessage = false
			}, 1500)
		})
	}

	clearTimeout = () => {
		clearTimeout(this.setTimer)
	}
}
