<div class="link-desktop">
  <div class="link-desktop__header">
    <div>
      {{ getModalTitle() }}
    </div>
    <div (click)="closeDialog()" class="link-desktop__header-close-button">
      <img src="./assets/img/iot/delete-btn.png" alt="" />
    </div>
  </div>

  <div *ngIf="!showLinkClientUser && !pluginEnabled && desktopPluginId === null" class="link-desktop__body">
    <div class="link-desktop__search">
      <div class="link-desktop__icon">
        <i class="fa fa-search"></i>
      </div>
      <input type="text" class="search-default" type="text" placeholder="" [(ngModel)]="searchKey" />
    </div>
    <div id="scroll-bar" style="overflow: auto; overflow-x: hidden; height: 280px;">
      <table class="table">
        <thead>
          <tr class="black">
            <th scope="col">HOST NAME</th>
            <th scope="col" style="width: 50px;">SELECT {{dPlugin.toUpperCase()}}</th>
          </tr>
        </thead>
        <tbody>
          <tr scope="row" *ngFor="let item of modalList | mainMenuFilter: searchKey:'linkDialogHost'">
            <td>
              {{getHostName(item.host_info)}}
            </td>
            <td>
              <div (click)="openLinkClient(item)" class="link-desktop__body-add-button">
                <img src="./assets/img/vs_img/dialog_add_button.png" alt="" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="showLinkClientUser && !pluginEnabled" class="link-desktop__body link-desktop__body-2">
    <div class="link-desktop__image">
      <img *ngIf="!isLoading" src="../../../../assets/img/vs_img/delete-icon.png" alt="" />
      <img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
    </div>
    <div class="link-desktop__text-button-container">
      <div *ngIf="!doneAction" class="">
        Link <strong>{{ clientToLink }}</strong> to <br />
        Host: <strong>{{ hostName }}</strong> ?
      </div>
      <div *ngIf="doneAction" class="">
        <strong>{{actionResultMessage}}</strong>
      </div>
      <div *ngIf="!doneAction" class="link-desktop__button-container">
        <div class="link-desktop__button-item link-desktop__button-item--yes" (click)="linkDesktopPlugin()">
          Yes, Link Them!
        </div>
        <div class="link-desktop__button-item link-desktop__button-item--cancel" (click)="closeLinkClient()">
          X Cancel
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="pluginEnabled && desktopPluginId !== null" class="link-desktop__body link-desktop__body-2">
    <div class="link-desktop__image">
      <img *ngIf="!isLoading" src="../../../../assets/img/vs_img/delete-icon.png" alt="" />
      <img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
    </div>
    <div class="link-desktop__text-button-container">
      <div *ngIf="!doneAction" class="">
        Un-Link <strong>{{ clientToLink }}</strong> from <br />
        Host: <strong>{{ hostName }}</strong>?
        <br />
        <br />
        This can take up to 2 minutes to complete in the background.
        <br />
      </div>
      <div *ngIf="doneAction" class="">
        <strong>{{actionResultMessage}}</strong>
      </div>
      <div *ngIf="!doneAction" class="link-desktop__button-container">
        <div class="link-desktop__button-item link-desktop__button-item--yes-unlink" (click)="unlinkDesktopPlugin()">
          Yes, Un-Link Them!
        </div>
        <div class="link-desktop__button-item link-desktop__button-item--cancel-unlink" (click)="closeDialog()">
          X Cancel
        </div>
      </div>
    </div>
  </div>
</div>