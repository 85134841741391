<div class="panel-tools" id="printablediv">

	<div class="panel-tools__title-and-print-button">
		<div [ngClass]="{
           'panel-tools__title': collectorPanelStyle,
           'panel-tools__title-2': !collectorPanelStyle
		}">
			<h2>{{ PopupTitle }}</h2>
		</div>


		<div class="panel-tools__collector-button-and-print-button">
			<div class="panel-tools__collector-update" *ngIf="isCollectorUpdate">
				<div class="panel-tools__schedule pr-3" *ngIf="isSchedule">
					<input type="datetime-local" class="form-control datetime" [min]="minDate" (change)="setScheduleDate($event)" />
					<span class="btn panel-tools__submit ml-1 mr-1"
						[ngClass]="!scheduleDate || (!isReschedule&&!isAvailable)?'disable':''" (click)="submit()">Submit</span>
					<span class="btn panel-tools__cancel" (click)="cancel()">Cancel</span>
				</div>
				<div *ngIf="loading">
					<img [src]="loader" style="height: 40px; width: 40px;" />
				</div>
				<div ngbDropdown class="dropdown mr-3">
					<button style="width: 170px;" type="button" class="btn dd-menu" id="dropdownBasic1" ngbDropdownToggle>
						Collector Update <span class="dropdown-arrow"></span>
					</button>
					<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
						<strong class="pl-2" style="font-size: 0.75rem;">Version {{collectorLatestVersion}} Now Available</strong>
						<span ngbDropdownItem class="d-flex align-items-center dd-item" [ngClass]="!isAvailable?'disable':''"
							(click)="latestCollector(false)"><img src="assets/img/sync-now.svg" class="dropdown-icon mr-2" />Update
							Now</span>
						<span ngbDropdownItem class="d-flex align-items-center dd-item" (click)="scheduleNow()"
							[ngClass]="!isAvailable?'disable':''"><i class="fa fa-calendar dropdown-icon mr-2"></i>Schedule
							Now</span>
					</div>
				</div>
			</div>


			<div class="doNotPrint" [ngClass]="{
           'panel-tools__printer': collectorPanelStyle,
           'panel-tools__printer-2': !collectorPanelStyle
		}">

				<i class="fa fa-print fa-2x " (click)="printDiv('rt-panel')"></i>
			</div>



		</div>



	</div>


</div>