import { Subscription } from 'rxjs'

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { GetIotService } from '../../services/get-iot.service'
import { PopupTypeService } from '../../services/popupType.service'
import { RefreshApiService } from '../../services/refresh-api.service'
import { environment } from 'src/environments/environment'
import moment from 'moment-timezone'

@Component({
  selector: 'app-iot-device-panel',
  templateUrl: './iot-device-panel.component.html',
  styleUrls: ['./iot-device-panel.component.scss'],
})
export class IotDevicePanelComponent implements OnInit, OnDestroy {
  @Output() eventEmitter: EventEmitter<any> = new EventEmitter<any>();

  iotDeviceDetails: any;
  iotDeviceName: String = '--';
  routeId: any;
  filterListner: Subscription;
  refreshData: Boolean;
  isInitialLoadingDone: Boolean;
  syncTime: string = '';
  dCollector: String = 'Agent';
  constructor(
    private route: ActivatedRoute,
    private getIot: GetIotService,
    private refreshApiService: RefreshApiService,
    public ds: PopupTypeService
  ) {
    this.isInitialLoadingDone = false;
    this.route.params.subscribe((params) => {
      this.routeId = params['id'];
    });

    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if (m) {
        this.refreshData = m;
        this.getApiData();
      }
    });
    this.getIot.deviceLastSync.subscribe((x)=>{
      this.syncTime = moment(x?.timestamp || this.iotDeviceDetails?.uptime)
          .utc()
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format("MM-DD-YYYY hh:mma");
    })
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  getApiData() {
    if (this.refreshData) {
      this.getIotDeviceDetails();
    }
  }

  ngOnInit() {
    this.getIotDeviceDetails();
  }

  getIotDeviceDetails = () => {
    this.getIot.getIotDeviceDetails(this.routeId).subscribe((data) => {
      this.iotDeviceDetails = data;
      // console.log('this.iotDeviceDetails: ', this.iotDeviceDetails)
      if(this.iotDeviceDetails){
        this.syncTime = moment(this.iotDeviceDetails.updated_at)
          .utc()
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format("MM-DD-YYYY hh:mma");
      }
      this.setData();
      if (!this.isInitialLoadingDone) {
        this.isInitialLoadingDone = true;
      }
    });
  };

  setData = () => {
    this.iotDeviceName = 'IoT - ' + this.iotDeviceDetails.display_name + ' - Location';
  };



  openIotDeviceHealthHistory = () => {
    const data = {
      iot_device_id: this.iotDeviceDetails.iot_device_id,
      agent_id: this.iotDeviceDetails.agent_id,
      account_url: this.iotDeviceDetails.account_url,
      api_key: this.iotDeviceDetails.api_key,
    };
    this.ds.sendPopupType(62, {
      data,
    });
  };

  ngOnChange($event) {
    if ($event === 'callGetIotDeviceDetails') {
      this.getIotDeviceDetails();
    }
  }
}
