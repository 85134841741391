<div class="video-touch-panel-container">
    <h5>Touch Control</h5>
    <div class="video-touch-image-and-text">
        <img src="{{ touchIcon }}" alt="polycom_touch">
        <div class="video-touch-text">
            <div>
                <h6>IP Address</h6>
                <p>{{pairingsData.location}}</p>
            </div>
            <div>
                <h6>Firmware</h6>
                <p>{{pairingsData.client_id}}</p>
            </div>
        </div>
    </div>
</div>