<div class="latestevent">
    <div class="latestevent__title_btn_ctr">
        <div class="latestevent__title">
            <h5>30 Days Event List</h5>
        </div>
        <div class="latestevent__btn " (click)="openIotDeviceHealthHistory()">
            View More
        </div>
    </div>

    <div class="latestevent__box">
        <div *ngFor="let item of iotIncidentList">
            <div class="latestevent__box_item ">
                <div class="latestevent__box_item_ctr">
                    <div class="latestevent__box_item_title ">
                        Event ID: {{item.incident_id}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>