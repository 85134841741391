import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetDocumentCenterService } from '../../services/get-document-center.service';
import { MembersTypeService } from '../../services/membersType.service';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentCenterDialogComponent } from '../dialogs/document-center-dialog/document-center-dialog.component';
import { environment } from '../../../environments/environment';
import * as moment from 'moment-timezone';
import { AuthService } from 'src/app/services/auth.service'
import { RootList } from '../documents/documents.component'

export interface DocumentListInterface {
  document_id: number;
  device_id: number;
  quadrant_group: any;
  file_type: any;
  file_name: any;
  file_size: any;
  description: any;
  file_path: any;
  date_created: any;
  share_expiration: any;
  is_shared: any;
}

@Component({
  selector: 'app-company-documents',
  templateUrl: './company-documents.component.html',
  styleUrls: ['./company-documents.component.scss'],
})
export class CompanyDocumentsComponent implements OnInit {
  documentList: any = [];
  totalDocumentSize: number;
  storageLimit: number;
  shouldShowLoader: boolean = true;
  searchKey: any;
  totalFolders: number = 0;
  loader: String = environment.config.dynamicImage.loader;
  displayMode: boolean = false;
  root_id: number = 0;
  docPath: string = environment.serverUrl + '/api/v1/files/get-file/';
  foldersPath: RootList[] = [];
  searchDocumentList: any[] = [];
  isFiles: boolean = true;
  displayedColumns: any[] = [
    'file_type',
    'file_name',
    'file_size',
    'description',
    'date_created',
    'is_shared',
    'share_expiration',
    'document_delete',
  ];
  dataSource: MatTableDataSource<DocumentListInterface>;
  fileCount: number = 0;
  availableStorage: number = 0;

  emptyDocument: false;

  constructor(
    private ds: MembersTypeService,
    private getDocumentCenter: GetDocumentCenterService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.setData();
  }

  setData = () => {
    this.getDocumentsByCompany();
  };
  onChange(status) {
    this.isFiles = status;
    this.root_id = 0;
    this.foldersPath = [];
    this.getDocumentsByCompany();
  }

  // convertTimeStamp = (time) => (time === null ? '--' : moment(time).format('l, hh:mm a'));

  getDocumentsByCompany = () => {
    this.documentList = [];
    this.shouldShowLoader = true;
    const body = {
      root_id: this.root_id,
      isFiles: this.isFiles,
      offset: 0
    }
    this.getDocumentCenter.getDocumentsByCompany(body).subscribe((data: any) => {
      const { document_list, total_document_size, document_center_storage_limit } = data;
      // const totalFileSize = data.document_list.reduce((total, document) => total + document.file_size, 0);
      this.countFolders(document_list);
      this.totalDocumentSize = total_document_size; // function conversion
      this.documentList = document_list.map((d) => {
        const isNotExpired = moment(d.share_expiration).isAfter(moment());
        let isShared = 'No';
        if (isNotExpired) {
          isShared = d.shared_by_user_id !== null ? 'Yes' : 'No';
        }
        return {
          ...d,
          is_shared: isShared,
        };
      });
      this.dataSource = new MatTableDataSource(this.documentList);
       this.storageLimit = document_center_storage_limit* 1000000000;
      this.searchDocumentList = [...this.documentList];
      this.availableStorage = this.storageLimit  - this.totalDocumentSize;
      this.fileCount = this.documentList.reduce((pV, cV) => cV.file_type != 'folder' ? pV + 1 : pV + 0,0);
      this.shouldShowLoader = false;
    }, (err) => {
      // console.log(err)
      this.shouldShowLoader = false;

    });
  };

  objectEmitter = (params: any) => {
    if (params === 'getDocumentsByDevice') {
      this.getDocumentsByCompany();
    }
  };

  openDocumentDialog = (items, type) => {
    const config = {
      documentData: items,
      type,
      objectEmitter: (params) => {
        this.objectEmitter(params);
      },
    };
    const webportal = this.dialog.open(DocumentCenterDialogComponent, {
      panelClass: 'custom-dialog-container-no-border-radius',
      data: {
        config,
      },
    });
  };

  getLink = (deviceId, quadrantGroup) => {
    let link = null;

    switch (quadrantGroup) {
      case 'VIDEO':
        link = `video/device/${deviceId}`;
        break;
      case 'AUDIO':
        link = `audio/device/${deviceId}`;
        break;
      case 'DOMOTZ_IOT':
        link = `iot/device/${deviceId}`;
        break;
      case 'CLOUD':
        link = `cloud/room/${deviceId}`;
        break;
    }
    return link;
  };

  searchData = (key) => {
    if (this.displayMode) {
      if (!key.trim()) {
        this.dataSource.data = [...this.searchDocumentList];
      }
      this.dataSource.data = this.searchDocumentList.filter((x) => {
        return Object.values(x).some((val) => {
          return (typeof val == 'string') ? val.trim().toLocaleLowerCase().includes(key.trim().toLowerCase()): val === key;
        });
      })
    }
    else {
      this.dataSource.filter = key.trim().toLowerCase(); 
    }
  };

  close = () => {
    this.ds.sendMemberType(99);
  };
  openFolder(row) {
    this.root_id = row.document_id;
    this.addingPath(row.document_id, row.file_name);
    this.getDocumentsByCompany();
  }
  addingPath(document_id,file_name) {
    const data = this.foldersPath.find((x) => x.document_id == document_id && x.file_name == file_name);
    if (!data) this.foldersPath.push({
      document_id: document_id,
      file_name:file_name
    })
  }
  removePath() {
    this.foldersPath.pop();
    this.root_id = this.foldersPath.length > 0 ? this.foldersPath[this.foldersPath.length - 1].document_id : 0;
    this.getDocumentsByCompany();
  }
  onDisplayModeChange(mode: boolean): void {
    this.displayMode = mode;
  }
  countFolders(data:DocumentListInterface[]) {
    if (data && data.length && !this.isFiles) {
      this.totalFolders = data.reduce((pV, cV) => {
        return cV.file_type == 'folder' ? pV + 1 : pV + 0
      }, 0)
    }
    else this.totalFolders = 0;
  }
}
