<div *ngIf="showCongrats == 1" class="tut-congrats tut-show">
  congratulations! you have added your first device or service for monitoring. now select the
  quadrant icon to explore visibilityone.
</div>
<div class="logo-print">
  <img [src]="dashboardLogo" alt="Title" style="height: 2.5rem;" alt="Visiblity One" />
</div>
<div class="main-menu-ctr py-3">
  <div *ngIf="showCongrats == 1" class="nav-img-tut tut-congrats-btn tut-show" (click)="mainmenuState = 'open'"
    (click)="closeOverlay(0)" (click)="setTutorialapi(0)" style="cursor: pointer;"></div>

  <div class="nav-ctr">
    <div class="logo">
      <a routerLink="/" class="">
        <img [src]="dashboardLogo" alt="Title" style="height: 2.5rem;" alt="Visiblity One" />
      </a>
    </div>
    <search-bar #searchBar [ngClass]="{'disabled-btn': Auth?.isTrialExpired}"></search-bar>
    <!-- <div *ngIf="showUpdateCenter" class="new-schedule-update clickable" (click)="viewCollectors()">
      <div>
        New Scheduled Update
      </div>
    </div> -->
    <!-- <ng-container *ngIf="companyInfoDetails?.status == 'TRIAL'">
      <div class="trial-menu-container">
        <span *ngIf="!shouldShowAccessLevel && trialEndDay < 0" class="trial-header clickable">Trial expired</span>
        <span *ngIf="!shouldShowAccessLevel && trialEndDay >= 0" class="trial-header clickable">{{ trialEndDay }} days
          before trial expires</span>
      </div>
    </ng-container> -->

    <div *ngIf="shouldShowAccessLevel" class="trial-header">
      {{ loginType === 'superAdmin' ? 'Super Admin' : 'Integrator View' }}
    </div>

    <!-- <div>
      <button (click)="axeTest()">
        DNA!
      </button>
    </div> -->

    <!-- <div *ngIf="collectorStats" id="collector-stats">
      Collectors <span class="alert pointer" [class]="collectorStats?.total === 0? 'border border-grey' : 'bg-grey text-white'" (click)="onClickCollectorStat('all')">{{ collectorStats?.total }}</span> :
      <span class="alert pointer" [class]="collectorStats?.healthy === 0? 'border border-green' : 'bg-green text-white'" (click)="onClickCollectorStat('healthy')">{{ collectorStats?.healthy }}</span>
      <span class="alert pointer mx-1" [class]="collectorStats?.warning === 0? 'border border-yellow' : 'bg-yellow text-white'" (click)="onClickCollectorStat('warning')">{{ collectorStats?.warning }}</span>
      <span class="alert pointer" [class]="collectorStats?.critical === 0? 'border border-red' : 'bg-red text-white'" (click)="onClickCollectorStat('impacted')">{{ collectorStats?.critical }}</span>
    </div> -->

    <div *ngIf="collectorStats" id="collector-stats" [ngClass]="{'disabled-btn': Auth?.isTrialExpired}">
      <h5 class="d-inline">Collectors </h5>
      <span class="pointer border border-grey" [ngClass]="{ 'bg-grey text-white': collectorStats?.total !== 0}"
        (click)="onClickCollectorStat('all')">{{ collectorStats?.total }}</span> :
      <span class="pointer border border-green" [ngClass]="{'bg-green text-white': collectorStats?.healthy !== 0}"
        (click)="onClickCollectorStat('healthy')">{{ collectorStats?.healthy }}</span>
      <span class="pointer border border-yellow mx-1"
        [ngClass]="{'bg-yellow text-white': collectorStats?.warning !== 0}" (click)="onClickCollectorStat('warning')">{{
        collectorStats?.warning }}</span>
      <span class="pointer border border-red" [ngClass]="{'bg-red text-white': collectorStats?.critical !== 0}"
        (click)="onClickCollectorStat('impacted')">{{ collectorStats?.critical }}</span>
      <span class="pointer border border-blue mx-1" [ngClass]="{'bg-blue text-white': collectorStats?.paused !== 0}"
        (click)="onClickCollectorStat('paused')">{{ collectorStats?.paused }}</span>
    </div>
    <div class="position-relative" [ngClass]="{'disabled-btn': Auth?.isTrialExpired}">
      <i class="fa fa-bell-o fa-lg text-secondary pointer" (click)="onClickBellIcon()">
        <span class="badge badge-pill badge-danger" *ngIf="newNotificationsCount">{{ newNotificationsCount }}</span>
      </i>
    </div>

    <div class="nav-img-menu">
      <img [src]="companyLogo" />
      <!-- <h2>Hello {{ this.Auth.firstName }}</h2> -->
      <!-- <div [ngStyle]="mainmenuState !== 'open' ? icon_menu : icon_menu_pressed" (click)="mainmenuState = 'open'"></div> -->
    </div>
  </div>
  <!-- <app-collector-offline-notifications (click)="viewCollectors()" class="clickable">
  </app-collector-offline-notifications> -->

  <!-- <div class="main-container" [ngStyle]="{
      opacity: mainmenuState == 'open' ? 0 : 0,
      'pointer-events': mainmenuState == 'open' ? 'auto' : 'none'
    }" (click)="mainmenuState = 'close'" (click)="membersPopupState = 'close'" (click)="sendMemberType(99)"
    [@mainMenuPopup]="mainmenuState"></div> -->
  <div class="main-container" [ngStyle]="{
      opacity: 0,
      'pointer-events': membersPopupState == 'open' ? 'auto' : 'none'
    }" (click)="membersPopupState = 'close'; sendMemberType(0)" [@popupOpenClose]="membersPopupState"></div>

  <!-- <div class="main-menu-pop text-center" [@mainMenuPopup]="mainmenuState"> -->
  <div class="main-menu-pop text-center">
    <div id="menu-overlay" class="tut-show" *ngIf="runTutorial == 1"></div>
    <ul>
      <li class="d-flex align-items-center mt-2" [ngClass]="{'disabled-btn': Auth?.isTrialExpired}" *ngIf="!this.shouldShowAccessLevel" id="account" (click)="activeMenuSet(1)" (click)="sendMemberType(11)"
            (click)="ds2.sendPopupType(0)" (click)="membersPopupState = 'close'" [class.activeMenu]="activeMenu == 1">
        <img [src]="Auth.profilePicture?Auth.profilePicture:'assets/img/no-image.jpg'" class="rounded-circle mr-3" alt="...">
        <i class="fa fa-bars mr-3"></i>
        <h1 class="p-0 m-0">Hello {{ this.Auth.firstName }}</h1>
      </li>
      <li [ngClass]="{'disabled-btn': Auth?.isTrialExpired}">
        <h1>
          <div *ngIf="!this.shouldShowAccessLevel" id="membership" (click)="activeMenuSet(2)"
            (click)="membersPopupState = 'open'" (click)="sendMemberType(0)" [class.activeMenu]="activeMenu == 2">
            Membership & Subscriptions
          </div>
        </h1>
      </li>
      <li [ngClass]="{'disabled-btn': Auth?.isTrialExpired}">
        <h1>
          <div *ngIf="!this.shouldShowAccessLevel" id="documents" (click)="viewDocumentCenter()"
            [class.activeMenu]="activeMenu == 6">
            Document Center
          </div>
        </h1>
      </li>
      <li *ngIf="!isVcare" [ngClass]="{'disabled-btn': Auth?.isTrialExpired}">
        <h1>
          <div *ngIf="!this.shouldShowAccessLevel" id="report" (click)="activeMenuSet(3)" (click)="sendMemberType(13)"
            (click)="ds2.sendPopupType(0)" (click)="membersPopupState = 'close'" [class.activeMenu]="activeMenu == 3">
            Reports
          </div>
        </h1>
      </li>
      <li [ngClass]="{'disabled-btn': Auth?.isTrialExpired}">
        <h1>
          <div *ngIf="!this.shouldShowAccessLevel" id="support" (click)="activeMenuSet(4)"
            (click)="membersPopupState = 'close'" (click)="ds2.sendPopupType(0)" (click)="sendMemberType(10)"
            [class.activeMenu]="activeMenu == 4">
            Support
          </div>
        </h1>
      </li>
      <li>
        <h1>
          <div id="logout" (click)="activeMenuSet(5)" (click)="membersPopupState = 'close'"
            (click)="ds2.sendPopupType(0)" (click)="sendMemberType(99)" [class.activeMenu]="activeMenu == 5"
            (click)="Logout()">
            Logout
          </div>
        </h1>
      </li>
    </ul>
  </div>

  <div class="members-pop text-center" [@popupOpenClose]="membersPopupState">
    <div id="members-overlay" class="tut-show" *ngIf="runTutorial == 1"></div>
    <ul>
      <li>
        <div id="VisiblityOne" (click)="sendMemberType(9)">
          <img class="cursor-pointer" [src]="membershipSubscriptionLogo" alt="Visiblity One"
            [class.active]="activePanel == 9" style="width: 165px;" />
        </div>
      </li>
      <li>
        <div id="polycom" (click)="sendMemberType(1)" [ngClass]="{ 'overlayMembers2': overLayMembersMove == 1 }">
          <img src="./assets/img/main-menu/poly_logo.png" alt="Polycom" [class.active]="activePanel == 1" />
        </div>
      </li>
      <li>
        <div id="cisco" (click)="sendMemberType(2)" [ngClass]="{ 'overlayMembers2': overLayMembersMove == 1 }">
          <img src="./assets/img/main-menu/cisco_logo.png" alt="Cisco" style="width: auto; height: 40px;"
            [class.active]="activePanel == 2" />
        </div>
      </li>
      <li>
        <div id="logitech" (click)="sendMemberType(36)" [ngClass]="{ 'overlayMembers2': overLayMembersMove == 1 }">
          <img src="./assets/img/main-menu/logitech.png" alt="Logitech" style="width: auto; height: 40px;"
            [class.active]="activePanel == 36" />
        </div>
      </li>
      <li>
        <div id="zoom" (click)="sendMemberType(3)">
          <img src="./assets/img/main-menu/zoom_logo.png" alt="Zoom" [class.active]="activePanel == 3" />
        </div>
      </li>

      <li
        *ngIf="isShowDomotzIntegration && (companyInfoDetails?.is_msp_domotz || !companyInfoDetails?.has_selected_iot_not_domotz)">
        <div (click)="sendMemberType(33)">
          <img src="{{ mainMenuIotLogo }}" [class.active]="activePanel == 33" />
        </div>
      </li>

      <li
        *ngIf="!companyInfoDetails?.is_msp_domotz && (companyInfoDetails?.has_selected_iot_not_domotz === null || companyInfoDetails?.has_selected_iot_not_domotz === 1)">
        <div (click)="sendMemberType(35)">
          <img src="./assets/img/vs_img/iotLogo.png" [class.active]="activePanel == 35" />
        </div>
      </li>

      <li *ngIf="isVcare">
        <div id="yeah" (click)="sendMemberType('yeahlink')">
          <img src="./assets/img/main-menu/yealink.png" alt="yeahlink" [class.active]="activePanel == 11" />
        </div>
      </li>

      <li>
        <div id="teams" (click)="sendMemberType(6)">
          <img src="./assets/img/main-menu/teams.png" alt="jabra" [class.active]="activePanel == 6" />
        </div>
      </li>

      <!-- <li>
        <div id="slack" (click)="sendMemberType(7)">
          <img src="./assets/img/slack_logo.png@1X.png" alt="slack" [class.active]="activePanel == 7" />
        </div>
      </li> -->
    </ul>
  </div>
</div>