<div class="audio_all_device">
  <!-- Search Capability -->
  <input #searchAudioAllDevice (keyup)="applyFilter(searchAudioAllDevice.value)" placeholder="Search..." />
  <!-- End of Search Capability -->
  <div class="mat-elevation-z8 mat-table">
    <table class="data_table" mat-table [dataSource]="audioDevicesListData" matSort>
      <ng-container *ngIf="this.Auth.accessLevel == 'SUPERADMIN'" matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Company</strong>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.company_name }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Name</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <span (click)="clickRow(element)" class="clickable">
            {{ element.room_name }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>User</strong>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.sip_info?.user }}</td>
      </ng-container>
      <ng-container matColumnDef="health">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Health</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <app-table-health-icon [value]="element.health" [paused]="element.paused"></app-table-health-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="qos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Qos</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <app-table-qos-icon [value]="element.qos"> </app-table-qos-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Site</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.site_name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="oncall">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>On Call</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <app-table-oncall-icon [value]="element.on_call"></app-table-oncall-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="model">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Model</strong>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.model }}</td>
      </ng-container>

      <ng-container matColumnDef="ip">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>IP Address</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <span (click)="openRemoteIp(element.ipv4, element.mac)" class="clickable">
            <app-table-cog-icon [value]="element.ipv4"></app-table-cog-icon>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [colSpan]="displayedColumns.length">No data found in table</td>
      </tr>
    </table>
  </div>
</div>