import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core'
import { GetIotService } from '../../services/get-iot.service'
import * as moment from 'moment-timezone'
import { RefreshApiService } from '../../services/refresh-api.service'
import { Subscription } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { IncidentMessagesComponent } from 'src/app/popups/dialog/incident-messages/incident-messages.component'
import { IncidentType } from 'src/app/services/incident-messages.service'

@Component({
	selector: 'app-iot-lite-device-health-history',
	templateUrl: './iot-lite-device-health-history.component.html',
	styleUrls: ['./iot-lite-device-health-history.component.scss'],
})
export class IotLiteDeviceHealthHistoryComponent implements OnInit {
	@Input() data: any
	@Input() popupState: any
	iotIncidentList: any
	shouldShowLoaderBtn: any = false
	shouldShowTable: any = true
	incidentId: any = ''
	iotDeviceId: any = ''
	iotName: any
	filterListner: Subscription
	incidentDays: any = ''
	constructor(private GetIot: GetIotService, private refreshApiService: RefreshApiService, private dialog: MatDialog) {
		this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
			// this.getIotIncidentList();
		})
	}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.data && this.data) {
			const { iot_id, iot_name, days } = this.data;
			this.iotName = iot_name
			this.iotDeviceId = iot_id
			this.incidentDays = days
			this.getIotIncidentList()
		}
	}

	getIotIncidentList = () => {
		this.shouldShowLoaderBtn = true

		this.GetIot.getIotLiteIncidents(this.iotDeviceId, this.incidentDays).subscribe(
			(data: any) => {
				this.shouldShowLoaderBtn = false

				const res = data.response

				this.iotIncidentList = res.map(i => {
					const events = JSON.parse(i.events)
					const event_details = []

					for (const event in events) {
						event_details.push({
							trigger: this.translateTrigger(event, events[event]),
							pulls: events[event].pulls,
							// last_pull: this.printTime(events[event].last_pull),
							// end_date: this.printTime(events[event].end_date),
							// start_date: this.printTime(events[event].start_date),
							last_pull: events[event].last_pull,
							end_date: events[event].end_date,
							start_date: events[event].start_date,
						})
					}

					const incident = {
						incident_id: i.incident_id,
						actioned: i.actioned,
						// start_time: this.printTime(i.start_time),
						// end_time: this.printTime(i.end_time),
						start_time: i.start_time,
						end_time: i.end_time,
						event_details: this.removeEmpty(event_details),
					}

					return incident
				})
			},
			err => {}
		)
	}

	removeEmpty = arr => arr.filter(i => i.trigger)

	// printTime = time => {
	// 	if (time == null || !time) {
	// 		return '--'
	// 	} else {
	// 		return moment(time).format('l, hh:mm a')
	// 	}
	// }

	printTable = flag => {
		const elementId = flag ? 'table_1' : 'table_2'
		const contents = document.getElementById(elementId).innerHTML
		const frame1 = document.createElement('iframe')
		frame1.name = 'frame1'
		frame1.style.position = 'absolute'
		frame1.style.top = '-1000000px'
		document.body.appendChild(frame1)
		const frameDoc = frame1.contentWindow
		frameDoc.document.open()
		frameDoc.document.write(`
        <html>
        <head>
              <title>Print Report</title>
              <style>
              h1 {
                margin-left: 20px;
              }
              .table-1 {
                width: 100%;
                margin: 20px auto;
              }
              table tr td {
                padding: 5px;
                width: 200px;
                text-align: center;
              }
              </style>
            </head>
          <body onload="window.print(); window.close()">
          ${contents}
          </body>
        </html>
      `)
		setTimeout(function () {
			window.frames['frame1'].focus()
			window.frames['frame1'].print()
			document.body.removeChild(frame1)
		}, 500)
		return false
	}

	translateTrigger(trigger: string, event: any) {
		const space_message = event?.space_message || ''
		if (trigger === 'packetloss') return event.message
		if (trigger !== 'iot_component_offline') return `IoT ${this.iotName} ${space_message} ${trigger} is down.`
		return
	}

	onShowMessages = (incidentId, idx) => {
		const dialogRef = this.dialog.open(IncidentMessagesComponent, {
			panelClass: 'custom-dialog-container',
			data: {
				incidentId,
				incidentType: IncidentType.DOMOTZ_IOT,
				// showButton: true,
			},
			// height: '80%',
			// width: '60%',
			// minHeight: 'calc(100vh - 90px)',
			width: '80%',
			height: 'auto',
		})

		dialogRef.afterClosed().subscribe(isActioned => {
			if (isActioned) {
				this.iotIncidentList[idx].actioned = 1
			}
		})
	}
	getSpaceName(spaces){
    if(spaces){
      const space_name =  JSON.parse(spaces).map((x)=>x.space_name);
			return `in ${space_name}`
    }
    return ""
  }
}
