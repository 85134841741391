<div class="container" id="main-frm">
  <form id="support-frm" name="siteForm" [formGroup]="siteForm" (ngSubmit)="onSubmit()">
    <ng-container *ngIf="!sentRequest">
      <h5 class="m-4 text-center">
        Please visit our <a href="{{ links.faq }}" target="_blank">FAQS</a> for troubleshooting
        tips.
      </h5>
      <h5>
        We’re here to help! Please fill out the form and we’ll contact you as soon as possible.
      </h5>
      <h5 *ngIf="companyInfoData">Company: {{ companyInfoData[0]?.company_name }}</h5>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <select
              [(ngModel)]="selectSite"
              formControlName="selectSite"
              class="form-control"
              id="selectSite"
              required
            >
              <option value="" disabled>Select Site</option>
              <option *ngFor="let site of allSiteListData; index as n" value="{{ site.site_name }}">
                {{ site.site_name }}
              </option>
            </select>
            <div *ngIf="submitted && f.selectSite.errors" class="invalid-feedback">
              <div *ngIf="f.selectSite.errors.required" id="confirmMessage">!</div>
            </div>
          </div>
          <div class="form-group">
            <select
              [(ngModel)]="issueType"
              formControlName="issueType"
              class="form-control"
              id="issueType"
              required
            >
              <option value="" disabled>Issue type</option>
              <option value="Video">Video</option>
              <option value="Audio">Audio</option>
              <option value="Cloud">Cloud</option>
            </select>
            <div *ngIf="submitted && f.issueType.errors" class="invalid-feedback">
              <div *ngIf="f.issueType.errors.required" id="confirmMessage">!</div>
            </div>
          </div>
          <div class="form-group">
            <select
              [(ngModel)]="siteContact"
              formControlName="siteContact"
              class="form-control bg-silver"
              id="siteContact"
              required
            >
              <option value="" disabled>Site contact</option>
              <option
                *ngFor="let contact of companyContactList; index as n"
                value="{{ JSON.stringify(contact) }}"
              >
                {{ contact.first_name }} {{ contact.last_name }} - {{ contact.email }}
              </option>
            </select>
            <div *ngIf="submitted && f.siteContact.errors" class="invalid-feedback">
              <div *ngIf="f.siteContact.errors.required" id="confirmMessage">!</div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <textarea
            [(ngModel)]="comment"
            formControlName="comment"
            class="form-control bg-silver"
            id="comment"
            col="3"
            rows="6"
            placeholder="please describe the issue."
            required
          ></textarea>
          <div *ngIf="submitted && f.comment.errors" class="invalid-feedback">
            <div *ngIf="f.comment.errors.required" id="confirmMessage">!</div>
          </div>
          <input type="hidden" name="MAX_FILE_SIZE" value="10000" />
          <input
            type="file"
            id="supportFile"
            accept="image/jpeg, image/png"
            (change)="onFileSelected($event)"
          /><br />
          <p id="loadResult"></p>
          <p>
            <!-- <button ng-click="angular.copy(files)" (click) = "resetFile()"  type="reset">cancel</button> -->
            <i class="fa fa-paperclip"></i><span>Attach a file (.jpeg, .png, 10 mb limit)</span>
          </p>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-12">
          <button type="submit" class="btn btn-default float-right">Submit</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="sentRequest">
      <div>
        <p
          *ngIf="resetResponseData?.response.status === 'Ok'"
          style="text-align: center; font-size: 20px; color: black !important;"
        >
          Thank you for submitting your request! We will contact you as soon as possible.
        </p>
        <!-- <p *ngIf="resetResponseData?.response.status !== 'Ok' " style="text-align: center; font-size: 20px; color: red!important">Email was not found</p> -->
        <p
          *ngIf="resetResponseData?.response.status !== 'Ok'"
          style="text-align: center; font-size: 20px; color: red !important;"
        >
          Error sending message. Please try again.
        </p>
      </div>
    </ng-container>
  </form>
</div>
