<div class="teams-call-records">
  <div>
    <span *ngIf="beingLoaded" class="text-center">Fetching records...</span>
    <table class="table table-stripped" *ngIf="!beingLoaded">
      <thead>
        <th>Call Start Time</th>
        <th>Call End Time</th>
        <th>Call Duration Time</th>
      </thead>
      <tbody>
        <tr *ngFor="let record of records;">
          <td>{{record.start_time}}</td>
          <td>{{record.end_time}}</td>
          <td>{{record.duration}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>