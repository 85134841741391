import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { GetIotService } from '../../services/get-iot.service';
import * as moment from 'moment-timezone';
import { RefreshApiService } from '../../services/refresh-api.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog'
import { IncidentMessagesComponent } from '../dialog/incident-messages/incident-messages.component'
import { IncidentType } from 'src/app/services/incident-messages.service'

@Component({
  selector: 'app-iot-device-health-history',
  templateUrl: './iot-device-health-history.component.html',
  styleUrls: ['./iot-device-health-history.component.scss'],
})
export class IotDeviceHealthHistoryComponent implements OnInit {
  @Input() data: any;
  @Input() popupState: any;
  iotIncidentList: any = [];
  shouldShowLoaderBtn: any = false;
  shouldShowTable: any = true;
  incidentId: any = '';
  deviceId: any = '';
  filterListner: Subscription;
  constructor(
    private GetIot: GetIotService,
    private refreshApiService: RefreshApiService,
    private dialog: MatDialog
) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.popupState.currentValue === 'open' && this.data) {
      let isFirstLoad = true;
      const { iot_device_id } = this.data;
      this.deviceId = iot_device_id;
      this.getIotIncidentList();
      this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
        if (m && !isFirstLoad) {
          this.getIotIncidentList();
        }
        isFirstLoad = false;
      });
    } else {
      this.iotIncidentList = [];
      setTimeout(() => {
        this.filterListner.unsubscribe();
      }, 500);
    }
  }

  getIotIncidentList = () => {
    this.iotIncidentList = [];
    this.shouldShowLoaderBtn = true;
    this.shouldShowTable = true;
    this.GetIot.getIotIncidentList(this.deviceId).subscribe((data: any) => {
      this.shouldShowLoaderBtn = false;
      this.iotIncidentList = data.map((i) => {
        const events = JSON.parse(i.events);
        const event_details = [];

        for (const event in events) {
          event_details.push({
            trigger: this.translateTrigger(event, events[event].value,events[event]?.space_message),
            pulls: events[event].pulls,
            // last_pull: this.printTime(events[event].last_pull),
            // end_date: this.printTime(events[event].end_date),
            // start_date: this.printTime(events[event].start_date),
            last_pull: events[event].last_pull,
            end_date: events[event].end_date,
            start_date: events[event].start_date,
          });
        }

        const incident = {
          incident_id: i.incident_id,
					actioned: i.actioned,
          // start_time: this.printTime(i.start_time),
          // end_time: this.printTime(i.end_time),
          start_time: i.start_time,
          end_time: i.end_time,
          event_details,
        };

        return incident;
      });
    });
  };

  // printTime = (time) => {
  //   if (time == null || !time) {
  //     return '--';
  //   } else {
  //     return moment(time).format('l, hh:mm a');
  //   }
  // };

  printTable = (flag) => {
    const elementId = flag ? 'table_1' : 'table_2';
    const contents = document.getElementById(elementId).innerHTML;
    const frame1 = document.createElement('iframe');
    frame1.name = 'frame1';
    frame1.style.position = 'absolute';
    frame1.style.top = '-1000000px';
    document.body.appendChild(frame1);
    const frameDoc = frame1.contentWindow;
    frameDoc.document.open();
    frameDoc.document.write(`
        <html>
        <head>
              <title>Print Report</title>
              <style>
              h1 {
                margin-left: 20px;
              }
              .table-1 {
                width: 100%;
                margin: 20px auto;
              }
              table tr td {
                padding: 5px;
                width: 200px;
                text-align: center;
              }
              </style>
            </head>
          <body onload="window.print(); window.close()">
          ${contents}
          </body>
        </html>
      `);
    setTimeout(function () {
      window.frames['frame1'].focus();
      window.frames['frame1'].print();
      document.body.removeChild(frame1);
    }, 500);
    return false;
  };

  translateTrigger(trigger, value, space_message) {
    space_message = space_message || '';
    switch (trigger) {
      case 'iot_down':
        return `Device ${value ? value : 'DOWN'} ${space_message}.`;
      case 'alert_median':
        return `Median Alert: ${value ? `Device lastest median ${value} ${space_message}.` : ''}`;
      case 'alert_packetloss':
        return `Packetloss Alert: ${value ? `Device Packetloss ${value} ${space_message}.` : ''}`;
      default:
        return '';
    }
  }

    onShowMessages = (incidentId, idx) => {
    const dialogRef = this.dialog.open(IncidentMessagesComponent, {
			panelClass: 'custom-dialog-container',
			data: {
        incidentId,
        incidentType: IncidentType.DOMOTZ_IOT
				// showButton: true,
			},
			// height: '80%',
      // width: '60%',
			// minHeight: 'calc(100vh - 90px)',
      width: '80%',
			height: 'auto',
		})

    dialogRef.afterClosed().subscribe(isActioned => {
      if (isActioned) {
        this.iotIncidentList[idx].actioned = 1
      }
    })
  }
}