import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupTypeService } from 'src/app/services/popupType.service';
import { PopupSendDataService } from 'src/app/services/popup-send-data.service';
import { TableFilterService } from 'src/app/services/table-filter.service';

@Component({
  selector: 'app-audio-site-stats-panel',
  templateUrl: './audio-site-stats-panel.component.html',
  styleUrls: ['./audio-site-stats-panel.component.scss']
})
export class AudioSiteStatsPanelComponent implements OnInit {

  data: any;
  @Input() audioSitesData: any;
  @Input() isbutton: string;
  @Input() isVirtualSite: any;
  @Input() siteSize:any;
  calculatedMbps: any;
  currentFilter: string = "";
  activeFilter: any = "";
  isButton: boolean = true;
  siteId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    public ds: PopupTypeService,
    private sendPopupDataService: PopupSendDataService,
    private tableFilterService: TableFilterService,
    ) { 
    this.route.params.subscribe( params => {
      this.siteId = params['id'];
    });
  }

  setFilter(filter) {
    if (filter == this.activeFilter) {
      this.activeFilter = "";
      this.clickFilter("");
    } else {
  
      switch (filter) {
        case 'on_call':
          this.clickFilter("on_call");
          break;
        case 'impacted':
          this.clickFilter("impacted")
          break;
      }
    }

  }

  clickFilter(filter):void {
  
    this.tableFilterService.filter(filter);
  }

  //Calculates the live mbps and turns into whole numbers
  calMbps(){
    if(this.audioSitesData){
      var roundnum =  (this.audioSitesData.bitrate/ (1024*1024))
      this.calculatedMbps =  (Math.round(roundnum * 100) / 100);
      return this.calculatedMbps
    }
  }

  ngOnInit(){
    // send message to subscribers via observable subject
    this.ds.sendPopupType(0);
    this.paramData();
  }

  paramData(){
    // console.log("Sending param data to pop up")
    this.sendPopupDataService.sendPopupData(this.siteId)
  }

  sendPopupType(p){
    this.ds.sendPopupType(p);
    // // console.log(p)
  }

  ngOnDestroy(){
    // clear message
    this.ds.clearPopupType();
  }
  

}
