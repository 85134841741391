import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

const TWO_PI: number = Math.PI * 2;
const HALF_PI: number = Math.PI * 0.5;
const HALF = 0;

@Component({
  selector: 'app-success-animation',
  templateUrl: './success-animation.component.html',
  styleUrls: ['./success-animation.component.css']
})
export class SuccessAnimationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("drawing") drawingCanvas: ElementRef;
  private ctx: CanvasRenderingContext2D;
  private viewWidth: number = 512;
  private viewHeight: number = 512;
  private timeStep: number = 1 / 60;

  private particles: Particle[] = [];
  private loader: Loader;
  private exploader: Exploader;
  private phase: number = 0;

  ngOnInit() {}

  ngAfterViewInit() {
    this.initDrawingCanvas();
    this.requestAnimationFrame(this.loop);
    console.log("here");
  }

  ngOnDestroy() {}

  private initDrawingCanvas(): void {
    this.ctx = this.drawingCanvas.nativeElement.getContext('2d');
    this.createLoader();
    this.createExploader();
    this.createParticles();
  }

  private createLoader(): void {
    this.loader = new Loader(this.viewWidth * HALF, this.viewHeight * HALF);
  }

  private createExploader(): void {
    this.exploader = new Exploader(this.viewWidth * HALF, this.viewHeight * HALF, this.timeStep);
  }

  private createParticles(): void {
    for (let i = 0; i < 128; i++) {
      let p0: Point = new Point(this.viewWidth * HALF, this.viewHeight * HALF);
      let p1: Point = new Point(Math.random() * this.viewWidth, Math.random() * this.viewHeight);
      let p2: Point = new Point(Math.random() * this.viewWidth, Math.random() * this.viewHeight);
      let p3: Point = new Point(Math.random() * this.viewWidth, this.viewHeight + 64);

      this.particles.push(new Particle(p0, p1, p2, p3, this.timeStep));
    }
  }

  private update(): void {
    switch (this.phase) {
      case 0:
        this.loader.progress += 1 / 45;
        break;
      case 1:
        this.exploader.update();
        break;
      case 2:
        this.particles.forEach((p) => {
          p.update();
        });
        break;
    }
  }

  private draw(): void {
    this.ctx.clearRect(0, 0, this.viewWidth, this.viewHeight);

    switch (this.phase) {
      case 0:
        this.loader.draw(this.ctx);
        break;
      case 1:
        this.exploader.draw(this.ctx);
        break;
      case 2:
        this.particles.forEach((p) => {
          p.draw(this.ctx);
        });
        break;
    }
  }

  private requestAnimationFrame(callback: FrameRequestCallback): number {
    return requestAnimationFrame(callback);
  }

  private loop = () => {
    this.update();
    this.draw();

    if (this.phase === 0 && this.loader.complete) {
      this.phase = 1;
    } else if (this.phase === 1 && this.exploader.complete) {
      this.phase = 2;
    } else if (this.phase === 2 && this.checkParticlesComplete()) {
      // Reset
      this.phase = 0;
      this.loader.reset();
      this.exploader.reset();
      this.particles.length = 0;
      this.createParticles();
    }

    this.requestAnimationFrame(this.loop);
  };

  private checkParticlesComplete(): boolean {
    for (let i = 0; i < this.particles.length; i++) {
      if (!this.particles[i].complete) {
        return false;
      }
    }
    return true;
  }
}

class Point {
  constructor(public x: number = 0, public y: number = 0) {}
}

class Particle {
  public time: number;
  public duration: number;
  public color: string;
  public w: number;
  public h: number;
  public complete: boolean;
  public r: number;
  public sy: number;
  public x: number;
  public y: number;
  public timeStep: number;

  constructor(public p0: Point, public p1: Point, public p2: Point, public p3: Point, timeStep: number) {
    this.time = 0;
    this.duration = 3 + Math.random() * 2;
    this.color = '#' + Math.floor((Math.random() * 0xffffff)).toString(16);
    this.w = 8;
    this.h = 6;
    this.complete = false;
    this.timeStep = timeStep;
  }

  update(): void {
    this.time = Math.min(this.duration, this.time + this.timeStep);

    let f: number = Ease.outCubic(this.time, 0, 1, this.duration);
    let p: Point = cubeBezier(this.p0, this.p1, this.p2, this.p3, f);

    let dx: number = p.x - this.x;
    let dy: number = p.y - this.y;

    this.r = Math.atan2(dy, dx) + HALF_PI;
    this.sy = Math.sin(Math.PI * f * 10);
    this.x = p.x;
    this.y = p.y;

    this.complete = this.time === this.duration;
  }

  draw(ctx: CanvasRenderingContext2D): void {
    ctx.save();
    ctx.translate(this.x, this.y);
    ctx.rotate(this.r);
    ctx.scale(1, this.sy);

    ctx.fillStyle = this.color;
    ctx.fillRect(-this.w * 0.5, -this.h * 0.5, this.w, this.h);

    ctx.restore();
  }
}

class Loader {
  public complete: boolean;
  private _progress: number;
  public r: number;

  constructor(public x: number, public y: number) {
    this.r = 24;
    this._progress = 0;
    this.complete = false;
  }

  reset(): void {
    this._progress = 0;
    this.complete = false;
  }

  set progress(p: number) {
    this._progress = p < 0 ? 0 : p > 1 ? 1 : p;
    this.complete = this._progress === 1;
  }

  get progress(): number {
    return this._progress;
  }

  draw(ctx: CanvasRenderingContext2D): void {
    ctx.fillStyle = 'transparent';
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.r, -HALF_PI, TWO_PI * this._progress - HALF_PI);
    ctx.lineTo(this.x, this.y);
    ctx.closePath();
    ctx.fill();
  }
}

class Exploader {
  public time: number;
  public duration: number;
  public progress: number;
  public complete: boolean;
  public startRadius: number;
  public timeStep: number;

  constructor(public x: number, public y: number, timeStep: number) {
    this.startRadius = 24;
    this.time = 0;
    this.duration = 0.4;
    this.progress = 0;
    this.complete = false;
    this.timeStep = timeStep;
  }

  reset(): void {
    this.time = 0;
    this.progress = 0;
    this.complete = false;
  }

  update(): void {
    this.time = Math.min(this.duration, this.time + this.timeStep);
    this.progress = Ease.inBack(this.time, 0, 1, this.duration, 1.70158);
    this.complete = this.time === this.duration;
  }

  draw(ctx: CanvasRenderingContext2D): void {
    ctx.fillStyle = 'transparent';
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.startRadius * (1 - this.progress), 0, TWO_PI);
    ctx.fill();
  }
}

const Ease = {
  inCubic(t: number, b: number, c: number, d: number): number {
    t /= d;
    return c * t * t * t + b;
  },
  outCubic(t: number, b: number, c: number, d: number): number {
    t /= d;
    t--;
    return c * (t * t * t + 1) + b;
  },
  inOutCubic(t: number, b: number, c: number, d: number): number {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t * t + b;
    t -= 2;
    return c / 2 * (t * t * t + 2) + b;
  },
  inBack(t: number, b: number, c: number, d: number, s: number): number {
    s = s || 1.70158;
    return c * (t /= d) * t * ((s + 1) * t - s) + b;
  }
};

function cubeBezier(p0: Point, c0: Point, c1: Point, p1: Point, t: number): Point {
  let p: Point = new Point();
  let nt: number = 1 - t;

  p.x = nt * nt * nt * p0.x + 3 * nt * nt * t * c0.x + 3 * nt * t * t * c1.x + t * t * t * p1.x;
  p.y = nt * nt * nt * p0.y + 3 * nt * nt * t * c0.y + 3 * nt * t * t * c1.y + t * t * t * p1.y;

  return p;
}
