

import { Component, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'

import { environment } from '../../../environments/environment'
import { AddIotDomotzDialogComponentComponent } from '../../iot/dialogs/add-iot-domotz-dialog-component/add-iot-domotz-dialog-component.component'
import { IotLiteAddDeviceDialogComponent } from '../../iot/dialogs/iot-lite-add-device-dialog/iot-lite-add-device-dialog.component'
// tslint:disable-next-line: max-line-length
import { WebportalProtocolConfigDialogComponent } from '../../iot/dialogs/webportal-protocol-config-dialog/webportal-protocol-config-dialog.component'
import { AuthService } from '../../services/auth.service'
import { GetIotService } from '../../services/get-iot.service'

@Component({
  selector: 'app-iot',
  templateUrl: './iot.component.html',
  styleUrls: ['./iot.component.scss'],
})
export class IotComponent implements OnChanges {
  @Input() iotData: any;
  @Input() iotEvent: EventEmitter<any>;

  defaultIotType: String = '../../../assets/img/iot/pdu.png';
  selectDevice: String = this.defaultIotType;
  selectDeviceName: String;
  selectDeviceIP: String;
  iotLiteEnabled: any;
  selectType: Number = 5;

  shouldShowAddDevice: boolean = false;
  shouldShowDeleteModal: boolean = false;
  showErrorMessage: boolean = false;
  showIpErrorMessage: boolean = false;
  showNameErrorMessage: boolean = false;
  iotMessage: string;
  iotComponents: any = [];
  isDisableIotComponents: boolean = false;

  addComponentClass = 'clickable';
  actionCount: number = 0;
  shouldShowLoaderBtn: boolean = false;
  shouldShowUpdateCollectorForIot = false;
  iotId: any;
  iotName: any;
  dCollector: String = environment.config.dynamicText.dCollector;
  iotLoader: String = environment.config.dynamicImage.iotLoader;

  isDomotzEnabled: boolean = false;
  iotDomotzList: any;

  searchText: any;
  iotDomotzDeviceId: any;
  isShowDomotzIntegration = environment.config.domotzIntegration;
  iotUnlinkedDeviceList: any;

  collectorId: any;
  
  validateIot: any = {
    flag: true,
    message: 'message',
  };


  selectData: any = [
    { id: 1, value: '../../../assets/img/iot/monitor.png', type: 'DISPLAY' },
    { id: 2, value: '../../../assets/img/iot/server.png', type: 'SERVER' },
    { id: 3, value: '../../../assets/img/iot/switch.png', type: 'NETWORK' },
    { id: 4, value: '../../../assets/img/iot/browser.png', type: 'ACCESS POINT' },
    { id: 5, value: '../../../assets/img/iot/pdu.png', type: 'POWER' },
    { id: 6, value: '../../../assets/img/iot/wifi.png', type: 'WEB' },
    { id: 7, value: '../../../assets/img/iot/cam.png', type: 'CAMERA' },
    { id: 8, value: '../../../assets/img/iot/phonetablet.png', type: 'DEVICE' },
    { id: 9, value: '../../../assets/img/iot/cloud.png', type: 'CLOUD' },
  ];

  constructor(
    private Auth: AuthService,
    private GetIot: GetIotService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  pushDomotzTypeValuesToSelectData = () => {
    this.selectData.push({ id: 10, value: '../../../assets/img/iot/avdevice.png', type: 'AV_DEVICES' });
    this.selectData.push({ id: 11, value: '../../../assets/img/iot/networkdevice.png', type: 'NETWORK_DEVICES' });
    this.selectData.push({ id: 12, value: '../../../assets/img/iot/officeequip.png', type: 'OFFICE_DEVICES' });
    this.selectData.push({ id: 13, value: '../../../assets/img/iot/otherdevice.png', type: 'OTHER_DEVICES' });
    this.selectData.push({ id: 14, value: '../../../assets/img/iot/smartdevice.png', type: 'SMART_DEVICES' });
    this.selectData.push({ id: 15, value: '../../../assets/img/iot/mobiledevice.png', type: 'MOBILE_DEVICES' });
    this.selectData.push({ id: 16, value: '../../../assets/img/iot/servers.png', type: 'SERVERS' });
  };



  ngOnChanges(changes: SimpleChanges) {
    if (changes.iotData && this.iotData) {
     this.shouldDisableComopnent(this.iotData);
    
      const { collector_id } = this.iotData;
      this.collectorId = collector_id;
      if (this.isShowDomotzIntegration) {
        this.getDomotzAccountByCompanyId();
      } else {
        //false to set the iot lite?
        this.getIotLiteStatus();
        // ask if iot lite is enabled?
      }
    }
  }
  
  getDomotzAccountByCompanyId = () => {
    this.GetIot.getDomotzAccountByCompanyId().subscribe((data: any) => {
      const { enabled } = data;
      this.isDomotzEnabled = enabled === 1;
      if (this.isDomotzEnabled) {
        this.pushDomotzTypeValuesToSelectData();
        this.setIoTComponenets(true);
      } else {
        this.getIotLiteStatus();
      }
      //false to set the iot lite?

    });
  };

  setIoTComponenets = (flag) => {
    //false means its for Iot
    const validation = this.validateIot;
    
    
    
    if (validation.flag) {
      if (flag) {
        this.getLinkedIotDomotzDevices();
        this.getUnlinkedIotDomotzDevices();
      } else {
        if (this.iotLiteEnabled) {
          this.getIotLiteUnlinkedDeviceList();
          this.setIotLiteComponent();
        } else {
          this.disableButton();
          //error message to active it or something
        }
      }
    } else {
      this.disableButton();
      this.iotMessage = validation.message;
      this.showErrorMessage = true;
      this.isDisableIotComponents = true;
    }
    //check for the iot lite
  };

  setIotLiteComponent = () => {
    if (this.iotData.iot_components !== null) {
      this.isDisableIotComponents = false;
      // console.log('iotData: ', this.iotData)
      this.iotComponents = [...this.iotData.iot_components];
      this.addComponentClass = 'clickable';
      if (this.iotComponents.length >= 5) {
        this.disableButton();
      }
    }
  };
  


  shouldDisableComopnent = (params) => {
    const { status, quadrant, collector_version, iot_components, paused } = params;
    if(this.iotData?.iot_components?.length)this.iotComponents = this.iotData.iot_components;
    // if (collector_version < '2.0.1') {
    //   console.log('yes?')
    //   return {
    //     flag: false,
    //     message: `Update ${this.dCollector} version to 2.0.1 or above to use IoT`,
    //   };
    // }

    // if ((quadrant === 1 && !status) || (quadrant === 2 && !status)) {
    //   return {
    //     flag: false,
    //     message: `${this.dCollector} must be connected/online to use IoT`,
    //   };
    // }

    // if (quadrant == 3 && !status) {
    //   let flag = false;
    //   if (iot_components !== null && iot_components.length > 0) {
    //     flag = true;
    //   }
    //   this.validateIot = {
    //     flag,
    //     message: `${this.dCollector} must be connected/online to use IoT`,
    //   };
    // }
    
    if (paused) {
    this.validateIot = {
        flag: true,
        paused: true,
        message: `Unpause Device to add IoT`,
      };
    }

  };

  disableButton = () => {
    this.addComponentClass = 'disable-btn';
    this.shouldShowAddDevice = false;
  };

  enableButton = () => {
    this.addComponentClass = '';
  };


  selectMainItem = (item: any) => {
    this.selectDevice = item.value;
    this.selectType = item.id;
  };

  getImageType = (type) => {
    if (type == "" || type == null) {
      return "../../../assets/img/iot/otherdevice.png"
    }
    const filterImage = this.selectData.filter((i) => i.type === type);
    return filterImage[0].value;
  };

  getStatus = (params) => {
    if (this.iotLiteEnabled) {
      return this.getStatusIot(params)
    } else {
      return this.getStatusDomotz(params)
    }
  }

  getStatusIot = ({ status, is_monitored,pause }) => {
    let color = '';
    if(pause) {
      color = 'circle-blue';
  
    } else {
      if (status) {
        color = 'circle-green';
      } else {
        color = 'circle-red';
      }}
 
    return color;
  };

  getStatusDomotz = (item) => {
    let { status, paused } = item;

    let color = '';

    if (paused == 1) {
      status = 3;
    } else {
      status = status === 'ONLINE' ? 1 : 0;
    }

    switch (status) {
      case 0:
        color = 'circle-red';
        break;
      case 2:
        color = 'circle-yellow';
        break;
      case 1:
        color = 'circle-green';
        break;
      case 3:
        color = 'circle-blue';
        break;
      default:
        color = 'circle-red';
    }

    if (this.isDisableIotComponents) {
      color = color + '__disable';
    }

    return color;
  };

  getSettingStatus = (status) => {
    let icon = '';
    switch (status) {
      case 0:
        icon = '../../../assets/img/iot/setting-pink.png';
        break;
      case 2:
        icon = '../../../assets/img/iot/setting-pink.png';
        break;
      case 1:
        icon = '../../../assets/img/iot/setting-blue.png';
        break;
      default:
        icon = '../../../assets/img/iot/setting-pink.png';
    }
    return icon;
  };



  validate = () => {
    this.showErrorMessage = false;
    this.showNameErrorMessage = false;
    this.showIpErrorMessage = false;

    if (this.selectDeviceName.trim() === '') {
      this.iotMessage = 'Required Field';
      this.showNameErrorMessage = true;
      return false;
    } else if (this.selectDeviceName.length > 30) {
      this.iotMessage = 'Field Exceeds Limit';
      this.showNameErrorMessage = true;
      return false;
    }

    if (this.selectDeviceIP.trim() === '') {
      this.iotMessage = 'Required Field';
      this.showIpErrorMessage = true;
      return false;
    }

    return true;
  };


  showAddDevice = () => {
    this.iotMessage = '';
    this.showErrorMessage = false;
    if (this.isDomotzEnabled) {
      this.setIotDomotzAddComponent();
    } else {
      this.openAddIotLiteDialog();
    }
  };

  setIotDomotzAddComponent = () => {
    //wierd
    if (this.iotDomotzList) {
      this.openAddIotDomotzDialog();
    }
  };



  showDeleteModal = ({ iot_device_id, iot_name, iot_id }) => {
    this.iotId = this.isDomotzEnabled ? iot_device_id : iot_id;
    this.iotName = iot_name;
    this.shouldShowDeleteModal = !this.shouldShowDeleteModal;
  };

  confirmDelete = () => {
    const { quadrant } = this.iotData;
    if (this.isDomotzEnabled) {
      this.GetIot.setIotDeviceUnlink(this.iotId).subscribe(
        (data: any) => {
          this.iotComponents = this.iotComponents.filter((i) => i.iot_device_id !== this.iotId);
          this.refreshData(quadrant);
          this.shouldShowDeleteModal = !this.shouldShowDeleteModal;
        },
        (error) => { }
      );
    } else {
      this.Auth.deleteIot(this.iotId).subscribe(
        (data) => {
          this.iotComponents = this.iotComponents.filter((i) => i.iot_id !== this.iotId);
          this.refreshData(quadrant);
          this.shouldShowDeleteModal = !this.shouldShowDeleteModal;
        },
        (error) => { }
      );
    }
  };

  cancelDelete = () => {
    this.shouldShowDeleteModal = !this.shouldShowDeleteModal;
  };

  getUnlinkedIotDomotzDevices = () => {
    const { mac, ipv4 } = this.iotData;
    this.GetIot.getUnlinkedIotDevice().subscribe((data: any) => {
      // console.log('getUnlinkedIotDevice', data)
      this.iotDomotzList = data.filter(
        (e) => e.is_monitored === 1 && e.mac_address !== mac && e.ip_address !== ipv4
      );
      this.enableButton();
    });
  };

  getLinkedIotDomotzDevices = () => {
    const { iot_domotz_components } = this.iotData;
    if (iot_domotz_components !== null) {
      this.iotComponents = iot_domotz_components.map((i) => {
        const status = i.status;
        const paused = i.paused;
        const iot_device_id = i.iot_id;
        const iot_type = i.group_type;
        const iot_name = i.display_name;
        const iot_ip = i.ip_address;

        return { iot_device_id, status, iot_type, iot_name, iot_ip, paused };
      });
    } else {
      this.iotComponents = [];
    }
  };

  openWebPortalOptions = (params: any) => {
    const iotId = this.iotLiteEnabled ? params.iot_id : params.iot_device_id;
    //do something about this
    const webportal = this.dialog.open(WebportalProtocolConfigDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        iotId,
        iotEnabled: this.iotLiteEnabled
      },
    });
  };

  openAddIotDomotzDialog = () => {
    const { quadrant, device_id, room_name, site_id } = this.iotData;
    const config = {
      quadrant,
      device_id,
      room_name,
      site_id,
      iotDomotzList: this.iotDomotzList,
      iotEvent: this.iotEvent,
    };
    const webportal = this.dialog.open(AddIotDomotzDialogComponentComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        config,
      },
    });
  };

  openAddIotLiteDialog = () => {
    const { quadrant, device_id, room_name, site_id } = this.iotData;
    const config = {
      quadrant,
      device_id,
      room_name,
      site_id,
      iotLiteList: this.iotUnlinkedDeviceList,
      iotEvent: this.iotEvent,
    };
    const webportal = this.dialog.open(IotLiteAddDeviceDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        config,
      },
    });
  };

  navigateToIotDna = (params: any) => {
    if (this.iotLiteEnabled) {
      this.router.navigate(['iot/lite/device/', params.iot_id]);
    } else {
      this.router.navigate(['iot/device/', params.iot_device_id]);
    }
  }

  refreshData = (quadrant) => {
    if (quadrant === 1) {
      this.iotEvent.emit('refreshVideoData');
      return;
    }
    if (quadrant === 2) {
      this.iotEvent.emit('refreshAudioData');
      return;
    }
    if (quadrant === 3) {
      this.iotEvent.emit('refreshCloudData');
      return;
    }
  };

  // 
  getIotLiteUnlinkedDeviceList = () => {
    //for linking purpose
    this.shouldShowLoaderBtn = true;
    this.GetIot
      .getIotLiteDeviceList(
        'unlinked',
        this.collectorId
      )
      .subscribe((data) => {
        this.shouldShowLoaderBtn = false;
        const res = data.response
        this.iotUnlinkedDeviceList = res;
        // console.log(this.iotUnlinkedDeviceList, 'iotDeviceList')
        this.enableButton();
      });
  };


  getIotLiteStatus = () => {
    this.GetIot.getIotLiteStatus().subscribe((data: any) => {
      let [res] = data.response;
      this.iotLiteEnabled = res.is_iot_lite;
      this.setIoTComponenets(false);
    });
  }


}