

import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-reports-incident-sheet',
  templateUrl: './reports-incident-sheet.component.html',
  styleUrls: ['./reports-incident-sheet.component.scss']
})
export class ReportsIncidentSheetComponent implements OnInit {
  @Input() incidentList: any;
  @Input() listTitle: any;
  @Input() isZoom:boolean = false;
  
  constructor() { }

  ngOnInit() {

  }

  

}
