import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class GetDocumentCenterService {
  getUrlPath: Function = environment.apiServicePathBuilder('document-center');
  // getUrlPath: Function = environment.apiServicePathBuilder('dev');
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) {}

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }

  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const u = helper.decodeToken(token);

    const { company_id } = helper.decodeToken(token);
    return company_id;
  };

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getDocumentsByDevice({ quadrant_group, device_id, offset,root_id,isFiles }) {
    const company_id = this.getCompanyId();
    const url: string = this.getUrlPath(
      `getDocumentsByDevice/${company_id}/${quadrant_group}/${device_id}/${root_id}/${isFiles}/${offset}`
    );
    return this.http.get<any>(url).pipe(map(this.extractData))
  }

  getDocumentById(document_id) {
    const url: string = this.getUrlPath(`getDocumentById/${document_id}`);
    return this.http.get<any>(url).pipe(map(this.extractData))
  }

  getDocumentsByCompany(body) {
    const company_id = this.getCompanyId();
    const url: string = this.getUrlPath(`getDocumentsByCompany/${company_id}/${body.root_id}/${body.isFiles}/${body.offset}`);
    return this.http.get<any>(url).pipe(map(this.extractData))
  }

  createDocument(formData) {
    const url: string = this.getUrlPath('createDocument');
    return this.http
      .post<any>(url, formData, {
        reportProgress: true,
        observe: 'events',
      })
  }

  deleteDocument(document_id) {
    const url: string = this.getUrlPath('deleteDocument');
    const data = {
      document_id,
    };
    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  deleteFolder(document_id) {
   const url: string = environment.serverUrl + `/api/v1/documents/delete-folder/${document_id}`;
    return this.http
      .get<any>(url)
      .pipe(map(this.extractData))
  }

  shareDocument(document_id, share_expiration) {
    const url: string = this.getUrlPath('shareDocument');
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const { user_id } = helper.decodeToken(token);
    const data = {
      document_id,
      shared_by_user_id: user_id,
      share_expiration,
    };

    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  unshareDocument(document_id) {
    const url: string = this.getUrlPath('unshareDocument');
    const data = {
      document_id,
    };
    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  downloadDocument(document_id) {
    const url: string = this.getUrlPath(`downloadDocument/${document_id}`);
    return this.http.get<any>(url).pipe(map(this.extractData))
  }

  checkDocumentExists(params) {
    const url: string = this.getUrlPath('checkDocumentExists');

    return this.http
      .post<any>(url, params, this.httpOptions)
      .pipe(map(this.extractData))
  }

  startDocumentUpload(params) {
    const url: string = this.getUrlPath('startDocumentUpload');

    return this.http
      .post<any>(url, params, this.httpOptions)
      .pipe(map(this.extractData))
  }

  getDocumentUploadUrl(params) {
    const url: string = this.getUrlPath('getDocumentUploadUrl');

    return this.http
      .post<any>(url, params, this.httpOptions)
      .pipe(map(this.extractData))
  }

  completeDocumentUpload(params) {
    const url: string = this.getUrlPath('completeDocumentUpload');
    return this.http
      .post<any>(url, params, this.httpOptions)
      .pipe(map(this.extractData))
  }
  
  addDocument(body) {
    const url: string = environment.serverUrl + '/api/v1/documents/add-folder';
    return this.http.post<any>(url, body);
  }

  presignedUrl(presignedUrl, blob, selectedFile) {
    const config = {
      headers: {
        'Content-Type': selectedFile.type,
        'Access-Control-Expose-Headers': 'etag',
      },
      observe: 'response',
    };
    return this.http.put<any>(presignedUrl, blob, config as any);
  }
}
