<!-- <h2>{{title}}</h2> -->
<div class="popup-content">
  <div class="container-fluid">
    <div class="row pt-3">
      <div
        class="card bg-default"
        style="padding-bottom: 10px; padding-top: 10px; margin-bottom: 10px;"
      >
        <div class="col-md-12" *ngIf="meetingsResponseData.length > 0">
          <div
            class="card bg-default"
            style="margin-bottom: 10px;"
            *ngFor="let meeting of meetingsResponseData; index as n"
          >
            <h5 class="card-header">
              <!-- {{ printTime(meeting.start) }} - {{ printEndTime(meeting.end) }} -->
              {{ meeting.start | dateTimeZone : 'MM-DD-YYYY hh:mma' : false}} - {{ meeting.end | dateTimeZone : 'hh:mma'}}
            </h5>
            <div class="card-body">
              <table>
                <thead>
                  <tr>
                    <td>Title: {{ meeting.title }}</td>
                  </tr>
                  <tr>
                    <td>Meeting Organizer: {{ meeting.organizer }}</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="meetingsResponseData.length == 0">
          <div class="col-md-12">
            <div class="card bg-default">
              <h5 class="card-header">
                No Meetings Scheduled
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
