<div class="row">
    <div class="col-7 d-flex align-items-center">
      <h5>System Temperature</h5>
      <span style="padding-left: 7px">
        <ng-container *ngIf="isDisplayTemperature(manufacturer) && temperature <= 54; else yellowTemp">
          <img class="temp-icon" src="../../../../assets/img/shared/thermometer_green.png" alt="temperature gauge">			
        </ng-container>
        <ng-template  #yellowTemp>
          <ng-container *ngIf="isDisplayTemperature(manufacturer) && temperature >= 55 && temperature <= 64; else redTemp">
            <img class="temp-icon" src="../../../../assets/img/shared/thermometer_yellow.png" alt="temperature gauge">	
          </ng-container>	
        </ng-template>
        <ng-template  #redTemp>
          <ng-container *ngIf="isDisplayTemperature(manufacturer) && temperature >= 65; else noTemp">
            <img class="temp-icon" src="../../../../assets/img/shared/thermometer_red.png" alt="temperature gauge">
          </ng-container>	
        </ng-template>
  
        <ng-template #noTemp>
          <img class="temp-icon" src="../../../../assets/img/shared/thermometer_grey.png" alt="temperature gauge">
        </ng-template>
      </span>
      
    </div>
    <div class="col-5 d-flex align-items-center">
      <!-- <h4> {{ videoStats.temperature}} &#8457; / 0 &#8451;</h4>	 -->
      <ng-container *ngIf="isDisplayTemperature(manufacturer) && temperature >= 0; else noTempData">
        <h4 class="text-temp align-self-center"> {{ temperature * 1.8 + 32 | number : '1.0-0'}}&#8457; / {{ temperature }}&#8451;</h4>	
      </ng-container>
  
      <ng-template #noTempData>
        <h4 class="text-temp"> -- F/-- C</h4>
      </ng-template>

    </div>
  </div>