<div class="register" [ngStyle]="visibilityBackground">
  <div class="register__col">
    <div class="register__box">
      <h1>Free Trial</h1>
      <h3>Access to all feature</h3>
      <hr />
      <h3>Monitor up to</h3>
      <h3>100 devices & services</h3>
      <h3>Poly, Cisco & Zoom Rooms</h3>
    </div>
    <div class="register__box">
      <img [src]="dashboardLogo" style="cursor: pointer;" (click)="routeToHomePage()" />

      <input class="{{ formControl.company.style }}" type="text" placeholder="company name" value="register.company"
        [(ngModel)]="register.company" (keyup)="keyEvent($event, 'company')" />

      <div class="register__container-input-sm">
        <input class="{{ formControl.firstname.style }} register__input-sm" type="text" placeholder="firstname"
          value="register.firstname" [(ngModel)]="register.firstname" (keyup)="keyEvent($event, 'firstname')" />

        <input class="{{ formControl.lastname.style }}  register__input-sm" type="text" placeholder="lastname"
          value="register.lastname" [(ngModel)]="register.lastname" (keyup)="keyEvent($event, 'lastname')" />
      </div>

      <div>
        <input class="{{ formControl.email.style }}" type="text" placeholder="email" value="register.email"
          [(ngModel)]="register.email" (keyup)="keyEvent($event, 'email')" />
        <div *ngIf="formControl.email.flag" class="error-msg">
          {{ formControl.email.message }}
        </div>
      </div>

      <input class="{{ formControl.phone.style }}" type="text" placeholder="user phone" value="register.phone"
        [(ngModel)]="register.phone" (keyup)="keyEvent($event, 'phone')" />
      <div *ngIf="formControl.phone.flag" class="error-msg">
        {{ formControl.phone.message }}
      </div>

      <div class="register__container-input-sm">
        <div class="register__input-sm">
          <input class="{{ formControl.password.style }}" type="password" placeholder="password"
            value="register.password" [(ngModel)]="register.password" (keyup)="keyEvent($event, 'password')" />

          <div *ngIf="formControl.password.flag" class="error-msg">
            {{ formControl.password.message }}
          </div>
        </div>
        <div class="register__input-sm">
          <input class="{{ formControl.confirm.style }} " type="password" placeholder="confirm password"
            value="register.confirm" [(ngModel)]="register.confirm" (keyup)="keyEvent($event, 'confirm')" />

          <div *ngIf="formControl.confirm.flag" class="error-msg">
            {{ formControl.confirm.message }}
          </div>
        </div>
      </div>

      <div class="register__terms">
        <input class="{{ formControl.checkbox.style }}" type="checkbox" #checkboxInput [(ngModel)]="register.checkbox"
          (ngModelChange)="checkboxEvent(checkboxInput.checked)" />
        <a href="{{ links.terms }}" target="_blank" [ngClass]="{
            'error': formControl.checkbox.style == 'error'
          }">
          I agree to the terms and conditions.</a>
      </div>

      <div class="register__message" *ngIf="message.flag">
        {{ message.text }}
      </div>

      <button [ngClass]="{
          'disable-btn': disableBtn
        }" class="register__btn-primary" (click)="validateForm()">
        Create Account
      </button>

      <div class="register__to-login">
        <div>You already have and acount?</div>
        <a routerLink="/login"> Click here to login</a>
      </div>
    </div>
  </div>
</div>