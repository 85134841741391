import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; 
import { Router } from '@angular/router';  
import { GetVideoService } from '../../../services/get-video.service';
import { TableFilterService } from 'src/app/services/table-filter.service';
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-video-site-list-panel',
  templateUrl: './video-site-list-panel.component.html',
  styleUrls: ['./video-site-list-panel.component.scss']
})
export class VideoSiteListPanelComponent implements OnInit, OnDestroy {
  @Input() videoListData: any;
  @Input() videoDevicesListData:MatTableDataSource<any>;

  // Settings and Parameters for the the Smart Filter Tables
  videoDeviceTableSettings =  this.Auth.accessLevel == "SUPERADMIN"?this.getVideoDataService.videoDeviceTableSettingsSuperAdmin:this.getVideoDataService.videoDeviceTableSettings;
  filterListner: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, 
    private getVideoDataService: GetVideoService,
    private tableFilterService: TableFilterService,
    private Auth: AuthService) { 
        /* This function passes the filter click data globaly 
         to any component subscribe to this service */
         this.filterListner = this.tableFilterService.listen().subscribe((m:any) => {
          // console.log(m);
          this.onFilter(m);
        });
  
  }


  ngOnInit() {

  }

  ngOnDestroy(){
    this.filterListner.unsubscribe();
  }


   // This function handles the routing for when a table row is clicked
   onClickTable(event){
    let device = event.data;
    this.router.navigate(['video/site/',device.site_id,'videodevice', device.video_device_id]);
  }
  onFilter(event) {
    const query: string = event[0];
    const cat: string = event[1]
    if (!query) {
      this.videoListData.setFilter([]);
    } else {
      this.videoListData.setFilter([
        {
          field: cat,
          search: query,
          filter: (value: any, query: string) =>{
            if(cat == "health"){
              return +value < +query;
            }  else if(cat == 'oncall'){
              return +value >= +query;
            } else if(cat == 'impacted'){
              return +value === +query
            }
          }
        },
      ], false);
    } 
  }
  // This is for the search input form for the Smart-Table
  onSearch(query: string = '') {
    //// console.log(query);
    if (query === '') {
      this.videoListData.setFilter([]);
    } else {
      this.videoListData.setFilter([
        {
          field: 'company_name',
          search: query,
        },
        // fields we want to inclue in the search
        {
          field: 'room_name',
          search: query,
        },
        {
          field: 'health',
          search: query,
        },
        {
          field: 'live_qos',
          search: query,
        },
        {
          field: 'live_mbps',
          search: query,
        },
        {
          field: 'on_call',
          search: query,
        },
        {
          field: 'scheduled',
          search: query,
        },
        {
          field: 'Model',
          search: query,
        },
        {
          field: 'IP',
          search: query,
        },
      ], false);
      // second parameter specifying whether to perform 'AND' or 'OR' search
      // (meaning all columns should contain search query or at least one)
      // 'AND' by default, so changing to 'OR' by setting false here
    }
  }
}
