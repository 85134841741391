import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; 
import { Router } from '@angular/router';  
import { GetAudioService } from '../../../services/get-audio.service';

@Component({
  selector: 'app-audio-device-list',
  templateUrl: './audio-device-list.component.html',
  styleUrls: ['./audio-device-list.component.scss']
})
export class AudioDeviceListComponent implements OnInit {
  @Input() audioDevicesListData: any;
  @Input() audioDeviceData: any;



  constructor(private route: ActivatedRoute, private router: Router, 
    private getAudioDataService: GetAudioService) { 
  
  }

  ngOnInit() {
    // console.log("made it audio");
  }

  onClickTable(event){
    let user = event.data;
    this.router.navigate(['audio/user/',user.active_user_id]);
  }


}
