<div class="panel">
    <div class="panel__header">
        <app-panel-tools [PopupTitle]="iotDeviceName"></app-panel-tools>
        <div *ngIf="iotDeviceDetails?.collector_status === -1" class="panel__banner-secondary">
            {{ dCollector }} is offline. The information below is from the last successful sync at
            {{ syncTime }}
          </div>
    </div>
    <div class="panel__body">
        <div class="panel__body_info">
            <app-iot-lite-device-info [iotDeviceDetails]="iotDeviceDetails"
                [iotDevicePortsDetails]="iotDevicePortsDetails" (eventEmitter)="ngOnChange($event)">
            </app-iot-lite-device-info>
        </div>
        <div class="panel__body_stats_health">
            <div class="health-container">
                <div (click)="openIotDeviceHealthHistory()" class="incident-button">
                    Incidents
                </div>
                <app-system-health [isCursorPointer]="true"
                    [healthNum]="iotDeviceDetails?.pause === 1 ? 0 : iotDeviceDetails?.health"
                    [showBlackPointer]="true" (click)="openIotDeviceHealthHistory()"></app-system-health>
            </div>
            <app-iot-lite-device-stats [iotDeviceDetails]="iotDeviceDetails" (eventEmitter)="ngOnChange($event)">
            </app-iot-lite-device-stats>
        </div>
    </div>
</div>