import { Component, OnInit, Input } from '@angular/core';
import { PopupTypeService } from '../../../../services/popupType.service';

@Component({
  selector: 'app-cloud-user-utilization',
  templateUrl: './cloud-user-utilization.component.html',
  styleUrls: ['./cloud-user-utilization.component.scss'],
})
export class CloudUserUtilizationComponent implements OnInit {
  @Input() cloudUserUtilization: any;
  @Input() hasAccess: any;
  @Input() isNotButton: any;
  constructor(public ds: PopupTypeService) {}

  ngOnInit() {}
}
