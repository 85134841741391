import { GetCollectorService } from 'src/app/services/get-collector.service'
import { environment } from 'src/environments/environment'

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

import { AuthService } from '../../services/auth.service'
import { GetDocumentCenterService } from '../../services/get-document-center.service'

@Component({
  selector: 'app-collector-logging',
  templateUrl: './collector-logging.component.html',
  styleUrls: ['./collector-logging.component.scss']
})
export class CollectorLoggingComponent implements OnInit, OnChanges {
  @Input() data: any;
  docPath: string = environment.serverUrl + '/api/v1/files/get-file/';
  setTimer: any
  actionCount: any = 0;
  aDeviceIsFound: any = false;
  showSuccessMsg: any = false;
  successMessage: any = "Device Successfully Added";

  errorMsg: any = "Device Not Found";
  showErrMsg: any = false;
  errorMessage: any = 'Could not find device. Please try again.';

  showDownloading: any = false;

  showAddDevice: any = false;
  showErrorMessage: any = false;

  snapshotMessage: any;
  documentList: any;
  showSnapshotLoader: false;

  isLoading: any = false;
  constructor(
    private Auth: AuthService,
    private getCollector : GetCollectorService,
    private getDocumentCenter: GetDocumentCenterService,
  ) {

  }
  
  ngOnInit(): void {
    
  }
  
	ngOnChanges(changes: SimpleChanges) {
		if (changes.data && this.data) {
		  this.getCollectorLogs();
		}
	}
	
  resetFields = () => {
    this.actionCount = 0;
    this.showDownloading = false; 
    this.toggleCollectorLogLoader(false)
  }


  getCollectorLogs = ( ) =>  {
    const { collector_id } = this.data;
    this.getCollector.collectorLogging(collector_id).subscribe((data: any) => { 
      const [res] = data.response;
      
      if(data.status == 200) {
      const  { action_id }= res;
      this.checkStatus(action_id)
      this.snapshotMessage = `<h5>Downloading Collector Logs<h5/>`
      this.toggleCollectorLogLoader(true)
        
      }
      },
      (err) => { }
    );
  }

  toggleCollectorLogLoader = (show) => {
		this.showDownloading = show
	}
	
	checkStatus = (action_id) => {
    console.log('action_id: ', action_id);
    this.Auth.postDeviceActionId({ action_id }).subscribe((actionData) => {
      console.log('postDeviceActionId: ', actionData)
      console.log('actionCount: ', this.actionCount)
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 90) {
        //resets field if device not found 
        if (!this.aDeviceIsFound) {
          this.resetFields();
          this.snapshotMessage = 'Something went wrong. Please try again.';

        }

        return;
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus(action_id);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === 'FAILED') {
        this.resetFields();
        const [newData] = actionData.response;
        this.snapshotMessage = 'Something went wrong. Please try again.';
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        const [newData] = actionData.response;
        console.log('COMPLETE!!!', newData)
        
        this.getDocumentsByDevice()
        

      }
    });
  }

  downloadCollectorLogs = () => {
    const actionParams = {};
    const actionName = 'TakeImagefromWebCam';


  }

  getDocumentsByDevice = () => {
  	const propData = {
			quadrant_group: 'COLLECTOR',
			device_id: this.data.collector_id,
			description: '',
      offset: 0,
      root_id: 0,
      isFiles: true

		}
		
    this.documentList = [];
    this.getDocumentCenter.getDocumentsByDevice(propData).subscribe((data: any) => {
      const { document_list, total_document_size, document_center_storage_limit } = data;
      const totalFileSize = data.document_list.reduce((total, document) => total + document.file_size, 0);
      
      
      console.log(document_list)
      
      const [latest] = document_list;
      
      this.snapshotMessage = `<h5>Collector Log successfully uploaded to the Document Centre</h5>
       You can download it here: <a href="${this.docPath+latest?.document_id+'/'+1}">${latest?.file_name}</a>`
          
      this.resetFields()
      
    });
  };
 


}
