<div class="cloud-all-user-stats">
  <div class="cloud-all-user-stats__three-columns">
    <h5>
      Live Stats
    </h5>
    <div class="cloud-all-user-stats__three-columns-container">
      <app-btn-reg-stats [loading]="loading" [count]="cloudUserSummary?.impacted" label="Impacted" color="gray"
        (click)="setFilter('impacteduser')" [ngClass]="{ 'outlineBtn': activeFilter == 'impacted' }">
      </app-btn-reg-stats>
      <app-btn-reg-stats [loading]="loading" [count]="cloudUserSummary?.on_call" label="On Call" color="gray"
        (click)="setFilter('livemeetings')" [ngClass]="{ 'outlineBtn': activeFilter == 'liveMeetings' }">
      </app-btn-reg-stats>
      <app-btn-bitrate [count]="calMbps()" label="Live Mbps" color="gray" isbutton="not"></app-btn-bitrate>
    </div>
    <hr>
  </div>

  <div class="cloud-all-user-stats__three-columns">
    <h5>
      Today
    </h5>
    <div class="cloud-all-user-stats__three-columns-container">
      <app-btn-reg-stats class="not-button" isbutton="not" [loading]="loading"
        [count]="cloudUserSummary?.scheduled_meetings" label="Scheduled Meeting" color="gray"
        [ngClass]="{ 'outlineBtn': activeFilter == 'impacted' }">
      </app-btn-reg-stats>
      <app-btn-reg-stats [loading]="loading" [count]="cloudUserSummary?.vip_users" label="VIP Users" color="gray"
        (click)="setFilter('vipusers')" [ngClass]="{ 'outlineBtn': activeFilter == 'vipusers' }">
      </app-btn-reg-stats>
      <app-btn-reg-stats [loading]="loading" [count]="cloudUserSummary?.users_with_d_plugin" label="Users With Plugin"
        color="gray" (click)="setFilter('userswithdesktopplugin')"
        [ngClass]="{ 'outlineBtn': activeFilter == 'userswithdesktopplugin' }">
      </app-btn-reg-stats>
    </div>
    <hr>
  </div>


  <div class="cloud-all-user-stats__three-columns">
    <h5>
      Company Information
    </h5>
    <div class="cloud-all-user-stats__three-columns-container">
      <app-btn-api class="not-button" color="gray" isbutton="not"></app-btn-api>
      <app-btn-reg-stats class="not-button" [count]="cloudUserSummary?.on_call" label="Users Online" color="gray"
        isbutton="not" [loading]="loading">
      </app-btn-reg-stats>
      <app-btn-reg-stats class="not-button" [loading]="loading" [count]="cloudUserSummary?.total_users"
        label="All Users" color="gray" isbutton="not">
      </app-btn-reg-stats>
    </div>
  </div>


</div>

<!-- 
<div class="row">
  <div class="col-12 pl-0 pr-1 my-2">
    <hr />
  </div>
</div>
<div class="row">
  <div class="col-12 pl-1 pr-1">
    <h5>Today</h5>
    <app-btn-long-meetings [count]="cloudUserSummary?.scheduled_meetings" isbutton="not"></app-btn-long-meetings>
  </div>
</div>
<div class="row">
  <div class="col-12 my-2 pl-0 pr-1">
    <hr />
  </div>
</div>

<div class="row">
  <div class="col-12 pl-1">
    <h5>Company Information</h5>
  </div>
  <div class="col-4 pl-0 pr-1">
    <app-btn-api color="gray" isbutton="not"></app-btn-api>
  </div>
  <div class="col-4 pl-0 pr-1">
    <app-btn-reg-stats [count]="cloudUserSummary?.on_call" label="Users Online" color="gray" isbutton="not"
      [loading]="loading">
    </app-btn-reg-stats>
  </div>
  <div class="col-4 pl-0 pr-1">
    <app-btn-reg-stats [loading]="loading" [count]="cloudUserSummary?.total_users" label="All Users" color="gray"
      isbutton="not">
    </app-btn-reg-stats>
  </div>
</div> -->