import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { UserIdleService } from 'angular-user-idle'
import { Subscription } from 'rxjs'
import { AuthService } from 'src/app/services/auth.service'

@Component({
	selector: 'app-user-idle-dialog',
	templateUrl: './user-idle-dialog.component.html',
	styleUrls: ['./user-idle-dialog.component.scss'],
})
export class UserIdleDialogComponent implements OnInit, OnDestroy {
	timerCount: number = 20
	timerCountSubscription: Subscription

	constructor(
		private dialogRef: MatDialogRef<UserIdleDialogComponent>,
		// @Inject(MAT_DIALOG_DATA) private data: any,
		private auth: AuthService,
		private userIdle: UserIdleService
	) {}

	ngOnInit(): void {
		if (!this.timerCountSubscription || this.timerCountSubscription?.closed)
			this.timerCountSubscription = this.auth?.timerCountBehaviorSubject?.subscribe(count => {
				this.timerCount = 20 - count
			})
	}

	onContinueSession(): void {
		this.userIdle.stopTimer()
		this.closeDialog()
	}

  onCloseSession(): void {
		this.auth.logout()
		this.closeDialog()
	}

	closeDialog(): void {
		this.timerCountSubscription?.unsubscribe()
		this.dialogRef.close(true)
	}

	ngOnDestroy(): void {
		this.timerCountSubscription?.unsubscribe()
	}
}
