<div class="video-remote-support">
  <div class="video-remote-support__title">
    <h6>Remote Suport Integration</h6>
  </div>

  <div class="video-remote-support__img-button-container">
    <div class="video-remote-support__img">
      <img src="{{Image1}}" alt="">
    </div>
    <div class="video-remote-support__button-text">
      <div class="video-remote-support_text">
        Activation Status: {{activationState}}
      </div>
      <div class="video-remote-support_text">
        Enabled : {{isEnabled}}
      </div>
      <div class="video-remote-support_text">
        GoTo Accepted : {{isToCAccepted}}
      </div>
    </div>

  </div>


</div>