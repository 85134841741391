<div class="cloud-user-reboot">
  <div class="cloud-user-reboot__btn_ctr">
    <button class="cloud-user-reboot__btn" (click)="showConfirmation = true">
      Reboot PC
    </button>
  </div>
  <div class="cloud-user-reboot__comp-container">
    <div *ngIf="showRebootMessage" class="cloud-user-reboot__sync-message {{ messageclass }}">
      {{ succesMessage }}
    </div>

    <div class="cloud-user-reboot__confirmation-container" *ngIf="showConfirmation">
      <div class="">
        Are you sure you want to reboot your computer?
      </div>
      <div class="cloud-user-reboot__confirmation">
        <button class="cloud-user-reboot__btn" (click)="rebootDesktopPlugin()">
          Confirm
        </button>
        <button class="cloud-user-reboot__btn" (click)="showConfirmation = false">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>