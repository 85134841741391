<div class="iot-history">
    <div [ngClass]="{ 'iot-history__align-left': shouldShowTable }" class="iot-history__btn-ctr">
        <div *ngIf="!shouldShowTable" class="iot-history__btn" (click)="getIotIncidentList()">
            back
        </div>
        <div class="iot-history__print">
            <i class="fa fa-print fa-2x menu-icon" (click)="printTable(shouldShowTable)"></i>
        </div>
    </div>

    <div id="table_1" class="table-1" *ngIf="shouldShowTable">
        <table class="table">
            <thead>
                <tr>
                    <th class="device-health-history-thead actioned-thead">Actioned</th>
                    <th class="device-health-history-thead">Incident ID</th>
                    <th class="device-health-history-thead">Start Time</th>
                    <th class="device-health-history-thead">End Time</th>
                    <th class="device-health-history-thead-events">Details</th>
                </tr>
            </thead>
            <tbody *ngFor="let incident of iotIncidentList; index as idx">
                <tr>
                    <td class="text-center">
						<input type="checkbox" [checked]="incident?.actioned === 1" disabled />
						<i class="fa fa-comments-o m-1 pointer" (click)="onShowMessages(incident?.incident_id, idx)"></i>
					</td>
                    <td>I-{{ incident.incident_id }}</td>
                    <!-- <td>{{ incident.start_time | dateTimeZone : 'l, hh:mm a'}}</td> -->
                    <td>{{ incident.start_time | dateTimeZone : 'MM-DD-YYYY hh:mma'}}</td>
                    <td>{{ incident.end_time | dateTimeZone : 'MM-DD-YYYY hh:mma'}}</td>
                    <td class="details-container">
                        <div class="details-container__trigger" *ngFor="let detail of incident.event_details">
                            <div><label>Trigger:</label>{{ detail.trigger }}</div>
                            <div>
                                <label>Start:</label>{{ detail.start_date | dateTimeZone : 'MM-DD-YYYY hh:mma'}} <label>Failed Polls:</label>{{ detail.pulls
                                }}
                            </div>
                            <div>
                                <span *ngIf="detail.end_date !== '--'"><label>End:</label>{{ detail.end_date | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
                                </span><label>Last
                                    Failed Poll:</label>{{ detail.last_pull | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="shouldShowLoaderBtn" class="iot-history__loader">
        <img src="../../../assets/img/vs_img/btn_loader.gif" alt="" />
    </div>
</div>