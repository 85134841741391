import { Subscription } from 'rxjs'

import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { GetCloudService } from '../../../services/get-cloud.service'
import { PopupTypeService } from '../../../services/popupType.service'
import { RefreshApiService } from '../../../services/refresh-api.service'
import { ZoomPluginActionService } from '../../../services/zoom-plugin-action.service'

@Component({
  selector: 'app-cloud-room-panel',
  templateUrl: './cloud-room-panel.component.html',
  styleUrls: ['./cloud-room-panel.component.scss'],
})
export class CloudRoomPanelComponent implements OnInit, OnDestroy {
  PopupTitle: string = 'CLOUD ROOM';
  zoomRoomDetails : any;

  id: any;
  pluginId: any;
  cloudApiData: any = [];
  apiTimers: any;
  refreshData: any;
  filterListner: Subscription;
  liveParticipants: any;
  dataReceived = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getCloudUserService: GetCloudService,
    private refreshApiService: RefreshApiService,
    public zoomPluginAction: ZoomPluginActionService,
    public ds: PopupTypeService
  ) {
  
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    // this.getcloudApiData();
  
    });

    // This recieves the update data boolean to start the timer
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      this.refreshData = m;
      this.getcloudApiData();
    });
  }

  ngOnInit() {
    // this.getcloudApiData();
    this.getcloudApiData();
    
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  getcloudApiData = () => {
    this.cloudApiData = [];
    this.getCloudUserService.fetchApiCloudRoomDetails(this.id).subscribe((data: any) => {
     const [res] = data.response;
      this.zoomRoomDetails = { 
        ...res,
        plugin_host_info: this.checkForNullValue(res.plugin_host_info),
        plugin_cpu_info: this.checkForNullValue(res.plugin_cpu_info),
        plugin_network_adapters: this.checkForNullValue(res.plugin_network_adapters),
        plugin_disk_info: this.checkForNullValue(res.plugin_disk_info),
        plugin_memo_info: this.checkForNullValue(res.plugin_memo_info),
      }
      
      
    console.log('this.zoomRoomDetails: ', this.zoomRoomDetails)
   
      
      // this.cloudRoomStats = data['response'][0];
      // this.cloudRoomStats.plugin_host_info =  this.cloudRoomStats.plugin_host_info !== null ? this.cloudRoomStats.plugin_host_info[0]  : null;
      // this.cloudRoomStats.plugin_cpu_info = this.cloudRoomStats.plugin_cpu_info !== null ? this.cloudRoomStats.plugin_cpu_info[0]   : null;
      // this.cloudRoomStats.plugin_network_adapters =this.cloudRoomStats.plugin_network_adapters !== null  ? this.cloudRoomStats.plugin_network_adapters[0]   : null;
      // this.cloudRoomStats.plugin_disk_info = this.cloudRoomStats.plugin_disk_info !== null ? this.cloudRoomStats.plugin_disk_info[0]  : null;
      // this.cloudRoomStats.plugin_memo_info = this.cloudRoomStats.plugin_memo_info !== null ? this.cloudRoomStats.plugin_memo_info[0]  : null;
      
      // this.pluginId = this.cloudRoomStats.plugin_id;
      // this.liveParticipants = this.cloudRoomStats.participants;
      this.PopupTitle = 'Room: ' + this.zoomRoomDetails.room_name;
      this.dataReceived = true;
    });
  }
  
  checkForNullValue = (roomDetail) => {
    if(roomDetail != null) {
      const [index] = roomDetail;
      return index;
    }
    
    return null;
  }

  onCloudEvent = ($event) => {
    if ($event === 'refreshCloudData') {
      this.getcloudApiData();
    }
  };
}
