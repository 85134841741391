import { Subscription } from 'rxjs'
import { GetAudioService } from 'src/app/services/get-audio.service'
import { TableFilterService } from 'src/app/services/table-filter.service'

import { HttpClient } from '@angular/common/http'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'

import { environment } from '../../../../environments/environment'
import { GetVideoService } from '../../../services/get-video.service'
import { RefreshApiService } from '../../../services/refresh-api.service'

@Component({
  selector: 'app-audio-allsites-panel',
  templateUrl: './audio-allsites-panel.component.html',
  styleUrls: ['./audio-allsites-panel.component.scss'],
})
export class AudioAllsitesPanelComponent implements OnInit, OnDestroy {
  server_url: string = environment.serverUrl;
  PopupTitle: string = 'Audio Sites';
  public audioSitesSummary;
  public audiositesStats;
  public audiositesInfo;
  id: number;
  audioApiData: any = [];
  audioDeviceApiSummary: any = [];
  apiTimers: any;
  refreshData: any;
  filterListner: Subscription;
  audioSitesListData: MatTableDataSource<any>;
  baseFilter: any = 'all';
  loading: any = true;
  loader = environment.config.dynamicImage.loader;

  constructor(
    public http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private getVideoDataService: GetVideoService,
    private getAudioDataService: GetAudioService,
    private refreshApiService: RefreshApiService,
    private tableFilterService: TableFilterService
  ) {
    this.audioSitesListData = new MatTableDataSource();

    // This recieves the update data boolean to start the timer
    this.filterListner = this.refreshApiService
      .refreshAPIListen()
      .subscribe((m: any) => {
        // console.log('refresh data listener');
        this.refreshData = m;
        this.getApiData();
      });

    if (this.router.url == '/audio/impactedsites') {
      this.baseFilter = 'impacted';
    } else if (this.router.url == '/audio/allsites') {
      this.baseFilter = 'all';
    }

    this.filterListner = this.tableFilterService
      .listen()
      .subscribe((m: any) => {
        if (m == '') {
          if (this.router.url == '/audio/impactedsites') {
            this.baseFilter = 'impacted';
          } else if (this.router.url == '/audio/allsites') {
            this.baseFilter = 'all';
          }
        } else {
          this.baseFilter = m;
        }
        this.audioSitesListData.filter = '-'.trim().toLocaleLowerCase();
      });

    this.audioSitesListData.filterPredicate = (data: any, filter: string) => {
      var dataStr = data.site_name + data.company_name + data.health;
      if (this.baseFilter == 'impacted') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.impacted;
        } else {
          return (
            data.impacted &&
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'warning') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health > 31 && data.health < 76;
        } else {
          return (
            data.health > 31 &&
            data.health < 76 &&
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'healthy') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health > 76;
        } else {
          return (
            data.health > 76 &&
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'all') {
        if (filter == '-' || filter == '' || filter == null) {
          return true;
        } else {
          return (
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'on_call') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.on_call > 0;
        } else {
          return (
            data.on_call > 0 &&
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'impactedOnly') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health < 76;
        } else {
          return (
            data.health < 76 &&
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'teams_nc') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.ms_team_non_compliant > 0;
        } else {
          return (
            data.ms_team_non_compliant > 0 &&
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'zoom_nc') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.zoom_non_compliant > 0;
        } else {
          return (
            data.zoom_non_compliant > 0 &&
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'webex_nc') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.webex_non_compliant > 0;
        } else {
          return (
            data.webex_non_compliant > 0 &&
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      }
    };
  }

  getApiData() {
    if (this.refreshData) {
      this.getAudioApiData();
    }
  }

  accessLevel: any;

  ngOnInit() {
    // console.log('audio sites component')
    this.getAudioApiData();
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  // This function pulls All Audioo Sites data from API Server
  getAudioApiData() {
    this.getAudioDataService.apiGetAudioCompanyInfo().subscribe((data) => {
      this.audiositesInfo = data['response'];
    });

    this.getAudioDataService.apiGetAllAudioSites().subscribe((data) => {
      // This is to load data into an array for in order to create a searchable filter table
      this.audioApiData = data['response'];

      let arr = [];
      for (let d in this.audioApiData) {
        var siteName = this.audioApiData[d]['site_name'].toString();
        arr.push(this.audioApiData[d]);
      }
      this.audiositesStats = data;
      this.audioSitesListData.data = this.audioApiData;
      this.loading = false;
    });

    //GET Request for All Video Site Stats Summary
    this.getAudioDataService.apiGetAudioSitesSummary().subscribe((data) => {
      this.audioSitesSummary = data['response'][0];
    });
  }

  getStats() {
    this.getAudioDataService.fetchAudioSummary().subscribe((data) => {
      this.audioSitesSummary = data[0];
    });

    this.getVideoDataService.fetchVideoSites().subscribe((data) => {
      // This is to load data into an array for in order to create a searchable filter table
      let arr = [];
      for (let d in data) {
        arr.push(data[d]);
      }

      this.audiositesStats = data;
    });
  }
}
