<div class="popup-content">
    <div class="container-fluid" style="height: 500px; width: 100%">
        <br>
         <h2 *ngIf="beingFetched" class="center">
            <img src="assets/img/vs_img/loader.gif">
        </h2>
        <div *ngIf="!beingFetched">
            <h4 style="text-align: center">{{ heading }}</h4>
            <div class="col-md-12" *ngIf="callData.length == 0">
                <p style="text-align: center">No Calls</p>
            </div>
            <br>
            <div class="col-md-12" *ngIf="callData.length > 0">
                <table class="table table-hover table-sm bg-white mt-1 table-fixed text-center" style="padding-bottom:100px;">
                    <tr>
                        <th>Call ID</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Duration</th>
                        <th>Type</th>
                        <th>Address</th>
                        <th>MOS</th>
                        <th>Incidents</th>
                    </tr>
                    <tr *ngFor="let call of callData" 
                        [ngClass]="{ 'incident': call.total_incidents !== 0 }">
                        <td>{{call.audio_call_id }}</td>
                       <!-- <td>{{printTime(call.start_time)}}</td>
                        <td>{{printTime(call.end_time)}}</td> -->
                        <td>{{call.start_time | dateTimeZone : 'MM-DD-YYYY hh:mma'}}</td>
                        <td>{{call.end_time | dateTimeZone : 'MM-DD-YYYY hh:mma'}}</td>
                        <td>{{printDuration(call.call_duration)}}</td>
                        <td>{{call.call_type == "-" || call.call_type == null ?"--":call.call_type}}</td>
                        <td>{{ getSip(call.address) }}</td>
                        <td>{{call.total_mos}}</td>
                        <td>{{call.total_incidents}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>