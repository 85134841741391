<div class="device-history">
  <app-loader *ngIf="isLoading"></app-loader>
  <div *ngIf="!isLoading" id="device-history-container">
    <table *ngIf="isShowTableData" class="table table-fixed device-health-history-table">
      <thead>
        <tr>
          <th class="device-history-thead">Type</th>
          <th class="device-history-thead">Time</th>
        </tr>
      </thead>
      <tbody *ngFor="let event of eventHistoryList">
        <tr scope="row">
          <td class="{{ getEventColor(event.type) }}">{{ event.type }}</td>
          <td>{{ formatDate(event.timestamp) }}</td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!isShowTableData" class="device-history-nodata">
      <h1>No Data Available</h1>
    </div>
  </div>
</div>
