<div class="login" [ngStyle]="visibilityBackground">
  <div class="login__col">
    <div [ngClass]="!showLoader?'login__box':''">
      <ng-container *ngIf="!sso?.code">
        <img [src]="dashboardLogo" />
        <div class="login__register-message" *ngIf="message2.flag && message2.type == 1">
          <div class="image-container">
            <img src="../../../../assets/img/icon_llama.png" />
          </div>
          <div>
            Thank you for registering
          </div>
          <div>
            Please check your email to verify your account
          </div>
        </div>

        <div class="login__register-message" *ngIf="message2.flag && message2.type == 2">
          <div class="image-container">
            <img src="../../../../assets/img/icon_llama.png" />
          </div>
          <div>
            Thank you for registering
          </div>
        </div>

        <input
          class="{{ formControl.email.style }}"
          type="text"
          placeholder="email"
          value="email"
          [(ngModel)]="email"
        />

        <input
          class="{{ formControl.password.style }}"
          type="password"
          placeholder="password"
          value="password"
          [(ngModel)]="password"
        />

        <div class="login__message" *ngIf="message.flag">
          {{ message.text }}
        </div>
        <button class="btn btn-block login__btn-success" (click)="validateForm()">
          Login
        </button>

        <div class="login__links">
          <a href="{{ links.terms }}" target="_blank">
            {{ dTerms }}
          </a>
        </div>

        <div class="login__or">
          or
        </div>
        <button *ngIf="sso.enabled" type="button" class="btn btn-block btn-outline-primary text-primary mb-2" (click)="onClickSSOLogin()">
          <i class="fa fa-key" aria-hidden="true"></i>
          SSO
        </button>
        <button class="btn btn-block login__btn-primary" routerLink="/registration">
          Create Account
        </button>

        <div class="login__links">
          <a routerLink="/forgotpassword">Forgot password?</a>
        </div>
      </ng-container>
      <!-- THIS BLOCK IS FOR SSO CALLBACK -->
      <ng-container *ngIf="sso?.code">
        <div class="loader-img" *ngIf="showLoader">
          <img [src]="loader"> 
        </div>
        <div class="text-center" *ngIf="sso?.errorMsg">
          <h2 class="text-center mb-5">Oops!</h2>
          <p>An unexpected error occurred during the sign-in process:</p>
          <p class="text-danger">{{ sso?.errorMsg }}</p>
          <p>
            Please accept our apologies for any inconvenience caused. To resolve this issue, kindly fix the problem or
            contact our support team.
          </p>
          <p>Click <a routerLink="/">here</a> to reattempt the sign-in process.</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>