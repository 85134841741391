import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AlertService } from 'src/app/_alert'
import { AuthService } from 'src/app/services/auth.service'
import { GetIotService } from 'src/app/services/get-iot.service'

@Component({
  selector: 'app-logitech-rescan',
  templateUrl: './logitech-rescan.component.html',
  styleUrls: ['./logitech-rescan.component.scss']
})
export class LogitechRescanComponent implements OnInit, OnChanges {
  oldPorts: any[];
  @Input() data: any;
  @Input() isRefresh: any;
  @Output('resetEvent') resetEvent: EventEmitter<any> = new EventEmitter<any>();;
  devicePorts: any;
  isShowSync = false;
  apiKey: String = '';
  apiName: String = '';
  accountUrl: String = '';
  active: number;
  domotz_account_id: number;
  company_id: number;
  shouldShowAlerts: Boolean = false;
  syncMessage: any = 'Device Sync Success!';
  showSyncMessage: boolean = false;
  messageclass: any = 'iotadmin__sync-message--success';
  shouldShowDocuments: boolean = false;
  showRescanDevice: any = false;
  propData: any;
  
  disableUpdatePortButton: any = false;
  showUpdatePortsMessage: any = "Updating will replace your old ports to the new ports";
  showPortsForm: boolean = true;

  errorMessage: any = 'Could not find device. Please try again.';
  showSearching: any = false;
  showAddDevice: any = false;
  showErrorMessage: any = false;

  siteList: any = [];
  nmapResult: any;
  deviceMac: any;
  deviceMake: any;
  collectorId: any = "";
  iotType: any = "";
  showMsg30Sec: boolean = false;
  iotDeviceId: any = "";
  deviceName: any;
  deviceIP: any;
  setTimer: any

  actionDeviceName: any = '';
  actionDeviceIP: any = '';
  showSuccessMsg:any =false;
  showSiteListErrMsg: any = false;
  
  errorMsg: any = "Device Not Found";
  showErrMsg: any = false;
  logi_data: any;

  actionCount: any = 0;
  showDeleteDevice: boolean = false;
  siteId: any;
  iotDeviceIP: any;
  iotDeviceMac: any;
  iotDeviceName: any;
  iotDeviceMake: any;
  showRenameDevice: boolean = false;
  alreadyEditingInProcess: boolean= false;
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  iot_id: any;
  iotHostName: any
  constructor(
    private GetIot: GetIotService,
    private Auth: AuthService,
     private _alertService: AlertService
  ) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.resetAll();
    this.setProps();
    this.checkPort()
  }
  checkPort() {
    if (this?.data?.paused && this.devicePorts?.length) {
      this.devicePorts.map((x) => x.is_monitored = 0);
    }
  }
  setProps = () => {
    const { collector_id, ipv4, site_id, logitech_data} = this.data;
    this.collectorId = collector_id;
    this.actionDeviceIP = ipv4;
    this.siteId = site_id;
    this.logi_data = logitech_data;
    this.iot_id = this.logi_data ? this.logi_data?.iot_id : null;
    this.iot_id?this.getIotLitePortsByIotId():this.discoverDevice();
  }
  onChangePortName = (value, index) => {
    this.devicePorts[index].service.name = value;
    this.disableUpdatePortButton = false;
    this.showUpdatePortsMessage = "";
    this.checkPortsAreIdentical();
  }
  onChangePortMonitored = (event, index) => {
    var value = 0;
    if(event?.target?.checked) {
      value = 1;
    }
    this.devicePorts[index].is_monitored = value;
    this.disableUpdatePortButton = false;
    this.showUpdatePortsMessage = "";
    this.checkPortsAreIdentical();
  }
  checkPortsAreIdentical = () => {
    // const oldPortsNew = JSON.stringify(this.oldPorts.map(e =>  e.port_id ));
    // const newPortsNew = JSON.stringify(this.devicePorts.map(e =>  parseInt(e.portid) ));
    // if(oldPortsNew == newPortsNew) {
    //   this.disableUpdatePortButton = true;
    //   this.showUpdatePortsMessage = "Note: Cant update because ports are identical";
    // }
    if(this.devicePorts.length < 1){
      return false;
    }
    
    if (this.oldPorts.length !== this.devicePorts.length) {
      return false;
    }
    this.oldPorts.sort((a, b) => a.port_id - b.port_id);
    this.devicePorts.sort((a, b) => a.portid - b.portid);

    for (let i = 0; i < this.oldPorts.length; i++) {
      if (this.oldPorts[i].port_id !== parseInt(this.devicePorts[i].portid) || this.oldPorts[i].port_name !== this.devicePorts[i].service.name || this.oldPorts[i].is_monitored !== this.devicePorts[i].is_monitored) {
        return false;
      }
    }
    this.disableUpdatePortButton = true;
    this.showUpdatePortsMessage = "No new port found.";
    return true;
  }
  updatePorts = () => {
  if(this.devicePorts.length > 1) {
    this.devicePorts.map((item) => {
      item.portname = item.service.name;
    })
  }

    const newPorts = JSON.stringify(this.devicePorts);
    this.GetIot.updateIotLitePorts(
      this.iot_id,
      newPorts,
      this.data.video_device_id,
      true
      ).subscribe((data) => {
        // console.log('updateIotLitePorts: ', data);
        this.disableUpdatePortButton = true;
        this.showUpdatePortsMessage = "Device Ports Successfully Updated!";
        this.showPortsForm = false;
        this.setTimer = setTimeout(() => {
        this.disableUpdatePortButton = false;
          this.resetAll();
          this.showUpdatePortsMessage = "Updating will replace your old ports to the new ports";
        }, 2500);
    });
  }
  resetAll = () => {
    this.showRescanDevice = false;
    this.showAddDevice = false;
    this.shouldShowDocuments = false;
    this.showRenameDevice = false;
    this.showDeleteDevice = false;
    this.showPortsForm = true;

  }
  discoverDevice = () => {
    // edit later
    this.Auth.getNmap(this.collectorId, this.actionDeviceIP,this.iot_id?'update':'add', this.siteId).subscribe((data) => {
      // console.log('getNmap: ', data)
      let [res] = data.response;

      if(res.status == "OK") {
        this.checkStatus(res.action_id);
        this.showSearching = true;
      } else {
        this.errorMsg = res.message;
        this.showErrMsg = true;
        this.setTimer = setTimeout( () => {
          this.showErrMsg = false;
        }, 2000);
      }

    }, 
    (err) => {
      this.showSearching = false;
     })
  }

  checkStatus = (action_id) => {
    this.Auth.postDeviceActionId({ action_id }).subscribe((actionData) => {
      // console.log('postDeviceActionId: ', actionData)
      // console.log('actionCount: ', this.actionCount)
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 120) {
        this.resetFields();
        this.showErrMsg = true;
        // this.setTimer = setTimeout( () => {
        //   this.showErrMsg = false;
        // }, 2000);
        return;
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus(action_id);
        }, 1000);
        this.actionCount++;
        if (this.actionCount == 30) {
          this.showMsg30Sec = true;

          // Since total time of waiting is 120 seconds and after 30 we show It iss taking longer then usual
          setTimeout(() => {
            this.showMsg30Sec = false;
          }, 70*1000); 
        }
      } else if (actionData.response[0].status === 'FAILED') {
        const [newData] = actionData.response
        // console.log('FAILED: ', actionData.response)
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        const [newData] = actionData.response;
        this.resetFields();
        this.showAddDevice = true;
        this.setData(newData.results);
        if(this.iot_id) this.checkPortsAreIdentical();

        return;
      }
    });
  }
  resetFields = () => {
    this.actionCount = 0;
    this.showSearching = false;
    this.showErrorMessage = false;
    this.showAddDevice = false;
    this.disableUpdatePortButton = false;
    this.showUpdatePortsMessage = "Updating will replace your old ports to the new ports";
    this.showMsg30Sec = false;
  }
   setData = (params) => {
    const res2 = JSON.parse(params.results);
    // console.log('res2: ',res2)
    const { nmap: { host } } = res2;
    if(host) {
      if(host.ports) {
        if(host.ports.port) {
          if (host.ports.port.length > 1) {
            if (!this.iot_id) {
              this.iotDeviceIP = this.getDeviceIp(host);
              this.iotDeviceMac = this.getDeviceMac(host);
              this.iotDeviceName = `${this.data.room_name} VISUAL+`;
              this.iotDeviceMake = this.getDeviceMake(host);
              this.iotHostName = this.getHostname(host);
            }
            this.devicePorts = host.ports.port 
          } else {
            console.log('only 1 port')
            this.devicePorts = [];
          }
        } else {
          this.devicePorts = [];
        }
      } else {
        this.devicePorts = [];
      }
    } else {
      this.devicePorts = [];
    }
    this.devicePorts.map((item) => {
      const index = this.oldPorts?.findIndex((port) => port.port_id == item.portid);
      if(index > -1) {
        item.service.name = this.replaceSpecialCharacther(this.oldPorts[index].port_name);
        item.existsInOldPorts = true;
        item.is_monitored = this.data&&this.data.paused?0:this.oldPorts[index].is_monitored;
      } else {
        item.existsInOldPorts = false;
        item.is_monitored = this.data&&this.data.paused?0:1;
      }
    })
   }
    replaceSpecialCharacther = (portname) => {
      return  portname.replace(/\W/g, "_");;
    }
   getIotLitePortsByIotId = () => {
    this.GetIot.getIotLitePortsByIotId(this.iot_id).subscribe((data) => {
      const res = data.response;
      if (!res?.length) this.iot_id = 0;
      this.oldPorts = res;
      this.discoverDevice();
    });
   };
   getDeviceIp = (host: any) => {
    if (Array.isArray(host.address)) {
      if (host.address[0].addr) {
        return host.address[0].addr;
      }
    } else {
      if (host.address.addr) {
        return host.address.addr;
      }
    }
    return 'null';
  }

  getDeviceMac = (host: any) => {
    if (Array.isArray(host.address)) {
      if (host.address[1].addr) {
        return host.address[1].addr;
      }
    }
    return 'null';
  }

  getHostname = (host: any) => {
    if (host.hostnames) {
      if (host.hostnames.hostname) {
        if (host.hostnames.hostname.name) {
          return host.hostnames.hostname.name;
        }
      }
    }
    return 'null';
  }

  getDeviceMake = (host: any) => {
    //check if its in the address
    if (Array.isArray(host.address)) {
      if (host.address[1].vendor) {
        return host.address[1].vendor;
      }
    }
    return 'Unknown';
  }
  addIotDeviceAndLink = () => {
    if(this.devicePorts.length > 1) {
      this.devicePorts.map((item) => {
        item.portname = item.service.name;
      })
    }
    const newPorts = JSON.stringify(this.devicePorts);
    this.GetIot.iotLiteAdd(
      this.collectorId,
      this.iotDeviceName,
      this.iotHostName,
      this.actionDeviceIP,
      newPorts,
      this.iotDeviceMac,
      "8",
      this.iotDeviceMake,
      this.siteId,
      true
    ).subscribe((data: any) => {
      console.log('this.data: ', data)
      const res = data.response;
      this.iotDeviceId = res.iot_id
      if (this.data.video_device_id && this.iotDeviceId) {
        this.linkIotToVideoDevice();
      } else {
        this.showErrorMessage = true;
        this.errorMessage = 'Device Added but Device Linking failed';
      }
    });
  }
  linkIotToVideoDevice = () => {
    this.GetIot
      .iotLiteLinkDevice(
        this.iotDeviceId,
        this.data.video_device_id,
        'video',
      )
      .subscribe(
        (data: any) => {
          // console.log('linkIotToVideoDevice: ', data)
          this.disableUpdatePortButton = true;
          this.showUpdatePortsMessage = "Device Successfull Added and Linked!";
          this.showPortsForm = false;
           this.setTimer = setTimeout(() => {
        this.disableUpdatePortButton = false;
          this.resetAll();
        }, 2500);
          // this.iotIp = 0;
        },
        (error) => {
          this.showErrorMessage = true;
          this.errorMessage = 'Someting went wrong when linking IoT Device';
        }
      );
  };
  cancel() {
    this.resetAll();
    this.resetEvent.emit();
  }
}
