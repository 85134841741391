<div class="main-menu-membership" [@popupMembers]="popupTypeState" *ngIf="popupType">
  <!-- PANEL BODY / OTHER LOGO-->
  <div class="main-menu-membership__panel-body">

    <div class="main-menu-membership__close-wrapper">
      <div class="main-menu-membership__close-item">
        <img (click)="closePopUp()" src="./assets/img/btn close_default.png" />
      </div>
    </div>

    <div *ngIf="popupType == 1">
      <div class="main-menu-membership__panel-heading">
        <img src="./assets/img/poly_logo.png" alt="" style="height: 40px;" />
      </div>
      <!-- <app-polycom-popup [runTutorial]="runTutorial"></app-polycom-popup> -->
      <app-polycom-main-menu></app-polycom-main-menu>
    </div>

    <div *ngIf="popupType == 2">
      <div class="main-menu-membership__panel-heading">
        <img src="./assets/img/cisco_logo.png@1X.png" alt="" style="height: 40px;" />
      </div>
      <app-cisco-popup [runTutorial]="runTutorial"></app-cisco-popup>
    </div>

    <div *ngIf="popupType == 36">
      <div class="main-menu-membership__panel-heading">
        <img src="./assets/img/main-menu/logitech.png" alt="" style="height: 40px;" />
      </div>
      <app-logitech-main-menu></app-logitech-main-menu>
    </div>

    <div *ngIf="popupType == 3">
      <div class="main-menu-membership__panel-heading">
        <div class="main-menu-membership__panel-heading-image-and-button">
          <img src="./assets/img/zoom_logo.png@1X.png" alt="" style="height: 30px;" />
          <div class="main-menu-membership__panel-heading-item">
            <div><i class="fa fa-info-circle"></i></div>
            <a [ngClass]="{ 'main-menu-membership__no-events': disabledGettingStartedLink }" href="{{links.zoom}}"
              target="_blank">
              Getting Started Guides
            </a>
          </div>
        </div>
      </div>
      <app-zoom-popup [popupState]="popupTypeState" [runTutorial]="runTutorial"
        [desktopClientDownloadLink]="desktopClientDownloadLink"></app-zoom-popup>
    </div>

    <div *ngIf="popupType == 4">
      <div class="main-menu-membership__panel-heading">BlueJeans</div>
      <app-not-available></app-not-available>
    </div>

    <div *ngIf="popupType == 5">
      <div class="main-menu-membership__panel-heading">Plantronics</div>
      <app-not-available></app-not-available>
    </div>

    <div *ngIf="popupType == 6">
      <div class="main-menu-membership__panel-heading">
        <div class="main-menu-membership__panel-heading-image-and-button">
          <img src="./assets/img/logo-microsoft-teams-2.png" alt="" style="height: 40px;" />
          <div class="main-menu-membership__panel-heading-item">
            <div><i class="fa fa-info-circle"></i></div>
            <a [ngClass]="{ 'main-menu-membership__no-events': disabledGettingStartedLink }" href="{{links.teams}}"
              target="_blank">
              Getting Started Guides
            </a>
          </div>
        </div>
      </div>
      <app-teams-main-menu [desktopClientDownloadLink]="desktopClientDownloadLink"></app-teams-main-menu>
    </div>

    <div *ngIf="popupType == 7">
      <div class="main-menu-membership__panel-heading">
        <img src="./assets/img/slack_logo.png@1X.png" alt="" style="height: 40px;" />
      </div>
      <app-slack-popup></app-slack-popup>
    </div>
    <div *ngIf="popupType == 8">
      <div class="main-menu-membership__panel-heading">Solarwinds</div>
      <app-not-available></app-not-available>
    </div>

    <div *ngIf="popupType == 9">
      <div class="main-menu-membership__panel-heading">
        <div class="main-menu-membership__panel-heading-image-and-button">
          <img src="{{ membershipSubscriptionLogo }}" alt="" style="height: 30px;" />
          <div class="main-menu-membership__panel-heading-item">
            <div><i class="fa fa-info-circle"></i></div>
            <a [ngClass]="{ 'main-menu-membership__no-events': disabledGettingStartedLink }" href="{{links.tutorial}}"
              target="_blank">
              Getting Started Guides
            </a>
          </div>
        </div>
      </div>
      <app-visone-popup [runTutorial]="runTutorial" (isShowCollectorInfoChange)="receiveMessage($event)"
        [keyDown]="keyDown" [isShowCollectorInfo]="isShowCollectorInfo" [showScheduleModal]="showScheduleModal"
        [desktopClientDownloadLink]="desktopClientDownloadLink"></app-visone-popup>
    </div>

    <div *ngIf="popupType == 10">
      <div class="main-menu-membership__panel-heading">Support</div>
      <app-support-form [popupState]="popupTypeState"></app-support-form>
    </div>

    <div *ngIf="popupType == 11">
      <div class="main-menu-membership__panel-heading">Account Management</div>
      <app-account></app-account>
    </div>

    <div *ngIf="popupType == 13">
      <div class="main-menu-membership__panel-heading">Reports</div>
      <app-reports [popupState]="popupTypeState"></app-reports>
    </div>

    <div *ngIf="popupType == 32">
      <div class="main-menu-membership__panel-heading">Device Admin</div>
      <app-rescan [popupState]="popupTypeState"></app-rescan>
    </div>

    <div *ngIf="popupType == 33">
      <div class="main-menu-membership__panel-heading">
        <img src="{{ mainMenuIotLogo }}" alt="" style="height: 25px;" />
      </div>
      <app-iot-device-account-config [popupState]="popupTypeState"></app-iot-device-account-config>
    </div>

    <div *ngIf="popupType == 34">
      <div class="main-menu-membership__panel-heading">Document Center</div>
      <app-company-documents></app-company-documents>
    </div>

    <div *ngIf="popupType == 35">
      <div class="main-menu-membership__panel-heading">
        <img src="./assets/img/vs_img/iotLogo.png" alt="" style="height: 25px;" />
      </div>
      <!-- <app-iot-device-account-config [popupState]="popupTypeState"></app-iot-device-account-config> -->
      <app-iot-lite-main-menu></app-iot-lite-main-menu>
    </div>
  </div>
</div>