import { GetCollectorService } from 'src/app/services/get-collector.service'

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

@Component({
	selector: 'app-collector-alert-panel',
	templateUrl: './collector-alert-panel.component.html',
	styleUrls: ['./collector-alert-panel.component.scss'],
})
export class CollectorAlertPanelComponent implements OnInit, OnChanges {
	@Input() data: any

	lowRam: any = true
	highCpu: any = true
	congestedDrives: any = true
	collectorOffline: any = true
	setTimer: any

	showSucessMessage: any = false

	constructor(private getCollector: GetCollectorService) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.data && this.data) {
			this.setData()
		}
	}

	setData = () => {
		if (this.data.alerts_json != null) {
			const newJson = JSON.parse(this.data.alerts_json)
			const { lowRam, highCpu, congestedDrives, collectorOffline } = newJson

			this.lowRam = lowRam === undefined ? true : !!lowRam
			this.highCpu = highCpu === undefined ? true : !!highCpu
			this.congestedDrives = congestedDrives === undefined ? true : !!congestedDrives
			this.collectorOffline = collectorOffline === undefined ? true : !!collectorOffline
		}
	}

	setAlert = flag => {
		let params = {
			lowRam: true,
			highCpu: true,
			congestedDrives: true,
			collectorOffline: true,
		}

		if (flag) {
			params = {
				lowRam: this.lowRam,
				highCpu: this.highCpu,
				congestedDrives: this.congestedDrives,
				collectorOffline: this.collectorOffline,
			}
		}

		const { collector_id } = this.data

		this.getCollector.setAlertsJson(collector_id, params).subscribe(
			(data: any) => {
				if (data.status == 200) {
					this.showSucessMessage = true
					this.setTimer = setTimeout(() => {
						this.showSucessMessage = false
					}, 1500)
				}
			},
			err => {}
		)
	}

	clearTimeout = () => {
		clearTimeout(this.setTimer)
	}
}
