<div class="speaker-confirmation-container">
    <div class="speaker-confirmation-text">Are you sure you want to change the volume to
        {{this.sliderValue.sliderValue}}? <br />
    </div>
    <div class="speaker-confirmation-button-container">
        <button class="form-control btn btn-primary" (click)="confirmAddVolume()">Save</button>
        <button class="form-control btn btn-primary" (click)="cancelAddVolume()">Cancel</button>
    </div>
    <div *ngIf="showWarningMessage" class="row alert alert-danger">
        Warning: Controller will go offline while your setting is changed.
    </div>
</div>