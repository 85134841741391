<div class="video-device-update-dialog">

    <div *ngIf="isShowUpdate" class="video-device-update-dialog__table">
        <div class="video-device-update-dialog__header">
            <h5>
                {{ headerMessage }}
            </h5>

            <div (click)="closeDialog()" class="link-desktop__header-close-button">
                <img src="./assets/img/iot/delete-btn.png" alt="" />
            </div>
        </div>
        <div>
            <table class="table">
                <thead>
                    <tr class="black">
                        <th scope="col">Mod</th>
                        <th scope="col">Update Stage</th>
                        <th scope="col">Update Status</th>
                        <th scope="col">Percentage</th>
                    </tr>
                </thead>
                <tbody>

                    <tr>
                        <td>SYSTEM</td>
                        <td>{{ checkParams(localSystem2?.softUpdateStage) }}</td>
                        <td>{{ checkParams(localSystem2?.softUpdateStatus) }}</td>
                        <td>{{ checkParams(localSystem2?.percentageComplete) }}</td>
                    </tr>

                    <tr>
                        <td>LOCAL SYSTEM</td>
                        <td>{{ checkParams(localSystem?.softUpdateStage) }}</td>
                        <td>{{ checkParams(localSystem?.softUpdateStatus) }}</td>
                        <td>{{ checkParams(localSystem?.percentageComplete) }}</td>
                    </tr>

                    <tr>
                        <td>CAMERA</td>
                        <td>{{ checkParams(localCamera?.softUpdateStage) }}</td>
                        <td>{{ checkParams(localCamera?.softUpdateStatus) }}</td>
                        <td>{{ checkParams(localCamera?.percentageComplete) }}</td>


                    </tr>

                    <tr>
                        <td>REMOTE</td>
                        <td>{{ checkParams(localCamera?.softUpdateStage) }}</td>
                        <td>{{ checkParams(localCamera?.softUpdateStatus) }}</td>
                        <td>{{ checkParams(localCamera?.percentageComplete) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div *ngIf="!isShowUpdate" class="video-device-update-dialog__table">
        <div class="video-device-update-dialog__header">
            <h5>
                {{ headerMessage }}
            </h5>

            <div (click)="closeDialog()" class="link-desktop__header-close-button">
                <img src="./assets/img/iot/delete-btn.png" alt="" />
            </div>
        </div>
        <div>
            <table class="table">
                <thead>
                    <tr class="black">
                        <th scope="col">Mod</th>
                        <th scope="col">Software version</th>
                        <th scope="col">Last Failed timestamp</th>
                        <th scope="col">Last Completed timestamp</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>SYSTEM</td>
                        <td>{{videoDetails.softwareinfo}} </td>
                        <td> -- </td>
                        <td>{{convertTimeStamp(videoDetails.completed_update_stamp)}}</td>
                    </tr>

                    <tr>
                        <td>CAMERA</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>

                    </tr>

                    <tr>
                        <td>REMOTE</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>

                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</div>