<div class="video-service-provider">
  <div class="video-service-provider__title">
    <h6>Service Provider Status</h6>
  </div>

  <div class="video-service-provider__img-container">
    <div class="video-service-provider__three-column">
      <div class="video-service-provider__three-column-item">
        <img src="{{teamsLogo}}" alt="">
      </div>
      <div class="video-service-provider__three-column-item">
        <img src="{{zoomsLogo}}" alt="">
      </div>
      <div class="video-service-provider__three-column-item">
        <img src="{{meetLogo}}" alt="">
      </div>

    </div>

    <div class="video-service-provider__three-column">
      <div class="video-service-provider__three-column-item">
        <img src="{{ringCentralLogo}}" alt="">
      </div>
      <div class="video-service-provider__three-column-item">
        <img src="{{tencentLogo}}" alt="">
      </div>
      <div class="video-service-provider__three-column-item">
        <img src="{{byodLogo}}" alt="">
      </div>

    </div>

  </div>

</div>