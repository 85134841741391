<button
  type="button"
  [ngClass]="{ 'not-button': isbutton == 'not' }"
  class="btn btn-reg not-button"
  class="btn-sm mb-2"
>
  <!-- <fa-icon [icon]="['far', faName]"></fa-icon> {{ label }} -->
  <fa-icon [icon]="faName"></fa-icon> {{ label }}
  <span *ngIf="showLoader" class="show-loader">
    <img src="../../../../assets/img/vs_img/btn_loader.gif" alt=""
  /></span>
</button>
