<div class="teams-room-panel">
  <div *ngIf="dataError" class="teams-room-panel__error-page">
    Error - no user data
  </div>

  <div *ngIf="!dataError">
    <div class="teams-room-panel__header">
      <app-panel-tools [PopupTitle]="PopupTitle"></app-panel-tools>
    </div>
    <!-- <div *ngIf="!isMonitoringEnabled" class="teams-room-panel__monitor-banner" (click)="routeToTeamns()">
        Monitoring Link is disabled.The information below is from the last successful sync at {{ lastSync }}
      </div> -->
      <div *ngIf="teamsDetails?.collector_status === -1" class="teams-user-panel__banner-secondary">
        {{ dCollector }} is offline. The information below is from the last successful sync at
        {{ lastSync }}
      </div>
    <div class="teams-room-panel__body">
      <div class="teams-room-panel__info">
        <app-teams-room-info [showLoader]="showLoader" [teamsDetails]="teamsDetails" (cloudEvent)="onCloudEvent($event)"
          [cloudRoomInfo]="teamsDetails" [logitechTap]="logitechTap"></app-teams-room-info>
      </div>

      <div class="teams-room-panel__stats-and-health">
        <div class="teams-room-panel__health-container">
          <div (click)="
              this.ds.sendPopupType(76, {
                deviceId: teamsID,
                is_room: true
              })
            " class="incident-button">
            Incidents
          </div>
          <app-system-health [isCursorPointer]="true" [healthNum]="teamsUserHealth"></app-system-health>
        </div>

        <app-teams-room-stats [teamsDetails]="teamsDetails" [spaceStatus]="spaceStatus" [logitechSync]="logitechSync"
          [logitechScribe]="logitechScribe"></app-teams-room-stats>
      </div>
    </div>
  </div>
</div>