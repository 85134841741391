<div>
  <app-old-forgot-password
    *ngIf="!loginEnv"
    [message]="message"
    (data)="forgotEmitEvent($event)"
  ></app-old-forgot-password>

  <app-new-forgot-password
    *ngIf="loginEnv"
    [message]="message"
    (data)="forgotEmitEvent($event)"
  ></app-new-forgot-password>
</div>
