<div class="row right-panel">
		
	<div class="col-12">
		<app-panel-tools [PopupTitle]="PopupTitle"></app-panel-tools>
	</div>
	<div class="col-12">
		<div class="row">
			<div class="col-6 pl-1">
				<app-audio-site-info-panel [audioSiteInfo]="audioSiteInfo"></app-audio-site-info-panel>
			</div>
			<div class="col-6">
				<div class="col-12">
					<app-system-health [healthNum]="audioSiteSummary?.health"></app-system-health>
				</div>
				<app-audio-allsites-stats-panel [audioSitesSummary]="audioSiteSummary"></app-audio-allsites-stats-panel>
			</div>
		</div>
		<div class="row" style="top: -40px;position: relative;margin-bottom: -40px; padding-top:2%;">
			<div class="col-12 px-1 filter-table" >
				<app-audio-all-devices-list [audioDevicesListData]="audioDevicesListData"></app-audio-all-devices-list>
			</div>
		</div>
	</div>
</div>