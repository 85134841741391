import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qosRound',
})
export class QosRoundPipe implements PipeTransform {
  transform(num: any): any {
    const numString = num.toString().split('.');

    if (numString[0] === '0') {
      return '--';
    }

    if (numString.length === 1) {
      return num;
    }
    return num.toFixed(2);
  }
}
