<div class="video-device-health">
  <div class="video-device-health__title">
    <h5>Device(s) Health</h5>
  </div>


  <div class="video-device-health__button-container">
    <app-btn-device-health [count]="videoDeviceData?.total_mics"
      [mic_impacted]="videoDeviceData?.total_mics - videoDeviceData?.active_mics"
      [mic_connected]="videoDeviceData?.active_mics" [micModel]="videoDeviceData?.device_type"
      [muteStatus]="videoDeviceData?.mute" [videoDeviceId]="videoDeviceData?.video_device_id"
      [micCallStatus]="videoDeviceData?.oncall" [deviceStatus]="videoDeviceData?.online" label="total">
    </app-btn-device-health>
    <app-btn-device-camera-health [count]="videoDeviceData?.total_cameras"
      [camera_impacted]="videoDeviceData?.total_cameras - videoDeviceData?.active_cameras"
      [camera_connected]="videoDeviceData?.active_cameras" label="total"></app-btn-device-camera-health>
  </div>



</div>


<!-- <div class="row">
  <div class="col-12 px-0">
    <h5>Device(s) Health</h5>
  </div>
</div>

<div class="row">
  <div class="col-6 pl-0 pr-1">
    <app-btn-device-health [count]="videoDeviceData?.total_mics"
      [mic_impacted]="videoDeviceData?.total_mics - videoDeviceData?.active_mics"
      [mic_connected]="videoDeviceData?.active_mics" [micModel]="videoDeviceData?.device_type"
      [muteStatus]="videoDeviceData?.mute" [videoDeviceId]="videoDeviceData?.video_device_id"
      [micCallStatus]="videoDeviceData?.oncall" [deviceStatus]="videoDeviceData?.online" label="total">
    </app-btn-device-health>
  </div>
  <div class="col-6 pl-1 pr-0">
    <app-btn-device-camera-health [count]="videoDeviceData?.total_cameras"
      [camera_impacted]="videoDeviceData?.total_cameras - videoDeviceData?.active_cameras"
      [camera_connected]="videoDeviceData?.active_cameras" label="total"></app-btn-device-camera-health>
  </div>
</div> -->