import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { VisoneSiteService } from '../../services/get-visone-installedsites.service';
import { PopupTypeService } from '../../services/popupType.service';
import { InputValidationService } from 'src/app/services/input-validation.service'

@Component({
  selector: 'app-create-virtual-site',
  templateUrl: './create-virtual-site.component.html',
  styleUrls: ['./create-virtual-site.component.scss'],
})
export class CreateVirtualSiteComponent implements OnInit, OnDestroy {
  @Input() popUpEmitter: any;

  userIndex: any = -1;
  popupType: number;
  setoverlay: string = ''; //create
  firstname: any = '';
  lastname: any = '';
  email: any = '';
  phone: any = '';
  sitename: any = '';
  user_id: any = -1;
  parent_id: any = '';
  allsite$;
  allDeviceListData = [];
  allSiteListData = [];
  scrollCollector = environment.config.dynamicImage.scrollCollector;
  company_id: number;
  passfeedback: any;
  passfeedback2: any;

  getId: any;
  getValue: any;
  userFirstname: any;
  userLastname: any;
  userEmail: any;
  userPhone: any;
  getIndex: any = -1;
  fname: any;
  siteForm: FormGroup;
  submitted = false;
  model: any = {};

  userApiData: any = [];

  wellnessCheckTime: any = { hour: 1, minute: 30, second: 0 };
  wellnessCheckEnable: any = false;
  wellnessConvertedTime: any;
  showWellnessComponent: any = false;
  enableInProduction: any = environment.config.enableInProduction;
  showWellnessInProd: any = false;
  companyId: any;

  setTimer: any;
  showSuccessMessage: any = false;

  searchObject = {
    searchValue: '',
    searchIncident: '',
    startDate: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() - 7,
    },
    endDate: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    },
    startTime: { hour: 0, minute: 0, second: 0 },
    endTime: { hour: 23, minute: 59, second: 0 },
  };

  constructor(
    private allsitesService: VisoneSiteService,
    public http: HttpClient,
    private Auth: AuthService,
    private router: Router,
    private ds: PopupTypeService,
    private formBuilder: FormBuilder,
    private inputValidationService: InputValidationService
  ) {}

  fetchVisoneSites() {
    this.allsite$ = this.allsitesService.fetchVisoneSites();
  }

  ngOnInit() {
    this.getUserApiData();
    this.checkForm();
    this.onChanges();
    this.getCompanyId();
  }

  setWellnessProd = (enableInProd, companyId) => {
    return enableInProd && (companyId == 15 || companyId == 36);
  };

  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.companyId = decodedToken.company_id;
  };

  // convenience getter for easy access to form fields
  get f() {
    return this.siteForm.controls;
  }

  checkForm() {
    this.siteForm = this.formBuilder.group({
      parent_id: ['', [Validators.required]],
      sitename: ['', [Validators.required, Validators.maxLength(30)]],

      firstname: ['', [Validators.required, Validators.maxLength(30)]],
      lastname: ['', [Validators.required, Validators.maxLength(30)]],
      email: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(50)],
      ],
      phone: ['', [Validators.required, Validators.maxLength(25) , this.inputValidationService.customPhoneValidator]],
      user_id: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.sitename = (<HTMLInputElement>(
      document.getElementById('sitename')
    )).value;
    (<HTMLInputElement>document.getElementById('feedback')).innerHTML = '';
    this.submitted = true;

    if (this.siteForm.invalid) {
      return;
    } else {
      this.registerSite();
    }
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }

  getUserApiData() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = {
      company_id: decodedToken.company_id,
    };

    // this.userApiData = [];
    this.Auth.fetchContactList().subscribe((data) => {
      this.userApiData = data['response'];

      const arr = [];
      for (let d in this.userApiData) {
        arr.push(this.userApiData[d]);
      }
    });

    this.Auth.getAllPhysicalSites(companyId).subscribe((data) => {
      this.allSiteListData = data['response'];

      const arr = [];
      for (let d in this.allSiteListData) {
        arr.push(this.allSiteListData[d]);
      }
      // console.log('getAllPhysicalSites: ', arr);
    });
  }

  registerSite() {
    // event.preventDefault();
    // const target = event.target;

    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    this.wellnessConvertedTime = this.wellnessCheckTime;
    const propertyNames = Object.values(this.wellnessConvertedTime).join(':');
    this.wellnessConvertedTime = propertyNames;

    const registrationData = {
      company_id: companyId,
      data: {
        contact: {
          user_id: (<HTMLInputElement>document.getElementById('user_id')).value,
          firstname: (<HTMLInputElement>document.getElementById('firstname'))
            .value,
          lastname: (<HTMLInputElement>document.getElementById('lastname'))
            .value,
          email: (<HTMLInputElement>document.getElementById('email')).value,
          phone: (<HTMLInputElement>document.getElementById('phone')).value,
        },
        site: {
          sitename: (<HTMLInputElement>document.getElementById('sitename'))
            .value,
          parent_id: (<HTMLInputElement>document.getElementById('parent_id'))
            .value,
          wellnessCheck: this.wellnessCheckEnable
            ? this.wellnessConvertedTime
            : null,
        },
      },
    };

    this.Auth.addNewVirtualSite(registrationData).subscribe((data: any) => {
      // this.passfeedback = <HTMLInputElement>document.getElementById('feedback');
      // if (data.response[0].status === 'OK') {
      //   this.ds.sendPopupType(99);
      //   this.popUpEmitter('refreshGetSiteApiData');
      // } else {
      //   if (data.response[0].status === 'Error') {
      //     this.passfeedback.innerHTML = data.response[0].message;
      //   }
      // }

      const res = data;
      console.log('addNewVirtualSite: ', res);
      if (res.status == 200) {
        this.showSuccessMessage = true;
        this.setTimer = setTimeout(() => {
          this.showSuccessMessage = false;
        }, 3000);
      }
    });
  }

  setIndex(n) {
    this.clearInputs();
    this.userIndex = n;
    this.getIndex = (<HTMLInputElement>(
      document.getElementById('add_contact')
    )).value;
    // this.sitename = (<HTMLInputElement>document.getElementById('sitename')).value;
    // this.fname = (<HTMLInputElement>document.getElementById('firstname'));
    if (n == 'add') {
      this.userIndex = -1;
      this.user_id = -1;
      // console.log('this getindex', this.getIndex);
    } else if (n >= 0) {
      // console.log('userindex:', this.userIndex);
      this.getValue = (<HTMLInputElement>(
        document.getElementById('add_contact')
      )).value;
      // this.getValue = this.userApiData[n].add_contact;
      this.firstname = this.userApiData[n].first_name;
      this.lastname = this.userApiData[n].last_name;
      this.email = this.userApiData[n].email;
      this.phone = this.userApiData[n].phone;
      this.user_id = this.userApiData[n].user_id;
      // // console.log("user data in virtual site; " + this.sitename + this.firstname + this.lastname + this.email + this.phone + this.user_id);
      // (<HTMLInputElement>document.getElementById('add_contact')).value =
    }
    //this.getValue = this.userApiData[n].user_id;
    //// console.log(this.getValue + " get value " + this.userApiData[n].first_name + " userindex ", this.userIndex);
  }
  siteIndex = -1;

  setSiteIndex(n) {
    this.siteIndex = n;
    // console.log('site index is balla' + this.siteIndex);
    this.userIndex = n;
    // this.fname = (<HTMLInputElement>document.getElementById('firstname'));
    if (n >= 0) {
      // console.log('userindex:', this.userIndex);
      this.getValue = (<HTMLInputElement>(
        document.getElementById('add_contact')
      )).value;
      // this.getValue = this.userApiData[n].add_contact;

      this.parent_id = this.allSiteListData[n].site_id;

      // (<HTMLInputElement>document.getElementById('add_contact')).value =
    } else {
      this.parent_id = this.allSiteListData[n].site_id;
      // console.log('parent id' + this.parent_id);
    }
    //this.getValue = this.userApiData[n].user_id;
    //// console.log(this.getValue + " get value " + this.userApiData[n].first_name + " userindex ", this.userIndex);
  }
  clearInputs() {
    this.firstname = '';
    this.lastname = '';
    this.email = '';
    this.phone = '';
    this.user_id = -1;
    // this.passfeedback = "";
  }

  onChanges() {
    this.siteForm.get('user_id').valueChanges.subscribe((selectedUserId) => {
      if (selectedUserId == -1) {
        this.siteForm.get('firstname').enable();
        this.siteForm.get('lastname').enable();
        this.siteForm.get('email').enable();
        this.siteForm.get('phone').enable();
        // console.log('selectedUserId enable', selectedUserId);
      } else {
        // this.siteForm.get('firstname').reset();
        this.siteForm.get('firstname').disable();
        this.siteForm.get('lastname').disable();
        this.siteForm.get('email').disable();
        this.siteForm.get('phone').disable();
        // console.log('selectedUserId disable', selectedUserId);
      }
    });
  }

  onChangeWellnessCheckValue = (event) => {
    var value = 0;
    if (event?.target?.checked) {
      value = 1;
    }
    this.wellnessCheckEnable = value;
  };
}
