<button type="button" class=" btn-reg">
  <div *ngIf="deviceStatus == 0">
    <img src="../../../../assets/img/audio/icon_mic.png" alt="audio" />
    <p class="pt-1">Not In Call</p>
  </div>
  <div *ngIf="muteStatus == true && deviceStatus == 1">
    <img src="../../../../assets/img/audio/icon_mute.png" alt="audio" />
    <p class="pt-1">Muted</p>
  </div>
  <div *ngIf="muteStatus == false && deviceStatus == 1">
    <img src="../../../../assets/img/audio/icon_unmute.png" alt="audio" />
    <p class="pt-1">Un Muted</p>
  </div>
</button>