import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

const assetDir = '../../../../assets/img/logitech_tap';

enum Status {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
}

type StatusMap = {
  [key in Status]: string;
};

const statusMap: StatusMap = {
  [Status.ONLINE]: `${assetDir}/logi_tap_green.png`,
  [Status.OFFLINE]: `${assetDir}/logi_tap_red.png`,
};

@Component({
  selector: 'app-logitech-tap-panel',
  templateUrl: './logitech-tap-panel.component.html',
  styleUrls: ['./logitech-tap-panel.component.scss'],
})
export class LogitechTapPanelComponent implements OnInit, OnChanges {
  @Input() logitechTap: any;

  status: Status = Status.OFFLINE;
  tapIcon: string = statusMap[this.status];
  serialNumber: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.updateTapStatus();
  }

  updateTapStatus() {
    if (!this.logitechTap) return;

    const { ConnectionState, SerialNumber } = this.logitechTap;

    this.status = ConnectionState > 10 ? Status.ONLINE : Status.OFFLINE;
    this.tapIcon = statusMap[this.status];
    this.serialNumber = SerialNumber;
  }
}
