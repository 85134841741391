import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-reports-video-conf-devices',
  templateUrl: './reports-video-conf-devices.component.html',
  styleUrls: ['./reports-video-conf-devices.component.scss']
})
export class ReportsVideoConfDevicesComponent implements OnInit {
  @Input() videoConferSite: any;
  
  constructor() { }

  ngOnInit() {
  }

}
