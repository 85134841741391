import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

@Component({
	selector: 'app-instant-login',
	templateUrl: './instant-login.component.html',
})
export class InstantLoginComponent implements OnInit {
	userToken: any

	constructor(private route: ActivatedRoute, private router: Router) {
		this.route.params.subscribe(params => {
			this.userToken = params['id']
		})
	}

	ngOnInit() {
		this.loginTokenUser(this.userToken)
	}

	loginTokenUser(userToken) {
		localStorage.clear()
		sessionStorage.clear()

		localStorage.setItem('token', userToken)

		const helper = new JwtHelperService()
		const isExpired = helper.isTokenExpired(this.userToken)

		localStorage.setItem('verified', 'true')

		const decodedToken = helper.decodeToken(this.userToken)

		this.router.navigate([isExpired ? '/login' : decodedToken.redirect])
	}
}
