import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GetPluginService {
  server_url: string = environment.serverUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) { }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  createAction(plugin_id: number, action_params: any, action_name: string) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let postData = {
      plugin_id,
      action_params,
      action_name,
      user_id: decodedToken.user_id,
    };

    // // console.log('createAction: ', postData)

    const apiUrl = '/api/v1/plugin/zoomCreateAction';

    return this.http.post<any>(this.server_url + apiUrl, postData, this.httpOptions);
  }

  linkZoomPlugin(postData: any) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const params = {
      ...postData,
      user_id: decodedToken.user_id,
      domain: this.server_url,
    };

    const apiUrl = '/api/v1/plugin/linkZoomPlugin';

    return this.http.post<any>(this.server_url + apiUrl, params, this.httpOptions);
  }

  linkZoomPluginUpdate(action_id: number, password: string) {
    const params = {
      action_id,
      password,
    };

    const apiUrl = '/api/v1/plugin/linkPluginUpdate';

    return this.http.post<any>(this.server_url + apiUrl, params, this.httpOptions);
  }

  linkPluginToSiteUpdate(site_id: number, plugin_id: number, service_type: string) {
    const params = {
      site_id,
      plugin_id,
      service_type,
    };

    const apiUrl = '/api/v1/plugin/linkPluginToSiteUpdate';

    return this.http.post<any>(this.server_url + apiUrl, params, this.httpOptions);
  }

  getPluginActiveSiteIdsByCompanyId(company_id: number) {
    const params = {
      company_id,
    };

    const apiUrl = '/api/v1/plugin/getPluginActiveSiteIdsByCompanyId';

    return this.http.post<any>(this.server_url + apiUrl, params, this.httpOptions);
  }

  enableZoomPlugin(plugin_id: number, zoom_user_id: number) {
    const params = {
      plugin_id,
      zoom_user_id,
    };

    const apiUrl = '/api/v1/plugin/enableZoomPlugin';

    return this.http.post<any>(this.server_url + apiUrl, params, this.httpOptions);
  }

  disableZoomPlugin(plugin_id: number, zoom_user_id: number) {
    const params = {
      plugin_id,
      zoom_user_id,
    };

    const apiUrl = '/api/v1/plugin/disableZoomPlugin';

    return this.http.post<any>(this.server_url + apiUrl, params, this.httpOptions);
  }

  getPluginAction(deviceDetails: any) {
    return this.http.post<any>(
      this.server_url + '/api/v1/plugin/getAction',
      deviceDetails,
      this.httpOptions
    );
  }

  uploadSnapshotToS3(company_id: number, device_id: number, image: Blob, quadrant?: string) {
    const apiUrl = '/api/v1/plugin/uploadToS3';
    const postData = {
      company_id,
      device_id,
      image,
      quadrant: quadrant || 'CLOUD'
    }
    return this.http.post<any>(this.server_url + apiUrl, postData, this.httpOptions);
  }
}
