<div class="cloud-user-panel">
  <div *ngIf="dataError" class="cloud-user-panel__error-page">
    Error - no user data
  </div>
  <div *ngIf="!dataError">
    <div class="cloud-user-panel__header">
      <app-panel-tools [PopupTitle]="PopupTitle"></app-panel-tools>
    </div>
    <div class="cloud-user-panel__monitor-banner" *ngIf="cloudApiData?.plugin_status === -1">
      <div class="cloud-user-panel__monitor-banner-text">
        Plugin is offline.The information below is from the last successful sync at {{ lastSync }}
      </div>
    </div>
    <div class="cloud-user-panel__body">
      <div class="cloud-user-panel__info">
        <app-cloud-user-info [cloudUserInfo]="cloudApiData"></app-cloud-user-info>
      </div>

      <div class="cloud-user-panel__stats-and-health">
        <div class="cloud-user-panel__health-container">
          <div (click)="
              this.ds.sendPopupType(54, {
                'deviceId': this.id,
                'is_room': false
              })
            " class="incident-button">
            Incidents
          </div>
          <app-system-health [isCursorPointer]="true" [healthNum]="cloudApiData?.health"></app-system-health>
        </div>
        <app-cloud-user-stats [liveParticipants]="liveParticipants" [cloudUserStats]="cloudApiData"
          [dataReceived]="dataReceived"></app-cloud-user-stats>
      </div>
    </div>
  </div>
</div>