import { JwtHelperService } from '@auth0/angular-jwt'
import { AuthService } from 'src/app/services/auth.service'
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core'
import { GetVideoService } from '../../services/get-video.service'
import * as moment from 'moment-timezone'
import { LazyForDirective } from '../../directives/lazyFor.directive'
import { PrintDataService } from 'src/app/services/print-data.service'
import { MatDialog } from '@angular/material/dialog'
import { IncidentMessagesComponent } from '../dialog/incident-messages/incident-messages.component'
import { IncidentType } from 'src/app/services/incident-messages.service'

interface TriggerEvent{
	polls: number,
	lastPoll: string,
	trigger: string,
	startTime: string,
	endTime: string
}
@Component({
	selector: 'app-device-health-history',
	templateUrl: './device-health-history.component.html',
	styleUrls: ['./device-health-history.component.scss'],
})
export class DeviceHealthHistoryComponent implements OnInit, OnChanges {
	@Input() popData: any
	@Input() popupState: any
	isShowTableData: boolean = true
	shouldShowLoaderBtn: boolean = false
	incidentsData = []
	constructor(
		private getVideoService: GetVideoService,
		private Auth: AuthService,
		private printData: PrintDataService,
		private dialog: MatDialog
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.popupState.currentValue === 'open') {
			this.getData()
		} else {
			this.incidentsData = []
		}
	}

	printTime(t) {
		if (t == null) {
			return '--'
		} else {
			return moment(t).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma')
		}
	}

	// printEndTime(t) {
	//   const a = moment.unix(t);
	//   return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mma');
	// }

	getData = () => {
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService()
		const decodedToken = helper.decodeToken(token)
		this.shouldShowLoaderBtn = true

		this.Auth.getDeviceIncidentList({
			company_id: decodedToken.company_id,
			device_id: this.popData.deviceId,
			htype: this.popData.hType,
		}).subscribe(data => {
			if (data.response.length < 1) {
				this.isShowTableData = false
			}
			for (let i = 0; i < data.response.length; i++) {
				let inc = {
					incident_id:
						this.popData.hType === 'video' ? data.response[i].video_incident_id : data.response[i].audio_incident_id,
					actioned: data.response[i].actioned,
					// start_time: this.printTime(data.response[i].start_time),
					// end_time: this.printTime(data.response[i].end_time),
					start_time: data.response[i].start_time,
					end_time: data.response[i].end_time,
					events: this.newTriggerEvent(data.response[i].events),
				}
				this.incidentsData.push(inc)
			}

			this.shouldShowLoaderBtn = false
		})
	}

	// TODO: need to comment it and use dateTimeZone instead. But I can't test it rn so leaving it as it is
	printEvent(e) {
		let msg = ''
		switch (e.trigger) {
			case 'camera':
				msg = e.missing + ' camera(s) disconnected.'
				return this.printTime(e.stamp + 'Z') + ' - ' + msg
			case 'mic':
				msg = e.missing + ' microphone(s) disconnected.'
				return this.printTime(e.stamp + 'Z') + ' - ' + msg
			case 'speaker':
				msg = e.missing + ' speaker(s) disconnected.'
				return this.printTime(e.stamp + 'Z') + ' - ' + msg
			case 'offline':
				msg = 'The device was offline'
				return this.printTime(e.stamp + 'Z') + ' - ' + msg
			case 'iot_component_offline':
				msg = `Iot [${e.devices}] not resonding.`
				return this.printTime(e.stamp + 'Z') + ' - ' + msg
			case 'iot_webservice_offline':
				msg = `Iot [${e.devices}] webservice is not responding.`
				return this.printTime(e.stamp + 'Z') + ' - ' + msg
			case 'latency':
				msg = 'Latency was ' + e.value
				return this.printTime(e.stamp + 'Z') + ' - ' + msg
			case 'jitter':
				msg = 'Jitter was ' + e.value
				return this.printTime(e.stamp + 'Z') + ' - ' + msg
			case 'temperature':
				msg = 'Temperature was ' + e.value
				return this.printTime(e.stamp + 'Z') + ' - ' + msg
			case 'packetsloss':
				msg = 'Packetloss was ' + e.value
				return this.printTime(e.stamp + 'Z') + ' - ' + msg
			case 'packetloss':
				msg = 'Packetloss was ' + e.value
				return this.printTime(e.stamp + 'Z') + ' - ' + msg
			case 'call_disconnect':
				msg = 'Call disconnect was ' + e.value
				return this.printTime(e.stamp + 'Z') + ' - ' + msg
			case 'zaapi':
				msg = e.error
				return this.printTime(e.stamp + 'Z') + ' - ' + msg
			default:
				msg = e.trigger
				return msg
		}
	}

	printEvents(e) {
		if (e !== null) {
			let events = e.events
			let evnt = ''
			for (var i = 0; i < events.length; i++) {
				evnt = evnt + (i == 0 ? '' : '\n') + this.printEvent(events[i])
			}
			return evnt
		}
		return []
	}
	trackEvent(index, incident) {
		return incident ? incident.video_incident_id : undefined
	}

	ngOnInit() {
		// this.getMeetings();
	}

	printIncidentsToPdf = () => {
		this.printData.printToPdf('incident-history-list-container', 'Incident Report')
	}

	onShowMessages = (incidentId, idx) => {
		const dialogRef = this.dialog.open(IncidentMessagesComponent, {
			panelClass: 'custom-dialog-container',
			data: {
				incidentId,
				incidentType: IncidentType.AUDIO,
				// showButton: true,
			},
			// height: '80%',
			// width: '60%',
			// minHeight: 'calc(100vh - 90px)',
			width: '80%',
			height: 'auto',
		})

		dialogRef.afterClosed().subscribe(isActioned => {
			if (isActioned) {
				this.incidentsData[idx].actioned = 1
			}
		})
	}
	newTriggerEvent(e) {
		let obj: any ={}, newEvents: TriggerEvent[] = [];
		let events = e?.events || [];
		if (events && events?.length) {
			for (let i = 0; i < events.length; i++){
				if (obj[events[i].trigger]) {
					obj[events[i].trigger] = {
						...obj[events[i].trigger],
						polls: obj[events[i].trigger].polls + 1,
						lastPoll: events[i].stamp.replace(/\.0*/g, 'Z'),
						endTime: events[i].stamp.replace(/\.0*/g, 'Z')
					 }
				}
				else {
					obj[events[i].trigger] = {
						polls: 1,
						lastPoll: events[i].stamp.replace(/\.0*/g, 'Z'),
						trigger: this.triggerMessages(events[i]),
						startTime: events[i].stamp.replace(/\.0*/g, 'Z'),
						endTime: events[i].stamp.replace(/\.0*/g, 'Z')
					 }
				}
			}
		let data:any = Object.values(obj)
		newEvents = [...data]
		}
		return newEvents;
	}
	triggerMessages(e) {
		let msg = '';
		switch (e.trigger) {
				case 'camera':
						msg = 'Camera is not connected';
						break;
				case 'triovisual':
					msg = 'Trio visual is not paired';
					break;
				case 'mic':
						msg = e.missing + ' microphone(s) disconnected.';
						break;
				case 'speaker':
						msg = e.missing + ' speaker(s) disconnected.';
						break;
				case 'offline':
						msg = 'The device was offline';
						break;
				case 'iot_component_offline':
						msg = `Iot [${e.devices}] not responding.`;
						break;
				case 'iot_webservice_offline':
						msg = `Iot [${e.devices}] webservice is not responding.`;
						break;
				case 'latency':
						msg = 'Latency was ' + e.value;
						break;
				case 'jitter':
						msg = 'Jitter was ' + e.value;
						break;
				case 'temperature':
						msg = 'Temperature was ' + e.value;
						break;
				case 'packetsloss':
				case 'packetloss': // Handle packetloss and packetsloss both as same case
						msg = 'Packetloss was ' + e.value;
						break;
				case 'call_disconnect':
						msg = 'Call disconnect was ' + e.value;
						break;
				case 'zaapi':
						msg = e.error;
						break;
				default:
						msg = e.trigger;
		}
		return msg;

	}
}