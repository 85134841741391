import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment-timezone';
import { PrintDataService } from 'src/app/services/print-data.service';
import { RefreshApiService } from '../../services/refresh-api.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-teams-incident',
  templateUrl: './teams-incident.component.html',
  styleUrls: ['./teams-incident.component.scss']
})

export class TeamsIncidentComponent implements OnInit, OnChanges {
  @Input() popData: any;
  @Input() popupState: any;
  isShowTableData: boolean = true;
  shouldShowLoaderBtn: boolean = false;
  filterListner: Subscription;
  deskPluginText: String = environment.config.dynamicText.deskPlugin;
  pluginText: String = environment.config.dynamicText.dPlugin;

  incidentsData = [];
  constructor(
    private Auth: AuthService,
    private printData: PrintDataService,
    private refreshApiService: RefreshApiService
  ) {
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      //this might destroy the code but this is proper implentation
      // let isFirstLoad = true;
      // if (m && !isFirstLoad) {
      // console.log('fresh from teams incident')
      this.getData();
      // }
      // isFirstLoad = false;
    });
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.popupState.currentValue === 'open') {
      this.getData();
      this.incidentsData = [];
    }
  }

  getData = () => {
    this.shouldShowLoaderBtn = true;
    this.Auth.getZoomIncidentList(this.popData.deviceId).subscribe((data) => {
      if (data.length < 1) {
        this.isShowTableData = false;
      }

      this.incidentsData = data.map((i) => {
        const notification_info = JSON.parse(i.notification_info);
        const issue = notification_info.message.split(':');
        const eventsIssues = { ...JSON.parse(i.events) };
        const eventDetails = [];

        for (const event in eventsIssues) {
          eventDetails.push({
            trigger: this.translateTrigger(event),
            pulls: eventsIssues[event].pulls,
            // last_pull: this.printTime(eventsIssues[event].last_pull),
            // end_time: this.printTime(eventsIssues[event].end_date),
            // start_date: this.printTime(eventsIssues[event].start_date),
            last_pull: eventsIssues[event].last_pull,
            end_time: eventsIssues[event].end_date,
            start_date: eventsIssues[event].start_date,
          });
        }
        const incident = {
          incident_id: `T-${i.zoom_incident_id}`,
          // start_time: this.printTime(i.start_time),
          // end_time: this.printTime(i.end_time),
          start_time: i.start_time,
          end_time: i.end_time,
          summary: issue[issue.length - 1].trim(),
          event_details: eventDetails,
        };
        return incident;
      });


      this.incidentsData = this.incidentsData.filter(e => e.event_details.length !== 0)

      this.shouldShowLoaderBtn = false;
    });
  };

  translateTrigger(trigger) {
    switch (trigger) {
      case 'video_disconnection':
        return 'Camera(s) disconnected.';
      case 'is_wireless_connection':
        return 'Wifi(s) Unstable.';
      case 'audio_mic_disconnection':
        return 'Microphone(s) disconnected.';
      case 'audio_speaker_disconnection':
        return 'Speaker(s) disconnected.';
      case 'iot_component_offline':
        return 'Iot not resonding.';
      case 'iot_webservice_offline':
        return `Iot webservice not responding.`;
      case 'latency':
        return 'Exceeded Latency alert threshold.';
      case 'jitter':
        return 'Exceeded Jitter alert threshold.';
      case 'temperature':
        return 'Exceeded Temperature alert threshold.';
      case 'packetsloss':
        return 'Exceeded Packetloss alert threshold.';
      case 'plugin_offline':
        return this.popData.is_room
          ? `${this.pluginText} offline.`
          : `${this.deskPluginText} offline.`;
      case 'room_offline':
        return 'Room offline.';
      case 'zaapi':
        return 'Zaapi issue.';
      default:
        return '';
    }
  }

  // printTime(t) {
  //   if (t == null || !t) {
  //     return '--';
  //   } else {
  //     return moment(t)
  //       .utc()
  //       .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
  //       .format('MM-DD-YYYY hh:mma');
  //   }
  // }

  printIncidentsToPdf = () => {
    this.printData.printToPdf('incident-history-list-container', 'Incident Report');
  };

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }
}
