import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subscription } from 'rxjs/Subscription';


import { MatDialog } from '@angular/material/dialog';
import { VideoDeviceUpdateDialogComponent } from '../dialog/video-device-update-dialog/video-device-update-dialog.component'

@Component({
  selector: 'app-video-device-update',
  templateUrl: './video-device-update.component.html',
  styleUrls: ['./video-device-update.component.scss']
})
export class VideoDeviceUpdateComponent implements OnChanges {
  @Input() videoDeviceData: any
  filterListner: Subscription;
  filterSubject: any
  constructor(
    public dialog: MatDialog,
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.videoDeviceData && this.videoDeviceData) {
    }
  }



  openDialog = () => {

    // console.log('openDesktopLinkModal: ');
    const config = this.videoDeviceData;

    const webportal = this.dialog.open(VideoDeviceUpdateDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        config,
      },
    });
  }

}
