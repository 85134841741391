import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { GetAudioService } from 'src/app/services/get-audio.service';
import { RefreshApiService } from 'src/app/services/refresh-api.service';
import { TableFilterService } from 'src/app/services/table-filter.service';

@Component({
  selector: 'app-audio-site-panel',
  templateUrl: './audio-site-panel.component.html',
  styleUrls: ['./audio-site-panel.component.scss']
})
export class AudioSitePanelComponent implements OnInit {

  PopupTitle: string = "Audio Site"
  public audioSiteSummary: any;
  public audiositesStats: any;
  public audioSiteInfo: any;
  id: number;
  audioApiData: any = [];
  audioDeviceApiSummary: any = [];
  audioDevicesListData: MatTableDataSource<any>;
  apiTimers: any;
  refreshData: any;
  // Settings and Parameters for the the Smart Filter Tables
  refreshListner: Subscription;
  filterListner: Subscription;
  baseFilter: any = "all"
  loading: any = true;
  isVirtualSite: any;
  constructor(private route: ActivatedRoute, private router: Router,
    private getAudioDataService: GetAudioService, private refreshApiService: RefreshApiService, private tableFilterService: TableFilterService) {
    this.audioDevicesListData = new MatTableDataSource();
    
    this.route.params.subscribe(params => {
      this.id = +params['id'];
    });


    // This recieves the update data boolean to start the timer
    this.refreshListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      this.refreshData = m;
      this.getApiData();
    });

    this.filterListner = this.tableFilterService.listen().subscribe((m: any) => {

      if (m == "") {
        this.baseFilter = "all";
      } else {
        this.baseFilter = m;
      }
      this.audioDevicesListData.filter = "-".trim().toLocaleLowerCase();
    });

    this.audioDevicesListData.filterPredicate = (data: any, filter: string) => { 
      var dataStr = data.ipv4 + data.site_name + data.health + data.liveMeetings + data.company_name + data.room_name;
      if (this.baseFilter == "impacted") {
        if (filter == "-" || filter == "" || filter == null) {
          return data.health < 76 && +data.paused === 0;
        }
        else {
          return (
						data.health < 76 &&
						+data.paused === 0 &&
						dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
					)
        }

      } else if (this.baseFilter == "all") {
        if (filter == "-" || filter == "" || filter == null) {
          return true;
        }
        else {
          return dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1;
        }
      } else if (this.baseFilter == "on_call") {

        if (filter == "-" || filter == "" || filter == null) {
          return data.on_call == 1;
        }
        else {
          return data.oncall == 1 && dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1;
        }
      } else if (this.baseFilter == "impactedOnly") {

        if (filter == "-" || filter == "" || filter == null) {
          return data.health < 76;
        }
        else {
          return data.health < 76 && dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1;
        }

      } else if (this.baseFilter == "teams_nc") {
				if (filter == "-" || filter == "" || filter == null) {
					return data.microsoft_team_service != 1;
				}
				else {
					return data.microsoft_team_service != 1 && dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1;
				}
			} else if (this.baseFilter == "zoom_nc") {
				if (filter == "-" || filter == "" || filter == null) {
					return data.zoom_service != 1;
				}
				else {
					return data.zoom_service != 1 && dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1;
				}
			} else if (this.baseFilter == "webex_nc") {
				if (filter == "-" || filter == "" || filter == null) {
					return data.webex_service != 1;
				}
				else {
					return data.webex_service != 1 && dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1;
				}
			}
    };

  }

  getApiData() {
    if (this.refreshData) {
      this.getAudioApiData();
    }
  }

  ngOnInit() {
    this.getAudioApiData();
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  getAudioApiData() {
    //GET Request for Company Info for Specific Site
    this.getAudioDataService.apiGetAudioCompanySiteInfo(this.id).subscribe(data => {
      this.audioSiteInfo = data['response'];
    });

    //GET Request for Audio Device Stats Summary by Site
    this.getAudioDataService.apiGetAudioSiteDeviceSummary(this.id).subscribe(data => {
      this.audioSiteSummary = data["response"][0];
    });

    // this.audioApiData = [];

    //GET Request for Audio Device Detailed Device List by Site
    this.getAudioDataService.apiGetAudioSiteDevicebySite(this.id).subscribe(data => {
      // This is to load data into an array for in order to create a searchable filter table
      this.audioApiData = data['response'];

      // This is to load data into an array for in order to create a searchable filter table
      let arr = [];
      for (let d in this.audioApiData) {
        arr.push(this.audioApiData[d]);
      }
      this.audioDevicesListData.data = this.audioApiData;
      this.loading = false;
    });
  }
}