<div class="teams-spaces-service">

    <div class="teams-spaces-service__left-column">
        <div class="teams-spaces-service__btn-and-other-info">
            <div class="teams-spaces-service__other-info">
                <span class=" teams-spaces-service__title-span">Teams Spaces</span>
                <div class="teams-spaces-service__checkbox-div">
                    <div class="disabled float-left">Room</div>
                    <label class="switch mx-2">
                        <input type="checkbox" (change)="toggleTypeToBeAdded()" />
                        <span class="slider round"></span>
                    </label>
                    <div class="enabled float-right">User</div>
                </div>
            </div>
        </div>
    </div>

    <div [formGroup]="add_rooms_form" *ngIf="toggleRoomOrUser ==='Room'" class="teams-spaces-service__right-column">
        <div class="teams-spaces-service__success-msg {{ msgColor }}">
            <h5 *ngIf="shouldShowMsg">
                {{ msg }}
            </h5>
        </div>

        <div class="teams-spaces-service__separator">
            <div class="teams-spaces-service__container form-group">
                <div class="teams-spaces-service__label">
                    Booking Email ID
                </div>
                <input class="teams-spaces-service__input" formControlName="bookingEmail" placeholder="Booking Email ID"
                    required />
                <span class="text-danger teams-spaces-service__error-span"
                    *ngIf="add_rooms_form.controls['bookingEmail'].hasError('required') && (add_rooms_form.controls['bookingEmail'].dirty || add_rooms_form.controls['bookingEmail'].touched)">Booking
                    Email ID
                    is required</span>
                <span class="text-danger teams-spaces-service__error-span"
                    *ngIf="add_rooms_form.controls['bookingEmail'].hasError('pattern') && (add_rooms_form.controls['bookingEmail'].dirty || add_rooms_form.controls['bookingEmail'].touched)">Please
                    enter valid email address</span>
            </div>

            <div class="teams-spaces-service__container form-group">
                <div class="teams-spaces-service__label">
                    Room Name
                </div>
                <input class="teams-spaces-service__input" formControlName="teamsRoomName" placeholder="Room Name"
                    required />
                <span class="text-danger teams-spaces-service__error-span"
                    *ngIf="add_rooms_form.controls['teamsRoomName'].hasError('required') && (add_rooms_form.controls['teamsRoomName'].dirty || add_rooms_form.controls['teamsRoomName'].touched)">Room
                    Name is required</span>
            </div>

            <div class="teams-spaces-service__button-blank-container">
                <div class="teams-spaces-service__blank-container"></div>
                <div class="teams-spaces-service__button-container">

                    <div class="teams-spaces-service__btn">
                        <button *ngIf="!isAddingRoom" (click)="manualAddTeamsRoom()">
                            Add Room
                        </button>
                        <img *ngIf="isAddingRoom" class="teams-spaces-service__btn-image" src="{{ btnLoader }}"
                            alt="" />
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div [formGroup]="add_user_form" *ngIf="toggleRoomOrUser ==='User'" class="teams-spaces-service__right-column">
        <div class="teams-spaces-service__success-msg {{ msgUserColor }}">
            <h5 *ngIf="shouldShowUserMsg">
                {{ msgUser }}
            </h5>
        </div>

        <div class="teams-spaces-service__separator">
            <div class="teams-spaces-service__container form-group">
                <div class="teams-spaces-service__label">
                    First Name
                </div>
                <input formControlName="firstName" class="teams-spaces-service__input" placeholder="First Name"
                    required />
                <span class="text-danger teams-spaces-service__error-span"
                    *ngIf="add_user_form.controls['firstName'].hasError('required') && (add_user_form.controls['firstName'].dirty || add_user_form.controls['firstName'].touched)">First
                    Name is required</span>
            </div>

            <div class="teams-spaces-service__container form-group">
                <div class="teams-spaces-service__label">
                    Last Name
                </div>
                <input formControlName="lastName" class="teams-spaces-service__input" placeholder="Last Name"
                    required />
                <span class="text-danger teams-spaces-service__error-span"
                    *ngIf="add_user_form.controls['lastName'].hasError('required') && (add_user_form.controls['lastName'].dirty || add_user_form.controls['lastName'].touched)">Last
                    Name is required</span>
            </div>

            <div class="teams-spaces-service__container form-group">
                <div class="teams-spaces-service__label">
                    Email
                </div>
                <input formControlName="email" class="teams-spaces-service__input" placeholder="Email" required />
                <span class="text-danger teams-spaces-service__error-span"
                    *ngIf="add_user_form.controls['email'].hasError('required') && (add_user_form.controls['email'].dirty || add_user_form.controls['email'].touched)">Email
                    is required</span>
                <span class="text-danger teams-spaces-service__error-span"
                    *ngIf="add_user_form.controls['email'].hasError('pattern') && (add_user_form.controls['email'].dirty || add_user_form.controls['email'].touched)">Please
                    enter valid email address</span>
            </div>

            <div class="teams-spaces-service__button-blank-container">
                <div class="teams-spaces-service__blank-container"></div>
                <div class="teams-spaces-service__button-container">

                    <div class="teams-spaces-service__btn">
                        <button *ngIf="!isAddingUser" (click)="manualAddTeamsUser()">
                            Add User
                        </button>
                        <img *ngIf="isAddingUser" class="teams-spaces-service__btn-image" src="{{ btnLoader }}"
                            alt="" />
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>