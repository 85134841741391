<div class="container-fluid" (click)="isShowDiableServiceConfirmation = 0" (click)="isEdit = 0"
  style="padding-bottom: 200px">
  <div class="row lower-row">
    <div class="col-2 center px-0">
      <h3 class="add-device cursor-pointer" (click)="setHighlight(1)" [class.btn-background]="highlightNum == 1">
        My account info
      </h3>
      <h3 class="add-company cursor-pointer" *ngIf="isCompanyVisible == 1" (click)="setHighlight(2)"
        [class.btn-background]="highlightNum == 2">
        Company info
      </h3>
      <h3 class="list-device cursor-pointer" *ngIf="isUserListVisible == 1 && !gamEnabed" (click)="setHighlight(3)"
        [class.btn-background]="highlightNum == 3">
        Users
      </h3>
      <h3 class="billin-info cursor-pointer" *ngIf="isIntegrationVisible == 1 && !gamEnabed" (click)="setHighlight(4)"
        [class.btn-background]="highlightNum == 4">
        Integrations
      </h3>
      <div class="row pt-3 px-3" style="top: 20px"></div>
      <div class="row pt-3 px-3" style="top: 20px"></div>
      <div class="row pt-3 px-3" style="top: 20px"></div>
      <div class="row pt-3 px-3" style="top: 20px"></div>
      <div class="row pt-3 px-3" style="top: 20px">
        <span style="margin-left: 2em">
          <h4>Notifications</h4>
        </span>
      </div>
      <div class="row pt-3 px-3" style="top: 20px;">
        <div class="disabled float-left">disabled</div>
        <label class="switch mx-2">
          <input type="checkbox" (change)="setNotification($event)" [checked]="userALerts" />
          <span class="slider round"></span>
        </label>
        <div class="enabled float-right">enabled</div>
      </div>
      <div class="col" style="padding-top: 10px !important">
        <div class="form-group terms-container text-center row pt-3 px-3" *ngIf="showGDPR">
          <a style="margin-left: 2em" href="{{ links.gdpr }}" target="_blank">GDPR request</a>
        </div>
        <div class="form-group terms-container text-center row pt-3 px-3" style="margin-top: -2em" *ngIf="showLegal">
          <a style="margin-left: 2em" href="{{ links.legal }}" target="_blank">Privacy policy</a>
        </div>
      </div>
    </div>

    <div style="padding-left: 50px; border-left: 2px solid black"></div>
    <div class="col-9 d-flex" *ngIf="highlightNum == 1">
      <form role="form" name="userAcctForm" class="col-5" [formGroup]="userAcctForm" (ngSubmit)="onUserSubmit()"
        novalidate>
        <div class="row pb-2 px-3" style="top: 20px">
          Access Level: {{ acclev }}
        </div>
        <div class="form-group">
          <input type="text" [(ngModel)]="firstname" formControlName="firstname" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" placeholder="First Name" name="firstname"
            id="firstname" autocomplete="given-name" required />
          <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
            <div *ngIf="f.firstname.errors.maxlength" class="phonetext alert alert-danger p-2 m-0">
              Exceed Maximum 30 character
            </div>
            <div *ngIf="f.firstname.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        <div class="form-group">
          <input type="text" [(ngModel)]="lastname" formControlName="lastname" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" placeholder="Last Name" name="lastname"
            id="lastname" autocomplete="family-name" required />
          <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
            <div *ngIf="f.lastname.errors.maxlength" class="password2-text alert alert-danger p-2 m-0">
              Exceed Maximum 30 character
            </div>
            <!-- <div *ngIf="f.lastname.errors.required">Last Name is required</div> -->
            <div *ngIf="f.lastname.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        <div class="form-group">
          <!-- <input [(ngModel)]="email" type="email" formControlName="email" id="email" placeholder="email (username)" class="form-control" required /> -->
          <input type="email" [(ngModel)]="email" formControlName="email" class="form-control" placeholder="email"
            name="email" id="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email"
            autocomplete="email" required readonly />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.maxlength" class="phonetext alert alert-danger p-2 m-0">
              Exceed Maximum 50 character
            </div>
            <div *ngIf="f.email.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <!-- <div class="col-3">
            <input type="text" [(ngModel)]="userphonecode" formControlName="userphonecode" class="form-control" name="userphonecode" id="userphonecode" placeholder="phone code"/>
           </div> -->
            <div class="col-12">
              <!-- <input type="text" class="form-control" id="userphone" placeholder="phone number" required /> -->
              <!-- <input [(ngModel)]="phone" type="text" formControlName="phone" class="form-control" name="phone" id="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
              placeholder="User Phone" autocomplete='tel-national' required /> -->
              <input type="text" [(ngModel)]="userphone" formControlName="userphone" class="form-control"
                name="userphone" id="userphone" [ngClass]="{ 'is-invalid': submitted && f.userphone.errors }"
                placeholder="User Phone" autocomplete="tel-national" required />
              <div *ngIf="submitted && f.userphone.errors" class="invalid-feedback">
                <div *ngIf="f.userphone.errors" class="phonetext alert alert-danger p-2 m-0">
                  Please enter valid phone number
                </div>
                <div *ngIf="f.userphone.errors.required" id="confirmMessage">
                  !
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group mb-0">
          <!-- <select formControlName="timezone" [(ngModel)]="selectedTimezone" class="form-control" placeholder="Timezone">
            <option *ngFor="let zone of timezones" [ngValue]="zone">{{ zone.value }} (UTC{{ zone.utcOffset }})</option>
          </select> -->
          <ng-moment-timezone-picker formControlName="timezone" [clearable]="true"></ng-moment-timezone-picker>
          <div *ngIf="submitted && f.timezone.errors" class="invalid-feedback">
            <div *ngIf="f.timezone.errors" class="phonetext alert alert-danger p-2 m-0">
              Please choose a timezone
            </div>
            <div *ngIf="f.timezone.errors.required" id="confirmMessage">
              !
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <form action="/">
              <input type="file" class="upload_btn" accept="image/jpeg, image/png" id="image_browse1"
                (change)="onFileSelected($event,true)" #fileReset />
              <!-- <img
                class="image-upd"
                [src]="this.server_url + '/api/v1/images/companylogo/' + this.logo"
                id="image-placeholder"
              /> -->
            </form>
            <i class="fa fa-paperclip"></i><span>Upload Profile (upto 2mb max)</span>
          </div>
        </div>
        <div class="form-group clearfix">
          <button type="button" class="btn btn-dark float-left" (click)="changePassword()"
            [ngClass]="{ 'disabled-btn': gamEnabed }">
            Change password
          </button>
          <button type="submit" class="btn btn-primary float-right">
            Save changes
          </button>
        </div>
        <div (click)="maintenance()" class="btn btn-primary" [ngClass]="isNoftificationSchedule?'border-active':''">
          Alerts Schedule
        </div>
        <br />
        <div>{{ passwordresetmessage }}</div>
      </form>
      <app-notification-schedule [isForCompany]="false" *ngIf="isNoftificationSchedule" class="col-7 notification-schedule"></app-notification-schedule>
    </div>
    <div class="col-9 d-flex" *ngIf="highlightNum == 2">
      <form role="form" name="compAcctForm" class="mt-2 col-5" [formGroup]="compAcctForm" (ngSubmit)="onCompSubmit()"
        novalidate>
        <div class="form-group">
          <!-- <input type="text" class="form-control" id="companyname" placeholder="company name" value={{compname}} required /> -->
          <input type="text" formControlName="companyname" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && fc.companyname.errors }" placeholder="Company Name"
            name="companyname" id="companyname" autocomplete="additional-name" required />
          <div *ngIf="submitted && fc.companyname.errors" class="invalid-feedback">
            <!-- <div *ngIf="fc.companyname.errors.required">companyname is required</div> -->
            <div *ngIf="fc.companyname.errors.required" id="confirmMessage">
              !
            </div>
          </div>
        </div>
        <div class="form-group">
          <!-- <input type="text" class="form-control" id="address1" placeholder="address1" value={{compadd1}} required /> -->
          <input type="text" formControlName="address1" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && fc.address1.errors }" placeholder="Address1" name="address1"
            id="address1" autocomplete="street-address" required />
          <div *ngIf="submitted && fc.address1.errors" class="invalid-feedback">
            <!-- <div *ngIf="fc.address1.errors.required">address1 is required</div> -->
            <div *ngIf="fc.address1.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        <div class="form-group">
          <input type="text" formControlName="address2" class="form-control" id="address2" placeholder="Address2"
            autocomplete="address-line1" />
        </div>
        <div class="form-group">
          <select #setCountrySelect (change)="setCountry(setCountrySelect.value)" type="text" formControlName="country"
            class="form-control" placeholder="Country" name="country" id="country" autocomplete="country" required>
            <option value="companyInfoDtls[0].country" selected disabled>
              {{ countryName }}
            </option>
            <option *ngFor="let countries of allCountryListData; let i = index" value="{{ countries.id }}">
              {{ countries.name }}
            </option>
          </select>
          <div *ngIf="submitted && fc.country.errors" class="invalid-feedback">
            <div *ngIf="fc.country.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        <div class="form-group">
          <!-- <input type="text" class="form-control" id="city" placeholder="city" value={{compcity}} required /> -->
          <input type="text" formControlName="city" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && fc.city.errors }" placeholder="City" name="city" id="city"
            autocomplete="address-line2" required />
          <div *ngIf="submitted && fc.city.errors" class="invalid-feedback">
            <!-- <div *ngIf="fc.city.errors.required">city is required</div> -->
            <div *ngIf="fc.city.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-9">
              <select type="text" formControlName="state" class="form-control" placeholder="State/Province/Region"
                name="state" id="state" autocomplete="state" required>
                <option value="companyInfoDtls[0].state" selected disabled>
                  {{ companyInfoDtls[0].state }}
                </option>
                <option *ngFor="let states of allStatesListData; let i = index" value="{{ states.name }}">
                  {{ states.name }}
                </option>
              </select>
            </div>
            <div class="col-3">
              <input type="text" formControlName="zip" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && fc.zip.errors }" placeholder="Zip" name="zip" id="zip"
                autocomplete="postal-code" required />
              <div *ngIf="submitted && fc.zip.errors" class="invalid-feedback">
                <div *ngIf="fc.zip.errors" class="zip-text alert alert-danger p-2 m-0">
                  Please enter a valid Zipcode of the country
                </div>
                <div *ngIf="fc.zip.errors.required" id="confirmMessage">!</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex flex-column">
            <form action="/" class="my-2">
              <input type="file" class="upload_btn" accept="image/jpeg, image/png" id="image_browse"
                (change)="onFileSelected($event)" #fileReset />
              <!-- <img
                class="image-upd"
                [src]="this.server_url + '/api/v1/images/companylogo/' + this.logo"
                id="image-placeholder"
              /> -->
              <i class="fa fa-paperclip"></i><span>400 x 400px, 1 MB max</span>
            </form>
            <div (click)="maintenance()" class="btn btn-primary maintenance" [ngClass]="isNoftificationSchedule?'border-active':''">
              Alerts Schedule
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-4">
                <input type="text" [(ngModel)]="compphonecode" formControlName="compphonecode" class="form-control"
                  name="compphonecode" id="compphonecode" placeholder="code" readonly />
              </div>
              <div class="col-8">
                <div class="form-group">
                  <!-- <input type="text" class="form-control" id="companyphone" placeholder="phone number" /> -->
                  <input type="text" [(ngModel)]="companyphone" formControlName="companyphone" class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && fc.companyphone.errors
                    }" placeholder="Company Phone" name="companyphone" id="companyphone" autocomplete="tel-national"
                    required />
                  <div *ngIf="submitted && fc.companyphone.errors" class="invalid-feedback">
                    <div *ngIf="fc.companyphone.errors" class="phonetext alert alert-danger p-2 m-0">
                      Please enter valid phone number
                    </div>
                    <div *ngIf="fc.companyphone.errors.required" id="confirmMessage">
                      !
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary float-right">
                save changes
              </button>
              <!-- (click)="updateCompInfo()" -->
            </div>
            <div class="dot3"></div>
            <div class="form-group">
              <button type="button" class="btn btn-danger float-right" (click)="showDisableSerivceConfirmation()">
                disable service
              </button>
            </div>
          </div>
        </div>
      </form>
      <app-notification-schedule [isForCompany]="true" *ngIf="isNoftificationSchedule" class="col-7 notification-schedule"></app-notification-schedule>
    </div>
    <div class="col-9" *ngIf="highlightNum == 3">
      <div class="row" style="overflow-y: auto; height: 450px">
        <table class="table table-striped table-hover table-sm bg-white mt-1" style="padding-bottom: 100px">
          <thead>
            <tr>
              <th colspan="2" scope="col">First</th>
              <th scope="col">Last</th>
              <th scope="col">Email</th>
              <th scope="col">Last login</th>
              <th scope="col">Access</th>
              <th scope="col">Account</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr scope="row">
              <td colspan="2">-- -- --</td>
              <td>-- -- --</td>
              <td>
                <div class="form-group">
                  <input type="email" id="reg-email" placeholder="Invite new user by email" class="form-control"
                    required />
                </div>
              </td>
              <td>-- -- --</td>
              <td>
                <div class="form-group">
                  <select class="form-control" id="user-access" required>
                    <option value="ADMIN" selected>Admin</option>
                    <option value="MANAGER">Manager</option>
                    <option value="USER">User</option>
                  </select>
                </div>
              </td>
              <td>-- -- --</td>
              <td>
                <app-btn-rounded typebtn="email" id="sendemail" (click)="sendEmailInvite()"></app-btn-rounded>
              </td>
              <td>
                <span *ngIf="sentInvite == 1">
                  <h5>Invite sent!</h5>
                </span>
                <span *ngIf="sentInvite == -1" style="color: crimson">
                  Error: {{ errorMessage }}
                </span>
              </td>
            </tr>
            <tr scope="row" *ngFor="let allUsers of allUserListData; let i = index">
              <td colspan="2">{{ allUsers.first_name }}</td>
              <td>{{ allUsers.last_name }}</td>
              <td>{{ allUsers.email }}</td>
              <!-- <td>{{ printTime(allUsers.last_login) }}</td> -->
              <td>{{ allUsers.last_login | dateTimeZone : 'MM-DD-YYYY hh:mma'}}</td>
              <td>{{ allUsers.accesslevel }}</td>
              <td>
                <div row *ngIf="
                    allUsers.last_name != null || allUsers.last_name != null
                  ">
                  <label *ngIf="
                      this.accessLevel == 3 ||
                        this.accessLevel > allUsers.accessLevel;
                      else disableSwitch
                    " class="switch mx-2">
                    <input type="checkbox" (change)="
                        allUsers.active = allUsers.active == 1 ? 0 : 1;
                        toggleActiveUser(allUsers.user_id, allUsers.active)
                      " [checked]="allUsers.active" />
                    <span class="slider round"></span>
                  </label>
                  <ng-template #disableSwitch>
                    <label class="switch mx-2" disabled>
                      <input type="checkbox" disabled [checked]="allUsers.active" />
                      <span class="slider round" id="disable"></span>
                    </label>
                  </ng-template>
                  <span style="word-wrap: normal !important" class="enabled" *ngIf="allUsers.active == 1">active</span>
                  <span style="word-wrap: normal !important; font-size: 15px" class="paused"
                    *ngIf="allUsers.active == 0">inactive</span>
                </div>
              </td>
              <td id="active-invite">
                <span *ngIf="
                    (allUsers.last_name != null ||
                      allUsers.last_name != null) &&
                    (this.accessLevel > allUsers.accessLevel ||
                      this.accessLevel == allUsers.accessLevel)
                  " (click)="submitted = false" class="fa fa-edit fa-lg" (click)="toEdit(1, allUsers)"></span>
                <span *ngIf="allUsers.new_user != '' && resent != allUsers.email"
                  (click)="resendEmailInvite(allUsers.email)">Resend invite</span>
                <span *ngIf="allUsers.new_user != '' && resent == allUsers.email" style="color: green">Invite
                  Sent!</span>
              </td>
              <div class="edit-form" *ngIf="isEdit == 1" style="
                  position: absolute;
                  width: 250px;
                  right: 250px;
                  top: 250px;
                  background: white;
                  border: 1px black solid;
                ">
                <form>
                  <label class="text-center pt-2" style="width: 100%">
                    {{ this.selectedUser.first_name }}
                    {{ this.selectedUser.last_name }}</label>
                  <button (click)="isEdit = 0" (click)="sendResetPassword()" style="background-color: #76d132"
                    class="form-control btn btn-primary save-btn">
                    send reset password email
                  </button>
                  <div *ngIf="this.accessLevel == 3" style="padding-top: 10px">
                    <label class="text-center pt-2" style="width: 100%">
                      Change access level to
                    </label>
                    <button (click)="isEdit = 0" (click)="changeUserAccessLevel('ADMIN')" style="padding-top: 10px"
                      class="form-control btn btn-primary save-btn">
                      Admin
                    </button>
                    <button (click)="isEdit = 0" (click)="changeUserAccessLevel('MANAGER')" style="padding-top: 10px"
                      class="form-control btn btn-primary save-btn">
                      Manager
                    </button>
                    <button (click)="isEdit = 0" (click)="changeUserAccessLevel('USER')" style="padding-top: 10px"
                      class="form-control btn btn-primary save-btn">
                      User
                    </button>
                  </div>
                </form>
              </div>
              <td>
                <span class="fa fa-trash fa-lg" *ngIf="this.userId !== allUsers.user_id"
                  (click)="toDeleteUser(allUsers.user_id)" (click)="
                    user_firstName = allUsers.first_name;
                    user_lastName = allUsers.last_name
                  "></span>
              </td>
              <!-- <td><span class="fa fa-trash fa-lg" (click)="deleteUser(allUsers.user_id)"></span></td> -->
              <!-- <td *ngIf="isEdit==1"><span>coming soon</span></td> -->
            </tr>
            <div class="confirm-form3" *ngIf="showStatConfirmation == 1" style="
                background: white;
                border-color: grey;
                border-style: solid;
                z-index: 99 !important;
              ">
              <form>
                <div class="row m-2">
                  <h6 style="margin-left: auto; margin-right: auto">
                    Are you sure you want to delete {{ user_firstName }}
                    {{ user_lastName }} ?
                  </h6>
                </div>
                <span>
                  <button type="submit" class="form-control btn btn-primary float-right" (click)="deleteUser()">
                    Yes
                  </button>
                  <button type="submit" class="form-control btn btn-dark float-right"
                    (click)="showStatConfirmation = 0">
                    Cancel
                  </button>
                </span>
              </form>
            </div>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-6" *ngIf="highlightNum == 4">
      <integrations></integrations>
    </div>
    <div class="col-4 search" *ngIf="isdisable == 1">
      <h3>Disable services coming soon....</h3>
    </div>
    <div class="col-4 search" *ngIf="isError != 1 && isSaved == 1">
      <h3>Data saved successfully!</h3>
    </div>
    <div class="col-4 search" *ngIf="uploadProgress">
      <h3 id="download_progress">{{uploadProgress}}</h3>
    </div>
    <div class="col-4 search" *ngIf="isError == 1">
      <h3>{{ errorMsg }}</h3>
    </div>
    <div class="col-4 search" *ngIf="sentRequest">
      <h3>{{ messPasswordReset }}</h3>
    </div>
    <div *ngIf="isShowDiableServiceConfirmation == 1" style="
        margin: auto;
        background: white;
        border-color: grey;
        border-style: solid;
      ">
      <form>
        <div class="row m-2">
          <h6 style="margin-left: auto; margin-right: auto">
            Are you sure you want to disable services?
          </h6>
        </div>
        <button class="btn btn-danger" style="width: 50%" (click)="disableSerivce()" routerLink="/login"
          (click)="isShowDiableServiceConfirmation = 0">
          Yes
        </button>
        <button class="btn btn-dark" style="width: 50%" (click)="isShowDiableServiceConfirmation = 0">
          No
        </button>
      </form>
    </div>
  </div>
</div>