<button type="button" [ngClass]="{'btnbacklite': backcolorbtn == 'btnbacklite','not-button': isbutton == 'not' }"
  class="btn btn-reg not-button" [class.btnpressed]="activeBtn==1">

  <h3 class="count" *ngIf="count; else noData"
    [ngClass]="{'gray': color == 'gray', 'green': color == 'green', 'yellow': color == 'yellow', 'red': color == 'red'}">
    {{count}}</h3>

  <p>{{label}}</p> <!-- {{activeBtn}} -->
</button>

<ng-template #noData>
  <p>--</p>
</ng-template>