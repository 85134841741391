import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

const assetDir = '../../../../assets/img/logitech_scribe'

enum Status {
	ONLINE = 'Online',
	OFFLINE = 'Offline',
}

type StatusMap = {
	[key in Status]: string
}

const statusMap: StatusMap = {
	[Status.ONLINE]: `${assetDir}/logitech_scribe_green.png`,
	[Status.OFFLINE]: `${assetDir}/logitech_scribe_red.png`,
}

@Component({
	selector: 'app-logitech-scribe-panel',
	templateUrl: './logitech-scribe-panel.component.html',
	styleUrls: ['./logitech-scribe-panel.component.scss'],
})
export class LogitechScribePanelComponent implements OnInit, OnChanges {
	@Input() logitechScribe: any

	status: Status = Status.OFFLINE
	icon: string = statusMap[this.status]

	constructor() {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		this.updateScribeStatus()
	}

	updateScribeStatus() {
		console.log('this.logitechScribe', this.logitechScribe)
		if (!this.logitechScribe) return

		const { status } = this.logitechScribe

		this.status = status === 1 ? Status.ONLINE : Status.OFFLINE
		this.icon = statusMap[this.status]
	}
}
