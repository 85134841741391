<button type="button" [ngClass]="{'btnbacklite': backcolorbtn == 'btnbacklite','not-button': isbutton == 'not' }"
  class="btn btn-reg" [class.btnpressed]="activeBtn==1">

  <h3 class="count" *ngIf="count; else noData"
    [ngClass]="{'gray': color == 'gray', 'green': color == 'green', 'yellow': color == 'yellow', 'red': color == 'red'}">
    {{count | twoDecimals}}</h3>
  <p style="white-space: nowrap !important ; font-size:0.7rem !important; text-align: center!important">{{label}}</p>
  <!-- {{activeBtn}} -->
</button>

<ng-template #noData>
  <div>--</div>
</ng-template>