import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { GetTeamsService } from '../../services/get-teams.service';

import { Component, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment-timezone';
import * as Chart from 'chart.js';
import { TableColorRowComponent } from '../../components/table-icons/table-color-row/table-color-row.component';



@Component({
  selector: 'app-chart-teams-dna',
  templateUrl: './chart-teams-dna.component.html',
  styleUrls: ['./chart-teams-dna.component.scss'],
})


export class ChartTeamsDnaComponent implements AfterViewInit, OnChanges {
  @Input() videoDeviceId: any;
  id: number;
  @Input() deviceId: any;
  @Input() popupState: any;
  @Input() data: any;

  chartApiData: any;
  cloudInsTableSetting: any;
  activeBtn: any = 0;
  toggleValue: any = 0;
  printTableData: any;
  printMode: boolean = false;
  canvas: any;
  ctx: any;
  myChartSite: any;
  currentChart = '24h';
  duration: any = 1;
  allInsListData = [];


  // Settings and Parameters for the the Smart Filter Tables
  cloudInsTableSettingMbps = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: false,
    },
    columns: {
      timestamp: {
        title: 'Time Stamp',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (instance == null) {
            return '--';
          } else {
            // instance = moment(instance).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
          }
          return instance;
        },
      },
      total_mbps: {
        title: 'Live Mbps',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
      wifi_strength: {
        title: 'Wifi Strength',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
      },
    },
  };

  cloudInsTableSettingWifi = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: false,
    },
    columns: {
      timestamp: {
        title: 'Time Stamp',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (instance == null) {
            return '--';
          } else {
            // instance = moment(instance).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
          }
          return instance;
        },
      },
      total_mbps: {
        title: 'Frame Rate',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      frame_rate: {
        title: 'Frame Rate',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      total_jitter: {
        title: 'Jitter',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      total_packetloss: {
        title: 'Packetloss',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      zoom_incident_id: {
        title: 'Incident Id',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
    },
  };

  // chart data
  chartData = {
    datasets: [
      {
        label: 'mbps (mb/s)',
        data: [],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)',
        backgroundColor: 'rgb(255, 184, 0, .5)',
        borderWidth: 1,
        hidden: false,
      },
      {
        label: 'wifi',
        data: [],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)',
        backgroundColor: 'rgb(0, 128, 255, .5)',
        borderWidth: 1,
        hidden: false,
      },

    ],
  };

  constructor(public http: HttpClient, private Auth: AuthService,
    private getTeams: GetTeamsService,
  ) {
    // // console.log(this.cloudInsData)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && this.data) {

      this.setData()
    } else {
      setTimeout(() => {
        this.myChartSite.destroy();
      }, 500);
    }
  }

  setData = () => {
    this.canvas = document.getElementById('myChartSite');
    this.ctx = this.canvas.getContext('2d');

    this.myChartSite = new Chart(this.ctx, {
      type: 'line',
      options: {
        responsive: false,
        display: true,
        fontSize: '6px',
        padding: '0px',
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0,
          },
        },
      },
    });

    this.currentChart = '60m';
    // this.activeBtn = 0;
    // this.toggleValue = 0;
    this.toggleBtn(1);

    this.fetchChartData();
  }

  ngAfterViewInit() { }

  fetchChartData = () => {


    this.getTeams.getUserTeamsMbps(this.data.id, this.duration).subscribe((data: any) => {
      const [newData] = data.response

      // console.log('getUserTeamsMbps: ', newData)


      if (newData) {
        let mbps = newData.mbps_total.map((d) => {
          if (d.y === 0) {
            delete d.y;
          }
          return d;
        });
        mbps.sort(this.custom_sort);

        let wifi = newData.wifi.map((d) => {
          if (d.y === 0) {
            delete d.y;
          }
          return d;
        });
        wifi.sort(this.custom_sort);

        this.chartData.datasets[0].data = mbps;
        this.chartData.datasets[1].data = wifi;
        this.chartData.datasets[0].hidden = !('mbps' == this.data.section);
        this.chartData.datasets[1].hidden = !('wifi' == this.data.section);
      }


      this.loadChart();
    }, (error) => {
      // console.log('getUserTeamsMbps error', error)
    })

  }

  custom_sort(a, b) {
    return new Date(a.x).getTime() - new Date(b.x).getTime();
  }

  loadChart() {
    // this.myChartSite.reset();
    this.myChartSite.options.scales = {
      xAxes: [
        {
          type: 'time',
          display: true,
          scaleLabel: {
            display: true,
            labelString: this.currentChart == '7d' ? 'Date' : 'Time',
          },
          time: {
            unit:
              this.currentChart == '7d' ? 'day' : this.currentChart == '24h' ? 'hour' : 'minute',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    };
    this.myChartSite.options.tooltips = {
      callbacks: {
        label: function (tooltipItem, chart) {
          // if (tooltipItem.datasetIndex == 0) {
          //   return 'ID: ' + chart.datasets[0].data[tooltipItem.index].incident_id;
          // } else {
          return tooltipItem.yLabel;
          // }
        },
        beforeLabel: function (tooltipItem, chart) {
          // console.log('beforeLabel: ', tooltipItem)
          switch (tooltipItem.datasetIndex) {
            case 0:
              return 'mbps';
            case 1:
              return 'wifi';
          }
        },
        afterLabel: function (tooltipItem, chart) {

          //no need for now cause i dont have any incidents?
          // if (tooltipItem.datasetIndex == 0) {
          //   let triggers = chart.datasets[0].data[tooltipItem.index].triggers.split(' - ');
          //   triggers.splice(0, 1);
          //   return triggers;
          // } else {
          //   return '';
          // }
          return '';
        },
        title: function (tooltipItem, chart) {
          // return moment(tooltipItem[0].xLabel).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
        },
      },
    };
    this.myChartSite.data.datasets = this.chartData.datasets;
    this.myChartSite.update({ duration: 500 });
  }

  set60m() {
    if (this.currentChart != '60m') {
      this.currentChart = '60m';
      this.toggleBtn(1);
      this.duration = 1;
      this.fetchChartData();

    }
  }
  set24h() {
    if (this.currentChart != '24h') {
      this.currentChart = '24h';
      this.toggleBtn(2);
      this.duration = 24
      this.fetchChartData();

    }
  }
  set7d() {
    if (this.currentChart != '7d') {
      this.currentChart = '7d';
      this.toggleBtn(3);
      this.duration = 7
      this.fetchChartData();
    }
  }

  toggleView(n) {
    ///optimize this
    if (this.toggleValue == n) {
      this.toggleValue = 0;
    } else {
      this.toggleValue = n;
    }
  }

  toggleBtn(n) {
    // if (this.activeBtn == n) {
    //   this.activeBtn = 0;
    // } else {
    this.activeBtn = n;
    // }
  }

  print() {
    var divId;
    if (this.toggleValue == 0) {
      // chart
      this.printCanvas();
    } else {
      // list
      this.printList();
    }
  }

  printCanvas() {
    const printContents = (<HTMLCanvasElement>document.getElementById('myChartSite')).toDataURL();
    const frame1 = document.createElement('iframe');
    frame1.name = 'frame1';
    frame1.style.position = 'absolute';
    frame1.style.top = '-1000000px';
    document.body.appendChild(frame1);
    const frameDoc = frame1.contentWindow;
    frameDoc.document.open();
    frameDoc.document.write(`
        <html>
          <head>
            <title>Print Chart</title>
          </head>
          <body onload="window.print(); window.close()">
            <img style='width: 100%;' src='${printContents}'/>
          </body>
        </html>
      `);
    setTimeout(function () {
      window.frames['frame1'].focus();
      window.frames['frame1'].print();
      document.body.removeChild(frame1);
    }, 500);
    return false;
  }

  printList() {
    this.printMode = true;
    setTimeout(() => {
      let printContents, stylesHtml, linksHtml, popupWin;
      printContents = document.getElementById('printTable').innerHTML;
      stylesHtml = this.getTagsHtml('style');
      linksHtml = this.getTagsHtml('link');
      const frame1 = document.createElement('iframe');
      frame1.name = 'frame1';
      frame1.style.position = 'absolute';
      frame1.style.top = '-1000000px';
      document.body.appendChild(frame1);
      const frameDoc = frame1.contentWindow;
      frameDoc.document.open();
      frameDoc.document.write(`
          <html>
            <head>
              <title>Print Report</title>
              ${stylesHtml}
              ${linksHtml}
              <style>
              .wrap {
                width: 100%;
                margin: 0 auto;
                margin-left: 15%;
                margin-top: 5%;
              }
              
              .wrap table {
                width: 1300px;
              
              }
              table tr td {
                padding: 5px;
                /* border: 1px solid #eee; */
                width: 110px;
                /* word-wrap: break-word; */
                cursor:context-menu;
                text-align: center;
            }
              table.head tr td {
                background: #eee;
              }
              .inner_table {
                height: auto;
                
              }
              </style>
            </head>
            <body onload="window.print(); window.close()">
              ${printContents}
            </body>
          </html>
        `);
      frameDoc.document.close();
      this.printMode = false;
    }, 500);
  }
  private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string {
    const htmlStr: string[] = [];
    const elements = document.getElementsByTagName(tagName);

    for (let idx = 0; idx < elements.length; idx++) {
      htmlStr.push(elements[idx].outerHTML);
    }

    return htmlStr.join('\r\n');
  }
}
