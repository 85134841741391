import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'
import { Observable } from 'rxjs/Observable'
import { Subject } from 'rxjs/Subject'
import { environment } from 'src/environments/environment'
import { IntegratorService } from './integrator.service'
import { map } from 'rxjs/operators'

interface SearchResult {
  id: string
  key: string
  value: string
  type: string
  url: string
  other: any
}
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private searchInProgress = new Subject<boolean>();
  public searchResults = new Subject<any>();
  public showResults = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private integrator: IntegratorService
  ) { }
  //server global variable;
  server_url: string = environment.serverUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
  };

  httpOptions2 = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
  };
  isSearchProgress(): Observable<boolean> {
    return this.searchInProgress.asObservable()
  }
  searchResultsObs(): Observable<any> {
    return this.searchResults.asObservable()
  }
  private extractData(res: Response) {
    let body = res
    return body || {}
  }
  async search(query: string, quadrant: string) {
    try {
      this.searchInProgress.next(true)

      const token = localStorage.getItem('token')
      const helper = new JwtHelperService()
      const decodedToken = helper.decodeToken(token)
      let company_id = decodedToken.company_id || 0
      if (this.integrator.isIntegrator()) {
        company_id = company_id * -1
      }
      this.http
        .post<any>(
          this.server_url + '/api/v1/company/globalSearch',
          {
            company_id,
            searchKey: query,
            quadrant: quadrant
          },
          this.httpOptions
        )
        .pipe(map(this.extractData))
        .subscribe((resp) => {
          const video = resp["response"].videoDevicesSearchResult
          const user = resp["response"].userSearchResult
          const site = resp["response"].sitesSearchResult
          const iot = resp["response"].iotevicesResult
          const audio = resp["response"].audio

          // searching col for video devices
          video.forEach(function (videoObject, index) {
            let arrayToPush = []
            for (var key in videoObject) {
              let value: string = videoObject[key]
              if ((typeof value == 'string' || typeof value == 'object') && value != null && value.toLowerCase().includes(query.toLowerCase())) {

                let valToPush = key

                arrayToPush.push(valToPush)
              }
            }
            video[index].meta = arrayToPush
          })
          //searching col for user and rooms
          user.forEach(function (userObject, index) {
            let arrayToPush = []
            for (var key in userObject) {
              let value: string = userObject[key]
              if (key == 'plugin_infomation') {
                if (userObject[key] != null) {
                  let data = JSON.parse(userObject[key])
                  let obj = data[0]
                  for (let plug_key in obj) {
                    let current_key_value = obj[plug_key]
                    if (typeof current_key_value == 'string' && current_key_value != null && current_key_value.includes(query.toLowerCase())) {
                      let valToPush = key
                      arrayToPush.push(valToPush)
                    }
                  }
                }
              }
              if ((typeof value == 'string' || typeof value == 'object') && value != null && value.toLowerCase().includes(query.toLowerCase())) {
                let valToPush = key
                arrayToPush.push(valToPush)
              }
            }
            user[index].meta = arrayToPush
          })
          // search cl for IOT

          iot.forEach(function (iotObject, index) {
            let arrayToPush = []
            for (var key in iotObject) {
              let value: string = iotObject[key]
              if ((typeof value == 'string' || typeof value == 'object') && value != null && value.toLowerCase().includes(query.toLowerCase())) {
                let valToPush = key
                arrayToPush.push(valToPush)
              }
            }
            iot[index].meta = arrayToPush
          })
          // search col for audio device

          audio.forEach(function (audObject, index) {
            let arrayToPush = []
            for (var key in audObject) {
              let value: string = audObject[key]
              if ((typeof value == 'string' || typeof value == 'object') && value != null && value.toLowerCase().includes(query.toLowerCase())) {
                let valToPush = key
                arrayToPush.push(valToPush)
              }
            }
            audio[index].meta = arrayToPush
          })

          //search col for site
          site.forEach(function (siteObject, index) {
            let arrayToPush = []
            for (var key in siteObject) {
              let value: string = siteObject[key]
              if ((typeof value == 'string' || typeof value == 'object') && value != null && value.toLowerCase().includes(query.toLowerCase())) {
                let valToPush = key
                arrayToPush.push(valToPush)
              }
            }
            site[index].meta = arrayToPush
          })

          this.searchResults.next({
            video,
            user,
            site,
            iot,
            audio,
          })

          this.searchInProgress.next(false)
          this.showResults.next(true)
          const searchFormWidth = document.querySelector("search-bar").clientWidth
          setTimeout(() => {
            (<HTMLElement>document.querySelector("search-results")).style.width = (searchFormWidth + 14) + 'px'
          }, 0)
        })
    } catch (error) {

    } finally {
    }
  }
  close() {
    this.showResults.next(false)
  }
}