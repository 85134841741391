import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'

import { PopupTypeService } from '../../services/popupType.service'

@Component({
  selector: 'app-system-health',
  templateUrl: './system-health.component.html',
  styleUrls: ['./system-health.component.scss'],
})
export class SystemHealthComponent implements OnChanges {
  left: Number = 0;
  @Input() healthNum: any = -1;
  @Input() fullBarStatus: Number = 0;
  @Input() isCursorPointer: Boolean = false;
  @Input() showBlackPointer: Boolean = true;
  val: Number = 0;
  curval: Number = 0;
  preval: Number = 0;
  displayValue: number = 0;
  
  setTimer: any;
  

  constructor(
    private ds: PopupTypeService,
  
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.curval = changes.healthNum.currentValue || 0;
    this.preval = changes.healthNum.previousValue  || 0;


    if (isNaN(changes.healthNum.currentValue)) {
      if (isNaN(changes.healthNum.previousValue)) {
        this.displayValue = 0;
        this.val = 5;
      } else {
        this.displayValue = changes.healthNum.previousValue;
        if (changes.healthNum.previousValue < 0) {
          this.val = 5;
        } else if (changes.healthNum.previousValue > 95) {
          this.val = 100;
        } else {
          this.val = changes.healthNum.previousValue;
        }
      }
    } else {
      
      this.displayValue = changes.healthNum.currentValue;
      if (changes.healthNum.currentValue < 0) {
      
      
      changes.healthNum.currentValue = 0;
      this.displayValue = changes.healthNum.currentValue;
        // this.displayValue = 0;
        setTimeout(() => {
          this.val = 5;
        }, 500);
        
        
       this.setTimer = setTimeout(() => {
          this.val = 5;
        }, 500);
          
        
      } else if (changes.healthNum.currentValue > 95) {
       this.setTimer = setTimeout(() => {
          this.val = 100;
        }, 500);
          
      } else {
         this.setTimer = setTimeout(() => {
            this.val = changes.healthNum.currentValue;
        }, 500);
          
      }
    }
  }

  checkStatus = () => {
    if (this.displayValue == null) {
      return 0;
    } else {
      return this.displayValue;
    }
  }
  
   
  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

  ngOnDestroy() {
    this.ds.clearPopupType();
    this.clearTimeout();
  }
}


          