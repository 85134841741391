import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-cloud-user-path-detection',
  templateUrl: './cloud-user-path-detection.component.html',
  styleUrls: ['./cloud-user-path-detection.component.scss'],
})
export class CloudUserPathDetectionComponent implements OnInit, OnChanges {
  @Input() cloudUserStats: any;
  @Input() isUserOnCall: any;
  callSimulatorData = [];
  shouldShowHeader: boolean = true;
  expandPanel: boolean = false;
  sliderUpImg: string = '../../../../../assets/img/vs_img/slide-up-black.png';
  sliderDownImg: string = '../../../../../assets/img/vs_img/slide-down-black.png';
  pathStatus: string = 'N/A';
  isReady: boolean = false;
  pathIp: string = null;
  serviceType: string;
  computerHop = [
    {
      address: 'computer',
      name: 'computer',
      color: 'green',
    },
  ];

  zoomHop = [
    {
      address: 'zoom',
      name: 'zoom',
      color: 'green',
    },
  ];

  constructor() { }

  ngOnInit() { }

  openPanel = () => {
    this.shouldShowHeader = false;
  };

  collapsePanel = () => {
    this.expandPanel = false;
    this.shouldShowHeader = true;
  };

  ngOnChanges(changes: SimpleChanges) {
    if (this.cloudUserStats && this.cloudUserStats.zoom_plugin_data && this.cloudUserStats.plugin_status == 1) {
      this.serviceType = this.cloudUserStats.service_type;

      this.formatCallSimulatorData();
      if (
        this.pathIp === null &&
        this.cloudUserStats.liveMeetings === 1 &&
        this.callSimulatorData.length > 0
      ) {
        this.pathIp = this.callSimulatorData[0].ip_address;
        this.tabOnChange(this.pathIp);
      }
    }
  }

  formatCallSimulatorData() {
    if (this.serviceType === 'zoom') {
      const callSim =  this.cloudUserStats.zoom_plugin_data.callSimulator.callSimulatorZoom.filter( e => e.data );

      this.callSimulatorData = [
        ...callSim,
      ];

      // console.log('zoom', this.callSimulatorData)

    } else if (this.serviceType === 'teams') {
      const callSim = this.cloudUserStats.zoom_plugin_data.callSimulator.callSimulatorTeams.filter( e => e.data );
      
      this.callSimulatorData = [
        ...callSim, // for user
      ];
    }

    if (this.cloudUserStats.liveMeetings === 0) {
      this.pathStatus = 'N/A';
      this.callSimulatorData = [];
    }
  }

  sliderDown = (status) => {
    if (status === 'Warning') {
      this.sliderDownImg = '../../../../../assets/img/vs_img/slide-down-yellow.png';
    }

    if (status === 'Alert') {
      this.sliderDownImg = '../../../../../assets/img/vs_img/slide-down-red.png';
    }

    if (status === 'Good') {
      this.sliderDownImg = '../../../../../assets/img/vs_img/slide-down-black.png';
    }
  };

  sliderUp = (status) => {
    if (status === 'Warning') {
      this.sliderUpImg = '../../../../../assets/img/vs_img/slide-up-yellow.png';
    }

    if (status === 'Alert') {
      this.sliderUpImg = '../../../../../assets/img/vs_img/slide-up-red.png';
    }

    if (status === 'Good') {
      this.sliderUpImg = '../../../../../assets/img/vs_img/slide-up-black.png';
    }
  };

  tabOnChange(ip) {
    this.pathIp = ip;
    const status = this.callSimulatorData.filter((e) => e.ip_address === ip);
    if (status) {

      let pathDetectionStatus = status[0].path_detection_status;

      let isPacketLossHundred = this.checkForPacketLossHundred(status);

      if (isPacketLossHundred === 1) {
        pathDetectionStatus = 'Good'
      }
      this.pathStatus = pathDetectionStatus;
      this.sliderDown(pathDetectionStatus);
      this.sliderUp(pathDetectionStatus);
      // this.pathStatus = status[0].path_detection_status;
      // this.sliderDown(status[0].path_detection_status);
      // this.sliderUp(status[0].path_detection_status);
    }
  }

  checkForPacketLossHundred(status) {
    let result = 0;

    let flag = 0;
    let redCount = 0;
    const data = status[0].data;

    if (data) {
      for (let i = 0; i < data.length && !flag; i++) {
        if (data[i].color === 'red') {
          let lossVal = parseInt(data[i].lossVal);
          if (lossVal === 100) {
            redCount += 1;
          } else if (lossVal <= 99) {
            flag = 1;
          }
        } else if (data[i].color === 'yellow') {
          flag = 1;
        }
      }
    }

    if (flag === 0 && redCount > 1) {
      result = 1;
    }
    return result;
  }
}
