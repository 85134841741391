import * as moment from 'moment-timezone'
import { Subscription } from 'rxjs'
import { AuthService } from 'src/app/services/auth.service'
import { PrintDataService } from 'src/app/services/print-data.service'

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'

import { RefreshApiService } from '../../services/refresh-api.service'

@Component({
  selector: 'app-video-wellness-check',
  templateUrl: './video-wellness-check.component.html',
  styleUrls: ['./video-wellness-check.component.scss']
})
export class VideoWellnessCheckComponent implements  OnInit, OnChanges {
  @Input() propDetails: any;
  @Input() popupState: any;
  isShowTableData: boolean = true;
  shouldShowLoaderBtn: boolean = false;
  filterListner: Subscription;

  incidentsData = [];
  constructor(
    private Auth: AuthService,
    private printData: PrintDataService,
    private refreshApiService: RefreshApiService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.propDetails && changes.propDetails) {
      this.getData();
    } 
  }

  getData = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.shouldShowLoaderBtn = true;

    this.Auth.getVideoWellnessCheck({
      'company_id': decodedToken.company_id,
      'device_id': this.propDetails.deviceId
    }).subscribe((data: any) => {
      const res = data.response
      if (res.length < 1) {
        this.isShowTableData = false;
      }

      this.incidentsData = res;

      console.log('getVideoWellnessCheck: ',res);
      this.incidentsData = data.response.map((i) => {

        return  {
          ...i,
          incident_id: this.getIncident(i), 
          start_time: this.formatTime(i.start_time),
          end_time: this.formatTime(i.end_time),
          summary: this.getSummary(i.notification_info)
          // summary: issue[issue.length - 1].trim(),
          // event_details: this.processEventDetails(eventsIssues)
        };
      });

      console.log('incidentsData: ', this.incidentsData)
      // console.log('data: ', data)

      this.shouldShowLoaderBtn = false;
    });
  };

  getSummary = ({message}:any) => {
    return message ? message : 'All Ok';
  }

  getIncident = (i:any) => {
    if(i.video_incident_id != null) {
      return `V-${i.video_incident_id}`
    }

    return '--'
  }

  processEventDetails = (eventsIssues) => {

    console.log(eventsIssues)

    let arr = [];

    const { reboot,wake_up, get_device } = eventsIssues;

    
    for (const event in {wake_up, reboot, get_device }) {
      if(eventsIssues[event]) {
        arr.push({
          trigger: this.translateTrigger(event),
          status:  eventsIssues[event].status ,
          // expected: eventsIssues[event].expected,
          // result: eventsIssues[event].result,
          end_time: this.formatTime(eventsIssues[event].end_date),
          start_date: this.formatTime(eventsIssues[event].start_date),
        });
      }
 
    }



    return arr;
  }



  translateTrigger(trigger) {
    console.log(trigger)
    switch (trigger) {
      case 'camera':
        return 'Camera(s) disconnected.';
      case 'mic':
        return 'Microphone(s) disconnected.';
      case 'speaker':
        return 'Speaker(s) disconnected.';
      case 'offline':
        return 'Device offline.';
      case 'iot_component_offline':
        return 'Iot not resonding.';
      case 'iot_webservice_offline':
        return `Iot webservice not responding.`;
      case 'latency':
        return 'Exceeded Latency alert threshold.';
      case 'jitter':
        return 'Exceeded Jitter alert threshold.';
      case 'temperature':
        return 'Exceeded Temperature alert threshold.';
      case 'packetsloss':
        return 'Exceeded Packetloss alert threshold.';
      case 'call_disconnect':
        return 'Call disconnected.';
      default:
        return 'Trigger Unknown';
    }
  }


  formatTime(t) {
    if (t == null || !t) {
      return '--';
    } else {
      return moment(t)
        .utc()
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format('MM-DD-YYYY hh:mma');
    }
  }

  printIncidentsToPdf = () => {
    this.printData.printToPdf('incident-history-list-container', 'Incident Report');
  };
}
