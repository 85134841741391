<div class="recommended-actions">
  <div class="action-text">Recomended actions</div>
  <div>
    <div *ngIf="isShowQosRecommendedActions"><p class="issue">Please Contact Support</p></div>
    <div *ngIf="isShowDevicesRecommendedActions">
      <p class="issue">1) Please ensure the ( {{ devicesWithIssues }} ) is connected.</p>
      <p class="issue">2) Please disconnected and connect the ( {{ devicesWithIssues }} ).</p>
      <p class="issue">3) Please Reboot the device by initiating the request above.</p>
      <p class="issue">4) Please Contact Support.</p>
    </div>
    <div *ngIf="!isShowQosRecommendedActions && !isShowDevicesRecommendedActions"><p>None</p></div>
  </div>
</div>
