<div *ngIf="quadrantType === 'video'">
  <div *ngIf="(polycomDevices  && ciscoDevices  && logitechDevices ) ||
              (ciscoDevices  && polycomDevices ) ||
              (ciscoDevices  && logitechDevices ) ||
              (polycomDevices  && logitechDevices ); else noSlideShow"
	>
		<!-- <img *ngIf="ciscoDevices" src="../../assets/img/cisco.png" class="cisco" alt="Device Type" />
		<img *ngIf="polycomDevices && !interval" src="../../assets/img/poly_logo.png" class="polycom" />
		<img *ngIf="logitechDevices && interval" src="../../assets/img/logitechh.png" [ngClass]="ciscoDevices?'polycom':'cisco'" /> -->
    <img *ngIf="images.length" [src]="images[interval].img" class="animation">
	</div>

	<ng-template #noSlideShow>
		<img *ngIf="ciscoDevices == 1" [src]="'../../assets/img/cisco.png'" />
		<img *ngIf="polycomDevices == 1" [src]="'../../assets/img/poly_logo.png'" />
		<img *ngIf="logitechDevices == 1" src="../../assets/img/logitechh.png" />
	</ng-template>
</div>

<div *ngIf="quadrantType === 'cloud'">
  <div *ngIf="zoomEnabled == true && teamsEnabled == true; else noSlideShow">
    <img *ngIf="zoomEnabled" src="../../../assets/img/vs_img/zoom_logo.png" class="cisco adevice0" />
    <img *ngIf="teamsEnabled" src="../../../assets/img/vs_img/teams_logo.png" class="polycom adevice1" />
  </div>

  <ng-template #noSlideShow>
    <img *ngIf="zoomEnabled == true" src="../../../assets/img/vs_img/zoom_logo.png" alt="Device Type" />
    <img *ngIf="teamsEnabled == true" src="../../../assets/img/vs_img/teams_logo.png" />
  </ng-template>
</div>