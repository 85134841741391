<h4 class="text-center mt-2 mb-0">
  {{ listTitle }}
</h4>
<!-- <div class="rainBot"></div> -->
<table class="table table-hover table-sm bg-white mt-2 mb-2" style="padding-bottom:100px; table-layout: fixed;">
  <thead>
    <tr>
      <th class="text-left">Site Name</th>
      <th class="text-center">Room Name</th>
      <th class="text-center">Device Name</th>
      <th class="text-center">Ip Address</th>
      <th class="text-center">Serial Number</th>
      <th class="text-center">System Type</th>
      <th class="text-center">Mic</th>
      <th class="text-center">Camera</th>
    </tr>
  </thead>
  <tbody>
    <tr scope="row" style="background:none;">
      <td colspan="8" class="rainBot"></td>
    </tr>
    <tr scope="row" *ngFor="let reportInvent of reportInventory" style="background:none;">
      <td class="text-left" style="word-wrap: break-word;">{{reportInvent.site_name}}</td>
      <td class="text-left" style="word-wrap: break-word;">{{reportInvent.room_name}}</td>
      <td class="text-center" style="word-wrap: break-word;">{{reportInvent.device_name}}</td>
      <td class="text-center">{{reportInvent.ip_address}}</td>
      <td class="text-center">{{reportInvent.serial_number}}</td>
      <td class="text-center">{{reportInvent.system_type}}</td>
      <td class="text-center">{{reportInvent.mic}}</td>
      <td class="text-center">{{reportInvent.cam}}</td>
    </tr>
  </tbody>
</table>