<button type="button" [ngClass]="{'btnbacklite': backcolorbtn == 'btnbacklite','not-button': isbutton == 'not' }"
  class="btn btn-reg not-button" [class.btnpressed]="activeBtn==1">

  <h3 class="count" *ngIf="count; else noData"
    [ngClass]="{'gray': color == 'gray', 'green': color == 'green', 'yellow': color == 'yellow', 'red': color == 'red'}">
    {{count | wholeNumber}}
  </h3>

  <p *ngIf="label !== 'VIP Users'; else otherLabel">{{label}}</p>
</button>

<ng-template #noData>
  <div *ngIf="!loading || loading == null">--</div>
  <img *ngIf="loading" style="height: 2em;" [src]="loader" />
</ng-template>

<ng-template #otherLabel>
  <div>
    <p>VIP</p>
    <p>Users</p>
  </div>
</ng-template>