import { Component, Inject, EventEmitter } from '@angular/core';
// import { GetDesktopPluginService } from '../../../services/get-desktop-plugin.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-teams-room-warning-modal',
  templateUrl: './teams-room-warning-modal.component.html',
  styleUrls: ['./teams-room-warning-modal.component.scss']
})
export class TeamsRoomWarningModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<TeamsRoomWarningModalComponent>
  ) {


  }

  closeDialog = () => {
    // this.isLoading = false;
    this.dialogRef.close(true);
  };



}
