import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GetPluginService } from 'src/app/services/get-plugin.service';
import { PopupTypeService } from '../../services/popupType.service';
import { ZoomPluginStatusService } from '../../services/zoom-plugin-status.service';
import { ZoomPluginActionService } from '../../services/zoom-plugin-action.service';

@Component({
  selector: 'app-speaker-list',
  templateUrl: './speaker-list.component.html',
  styleUrls: ['./speaker-list.component.scss'],
})
export class SpeakerListComponent implements OnChanges {
  @Input() connectedAudioSpeaker: any;
  @Input() pluginId: number;
  @Input() serviceType: any = 'zoom';

  speakerList: any;
  speakerStatus: string;
  selectedSpeakerId: string;
  deviceStatus: number;

  constructor(
    private getPluginService: GetPluginService,
    public ds: PopupTypeService,
    public zoomPluginStatus: ZoomPluginStatusService,
    public zoomPluginAction: ZoomPluginActionService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.connectedAudioSpeaker) {
      if (this.connectedAudioSpeaker) {
        // console.log(this.connectedAudioSpeaker);
        this.arrangeMicList(this.connectedAudioSpeaker);
      }
    }
  }

  arrangeMicList = (list) => {
    const copiedArr = [...list];
    let speakerInuse = [];
    let otherSpeaker = [];
    let newArry = [];

    if( this.serviceType == 'teams') {
      speakerInuse = copiedArr.filter((c) => c.inuse_teams);
      otherSpeaker = copiedArr.filter((c) => !c.inuse_teams);
      newArry = speakerInuse.concat(otherSpeaker);
    } else {
      speakerInuse = copiedArr.filter((c) => c.inuse);
      otherSpeaker = copiedArr.filter((c) => !c.inuse);
      newArry = speakerInuse.concat(otherSpeaker);
    }
    
     newArry = speakerInuse.concat(otherSpeaker);
    this.speakerList = newArry;
    if (!this.isEmpty(this.speakerList)) {
      this.speakerSelect(this.speakerList[0].id);
    }
  };

  speakerSelect = (id) => {
    const filterData = this.connectedAudioSpeaker.filter((val) => val.id === id);
    this.speakerStatus = this.zoomPluginStatus.pluginStatus[filterData[0].status];
    this.deviceStatus = filterData[0].status;
    this.selectedSpeakerId = id;
  };

  isEmpty = (arr) => {
    return arr.length === 0;
  };

  setDevice = () => {
    const actionParams = JSON.stringify({ selectedId: this.selectedSpeakerId });
    const actionName = 'SetConfigurationAudioOutputSelectedId';
    this.zoomPluginAction.visibility_one_loader = true;
    this.getPluginService
      .createAction(this.pluginId, actionParams, actionName)
      .subscribe((result) => {
        if (result.response[0].status === 'OK') {
          this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
          this.zoomPluginAction.setActionCount();
        } else {
          this.zoomPluginAction.visibility_one_loader = false;
        }
      });
  };
}
