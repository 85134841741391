<div class="container-sm audio-monitoring">
  <h3 class="text-center title">Monitoring</h3>
  <img class="banner" src="../../../../assets/img/teleportivity_img/logo.png" alt="" />

  <app-support-contact
    [directAccessInfo]="audioDeviceData?.direct_access_info"
  ></app-support-contact>

  <div class="col-sm audio-monitoring-content">
    <p class="text-sm-left site-name">
      Site: <span>{{ siteName }}</span>
    </p>

    <p class="text-sm-left room-name">
      Room name: <span>{{ roomName }}</span>
    </p>

    <app-device-status
      [paused]="audioDeviceData?.paused"
      [online]="audioDeviceData?.online"
      [collector_status]="audioDeviceData?.collector_status"
    ></app-device-status>

    <app-device-action [deviceData]="audioDeviceData" deviceType="audio"> </app-device-action>

    <div class="system-status-container {{ hasIssues ? 'has-incidents' : 'no-incidents' }}">
      <mat-expansion-panel
        class="system-status-expansion"
        hideToggle="true"
        [expanded]="isExpandSystemStatusConnectedDevices"
      >
        <mat-expansion-panel-header
          class="expansion-header-top"
          (click)="toggleSystemStatusConnectedDevices()"
          *ngIf="isShowSystemStatusConnectedDevicesHeader"
        >
        </mat-expansion-panel-header>
        <app-audio-system-status
          *ngIf="isShowSystemStatusConnectedDevicesBody"
          [networkIcon]="networkIcon"
          [lanPortStatus]="lanPortStatus"
          [micIcon]="micData.micIcon"
          [micStatus]="micData.micStatus"
          [isDisplayPresence]="isDisplayPresence"
        ></app-audio-system-status>
      </mat-expansion-panel>

      <mat-expansion-panel hideToggle="true" [expanded]="isExpandSystemStatusCall">
        <mat-expansion-panel-header
          class="expansion-header-bottom"
          (click)="toggleSystemStatusCall()"
          *ngIf="isShowSystemStatusCallHeader"
        >
        </mat-expansion-panel-header>
        <app-device-call-status
          *ngIf="isShowSystemStatusCallBody"
          deviceType="audio"
          [callData]="callStatusData"
          [sipGateColor]="sipGate?.sipGateColor"
          [sipGateText]="sipGate?.sipGateText"
          [isShowSip]="sipGate?.isShowSip"
        ></app-device-call-status>
      </mat-expansion-panel>
    </div>

    <app-recommended-actions type="audio" [incidentSummary]="incidentSummary">
    </app-recommended-actions>
  </div>
</div>
