import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-video-touch-panel-logitech',
  templateUrl: './video-touch-panel-logitech.component.html',
  styleUrls: ['./video-touch-panel-logitech.component.scss']
})
export class VideoTouchPanelLogitechComponent implements OnInit, OnChanges {

  @Input() logitechData: any;
  @Input() isDevicePaused: boolean;
  onlineIcon: string = '../../../../assets/img/touch_icon/logitech-touch_online.png';
  offlineIcon:string = '../../../../assets/img/touch_icon/logitech-touch_offline.png';
  pauseIcon:string = '../../../../assets/img/touch_icon/logitech-touch_pause.png';
  touchIcon: string = this.offlineIcon;
  tapName: any;
  tapOrientation: any;
  tapFirmware: any;
  tapPowerSaving: any;
  
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges) {
    if((changes.logitechData && this.logitechData) || changes.isDevicePaused) {
      this.setData();
    }
  }
  
  setData = () => {
    // console.log('VideoTouchPanelLogitech: ', this.logitechData)
    
    const { settings: { peripherals } } = this.logitechData
    
    if(peripherals) {
      if(peripherals.length > 0) {
        const [getTap] = peripherals.filter( e => e.type == 'TAP')
        this.tapName = getTap.name
        this.tapOrientation = getTap.tapAutoOrientation
        this.tapFirmware = getTap.firmware
        if (this.isDevicePaused)
          this.touchIcon = this.pauseIcon
        else
          this.touchIcon = this.onlineIcon;
        // this.tapPowerSaving = getTap.tapCustomOrientation
      }
    
    }
    
  }

}
