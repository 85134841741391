<div style="padding-top: 10px;">
  <input #applyFilterr (keyup)="applyFilter(applyFilterr.value)" placeholder="Search..." style="
      width: 100%;
      padding-left: 21px;
      height: 35px;
      font-size: 15px;
      border-bottom: 1px solid lightgray !important;
    " />
  <div class="example-container mat-elevation-z8">
    <table mat-table [dataSource]="cloudRoomsListData" matSort class="mat-elevation-z8">
      <ng-container *ngIf="this.Auth.accessLevel == 'SUPERADMIN'" matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Company</strong>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.company_name }}</td>
      </ng-container>

      <!-- Site Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Name</strong>
        </th>
        <td mat-cell *matCellDef="let element" matTooltip="{{ element.room_name }}" [matTooltipPosition]="'above'">{{
          element.room_name }}</td>
      </ng-container>

      <ng-container matColumnDef="health">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Health</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <app-table-health-icon [serviceType]="element.service_type" [value]="element.health" [paused]="element.pause"
            style="text-align: center;">
          </app-table-health-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="qos">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Qos</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <app-table-qos-icon [value]="element.qos" style="text-align: center;"></app-table-qos-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="livembps">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Live Mbps</strong>
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.livembps }}
        </td>
      </ng-container>

      <ng-container matColumnDef="liveMeetings">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>On Call</strong>
        </th>
        <td mat-cell *matCellDef="let element">
          <app-table-zoom-on-call [value]="element?.liveMeetings === 2 ? 1: element.liveMeetings"
            style="text-align: center;">
          </app-table-zoom-on-call>
        </td>
      </ng-container>

      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Call Duration</strong>
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ getDuration(element.duration) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="calender">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Calender</strong>
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.scheduledMeetings == null ? '--' : element.scheduledMeetings }}
        </td>
      </ng-container>

      <ng-container matColumnDef="service">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Service</strong>
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.service }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="clickRow(row)"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [colSpan]="displayedColumns.length">No data found in table</td>
      </tr>
    </table>
  </div>
</div>