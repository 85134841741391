<!-- <span><fa name="phone" class="pr-1"></fa>{{ renderValue }}</span> -->


<div style="text-align: center">

  <span *ngIf="renderValue == null || renderValue == 0">
    <img style="width: 13px" src="../../../assets/img/video/hang-up.png" />
  </span>

  <span *ngIf="renderValue > 0">
    <fa-icon [icon]="faIcon" class="pr-1"></fa-icon>{{ renderValue }}
  </span>
</div>