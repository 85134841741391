<div class="video-life-time-usage">

  <div class="video-life-time-usage__row">
    <div class="video-life-time-usage__title">
      <h5>Lifetime Usage</h5>
    </div>

    <div class="video-life-time-usage__button-container">
      <app-btn-utilization [count]="videoDeviceData?.util_total_calls" label="Calls" color="gray"></app-btn-utilization>
      <app-btn-utilization [count]="formatLifetimeUsage(videoDeviceData?.util_total_usage)" label="Usage (d/h/m)"
        color="gray"></app-btn-utilization>
      <app-btn-utilization [count]="videoDeviceData?.util_failed" label="Failed" color="gray"></app-btn-utilization>
    </div>

    <div class="video-life-time-usage__button-container">
      <app-btn-utilization [count]="videoDeviceData?.adhoc" label="Adhoc" color="gray" (click)="openCallLog('ADHOC')"
        [isbutton]="isNotButton"></app-btn-utilization>
      <app-btn-utilization [count]="videoDeviceData?.scheduled" label="Scheduled" color="gray"
        (click)="openCallLog('SCHEDULED')" [isbutton]="isNotButton"></app-btn-utilization>
      <app-btn-utilization [count]="videoDeviceData?.failed" label="Failed" color="gray" (click)="openCallLog('FAILED')"
        [isbutton]="isNotButton"></app-btn-utilization>
    </div>
  </div>



</div>


<!-- 
<div class="row">
  <div class="col-12 px-0">
    <h5>Lifetime Usage</h5>
  </div>
  <div class="col-4 btn-col pl-0 pr-1">
    <app-btn-utilization [count]="videoDeviceData?.util_total_calls" label="Calls" color="gray"></app-btn-utilization>
  </div>
  <div class="col-4 btn-col pl-0 pr-1">
    <app-btn-utilization [count]="formatLifetimeUsage(videoDeviceData?.util_total_usage)" label="Usage (d/h/m)"
      color="gray"></app-btn-utilization>
  </div>
  <div class="col-4 btn-col pl-0 pr-1">
    <app-btn-utilization [count]="videoDeviceData?.util_failed" label="Failed" color="gray"></app-btn-utilization>
  </div>
</div>


<div class="row">
  <div class="col-12 px-0">
    <h5>30 Day Usage</h5>
  </div>
  <div class="col-4 btn-col pl-0 pr-1">
    <app-btn-utilization [count]="videoDeviceData?.adhoc" label="Adhoc" color="gray" (click)="openCallLog('ADHOC')"
      [isbutton]="isNotButton"></app-btn-utilization>
  </div>
  <div class="col-4 btn-col pl-0 pr-1">
    <app-btn-utilization [count]="videoDeviceData?.scheduled" label="Scheduled" color="gray"
      (click)="openCallLog('SCHEDULED')" [isbutton]="isNotButton"></app-btn-utilization>
  </div>
  <div class="col-4 btn-col pl-0 pr-1">
    <app-btn-utilization [count]="videoDeviceData?.failed" label="Failed" color="gray" (click)="openCallLog('FAILED')"
      [isbutton]="isNotButton"></app-btn-utilization>
  </div>
</div> -->