import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collector-delete-site',
  templateUrl: './collector-delete-site.component.html',
  styleUrls: ['./collector-delete-site.component.css']
})
export class CollectorDeleteSiteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
