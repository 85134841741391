<div class="collector-stats">
  <app-collector-live-stats [collectorLiveStats]="collectorDetails">
  </app-collector-live-stats>

  <hr />

  <app-collector-path-test [collectorDetails]="collectorDetails">
  </app-collector-path-test>

  <!-- <app-cloud-live-call-list [liveParticipants]="s"></app-cloud-live-call-list> -->

  <app-collector-host-utilization [collectorHostUtilization]="collectorDetails">
  </app-collector-host-utilization>

  <hr />

  <app-cloud-user-notes type="COLLECTOR" [notes]="collectorDetails?.notes" [zoom_user_id]="collectorDetails?.site_id">
  </app-cloud-user-notes>
</div>