import { Component, OnInit, Input } from '@angular/core';
import { PopupTypeService } from '../../../services/popupType.service';
import { environment } from './../../../../environments/environment';
import { PopupSendDataService } from '../../../services/popup-send-data.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/services/auth.service';
import { faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-info-site-details',
  templateUrl: './info-site-details.component.html',
  styleUrls: ['./info-site-details.component.scss'],
})
export class InfoSiteDetailsComponent implements OnInit {
  @Input() siteInformation: any;

  constructor(
    public ds: PopupTypeService,
    private sendPopupDataService: PopupSendDataService,
    private Auth: AuthService
  ) { }

  server_url: string = environment.serverUrl;
  mspLogo: string = environment.config.logo;
  isSuperAdmin: any;
  iconAddSite = environment.config.dynamicImage.iconAddSite;
  iconVirtual = environment.config.dynamicImage.iconVirtual;
  companyLogo: any;
  faIcon = faUser;
  ngOnInit() {
    // send message to subscribers via observable subject
    this.ds.sendPopupType(0);
    this.getCompanyInfo();
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.isSuperAdmin = decodedToken.accesslevel == 'SUPERADMIN';
  }


  getDecodedtoken = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    return helper.decodeToken(token);
  };

  getCompanyInfo() {
    const decodedToken = this.getDecodedtoken();
    this.Auth.getCompanyInfo({ company_id: decodedToken.company_id }).subscribe((data) => {
      const [newData] = data.response;
      this.companyLogo = this.server_url + '/api/v1/images/companylogo/' + newData.logo;
      // console.log(this.companyLogo);
    });
  }


  nullCheck(value) {
    return value ? value : '--';
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
    // console.log(p);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }
}
