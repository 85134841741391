import * as moment from 'moment-timezone'
import { Subscription } from 'rxjs'

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { EventEmitterGlobalService } from '../../services/event-emitter-global.service'
import { GetTeamsService } from '../../services/get-teams.service'
import { PopupTypeService } from '../../services/popupType.service'
import { RefreshApiService } from '../../services/refresh-api.service'

@Component({
  selector: 'app-teams-user-panel',
  templateUrl: './teams-user-panel.component.html',
  styleUrls: ['./teams-user-panel.component.scss'],
})
export class TeamsUserPanelComponent implements OnInit {
  PopupTitle: string = 'CLOUD USER';
  teamsDetails: any; // re,pve the default object test again when on call
  teamsID: any;
  dataError: any;
  refreshData: any;
  filterListner: Subscription;
  isMonitoringEnabled: boolean = true;
  isTeamsEnabled: any; // can be pass to stas and info
  wifiRange: any;
  teamsMbps: any;
  networkMonitorTeams: any;
  isDisableDesktopClientPluginToggle: boolean = true; //disable for now
  isShowPeopleCount: boolean;
  isZoomPluginEnabled: boolean = false;
  deskPluginStatus: number;
  lastSync: any;
  id: any;
  teamsUserHealth: number = 100;
  //

  showMonitoringBanner: boolean = false;

  constructor(
    private getTeams: GetTeamsService,
    private route: ActivatedRoute,
    private refreshApiService: RefreshApiService,
    private eventEmmit: EventEmitterGlobalService,
    public ds: PopupTypeService

  ) {
    this.route.params.subscribe((params) => {
      this.teamsID = params['id'];
    });

    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      this.getUserDetails();
    });

  }

  ngOnInit() {
    this.getUserDetails();
    this.getLastSync()
  }

  getLastSync = () => {
    this.getTeams.getLastSync().subscribe(
      (data: any) => {
        const newDate = moment(data.last_sync_stamp).format('MMMM Do YYYY, h:mm:ss a');
        this.lastSync = newDate;
      },
      (err) => { }
    );
  }

  getMonitoringDetails = () => {
    this.getTeams.getMonitoringDetails().subscribe(
      (data: any) => {
        this.isMonitoringEnabled = data !== true ? false : data; // !data is = null
        if (!this.isMonitoringEnabled) {
          this.setSession();
          this.showMonitoringBanner = this.getSession();
          // console.log(this.getSession())
        } else {

        }
      },
      (err) => { }
    );
  };

  removeBanner = () => {
    this.showMonitoringBanner = false
    let bool = false;
    sessionStorage.setItem('teamsBanner', JSON.stringify(bool)); // set new token as close true :)
  }


  setSession = () => {
    const sessionNotification = this.getSession();

    let bool = true;



    if (sessionNotification || sessionNotification == null) {
      sessionStorage.setItem('teamsBanner', JSON.stringify(bool));
    } else {
      bool = false;
      sessionStorage.setItem('teamsBanner', JSON.stringify(bool));
    }

  }

  getSession = () => {
    return JSON.parse(sessionStorage.getItem('teamsBanner'));
  }

  getUserDetails = () => {
    this.getTeams.getUserDetails(this.teamsID).subscribe(
      (data: any) => {
        this.teamsDetails = {
					...data,
					zoom_plugin_connected_devices: data?.zoom_plugin_connected_devices
						? JSON.parse(data.zoom_plugin_connected_devices)
						: [],
					zoom_plugin_data: data?.zoom_plugin_data ? JSON.parse(data.zoom_plugin_data) : null,
					plugin_application: data?.plugin_application ? JSON.parse(data.plugin_application) : null,
					plugin_host_info: data?.plugin_host_info ? JSON.parse(data.plugin_host_info) : null,
					plugin_cpu_info: data?.plugin_cpu_info ? JSON.parse(data.plugin_cpu_info) : null,
					plugin_disk_info: data?.plugin_disk_info ? JSON.parse(data.plugin_disk_info) : null,
					plugin_memo_info: data?.plugin_memo_info ? JSON.parse(data.plugin_memo_info) : null,
					plugin_network_adapters: data?.plugin_network_adapters? JSON.parse(data.plugin_network_adapters) : null,
					status: data?.status? this.getUserStatus(data.status, data.plugin_application) : null,
				}
        // if (this.teamsDetails.health) {
        this.teamsUserHealth = this.teamsDetails?.health ?? 0;
        // }
        if (this.teamsDetails === '' || this.teamsDetails === undefined) {
          this.dataError = true;
        } else {
          this.dataError = false;
        }

        this.getMonitoringDetails();
        this.getTeamsPluginEnabled();
        this.checkIfUserIsOnCall();

      console.log('teamsDetailss: ', this.teamsDetails)

        this.PopupTitle = 'User: ' + this.teamsDetails.firstname + ' ' + this.teamsDetails.lastname;
      },
      (err) => { }
    );

  };
  
  getUserStatus = (status, pluginApplication) => {
    if(pluginApplication == null) {
      return status;
    }
    
    let [parseData] = JSON.parse(pluginApplication);
    let getTeamsStatus = parseData.teams
    
    if(getTeamsStatus) {
      return 'Online'
    }
    
    return status;
  }

  checkIfUserIsOnCall = () => {
    const { liveMeetings, plugin_host_info, id } = this.teamsDetails;
    if (liveMeetings && this.isTeamsEnabled && plugin_host_info) {
      this.networkMonitorTeams = this.getNetworkMonitorTeams(this.teamsDetails);
      this.wifiRange = plugin_host_info.wifi_signal_strength.split('%')[0] || '0';

      if (this.networkMonitorTeams) {
        this.calculatedMbpsTeams(this.networkMonitorTeams)
      }

      this.teamsDetails = {
        ...this.teamsDetails,
        calculatedMbpsTeams: this.teamsMbps,
        wifiRange: this.wifiRange,
        wifiSsid: plugin_host_info.wifi_ssid
      }
      // console.log('teamsDetails: ', this.teamsDetails)
    }
  }


  calculatedMbpsTeams = ({ receive_speed, send_speed }) => {

    if ((receive_speed != null && send_speed != null) && (receive_speed != '' && send_speed != '')) {
      const newMbps = parseFloat(receive_speed.split("K")[0]) + parseFloat(send_speed.split("K")[0]);
      this.teamsMbps = (newMbps / 122).toFixed(2)
    } else {
      this.teamsMbps = '--'
    }
  }

  getNetworkMonitorTeams = ({ service_type, zoom_plugin_data }) => {
    if (service_type === 'teams' && zoom_plugin_data !== null) {
      const {
        zoom_plugin_data: {
          networkMonitor: { networkMonitorTeams },
        },
      } = this.teamsDetails;

      return networkMonitorTeams;
    }
    return false;
  };

  getTeamsPluginEnabled = () => {
    if (this.teamsDetails.plugin_id !== null) {
      this.isDisableDesktopClientPluginToggle = false;
    } else {
      this.isDisableDesktopClientPluginToggle = true;
    }

    this.isZoomPluginEnabled =
      !this.isDisableDesktopClientPluginToggle && this.teamsDetails.zoom_plugin_enabled === 1;
    this.deskPluginStatus = this.teamsDetails.plugin_status;
    this.isTeamsEnabled = this.isZoomPluginEnabled && this.deskPluginStatus == 1;

    // // console.log('isTeamsEnabled: ', this.isTeamsEnabled)
  }

  routeToTeamns = () => {
    this.eventEmmit.watcherParams('teams');
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

}
