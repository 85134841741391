<div class="iot-lite-dialog">

    <div class="iot-lite-dialog__header">
        <div>
            Message
        </div>
        <div (click)="closeDialog()" class="iot-lite-dialog__header-close-button">
            <img src="./assets/img/iot/delete-btn.png" alt="" />
        </div>
    </div>

    <div class="iot-lite-dialog__body">
        {{message}}
    </div>

    <div class="iot-lite-dialog__footer" *ngIf="showButton">
        <button class="btn mb-3" (click)="onProceed()">Proceed</button>
    </div>
</div>