<div class="speakerlist">
  <div class="speakerlist__col-1">
    <div class="speakerlist__select">
      <label>Select Speaker Device:</label>
      <select #selectSpeakerList class="form-control" required (change)="speakerSelect(selectSpeakerList.value)">
        <option *ngFor="let item of speakerList" value="{{ item.id }}">
          {{ item.name }}
        </option>
      </select>
    </div>
    <div class="speakerlist__btn-col">
      <button
        [ngClass]="{
          'speakerlist__disable': deviceStatus == 4 || deviceStatus == 11 || deviceStatus == 12
        }"
        class="form-control btn btn-primary"
        (click)="setDevice()"
      >
        Save
      </button>
      <button class="form-control btn btn-primary" (click)="this.ds.sendPopupType(0)">
        Cancel
      </button>
    </div>
    <div class="row alert alert-danger">
      Warning: Controller will go offline while your setting is changed.
    </div>
  </div>
  <div class="speakerlist__status">
    <div>Status</div>
    <div>{{ speakerStatus }}</div>
  </div>
</div>
