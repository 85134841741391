import { Injectable } from '@angular/core';
import {
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
  loginType: string;
  constructor(private auth: AuthService, public router: Router) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.loginType = decodedToken.loginType;
  }
  canActivateChild (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.loginType === 'directAccess') {
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['login']);
      return false;
    }
    //Check to see if User has been authenitcated
    if (!this.auth.isAuthenticated()) {
      // If not authenitcated then re-routed back to login page
      // // console.log('You need to be login to access this page-auth.guard');
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
  // return this.auth.isLoggedIn;
}
