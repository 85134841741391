<div class="col-12 m-2 reports-cover">
  <div class="row">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <img [src]="dashboardLogo" style="width:350px;">
            </div>
            <div class="col-12">
              <!-- <h3 class="text-left font-weight-bold pl-3">Site Report</h3> -->
            </div>
          </div>
        </div>
        <div class="col-6">
          
        </div>
      </div>
    </div>
  </div>
  <div class="row rounded bg-white pt-2 mr-3 ml-1">
    <div class="col-8">
      <h3>{{siteTitle}}</h3>
      <h3>{{siteSubTitle}}</h3>
      <h3>{{months[fromdate.month-1]}} {{fromdate.day}}, {{fromdate.year}} - {{months[todate.month-1]}} {{todate.day}}, {{todate.year}}</h3>
      <br>
      <h3>{{companyInfo[0].company_name}}</h3>
      <h3>{{companyInfo[0].address1}}, {{companyInfo[0].address2}}</h3>
      <h3>{{companyInfo[0].city}}, {{companyInfo[0].state}}. {{companyInfo[0].zip}}</h3>
    </div>
    <div class="col-4">
      <div *ngIf="siteSubTitle != 'Inventory Report'" style="border: 12px solid gray;
      width: 12em;
      text-align: center;
      height: 12em;
      margin: 15px auto;">
        <h1 *ngIf="letterGrade.avg_health >= 90 || letterGrade.avg_health === 0" class="grade-letter text-success">A {{letterGrade.videoConferSite}}</h1>
        <h1 *ngIf="letterGrade.avg_health >= 41 && letterGrade.avg_health <= 89" class="grade-letter text-warning">B {{letterGrade.videoConferSite}}</h1>
        <h1 *ngIf="letterGrade.avg_health <= 40 && letterGrade.avg_health !== 0" class="grade-letter text-danger">C {{letterGrade.videoConferSite}}</h1>
      </div>
    </div>
  </div>
  <div class="row rounded bg-white pt-2 mt-3 mr-3 ml-1" style="height: auto;">
    <div class="col-8">
      <h3>Report Includes</h3>
      <h3 *ngIf="isAllList==false">{{siteName}}</h3>
      <div *ngIf="isAllList==true">
        <h3>All Sites</h3>
        <p>{{siteSubTitle}}</p>
        <p>Incidents</p>
        <div *ngFor="let sname of siteName; let i = index">
          <h3>{{sname.site_name}}</h3>
          <p>{{siteSubTitle}}</p>
          <p>Incidents</p>
        </div>
      </div>
      <div *ngIf="isAllDeviceList==true">
        <h3>All Devices</h3>
        <p>{{siteSubTitle}}</p>
        <p>Incidents</p>
        <div *ngFor="let dname of deviceName; let i = index">
          <h3>{{dname}}</h3>
          <p>{{siteSubTitle}}</p>
          <p>Incidents</p>
        </div>
      </div>
      <div *ngIf="isSingleDevice==true">
        <h3>{{deviceName}}</h3>
        <p>{{siteSubTitle}}</p>
        <p>Incidents</p>
      </div>
    </div>
  </div>
</div>