import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { GetVideoService } from '../../../services/get-video.service';

@Component({
  selector: 'app-btn-device-health',
  templateUrl: './btn-device-health.component.html',
  styleUrls: ['./btn-device-health.component.scss'],
})
export class BtnDeviceHealthComponent implements OnChanges {
  @Input() count: number = 0;
  @Input() label: string = 'Not-Active';
  @Input() mic_impacted: number = 0;
  @Input() mic_connected: number = 0;
  @Input() micModel: string;
  @Input() muteStatus: number;
  @Input() videoDeviceId: number;
  @Input() micCallStatus: number;
  @Input() deviceStatus: number;
  iconClickable: boolean = false;

  constructor(public GetVideo: GetVideoService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.count) {
      this.count = changes.count.currentValue;
      if (this.count === 0) {
        this.mic_impacted = 0;
        this.mic_connected = 0;
      }
    }
    if (changes.total) {
      this.label = changes.label.currentValue;
    }

    if (changes.micModel) {
      if (this.micModel === 'hdx' || this.micModel === 'polystudio') {
        this.iconClickable = this.deviceStatus === 1 ? true : false;
      } else {
        this.iconClickable = false;
      }
    }
  }

  muteAndUnmuteMic = () => {
    let dynamicMicStatus = 0;
    if (this.muteStatus === 0) {
      dynamicMicStatus = 1;
    }
    this.muteStatus = dynamicMicStatus;
    this.GetVideo.muteAndUnmuteButton(dynamicMicStatus, this.videoDeviceId).subscribe((data) => {});
  };
}
