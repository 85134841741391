import { Component, Input, OnInit, SimpleChanges } from '@angular/core'

@Component({
	selector: 'app-video-additional-logitech',
	templateUrl: './video-additional-logitech.component.html',
	styleUrls: ['./video-additional-logitech.component.scss'],
})
export class VideoAdditionalLogitechComponent implements OnInit {
	@Input() videoDeviceData: any

	wifiMac: any
	isAutoDate: any
	ntpServer: any
	is24HoursFormat: any
	currentTime: any
	defaultNtpServer: any
	constructor() {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.videoDeviceData && this.videoDeviceData) {
			this.setData()
		}
	}

	setData = () => {
		if (!this.videoDeviceData.logitech_data) return

		const {
			logitech_data: {
				settings: {
					dateAndTime: { isAutoDate, ntpServer, is24HoursFormat, currentTime, defaultNtpServer },
					deviceInfo: { wifiMac },
				},
			},
		} = this.videoDeviceData

		this.wifiMac = wifiMac
		this.isAutoDate = isAutoDate
		this.defaultNtpServer = defaultNtpServer
		this.ntpServer = ntpServer
		this.is24HoursFormat = is24HoursFormat
		this.currentTime = currentTime
	}
}
