import { Component, OnInit, Input } from '@angular/core';
import { environment } from './../../../../environments/environment';


@Component({
  selector: 'app-table-sitetype-icon',
  templateUrl: './table-sitetype-icon.component.html',
  styleUrls: ['./table-sitetype-icon.component.scss']
})
export class TableSiteTypeIconComponent implements OnInit {

  renderValue: any;
  
  @Input() value: string | number;
  @Input() rowData: any;

  iconAddSite = environment.config.dynamicImage.iconAddSite;
  iconVirtual = environment.config.dynamicImage.iconVirtual;
  
  constructor() { }

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

}
