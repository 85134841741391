import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { GetCloudService } from '../../services/get-cloud.service';

@Component({
  selector: 'app-zoom-manage-service',
  templateUrl: './zoom-manage-service.component.html',
  styleUrls: ['./zoom-manage-service.component.scss'],
})
export class ZoomManageServiceComponent implements OnInit, OnChanges {
  @Input() zoomInfoResult: any = [];
  @Output() sendPopupType: EventEmitter<any> = new EventEmitter<any>();

  enabled: any;
  notification: any;
  enableCheckbox: boolean = false;
  apiKey: any;
  apiSecret: any;
  webToken: any;

  shouldShowMsg: boolean = false;
  msg: string = 'Zoom Service is sucessfully added.';
  msgColor: string = ' ';
  setTimer: any;

  constructor(private getCloudService: GetCloudService) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.zoomInfoResult && this.zoomInfoResult) {
      this.enableCheckbox = true
      this.setData();
    }
  }

  setData = () => {

    const { active, notification } = this.zoomInfoResult;

    this.enabled = active;
    this.notification = notification;
    this.enableCheckbox = this.zoomInfoResult.zoom_id ? true : false;

  };

  emitterEvent($event) {
    if ($event === 'boolSuccess') {
      this.msgConfig('Zoom Service is sucessfully added.', '');
      this.enableService();
      this.enableNotification();
      return;
    }
    if ($event === 'boolErr') {
      this.msgConfig('Invalid API Key or Secret.', 'zoom-service__success-msg--red');
      // this.disableService();
      // this.disableNotification();
      return;
    }
  }

  msgConfig = (text, color) => {
    this.shouldShowMsg = true;
    this.msg = text;
    this.msgColor = color;
    this.setTimer = setTimeout(() => {
      this.shouldShowMsg = false;
      this.clearTimeout();
    }, 3000);
  };

  disableNotification = () => {
    this.getCloudService.disableZoomNotification().subscribe(() => {
      this.sendPopupType.emit('getZoomInfo');
    });
  };

  enableNotification = () => {
    this.getCloudService.enableZoomNotification().subscribe(() => {
      this.sendPopupType.emit('getZoomInfo');
    });
  };

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

  setEnabled = () => {

    this.enableCheckbox = false;
    
    if (this.setFlag(this.enabled))
      this.enableService();
    else
      this.disableService();

};

setNotification = () => {
    this.enableCheckbox = false;
    
    if (this.setFlag(this.notification))
      this.enableNotification();
    else
      this.disableNotification();

  };

  setFlag = (flag) => (flag ? 0 : 1);

  disableService = () => {
    this.getCloudService.disableZoomService().subscribe(() => {
      this.sendPopupType.emit('getZoomInfo');
    });
  };

  enableService = () => {
    this.getCloudService.enableZoomService().subscribe(() => {
      this.sendPopupType.emit('getZoomInfo');
    });
  };
}