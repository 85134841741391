<div class="collector-host-utilization">
  <div class="collector-host-utilization__title">
    <h4>Host Utilization</h4>
  </div>

  <div class="collector-host-utilization__container">
    <div class="collector-host-utilization__two-column">
      <div class="collector-host-utilization__stats-item-data ">
        <img src="../../../assets/img/vs_img/collector_cpu.png" alt="" style="height: 65px;">
      </div>
      <div class="collector-host-utilization__stats-item-label">{{collectorCpu}}%</div>
    </div>

    <div class="collector-host-utilization__two-column">
      <div class="collector-host-utilization__stats-item-data ">
        <img src="../../../assets/img/vs_img/collector_ram.png" alt="" style="height: 50px; margin-bottom: 8px">
      </div>
      <div class="collector-host-utilization__stats-item-label">{{collectorRam}}%</div>
    </div>
  </div>




  <div class="collector-host-utilization__container">
    <div class="collector-host-utilization__half-column">
      <div class="collector-host-utilization__stats_item_data">
        <div>
          <img src="../../../assets/img/vs_img/collector_disk.png" alt="" style="height: 80px; margin-bottom: 8px">
        </div>
      </div>
      <div style="margin-left: 20px;display: flex;flex-direction: column;align-items: baseline;">
        <div *ngFor="let drive of collectorDrives" class="collector-host-utilization__stats_item_label">Drive
          {{drive.drive}} | {{drive.total}} GB ({{drive.free}}% free)</div>
      </div>
    </div>
  </div>
</div>