<button type="button" class="btn-long call-stats not-button">
  <div *ngIf="count == 1 || count ==2">
    <h3><img src="../../../../assets/img/shared/icon_on_call.png" alt="" style="width: 50px"> On Call</h3>
    <h4>Status</h4>
  </div>

  <div *ngIf="count == 3">
    <h5> External Call In Progress</h5>
  </div>

  <!-- template for the On Call if statement -->
  <div *ngIf="count == 0 || count == null">
    <h3><img src="../../../../assets/img/icon_call_available.png" alt="" style="width: 50px">{{serviceType === 'teams' ?
      'Away' : 'Available'}}</h3>
    <h4>Status</h4>
  </div>

  <!-- Need to determin when this case would be true for Call Status Button -->
  <!-- <div *ngIf="count == 0">
    <h3>--</h3>
  </div> -->
</button>