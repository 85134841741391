<div class="collector-panel">
  <div *ngIf="dataError" class="collector-panel__error-page">
    Error - no site data
  </div>

  <div
  *ngIf="collectorDetails?.action_status && [collectorAction.PROCESSING,collectorAction.OPEN].includes(collectorDetails.action_status)"
		class="collector-panel__collector-update"
	>
		Collector Update In Progress
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
	</div>

  <div *ngIf="!dataError">
    <div class="collector-panel__header">
      <app-panel-tools [PopupTitle]="PopupTitle"></app-panel-tools>
    </div>

    <div class="collector-panel__body">
      <div class="collector-panel__info">
        <app-collector-info [collectorDetails]="collectorDetails" [companyLogo]="companyLogo"></app-collector-info>
      </div>

      <div class="collector-panel__stats-and-health">
        <div class="collector-panel__health-container">
          <div class="incident-button" (click)="openHealthHistory()">
            Incidents
          </div>
          <app-system-health (click)="openHealthHistory()" [isCursorPointer]="true"
            [healthNum]="collectorDetails?.paused === 1 ? 0 : collectorDetails?.health"></app-system-health>
        </div>
        <app-collector-stats [collectorDetails]="collectorDetails"></app-collector-stats>
      </div>
    </div>
  </div>
</div>