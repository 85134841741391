<div class="row p-3">
  <div class="col-4 text-center">
        <img src="../../../../assets/img/zoom_logo.png@1X.png"  *ngIf="videoConferSite.rooms_per_device_type.zoom>0">
  </div>
  <div class="col-4 text-center">
    <img src="../../../../assets/img/polycom_logo.png@1X.png"  *ngIf="videoConferSite.rooms_per_device_type.polycom>0">
  </div>
  <div class="col-4 text-center">
    <img src="../../../../assets/img/cisco_logo.png@1X.png"  *ngIf="videoConferSite.rooms_per_device_type.cisco>0">
  </div>
  
</div>

<div class="row p-3">
  <div class="col-2 text-center">
        <h3 *ngIf="videoConferSite.rooms_per_device_type.zoom>0">{{videoConferSite.total_huddle_rooms}} Rooms</h3>
  </div>
  <div class="col-2 text-center">
      <h3 *ngIf="videoConferSite.rooms_per_device_type.zoom>0">{{videoConferSite.cloud_users.users}} Users</h3>
  </div>
  <div class="col-4 text-center">
    <h3 *ngIf="videoConferSite.rooms_per_device_type.polycom>0">{{videoConferSite.rooms_per_device_type.polycom}} Rooms</h3>
  </div>
  <div class="col-4 text-center">
    <h3 *ngIf="videoConferSite.rooms_per_device_type.cisco>0">{{videoConferSite.rooms_per_device_type.cisco}} Rooms</h3>
  </div>
  
</div>