

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'
import { NgbCalendar, NgbDate, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'

import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'
import { GetCloudService } from '../../services/get-cloud.service'

@Component({
	selector: 'app-reports',
	templateUrl: './reports.component.html',
	//  templateUrl: "./reports.component.html?v=" + new Date().getTime(),
	styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnChanges {
  @Input() popupState: any
  dev_server = environment.dev_server;
  title: any = 'Available on December 1st.';
  report_type: any;
  report_user: any;
  report_details: any;
  allSiteListData: any;
  siteApiData: any;
  huddleApiData: any = [];
  deviceApiData: any;
  companyApiData: any;
  topFiveSiteApiData: any;
  topFiveHuddleApiData: any;
  topFiveDeviceApiData: any;
  videoConferSiteApiData: any;
  videoConferAllSiteApiData: any;
  videoConferHuddleApiData: any;
  videoConferAllHuddleApiData: any;
  videoConferDeviceApiData: any;
  inventoryDeviceApiData: any;
  incidentDeviceApiData: any;
  incidentAllDeviceApiData: any;
  incidentSingleDeviceApiData: any;
  siteHealthApiData: any;
  model: NgbDateStruct;
  date: { year: number, month: number };
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  genFromDate: NgbDate;
  genToDate: NgbDate;
  fromMonth: any;
  company_id: any;
  loading: any = 0;
  siteId: any = '*';
  huddleId: any = '*';
  deviceId: any = '*';
  sitename: any = "All Sites";
  huddlename: any = "All Huddle Rooms";
  sitetype: any = -1;
  huddletype: any = -1;
  devicename: any = "All Devices";
  genSitename: any = "All Sites";
  genDevicename: any = "All Sites";
  genSitetype: any = -1;
  genHuddleName: any = "All Huddle Rooms";
  genHuddleType: any = -1;
  reportUser: any;
  hideService: any = 1;
  date_range_start: any;
  date_range_end: any;
  showPoweredBy = environment.config.showPoweredBy;
  loader = environment.config.dynamicImage.loader;


  listAllTitle: any = ['Sites with most incidents',
    'Sites with least incidents',
    'Most utilized sites',
    'Least utilized sites',
    'Sites with most network incidents',
    'Sites with least network incidents',
    'Highest uptime',
    'Lowest uptime'
  ];

  listAllHuddlesTitle: any = ['Huddle rooms with most incidents',
    'Huddle rooms with least incidents',
    'Most utilized huddle rooms',
    'Least utilized huddle rooms',
    'Huddle rooms with most network incidents',
    'Huddle rooms with least network incidents',
    'Highest uptime',
    'Lowest uptime'
  ];

  colAllTitle: any = ['Incident(s)', 'No. of Calls', 'Incident(s)', 'Total time'];

  listSingleTitle: any = ['Devices with most incidents',
    'Devices with least incidents',
    'Most utilized devices',
    'Least utilized devices',
    'Devices with most network incidents',
    'Devices with least network incidents',
    'Highest uptime',
    'Lowest uptime'
  ];

  colSingleTitle: any = ['Incident(s)', 'No. of Calls', 'Incident(s)', 'Total time'];
  listCol1Title: any = ['site(s)', 'Device(s)', 'huddle(s)'];


  isShowCalender: any = 0;
  isManagment: boolean = false;
  isStaff: boolean = false;
  isTopFiveTrend: boolean = false;
  isVideoConferencing: boolean = false;
  isInventory: boolean = false;
  isSites: boolean = false;
  isHuddleRooms: boolean = false;
  isShowDateRange: boolean = false;
  isSiteSelected: boolean = false;
  isSingleSite: boolean = false;
  isHuddleSelected: boolean = false;
  siteSelection: any = 0;
  huddleSelected = 0;
  deviceSelected = 0;
  today: any;
  huddleHealth: any;
  isDateSelected: boolean = false;
  optionSelected = 0; 
  siteNameData = [];
  
  isPrint = 0;
  showRepFlg = 0;
  showMissingMsg = 0;
  deviceNameData = [];
  incidentAllHuddlesApiData: any;
  
    
  constructor(
    private pickerI18n: NgbDatepickerI18n,
    private calendar: NgbCalendar,
    private getCloudDataService: GetCloudService,
    private Auth: AuthService) {
    this.fromDate = calendar.getToday();
    this.today = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 0);
    // this.fromMonth = pickerI18n.getMonthFullName(1);
  }

  ngOnChanges(changes: SimpleChanges) {
  
  }
  
  ngOnInit() {
    this.getAllSiteApiData();
    this.getHuddleApiData();
  }
  

  getAllSiteApiData = () => {
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService()
		const decodedToken = helper.decodeToken(token)
		const companyId = {
			company_id: decodedToken.company_id,
		}

		this.Auth.getAllSiteList().subscribe((data:any) => {
			this.siteApiData = data['response']
			// console.log('this.siteApiData: ',this.siteApiData)
		})

		this.companyApiData = []
		this.Auth.getCompanyInfo().subscribe((data:any) => {
			this.companyApiData = data['response']
			// console.log('this.companyApiData: ',this.companyApiData)
			let arr = []
			for (let d in this.companyApiData) {
				arr.push(this.companyApiData[d])
			}
		})
	}
	
	getHuddleApiData = () => {
		this.huddleApiData = []
		const postData = {
			list_limit: 500,
			off_set: 0,
			search_key: '',
			service_type: 'all',
		}
		this.getCloudDataService.fetchApiCloudAllRooms(postData).subscribe(data => {
			// console.log("THis is cloud data", data);
			this.huddleApiData = data['response']
		})
	}

  // This function Clears the form on popup load
  resetForm = () => {
    this.isPrint = 0;
    this.showRepFlg = 0;
    this.showMissingMsg = 0;

    this.resetReportType();
    this.resetReportDetails();
    this.resetSiteList();
    this.resetHuddleList();
    this.resetDeviceList();
    this.resetDateRange();
    this.resetCalender();
    this.isManagment = false;
    this.isStaff = false;
    // (<HTMLInputElement>document.getElementById('staff')).checked = false;
  }

  resetValueReportType = () => {
    this.isTopFiveTrend = false;
    this.isVideoConferencing = false;
    this.isInventory = false;

    this.isSites = false;

  }


  reportUserChange = (event) => {

    console.log('reportUserChange', event.target.checked)

    this.resetReportType();
    this.resetReportDetails();
    this.resetSiteList();
    this.resetHuddleList();
    this.resetDeviceList();
    this.resetDateRange();
    this.resetCalender();

    this.isStaff = event.target.checked;

    console.log('this.isStaff: ', this.isStaff)

  }

  
  reportTypeChange = (event) => {
    //reset everything below Report Type


    this.resetReportDetails();
    this.resetSiteList();
    this.resetHuddleList();
    this.resetDeviceList();
    this.resetDateRange();
    this.resetCalender();

    this.resetValueReportType()

    if (event.target.value == "top5") {
      this.isTopFiveTrend = true

    }

     if (event.target.value == "video") {
       this.isVideoConferencing = true
       
    }

    if (event.target.value == "inventory") {
      this.isInventory = true
    }

    // this.isTopFiveTrend = (<HTMLInputElement>document.getElementById('top-five-trends')).checked;
    // this.isVideoConferencing = (<HTMLInputElement>document.getElementById('video-conf')).checked;
    // this.isInventory = (<HTMLInputElement>document.getElementById('inventory')) && (<HTMLInputElement>document.getElementById('inventory')).checked;
  }




  reportDetailsChange(event) {


    this.resetSiteList();
    this.resetHuddleList();
    this.resetDeviceList();
    this.resetDateRange();

    // this.isSites = (<HTMLInputElement>document.getElementById('sites')).checked;
    // this.isHuddleRooms = (<HTMLInputElement>document.getElementById('huddle-rooms'))?(<HTMLInputElement>document.getElementById('huddle-rooms')).checked:false;

    this.isSites = event.target.checked;

    console.log("this.isSites",this.isSites)
    console.log("this.isVideoConferencing",this.isVideoConferencing)
  }

	siteIdIndex(event) {
		const n = event.target.value

    this.resetHuddleList();
    this.resetDeviceList();
    this.resetCalender();
    this.resetDateRange();

    this.isShowDateRange = true;
    this.isSiteSelected = true;
    this.siteSelection = 0;
    this.deviceApiData = [];

    if (n == -1) {

      this.siteId = "*";
      this.sitename = "All Sites";
      this.sitetype = -1;
      this.siteSelection = 1;
      // console.log("selected site::" + this.siteSelection + this.siteId + this.sitename);
      this.isSingleSite = false;

    } else if (n >= 0) {

      this.isSingleSite = true;
      this.siteId = this.siteApiData[n].site_id;
      this.sitename = this.siteApiData[n].site_name;
      this.sitetype = this.siteApiData[n].virtual;
      this.updateDeviceList();
    }
  }

	// huddleIdIndex(event) {
	// 	const n = event.target.value;

  //   this.resetSiteList();
  //   this.resetDeviceList();
  //   this.resetCalender();
  //   this.resetDateRange();
  //   this.isHuddleSelected = true;
  //   this.isShowDateRange = true;

  //   if (n == -1) {

  //     this.huddleId = "*";
  //     this.huddlename = "All Huddle Rooms";
  //     this.huddletype = -1;
  //     this.huddleSelected = 1;

  //   } else if (n >= 0) {

  //     this.huddleId = this.huddleApiData[n].room_id;
  //     this.huddlename = this.huddleApiData[n].room_name;
  //     this.huddletype = this.huddleApiData[n].virtual;
  //   }
  // }

  reportDeviceChange() {
    this.resetCalender();
  }

	deviceIdIndex(event) {
		const n = event.target.value;

    this.resetCalender();
    this.resetDateRange();
    this.isShowDateRange = true;

    if (n == -1) {

      this.deviceId = "*";
      this.devicename = "All Devices";
      this.deviceSelected = 1;
    } else if (n >= 0) {
      this.deviceId = this.deviceApiData[n].video_device_id;
      this.devicename = this.deviceApiData[n].device_name;
      this.deviceSelected = 2;
    }
  }

  resetReportType() {

    this.isTopFiveTrend = false;
    this.isVideoConferencing = false;
    this.isInventory = false;

    if ((<HTMLInputElement>document.getElementById('top-five-trends')))
      (<HTMLInputElement>document.getElementById('top-five-trends')).checked = false;
    if ((<HTMLInputElement>document.getElementById('video-conf')))
      (<HTMLInputElement>document.getElementById('video-conf')).checked = false;
    if ((<HTMLInputElement>document.getElementById('inventory')))
      (<HTMLInputElement>document.getElementById('inventory')).checked = false;

  }

  resetReportDetails() {

    this.isSites = false;
    this.isHuddleRooms = false;

    if ((<HTMLInputElement>document.getElementById('sites')))
      (<HTMLInputElement>document.getElementById('sites')).checked = false;
    if ((<HTMLInputElement>document.getElementById('huddle-rooms')))
      (<HTMLInputElement>document.getElementById('huddle-rooms')).checked = false;

  }

  resetDeviceList() {

    this.deviceSelected = 0;
    if ((<HTMLInputElement>document.getElementById('device-list1')))
      (<HTMLInputElement>document.getElementById('device-list1')).checked = false;
    if ((<HTMLInputElement>document.getElementById('device-list2')))
      (<HTMLInputElement>document.getElementById('device-list2')).checked = false;

  }

  resetHuddleList() {

    this.isHuddleSelected = false;
    if ((<HTMLInputElement>document.getElementById('huddle-list1')))
      (<HTMLInputElement>document.getElementById('huddle-list1')).checked = false;
    if ((<HTMLInputElement>document.getElementById('huddle-list2')))
      (<HTMLInputElement>document.getElementById('huddle-list2')).checked = false;

  }

  resetSiteList() {

    this.isSiteSelected = false;
    this.siteSelection = 0;
    this.isSingleSite = false;

    if ((<HTMLInputElement>document.getElementById('site-list1')))
      (<HTMLInputElement>document.getElementById('site-list1')).checked = false;
    if ((<HTMLInputElement>document.getElementById('site-list2')))
      (<HTMLInputElement>document.getElementById('site-list2')).checked = false;

  }

  resetDateRange() {

    this.isShowDateRange = false;
    this.isDateSelected = false;
    if ((<HTMLInputElement>document.getElementById('date-range')))
      (<HTMLInputElement>document.getElementById('date-range')).value = "";

  }
  resetCalender() {

    this.isShowCalender = 0;
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 0);

  }

  setShowCalender(event) {
		const n = event.target.value

    this.isDateSelected = true;
    this.isShowCalender = 1;
    this.isDateSelected = true;
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 0);

    if (n == 'day') {
      this.optionSelected = 1;
      this.toDate = this.fromDate;
    } else if (n == 'week') {
      this.optionSelected = 2;
      if ((this.fromDate.day + 7) > 30) {
        this.toDate.day = ((this.fromDate.day + 7) - 30);
        if (this.fromDate.month == 12) {
          this.toDate.month = 1;
          this.toDate.year = this.fromDate.year + 1;
        } else {
          this.toDate.month = this.fromDate.month + 1;
          this.toDate.year = this.fromDate.year;
        }
      } else {
        this.toDate.day = this.fromDate.day + 7;
        this.toDate.month = this.fromDate.month;
        this.toDate.year = this.fromDate.year;
      }
    } else if (n == 'month') {
      this.optionSelected = 3;
      this.toDate.day = this.fromDate.day;
      if (this.fromDate.month == 12) {
        this.toDate.month = 1;
        this.toDate.year = this.fromDate.year + 1;
      } else {
        this.toDate.month = this.fromDate.month + 1;
        this.toDate.year = this.fromDate.year;
      }
    } else if (n == 'quarter') {
      this.optionSelected = 4;
      this.toDate.day = this.fromDate.day;
      if ((this.fromDate.month + 4) > 12) {
        this.toDate.month = ((this.fromDate.month + 4) - 12);
        this.toDate.year = this.fromDate.year + 1;
      } else {
        this.toDate.month = this.fromDate.month + 4;
        this.toDate.year = this.fromDate.year;
      }
    }

  }

  onDateSelection(date: NgbDate) {
    if (this.optionSelected == 1) {

      this.fromDate = date;
      this.toDate = this.fromDate;
    } else if (this.optionSelected == 2) {
      this.fromDate = date;
      if ((this.fromDate.day + 7) > 30) {
        this.toDate.day = ((this.fromDate.day + 7) - 30);
        if (this.fromDate.month == 12) {
          this.toDate.month = 1;
          this.toDate.year = this.fromDate.year + 1;
        } else {
          this.toDate.month = this.fromDate.month + 1;
          this.toDate.year = this.fromDate.year;
        }
      } else {
        this.toDate.day = this.fromDate.day + 7;
        this.toDate.month = this.fromDate.month;
        this.toDate.year = this.fromDate.year;
      }
    } else if (this.optionSelected == 3) {
      this.fromDate = date;
      this.toDate.day = this.fromDate.day;
      if (this.fromDate.month == 12) {
        this.toDate.month = 1;
        this.toDate.year = this.fromDate.year + 1;
      } else {
        this.toDate.month = this.fromDate.month + 1;
        this.toDate.year = this.fromDate.year;
      }
    } else if (this.optionSelected == 4) {
      this.fromDate = date;
      this.toDate.day = this.fromDate.day;
      if ((this.fromDate.month + 4) > 12) {
        this.toDate.month = ((this.fromDate.month + 4) - 12);
        this.toDate.year = this.fromDate.year + 1;
      } else {
        this.toDate.month = this.fromDate.month + 4;
        this.toDate.year = this.fromDate.year;
      }
    }
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  optionMissing() {
    this.loading = 0;
    this.showMissingMsg = 1;
  }


  genReport() {

    this.showMissingMsg = 0;
    this.loading = 1;
    this.genFromDate = this.fromDate;
    this.genToDate = this.toDate;
    this.genSitename = this.sitename;
    this.genSitetype = this.sitetype;
    this.genHuddleName = this.huddlename;
    this.genHuddleType = this.huddletype;
    this.genDevicename = this.devicename;
    this.report_user = 0;
    this.report_type = 0;
    
  

    if (this.optionSelected == 0) {
      this.optionSelected = 1;
      this.toDate = this.fromDate;
    }
    

   if ((<HTMLInputElement>document.getElementById('staff')).checked == true) {
      this.report_user = 2;
      this.reportUser = 'Staff Report';
    } else {
      this.optionMissing();
      return;
    }
    

    if ((<HTMLInputElement>document.getElementById('top-five-trends')).checked == true) {
      this.report_type = 1;
    } else if ((<HTMLInputElement>document.getElementById('video-conf')).checked == true) {
      this.report_type = 2;
    } else if ((<HTMLInputElement>document.getElementById('inventory')).checked == true) {
      this.fromDate = this.calendar.getToday();
      this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 0);
      this.genFromDate = this.fromDate;
      this.genToDate = this.toDate;
      this.report_type = 3;
    } else {
      this.optionMissing();
      return;
    }

  
  //metcode
    if (this.report_type == 1 || this.report_type == 2) {
      if (this.siteSelection > 0 || this.isHuddleSelected) {
        //site selected
      } else {
        // console.log("report site selection missing");
        this.optionMissing();
        return;
      }
    }
  //metcode
    
  //metcode

    if (this.report_user == 2 && this.report_type == 2 && (this.siteId !== "*")) {
      if (this.deviceSelected > 0 || this.isHuddleSelected) {
        //device selected
      } else {
        // console.log("report device selection missing");
        this.optionMissing();
        return;
      }
    }
  //metcode
    

    this.getHuddleHealth();
    

    
    console.log('this.showMissingMsg: ', this.showMissingMsg)
    console.log('this.genFromDate: ', this.genFromDate)
    console.log('this.genToDate: ', this.genToDate)
    console.log('this.genSitename: ', this.genSitename)
    console.log('this.genSitetype: ', this.genSitetype)
    console.log('this.genHuddleName: ', this.genHuddleName)
    console.log('this.genHuddleType: ', this.genHuddleType)
    console.log('this.genDevicename: ', this.genDevicename)
    console.log('this.report_user: ', this.report_user)
    console.log('this.report_type: ', this.report_type)
    console.log('this.isHuddleSelected: ', this.isHuddleSelected)
    console.log('this.deviceId: ', this.deviceId)
    console.log('this.siteId: ', this.siteId)
    console.log('this.isPrint: ', this.isPrint)
    if (this.isHuddleSelected) {

      if ((this.report_user == 1) && (this.report_type == 1) && (this.huddleId == "*")) {

        
        this.genTopTrendHuddles();
        this.showRepFlg = 12;


      } else if ((this.report_user == 1) && (this.report_type == 1) && (this.huddleId !== "*")) {


        this.showRepFlg = 13;

      } else if ((this.report_user == 1) && (this.report_type == 2) && (this.huddleId == "*")) {


        this.genVideoConfAllHuddles();
        this.showRepFlg = 14;


      } else if ((this.report_user == 1) && (this.report_type == 2) && (this.huddleId !== "*")) {


        this.genVideoConfSingleHuddle();
        this.showRepFlg = 15;

      } else if ((this.report_user == 2) && (this.report_type == 1) && (this.huddleId == "*")) {

        this.genIncidentsAllHuddles();
        this.genTopTrendHuddles();
        this.showRepFlg = 16;

      } else if ((this.report_user == 2) && (this.report_type == 1) && (this.huddleId !== "*")) {

        this.genIncidentsSingleHuddle();
        //missing single Top
        this.showRepFlg = 17;

      } else if ((this.report_user == 2) && (this.report_type == 2) && (this.huddleId == "*")) {

        this.genIncidentsAllHuddles();
        this.genVideoConfAllHuddles();

        this.showRepFlg = 18;

      } else if ((this.report_user == 2) && (this.report_type == 2) && (this.huddleId !== "*")) {
        this.genIncidentsSingleHuddle();
        this.genVideoConfSingleHuddle();
        this.showRepFlg = 19;
      }

      return;
    }

    this.genAverageHealth();
    
    // AXE NEW
    if ((this.report_user == 2) && (this.report_type == 1) && (this.siteId == "*")) {
      this.genIncidents()
      this.genTopTrendAllSites()
      this.showRepFlg = 1;
      
      console.log('staff -> top 5 -> sites -> all sites')
    }
    
    if ((this.report_user == 2) && (this.report_type == 1) && (this.siteId !== "*")) {
      
      console.log('staff -> top 5 -> sites -> single site')
      
      this.genIncidents();
      this.genTopTrendSingleSites();
      this.showRepFlg = 2;
    }
    
    if ((this.report_user == 2) && (this.report_type == 2) && (this.siteId == "*")) {
    
      console.log('staff -> video -> sites -> all site')
    
      this.genAllSiteNames();
      this.genIncidentsAllSites();
      this.genVideoConfAllSites();
      this.showRepFlg = 3;

    }
    
    if ((this.report_user == 2) && (this.report_type == 2) && (this.siteId !== "*") && (this.deviceId !== "*")) {
    console.log('staff -> video -> sites -> single site -> single devices')
    
      this.genIncidentsAllDevices();
      this.genVideoConfDevice();
      this.showRepFlg = 5;
    }
    
    if ((this.report_user == 2) && (this.report_type == 2) && (this.siteId !== "*") && (this.deviceId == "*")) {
      
    console.log('staff -> video -> sites -> single site -> all devices')

      this.genAllDeviceNames();
      this.genIncidentsAllDevices();
      this.genVideoConfDevice();
      this.showRepFlg = 4;
    } 
    

    
    if ((this.report_user == 2) && (this.report_type == 3)) {
    
      console.log('staff -> inventory')
      this.genSitename = "All Sites";
      this.genIncidentsInv();
      this.genInventory();
      this.showRepFlg = 8;
    }
    
  }

  genTopTrendHuddles() {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;

    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyTopFiveHuddle = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
    }
    this.topFiveHuddleApiData = [];
    this.Auth.getTopFiveHuddle(bodyTopFiveHuddle).subscribe((data) => {
      this.loading = 0;
      this.topFiveHuddleApiData = data['response'];
    });
  }

  genVideoConf() {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyVideoDevice = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id_list: this.siteId
    }
    
    console.log('bodyVideoDevice: ', bodyVideoDevice)
    
    
    this.Auth.getVideoConferSite(bodyVideoDevice).subscribe((data) => {
      this.loading = 0;
      this.videoConferSiteApiData = data['response'];
    });
  }

  genVideoConfAllSites = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyVideoDevice = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
    }
    this.Auth.getVideoConferAllSite(bodyVideoDevice).subscribe((data) => {
      this.loading = 0;
      this.videoConferAllSiteApiData = data['response'];
      
      
      console.log('genVideoConfAllSites: ', this.videoConferAllSiteApiData )
    });
  }

  genVideoConfAllHuddles() {

    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyVideoDevice = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
    }
    this.videoConferAllHuddleApiData = [];
    this.Auth.getVideoConferAllHuddle(bodyVideoDevice).subscribe((data) => {
      this.loading = 0;
      this.videoConferAllHuddleApiData = data['response'];
    });
  }
  videoConferSingleHuddleApiData: any;
  genVideoConfSingleHuddle() {

    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyVideoDevice = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      room_id: this.huddleId
    }
    this.videoConferAllHuddleApiData = [];
    this.Auth.getVideoConferSingleHuddle(bodyVideoDevice).subscribe((data) => {
      this.loading = 0;
      this.videoConferSingleHuddleApiData = data['response'];
    });
  }

  genVideoConfDevice() {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyVideoDevice = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id: this.siteId,
      device_id_list: this.deviceId
    }
    this.videoConferDeviceApiData = [];
    this.Auth.getVideoConferDevices(bodyVideoDevice).subscribe((data) => {
      this.loading = 0;
      this.videoConferDeviceApiData = data['response'];
    });
  }

  genInventory() {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const bodyInventoryDevice = {
      company_id: decodedToken.company_id,
      site_id_list: '*'
    }
    this.Auth.getInventoryDevice(bodyInventoryDevice).subscribe((data) => {
      this.loading = 0;
      this.inventoryDeviceApiData = data['response'];
    });
  }



  genIncidentsInv() {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);

    const bodyIncident = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id_list: this.siteId
    }
    this.Auth.getIncidentList(bodyIncident).subscribe((data) => {
      this.incidentDeviceApiData = data['response'][0].data;
    });
  }

  genIncidentsAllSites = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyIncident = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id_list: '*'
    }
    this.Auth.getIncidentAllList(bodyIncident).subscribe((data) => {
      this.incidentAllDeviceApiData = data['response'];
      
      
      console.log('genIncidentsAllSites: ', this.incidentAllDeviceApiData)
    });
  }
  
  
  genIncidentsAllHuddles() {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyIncident = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
    }

    this.Auth.getIncidentAllHuddles(bodyIncident).subscribe((data) => {
      this.incidentAllHuddlesApiData = data['response'];
    });
  }
  incidentSingleHuddlepiData: any;
  genIncidentsSingleHuddle() {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyIncident = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      room_id: this.huddleId
    }
    this.incidentSingleHuddlepiData = [];

    this.Auth.getIncidentSingleHuddles(bodyIncident).subscribe((data) => {
      this.incidentSingleHuddlepiData = data['response'];
    });
  }


  
  getHuddleHealth = () => {

    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyVideoDevice = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
    }
    this.huddleHealth = [];
    this.Auth.getVideoConferAllHuddle(bodyVideoDevice).subscribe((data) => {
      this.loading = 0;
      this.huddleHealth = data['response'];
    });



  }
  genIncidentsAllDevices = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyIncident = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id: this.siteId,
      device_id_list: this.deviceId
    }
    this.Auth.getIncidentAllDeviceList(bodyIncident).subscribe((data) => {
      this.incidentSingleDeviceApiData = data['response'];
    });
  }

  genAverageHealth = () => {
  
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const bodySite = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day + " 00:00:00",
      date_range_end: this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day + " 23:59:59",
      site_id_list: this.siteId
    }
    
    this.Auth.getAverageHealth(bodySite).subscribe((data) => {
      const [res] = data.response  
      this.siteHealthApiData = res;
      
      // console.log('this.siteHealthApiData: ',this.siteHealthApiData)
    });
  }

  


  genAllDeviceNames() {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const bodySite = {
      company_id: decodedToken.company_id,
      site_id: this.siteId,
      device_id_list: '*'
    }
    this.Auth.genAllDeviceNames(bodySite).subscribe((data) => {
      this.deviceNameData = data['response'][0].data;
    });
  }


  updateDeviceList() {
    if (((<HTMLInputElement>document.getElementById('staff')).checked == true) && ((<HTMLInputElement>document.getElementById('video-conf')).checked == true)) {
      this.getAllDeviceApiData();
    }
    this.siteSelection = 2;
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(''), ms)).then(() => console.log('fired'));
  }

  setPrintMode() {
    this.isPrint = 1;
    this.delay(1000).then(any => {
      this.isPrint = 0;
      this.printDivV2('report-main-panel');
      this.delay(1000).then(any => {
        this.isPrint = 0;
      });
    });
  }

  printDiv(divID) {
    //Get the HTML of div
    var divElements = document.getElementById(divID).innerHTML;
    //Get the HTML of whole page
    var oldPage = document.body.innerHTML;

    //Reset the page's HTML with div's HTML only
    document.body.innerHTML =
      "<html><head><title></title></head><body>" +
      divElements + "</body>";

    //Print Page
    window.print();

    //Restore orignal HTML
    document.body.innerHTML = oldPage;

    location.reload();
  }

  printDivV2(divId) {
    let printContents, stylesHtml, linksHtml, popupWin;
    printContents = document.getElementById(divId).innerHTML;
    stylesHtml = this.getTagsHtml('style');
    linksHtml = this.getTagsHtml('link');

    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
        ${stylesHtml}
        ${linksHtml}
        <title>Print Report</title>
        </head>
        <body onload="window.print(); window.close()">
          ${printContents}
        </body>
      </html>
    `)
		popupWin.document.close()
		this.delay(1000).then(any => {
			this.isPrint = 0
		})
	}

	// Helper for printDivV2 - used to preserve styles and links for printed div
	private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string {
		const htmlStr: string[] = []
		const elements = document.getElementsByTagName(tagName)

		for (let idx = 0; idx < elements.length; idx++) {
			htmlStr.push(elements[idx].outerHTML)
		}

		return htmlStr.join('\r\n')
	}

	getAllDeviceApiData() {
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService()
		const decodedToken = helper.decodeToken(token)
		const devInp = {
			company_id: decodedToken.company_id,
			site_id: this.siteId,
		}
		// console.log("Device DATA Input: " + this.siteId, decodedToken.company_id);
		this.deviceApiData = []
		this.Auth.getAllDeviceBySiteList(devInp).subscribe(data => {
			// console.log("Device DATA RESPONSE: " + data["response"]);
      this.deviceApiData = data['response'].map(e => {
        return {
          ...e,
          device_name: e.device_name == "" ? "Device" : e.device_name

        }


      })
      console.log('this.deviceApiData: ', this.deviceApiData)
		})
	}
	
	//AXE
	
	genAllSiteNames =  () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const bodySite = {
      company_id: decodedToken.company_id,
      site_id_list: '*'
    }
    this.Auth.getAllSiteNameList(bodySite).subscribe((data) => {
      const [res] = data.response  
      this.siteNameData = res.data;
      
      console.log('genAllSiteNames: ', this.siteNameData)
      
    });
  }
	
	genTopTrendAllSites = () => {

    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }
    const bodyTopFiveSite = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day
    }
    this.Auth.getTopFiveSite(bodyTopFiveSite).subscribe((data: any) => {
      this.loading = 0;
      const [res] = data.response;
      this.topFiveSiteApiData = res.data
      
      console.log('genTopTrendSingleSites: ',this.topFiveSiteApiData)
   

    });
  }


  genTopTrendSingleSites = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let tmpToDate;
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyTopFiveDevice = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id_list: this.siteId
    }
    this.Auth.getTopFiveDevice(bodyTopFiveDevice).subscribe((data: any) => {
      this.loading = 0;
      const [res] = data.response;
      this.topFiveDeviceApiData  = res.data
      
      console.log('genTopTrendSingleSites: ',this.topFiveDeviceApiData )
    });
  }


	onSubmit() {
		this.genReport()
  }
  
  // AXE FIX
  
    genIncidents = () => {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

      let tmpToDate;
      
    if (this.fromDate == this.toDate) {
      tmpToDate = this.calendar.getNext(this.fromDate, 'd', 1);
    } else {
      tmpToDate = this.toDate;
    }

    const bodyIncident = {
      company_id: decodedToken.company_id,
      date_range_start: this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day,
      date_range_end: tmpToDate.year + "-" + tmpToDate.month + "-" + tmpToDate.day,
      site_id_list: this.siteId
      }
      
    console.log('bodyIncident: ', bodyIncident)

    this.Auth.getIncidentList(bodyIncident).subscribe((data: any) => {
    
      const [newRes] = data.response;
      
      this.incidentDeviceApiData = newRes.data;

      console.log("genIncidents: ", this.incidentDeviceApiData )
    });
  }


}