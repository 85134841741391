import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
// import { decode } from '@angular/router/src/url_tree';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-token-login',
  templateUrl: './token-login.component.html',
  styleUrls: ['./token-login.component.scss'],
})
export class TokenLoginComponent implements OnInit {
  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.userToken = params['id'];
    });
  }

  userToken: any;
  pass1: any;

  ngOnInit() {
    this.loginTokenUser(this.userToken);
  }

  // This function grab the user token from the url params to login as that user
  loginTokenUser(userToken) {
    localStorage.clear();
    sessionStorage.clear();

    // Grabs token from route to login as the user
    this.Auth.loginTokenUser(userToken).subscribe((data) => {

      localStorage.setItem('token', data.response.token);
      const token = data.response.token;
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      const expirationDate = helper.getTokenExpirationDate(token);
      const isExpired = helper.isTokenExpired(token);
      localStorage.setItem('verified', 'true');
      this.Auth.decodedToken = decodedToken;

      // console.log('company id:', decodedToken.company_id);
      // console.log('Decoded token info is: ', decodedToken);
      // console.log('Token expiration date is: ', expirationDate);
      // console.log('Token expiration date is: ', decodedToken.accesslevel);

      if (!isExpired) {
        // redirect to Admin homepage
        // console.log('not expired')
        this.router.navigate(['/']);
        // console.log('company id:', decodedToken.company_id);
        // this.Auth.setLoggedIn(true);

      } else {
        // console.log('You need to be logged in to access the page');
        this.router.navigate(['/login']);
      }
    });
  }
}
