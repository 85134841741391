import { Component, OnInit, Input, ViewChild, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-video-site-devices-list-table',
  templateUrl: './video-site-devices-list-table.component.html',
  styleUrls: ['./video-site-devices-list-table.component.scss'],
})
export class VideoSiteDevicesListTableComponent implements OnInit {
  constructor(private router: Router, public Auth: AuthService) { }

  @Input() videoDevicesListData: MatTableDataSource<any>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] =
    this.Auth.accessLevel == 'SUPERADMIN'
      ? [
        'company',
        'name',
        'health',
        'qos',
        'site',
        'livembps',
        'oncall',
        'calender',
        'model',
        'ip',
      ]
      : ['name', 'health', 'qos', 'site', 'livembps', 'oncall', 'calender', 'model', 'ip'];
  Math: any = Math;

  ngOnInit(): void { }

  applyFilter(filterValue: string) {
    // console.log(filterValue);
    if (filterValue == '') this.videoDevicesListData.filter = '-'.trim().toLowerCase();
    else this.videoDevicesListData.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {
    this.videoDevicesListData.filter = '-'.trim().toLocaleLowerCase();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.videoDevicesListData.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'company': {
          return item.company_name.toLocaleLowerCase();
        }
        case 'name': {
          return item.room_name.toLocaleLowerCase();
        }
        case 'health': {
          return item.health.toLocaleLowerCase();
        }
        case 'qos': {
          return item.qos.toLocaleLowerCase();
        }
        case 'site': {
          return item.site_name.toLocaleLowerCase();
        }
        case 'calender': {
          return item.scheduled_meetings.toLocaleLowerCase();
        }
        case 'livembps': {
          return item.total_mbps.toLocaleLowerCase();
        }
        case 'oncall': {
          return item.oncall.toLocaleLowerCase();
        }
        case 'model': {
          return item.model.toLocaleLowerCase();
        }
        case 'ip': {
          return item.ipv4.toLocaleLowerCase();
        }
        default:
          return item[property].toLocaleLowerCase();
      }
    };
  }

  clickRow(row) {
    this.router.navigate(['video/site/', row.site_id, 'videodevice', row.video_device_id]);
  }
}
