import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-video-notes',
  templateUrl: './video-notes.component.html',
  styleUrls: ['./video-notes.component.scss']
})
export class VideoNotesComponent implements OnInit, OnChanges {

  @Input() videoDeviceData: any;
  @Input() hasAccess: any;
  
	notesInfo: any = [];
  
  constructor(
    private Auth: AuthService,
  ) { }

  ngOnInit(): void {
  }
  
  	ngOnChanges(changes: SimpleChanges) {
		if (changes.videoDeviceData && this.videoDeviceData) {
			// console.log('videoDeviceData last call: ', this.videoDeviceData)
			this.setData()
		}
	}
	
	setData = () => {
	
	}
	
	  
  saveNotes = () => {
    const notesDtls = {
      company_id: this.videoDeviceData.company_id,
      device_id: this.videoDeviceData.video_device_id,
      notes: (<HTMLInputElement>document.getElementById('exampleFormControlTextarea1')).value,
    };

    this.Auth.updateNotes(notesDtls).subscribe((data) => {
      this.notesInfo = data.response;
    });
  }



}
