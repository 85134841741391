import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-iot-summary-stats',
  templateUrl: './iot-summary-stats.component.html',
  styleUrls: ['./iot-summary-stats.component.scss'],
})
export class IotSummaryStatsComponent implements OnInit {
  @Output() summaryEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() agentSummaryList;
  @Input() showIotLiteSite;
  @Input() isSpacesEnabled:boolean = false;


  constructor() {}



  ngOnInit() {}
}
