import * as Chart from 'chart.js'
import * as moment from 'moment-timezone'
import { Subscription } from 'rxjs'

import { Component, Input, OnInit } from '@angular/core'

import { GetIotService } from '../../../services/get-iot.service'
import { RefreshApiService } from '../../../services/refresh-api.service'

@Component({
  selector: 'app-chart-historic-ping-range',
  templateUrl: './chart-historic-ping-range.component.html',
  styleUrls: ['./chart-historic-ping-range.component.scss'],
})
export class ChartHistoricPingRangeComponent implements OnInit {
  @Input() device_id: number;
  @Input() agent_id: number;
  @Input() account_url: String;
  @Input() api_key: String;
  @Input() paused: number;
  filterListner: Subscription;
  canvas: any;
  ctx: any;
  historicPingRangeChart: any;
  chartData = [];

  constructor(private getIot: GetIotService, private refreshApiService: RefreshApiService) {
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if (m) {
        // not get data if pause is 1
        if (this.paused === 0) {
          this.getChartData();
        }
      }
    });
  }

  ngOnInit() {
    this.initializeChart();
    this.getChartData();
  }

  initializeChart = () => {
    this.canvas = document.getElementById('historic-ping-range');
    this.ctx = this.canvas.getContext('2d');

    const chartOptions = {
      responsive: false,
      display: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0,
        },
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, chart) {
            const d = new Date(tooltipItem[0].label);
            return d.toLocaleTimeString();
          },
          beforeLabel: function (tooltipItem, chart) {
            switch (tooltipItem.datasetIndex) {
              case 0:
                return 'Median';
              case 1:
                return 'Best';
              case 2:
                return 'Worst';
            }
          },
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
            ticks: {
              callback: function (value, index, values) {
                const d = new Date(value);
                return d
                  .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                  .replace(/\s+/g, '');
              },
            },
          },
        ],
      },
    };

    const chartData = {
      datasets: [
        {
          label: 'Median',
          data: [],
          backgroundColor: ['rgb(0, 128, 255, .5'],
          pointBackgroundColor: 'rgb(184, 58, 36, 0)',
          pointBorderColor: 'rgb(184, 58, 36, 0)',
          borderWidth: 0,
          stepped: true,
        },
        {
          label: 'Best',
          data: [],
          backgroundColor: ['rgb(3, 173, 20, .5'],
          pointBackgroundColor: 'rgb(184, 58, 36, 0)',
          pointBorderColor: 'rgb(184, 58, 36, 0)',
          borderWidth: 0,
        },
        {
          label: 'Worst ',
          data: [],
          backgroundColor: ['rgb(188, 71, 3, .5'],
          pointBackgroundColor: 'rgb(184, 58, 36, 0)',
          pointBorderColor: 'rgb(184, 58, 36, 0)',
          borderWidth: 0,
        },
      ],
    };

    const chartConfig = {
      type: 'line',
      data: chartData,
      options: chartOptions,
    };

    this.historicPingRangeChart = new Chart(this.ctx, chartConfig);
  };

  getChartData = () => {
  
    const dateNow = moment().utcOffset(0, false).format();
    const dateTo = moment(dateNow).utcOffset(0, false).format().split('Z')[0];
    const dateFrom = moment(dateNow)
      .subtract(5, 'minutes')
      .utcOffset(0, false)
      .format()
      .split('Z')[0];

    if (!this.device_id && !this.agent_id) return;
  

    this.getIot
      .getDeviceRTDHistory(
        this.device_id,
        this.agent_id,
        this.account_url,
        this.api_key,
        dateFrom,
        dateTo
      )
      .subscribe((data:any) => {
        
        this.setChartData(data);
      });
  };

  setChartData = (rtdList) => {
  
    const medianList = [];
    const bestList = [];
    const worstList = [];
    const timeLabels = [];
    for (let i = 0; i < rtdList.length; i++) {
      const { min, max, median, timestamp } = rtdList[i];
      medianList.push(parseFloat(median));
      bestList.push(parseFloat(min));
      worstList.push(parseFloat(max));
      timeLabels.push(timestamp);
    }

    this.historicPingRangeChart.reset();
    this.historicPingRangeChart.data.labels = timeLabels;
    this.historicPingRangeChart.data.datasets[0].data = medianList;
    this.historicPingRangeChart.data.datasets[1].data = bestList;
    this.historicPingRangeChart.data.datasets[2].data = worstList;
    this.historicPingRangeChart.update({ duration: 1000 });
  };

  ngOnDestroy() {
    if (this.historicPingRangeChart) {
      this.historicPingRangeChart.destroy();
    }
    this.filterListner.unsubscribe();
  }
}
