import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { PopupTypeService } from '../../../services/popupType.service';
import { HandleSpeakerService } from '../../../services/handle-speaker.service';

@Component({
  selector: 'app-btn-volume',
  templateUrl: './btn-volume.component.html',
  styleUrls: ['./btn-volume.component.scss'],
})
export class BtnVolumeComponent implements OnChanges, OnInit, OnDestroy {
  @Input() connectedAudioSpeaker: any;
  @Input() pluginId: number;
  @Input() failOver: boolean;
  @Input() defaultDevice: string;
  @ViewChild('sliderInput', { static: true }) sliderInput: ElementRef;
  @Input() pluginStatus: any;
  @Input() cloudRoomStats: any;

  data: any;
  icon: String = '../../assets/img/cloud/icon_volume.png';
  sliderValue: number;
  sliderOriginalValue: number;
  testFunction: Function;
  defaultName: string;
  masterVolume: number = -1;

  iconTriangleGreen: String = '../../assets/img/video/triangle_green.png';
  iconTriangleRed: String = '../../assets/img/video/triangle_red.png';
  iconTriangleGrey: String = '../../assets/img/video/triangle_grey.png';
  iconTriangleOrange: String = '../../assets/img/video/traingle_orange.png';

  speakerBackup: any;

  inuseSpeaker = {
    status: -1,
    name: "",
  };

  constructor(public ds: PopupTypeService, public speakerVolumeService: HandleSpeakerService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.connectedAudioSpeaker && this.connectedAudioSpeaker) {
        this.setAudio();
    }
    if (changes.cloudRoomStats && this.cloudRoomStats) {
      if (this.cloudRoomStats.plugin_host_info) {
        this.masterVolume = this.cloudRoomStats.plugin_host_info.master_volume;
      }
    }
  }

  setAudio = () => {
    const copiedArray = [...this.connectedAudioSpeaker];
    this.defaultName = this.checkDefaultIfEmpty(this.defaultDevice);
    if (copiedArray.length !== 0) {
      this.inuseSpeaker = this.getInuseSpeaker(copiedArray);
      if (this.inuseSpeaker.status === 1) {
        this.speakerBackup = copiedArray.filter((item) => item.backup !== 0);
        this.data = {
          connectedAudioSpeaker: copiedArray,
          pluginId: this.pluginId,
          failOver: this.failOver,
        };
      } else {
        this.inuseSpeaker = {
          status: -1,
          name: this.defaultName,
        };
      }

    } else {
      this.inuseSpeaker = {
        status: -1,
        name: this.defaultName,
      };
    }
  }

  getInuseSpeaker = (speaker) => {
    const inuseSpeaker = speaker.filter((s) => s.inuse);
    if (!this.isEmpty(inuseSpeaker)) {
      return inuseSpeaker[0];
    }
    return {
      status: -1,
      name: this.defaultDevice,
    };
  };

  checkDefaultIfEmpty = (name) => {
    if (name === null || typeof name === 'undefined' || name.trim() === '') {
      return 'No Data';
    }
    return name;
  };

  ngOnInit() {
    this.sliderValue = 0;
    this.sliderOriginalValue = 10;
    this.initSliderValue(this.sliderValue);
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }

  getPrimaryAudioSpeaker = (speaker) => {
    if (speaker) {
      let primarySpeaker = speaker.filter((item) => item.primary);
      if (primarySpeaker.length === 0) {
        primarySpeaker = speaker.filter((item) => item.inuse);
        if (primarySpeaker.length === 0) {
          primarySpeaker = [
            {
              status: -1,
              name: this.defaultDevice,
            },
          ];
        }
      }
      return primarySpeaker[0];
    }
    return {
      status: -1,
      name: this.defaultDevice,
    };
  };

  handleSliderValue = (event) => {
    this.setSliderValue(event);
  };

  setSliderValue = (val) => {
    this.sliderInput.nativeElement.value = val;
    this.sliderValue = val;
    const data = {
      sliderValue: this.sliderValue,
      pluginId: this.pluginId,
      failOver: this.failOver,
    };

    this.ds.sendPopupType(51, { data });
  };

  sliderDecrement = () => {
    if (this.sliderValue > 0) {
      this.sliderInput.nativeElement.stepDown(this.sliderOriginalValue);
      this.sliderValue -= this.sliderOriginalValue;
    }
    const data = {
      sliderValue: this.sliderValue,
      pluginId: this.pluginId,
      failOver: this.failOver,
    };

    this.ds.sendPopupType(51, { data });
  };

  sliderIncrement = () => {
    if (this.sliderValue < 100) {
      this.sliderInput.nativeElement.stepUp(this.sliderOriginalValue);
      this.sliderValue += this.sliderOriginalValue;
    }
    this.ds.sendPopupType(51, { data: this.sliderValue });

    const data = {
      sliderValue: this.sliderValue,
      pluginId: this.pluginId,
      failOver: this.failOver,
    };

    this.ds.sendPopupType(51, { data });
  };



  initSliderValue = (val) => {
    this.sliderInput.nativeElement.value = val;
    this.sliderValue = val;
  };

  iconOnHover() {
    this.icon = '../../assets/img/cloud/icon_volume_blue.png';
  }

  iconOnLeave() {
    this.icon = '../../assets/img/cloud/icon_volume.png';
  }

  getStatusIcon(primary) {
    const { status, name } = primary;
    if (status === 1 || name !== 'No Data') {
      return this.iconTriangleGreen;
    } else {
      return this.iconTriangleRed;
    }
  }

  isEmpty = (arr) => {
    return arr.length === 0;
  };
}
