<div class="mos60MinMosChartContainer">
    <div class="row">
        <div class="col-6 text-center">
            60 Minutes View
        </div>
        <div class="col-6 text-center">
            Calls: {{ totaCalls }}
        </div>  
    </div>
    <br>
    <canvas id="mos60MinChart" width="300px" height="210px"></canvas>  
</div>
