import { Subscription } from 'rxjs'
import { TableFilterService } from 'src/app/services/table-filter.service'

import { HttpClient } from '@angular/common/http'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'

import { environment } from '../../../../environments/environment'
import { GetVideoService } from '../../../services/get-video.service'
import { RefreshApiService } from '../../../services/refresh-api.service'

@Component({
  selector: 'app-video-allsites-panel',
  templateUrl: './video-allsites-panel.component.html',
  styleUrls: ['./video-allsites-panel.component.scss'],
})
export class VideoAllsitesPanelComponent implements OnInit, OnDestroy {
  //server global variable;
  loaderPath = environment.config.dynamicImage.loader;
  server_url: string = environment.serverUrl;
  PopupTitle: string = 'Video Sites';
  public videoSitesSummary;
  public videositesStats;
  public videositesInfo;
  id: number;
  videoApiData: any = [];
  videoDeviceApiSummary: any = [];
  apiTimers: any;
  refreshData: any;
  filterListner: Subscription;
  videoSitesListData: MatTableDataSource<any>;
  baseFilter: any = 'all';
  loading: any = true;

  constructor(
    public http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private getVideoDataService: GetVideoService,
    private refreshApiService: RefreshApiService,
    private tableFilterService: TableFilterService
  ) {
    this.videoSitesListData = new MatTableDataSource();

    // This recieves the update data boolean to start the timer
    this.filterListner = this.refreshApiService
      .refreshAPIListen()
      .subscribe((m: any) => {
        this.refreshData = m;
        this.getApiData();
      });

    if (this.router.url == '/video/impactedsites') {
      this.baseFilter = 'impacted';
    } else if (this.router.url == '/video/allsites') {
      this.baseFilter = 'all';
    }

    this.filterListner = this.tableFilterService
      .listen()
      .subscribe((m: any) => {
        if (m == '') {
          if (this.router.url == '/video/impactedsites') {
            this.baseFilter = 'impacted';
          } else if (this.router.url == '/video/allsites') {
            this.baseFilter = 'all';
          }
        } else {
          this.baseFilter = m;
        }
        this.videoSitesListData.filter = '-'.trim().toLocaleLowerCase();
      });

    this.videoSitesListData.filterPredicate = (data: any, filter: string) => {
      var dataStr = data.site_name + data.company_name + data.health;

      if (this.baseFilter == 'impacted') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.impacted;
        } else {
          return (
            data.impacted &&
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'warning') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health > 31 && data.health < 76;
        } else {
          return (
            data.health > 31 &&
            data.health < 76 &&
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'healthy') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health > 76;
        } else {
          return (
            data.health > 76 &&
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'all') {
        if (filter == '-' || filter == '' || filter == null) {
          return true;
        } else {
          return (
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'oncall') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.oncall == 1;
        } else {
          return (
            data.oncall == 1 &&
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'impactedOnly') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health < 76;
        } else {
          return (
            data.health < 76 &&
            dataStr
              .trim()
              .toLocaleLowerCase()
              .indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      }
    };
  }

  getApiData() {
    if (this.refreshData) {
      this.getVideoApiData();
    }
  }

  accessLevel: any;
  ngOnInit() {
    this.getVideoApiData();
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  getVideoApiData() {
    this.getVideoDataService.apiGetVideoCompanyInfo().subscribe((data) => {
      this.videositesInfo = data['response'];
    });

    this.getVideoDataService.apiGetAllVideoSites().subscribe((data) => {
      this.videoApiData = data['response'];

      // console.log('apiGetAllVideoSites: ', this.videoApiData)

      let arr = [];
      for (let d in this.videoApiData) {
        var siteName = this.videoApiData[d]['site_name'].toString();

        arr.push(this.videoApiData[d]);
      }
      this.videositesStats = data;

      this.videoSitesListData.data = this.videoApiData;

      this.loading = false;
    });

    this.getVideoDataService.apiGetVideoSitesSummary().subscribe((data) => {
      this.videoSitesSummary = data['response'][0];
    });
  }

  getStats() {
    this.getVideoDataService.fetchVideoSummary().subscribe((data) => {
      this.videoSitesSummary = data[0];
    });

    this.getVideoDataService.fetchVideoSites().subscribe((data) => {
      let arr = [];
      for (let d in data) {
        arr.push(data[d]);
      }
      this.videositesStats = data;
    });
  }
}
