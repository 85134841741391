import * as moment from 'moment-timezone'
import { timestamp } from 'rxjs/operators'

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-iot-device-stats',
  templateUrl: './iot-device-stats.component.html',
  styleUrls: ['./iot-device-stats.component.scss'],
})
export class IotDeviceStatsComponent implements OnInit, OnChanges {
  @Output() eventEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() iotDeviceDetails: any;
  pingRtd: any = '--';
  packetLoss: any = '--';

  worstRtd: any = '--';
  medianRtd: any = '--';
  bestRtd: any = '--';
  packetLossRtd: any = '--';

  latestEventsMins: any = '--';
  latestEvents24h: any = '--';

  deviceID: any;
  notes: any;

  deviceHisoryParams: any;

  medianAlert: Number = 10;
  medianWarning: Number = 9;

  packetLossAlert: Number = 15;
  packetLossWarning: Number = 10;
  constructor() {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.iotDeviceDetails) {
      if (this.iotDeviceDetails) {
        this.setData();
        if (this.iotDeviceDetails.paused === 0) {
          this.setDataWhenUnpause();
        } else {
          this.resetProps();
        }
      }
    }
  }

  resetProps = () => {
    this.pingRtd = '--';
    this.packetLoss = '--';
    this.worstRtd = '--';
    this.medianRtd = '--';
    this.bestRtd = '--';
    this.packetLossRtd = '--';
    this.latestEventsMins = '--';
    this.latestEvents24h = '--';
  };

  setData = () => {
    const { iot_device_id, notes, api_key, account_url, agent_id, paused } = this.iotDeviceDetails;
    this.deviceID = iot_device_id;
    this.notes = notes;

    this.deviceHisoryParams = {
      iot_device_id,
      api_key,
      account_url,
      agent_id,
      paused,
    };
    let {triggers} = this.iotDeviceDetails;
    if (typeof triggers === 'string' && triggers) {
      triggers = JSON.parse(triggers);
      this.iotDeviceDetails.triggers = triggers;
    }

  };

  setDataWhenUnpause = () => {
    const { live_status, device_history, live_stats, triggers } = this.iotDeviceDetails;
    if (live_status !== null) {
      const {
        latest_median,
        latest_packet_loss,
        avg_lost_packet_count,
        avg_max,
        avg_median,
        avg_min,
      } = live_status;
      this.pingRtd = latest_median;
      this.packetLoss = latest_packet_loss;
      this.worstRtd = avg_max;
      this.medianRtd = avg_median;
      this.bestRtd = avg_min;
      this.packetLossRtd = avg_lost_packet_count;
    }

    if (device_history !== null) {
      const { last24HrsEventCount, lastEventOccuredTime } = device_history;

      this.latestEvents24h = last24HrsEventCount;
      if (lastEventOccuredTime !== null) {
        this.latestEventsMins = this.calculateLatestEvent(lastEventOccuredTime);
      }
    }
    
    if (live_stats == null) return;
    
    const parseLiveStats =  JSON.parse(live_stats) ;
    
    if (this.checkForTriggerProp(parseLiveStats)) {
      const {
        triggers: {
          median: { alert, warning },
          packetloss: { alert: alert2, warning: warning2 },
        },
      } = parseLiveStats;
      this.setTriggeralue(alert, warning, alert2, warning2);
    } else {
      if(!triggers) return;
      const {
        median: { alert, warning },
        packetloss: { alert: alert2, warning: warning2 },
      } = triggers;

      this.setTriggeralue(alert, warning, alert2, warning2);
    }
  };

  checkForTriggerProp = (params = {}) => {
    if ('triggers' in params) {
      return true;
    }
    return false;
  };

  setTriggeralue = (alert, warning, alert2, warning2) => {
    this.medianAlert = alert;
    this.medianWarning = warning;

    this.packetLossAlert = alert2;
    this.packetLossWarning = warning2;
  };

  calculateLatestEvent = (time) => {
    const dayDiff = moment().diff(time, 'days');
    const hourDiff = moment().diff(time, 'hours');
    const minuteDiff = moment().diff(time, 'minutes');
    const secondDiff = moment().diff(time, 'seconds');
    if (dayDiff !== 0) {
      return {
        timestamp: dayDiff,
        message: dayDiff === 1 ? 'day ago' : 'days ago',
      };
    }

    if (hourDiff !== 0) {
      return {
        timestamp: hourDiff,
        message: hourDiff === 1 ? 'hour ago' : 'hours ago',
      };
    }

    if (minuteDiff !== 0) {
      return {
        timestamp: minuteDiff,
        message: minuteDiff === 1 ? 'minute ago' : 'minutes ago',
      };
    }

    return {
      timestamp: secondDiff,
      message: secondDiff === 1 ? 'second ago' : 'seconds ago',
    };
  };
}
