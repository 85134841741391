import { Component, OnInit, SimpleChanges, Input } from '@angular/core';

@Component({
  selector: 'app-btn-handset',
  templateUrl: './btn-handset.component.html',
  styleUrls: ['./btn-handset.component.scss']
})
export class BtnHandsetComponent implements OnInit {
  ngOnInit(): void {
    // throw new Error("Method not implemented.");
  }
  
  @Input() count: number = 0;
  @Input() label: string = "Not-Active";

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.count) {
      this.count = changes.count.currentValue
    }
    if (changes.total) {
      this.label = changes.label.currentValue
    }
  }

}
