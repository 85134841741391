<div class="row">
    <div class="col-4 pt-3">
      <div class="row">
          <div class="col-12 text-left">
            <h3>Sites</h3>
          </div>
      </div>
      <div class="row">
        <div class="col-4">
          <h1 class="text-center report-bold">{{videoConferSite?.total_sites}}</h1>
        </div>
        <div class="col-1 px-0"></div>
        <div class="col-4">
          <h1 class="text-center report-bold">{{videoConferSite?.impacted_sites?.impacted}}</h1>
        </div>
        <div class="col-1 px-0">
            <div *ngIf="videoConferSite?.impacted_sites?.difference <= -100" class="report-oval float-right text-success">
                <h5 class="text-center report-oval-number">{{videoConferSite?.impacted_sites?.difference}}</h5>
             </div>
          <div *ngIf="videoConferSite?.impacted_sites?.difference < 0 && videoConferSite?.impacted_sites?.difference >-100" class="report-circle float-right text-success">
            <h5 class="text-center report-circle-number">{{videoConferSite?.impacted_sites?.difference}}</h5>
          </div>
          <div *ngIf="videoConferSite?.impacted_sites?.difference > 0 && videoConferSite?.impacted_sites?.difference < 100" class="report-circle float-right text-danger">
            <h5 class="text-center report-circle-number">+{{videoConferSite?.impacted_sites?.difference}}</h5>
          </div>
          <div *ngIf="videoConferSite?.impacted_sites?.difference > 100" class="report-oval float-right text-danger">
            <h5 class="text-center report-oval-number">+{{videoConferSite?.impacted_sites?.difference}}</h5>
          </div>
          <div *ngIf="videoConferSite?.impacted_sites?.difference == 0" class="report-circle float-right text-dark">
            <h5 class="text-center report-circle-number">--</h5>
          </div>
        </div>
      </div>
      <div class="row" style="border-bottom:3px solid silver;">
        <div class="col-6">
          <h4 class="text-center">Total sites</h4>
        </div>
        <div class="col-6">
          <h4 class="text-center">Impacted</h4>
        </div>
      </div>

      <div class="row">
          <div class="col-12 text-left">
            <h4>Conference Rooms</h4>
          </div>
      </div>
      <div class="row">
        <div class="col-4">
          <h1 class="text-center report-bold">{{videoConferSite?.total_rooms}}</h1>
        </div>
        <div class="col-1 px-0"></div>
        <div class="col-4">
          <h1 class="text-center report-bold">{{videoConferSite?.impacted_rooms.impacted}}</h1>
        </div>
        <div class="col-1 px-0">
            <div *ngIf="videoConferSite.impacted_rooms.difference <=-100" class="report-oval float-right text-success">
                <h5 class="text-center report-oval-number">{{videoConferSite.impacted_rooms.difference}}</h5>
              </div>
          <div *ngIf="videoConferSite.impacted_rooms.difference <0 && videoConferSite.impacted_rooms.difference >-100" class="report-circle float-right text-success">
            <h5 class="text-center report-circle-number">{{videoConferSite.impacted_rooms.difference}}</h5>
          </div>
          <div *ngIf="videoConferSite.impacted_rooms.difference>0 && videoConferSite.impacted_rooms.difference  < 100" class="report-circle float-right text-danger">
            <h5 class="text-center report-circle-number">+{{videoConferSite.impacted_rooms.difference}}</h5>
          </div>
          <div *ngIf="videoConferSite.impacted_rooms.difference>=100" class="report-oval float-right text-danger">
            <h5 class="text-center report-oval-number">+{{videoConferSite.impacted_rooms.difference}}</h5>
          </div>
          <div *ngIf="videoConferSite.impacted_rooms.difference==0" class="report-circle float-right text-dark">
            <h5 class="text-center report-circle-number">--</h5>
          </div>
        </div>
      </div>
      <div class="row" style="border-bottom:4px solid silver;">
        <div class="col-6">
          <h4 class="text-center">Total rooms</h4>
        </div>
        <div class="col-6">
          <h4 class="text-center">Impacted</h4>
        </div>
      </div>

      <div class="row">
          <div class="col-12 text-left">
            <h4>Huddle Rooms</h4>
          </div>
      </div>
      <div class="row">
        <div class="col-4">
          <h1 class="text-center report-bold">{{videoConferSite.total_huddle_rooms?videoConferSite?.total_huddle_rooms:0}}</h1>
        </div>
        <div class="col-1 px-0"></div>
        <div class="col-4">
          <h1  class="text-center report-bold">{{videoConferSite.impacted_huddle_rooms?videoConferSite?.impacted_huddle_rooms.impacted:0}}</h1>
        </div>
  
          <div class="col-1 px-0">
            <div *ngIf="videoConferSite.impacted_huddle_rooms && videoConferSite.impacted_huddle_rooms.difference <=-100" class="report-oval float-right text-success">
                <h5 class="text-center report-oval-number">{{videoConferSite?.impacted_huddle_rooms.difference}}</h5>
              </div>
          <div *ngIf="videoConferSite.impacted_huddle_rooms && videoConferSite.impacted_huddle_rooms.difference <0 && videoConferSite.impacted_rooms.difference >-100" class="report-circle float-right text-success">
            <h5 class="text-center report-circle-number">{{videoConferSite?.impacted_huddle_rooms.difference}}</h5>
          </div>
          <div *ngIf="videoConferSite.impacted_huddle_rooms && videoConferSite.impacted_huddle_rooms.difference>0 && videoConferSite.impacted_rooms.difference  < 100" class="report-circle float-right text-danger">
            <h5 class="text-center report-circle-number">+{{videoConferSite?.impacted_huddle_rooms.difference}}</h5>
          </div>
          <div *ngIf="videoConferSite.impacted_huddle_rooms && videoConferSite.impacted_huddle_rooms.difference>=100" class="report-oval float-right text-danger">
            <h5 class="text-center report-oval-number">+{{videoConferSite?.impacted_huddle_rooms.difference}}</h5>
          </div>
          <div *ngIf="!videoConferSite.impacted_huddle_rooms || (videoConferSite.impacted_huddle_rooms&&  videoConferSite.impacted_huddle_rooms.difference==0)" class="report-circle float-right text-dark">
            <h5 class="text-center report-circle-number">--</h5>
          </div>
          
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <h4 class="text-center">Total rooms</h4>
        </div>
        <div class="col-6">
          <h4 class="text-center">Impacted</h4>
        </div>
      </div>
    </div>
    <div class="col-8 text-center pt-3" style="border-left:3px solid silver;" >
      <h4>Total Network Bandwidth In/Out Usage (mbps)</h4>
      <div class="row">
          <div class="col-6 text-center">
            <h2>{{videoConferSite.bandwidth_usage.in}}</h2>
            <h2>Average In</h2>
          </div>
          <div class="col-6 text-center">
            <h2>{{videoConferSite.bandwidth_usage.out}}</h2>
            <h2>Average Out</h2>
          </div>
      </div>
      <div class="row" style="padding-top: 49px;">
        <div class="col-12 text-center">
          <h2>{{videoConferSite.bandwidth_usage.total}}</h2>
        </div>
        <div class="col-12 text-center">
          <h2>Total</h2>
        </div>
      </div>
      <div class="row" style="border-top: 4px solid silver; margin-top: 21px;">
        <div class="col-12">
          <div class="row">
            <div class="col-12 text-left">
              <h4>Cloud Users</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <h1  class="text-center report-bold">{{videoConferSite?.cloud_users?videoConferSite?.cloud_users.users:0}}</h1>
            </div>
            <!-- <div class="col-3"> -->
              <!-- Cloud Users trend circle (removed) -->
              <!-- <div *ngIf="restore_time_trend<0" class="report-circle float-right text-success">
                <h5 class="text-center report-circle-number">{{restore_time_trend}}</h5>
              </div>
              <div *ngIf="restore_time_trend>0" class="report-circle float-right text-danger">
                <h5 class="text-center report-circle-number">+{{restore_time_trend}}</h5>
              </div>
              <div *ngIf="restore_time_trend==0" class="report-circle float-right text-dark">
                <h5 class="text-center report-circle-number">--</h5>
              </div> -->
            <!-- </div> -->
            <div class="col-3">
              <h1 class="text-center report-bold">0</h1>
            </div>
            <!-- <div class="col-3"> -->
              <!-- VIP Users trend circle (removed) -->
              <!-- <div *ngIf="restore_time_trend<0" class="report-circle float-right text-success">
                <h5 class="text-center report-circle-number">{{restore_time_trend}}</h5>
              </div>
              <div *ngIf="restore_time_trend>0" class="report-circle float-right text-danger">
                <h5 class="text-center report-circle-number">+{{restore_time_trend}}</h5>
              </div>
              <div *ngIf="restore_time_trend==0" class="report-circle float-right text-dark">
                <h5 class="text-center report-circle-number">--</h5>
              </div> -->
            <!-- </div> -->
            <div class="col-3">
              <h1 class="text-center report-bold">0</h1>
            </div>
            <div class="col-1">
                <div *ngIf="restore_time_trend<=-100" class="report-oval float-right text-success">
                    <h5 class="text-center report-oval-number">{{restore_time_trend}}</h5>
                  </div>
              <div *ngIf="restore_time_trend<0 && restore_time_trend > -100" class="report-circle float-right text-success">
                <h5 class="text-center report-circle-number">{{restore_time_trend}}</h5>
              </div>
              <div *ngIf="restore_time_trend>0 && restore_time_trend <100" class="report-circle float-right text-danger">
                <h5 class="text-center report-circle-number">+{{restore_time_trend}}</h5>
              </div>
              <div *ngIf="restore_time_trend  >= 100" class="report-oval float-right text-danger">
                  <h5 class="text-center report-oval-number">+{{restore_time_trend}}</h5>
                </div>
              <div *ngIf="restore_time_trend==0" class="report-circle float-right text-dark">
                <h5 class="text-center report-circle-number">--</h5>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <h4 class="text-center">Total users</h4>
            </div>
            <div class="col-3">
              <h4 class="text-center">VIP users</h4>
            </div>
            <div class="col-3">
              <h4 class="text-center">VIP's Impacted</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>