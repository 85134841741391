import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'displayEmpty',
})
export class DisplayEmptyPipe implements PipeTransform {
  transform(value: any): any {
    // // console.log(value);
    if (value === null || typeof value === 'undefined' || value === '' || value === 'null' || value === 'NaN') {
      return '--';
    }
    return value;
  }
}
