import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentFileSize',
})
export class DocumentFileSizePipe implements PipeTransform {
  transform(value: any): any {
    if (value === null || typeof value === 'undefined' || value === '' || value === 0) {
      return '0 KB';
    }

        // Function to format file size without an external library
    const formatFileSize = (sizeInBytes) => {
      if (sizeInBytes >= 1000000000) { // 1 GB
        const size = (sizeInBytes / 1_000_000_000);  
        return (parseInt(size * 100 +'' )) / 100 + ' GB';
      } else if (sizeInBytes >= 1000000) { // 1 MB
        const size = (sizeInBytes / 1000000);
        return (parseInt(size * 100 +'' )) / 100 + ' MB';
      } else if (sizeInBytes >= 1000) { // 1 KB
        const size = (sizeInBytes / 1000);
        return (parseInt(size * 100 +'' )) / 100 + ' KB';
      } else {
        return sizeInBytes + ' bytes';
      }
    };

    return formatFileSize(value);
  }
}
