import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GetDocumentCenterService } from '../../services/get-document-center.service';
import { DocumentCenterDialogComponent } from '../dialogs/document-center-dialog/document-center-dialog.component';
import { environment } from '../../../environments/environment';
import * as moment from 'moment-timezone';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentFileSizePipe } from 'src/app/pipes/document-file-size.pipe'
import { DateTimeZonePipe } from 'src/app/pipes/display-date.pipe'
import { AuthService } from 'src/app/services/auth.service'
import { finalize } from 'rxjs/operators'

export interface DocumentListInterface {
  document_id: number;
  device_id: number;
  quadrant_group: any;
  file_type: any;
  file_name: any;
  file_size: any;
  description: any;
  file_path: any;
  date_created: any;
  share_expiration: any;
  file_owner: any;
  is_shared: any;
}
export interface RootList{
  file_name: any;
  document_id:any
}
@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnChanges {
  @Input() propData;
  documentList: any = [];
  totalDocumentSize: any;
  shouldShowLoader: boolean = false;
  displayMode: boolean = false;
  storageLimit: number;
  dataSource: MatTableDataSource<DocumentListInterface>;
  foldersPath: RootList[] = [];
  searchDocumentList: any[] = [];
  totalFolders: number = 0;
  docPath: string = environment.serverUrl + '/api/v1/files/get-file/';
  displayedColumns: any[] = [
    'file_type',
    'file_name',
    'file_size',
    'date_created',
    'file_owner',
    'document_preview',
  ];
  quadrantName: string = '';
  fileCount: number = 0;
  availableStorage: number = 0;
  root_id: number = 0;
  loader: String = environment.config.dynamicImage.loader;
  isFiles: boolean = true;
  @ViewChild('searchDataInput') searchDataInput: ElementRef;

  constructor(private getDocumentCenter: GetDocumentCenterService, public dialog: MatDialog, private auth : AuthService) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.propData && this.propData) {
      this.setData();
    }
  }

  setData = () => {
    this.quadrantName = this.propData.quadrant_group;
    this.getDocumentsByDevice();
  };
  onChange(status) {
    this.isFiles = status;
    this.root_id = 0;
    this.foldersPath = [];
    this.getDocumentsByDevice();
  }
  // convertTimeStamp = (time) => (time === null ? '--' : moment(time).format('l, hh:mm a'));

  getDocumentsByDevice = () => {
    if (this.shouldShowLoader) return;
    this.documentList = [];
    this.shouldShowLoader = true;
    this.propData.root_id = this.root_id;
    this.propData.isFiles = this.isFiles;
    this.getDocumentCenter.getDocumentsByDevice(this.propData)
      .pipe(finalize(() => {
        this.shouldShowLoader = false;
      }))
      .subscribe((data: any) => {
      const { document_list, total_document_size, document_center_storage_limit } = data;
      // const totalFileSize = data.document_list.reduce((total, document) => total + document.file_size, 0);
      this.countFolders(document_list);
      this.totalDocumentSize = total_document_size;  // function conversion
      this.documentList = document_list.map((d) => {
        return {
          ...d,
          is_shared: d.shared_by_user_id !== null ? 'Yes' : 'No',
          file_owner: d.last_name + ' ' + d.first_name,
        };
      });
      this.storageLimit = document_center_storage_limit * 1000000000 ;
      this.searchDocumentList = [...this.documentList];
      this.dataSource = new MatTableDataSource(this.documentList);
      this.dataSource.filterPredicate = (data: DocumentListInterface, filter: string) : boolean => {
        if (data?.file_name?.toLowerCase()?.includes(filter) || data?.file_owner?.toLowerCase()?.includes(filter))
					return true

        const transformedSize = DocumentFileSizePipe.prototype.transform(data?.file_size)
        if (transformedSize?.toLowerCase()?.includes(filter))
          return true

        const dateTimeZonePipe = new DateTimeZonePipe(this.auth)
        const transformedDate = dateTimeZonePipe.transform(data?.date_created, 'MM-DD-YYYY hh:mma')
        if (transformedDate?.toLowerCase()?.includes(filter))
          return true
        
        return false
      }
      this.availableStorage = this.storageLimit - this.totalDocumentSize;
      this.fileCount = this.documentList.reduce((pV, cV) => cV.file_type != 'folder' ? pV + 1 : pV + 0,0);;
      this.shouldShowLoader = false;
    });
  };

  objectEmitter = (params: any) => {
    if (params === 'getDocumentsByDevice') {
      this.getDocumentsByDevice();
    }
  };

  openDocumentDialog = (items, type) => {
    // console.log('openDocumentDialog');
    if(type == 'upload') items.root_path = this.foldersPath.map(x => x.file_name).join('|');
    const config = {
      documentData: items,
      type,
      objectEmitter: (params) => {
        this.objectEmitter(params);
      },
    };
    const webportal = this.dialog.open(DocumentCenterDialogComponent, {
      panelClass: 'custom-dialog-container-no-border-radius',
      data: {
        config,
      },
    });
  };

  searchData = (key) => {
    if (this.displayMode) {
      if (!key.trim()) {
        this.dataSource.data = [...this.searchDocumentList];
      }
      this.dataSource.data = this.searchDocumentList.filter((x) => {
        return Object.values(x).some((val) => {
          return (typeof val == 'string') ? val.trim().toLocaleLowerCase().includes(key.trim().toLowerCase()): val === key;
        });
      })
    }
    else {
      this.dataSource.filter = key.trim().toLowerCase(); 
    }
  };

  download = (document_id, file_path) => {
    this.shouldShowLoader = true;
    this.getDocumentCenter.downloadDocument(document_id).subscribe(
      (data: any) => {
        const fPath = file_path.split('.');
        const tempFileType = data.file_type.split('/');
        const fileType = tempFileType[tempFileType.length - 1].toLowerCase();
        const linkSource = `data:application/${fileType};base64,${data.base64}`;
        const downloadLink = document.createElement('a');
        const fileName = `${data.file_name}.${fPath[fPath.length - 1]}`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.shouldShowLoader = false;
      },
      (error) => {
        this.shouldShowLoader = false;
      }
    );
  };

  onCancelSearch = () => {
    this.dataSource.filter = null;
    this.searchDataInput.nativeElement.value = null;
    if (this.displayMode) this.dataSource.data = [...this.searchDocumentList];
  }
  openFolder(row) {
    this.root_id = row.document_id;
    this.addingPath(row.document_id, row.file_name);
    this.getDocumentsByDevice();
  }
  addingPath(document_id,file_name) {
    const data = this.foldersPath.find((x) => x.document_id == document_id && x.file_name == file_name);
    if (!data) this.foldersPath.push({
      document_id: document_id,
      file_name:file_name
    })
  }
  removePath() {
    this.foldersPath.pop();
    this.root_id = this.foldersPath.length > 0 ? this.foldersPath[this.foldersPath.length - 1].document_id : 0;
    this.getDocumentsByDevice();
  }
  onDisplayModeChange(mode: boolean): void {
    this.displayMode = mode;
  }
  countFolders(data:DocumentListInterface[]) {
    if (data && data.length && !this.isFiles) {
      this.totalFolders = data.reduce((pV, cV) => {
        return cV.file_type == 'folder' ? pV + 1 : pV + 0
      }, 0)
    }
    else this.totalFolders = 0;
  }
}
