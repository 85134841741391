import { Component, EventEmitter, Input, OnInit } from '@angular/core';

import { GetIotService } from '../../services/get-iot.service';

@Component({
  selector: 'app-iot-lite-device-notes',
  templateUrl: './iot-lite-device-notes.component.html',
  styleUrls: ['./iot-lite-device-notes.component.scss'],
})
export class IotLiteDeviceNotesComponent implements OnInit {
  @Input() eventEmitter: EventEmitter<any>;
  @Input() deviceID: any;
  @Input() notes: any;
  shouldShowMessage: Boolean = false;
  btnCtrClass: String = 'notes__btn_ctr';
  companyId: string = '';

  constructor(private getIot: GetIotService) {}

  ngOnInit() {
    this.companyId = this.getIot.getCompanyId();
  }

  saveNotes = () => {
    this.getIot
      .setIotDeviceNotes(this.companyId, this.deviceID, this.notes)
      .subscribe(
        (data) => {
          this.shouldShowMessage = true;
          this.btnCtrClass = 'notes__btn_ctr1';
          setTimeout(() => {
            this.shouldShowMessage = false;
            this.btnCtrClass = 'notes__btn_ctr';
          }, 2000);
        },
        (error) => {}
      );
    // this.eventEmitter.emit('callGetIotDeviceDetails');
  };
}
