<!-- <div *ngIf="dataError == true">
  <div style="height: 9cm;"></div>
  <div style="text-align: center; font-size: 225%; background-color: red;">
    Error - no device data
  </div>
  <div style="height: 9cm;"></div>
</div> -->
<!-- <div *ngIf="dataError == false"> -->
<div>
  <div class="row pt-2 pb-2 right-panel">
    <div class="col-12">
      <app-panel-tools [PopupTitle]="PopupTitle"></app-panel-tools>
      <div
        *ngIf="audioDeviceData?.online == 0 && (audioDeviceData?.collector_status == 1 || audioDeviceData?.collector_status == 2)"
        class="row pt-2 bg-danger" id="printablediv" style="
          padding: 10px;
          margin-bottom: 10px;
          color: white;
          background-color: #d24545 !important;
        ">
        Device is offline. The information below is from the last successful sync at {{ syncTime }}
      </div>
      <div *ngIf="audioDeviceData?.collector_status === -1" class="audio-device__banner-secondary">
        {{ dCollector }} is offline. The information below is from the last successful sync at
        {{ syncTime }}
      </div>
      <div *ngIf="audioDeviceData?.site_paused == 1" class="row pt-2 bg-secondary" style="
          padding: 10px;
          margin-bottom: 10px;
          color: white;
          background-color: #377fcb !important;
        ">
        The site is paused. The information below is from the last successful sync at {{ syncTime }}
      </div>
      <div *ngIf="audioDeviceData?.device_type == 'cisco' && audioDeviceData?.cisco_paused != '0'"
        class="row pt-2 bg-secondary" style="
          padding: 10px;
          margin-bottom: 10px;
          color: white;
          background-color: #377fcb !important;
        ">
        Cisco devices are paused. The information below is from the last successful sync at
        {{ syncTime }}
      </div>
      <div *ngIf="isPolycom(audioDeviceData?.device_type) && audioDeviceData?.polycom_paused != '0'"
        class="row pt-2 bg-secondary" style="
          padding: 10px;
          margin-bottom: 10px;
          color: white;
          background-color: #377fcb !important;
        ">
        Polycom devices are paused.The information below is from the last successful sync at
        {{ syncTime }}
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-6 pl-1">
          <app-audio-device-info [audioDeviceData]="audioDeviceData"
            (audioEvent)="onAudioEvent($event)"></app-audio-device-info>
        </div>
        <div class="col-6">
          <div class="col-12 px-0">
            <div class="health-container">
              <div (click)="openHealthHistory()" class="incident-button">
                Incidents
              </div>
              <app-system-health [isCursorPointer]="true"
                [healthNum]="audioDeviceData?.paused == 1 ? 0 : audioDeviceData?.health"
                (click)="openHealthHistory()"></app-system-health>
            </div>
          </div>
          <app-audio-device-stats [audioDeviceData]="audioDeviceData"></app-audio-device-stats>
        </div>
      </div>
    </div>
  </div>
</div>