<!-- <h2>{{title}}</h2> -->
<div class="popup-content">
  <div class="container-fluid" style="height: 500px; width: 100%;">
    <br />
    <h4 style="text-align: center;">{{ heading }}</h4>
    <div class="col-md-12" *ngIf="!callData?.length || callData?.length == 0">
      <p style="text-align: center;">No Calls</p>
    </div>
    <div class="col-md-12" *ngIf="callData?.length > 0">
      <table class="table table-sm bg-white mt-1 table-fixed" style="padding-bottom: 100px;">
        <thead>
          <tr>
            <th style="width: 10%;">
              Call ID
            </th>
            <th style="width: 15%;">
              Start Time
            </th>
            <th style="width: 15%;">
              End Time
            </th>
            <th style="width: 10%;">
              Duration
            </th>
            <th style="width: 5%;">
              Type
            </th>
            <th style="width: 25%;">
              Address
            </th>
            <th style="width: 15%;">
              Disconnect Type
            </th>
          </tr>
        </thead>
        <tbody>
          <tr scope="row" *ngFor="let call of callData">
            <td style="width: 10%;">
              {{ call.video_call_id }}
            </td>
            <!-- <td style="width: 15%;">
              {{ printTime(call.start_time) }}
            </td>
            <td style="width: 15%;">
              {{ printTime(call.end_time) }}
            </td> -->
            <td style="width: 15%;">
              {{ call.start_time | dateTimeZone : 'MM-DD-YYYY hh:mma' }}
            </td>
            <td style="width: 15%;">
              {{ call.end_time | dateTimeZone : 'MM-DD-YYYY hh:mma' }}
            </td>
            <td style="width: 10%;">
              {{ printDuration(call.duration) }}
            </td>

            <td style="width: 5%;">
              {{ call.call_type == '-' || call.call_type == null ? '--' : call.call_type }}
            </td>
            <td style="width: 25%;">
              {{ formatAddress(call.call_address, call.call_type) }}
            </td>
            <td style="width: 15%;">
              {{ call.call_disconnect == null ? '--' : call.call_disconnect }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div></div>
  </div>
</div>
