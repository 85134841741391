<div class="container border doNotPrint health-card-dark">
  <!-- <h2 *ngIf="soon == true" class="soon"></h2> -->

  <div class="row pl-2 ml-2">
    <div class="col-9">
      <div class="row">
        <div class="col-2">
          <img src="{{ iconLink }}" alt="Device Icon" class="img-class"
            [ngClass]="{ 'img-class2': mainTitle == 'Audio & Hybrid Devices' }" />
        </div>
        <div class="col-8 pr-0 mt-2">
          <h4>{{ mainTitle }}</h4>
          <small>{{ subTitle }}</small>
        </div>
        <div class="col-2">
          <ng-content></ng-content>
        </div>
      </div>
      <div>
        <div class="row pb-2" *ngFor="let bar of bars">
          <app-health-bar [enabled]="enabled" [routerLink]="bar.routerLink" color="{{ bar.color }}" [total]="bar.total"
            [value]="bar.value" [removeClickable]="bar.value"></app-health-bar>
        </div>
      </div>
    </div>

    <div class="col-3">
      <div class="vertical-3btn" *ngFor="let btn of buttons">
        <app-btn-health [ngClass]="{ 'disable-button': btn.count == null}" [routerLink]="btn.routerLink"
          [count]="btn.count" label="{{ btn.label }}" color="{{ btn.color }}"></app-btn-health>
      </div>
    </div>
  </div>
</div>