import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ZoomIncidentTrackerService } from '../../services/zoom-incident-tracker.service';

@Component({
  selector: 'app-zoom-system-status',
  templateUrl: './zoom-system-status.component.html',
  styleUrls: ['./zoom-system-status.component.scss'],
})
export class ZoomSystemStatusComponent implements OnInit, OnChanges {
  @Input() zMics: number;
  @Input() zMicsImpacted: number;
  @Input() zCameras: number;
  @Input() zCamerasImpacted: number;
  @Input() zSpeakers: number;
  @Input() zSpeakersImpacted: number;

  mics: any;
  cameras: any;
  speakers: any;
  micsImpacted: any;
  camerasImpacted: any;
  speakersImpacted: any;

  ngOnChanges(changes: SimpleChanges): void {
    this.mics = this.zMics ? this.zMics : '--';
    this.micsImpacted = this.zMicsImpacted;
    this.cameras = this.zCameras ? this.zCameras : '--';
    this.camerasImpacted = this.zCamerasImpacted;
    this.speakers = this.zSpeakers ? this.zSpeakers : '--';
    this.speakersImpacted = this.zSpeakersImpacted;
  }

  ngOnInit() {}
}
