import { Component, OnInit, ElementRef, SimpleChanges, OnChanges, Input } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EmailService } from '../../services/email.service'
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { GetCompanyService } from '../../services/get-company.service'
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-support-form',
  templateUrl: './support-form.component.html',
  styleUrls: ['./support-form.component.scss']
})
@ViewChild('myInput',{static:true})

export class SupportFormComponent implements OnInit, OnChanges {
  
	supportForm:any;
  	selectedFile: File = null;
	imageSrc: any;
	server_url: string = environment.serverUrl;
	allSiteListData = [];
	companyInfoData: any;
	sentRequest: any = false;
	resetResponseData: any;
	companyContactList: any
	JSON: any
	@Input() popupState: any
	selectSite: any =''
	siteContact: any = ''
	comment: any = ''
	issueType: any =''
	links = environment.config.links;

	constructor(private emailService: EmailService,  
		public http: HttpClient,
		private Auth: AuthService,
		private companyService: GetCompanyService,
		private formBuilder: FormBuilder
		) { 
			this.JSON = JSON;
		}


	ngOnChanges(changes: SimpleChanges) {
		
		if( changes.popupState){
			if(this.popupState == 'open'){
				// console.log("pop up is opening")
				this.sentRequest = false;
				this.submitted = false;
				this.resetForm();
				this.selectSite =''
				this.siteContact = ''
				this.comment = ''
				this.issueType =''
			}
		}
	}

	ngOnInit() {
		this.getSiteList()
		this.getCompanyInfo();
		this.getContactList();
		this.checkForm();
	}

	siteForm: FormGroup;
	submitted = false;
	model: any = {};
	// convenience getter for easy access to form fields
	get f() { return this.siteForm.controls; }

	checkForm(){
		// if(this.userIndex == -1){
		this.siteForm = this.formBuilder.group({
			selectSite: ['', [Validators.required]],
			siteContact: ['', [Validators.required]],
			comment: ['', [Validators.required]],
			issueType: ['', [Validators.required]],
			// // city: ['', [Validators.required]],
			// // state: ['', [Validators.required]],
			// // zip: ['', [Validators.required]],
			
			// firstname: ['', [Validators.required, Validators.maxLength(30)]],
			// lastname: ['', [Validators.required, Validators.maxLength(30)]],
			// email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
			// phone: ['', [Validators.required, Validators.pattern('^[\+]?[(][0-9]{3}[)][-\s\.]?[0-9]{3}[-][0-9]{4}$')]],
			// user_id: ['', [Validators.required]]
		});
	}

	// This function Clears the form on popup load
	resetForm(){
		let issueType = (<HTMLInputElement>document.getElementById('issueType'));
				let selectSite = (<HTMLInputElement>document.getElementById('selectSite'));
				let siteContact = (<HTMLInputElement>document.getElementById('siteContact'));
				let comment = (<HTMLInputElement>document.getElementById('comment'));
				let uploadFile = (<HTMLInputElement>document.getElementById('supportFile'));
				let fileLoadResult = (<HTMLInputElement>document.getElementById('loadResult'));

				if(issueType){
					issueType.value = ""
				}
				if(selectSite){
					selectSite.value = ""
				}
				if(siteContact){
					siteContact.value = ""
				}
				if(comment){
					comment.value = ""
				}

				if(uploadFile){
					uploadFile.value = ""
				}

				if(fileLoadResult){
					fileLoadResult.innerHTML = ""
				}
	}

	getSiteList(){
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService();
		const decodedToken = helper.decodeToken(token);

		const companyId = {
			company_id: decodedToken.company_id
		}
		this.Auth.getAllSiteList().subscribe(data => {
			this.allSiteListData = data.response;
			// console.log("This is the site list", this.allSiteListData);
		 });
	}

	getContactList(){
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService();
		const decodedToken = helper.decodeToken(token);

		const companyId = {
			company_id: decodedToken.company_id
		}
		this.Auth.getContactList(companyId).subscribe(data => {
			this.companyContactList = data['response']
			// console.log("This is the company contact list: ", this.companyContactList);
		});

	}

	getCompanyInfo(){
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService();
		const decodedToken = helper.decodeToken(token);

		const companyId = {
			company_id: decodedToken.company_id
		}
		this.companyService.apiGetCompanyInfo().subscribe(data => {
			this.companyInfoData = data['response']
			// console.log("This is the company info: ", this.companyInfoData);
		 });
	}

	// This function gathers all data that will be sent via email
	sendSupportRequest(){
		// event.preventDefault();
		// const target = event.target;
		
		const token = localStorage.getItem('token');
		const helper = new JwtHelperService();
		
		const decodedToken = helper.decodeToken(token);
		const companyId = decodedToken.company_id;
		const userId = decodedToken.user_id;

		const supportData = {
			company_id: companyId,
			data: {
				issue: {
					issueType: (<HTMLInputElement>document.getElementById('issueType')).value,
					site: (<HTMLInputElement>document.getElementById('selectSite')).value,
					siteContact: (<HTMLInputElement>document.getElementById('siteContact')).value,
					comment: (<HTMLInputElement>document.getElementById('comment')).value,
					attachment: this.selectedFile
				}
			}
		}
		
		const ticketData = new FormData();
		ticketData.append("issueType", (<HTMLInputElement>document.getElementById('issueType')).value);
		ticketData.append("site", (<HTMLInputElement>document.getElementById('selectSite')).value);
		ticketData.append("siteContact", (<HTMLInputElement>document.getElementById('siteContact')).value);
		ticketData.append("comment", (<HTMLInputElement>document.getElementById('comment')).value);
		if(this.selectedFile){
			ticketData.append("supportImage", this.selectedFile, this.selectedFile.name);
		}
		// console.log(supportData);
		// this.emailService.sendSupportRequestEmail(ticketData).subscribe( data => {
		// 	// console.log(data);
		// })
		
		this.http.post( this.server_url + "/api/v1/email/supportemail", ticketData, {
		// this.http.post("http://127.0.0.1:8080/api/v1/email/supportemail", ticketData, {
			reportProgress: true,
			// observe: 'events'
		})
		.subscribe(data => {
			this.sentRequest = true;
			this.resetResponseData = data;
		})
	}

	onSubmit() {
		// if (this.supportForm.valid) {
		// 	// console.log("Form Submitted!");
		// 	alert("test:" + this.supportForm);
		// }
		// stop here if form is invalid
		this.submitted = true;

		if (this.siteForm.invalid) {
			// console.log("invalid form in virtual site; " + this.selectSite);
			// console.log("Errors: ", this.f.selectSite.errors)
			  return;
		 }else{
			this.sendSupportRequest()
		 }
	}

	myInputVariable: ElementRef;

	// This function handles the file selection, image and image preview
	onFileSelected(event){
		// console.log(event);
		this.selectedFile = <File>event.target.files[0];
	
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			var total = file.length; 
			var loaded = 0;
			const reader = new FileReader();
			reader.readAsBinaryString(file);
			reader.onloadend = function() {
				(<HTMLInputElement>document.getElementById('loadResult')).innerHTML = ("Image loaded");

			}
			
	  	}
	}

	resetFile(){
		(<HTMLInputElement>document.getElementById('load-result')).innerHTML = ("");
	}

	// This function send the image file to the server to be saved
	// onUpload(){
	// 	const token = localStorage.getItem('token')
   //    const helper = new JwtHelperService();
   //    const decodedToken = helper.decodeToken(token);
	// 	const company_id = decodedToken.company_id;
	// 	const fd = new FormData();
	// 	fd.append("companyImage", this.selectedFile, this.selectedFile.name);
	// 	fd.append("company_id", company_id);
	// 	this.http.post( this.server_url + "/api/v1/user/uploadTest", fd, {
	// 		reportProgress: true,
	// 		observe: 'events'
	// 	})
	// 	.subscribe(event => {
	// 		if(event.type === HttpEventType.UploadProgress){
	// 			// console.log("Upload Progress: " + Math.round(event.loaded / event.total * 100) + "%")
	// 		} else {
	// 			// console.log(event);
	// 		}
	// 	})
	// }

}
