import { animate, style, transition, trigger } from '@angular/animations'
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
interface Logos{
  type: string,
  img: string
}
@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit, OnChanges {
  @Input() quadrantType: any;
  images: Logos[] = []
  @Input() polycomDevices: number = 0;
  @Input() ciscoDevices: number = 0;
  @Input() logitechDevices: number = 0;
  @Input() zoomEnabled: boolean = false;
  @Input() teamsEnabled: boolean = false;
  interval: number = 0;
  constructor() { }

  ngOnInit() { 
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.polycomDevices && this.polycomDevices) {
      this.animation('poly');
    }
    if (changes.ciscoDevices && this.ciscoDevices) {
      this.animation('cisco');
    }
    if (changes.logitechDevices && this.logitechDevices) {
      this.animation('logitech');
    }
  }
  animation(type:string) {
      let isExist = !!this.images.find((x) => x.type == type);
      if (!isExist) this.pushImages(type);
      if(this.images.length > 1) {
        setInterval(() => {
            this.interval++;
            this.interval = this.interval % this.images.length;
          },5000)
      }
  }
  pushImages(type: string) {
    const imagePath = type === 'cisco' ? '../../assets/img/cisco.png' :
                  type === 'poly' ? '../../assets/img/poly_logo.png' :
                  type === 'logitech' ? '../../assets/img/logitechh.png' :
          '';
    this.images.push({ type: type, img: imagePath });
  }
}
