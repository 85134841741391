import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { GetIotService } from '../../services/get-iot.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { WebportalProtocolConfigDialogComponent } from '../dialogs/webportal-protocol-config-dialog/webportal-protocol-config-dialog.component';



export interface DeviceListInterface {
  agent_id: number;
  display_name: string;
  iot_device_id: number;
  ip_address: string;
  make: string;
  device_name: string;
  model: string;
  ping_rtd: number;
  status: string;
  company_name: string;
}


@Component({
  selector: 'app-iot-lite-site-summary',
  templateUrl: './iot-lite-site-summary.component.html',
  styleUrls: ['./iot-lite-site-summary.component.scss']
})
export class IotLiteSiteSummaryComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() filterStatus: String;
  @Input() filterAgent: number;
  displayedColumns: string[];
  dataSource: MatTableDataSource<DeviceListInterface>;
  deviceList: any = [];
  pageSizeSelect: any[] = [];
  totalPageLimit = 100;
  paginateOjb: any = {
    currentPage: 1,
    endIndex: 4,
    pageSize: 0,
    pages: [],
    startIndex: 0,
    totalItems: 0,
    totalPages: 0,
  };
  totalCount: number;
  pageSelected: any = 0;
  searchKey: String = 'null';
  refreshData: Boolean;
  greenIcon = '../../../assets/img/triangle_green.png';
  redIcon = '../../../assets/img/triangle_red.png';
  

  constructor(
    private getIot: GetIotService,
    public Auth: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.displayedColumns = this.isShowCompanyName()
      ? [
        'company_name',
        'site',
        'health',
        'impacted',
        'devices',
      ]
      : [
        'site',
        'health',
        'impacted',
        'devices',
      ];
  }


  ngOnInit() {
    this.getAllDomotzIotDevicesSummaryList();
   }

  getAllDomotzIotDevicesSummaryList = () => {
    const filterAgent = this.filterAgent ? this.filterAgent : -1;
    this.getIot
      .getAllDomotzIotDevicesSummaryList(
        this.searchKey,
        this.totalPageLimit,
        this.paginateOjb.startIndex,
        0,
        filterAgent
      )
      .subscribe((data: any) => {
        this.deviceList = data.map( e=> {
          return {
            ...e, 
            health: e.health == null ? 0 : e.health
          }
        });

        console.log(this.deviceList)
     
        this.dataSource = new MatTableDataSource([]);
        this.dataSource.sort = this.sort;
      });
  };

  searchData = (key) => { 
    this.searchKey = key;
    // this.getAllDomotzIotDevicesSummaryList();
  };


  isShowCompanyName = () => {
    return this.Auth.accessLevel === 'SUPERADMIN';
  };


}
