<div class="zoom-rooms">
  <div infinite-scroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="500" [scrollWindow]="false" (scrolled)="onScroll()" style="overflow: auto; height: 280px;">
    <table class="table table-stripped">
      <thead>
        <tr class="black">
          <th scope="col">Plugin</th>
          <th scope="col">Room name</th>
          <th scope="col">Site name</th>
          <th scope="col">IP</th>
          <th scope="col">Room Status</th>
          <th scope="col">{{ dPlugin }} Status</th>
          <th scope="col">Monitoring</th>

          <th scope="col">Link</th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row" *ngFor="let items of zoomRoomList">
          <td>
            <div *ngIf="items.plugin_id === null">
              --
            </div>
            <img gIf="items.plugin_id !== null" src="{{ getPluginIcon(items.plugin_id) }}" alt="" />
          </td>
          <td>
            {{ items.room_name | displayEmpty }}
          </td>
          <td>
            {{ items.site_name }}
          </td>
          <td>
            {{ items.plugin_ip_address }}
          </td>
          <td>
            <div class="zoom-rooms__status {{ getRoomStatus(items.online) }}"></div>
          </td>
          <td>
            <div class="zoom-rooms__status {{
                getPluginStatus(items.plugin_status, items.zoom_plugin_enabled)
              }}"></div>
          </td>
          <td>
            <div class="checkbox_switch">
              <label class="switch" [ngClass]="{ 'disable-checkbox': items.plugin_id === null}">
                <input #setMonitredZoomRoomsCheckbox type="checkbox" [checked]="items.is_monitored === 1"
                  (change)="setMonitored(items.zoom_user_id, setMonitredZoomRoomsCheckbox.checked)" />
                <span class="slider round"></span>
              </label>
            </div>
          </td>
          <td>
            <div (click)="openPluginLinkModalZoomROoms(items)">
              <div>
                <img src="{{getLinkIcon(items)}}">
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="zoom-rooms__loader-img">
      <img *ngIf="showLoader" [src]="loader" />
    </div>
  </div>
</div>