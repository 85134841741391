import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../services/auth.service'
import { GetIotService } from '../../services/get-iot.service'
import { MatDialog } from '@angular/material/dialog'
import { JwtHelperService } from '@auth0/angular-jwt'

@Component({
	selector: 'app-polycom-main-menu',
	templateUrl: './polycom-main-menu.component.html',
	styleUrls: ['./polycom-main-menu.component.scss'],
})
export class PolycomMainMenuComponent implements OnInit {
	activePanel: Number = 1
	iotLiteEnabled: any = false
	isDomotzEnabled: any = false
	disableToggle: boolean = false
	compId: any = ''
	polyAlertMonitorDtls: any = []
	polyAlert: any = 0
	polyMonitor: any = 0

	constructor(private Auth: AuthService, private GetIot: GetIotService, public dialog: MatDialog) {}

	ngOnInit() {
		this.disableToggle = true

		const token = localStorage.getItem('token')
		const helper = new JwtHelperService()
		const decodedToken = helper.decodeToken(token)
		this.compId = decodedToken.company_id

		this.getAlertMonitorStatus()
	}

	selectActivePanel = opt => (this.activePanel = opt)

	getAlertMonitorStatus() {
		const cmpData = {
			company_id: this.compId,
		}

		this.polyAlertMonitorDtls = []
		this.Auth.getDeviceAlertMonitorStatus(cmpData).subscribe(data => {
			this.polyAlertMonitorDtls = data['response']
			this.polyAlert = +this.polyAlertMonitorDtls[0].polycom_alerts
			this.polyMonitor = 1 - +this.polyAlertMonitorDtls[0].polycom_paused
			this.disableToggle = false
		})
	}

	togglePolyMonitor() {
		this.disableToggle = true
		this.polyMonitor = 1 - this.polyMonitor

		const cmpData2 = {
			company_id: this.compId,
			device_type: 'polycom',
		}

		if (this.polyMonitor) {
			this.Auth.setDeviceTypeMonitorOn(cmpData2).subscribe(() => {
				this.getAlertMonitorStatus()
			})
		} else {
			this.Auth.setDeviceTypeMonitorOff(cmpData2).subscribe(() => {
				this.getAlertMonitorStatus()
			})
		}
	}

	togglePolyAlerts() {
		this.disableToggle = true
		this.polyAlert = 1 - this.polyAlert

		const cmpData1 = {
			company_id: this.compId,
			device_type: 'polycom',
		}

		if (this.polyAlert) {
			this.Auth.setDeviceTypeNotifyOn(cmpData1).subscribe(() => {
				this.getAlertMonitorStatus()
			})
		} else {
			this.Auth.setDeviceTypeNotifyOff(cmpData1).subscribe(() => {
				this.getAlertMonitorStatus()
			})
		}
	}
}
