import { ApplicationRef, ComponentFactoryResolver, ComponentRef, ElementRef, HostListener, Injector, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'longnumber'
})
export class LongNumberPipe implements PipeTransform {
  transform(text: string | number , suffix: string = '...' , length : number = 4): string {
    if ( (text + '').length > length) {
      let truncated: string = (text + '').substring(0, length ).trim() + suffix;
      return truncated;
    }

    return text+'';
  }
}