<div class="devicetable">
    <input #searchDataInput (keyup)="searchData(searchDataInput.value)" placeholder="Search..." class="input-class" />
    <div class="mat-elevation-z8 child mat-table">
        <table class="devicetable__data-table" mat-table [dataSource]="dataSource" matSort>

            <ng-container *ngIf="isShowCompanyName()" matColumnDef="company_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>Company</strong>
                </th>
                <td mat-cell *matCellDef="let row">
                    <span>
                        {{ row.company_name | displayEmpty }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="site">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>Site</strong>
                </th>
                <td mat-cell *matCellDef="let row" title="{{ row.iot_name | displayEmpty }}">
                    <span>
                        {{ row.iot_name | displayEmpty }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="health">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>Healths</strong>
                </th>
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <img src="{{ row.health > 50 ? greenIcon : redIcon }}" />
                </td>
            </ng-container>

            <ng-container matColumnDef="impacted">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>Impacted</strong>
                </th>
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <span>
                        --
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="devices">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <strong>Devices</strong>
                </th>
                <td mat-cell *matCellDef="let row">
                    <span>
                        --
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</div>