import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-table-health-icon',
  templateUrl: './table-health-icon.component.html',
  styleUrls: ['./table-health-icon.component.scss']
})
export class TableHealthIconComponent implements OnInit {
  @Input() value: string | number;
  @Input() paused: number;
  @Input() rowData: any;
  @Input() serviceType: any;

  ngOnInit() {
    // this.renderValue = this.value;
  }


  setHealthArrow = () => {
    if (this.value >= "76") {
      return '../../../assets/img/triangle_green.png'
    }
    if (this.value < "76" && this.value >= "51") {
      return '../../../assets/img/shared/triangle_orange.png'
    }
    if (this.value <= "50") {
      return '../../../assets/img/shared/triangle_red.png'
    }
  }
}
