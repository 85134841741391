import { AuthService } from 'src/app/services/auth.service'
import { GetCollectorService } from 'src/app/services/get-collector.service'
import { environment } from 'src/environments/environment'

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'

import { GetDocumentCenterService } from '../../services/get-document-center.service'
import { PopupTypeService } from '../../services/popupType.service'

@Component({
	selector: 'app-collector-admin',
	templateUrl: './collector-admin.component.html',
	styleUrls: ['./collector-admin.component.scss'],
})
export class CollectorAdminComponent implements OnInit, OnChanges {
	@Input() data: any

	company_id: number
	shouldShowDocuments: boolean = false
	propData: any
	showMsg30Sec: boolean = false
	setTimer: any
	showDeleteDevice: boolean = false
	showRenameDevice: boolean = false
	showLogging: boolean = false
	alreadyEditingInProcess: boolean = false
	showCollectorAlert: any = false;

	editSiteText = 'Please enter new Site name'
	config: any


	//collector logs

	docPath: string = environment.serverUrl + '/api/v1/files/get-file/';
  actionCount: any = 0;
  aDeviceIsFound: any = false;
  showSuccessMsg: any = false;
  successMessage: any = "Device Successfully Added";

  errorMsg: any = "Device Not Found";
  showErrMsg: any = false;
  errorMessage: any = 'Could not find device. Please try again.';

  showDownloading: any = false;

  showAddDevice: any = false;
  showErrorMessage: any = false;

  snapshotMessage: any;
  documentList: any;
  showSnapshotLoader: false;

  isLoading: any = false;

	options = {
		autoClose: true,
		keepAfterRouteChange: false,
	}
	

	constructor(
		private ds: PopupTypeService,
		private collectorService: GetCollectorService,
		public dialog: MatDialog,
		private router: Router,

		private Auth: AuthService,
    private getCollector : GetCollectorService,
    private getDocumentCenter: GetDocumentCenterService,
	) {}

	ngOnInit() {
		// when a site is deleted
		this.collectorService.collectorSiteChangeEvent.subscribe(data => {
			this.sendPopupType(0)
			this.router.navigate(['collectors/all'])
		})
		this.config = {
			company_id: this.Auth.companyId,
			site_id: this.data?.site_id,
			site_name: this.data?.site_name,
			// sendPopupType: null,
			// cloud_rooms_count,
			// zoom_room,
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		// if (changes.iotDeviceDetails && this.iotDeviceDetails) {
		//   this.setProps();
		// }
	}

	showDocumentCenter = () => {
		this.propData = {
			quadrant_group: 'COLLECTOR',
			device_id: this.data.collector_id,
			description: '',
			offset: 0,
		}
		this.shouldShowDocuments = true
	}

	resetAll = () => {
		this.shouldShowDocuments = false
		this.showRenameDevice = false
		this.showLogging = false;
		this.showCollectorAlert = false;
		this.showDeleteDevice = false
	}

	showPanel = params => {
		this.resetAll()
		
		
		if (params == 1) {
			// this.showDocumentCenter()
			this.showCollectorAlert = true;
		}
		if (params == 2) {
			this.showDocumentCenter()
		}


		
		if (params == 3) {
			this.showRenameDevice = true
		}
		
		if (params == 4) {
			this.showDeleteDevice = true
			// this.openDeleteModal()
		}
		
		if (params == 5) {
			this.showLogging = true
			this.getCollectorLogs()

		}
	}

	// cancelDelete = () => {
	// 	this.showDeleteDevice = false
	// }

	deleteDevice = () => {
		console.log('add the api')
	}

	clearTimeout = () => {
		clearTimeout(this.setTimer)
	}

	replaceSpecialCharacther = portname => {
		return portname.replace(/\W/g, '_')
	}

	editSiteName = (val: any) => {
		this.alreadyEditingInProcess = true

		this.collectorService.updateSiteName(this.data.site_id, val).subscribe(e => {
			this.alreadyEditingInProcess = false

			this.editSiteText = this.editSiteText + ' | Done!'

			setTimeout(() => {
				this.editSiteText = this.editSiteText.split(' |')[0]
			}, 5000)
		})
	}

	sendPopupType(p) {
		this.ds.sendPopupType(p)
	}

	// openDeleteModal = () => {
	// 	const webportal = this.dialog.open(DeleteSiteDialogComponent, {
	// 		panelClass: 'custom-dialog-container',
	// 		data: {
	// 			config: this.config,
	// 		},
	// 	})

	// 	// webportal.afterClosed().subscribe(() => {
	// 	// })
	// }


	//collector loging temporary fix

	getCollectorLogs = () => {
			console.log('getCollectorLogs')
		const { collector_id } = this.data
		this.getCollector.collectorLogging(collector_id).subscribe((data: any) => {
			const [res] = data.response
      
			if (data.status == 200) {
				const { action_id } = res
				this.checkStatus(action_id)
				this.snapshotMessage = `<h5>Downloading Collector Logs<h5/>`
				this.toggleCollectorLogLoader(true)
        
			}
		},
			(err) => { }
		)
	}

  resetFields = () => {
    this.actionCount = 0;
    this.showDownloading = false; 
    this.toggleCollectorLogLoader(false)
	this.showMsg30Sec = false;
  }		


	toggleCollectorLogLoader = (show) => {
		this.showDownloading = show
	}
	
	checkStatus = (action_id) => {
    console.log('action_id: ', action_id);
    this.Auth.postDeviceActionId({ action_id }).subscribe((actionData) => {
      console.log('postDeviceActionId: ', actionData)
      console.log('actionCount: ', this.actionCount)
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 90) {
        //resets field if device not found 
        if (!this.aDeviceIsFound) {
          this.resetFields();
          this.snapshotMessage = 'Something went wrong. Please try again.';

        }

        return;
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus(action_id);
        }, 1000);
        this.actionCount++;
		if (this.actionCount == 30) {
			this.showMsg30Sec = true;
  
			// Since total time of waiting is 90 seconds and after 30 we show It iss taking longer then usual
			setTimeout(() => {
			  this.showMsg30Sec = false;
			}, 70*1000); 
		  }
      } else if (actionData.response[0].status === 'FAILED') {
        this.resetFields();
        const [newData] = actionData.response;
        this.snapshotMessage = 'Something went wrong. Please try again.';
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        const [newData] = actionData.response;
        console.log('COMPLETE!!!', newData)
        
        this.getDocumentsByDevice()
        

      }
    });
  }

  downloadCollectorLogs = () => {
    const actionParams = {};
    const actionName = 'TakeImagefromWebCam';


  }

  getDocumentsByDevice = () => {
  	const propData = {
			quadrant_group: 'COLLECTOR',
			device_id: this.data.collector_id,
			description: '',
      offset: 0,
      root_id: 0,
      isFiles: true

		}
		
    this.documentList = [];
    this.getDocumentCenter.getDocumentsByDevice(propData).subscribe((data: any) => {
      const { document_list, total_document_size, document_center_storage_limit } = data;
      const totalFileSize = data.document_list.reduce((total, document) => total + document.file_size, 0);
      
      
      console.log(document_list)
      
      const [latest] = document_list;
      
      this.snapshotMessage = `<h5>Collector Log successfully uploaded to the Document Centre</h5>
       You can download it here: <a href="${this.docPath+latest?.document_id+'/'+1}">${latest?.file_name}</a>`
          
      this.resetFields()
      
    });
  };
 


	ngOnDestroy(): void {
		this.clearTimeout()
	}
}
