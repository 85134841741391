import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrintDataService {
  constructor() {}

  printToPdf = (divId, title) => {
    const element = document.getElementById(divId);
    setTimeout(() => {
      const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      const stylesHtml = this.getTagsHtml('style');
      popupWin.document.open();

      popupWin.document.write(`
        <html>
          <head>
            <title>${title}</title>
            ${stylesHtml}
          </head>
          <body onload="window.print(); window.close()">
            ${element.innerHTML}
          </body>
        </html>
      `);
      popupWin.document.close();
    }, 500);
  };

  printCanvas(chartId, title) {
    let printContents = (<HTMLCanvasElement>document.getElementById(`${chartId}`)).toDataURL();
    let popupWin;

    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>${title}</title>
        </head>
        <body onload="window.print(); window.close()">
          <img style='width: 100%;' src='${printContents}'/>
        </body>
      </html>
    `);
    popupWin.document.close();
  }

  getTagsHtml = (tagName) => {
    const htmlStr: string[] = [];
    const elements = document.getElementsByTagName(tagName);

    for (let idx = 0; idx < elements.length; idx++) {
      htmlStr.push(elements[idx].outerHTML);
    }

    return htmlStr.join('\r\n');
  };
}
