<div class="container-fluid" style="padding-top:60px;padding-bottom: 200px;padding-left:10%;">
    <div class="row upper-row">
        <div class="col-12">
          <div class="row">
            <div class="col-3 pl-5">
                <img src="../../assets/img/bluejeans_logo.png@1X.png" alt="zoom logo"/>
            </div>
            <div class="col-2">
                <div class="retract">Retract</div>
                <div class="consent">API</div>
                <div class="enabled" style="position: relative;top:-18px;left:67px;">Consent</div>
              <label class="switch" style="position: relative;top: -45px;">
              <input type="checkbox" checked>
              <span class="slider round"></span>           
              </label>
              <div class="paused">paused</div>
              <!-- <div class="enabled">enabled</div> -->
            </div>
            <div class="col-2 col-offset-5">
              <div class="status-green text-center">Status</div>
            </div>
          </div>
        </div>
      </div>
    <div class="row lower-row ">
      <div class="col-12 center">
        <form role="form" class="form-inline" >
          <div class="form-group">
            <label for="exampleInputEmail1">
            </label>
            <input type="password" value="************"  class="form-control" id="exampleInputEmail1" />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">
            </label>
            <input type="password" value="************"  class="form-control" id="exampleInputPassword1" />
          </div>
          <button type="button" class="btn btn-primary btn1" style="width:90px important;">
            Save
          </button>
          <button type="button" class="btn btn-primary btn2" style="width:90px important;">
            Manage
          </button>
          <button type="button" class="fa fa-question"></button>
        </form>
      </div>
    </div>
    <div class="row upper-row">
        <div class="col-12">
          <div class="row">
            <div class="col-3 pl-5">
                <!-- <img src="../../assets/img/zoom_logo.png@1X.png" alt="zoom"/> -->
            </div>
            <!-- <div class="api-btn">API</div>
            <div class="col-2">
              <label class="switch">
              <input type="checkbox" checked>
              <span class="slider round"></span>           
              </label>
              <div class="retract">Retract</div>
              <div class="consent">Consent</div>
            </div> -->
            <!-- <div class="col-2 col-offset-5">
              <div class="status-green text-center">Status</div>
            </div> -->
          </div>
        </div>
      </div>
    <div class="row lower-row ">
      <div class="col-12 center">
          <div class="row btm-row ">- GDPH Compliance -</div>
        <form role="form" class="form-inline" >
          <button type="button" class="btn btn-primary">
            Delete User Data
          </button>
          <button type="button" class="btn btn-primary">
            Download User Data
          </button>
          <a href="" alt="privacy policy" class="policy">Privacy Policy</a>
        </form>
      </div>
    </div>
    <!-- <div class="row btm-row ">GDPH Compliance</div> -->
</div>