import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VideoIncidentTrackerService } from '../../services/video-incident-tracker.service';
import { VideoHelperService } from '../../services/video-helper.service';
import { CallHelperService } from '../../services/call-helper.service';

@Component({
  selector: 'app-video-monitoring',
  templateUrl: './video-monitoring.component.html',
  styleUrls: ['./video-monitoring.component.scss'],
})
export class VideoMonitoringComponent implements OnInit, OnChanges {
  @Input() videoDeviceData: any;
  onCall = 0;
  sipGate: any;
  incidentSummary: any;
  hasIssues = false;
  roomName: string;
  siteName: string;
  // SystemStatus
  mics: number;
  micImpacted: number;
  cameras: number;
  cameraImpacted: number;
  temperature: any;
  isShowTemperature = false;

  isShowSystemStatusConnectedDevicesHeader: boolean = false;
  isShowSystemStatusConnectedDevicesBody: boolean = true;
  isShowSystemStatusCallHeader: boolean = true;
  isShowSystemStatusCallBody: boolean = false;
  isExpandSystemStatusConnectedDevices: boolean = true;
  isExpandSystemStatusCall: boolean = false;

  callStatusData: any = {
    jitter: {
      value: null,
      color: 'gray',
    },
    packetloss: {
      value: null,
      color: 'gray',
    },
    latency: {
      value: null,
      color: 'gray',
    },
  };

  constructor(
    private videoIncidents: VideoIncidentTrackerService,
    private videoHelper: VideoHelperService,
    private callHelper: CallHelperService
  ) {
    this.videoIncidents.resetVideoIncidents();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.onCall = 0;
    this.hasIssues = false;
    if (this.videoDeviceData) {
      const { room_name, site_name, oncall } = this.videoDeviceData;
      this.onCall = oncall;

      this.roomName = room_name;
      this.siteName = site_name;

      this.iniSystemStatus();
      this.iniCallStatus();
      this.gatherIncidents();
    }
  }

  toggleSystemStatusConnectedDevices = () => {
    this.isShowSystemStatusConnectedDevicesHeader = false;
    this.isShowSystemStatusConnectedDevicesBody = true;
    this.isShowSystemStatusCallHeader = true;
    this.isShowSystemStatusCallBody = false;
    this.isExpandSystemStatusConnectedDevices = true;
    this.isExpandSystemStatusCall = false;
  };

  toggleSystemStatusCall = () => {
    this.isShowSystemStatusConnectedDevicesHeader = true;
    this.isShowSystemStatusConnectedDevicesBody = false;
    this.isShowSystemStatusCallHeader = false;
    this.isShowSystemStatusCallBody = true;
    this.isExpandSystemStatusConnectedDevices = false;
    this.isExpandSystemStatusCall = true;
  };

  iniSystemStatus = () => {
    const {
      total_cameras,
      active_cameras,
      total_mics,
      active_mics,
      temprature,
      device_type,
      triggers,
    } = this.videoDeviceData;
    // System Status
    this.isShowTemperature = false;
    this.mics = active_mics ? active_mics : '--';
    this.cameras = total_cameras ? total_cameras : '--';
    this.cameraImpacted = total_cameras - active_cameras;
    this.micImpacted = total_mics - active_mics;
    this.isShowTemperature = this.videoHelper.getIsDisplayTemperature(device_type);
    this.temperature = this.videoHelper.setTemperatureValue(temprature, triggers.temperature);
  };

  iniCallStatus = () => {
    const {
      total_jitter,
      total_packetloss,
      total_latency,
      trigger_limits,
      triggers,
      sip_info,
      gatekeeper,
    } = this.videoDeviceData;

    // call status data
    this.callStatusData = {
      jitter: {
        value: 0,
        color: 'gray',
      },
      packetloss: {
        value: 0,
        color: 'gray',
      },
      latency: {
        value: 0,
        color: 'gray',
      },
    };

    if (this.onCall) {
      // jitter
      this.callStatusData.jitter.value = total_jitter;
      this.callStatusData.jitter.color = this.callHelper.getColor(
        trigger_limits.jitter.good,
        total_jitter,
        triggers.jitter.warning,
        triggers.jitter.alert
      );

      // packetloss
      this.callStatusData.packetloss.value = total_packetloss;
      this.callStatusData.packetloss.color = this.callHelper.getColor(
        trigger_limits.packetsloss.good,
        total_packetloss,
        triggers.packetsloss.warning,
        triggers.packetsloss.alert
      );

      // packetloss
      this.callStatusData.latency.value = total_latency;
      this.callStatusData.latency.color = this.callHelper.getColor(
        trigger_limits.latency.good,
        total_latency,
        triggers.latency.warning,
        triggers.latency.alert
      );
    }

    // sip gate
    this.sipGate = this.callHelper.getSipGateKeeperStatus('video', sip_info, gatekeeper);
  };

  gatherIncidents = () => {
    this.videoIncidents.resetVideoIncidents();

    this.videoIncidents.setCamereIncident(this.cameraImpacted);
    this.videoIncidents.setMicIncident(this.micImpacted);
    this.videoIncidents.setJitterIncident(this.callStatusData.jitter.color);
    this.videoIncidents.setPacketlossIncident(this.callStatusData.packetloss.color);
    this.videoIncidents.setLatencyIncident(this.callStatusData.latency.color);

    this.videoIncidents.gatherIncidents();

    this.hasIssues = this.videoIncidents.hasIncidents;
    this.incidentSummary = this.videoIncidents.getIncidents();
  };
}
