import { Subscription } from 'rxjs'
import { AuthService } from 'src/app/services/auth.service'
import { IncidentType } from 'src/app/services/incident-messages.service'
import { PrintDataService } from 'src/app/services/print-data.service'

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { IncidentMessagesComponent } from '../../popups/dialog/incident-messages/incident-messages.component'

@Component({
	selector: 'app-collector-incident-list',
	templateUrl: './collector-incident-list.component.html',
	styleUrls: ['./collector-incident-list.component.scss'],
})
export class CollectorIncidentListComponent implements OnInit, OnChanges {
	@Input() data: any
	@Input() popupState: any
	isShowTableData: boolean = true
	shouldShowLoaderBtn: boolean = false
	filterListner: Subscription

	incidentsData = []
	constructor(private Auth: AuthService, private printData: PrintDataService, private dialog: MatDialog) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (this.data && changes.data) {
			this.getData()
		}
	}

	getData = () => {
		this.shouldShowLoaderBtn = true

		this.Auth.getDeviceIncidentList({
			site_id: this.data.deviceId,
			htype: 'collector',
		}).subscribe((data: any) => {
			if (data.data.length < 1) {
				this.isShowTableData = false
			}

			function getEventsNew(i) {
				return Object.keys(i.events).map(e => ({
					trigger: i.events[e].message,
					pulls: i.events[e].polls,
					last_pull: i.events[e].lastPollStamp,
					end_time: i.events[e].endStamp,
					start_date: i.events[e].startStamp,
				}))
			}

			function getEventsOld(i) {
				const now = new Date()
				const start_date = new Date(i.start_time)
				const end_time = i.end_time ? new Date(i.end_time) : null

				now.setSeconds(0)
				now.setMilliseconds(0)

				const last_pull = new Date(now.getTime())
				last_pull.setMinutes(last_pull.getMinutes() - 1)

				const pulls = Math.round(((end_time?.getTime() || now.getTime()) - start_date.getTime()) / (1000 * 60))

				return [
					{
						trigger: i.events[0].event,
						pulls,
						last_pull,
						end_time,
						start_date,
					},
				]
			}

			this.incidentsData = data.data.map(i => ({
				incident_id: i.incident_id,
				actioned: i.actioned,
				start_time: i.start_time,
				end_time: i.end_time,
				event_details: i.old_format ? getEventsOld(i) : getEventsNew(i),
			}))

			this.shouldShowLoaderBtn = false
		})
	}

	printIncidentsToPdf = () => {
		this.printData.printToPdf('incident-history-list-container', 'Incident Report')
	}

	onShowMessages = (incidentId, idx) => {
		const dialogRef = this.dialog.open(IncidentMessagesComponent, {
			panelClass: 'custom-dialog-container',
			data: {
				incidentId,
				incidentType: IncidentType.VIDEO,
			},
			width: '80%',
			height: 'auto',
		})

		dialogRef.afterClosed().subscribe(isActioned => {
			if (isActioned) {
				this.incidentsData[idx].actioned = 1
			}
		})
	}
}
