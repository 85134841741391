<div class="wrap" *ngIf="data.section == 'mbps'">
  <table class="head">
    <tr style="font-weight: bold;">
      <td style="width: 10%;">Time Stamp</td>
      <td style="width: 10%;">Live Mbps</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 10%;">Frame Rate</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 10%;">Jitter</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 10%;">Packet Loss</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 5%;">People Count</td>
      <td style="width: 10%;">Incidents Id</td>
    </tr>
  </table>
  <div class="inner_table">
    <table *ngFor="let data of cloudData | reverse" style="border-bottom-style: solid; border-bottom-width: 1px;"
      [style.color]="data.zoom_incident_id ? 'red' : 'black'">
      <tr>
        <td style="width: 10%;">{{ data.timestamp }}</td>
        <td style="width: 10%;">{{ data.total_mbps }}</td>
        <td style="width: 5%;" class="details">
          {{
          !data.audio_input || !data.video_input || !data.as_input
          ? 0
          : ((data.audio_input.bitrate + data.as_input.bitrate + data.video_input.bitrate) /
          1000000 | twoDecimals)
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.audio_output || !data.video_output || !data.as_output
          ? 0
          : ((data.audio_output.bitrate + data.as_output.bitrate + data.video_output.bitrate) /
          1000000 | twoDecimals)
          }}
        </td>
        <td style="width: 10%;">
          {{
          !data.video_input || !data.video_output || !data.as_input || !data.as_output
          ? 0
          : data.video_input.frame_rate +
          data.as_input.frame_rate +
          data.video_output.frame_rate +
          data.as_output.frame_rate
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_input || !data.as_input
          ? 0
          : data.video_input.frame_rate + data.as_input.frame_rate
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_output || !data.as_output
          ? 0
          : data.video_output.frame_rate + data.as_output.frame_rate
          }}
        </td>
        <td style="width: 10%;">{{ data.total_jitter }}</td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_input || !data.as_input || !data.audio_input
          ? 0
          : data.video_input.jitter + data.audio_input.jitter + data.as_input.jitter
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_output || !data.as_output || !data.audio_output
          ? 0
          : data.video_output.jitter + data.audio_output.jitter + data.as_output.jitter
          }}
        </td>
        <td style="width: 10%;">{{ data.total_packetloss }}</td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_input || !data.as_input || !data.audio_input
          ? 0
          : (data.video_input.avg_loss + data.audio_input.avg_loss + data.as_input.avg_loss
          | twoDecimals)
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_output || !data.as_output || !data.audio_output
          ? 0
          : (data.video_output.avg_loss + data.audio_output.avg_loss + data.as_output.avg_loss
          | twoDecimals)
          }}
        </td>
        <td style="width: 5%;">{{ data.people_count }}</td>
        <td style="width: 10%;">{{ data.zoom_incident_id }}</td>
      </tr>
      <tr>
        <td>Video</td>
        <td>0</td>
        <td class="details">
          {{ !data.video_input ? 0 : (data.video_input.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td class="details">
          {{ !data.video_output ? 0 : (data.video_output.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td>
          {{
          !data.video_input || !data.video_output
          ? 0
          : data.video_input.frame_rate + data.video_output.frame_rate
          }}
        </td>
        <td class="details">{{ !data.video_input ? 0 : data.video_input.frame_rate }}</td>
        <td class="details">{{ !data.video_output ? 0 : data.video_output.frame_rate }}</td>
        <td>
          {{
          !data.video_input || !data.video_output
          ? 0
          : data.video_input.jitter + data.video_output.jitter
          }}
        </td>
        <td class="details">{{ !data.video_input ? 0 : data.video_input.jitter }}</td>
        <td class="details">{{ !data.video_output ? 0 : data.video_output.jitter }}</td>
        <td>{{ !data.video_input ? 0 : data.video_input.avg_loss + data.video_input.avg_loss }}</td>
        <td class="details">{{ !data.video_input ? 0 : data.video_input.avg_loss }}</td>
        <td class="details">{{ !data.video_output ? 0 : data.video_output.avg_loss }}</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Audio</td>
        <td>
          {{
          !data.audio_input || !data.audio_output
          ? 0
          : ((data.audio_input.bitrate + data.audio_output.bitrate) / 1000000 | twoDecimals)
          }}
        </td>
        <td class="details">
          {{ !data.audio_input ? 0 : (data.audio_input.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td class="details">
          {{ !data.audio_output ? 0 : (data.audio_output.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td>0</td>
        <td class="details">0</td>
        <td class="details">0</td>
        <td>
          {{
          !data.audio_input || !data.audio_output
          ? 0
          : data.audio_input.jitter + data.audio_output.jitter
          }}
        </td>
        <td class="details">{{ !data.audio_input ? 0 : data.audio_input.jitter }}</td>
        <td class="details">{{ !data.audio_output ? 0 : data.audio_output.jitter }}</td>
        <td>
          {{
          !data.audio_input || !data.audio_output
          ? 0
          : data.audio_input.avg_loss + data.audio_output.avg_loss
          }}
        </td>
        <td class="details">{{ !data.audio_input ? 0 : data.audio_input.avg_loss }}</td>
        <td class="details">{{ !data.audio_output ? 0 : data.audio_output.avg_loss }}</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Content</td>
        <td>
          {{
          !data.as_input || !data.as_output
          ? 0
          : ((data.as_input.bitrate + data.as_output.bitrate) / 1000000 | twoDecimals)
          }}
        </td>
        <td class="details">
          {{ !data.as_input ? 0 : (data.as_input.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td class="details">
          {{ !data.as_output ? 0 : (data.as_output.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td>
          {{
          !data.as_input || !data.as_output
          ? 0
          : data.as_input.frame_rate + data.as_output.frame_rate
          }}
        </td>
        <td class="details">{{ !data.as_input ? 0 : data.as_input.frame_rate }}</td>
        <td class="details">{{ !data.as_output ? 0 : data.as_output.frame_rate }}</td>
        <td>
          {{ !data.as_input || !data.as_output ? 0 : data.as_input.jitter + data.as_output.jitter }}
        </td>
        <td class="details">{{ !data.as_input ? 0 : data.as_input.jitter }}</td>
        <td class="details">{{ !data.as_output ? 0 : data.as_output.jitter }}</td>
        <td>
          {{
          !data.as_input || !data.as_output ? 0 : data.as_input.avg_loss + data.as_output.avg_loss
          }}
        </td>
        <td class="details">{{ !data.as_input ? 0 : data.as_input.avg_loss }}</td>
        <td class="details">{{ !data.as_output ? 0 : data.as_output.avg_loss }}</td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
</div>

<div class="wrap" *ngIf="data.section == 'framerate'">
  <table class="head">
    <tr style="font-weight: bold;">
      <td style="width: 10%;">Time Stamp</td>
      <td style="width: 10%;">Frame Rate</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 10%;">Jitter</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 10%;">Packet Loss</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 10%;">Live Mbps</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 5%;">People Count</td>
      <td style="width: 10%;">Incidents Id</td>
    </tr>
  </table>
  <div class="inner_table">
    <table *ngFor="let data of cloudData" style="border-bottom-style: solid; border-bottom-width: 1px;"
      [style.color]="data.zoom_incident_id ? 'red' : 'black'">
      <tr>
        <td style="width: 10%;">{{ data.timestamp }}</td>
        <td style="width: 10%;">
          {{
          !data.video_input || !data.video_output || !data.as_input || !data.as_output
          ? 0
          : data.video_input.frame_rate +
          data.as_input.frame_rate +
          data.video_output.frame_rate +
          data.as_output.frame_rate
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_input || !data.as_input
          ? 0
          : data.video_input.frame_rate + data.as_input.frame_rate
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_output || !data.as_output
          ? 0
          : data.video_output.frame_rate + data.as_output.frame_rate
          }}
        </td>
        <td style="width: 10%;">{{ data.total_jitter }}</td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_input || !data.as_input || !data.audio_input
          ? 0
          : data.video_input.jitter + data.audio_input.jitter + data.as_input.jitter
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_output || !data.as_output || !data.audio_output
          ? 0
          : data.video_output.jitter + data.audio_output.jitter + data.as_output.jitter
          }}
        </td>
        <td style="width: 10%;">{{ data.total_packetloss }}</td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_input || !data.as_input || !data.audio_input
          ? 0
          : data.video_input.avg_loss + data.audio_input.avg_loss + data.as_input.avg_loss
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.audio_output || !data.video_output || !data.as_output
          ? 0
          : data.video_output.avg_loss + data.audio_output.avg_loss + data.as_output.avg_loss
          }}
        </td>
        <td style="width: 10%;">{{ data.total_mbps }}</td>
        <td style="width: 5%;" class="details">
          {{
          !data.audio_input || !data.video_input || !data.as_input
          ? 0
          : ((data.audio_input.bitrate + data.as_input.bitrate + data.video_input.bitrate) /
          1000000 | twoDecimals)
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.audio_output || !data.video_output || !data.as_output
          ? 0
          : ((data.audio_output.bitrate + data.as_output.bitrate + data.video_output.bitrate) /
          1000000 | twoDecimals)
          }}
        </td>
        <td style="width: 5%;">{{ data.people_count }}</td>
        <td style="width: 10%;">{{ data.zoom_incident_id }}</td>
      </tr>
      <tr>
        <td>Video</td>
        <td>
          {{
          !data.video_input || !data.video_output
          ? 0
          : data.video_input.frame_rate + data.video_output.frame_rate
          }}
        </td>
        <td class="details">{{ !data.video_input ? 0 : data.video_input.frame_rate }}</td>
        <td class="details">{{ !data.video_output ? 0 : data.video_output.frame_rate }}</td>
        <td>
          {{
          !data.video_input || !data.video_output
          ? 0
          : data.video_input.jitter + data.video_output.jitter
          }}
        </td>
        <td class="details">{{ !data.video_input ? 0 : data.video_input.jitter }}</td>
        <td class="details">{{ !data.video_output ? 0 : data.video_output.jitter }}</td>
        <td>{{ !data.video_input ? 0 : data.video_input.avg_loss + data.video_input.avg_loss }}</td>
        <td class="details">{{ !data.video_input ? 0 : data.video_input.avg_loss }}</td>
        <td class="details">{{ !data.video_output ? 0 : data.video_output.avg_loss }}</td>
        <td>
          {{
          !data.video_input || !data.video_output
          ? 0
          : ((data.video_input.bitrate + data.video_output.bitrate) / 1000000 | twoDecimals)
          }}
        </td>
        <td class="details">
          {{ !data.video_input ? 0 : (data.video_input.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td class="details">
          {{ !data.video_output ? 0 : (data.video_output.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Audio</td>
        <td>0</td>
        <td class="details">0</td>
        <td class="details">0</td>
        <td>
          {{
          !data.audio_input || !data.audio_output
          ? 0
          : data.audio_input.jitter + data.audio_output.jitter
          }}
        </td>
        <td class="details">{{ !data.audio_input ? 0 : data.audio_input.jitter }}</td>
        <td class="details">{{ !data.audio_output ? 0 : data.audio_output.jitter }}</td>
        <td>
          {{
          !data.audio_input || !data.audio_output
          ? 0
          : data.audio_input.avg_loss + data.audio_output.avg_loss
          }}
        </td>
        <td class="details">{{ !data.audio_input ? 0 : data.audio_input.avg_loss }}</td>
        <td class="details">{{ !data.audio_output ? 0 : data.audio_output.avg_loss }}</td>
        <td>
          {{
          !data.audio_input || !data.audio_output
          ? 0
          : ((data.audio_input.bitrate + data.audio_output.bitrate) / 1000000 | twoDecimals)
          }}
        </td>
        <td class="details">
          {{ !data.audio_input ? 0 : (data.audio_input.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td class="details">
          {{ !data.audio_output ? 0 : (data.audio_output.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Content</td>
        <td>
          {{
          !data.as_input || !data.as_output
          ? 0
          : data.as_input.frame_rate + data.as_output.frame_rate
          }}
        </td>
        <td class="details">{{ !data.as_input ? 0 : data.as_input.frame_rate }}</td>
        <td class="details">{{ !data.as_output ? 0 : data.as_output.frame_rate }}</td>
        <td>
          {{ !data.as_input || !data.as_output ? 0 : data.as_input.jitter + data.as_output.jitter }}
        </td>
        <td class="details">{{ !data.as_input ? 0 : data.as_input.jitter }}</td>
        <td class="details">{{ !data.as_output ? 0 : data.as_output.jitter }}</td>
        <td>
          {{
          !data.as_input || !data.as_output ? 0 : data.as_input.avg_loss + data.as_output.avg_loss
          }}
        </td>
        <td class="details">{{ !data.as_input ? 0 : data.as_input.avg_loss }}</td>
        <td class="details">{{ !data.as_output ? 0 : data.as_output.avg_loss }}</td>
        <td>
          {{
          !data.as_input || !data.as_output
          ? 0
          : ((data.as_input.bitrate + data.as_output.bitrate) / 1000000 | twoDecimals)
          }}
        </td>
        <td class="details">
          {{ !data.as_input ? 0 : (data.as_input.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td class="details">
          {{ !data.as_output ? 0 : (data.as_output.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
</div>

<div class="wrap" *ngIf="data.section == 'jitter'">
  <table class="head">
    <tr style="font-weight: bold;">
      <td style="width: 10%;">Time Stamp</td>
      <td style="width: 10%;">Jitter</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 10%;">Packet Loss</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 10%;">Live Mbps</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 10%;">Frame Rate</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 5%;">People Count</td>
      <td style="width: 10%;">Incidents Id</td>
    </tr>
  </table>
  <div class="inner_table">
    <table *ngFor="let data of cloudData" style="border-bottom-style: solid; border-bottom-width: 1px;"
      [style.color]="data.zoom_incident_id ? 'red' : 'black'">
      <tr>
        <td style="width: 10%;">{{ data.timestamp }}</td>
        <td style="width: 10%;">{{ data.total_jitter }}</td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_input || !data.as_input || !data.audio_input
          ? 0
          : data.video_input.jitter + data.audio_input.jitter + data.as_input.jitter
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_output || !data.as_output || !data.audio_output
          ? 0
          : data.video_output.jitter + data.audio_output.jitter + data.as_output.jitter
          }}
        </td>
        <td style="width: 10%;">{{ data.total_packetloss }}</td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_input || !data.as_input || !data.audio_input
          ? 0
          : data.video_input.avg_loss + data.audio_input.avg_loss + data.as_input.avg_loss
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.audio_output || !data.video_output || !data.as_output
          ? 0
          : data.video_output.avg_loss + data.audio_output.avg_loss + data.as_output.avg_loss
          }}
        </td>
        <td style="width: 10%;">{{ data.total_mbps }}</td>
        <td style="width: 5%;" class="details">
          {{
          !data.audio_input || !data.video_input || !data.as_input
          ? 0
          : ((data.audio_input.bitrate + data.as_input.bitrate + data.video_input.bitrate) /
          1000000 | twoDecimals)
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.audio_output || !data.video_output || !data.as_output
          ? 0
          : ((data.audio_output.bitrate + data.as_output.bitrate + data.video_output.bitrate) /
          1000000 | twoDecimals)
          }}
        </td>
        <td style="width: 10%;">
          {{
          !data.video_input || !data.video_output || !data.as_input || !data.as_output
          ? 0
          : data.video_input.frame_rate +
          data.as_input.frame_rate +
          data.video_output.frame_rate +
          data.as_output.frame_rate
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_input || !data.as_input
          ? 0
          : data.video_input.frame_rate + data.as_input.frame_rate
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_output || !data.as_output
          ? 0
          : data.video_output.frame_rate + data.as_output.frame_rate
          }}
        </td>
        <td style="width: 5%;">{{ data.people_count }}</td>
        <td style="width: 10%;">{{ data.zoom_incident_id }}</td>
      </tr>
      <tr>
        <td>Video</td>
        <td>
          {{
          !data.video_input || !data.video_output
          ? 0
          : data.video_input.jitter + data.video_output.jitter
          }}
        </td>
        <td class="details">{{ !data.video_input ? 0 : data.video_input.jitter }}</td>
        <td class="details">{{ !data.video_output ? 0 : data.video_output.jitter }}</td>
        <td>{{ !data.video_input ? 0 : data.video_input.avg_loss + data.video_input.avg_loss }}</td>
        <td class="details">{{ !data.video_input ? 0 : data.video_input.avg_loss }}</td>
        <td class="details">{{ !data.video_output ? 0 : data.video_output.avg_loss }}</td>
        <td>0</td>
        <td class="details">
          {{ !data.video_input ? 0 : (data.video_input.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td class="details">
          {{ !data.video_output ? 0 : (data.video_output.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td>
          {{
          !data.video_input || !data.video_output
          ? 0
          : data.video_input.frame_rate + data.video_output.frame_rate
          }}
        </td>
        <td class="details">{{ !data.video_input ? 0 : data.video_input.frame_rate }}</td>
        <td class="details">{{ !data.video_output ? 0 : data.video_output.frame_rate }}</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Audio</td>
        <td>
          {{
          !data.audio_input || !data.audio_output
          ? 0
          : data.audio_input.jitter + data.audio_output.jitter
          }}
        </td>
        <td class="details">{{ !data.audio_input ? 0 : data.audio_input.jitter }}</td>
        <td class="details">{{ !data.audio_output ? 0 : data.audio_output.jitter }}</td>
        <td>
          {{
          !data.audio_input || !data.audio_output
          ? 0
          : data.audio_input.avg_loss + data.audio_output.avg_loss
          }}
        </td>
        <td class="details">{{ !data.audio_input ? 0 : data.audio_input.avg_loss }}</td>
        <td class="details">{{ !data.audio_output ? 0 : data.audio_output.avg_loss }}</td>
        <td>
          {{
          !data.audio_input || !data.audio_output
          ? 0
          : ((data.audio_input.bitrate + data.audio_output.bitrate) / 1000000 | twoDecimals)
          }}
        </td>
        <td class="details">
          {{ !data.audio_input ? 0 : (data.audio_input.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td class="details">
          {{ !data.audio_output ? 0 : (data.audio_output.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td>0</td>
        <td class="details">0</td>
        <td class="details">0</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Content</td>
        <td>
          {{ !data.as_input || !data.as_output ? 0 : data.as_input.jitter + data.as_output.jitter }}
        </td>
        <td class="details">{{ !data.as_input ? 0 : data.as_input.jitter }}</td>
        <td class="details">{{ !data.as_output ? 0 : data.as_output.jitter }}</td>
        <td>
          {{
          !data.as_input || !data.as_output ? 0 : data.as_input.avg_loss + data.as_output.avg_loss
          }}
        </td>
        <td class="details">{{ !data.as_input ? 0 : data.as_input.avg_loss }}</td>
        <td class="details">{{ !data.as_output ? 0 : data.as_output.avg_loss }}</td>
        <td>
          {{
          !data.as_input || !data.as_output
          ? 0
          : ((data.as_input.bitrate + data.as_output.bitrate) / 1000000 | twoDecimals)
          }}
        </td>
        <td class="details">
          {{ !data.as_input ? 0 : (data.as_input.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td class="details">
          {{ !data.as_output ? 0 : (data.as_output.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td>
          {{
          !data.as_input || !data.as_output
          ? 0
          : data.as_input.frame_rate + data.as_output.frame_rate
          }}
        </td>
        <td class="details">{{ !data.as_input ? 0 : data.as_input.frame_rate }}</td>
        <td class="details">{{ !data.as_output ? 0 : data.as_output.frame_rate }}</td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
</div>

<div class="wrap" *ngIf="data.section == 'packetloss'">
  <table class="head">
    <tr style="font-weight: bold;">
      <td style="width: 10%;">Time Stamp</td>
      <td style="width: 10%;">Packet Loss</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 10%;">Live Mbps</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 10%;">Frame Rate</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 10%;">Jitter</td>
      <td style="width: 5%;" class="details">Rx</td>
      <td style="width: 5%;" class="details">Tx</td>
      <td style="width: 5%;">People Count</td>
      <td style="width: 10%;">Incidents Id</td>
    </tr>
  </table>
  <div class="inner_table">
    <table *ngFor="let data of cloudData" style="border-bottom-style: solid; border-bottom-width: 1px;"
      [style.color]="data.zoom_incident_id ? 'red' : 'black'">
      <tr>
        <td style="width: 10%;">{{ data.timestamp }}</td>
        <td style="width: 10%;">{{ data.total_packetloss }}</td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_input || !data.as_input || !data.audio_input
          ? 0
          : data.video_input.avg_loss + data.audio_input.avg_loss + data.as_input.avg_loss
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.audio_output || !data.video_output || !data.as_output
          ? 0
          : data.video_output.avg_loss + data.audio_output.avg_loss + data.as_output.avg_loss
          }}
        </td>
        <td style="width: 10%;">{{ data.total_mbps }}</td>
        <td style="width: 5%;" class="details">
          {{
          !data.audio_input || !data.video_input || !data.as_input
          ? 0
          : ((data.audio_input.bitrate + data.as_input.bitrate + data.video_input.bitrate) /
          1000000 | twoDecimals)
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.audio_output || !data.video_output || !data.as_output
          ? 0
          : ((data.audio_output.bitrate + data.as_output.bitrate + data.video_output.bitrate) /
          1000000 | twoDecimals)
          }}
        </td>
        <td style="width: 10%;">
          {{
          !data.video_input || !data.video_output || !data.as_input || !data.as_output
          ? 0
          : data.video_input.frame_rate +
          data.as_input.frame_rate +
          data.video_output.frame_rate +
          data.as_output.frame_rate
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_input || !data.as_input
          ? 0
          : data.video_input.frame_rate + data.as_input.frame_rate
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_output || !data.as_output
          ? 0
          : data.video_output.frame_rate + data.as_output.frame_rate
          }}
        </td>
        <td style="width: 10%;">{{ data.total_jitter }}</td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_input || !data.as_input || !data.audio_input
          ? 0
          : data.video_input.jitter + data.audio_input.jitter + data.as_input.jitter
          }}
        </td>
        <td style="width: 5%;" class="details">
          {{
          !data.video_output || !data.as_output || !data.audio_output
          ? 0
          : data.video_output.jitter + data.audio_output.jitter + data.as_output.jitter
          }}
        </td>
        <td style="width: 5%;">{{ data.people_count }}</td>
        <td style="width: 10%;">{{ data.zoom_incident_id }}</td>
      </tr>
      <tr>
        <td>Video</td>
        <td>{{ !data.video_input ? 0 : data.video_input.avg_loss + data.video_input.avg_loss }}</td>
        <td class="details">{{ !data.video_input ? 0 : data.video_input.avg_loss }}</td>
        <td class="details">{{ !data.video_output ? 0 : data.video_output.avg_loss }}</td>
        <td>
          {{
          !data.video_input || !data.video_output
          ? 0
          : ((data.video_input.bitrate + data.video_output.bitrate) / 1000000 | twoDecimals)
          }}
        </td>
        <td class="details">
          {{ !data.video_input ? 0 : (data.video_input.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td class="details">
          {{ !data.video_output ? 0 : (data.video_output.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td>
          {{
          !data.video_input || !data.video_output
          ? 0
          : data.video_input.frame_rate + data.video_output.frame_rate
          }}
        </td>
        <td class="details">{{ !data.video_input ? 0 : data.video_input.frame_rate }}</td>
        <td class="details">{{ !data.video_output ? 0 : data.video_output.frame_rate }}</td>
        <td>
          {{
          !data.video_input || !data.video_output
          ? 0
          : data.video_input.jitter + data.video_output.jitter
          }}
        </td>
        <td class="details">{{ !data.video_input ? 0 : data.video_input.jitter }}</td>
        <td class="details">{{ !data.video_output ? 0 : data.video_output.jitter }}</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Audio</td>
        <td>
          {{
          !data.audio_input || !data.audio_output
          ? 0
          : data.audio_input.avg_loss + data.audio_output.avg_loss
          }}
        </td>
        <td class="details">{{ !data.audio_input ? 0 : data.audio_input.avg_loss }}</td>
        <td class="details">{{ !data.audio_output ? 0 : data.audio_output.avg_loss }}</td>
        <td>
          {{
          !data.audio_input || !data.audio_output
          ? 0
          : ((data.audio_input.bitrate + data.audio_output.bitrate) / 1000000 | twoDecimals)
          }}
        </td>
        <td class="details">
          {{ !data.audio_input ? 0 : (data.audio_input.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td class="details">
          {{ !data.audio_output ? 0 : (data.audio_output.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td>0</td>
        <td class="details">0</td>
        <td class="details">0</td>
        <td>
          {{
          !data.audio_input || !data.audio_output
          ? 0
          : data.audio_input.jitter + data.audio_output.jitter
          }}
        </td>
        <td class="details">{{ !data.audio_input ? 0 : data.audio_input.jitter }}</td>
        <td class="details">{{ !data.audio_output ? 0 : data.audio_output.jitter }}</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Content</td>
        <td>
          {{
          !data.as_input || !data.as_output ? 0 : data.as_input.avg_loss + data.as_output.avg_loss
          }}
        </td>
        <td class="details">{{ !data.as_input ? 0 : data.as_input.avg_loss }}</td>
        <td class="details">{{ !data.as_output ? 0 : data.as_output.avg_loss }}</td>
        <td>
          {{
          !data.as_input || !data.as_output
          ? 0
          : ((data.as_input.bitrate + data.as_output.bitrate) / 1000000 | twoDecimals)
          }}
        </td>
        <td class="details">
          {{ !data.as_input ? 0 : (data.as_input.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td class="details">
          {{ !data.as_output ? 0 : (data.as_output.bitrate / 1000000 | twoDecimals) }}
        </td>
        <td>
          {{
          !data.as_input || !data.as_output
          ? 0
          : data.as_input.frame_rate + data.as_output.frame_rate
          }}
        </td>
        <td class="details">{{ !data.as_input ? 0 : data.as_input.frame_rate }}</td>
        <td class="details">{{ !data.as_output ? 0 : data.as_output.frame_rate }}</td>
        <td>
          {{ !data.as_input || !data.as_output ? 0 : data.as_input.jitter + data.as_output.jitter }}
        </td>
        <td class="details">{{ !data.as_input ? 0 : data.as_input.jitter }}</td>
        <td class="details">{{ !data.as_output ? 0 : data.as_output.jitter }}</td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
  <div></div>
  <div style="
      position: absolute;
      top: 50%;
      width: 100%;
      display: inline-block !important;
      text-align: center !important;
    " *ngIf="loading">
    <div class="loader-icon">
      <img [src]="loader" />
    </div>
  </div>
  <div style="position: absolute; top: 0px; width: 100%;" *ngIf="loading">
    <div class="loader-icon">
      <img [src]="loader" />
    </div>
  </div>
</div>