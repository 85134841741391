import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-btn-call-duration',
  templateUrl: './btn-call-duration.component.html',
  styleUrls: ['./btn-call-duration.component.scss']
})
export class BtnCallDurationComponent implements OnChanges {
  @Input() count: number;
  @Input() label: string = "Not-Active";
  @Input() color: string;
  @Input() backcolorbtn: any;
  @Input() isbutton: string;
  @Input() active:boolean;
  @Input() activeN: any;
  @Input() activeBtn:number;

  prevcount: string;

  constructor() { }

  activeSelection = 0;
  setActive(n){
    if (this.activeBtn == n) {
      this.activeBtn = 0;
    } else {
      this.activeBtn = n;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.count.currentValue != changes.count.previousValue ) {
        // // console.log(changes.count.currentValue + ", pre" + changes.count.previousValue)
        // 1 second delay to display the first set of values
        // setTimeout(() => {
          this.backcolorbtn = "btnbacklite"
          // // console.log(this.backcolorbtn + "backcolorbtn")
        //   }, 100);
        setTimeout(() => {
          this.backcolorbtn = "btnbacklite-default"
          // // console.log(this.backcolorbtn + "backcolorbtn")
          }, 2500);
    } else{
      setTimeout(() => {
        this.backcolorbtn = "btnbacklite-default"
        // // console.log(this.backcolorbtn + "backcolorbtn")
        }, 1000);
    }
  }

  covertTime(seconds){
      seconds = Number(seconds);
      let h = Math.floor(seconds / 3600);
      let m = Math.floor(seconds % 3600 / 60);
      let s = Math.floor(seconds % 3600 % 60);
  
      let hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
      let mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
      let sDisplay = s > 0 ? s + (s == 1 ? " sec " : " secs ") : "";
      return hDisplay + mDisplay + sDisplay; 
  }
}
