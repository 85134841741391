import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collector-document-center',
  templateUrl: './collector-document-center.component.html',
  styleUrls: ['./collector-document-center.component.css']
})
export class CollectorDocumentCenterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
