import { Component, OnInit, Input } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-video-site-info-panel',
  templateUrl: './video-site-info-panel.component.html',
  styleUrls: ['./video-site-info-panel.component.scss']
})
export class VideoSiteInfoPanelComponent implements OnInit {

  @Input() videoSiteInfo: any;
  constructor() { }

  accessLevel:any;
  ngOnInit() {

    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.accessLevel = decodedToken.accesslevel;

  }

}
