<span *ngIf="renderValue && renderValue !== 0; else noData">
  <span *ngIf="renderValue == 1" class="table-health-triangle">
    <img src="../../../assets/img/triangle_green.png" />
  </span>
  <span *ngIf="renderValue == -1" class="table-health-triangle">
    <img src="../../../assets/img/triangle_red.png" />
  </span>
</span>

<ng-template #noData>
  <p>--</p>
</ng-template>
