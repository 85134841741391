import { Component, OnInit, Input } from '@angular/core';
import { faPhone } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-table-oncall-icon',
  templateUrl: './table-oncall-icon.component.html',
  styleUrls: ['./table-oncall-icon.component.scss']
})
export class TableOnCallIconComponent implements OnInit {

  renderValue: any;
  
  @Input() value: string | number;
  @Input() rowData: any;
  faIcon: any = faPhone;
  constructor() { }

  ngOnInit() {
    if (this.value) {
      this.renderValue = this.value.toString().toUpperCase();
    }
  }

}
