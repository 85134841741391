import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class GetTeamsService {
  getUrlPath: Function = environment.apiServicePathBuilder('teams');
  server_url: string = environment.serverUrl;

  // getUrlPath = (params) => `http://127.0.0.1:3000/teams/dev/${params}` ;
  // getUrlPath: Function = environment.apiServicePathBuilder('dev');
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) { }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throwError(error);
  }

  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const u = helper.decodeToken(token);

    const { company_id } = helper.decodeToken(token);
    return company_id;
  };

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getConfig() {
    const company_id = this.getCompanyId();
    const url: string = this.getUrlPath(`config/${company_id}`);
    return this.http.get<any>(url).pipe(map(this.extractData))
  }

  authorizeTeams() {
    const url: string = this.getUrlPath(`authorize`);
    return this.http.get<any>(url).pipe(map(this.extractData))
  }

  getUserList(postData) {
    const url: string = this.getUrlPath('users');
    const company_id = this.getCompanyId();

    const data = {
      company_id,
      ...postData
    };


    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  getUserDetails(zoom_user_id) {
    const company_id = this.getCompanyId();
    const url: string = this.getUrlPath(`users/${zoom_user_id}`);
    return this.http.get<any>(url).pipe(map(this.extractData))
  }

  getEnableMonitoringUrl() {
    const company_id = this.getCompanyId();
    const url: string = this.getUrlPath(`presenceConfig/${company_id}`);
    return this.http.get<any>(url).pipe(map(this.extractData))
  }

  GetIsTeamsActivated() {
    const company_id = this.getCompanyId();
    const url: string = this.getUrlPath(`isTeamsActivated/${company_id}`);
    return this.http.get<any>(url).pipe(map(this.extractData))
  }

  syncTeamsWebhooks() {
    const company_id = this.getCompanyId();
    const url: string = this.getUrlPath(`subscription/${company_id}`);
    return this.http.post<any>(url, this.httpOptions).pipe(map(this.extractData))
  }

  getMonitoringDetails() {
    const url: string = this.getUrlPath('getMonitoringDetails');
    const company_id = this.getCompanyId();

    const data = {
      company_id,
    };

    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  updateTenantId(tenant_id) {
    const url: string = this.getUrlPath('config');
    const company_id = this.getCompanyId();

    const data = {
      tenant_id,
      company_id,
    };

    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  getTeamsMeetings(teams_user_id) {
    const url: string = this.getUrlPath('getTeamsMeetings');
    // const company_id = this.getCompanyId();

    const data = {
      teams_user_id,
    };

    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  getTeamMeetingDetails(id) {
    const url: string = this.getUrlPath('getTeamMeetingDetails');

    const data = {
      id,
    };

    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  getUserTeamsMbps(user_id, duration) {
    const url: string = this.getUrlPath('getUserTeamsMbps');

    const data = {
      user_id,
      duration
    };

    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  activateAlert(id, status) {
    const url: string = this.getUrlPath('activateAlert');

    const data = {
      id,
      status
    };

    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  getLiveDuration = (id) => {
    const url: string = this.getUrlPath('getLiveDuration');
    const data = {
      id
    };

    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  getLastSync = () => {
    const company_id = this.getCompanyId();
    const url: string = this.getUrlPath('getLastSync');
    const data = {
      company_id
    };

    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  getTeamsSpaceStatus = () => {
    const company_id = this.getCompanyId();
    const url: string = this.getUrlPath('getTeamSpaceStatus');
    const data = {
      company_id
    };

    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  insertTeamsRoom(emailAddress, displayName) {
    const url: string = this.getUrlPath('insertTeamsRoom');
    const company_id = this.getCompanyId();

    const data = {
      company_id,
      emailAddress,
      displayName,
    };

    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  insertTeamsUserManual(firstname, lastname, emailAddress) {
    const url: string = this.getUrlPath('insertTeamsUser');
    const company_id = this.getCompanyId();

    const data = {
      company_id,
      firstname,
      lastname,
      emailAddress,
    };

    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  setTeamsMonitoring = (id, flag) => {
    const data = {
      id: id,
      flag: flag
    };
    const url: string = this.getUrlPath('setTeamsRoomMonitoring');
    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  };

  deleteTeamsRoom = (zoom_user_id) => {
    const data = {
      zoom_user_id,
    };
    const url: string = this.getUrlPath('deleteTeamsRoom');
    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  };

  getMeetingRecords(deviceId: string) {
    return this.http
      .get<any>(this.server_url + '/api/v1/teams/team-calls/' + deviceId, this.httpOptions)
      .pipe(map(this.extractData))
  }

}
