<app-popup-container style="left: 0px; position: absolute;"></app-popup-container>

<div class="container">
  <!-- main menu rounded buttons -->
  <div class="row">
    <div class="col-1">
      <app-btn-rounded typebtn="{{ typebtnClose }}"></app-btn-rounded>
    </div>
    <div class="col-1">
      <app-btn-rounded typebtn="{{ typebtnSearch }}"></app-btn-rounded>
    </div>
    <div class="col-1">
      <app-btn-rounded typebtn="{{ typebtnPdf }}"></app-btn-rounded>
    </div>
    <div class="col-1">
      <app-btn-rounded typebtn="{{ typebtnEmail }}"></app-btn-rounded>
    </div>
    <div class="col-2">
      <button (click)="colorChange()" class="btn-sm">Start timer</button>
    </div>
    <div class="col-2">
      <button (click)="randomNumber()" class="btn-sm">random</button>
    </div>
    <div class="col-2">
      <button (click)="randomNumber()" (click)="sendPopupType(randomPop)" class="btn-sm">
        random popups
      </button>
    </div>
  </div>
  <!-- hr line space -->
  <div class="row">
    <div class="col-12">
      <hr />
    </div>
  </div>
  <!-- stats buttons -->
  <div class="row">
    <div class="col-3 pr-0">
      <app-btn-reg-stats
        [class.active]="activeBtn == 1"
        (click)="setActive(1)"
        [backcolorbtn]="backcolorbtn"
        [count]="audioImpactUsersCount"
        label="{{ audioImpactUsersLabel }}"
        color="{{ audioImpactUsersColor }}"
        (click)="sendPopupType(2)"
      ></app-btn-reg-stats>
    </div>
    <div class="col-3 px-1">
      <app-btn-reg-stats
        [count]="cloudRoomLiveMbpsCount"
        label="{{ cloudRoomLiveMbpsLabel }}"
        color="{{ cloudRoomLiveMbpsColor }}"
      ></app-btn-reg-stats>
    </div>
    <div class="col-3 px-1">
      <app-btn-reg-stats
        [count]="cloudRoomJitterCount"
        label="{{ cloudRoomJitterLabel }}"
        color="{{ cloudRoomJitterColor }}"
      ></app-btn-reg-stats>
    </div>
    <div class="col-3 pl-0">
      <app-btn-reg-stats
        [count]="cloudRoomPacketLossCount"
        label="{{ cloudRoomPacketLossLabel }}"
        color="{{ cloudRoomPacketLossColor }}"
      ></app-btn-reg-stats>
    </div>
  </div>
  <!-- hr line space -->
  <div class="row">
    <div class="col-12">
      <hr />
    </div>
  </div>
  <!-- schdule meeting button -->
  <div class="row">
    <div class="col-12">
      <app-btn-long-meetings
        [count]="LongMeetingCount"
        (click)="sendPopupType(1)"
      ></app-btn-long-meetings>
    </div>
  </div>
  <!-- hr line space -->
  <div class="row">
    <div class="col-12">
      <hr />
    </div>
  </div>
  <!-- call stats, bluetooth and total talk -->
  <div class="row" style="height: 150px;">
    <div class="col-4">
      <app-info-uptime
        [uptimeDays]="uptimeDays"
        [uptimeHours]="uptimeHours"
        [uptimeMins]="uptimeMins"
      ></app-info-uptime>
    </div>
    <div class="col-4">
      <app-btn-bluetooth [bluetooth]="bluetoothCount"></app-btn-bluetooth>
    </div>
    <div class="col-4">
      <app-btn-total-talk></app-btn-total-talk>
    </div>
  </div>
  <!-- hr line space -->
  <div class="row">
    <div class="col-12">
      <hr />
    </div>
  </div>
  <!-- syst health and temperture -->
  <div class="row">
    <div class="col-4">
      <app-system-health [healthNum]="sysHealthValue"></app-system-health>
    </div>
    <div class="col-4">
      <app-system-temp manufacturer="cisco" [temperature]="tempValue"></app-system-temp>
    </div>
    <div class="col-4">
      <app-system-temp manufacturer="plantronics" [temperature]="tempValue"></app-system-temp>
    </div>
  </div>
  <!-- device -->
  <div class="row">
    <div class="col-6">
      <app-btn-device-health
        [count]="btnDeviceHealth"
        [mic_impacted]="micImpacted"
        [mic_connected]="micConnected"
        label="total"
      ></app-btn-device-health>
    </div>
    <div class="col-6">
      <app-btn-stat-call [count]="statCallCount" label="Updates"></app-btn-stat-call>
    </div>
  </div>
  <!-- hr line space -->
  <div class="row">
    <div class="col-12">
      <hr />
    </div>
  </div>
  <!-- utilisation stat buttons -->
  <div class="row">
    <div class="col-3">
      <app-btn-utilization [count]="utilizationAdhocCount" label="Adhoc"></app-btn-utilization>
    </div>
    <div class="col-5">
      <app-btn-utilization [count]="utilizationScheduled" label="Scheduled"></app-btn-utilization>
    </div>
    <div class="col-4">
      <app-btn-utilization
        [count]="utilizationTotalUsage"
        label="Total Usage (d/h/m)"
      ></app-btn-utilization>
    </div>
  </div>
  <!-- hr line space -->
  <div class="row">
    <div class="col-12">
      <hr />
    </div>
  </div>
  <!-- info panel buttons -->
  <div class="row">
    <div class="col-2">
      <app-btn-info label="Edit Profile" [faName]="faCameraIcon"></app-btn-info>
    </div>
    <div class="col-2">
      <app-btn-info label="Reboot" [faName]="faUserIcon"></app-btn-info>
    </div>
    <div class="col-2">
      <app-btn-info label="Pause" [faName]="faBlankIcon"></app-btn-info>
    </div>
    <div class="col-2">
      <app-info-status [audioDeviceInfo]="audioDeviceCount"></app-info-status>
    </div>
    <div class="col-2"></div>
    <div class="col-2"></div>
  </div>
  <!-- hr line space -->
  <div class="row">
    <div class="col-12">
      <hr />
    </div>
  </div>
  <!-- qos and firmware button -->
  <div class="row">
    <div class="col-2">
      <app-btn-audio-firmware-users
        [count]="audioFirmwareUsers"
        label="Updates"
      ></app-btn-audio-firmware-users>
    </div>
    <div class="vl"></div>
    <div class="col-3">
      <app-btn-audio-firmware-devices
        [count]="audioFirmwareDevices1"
        label="Updates"
        device="jabra"
      ></app-btn-audio-firmware-devices>
    </div>
    <div class="col-3">
      <app-btn-audio-firmware-devices
        [count]="audioFirmwareDevices2"
        label="Updates"
        device="plan"
      ></app-btn-audio-firmware-devices>
    </div>
    <div class="col-3">
      <app-btn-qos (click)="sendPopupType(3)"></app-btn-qos>
    </div>
  </div>
  <!-- hr line space -->
  <div class="row">
    <div class="col-12">
      <hr />
    </div>
  </div>
  <!-- audio button live stats -->
  <div class="row">
    <div class="col-6">
      <app-btn-audio-live-stats
        [mute]="audiomuteValue"
        [name]="audioName"
        [activeDevice]="activeDeviceV"
      ></app-btn-audio-live-stats>
    </div>
    <div class="col-6">
      <app-chart-audio-twentyfourhours></app-chart-audio-twentyfourhours>
    </div>
  </div>
</div>
