<button type="button" class="not-button btn-long d-flex justify-content-center py-2">
  <div class="row">
    <div
      class="col-4"
      [ngClass]="{
        'icon-microphone-clickable': iconClickable,
        'icon-microphone-not-clickable': !iconClickable
      }"
      (click)="muteAndUnmuteMic()"
    >
      <img
        *ngIf="deviceStatus === 1 && muteStatus === 1 && iconClickable"
        src="../../assets/img/video/icon_microphone_mute.png"
        class="video-btn-icon"
      />
      <img
        *ngIf="!iconClickable"
        src="../../assets/img/video/icon_microphone.png"
        class="video-btn-icon"
      />
      <img
        *ngIf="deviceStatus === 1 && muteStatus === 0 && iconClickable"
        src="../../assets/img/video/icon_microphone_oncall.png"
        class="video-btn-icon"
      />
    </div>
    <div class="col-4 vid-icon-text justify-content-center">
      <!-- <h3 class="">{{ videoStats?.mic_total}}</h3> -->
      <h3 *ngIf="count; else noData">{{ count }}</h3>
      <p>{{ label }}</p>
    </div>
    <div class="col-4 camera-health-btn">
      <div class="row justify-content-center">
        <h5 *ngIf="mic_impacted; else noData" class="fraction-text">
          {{ mic_impacted }}
          <span
            ><img class="fraction-triangle" src="../../assets/img/video/triangle_red.png" alt=""
          /></span>
        </h5>
      </div>
      <hr class="device-hr" />
      <div class="row justify-content-center">
        <h5 *ngIf="mic_connected; else noData" class="fraction-text">
          {{ mic_connected }}
          <span
            ><img class="fraction-triangle" src="../../assets/img/video/triangle_green.png" alt=""
          /></span>
        </h5>
      </div>
    </div>
  </div>
</button>

<ng-template #noData>
  <h3>--</h3>
</ng-template>
