import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { faCog, faPause, faPlay, faUser } from '@fortawesome/free-solid-svg-icons'

import { GetIotService } from '../../services/get-iot.service'
import { PopupTypeService } from '../../services/popupType.service'
// tslint:disable-next-line: max-line-length
import { WebportalProtocolConfigDialogComponent } from '../dialogs/webportal-protocol-config-dialog/webportal-protocol-config-dialog.component'
import { RefreshApiService } from 'src/app/services/refresh-api.service'

@Component({
  selector: 'app-iot-device-info',
  templateUrl: './iot-device-info.component.html',
  styleUrls: ['./iot-device-info.component.scss'],
})
export class IotDeviceInfoComponent implements OnInit, OnChanges {
  @Input() iotDeviceDetails: any;
  @Output() eventEmitter: EventEmitter<any> = new EventEmitter<any>();
  make: String = '--';
  agentName: String = '--';
  spaces:any[] = [];
  displayName: String = '--';
  status: String = '';
  model: String = '--';
  ip: String = '--';
  mac: String = '--';
  timezone: String = '--';
  paused: any = '--';
  uptimeDays: any = '--';
  uptimeHours: any = '--';
  uptimeMins: any = '--';
  linkDevice: any;
  faPauseIcon = faPause;
  faPlayIcon = faPlay;
  iotDeviceID: any;
  statusText: String = 'Offline';
  statusColor: String = 'online-btn';
  powerManagement: any;
  agentId: number;
  accountUrl: String;
  apiKey: String;
  shouldRecallAdminAlert: Boolean = false;
  faIcon = faUser;
  faCogIcon: any = faCog;
  constructor(
    private getIot: GetIotService,
    public ds: PopupTypeService,
    public dialog: MatDialog,
    private router: Router,
    private refreshApiData: RefreshApiService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.iotDeviceDetails) {
      if (this.iotDeviceDetails) {
        // console.log('this.iotDeviceDetails info ', this.iotDeviceDetails)
        this.setData();
        this.setDisplayStatus();
      }
    }
  }

  setData = () => {
    const {
      make,
      agent_name,
      display_name,
      model,
      ip_address,
      mac_address,
      uptime,
      timezone,
      paused,
      iot_device_id,
      status,
      power_management,
      agent_id,
      account_url,
      api_key,
      device_name,
      device_id,
      device_type,
      device_site_id,
      spaces
    } = this.iotDeviceDetails;
    this.make = make;
    this.agentName = agent_name;
    this.spaces = this.spaces?.length?JSON.parse(spaces):[];
    this.displayName = display_name;
    this.model = model;
    this.ip = ip_address;
    this.mac = mac_address;
    this.timezone = timezone;
    this.paused = paused;
    this.iotDeviceID = iot_device_id;
    this.status = status;
    this.powerManagement = power_management;
    this.agentId = agent_id;
    this.accountUrl = account_url;
    this.apiKey = api_key;
    this.linkDevice = {
      device_name,
      device_id,
      device_type,
      device_site_id,
    };
    this.calculateUpTime(uptime);
    if (this.shouldRecallAdminAlert) {
      this.sendPopupType(58);
    }
  };

  calculateUpTime = (time) => {
    const dateNow = Date.now();
    const convertedTime = new Date(time).getTime();
    const calculatedDate = dateNow - convertedTime;

    this.uptimeHours = new Date(calculatedDate).getHours();
    this.uptimeMins = new Date(calculatedDate).getMinutes();
    this.uptimeDays = new Date(calculatedDate).getDay();
  };

  setPaused = () => {
    const flag = this.setFlag(this.paused);
    this.paused = flag;
    this.getIot.setIotDevicePaused(this.iotDeviceID, flag).subscribe(
      (data) => {
        this.eventEmitter.emit('callGetIotDeviceDetails');
        this.refreshApiData.refreshStatsEvent.emit('iot')
      },
      (error) => {}
    );
  };

  setFlag = (flag) => (flag ? 0 : 1);

  setDisplayStatus = () => {
    if (this.paused === 1) {
      this.statusText = 'Paused';
      this.statusColor = 'status__paused';
    } else if (this.iotDeviceDetails?.agent_status?.toLowerCase() === 'offline' && this.paused === 0) {
		this.statusText = 'Agent Offline'
		this.statusColor = 'status__offline'
	} else if (this.status === 'ONLINE' && this.paused === 0) {
      this.statusText = 'Online';
      this.statusColor = 'status__online';
    } else if (this.status === 'OFFLINE' && this.paused === 0) {
      this.statusText = 'Offline';
      this.statusColor = 'status__offline';
    } else if (this.status === 'DOWN' && this.paused === 0) {
      this.statusText = 'Offline';
      this.statusColor = 'status__offline';
    } 
  };

  isShowReboot = () => {
    // if (this.powerManagement !== null) {
    //   return this.powerManagement.cycle;
    // }
    return false;
  };

  isShowWakeUp = () => {
    // if (this.powerManagement !== null) {
    //   return this.powerManagement.on;
    // }
    return false;
  };

  openRebootModal() {
    const data = {
      iotDeviceID: this.iotDeviceID,
      agentId: this.agentId,
      accountUrl: this.accountUrl,
      apiKey: this.apiKey,
    };
    this.ds.sendPopupType(53, {
      data,
    });
  }

  wakeDevice = () => {
    this.getIot
      .wakeIotDevice(this.iotDeviceID, this.agentId, this.accountUrl, this.apiKey)
      .subscribe((data) => {});
  };

  openWebPortalOptions = () => {
    const webportal = this.dialog.open(WebportalProtocolConfigDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        iotId: this.iotDeviceID,
      },
    });
  };

  sendPopupType(p) {
    if (p === 58) {
      this.ds.sendPopupType(p, {
        data: this.iotDeviceDetails,
        popUpEmitter: () => {
          this.eventEmitter.emit('callGetIotDeviceDetails');
          this.shouldRecallAdminAlert = true;
        },
      });
      this.shouldRecallAdminAlert = false;
    } else {
      this.ds.sendPopupType(p);
    }
  }

  navigateToDnaPage = () => {
    const { device_id, device_type, device_site_id } = this.linkDevice;

    if (device_type === 'video') {
      this.router.navigate(['video/site/', device_site_id, 'videodevice', device_id]);
    }

    if (device_type === 'audio') {
      this.router.navigate(['audio/site/', device_site_id, 'audiodevice', device_id]);
    }

    if (device_type === 'teams') {
      this.router.navigate(['teams/room/', device_id]);
    }
    
      if (device_type === 'zoom') {
      this.router.navigate(['zoom/room/', device_id]);
    }
  };
}
