import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class GetDirectAccessService {
  server_url: string = environment.serverUrl;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  constructor(private http: HttpClient) {}

  private handleError(error: Response | any) {
    console.error("ApiService::handleError", error);
    return Observable.throw(error);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  createPin(postData: any) {
    const apiUrl = "/api/v1/directAccess/createPin";

    return this.http.post<any>(
      this.server_url + apiUrl,
      postData,
      this.httpOptions
    );
  }

  executePin(postData: any) {
    const apiUrl = "/api/v1/directAccess/executePin";

    return this.http.post<any>(
      this.server_url + apiUrl,
      postData,
      this.httpOptions
    );
  }
}
