import { Injectable } from '@angular/core';

const videoIssues = {
  cameraDown: false,
  micDown: false,
  temperatureAlert: false,
  jitterAlert: false,
  packetlossAlert: false,
  latencyAlert: false,
  sip: false,
};

@Injectable({
  providedIn: 'root',
})
export class VideoIncidentTrackerService {
  private videoIncidents: any;
  hasIncidents: boolean = false;

  constructor() {
    this.videoIncidents = { ...videoIssues };
  }

  resetVideoIncidents = () => {
    this.videoIncidents = { ...videoIssues };
  };

  setCamereIncident = (impactedCount = 0) => {
    this.videoIncidents.cameraDown = impactedCount > 0;
  };

  setMicIncident = (impactedCount = 0) => {
    this.videoIncidents.micDown = impactedCount > 0;
  };

  setTemperatureIncident = (status = '') => {
    this.videoIncidents.temperatureAlert = status === 'HIGH';
  };

  setJitterIncident = (color = '') => {
    this.videoIncidents.jitterAlert = color === 'red';
  };

  setPacketlossIncident = (color = '') => {
    this.videoIncidents.packetlossAlert = color === 'red';
  };

  setLatencyIncident = (color = '') => {
    this.videoIncidents.latencyAlert = color === 'red';
  };

  setSipIncident = (color = '') => {
    this.videoIncidents.sip = color === 'red';
  };

  gatherIncidents() {
    let flag = false;
    const incidentValues = Object.values(this.videoIncidents);
    for (let i = 0; i < incidentValues.length; i++) {
      if (incidentValues[i]) {
        flag = true;
        break;
      }
    }
    this.hasIncidents = flag;
  }

  getIncidents() {
    return { ...this.videoIncidents };
  }
}
