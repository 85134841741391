<div class="counter">
  <p>
    Total # of devices are <strong>{{ totalDeviceCount }}</strong>
  </p>
</div>
<div class="iot-device-list">
  <input
    #iotInputText
    (keyup)="applyFilter($event)"
    placeholder="Search..."
    class="input-class"
  />

  <div class="mat-elevation-z8 child mat-table">
    <table class="data-table" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="site_id">
        <th mat-header-cell *matHeaderCellDef><strong>Site</strong></th>
        <td mat-cell *matCellDef="let row">
          <img [src]="iconAddSite" style="height: 30px" />
        </td>
      </ng-container>

      <ng-container matColumnDef="device_name">
        <th mat-header-cell *matHeaderCellDef><strong>Device Name</strong></th>
        <td mat-cell *matCellDef="let row">
          <div>
            <!-- <div class="text-center iot-device-list__status-color {{ getStatusColor(row) }}"></div> -->
            {{ row.device_name | displayEmpty }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="room_name">
        <th mat-header-cell *matHeaderCellDef><strong>Room Name</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.room_name | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="site_name">
        <th mat-header-cell *matHeaderCellDef><strong>Site Name</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.site_name | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="ip_address">
        <th mat-header-cell *matHeaderCellDef><strong>IP Address</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.ip_address | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef><strong>Status</strong></th>
        <td mat-cell *matCellDef="let row">
          <ng-template [ngIf]="row.paused == 1">
                  <div class="status-blue"></div>
          </ng-template>
          <ng-template [ngIf]="
              row.paused == 0 && row.collector_status == -1
            ">
            <div class="status-grey"></div>
          </ng-template>
          <ng-template [ngIf]="
              row.paused == 0 &&
              row.collector_status == 1 &&
              row.status == 1
            ">
            <div class="status-green"></div>
          </ng-template>
          <ng-template [ngIf]="
              row.paused == 0 &&
              row.collector_status == 1 &&
              row.status == 0
            ">
            <div class="status-red"></div>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div *ngIf="shouldShowLoaderBtn" class="loader-child">
    <img src="{{ loader }}" alt="" />
  </div>
</div>
