<div class="iot-space-list">
  <input #searchDataInput (keyup)="searchData(searchDataInput.value)" placeholder="Search..." class="input-class" />

  <div class="mat-elevation-z8 child mat-table">
    <table class="data-table" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="space_name">
        <th mat-header-cell *matHeaderCellDef class="device-name-style">
          <strong>Space Name</strong>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.space_name | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="agent_name">
        <th mat-header-cell *matHeaderCellDef><strong>{{ isDomotz? 'Agent' : 'Site' }} Name</strong></th>
        <td mat-cell *matCellDef="let row">{{ row.agent_name | displayEmpty }}</td>
      </ng-container>

      <ng-container matColumnDef="linked_devices_count">
        <th mat-header-cell *matHeaderCellDef><strong># of IoT Devices</strong></th>
        <td mat-cell *matCellDef="let row">{{ row.linked_devices_count | displayEmpty }}</td>
      </ng-container>

      <ng-container matColumnDef="is_monitored">
        <th mat-header-cell *matHeaderCellDef><strong>Monitoring</strong></th>
        <td mat-cell *matCellDef="let row">
          <div class="checkbox_switch">
            <label class="switch">
              <input type="checkbox" [checked]="row.is_monitored === 1" readonly
                (click)="setIsMonitored(row, $event)" />
              <span class="slider round"></span>
            </label>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="is_paused">
        <th mat-header-cell *matHeaderCellDef><strong>Pause</strong></th>
        <td mat-cell *matCellDef="let row">
          <div class="checkbox_switch">
            <label class="switch">
              <input type="checkbox" [checked]="row.is_paused === 1" readonly
                (click)="setIsPaused(row, $event)" />
              <span class="slider round"></span>
            </label>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef><strong>Edit</strong></th>
        <td mat-cell *matCellDef="let row; let idx = index"><span class="fa fa-edit fa-lg" (click)="openPopup(84, row, idx)"></span></td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef><strong>Delete</strong></th>
        <td mat-cell *matCellDef="let row; let idx = index"><span class="fa fa-trash fa-lg" (click)="onDeleteSpace(row.space_id, idx)"></span></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [colSpan]="displayedColumns.length">No data found in table</td>
      </tr>
    </table>
  </div>
  <div *ngIf="shouldShowLoaderBtn" class="loader-child">
    <img src="{{ loader }}" alt="" />
  </div>
</div>