import { Component, AfterViewInit, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Chart from 'chart.js';
import { AuthService } from '../../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GetVideoService } from '../../../services/get-video.service'
import { PopupSendDataService } from '../../../services/popup-send-data.service'
import { GetCloudService } from '../../../services/get-cloud.service';
import * as moment from 'moment-timezone';
import { TableColorRowComponent } from '../../table-icons/table-color-row/table-color-row.component'
import { JsonPipe } from '@angular/common';
@Component({
  selector: 'app-chart-qos',
  templateUrl: './chart-qos.component.html',
  styleUrls: ['./chart-qos.component.scss']
})
export class ChartQosComponent implements AfterViewInit, OnChanges  {

  @Input() siteId:any;
  @Input() remoteSiteId:any;
  printTableData: any;
  printMode: boolean = false;
  incidentType:boolean = false;
  chartApiData:any;
  constructor(
    public http: HttpClient,
    private Auth: AuthService) {
  }
  ngOnChanges(changes: SimpleChanges) {
   
    this.currentChart = "60m";
    this.activeBtn = 0;
    this.toggleValue = 0;
    this.toggleBtn(1);
    this.fetchChartData(1);
    
  }


  canvas: any;
  ctx: any;
  myChartSite: any;
  currentChart = "24h";

  chartData = {
    datasets: [
      
      {
        label: 'mbps (mb/s)',
        data: [],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)',
        backgroundColor: 'rgb(255, 184, 0, .5)',
        borderWidth: 1,
        hidden: false
      },
      {
        label: 'jitter (msec)',
        data: [],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)',
        backgroundColor: 'rgb(0, 128, 255, .5)',
        borderWidth: 0,
        hidden: false
      },
      {
        label: 'packet loss (%)',
        data: [],
        backgroundColor: 'rgb(142, 104, 80, .5)',
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)',
        borderWidth: 1,
        hidden: false
      }
    ]
  }

  ngAfterViewInit() {
  }
  ngOnInit(){
        this.canvas = document.getElementById('myChartSite');
        this.ctx = this.canvas.getContext('2d');
        this.myChartSite = new Chart(this.ctx, {
          type: 'line',
          // data: this.data24h,
          options: {
            responsive: false,
            display: true,
            fontSize: '6px',
            padding: '0px',
            maintainAspectRatio: false,
            elements: {
              line: {
                  tension: 0, // disables bezier curves
              }
            }
          }
        });
        this.currentChart = "60m";
        this.activeBtn = 0;
        this.toggleValue = 0;
        this.toggleBtn(1);
        this.fetchChartData(1);
      
  }

  allListData=[];
  fetchChartData(d) {
    
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let qosDetails = {
      company_id: decodedToken.company_id,
      site_id: this.siteId,
      remote_site_id: this.remoteSiteId,
      duration: d
    }
    this.chartApiData = [];

    this.Auth.getQosAllChartData(qosDetails).subscribe((data) => {
      // console.log("inside popup graph",data["response"]); 
      this.chartApiData = data['response'];

      this.chartApiData = [];
      this.Auth.getQosAllChartData(qosDetails).subscribe((data) => { 
       
     
        let jitter = data['response'][0].jitter;
        jitter.sort(this.custom_sort);
        let bps = data['response'][0].bps;
        bps.sort(this.custom_sort);
        let packetloss = data['response'][0].packetloss;
        packetloss.sort(this.custom_sort);
     
        this.chartData.datasets[0].data = bps;
        this.chartData.datasets[1].data = jitter;
        this.chartData.datasets[2].data = packetloss;
        this.loadChart();
      });
    });
    this.allListData = [];
    this.Auth.getQosAllChartRawData(qosDetails).subscribe((data) => {

      this.allListData = data['response'];
        let arr = [];
        for (let k = 0; k < this.allListData.length; k++) {
          arr.push(this.allListData[k]);
          let timestamp = arr[k].timestamp;
          arr[k].timestamp = moment(timestamp).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
        }
        this.printTableData=arr;
      });
    
  
  }

  custom_sort(a, b) {
    return new Date(a.x).getTime() - new Date(b.x).getTime();
  }

  loadChart() {
    // this.myChartSite.reset();
    this.myChartSite.options.scales = {
      xAxes: [{
        type: "time",
        display: true,
        scaleLabel: {
          display: true,
          labelString: this.currentChart == "7d" ? "Date" : "Time",
        },
        time: {
          unit: this.currentChart == "7d" ? "day" : this.currentChart == "24h" ? "hour" : "minute"
        }
      }],
      yAxes: [{
          ticks: {
              beginAtZero:true
          }
      }],
    };
    this.myChartSite.options.tooltips = {
      callbacks: {
        label: function(tooltipItem, chart) {
          if (tooltipItem.datasetIndex == 0) {
            return "ID: " + chart.datasets[0].data[tooltipItem.index].incident_id;
          }
          else {
            return tooltipItem.yLabel
          }
        },
        beforeLabel: function(tooltipItem, chart) {
          switch(tooltipItem.datasetIndex) {
            case 0: 
              return "bps";
            case 1: 
              return "jitter";
            case 2: 
              return "packetloss";
          }
        },
        afterLabel: function(tooltipItem, chart) {
          if (tooltipItem.datasetIndex == 0) {
            let triggers = chart.datasets[0].data[tooltipItem.index].triggers.split(" - ");
            triggers.splice(0,1);
            return triggers;
          }
          else {
            return ""
          }
        },
        title: function(tooltipItem, chart) {
          return moment(tooltipItem[0].xLabel).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
        },
      }
    }
    this.myChartSite.data.datasets = this.chartData.datasets;
    this.myChartSite.update({duration: 500});
  }

  set60m() {
    if (this.currentChart != "60m") {
      this.currentChart = "60m";
      // console.log("#### loaging 60m")
      this.toggleBtn(1);
      this.fetchChartData(1);
    }
  }
  set24h() {
    if (this.currentChart != "24h") {
      this.currentChart = "24h";
      // console.log("#### loaging 24h")
      this.toggleBtn(2);
      this.fetchChartData(24);
    }
  }
  set7d() {
    if (this.currentChart != "7d") {
      this.currentChart = "7d";
      // console.log("#### loaging 7d")
      this.toggleBtn(3);
      this.fetchChartData(7);
    }
  }

  toggleValue: any = 0;
  toggleView(n) {
    if (this.toggleValue == n) {
      this.toggleValue = 0;
    } else {
      this.toggleValue = n;
    }
  }

  activeBtn:any = 0;
  toggleBtn(n) {
    if (this.activeBtn == n) {
      this.activeBtn = 0;
    } else {
      this.activeBtn = n;
    }
  }

  // Settings and Parameters for the the Smart Filter Tables
  QosTableSetting= {
    actions: {
        add: false,
        edit: false,
        delete: false
      },
      attr: {
        class: "filterTable"
      },
      hideSubHeader: true,
      pager:{
        display: false
      },
      columns: {
        timestamp: {
          title: 'Time Stamp',
    
        },
        bps: {
          title: 'BPS',
        },
        jitter: {
          title: 'Jitter',
          
        },
        packetloss: {
          title: 'Packetloss',
   
        },
        success: {
          title: 'Status',
          valuePrepareFunction(cell, row){
            return cell == 1?"Succeed":"Failed";
          } 
        }
      }
    };

 
  print() {
    var divId
    if (this.toggleValue == 0) { // chart
      this.printCanvas()
    } else { // list
      this.printList();
    }
  }

  printCanvas() {
    let printContents = (<HTMLCanvasElement>document.getElementById('myChartSite')).toDataURL();
    let popupWin;

    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print Chart</title>
        </head>
        <body onload="window.print(); window.close()">
          <img style='width: 100%;' src='${printContents}'/>
        </body>
      </html>
    `);
    popupWin.document.close();
  }

  printList() {
    this.printMode = true;
    setTimeout(() => {
      let printContents, stylesHtml, linksHtml, popupWin;

      printContents = document.getElementById('printTable').innerHTML;
      stylesHtml = this.getTagsHtml('style');
      linksHtml = this.getTagsHtml('link');

      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Print Report</title>
            ${stylesHtml}
            ${linksHtml}
            <style>
            .wrap {
              width: 100%;
              margin: 0 auto;
              margin-left: 15%;
              margin-top: 5%;
            }
            
            .wrap table {
              width: 1300px;
            
            }
            table tr td {
              padding: 5px;
              /* border: 1px solid #eee; */
              width: 110px;
              /* word-wrap: break-word; */
              cursor:context-menu;
              text-align: center;
          }
            table.head tr td {
              background: #eee;
            }
            .inner_table {
              height: auto;
              
            }
            </style>
          </head>
          <body onload="window.print(); window.close()">
            ${printContents}
          </body>
        </html>
      `);
      popupWin.document.close();
      this.printMode = false;
    }, 500);
  }

  // Helper for printDivV2 - used to preserve styles and links for printed div
  private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string {
    const htmlStr: string[] = [];
    const elements = document.getElementsByTagName(tagName);

    for (let idx = 0; idx < elements.length; idx++) {
        htmlStr.push(elements[idx].outerHTML);
    }

    return htmlStr.join('\r\n');
  }
}
