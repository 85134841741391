import { Component, Input, OnInit, SimpleChanges } from '@angular/core'

@Component({
	selector: 'app-collector-stats',
	templateUrl: './collector-stats.component.html',
	styleUrls: ['./collector-stats.component.scss'],
})
export class CollectorStatsComponent implements OnInit {
	@Input() collectorDetails: any

	collectorLiveStats: any
	collectorHostUtilization: any
	collectorPathTest: any

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {}

	ngOnInit() {}
}
