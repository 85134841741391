import { Observable } from 'rxjs/Observable'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'

import { IntegratorService } from '../services/integrator.service'
import { Subject } from 'rxjs'
import { AuthService } from './auth.service'

@Injectable({
	providedIn: 'root',
})
export class GetIotService {
	getUrlPath: Function = environment.apiServicePathBuilder('iot')
	// getUrlPath: Function = environment.apiServicePathBuilder('dev');
	server_url: string = environment.serverUrl
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	}
	deviceLastSync:Subject<any> = new Subject<any>();

	constructor(private http: HttpClient, private integrator: IntegratorService, private Auth: AuthService) {}

	private handleError(error: Response | any) {
		// console.error('ApiService::handleError', error);
		return Observable.throwError(error)
	}

	getCompanyId = () => {
		return this.Auth.getCompanyId()
		// const token = localStorage.getItem('token')
		// const helper = new JwtHelperService()
		// const { company_id } = helper.decodeToken(token)
		// return company_id
	}

	private extractData(res: Response) {
		let body = res
		return body || {}
	}

	getDomotzAccountByCompanyId() {
		const company_id = this.getCompanyId()
		const url: string = this.getUrlPath(`getDomotzAccountByCompanyId/${company_id}`)
		return this.http.get<any>(url).pipe(map(this.extractData))
	}

	getAllDomotzAgents(search_key, limit, offset, filterLink) {
		const company_id = this.getCompanyId()
		const url: string = this.getUrlPath(
			`getAllDomotzAgents/${company_id}/search_key/${search_key.trim()}/limit/${limit}/offset/${offset}/filterLink/${filterLink}`
		)
		return this.http.get<any>(url).pipe(map(this.extractData))
	}

	getAllDomotzAgentsSubVendor(search_key, limit, offset) {
		const company_id = this.getCompanyId()
		const url: string = this.getUrlPath(
			`getAllDomotzAgentsSubVendor/${company_id}/search_key/${search_key.trim()}/limit/${limit}/offset/${offset}`
		)

		return this.http.get<any>(url).pipe(map(this.extractData))
	}

	getAllIotDevices(search_key, limit, offset, is_monitored_only) {
		const company_id = this.getCompanyId()
		const url: string = this.getUrlPath(
			`getAllIotDevices/${company_id}/search_key/${search_key.trim()}/limit/${limit}/offset/${offset}/is_monitored_only/${is_monitored_only}`
		)
		return this.http.get<any>(url).pipe(map(this.extractData))
	}

	updateDomotzAccount(domotz_account_id, api_name, account_url, api_key, active) {
		const url: string = this.getUrlPath('updateDomotzAccount')
		const company_id = this.getCompanyId()
		const data = {
			domotz_account_id,
			api_name,
			account_url,
			api_key,
			active,
			company_id,
		}
		return this.http.post<any>(url, data, this.httpOptions).pipe(map(this.extractData))
	}

	addDomotzAccount(api_key, api_name, account_url) {
		const _url: string = this.getUrlPath('addDomotzAccount')
		const company_id = this.getCompanyId()
		const data = {
			company_id,
			api_name,
			api_key,
			account_url,
		}
		return this.http.post<any>(_url, data, this.httpOptions).pipe(map(this.extractData))
	}

	setDomotzAccountEnabled(account_id, flag) {
		const _url: string = this.getUrlPath('setDomotzAccountEnabled')
		const data = {
			account_id,
			flag,
		}

		return this.http.post<any>(_url, data, this.httpOptions).pipe(map(this.extractData))
	}

	setDomotzAccountNotification(account_id, flag) {
		const _url: string = this.getUrlPath('setDomotzAccountNotification')
		const data = {
			account_id,
			flag,
		}
		return this.http.post<any>(_url, data, this.httpOptions).pipe(map(this.extractData))
	}

	setIotDevicePaused(device_id, flag) {
		const _url: string = this.getUrlPath('setIotDevicePaused')
		const data = {
			device_id,
			flag,
			company_id: this.getCompanyId(),
		}
		return this.http.post<any>(_url, data, this.httpOptions).pipe(map(this.extractData))
	}

	setIotAgentIsMonitored(agent_id, flag) {
		const _url: string = this.getUrlPath('setIotAgentIsMonitored')
		const data = {
			agent_id,
			flag,
		}
		return this.http.post<any>(_url, data, this.httpOptions).pipe(map(this.extractData))
	}

	setIotDeviceIsMonitored(device_id, flag) {
		const _url: string = this.getUrlPath('setIotDeviceIsMonitored')
		const data = {
			device_id,
			flag,
		}
		return this.http.post<any>(_url, data, this.httpOptions).pipe(map(this.extractData))
	}

	setIotDeviceNotes(company_id, device_id, message) {
		const _url: string = this.getUrlPath('setIotDeviceNotes')
		const data = {
			company_id,
			device_id,
			message,
		}
		return this.http.post<any>(_url, data, this.httpOptions).pipe(map(this.extractData))
	}

	getAllIotAgentSummaryList(search_key, limit, offset, is_impacted_only) {
		const company_id = this.integrator.isIntegrator() ? this.getCompanyId() * -1 : this.getCompanyId()
		const url: string = this.getUrlPath(
			// tslint:disable-next-line: max-line-length
			`getAllIotAgentSummaryList/${company_id}/search_key/${search_key.trim()}/limit/${limit}/offset/${offset}/is_impacted_only/${is_impacted_only}`
		)
		return this.http.get<any>(url).pipe(map(this.extractData))
	}

	getAllDomotzIotDevicesSummaryList(search_key, limit, offset, filter_status, filter_agent = -1) {
		const company_id = this.integrator.isIntegrator() ? this.getCompanyId() * -1 : this.getCompanyId()
		const url: string = this.getUrlPath(
			// tslint:disable-next-line: max-line-length
			`getAllDomotzIotDevicesSummaryList/${company_id}/search_key/${search_key.trim()}/limit/${limit}/offset/${offset}/filter_status/${filter_status}/filter_agent/${filter_agent}`
		)
		return this.http.get<any>(url).pipe(map(this.extractData))
	}

	getAllIotAgentsSummary(filter_agent = -1) {
		const company_id = this.integrator.isIntegrator() ? this.getCompanyId() * -1 : this.getCompanyId()
		const url: string = this.getUrlPath(`getAllIotAgentsSummary/${company_id}/filter_agent/${filter_agent}`)
		return this.http.get<any>(url).pipe(map(this.extractData))
	}

	getAllIotLiteSummary() {
		const company_id = this.integrator.isIntegrator() ? this.getCompanyId() * -1 : this.getCompanyId()
		const url: string = this.getUrlPath(`getAllIotLiteSummary/${company_id}`)
		return this.http.get<any>(url).pipe(map(this.extractData))
	}

	// getAllIotDeviceSummary() {
	//   const company_id = this.integrator.isIntegrator()
	//     ? this.getCompanyId() * -1
	//     : this.getCompanyId();
	//   const url: string = this.getUrlPath(`getAllIotDeviceSummary/${company_id}`);
	//   return this.http.get<any>(url).pipe(map(this.extractData));
	// }

	getAllIotDeviceSummary() {
		const company_id = this.integrator.isIntegrator() ? this.getCompanyId() * -1 : this.getCompanyId()
		const url: string = this.getUrlPath(`getAllIotDeviceSummary/${company_id}`)
		return this.http.get<any>(url).pipe(map(this.extractData))
	}

	getIotDeviceDetails(device_id) {
		const company_id = this.integrator.isIntegrator() ? this.getCompanyId() * -1 : this.getCompanyId()
		const url: string = this.getUrlPath(`getIotDeviceDetails/${device_id}/${company_id}`)
		return this.http.get<any>(url)
	}

	getDeviceStatusHistory(device_id, agent_id, account_url_, api_key, dateFrom, dateTo) {
		const requestOptions = {
			headers: {
				'X-Api-Key': api_key,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}
		const url: string =
			account_url_ + `/agent/${agent_id}/device/${device_id}/history/network/event?from=${dateFrom}&to=${dateTo}`
		return this.http.get<any>(url, requestOptions).pipe(map(this.extractData))
	}

	getDeviceRTDHistory(device_id, agent_id, account_url_, api_key, dateFrom, dateTo) {
		const requestOptions = {
			headers: {
				'X-Api-Key': api_key,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}
		const url: string =
			account_url_ + `/agent/${agent_id}/device/${device_id}/history/rtd?from=${dateFrom}&to=${dateTo}`
		return this.http.get<any>(url, requestOptions).pipe(map(this.extractData))
	}

	rebootIotDevice(device_id, agent_id, account_url_, api_key) {
		const requestOptions = {
			headers: {
				'X-Api-Key': api_key,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}
		const url: string = account_url_ + `/agent/${agent_id}/device/${device_id}/action/power/cycle`
		return this.http.post<any>(url, requestOptions).pipe(map(this.extractData))
	}

	wakeIotDevice(device_id, agent_id, account_url_, api_key) {
		const requestOptions = {
			headers: {
				'X-Api-Key': api_key,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}
		const url: string = account_url_ + `/agent/${agent_id}/device/${device_id}/action/power/on`
		return this.http.post<any>(url, requestOptions).pipe(map(this.extractData))
	}

	getDeviceWebPortalUrl(device_id, protocol, port) {
		const company_id = this.integrator.isIntegrator() ? this.getCompanyId() * -1 : this.getCompanyId()
		const url: string = this.getUrlPath(`getDeviceWebPortalUrl/${device_id}/protocol/${protocol}/port/${port}/${company_id}`)
		return this.http.get<any>(url).pipe(map(this.extractData))
	}

	syncDomotz(company_id, api_name, account_url) {
		const _url: string = this.getUrlPath('syncDomotzAccount')
		const data = {
			company_id,
			api_name,
			account_url,
		}

		return this.http.post<any>(_url, data, this.httpOptions).pipe(map(this.extractData))
	}

	setIotDeviceTriggerAlerts(device_id, triggers) {
		const _url: string = this.getUrlPath('setIotDeviceTriggerAlerts')
		const data = {
			device_id,
			triggers,
		}
		return this.http.post<any>(_url, data, this.httpOptions).pipe(map(this.extractData))
	}

	getUnlinkedIotDevice = () => {
		const company_id = this.getCompanyId()
		const url: string = this.getUrlPath(`/list?company_id=${company_id}`)
		return this.http.get<any>(url).pipe(map(this.extractData))
	}

	// getLinkedIotDevice = (device_id, device_type) => {
	//   const url: string = this.getUrlPath(
	//     `/getLinkedDevices?device_type=${device_type}&device_id=${device_id}`
	//   );
	//   return this.http.get<any>(url).pipe(map(this.extractData));
	// };

	setIotDeviceLink(device_id, device_name, device_type, iot_device_id, device_site_id) {
		const _url: string = this.getUrlPath(`link/${iot_device_id}`)
		const data = {
			device_id,
			device_name,
			device_type,
			device_site_id,
		}
		return this.http.put<any>(_url, data, this.httpOptions).pipe(map(this.extractData))
	}

	setIotDeviceUnlink(iot_device_id) {
		const _url: string = this.getUrlPath(`/link/${iot_device_id}`)
		return this.http.delete<any>(_url).pipe(map(this.extractData))
	}

	getIotDeviceIpAndMacMatch = (ip_address, mac_address) => {
		const company_id = this.getCompanyId()
		const url: string = this.getUrlPath(
			`match?company_id=${company_id}&ip_address=${ip_address}&mac_address=${mac_address}`
		)
		return this.http.get<any>(url).pipe(map(this.extractData))
	}

	getUnlinkedPluginToRoomCount = () => {
		const company_id = this.getCompanyId()
		const url: string = this.getUrlPath(`countDevices?company_id=${company_id}`)

		return this.http.get<any>(url).pipe(map(this.extractData))
	}

	getIotIncidentList = device_id => {
		const url: string = this.getUrlPath(`getIotIncidentList?device_id=${device_id}`)
		return this.http.get<any>(url).pipe(map(this.extractData))
	}

	getMSPDomotzStatus = () => {
		//special single no object
		const company_id = this.getCompanyId()
		const url: string = this.getUrlPath(`getMSPDomotzStatus/${company_id}`)
		return this.http.get<any>(url).pipe(map((res: Response) => res))
	}

	getDeviceRtdStatsIotLite(iot_id) {
		const _url: string = this.getUrlPath('getDeviceRtdStatsIotLite')
		const data = {
			iot_id,
		}
		return this.http.post<any>(_url, data, this.httpOptions).pipe(
			map(e => {
				if (!e) return {}

				const { lost } = e
				const resp = { ...e }

				if (![null, undefined].includes(lost)) {
					resp.lost = +lost > 40 ? +lost : +lost / 20
				}

				return resp
			})
		)
	}

	getIotLiteDeviceList = (display_type, collector_id) => {
		const postData = {
			display_type,
			company_id: this.getCompanyId(),
			collector_id,
		}
		// // console.log('postData: ', postData)
		return this.http.post<any>(this.server_url + '/api/v1/iot/iotLiteDeviceList', postData, this.httpOptions)
	}

	iotLiteEnabled = flag => {
		const postData = {
			company_id: this.getCompanyId(),
			flag,
		}

		return this.http.post<any>(this.server_url + '/api/v1/iot/iotLiteEnabled', postData, this.httpOptions)
	}

	getIotLiteStatus = () => {
		const postData = {
			company_id: this.getCompanyId(),
		}
		return this.http.post<any>(this.server_url + '/api/v1/iot/getIotLiteStatus', postData, this.httpOptions)
	}

	iotLiteLinkDevice = (iot_id, device_id, quadrant_group) => {
		const postData = {
			iot_id,
			device_id,
			quadrant_group,
		}
		return this.http.post<any>(this.server_url + '/api/v1/iot/iotLiteLinkDevice', postData, this.httpOptions)
	}

	iotLiteAdd = (collector_id, iot_name, host_name, iot_ip, devicePort, mac_address, iotType, make, site_id,is_logitech?:boolean) => {
		const postData = {
			collector_id,
			company_id: this.getCompanyId(),
			iot_name,
			host_name,
			iot_ip,
			devicePort,
			mac_address,
			iotType,
			make,
			site_id,
			is_logitech
		}
		console.log('iotLiteAdd: ', postData)

		return this.http.post<any>(this.server_url + '/api/v1/iot/add', postData, this.httpOptions)
	}

	iotLiteDeactivateDevice = data => {
		return this.http.post<any>(this.server_url + '/api/v1/iot/deactivateDevice', data, this.httpOptions)
	}

	iotLiteSetMonitor = (iot_id, flag) => {
		const postData = {
			iot_id,
			flag,
		}

		return this.http.post<any>(this.server_url + '/api/v1/iot/iotLiteSetMonitor', postData, this.httpOptions)
	}

	getIotLitePortsByIotId = iot_id => {
		const postData = {
			iot_id,
		}

		return this.http.post<any>(this.server_url + '/api/v1/iot/getIotLitePortsByIotId', postData, this.httpOptions)
	}

	getIotLiteIncidents = (iot_id, days) => {
		const postData = {
			company_id: this.getCompanyId(),
			iot_id,
			days,
		}

		return this.http.post<any>(this.server_url + '/api/v1/iot/getIotLiteIncidents', postData, this.httpOptions)
	}

	updateIotLitePorts = (iot_id, devicePort,video_device_id?:any,is_logitech?:boolean) => {
		const postData = {
			iot_id,
			devicePort,
			video_device_id,
			is_logitech
		}

		return this.http.post<any>(this.server_url + '/api/v1/iot/updateIotLitePorts', postData, this.httpOptions)
	}

	setIotPortStatus = (iot_port_id, status) => {
		const postData = {
			iot_port_id,
			status: status.toString(),
		}

		// console.log('postData; ',postData)

		return this.http.post<any>(this.server_url + '/api/v1/iot/setIotPortStatus', postData, this.httpOptions)
	}

	getIotAlerts = () => {
		const postData = {
			company_id: this.getCompanyId(),
		}

		return this.http.post<any>(this.server_url + '/api/v1/iot/getIotAlerts', postData, this.httpOptions)
	}

	setIotAlerts = flag => {
		const postData = {
			company_id: this.getCompanyId(),
			flag,
		}

		return this.http.post<any>(this.server_url + '/api/v1/iot/setIotAlerts', postData, this.httpOptions)
	}
	setIotName = payload => {
		let uri = this.server_url + '/api/v1/iot/renameIot'
		// let curl = ' http://127.0.0.1:8080/api/v1/iot/renameIot';
		return this.http.post<any>(uri, payload, this.httpOptions)
	}

	setHasSelectedIotNotDomotz = flag => {
		const postData = {
			company_id: this.getCompanyId(),
			flag,
		}

		return this.http.post<any>(this.server_url + '/api/v1/iot/setHasSelectedIotNotDomotz', postData, this.httpOptions)
	}

	getIotLiteDeviceChart = (iot_id, duration) => {
		const postData = {
			company_id: this.getCompanyId(),
			iot_id,
			duration,
		}

		console.log('postData: ', postData)

		return this.http.post<any>(this.server_url + '/api/v1/iot/getIotLiteDeviceChart', postData, this.httpOptions)
	}
	
	//SPACES 
	
		getAllIotSpacesSummaryList = (search_key, limit, offset, is_impacted_only) => {
		const company_id = this.integrator.isIntegrator() ? this.getCompanyId() * -1 : this.getCompanyId()
		const url: string = this.getUrlPath(
			// tslint:disable-next-line: max-line-length
			`getAllIotSpacesSummaryList/${company_id}/search_key/${search_key.trim()}/limit/${limit}/offset/${offset}/is_impacted_only/${is_impacted_only}`
		)
		return this.http.get<any>(url).pipe(map(this.extractData))
	}
	
	
	getAgentLinkToSpaces = (search_key,space_id, limit, offset, is_impacted_only) => {
		const company_id = this.integrator.isIntegrator() ? this.getCompanyId() * -1 : this.getCompanyId()
		const url: string = this.getUrlPath(
			// tslint:disable-next-line: max-line-length
			`getAgentLinkToSpaces/${company_id}/space_id/${space_id}/search_key/${search_key.trim()}/limit/${limit}/offset/${offset}/is_impacted_only/${is_impacted_only}`
		)
		return this.http.get<any>(url).pipe(map(this.extractData))
	}
}
