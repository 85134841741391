import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GetAudioService } from '../../../services/get-audio.service';
import { RefreshApiService } from '../../../services/refresh-api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-audio-main',
  templateUrl: './audio-main.component.html',
  styleUrls: ['./audio-main.component.scss'],
})
export class AudioMainComponent implements OnInit, OnDestroy {
  deviceId: number;
  companyId: number;
  audioDeviceData: any;
  filterListner: Subscription;
  displayStringForTesting: string;
  isShowContent = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getAudioDataService: GetAudioService,
    private refreshApiService: RefreshApiService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.deviceId = params['id'];
    });

    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.companyId = decodedToken.company_id;

    this.getAudioApiData();
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if (m) {
        this.getAudioApiData();
      }
    });
  }

  getAudioApiData() {
    this.getAudioDataService.apiGetAudioDeviceDetails(this.deviceId).subscribe((data) => {
      this.audioDeviceData = data['response'];
      this.isShowContent = true;
    });
  }

  triggerEvent($event) {
    if ($event === 'refreshDeviceDetails') {
      this.getAudioApiData();
    }
  }

  ngOnDestroy() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
  }
}
