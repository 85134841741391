import { Component, OnInit, Output, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { JwtHelperService } from '@auth0/angular-jwt';

import { MembersTypeService } from '../../services/membersType.service';
import { PopupMembers } from '../../services/popupMembers.animation';
import { MainMenuTypeService } from '../../services/mainmenuType.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from '../../../environments/environment';
import { GetDesktopPluginService } from '../../services/get-desktop-plugin.service';

@Component({
  selector: 'app-main-menu-membership',
  templateUrl: './main-menu-membership.component.html',
  styleUrls: ['./main-menu-membership.component.scss'],
  animations: [PopupMembers],
})
export class MainMenuMembershipComponent implements OnInit, OnDestroy {
  @Output() popupTypeState: string = 'close';
  @Input() title: string = 'Title';
  @Input() runTutorial: any;
  @Input() keyDown: any;

  links = environment.config.links;
  envTitle = environment.config.title;

  popupType: any;
  subscription: Subscription;
  allSiteListData: any;
  allDeviceListData: any;
  //
  isShowCollectorInfo: any;
  showScheduleModal: any;
  desktopClientDownloadLink: any;
  membershipSubscriptionLogo = environment.config.dynamicImage.membershipSubscriptionLogo;
  mainMenuIotLogo = environment.config.dynamicImage.mainMenuIotLogo;

  disabledGettingStartedLink: boolean = true;

  constructor(
    private ds: MembersTypeService,
    private dsM: MainMenuTypeService,
    private Auth: AuthService,
    private desktopPlugin: GetDesktopPluginService
  ) {
    this.subscription = this.ds.getMemberType().subscribe((x) => {
      this.popupType = x;
      if (this.popupType != null && this.popupType !== 0) {
        if (this.popupTypeState === 'open') {
          this.togglePopupType();
          setTimeout(() => {
            this.togglePopupType();
          }, 200);
        } else {
          this.togglePopupType();
        }
      } else {
        this.popupTypeState === 'close';
      }
    });
  }

  togglePopupType() {
    // 1-line if statement that toggles the value:
    this.popupTypeState = this.popupTypeState === 'open' ? 'close' : 'open';
  }

  receiveMessage(params) {
    if (params === 'isShowCollectorInfo') {
      this.isShowCollectorInfo = true;
    }

    if (params === 'showScheduleModal') {
      this.showScheduleModal = true;
    }
  }

  ngOnInit() {
    this.getAllSiteDetail();
    this.desktopPluginDownloadURL();
    this.checkForEnvironment();
  }

  checkForEnvironment = () => {
    if (this.envTitle === 'VisibilityOne' || this.envTitle === 'CXDetect') {
      this.disabledGettingStartedLink = false;
    }
  }

  desktopPluginDownloadURL = () => {
    this.desktopPlugin.downloadDesktopClient().subscribe((data: any) => {
      const { download_url } = data;
      this.desktopClientDownloadLink = download_url;
    });
  };

  sendMainMenuType(p) {
    this.dsM.sendMainMenuType(p);
  }

  ngOnDestroy() {
    this.dsM.clearMainMenuType();
  }

  closePopUp = () => {
    if (!this.isShowCollectorInfo && !this.showScheduleModal) {
      this.popupTypeState = 'close';
      this.isShowCollectorInfo = false;
      this.isShowCollectorInfo = false;
    }
    if (this.isShowCollectorInfo) {
      this.isShowCollectorInfo = false;
    }

    if (this.showScheduleModal) {
      this.showScheduleModal = false;
    }
  };

  clearMainMenuType() {
    // clear message
    this.dsM.clearMainMenuType();
  }

  // This function gets a list of sites for this company
  getAllSiteDetail() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = {
      company_id: decodedToken.company_id,
    };
    const deviceData = {
      company_id: decodedToken.company_id,
      type: 'polycom',
    };

    this.Auth.getAllSiteList().subscribe((data) => {
      this.allSiteListData = data.response;
    });

    // this.allSiteListData = [];
    this.Auth.getAllSiteList().subscribe((data) => {
      this.allSiteListData = data['response'];

      let arr = [];
      for (let d in this.allSiteListData) {
        arr.push(this.allSiteListData[d]);
      }
      if (this.runTutorial == 1 && arr[0] != 1) {
        this.popupType == 9;
        this.sendMainMenuType(9);
        this.popupTypeState = 'open';
      }
    });

    this.Auth.getAllDeviceList(deviceData).subscribe((data) => {
      this.allDeviceListData = data['response'];

      let arr = [];
      for (let d in this.allDeviceListData) {
        arr.push(this.allDeviceListData[d]);
      }
    });
  }


}
