<div class="cloud-user-device-health-button">
  <div class="cloud-user-device-health-button__image-text-container">
    <img class="cloud-user-device-health-button__image-text-container__clickable"
      *ngIf="quadrantType !== 'zoom' && quadrantType !== 'teams'" src="{{ getQuadrantImage() }}"
      alt="Click to view device heal records." (click)="displayDeviceHealList()" />
    <img *ngIf="quadrantType === 'zoom' || quadrantType === 'teams'" src="{{ getQuadrantImage() }}" alt="" />

    <div class="cloud-user-device-health-button__image-text-container-two-rows">
      <div class="cloud-user-device-health-button__inner-image-text-container">
        <div *ngIf="quadrantType !== 'zoom' && quadrantType !== 'teams'"
          class="cloud-user-device-health-button__inner-text">
          <h6>
            {{ device }}
          </h6>
          <div class="tooltip">
            {{ device }}
          </div>
        </div>

        <img class="cloud-user-device-health-button__zoom-img"
          *ngIf="quadrantType === 'zoom' || quadrantType === 'teams'" src="{{ getQuadrantTypeLogo(quadrantType) }}"
          alt="" />
        <img *ngIf="quadrantType !== 'zoom' && quadrantType !== 'teams' && device.length > 0" src="{{ deviceStatus }}"
          alt="" />
      </div>
      <div [ngClass]="{ 'disable-button': shouldShowLoader && getLoader() }"
        class="cloud-user-device-health-button__inner-lower-text" (click)="executeAction()">
        {{ quadrantType !== 'zoom' && quadrantType !== 'teams' ? 'Remote Heal' : 'Launch' }}
        <img *ngIf="shouldShowLoader && getLoader()" src="{{ loader }}" alt="" />
      </div>
    </div>
  </div>
  <div [ngClass]="{
      'cloud-user-device-health-button__message-container--success': resultStatus == 'OK',
      'cloud-user-device-health-button__message-container--failed': resultStatus == 'Failed'
    }">
    {{ resultMessage }}
  </div>
</div>