import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

import { GetIotService } from '../../services/get-iot.service'

@Component({
  selector: 'app-iot-admin-pop-up',
  templateUrl: './iot-admin-pop-up.component.html',
  styleUrls: ['./iot-admin-pop-up.component.scss'],
})
export class IotAdminPopUpComponent implements OnInit, OnChanges {
  @Input() iotDeviceDetails: any
  @Input() popUpEmitter: any

  dumbKey = '00000000-0000-0000-0000-000000000000'

  isShowSync = false
  apiKey: string = this.dumbKey
  apiName: string = ''
  accountUrl: string = ''
  active: number
  domotz_account_id: number
  company_id: number
  shouldShowAlerts: Boolean = false
  syncMessage: any = 'Device Sync Success!'
  showSyncMessage: boolean = false
  messageclass: any = 'iotadmin__sync-message--success'
  shouldShowDocuments: boolean = false
  propData: any
  constructor(private GetIot: GetIotService) { }

  ngOnInit() {
    this.getDomotzAccountByCompanyId()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.iotDeviceDetails && this.iotDeviceDetails) {
      console.log(this.iotDeviceDetails);
      // this.setData();
    }
  }

  getDomotzAccountByCompanyId = () => {
    this.GetIot.getDomotzAccountByCompanyId().subscribe((data: any) => {
      const { company_id, api_name, api_key, account_url } = data

      this.company_id = company_id
      this.apiName = api_name
      this.apiKey = this.dumbKey
      this.accountUrl = account_url
    })
  }

  showAlerts = () => {
   this.resetDefault();
    this.shouldShowAlerts = !this.shouldShowAlerts
  }

  hideMessage = (t = 2000) => {
    setTimeout(() => {
      this.showSyncMessage = false
    }, t)
  }

  syncDomotzAccount = () => {
    this.resetDefault();
    this.syncMessage = 'Syncing... This can take up to 2 minutes. Please be patient and do not leave this page.'
    this.showSyncMessage = true
    
    this.GetIot.syncDomotz(this.company_id, this.apiName, this.accountUrl).subscribe()
    // data => {
    // 	this.showSyncMessage = true
    // 	this.syncMessage = 'Device Sync Success!'
    // 	this.messageclass = 'iotadmin__sync-message--success'
    // 	this.hideMessage()
    // },
    // error => {
    // 	this.showSyncMessage = true
    // 	this.syncMessage = 'Device Sync Failed!'
    // 	this.messageclass = 'iotadmin__sync-message--error'
    // 	this.hideMessage()
    // }

    this.showSyncMessage = true
    this.syncMessage = 'Syncing... This can take up to 2 minutes. Please be patient and do not leave this page.'
    this.messageclass = 'iotadmin__sync-message--success'
    this.hideMessage(59000)

    setTimeout(() => {
      this.showSyncMessage = true
      this.syncMessage = 'Device Sync Success!'
      this.messageclass = 'iotadmin__sync-message--success'
      this.hideMessage(10000)
    }, 60000)
  }

  showDocumentCenter = () => {
    // // console.log('show document center');

    //edit depending  on what column
    // this.resetButton();
    //video
    //cloud
    console.log(this.iotDeviceDetails)
    
    this.propData = {
      quadrant_group: 'DOMOTZ_IOT',
      device_id: this.iotDeviceDetails.iot_device_id,
      description: this.iotDeviceDetails.display_name,
      offset: 0,
    }
    this.resetDefault();
    this.shouldShowDocuments = true
  }
	
  resetDefault = () => {
    this.shouldShowAlerts = false
    this.shouldShowDocuments = false
    this.showSyncMessage = false
  }
}