import { Component, Input, OnInit } from '@angular/core'

import { PopupTypeService } from '../../services/popupType.service'

@Component({
  selector: 'app-iot-lite-device-live-stats',
  templateUrl: './iot-lite-device-live-stats.component.html',
  styleUrls: ['./iot-lite-device-live-stats.component.scss']
})
export class IotLiteDeviceLiveStatsComponent implements OnInit {

  @Input() pingRtd: any = '--';
  @Input() packetLoss: any = '--';

  @Input() medianAlert: Number;
  @Input() medianWarning: Number;

  @Input() packetLossAlert: Number;
  @Input() packetLossWarning: Number;
   @Input() worstRtd: any = '--';
  @Input() bestRtd: any = '--';
  @Input() packetLossRtd: any = '--';
  @Input() iotDeviceDetails: any;
  
  constructor(private ds: PopupTypeService) {}

  ngOnInit() {}
  checkRtdAlert = () => {
    if (this.pingRtd > this.medianAlert) {
      return 'livestats__rtd-alert';
    }
    if (this.pingRtd > this.medianWarning) {
      return 'livestats__rtd-warning';
    }

    return '';
  };
  checkPacketLossAlert = () => {
    if (this.packetLoss > this.packetLossAlert) {
      return 'livestats__packetloss-alert';
    }
    if (this.packetLoss > this.packetLossWarning) {
      return 'livestats__packetloss-warning';
    }

    return '';
  };
  
   openIotLiteChart = (viewType) => {
    const data = {
      iotDeviceID: this.iotDeviceDetails.iot_id,
      paused: 0,
      dateRange: 1,
      viewType
    };
    
    
    this.ds.sendPopupType(79, {
      data,
    });
  };
}
  
  
  
  
  
  
