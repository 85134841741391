<div class="stats">
    <app-iot-lite-device-live-stats [pingRtd]="pingRtd" [packetLoss]="packetLoss"
        [iotDeviceDetails]="deviceHisoryParams">
    </app-iot-lite-device-live-stats>
    <hr />
    <app-iot-lite-device-historic-ping [worstRtd]="worstRtd" [medianRtd]="medianRtd" [bestRtd]="bestRtd"
        [packetLossRtd]="packetLossRtd" [iotDeviceDetails]="deviceHisoryParams"></app-iot-lite-device-historic-ping>
    <hr />
    <app-iot-lite-device-last-event [latestEventsMins]="latestEventsMins" [latestEvents24h]="latestEvents24h"
        [deviceID]="deviceID" [deviceName]="deviceName">
    </app-iot-lite-device-last-event>
    <hr />

    <app-iot-lite-device-latest-event [deviceHisoryParams]="deviceHisoryParams" [deviceID]="deviceID">
    </app-iot-lite-device-latest-event>

    <app-iot-lite-device-notes [eventEmitter]="eventEmitter" [deviceID]="deviceID" [notes]="notes">
    </app-iot-lite-device-notes>
</div>