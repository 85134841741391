<div class="download-icons"
  [ngClass]="{ 'download-icons__100-width': componentName == 'vsone', 'download-icons__enlarge-img': !displayMacPlugin  }">
  <div class="download-icons__left-row  download-icons__row">
    <div class="download-icons__title">
      <i class="fa fa-info-circle"></i>
      <div class="download-icons__title-text">
        Downloads
      </div>
    </div>

    <div>
      <a href="{{ links.collector }}" target="_blank">
        <img [src]="downloadCollector" />
      </a>
    </div>

    <!-- temporarily hiding mac collector -->
    <!-- <div>
      <a href="{{ links.macCollector }}" target="_blank">
        <img [src]="downloadCollectorMac" />
      </a>
    </div> -->

    <div>
      <!-- <a href="{{ links.plugin }}" target="_blank"> -->
      <span (click)="downloadPlugin(pluginDownloadUrl, 'zoom')">	
        <span *ngIf="!pluginBeingDownloaded">
          <img [src]="pluginZoom" />
        </span>
        <span *ngIf="pluginBeingDownloaded">
          <img [src]="loader" />
        </span>
      </span>
    </div>

    <div *ngIf="displayMacPlugin">
      <!-- <a href="{{ links.plugin }}" target="_blank"> -->
      <a href="{{ pluginMacDownloadUrl }}" target="_blank">
        <img [src]="pluginMac" />
      </a>
    </div>

    <div id="new-desktop-plugin">
      <span (click)="downloadPlugin(desktopClientDownloadLink, 'desktop')">	
        <span *ngIf="!desktopPluginBeingDownloaded">	
          <img [src]="desktopPlugin" />	
        </span>	
        <span *ngIf="desktopPluginBeingDownloaded">	
        <img   [src]="loader" />	
        </span>	
      </span>
    </div>
  </div>

  <div *ngIf="componentName !== 'zoom' && componentName !== 'teams'"
    class="download-icons__right-row download-icons__row">
    <div class="download-icons__title">
      <i class="fa fa-info-circle"></i>
      <div class="download-icons__title-text">
        Create a Site
      </div>
    </div>

    <div>
      <div id="new-site" (click)="sendPopupTypeEmitter(20)">
        <img [src]="createNewSite" />
      </div>
    </div>

    <div (click)="sendPopupTypeEmitter(21)">
      <img [src]="createVirtualSite" />
    </div>
  </div>

  <div *ngIf="componentName === 'zoom'" class="download-icons__right-row download-icons__zoom-row">
    <div *ngIf="cloudRadioProp == 'users'" class="download-icons__zoom-text">
      <div>
        Total # of zoom users without a desktop client installed: {{ totalZoomUsersNoPlugin }} users
      </div>
      <div>Total # of zoom users: {{ totalZoomUsers }} users</div>
    </div>
    <div *ngIf="cloudRadioProp == 'rooms'" class="download-icons__zoom-text">
      <!-- {{ cloudRadioProp }} -->
      <div>
        Total # of zoom rooms without a plugin installed: {{ totalZoomRoomsNoPlugin }} rooms
      </div>
      <div>Total # of zoom rooms: {{ totalZoomRooms }} rooms</div>
    </div>
  </div>

  <div *ngIf="componentName === 'teams'" class="download-icons__right-row download-icons__zoom-row">
    <!-- <div *ngIf="cloudRadioProp != 'users' && cloudRadioProp != 'rooms' ">
      <img src="../../../assets/img/vs_img/beta2.png" alt="">
    </div> -->
    <div *ngIf="cloudRadioProp == 'users'" class="download-icons__zoom-text">
      <div>
        Total # of teams users without a desktop client installed:
        {{ totalTeamsUsersNoPlugin }} users
      </div>
      <div>Total # of teams users: {{ totalTeamsUsers }} users</div>
    </div>
    <div *ngIf="cloudRadioProp == 'rooms'" class="download-icons__zoom-text">
      <!-- {{ cloudRadioProp }} -->
      <div>
        Total # of teams rooms without a plugin installed: {{ totalTeamsRoomsNoPlugin }} rooms
      </div>
      <div>Total # of teams rooms: {{ totalTeamsRooms }} rooms</div>
    </div>
  </div>
</div>