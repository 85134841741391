import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root',
})
export class RefreshApiService {
  private _apiListners = new Subject<any>();
  public refreshStatsEvent: EventEmitter<string> = new EventEmitter();

  refreshAPIListen(): Observable<any> {
    return this._apiListners.asObservable();
  }

  updateApiData(refreshNow: any) {
    this._apiListners.next(refreshNow);
  }
}
