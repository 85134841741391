import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-video-allsites-list-table',
  templateUrl: './video-allsites-list-table.component.html',
  styleUrls: ['./video-allsites-list-table.component.scss'],
})
export class VideoAllsitesListTableComponent implements OnInit {
  constructor(private router: Router, public Auth: AuthService) {}

  @Input() videoSitesListData: MatTableDataSource<any>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] =
    this.Auth.accessLevel == 'SUPERADMIN'
      ? [
          'logo',
          'company',
          'site',
          'health',
          'impacted',
          'infrast',
          'devices',
          'oncall',
          'calendar',
          'livembps',
        ]
      : [
          'logo',
          'site',
          'health',
          'impacted',
          'infrast',
          'devices',
          'oncall',
          'calendar',
          'livembps',
        ];
  Math: any = Math;

  ngOnInit(): void {}

  applyFilter(filterValue: string) {
    if (filterValue == '') this.videoSitesListData.filter = '-'.trim().toLowerCase();
    else this.videoSitesListData.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {
    this.videoSitesListData.filter = '-'.trim().toLocaleLowerCase();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.videoSitesListData.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'company': {
          return item.company_name.toLocaleLowerCase();
        }
        case 'site': {
          return item.site_name.toLocaleLowerCase();
        }
        case 'health': {
          return item.health;
        }
        case 'impacted': {
          return item.impacted;
        }
        case 'infrast': {
          return item.infrastructure;
        }
        case 'calendar': {
          return item.scheduled_meetings;
        }
        case 'livembps': {
          return item.bitrate;
        }
        case 'oncall': {
          return item.oncall;
        }
        case 'model': {
          return item.model;
        }
        case 'ip': {
          return item.ipv4;
        }
        default:
          return item[property];
      }
    };

    this.videoSitesListData.sort = this.sort;
  }

  clickRow(row) {
    this.router.navigate(['video/site/', row.site_id]);
  }
}
