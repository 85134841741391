<div style="padding-top: 5%;">
  <input #videoSiteInputss (keyup)="applyFilter(videoSiteInputss.value)" placeholder="Search..." style="
      width: 100%;
      padding-left: 21px;
      height: 35px;
      font-size: 15px;
      border-bottom: 1px solid lightgray !important;
    " />
  <div class="example-container mat-elevation-z8" style="background-color: white;">
    <table mat-table [dataSource]="videoDevicesListData" matSort class="mat-elevation-z8">
      <ng-container *ngIf="this.Auth.accessLevel == 'SUPERADMIN'" matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black; font-weight: bold;">
          Company
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.company_name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name" style="padding-left: 5px;">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black; font-weight: bold;">
          Name
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.room_name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="health" tyle="padding-left:5px;">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black; font-weight: bold;">
          Health
        </th>
        <td mat-cell *matCellDef="let element">
          <app-table-health-icon [value]="element.health" style="text-align: center;"></app-table-health-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="qos" style="padding-left: 5px;">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black; font-weight: bold;">
          Qos
        </th>
        <td mat-cell *matCellDef="let element">
          <app-table-qos-icon style="text-align: center !important;" [value]="element.qos" style="text-align: center;">
          </app-table-qos-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="site" style="padding-left: 5px;">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black; font-weight: bold;">
          Site
        </th>
        <!-- <td mat-cell *matCellDef="let element">{{element.site_name}}</td> -->
        <td mat-cell *matCellDef="let element" style="text-align: center;">--</td>
      </ng-container>

      <ng-container matColumnDef="livembps" style="padding-left: 5px;">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black; font-weight: bold;">
          Live Mbps
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.total_mbps ? (element.total_mbps / (1024 * 1024) | twoDecimals) : '--' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="oncall" style="padding-left: 5px;">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black; font-weight: bold;">
          On Call
        </th>
        <td mat-cell *matCellDef="let element">
          <app-table-oncall-icon [value]="element.oncall" style="text-align: center;"></app-table-oncall-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="calender" style="padding-left: 5px;">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black; font-weight: bold;">
          Calender
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.scheduled_meetings == 0 ? '--' : element.scheduled_meetings }}
        </td>
      </ng-container>

      <ng-container matColumnDef="model" style="padding-left: 5px;">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black; font-weight: bold;">
          Model
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">{{ element.model }}</td>
      </ng-container>

      <ng-container matColumnDef="ip" tyle="padding-left:5px;">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black; font-weight: bold;">
          IP Address
        </th>
        <td mat-cell *matCellDef="let element">
          <app-table-cog-icon [value]="element.ipv4"
            style="text-align: center; color: #007bff !important;"></app-table-cog-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="clickRow(row)"></tr>
    </table>
  </div>
</div>