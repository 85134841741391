<table class="tooltip-table" style="width:100%;">
  <thead>
    <tr align="center">
      <th>{{userName}}</th>
    </tr>
  </thead>
  <tr>
    <th>Audio</th>
    <td>Jitter</td>
    <td nowrap>Packet Loss</td>
  </tr>
  <tr>
    <td>Tx</td>
    <td>{{audioInputJitter}}</td>
    <td>{{audioInputPacketLoss}}</td>
  </tr>
  <tr>
    <td>Rx</td>
    <td>{{audioOutputJitter}}</td>
    <td>{{audioOutputPacketLoss}}</td>
  </tr>
  <tr>
    <th>Video</th>
    <td>Jitter</td>
    <td nowrap>Packet Loss</td>
    <td nowrap>Frame Rate</td>
  </tr>
  <tr>
    <td>Tx</td>
    <td>{{videoInputJitter}}</td>
    <td>{{videoInputPacketLoss}}</td>
    <td>{{videoInputFrameRate}}</td>
  </tr>
  <tr>
    <td>Rx</td>
    <td>{{videoOutputJitter}}</td>
    <td>{{videoOutputPacketLoss}}</td>
    <td>{{videoOutputFrameRate}}</td>
  </tr>
  <tr>
    <th>Content</th>
    <td>Jitter</td>
    <td nowrap>Packet Loss</td>
  </tr>
  <tr>
    <td>Tx</td>
    <td>{{contentInputJitter}}</td>
    <td>{{contentInputPacketLoss}}</td>
  </tr>
  <tr>
    <td>Rx</td>
    <td>{{contentOutputJitter}}</td>
    <td>{{contentOutputPacketLoss}}</td>
  </tr>

</table>