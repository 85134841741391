import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plantronics-popup',
  templateUrl: './plantronics-popup.component.html',
  styleUrls: ['./plantronics-popup.component.scss']
})
export class PlantronicsPopupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
