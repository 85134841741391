import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { IntegratorService } from "./integrator.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { GetIotService } from "./get-iot.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class NotificationScheduleService {
  server_url: string = environment.serverUrl;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  constructor(
    private _httpService: HttpClient,
    private _integratorService: IntegratorService,
    private _iotSerivce: GetIotService
  ) {}

  getAllAlertsByDevice(body) {
    //Grabs Token from Local Storage
    const token = localStorage.getItem("token");
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const company_id = decodedToken.company_id;
    body.company_id = this._integratorService.isIntegrator()
      ? company_id * -1
      : company_id;
    // if (body?.type === "IOT") {
    //   return this._iotSerivce
    //     .getAllDomotzIotDevicesSummaryList(body.search, body.limit, (body.page - 1) * body.limit, "ALL")
    //     .pipe(map(this.transformData));
    // }
    return this._httpService.post<any>(
      this.server_url + "/api/v1/notification-schedule/get-alerts-by-device",
      body,
      this.httpOptions
    );
  }
  transformData(result: any) {
    const resp = result.length
      ? {
          result,
          message: "Record Found",
          success: true,
        }
      : {
          result,
          message: "Record Not Found",
          success: false,
        };
    return resp;
  }
  notificationSchedule(body, is_reset=false){
    if(is_reset){
      return this._httpService.delete<any>(
        this.server_url + "/api/v1/notification-schedule/notification-schedule",
        {
          body : body,
          ...this.httpOptions
        }
      );
    }
    else if(body.schedule_id){
      return this._httpService.put<any>(
        this.server_url + "/api/v1/notification-schedule/notification-schedule",
        body,
        this.httpOptions
      );
    }
    if(body.isForCompany){
      const token = localStorage.getItem("token");
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      body.company_id = decodedToken.company_id;
    }
    return this._httpService.post<any>(
      this.server_url + "/api/v1/notification-schedule/notification-schedule",
      body,
      this.httpOptions
    );
  }
}
