<div class="logitech-firmware-update">
  <div class="logitech-firmware-update__info">
    <p class="logitech-firmware-update__info__header">Latest Firmware Published Date</p>
    <p>{{ logitechRally.latestFirmwarePublishedDate }}</p>
    <br />
    <p class="logitech-firmware-update__info__header">
      Camera: <span>{{ rallyDevices['Rally Camera'].name }}</span>
    </p>
    <p>Firmware Version: {{ rallyDevices['Rally Camera'].firmwareVersion }}</p>
    <p>Available Firmware Version: {{ rallyDevices['Rally Camera'].availableFirmwareVersion }}</p>
  </div>
  <div class="logitech-firmware-update__info">
    <p class="logitech-firmware-update__info__header">
      Display Hub: <span>{{ rallyDevices['Rally Display Hub'].name }}</span>
    </p>
    <p>Firmware Version: {{ rallyDevices['Rally Display Hub'].firmwareVersion }}</p>
    <p>
      Available Firmware Version: {{ rallyDevices['Rally Display Hub'].availableFirmwareVersion }}
    </p>
    <br />
    <p class="logitech-firmware-update__info__header">
      Table Hub: <span>{{ rallyDevices['Rally Table Hub'].name }}</span>
    </p>
    <p>Firmware Version: {{ rallyDevices['Rally Table Hub'].firmwareVersion }}</p>
    <p>
      Available Firmware Version: {{ rallyDevices['Rally Table Hub'].availableFirmwareVersion }}
    </p>
  </div>
  <div class="logitech-firmware-update__info">
    <p class="logitech-firmware-update__info__header">
      MIC: <span>{{ rallyDevices['Rally Mic Pod'].name }}</span>
    </p>
    <p>Firmware Version: {{ rallyDevices['Rally Mic Pod'].firmwareVersion }}</p>
    <p>Available Firmware Version: {{ rallyDevices['Rally Mic Pod'].availableFirmwareVersion }}</p>
  </div>
  <div class="logitech-firmware-update__update-btn-container">
    <button class="btn btn-primary" (click)="updateRallyFirmware()">
      Update
    </button>
    <p class="logitech-firmware-update__update-btn-container__header">
      Firmware Package Version Currently Installed
    </p>
    <p>{{ logitechRally.firmwarePackageVersion }}</p>
    <p class="logitech-firmware-update__update-btn-container__header">
      Latest Firmware Package Version
    </p>
    <p>{{ logitechRally.latestFirmwarePackageVersion }}</p>
    <div *ngIf="progressBarPercentage > 0">
      <span>{{ progressBarPercentage }} %</span>
      <mat-progress-bar mode="determinate" [value]="progressBarPercentage"></mat-progress-bar>
    </div>
    <div
      [ngClass]="{
        'ok': message.status === 'ok',
        'error': message.status === 'error'
      }"
      *ngIf="message.text.length > 0"
    >
      {{ message.text }}
    </div>
  </div>
</div>
