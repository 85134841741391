<div class="video-system-status">
  <h3 class="text-center title">System Status</h3>
  <div class="row video-system-status-content">
    <div class="col active-status">
      <img src="../../../../assets/img/teleportivity_img/icon_camera.png" alt="" />
      <span>{{ cameras }}</span>
    </div>
    <div class="col impacted-status">
      <p>{{ camerasImpacted }} Down</p>
    </div>
  </div>
  <div class="row video-system-status-content">
    <div class="col active-status">
      <img src="../../../../assets/img/teleportivity_img/icon_microphone.png" alt="" />
      <span>{{ mics }}</span>
    </div>
    <div class="col impacted-status">
      <p>{{ micsImpacted }} Down</p>
    </div>
  </div>
  <div class="row video-system-status-content" *ngIf="isShowTemperature">
    <div class="col temperature-status">
      <img src="{{ temperatureImage }}" alt="" />
    </div>
    <div class="col temperature-value">
      <p>{{ temperature * 1.8 + 32 | number: '1.0-0' }}&#8457; / {{ temperature }}&#8451;</p>
    </div>
  </div>
  <div class="temperature-text" *ngIf="isShowTemperature">
    {{ temeratureMsg }}
  </div>
</div>
