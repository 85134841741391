<div class="iotalert">
  <div *ngIf="showSuccesMessage">
    <h4>Configuration Saved!</h4>
  </div>
  <table>
    <tbody>
      <tr>
        <th>RTD</th>
      </tr>

      <tr>
        <td>warning</td>
        <td class="iotalert__slider">
          <ng5-slider
            [(value)]="medianWarning"
            [options]="{
              floor: medianMin,
              ceil: medianMax
            }"
            (valueChange)="medianEvent($event, 'warning')"
          >
          </ng5-slider>
        </td>
      </tr>
      <tr>
        <td>alert</td>
        <td class="iotalert__slider">
          <ng5-slider
            [(value)]="medianAlert"
            [options]="{
              floor: medianMin,
              ceil: medianMax
            }"
            (valueChange)="medianEvent($event, 'alert')"
          >
          </ng5-slider>
        </td>
      </tr>
      <tr>
        <th>PacketLoss</th>
      </tr>

      <tr>
        <td>warning</td>
        <td class="iotalert__slider">
          <ng5-slider
            [(value)]="packetLossWarning"
            [options]="{
              floor: packetLossMin,
              ceil: packetLossMax
            }"
            (valueChange)="packetLossEvent($event, 'warning')"
          >
          </ng5-slider>
        </td>
      </tr>
      <tr>
        <td>alert</td>
        <td class="iotalert__slider">
          <ng5-slider
            [(value)]="packetLossAlert"
            [options]="{
              floor: packetLossMin,
              ceil: packetLossMax
            }"
            (valueChange)="packetLossEvent($event, 'alert')"
          >
          </ng5-slider>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="iotalert__btn_ctr">
    <button class="iotalert__btn" (click)="saveAlert()">Save</button>
    <button class="iotalert__btn" (click)="resetAlerts()">
      Reset to default
    </button>
  </div>
</div>
