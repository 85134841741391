import { AuthService } from 'src/app/services/auth.service'

import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import { GetIotService } from '../../services/get-iot.service'
import { WebportalProtocolConfigDialogComponent } from '../dialogs/webportal-protocol-config-dialog/webportal-protocol-config-dialog.component'

export interface DeviceListInterface {
  agent_id: number;
  display_name: string;
  iot_device_id: number;
  ip_address: string;
  make: string;
  device_name: string;
  model: string;
  ping_rtd: number;
  status: string;
  company_name: string;
}

@Component({
  selector: 'app-iot-space-panel',
  templateUrl: './iot-space-panel.component.html',
  styleUrls: ['./iot-space-panel.component.scss']
})
export class IotSpacePanelComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() filterStatus: String;
  @Input() filterAgent: number;
  @Input() iotLiteEnabled: any;
  
  displayedColumns: string[];
  dataSource: MatTableDataSource<DeviceListInterface>;
  deviceList: any = [];
  pageSizeSelect: any[] = [];
  totalPageLimit = 100;
  paginateOjb: any = {
    currentPage: 1,
    endIndex: 4,
    pageSize: 0,
    pages: [],
    startIndex: 0,
    totalItems: 0,
    totalPages: 0,
  };
  // iotLiteEnabled: any;
  totalCount: number;
  pageSelected: any = 0;
  searchKey: String = 'null';
  refreshData: Boolean;
  greenIcon = '../../../assets/img/triangle_green.png';
  redIcon = '../../../assets/img/triangle_red.png';
  
  faCogIcon: any = faCog;
  id: any;

  constructor(
    private getIot: GetIotService,
    public Auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.displayedColumns = this.isShowCompanyName()
      ? [
        'company_name',
        'display_name',
        'device_name',
        'status',
        'agent_name',
        'ip_address',
        'mac',
        'make',
        'model',
      ]
      : [
        'display_name',
        'device_name',
        'status',
        'agent_name',
        'ip_address',
        'mac',
        'make',
        'model',
      ];
      
          this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.dataSource = new MatTableDataSource(this.deviceList);
    if(changes.filterStatus && this.filterStatus) {
       this.getAgentLinkToSpaces();
    }
  }

  ngOnInit() {
  
  }
   
   
  // getIotLiteStatus = () => {
  //   if (this.getIot.getCompanyId() == -1) { //why axe?
  //     this.iotLiteEnabled = 1;
  //     this.getAgentLinkToSpaces();

  //   } else {
  //     this.getIot.getIotLiteStatus().subscribe((data: any) => {
  //       let [res] = data.response;
  //       if (res !== undefined) {
  //         this.iotLiteEnabled = res.is_iot_lite;
  //         this.getAgentLinkToSpaces();

  //       }
  //     });
  //   }
  // };


  getAgentLinkToSpaces = () => {
  
    // console.log( this.id,  this.searchKey,  this.totalPageLimit,
    //     this.paginateOjb.startIndex,)
    
    this.getIot
      .getAgentLinkToSpaces(
        this.searchKey,
        this.id,
        this.totalPageLimit,
        this.paginateOjb.startIndex,
        0,
        
      )
      .subscribe((data:any) => {
        const newRes = data.filter( e => e.active);
        
        this.deviceList = newRes.map(e => {
          return {
            ...e,
            display_name: e.is_domotz ? e.display_name : e.iot_name,
            ip_address: e.is_domotz ? e.ip_address : e.iot_ip,
            mac: e.is_domotz ? e.mac : e.mac_address,
        
          }
        
        }).filter( e => e.is_monitored)
        
        
        console.log('newRes: ', newRes)
        
        
       
        this.dataSource = new MatTableDataSource(this.deviceList);
        this.dataSource.sort = this.sort;
      });
  };

  searchData = (key) => { 
    this.searchKey = key;
    this.getAgentLinkToSpaces();
  };

  clickRow(params) {
    //check for iot enabled
    console.log(params)
    if(this.iotLiteEnabled) {
      const { iot_id } = params;
       this.router.navigate(['iot/lite/device/', iot_id]);
    } else {
      const { iot_device_id } = params;
    this.router.navigate(['iot/device/', iot_device_id]);
    
    }
  }

  openWebPortalOptions = (iotId) => {
    console.log('iotDeviceId: ', iotId)
    const webportal = this.dialog.open(WebportalProtocolConfigDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        iotId,
      },
    });
  };

  isShowCompanyName = () => {
    return this.Auth.accessLevel === 'SUPERADMIN';
  };


}
