import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-btn-long-meetings',
  templateUrl: './btn-long-meetings.component.html',
  styleUrls: ['./btn-long-meetings.component.scss']
})
export class BtnLongMeetingsComponent implements OnChanges {
  @Input() count: number = 0;
  @Input() label: string = "Not-Active";
  @Input() backcolorbtn: any;
  @Input() isButton: boolean = false;
  prevcount: string;
  
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.count.currentValue != changes.count.previousValue ) {
      this.prevcount = changes.count.previousValue;
        this.count = changes.count.currentValue,
        // // console.log(changes.count.currentValue + ", pre" + changes.count.previousValue)
          // 1 second delay to display the first set of values
          setTimeout(() => {
            this.backcolorbtn = "btnbacklite"
            // // console.log(this.backcolorbtn + "backcolorbtn")
            }, 100);
            setTimeout(() => {
              this.backcolorbtn = "btnbacklite-default"
              // // console.log(this.backcolorbtn + "backcolorbtn")
              }, 2000);
    }else{
      setTimeout(() => {
        this.backcolorbtn = "btnbacklite-default"
        // // console.log(this.backcolorbtn + "backcolorbtn")
        }, 1000);
    }
  }

}
