import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

import { PopupTypeService } from '../../services/popupType.service'

@Component({
  selector: 'app-video-life-time-usage',
  templateUrl: './video-life-time-usage.component.html',
  styleUrls: ['./video-life-time-usage.component.scss']
})
export class VideoLifeTimeUsageComponent implements OnInit, OnChanges {
  @Input() videoDeviceData: any;
  @Input() hasAccess: any;
  
  isNotButton: string;
  
  constructor(
    public ds: PopupTypeService,
  
  ) { }
  
  

  ngOnInit(): void {
  }
  
  	ngOnChanges(changes: SimpleChanges) {
		if (changes.videoDeviceData && this.videoDeviceData) {
			// console.log('videoDeviceData last call: ', this.videoDeviceData)
			this.setData()
		}
	}
	
	setData = () => {
	    this.isNotButton = !this.hasAccess ? 'not' : 'yes';
	}
	
	openCallLog = (t) => {
    this.ds.sendPopupType(37, { deviceId: this.videoDeviceData.video_device_id, type: t }, this.hasAccess);
  }
  
  formatLifetimeUsage = (str) => {
    if (!str) return;
    let splits = str.split(':');

    let ret = [];
    let nr = [];

    if (splits[0] >= 24) {
      nr.push(Math.floor(splits[0] / 24));
      splits[0] = splits[0] % 24;
    } else {
      nr.push('00');
    }

    if (splits.length > 3) {
      ret.push(splits[0]);
      ret.push(splits[1]);
      ret.push(splits[2]);
    } else if (splits.length == 3) {
      ret.push(nr[0]);
      //ret.push('00')
      ret.push(splits[0]);
      ret.push(splits[1]);
    } else {
      return str;
    }
    return ret.join(':');
  }



}
