import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-video-display-logitech',
  templateUrl: './video-display-logitech.component.html',
  styleUrls: ['./video-display-logitech.component.scss']
})
export class VideoDisplayLogitechComponent implements OnInit, OnChanges {

@Input() logitechData: any;
  onlineIcon: string = '../../../../assets/img/touch_icon/logitech_online.png';
  offlineIcon:string = '../../../../assets/img/touch_icon/logitech_offline.png';
  touchIcon: string = this.offlineIcon;
  displayName: any;
  displayMac: any;
  displayFirmware: any;
  displayBatteryLevel: any;
  
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges) {
    if(changes.logitechData && this.logitechData) {
      this.setData();

    }
  }
  
  setData = () => {
    // console.log('VideoTouchPanelLogitech: ', this.logitechData)
    const { settings: { peripherals } } = this.logitechData
    
    if(peripherals) {
      if(peripherals.length > 0) {
        const [getdisplay] = peripherals.filter( e => e.type == 'REMOTE')
        this.displayName = getdisplay.name
        this.displayMac = getdisplay.macAddress
        this.displayBatteryLevel = getdisplay.batteryLevel
        // this.touchIcon = this.onlineIcon;
        // this.displayPowerSaving = getTap.tapCustomOrientation
      }
    
    }
    
  }

}
