<div id="chartParent" class="info-chartSite pt-3 mb-3 chart-container" style="cursor: auto !important;">
  <div class="printer-border print-btn">
    <i class="fa fa-print fa-2x menu-icon" (click)="print()"></i>
  </div>
  <div style="margin-left: 600px;">
    <button (click)="set60m();" [ngClass]="{'btnpressed': activeBtn == 1}">
      60m
    </button>
    <button (click)="set24h();" [ngClass]="{'btnpressed': activeBtn == 2}">
      24h
    </button>
    <button (click)="set7d();" [ngClass]="{'btnpressed': activeBtn == 3}">
      7d
    </button>
    <div style="padding-left: 50px !important; display: inline">
      <label class="switch">
        <input type="checkbox" [checked]="toggleValue==0" (click)="toggleView(1)">
        <span class="slider round">
          <span class="paused">chart</span>
          <span class="enabled" style="padding-right: 200px;">list</span>
        </span>
      </label>
    </div>
  </div>

  <div class="list-view" *ngIf="toggleValue==1" style="display:none; cursor: pointer!important;"
    [ngStyle]="{display: toggleValue==1 ? 'block' : 'none'}">
    <div class="row">
      <!-- <app-filter-table id="printTable" [tableSeedData]="printTableData"
        [tableSettings]="QosTableSetting"></app-filter-table> -->
    </div>
  </div>
  <!-- <div style="font-size: .5em">*** Chart contains sample data</div> -->
  <canvas id="myChartSite" width="1150px" height="350px" style="padding-left: 150px"
    [ngStyle]="{display: toggleValue==0 ? 'block' : 'none'}"></canvas>
</div>