<div class="cloud-user-console">
  <div class="cloud-user-console__disable" *ngIf="isDisable" (click)="displayDisableMessage()"></div>
  <div class="cloud-user-console__title">
    <h4>Console</h4>

    <button (click)="run()" [disabled]="getLoader()">
      <span *ngIf="!getLoader()">Run</span>
      <img *ngIf="getLoader()" src="{{ loader }}" alt="" />
    </button>
  </div>
  <div class="cloud-user-console__input_text">
    Origination IP: <span>{{ originationIp }}</span>
  </div>
  <div class="cloud-user-console__input_text">
    Destination IP: <span><input type="text" class="destination-ip" [(ngModel)]="host" /></span>
  </div>
  <div class="cloud-user-console__input_text" *ngIf="isShowDscp()">
    {{ actionName === 'SimulateCallQoS' ? 'DSCP' : 'PORT' }} Value: <br>
    <span><input type="text" class="destination-ip" [(ngModel)]="dscp" /></span>
  </div>

  <div class="cloud-user-console__btn_ctr">
    <mat-radio-group [(ngModel)]="actionName">
      <mat-radio-button value="Ping">Ping </mat-radio-button>
      <mat-radio-button value="Traceroute">Traceroute</mat-radio-button>

      <!-- <mat-radio-button class="cloud-user-console__btn_item-checkbox-2" value="SimulateCallQoS">QoS Test
      </mat-radio-button>
      <mat-radio-button class="cloud-user-console__btn_item-checkbox-2" value="SimulatePortTest">UDP Firewall Trace
      </mat-radio-button> -->
    </mat-radio-group>
  </div>

  <div *ngIf="isRunButtonClicked; else runButtonNotClicked" class="cloud-user-console__output">
    <p *ngFor="let result of getActionResults()">
      {{ result }}
    </p>
  </div>
  <ng-template #runButtonNotClicked>
    <div class=cloud-user-console__output></div>
  </ng-template>
</div>