import { Component, Input, OnInit, Output, SimpleChanges } from '@angular/core'

import { PopupTypeService } from '../../services/popupType.service'

@Component({
	selector: 'app-video-device-live-stats',
	templateUrl: './video-device-live-stats.component.html',
	styleUrls: ['./video-device-live-stats.component.scss'],
})
export class VideoDeviceLiveStatsComponent implements OnInit {
	@Input() videoDeviceData: any
	@Output() not: any = 'not'
	id: number
	accessLevel: any
	isNotButton: string
	hasAccess: boolean
	liveMpbs: any

	contentRXParsed: any
	contentTXParsed: any
	audioRXParsed: any
	audioTXParsed: any
	videoRXParsed: any
	videoTXParsed: any

	liveStats = ['Video', 'Audio', 'Content'].map((e, i) => ({
		label: e,
		color: 'gray',
		expanded: i === 0,
		rx: [],
		tx: [],
	}))

	constructor(public ds: PopupTypeService) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (!changes.videoDeviceData || !this.videoDeviceData) return
		this.setData()
	}

	getStatsArray = (type: 'video' | 'audio' | 'content', sub: 'RX' | 'TX') => {
		const template = [
			{
				value: 'mbps',
				label: 'Live Mbps',
			},
			{
				value: 'jitter',
				label: 'Jitter(msec)',
			},
			{
				value: 'packetloss',
				label: 'Packetloss(%)',
			},
			{
				value: 'latency',
				label: 'Latency',
			},
		]

		const val = this[`${type}${sub}Parsed`]

		if (val?.mbps) val.mbps = val.mbps / (1024 * 1024)
		if (val?.packetsloss) val.packetloss = val.packetsloss

		return template.map(e => ({
			chart: `${e.value}_in`,
			value: val ? val[e.value] : null,
			name: e.value,
			sub,
			label: e.label,
			color: this.setColors(e.value, sub, type),
		}))
	}

	setData = () => {
		this.contentRXParsed = this.videoDeviceData.contentRx !== null ? JSON.parse(this.videoDeviceData.contentRx) : null
		this.contentTXParsed = this.videoDeviceData.contentTx !== null ? JSON.parse(this.videoDeviceData.contentTx) : null
		this.audioRXParsed = this.videoDeviceData.audioRx !== null ? JSON.parse(this.videoDeviceData.audioRx) : null
		this.audioTXParsed = this.videoDeviceData.audioRx !== null ? JSON.parse(this.videoDeviceData.audioTx) : null
		this.videoRXParsed = this.videoDeviceData.videoRx !== null ? JSON.parse(this.videoDeviceData.videoRx) : null
		this.videoTXParsed = this.videoDeviceData.videoRx !== null ? JSON.parse(this.videoDeviceData.videoTx) : null

		const videoLiveStatsRx = this.getStatsArray('video', 'RX')
		const videoLiveStatsTx = this.getStatsArray('video', 'TX')

		const audioLiveStatsRx = this.getStatsArray('audio', 'RX')
		const audioLiveStatsTx = this.getStatsArray('audio', 'TX')

		const contentLiveStatsRx = this.getStatsArray('content', 'RX')
		const contentLiveStatsTx = this.getStatsArray('content', 'TX')

		this.liveStats[0].rx = videoLiveStatsRx
		this.liveStats[0].tx = videoLiveStatsTx
		this.liveStats[1].rx = audioLiveStatsRx
		this.liveStats[1].tx = audioLiveStatsTx
		this.liveStats[2].rx = contentLiveStatsRx
		this.liveStats[2].tx = contentLiveStatsTx

		this.liveStats.map(e => {
			const rxValues = e.rx.map(x => x.value)
			const txValues = e.tx.map(x => x.value)

			const values = [...rxValues, ...txValues]

			if (values.every(x => [0, undefined, null].includes(x))) {
				e.color = 'gray'
				e.expanded = false
				return
			}

			const rxColors = e.rx.map(x => x.color)
			const txColors = e.tx.map(x => x.color)

			const colors = [...rxColors, ...txColors]

			if (colors.includes('red')) {
				e.color = 'red'
			} else if (colors.includes('yellow')) {
				e.color = 'yellow'
			} else {
				e.color = 'green'
			}
		})
	}

	openChart = params => {
		this.ds.sendPopupType(31, {
			deviceId: this.videoDeviceData.video_device_id,
			section: params,
			type: this.videoDeviceData.device_type,
		})
	}

	setColors = (type, sub, stream) => {
		if (type == 'mbps') return 'gray'

		const val = this[`${stream}${sub}Parsed`]?.[`${type}`]
		if (!val) return 'gray'

		const newTrigs = this.videoDeviceData?.triggers?.[stream]
		const oldTrigs = this.videoDeviceData?.triggers

		const triggers = newTrigs ? newTrigs : oldTrigs

		triggers.packetloss = triggers.packetsloss

		const warning = newTrigs ? triggers[type][sub.toLowerCase()].warning : triggers[type].warning
		const alert = newTrigs ? triggers[type][sub.toLowerCase()].alert : triggers[type].alert

		if (val >= alert) {
			return 'red'
		} else if (val >= warning) {
			return 'yellow'
		} else {
			return 'gray'
		}
	}
}
