import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-btn-service',
  templateUrl: './btn-service.component.html',
  styleUrls: ['./btn-service.component.scss']
})
export class BtnServiceComponent implements OnChanges {
  @Input() presence;
  isDisplay:boolean = false;
  pTitle:string = '';
  constructor() { }

  ngOnChanges(changes: SimpleChanges):void {
    if (changes.presence.currentValue !== changes.presence.previousValue) {
      this.isDisplay = false;
      this.pTitle = this.presence;
      if(this.presence.length > 0){
        this.isDisplay = true;
      }
    }
  }
}
