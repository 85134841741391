import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-device-call-status',
  templateUrl: './device-call-status.component.html',
  styleUrls: ['./device-call-status.component.scss'],
})
export class DeviceCallStatusComponent implements OnInit, OnChanges {
  @Input() callData: any;
  @Input() deviceType: string;
  @Input() sipGateColor: string;
  @Input() sipGateText: string;
  @Input() isShowSip: boolean;
  @Input() connectionType: string;
  @Input() connectionTypeColor: string;
  callStatsData: any;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.callStatsData = { ...this.callData };
  }

  ngOnInit() {}
}
