import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { PopupTypeService } from '../../../services/popupType.service';

@Component({
  selector: 'app-btn-device-logitech',
  templateUrl: './btn-device-logitech.component.html',
  styleUrls: ['./btn-device-logitech.component.scss'],
})
export class BtnDeviceLogitechComponent implements OnChanges {
  @Input() logitechData: any;
  @Input() pluginStatus: number;
  @Input() pluginEnabled: number;
  @Input() pluginId: number;
  isShowLogitech: boolean = false;
  rallyPackageFirmwareVersion: number;
  latestFirmwarePackageVersion: number;

  constructor(public ds: PopupTypeService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.logitechData && this.logitechData !== null) {
      // console.log('StartTeams: ', this.logitechData)
      this.init();
    }

    if (this.logitechData !== null && this.pluginEnabled) {
      this.isShowLogitech = true;
    } else {
      this.isShowLogitech = false;
    }
  }

  init() {
    const logictechDevices = [...this.logitechData.AllProducts.Products];
    const latestFirmwareByProductId = [...this.logitechData.LatestFirmwareByProductId];
    const rally = logictechDevices.find((e) => e.Name === 'Rally');
    const rallyUuid = rally.Uuid;
    this.rallyPackageFirmwareVersion = rally.FirmwarePackageVersion;

    // rally latest firmware
    const rallyLatestFirmware = latestFirmwareByProductId.find((e) => e.ProductUuid === rallyUuid) || {};
    // if (rallyLatestFirmware) {
    // console.log('rallyLatestFirmware', rallyLatestFirmware)

    this.latestFirmwarePackageVersion = rallyLatestFirmware.LatestFirmwarePackageVersion;
    // console.log('latestFirmwarePackageVersion', this.latestFirmwarePackageVersion)

    // }
  }

  firmwareUpdate() {
    const data = { ...this.logitechData, pluginStatus: this.pluginStatus, pluginId: this.pluginId };
    this.ds.sendPopupType(68, {
      data,
    });
  }
}
