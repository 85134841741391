import { Component, OnInit, Output } from '@angular/core';

import { PopupTypeService } from '../../services/popupType.service';
import { HealthblockUpperLeft } from '../../services/HealthBlockUpperLeft.animation';
import { HealthblockUpperRight } from '../../services/HealthBlockUpperRight.animation';
import { HealthblockLowerLeft } from '../../services/HealthBlockLowerLeft.animation';
import { HealthblockLowerRight } from '../../services/HealthBlockLowerRight.animation';
import { faCamera, faUser } from '@fortawesome/free-solid-svg-icons';
import { faMehBlank } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-components-all',
  templateUrl: './components-all.component.html',
  styleUrls: ['./components-all.component.scss'],
  animations: [HealthblockUpperLeft, HealthblockUpperRight, HealthblockLowerLeft, HealthblockLowerRight]
})
export class ComponentsAllComponent implements OnInit {
  @Output() backcolorbtn: any;
  popupType: any;

  //health panel bars
  healthBarGreenTotal = 30;
  healthBarGreenValue = 3;
  healthBarYellowTotal = 33;
  healthBarYellowValue = 33;
  healthBarRedTotal = 100;
  healthBarRedValue = 90;
  healthBarBlueTotal = 50;
  healthBarBlueValue = 25;
  //health panel btn
  healthBarImpactCount = 22;
  healthBarImpactLabel = "Impacted Sites";
  healthBarImpactColor = "gray";
  healthBarAllSitesCount = 33;
  healthBarAllSitesLabel = "All Sites";
  healthBarAllSitesColor = "gray";
  healthBarAllDevicesCount = 9;
  healthBarAllDevicesLabel = "All Devices";
  healthBarAllDevicesColor = "gray";
  //audio users impacted
  audioImpactUsersCount = 22;
  audioImpactUsersLabel = "Impacted Sites";
  audioImpactUsersColor = "gray";
  //audio device count
  audioDeviceCount = 1;
  //cloud room dna
  cloudRoomLiveMbpsCount = 33;
  cloudRoomLiveMbpsLabel = "Live Mpbs";
  cloudRoomLiveMbpsColor = "green";
  cloudRoomJitterCount = 3;
  cloudRoomJitterLabel = "Jitter";
  cloudRoomJitterColor = "orange";
  cloudRoomPacketLossCount = 3;
  cloudRoomPacketLossLabel = "Packet Loss";
  cloudRoomPacketLossColor = "red";
  cloudRoomLatencyCount = 3;
  cloudRoomLatencyLabel = "Latency";
  cloudRoomLatencyColor = "red";
  //meeting count
  LongMeetingCount = 12;
  //rounded buttons
  typebtnClose = "close"
  typebtnSearch = "search"
  typebtnPdf = "pdf"
  typebtnEmail = "email"
  //temp and system health
  tempValue: number = 10;
  sysHealthValue: number = 31;
  //device health button
  btnDeviceHealth: number = 0;
  micImpacted: number = 0;
  micConnected: number = 0;
  //bluetooth value
  bluetoothCount: number = 1;
  //button stats call
  statCallCount: number = 0;
  //utilization buttons
  utilizationAdhocCount = 3;
  utilizationScheduled = 22;
  utilizationTotalUsage = 99;

  //info uptime values
  uptimeDays = 1;
  uptimeHours = 20;
  uptimeMins = 30;

  // audio live stats button
  audiomuteValue = 1;
  audioName = "EncorePro 700 Digital Series";
  activeDeviceV = 1;

  // audio firmware user and devices
  audioFirmwareUsers = 10;
  audioFirmwareDevices1 = 20;
  audioFirmwareDevices2 = 30;
  //meeting schedule popup
  date = "12/25/2018";
  time = "12:00a";
  description = "Scheduled Meetings - Next Level Buzz All Sites";
  site = "Malibu Beach"
  orgina
  // popup random number
  randomPop = 10;

  // main menu animation
  MainMenuState = "open";
  currentState = "home";
  menuStateNote2 = 'close';
  menuState = 'close';
  // health block states
  HealthBlockStateUpLt = 'home';
  HealthBlockStateUpRt = 'home';
  HealthBlockStateLwLt = 'home';
  HealthBlockStateLwRt = 'home';
  faUserIcon = faUser;
  faBlankIcon = faMehBlank;
  faCameraIcon = faCamera;
  togglePopups() {
    // 1-line if statement that toggles the value:
    this.menuState = this.menuState === 'open' ? 'close' : 'open';
  }
  toggleMainPopups() {
    // 1-line if statement that toggles the value:
    this.menuStateNote2 = this.menuStateNote2 === 'open' ? 'close' : 'open';
  }

  animationHome() {
    //timer for background blue color on reg stat buttons
    this.HealthBlockStateUpLt = this.HealthBlockStateUpLt === 'home' ? 'left' : 'home';
    setTimeout(() => {
      this.HealthBlockStateUpRt = this.HealthBlockStateUpRt === 'home' ? 'left' : 'home';
    }, 400);
    setTimeout(() => {
      this.HealthBlockStateLwLt = this.HealthBlockStateLwLt === 'home' ? 'left' : 'home';
    }, 800);
    setTimeout(() => {
      this.HealthBlockStateLwRt = this.HealthBlockStateLwRt === 'home' ? 'left' : 'home';
    }, 1200);
  }

  colorChange() {
    //timer for background blue color on reg stat buttons
    setInterval(() => {
      this.audioImpactUsersCount += 10;
    }, 3500);
    setInterval(() => {
      this.cloudRoomLiveMbpsCount += 3;
    }, 5000);
    setInterval(() => {
      this.LongMeetingCount += 1;
    }, 4500);
    setInterval(() => {
      this.healthBarGreenValue = Math.floor((Math.random() * 30) + 0);
    }, 4200);
  }
  randomNumber() {
    //random numbers and temp
    this.randomPop = Math.floor(Math.random() * 10);
    this.activeDeviceV = Math.floor(Math.random() * 2);
    this.audiomuteValue = Math.floor(Math.random() * 2);
    // console.log(this.activeDeviceV + " activedeviceV" + this.audiomuteValue);
    this.tempValue = Math.floor((Math.random() * 100) + 1);
    this.sysHealthValue = Math.floor((Math.random() * 100) + 1);
    this.statCallCount = Math.floor((Math.random() * 3) + 0);
    this.audioDeviceCount = Math.floor((Math.random() * 2) + 0);
    this.bluetoothCount = Math.floor((Math.random() * 4) + 0);
    this.healthBarGreenValue = Math.floor((Math.random() * 30) + 0);
    this.LongMeetingCount += 1;
    this.cloudRoomLiveMbpsCount += 3;
    this.audioImpactUsersCount += 10;
    this.btnDeviceHealth += 1;
    this.micImpacted += 2;
    this.micConnected += 3;
    // console.log(this.bluetoothCount + " blue, callcount" + this.statCallCount + ": statCallCount" + ", audio:" + this.audioDeviceCount)
  }

  constructor(private ds: PopupTypeService) {
  }

  activeBtn = 0;

  setActive(n) {
    if (this.activeBtn == n) {
      this.activeBtn = 0;
      // console.log(n);
    } else {
      this.activeBtn = n;
      // console.log(n);
    }
  }

  ngOnInit() {
    // send message to subscribers via observable subject
    this.ds.sendPopupType(0);
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
    // console.log(p)
  }

  ngOnDestroy() {
    // clear message
    this.ds.clearPopupType();
  }

  clearPopupType() {
    // clear message
    this.ds.clearPopupType();
  }

}
