import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-video-allsites-info-panel',
  templateUrl: './video-allsites-info-panel.component.html',
  styleUrls: ['./video-allsites-info-panel.component.scss']
})
export class VideoAllsitesInfoPanelComponent implements OnInit {

  @Input() videoSitesSummary: any;

  constructor() { }

  ngOnInit() {
  }

}
