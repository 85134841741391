import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-new-home-login',
  templateUrl: './new-home-login.component.html',
  styleUrls: ['./new-home-login.component.scss'],
})
export class NewHomeLoginComponent implements OnInit {
  email: any;
  password: any;

  message: any = { text: 'Email address not found', flag: false, type: 1 };
  dashboardLogo = environment.config.dynamicImage.dashboardLogo;

  links = environment.config.links;
  dTerms = environment.config.dynamicText.dTerms;
  bgImageNew = environment.config.dynamicImage.bgImageNew;

  loginEnv: any = environment.config.title === 'VisibilityOne';
  isVcare: any = false;
  isVspoint: any = false;
  isVidelio: any = false
  isDekom: any = false

  darkTheme: any = true;


  visibilityBackground = {
    'background-image': 'url(' + this.bgImageNew + ')',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
    'background-attachment': 'fixed',
    'background-size': 'cover',
    height: '88.5vh',
  };

  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.checkRouterUrl();
  }

  ngOnInit() {
    this.Auth.shouldShowCreateNewAccount = true;
    this.setEnvirontment();
  }

  setEnvirontment = () => {
    if (environment.config.title === 'vCare') {
      this.isVcare = true;
    }

    if (environment.config.title === 'Vision Point') {
      this.isVspoint = true;
    }

    if (environment.config.title === 'Videlio') {
      this.isVidelio = true;
    }

    if (environment.config.title === 'DEKOM') {
      this.isDekom = true;
    }
  }

  checkRouterUrl = () => {
    this.route.queryParams.subscribe((params) => {
      const message = params.message
      const flag = params.flag
      const type = params.type
      if (flag) {
        this.setMessageForLogin(message, type)
      }
    });
  };

  setMessageForLogin = (message, type) => {
    if (message) {
      this.message = {
        text: message,
        flag: true,
        type: type,
      };

      setTimeout(() => {
        this.router.navigate(['logout']);
      }, 7000);
    }
  };
}
