<div class="confirm" [ngStyle]="visibilityBackground">
  <div class="confirm__col">
    <div class="confirm__box" *ngIf="shouldShowActivated">
      <div>
        <div class="confirm__text">
          <h3>Account has been activated</h3>
        </div>
        <hr />
        <div class="confirm__input-ctr">
          <button
            class="confirm__btn-primary"
            routerLink="/login"
          >
            Return to Login
          </button>
        </div>
      </div>
    </div>

    <div class="confirm__box" *ngIf="shouldShowError">
      <div>
        <div class="confirm__text">
          <h3>Oh, snap!</h3>
        </div>
        <hr />
        <div class="confirm__text confirm__link-fa">
          <i class="fa fa-frown-o register__icon"></i>
          <h5>Invalid Link</h5>
        </div>
      </div>
    </div>
  </div>
</div>
