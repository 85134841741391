<div class="zoom-system-status">
  <h3 class="text-center title">System Status</h3>
  <div class="row zoom-system-status-content">
    <div class="col active-status">
      <img src="../../../../assets/img/teleportivity_img/icon_camera.png" alt="" />
      <span>{{ cameras }}</span>
    </div>
    <div class="col impacted-status">
      <p>{{ camerasImpacted }} Down</p>
    </div>
  </div>
  <div class="row zoom-system-status-content">
    <div class="col active-status">
      <img src="../../../../assets/img/teleportivity_img/icon_microphone.png" alt="" />
      <span>{{ mics }}</span>
    </div>
    <div class="col impacted-status">
      <p>{{ micsImpacted }} Down</p>
    </div>
  </div>
  <div class="row zoom-system-status-content">
    <div class="col active-status">
      <img src="../../../../assets/img/teleportivity_img/icon_volume.png" alt="" />
      <span>{{ speakers }}</span>
    </div>
    <div class="col impacted-status">
      <p>{{ speakersImpacted }} Down</p>
    </div>
  </div>
</div>
