import { Pipe, PipeTransform } from '@angular/core'
import moment from 'moment-timezone'
import { AuthService } from '../services/auth.service'

@Pipe({
	name: 'transformTimeInGivenZoneToAnother',
})
export class TransformTimeInGivenZoneToAnotherPipe implements PipeTransform {
	constructor(private auth: AuthService) {}

	// 	const testOffsets = [
	//     'UTC-07:00', 'UTC-05:00', 'UTC-04:00', 'UTC-03:00',
	//     'UTC 10:00', 'UTC 08:00', 'UTC 01:00', 'UTC 00:00',
	//     '05:30', '-07:00', '-06:00', '-05:00', '-04:00', '-01:00', ' 08:00', ' 05:30'
	// ];
	// All the above inconsistent 'testOffsets' will be normalized acc. to moment standardised utcOffset using 'convertOffsetToMomentFormat' method
	convertOffsetToMomentFormat(offset) {
		const match = offset.match(/(?:GMT)?([+-]?\d{1,2}):?(\d{2})?/)

		if (match) {
			const sign = match[1] === '-' ? -1 : 1
			const hours = parseInt(match[1], 10) || 0
			const minutes = parseInt(match[2], 10) || 0

			const offsetMinutes = sign * (hours * 60 + minutes)

			return moment().utcOffset(offsetMinutes).format('Z')
		}

		return null
	}

	convertToSelectedZoneTime(wellness_check_time, wellness_timezone) {
		const convertedWellnessTime =
			moment
				.utc(wellness_check_time, 'HH:mm:ss')
				.utcOffset(this.convertOffsetToMomentFormat(wellness_timezone), true)
				.tz(this.auth?.timezone)
				.format('h:mm A') + ` (${this.auth.timezone})`

		return convertedWellnessTime
	}

	transform(time: string, timezone: string): string {
		if (!time || !this.auth?.timezone) return ''

		return this.convertToSelectedZoneTime(time, timezone)
	}
}
