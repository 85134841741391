<div class="livestats">
  <div class="livestats__title">
    <h5>Live Stats</h5>
  </div>

  <div class="livestats__stats_ctr">

    <div *ngIf="!showIotLiteSite" class="livestats__stats_item">
      <div class="livestats__stats_item_data">
        <h3>{{ impactedAgents | displayEmpty }}</h3>
      </div>
      <p class="livestats__stats_item_label">Impacted Agents</p>
    </div>

    <div *ngIf="showIotLiteSite" class="livestats__stats_item">
      <div class="livestats__stats_item_data">
        <h3>{{ impactedSites | displayEmpty }}</h3>
      </div>
      <p class="livestats__stats_item_label">Impacted Sites</p>
    </div>

    <div class="livestats__stats_item">
      <div class="livestats__stats_item_data">
        <h3>{{ mbpsUp | displayEmpty }}</h3>
      </div>
      <p class="livestats__stats_item_label">Mbps Down</p>
    </div>

    <div class="livestats__stats_item">
      <div class="livestats__stats_item_data">
        <h3>{{ mbpsDown | displayEmpty }}</h3>
      </div>
      <p class="livestats__stats_item_label">Mbps Up</p>
    </div>
  </div>
</div>