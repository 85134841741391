import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Component, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment-timezone';
// import { DataService } from './../data.service';
import * as Chart from 'chart.js';
import { TableColorRowComponent } from '../../table-icons/table-color-row/table-color-row.component';

@Component({
  selector: 'app-chart-cloud-dna',
  templateUrl: './chart-cloud-dna.component.html',
  styleUrls: ['./chart-cloud-dna.component.scss'],
})
export class ChartCloudDnaComponent implements AfterViewInit, OnChanges {
  @Input() videoDeviceId: any;
  id: number;
  @Input() deviceId: any;
  @Input() popupState: any;
  @Input() data: any;

  chartApiData: any;
  cloudInsTableSetting: any;
  activeBtn: any = 0;

  toggleValue: any = 0;
  printTableData: any;
  printMode: boolean = false;
  canvas: any;
  ctx: any;
  myChartSite: any;
  currentChart = '24h';
  allInsListData = [];

  cloudInsTableSettingPacketloss = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: false,
    },
    columns: {
      timestamp: {
        title: 'Time Stamp',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (instance == null) {
            return '--';
          } else {
            // instance = moment(instance).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
          }
          return instance;
        },
      },
      total_packetloss: {
        title: 'Packetloss',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      total_mbps: {
        title: 'Live Mbps',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
      frame_rate: {
        title: 'Frame Rate',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      total_jitter: {
        title: 'Jitter',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      zoom_incident_id: {
        title: 'Incident Id',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (instance == null) {
            return '--';
          }
          return instance;
        },
      },
    },
  };

  cloudInsTableSettingJitter = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: false,
    },
    columns: {
      timestamp: {
        title: 'Time Stamp',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (instance == null) {
            return '--';
          } else {
            // instance = moment(instance).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
          }
          return instance;
        },
      },
      total_jitter: {
        title: 'Jitter',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      total_mbps: {
        title: 'Live Mbps',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
      frame_rate: {
        title: 'Frame Rate',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      total_packetloss: {
        title: 'Packetloss',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      zoom_incident_id: {
        title: 'Incident Id',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (instance == null) {
            return '--';
          }
          return instance;
        },
      },
    },
  };

  cloudInsTableSettingFramerate = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: false,
    },
    columns: {
      timestamp: {
        title: 'Time Stamp',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (instance == null) {
            return '--';
          } else {
            // instance = moment(instance).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
          }
          return instance;
        },
      },
      frame_rate: {
        title: 'Frame Rate',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      total_mbps: {
        title: 'Live Mbps',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
      total_jitter: {
        title: 'Jitter',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      total_packetloss: {
        title: 'Packetloss',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      people_count: {
        title: 'Packetloss',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      zoom_incident_id: {
        title: 'Incident Id',
        // type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (instance == null) {
            return '--';
          }
          return instance;
        },
      },
    },
  };
  // Settings and Parameters for the the Smart Filter Tables
  cloudInsTableSettingMbps = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: false,
    },
    columns: {
      timestamp: {
        title: 'Time Stamp',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (instance == null) {
            return '--';
          } else {
            // instance = moment(instance).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
          }
          return instance;
        },
      },
      total_mbps: {
        title: 'Live Mbps',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
      frame_rate: {
        title: 'Frame Rate',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      total_jitter: {
        title: 'Jitter',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      total_packetloss: {
        title: 'Packetloss',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
      },
      zoom_incident_id: {
        title: 'Incident Id',
        class: 'incidentRow',
        type: 'custom',
        renderComponent: TableColorRowComponent,
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
    },
  };

  // chart data
  chartData = {
    datasets: [
      {
        label: 'incident',
        data: [],
        backgroundColor: 'rgb(184, 58, 36, 1)',
        pointBackgroundColor: 'rgb(184, 58, 36, 1)',
        pointBorderColor: 'rgb(184, 58, 36, 1)',
        borderWidth: 1,
        showLine: false,
        hidden: false,
      },
      {
        label: 'mbps (mb/s)',
        data: [],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)',
        backgroundColor: 'rgb(255, 184, 0, .5)',
        borderWidth: 1,
        hidden: false,
      },
      {
        label: 'jitter (msec)',
        data: [],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)',
        backgroundColor: 'rgb(0, 128, 255, .5)',
        borderWidth: 0,
        hidden: false,
      },
      {
        label: 'frame rate',
        data: [],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)',
        backgroundColor: 'rgb(142, 204, 80, .5)',
        borderColor: 'rgb(142, 204, 80, 1)',
        borderWidth: 1,
        hidden: false,
      },
      {
        label: 'packet loss (%)',
        data: [],
        backgroundColor: 'rgb(142, 104, 80, .5)',
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)',
        borderWidth: 1,
        hidden: false,
      },
      {
        label: 'People Count',
        data: [],
        backgroundColor: 'rgb(255, 0, 255, .5)',
        pointBackgroundColor: 'rgb(255, 0, 255, 0)',
        pointBorderColor: 'rgb(255, 0, 255, 0)',
        borderWidth: 1,
        hidden: false,
      },
    ],
  };

  constructor(public http: HttpClient, private Auth: AuthService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.popupState.currentValue == 'open') {
      // // console.log('open', changes.popupState.currentValue)

      this.canvas = document.getElementById('myChartSite');
      this.ctx = this.canvas.getContext('2d');

      this.myChartSite = new Chart(this.ctx, {
        type: 'line',
        options: {
          responsive: false,
          display: true,
          fontSize: '6px',
          padding: '0px',
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0, // disables bezier curves
            },
          },
        },
      });

      this.currentChart = '60m'; //current set up
      this.activeBtn = 0;
      this.toggleValue = 0;
      this.toggleBtn(1); // wethere list or not

      this.fetchChartData(1);
    } else {
      setTimeout(() => {
        this.myChartSite.destroy();
      }, 500);
    }
  }

  ngAfterViewInit() { }

  fetchChartData(d) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let deviceDetails = {
      company_id: decodedToken.company_id,
      user_id: this.data.deviceId,
      duration: d,
    };

    this.chartApiData = [];
    this.Auth.getZoomAllChartData(deviceDetails).subscribe((data) => {
      // // console.log('chartApiData: ', data);

      this.chartApiData = data['response'];

      this.chartApiData = [];
      this.Auth.getZoomAllChartData(deviceDetails).subscribe((data) => {
        let framerate = data['response'][0].frame_rate.map((d) => {
          if (d.y === 0) {
            delete d.y;
          }
          return d;
        });
        framerate.sort(this.custom_sort);
        let jitter = data['response'][0].total_jitter.map((d) => {
          if (d.y === 0) {
            delete d.y;
          }
          return d;
        });
        jitter.sort(this.custom_sort);
        let mbps = data['response'][0].mbps_total.map((d) => {
          if (d.y === 0) {
            delete d.y;
          }
          return d;
        });
        mbps.sort(this.custom_sort);
        let packetloss = data['response'][0].total_packetloss.map((d) => {
          if (d.y === 0) {
            delete d.y;
          }
          return d;
        });
        packetloss.sort(this.custom_sort);
        let peopleCount = data['response'][0].people_count;
        peopleCount.sort(this.custom_sort);
        let incidents = data['response'][0].incidents;

        this.chartData.datasets[0].data = incidents;
        this.chartData.datasets[1].data = mbps;
        this.chartData.datasets[2].data = jitter;
        this.chartData.datasets[3].data = framerate;
        this.chartData.datasets[4].data = packetloss;
        this.chartData.datasets[5].data = peopleCount;
        this.chartData.datasets[1].hidden = !('mbps' == this.data.section);
        this.chartData.datasets[2].hidden = !('jitter' == this.data.section);
        this.chartData.datasets[3].hidden = !('framerate' == this.data.section);
        this.chartData.datasets[4].hidden = !('packetloss' == this.data.section);
        this.chartData.datasets[5].hidden = !('people_count' == this.data.section);
        this.loadChart();
      });
    });

    this.allInsListData = [];
    this.Auth.getZoomAllInsChartListData(deviceDetails).subscribe((data) => {
      this.allInsListData = data['response'];


      // console.log('chartApiData: ', this.allInsListData);


      let arr3 = [];

      for (let k = 0; k < this.allInsListData.length; k++) {
        arr3.push(this.allInsListData[k]);
        let timestamp = arr3[k].timestamp;
        arr3[k].timestamp = moment(timestamp)
          .utc()
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format('MM-DD-YYYY hh:mma');
      }
      switch (this.data.section) {
        case 'mbps':
          this.cloudInsTableSetting = this.cloudInsTableSettingMbps;
          break;
        case 'jitter':
          this.cloudInsTableSetting = this.cloudInsTableSettingJitter;
          break;
        case 'framerate':
          this.cloudInsTableSetting = this.cloudInsTableSettingFramerate;
          break;
        case 'packetloss':
          this.cloudInsTableSetting = this.cloudInsTableSettingPacketloss;
          break;
      }

      this.printTableData = arr3;
    });
  }

  custom_sort(a, b) {
    return new Date(a.x).getTime() - new Date(b.x).getTime();
  }

  loadChart() {
    // this.myChartSite.reset();
    this.myChartSite.options.scales = {
      xAxes: [
        {
          type: 'time',
          display: true,
          scaleLabel: {
            display: true,
            labelString: this.currentChart == '7d' ? 'Date' : 'Time',
          },
          time: {
            unit:
              this.currentChart == '7d' ? 'day' : this.currentChart == '24h' ? 'hour' : 'minute',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    };
    this.myChartSite.options.tooltips = {
      callbacks: {
        label: function (tooltipItem, chart) {
          if (tooltipItem.datasetIndex == 0) {
            return 'ID: ' + chart.datasets[0].data[tooltipItem.index].incident_id;
          } else {
            return tooltipItem.yLabel;
          }
        },
        beforeLabel: function (tooltipItem, chart) {
          switch (tooltipItem.datasetIndex) {
            case 0:
              return 'Incidents';
            case 1:
              return 'mbps';
            case 2:
              return 'Jitter';
            case 3:
              return 'Framerate';
            case 4:
              return 'Packetloss';
            case 5:
              return 'People Count';
          }
        },
        afterLabel: function (tooltipItem, chart) {
          if (tooltipItem.datasetIndex == 0) {
            let triggers = chart.datasets[0].data[tooltipItem.index].triggers.split(' - ');
            triggers.splice(0, 1);
            return triggers;
          } else {
            return '';
          }
        },
        title: function (tooltipItem, chart) {
          // return moment(tooltipItem[0].xLabel).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
        },
      },
    };
    this.myChartSite.data.datasets = this.chartData.datasets;
    this.myChartSite.update({ duration: 500 });
  }

  set60m() {
    if (this.currentChart != '60m') {
      this.currentChart = '60m';
      this.toggleBtn(1);
      this.fetchChartData(1);
    }
  }
  set24h() {
    if (this.currentChart != '24h') {
      this.currentChart = '24h';
      this.toggleBtn(2);
      this.fetchChartData(24);
    }
  }
  set7d() {
    if (this.currentChart != '7d') {
      this.currentChart = '7d';
      this.toggleBtn(3);
      this.fetchChartData(7);
    }
  }

  toggleView(n) {
    if (this.toggleValue == n) {
      this.toggleValue = 0;
    } else {
      this.toggleValue = n;
    }
  }

  toggleBtn(n) {
    if (this.activeBtn == n) {
      this.activeBtn = 0;
    } else {
      this.activeBtn = n;
    }
  }

  print() {
    var divId;
    if (this.toggleValue == 0) {
      // chart
      this.printCanvas();
    } else {
      // list
      this.printList();
    }
  }

  printCanvas() {
    const printContents = (<HTMLCanvasElement>document.getElementById('myChartSite')).toDataURL();
    const frame1 = document.createElement('iframe');
    frame1.name = 'frame1';
    frame1.style.position = 'absolute';
    frame1.style.top = '-1000000px';
    document.body.appendChild(frame1);
    const frameDoc = frame1.contentWindow;
    frameDoc.document.open();
    frameDoc.document.write(`
        <html>
          <head>
            <title>Print Chart</title>
          </head>
          <body onload="window.print(); window.close()">
            <img style='width: 100%;' src='${printContents}'/>
          </body>
        </html>
      `);
    setTimeout(function () {
      window.frames['frame1'].focus();
      window.frames['frame1'].print();
      document.body.removeChild(frame1);
    }, 500);
    return false;
  }

  printList() {
    this.printMode = true;
    setTimeout(() => {
      let printContents, stylesHtml, linksHtml, popupWin;
      printContents = document.getElementById('printTable').innerHTML;
      stylesHtml = this.getTagsHtml('style');
      linksHtml = this.getTagsHtml('link');
      const frame1 = document.createElement('iframe');
      frame1.name = 'frame1';
      frame1.style.position = 'absolute';
      frame1.style.top = '-1000000px';
      document.body.appendChild(frame1);
      const frameDoc = frame1.contentWindow;
      frameDoc.document.open();
      frameDoc.document.write(`
          <html>
            <head>
              <title>Print Report</title>
              ${stylesHtml}
              ${linksHtml}
              <style>
              .wrap {
                width: 100%;
                margin: 0 auto;
                margin-left: 15%;
                margin-top: 5%;
              }
              
              .wrap table {
                width: 1300px;
              
              }
              table tr td {
                padding: 5px;
                /* border: 1px solid #eee; */
                width: 110px;
                /* word-wrap: break-word; */
                cursor:context-menu;
                text-align: center;
            }
              table.head tr td {
                background: #eee;
              }
              .inner_table {
                height: auto;
                
              }
              </style>
            </head>
            <body onload="window.print(); window.close()">
              ${printContents}
            </body>
          </html>
        `);
      frameDoc.document.close();
      this.printMode = false;
    }, 500);
  }
  private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string {
    const htmlStr: string[] = [];
    const elements = document.getElementsByTagName(tagName);

    for (let idx = 0; idx < elements.length; idx++) {
      htmlStr.push(elements[idx].outerHTML);
    }

    return htmlStr.join('\r\n');
  }
}
