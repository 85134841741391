import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-table-color-row',
  templateUrl: './table-color-row.component.html',
  styleUrls: ['./table-color-row.component.scss']
})
export class TableColorRowComponent implements OnInit {

  renderValue: any;
  
  @Input() value: string | number;
  @Input() rowData: any;

  // incident = this.rowData.video_incident_id
  constructor() { }

  ngOnInit() {

    this.renderValue = this.value.toString().toUpperCase();
  }

}
