import { ApplicationRef, ComponentFactoryResolver, ComponentRef, ElementRef, HostListener, Injector, Pipe, PipeTransform } from '@angular/core';
import { GetVideoService } from '../services/get-video.service';

@Pipe({
  name: 'longnumbers' ,
  pure : false
})
export class LongNumbersPipe implements PipeTransform {
  private showFullNumbers : Boolean = false;
  constructor(private _videoService : GetVideoService){
      this._videoService.showFullNumbers.subscribe(val=>this.showFullNumbers=val)
  }
  transform(text: string | number , suffix: string = '...'): string {
    if(this.showFullNumbers){

    }else if ( (text + '').length > 4) {
      let truncated: string = (text + '').substring(0, 4).trim() + suffix;
      return truncated;
    }

    return text+'';
  }
}