import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-desktop-plugin-display-message',
  templateUrl: './desktop-plugin-display-message.component.html',
  styleUrls: ['./desktop-plugin-display-message.component.scss'],
})
export class DesktopPluginDisplayMessageComponent implements OnChanges {
  @Input() message: string;
  dPlugin: String = environment.config.dynamicText.deskPlugin;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {}
}
