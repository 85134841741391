import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { AuthService } from '../../../services/auth.service'
import { GetIotService } from '../../../services/get-iot.service'

@Component({
  selector: 'app-iot-lite-add-device-with-ports-dialog',
  templateUrl: './iot-lite-add-device-with-ports-dialog.component.html',
  styleUrls: ['./iot-lite-add-device-with-ports-dialog.component.scss'],
})
export class IotLiteAddDeviceWithPortsDialogComponent {
  deviceMac: any;
  deviceMake: any;
  collectorID: any = '';
  siteId: any = '';
  iotType: any = '';
  deviceName: any;
  deviceIP: any;
  devicePorts: any;
  setTimer: any;

  actionDeviceName: any = '';
  actionDeviceIP: any = '';
  showSuccessMsg: any = false;

  errorMsg: any = 'Device Not Found';
  showErrMsg: any = false;

  actionCount: any = 0;
  errorMessage: any = 'Could not IoT find device. Please try again.';

  showSearching: any = false;
  showAddDevice: any = false;
  showErrorMessage: any = false;
  closeButtonImage: any = './assets/img/iot/delete-btn.png';
  devicePortRename: any;
  devicePortsNewName: any = [];

  isLoading: any = false;
  hostName: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private Auth: AuthService,
    private dialogRef: MatDialogRef<IotLiteAddDeviceWithPortsDialogComponent>,
    private getIot: GetIotService
  ) {
    if (data) {
      const config = { ...data.config };
      this.collectorID = config.collector_id;
      this.siteId = config.site_id;
      this.iotType = config.iot_type;
      this.actionDeviceIP = config.actionDeviceIP;
      this.actionDeviceName = config.actionDeviceName;
      if (this.collectorID && this.actionDeviceIP) {
        this.discoverDevice();
      } else {
        //we can say no device was found
      }
    }
  }

  discoverDevice = () => {
    // console.log('collectorID: ',this.collectorID)
    // console.log('siteId: ',this.siteId)
    // console.log('actionDeviceIP: ',this.actionDeviceIP)
    // console.log(this.siteId)
    this.Auth.getNmap(
      this.collectorID,
      this.actionDeviceIP,
      'add',
      this.siteId
    ).subscribe(
      (data: any) => {
        let [res] = data.response;
        if (res.status == 'OK') {
          this.checkStatus(res.action_id);
          this.showSearching = true;
        } else {
          this.errorMsg = res.message;
          this.showErrMsg = true;
        }
      },
      (err) => {
        this.showSearching = false;
      }
    );
  };

  checkStatus = (action_id) => {
    this.Auth.postDeviceActionId({ action_id }).subscribe((actionData) => {
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }

      if (this.actionCount == 90) {
        this.resetFields();
        this.showErrMsg = true;
        return;
      }

      const handleFailed = () => {
        const [newData] = actionData.response;
        this.errorMsg = 'Could not find device. Please try again.';
        this.resetFields();
        this.showErrMsg = true;
        console.log('FAILED: ', newData);
      };

      const parseIfValid = (str: string) => {
        let result: any;
        try {
          result = JSON.parse(str);
        } catch (e) {
          return { valid: false, result: null };
        }
        return { valid: true, result };
      };

      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus(action_id);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === 'FAILED') {
        handleFailed();
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        const [newData] = actionData.response;
        const parsedResults = parseIfValid(newData.results.results);

        if (!parsedResults.valid) {
          console.log(111);
          return handleFailed();
        }
        if (!parsedResults.result?.nmap?.args) {
          console.log(222);
          return handleFailed();
        }

        console.log('COMPLETE: ', newData);
        this.resetFields();
        this.showAddDevice = true;
        this.setData(newData.results);

        return;
      }
    });
  };

  resetFields = () => {
    this.actionCount = 0;
    this.showSearching = false;
    this.showErrorMessage = false;
    this.showAddDevice = false;
  };

  setData = (params) => {
    const res2 = JSON.parse(params.results);
    const {
      nmap: { host },
    } = res2;
    console.log('res2: ', res2);
    if (host) {
      this.deviceIP = this.getDeviceIp(host);
      this.deviceMac = this.getDeviceMac(host);
      this.deviceName = this.actionDeviceName;
      this.deviceMake = this.getDeviceMake(host);
      this.hostName = this.getHostname(host);

      if (host.ports) {
        if (host.ports.port) {
          if (host.ports.port.length > 1) {
            this.devicePorts = host.ports.port.map((e) => {
              return {
                portid: e.portid,
                portname: this.replaceSpecialCharacther(e.service.name),
              };
            }); //ask if its no empty?
            this.devicePortRename = this.devicePorts.map((e) => {
              return '';
            });
          } else {
            // console.log('only 1 port')
            this.devicePorts = [];
          }
        } else {
          this.devicePorts = [];
        }
      } else {
        this.devicePorts = [];
      }
    } else {
      //display device not found
      this.resetFields();
      this.showErrorMessage = true;
    }
  };

  replaceSpecialCharacther = (portname) => {
    return portname.replace(/\W/g, '_');
  };

  getDeviceIp = (host: any) => {
    if (Array.isArray(host.address)) {
      if (host.address[0].addr) {
        return host.address[0].addr;
      }
    } else {
      if (host.address.addr) {
        return host.address.addr;
      }
    }
    return 'null';
  };

  getDeviceMac = (host: any) => {
    if (Array.isArray(host.address)) {
      if (host.address[1].addr) {
        return host.address[1].addr;
      }
    }
    return 'null';
  };

  getHostname = (host: any) => {
    if (host.hostnames) {
      if (host.hostnames.hostname) {
        if (host.hostnames.hostname.name) {
          return host.hostnames.hostname.name;
        }
      }
    }
    return 'null';
  };

  getDeviceMake = (host: any) => {
    //check if its in the address
    if (Array.isArray(host.address)) {
      if (host.address[1].vendor) {
        return host.address[1].vendor;
      }
    }
    return 'Unknown';
  };

  closeDialog = () => {
    this.isLoading = false;
    this.dialogRef.close(true);
  };

  cancelAddDevice = () => {
    this.showAddDevice = false;
  };

  addDevice = () => {
    if (this.devicePorts.length > 0) {
      this.devicePortsNewName = this.devicePorts.map((e, index) => {
        return {
          ...e,
          portname:
            this.devicePortRename[index] == ''
              ? e.portname
              : this.devicePortRename[index],
          is_monitored: 1,
        };
      });
    }

    this.devicePortsNewName = JSON.stringify(this.devicePortsNewName);

    // console.log('collectorID: ',this.collectorID)
    // console.log('deviceName: ',this.deviceName)
    // console.log('deviceIP: ',this.deviceIP)
    // console.log('hostName: ',this.hostName)
    // console.log('devicePortsNewName: ',this.devicePortsNewName)
    // console.log('deviceMac: ',this.deviceMac)
    // console.log('iotType: ',this.iotType)
    // console.log('deviceMake: ',this.deviceMake)

    this.getIot
      .iotLiteAdd(
        this.collectorID,
        this.deviceName,
        this.hostName,
        this.deviceIP,
        this.devicePortsNewName,
        this.deviceMac,
        this.iotType,
        this.deviceMake,
        this.siteId
      )
      .subscribe((data) => {
        // console.log('this.data: ', data)
        this.resetFields();
        this.showSuccessMsg = true;
      });
  };
}
