import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { environment } from '../../../environments/environment'
import { DeleteRoomDialogComponent } from '../../iot/dialogs/delete-room-dialog/delete-room-dialog.component'
import { AuthService } from '../../services/auth.service'
import { GetCloudService } from '../../services/get-cloud.service'
import { GetIotService } from '../../services/get-iot.service'
import { PopupTypeService } from '../../services/popupType.service'

@Component({
  selector: 'app-vs-menu-all-room-plugins',
  templateUrl: './vs-menu-all-room-plugins.component.html',
  styleUrls: ['./vs-menu-all-room-plugins.component.scss'],
})
export class VsMenuAllRoomPluginsComponent implements OnInit, OnChanges {
  @Output() sendPopupType: EventEmitter<any> = new EventEmitter<any>();

  @Input() roomPluginList: any;
  @Input() isShowCollectorEdit: any;
  @Input() isShowCollectorInfo: any;
  @Input() runTutorial: any;
  @Input() keyDown: any;
  @Input() searchKey: any;
  @Input() domotzEnabled: any;
  @Input() unlinkedIotDevice: any;
  @Input() unlinkedPlugin: any;

  viewTutorial = environment.config.dynamicImage.viewTutorial;
  downloadCollector = environment.config.dynamicImage.downloadCollector;
  createNewSite = environment.config.dynamicImage.createNewSite;
  createVirtualSite = environment.config.dynamicImage.createVirtualSite;
  pluginZoom = environment.config.dynamicImage.pluginZoom;
  desktopPlugin = environment.config.dynamicImage.desktopPlugin;
  iconAddSite = environment.config.dynamicImage.iconAddSite;
  iconVirtual = environment.config.dynamicImage.iconVirtual;
  iconPlugin = environment.config.dynamicImage.iconPlugin;
  vsOnePopup = environment.config.dynamicText.vsOnePopup;
  dCollector = environment.config.dynamicText.dCollector;
  dPlugin = environment.config.dynamicText.dPlugin;

  collectorParams: any;
  collectorLatestVersion: any;
  confirmationInput: any = '';
  selected: any = 'allPlugin';
  roomPluginListCopy: any;
  cid: any = 0;
  sid: any = 0;
  zoomRoom: any;
  zoomRoomName = '';

  compId: number;
  showStatConfirmation: boolean = false;
  showPluginConfirmation: Boolean = false;
  showPluginConfirmationError: Boolean = false;
  showScheduleModal: boolean = false;

  roomListFiltered: any;

  constructor(
    private GetIot: GetIotService,
    private getCloudUserService: GetCloudService,
    public dialog: MatDialog,
    private Auth: AuthService,
    private ds: PopupTypeService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.roomPluginList && this.roomPluginList) {
      this.roomPluginListCopy = this.roomPluginList;
    }
  }

  //-t

  getRoomStatus = (status, enabled) => {
    if (enabled === 0) {
      return '';
    } else if (status === 1) {
      return 'all-room-plugins__status--green';
    } else if (status === -1) {
      return 'all-room-plugins__status--red';
    } else if (status === 2) {
      return 'all-room-plugins__status--yellow';
    } else {
      return 'all-room-plugins__status--green';
    }
  };

  getPluginTypeImage = (type) => {
    if (type == null) {
      return '../../../assets/img/non-compatible.png';
    }
    return type === 'zoom'
      ? '../../../assets/img/zoom_logo.png'
      : '../../../assets/img/logo-microsoft-teams-2.png';
  };

  toggleSiteAlerts = (sid, notifyflg) => {
    const siteData1 = {
      company_id: this.compId,
      site_id: sid,
    };

    if (notifyflg === 0) {
      this.Auth.setSiteNotifyOff(siteData1).subscribe((data) => {
        if (data['response'][0]['status'] === 'OK') {
        }
      });
    } else if (notifyflg === 1) {
      this.Auth.setSiteNotifyOn(siteData1).subscribe((data) => {
        if (data['response'][0]['status'] === 'OK') {
        }
      });
    }
  };

  isDefaultDeviceEmpty = (device) => {
    if (device && device.length !== 0 && device.trim() !== '') {
      return 1;
    }
    return 0;
  };

  checkIfZoomPluginHasAnyData = (devices) => {
    if (devices == null) {
      return false;
    }
    const { audio_mic, video, audio_speaker } = devices;
    if (
      audio_mic.length === 0 &&
      video.length === 0 &&
      audio_speaker.length === 0
    ) {
      return false;
    }
    return true;
  };

  checkPluginEnabledAndStatus = (enabled, status) => {
    if (enabled === 1 && status === 1) {
      return false;
    }
    return true;
  };

  // ---------------

  getSiteTypeIcon = (bool) => {
    return bool ? this.iconVirtual : this.iconAddSite;
  };

  calculateIotDeviceCount = (audio, video, cloud) => +audio + +video + +cloud;

  sendPopupTypeEmitter(number) {
    this.sendPopupType.emit(number);
  }

  checkCollectorVersionThatNeedUpdates = (collector) => {
    const {
      collector: { version },
      virtual,
    } = collector;

    if (virtual === 1) {
      return false;
    }

    return version < this.collectorLatestVersion;
  };

  toDeleteSite = (cid, sid, zoomPluginCount, zoomRoom) => {
    this.cid = cid;
    this.sid = sid;
    if (zoomPluginCount > 0) {
      this.showPluginConfirmation = true;
      this.zoomRoom = zoomRoom;
      this.zoomRoomName = Object.keys(zoomRoom).toString();
    } else {
      this.showStatConfirmation = true;
    }
    event.stopPropagation();
  };

  sendData = (p, n) => {
    this.sendPopupTypeEmitter(11);
  };

  cancelDeleteSiteWithPlugin = () => {
    this.showPluginConfirmation = false;
    this.zoomRoom = null;
    this.zoomRoomName = null;
    this.cid = 0;
    this.sid = 0;
  };

  deleteSite = () => {
    const deactivateData = {
      company_id: this.cid,
      site_id: this.sid,
    };
    this.Auth.deactivateSite(deactivateData).subscribe((data) => {
      if (data['response'][0]['status'] === 'OK') {
        this.sendPopupType.emit('getSiteList');
      }
    });
    this.showStatConfirmation = false;
    this.cid = 0;
    this.sid = 0;
  };

  deleteSiteWithPlugin1() {
    this.showPluginConfirmationError = false;
    if (this.confirmationInput.toLowerCase() === 'yes') {
      const deactivateData = {
        company_id: this.cid,
        site_id: this.sid,
      };
      this.Auth.deactivateSite(deactivateData).subscribe((data) => {
        if (data['response'][0]['status'] === 'OK') {
          this.sendPopupType.emit('getSiteList');
        }
      });
      this.cid = 0;
      this.sid = 0;
      this.zoomRoom = null;
      this.zoomRoomName = null;
      this.showPluginConfirmationError = false;
      this.showPluginConfirmation = false;
    } else {
      this.showPluginConfirmationError = true;
    }
  }

  openDeleteModal = ({ plugin_id, host_name, room_name }) => {
    const config = {
      plugin_id,
      host_name,
      room_name,
      sendPopupType: this.sendPopupType,
    };

    const webportal = this.dialog.open(DeleteRoomDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        config,
      },
    });
  };

  pluginListSelect = (event) => {
    this.sendPopupType.emit(event);
  };
}
