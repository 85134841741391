<button type="button" class="not-button btn-reg firmware-users" id="btn">
  <div class="row">
    <div class="col-6">
      <img src="../../../../assets/img/audio/icon_headset.png" alt="firmware updates">
    </div>
    <div class="col-6">
      <h3 class="count">{{count}}</h3>
      <p>{{label}}</p>
    </div>
  </div>
</button>