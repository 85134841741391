<div class="panel">
  <div class="panel__header">
    <app-panel-tools [PopupTitle]="summaryTitle"></app-panel-tools>
  </div>
  <div class="panel__body">
    <div class="panel__body_info">
      <app-info-details [siteInformation]="companyInfo"></app-info-details>
    </div>
    <div class="panel__body_stats_health">
      <app-system-health [isCursorPointer]="true" [healthNum]="'0'" [showBlackPointer]="false"></app-system-health>
      <app-iot-summary-stats [agentSummaryList]="agentSummaryList" [showIotLiteSite]="iotLiteEnabled"
        [isSpacesEnabled]="false" (summaryEvent)="onSummaryEvent($event)">
      </app-iot-summary-stats>
    </div>
  </div>
  <div class="panel__table">
    <app-iot-summary-device-list *ngIf="!shouldShowAgent && !showIotLiteSummary" [filterStatus]="filterStatus"
      [filterAgent]="filterAgent" [iotLiteEnabled]="iotLiteEnabled">
    </app-iot-summary-device-list>

    <app-iot-summary-agent-list *ngIf="shouldShowAgent" [filterRoute]="filterRoute"></app-iot-summary-agent-list>

    <app-iot-lite-site-summary *ngIf="showIotLiteSummary" [filterStatus]="filterStatus"></app-iot-lite-site-summary>
  </div>
</div>