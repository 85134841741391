import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { faPause, faPlay, faUser } from '@fortawesome/free-solid-svg-icons'
import { PopupTypeService } from '../../services/popupType.service'
import { AuthService } from 'src/app/services/auth.service'
import { GetCollectorService } from 'src/app/services/get-collector.service'

@Component({
	selector: 'app-collector-info',
	templateUrl: './collector-info.component.html',
	styleUrls: ['./collector-info.component.scss'],
})
export class CollectorInfoComponent implements OnInit, OnChanges {
	@Input() collectorDetails: any
	@Input() companyLogo: any

	faIcon = faUser
	faPauseIcon = faPause
	faPlayIcon = faPlay

	collectorStatus: any = 'Loading'

	uptimeDays: any
	uptimeHours: any
	uptimeMins: any

	constructor(
		public ds: PopupTypeService,
		private auth: AuthService,
		private collectorService: GetCollectorService
	) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.collectorDetails && this.collectorDetails) {
			console.log('collectorDetails info: ', this.collectorDetails)
			this.setData()
		}
	}

	setData = () => {
		// const { host_name, site_name, site_id, ip_address, mac_address, status, paused } = this.collectorDetails
		const { status, paused } = this.collectorDetails

		this.collectorStatus = paused ? 'Paused' : status == 1 ? 'Online' : status == 2 ? 'Warning' : 'Offline'
		this.getUptime()
	}

	openCollectorAdmin = () => {
		this.ds.sendPopupType(81, this.collectorDetails)
	}

	openCollectorReboot = () => {
		this.ds.sendPopupType(82, this.collectorDetails)
	}

	onCollectorPauseOrUnpause = () => {
		const newPausedState = this.collectorDetails?.paused === 0 ? 1 : 0
		this.auth.toggleSiteMonitor(this.collectorDetails?.site_id, newPausedState).subscribe(data => {
			if (data.error === null) {
				this.collectorDetails.paused = newPausedState
				const { status } = this.collectorDetails
				this.collectorStatus = newPausedState ? 'Paused' : status == 1 ? 'Online' : status == 2 ? 'Warning' : 'Offline'
				this.collectorService.collectorSiteChangeEvent.emit()
			}
		})
	}

	getUptime = () => {
		const { last_reboot } = this.collectorDetails

		if (!last_reboot) return

		const uptime = new Date().getTime() - new Date(last_reboot).getTime()

		this.uptimeDays = Math.floor(uptime / (1000 * 60 * 60 * 24))
		this.uptimeHours = Math.floor((uptime / (1000 * 60 * 60)) % 24)
		this.uptimeMins = Math.floor((uptime / (1000 * 60)) % 60)
	}
}
