import { AuthService } from 'src/app/services/auth.service'
import { TableFilterService } from 'src/app/services/table-filter.service'

import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'

@Component({
  selector: 'app-cloud-all-rooms-list-table',
  templateUrl: './cloud-all-rooms-list-table.component.html',
  styleUrls: ['./cloud-all-rooms-list-table.component.scss'],
})
export class CloudAllRoomsListTableComponent implements OnInit {
  constructor(private router: Router, public Auth: AuthService,
    private tableFilterService: TableFilterService
  ) {
    this.tableFilterService.listen().subscribe((m: any) => {
      // this.onFilter(m);
    });
  }

  dataSource: MatTableDataSource<any>;

  @Input() cloudRoomsListData: MatTableDataSource<any>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] =
    this.Auth.accessLevel == 'SUPERADMIN'
      ? [
        'company',
        'name',
        'health',
        'qos',
        'livembps',
        'liveMeetings',
        'duration',
        'calender',
        'service',
      ]
      : ['name', 'health', 'qos', 'livembps', 'liveMeetings', 'duration', 'calender', 'service'];
  Math: any = Math;

  ngOnInit(): void { }

  applyFilter(filterValue: string) {
    if (filterValue == '') this.cloudRoomsListData.filter = '-'.trim().toLowerCase();
    else this.cloudRoomsListData.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {
    this.cloudRoomsListData.filter = '-'.trim().toLocaleLowerCase();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cloudRoomsListData && this.cloudRoomsListData) {
      // this.cloudRoomsListData.sort = this.sort;
      this.cloudRoomsListData.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'company': {
            return item.company_name.toLocaleLowerCase();
          }
          case 'name': {
            return item.room_name.toLocaleLowerCase();
          }
          case 'health': {
            return item.health;
          }
          case 'qos': {
            return item.qos;
          }
          case 'livembps': {
            return item.livembps;
          }
          case 'liveMeetings': {
            return item.liveMeetings;
          }
          case 'duration': {
            return item.duration;
          }
          case 'calender': {
            return item.scheduledMeetings;
          }
          case 'service': {
            return item.service;
          }
          default:
            return item[property];
        }
      };
  
      this.cloudRoomsListData.sort = this.sort;
    }
  }

  getMbps = (params) => {
    if (params.service_type === 'teams') {
      return params.liveMbpsTeams
    } else if (params.service_type === 'zoom') {
      if (params.liveMeetings == 2) {
        const zoomJsonData = JSON.parse(params.zoom_plugin_data)
        const zoomNetworkMonitor = zoomJsonData.callSimulator.zoom.network_monitor;
        return this.calculateMbpsFromPlugin(zoomNetworkMonitor);
      }
    }
    return params.total_bitrate ? (params.total_bitrate / (1024 * 1024)).toFixed(2) : '--'
  }

  calculateMbpsFromPlugin = (network_monitor) => {
    if ((network_monitor.receive_speed != null && network_monitor.send_speed != null) && (network_monitor.receive_speed != '' && network_monitor.send_speed != '')) {
      const newMbps = parseFloat(network_monitor.receive_speed.split("K")[0]) + parseFloat(network_monitor.send_speed.split("K")[0]);
      return (newMbps / 122).toFixed(2);
    } else {
      return '--';
    }
  };

  clickRow({ service_type, zoom_user_id }) {
    if (service_type == 'teams') {
      this.router.navigate(['teams/room/', zoom_user_id]);
    } else {
      this.router.navigate(['cloud/room/', zoom_user_id]);
    }
  }

  getDuration = (duration) => {
    if (duration && duration != null) {
      let calculateDuration = '';
      calculateDuration = duration + ' m';
      if (duration > 60) {
        calculateDuration = (duration / 60) + ' h';
        if (duration % 60 !== 0) {
          calculateDuration = (duration / 60).toFixed(2);
          let split = calculateDuration.split('.');
          calculateDuration = split[0] + ' h ' + split[1] + ' m';
        }
      }
      return calculateDuration;
    }
    return '--';
  }
}