<div class="zoomform">
  <div class="zoomform__container">
    <div class="zoomform__label">
      CLIENT ID
      <span class="zoomform__disable">
        <a href="{{ href1 }}" target="_blank">
          <img src="../../../../assets/img/vs_img/eye.png" alt="" /> </a></span>
    </div>
    <input class="zoomform__input" value="apiKey" [(ngModel)]="apiKey" type="password" placeholder="api key" />
  </div>

  <div class="zoomform__container">
    <div class="zoomform__label">
      CLIENT SECRET
      <span class="zoomform__disable">
        <a href="{{ href2 }}" target="_blank">
          <img src="../../../../assets/img/vs_img/eye.png" alt="" /> </a></span>
    </div>
    <input class="zoomform__input" value="apiSecret" [(ngModel)]="apiSecret" type="password" placeholder="api secret" />
  </div>

  <div class="zoomform__container">
    <div class="zoomform__label">
      SECRET TOKEN
      <span class="zoomform__disable">
        <a href="{{ href2 }}" target="_blank">
          <img src="../../../../assets/img/vs_img/eye.png" alt="" /> </a></span>
    </div>
    <input class="zoomform__input" value="verificationToken" [(ngModel)]="verificationToken" type="password"
      placeholder="verification token" />
  </div>

  <div class="zoomform__button-blank-container">
    <div class="zoomform__blank-container"></div>
    <div class="zoomform__button-container">
      <div class="d-flex flex-column">
        <div class="zoomform__other-info">
          <strong>Event Notification Endpoint URL:</strong>
          <div>{{ zoomWebHook }}</div>
        </div>
  
        <div class="zoomform__other-info">
          <strong>OAuth Redirect URL:</strong>
          <div>{{ oAuthRedirectURL }}</div>
        </div>
        
      </div>

      <div *ngIf="!isLoading">
        <div class="zoomform__btn" *ngIf="showButtonForAuthLink">
          <button (click)="updateApiKey()" class="loader-img" [disabled]="isSaveLoader" [style]="isSaveLoader?{'opacity':0.5}:{}"><img [src]="loader" *ngIf="isSaveLoader"> Save</button>
        </div>
  
        <div class="zoomform__btn" *ngIf="!showButtonForAuthLink">
          <button (click)="zoom_status?reAuthorize():openAuthLink()">Authorize/Reauthorize</button>
        </div>
      </div>
    </div>
  </div>
</div>