import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HandleSpeakerService implements OnInit{
  defineSpeakerValueForCallBack:boolean;
  constructor() { }
  ngOnInit(){
    // this.ds.sendPopupType(0);
  }
}
