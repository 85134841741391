import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-iot-summary-device-types',
  templateUrl: './iot-summary-device-types.component.html',
  styleUrls: ['./iot-summary-device-types.component.scss'],
})
export class IotSummaryDeviceTypesComponent implements OnInit, OnChanges {
  constructor() {}
  @Input() agentSummaryList;
  @Input() summaryEvent: EventEmitter<any>;
  @Input() isSpacesEnabled = false;
  
  avDevices: Number;
  officeDevices: Number;
  networkDevices: Number;
  smartDevices: Number;
  mobileDevices: Number;
  servers: Number;
  otherDevices: Number;
  activeGroupType: String;
  

  ngOnInit() {
    this.activeGroupType = null;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.agentSummaryList) {
      if (this.agentSummaryList) {
        this.setDeviceType(this.agentSummaryList);
      }
    }
  }

  setDeviceType = (data) => {
    const {
      av_devices_count,
      office_devices_count,
      network_devices_count,
      smart_devices_count,
      mobile_devices_count,
      servers_count,
      other_devices_count,
    } = data;
    this.avDevices = av_devices_count;
    this.officeDevices = office_devices_count;
    this.networkDevices = network_devices_count;
    this.smartDevices = smart_devices_count;
    this.mobileDevices = mobile_devices_count;
    this.servers = servers_count;
    this.otherDevices = other_devices_count;
  };

  filterByGroupType(groupType) {
    if (this.activeGroupType === groupType) {
      this.activeGroupType = null;
      groupType = 'ALL';
    }
    this.activeGroupType = groupType;
    const eventData = { eventType: 'device', data: groupType };
    this.summaryEvent.emit(eventData);
  }
}
