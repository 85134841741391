<div *ngIf="isShowLogitech" class="btn-device-logitech" (click)="firmwareUpdate()">
  <div class="btn-device-logitech__logo">
    <img src="../../../../assets/img/3rd_party_logo/logitech.png" />
    <label>Camera Firmware</label>
  </div>
  <div
    [ngClass]="{
      'circle-yellow':
        rallyPackageFirmwareVersion !== latestFirmwarePackageVersion && pluginStatus === 1,
      'circle-green':
        rallyPackageFirmwareVersion === latestFirmwarePackageVersion && pluginStatus === 1,
      'circle-grey': pluginStatus !== 1
    }"
  ></div>
</div>
