import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-btn-reg-stats-twodec',
  templateUrl: './btn-reg-stats-twodec.component.html',
  styleUrls: ['./btn-reg-stats-twodec.component.scss']
})
export class BtnRegStatsTwodecComponent implements OnChanges {
  @Input() count: number;
  @Input() label: string = "Not-Active";
  @Input() color: string;
  @Input() backcolorbtn: any;
  @Input() isbutton: string;
  @Input() active: boolean;
  @Input() activeN: any;
  @Input() activeBtn: number;

  prevcount: string;
  setTimer: any;

  constructor() { }

  activeSelection = 0;
  setActive(n) {
    if (this.activeBtn == n) {
      this.activeBtn = 0;
    } else {
      this.activeBtn = n;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.count.currentValue != changes.count.previousValue) {
      this.backcolorbtn = "btnbacklite"
      this.setTimer = setTimeout(() => {
        this.backcolorbtn = "btnbacklite-default"
      }, 2500);
    } else {
      this.setTimer = setTimeout(() => {
        this.backcolorbtn = "btnbacklite-default"
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };
}