import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { TableFilterService } from '../../../services/table-filter.service';

import { PopupTypeService } from '../../../services/popupType.service';

@Component({
  selector: 'app-video-allsites-stats-panel',
  templateUrl: './video-allsites-stats-panel.component.html',
  styleUrls: ['./video-allsites-stats-panel.component.scss'],
})
export class VideoAllsitesStatsPanelComponent implements OnInit {
  data: any;
  @Input() videoSitesSummary: any;
  @Output() onFilter: EventEmitter<number> = new EventEmitter();
  currentFilter: string = '';
  activeSelection: any;
  @Output() btnState: any;
  activeBtn: any;
  activeBtn1: any;
  activeBtn2: any;
  activeBtn3: any;
  @Output() not: any = 'not';
  calculatedMbps: any;
  activeFilter: any = '';

  constructor(
    private ds: PopupTypeService,
    private tableFilterService: TableFilterService,
    private router: Router
  ) {}

  //Calculates the live mbps and turns into whole numbers
  calMbps() {
    if (this.videoSitesSummary) {
      var roundnum = this.videoSitesSummary.bitrate / (1024 * 1024);
      this.calculatedMbps = Math.round(roundnum * 100) / 100;
      return this.calculatedMbps;
    }
  }

  setFilter(filter) {
    if (filter == this.activeFilter) {
      this.activeFilter = '';
      this.clickFilter('');
    } else {
      this.activeFilter = filter;
      switch (filter) {
        case 'oncall':
          this.clickFilter('oncall');
          break;
        case 'impacted':
          this.clickFilter('impacted');
          break;
      }
    }
  }

  clickFilter(filter): void {
    // this.onFilter.emit(this.data);
    // This is used the pass the panel header text
    this.tableFilterService.filter(filter);
  }

  ngOnInit() {
    // send message to subscribers via observable subject
    this.ds.sendPopupType(0);
    var routeType = this.router.url;
    this.clickFilter('');
    this.activeFilter = routeType.includes('impacted') ? 'impacted' : '';
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
    // console.log(p);
  }

  ngOnDestroy() {
    // clear message
    this.ds.clearPopupType();
  }

  toggleBtn() {
    if (this.data[1] == 'impacted') {
      this.activeBtn1 = 1;
    } else if (this.data[1] == 'oncall') {
      this.activeBtn2 = 1;
    } else {
      this.activeBtn1 = '';
      this.activeBtn2 = '';
    }
  }
}
