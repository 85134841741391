<div class="miclist">
  <div class="miclist__col-1">
    <div class="miclist__select">
      <label>Select Microphone Device:</label>
      <select #selectAutoInput class="form-control" required (change)="audioSelect(selectAutoInput.value)">
        <option *ngFor="let item of micList" value="{{ item.id }}">
          {{ item.name }}
        </option>
      </select>
    </div>
    <div class="miclist__btn-col">
      <button [ngClass]="{
          'miclist__disable': deviceStatus == 4 || deviceStatus == 11 || deviceStatus == 12
        }" class="form-control btn btn-primary" (click)="setDevice()">
        Save
      </button>
      <button class="form-control btn btn-primary" (click)="this.ds.sendPopupType(0)">
        Cancel
      </button>
    </div>
    <div class="row alert alert-danger">
      Warning: Controller will go offline while your setting is changed.
    </div>
  </div>
  <div class="miclist__status">
    <div>Status</div>
    <div>{{ audioMicStatus }}</div>
  </div>
</div>