import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { GetAudioService } from '../../../services/get-audio.service';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { RefreshApiService } from 'src/app/services/refresh-api.service';
import { TableFilterService } from 'src/app/services/table-filter.service';

@Component({
  selector: 'app-audio-all-devices-panel',
  templateUrl: './audio-all-devices-panel.component.html',
  styleUrls: ['./audio-all-devices-panel.component.scss'],
})
export class AudioAllDevicesPanelComponent implements OnInit {
  PopupTitle: string = 'Audio Devices';

  public audioDeviceSummary;
  public audiositesInfo;
  // public videositesStats;
  id: number;
  audioApiData: any = [];
  audioDeviceApiSummary: any = [];
  audioDevicesListData: MatTableDataSource<any>;

  apiTimers: any;
  refreshData: any;
  filterListner: Subscription;
  // Settings and Parameters for the the Smart Filter Tables
  baseFilter: any = 'all';
  loading: any = true;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getAudioDataService: GetAudioService,
    private refreshApiService: RefreshApiService,
    private tableFilterService: TableFilterService
  ) {
    this.audioDevicesListData = new MatTableDataSource();

    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });

    // This recieves the update data boolean to start the timer
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      this.refreshData = m;
      this.getApiData();
    });

    if (this.router.url == '/audio/healthydevices') {
      this.baseFilter = 'healthy';
    } else if (this.router.url == '/audio/warningdevices') {
      this.baseFilter = 'warning';
    } else if (this.router.url == '/audio/impacteddevices') {
      this.baseFilter = 'impacted';
    } else if (this.router.url == '/audio/pauseddevices') {
      this.baseFilter = 'paused';
    } else if (this.router.url == '/audio/alldevices') {
      this.baseFilter = 'all';
    }

    this.filterListner = this.tableFilterService.listen().subscribe((m: any) => {
      if (m == '') {
        if (this.router.url == '/audio/healthydevices') {
          this.baseFilter = 'healthy';
        } else if (this.router.url == '/audio/warningdevices') {
          this.baseFilter = 'warning';
        } else if (this.router.url == '/audio/pauseddevices') {
          this.baseFilter = 'paused';
        } else if (this.router.url == '/audio/impacteddevices') {
          this.baseFilter = 'impacted';
        } else if (this.router.url == '/audio/alldevices') {
          this.baseFilter = 'all';
        }
      } else {
        this.baseFilter = m;
      }
      this.audioDevicesListData.filter = '-'.trim().toLocaleLowerCase();
    });

    this.audioDevicesListData.filterPredicate = (data: any, filter: string) => {
      var dataStr =
        data.ipv4 +
        data.site_name +
        data.health +
        data.liveMeetings +
        data.company_name +
        data.room_name;

      if (this.baseFilter == 'impacted') {
        if (filter == '-' || filter == '' || filter == null) {
          return (data.health < 76 || +data.online === 0) && data.paused === 0
        } else {
          return (
						(data.health < 76 || +data.online === 0) &&
						data.paused === 0 &&
						dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
					)
        }
      } else if (this.baseFilter == 'warning') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health >= 51 && data.health < 76 && data.paused === 0;
        } else {
          return (
            data.health >= 51 &&
            data.health < 76 &&
            data.paused === 0 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'paused') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.paused == 1;
        } else {
          return (
            data.paused == 1 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'healthy') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health >= 76 && data.paused === 0;
        } else {
          return (
            data.health >= 76 &&
            data.paused === 0 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'all') {
        if (filter == '-' || filter == '' || filter == null) {
          return true;
        } else {
          return (
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'on_call') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.on_call == 1;
        } else {
          return (
            data.on_call == 1 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'impactedOnly') {
        if (filter == '-' || filter == '' || filter == null) {
          return (data.health < 76 || +data.online === 0) && data.paused != 1
        } else {
          return (
						(data.health < 76 || +data.online === 0) &&
						data.paused != 1 &&
						dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
					)
        }
      } else if (this.baseFilter == 'teams_nc') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.microsoft_team_service != 1;
        } else {
          return (
            data.microsoft_team_service != 1 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'zoom_nc') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.zoom_service != 1;
        } else {
          return (
            data.zoom_service != 1 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'webex_nc') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.webex_service != 1;
        } else {
          return (
            data.webex_service != 1 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      }
    };
  }

  getApiData() {
    if (this.refreshData) {
      this.getAudioApiData();
    }
  }

  ngOnInit() {
    this.getAudioApiData();
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  // This function pulls All Audio Device data from API Server
  getAudioApiData() {
    //GET Request for Company Info
    this.getAudioDataService.apiGetAudioCompanyInfo().subscribe((data) => {
      this.audiositesInfo = data['response'];
    });

    this.getAudioDataService.apiGetAllAudioDevices().subscribe((data) => {
      this.audioApiData = data['response'];
      let arr = [];
      for (let d in this.audioApiData) {
        arr.push(this.audioApiData[d]);
      }
      this.audioDevicesListData.data = this.audioApiData;
      this.loading = false;
    });

    //GET Request for Audio Device Stats Summary by Site
    this.getAudioDataService.getAllDeviceSummary().subscribe((data) => {
      let resp = data["response"] || [];
      let objectToPass = {  ...resp[0] , all_devices : 0,impacted : 0 , on_call : 0 , total_mos : null };

      // Each object has all devices their sum is considered the count
      let allDevicesCount = resp.reduce((a , e)=>a + e.all_devices, 0)
      objectToPass.all_devices = allDevicesCount;
      const devicesCount = resp.length;
      
      let totalMos = resp.reduce((a, e) => a + e.total_mos, 0)

      objectToPass.total_mos = Math.ceil(totalMos / devicesCount);

      objectToPass.on_call = resp.reduce((a, e) => a + e.on_call, 0)

      objectToPass.impacted = resp.reduce((a, e) => a + e.impacted, 0)

      this.audioDeviceSummary = objectToPass;
    });
  }
}
