export default function getApiPathBuilder({ apiUrl, apiServicePorts = {} }) {
  return function getServicePathBuilder(service) {
    if (!service) throw new Error('service not defined');
    const port = apiServicePorts[service] || false;
    const basePath = port ? apiUrl + ':' + port : apiUrl;

    return function buildPath(functionName) {
      if (!functionName) throw new Error('function endpoint not defined');

      const endpoint = basePath + '/' + service + '/' + functionName;
      // const endpoint = basePath + '/' + functionName;

      // // console.log(endpoint);
      return endpoint;
    };
  };
}
