import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-video-live-stat-logitech',
  templateUrl: './video-live-stat-logitech.component.html',
  styleUrls: ['./video-live-stat-logitech.component.scss']
})
export class VideoLiveStatLogitechComponent implements OnInit, OnChanges {
  @Input() iotDeviceDetails: any;
    @Input() pingRtd: any = '--';
  @Input() packetLoss: any = '--';

  @Input() medianAlert: Number;
  @Input() medianWarning: Number;

  @Input() packetLossAlert: Number;
  @Input() packetLossWarning: Number;
  
  constructor() { }

  ngOnInit(): void {
  }
  
  	ngOnChanges(changes: SimpleChanges) {
		if (changes.iotDeviceDetails && this.iotDeviceDetails) {
			// console.log('videoDeviceData last call: ', this.videoDeviceData)
			this.setData()
		}
	}
	
	setData = () => {
	
	}
	
	 checkRtdAlert = () => {
    if (this.pingRtd > this.medianAlert) {
      return 'livestats__rtd-alert';
    }
    if (this.pingRtd > this.medianWarning) {
      return 'livestats__rtd-warning';
    }

    return '';
  };
  
  checkPacketLossAlert = () => {
    if (this.packetLoss > this.packetLossAlert) {
      return 'livestats__packetloss-alert';
    }
    if (this.packetLoss > this.packetLossWarning) {
      return 'livestats__packetloss-warning';
    }

    return '';
  };


}
