import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; 
import { Router } from '@angular/router';

// Services
import { GetVideoService } from '../../services/get-video.service'
import { GetAudioService } from '../../services/get-audio.service'

import { TableHealthIconComponent } from '../../components/table-icons/table-icon/table-health-icon.component';
import { GeneralListenerService } from '../../services/general-listener.service';
import { TableOnCallIconComponent } from '../../components/table-icons/table-oncall-icon/table-oncall-icon.component';

@Component({
  selector: 'app-table-styles',
  templateUrl: './table-styles.component.html',
  styleUrls: ['./table-styles.component.scss']
})
export class TableStylesComponent implements OnInit {
  data: any;
  public videoSiteSummaryData;
  public videoSiteData;
  public videoDeviceData;
  public audioUserData;

  @Output() tableCat: EventEmitter<any> = new EventEmitter();

  videoSitesTableSetting = this.getVideoDataService.videoSitesTableSettings;
  videoDeviceTableSettings = this.getVideoDataService.videoDeviceTableSettings;


  settings = {
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    attr: {
      class: "filterTable"
    },
    hideSubHeader: true,
    columns: {
      location: {
        title: 'Name',
        class: "filter-test"
      },
      health: {
        title: 'Health',
        type: 'temperture',
        renderComponent: TableHealthIconComponent
      },
      impacted: {
        title: 'Impacted'
      },
      infrastructure: {
        title: 'Infrastructure'
      },
      devices: {
        title: 'Devices'
      },
      oncall: {
        title: 'O111n Call',
        type: 'custom',
        renderComponent: TableOnCallIconComponent
      },
      meetings: {
        title: 'Scheduled Meetings'
      },
      livembps: {
        title: 'Live Mbps'
      }
    }
  };
  data1 = [
    {
      id: 'ade',
      name: 'test'
    }
  ]
  // source: LocalDataSource;


  constructor(private route: ActivatedRoute, private router: Router, 
    private getVideoDataService: GetVideoService,
    private getAudioDataService: GetAudioService,
    private listenerService: GeneralListenerService) { 
      // this.source = new LocalDataSource();
    }

  getStats() {

    this.getVideoDataService.fetchVideoSummary().subscribe(data => {
      this.videoSiteSummaryData = data[0];
    });

    this.getVideoDataService.fetchVideoSites().subscribe(data => {
      let arr = [];
        for(let d in data) {
          arr.push(data[d]);
      }
      this.videoSiteData = data;
      // this.source.load(arr);
    });

    this.getVideoDataService.fetchVideoDevices().subscribe(data => {
      this.videoDeviceData = data;
    })
  
    // Get Audio JSON Data
  }

  

  onSearch(query: string = '') {
    // this.source.setFilter([
    //   // fields we want to inclue in the search
    //   {
    //     field: 'location',
    //     search: query,
    //   },
    //   {
    //     field: 'health',
    //     search: query,
    //   },
    //   {
    //     field: 'impacted',
    //     search: query,
    //   },
    //   {
    //     field: 'infrastructure',
    //     search: query,
    //   },
    //   {
    //     field: 'devices',
    //     search: query,
    //   },
    //   {
    //     field: 'oncall',
    //     search: query,
    //   },
    //   {
    //     field: 'meetings',
    //     search: query,
    //   },
    //   {
    //     field: 'livembps',
    //     search: query,
    //   },
    // ], false);
    // second parameter specifying whether to perform 'AND' or 'OR' search
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }


  ngOnInit() {
    this.getStats();
  }

}
