import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collector-edit-site',
  templateUrl: './collector-edit-site.component.html',
  styleUrls: ['./collector-edit-site.component.css']
})
export class CollectorEditSiteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
