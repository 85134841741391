<div class="row p-3" style="border-top: 3px solid silver;">
  <div class="col-12">
    <div class="row">
      <div class="col-2">
        <img src="../../../../assets/img/icon_calendar.png">
      </div>
      <div class="col-5">
        <h4 class="text-center">Conference Rooms Calls</h4>
        <div class="row">
          <div class="col-12" style="padding:0px 100px;">
            <h1 class="float-left report-bold">{{videoConferSite.calls_scheduled.scheduled}}</h1><h1 class="float-right report-bold">{{videoConferSite.calls_scheduled.adhoc}}</h1>
          </div>
        </div>
        <h4 class="text-center">Scheduled Vs Adhoc</h4>
      </div>
      <div class="col-5">
        <h4 class="text-center">Huddle Rooms Calls</h4>
        <div class="row">
          <div class="col-12" style="padding:0px 100px;">
            <h1  class="float-left report-bold">{{videoConferSite.huddle_room_calls_scheduled?videoConferSite.huddle_room_calls_scheduled.scheduled:0}}</h1><h1 class="float-right report-bold" >{{videoConferSite.huddle_room_calls_scheduled?videoConferSite.huddle_room_calls_scheduled.adhoc:0}}</h1>
          </div>
        </div>
        <h4 class="text-center">Scheduled Vs Adhoc</h4>
      </div>
    </div>
  </div>
</div>