import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { Router } from '@angular/router'

import { GetUsStatesService } from '../../../../app/services/get-us-states.service'
import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-old-register',
  templateUrl: './old-register.component.html',
  styleUrls: ['./old-register.component.scss'],
})
export class OldRegisterComponent implements OnInit, OnChanges {
  @Output() public data = new EventEmitter<{
    company: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    password: string;
    confirm: string;
  }>();
  @Input() message: any;

  links = environment.config.links;
  dTerms = environment.config.dynamicText.dTerms;
  backgroundImage = environment.config.dynamicImage.bgImage;
  dashboardLogo = environment.config.dynamicImage.dashboardLogo;

  formControl: any;
  setTimer: any;
  register: any = {
    company: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    password: '',
    confirm: '',
    checkbox: false,
  };

  visibilityBackground = {
    'background-image': 'url(' + this.backgroundImage + ')',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
    'background-attachment': 'fixed',
    'background-size': 'cover',
    height: '100vh',
  };
  disableBtn: boolean = false;

  startValidateCompany: boolean = false;
  startValidateFirstname: boolean = false;
  startValidateLastname: boolean = false;
  startValidateEmail: boolean = false;
  startValidatePhone: boolean = false;
  startValidatePassword: boolean = false;
  startValidateConfirm: boolean = false;

  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private router: Router,
    private getStates: GetUsStatesService
  ) {}

  ngOnInit() {
    this.setFormControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.message) {
    }
  }

  setFormControl = () => {
    const deepCopy = {
      company: {
        style: 'default',
        message: '',
        flag: false,
      },
      firstname: {
        style: 'default',
        message: ' ',
        flag: false,
      },
      lastname: {
        style: 'default',
        message: ' ',
        flag: false,
      },
      email: {
        style: 'default',
        message: ' ',
        flag: false,
      },
      phone: {
        style: 'default',
        message: ' ',
        flag: false,
      },
      password: {
        style: 'default',
        message: ' ',
        flag: false,
      },
      confirm: {
        style: 'default',
        message: ' ',
        flag: false,
      },
      checkbox: {
        style: 'default',
        message: ' ',
        flag: false,
      },
    };

    this.formControl = { ...deepCopy };
  };

  validateForm = () => {
    const validation = this.validate();

    if (validation) {
      this.emitEvent();
    }
  };

  isEmpty = (params) => {
    if (params.length === 0) {
      return true;
    }
    return false;
  };

  setErrorMessageAndStatus = (propname, bool, style, message) => {
    this.formControl[propname].flag = bool;
    this.formControl[propname].style = style;
    this.formControl[propname].message = message;
  };

  checkboxEvent = (event) => {
    this.register.checkbox = event;
    this.validate();
  };

  emitEvent = () => {
    this.data.emit({
      company: this.register.company,
      firstname: this.register.firstname,
      lastname: this.register.lastname,
      email: this.register.email,
      phone: this.register.phone,
      password: this.register.password,
      confirm: this.register.confirm,
    });
  };

  validate = () => {
    let errCount = 0;
    this.setFormControl();

    if (this.isEmpty(this.register.company)) {
      this.startValidateCompany = true;
      this.setErrorMessageAndStatus('company', true, 'error', 'Required Field');
      errCount++;
    }

    if (this.isEmpty(this.register.firstname)) {
      this.startValidateFirstname = true;
      this.setErrorMessageAndStatus('firstname', true, 'error', 'Required Field');
      errCount++;
    }

    if (this.isEmpty(this.register.lastname)) {
      this.startValidateLastname = true;
      this.setErrorMessageAndStatus('lastname', true, 'error', 'Required Field');
      errCount++;
    }

    if (this.isEmpty(this.register.email)) {
      this.startValidateEmail = true;
      this.setErrorMessageAndStatus('email', true, 'error', 'Required Field');
      errCount++;
    } else if (!this.validateEmail(this.register.email)) {
      this.startValidateEmail = true;
      this.setErrorMessageAndStatus('email', true, 'error', 'format (ex. email@domain.com) ');
      errCount++;
    }

    if (this.isEmpty(this.register.phone)) {
      this.startValidatePhone = true;
      this.setErrorMessageAndStatus('phone', true, 'error', 'Please enter valid phone number');
      errCount++;
    }
    if (this.isEmpty(this.register.password)) {
      this.startValidatePassword = true;
      this.setErrorMessageAndStatus('password', true, 'error', 'Required Field');
      errCount++;
    } else if (this.register.password.length < 8) {
      this.startValidatePassword = true;
      this.setErrorMessageAndStatus('password', true, 'error', 'Minimum of 8 character password');
      errCount++;
    }
    if (this.isEmpty(this.register.confirm)) {
      this.startValidateConfirm = true;
      this.setErrorMessageAndStatus('confirm', true, 'error', 'Required Field');
      errCount++;
    } else if (this.register.confirm !== this.register.password) {
      this.startValidateConfirm = true;
      this.setErrorMessageAndStatus('confirm', true, 'error', 'Password mismatch');
      errCount++;
    }

    if (!this.register.checkbox) {
      this.setErrorMessageAndStatus('checkbox', true, 'error', 'Required Field');
      errCount++;
    }

    this.disableBtn = errCount !== 0;
    return errCount === 0;
  };

  keyEvent = (event, type) => {
    if (type === 'company') {
      if (this.startValidateCompany) {
        this.validate();
      }
    }

    if (type === 'firstname') {
      if (this.startValidateFirstname) {
        this.validate();
      }
    }

    if (type === 'lastname') {
      if (this.startValidateLastname) {
        this.validate();
      }
    }

    if (type === 'email') {
      if (this.startValidateEmail) {
        this.validate();
      }
    }
    if (type === 'phone') {
      if (this.startValidatePhone) {
        this.validate();
      }
    }
    if (type === 'password') {
      // if (this.startValidatePassword) {
      this.validate();
      // }
    }
    if (type === 'confirm') {
      // if (this.startValidateConfirm) {
      this.validate();
      // }
    }
  };

  validateEmail = (email) => {
    // tslint:disable-next-line: max-line-length
    const regexEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEx.test(String(email).toLowerCase());
  };
  
  routeToHomePage = () => {
    this.router.navigate(['login']);
  }
}
