import { Subscription } from 'rxjs'
import { TableFilterService } from 'src/app/services/table-filter.service'

import { Component, OnInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'

import { environment } from '../../../../environments/environment'
import { GetCloudService } from '../../../services/get-cloud.service'
import { RefreshApiService } from '../../../services/refresh-api.service'

@Component({
  selector: 'app-cloud-all-rooms-panel',
  templateUrl: './cloud-all-rooms-panel.component.html',
  styleUrls: ['./cloud-all-rooms-panel.component.scss'],
})
export class CloudAllRoomsPanelComponent implements OnInit {
  PopupTitle: string = 'CLOUD ROOMS';
  public cloudRoomSummary;
  public cloudsitesStats;
  id: number;
  refreshData: any;
  filterListner: Subscription;
  cloudRoomsListData: MatTableDataSource<any>;
  cloudApiData: any = [];
  loader = environment.config.dynamicImage.loader;
  baseFilter: string;
  loading: any = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getCloudDataService: GetCloudService,
    private refreshApiService: RefreshApiService,
    private tableFilterService: TableFilterService
  ) {
    this.cloudRoomsListData = new MatTableDataSource();

    this.filterListner = this.tableFilterService.listen().subscribe((m: any) => {
      if (m == '') {
        if (this.router.url === '/cloud/impactedrooms') {
          this.baseFilter = 'impacted';
        } else if (this.router.url === '/cloud/healthyrooms') {
          this.baseFilter = 'healthyrooms';
        } else if (this.router.url === '/cloud/warningrooms') {
          this.baseFilter = 'warningrooms';
        } else if (this.router.url === '/cloud/redrooms') {
          this.baseFilter = 'redrooms';
        } else if (this.router.url === '/cloud/pausedrooms') {
          this.baseFilter = 'pausedrooms';
        } else if (this.router.url === '/cloud/allrooms') {
          this.baseFilter = 'all';
        }
      } else {
        this.baseFilter = m;
      }
      this.cloudRoomsListData.filter = '-'.trim().toLocaleLowerCase();
    });

    this.cloudRoomsListData.filterPredicate = (data: any, filter: string) => {
      var dataStr = data.room_name + data.health + data.liveMeetings + data.company_name;
      if (this.baseFilter == 'impacted') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health < 76;
        } else {
          return (
            data.health < 76 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'all') {
        if (filter == '-' || filter == '' || filter == null) {
          return true;
        } else {
          return (
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'liveMeetings') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.liveMeetings == 1;
        } else {
          return (
            data.liveMeetings == 1 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'online') {
        if (filter == '-' || filter == '' || filter == null) {
          // return data.online == 1;
          return data.status === 'Online' && data.pause === 0;
        } else {
          return (
            // data.online == 1 &&
            data.status === 'Online' && data.pause === 0 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      } else if (this.baseFilter == 'impactedOnly') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health < 76;
        } else {
          return (
            data.health < 76 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      }

      // ------------------------------------------------------------------------------------------------------------

      if (this.baseFilter == 'healthyrooms') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health >= 76 && data.pause === 0;
        } else {
          return (
            data.health >= 76 &&
            data.pause === 0 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      }

      if (this.baseFilter == 'warningrooms') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health >= 51 && data.health < 76 && data.pause === 0;
        } else {
          return (
            data.health >= 51 &&
            data.health < 76 &&
            data.pause === 0 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      }

      if (this.baseFilter == 'redrooms') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.health <= 50 && data.pause === 0;
        } else {
          return (
            data.health <= 50 &&
            data.pause === 0 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      }

      if (this.baseFilter == 'pausedrooms') {
        if (filter == '-' || filter == '' || filter == null) {
          return data.pause === 1;
        } else {
          return (
            data.pause === 1 &&
            dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
          );
        }
      }
    };
    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });

    // This recieves the update data boolean to start the timer
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      this.refreshData = m;
      this.getApiData();
    });
  }

  ngOnInit() {
    this.getcloudApiData();
  }

  getApiData() {
    if (this.refreshData) {
      // // console.log("All Devices - Getting updated API data");
      this.getcloudApiData();
    }
  }

  // This will Cloud Room Data from server API
  getcloudApiData = () => {
    // this.cloudApiData = [];
    const postData = {
      list_limit: 500,
      off_set: 0,
      search_key: '',
      service_type: 'all'
    }
    this.getCloudDataService.fetchApiCloudAllRooms(postData).subscribe((data) => {
      const res = data.response.map(e => {
        return {
          ...e,
          livembps: this.checkIfUserIsOnCall(e) //calculation for teams will be here
        }
      });
      this.cloudRoomsListData.data = res.filter(e => e.service_type === 'zoom' || (e.service_type === 'teams' && e.is_monitored == 1))

    console.log(this.cloudRoomsListData.data)

      this.loading = false;
    });

    this.getCloudDataService.fetchApiCloudRoomSummary().subscribe((data: any) => {
      this.cloudRoomSummary = data['response'][0];
    });
  }

  checkIfUserIsOnCall = (params) => {
    const { liveMeetings, service_type, total_bitrate } = params;

    if (!liveMeetings) return 0;

    // const bitrateValid = ![null, '', undefined].includes(total_bitrate) && +total_bitrate > 0;

    // if (service_type === 'zoom' && bitrateValid)
    //   return (total_bitrate / (1024 * 1024)).toFixed(2);

    if (!this.getTeamsPluginEnabled(params)) return 0;

    const networkMonitorTeams = this.getNetworkMonitorTeams(params);

    if (!networkMonitorTeams) return 0;

    return this.calculatedMbpsTeams(networkMonitorTeams)
  }

  getTeamsPluginEnabled = (params) => {
    return params.plugin_id != null && params.zoom_plugin_enabled && params.plugin_status == 1
  }

  calculatedMbpsTeams = ({ receive_speed, send_speed }) => {
    if (receive_speed && send_speed) {
      receive_speed = +receive_speed.split(" ")[0];
      send_speed = +send_speed.split(" ")[0];
			const mbps = send_speed + receive_speed
			return (mbps / 122).toFixed(2)
		} else {
			return 0
		}
  }

  getNetworkMonitorTeams = (params) => {
    const { service_type, zoom_plugin_data } = params;
    const parseZoomData = JSON.parse(zoom_plugin_data);

    if(!['teams', 'zoom'].includes(service_type)) return false;
    if (!parseZoomData) return false;
    const network_monitor = service_type === 'zoom' ? parseZoomData?.callSimulator?.zoom?.network_monitor : parseZoomData?.callSimulator?.teams?.network_monitor;
    return network_monitor || '--';
  };

}
