import * as moment from 'moment-timezone'

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

import { GetIotService } from '../../services/get-iot.service'
import { PopupTypeService } from '../../services/popupType.service'

@Component({
	selector: 'app-iot-lite-device-latest-event',
	templateUrl: './iot-lite-device-latest-event.component.html',
	styleUrls: ['./iot-lite-device-latest-event.component.scss'],
})
export class IotLiteDeviceLatestEventComponent implements OnInit, OnChanges {
	@Input() deviceHisoryParams: any
	@Input() deviceID: any = '--'

	lastEventList: any
	lastEventForFilter: any
	shouldShowMore: Boolean = true
	dynamicClass: String = 'latestevent__noevent'
	deviceId: any = ''
	deviceName: any = ''
	iotIncidentList: any

	constructor(private getIot: GetIotService, public ds: PopupTypeService) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.deviceHisoryParams && this.deviceHisoryParams) {
			const { iot_id, iot_name } = this.deviceHisoryParams
			this.deviceID = iot_id
			this.getIotIncidentList()
		}
	}

	openIotDeviceHealthHistory = () => {
		const data = {
			iot_id: this.deviceID,
			iot_name: this.deviceName,
			days: 30,
		}
		this.ds.sendPopupType(74, {
			data,
		})
	}

	getIotIncidentList = () => {
		this.getIot.getIotLiteIncidents(this.deviceID, 30).subscribe(
			(data: any) => {
				const res = data.response

				this.iotIncidentList = res.map(i => {
					const events = JSON.parse(i.events)
					const event_details = []

					for (const event in events) {
						event_details.push({
							trigger: this.translateTrigger(event, events[event]),
							pulls: events[event].pulls,
							// last_pull: this.printTime(events[event].last_pull),
							// end_date: this.printTime(events[event].end_date),
							// start_date: this.printTime(events[event].start_date),
							last_pull: events[event].last_pull,
							end_date: events[event].end_date,
							start_date: events[event].start_date,
						})
					}

					const incident = {
						incident_id: `I-${i.incident_id}`,
						// start_time: this.printTime(i.start_time),
						// end_time: this.printTime(i.end_time),
						start_time: i.start_time,
						end_time: i.end_time,
						event_details: this.removeEmpty(event_details),
					}

					return incident
				})

				// console.log(':iotIncidentList: ', this.iotIncidentList)
			},
			err => {}
		)
	}

	removeEmpty = arr => arr.filter(i => i.trigger)

	// printTime = time => {
	// 	if (time == null || !time) {
	// 		return '--'
	// 	} else {
	// 		return moment(time).format('l, hh:mm a')
	// 	}
	// }

	printTable = flag => {
		const elementId = flag ? 'table_1' : 'table_2'
		const contents = document.getElementById(elementId).innerHTML
		const frame1 = document.createElement('iframe')
		frame1.name = 'frame1'
		frame1.style.position = 'absolute'
		frame1.style.top = '-1000000px'
		document.body.appendChild(frame1)
		const frameDoc = frame1.contentWindow
		frameDoc.document.open()
		frameDoc.document.write(`
          <html>
          <head>
                <title>Print Report</title>
                <style>
                h1 {
                  margin-left: 20px;
                }
                .table-1 {
                  width: 100%;
                  margin: 20px auto;
                }
                table tr td {
                  padding: 5px;
                  width: 200px;
                  text-align: center;
                }
                </style>
              </head>
            <body onload="window.print(); window.close()">
            ${contents}
            </body>
          </html>
        `)
		setTimeout(function () {
			window.frames['frame1'].focus()
			window.frames['frame1'].print()
			document.body.removeChild(frame1)
		}, 500)
		return false
	}

	translateTrigger(trigger: string, event: any) {
		if (trigger === 'packetloss') return event.message
		if (trigger !== 'iot_component_offline') return `IoT ${this.deviceName} ${trigger} is down.`
		return
	}
}
