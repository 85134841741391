import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GetVideoService } from '../../services/get-video.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-scheduled-meetings',
  templateUrl: './scheduled-meetings.component.html',
  styleUrls: ['./scheduled-meetings.component.scss'],
})
export class ScheduledMeetingsComponent implements OnInit, OnChanges {
  @Input() date: string = '--';
  @Input() time: string = '--';
  @Input() title: string = 'Scheduled Meetings';
  @Input() site: string = '--';
  @Input() organizer: string = '--';
  meetingsResponseData: any;
  @Input() popData: any;
  @Input() popupState: any;

  constructor(private getVideoService: GetVideoService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.popupState.currentValue == 'open') {
      if (this.popData != undefined) {
        this.meetingsResponseData = this.popData.meetings;
        // console.log('This is the meetings array', this.meetingsResponseData);
      }
    } else {
      setTimeout(() => {
        this.meetingsResponseData = [];
      }, 500);
    }
  }
  // printTime(t) {
  //   if (isNaN(t)) {
  //     let a = moment(t);
  //     return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
  //   } else {
  //     let a = moment.unix(t);
  //     return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
  //   }
  // }

  // printEndTime(t) {
  //   if (isNaN(t)) {
  //     let a = moment(t);
  //     return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mma');
  //   } else {
  //     let a = moment.unix(t);
  //     return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mma');
  //   }
  // }

  getMeetings() {
    // this.getVideoService.apiGetVideoDeviceDetails(this.popData.deviceId).subscribe(data =>{
    //   // console.log(data)
    // })
    if (this.popData != undefined) {
      this.meetingsResponseData = this.popData.meetings;
      // console.log('This is the meetings array', this.meetingsResponseData);
    }
  }

  ngOnInit() {
    // this.getMeetings();
  }
}
