import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-btn-device-screen',
  templateUrl: './btn-device-screen.component.html',
  styleUrls: ['./btn-device-screen.component.scss'],
})
export class BtnDeviceScreenComponent implements OnChanges {
  @Input() pluginStatus: Number = 0;
  @Input() pluginEnabled: Number = 0;
  statusLabel: String = 'Online';
  statusColor: String = 'circle-green';

  dPlugin: String = environment.config.dynamicText.dPlugin;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pluginStatus) {
      if (this.pluginEnabled === 0) {
        this.statusLabel = 'Offline';
        this.statusColor = 'circle-grey';
      } else if (this.pluginStatus === 1) {
        this.statusLabel = 'Online';
        this.statusColor = 'circle-green';
      } else if (this.pluginStatus === -1) {
        this.statusLabel = 'Offline';
        this.statusColor = 'circle-red';
      } else if (this.pluginStatus === 2) {
        this.statusLabel = 'Offline';
        this.statusColor = 'circle-yellow';
      } else {
        this.statusLabel = 'Offline';
        this.statusColor = 'circle-grey';
      }
    }
  }
}
