import { Component, OnInit, Input } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { GetTeamsService } from '../../services/get-teams.service';

@Component({
  selector: 'app-teams-management-service',
  templateUrl: './teams-management-service.component.html',
  styleUrls: ['./teams-management-service.component.scss'],
})
export class TeamsManagementServiceComponent implements OnInit {
  @Input() desktopClientDownloadLink: any;

  envTitle: any = environment.config.title;

  authLink: any = '';
  monitoringLink: any = '';

  tenantId: any = '';
  enabled: any;
  notification: any;
  enableCheckbox: boolean = false;
  apiKey: any;
  apiSecret: any;
  webToken: any;

  shouldShowMsg: boolean = false;
  msg: string = 'Tenant ID is sucessfully added.';
  msgColor: string = ' ';
  setTimer: any;

  shouldShowAcceptanceLink: any = true;
  isTenantIdEmpty: any = false;
  shouldShowMonitoringLink: any;

  href2: any = 'https://docs.microsoft.com/en-us/onedrive/find-your-office-365-tenant-id';

  constructor(private getTeams: GetTeamsService) { }

  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const u = helper.decodeToken(token);

    const { company_id } = helper.decodeToken(token);
    return company_id;
  };

  ngOnInit() {
    this.GetIsTeamsActivated();
    this.getTeamsInfo();
    this.getEnableMonitoringUrl();
    this.getMonitoringDetails();
  }

  getMonitoringDetails = () => {
    this.getTeams.getMonitoringDetails().subscribe(
      (data: any) => {
        this.shouldShowMonitoringLink = data !== true ? false : data;
        // // console.log('getMonitoringDetails: ', this.shouldShowMonitoringLink);
      },
      (err) => {
        // console.log('getMonitoringDetails: ', err)
      }
    );
  };

  getTeamsInfo = () => {
    this.getTeams.getConfig().subscribe(
      (data: any) => {
        const { enable_url, tenant_id } = data;
        this.authLink = enable_url;
        this.tenantId = tenant_id;
        this.checkIfTenentIdIsNotEmpty(tenant_id);
      },
      (err) => { }
    );
  };

  checkIfTenentIdIsNotEmpty = (id) => {
    this.isTenantIdEmpty = id ? false : true;
  };

  GetIsTeamsActivated = () => {
    this.getTeams.GetIsTeamsActivated().subscribe(
      (data: any) => {
        this.shouldShowAcceptanceLink = data !== true ? false : data;

        // // console.log(this.shouldShowAcceptanceLink, 'this.shouldShowAcceptanceLink')
      },
      (err) => { }
    );
  };

  updateTenantId = () => {
    this.getTeams.updateTenantId(this.tenantId).subscribe(
      (data) => {
        this.msgConfig('Tenant ID is sucessfully added.', '');
        this.getTeamsInfo();
        // window.open(this.monitoringLink);
        if (this.shouldShowAcceptanceLink && !this.shouldShowMonitoringLink) {
          window.open(this.monitoringLink);
        } else {
          window.open(this.authLink);
        }
      },
      (err) => {
        this.msgConfig('Invalid Tenant ID.', 'teams-service__success-msg--red');
      }
    );
  };

  getEnableMonitoringUrl = () => {
    this.getTeams.getEnableMonitoringUrl().subscribe(
      (data: any) => {
        // console.log(data)
        const { enable_url } = data;
        this.monitoringLink = enable_url;
      },
      (err) => {
        // console.log('getEnableMonitoringUrl', err)
      }
    );
  };

  msgConfig = (text, color) => {
    this.shouldShowMsg = true;
    this.msg = text;
    this.msgColor = color;
    this.setTimer = setTimeout(() => {
      this.shouldShowMsg = false;
      this.clearTimeout();
    }, 3000);
  };

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

  syncTeamsWebhooks = () => {
    this.getTeams.syncTeamsWebhooks().subscribe(
      (data) => {
        this.msgConfig('Sync Account Success.', '');
      },
      (err) => {
        this.msgConfig('Sync Account Failed.', 'teams-service__success-msg--red');
      }
    );
  };
}
