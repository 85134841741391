import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-audio-system-status',
  templateUrl: './audio-system-status.component.html',
  styleUrls: ['./audio-system-status.component.scss'],
})
export class AudioSystemStatusComponent implements OnInit {
  @Input() networkIcon: string;
  @Input() micIcon: string;
  @Input() micStatus: string;
  @Input() isDisplayPresence: boolean;
  @Input() lanPortStatus: string;

  constructor() {}

  ngOnInit() {}
}
