import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GetVideoService } from '../../services/get-video.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-device-call-history',
  templateUrl: './device-call-history.component.html',
  styleUrls: ['./device-call-history.component.scss'],
})
export class DeviceCallHistoryComponent implements OnInit, OnChanges {
  @Input() popData: any;
  @Input() popupState: any;
  heading = '';
  callData: any;
  constructor(private getVideoService: GetVideoService, private Auth: AuthService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.popupState.currentValue == 'open') {
      if (this.popData != undefined) {
        if (this.popData.deviceId == null) {
        } else {
          this.getData();
        }
      }
    } else {
      setTimeout(() => {
        this.callData = [];
      }, 500);
    }
  }

  // printTime(t) {
  //   if (t == null) {
  //     return '--';
  //   } else {
  //     return moment(t)
  //       .utc()
  //       .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
  //       .format('MM-DD-YYYY hh:mma');
  //   }
  // }
  printDuration(s) {
    var date = new Date(null);
    date.setSeconds(s); // specify value for SECONDS here
    var result = date.toISOString().substr(11, 8);
    return result;
  }

  // printEndTime(t) {
  //   let a = moment.unix(t);

  //   return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mma');
  // }

  getData() {
    // console.log('GETTING Incident Data');
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    switch (this.popData.type) {
      case 'FAILED':
        this.heading = 'Failed Calls';
        break;
      case 'SCHEDULED':
        this.heading = 'Scheduled Calls';
        break;
      case 'ADHOC':
        this.heading = 'Adhoc Calls';
        break;
    }
    this.Auth.getDeviceCallLogs({
      company_id: decodedToken.company_id,
      device_id: this.popData.deviceId,
      type: this.popData.type,
    }).subscribe((data) => {
      this.callData = data.response;
    });
  }

  formatAddress = (address, ctype) => {
    const textToReplace = ctype.toLowerCase() + ':';

    if (address === null) {
      return '--';
    }

    return address.replace(textToReplace, '').trim();
  };

  ngOnInit() {
    // this.getMeetings();
  }
}
