import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AuthService } from 'src/app/services/auth.service'
import { IncidentMessage, IncidentMessagesService, IncidentType } from 'src/app/services/incident-messages.service'

@Component({
	selector: 'app-incident-messages',
	templateUrl: './incident-messages.component.html',
	styleUrls: ['./incident-messages.component.scss'],
})
export class IncidentMessagesComponent implements OnInit {
	incidentId: number
	incidentType: IncidentType
	message: string
	messages: IncidentMessage[]
	editedMessage: IncidentMessage
	editedMessageIndex: number
	newFiles: File[]
	deletedFiles: {
		document_id: number
		file_name: string
	}[] = []
	user: any
	errorMessage: string

	constructor(
		private dialogRef: MatDialogRef<IncidentMessagesComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private incidentMessagesService: IncidentMessagesService,
		private auth: AuthService
	) {
		this.incidentId = data?.incidentId
		this.incidentType = data?.incidentType
	}

	ngOnInit(): void {
		this.getMessages()
		this.auth.getUserInfo().subscribe(resp => {
			this.user = resp.response[0]
		})

		this.dialogRef.keydownEvents().subscribe(event => {
			if (event.key === 'Escape') {
				this.closeDialog()
			}
		})
		this.dialogRef.backdropClick().subscribe(event => {
			this.closeDialog()
		})
	}

	closeDialog = () => {
		this.dialogRef.close(this.messages?.length > 0)
	}

	getMessages(): void {
		this.incidentMessagesService.getMessages(this.incidentId, this.incidentType).subscribe(msgs => {
			this.messages = msgs
		})
	}

	onEditMessage(message: IncidentMessage, idx: number): void {
		this.editedMessage = JSON.parse(JSON.stringify(message)) // deep copy attachments too so that the changes don't reflect on the original msg while editing
		this.editedMessageIndex = idx
		this.message = message?.message
	}

	onCancelEditing(): void {
		this.clear()
	}

	onAttachFile(event: Event): void {
		const target = event.target as HTMLInputElement
		// this.newFiles = Array.from(target.files)
		this.errorMessage = null
		const newFiles = []
		const fifteenMB = 15728640 // 15 * 1024 *1024
		for (let i = 0; i < target.files.length; i++) {
			let file = target.files.item(i)
			if (file.size <= fifteenMB) newFiles.push(file)
			else {
				this.errorMessage =
					'A file cannot exceed 15 MB in size. Please choose a smaller file or consider compressing it'
				break
			}
		}
		if (newFiles.length && !this.errorMessage) this.newFiles = newFiles
	}

	onRemoveNewAttachedFile(idx: number): void {
		this.newFiles.splice(idx, 1)
	}

	onRemoveEditedMessageFile(
		doc: {
			document_id: number
			file_name: string
		},
		idx: number
	): void {
		this.editedMessage.attachments?.splice(idx, 1)
		this.deletedFiles.push(doc)
	}

	clear(): void {
		this.message = null
		this.editedMessage = null
		this.editedMessageIndex = null
		this.newFiles = null
		this.errorMessage = null
	}

	onSendOrUpdateMessage(): void {
		this.errorMessage = null
		if (this.editedMessage) this.updateMessage()
		else this.createMessage()
	}

	// createMessage(): void {
	// 	this.incidentMessagesService.createMessage(this.incidentId, this.message).subscribe((id: number) => {
	// 		this.messages.push(
	// 			new IncidentMessage({
	// 				message_id: id,
	// 				message: this.message,
	// 				created_at: new Date(),
	// 				user: this.user,
	// 			})
	// 		)
	// 		this.message = null
	// 	})
	// }

	createMessage(): void {
		if (this.message || this.newFiles) {
			const formData = new FormData()
			if (this.message) formData.append('message', this.message)
			// let fileNames = []
			if (this.newFiles) {
				formData.append('companyId', this.auth?.companyId)
				for (let i = 0; i < this.newFiles?.length; i++) {
					formData.append('attachments', this.newFiles[i])
					// fileNames.push(this.newFiles[i].name)
				}
			}

			this.incidentMessagesService.createMessage(this.incidentId, this.incidentType, formData).subscribe(
				(msg: IncidentMessage) => {
					// this.incidentMessagesService.createMessage(this.incidentId, this.message).subscribe((id: number) => {
					this.messages.push(
						new IncidentMessage({
							...msg,
							message: this.message,
							created_at: new Date(),
							user: this.user,
							// attachments: fileNames,
						})
					)
					this.message = null
					this.newFiles = null
				},
				err => (this.errorMessage = err?.error)
			)
		}
	}

	// updateMessage(): void {
	// 	this.editedMessage.message = this.message
	// 	this.incidentMessagesService.updateMessage(this.editedMessage, this.deletedFiles).subscribe(resp => {
	// 		this.messages[this.editedMessageIndex] = new IncidentMessage({
	// 			...this.editedMessage,
	// 			edited_at: new Date(),
	// 			edited_by: this.user,
	// 		})
	// 		this.clear()
	// 	})
	// }
	updateMessage(): void {
		if (this.message !== this.editedMessage.message || this.newFiles || this.deletedFiles.length !== 0) {
			const formData = new FormData()
			formData.append('message_id', this.editedMessage.message_id.toString())
			formData.append('companyId', this.auth?.companyId)
			if (this.message) formData.append('message', this.message)
			// let fileNames = []
			if (this.newFiles) {
				for (let i = 0; i < this.newFiles?.length; i++) {
					formData.append('attachments', this.newFiles[i])
					// fileNames.push(this.newFiles[i].name)
				}
			}
			if (this.deletedFiles.length) formData.append('attachmentsToBeDeleted', JSON.stringify(this.deletedFiles))

			this.incidentMessagesService.updateMessage(formData).subscribe(
				resp => {
					this.messages[this.editedMessageIndex] = new IncidentMessage({
						...this.editedMessage,
						message: this.message,
						edited_at: new Date(),
						edited_by: this.user,
						attachments: [...this.editedMessage.attachments, ...resp.attachments],
					})
					this.clear()
				},
				err => (this.errorMessage = err?.error)
			)
		}
	}
}
