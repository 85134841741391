import { Component, OnInit, Input, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-cloud-user-connected-devices",
  templateUrl: "./cloud-user-connected-devices.component.html",
  styleUrls: ["./cloud-user-connected-devices.component.scss"]
})
export class CloudUserConnectedDevicesComponent implements OnInit {
  @Input() microphone: any;
  @Input() speaker: any;
  @Input() camera: any;
  @Input() device: any;
  @Input() ipAddress: any;
  @Input() connectionType: any;
  @Input() networkType: any;
  @Input() onCall: any;
  @Input() isRoom: any;
  @Input() status: any;
  @Input() dataReceived: any = false;
  roomIp: string;
  constructor() {}
  ngOnChanges(changes: SimpleChanges) {
    
  }
  nullCheck(value) {
    return value ? value : "--";
  }

  computerCheck(value) {
    if (!this.dataReceived) {
      return "--"
    } else  {
      if (this.isRoom) {
        if (this.onCall) {
          if (value && value != "" && value != " ") {
            return value;
          } else {
            return "Not Detected";
          }
        } else {
          if (this.status == "Offline") {
            return this.status;
          } else {
            return "Online";
          }
        }
      } else {
        if (this.onCall) {
          if (value && value != "" && value != " ") {
            return value;
          } else {
            return "Not Detected";
          }
        } else {
          return "--";
        }
      }
    }
  }

  deviceCheck(value) {
    if (!this.dataReceived) {
      return "--"
    } else  {
      if (this.isRoom) {
        if (value && value != "" && value != " ") {
          return value;
        } else {
          return "Not Detected";
        }
      } else {
        if (this.onCall) {
          if (value && value != "" && value != " ") {
            return value;
          } else {
            return "Not Detected";
          }
        } else {
          return "--";
        }
      }
    }
  }

  stringReducer(str) {
    if (str) {
      var newStr = str.split(";")[0];
    }
    // console.log("Shorten ip address");
    // console.log(newStr);
    return str ? newStr : "--";
  }

  findIpDetails(ipDetails, query) {
    if (!this.dataReceived) {
      return "--"
    } else  {
      if (!ipDetails) {
        return "--";
      } else {
        let ipdetailsArr = ipDetails.split(";");
        this.roomIp = ipdetailsArr.filter(s => s.includes(query));
        if (ipdetailsArr.length == 1) {
          return ipdetailsArr[0].replace("Computer :", "");
        } else if (this.roomIp.length > 0) {
          return this.roomIp[0].replace("Computer :", "");
        } else {
          return "--";
        }
      }
    }
  }

  ngOnInit() {}
}
