<div class="teams">
  <app-download-icons [componentName]="componentName" [cloudRadioProp]="tableSelected"
    [desktopClientDownloadLink]="desktopClientDownloadLink" [siteSummary]="siteSummary"
    (sendPopupType)="sendPopupType($event)"></app-download-icons>

  <div [ngClass]="{ 'teams__margin-top': tableSelected === 'service' }" class="teams__radio-button-and-search">
    <div class="teams__radio-button">
      <mat-radio-group [(ngModel)]="tableSelected" (change)="radioChange($event.value)">
        <mat-radio-button value="service">Integrate & Manage Service</mat-radio-button>
        <mat-radio-button value="rooms">List of Rooms</mat-radio-button>
        <mat-radio-button value="users">List of Users</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- <div *ngIf="tableSelected !=='service'" class="teams__search"> -->
    <div [ngClass]="{ 'teams__disappear': tableSelected === 'service' }" class="teams__search">
      <div class="teams__icon">
        <i class="fa fa-search"></i>
      </div>
      <input #yourElement type=" text" class="search-default" type="text" placeholder="" [(ngModel)]="searchKey" />
    </div>

  </div>
  <div [ngClass]="{ 'teams__margin-top': tableSelected === 'service' }" class="teams__table-container">
    <div>
      <img class="teams__service-loader" *ngIf="showServiceLoader" src="{{ btnLoader }}" alt="" />
    </div>
    <app-teams-management-service *ngIf="tableSelected === 'service' && spaceStatus === false && !showServiceLoader">
    </app-teams-management-service>

    <app-teams-spaces-management-service
      *ngIf="tableSelected === 'service' && spaceStatus === true && !showServiceLoader">
    </app-teams-spaces-management-service>

    <app-teams-rooms *ngIf="tableSelected ==='rooms'" [hostToLink]="hostToLink" (sendPopupType)="sendPopupType($event)"
      [teamsRoomList]="teamsRoomList" [searchKey]="searchKey" [showLoader]="showLoader" [spaceStatus]="spaceStatus" [getRoomsList]="triggerGetRoomsList">
    </app-teams-rooms>

    <app-teams-users *ngIf="tableSelected === 'users'" [teamsUserList]="teamsUserList"
      (sendPopupType)="sendPopupType($event)" [searchKey]="searchKey" [hostToLink]="hostToLink"
      [totalTeamsUser]="this.siteSummary.total_teams_users" [showLoader]="showLoader" [totalUserCount]="totalUserCount"
      [spaceStatus]="spaceStatus">
    </app-teams-users>

    <!-- [totalTeamsUser]="this.siteSummary.total_teams_users" [showLoader]="showLoader" [totalUserCount]="totalUserCount"> -->
  </div>
</div>