import { AuthService } from 'src/app/services/auth.service'

import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'

import { GetIotService } from '../../services/get-iot.service'
import { WebportalProtocolConfigDialogComponent } from '../dialogs/webportal-protocol-config-dialog/webportal-protocol-config-dialog.component'

export interface DeviceListInterface {
  agent_id: number;
  display_name: string;
  iot_device_id: number;
  ip_address: string;
  make: string;
  device_name: string;
  model: string;
  ping_rtd: number;
  status: string;
  company_name: string;
}

@Component({
  selector: 'app-iot-lite-summary-list',
  templateUrl: './iot-lite-summary-list.component.html',
  styleUrls: ['./iot-lite-summary-list.component.scss']
})

export class IotLiteSummaryListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() filterStatus: String;
  @Input() filterAgent: number;
  displayedColumns: string[];
  dataSource: MatTableDataSource<DeviceListInterface>;
  deviceList: any = [];
  pageSizeSelect: any[] = [];
  totalPageLimit = 500;
  paginateOjb: any = {
    currentPage: 1,
    endIndex: 4,
    pageSize: 0,
    pages: [],
    startIndex: 0,
    totalItems: 0,
    totalPages: 0,
  };
  iotLiteEnabled: any;
  totalCount: number;
  pageSelected: any = 0;
  searchKey: String = 'null';
  refreshData: Boolean;
  greenIcon = '../../../assets/img/triangle_green.png';
  redIcon = '../../../assets/img/triangle_red.png';


  constructor(
    private getIot: GetIotService,
    public Auth: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.displayedColumns = this.isShowCompanyName()
      ? [
        'company_name',
        'iot_name',
        'health',
        // 'space_name',
        'mac_address',
        'iot_ip',
        'make',
        // 'webportal',
      ]
      : [
        'iot_name',
        'health',
        // 'space_name',
        'mac_address',
        'iot_ip',
        'make',
        // 'webportal',
      ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.dataSource = new MatTableDataSource(this.deviceList);
    if (changes.filterStatus && this.filterStatus) {
      this.getAllDomotzIotDevicesSummaryList();
    }
  }

  ngOnInit() {
    // this.getAllDomotzIotDevicesSummaryList();
  }

  getAllDomotzIotDevicesSummaryList = () => {
    const filterAgent = this.filterAgent ? this.filterAgent : -1;

    console.log('this.searchKey: ', this.searchKey)
    console.log('this.totalPageLimit: ', this.totalPageLimit)
    console.log('this.paginateOjb.startIndex: ', this.paginateOjb.startIndex)
    console.log('this.filterStatus: ', this.filterStatus)
    console.log('this.filterAgent: ', this.filterAgent)

    this.getIot
      .getAllDomotzIotDevicesSummaryList(
        this.searchKey,
        this.totalPageLimit,
        this.paginateOjb.startIndex,
        this.filterStatus,
        filterAgent
      )
      .subscribe((data: any) => {
        this.deviceList = data.map(e => {
          return {
            ...e,
            health: e.health == null ? 0 : e.health
          }
        });
        console.log('this.deviceList', this.deviceList)
        this.dataSource = new MatTableDataSource(this.deviceList);
        this.dataSource.sort = this.sort;
      });
  };

  searchData = (key) => {
    this.searchKey = key;
    this.getAllDomotzIotDevicesSummaryList();
  };

  clickRow(routeId) {
    //check for iot enabled
    this.router.navigate(['iot/lite/device/', routeId]);
  }

  openWebPortalOptions = (iotDeviceId) => {
    const webportal = this.dialog.open(WebportalProtocolConfigDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        iotDeviceId,
      },
    });
  };

  isShowCompanyName = () => {
    return this.Auth.accessLevel === 'SUPERADMIN';
  };

  getIotLiteStatus = () => {
    this.getIot.getIotLiteStatus().subscribe((data) => {
      let [res] = data.response;
      this.iotLiteEnabled = res.is_iot_lite;
      // // console.log('iotLiteEnabled: ',this.iotLiteEnabled)
    });
  }
}
