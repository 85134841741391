<div>
  <app-old-register
    *ngIf="!loginEnv"
    [message]="message"
    (data)="registerEmitEvent($event)"
  ></app-old-register>
  <app-new-register
    *ngIf="loginEnv"
    [message]="message"
    [beingRegistrationFormSubmitted]="beingRegistrationFormSubmitted"
    (data)="registerEmitEvent($event)"
  ></app-new-register>
</div>
