<div class="device-action">
  <div class="btn-container">
    <button mat-raised-button *ngIf="isShowWakeUpBtn()" (click)="wakeUp()">wakeup</button>
    <button mat-raised-button *ngIf="isShowRebootBtn()" (click)="reboot()">reboot</button>
    <button mat-raised-button *ngIf="isShowRebootPcBtn()" (click)="rebootPc()">reboot pc</button>
    <button mat-raised-button *ngIf="isShowRestartZoomBtn()" (click)="restartZoom()">
      restart zoom
    </button>
    <button mat-raised-button (click)="showPinInputDef()">pin</button>
  </div>

  <div *ngIf="showEmailInput" class="row">
    <div class="col-10 input-style">
      <input type="text" id="requestorEmail" placeholder="Input your email address.." />
    </div>

    <div class="col-2 btn-img">
      <img
        src="../../../../assets/img/teleportivity_img/send_icon.png"
        (click)="createPin()"
        alt=""
      />
      <img
        src="../../../../assets/img/teleportivity_img/x_icon.png"
        (click)="closeInputAndEmailDef(); closeActionStatus()"
        alt=""
      />
    </div>
  </div>

  <div *ngIf="showPinInput" class="row">
    <div class="col-10 input-style">
      <input type="text" id="pin" placeholder="Input pin" />
    </div>

    <div class="col-2 btn-img">
      <img
        src="../../../../assets/img/teleportivity_img/send_icon.png"
        (click)="executePin()"
        alt=""
      />
      <img
        src="../../../../assets/img/teleportivity_img/x_icon.png"
        (click)="closeInputAndEmailDef(); closeActionStatus()"
        alt=""
      />
    </div>
  </div>

  <div class="action-status">
    <div class="loader" *ngIf="isShowLoader">
      <app-simple-loader></app-simple-loader>
    </div>
    <p [ngClass]="{ 'error': actionResult.isError, 'good': !actionResult.isError }">
      {{ actionResult.message }}
    </p>
  </div>
</div>
