import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-video-system-status',
  templateUrl: './video-system-status.component.html',
  styleUrls: ['./video-system-status.component.scss'],
})
export class VideoSystemStatusComponent implements OnInit, OnChanges {
  @Input() vMics: number;
  @Input() vMicsImpacted: number;
  @Input() vCameras: number;
  @Input() vCamerasImpacted: number;
  @Input() temperature: number;
  @Input() temeratureMsg: string;
  @Input() temperatureImage: string;
  @Input() isShowTemperature: boolean;

  mics: any;
  cameras: any;
  micsImpacted: any;
  camerasImpacted: any;

  ngOnChanges(changes: SimpleChanges): void {
    this.mics = this.vMics ? this.vMics : '--';
    this.micsImpacted = this.vMicsImpacted;
    this.cameras = this.vCameras ? this.vCameras : '--';
    this.camerasImpacted = this.vCamerasImpacted;
  }

  ngOnInit() {}
}
