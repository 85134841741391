<!-- stats buttons -->
<div class="row">
	<div class="col-12 pl-1">
		<h5>Live Stats</h5>
	</div>
	<div class="col-4 pl-0 pr-1">
		<app-btn-reg-stats [count]="cloudRoomSummary?.impacted" label='Impacted' color='gray' (click)="setFilter('impacted')" [ngClass]="{'outlineBtn': activeFilter == 'impacted'}" ></app-btn-reg-stats>
	</div>
	<div class="col-4 pl-0 pr-1">
		<app-btn-reg-stats [count]="cloudRoomSummary?.on_call" label='On Call' color='gray' (click)="setFilter('on_call')" [ngClass]="{'outlineBtn': activeFilter == 'on_call'}" ></app-btn-reg-stats>
	</div>
	<div class="col-4 pl-0 pr-1">
		<app-btn-reg-stats-twodec [count]="calMbps()" label='Live Mbps' color='gray' isbutton='not'></app-btn-reg-stats-twodec>
	</div>
</div>
<!-- hr line space -->
<div class="row">
  <div class="col-12 pl-0 pr-1 my-2">
    <hr>
  </div>
</div>
<!-- temporarily hiding scheduled meetings for zoom room -->
  <!-- stats buttons -->
<!-- <div class="row">
  <div class="col-12 pl-1 pr-1">
      <h5>Today</h5>
      <app-btn-long-meetings [count]="cloudRoomSummary?.scheduled_meetings"  isbutton='not'></app-btn-long-meetings>
  </div>
</div> -->
<!-- hr line space -->
<!-- <div class="row">
  <div class="col-12 my-2 pl-0 pr-1">
    <hr>
  </div>
</div> -->
<!-- stats buttons -->
<div class="row">
    <div class="col-12 pl-1">
      <h5>Company Information</h5>
    </div>
    <div class="col-4 pl-0 pr-1">
        <app-btn-api color='gray'  isbutton='not'></app-btn-api>
    </div>
    <div class="col-4 pl-0 pr-1">
      <app-btn-reg-stats [count]="cloudRoomSummary?.online"  (click)="setFilter('online')" [ngClass]="{'outlineBtn': activeFilter == 'online'}" label="Rooms Online" color='gray' ></app-btn-reg-stats>
    </div>
    <div class="col-4 pl-0 pr-1">
      <app-btn-reg-stats [count]="cloudRoomSummary?.total_rooms" label="All Rooms" color='gray' isbutton='not'></app-btn-reg-stats>
    </div>
</div>
