<div class="iot-lite-add-device">
  <div class="iot-lite-add-device__left-column">
    <div class="test1">
      <select [(ngModel)]="siteId" id="index" class="form-control iot-lite-add-device__select-input-style" [ngClass]="{'iot-lite-add-device__select-input-style--disable-select': disableSelectAndSubmit,
                'iot-lite-add-device__disable': disableAllFields}" (change)="siteSelectorEvent($event)" required>
        <option value="" selected disabled>Select a site</option>
        <option *ngFor="let item of siteList; index as n" value="{{item.site_id}}">
          {{ item.site_name }}</option>
      </select>
    </div>

    <div *ngIf="showSiteListErrMsg" class="iot-lite-add-device__error-message">
      Error: No Online Collectors
    </div>

    <div *ngIf="disableSelectAndSubmit" class="iot-lite-add-device__error-message">
      Please use collector version {{ collectorLatestVersion}} or >
    </div>

    <div class="iot-lite-add-device__iot-type-container">
      <select [(ngModel)]="iotType" id="index" [ngClass]="{'iot-lite-add-device__select-input-style--disable-select': iotTypeEmpty,
                'iot-lite-add-device__disable': disableAllFields}"
        class="form-control iot-lite-add-device__select-input-style" required>
        <option value="" selected disabled>Select a IoT Type</option>
        <option *ngFor="let item of iotTypeList; index as n" value="{{item.id}}">
          {{ item.value }}</option>
      </select>
    </div>

    <div class="iot-lite-add-device__container">
      <input [ngClass]="{'iot-lite-add-device__select-input-style--disable-input': actionDeviceNameEmpty,
            'iot-lite-add-device__disable': disableAllFields}" class="iot-lite-add-device__input form-control"
        [(ngModel)]="actionDeviceName" type="text" placeholder="Device Name" />
    </div>
    <div class="iot-lite-add-device__container">
      <input [ngClass]="{'iot-lite-add-device__select-input-style--disable-input': actionDeviceIPEmpty,
            'iot-lite-add-device__disable': disableAllFields  }" class="iot-lite-add-device__input form-control"
        [(ngModel)]="actionDeviceIP" type="text" placeholder="Device IP" />
      <div *ngIf="isIpAddressInvalid" style="color: red;">Invalid IP address format.</div>
    </div>

    <div class="iot-lite-add-device__btn" (click)="openAddIotLiteDeviceDialog()">
      <button [ngClass]="{'iot-lite-add-device__disable': disableSelectAndSubmit || disableAllFields }">Discover
        Device</button>
    </div>
  </div>

</div>