import { Component, Input, SimpleChanges, OnChanges  } from '@angular/core';

@Component({
  selector: 'app-btn-bluetooth',
  templateUrl: './btn-bluetooth.component.html',
  styleUrls: ['./btn-bluetooth.component.scss']
})
export class BtnBluetoothComponent implements OnChanges {
  @Input() bluetooth: number = 0;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.countHrs) {
      this.bluetooth = changes.count.currentValue
    }
  }

}
