import { Component, Input, OnInit } from '@angular/core'

import { PopupTypeService } from '../../services/popupType.service'

@Component({
  selector: 'app-iot-lite-device-historic-ping',
  templateUrl: './iot-lite-device-historic-ping.component.html',
  styleUrls: ['./iot-lite-device-historic-ping.component.scss']
})
export class IotLiteDeviceHistoricPingComponent implements OnInit {
  @Input() worstRtd: any = '--';
  @Input() medianRtd: any = '--';
  @Input() bestRtd: any = '--';
  @Input() packetLossRtd: any = '--';
  @Input() iotDeviceDetails: any;

  constructor(private ds: PopupTypeService) {}

  ngOnInit() {}

  openHistoricPingStatusChart = (viewType) => {
    const data = {
      iotDeviceID: this.iotDeviceDetails.iot_device_id,
      agentId: this.iotDeviceDetails.agent_id,
      accountUrl: this.iotDeviceDetails.account_url,
      apiKey: this.iotDeviceDetails.api_key,
      paused: this.iotDeviceDetails.paused,
      dateRange: 1,
      viewType,
    };
    this.ds.sendPopupType(57, {
      data,
    });
  };
}
