import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service'
import { GetIotService } from 'src/app/services/get-iot.service'
import { GetSpaceService, Space } from 'src/app/services/get-space.service'
type AddSpace = {
  space_name: number,
  isDomotz: boolean,
  agents: {agent_id:number}[]
}
@Component({
  selector: 'app-add-space-domotz-dialog',
  templateUrl: './add-space-domotz-dialog.component.html',
  styleUrls: ['./add-space-domotz-dialog.component.scss']
})
export class AddSpaceDomotzDialogComponent implements OnInit, OnChanges {
  @Input() data: {
    isDomotz: number,
    spaceToBeEdited: Space
  };
  mspDomotzEnabled: any;
  companyDetails: any;
  spaceForm: FormGroup;
  iotAgentList: any[] = [];
  message: string = '';
  errMsg: string = '';
  color: string = '';
  isShowLoader: boolean = false;
  constructor(private formBuilder: FormBuilder,
              private GetIot: GetIotService,
              private _spaceService:GetSpaceService,
              private _authService: AuthService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkForm();
    if (changes.data) {
      this.message = null
      if (this.data?.spaceToBeEdited)
        this.spaceForm?.controls['space_name']?.patchValue(this.data.spaceToBeEdited.space_name)
      else 
        this.reset()
      if(!this?.data?.isDomotz) this.getSiteList();
      else this.getCompanyInfoAndDomotzStatus();
    }
  }

  get f() {
    return this.spaceForm.controls;
  }

  checkForm() {
    this.spaceForm = this.formBuilder.group({
			space_name: [this.data?.spaceToBeEdited?.space_name ?? '', [Validators.required, Validators.maxLength(30)]],
			agent_id: [this.data?.spaceToBeEdited?.agent_id ?? '', [Validators.required]],
		})
  }

  setData() {
    const { company_parent } = this.companyDetails;
    if(this.mspDomotzEnabled && company_parent !=null) {
      this.getAllDomotzAgentsSubVendor();
    } else {
      this.getIotAgentList();
    }
  }
  
  getSiteList = () => {
    this.isShowLoader = true;
    this._authService.getAllSiteList()
    .pipe(finalize(()=>this.isShowLoader = false))
    .subscribe((data) => {
      let res = data.response;
      this.iotAgentList = res.map(e => {
        return {
          agent_id: e.site_id,
          status: e.collector_status,
          agent_name: e.site_name
        }
      })

      if (this.data.spaceToBeEdited) {
        // this.spaceForm?.controls['agent_id']?.disable()
        setTimeout(() => this.spaceForm?.controls['agent_id']?.patchValue(this.data.spaceToBeEdited.agent_id), 0)
      }
      else this.spaceForm?.controls['agent_id']?.enable()
    });
  }

  onSubmit() {
    if (this.spaceForm.invalid) {
      this.spaceForm.markAllAsTouched();
      return;
    }
     const form = this.spaceForm.value;
     const { space_id } = this.data?.spaceToBeEdited || {}

    if (space_id !== undefined)
      this.editSpace(form, space_id)
    else
      this.addSpace(form)
  }

  addSpace(form) {
    const newSpace: AddSpace = {
      space_name: form.space_name,
      isDomotz: !!this.data?.isDomotz,
      agents: [
        {
          agent_id: form.agent_id,
        },
      ],
    }
    this._spaceService.addSpace(newSpace).subscribe(
      resp => {
        this.color = resp.success ? 'text-success' : 'text-danger'
        this.message = resp.message
        this.reset()
      },
      err => {
        this.color = 'text-danger'
        this.message = 'Something went wrong while adding space'
        this.reset()
      }
    )
  }

  editSpace(form, space_id) {
    const editedSpace: any = {
        space_id,
        isDomotz: !!this.data?.isDomotz
      }
      if (this.data.spaceToBeEdited.space_name !== form.space_name)
        editedSpace.space_name = form.space_name
      if (this.data.spaceToBeEdited.agent_id !== form.agent_id) {
        editedSpace.agent_id = form.agent_id
        editedSpace.old_agent_id = this.data.spaceToBeEdited.agent_id
      }
    this._spaceService.editSpace(editedSpace).subscribe(
      resp => {
        this.color = resp.success ? 'text-success' : 'text-danger'
        this.message = resp.message
        
        const agent_name = this.iotAgentList.find(agent => agent.agent_id === +form.agent_id)?.agent_name
        this._spaceService.SpaceChangedEvent.emit({
          ...this.data.spaceToBeEdited,
          ...editedSpace,
          agent_name,
        })
        this.reset()
      },
      err => {
        this.color = 'text-danger'
          if (err?.status === 405) {
            const deviceNames = err?.error?.result.map(device => ` ${device.name}`)
            this.message = `Space is linked to the device(s):${deviceNames}. Please unlink the device(s) first in order to change the ${this?.data?.isDomotz? 'agent': 'site'}.`
          } else this.message = 'Something went wrong while editing space'
      }
    )
  }

  reset() {
    this.spaceForm?.reset({agent_id:''});
    setTimeout(() => {
      this.message = '';
    }, 5000)
  }
  getAllDomotzAgentsSubVendor = () => {
    this.GetIot.getAllDomotzAgentsSubVendor(
      'null',
      500,
      0,
    )
    .pipe(finalize(()=>this.isShowLoader = false))
    .subscribe((resp:any) => {
      // // console.log('getAllDomotzAgentsSubVendor: ', data)
      this.iotAgentList = resp;
      if (this.data.spaceToBeEdited) {
        // this.spaceForm?.controls['agent_id']?.disable()
        setTimeout(() => this.spaceForm?.controls['agent_id']?.patchValue(this.data.spaceToBeEdited.agent_id), 0)
      }
      else this.spaceForm?.controls['agent_id']?.enable()
    });
  }

  getIotAgentList = () => {
    this.GetIot.getAllDomotzAgents(
      'null',
      500,
      0,
      this.mspDomotzEnabled
    )
    .pipe(finalize(()=>this.isShowLoader = false))
    .subscribe((resp:any) => {
      // // console.log('getIotAgentList: ', data)
      this.iotAgentList = resp;
      if (this.data.spaceToBeEdited) {
        // this.spaceForm?.controls['agent_id']?.disable()
        setTimeout(() => this.spaceForm?.controls['agent_id']?.patchValue(this.data.spaceToBeEdited.agent_id), 0)
      }
      else this.spaceForm?.controls['agent_id']?.enable()
    });
  };
  getCompanyInfoAndDomotzStatus() {
    this.isShowLoader = true;
    const companyInfoRequest = this._authService.getCompanyInfo({ company_id: this.getCompanyId() });
    const domotzStatusRequest = this.GetIot.getMSPDomotzStatus();
  
    forkJoin([companyInfoRequest, domotzStatusRequest])
    .subscribe(
      ([companyInfoResponse, domotzStatusResponse]) => {
        this.isShowLoader = true;
        let [res] = companyInfoResponse.response;
        this.companyDetails = res;
        this.mspDomotzEnabled = domotzStatusResponse;
        this.setData();
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }
  getCompanyId() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    return decodedToken.company_id;
  }

}