import moment from 'moment-timezone'
import { finalize } from 'rxjs/operators'
import { AuthService } from 'src/app/services/auth.service'
import { GetCollectorService } from 'src/app/services/get-collector.service'
import { environment } from 'src/environments/environment'

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'

@Component({
	selector: 'app-panel-tools',
	templateUrl: './panel-tools.component.html',
	styleUrls: ['./panel-tools.component.scss'],
})
export class PanelToolsComponent implements OnInit, OnChanges {
	@Input() PopupTitle: any
	@Input() isCollectorUpdate: any
  @Input() collector:any
  @Input() retryAgain:any
  @Output() lastestCollectorEvent:EventEmitter<any> = new EventEmitter<any>();
	isSchedule: boolean = false;
  isAvailable: boolean = false;
  scheduleDate: string = '';
  minDate = new Date().toISOString().substring(0,16);
  loading: boolean = false;
	loader = environment.config.dynamicImage.loader
  collectorLatestVersion:number = 0;
  isReschedule: boolean = false;
  
  collectorPanelStyle: any = false;
  
  
	constructor(private Auth: AuthService,
              private _collectorService:GetCollectorService
  ) {}

	ngOnInit() { }
	
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.isCollectorUpdate && this.isCollectorUpdate){
      this.getCollectorVersionData();
    }
    if(changes.collector && this.collector){
      this.isAvailable = this.collector?.some((x)=>x.isUpdate === true)
      if(!this.isAvailable) this.isSchedule = false;
    }
    if(changes.retryAgain && this.retryAgain){
      if(this.retryAgain.status) this.scheduleNow(true);
      else this.reTry(this.retryAgain.data,false);
    }
    
    if (changes.PopupTitle && this.PopupTitle) {
        if(this.PopupTitle == 'Collectors') {
          this.collectorPanelStyle = true;
      } else {
          // console.log('dont change styles!')
      
          this.collectorPanelStyle = false;
      }
    }
    
  }

	printDiv(divID) {
		//Get the HTML of div
		// var divElements = document.getElementById(divID).innerHTML;
		// //Get the HTML of whole page
		// var oldPage = document.body.innerHTML;

		// //Reset the page's HTML with div's HTML only
		// document.body.innerHTML =
		//   "<html><head><title></title></head><body>" +
		//   divElements + "</body>";

		//Print Page
		window.print()

		// //Restore orignal HTML
		// document.body.innerHTML = oldPage;
	}
	
	scheduleNow(status=false) {
    this.isSchedule = true;
    this.isReschedule = status;
  }
  cancel(){
    this.isSchedule = false;
    this.isReschedule = false;
  }
  
  submit(){
    if(this.isReschedule){
      this.reTry(this.retryAgain.data,true);
    }
    else this.latestCollector(true);
  }
  
  getCollectorVersionData = () => {
    this.Auth.getCollectorLatestVersion('collector').subscribe((data) => {
      if (data?.response?.length) {
        this.collectorLatestVersion = data.response[0].version;
        this.lastestCollectorEvent.emit(data.response[0]);
      }
    });
  };
  
  latestCollector = (status:boolean) =>{
    const collectors = [];
    this.collector.forEach((x)=>{
      if(x.isUpdate){
        collectors.push({
          collector_id:x.collector_id,
          collector_update_schedule: status? this.scheduleDate : ''
        })
      }
    })
    if(!this.collector.length) return;
    this.loading = true;
    const body = {
      collectors,
      isSchedule: status
    }
    console.log(body);
    this.Auth.latestCollector(body)
    .pipe(finalize(()=>this.reset()))
    .subscribe((data) => {
      this._collectorService.isRefresh.next(true);
    });
  }
  
  setScheduleDate({target:{value:selectedDate}}:any){
    const sDate = selectedDate.split('T');
    this.scheduleDate = moment(sDate[0] + ' ' + sDate[1])
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format();
  }
  
  reTry(collector,status){
    this.loading = true;
    const body = {
      collectors:[{
        collector_id:collector.collector_id,
        collector_update_schedule: status? this.scheduleDate : ''
      }],
      isSchedule: status
    }
    this.Auth.latestCollector(body)
    .pipe(finalize(()=>this.reset()))
    .subscribe((data) => {
      this._collectorService.isRefresh.next(true);
    });
  }
  
  reset(){
    this.cancel();
    this.loading = false;
  }
}
