<div class="video-logitech-uptime">
  <h5 class="text-center">Current Time</h5>
  <!-- <div class="video-logitech-uptime__time-container">
    <h4>{{ days }}:</h4>
    <h4>{{ hours }}:</h4>
    <h4>{{ minutes }}</h4>
  </div>
  <div class="video-logitech-uptime__time-container-value">
    <p>days</p>
    <p>hrs.</p>
    <p>min.</p>
  </div> -->
  <div class="text-center">
    {{currentTime }}
  </div>
  <div class="text-center">
    Timezone: {{ timeZoneId }} {{ timeZoneName }}
  </div>
</div>