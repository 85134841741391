<div class="main-container" [ngStyle]="{
    transition: urlChangeState == 'close' ? 'all 0ms' : 'all 750ms',
    opacity: slackState == 'open' ? opacity : 0,
    'pointer-events': slackState == 'open' ? auto : 'none'
  }" (click)="toggleSlackClose()"></div>

<div class="main-panel doNotPrint" [class.main-notify]="notify == 1"
  [ngStyle]="{ right: slackState == 'open' ? '260px' : '0px' }">
  <div class="slack-panel" [ngClass]="{ slackbacklite: backcolorbtn == 'slackbacklite' }">
    <!-- <img [src]="notifImage" alt="slack side panel" (click)="toggleSlack()" /> -->
    <div class="panel panel-default">
      <div class="alert alert-dark rounded-0 notif-font">Notifications</div>
      <button (click)="deleteAllNotifications()" class="btn btn-small float-right mb-3 mr-2">
        {{beingDeleted ? 'Deleting notifications' : 'Delete All'}}
      </button>

      <ul *ngFor="let notification of notificationsData$; index as n" class="m-0 w-100">
        <!-- WELLNESS -->
        <div *ngIf="notification?.section == 'WELLNESS'" class="notif-ctr {{ checkIfNotifIsRead(notification.read) }}">
          <div class="title-and-delete-btn">
            <div>WELLNESS</div>
            <div class="fa-style" (click)="
              deleteNotification($event, notification.company_id, notification.notification_id)
            ">
              <fa-icon [icon]="faIconTimes" class="pr-1"></fa-icon>
            </div>
          </div>
          <span (click)="markAsRead(notification)">
            <div *ngIf="notification.device_info.zoom_incident_id !== null">
              Incident ID: W-{{ notification.video_incident_id }}
            </div>
            <div>
              {{ checkIfNotificationIsNotEmptyForCollector(notification.message) }}
            </div>

            <div *ngIf="notification?.company_name">
              Company: {{notification.company_name}}
            </div>

            <div>
              <a (click)="toggleSlackClose()" routerLink="{{
                  notification.device_info.section.toLowerCase() +
                    '/site/' +
                    notification.device_info.site_id +
                    '/videodevice/' +
                    notification.device_info.video_device_id
                }}">Go to device - {{ notification.device_info.device_name }}</a>
            </div>

            <div>
              {{ notification?.timestamp | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
            </div>

          </span>
        </div>
        <!-- WELLNESS -->


        <!-- IOT PORTS -->
        <div *ngIf="notification?.section == 'IOTPORT'" class="notif-ctr {{ checkIfNotifIsRead(notification.read) }}">
          <div class="title-and-delete-btn">
            <div>IoT</div>
            <div class="fa-style" (click)="
                deleteNotification(
                  $event,
                  notification.company_id,
                  notification.notification_id
                )
              ">
              <fa-icon [icon]="faIconTimes" class="pr-1"></fa-icon>
            </div>
          </div>
          <span (click)=markAsRead(notification)>
            <div *ngIf="notification.device_info.zoom_incident_id !== null">
              Incident ID: I-{{ notification.video_incident_id }}
            </div>
            <div style="white-space: pre-line;">
              {{
              checkIfNotificationIsNotEmptyForCollector(notification.message)
              }}
            </div>

            <div *ngIf="notification?.company_name">
              Company: {{ notification.company_name }}
            </div>

            <div>
              <a (click)="toggleSlackClose()" routerLink="{{
                  '/iot/lite/device/' + notification.device_info.iot_device_id
                }}">Go to IoT Device-
                {{ notification.device_info?.iot_device_name || notification.device_info.iot_device_id }}</a>
            </div>

            <div>
              {{ notification?.timestamp | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
            </div>
          </span>
        </div>
        <!-- IOT PORTS -->

        <!-- TEAMS -->
        <div *ngIf="notification?.section == 'TEAMS'" class="notif-ctr {{ checkIfNotifIsRead(notification.read) }}">
          <div class="title-and-delete-btn">
            <div>Teams</div>
            <div class="fa-style" (click)="
                deleteNotification(
                  $event,
                  notification.company_id,
                  notification.notification_id
                )
              ">
              <fa-icon [icon]="faIconTimes" class="pr-1"></fa-icon>
            </div>
          </div>
          <span (click)=markAsRead(notification)>
            <div *ngIf="notification.device_info.zoom_incident_id !== null">
              Incident ID: T-{{ notification.device_info.zoom_incident_id }}
            </div>
            <div>
              {{
              checkIfNotificationIsNotEmptyForCollector(notification.message)
              }}
            </div>

            <div *ngIf="notification?.company_name">
              Company: {{ notification.company_name }}
            </div>

            <div *ngIf="notification?.device_info?.is_room == 0">
              <a (click)="toggleSlackClose()" routerLink="{{
                  '/teams/user/' + notification.device_info.zoom_user_id
                }}">Go to user - {{ notification.device_info.name }}</a>
            </div>

            <div *ngIf="notification?.device_info?.is_room == 1">
              <a (click)="toggleSlackClose()" routerLink="{{
                  '/teams/room/' + notification.device_info.zoom_user_id
                }}">Go to Room - {{ notification.device_info.name }}</a>
            </div>

            <div>
              {{ notification?.timestamp | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
            </div>
          </span>
        </div>
        <!-- TEAMS -->

        <div *ngIf="notification?.section == 'COLLECTOR'" class="notif-ctr {{ checkIfNotifIsRead(notification.read) }}">
          <div class="title-and-delete-btn">
            <div>{{ dCollector }}</div>
            <div class="fa-style" (click)="
                deleteNotification(
                  $event,
                  notification.company_id,
                  notification.notification_id
                )
              ">
              <fa-icon [icon]="faIconTimes" class="pr-1"></fa-icon>
            </div>
          </div>
          <span (click)=markAsRead(notification)>
            <div *ngIf="notification.video_incident_id !== null">
              Incident ID: C-{{ notification.video_incident_id }}
            </div>
            <div style="white-space: pre-line;">
              {{
              checkIfNotificationIsNotEmptyForCollector(notification.message)
              }}
            </div>
            <div *ngIf="notification?.company_name">
              Company: {{ notification.company_name }}
            </div>
            <div>
              <a (click)="toggleSlackClose()" routerLink="{{
                  '/collector/site/' + notification.device_info.site_id
                }}">Go to site - {{ notification.device_info.site_name }}</a>
            </div>
            <div>
              {{ notification?.timestamp | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
            </div>
          </span>
        </div>
        <!-- COLLECTOR -->

        <div *ngIf="notification?.section == 'ZOOM'" class="notif-ctr {{ checkIfNotifIsRead(notification.read) }}">
          <div class="title-and-delete-btn">
            <div>Zoom</div>
            <div class="fa-style" (click)="
                deleteNotification(
                  $event,
                  notification.company_id,
                  notification.notification_id
                )
              ">
              <fa-icon [icon]="faIconTimes" class="pr-1"></fa-icon>
            </div>
          </div>

          <span (click)=markAsRead(notification)>
            <div *ngIf="notification.video_incident_id !== null">
              Incident ID: Z-{{ notification.video_incident_id }}
            </div>
            <div>
              Zoom
              {{ getZoomIncidentTitle(notification.device_info.is_room) }} :
              {{ notification?.message }}
            </div>
            <div *ngIf="notification?.company_name">
              Company: {{ notification.company_name }}
            </div>
            <div *ngIf="notification.device_info.is_room !== -1">
              <a (click)="toggleSlackClose()" routerLink="{{
                  '/cloud/' +
                    (notification.device_info.is_room == 1
                      ? 'room/'
                      : 'user/') +
                    notification.device_info.zoom_user_id
                }}">{{
                notification.device_info.is_room ? "Go to room" : "Go to user"
                }}
                - {{ notification.device_info.name }}</a>
            </div>
            <div>
              {{ notification?.timestamp | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
            </div>
          </span>
        </div>
        <!-- ZOOM -->

        <div *ngIf="notification.section == 'VIDEO'" class="notif-ctr {{ checkIfNotifIsRead(notification.read) }}">
          <div class="title-and-delete-btn">
            <div>Video</div>
            <div class="fa-style" (click)="
                deleteNotification(
                  $event,
                  notification.company_id,
                  notification.notification_id
                )
              ">
              <fa-icon [icon]="faIconTimes" class="pr-1"></fa-icon>
            </div>
          </div>

          <span (click)=markAsRead(notification)>
            <div *ngIf="notification.video_incident_id !== null">
              Incident ID: V-{{ notification.video_incident_id }}
            </div>
            <div style="white-space: pre-line;">
              {{ notification?.message }}
            </div>
            <div *ngIf="notification?.company_name">
              Company: {{ notification.company_name }}
            </div>
            <div>
              <a (click)="toggleSlackClose()" routerLink="{{
                  notification.device_info.section.toLowerCase() +
                    '/site/' +
                    notification.device_info.site_id +
                    '/videodevice/' +
                    notification.device_info.video_device_id
                }}">Go to device - {{ notification.device_info.device_name }}</a>
            </div>
            <div>
              {{ notification?.timestamp | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
            </div>
          </span>
        </div>
        <!-- VIDEO -->

        <div *ngIf="notification.section == 'AUDIO'" class="notif-ctr {{ checkIfNotifIsRead(notification.read) }}">
          <div class="title-and-delete-btn">
            <div>Audio</div>
            <div class="fa-style" (click)="
                deleteNotification(
                  $event,
                  notification.company_id,
                  notification.notification_id
                )
              ">
              <fa-icon [icon]="faIconTimes" class="pr-1"></fa-icon>
            </div>
          </div>
          <span (click)="markAsRead(notification)">
            <div *ngIf="notification.video_incident_id !== null">
              Incident ID: A-{{ notification.video_incident_id }}
            </div>
            <div>
              {{ notification?.message }}
            </div>
            <div *ngIf="notification?.company_name">
              Company: {{ notification.company_name }}
            </div>
            <div>
              <a (click)="toggleSlackClose()" routerLink="{{
                  notification.device_info.section.toLowerCase() +
                    '/site/' +
                    notification.device_info.site_id +
                    '/audiodevice/' +
                    notification.device_info.audio_device_id
                }}">
                Go to device - {{ notification.device_info.device_name }}</a>
            </div>
            <div>
              {{ notification?.timestamp | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
            </div>
          </span>
        </div>
        <!-- AUDIO -->

        <div *ngIf="notification?.section == 'PLUGIN'" class="notif-ctr {{ checkIfNotifIsRead(notification.read) }}">
          <div class="title-and-delete-btn">
            <div>{{ dPlugin }}</div>
            <div class="fa-style" (click)="
                deleteNotification(
                  $event,
                  notification.company_id,
                  notification.notification_id
                )
              ">
              <fa-icon [icon]="faIconTimes" class="pr-1"></fa-icon>
            </div>
          </div>

          <span (click)=markAsRead(notification)>
            <div *ngIf="notification.video_incident_id !== null">
              Incident ID: P-{{ notification.video_incident_id }}
            </div>
            <div>
              {{ checkIfNotificationIsNotEmptyForPlugin(notification.message) }}
            </div>
            <div *ngIf="notification?.company_name">
              Company: {{ notification.company_name }}
            </div>
            <div>
              <a (click)="toggleSlackClose()" routerLink="{{
                  '/cloud/room/' + notification.device_info.zoom_user_id
                }}">Go to room - {{ notification.device_info.name }}</a>
            </div>
            <div>
              {{ notification?.timestamp | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
            </div>
          </span>
        </div>
        <!-- PLUGIN -->

        <div *ngIf="notification?.section == 'DESKTOP_PLUGIN'"
          class="notif-ctr {{ checkIfNotifIsRead(notification.read) }}">
          <div class="title-and-delete-btn">
            <div>{{ desktopPlugin }}</div>
            <div class="fa-style" (click)="
                deleteNotification(
                  $event,
                  notification.company_id,
                  notification.notification_id
                )
              ">
              <fa-icon [icon]="faIconTimes" class="pr-1"></fa-icon>
            </div>
          </div>

          <span (click)=markAsRead(notification)>
            <div *ngIf="notification.video_incident_id !== null">
              Incident ID: DP-{{ notification.video_incident_id }}
            </div>
            <div>
              {{ notification.message + "." }}
            </div>
            <div *ngIf="notification?.company_name">
              Company: {{ notification.company_name }}
            </div>
            <div>
              <a (click)="toggleSlackClose()" routerLink="{{
                  '/teams/user/' + notification.device_info.zoom_user_id
                }}">Go to User - {{ notification.device_info.firstname }}
                {{ notification.device_info.lastname }}</a>
            </div>
            <div>
              {{ notification?.timestamp | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
            </div>
          </span>
        </div>
        <!-- DESKTOP_PLUGIN -->

        <div *ngIf="notification?.section == 'IOT_DOMOTZ'"
          class="notif-ctr {{ checkIfNotifIsRead(notification.read) }}">
          <div class="title-and-delete-btn">
            <div>IoT</div>
            <div class="fa-style" (click)="
                deleteNotification(
                  $event,
                  notification.company_id,
                  notification.notification_id
                )
              ">
              <fa-icon [icon]="faIconTimes" class="pr-1"></fa-icon>
            </div>
          </div>

          <span *ngIf="!isIotDomotzAgent(notification?.message)" (click)=markAsRead(notification)>
            <div *ngIf="notification.video_incident_id !== null">
              Incident ID: I-{{ notification.video_incident_id }}
            </div>
            <div>
              {{ "IoT Device: " + notification?.message }}
            </div>
            <div *ngIf="notification?.company_name">
              Company: {{ notification.company_name }}
            </div>
            <div>
              <a (click)="toggleSlackClose()" routerLink="{{
                  '/iot/device/' + notification.device_info.iot_device_id
                }}">Go to IoT device -
                {{ notification.device_info.display_name }}</a>
            </div>
            <div>
              {{ notification?.timestamp | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
            </div>
          </span>
          <span *ngIf="isIotDomotzAgent(notification?.message)" (click)=markAsRead(notification)>
            <div *ngIf="notification.video_incident_id !== null">
              Incident ID: I-{{ notification.video_incident_id }}
            </div>
            <div>
              {{ "IoT Agent : " + notification?.message }}
            </div>
            <div *ngIf="notification?.company_name">
              Company: {{ notification.company_name }}
            </div>
            <div>
              <a (click)="toggleSlackClose()" routerLink="{{
                  'iot/agentdevices/' +
                    notification?.device_info?.agent_id +
                    '/' +
                    notification?.device_info?.agent_name
                }}">{{
                "Agent name : " + notification?.device_info?.agent_name
                }}</a>
            </div>
            <div>
              {{ notification?.timestamp | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
            </div>
          </span>
        </div>
        <!-- IOT_DOMOTZ -->
      </ul>
    </div>
  </div>
</div>

<!-- @spanelSlide]="spanelState" [@slackSlide]="slackState" -->