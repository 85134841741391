<div id="chartParent" class="info-chartSite pt-3 mb-3 chart-container" style="cursor: auto !important;">
    <div class="printer-border print-btn">
        <i class="fa fa-print fa-2x menu-icon" (click)="print()"></i>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <button (click)="set60m();" [ngClass]="{'btnpressed': activeBtn == 1}">
                60m
            </button>
            <button (click)="set24h();" [ngClass]="{'btnpressed': activeBtn == 2}">
                24h
            </button>
            <button (click)="set7d();" [ngClass]="{'btnpressed': activeBtn == 3}">
                7d
            </button>
            <div style="padding-left: 50px !important; display: inline">
                <label class="switch">
                    <input type="checkbox" [checked]="toggleValue==0" (click)="toggleView(1)">
                    <span class="slider round">
                        <span class="paused">chart</span>
                        <span class="enabled" style="padding-right: 200px;">list</span>
                    </span>
                </label>
            </div>
        </div>
    </div>
    <canvas 
        id="audioAllDataChart" 
        width="1150px" 
        height="350px" 
        style="padding-left: 150px"
        [ngStyle]="{display: toggleValue==0 ? 'block' : 'none'}"></canvas>
    
    <div id="printTable" *ngIf="toggleValue!=0 && !printMode" style="cursor: pointer!important;">
        <div class="row raw-data-table--head">
            <table>
                <tr>
                    <th class="raw-data-table--timestamp">Time Stamp</th>
                    <th class="raw-data-table--number">Live Mos</th>
                    <th class="raw-data-table--number">Latency</th>
                    <th class="raw-data-table--number">Jitter</th>
                    <th class="raw-data-table--number">Packet Loss</th>
                    <th class="raw-data-table--number">Incidents Id</th>
                </tr>
            </table>
        </div>
        <div class="row raw-data-table--body not-print" >
            <table>
                <tr *ngFor="let data of printTableData">
                    <td [ngClass]="{ 'td-incident': data.audio_incident_id }" 
                        class="raw-data-table--timestamp">{{data.timestamp}}</td>
                    <td [ngClass]="{ 'td-incident': data.audio_incident_id }"
                        class="raw-data-table--number">{{data.total_mos}}</td>
                    <td [ngClass]="{ 'td-incident': data.audio_incident_id }"
                        class="raw-data-table--number">{{data.total_latency}}</td>
                    <td [ngClass]="{ 'td-incident': data.audio_incident_id }"
                        class="raw-data-table--number">{{data.total_jitter}}</td>
                    <td [ngClass]="{ 'td-incident': data.audio_incident_id }"
                        class="raw-data-table--number">{{data.total_packetloss}}</td>
                    <td [ngClass]="{ 'td-incident': data.audio_incident_id }"
                        class="raw-data-table--number">{{data.audio_incident_id ? data.audio_incident_id : '--' }}</td>
                </tr>
            </table>
        </div>
    </div>
    <div id="printTable" *ngIf="toggleValue!=0 && printMode" style="cursor: pointer!important;">
            <div class="row raw-data-table--head">
                <table>
                    <tr>
                        <th class="raw-data-table--timestamp">Time Stamp</th>
                        <th class="raw-data-table--number">Live Mos</th>
                        <th class="raw-data-table--number">Latency</th>
                        <th class="raw-data-table--number">Jitter</th>
                        <th class="raw-data-table--number">Packet Loss</th>
                        <th class="raw-data-table--number">Incidents Id</th>
                    </tr>
                </table>
            </div>
            <div class="row raw-data-table--body print" >
                <table>
                    <tr *ngFor="let data of printTableData">
                        <td [ngClass]="{ 'td-incident': data.audio_incident_id }" 
                            class="raw-data-table--timestamp">{{data.timestamp}}</td>
                        <td [ngClass]="{ 'td-incident': data.audio_incident_id }"
                            class="raw-data-table--number">{{data.total_mos}}</td>
                        <td [ngClass]="{ 'td-incident': data.audio_incident_id }"
                            class="raw-data-table--number">{{data.total_latency}}</td>
                        <td [ngClass]="{ 'td-incident': data.audio_incident_id }"
                            class="raw-data-table--number">{{data.total_jitter}}</td>
                        <td [ngClass]="{ 'td-incident': data.audio_incident_id }"
                            class="raw-data-table--number">{{data.total_packetloss}}</td>
                        <td [ngClass]="{ 'td-incident': data.audio_incident_id }"
                            class="raw-data-table--number">{{data.audio_incident_id ? data.audio_incident_id : '--' }}</td>
                    </tr>
                </table>
            </div>
        </div>
</div>