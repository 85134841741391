<div class="newlogin" [ngStyle]="visibilityBackground">
  <div *ngIf="!isVcare && !isVspoint && !isVidelio && !isDekom" class="newlogin__row-2">
    <div *ngIf="!message.flag" class="newlogin__col-2">
      <h1>
        Go beyond the cloud.
      </h1>
      <h1>
        Monitor. Manage. Restore.
      </h1>
      <img [src]="dashboardLogo" />
    </div>

    <div *ngIf="message.flag && message.type == 1" class="newlogin__col-2">
      <app-success-animation></app-success-animation>
      <h1>
        Thank you for registering.
      </h1>
      <h1>
        Please check your email to
      </h1>
      <h1>
        Verify your account.
      </h1>
      <img [src]="dashboardLogo" />
    </div>

    <div *ngIf="message.flag && message.type == 2" class="newlogin__col-2">
      <h1>
        Thank you for registering.
      </h1>
      <img [src]="dashboardLogo" />
    </div>
  </div>

  <div *ngIf="isVcare" class="newlogin__row-2-vcare" [ngClass]="{ 'newlogin__text-shadow': isVcare }">
    <div *ngIf="!message.flag" class="newlogin__col-2-vcare">
      <h1>
        Go beyond the cloud.
      </h1>
      <h1>
        Monitor. Manage. Restore.
      </h1>
      <img [src]="dashboardLogo" *ngIf="!isVcare" />
    </div>

    <div *ngIf="message.flag && message.type == 1" class="newlogin__col-2">
      <h1>
        Thank you for registering.
      </h1>
      <h1>
        Please check your email to
      </h1>
      <h1>
        Verify your account.
      </h1>
    </div>

    <div *ngIf="message.flag && message.type == 2" class="newlogin__col-2">
      <h1>
        Thank you for registering.
      </h1>
    </div>
  </div>


  <div *ngIf="isVspoint" class="newlogin__row-2-vspoint" [ngClass]="{ 'newlogin__text-shadow': isVspoint }">
    <div *ngIf="!message.flag" class="newlogin__col-2-vspoint">
      <img src="./assets/img/visionpoint_img/homelogo.png" />
      <h1>
        All Your Devices. One Network.
      </h1>
      <h1>
        Monitor. Manage. Resolve.
      </h1>
    </div>

    <div *ngIf="message.flag && message.type == 1" class="newlogin__col-2">
      <h1>
        Thank you for registering.
      </h1>
      <h1>
        Please check your email to
      </h1>
      <h1>
        Verify your account.
      </h1>
    </div>

    <div *ngIf="message.flag && message.type == 2" class="newlogin__col-2">
      <h1>
        Thank you for registering.
      </h1>
    </div>
  </div>


  <div *ngIf="isVidelio" class="newlogin__row-2-videlio" [ngClass]="{ 'newlogin__text-shadow': isVidelio }">
    <div *ngIf="!message.flag" class="newlogin__col-2-vspoint">
      <img src="./assets/img/videlio_img/homelogo.png" />
      <h1>
        Go beyond the cloud.
      </h1>
      <h1>
        Monitor. Manage. Restore.
      </h1>
    </div>

    <div *ngIf="message.flag && message.type == 1" class="newlogin__col-2">
      <h1>
        Thank you for registering.
      </h1>
      <h1>
        Please check your email to
      </h1>
      <h1>
        Verify your account.
      </h1>
    </div>

    <div *ngIf="message.flag && message.type == 2" class="newlogin__col-2">
      <h1>
        Thank you for registering.
      </h1>
    </div>
  </div>


  <div *ngIf="isDekom" class="newlogin__row-2-dekom" [ngClass]="{ 'newlogin__text-shadow': isDekom }">
    <div *ngIf="!message.flag" class="newlogin__col-2-dekom">
      <h1>
        Go beyond the cloud.
      </h1>
      <h1>
        Monitor. Manage. Restore.
      </h1>
      <img src="./assets/img/dekom_img/homelogo.png" />
    </div>

    <div *ngIf="message.flag && message.type == 1" class="newlogin__col-2">
      <h1>
        Thank you for registering.
      </h1>
      <h1>
        Please check your email to
      </h1>
      <h1>
        Verify your account.
      </h1>
    </div>

    <div *ngIf="message.flag && message.type == 2" class="newlogin__col-2">
      <h1>
        Thank you for registering.
      </h1>
    </div>
  </div>
</div>