<div class="device-plugin-status row top-btn-row">
  <div class="container-fluid">
    <div class="row text-center" *ngIf="pluginId === null">
      <div class="col-12 text-center no-plugin">
        Enable advanced features by linking a {{ dPlugin }} in the Admin section of the DNA page.
      </div>
    </div>
    <div class="row" *ngIf="pluginId !== null && hasAccess">
      <div class="col-2">
        <div class="form-group">
          <button [ngClass]="{ 'btn_disable': pluginStatus === -1 }" class="form-control btn btn-primary"
            *ngIf="isRoom && pluginEnabled" style="margin-top: 10px;" (click)="showRebootPCFunc(true)">
            Reboot PC
          </button>
          <button [ngClass]="{ 'btn_disable': pluginStatus === -1 }" class="form-control btn btn-primary"
            *ngIf="isRoom && pluginEnabled" style="margin-top: 10px;" (click)="showRebootZoomFunc(true)">
            {{ serviceType === 'zoom' ? 'Restart Zoom' : 'Restart Teams' }}
          </button>
        </div>
      </div>
      <div class="col-10">
        <div class="row">
          <div class="col-12" *ngIf="!showDevicePluginDynamicContainer">
            <div *ngIf="showRebootPC">
              <div class="device-plugin-dynamic-button-title">
                Are you sure you wanted to Reboot PC?
              </div>
              <div class="device-plugin-dynamic-button-child">
                <button (click)="rebootPc()" class="form-control btn btn-primary">
                  Yes
                </button>
                <button (click)="setShowDevicePluginDynamicContainer(true)" class="form-control btn">
                  No
                </button>
              </div>
            </div>
            <div *ngIf="showRebootZoom">
              <div class="device-plugin-dynamic-button-title">
                Are you sure you wanted to Reboot Zoom?
              </div>
              <div class="device-plugin-dynamic-button-child">
                <button (click)="restartZoom()" class="form-control btn btn-primary">
                  Yes
                </button>
                <button (click)="setShowDevicePluginDynamicContainer(true)" class="form-control btn">
                  No
                </button>
              </div>
            </div>
          </div>
          <div class="col-3 no-padding" *ngIf="showDevicePluginDynamicContainer">
            <h4 class="float-left">Host name</h4>
            <input type="text" class="form-control" placeholder="host name" [ngModel]="hostName" name="hostname"
              readonly />
            <h4 class="float-left">Last data connect</h4>
            <input type="text" class="form-control" placeholder="site name" [ngModel]="lConnect" name="lConnect"
              readonly />
          </div>

          <div class="col-3" *ngIf="showDevicePluginDynamicContainer">
            <h4>Host CPU usage</h4>
            <progress-bar [progress]="cpuTotalUsage" [color]="'#90c95a'"> </progress-bar>
            <h4>Host Memory utilization</h4>
            <p>
              {{ displayMemmory(memoryTotalUsage) }} used of {{ convertBytesToGb(memoryTotal) }} GB
            </p>
            <progress-bar [progress]="memoryTotalUsagePercentage" [color]="'#90c95a'">
            </progress-bar>
          </div>
          <div class="col-3" *ngIf="showDevicePluginDynamicContainer">
            <h4>{{ dPlugin }} CPU usage</h4>
            <progress-bar [progress]="cpuUsagePlugin" [color]="'#90c95a'"> </progress-bar>
            <h4>{{ dPlugin }} Memory utilization</h4>
            <p>
              {{ displayMemmory(memoryPlugintUsage) }} used of
              {{ convertBytesToGb(memoryTotal) }} GB
            </p>
            <progress-bar [progress]="memoryPluginUsagePercentage" [color]="'#90c95a'">
            </progress-bar>
          </div>
          <div class="col-3" *ngIf="showDevicePluginDynamicContainer">
            <h4>Network</h4>
            <p>
              <img src="{{getNetworkImg(networkStatus)}}" style="height: 30px; margin-right: 5px;" />{{networkStatus}}
            </p>
            <h4>Version</h4>
            <p>{{ version }}</p>
            <div *ngFor="let disk of diskInfo">
              <h4>
                Drive {{disk.drive}}
              </h4>
              <p>
                <b>Total :</b> {{formatBytes(disk.total)}}
                <br>
                <b>Free :</b> {{formatBytes(disk.free)}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>