<div class="row right-panel">

  <div class="col-12">
    <app-panel-tools [PopupTitle]="PopupTitle"></app-panel-tools>
  </div>
  
  <div class="col-12">
    <div class="row">
      <div class="col-6 pl-1">
        <app-audio-allsites-info-panel [audioSitesSummary]="audiositesInfo"></app-audio-allsites-info-panel>
      </div>
      <div class="col-6">
        <div class="col-12 px-0">
          <app-system-health [healthNum]="audioSitesSummary?.health"></app-system-health>
        </div>
        <app-audio-allsites-stats-panel [audioSitesSummary]="audioSitesSummary"></app-audio-allsites-stats-panel>
      </div>
    </div>

    <div class="row" id="all-audio-test">
      <div class="col-12 px-1 filter-table">
        <app-audio-allsites-list [audioSitesListData]="audioSitesListData">
        </app-audio-allsites-list>
      </div>
    </div>

    <div *ngIf="loading" style="position: absolute;top: 50%; z-index: 7;width: 100%;">
      <div class="loader-icon">
        <img [src]="loader">
      </div>
    </div>

  </div>
</div>