import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'

import { GetIotService } from '../../services/get-iot.service'

@Component({
	selector: 'app-iot-lite-device-stats',
	templateUrl: './iot-lite-device-stats.component.html',
	styleUrls: ['./iot-lite-device-stats.component.scss'],
})
export class IotLiteDeviceStatsComponent implements OnInit, OnChanges {
	@Output() eventEmitter: EventEmitter<any> = new EventEmitter<any>()

	@Input() iotDeviceDetails: any
	pingRtd: any = '--'
	packetLoss: any = '--'

	worstRtd: any = '--'
	medianRtd: any = '--'
	bestRtd: any = '--'
	packetLossRtd: any = '--'

	latestEventsMins: any = '--'
	latestEvents24h: any = '0'

	deviceID: any
	deviceName: any
	notes: any

	deviceHisoryParams: any

	medianAlert: Number = 10
	medianWarning: Number = 9

	packetLossAlert: Number = 15
	packetLossWarning: Number = 10
	constructor(private getIot: GetIotService) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.iotDeviceDetails && this.iotDeviceDetails) {
			this.setData()
		}
	}

	resetProps = () => {
		this.pingRtd = '--'
		this.packetLoss = '--'
		this.worstRtd = '--'
		this.medianRtd = '--'
		this.bestRtd = '--'
		this.packetLossRtd = '--'
		this.latestEventsMins = '--'
		this.latestEvents24h = '--'
	}

	formatValue = v => ([null, undefined].includes(v) ? '--' : v)

	setData = () => {
		const { iot_id, notes, api_key, account_url, agent_id, paused, minrtt } = this.iotDeviceDetails
		const { lost, worstRtd, bestRtd, medianRtd, packetloss, iot_name } = this.iotDeviceDetails

		this.deviceID = iot_id
		this.notes = notes

		this.pingRtd = this.formatValue(minrtt)
		this.packetLoss = this.formatValue(lost)
		this.worstRtd = this.formatValue(worstRtd)
		this.medianRtd = this.formatValue(medianRtd)
		this.bestRtd = this.formatValue(bestRtd)
		this.packetLossRtd = this.formatValue(packetloss)
		this.deviceName = this.formatValue(iot_name)
		
			
		this.deviceHisoryParams = {
			iot_id,
		}

		this.getIotIncidentList()
	}

	checkForTriggerProp = (params = {}) => 'triggers' in params

	setTriggeralue = (alert, warning, alert2, warning2) => {
		this.medianAlert = alert
		this.medianWarning = warning

		this.packetLossAlert = alert2
		this.packetLossWarning = warning2
	}

	getIotIncidentList = () => {
		this.getIot.getIotLiteIncidents(this.deviceID, 1).subscribe(
			(data: any) => {
				const res = data.response

				if (res && res.length > 0) {
					this.latestEvents24h = res.length
				}
			},
			err => {}
		)
	}
}
