import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ZoomHelperService {
  constructor() {}

  countInuseDevice = (deviceList) => {
    const inUseDevice = deviceList.filter((device) => device.inuse);
    return inUseDevice.length;
  };
}
