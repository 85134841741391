import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-support-contact',
  templateUrl: './support-contact.component.html',
  styleUrls: ['./support-contact.component.scss'],
})
export class SupportContactComponent implements OnInit, OnChanges {
  @Input() directAccessInfo;
  supportEmail: string;
  supportPhone: string;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.directAccessInfo.supportUser) {
      this.supportEmail = this.directAccessInfo.supportUser.email;
      this.supportPhone = this.directAccessInfo.supportUser.phone;
    } else {
      this.supportEmail = '';
      this.supportPhone = '';
    }
  }

  ngOnInit() {}
}
