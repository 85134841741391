import { RefreshApiService } from 'src/app/services/refresh-api.service'

import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'
import { faCog, faPause, faPlay, faUser } from '@fortawesome/free-solid-svg-icons'

import { environment } from '../../../../environments/environment'
// tslint:disable-next-line: max-line-length
import { WebportalProtocolConfigDialogComponent } from '../../../iot/dialogs/webportal-protocol-config-dialog/webportal-protocol-config-dialog.component'
import { AuthService } from '../../../services/auth.service'
import { GetIotService } from '../../../services/get-iot.service'
import { GetVideoService } from '../../../services/get-video.service'
import { PopupSendDataService } from '../../../services/popup-send-data.service'
import { PopupTypeService } from '../../../services/popupType.service'

@Component({
  selector: 'app-video-device-info',
  templateUrl: './video-device-info.component.html',
  styleUrls: ['./video-device-info.component.scss'],
})
export class VideoDeviceInfoComponent implements OnChanges, OnInit, OnDestroy {
  @Output() videoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() not: any = 'not';
  @Input() videoDeviceData: any;
  @Input() videoDeviceType: any;
  @Input() isShowLincese: any;
  @Input() unmonitoredPorts: any;
  @Input() monitoredPorts: any;
  @Input() iotDevicePortsDetails: any = [];
  @Input() iotDeviceDetails: any = [];
  
  
  siteApiData: any;
  id: number;
  allSiteListData: any;
  mins: any;
  days: any;
  hrs: any;
  accessLevel: any;
  companyName: any;
  faPauseIcon = faPause;
  faPlayIcon = faPlay;
  mspLogo: string = '../../assets/img/logo/' + environment.config.logo;
  // filterGateKeeperArray: any[] = [];
  // filterSipKeeperArray: any[] = [];
  shouldShowTouchControl: any = false;
  shouldShowTouchControlLogitech: any = false;
  isRemoteControl : boolean = false;
  pairingsData: any;
  hasAccess: boolean;
  isSuperUser: any;

  shouldShowIot: Boolean;
  iotData: any;
  videoPaused: any = 0;

  showWakeupLoader: any = 0;
  isDomotzEnabled: boolean = false;
  isShowDomotzIntegration = environment.config.domotzIntegration;
  faIcon = faUser;
  faCogIcon: any = faCog;

  isShowUptoDate: any = false;
  // checkDeviceState1: any;
  
  deviceActionId: any;
  setTimer: any;
  actionCount: any = 0;
  // showUcBoardHealth: any = false;
  
  iotLiteEnabled: any;
  iotDeviceIdList: any = [];
  dCollector: String = environment.config.dynamicText.dCollector;
  iotLoader: String = environment.config.dynamicImage.iotLoader;
  iotDomotzList: any;
  iotDomotzDeviceId: any;
  iotUnlinkedDeviceList: any;
  
	videoDeviceStatus: any = 'Loading'
	
  
  logitechData: any;
  showWarningStatus: any = false;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public ds: PopupTypeService,
    private sendPopupDataService: PopupSendDataService,
    public http: HttpClient,
    private Auth: AuthService,
    private getVideoService: GetVideoService,
    private GetIot: GetIotService,
    public dialog: MatDialog,
    private refreshApiData: RefreshApiService,
  ) {
    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.videoDeviceData && this.videoDeviceData) {
      this.setData();
    }
  }
  
  ngOnInit() {
    this.getCompanyInfo();
  }
  
  ngOnDestroy() {
    this.ds.clearPopupType();
  }
  
  getCompanyInfo = () => {
    this.ds.sendPopupType(0);
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.accessLevel = decodedToken.accesslevel;
    this.isSuperUser = this.Auth.accessLevel === 'SUPERADMIN';
    this.hasAccess = this.getHasAccess();

    const companyId = {
      company_id: decodedToken.company_id,
    };

    this.Auth.getCompanyInfo(companyId).subscribe((data) => {
      this.companyName = data.response[0].company_name;
  });}
  
  setData = () => {
        // this.testOpenAdmin(); //rempve
    const {
      collector_id,
      video_device_id,
      iot_components,
      iot_domotz_components,
      collector_version,
      collector_status,
      room_name,
      site_id,
      mac,
      ipv4,
      gatekeeper,
      sip_info,
      paused,
      status,
      device_type,
      online,
      logitech_data,
      direct_access_info,
     is_direct_access, 
      
    } = this.videoDeviceData;
    
    this.iotData = {
      collector_id,
      device_id: video_device_id,
      quadrant: 1,
      iot_components,
      iot_domotz_components,
      collector_version,
      status: collector_status === 1,
      room_name,
      site_id,
      mac,
      ipv4,
    };
      
    this.logitechData = logitech_data;
    // this.filterGateKeeperAndSip(gatekeeper,sip_info);
    this.converUptimeForNonHdxDevice(); //not sure why they use prev value?
    

    
    // START TEMPORARY WORKAROUND
    this.setDeviceType();
;        // END TEMPORARY WORKAROUND
    // this.checkDeviceState()
    // this.showUcBoardHealth = this.videoDeviceData.device_type !== 'hdx';
    // this.checkDeviceState1 = this.checkDeviceState();
    // this.showWellnessCheck();
    this.getDomotzAccountByCompanyId();
    
    if( device_type == 'logitech') {
      this.showTouchControlLogitech(logitech_data);
    } else {
      this.showTouchControl();
    }
        
		// this.videoDeviceStatus = paused ? 'Paused' : status == 1 ? 'Online' : status == 2 ? 'Warning' : 'Offline'
        
    this.setDeviceStatus(online, paused, collector_status, is_direct_access, direct_access_info);
  }
  
  setDeviceStatus = (online, paused, collector_status, is_direct_access, direct_access_info) => {
    if( online == 1 && paused == 0 && collector_status != -1 ) {
      this.videoDeviceStatus = 'Online'
    }
    
    if( online == -1 && paused == 0 && collector_status != -1 ) {
      this.videoDeviceStatus = 'Sleep'
    }
    
    if( online == 0 && paused == 0 && collector_status != -1 ) {
      this.videoDeviceStatus = 'Offline'
    }
    
    if( collector_status == -1 ) {
      this.videoDeviceStatus = 'Collector Offline'
    }
    
    if( collector_status == 2 ) {
      this.videoDeviceStatus = 'Collector Offline'
      this.showWarningStatus = true;
    }
    
    if( is_direct_access  == 1 &&  direct_access_info?.isEnable == 1 ) {
      this.videoDeviceStatus = 'Teleportivity'
      this.showWarningStatus = true;
    }

    if (paused === 1) {
      this.videoDeviceStatus = 'Paused'
    }

  }
    
  getDomotzAccountByCompanyId = () => {
    this.GetIot.getDomotzAccountByCompanyId().subscribe((data: any) => {
      const { enabled } = data;
      this.isDomotzEnabled = enabled === 1;
      if (this.isDomotzEnabled) {
        this.setIoTComponenets(true);
      } else {
        this.getIotLiteStatus();
      }
    });
  };
  
  getIotLiteStatus = () => {
    this.GetIot.getIotLiteStatus().subscribe((data: any) => {
      let [res] = data.response;
      this.iotLiteEnabled = res.is_iot_lite;
      this.setIoTComponenets(false);
    });
  }
  
  setIoTComponenets = (isdomotzEnabled) => {
      if (isdomotzEnabled) {
        this.getLinkedIotDomotzDevices();
      } else {
        if (this.iotLiteEnabled) {
          this.getLinkedIotDevices();
        } 
      }
  };
  
  getLinkedIotDevices = () => {
    if (this.iotData.iot_components !== null) {
      this.iotDeviceIdList = [...this.iotData.iot_components].map( e => e.iot_id);
    }
  };

  getLinkedIotDomotzDevices = () => {
    const { iot_domotz_components } = this.iotData;
    if (iot_domotz_components !== null) {
      this.iotDeviceIdList = iot_domotz_components.map((i) => {
        const status = i.status;
        const paused = i.paused;
        const iot_device_id = i.iot_id;
        const iot_type = i.group_type;
        const iot_name = i.display_name;
        const iot_ip = i.ip_address;

        return { iot_device_id, status, iot_type, iot_name, iot_ip, paused };
      });
    } else {
      this.iotDeviceIdList = [];
    }
  };
  
  
  showTouchControlLogitech = (logitech_data) => {
  
      const { settings: { peripherals } } = logitech_data
    
    if (peripherals) {
      if (peripherals.length > 0) {
        this.showRemoteControl(peripherals);
        const [getTap] = peripherals.filter(e => e.type == 'TAP')
        
        if(getTap) {
          this.shouldShowTouchControlLogitech = true;
        } else {
          this.shouldShowTouchControlLogitech = false;
        }
        // this.tapPowerSaving = getTap.tapCustomOrientation
      }
    }
  
  }

  showRemoteControl = (peripherals) => {
    const [getRemote] = peripherals.filter(e => e.type === 'REMOTE')
    if(getRemote && getRemote?.batteryLevel){
      this.isRemoteControl = getRemote.batteryLevel === -1 ? false : true;
    } else this.isRemoteControl = false;
  }
  
  showTouchControl = () => {
    const deviceInfo = { ...this.videoDeviceData.device_info };
    if (deviceInfo) {
      if (
        deviceInfo.pairings &&
        deviceInfo.pairings !== null &&
        deviceInfo.pairings.hardware_version === '4'
      ) {
        this.shouldShowTouchControl = true;
        this.pairingsData = { ...deviceInfo.pairings };
      } else {
        this.shouldShowTouchControl = false;
      }
    }
  }
  
  setDeviceType = () => {
    if (
      this.videoDeviceData.device_type &&
      this.videoDeviceData.device_type == 'polycom'
    ) {
      if (
        this.videoDeviceData.model &&
        this.videoDeviceData.model.toLowerCase().includes('studio')
      ) {
        this.videoDeviceData.device_type = 'polystudio';
      }
    }
  }

  converUptimeForNonHdxDevice = () => {
    if (this.videoDeviceData.device_type !== 'hdx') {
      let t = this.convert(this.videoDeviceData.uptime);
      this.days = Math.floor(t / (3600 * 24));
      t -= this.days * 3600 * 24;
      this.hrs = Math.floor(t / 3600);
      t -= this.hrs * 3600;
      this.mins = Math.floor(t / 60);
      t -= this.mins * 60;
    } else {
      const dTime = this.videoDeviceData.uptime.split(':');
      const t0 = parseInt(dTime[0]);
      const t1 = parseInt(dTime[1]);
      const t2 = parseInt(dTime[2]);
      if (t0 >= 24) {
        const days = Math.floor(t0 / 24);
        const extraHrs = t0 % 24;
        this.days = days;
        this.hrs = t1 + extraHrs;
        this.mins = t2;
      } else {
        this.days = 0;
        this.hrs = t0;
        this.mins = t1;
      }
    }
    
    
    if (this.videoDeviceData.device_type == 'logitech') {
      const newDate = new Date().getTime()
        this.days = 1;
        this.hrs = 2;
        this.mins = 3;
    }
    
    }
    
    
  


  convert = (n) => {
    if (n) {
      var [lead, decimal, pow] = n.toString().split(/e|\./);
      if (pow) {
        return +pow <= 0
          ? '0.' + '0'.repeat(Math.abs(pow) - 1) + lead + decimal
          : lead +
              (+pow >= decimal.length
                ? decimal + '0'.repeat(+pow - decimal.length)
                : decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
      } else {
        return n;
      }
    }
  }

  paramData = () => {
    this.sendPopupDataService.sendPopupData(this.id);
  }

  sendPopupType = (p) => {
    this.ds.sendPopupType(p, { mediaType: 'video', deviceId: this.id });
  }
  
  pauseVideo = (n) => {
    if (this.videoPaused == n) {
      this.videoPaused = 0;
      this.videoDeviceData = null;
    } else {
      this.videoPaused = n;
    }
  }
  

  
  setDevicePause = (flag) => {
    const pauseFlag = flag ? 0 : 1;
    // let i = 0;
    
    this.getVideoService.pauseVideoDevice(this.id, pauseFlag).subscribe((data: any) => {
      // console.log('pauseAudioDevice', data);
      const [res] = data.response;
      if(res.status == 'OK') {
        // console.log(res.status)
        this.videoDeviceData.paused = pauseFlag
        const { paused, status } = this.videoDeviceData

        this.videoDeviceStatus = paused ? 'Paused' : status == 1 ? 'Online' : status == 2 ? 'Warning' : 'Offline'
        this.iotData?.iot_components?.forEach(component => (component.pause = paused))
        this.iotDevicePortsDetails?.forEach(port => (port.is_monitored = paused === 1 ? 0 : 1))
        this.refreshApiData.refreshStatsEvent.emit('video')
        // while ( i < this.iotDeviceIdList.length) {
        //   console.log(i)
        //   this.setPaused(this.iotDeviceIdList[i], pauseFlag);
        //   i++;
        // }
        // if (this?.iotData?.iot_components?.length) {
        //   this.iotData.iot_components = this.iotData.iot_components.map((x) => {
        //     x.pause = pauseFlag;
        //     return x;
        //   })
        // }

        // this.refreshAudio();
        // setTimeout((x) => {
        //  this.videoEvent.emit('refreshVideoData'); 
        // },1000)
        
      }
    });
  } 
  
  // setPaused = (iot_id,flag) => {
  //   this.GetIot.setIotDevicePaused(iot_id, flag).subscribe(
  //     (data: any) => {
  //       // this.eventEmitter.emit('callGetIotDeviceDetails');
  //       console.log('setIotDevicePaused: ', data)
        
  //     },
  //     (error) => { }
  //   );
  // };
  

  wakeupDevice = () => {
    this.showWakeupLoader = 1;
    this.getVideoService.wakeupVideoDevice(this.id).subscribe(
      (data: any) => {
        // console.log('wakeupDevice: ', data);
        // this.showWakeupLoader = 0;
        let [res] = data.response;
        if (res.status == 'OK') {
          this.deviceActionId = res.action_id;
          this.checkStatus(res.action_id);
          // this.showSearching = true;
        } else {
          // this.errorMsg = res.message;
          // this.showErrMsg = true;
        }
      },
      (err: any) => {
        this.showWakeupLoader = 0;
      }
    );
  };



  checkStatus = (action_id) => {
    console.log('action_id: ', action_id);
    this.Auth.postDeviceActionId({ action_id }).subscribe((actionData) => {
      // console.log('postDeviceActionId: ', actionData);
      // console.log('actionCount: ', this.actionCount);
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 90) {
        this.showWakeupLoader = 0;
        this.videoEvent.emit('refreshVideoData');

        return;
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus(action_id);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === 'FAILED') {
        const [newData] = actionData.response;
        console.log('newData FAILED: ', newData);
        this.videoEvent.emit('refreshVideoData');
        this.showWakeupLoader = 0;
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        const [newData] = actionData.response;
        console.log('newData COMPLETE: ', newData);
        this.videoEvent.emit('refreshVideoData');

        this.showWakeupLoader = 0;
        return;
      }
    });
  };

  rebootDevice = () => {
    this.getVideoService.rebootVideoDevice(this.id).subscribe((data) => {});
  }

  // filterGateKeeper = (arr) => {
  //   if (arr.length > 0 && arr[0].address  && arr[0].address != '' ) {
  //     const filterArr = arr.filter((gate) => gate.address.length != 0);
  //     if (filterArr.length > 0) {
  //       const storeArry = [];
  //       for (let i = 0; i < filterArr.length; i++) {
  //         const { address: regx } = filterArr[i];
  //         const r = /\d\d+/;
  //         const regMatch = regx.match(r);
  //         if (regMatch) {
  //           const addressReg = regx
  //             .substring(regx.match(r).index)
  //             .split(/[<;]/)[0];
  //           storeArry.push({ value: filterArr[i].value, address: addressReg });
  //         }
  //       }
  //       return storeArry.length > 0 ? storeArry : [];
  //     }
  //   }
  //   return [];
  // };



  showUcBoardHealth = () => this.videoDeviceData.device_type !== 'hdx';

  getHasAccess = () => {
    if (this.accessLevel) {
      if (this.accessLevel === 'USER' || this.isSuperUser) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  openRemoteIp = async (ip) => {
    const url = this.checkIp(ip);
    const iotDevice: any = await this.getIotDeviceIpAndMacMatch(
      this.iotData.ipv4,
      this.iotData.mac
    );

    if (this.isDomotzEnabled && this.checkIotIsMonitored(iotDevice)) {
      const iotDeviceId = iotDevice.iot_device_id;

      const webportal = this.dialog.open(
        WebportalProtocolConfigDialogComponent,
        {
          panelClass: 'custom-dialog-container',
          data: {
            iotDeviceId,
          },
        }
      );
    } else {
      window.open(url);
    }
  };

  checkIp = (ip) => {
    return ip.includes(':') ? `https://${ip}` : `http://${ip}`;
  };

  checkIotIsMonitored = (iot) => {
    if (iot) {
      return iot.is_monitored === 1;
    }
    return false;
  };

  showIpWithPort = (port) => port !== null && port !== "" ;

  getIotDeviceIpAndMacMatch = async (ip, mac) => {
    return new Promise((resolve) => {
      this.GetIot.getIotDeviceIpAndMacMatch(ip, mac).subscribe((data: any) => {
        const [response] = data;
        resolve(response);
      });
    });
  };


  testOpenAdmin = () => {
    this.ds.sendPopupType(32, {
      alertsId: this.videoDeviceData.video_device_id,
      alertType: 'video',
      siteId: this.videoDeviceData.site_id,
      data: this.videoDeviceData,
      deviceId: this.videoDeviceData.video_device_id,
    });
  };
  
  setFlag = (flag) => (flag ? 0 : 1);
  

  
  setIotPortStatus = ({ iot_port_id, is_monitored }) => {
    const flag = this.setFlag(is_monitored);
    console.log('iot_port_id: ', iot_port_id)
    console.log('flag: ', flag)

    this.GetIot.setIotPortStatus(iot_port_id, flag).subscribe(
      (data) => {
        console.log('setIsMonitored: ', data);
      },
      (error) => {
        console.log('setIsMonitored error: ', error);

      }
    );
  };
  
  
  getStatus = ({ port_status, is_monitored }) => {
    let color = '';
    if (is_monitored) {
      if (port_status) {
        color = 'circle-green';
      } else {
        color = 'circle-red';
      }
    } else {
      color = 'circle-grey';
    }

    return color;

  };

}
