import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reset-notification-schedule',
  templateUrl: './reset-notification-schedule.component.html',
  styleUrls: ['./reset-notification-schedule.component.scss']
})
export class ResetNotificationScheduleComponent implements OnInit {

  constructor(private _dialogRef:MatDialogRef<ResetNotificationScheduleComponent>) { }

  ngOnInit(): void {
  }

  onClose(status:boolean){
    this._dialogRef.close(status);
  }

}
