import { Component, EventEmitter, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { environment } from '../../../../environments/environment'
import { GetDesktopPluginService } from '../../../services/get-desktop-plugin.service'

@Component({
  selector: 'app-link-desktop-plugin-host-dialog',
  templateUrl: './link-desktop-plugin-host-dialog.component.html',
  styleUrls: ['./link-desktop-plugin-host-dialog.component.scss'],
})
export class LinkDesktopPluginHostDialogComponent {
  modalList: any;
  showLinkClientUser: boolean = false;
  clientToLink: any = '';
  hostName: any = '';
  pluginEnabled: any;
  desktopPluginId: any;
  zoomUserId: any;
  isLoading: any = false;
  pluginId: any;
  sendPopupType: EventEmitter<any>;
  searchKey: any;
  btnLoader: string = environment.config.dynamicImage.loader;
  serviceType: any = 'zoom';
  constructor(
    private desktopPluginService: GetDesktopPluginService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<LinkDesktopPluginHostDialogComponent>
  ) {
    if (data) {
      const {
        config,
        user: {
          plugin_active,
          plugin_id,
          assigned_user,
          plugin_host_name,
          zoom_user_id,
          service_type,
          plugin_application,
        },
        sendPopupType,
      } = data;
      this.pluginEnabled = plugin_active === 1;
      this.clientToLink = assigned_user;
      this.hostName = plugin_host_name;
      this.desktopPluginId = plugin_id;
      this.zoomUserId = zoom_user_id;
      this.serviceType = service_type;
      this.sendPopupType = sendPopupType;
      this.modalList = config.map((e) => {
        return {
          ...e,
          host_name: e.host_name || '--',
        };
      });
    }
  }

  unlinkDesktopPlugin = () => {
    this.isLoading = true;
    this.desktopPluginService.unlinkDesktopPlugin(this.desktopPluginId, this.serviceType).subscribe(
      (data) => {
        this.sendPopupType.emit('resetPagination');
        this.sendPopupType.emit('desktopClientList');
        this.sendPopupType.emit('getListCollector');

        this.closeDialog();
      },
      (err) => { }
    );
  };

  linkDesktopPlugin = () => {
    const obj = {
      desktop_plugin_id: this.desktopPluginId,
      zoom_user_id: this.zoomUserId,
      service_type: this.serviceType,
    };
    // // console.log('linkDesktopPlugin: ', obj);
    this.isLoading = true;
    this.desktopPluginService.linkDesktopPlugin(obj).subscribe(
      (data) => {
        this.sendPopupType.emit('resetPagination');
        this.sendPopupType.emit('desktopClientList');
        this.sendPopupType.emit('getListCollector');
        this.closeDialog();
      },
      (err) => { }
    );
  };

  getPluginTypeImage = (type) => {
    return type === 'zoom'
      ? './assets/img/zoom_logo.png'
      : './assets/img/logo-microsoft-teams-2.png';
  };

  openLinkClient = ({ host_name, desktop_plugin_id }) => {
    this.hostName = host_name;
    this.desktopPluginId = desktop_plugin_id;
    this.showLinkClientUser = true;
  };

  closeLinkClient = () => {
    this.showLinkClientUser = false;
    this.dialogRef.close(true);
    
  };

  closeDialog = () => {
    this.isLoading = false;
    this.dialogRef.close(true);
  };

  getModalTitle = () => {
    if (this.pluginEnabled) {
      return 'UN-LINK CLIENT TO USER';
    }
    if (!this.pluginEnabled) {
      return 'LINK CLIENT TO USER';
    }
    return 'title';
  };

  getUsersToLinkName = ({ firstname }) => {
    return firstname;
  };

  // shouldLinkToHost = () => {
  //   // return !this.showLinkClientUser && !this.pluginEnabled && hostName === null && desktopPluginId === null
  //   return !this.showLinkClientUser && !this.pluginEnabled && this.hostName === null && this.desktopPluginId === null
  // }
}
