import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { ZoomPluginStatusService } from '../../services/zoom-plugin-status.service';


@Component({
  selector: 'app-teams-room-device-list-popup',
  templateUrl: './teams-room-device-list-popup.component.html',
  styleUrls: ['./teams-room-device-list-popup.component.scss']
})
export class TeamsRoomDeviceListPopupComponent implements OnChanges {

  @Input() data: any;

  deviceList: any;

  constructor(
    public zoomPluginStatus: ZoomPluginStatusService

  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && this.data) {
      this.setData();

    }
  }


  setData = () => {
    // console.log('setdata: ', this.data)
    const { deviceList } = this.data;
    this.deviceList = deviceList
  }


}
