<mat-tab-group
  class="tabs-round-buttons tabs-body-none"
  [(selectedIndex)]="tabIndex"
  (selectedTabChange)="onTabChange()"
>
  <mat-tab [label]="tab.label" *ngFor="let tab of tabList" [disabled]="tab.type !== tabList[tabIndex].type && showLoader"></mat-tab>
</mat-tab-group>
<hr class="horizontal-line" />
<div class="col-12 d-flex justify-content-end align-items-center">
  <input
    type="text"
    placeholder="Search here"
    class="form-control input-size bg-light col-4"
    id="search"
    name="search"
    [(ngModel)]="search"
  />
  <button
    class="btn btn-block bg-blue text-white h-100 col-2"
    (click)="onSearch()"
  >
    Search
  </button>
</div>
<mat-table
  [dataSource]="dataSource"
  class="mt-1 table"
  infinite-scroll
  [infiniteScrollDistance]="1"
  [infiniteScrollThrottle]="500"
  [scrollWindow]="false"
  (scrolled)="onScroll()"
>
  <ng-container
    *ngFor="let column of tabList[tabIndex].columns"
    [matColumnDef]="column.columnDef"
  >
    <mat-header-cell *matHeaderCellDef>
      {{ column.header }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div
        *ngIf="column.columnDef == 'alert';"
        class="d-flex justify-content-center align-items-center"
      >
        <input type="checkbox" [disabled]="showLoader || !element.schedule_start_time" [(ngModel)]="element.is_enabled" (ngModelChange)="onSubmit(element)" class="mr-2" />
        <span>Disable Alerts</span>
      </div>
      <div
        style="cursor:pointer;"
        *ngIf="column.columnDef == 'schedule_date';else elseBlock"
      >
        <span style="color: gray;" *ngIf="element.schedule_type">{{column.getCell(element,column.columnDef)}} &nbsp; <i class="fa fa-undo" [ngClass]="showLoader?'disabled-btn':''" (click)="resetAlert(element);"></i></span>
        <i class="fa fa-calendar" *ngIf="!element.schedule_type" (click)="open(element)"></i>
      </div>
      <ng-template #elseBlock>{{ column.getCell(element,column.columnDef) }}</ng-template>
    </mat-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="displayedColumns; sticky: true"
  ></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
 
</mat-table>
<div class="loader-img">
  <img *ngIf="showLoader" [src]="loader" />
</div>

