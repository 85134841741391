<!-- stats buttons -->
<div class="row">
  <div class="col-12 pl-1">
    <h5>Live Stats</h5>
  </div>
  <div class="col-4 btn-col pl-0 pr-1">
    <app-btn-reg-stats [count]="videoSitesSummary?.impacted_sites" label="Impacted" color="gray"
      (click)="setFilter('impacted')" [ngClass]="{ 'outlineBtn': activeFilter == 'impacted' }"></app-btn-reg-stats>
  </div>
  <div class="col-4 btn-col pl-0 pr-1">
    <app-btn-reg-stats [count]="videoSitesSummary?.on_call" label="On Call" color="gray" (click)="setFilter('oncall')"
      [ngClass]="{ 'outlineBtn': activeFilter == 'oncall' }"></app-btn-reg-stats>
  </div>
  <div class="col-4 btn-col pl-0 pr-1">
    <app-btn-bitrate [count]="calMbps()" label="Live Mbps" color="gray" [activeBtn]="activeBtn3"
      [isbutton]="not"></app-btn-bitrate>
    <!-- [class.btnpressed]="activeSelection==3" -->
  </div>
</div>
<!-- hr line space -->
<div class="row">
  <div class="col-12 pl-0 pr-1 my-1">
    <hr />
  </div>
</div>
<!-- stats buttons -->
<div class="row">
  <div class="col-12 pl-0 pr-1">
    <h5>Today</h5>
    <app-btn-long-meetings [count]="videoSitesSummary?.scheduled_meetings"></app-btn-long-meetings>
  </div>
</div>
<!-- hr line space -->
<div class="row">
  <div class="col-12 pl-0 pr-1 my-1">
    <hr />
  </div>
</div>
<!-- stats buttons -->
<div class="row">
  <div class="col-12 pl-1">
    <h5>Company Information</h5>
  </div>
  <div class="col-4 pl-0 pr-1">
    <app-btn-reg-stats [count]="videoSitesSummary?.sites" label="Sites" color="gray"
      [isbutton]="not"></app-btn-reg-stats>
  </div>
  <div class="col-4 pl-0 pr-1">
    <app-btn-reg-stats [count]="videoSitesSummary?.infrastructure" label="Infrastructure" color="gray"
      [isbutton]="not"></app-btn-reg-stats>
  </div>
  <div class="col-4 pl-0 pr-1">
    <app-btn-reg-stats [count]="videoSitesSummary?.total_devices" label="Devices" color="gray"
      [isbutton]="not"></app-btn-reg-stats>
  </div>
</div>