import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PopupTypeService } from '../../../../services/popupType.service';

@Component({
  selector: 'app-cloud-user-live-stats',
  templateUrl: './cloud-user-live-stats.component.html',
  styleUrls: ['./cloud-user-live-stats.component.scss'],
})
export class CloudUserLiveStatsComponent implements OnInit {
  @Input() cloudUserLivestats: any;
  @Input() isNotButton: any;
  @Input() isTeamsUser: any;

  shouldShowScheduledMeetings: boolean = true;
  calculatedMbps: number;
  teamsMbps: any = "--";
  frameColor: any;
  pacColor: any;
  jitColor: any;
  bitColor: any;
  isShowPeopleCount: boolean;
  id: number;
  totalMbps: any;
  networkMonitorTeams: any; getTeamsTotalMpbs: any;
  isDisableDesktopClientPluginToggle: boolean = true; //disable for now

  isZoomPluginEnabled: boolean = false;
  desktopPluginMessage: string;
  deskPluginStatus: number;
  serviceType: any = 'zoom'


  constructor(public ds: PopupTypeService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cloudUserLivestats && this.cloudUserLivestats) {
      // // console.log('cloudUserLivestats: ', this.cloudUserLivestats);
      this.setData();
    }
    if (changes.isTeamsUser && this.isTeamsUser) {
      this.shouldShowScheduledMeetings = false;
      this.serviceType = 'teams'
    }
  }

  ngOnInit() { }

  setData = () => {
    this.id = this.cloudUserLivestats.zoom_user_id;
    // // console.log(this.id)
    this.isShowPeopleCount =
      this.cloudUserLivestats.service_type !== 'teams' && this.isShowPanacast();

  };


  getNetworkMonitorTeams = ({ liveMeetings, service_type, zoom_plugin_data }) => {
    if (true && service_type === 'teams' && zoom_plugin_data !== null) {
      const {
        zoom_plugin_data: {
          networkMonitor: { networkMonitorTeams },
        },
      } = this.cloudUserLivestats;

      return networkMonitorTeams;
    }
    return false;
  };

  getTotalMbps = ({ }) => {
    return;
  };

  lowAsGood = (total, warning, alert) => {
    if (total >= alert) {
      return 'red';
    }
    if (total >= warning && total < alert) {
      return 'yellow';
    }

    return 'gray'
  }

  highAsGood = (total, warning, alert) => {
    if (total <= alert) {
      return 'red';
    }
    if (total > alert && total <= warning) {
      return 'yellow';
    }
    return 'gray'
  }


  calMbps = () => {
    if (this.cloudUserLivestats) {
      const roundnum = this.cloudUserLivestats.total_bitrate / (1024 * 1024);
      this.calculatedMbps = Math.round(roundnum * 100) / 100;
      return this.calculatedMbps;
    }
  };

  colorBit() {
    if (this.cloudUserLivestats.trigger_limits) {
      this.bitColor =
        this.cloudUserLivestats.trigger_limits.bitrate.good == 'low'
          ? this.lowAsGood(
            this.cloudUserLivestats.total_bitrate,
            this.cloudUserLivestats.triggers.bitrate.warning,
            this.cloudUserLivestats.triggers.bitrate.alert
          )
          : this.highAsGood(
            this.cloudUserLivestats.total_bitrate,
            this.cloudUserLivestats.triggers.bitrate.warning,
            this.cloudUserLivestats.triggers.bitrate.alert
          );
    }
    return this.bitColor || '';
  }

  colorFrame() {
    if (this.cloudUserLivestats.trigger_limits) {
      this.frameColor =
        this.cloudUserLivestats.trigger_limits.framerate.good == 'low'
          ? this.lowAsGood(
            this.cloudUserLivestats.frame_rate,
            this.cloudUserLivestats.triggers.framerate.warning,
            this.cloudUserLivestats.triggers.framerate.alert
          )
          : this.highAsGood(
            this.cloudUserLivestats.frame_rate,
            this.cloudUserLivestats.triggers.framerate.warning,
            this.cloudUserLivestats.triggers.framerate.alert
          );
    }

    return this.frameColor;
  }

  colorJit() {
    if (this.cloudUserLivestats.trigger_limits) {
      // // console.log('this.cloudUserLivestats.trigger_limits: ', this.cloudUserLivestats)
      this.jitColor =
        this.cloudUserLivestats.trigger_limits.jitter.good == 'low'
          ? this.lowAsGood(
            this.checkNull(this.cloudUserLivestats.total_jitter),
            this.cloudUserLivestats.triggers.jitter.warning,
            this.cloudUserLivestats.triggers.jitter.alert
          )
          : this.highAsGood(
            this.checkNull(this.cloudUserLivestats.total_jitter),
            this.cloudUserLivestats.triggers.jitter.warning,
            this.cloudUserLivestats.triggers.jitter.alert
          );
    }

    // // console.log(this.jitColor, 'jitColor')
    return this.jitColor;
  }

  checkNull = (params) => {
    return params == null || params == undefined ? 0 : params;
  }

  colorPac() {
    if (this.cloudUserLivestats.trigger_limits) {
      this.pacColor = this.cloudUserLivestats.trigger_limits.packetsloss.good = 'low'
        ? this.lowAsGood(
          this.cloudUserLivestats.avg_packet_loss,
          this.cloudUserLivestats.triggers.packetsloss.warning,
          this.cloudUserLivestats.triggers.packetsloss.alert
        )
        : this.highAsGood(
          this.cloudUserLivestats.avg_packet_loss,
          this.cloudUserLivestats.triggers.packetsloss.warning,
          this.cloudUserLivestats.triggers.packetsloss.alert
        );
    }
    return this.pacColor;
  }

  isShowPanacast() {
    if (this.cloudUserLivestats) {
      if (
        this.cloudUserLivestats.zoom_plugin_connected_devices &&
        this.cloudUserLivestats.zoom_plugin_enabled === 1
      ) {
        if (
          this.cloudUserLivestats.plugin_application &&
          this.cloudUserLivestats.plugin_application[0].panacast === -1
        ) {
          return false;
        }
        const cameraList = [...this.cloudUserLivestats.zoom_plugin_connected_devices.video];
        const panacast = cameraList.filter((cam) => {
          const camName = cam.name.toLowerCase();
          if (camName.search('panacast') !== -1) {
            return cam.primary;
          }
          return false;
        });
        return panacast.length > 0;
      }
      return false;
    }
    return false;
  }

  getWifiSignalRange = (range) => {
    // // console.log(range)
    if (range == null || !this.cloudUserLivestats.isTeamsEnabled) {
      return '../../../../../assets/img/vs_img/wifi_gray.png';
    }

    if (range >= 90) {
      return '../../../../../assets/img/vs_img/wifi_green_2.png';
    }

    if (range >= 80) {
      return '../../../../../assets/img/vs_img/wifi_green_1.png';
    }

    if (range >= 50) {
      return '../../../../../assets/img/vs_img/wifi_orange.png';
    }

    if (range >= 10) {
      return '../../../../../assets/img/vs_img/wifi_red.png';
    }

    return '../../../../../assets/img/vs_img/wifi_gray.png';
  };


  showPerfomanceGraph = (section) => {
    this.ds.sendPopupType(69, { deviceId: this.id, section, id: this.cloudUserLivestats.id })
  }

}
