import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VisoneSiteService {
    constructor(private http: HttpClient) {}
    // Load the JSON file for the Site Panel
    fetchVisoneSites(): Observable<Object> {
        return this.http.get('/assets/db/popups/VisoneSites.json');
    }
}
