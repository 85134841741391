import * as moment from 'moment-timezone'

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

import { environment } from '../../../../../environments/environment'
import { GetDesktopPluginService } from '../../../../services/get-desktop-plugin.service'
import { PopupTypeService } from '../../../../services/popupType.service'
import { ZoomPluginActionService } from '../../../../services/zoom-plugin-action.service'

@Component({
  selector: 'app-cloud-user-device-health-button',
  templateUrl: './cloud-user-device-health-button.component.html',
  styleUrls: ['./cloud-user-device-health-button.component.scss'],
})
export class CloudUserDeviceHealthButtonComponent implements OnInit, OnChanges {
  @Input() quadrantType: string;
  @Input() cloudUserStats: any;
  camera: any = '../../../../../assets/img/cloud/icon_camera.png';
  mic: any = '../../../../../assets/img/cloud/icon_microphone.png';
  speaker: any = '../../../../../assets/img/cloud/icon_volume.png';
  zoom: any = '../../../../../assets/img/cloud/desktop_app.png';
  teamsImg: any = '../../../../../assets/img/cloud/desktop_app.png';
  isOnCall: boolean = false;
  zoomPluginEnabled: boolean = true;
  pluginId: number;
  zoomUserId: number;
  pluginStatus: number;
  zoomApiConnectedDevice: any;
  pluginConnectedDevice: any = null;
  deviceStatus: string;
  device: string = '';
  GREEN = '../../../../../assets/img/shared/triangle_green.png';
  YELLOW = '../../../../../assets/img/shared/traingle_orange.png';
  RED = '../../../../../assets/img/shared/triangle_red.png';
  GREY = '../../../../../assets/img/shared/triangle_grey.png';
  shouldShowLoader: boolean = false;
  loader: String = environment.config.dynamicImage.iotLoader;
  dPluginText: String = environment.config.dynamicText.deskPlugin;
  resultMessage: String;
  resultStatus: String = 'OK';

  constructor(
    private desktopPlugin: GetDesktopPluginService,
    public zoomPluginAction: ZoomPluginActionService,
    public ds: PopupTypeService
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isReady()) {
      this.zoomPluginEnabled = this.cloudUserStats.zoom_plugin_enabled === 1 ? true : false;
      this.pluginId = this.cloudUserStats.plugin_id;
      this.zoomUserId = this.cloudUserStats.zoom_user_id;
      this.isOnCall = this.cloudUserStats.liveMeetings === 1 ? true : false;
      this.pluginStatus = this.cloudUserStats.plugin_status;
      this.setConnectedDevices();

      // // console.log(this.quadrantType, 'quadrantType')
    }
  }

  getQuadrantImage = () => {
    if (this.quadrantType === 'mic') {
      return this.mic;
    }
    if (this.quadrantType === 'camera') {
      return this.camera;
    }
    if (this.quadrantType === 'speaker') {
      return this.speaker;
    }

    return this.zoom;
  };

  setConnectedDevices = () => {
  
    
    const { connected_devices,zoom_plugin_connected_devices } = this.cloudUserStats;
    // console.log('connected_devices: ', connected_devices)
    // console.log('cloudUserStats: ', connected_devices)
    if (!zoom_plugin_connected_devices || zoom_plugin_connected_devices == null) {
    
      if (this.quadrantType === 'mic') {
          this.pluginConnectedDevice = [
            {
                "id": "{0.0.1.00000000}.{2483aed1-5154-4a17-ba12-5a7732ea0222}",
                "name": this.checkIfThisIsEmpty(connected_devices?.microphone),
                "inuse": true,
                "status": 1
            }
          ]
          
        console.log(' this.pluginConnectedDevice: ',  this.pluginConnectedDevice)
        this.device = this.pluginConnectedDevice[0].name;
        
      }
        if (this.quadrantType === 'speaker') {
          this.pluginConnectedDevice = [
            {
                "id": "{0.0.1.00000000}.{2483aed1-5154-4a17-ba12-5a7732ea0222}",
                "name": this.checkIfThisIsEmpty(connected_devices?.speaker) ,
                "inuse": true,
                "status": 1
            }
          ]
        console.log(' this.pluginConnectedDevice: ',  this.pluginConnectedDevice)
        this.device = this.pluginConnectedDevice[0].name;
        
      }
        if (this.quadrantType === 'camera') {
          this.pluginConnectedDevice = [
            {
                "id": "{0.0.1.00000000}.{2483aed1-5154-4a17-ba12-5a7732ea0222}",
                "name": this.checkIfThisIsEmpty(connected_devices?.camera),
                "inuse": true,
                "status": 1
            }
          ]
        console.log(' this.pluginConnectedDevice: ',  this.pluginConnectedDevice)
        this.device = this.pluginConnectedDevice[0].name;
      }
      
    } else {
    
      // we should check if this was the zoom api or not
    
    
      if (this.quadrantType === 'mic') {
      this.zoomApiConnectedDevice = this.cloudUserStats.connected_devices?.microphone || '';
      if (
        this.zoomPluginEnabled &&
        this.pluginId !== null &&
        this.cloudUserStats.zoom_plugin_connected_devices?.length !== 0 //temporary solution
      ) {
        this.pluginConnectedDevice = this.cloudUserStats.zoom_plugin_connected_devices?.audio_mic?.filter(
          (a) => a.inuse
        );
        
        console.log(' this.pluginConnectedDevice: ',  this.pluginConnectedDevice)
      }
    }
    if (this.quadrantType === 'camera') {
      this.zoomApiConnectedDevice = this.cloudUserStats.connected_devices?.camera || '';

      if (
        this.zoomPluginEnabled &&
        this.pluginId !== null &&
        this.cloudUserStats.zoom_plugin_connected_devices?.length !== 0
      ) {
        this.pluginConnectedDevice = this.cloudUserStats.zoom_plugin_connected_devices?.video?.filter(
          (v) => v.inuse
        );
      }
    }
    if (this.quadrantType === 'speaker') {
      this.zoomApiConnectedDevice = this.cloudUserStats.connected_devices?.speaker || '';
      if (
        this.zoomPluginEnabled &&
        this.pluginId !== null &&
        this.cloudUserStats.zoom_plugin_connected_devices?.length !== 0
      ) {
        this.pluginConnectedDevice = this.cloudUserStats.zoom_plugin_connected_devices?.audio_speaker.filter(
          (s) => s.inuse
        );
      }
    }
    
    if (
      this.quadrantType === 'mic' ||
      this.quadrantType === 'speaker' ||
      this.quadrantType === 'camera'

    ) {
      if (
        this.zoomPluginEnabled &&
        this.cloudUserStats.zoom_plugin_connected_devices?.length !== 0
      ) {
        this.pluginConnectedDevice = this.pluginConnectedDevice[0]
          ? this.pluginConnectedDevice[0]
          : null;

        if (this.pluginConnectedDevice) {
          this.device = this.pluginConnectedDevice.name;
          if (this.pluginConnectedDevice.status === 1) {
            this.deviceStatus = this.GREEN;
          } else {
            this.deviceStatus = this.RED;
          }
        } else {
          this.deviceStatus = this.GREY;
        }
      } else {
        this.device = this.zoomApiConnectedDevice;
        if (this.device.length > 0 && this.isOnCall) {
          this.deviceStatus = this.GREEN;
        } else if (this.device.length === 0 && this.isOnCall) {
          this.deviceStatus = this.RED;
        } else {
          this.deviceStatus = this.GREY;
        }
      }
    }
    
  }
  

  };
  
  checkIfThisIsEmpty = (devices) => {
    if(!devices) {
      return 'Primary'
    }
    return devices
  }

  isReady = () => {
    if (!this.cloudUserStats) {
      return false;
    }
    if (this.cloudUserStats.length == 0) {
      return false;
    }
    return true;
  };

  executeAction = () => {

    this.resultMessage = '';
    this.zoomPluginAction.clearActionResult();
    let actionParams = null;
    let actionName = '';
    // // console.log('this.pluginConnectedDevice.id:', this.pluginConnectedDevice);
    if (this.pluginStatus !== 1) {
      this.resultMessage = `${this.dPluginText} is offline.`;
      this.resultStatus = 'Failed';
    } else if (this.pluginConnectedDevice === null && this.quadrantType !== 'zoom' && this.quadrantType !== 'teams') {
      this.resultMessage = 'Device not present.';
      this.resultStatus = 'Failed';
    } else {
      // console.log('quadrantType', this.quadrantType)
      if (this.quadrantType === 'zoom') {
        actionName = 'StartZoomClient';

      } else if (this.quadrantType === 'teams') {
        actionName = 'StartTeamsClient';
      }

      else if (this.quadrantType === 'mic') {
        actionParams = { id: this.pluginConnectedDevice.id };
        actionName = 'RemoteHealMicrophone';
      } else if (this.quadrantType === 'camera') {
        actionParams = { id: this.pluginConnectedDevice.id };
        actionName = 'RemoteHealCamera';
      } else if (this.quadrantType === 'speaker') {
        actionParams = { id: this.pluginConnectedDevice.id };
        actionName = 'RemoteHealSpeaker';
      }
      this.zoomPluginAction.deviceButtonLoader = true;
      this.shouldShowLoader = true;

      this.desktopPlugin.createAction(this.pluginId, actionParams, actionName).subscribe((data) => {
        if (data.status === 'Ok') {
          this.zoomPluginAction.checkDesktopPluginActionStatus(data.action_id);
          this.zoomPluginAction.setActionCount();
        } else {
          this.zoomPluginAction.clearActionResult();
          this.zoomPluginAction.deviceButtonLoader = false;
        }
      });
    }
  };

  getLoader() {
    if (this.zoomPluginAction.deviceButtonLoader) {
      return true;
    }
    this.shouldShowLoader = false;
    if (!this.shouldShowLoader) {
      if (this.zoomPluginAction.actionResult.message) {
        if (this.zoomPluginAction.actionResult.status === 'OK') {
          const dDate = moment(this.zoomPluginAction.actionResult.datetime)
            .utc()
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format('MM-DD-YYYY hh:mma');
          this.resultMessage = `${this.zoomPluginAction.actionResult.message} Date and time: ${dDate}`;
        } else {
          this.resultMessage = `${this.zoomPluginAction.actionResult.message}`;
        }
      }
      this.resultStatus = this.zoomPluginAction.actionResult.status;
    }
    return false;
  }

  displayDeviceHealList() {
    if (this.quadrantType !== 'zoom' && this.pluginConnectedDevice) {
      let data = {
        device: '',
        deviceAction: '',
        pluginId: this.pluginId,
        deviceId: this.pluginConnectedDevice.id,
      };
      if (this.quadrantType === 'mic') {
        data.device = 'Microphone';
        data.deviceAction = 'RemoteHealMicrophone';
      } else if (this.quadrantType === 'camera') {
        data.device = 'Camera';
        data.deviceAction = 'RemoteHealCamera';
      } else if (this.quadrantType === 'speaker') {
        data.device = 'Speaker';
        data.deviceAction = 'RemoteHealSpeaker';
      }

      this.ds.sendPopupType(61, { data });
    }
  }

  getQuadrantTypeLogo = (servicetype) => {
    return servicetype === 'zoom'
      ? '../../../../../assets/img/vs_img/zoom_logo.png'
      : '../../../../../assets/img/vs_img/teams_logo_2.png';
  };
}
