import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-simple-loader',
  templateUrl: './simple-loader.component.html',
  styleUrls: ['./simple-loader.component.scss'],
})
export class SimpleLoaderComponent implements OnInit {
  loaderPath = environment.config.dynamicImage.loader;

  constructor() {}

  ngOnInit() {}
}
