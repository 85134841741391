<div class="container border doNotPrint" [class.active]="active">
  <h2 *ngIf="soon == true" class="soon"></h2>

  <div class="row pl-2 ml-2">
    <div class="col-9" *ngIf="skin == 1">
      <div class="row">
        <div class="col-2">
          <img
            src="{{ iconLink }}"
            alt="Device Icon"
            style="height: auto; width: 50px; margin-top: 10px;"
          />
        </div>
        <div class="col-8 pr-0 mt-2">
          <h3>{{ mainTitle }}</h3>
          <small>{{ subTitle }}</small>
        </div>
        <div class="col-2" *ngIf="enabled">
          <div *ngIf="polycomDevices >= 1 && ciscoDevices >= 1; else noSlideShow" id="slideshow">
            <img
              *ngIf="ciscoDevices >= 1"
              [src]="'../../assets/img/cisco.png'"
              class="cisco adevice0"
              alt="Device Type"
            />
            <img
              *ngIf="polycomDevices >= 1"
              [src]="'../../assets/img/poly_logo.png'"
              class="polycom adevice1"
              alt="Device Type"
            />
          </div>
          <ng-template #noSlideShow>
            <img
              *ngIf="ciscoDevices >= 1"
              [src]="'../../assets/img/cisco.png'"
              class=""
              alt="Device Type"
            />
            <img
              *ngIf="polycomDevices >= 1"
              [src]="'../../assets/img/poly_logo.png'"
              class=""
              alt="Device Type"
            />
          </ng-template>
        </div>
      </div>
      <div *ngIf="enabled">
        <div class="row pb-2">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{ healthBlockSummary?.good_devices != 0 ? healthBarRouteBtn3 : null }}"
            color="green"
            [total]="healthBlockSummary?.all_devices"
            [value]="healthBlockSummary?.good_devices"
            [removeClickable]="healthBlockSummary?.good_devices"
          ></app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{ healthBlockSummary?.impacted_devices != 0 ? healthBarRouteBtn1 : null }}"
            color="yellow"
            [total]="healthBlockSummary?.all_devices"
            [value]="healthBlockSummary?.impacted_devices"
            [removeClickable]="healthBlockSummary?.impacted_devices"
          ></app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{ healthBlockSummary?.red_devices != 0 ? healthBarRouteBtn4 : null }}"
            color="red"
            [total]="healthBlockSummary?.all_devices"
            [value]="healthBlockSummary?.red_devices"
            [removeClickable]="healthBlockSummary?.red_devices"
          ></app-health-bar>
        </div>
        <div class="row pb-4">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{ healthBlockSummary?.paused_devices != 0 ? healthBarRouteBtn5 : null }}"
            color="blue"
            [total]="healthBlockSummary?.all_devices"
            [value]="healthBlockSummary?.paused_devices"
            [removeClickable]="healthBlockSummary?.paused_devices"
          ></app-health-bar>
        </div>
      </div>

      <div *ngIf="!enabled">
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-4">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
      </div>
    </div>
    <div class="col-9" *ngIf="skin == 2">
      <div class="row">
        <div class="col-2">
          <img
            src="{{ iconLink }}"
            alt="Device Icon"
            style="height: auto; width: 50px; margin-top: 10px;"
          />
        </div>
        <div class="col-8 pr-0 mt-2">
          <h3>{{ mainTitle }}</h3>
          <small>{{ subTitle }}</small>
        </div>
        <div class="col-2">
          <img *ngIf="iconLink2 != null" src="{{ iconLink2 }}" class="brandIcon" alt="Brand Icon" />
        </div>
      </div>
      <div *ngIf="enabled">
        <div class="row pb-2">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{
              healthBlockSummary?.good_devices != 0 ? healthBarRouteGoodDevices : null
            }}"
            color="green"
            [total]="healthBlockSummary?.all_devices"
            [value]="healthBlockSummary?.good_devices"
            [removeClickable]="healthBlockSummary?.good_devices"
          >
          </app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{
              healthBlockSummary?.impacted_devices != 0 ? healthBarRouteImpactedDevices : null
            }}"
            color="yellow"
            [total]="healthBlockSummary?.all_devices"
            [value]="healthBlockSummary?.impacted_devices"
            [removeClickable]="healthBlockSummary?.impacted_devices"
          >
          </app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{
              healthBlockSummary?.red_devices != 0 ? healthBarRouteRedDevices : null
            }}"
            color="red"
            [total]="healthBlockSummary?.all_devices"
            [value]="healthBlockSummary?.red_devices"
            [removeClickable]="healthBlockSummary?.red_devices"
          >
          </app-health-bar>
        </div>
        <div class="row pb-4">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{
              healthBlockSummary?.paused_devices != 0 ? healthBarRoutePauseDevices : null
            }}"
            color="blue"
            [total]="healthBlockSummary?.all_devices"
            [value]="healthBlockSummary?.paused_devices"
            [removeClickable]="healthBlockSummary?.paused_devices"
          >
          </app-health-bar>
        </div>
      </div>
      <div *ngIf="!enabled">
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-4">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
      </div>
    </div>
    <!-- SKIN 3 -->
    <div class="col-9" *ngIf="skin == 3">
      <div class="row">
        <div class="col-2">
          <img
            src="{{ iconLink }}"
            alt="Device Icon"
            style="height: auto; width: 50px; margin-top: 10px;"
          />
        </div>
        <div class="col-8 pr-0 mt-2">
          <h3>{{ mainTitle }}</h3>
          <small>{{ subTitle }}</small>
        </div>
        <div class="col-2">
          <img
            *ngIf="enabled"
            [src]="'../../assets/img/zoom_logo.png'"
            class="brandIcon"
            alt="Zoom"
            style="
              height: auto;
              width: 80px;
              margin-top: 10px;
              padding-right: 10px;
              margin-left: -2em;
            "
          />
        </div>
      </div>

      <div *ngIf="enabled">
        <div class="row pb-2">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{
              healthBlockSummary?.good_rooms !== 0 ? cloudHealthBarRouteGoodRooms : null
            }}"
            color="green"
            [total]="healthBlockSummary?.all_huddle_rooms"
            [value]="healthBlockSummary?.good_rooms"
            [removeClickable]="healthBlockSummary?.good_rooms"
          ></app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{
              healthBlockSummary?.warning_rooms !== 0 ? cloudHealthBarRouteWarningRooms : null
            }}"
            color="yellow"
            [total]="healthBlockSummary?.all_huddle_rooms"
            [value]="healthBlockSummary?.warning_rooms"
            [removeClickable]="healthBlockSummary?.warning_rooms"
          >
          </app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{
              healthBlockSummary?.red_rooms !== 0 ? cloudHealthBarRouteRedRoom : null
            }}"
            color="red"
            [total]="healthBlockSummary?.all_huddle_rooms"
            [value]="healthBlockSummary?.red_rooms"
            [removeClickable]="healthBlockSummary?.red_rooms"
          ></app-health-bar>
        </div>
        <div class="row pb-4">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{
              healthBlockSummary?.paused_rooms !== 0 ? cloudHealthBarRoutePauseRoom : 'null'
            }}"
            color="blue"
            [total]="healthBlockSummary?.all_huddle_rooms"
            [value]="healthBlockSummary?.paused_rooms"
            [removeClickable]="healthBlockSummary?.paused_rooms"
          ></app-health-bar>
        </div>
      </div>
      <div *ngIf="!enabled">
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-4">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
      </div>
    </div>

    <!-- SKIN 4 -->

    <div class="col-9" *ngIf="skin == 4">
      <div class="row">
        <div class="col-2">
          <img
            src="{{ iconLink }}"
            alt="Device Icon"
            style="height: auto; width: 50px; margin-top: 10px;"
          />
        </div>
        <div class="col-8 pr-0 mt-2">
          <h3>{{ mainTitle }}</h3>
          <small>{{ subTitle }}</small>
        </div>
        <div class="col-2">
          <img *ngIf="iconLink2 != null" src="{{ iconLink2 }}" class="brandIcon" alt="Brand Icon" />
        </div>
      </div>
      <div *ngIf="!enabled">
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-4">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
      </div>
    </div>

    <!-- SKIN 5 -->

    <div class="col-9" *ngIf="skin == 5">
      <div class="row">
        <div class="col-2">
          <img
            src="{{ iconLink }}"
            alt="Device Icon"
            style="height: auto; width: 50px; margin-top: 10px;"
          />
        </div>
        <div class="col-8 pr-0 mt-2">
          <h3>{{ mainTitle }}</h3>
          <small>{{ subTitle }}</small>
        </div>
        <div class="col-2">
          <img *ngIf="iconLink2 != null" src="{{ iconLink2 }}" class="brandIcon" alt="Brand Icon" />
        </div>
      </div>
      <div *ngIf="!enabled">
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
        <div class="row pb-4">
          <app-health-bar
            color="green"
            [removeClickable]="enabled"
            [enabled]="enabled"
          ></app-health-bar>
        </div>
      </div>
      <div *ngIf="enabled">
        <div class="row pb-2">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{
              healthBlockSummary?.good_rooms !== 0 ? cloudHealthBarRouteGoodRooms : null
            }}"
            color="green"
            [total]="healthBlockSummary?.all_huddle_rooms"
            [value]="healthBlockSummary?.good_rooms"
            [removeClickable]="healthBlockSummary?.good_rooms"
          ></app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{
              healthBlockSummary?.warning_rooms !== 0 ? cloudHealthBarRouteWarningRooms : null
            }}"
            color="yellow"
            [total]="healthBlockSummary?.all_huddle_rooms"
            [value]="healthBlockSummary?.warning_rooms"
            [removeClickable]="healthBlockSummary?.warning_rooms"
          >
          </app-health-bar>
        </div>
        <div class="row pb-2">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{
              healthBlockSummary?.red_rooms !== 0 ? cloudHealthBarRouteRedRoom : null
            }}"
            color="red"
            [total]="healthBlockSummary?.all_huddle_rooms"
            [value]="healthBlockSummary?.red_rooms"
            [removeClickable]="healthBlockSummary?.red_rooms"
          ></app-health-bar>
        </div>
        <div class="row pb-4">
          <app-health-bar
            [enabled]="enabled"
            routerLink="{{
              healthBlockSummary?.paused_rooms !== 0 ? cloudHealthBarRoutePauseRoom : 'null'
            }}"
            color="blue"
            [total]="healthBlockSummary?.all_huddle_rooms"
            [value]="healthBlockSummary?.paused_rooms"
            [removeClickable]="healthBlockSummary?.paused_rooms"
          ></app-health-bar>
        </div>
      </div>
    </div>

    <div class="col-3">
      <!-- video devices health -->
      <ng-container *ngIf="skin == 1 && enabled">
        <div class="vertical-3btn">
          <app-btn-health
            routerLink="{{ healthBarRouteBtn7 }}"
            [count]="healthBlockSummary?.impacted_sites"
            label="{{ healthBarBtnLabel1 }}"
            color="{{ healthBarBtnColor1 }}"
          ></app-btn-health>
        </div>
        <div class="vertical-3btn">
          <app-btn-health
            routerLink="{{ healthBarRouteBtn2 }}"
            [count]="healthBlockSummary?.all_sites"
            label="{{ healthBarBtnLabel2 }}"
            color="{{ healthBarBtnColor2 }}"
          ></app-btn-health>
        </div>
        <div class="vertical-3btn">
          <app-btn-health
            routerLink="{{ healthBarRouteBtn6 }}"
            [count]="healthBlockSummary?.all_devices"
            label="{{ healthBarBtnLabel3 }}"
            color="{{ healthBarBtnColor3 }}"
          ></app-btn-health>
        </div>
      </ng-container>
      <!-- audio devices health -->
      <ng-container *ngIf="skin == 2 && enabled">
        <div class="vertical-3btn">
          <app-btn-health
            routerLink="{{ healthBarRouteBtn1 }}"
            [count]="healthBlockSummary?.impacted_sites"
            label="{{ healthBarBtnLabel1 }}"
            color="{{ healthBarBtnColor1 }}"
          ></app-btn-health>
        </div>
        <div class="vertical-3btn">
          <app-btn-health
            routerLink="{{ healthBarRouteBtn2 }}"
            [count]="healthBlockSummary?.all_sites"
            label="{{ healthBarBtnLabel2 }}"
            color="{{ healthBarBtnColor2 }}"
          ></app-btn-health>
        </div>
        <div class="vertical-3btn">
          <app-btn-health
            routerLink="{{ healthBarRouteBtn3 }}"
            [count]="healthBlockSummary?.all_devices"
            label="{{ healthBarBtnLabel3 }}"
            color="{{ healthBarBtnColor3 }}"
          ></app-btn-health>
        </div>
      </ng-container>

      <!-- cloud devices health -->
      <ng-container *ngIf="skin == 3 && enabled">
        <div class="vertical-3btn">
          <app-btn-health
            routerLink="{{ healthBarRouteBtn2 }}"
            [count]="healthBlockSummary?.all_huddle_rooms"
            label="{{ healthBarBtnLabel2 }}"
            color="{{ healthBarBtnColor2 }}"
          ></app-btn-health>
        </div>
        <div class="vertical-3btn">
          <app-btn-health
            routerLink="{{ cloudSideButtonImpactedVipUsers }}"
            [count]="healthBlockSummary?.impacted_vip_users"
            label="{{ healthBarBtnLabel1 }}"
            color="{{ healthBarBtnColor1 }}"
          ></app-btn-health>
        </div>
        <div class="vertical-3btn">
          <app-btn-health
            routerLink="{{ healthBarRouteBtn6 }}"
            [count]="healthBlockSummary?.all_users"
            label="{{ healthBarBtnLabel3 }}"
            color="{{ healthBarBtnColor3 }}"
          ></app-btn-health>
        </div>
      </ng-container>

      <!-- iot devices health -->
      <ng-container *ngIf="skin == 5 && enabled">
        <div class="vertical-3btn">
          <app-btn-health
            routerLink="{{ healthBarRouteBtn2 }}"
            [count]="healthBlockSummary?.all_agents"
            label="{{ healthBarBtnLabel2 }}"
            color="{{ healthBarBtnColor2 }}"
          ></app-btn-health>
        </div>
        <div class="vertical-3btn">
          <app-btn-health
            routerLink="{{ cloudSideButtonImpactedVipUsers }}"
            [count]="healthBlockSummary?.impacted_agents"
            label="{{ healthBarBtnLabel1 }}"
            color="{{ healthBarBtnColor1 }}"
          ></app-btn-health>
        </div>
        <div class="vertical-3btn">
          <app-btn-health
            routerLink="{{ healthBarRouteBtn6 }}"
            [count]="healthBlockSummary?.all_devices"
            label="{{ healthBarBtnLabel3 }}"
            color="{{ healthBarBtnColor3 }}"
          ></app-btn-health>
        </div>
      </ng-container>
    </div>
  </div>
</div>
