import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GetDesktopPluginService } from '../../../../services/get-desktop-plugin.service';
import * as moment from 'moment-timezone';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../../../environments/environment';

export interface actionListInterface {
  status: string;
  message: string;
  requestStamp: string;
}

@Component({
  selector: 'app-cloud-user-actions',
  templateUrl: './cloud-user-actions.component.html',
  styleUrls: ['./cloud-user-actions.component.scss'],
})
export class CloudUserActionsComponent implements OnChanges, OnInit {
  @Input() data: any;
  @Input() popupState: any;
  displayedColumns: any[] = ['requestStamp', 'message', 'status'];
  dataSource: MatTableDataSource<actionListInterface>;
  actionList = [];
  loader = environment.config.dynamicImage.loader;
  shouldShowLoaderBtn: Boolean = false;
  isActionListEmpty: Boolean = true;

  constructor(private desktopPlugin: GetDesktopPluginService) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.popupState === 'open') {
      this.getActionListByPluginId();
    }

    if (this.popupState === 'close') {
      this.actionList = [];
      this.dataSource = new MatTableDataSource(this.actionList);
    }
  }

  getActionListByPluginId() {
    this.shouldShowLoaderBtn = true;
    const { pluginId, deviceAction } = this.data;
    this.desktopPlugin.getActionListByPluginId(pluginId, deviceAction).subscribe((results) => {
      this.actionList = results.map((a) => {
        const requestStamp = moment(a.request_stamp)
          .utc()
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format('MM-DD-YYYY hh:mma');
        const action = {
          status: a.results.status ? a.results.status.toUpperCase() : "FAILED",
          message: a.results.message ? a.results.message : "Internal Error",
          requestStamp,
        };
        return action;
      });
      if (this.actionList.length > 0) {
        this.isActionListEmpty = false;
      }
      this.dataSource = new MatTableDataSource(this.actionList);
      this.shouldShowLoaderBtn = false;
    });
  }
}
