import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { GetCloudService } from '../../../services/get-cloud.service';
import { TableFilterService } from '../../../services/table-filter.service';
import * as moment from 'moment-timezone';
import { now } from 'jquery';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-cloud-all-incidents-list',
  templateUrl: './cloud-all-incidents-list.component.html',
  styleUrls: ['./cloud-all-incidents-list.component.scss'],
})
export class CloudAllIncidentsListComponent implements OnChanges {
  @Input() backcolorbtn: any;
  @Input() cloudInsListData: any;
  @Input() cloudInsData: any;
  @Input() cloudInsTableSettings: any = this.getCloudDataService.cloudInsTableSettings;
  @Input() data: any;
  loading: boolean = false;
  cloudData: any;
  loader = environment.config.dynamicImage.loader;

  // Settings and Parameters for the the Smart Filter Tables
  // cloudInsTableSettings = this.getCloudDataService.cloudInsTableSettings;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getCloudDataService: GetCloudService,
    private tableFilterService: TableFilterService
  ) {
    /* This function passes the filter click data globaly 
         to any component subscribe to this service */
    this.tableFilterService.listen().subscribe((m: any) => {
      // console.log(m, 'cloudallincidents');
      // this.onFilter(m);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    this.cloudData = this.cloudInsListData;
    // console.log(this.cloudInsListData, 'cloudInsListData');

    function compareAscending(a, b) {
      // console.log(a.timestamp);
      if (
        moment(a.timestamp).diff('1981-01-01', 'seconds', true) >
        moment(b.timestamp).diff('1981-01-01', 'seconds', true)
      )
        return 1;
      if (
        moment(a.timestamp).diff('1981-01-01', 'seconds', true) <
        moment(b.timestamp).diff('1981-01-01', 'seconds', true)
      )
        return -1;
      return 0;
    }

    if (changes.cloudInsListData.currentValue != changes.cloudInsListData.previousValue) {

      // console.log(this.data.section, 'data section')
      // // console.log(changes.count.currentValue + ", pre" + changes.count.previousValue)
      // 1 second delay to display the first set of values
      // setTimeout(() => {
      this.backcolorbtn = 'btnbacklite';
      // // console.log(this.backcolorbtn + "backcolorbtn")
      //   }, 100);
      setTimeout(() => {
        this.backcolorbtn = 'btnbacklite-default';
        // // console.log(this.backcolorbtn + "backcolorbtn")
      }, 2500);
    } else {
      setTimeout(() => {
        this.backcolorbtn = 'btnbacklite-default';
        // // console.log(this.backcolorbtn + "backcolorbtn")
      }, 1000);
    }
  }

  // This function filters the list when certain Live Stat Buttons are clicked
  onFilter(event) {
    const query: string = event[0];
    const cat: string = event[1];
    if (!query) {
      this.cloudInsListData.setFilter([]);
    } else {
      this.cloudInsListData.setFilter(
        [
          {
            field: cat,
            search: query,
            filter: (value: any, query: string) => {
              if (cat == 'video_incident_id') {
                return +value < +query;
              }
            },
          },
        ],
        false
      );
    }
  }

  onLoadFilter() {
    //   if (this.router.url == '/cloud/impactedrooms') {
    //     let arr = ['1', 'impacted']
    //       this.onFilter(arr);
    //   }
  }

  ngOnInit() {
    //GET request for audio users
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);

    this.cloudData = this.cloudInsListData;
  }

  // This function handles the routing for when a table row is clicked
  onClickTable(event) {
    // let room = event.data;
    // this.router.navigate(['cloud/room/', room.room_id]);
  }

  // This is for the search input form for the Smart-Table
  onSearch(query: string = '') {
    if (query === '') {
      this.cloudInsListData.setFilter([]);
    } else {
      this.cloudInsListData.setFilter(
        [
          // fields we want to inclue in the search
          {
            field: 'timestamp',
            search: query,
          },
          {
            field: 'total_mbps',
            search: query,
          },
          {
            field: 'total_jitter',
            search: query,
          },
          {
            field: 'total_latency',
            search: query,
          },
          {
            field: 'total_packetloss',
            search: query,
          },
          {
            field: 'video_incident_id',
            search: query,
          },
        ],
        false,
        true
      );
      // second parameter specifying whether to perform 'AND' or 'OR' search
      // (meaning all columns should contain search query or at least one)
      // 'AND' by default, so changing to 'OR' by setting false here
    }
  }
}
