import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-audio-allsites-info-panel',
  templateUrl: './audio-allsites-info-panel.component.html',
  styleUrls: ['./audio-allsites-info-panel.component.scss']
})
export class AudioAllsitesInfoPanelComponent implements OnInit {

  @Input() audioSitesSummary: any;
  
  constructor() { }

  ngOnInit() {
  }

}
