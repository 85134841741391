import { Injectable } from '@angular/core';

const zoomIssues = {
  cameraDown: false,
  micDown: false,
  speakerDown: false,
  jitterAlert: false,
  packetlossAlert: false,
  frame_rate: false,
  connection_type: false,
};

@Injectable({
  providedIn: 'root',
})
export class ZoomIncidentTrackerService {
  private zoomIncidents: any;
  hasIncidents: boolean = false;

  constructor() {
    this.zoomIncidents = { ...zoomIssues };
  }

  resetZoomIncidents = () => {
    this.zoomIncidents = { ...zoomIssues };
  };

  setCameraIncident = (impactedCount = 0) => {
    this.zoomIncidents.cameraDown = impactedCount > 0;
  };

  setMicIncident = (impactedCount = 0) => {
    this.zoomIncidents.micDown = impactedCount > 0;
  };

  setSpeakerIncident = (impactedCount = 0) => {
    this.zoomIncidents.speakerDown = impactedCount > 0;
  };

  setJitterIncident = (color = '') => {
    this.zoomIncidents.jitterAlert = color === 'red';
  };

  setPacketlossIncident = (color = '') => {
    this.zoomIncidents.packetlossAlert = color === 'red';
  };

  setFramerateIncident = (color = '') => {
    this.zoomIncidents.frame_rate = color === 'red';
  };

  setConnectionIncident = (color = '') => {
    this.zoomIncidents.connection_type = color === 'red';
  };

  gatherIncidents() {
    let flag = false;
    const incidentValues = Object.values(this.zoomIncidents);
    for (let i = 0; i < incidentValues.length; i++) {
      if (incidentValues[i]) {
        flag = true;
        break;
      }
    }
    this.hasIncidents = flag;
  }

  getIncidents() {
    return { ...this.zoomIncidents };
  }
}
