<div class="container-fluid">
  <div class="row py-4 px-2">
    <div class="col-12 text-center">
      <h2 class="text-center">{{title}}</h2>
    </div>
  </div>
</div>

<!-- <div class="popup-content">
  <div class="container-fluid">
<div class="row pt-3">
  <div class="col-md-12">
    <div class="card bg-default">
      <h5 class="card-header">
        {{date}} {{time}} {{duration}}
        Kick-off meeting
      </h5>
      <div class="card-body">
          <table>
            <thead>
              <tr>
                <th>date:</th>
                <td>5/30/18 {{site}}</td>
              </tr>
              <tr>
                <th>time:</th>
                <td>10:00a - 11:00a {{organizer}}</td>
              </tr>
              <tr>
                <th>duration:</th>
                <td>1:00hr {{organizer}}</td>
              </tr>
            </thead>
          </table>
      </div>
      
    </div>
    <div class="card">
      <h5 class="card-header">
        Kick-off Meeting
      </h5>
      <div class="card-body">
        <table>
            <thead>
              <tr>
                <th>date:</th>
                <td>5/30/18 {{site}}</td>
              </tr>
              <tr>
                <th>time:</th>
                <td>12:00a - 1:30p {{organizer}}</td>
              </tr>
              <tr>
                <th>duration:</th>
                <td>1:30hr {{organizer}}</td>
              </tr>
            </thead>
          </table>
      </div>
     
    </div>
    <div class="card">
        <h5 class="card-header">
          Kick-off Meeting
        </h5>
        <div class="card-body">
          <table>
              <thead>
                <tr>
                  <th>date:</th>
                  <td>5/30/18 {{site}}</td>
                </tr>
                <tr>
                  <th>time:</th>
                  <td>1:30p - 2:00p {{organizer}}</td>
                </tr>
                <tr>
                  <th>duration:</th>
                  <td>0:30hr {{organizer}}</td>
                </tr>
              </thead>
            </table>
        </div>
        
      </div>
  </div>
</div>
</div>
</div> -->