<div class="teams-users">
  <div id="scroll-bar" #scrollBarElement style="overflow: auto; height: 280px;">
    <table class="table table-stripped">
      <thead>
        <tr class="black">
          <th scope="col">Client</th>
          <th scope="col">User Name</th>
          <th scope="col">User Type</th>
          <th scope="col">Email</th>
          <th scope="col">Status</th>
          <th scope="col">
            <div style="width: 100px;">
              Desktop Client Status
            </div>
          </th>
          <th scope="col">VIP Alerts</th>
          <th scope="col">Desktop Client</th>
          <th scope="col">Link</th>
          <th *ngIf="shouldShowTh" scope="col"></th>
          <!--   <th scope="col">Updates</th> -->
        </tr>
      </thead>
      <tbody>
        <tr scope="row" *ngFor="let items of teamsUserList" title="{{items.email? items.email: ''}}">
          <!-- <tr scope="row" *ngFor="let items of teamsUserList | teamsMainMenuFilter: searchKey:'teamsUser'"> -->
          <!-- <tr scope="row" *ngFor="let items of teamsUserList"> -->
          <td>
            <div *ngIf="items.plugin_active === null">
              --
            </div>
            <img *ngIf="items.plugin_active !== null" src="{{ getPluginIcon(items.plugin_active) }}" alt="" />
          </td>


          <td>{{ items.assigned_user }}</td>

          <td>
            <span *ngIf="items.alerts === 1" class="teams-users__vip">VIP</span>
            <span *ngIf="items.alerts !== 1">--</span>
          </td>

          <td>
            {{ items.email }}
          </td>

          <td>
            <div class="teams-users__inner-text">
              <div class="test-class">
                <img src="{{ getPhoneCallStatus(items.liveMeetings) }}" alt="" />
              </div>
              <div *ngIf="items.liveMeetings === 1" class="tooltip">
                User is on a call
              </div>
            </div>
          </td>

          <td>
            <div class="teams-users__status {{ getDesktopStatus(items.plugin_status) }}"></div>
          </td>

          <td>
            <div>
              <label class="switch" [ngClass]="{ 'disable-checkbox': items.plugin_id === null}">
                <input type="checkbox" (change)="alertsChange($event,items.zoom_user_id)" [checked]="items.alerts" />
                <span class="slider round"> </span>
              </label>
            </div>
          </td>

          <td>
            <div>
              <label class="switch" [ngClass]="{ 'disable-checkbox': items.plugin_active === null }">
                <input #teamUsersClientChange type="checkbox" (change)="desktopClientChange(teamUsersClientChange.checked, items.zoom_user_id)"
                  [checked]="items.zoom_plugin_enabled" />
                <span class="slider round"> </span>
              </label>
            </div>
          </td>

          <td>
            <div (click)="openDesktopLinkModal(items)" [ngClass]="{ 'disable-checkbox': items.plugin_status == -1 }">
              <img src="{{ getLinkIcon(items) }}">
            </div>
          </td>
          <td *ngIf="shouldShowTh">
            <div *ngIf="spaceStatus === true && items.plugin_id === null" class="fa fa-trash fa-lg"
              (click)="openDeleteTeamsUser(items.zoom_user_id,items.assigned_user)"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="totalTeamsUser > 500" class="teams-users__separator">
    <div class="teams-users__user-count">User Count:
      <span class="teams-users__loader-img" *ngIf="showLoader">
        <img *ngIf="showLoader" [src]="loader" />
      </span>
      <span *ngIf="!showLoader">
        {{ searchKey === '' ? totalUserCount :
        teamsUserList.length }}
      </span>
    </div>
  </div>

</div>