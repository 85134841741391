

import { HttpClient } from '@angular/common/http'
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'
import { GetCompanyService } from '../../services/get-company.service'
import { PopupSendDataService } from '../../services/popup-send-data.service'
import { PopupTypeService } from '../../services/popupType.service'
import { InputValidationService } from 'src/app/services/input-validation.service'

@Component({
  selector: 'app-edit-site',
  templateUrl: './edit-site.component.html',
  styleUrls: ['./edit-site.component.scss'],
})
export class EditSiteComponent implements OnInit, OnChanges {
  @Input() siteId: any;
  @Input() styleProp: any;
  @Input() popupState: any;

  userIndex: any = -1;
  popupType: number;
  setoverlay: String = '';
  firstname: any = '';
  lastname: any = '';
  email: any = '';
  phone: any = '';
  userId: any = -1;
  country: any;
  state: any = '';
  siteDetails: any;
  originContactId: any;
  updateMessage: any;
  company_id: number;
  passfeedback: any;
  passfeedback2: any;
  siteForm: FormGroup;
  getId: any;
  getValue: any;
  userFirstname: any;
  userLastname: any;
  userEmail: any;
  userPhone: any;
  getIndex: any = -1;
  fname: any;
  submitted = false;
  showClosebtn: any = false;
  userApiData: any = [];
  allCountryListData: any = [];
  allStatesListData: any = [];
  model: any = {};

  wellnessCheckEnable: any = false;
  wellnessConvertedTime: any;

  showWellnessComponent: any = false;
  enableInProduction: any = environment.config.enableInProduction;
  showWellnessInProd: any = false;
  companyId: any;

  setTimer: any;
  showSuccessMessage: any = false;

  wellnessLocaltimezone: any;
  siteVerified: any = false;
  
  newUser: any = 0;
  
  siteName: any;
  siteCountry: any;
  siteAddress1: any;
  siteAddress2: any;
  siteCity: any;
  siteState: any;
  siteZip: any;
  
  // NEW WELLNESS
  isShowWellness: boolean = false;
  firmwareSuccessMessageClass: any;
  firmwareSuccessMessage: any = '';
  enableWellnessCheckBox: any = false;
  allowSiteOverwrite: any = false;
  wellnessCheckTime: any = { hour: 1, minute: 30, second: 0 };
  wellnessCheckTimeWithColon: string = '01:30:00';
  wellnessInterval: any = '1';
  showWellnessSuccess: any;
  
  wellnessNameOfDay: any = "1";
  wellnessDate: any;
  
  

  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private router: Router,
    private ds: PopupTypeService,
    private formBuilder: FormBuilder,
    private getCompanyService: GetCompanyService,
    private sendPopupDataService: PopupSendDataService,
    private inputValidationService: InputValidationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.siteId && changes.siteId) {
      this.getSiteInfo();
      this.getUserApiData();
      this.getCountryData();
      this.getCompanyId();
    }
  }

  ngOnInit() {
    this.checkForm();
    this.initializedDate()
  }
  
  initializedDate = () => {
    let objectDate = new Date();
    let day = objectDate.getDate();
    let month = objectDate.getMonth();
    let year = objectDate.getFullYear();
    
    this.wellnessDate = `${year}-${month}-${day}`
  }
  

  getSiteInfo = () => {
    this.getCompanyService.apiGetCompanySiteInfo(this.siteId).subscribe((data: any) => {
      const [res] = data.response;
      this.siteDetails = res;
      this.siteVerified = res.collector_id != null;
      this.wellnessLocaltimezone = res.localUTC;
      
      this.setData();
      this.setWellnessSchedule(res);

      if (res.virtual) {
        this.getStatesData(res.country);
      } else {
        if (res.country && res.country != null) {
          this.getStatesData(res.country);
        }
      }

      this.setSiteContact(res.contact);
    });
  };
  
  setData = () => {
    const {  site_name, country , address1, address2, city,   state, zip } = this.siteDetails;
    this.siteName = site_name;
    this.siteCountry = country;
    this.siteAddress1 = address1;
    this.siteAddress2 = address2;
    this.siteCity = city;
    this.siteState = state;
    this.siteZip = zip;
    
    console.log('siteDetails: ',this.siteDetails)
  }

  setWellnessProd = (enableInProd, companyId) => {
    return enableInProd && (companyId == 15 || companyId == 36);
  };

  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.companyId = decodedToken.company_id;
  };

  async delay(ms: number) {
    await new Promise((resolve) => setTimeout(() => resolve(''), ms));
  }

  get f() {
    return this.siteForm.controls;
  }

  checkForm = () => {
    this.siteForm = this.formBuilder.group({
      sitename: ['', [Validators.required, Validators.maxLength(30)]],
      country: ['', [Validators.required, Validators.maxLength(30)]],
      address1: ['', [Validators.required, Validators.maxLength(50)]],
      address2: [''],
      city: [
        '',
        [
          Validators.required,
          Validators.pattern('[a-zA-Z ]*'),
          Validators.maxLength(30),
        ],
      ],
      state: ['', [Validators.required, Validators.maxLength(20)]],
      zip: ['', [Validators.required , Validators.maxLength(11) , Validators.minLength(3), this.inputValidationService.postalCodeValidator]],

      firstname: ['', [Validators.required, Validators.maxLength(30)]],
      lastname: ['', [Validators.required, Validators.maxLength(30)]],
      email: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(50)],
      ],
      phone: ['', [Validators.required, Validators.maxLength(25), this.inputValidationService.customPhoneValidator]],
      user_id: ['', [Validators.required, Validators.maxLength(30)]],
    });
  }

  sendPopupType = (p) => {
    this.ds.sendPopupType(p);
  }

  getUserApiData = () => {
    this.Auth.fetchContactList().subscribe((data) => {
      this.userApiData = data['response'];
      const arr = [];
      for (let d in this.userApiData) {
        arr.push(this.userApiData[d]);
      }
    });
  }
  
  onSubmit = () => {
    this.submitted = true;
    this.siteForm.updateValueAndValidity;
    if (this.siteForm.invalid) {
      return;
    } else {
      this.editSite();
      this.submitted = false;
    }
  }

  editSite = () => {
    this.wellnessConvertedTime = this.wellnessCheckTime;
    const propertyNames = Object.values(this.wellnessConvertedTime).join(':');
    this.wellnessConvertedTime = propertyNames;

    const registrationData = {
      company_id: this.companyId,
      site_id: this.siteId,
      new_user: this.newUser,
      data: {
        contact: {
          user_id: this.userId,
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phone: this.phone
        },
        site: {
          sitename: this.siteName,
          country: this.siteCountry,
          address1:  this.siteAddress1,
          address2:  this.siteAddress2,
          city:  this.siteCity,
          state:  this.siteState,
          zip:  this.siteZip,
          wellnessInterval: this.wellnessInterval,
          wellnessCheck: this.wellnessCheckEnable
            ? this.wellnessConvertedTime
            : null,
          wellnessDay: this.wellnessNameOfDay,
          wellnessDate: this.wellnessDate
        },
      },
    };
    
    console.log('wellnessCheckEnable: ', this.wellnessCheckEnable)
    console.log('registrationDataL ', registrationData)
    console.log('registrationDataL site ', registrationData['data'].site)
    
    
    this.getCompanyService.apiEditSite(registrationData).subscribe((data: any) => {
        this.passfeedback = <HTMLInputElement>(
          document.getElementById('feedback')
        );
        this.updateMessage = <HTMLInputElement>(
          document.getElementById('successMessage')
        );
        const [res] = data.response;
        
        if (res.status != 'Error') {
          this.passfeedback.innerHTML = '';
          this.showSuccessMessage = true;
          this.setTimer = setTimeout(() => {
            this.showSuccessMessage = false;
          }, 3000);
        } else {
          this.passfeedback.innerHTML = res.message;
        }
      });
  }

  onChangeWellnessCheckValue = (event) => {
    var value = 0;
    if (event?.target?.checked) {
      value = 1;
    }
    this.wellnessCheckEnable = value;
  };

  siteContactOnchange = (event) => {
    if(event == -1) {
      this.newUser = 1;
    } else {
      this.newUser = 0;
      const newArr = this.userApiData;
      const newContact = newArr[event];
      this.setSiteContact2(newContact);
    }
  };

  setSiteContact = (contact) => {
    const { email, firstname, lastname, phone, user_id } = contact;
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.phone = phone;
    this.userId = user_id;
  };

  setSiteContact2 = (contact) => {
    const { email, first_name, last_name, phone, user_id } = contact;
    this.firstname = first_name;
    this.lastname = last_name;
    this.email = email;
    this.phone = phone;
    this.userId = user_id;
  };
  
  setWellnessSchedule = ({schedule_interval, wellness_check_time, wellness_month, wellness_week}) => {
  
  
    this.wellnessInterval = schedule_interval || 1;
    this.wellnessNameOfDay = wellness_week || 1;
    this.wellnessDate = wellness_month;
  
    if (wellness_check_time) {
      if (wellness_check_time != '00:00:00') {
        this.wellnessCheckEnable = true;
        const wellnessTime = wellness_check_time;
        this.wellnessCheckTimeWithColon = wellness_check_time;
        let formatedTime = wellnessTime.split(':');
        this.wellnessCheckTime = {
          hour: parseInt(formatedTime[0]),
          minute: parseInt(formatedTime[1]),
          second: parseInt(formatedTime[2]),
        };
      }
    }
  };

  onWellnessTimeChange() {
    this.wellnessCheckTimeWithColon = Object.values(this.wellnessCheckTime).join(':')
  }

  getCountryData = () => {
    this.Auth.getAllCountryList().subscribe((data) => {
      this.allCountryListData = data['response'];
    });
  };

  setCountry = (n) => {
    this.getStatesData(n);
    (<HTMLInputElement>document.getElementById('zip')).value = '';
  };

  getStatesData = (countryNumber) => {
    this.Auth.getAllStatesList(countryNumber).subscribe((data: any) => {
      const res = data.response;
      this.allStatesListData = res;
    });
  };
  
 
  
  enableWellness = (event) => {
    this.enableWellnessCheckBox = event ? 1 : 0;
  };

  allowSiteOverwriteEvent = (event) => {
    this.allowSiteOverwrite = event ? 1 : 0;
  };

  setVideoWellnessCheck = () => {
    const formattedWellness = Object.values(this.wellnessCheckTime).join(':');

    // this.Auth.setVideoWellnessCheck(
    //   this.data.video_device_id,
    //   formattedWellness,
    //   this.wellnessInterval,
    //   this.enableWellnessCheckBox,
    //   this.allowSiteOverwrite
    // ).subscribe((data: any) => {
    //   console.log(data);
    //   const [res] = data.response;

    //   console.log('setVideoWellnessCheck: ', res);
    //   this.showWellnessSuccess = true;

    //   this.setTimer = setTimeout(() => {
    //     this.showWellnessSuccess = false;
    //   }, 1000);
    // });
  };
  
  intervalOnChange = (event: any) => {
    let wellnessInterval = event.target.value;
    if (wellnessInterval == 7) {
      console.log('show Days')
    }
    if (wellnessInterval == 30) {
      console.log('show Calendar')
    }
    
    this.wellnessInterval = wellnessInterval;
  };
  
   intervalEveryWeekOnChange = (event: any) => {
    let nameOfDays = event.target.value;
    this.wellnessNameOfDay = nameOfDays;
  };
  
 
  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

  ngOnDestroy() {
    this.ds.clearPopupType();
    this.clearTimeout();
  }
}
