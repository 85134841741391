import { Component, EventEmitter, Inject, OnInit, Optional, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: 'app-schedule-recurrence',
	templateUrl: './schedule-recurrence.component.html',
	styleUrls: ['./schedule-recurrence.component.scss'],
})
export class ScheduleRecurrenceComponent implements OnInit {
	form: FormGroup
	type: string = 'DAILY'
	typeList: string[] = ['DAILY', 'WEEKLY']
	dayList: any[] = [
		{
			day: 'M',
			isSet: false,
			number: 0
		},
		{
			day: 'T',
			isSet: false,
			number: 1
		},
		{
			day: 'W',
			isSet: false,
			number: 2
		},
		{
			day: 'T',
			isSet: false,
			number: 3
		},
		{
			day: 'F',
			isSet: false,
			number: 4
		},
		{
			day: 'S',
			isSet: false,
			number: 5
		},
		{
			day: 'S',
			isSet: false,
			number: 6
		},
	]
	@Output('submit') submit: EventEmitter<any> = new EventEmitter<any>()
	constructor(
		private dialogRef: MatDialogRef<ScheduleRecurrenceComponent>,
		@Inject(MAT_DIALOG_DATA) @Optional() public data: any,
		private _fb: FormBuilder
	) {}
	onClose(status: boolean) {
		this.dialogRef.close(status)
	}

	ngOnInit(): void {
		this.intializeForm()
	}
	intializeForm() {
		this.form = this._fb.group({
			type: ['DAILY', [Validators.required]],
			start_time: ['', [Validators.required]],
			end_time: ['', [Validators.required]],
		}, { validator: this.startTimeBeforeEndTimeValidator('start_time', 'end_time') })
	}
	onChange(event) {
		this.type = event?.value;
		if(this.type !== 'WEEKLY') this.dayList = this.dayList.map((x)=>{x.isSet = false;return x;});
	}
	submitSchedule(){
		const data = {
			...this.form.value,
			...(this.type === 'WEEKLY') &&{
				schedule_days : this.Days
			}
		}
		this.submit.emit(data);
		this.onClose(false);
	}
	get isDaySelect() : boolean {
		return this.dayList.some((x)=>x.isSet === true);
	}
	get Days(): number[] {
		const arr = [];
		for (let d of this.dayList){
			if(d.isSet){
				arr.push(d.number);
			}
		}
		return arr;
	}
	hasError(controlName: string, errorName: string): boolean {
		const control = this.formControls[controlName];
		return control && control.touched && control.hasError(errorName);
	}
	hasFormError(errorName: string): boolean {
		return this.form.hasError(errorName) && this.form.touched;
	}
	get formControls(){
		return this.form.controls;
	}
	startTimeBeforeEndTimeValidator(startKey: string, endKey: string): ValidatorFn {
		return (formGroup: AbstractControl): ValidationErrors | null => {
		  const startControl = formGroup.get(startKey);
		  const endControl = formGroup.get(endKey);
	  
		  if (startControl && endControl) {
			const startTime = startControl.value;
			const endTime = endControl.value;
	  
			if (startTime && endTime) {
			  const [startHours, startMinutes] = startTime.split(':').map(Number);
			  const [endHours, endMinutes] = endTime.split(':').map(Number);
	  
			  const startTotalMinutes = startHours * 60 + startMinutes;
			  const endTotalMinutes = endHours * 60 + endMinutes;
	  
			  if (startTotalMinutes >= endTotalMinutes) {
				return { startTimeBeforeEndTime: true };
			  }
			}
		  }
	  
		  return null;
		};
	  }
}
