<div class="collector-info">
  <div class="collector-info__site-img">

    <img src="{{companyLogo}}" />
  </div>

  <!-- <div class="collector-info__user-img">
    <img [src]="getUserProfilePicture('test')" alt="Cloud User" />
  </div> -->

  <div class="collector-info__text">
    <div class="collector-info__text-item">
      <strong>Host:</strong> {{collectorDetails?.host_name}}
    </div>
    <div class="collector-info__text-item">
      <strong>Site:</strong> {{collectorDetails?.site_name}}
    </div>
    <div class="collector-info__text-item">
      <strong>IP address:</strong> {{collectorDetails?.ip_address}}
    </div>
    <div class="collector-info__text-item">
      <strong>MAC address:</strong> {{collectorDetails?.mac_address}}
    </div>
  </div>

  <div class="collector-info__button-container">
    <div class="collector-info__uptime-button-container">
      <div class="collector-info__uptime">
        <app-info-uptime [uptimeDays]="uptimeDays" [uptimeHours]="uptimeHours"
          [uptimeMins]="uptimeMins"></app-info-uptime>
      </div>
      <div class="collector-info__gray-button-container">
        <app-btn-info label="admin" [faName]="faIcon" (click)="openCollectorAdmin()"></app-btn-info>
        <app-btn-info label="{{ collectorDetails?.paused === 1 ? 'unpause' : 'pause' }}"
          [faName]="collectorDetails?.paused == 1 ? faPauseIcon : faPlayIcon" (click)="onCollectorPauseOrUnpause()">
        </app-btn-info>
        <app-btn-info label="reboot" [faName]="faIcon" (click)="openCollectorReboot()"></app-btn-info>
      </div>
    </div>


    <div class="collector-info__button--" [ngClass]="{
        'collector-info__button-online': collectorStatus === 'Online',
        'collector-info__button-offline': collectorStatus === 'Offline',
        'collector-info__button-warning': collectorStatus === 'Warning',
        'collector-info__button-loading': collectorStatus === 'Loading',
        'collector-info__button-pause': collectorStatus === 'Paused'
      }">
      {{ collectorStatus }}
    </div>
    <hr style="margin: 15px 0px">
    <div class="collector-info__network-utilization">
    </div>
  </div>

  <div class="collector-info-outer">
    <app-collector-console [data]="collectorDetails" [originationIp]="collectorDetails?.ip_address">
    </app-collector-console>
  </div>
</div>