<div class="container-fluid py-5 main-row">
  <form>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">room name</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="room-name" required>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail" class="col-sm-2 col-form-label">email address</label>
      <div class="col-sm-10">
        <input type="email" class="form-control" id="email" required>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">type of room</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="room-type" required>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label"></label>
      <div class="col-sm-10 col-offset-2">
        <button type="submit" class="btn btn-sm">submit</button>
      </div>
    </div>
  </form>
</div>