import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  AfterViewInit
} from '@angular/core';

import * as Chart from 'chart.js';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { PopupTypeService } from '../../services/popupType.service';
import { RefreshApiService } from '../../services/refresh-api.service';
import { Subscription } from 'rxjs';
import { GetTeamsService } from '../../services/get-teams.service';


@Component({
  selector: 'app-chart-teams-mbps',
  templateUrl: './chart-teams-mbps.component.html',
  styleUrls: ['./chart-teams-mbps.component.scss']
})
export class ChartTeamsMbpsComponent implements OnChanges, AfterViewInit {

  canvas: any;
  ctx: any;
  myChartSite: any;
  id: number;
  chartApiData: any = [];
  currentChart = '60m';
  filterListner: Subscription;

  @Input() teamsDetails: any;

  chartData = {
    datasets: [
      {
        label: 'Mbps',
        data: [],
        backgroundColor: ['rgba(0, 128, 255, .5)'],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)', //red
        borderWidth: 0,
        stepped: true,
      },
    ],
  };

  constructor(
    private route: ActivatedRoute,
    private ds: PopupTypeService,
    public http: HttpClient,
    private Auth: AuthService,
    private refreshApiService: RefreshApiService,
    private getTeams: GetTeamsService
  ) {
    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });
  }

  ngAfterViewInit() {
    this.canvas = document.getElementById('myChartSite');
    this.ctx = this.canvas.getContext('2d');
    this.myChartSite = new Chart(this.ctx, {
      type: 'line',
      // data: this.data24h,
      options: {
        responsive: true,
        display: true,
        fontSize: '6px',
        padding: '0px',
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0, // disables bezier curves
          },
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, chart) {
              const d = new Date(tooltipItem[0].label);
              return d.toLocaleTimeString();
            },
          },
        },
      },
    });
    this.currentChart = '50m';
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.teamsDetails && this.teamsDetails) {
      this.setData();
    }
  }

  setData = () => {

    const { id, liveMeetings } = this.teamsDetails
    if (liveMeetings) {
      this.fetchChartData(id);
    }
  }

  fetchChartData = (id) => {
    this.getTeams.getUserTeamsMbps(id, 24).subscribe((data: any) => {
      const [newData] = data.response
      const { mbps_total } = newData
      // // console.log('mbps total: ', newData)

      if (mbps_total) {
        let mbps = mbps_total.map((d) => {
          if (d.y === 0) {
            delete d.y;
          }
          return d;
        });


        mbps.sort(this.custom_sort);




        this.chartData.datasets[0].data = mbps;
        this.loadChart();
      }
    }, (error) => {
      // console.log('getUserTeamsMbps error', error)
    })
  }

  custom_sort = (a, b) => {
    return new Date(a.x).getTime() - new Date(b.x).getTime();
  }

  loadChart = () => {
    this.myChartSite.reset();
    this.myChartSite.data.datasets = this.chartData.datasets;
    this.myChartSite.options.scales = {
      xAxes: [
        {
          type: 'time',
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Time',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    };
    this.myChartSite.update({ duration: 1000 });
  }

  ngOnDestroy() {
    if (this.myChartSite) {
      this.myChartSite.destroy();
    }
    // this.filterListner.unsubscribe();
  }

}
