<h4 class="text-center mt-2 mb-0">
  {{ listTitle }}
</h4>
<table class="table table-hover table-sm bg-white mt-2 mb-2" style="padding-bottom:100px;">
  <thead style="border:hidden;">
    <tr>
      <th class="text-left">{{ col1Title }}</th>
      <th class="text-center">{{ listColTitle }}</th>
      <th class="text-center">{{ trendTitle }}</th>
    </tr>
  </thead>
  <tbody>
    
    <tr scope="row" *ngFor="let allsites of allSiteList | slice:0:5" style="background:none;">
      
      <td class="text-left">{{allsites.site_name}}</td>

      <td *ngIf="reportType==1" class="text-center">
        <h2 *ngIf="allsites.incidents==null">{{allsites}} null</h2>
        <h5 style="border: 2px solid gray;border-radius:15px;">{{allsites.incidents}}</h5>
      </td>
      <td *ngIf="reportType==2" class="text-center">
        <h2 *ngIf="allsites.calls==null">{{allsites}} null</h2>
        <h5 style="border: 2px solid gray;border-radius:15px;">{{allsites.calls}}</h5>
      </td>
      <td *ngIf="reportType==3" class="text-center">
        <h2 *ngIf="allsites.uptime==null">{{allsites}} null</h2>
        <h5 style="border: 2px solid gray;border-radius:15px;">{{allsites.uptime == "--"?allsites.uptime:secToTime(allsites.uptime)}}</h5>
      </td>
  
      <td *ngIf="reportType==1">
        <ng-template [ngIf]="allsites.trend<0">
          <div class="trend-arrow trend-arrow-red">
            <img src="../../../../assets/img/tri-red.png">
          </div>
        </ng-template>
        <ng-template [ngIf]="allsites.trend>=1">
          <div class="trend-arrow trend-arrow-green-down">
            <img src="../../../../assets/img/tri-green.png">
          </div>
        </ng-template>
        <ng-template [ngIf]="allsites.trend==0">
          <div class="trend-arrow trend-arrow-grey">
            <p> -- </p>
          </div>
        </ng-template>
        <ng-template [ngIf]="allsites==false">
          <div class="trend-arrow trend-arrow-red">
            <img src="../../../../assets/img/tri-yellow.png">
          </div>
        </ng-template>
      </td>
      <td *ngIf="reportType==2">
        <ng-template [ngIf]="allsites.trend<0">
          <div class="trend-arrow trend-arrow-red-down-calls">
            <img src="../../../../assets/img/tri-red.png">
          </div>
        </ng-template>
        <ng-template [ngIf]="allsites.trend>=1">
          <div class="trend-arrow trend-arrow-green">
            <img src="../../../../assets/img/tri-green.png">
          </div>
        </ng-template>
        <ng-template [ngIf]="allsites.trend==0">
          <div class="trend-arrow trend-arrow-grey">
              <p> -- </p>
          </div>
        </ng-template>
        <ng-template [ngIf]="allsites==false">
          <div class="trend-arrow trend-arrow-red">
            <img src="../../../../assets/img/tri-yellow.png">
          </div>
        </ng-template>
      </td>
    </tr>
  </tbody>
</table>