export enum IncidentType {
	VIDEO = 'VIDEO',
	AUDIO = 'AUDIO',
	CLOUD = 'CLOUD',
	DOMOTZ_IOT = 'DOMOTZ_IOT',
}

export class IncidentMessage {
	message_id?: number
	incident_id?: number
	incidentType: IncidentType
	user: {
		user_id: number
		first_name: string
		last_name: string
	}
	message: string
	attachments?: {
		document_id: number
		file_name: string
		file_path: string
	}[]
	created_at: Date
	edited_by?: {
		user_id: number
		first_name: string
		last_name: string
	}
	edited_at?: Date

	constructor(obj: IncidentMessage) {
		Object.assign(this, obj)
	}
}

import { Injectable, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class IncidentMessagesService {
	constructor(private http: HttpClient) {}

	getMessages(incidentId: number, incidentType: IncidentType): Observable<IncidentMessage[]> {
		const url = environment.serverUrl + `/api/v1/messages/incident/${incidentType}/${incidentId}`
		return this.http.get<IncidentMessage[]>(url).pipe(
			map(messages => {
				return messages.map(message => new IncidentMessage(message))
			})
		)
	}

	// createMessage(incidentId: number, message: string): Observable<number> {
	// 	const url = environment.serverUrl + `/api/v1/messages/incident/${incidentId}`
	// 	return this.http.post<number>(url, { message })
	// }
	createMessage(incidentId: number, incidentType: IncidentType, message: FormData): Observable<IncidentMessage> {
		const url = environment.serverUrl + `/api/v1/messages/incident/${incidentType}/${incidentId}`
		return this.http.post<IncidentMessage>(url, message)
	}

	updateMessage(updatedMessage: FormData): Observable<IncidentMessage> {
		const url = environment.serverUrl + `/api/v1/messages`
		return this.http.put<IncidentMessage>(url, updatedMessage)
	}
}
