<div class="chart-teams-dna">
  <div class="chart-teams-dna__print">
    <i class="fa fa-print fa-2x" (click)="print()"></i>
  </div>

  <div class="chart-teams-dna__button-switch-container">
    <div class="chart-teams-dna__button-container">
      <button (click)="set60m()" class="chart-teams-dna__time-btn"  [ngClass]="{ 'chart-teams-dna__time-btn--active': activeBtn == 1 }">
        60m
      </button>
      <button (click)="set24h()" class="chart-teams-dna__time-btn" [ngClass]="{ 'chart-teams-dna__time-btn--active': activeBtn == 2 }">
        24h
      </button>
      <button (click)="set7d()" class="chart-teams-dna__time-btn"  [ngClass]="{ 'chart-teams-dna__time-btn--active': activeBtn == 3 }">
        7d
      </button> 
    </div>


    <!-- <div class="chart-teams-dna__switch-container">
      <div>list</div>
      <label class="switch">
        <input type="checkbox" [checked]="toggleValue" (click)="toggleView(1)" />
        <span class="slider round">
        </span>
      </label>
      <div>chart</div>
    </div> -->
  </div>

  <!-- ill change this id -->
  <div  class="chart-teams-dna__canvas" id="print_this">
    <canvas id="myChartSite" width="1400px" height="450px" [ngStyle]="{ display: !toggleValue ? 'block' : 'none' }" ></canvas>
  </div>

  <div
    *ngIf="toggleValue"
  >
    <div class="row">
      <!-- <app-cloud-all-incidents-list
        [data]="data"
        [cloudInsListData]="printTableData"
        [cloudInsTableSettings]="cloudInsTableSetting"
      ></app-cloud-all-incidents-list> -->
        <app-teams-chart-incident-list></app-teams-chart-incident-list>
    </div>
  </div>
</div>



<!-- 
<div class="info-chartSite pt-3 mb-3 chart-container" style="cursor: auto !important;">
  <div class="print-btn">
    <i class="fa fa-print fa-2x menu-icon" (click)="print()"></i>
  </div>

  <div style="text-align: center;">
    <button (click)="set60m()" [ngClass]="{ 'btnpressed': activeBtn == 1 }">
      60m
    </button>
    <button (click)="set24h()" [ngClass]="{ 'btnpressed': activeBtn == 2 }">
      24h
    </button>
    <button (click)="set7d()" [ngClass]="{ 'btnpressed': activeBtn == 3 }">
      7d
    </button>
     <div style="padding-left: 50px !important; display: inline;">
      <label class="switch">
        <input type="checkbox" [checked]="toggleValue == 0" (click)="toggleView(1)" />
        <span class="slider round">
          <span class="paused">chart</span>
          <span class="enabled" style="padding-right: 200px;">list</span>
        </span>
      </label>
    </div> 
  </div>


  <div id="print_this" style="margin-left: 101px; margin-top: 25px">
    <canvas id="myChartSite" width="1150px" height="350px" [ngStyle]="{ display: toggleValue == 0 ? 'block' : 'none' }"
    ></canvas>
  </div>

  <div
    class="list-view"
    *ngIf="toggleValue == 1"
    style="display: none; cursor: pointer !important;"
    [ngStyle]="{ display: toggleValue == 1 ? 'block' : 'none' }"
  >
    <div class="row">
      <app-cloud-all-incidents-list
        [data]="data"
        [cloudInsListData]="printTableData"
        [cloudInsTableSettings]="cloudInsTableSetting"
      ></app-cloud-all-incidents-list>
    </div>
  </div>

</div> -->
