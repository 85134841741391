import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { Router } from '@angular/router'

import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-old-forgot-password',
  templateUrl: './old-forgot-password.component.html',
  styleUrls: ['./old-forgot-password.component.scss'],
})
export class OldForgotPasswordComponent implements OnInit, OnChanges {
  @Output() public data = new EventEmitter<{ email: string }>();
  @Input() message: any;
  @Input() message2: any;
  setTimer: any;
  dashboardLogo = environment.config.dynamicImage.dashboardLogo;
  email: string = '';

  formControl: any;

  links = environment.config.links;
  dTerms = environment.config.dynamicText.dTerms;
  backgroundImage = environment.config.dynamicImage.bgImage;

  visibilityBackground = {
    'background-image': 'url(' + this.backgroundImage + ')',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
    'background-attachment': 'fixed',
    'background-size': 'cover',
    height: '100vh',
  };

  startValidateEmail: boolean = false;
  disableBtn: boolean = false;

  constructor(public http: HttpClient, private Auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.setFormControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.message) {
    }
  }

  setFormControl = () => {
    const deepCopy = {
      email: {
        flag: false,
        style: 'default',
        message: '',
      },
    };

    this.formControl = { ...deepCopy };
  };

  validateForm = () => {
    const validation = this.validate();

    if (validation) {
      this.emitEvent();
    } else {
    }
  };

  validate = () => {
    let errCount = 0;
    this.setFormControl();
    if (this.isEmpty(this.email)) {
      this.startValidateEmail = true;
      this.setErrorMessageAndStatus('email', true, 'error', 'Required Field');
      errCount++;
    } else if (!this.validateEmail(this.email)) {
      this.startValidateEmail = true;
      this.setErrorMessageAndStatus('email', true, 'error', 'format (ex. email@domain.com) ');
      errCount++;
    }
    this.disableBtn = errCount !== 0;
    return errCount === 0;
  };

  isEmpty = (params) => {
    if (params.length === 0) {
      return true;
    }
    return false;
  };

  setErrorMessageAndStatus = (propname, bool, style, message) => {
    this.formControl[propname].flag = bool;
    this.formControl[propname].style = style;
    this.formControl[propname].message = message;
  };

  emailKeyEvent = (events) => {
    if (this.startValidateEmail) {
      this.validate();
    }
  };

  validateEmail = (email) => {
    // tslint:disable-next-line: max-line-length
    const regexEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEx.test(String(email).toLowerCase());
  };

  emitEvent = () => {
    this.data.emit({ email: this.email });
  };
  
  routeToHomePage = () => {
    this.router.navigate(['login']);
  }
}
