import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GetVideoService } from '../../../services/get-video.service';
import { RefreshApiService } from '../../../services/refresh-api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-video-main',
  templateUrl: './video-main.component.html',
  styleUrls: ['./video-main.component.scss'],
})
export class VideoMainComponent implements OnInit, OnDestroy {
  @Input() videoDeviceData: any;
  deviceId: number;
  companyId: number;
  filterListner: Subscription;
  displayStringForTesting: string;
  isShowContent = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getVideoDataService: GetVideoService,
    private refreshApiService: RefreshApiService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.deviceId = params['id'];
    });

    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.companyId = decodedToken.company_id;

    this.getVideoApiData();
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if (m) {
        this.getVideoApiData();
      }
    });
  }

  getVideoApiData() {
    this.getVideoDataService.apiGetVideoDeviceDetails(this.deviceId).subscribe((data) => {
      this.videoDeviceData = data['response'][0];
      this.displayStringForTesting = JSON.stringify(this.videoDeviceData);
      this.isShowContent = true;
    });
  }

  triggerEvent($event) {
    if ($event === 'refreshDeviceDetails') {
      this.getVideoApiData();
    }
  }

  ngOnDestroy() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
  }
}
