import { trigger, state, style, transition,
    animate, group, query, stagger, keyframes
} from '@angular/animations';

export const PopupQosAnimation = [
      trigger('PopupQosSlide', [
        state('hide', style({
          // display: 'none'
        })),
        state('show', style({
          float: 'right',
          // width: '40%',
          display: 'block'
        })),
        transition('hide => show', animate('300ms ease-in-out')),
        transition('show => hide', animate('300ms ease-in-out'))
      ]),
]
