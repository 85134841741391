import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetIotService } from '../../../services/get-iot.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-webportal-protocol-config-dialog',
  templateUrl: './webportal-protocol-config-dialog.component.html',
  styleUrls: ['./webportal-protocol-config-dialog.component.scss'],
})
export class WebportalProtocolConfigDialogComponent {
  deviceId: number;
  isLoading = false;
  isShowConnectingText = false;
  btnLoader: string = environment.config.dynamicImage.loader;
  enableSsh = false;
  enableRdp = false;
  enableHttp = false;
  enableHttps = false;
  iotEnabled = false;
  ipV4: any;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<WebportalProtocolConfigDialogComponent>,
    private getIot: GetIotService
  ) {
    if (data) {
      this.deviceId = data.iotId;
      this.iotEnabled = data.iotEnabled
      // console.log(this.deviceId, 'deviceid')

      this.enableSsh = false;
      this.enableRdp = false;
      this.enableHttp = false;
      this.enableHttps = false;
      
      this.addConnectionTypes(this.deviceId);
    }
  }

  onCancel = () => {
    this.dialogRef.close(true);
  };

  onHttps = () => {
    if(this.iotEnabled) {
      if (this.enableHttps) {
        this.isLoading = true;
        let webportal = `https://${this.ipV4}`
        window.open(webportal, '_blank');
      }
    } else {
      if (this.enableHttps) {
        this.isLoading = true;
        this.openWebPortal(this.deviceId, 'https', 443);
      }
    }
  };

  onHttp = () => {
    if(this.iotEnabled) {
      if (this.enableHttp) {
        this.isLoading = true;
        let webportal = `http://${this.ipV4}`
        window.open(webportal, '_blank');
      }
    } else {
      if (this.enableHttp) {
        this.isLoading = true;
        this.openWebPortal(this.deviceId, 'http', 80);
      }
    }
  };

  onSsh = () => {
    if (this.enableSsh) {
      this.isLoading = true;
      this.openWebPortal(this.deviceId, 'ssh', 22);
    }
  };

  onRdp = () => {
    if (this.enableRdp) {
      this.isLoading = true;
      this.openWebPortal(this.deviceId, 'rdp', 3389);
    }
  };

  openWebPortal = (deviceId, protocol, port) => {
    this.getIot.getDeviceWebPortalUrl(deviceId, protocol, port).subscribe(
      (data) => {
        const webportal = data['link'];
        if (webportal) {
          setTimeout(() => {
            this.isLoading = false;
            window.open(webportal, '_blank');
            this.dialogRef.close(true);
          }, 10000);
        } else {
          this.isLoading = false;
          alert('No web portal.');
          this.dialogRef.close(true);
        }
      },
      (err) => {
        this.isLoading = false;
        alert('Unknown error happened in connecting to the device.');
      }
    );
  };

  addConnectionTypes = (deviceId) => {
    this.isLoading = true;
    this.isShowConnectingText = false;
    this.getIot.getIotDeviceDetails(deviceId).subscribe(
      (data) => {
        const { other_data, iot_ip } = data;
        this.ipV4 = iot_ip;
        const oData = JSON.parse(other_data);
        
        
        if (other_data) {
          if(this.iotEnabled) {
            this.getIotPorts(oData);
          } else {
          const ipPorts = oData.ip_ports ? oData.ip_ports : [];
            this.getDomotzPorts(ipPorts);
          }
          
        }
        this.isLoading = false;
        this.isShowConnectingText = true;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  };
  
  getIotPorts = (ports) => {
    for (let x = 0; x < ports.length; x++) {
        if (ports[x].portid == 80) {
          this.enableHttp = true;
        } else if (ports[x].portid == 443) {
          this.enableHttps = true;
        } 
    }
  }
  
  
  getDomotzPorts = (ports) => {
    console.log('yeah')
    let protocols = Object.keys(ports);
    for (let x = 0; x < protocols.length; x++) {
      let protocolPorts = ports[protocols[x]];
      for (let y = 0; y < protocolPorts.length; y++) {
        if (protocolPorts[y] === 80) {
          this.enableHttp = true;
        } else if (protocolPorts[y] === 443) {
          this.enableHttps = true;
        } else if (protocolPorts[y] === 22) {
          this.enableSsh = true;
        } else if (protocolPorts[y] === 3389) {
          this.enableRdp = true;
        }
      }
      
      console.log(this.enableHttp)
      console.log(this.enableHttps)
    }
  }
}
