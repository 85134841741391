import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { GetVideoService } from '../../../services/get-video.service';
import { TableFilterService } from '../../../services/table-filter.service'
import { WholeNumberPipe } from 'src/app/pipes/whole-number.pipe';
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-video-alldevices-list',
  templateUrl: './video-alldevices-list.component.html',
  styleUrls: ['./video-alldevices-list.component.scss']
})
export class VideoAlldevicesListComponent implements OnInit, OnDestroy {
  
  // @Input() videoListData: any;
  @Input() videoDevicesListData:MatTableDataSource<any>;
  // Settings and Parameters for the the Smart Filter Tables
  videoDeviceTableSettings =  this.Auth.accessLevel == "SUPERADMIN"?this.getVideoDataService.videoDeviceTableSettingsSuperAdmin:this.getVideoDataService.videoDeviceTableSettings;
 
  filterListner: Subscription;

  constructor(private route: ActivatedRoute, private router: Router,
    private getVideoDataService: GetVideoService,
    private tableFilterService: TableFilterService,
    private Auth: AuthService) {
    this.filterListner = this.tableFilterService.listen().subscribe((m: any) => {
      this.onFilter(m);
    });
  }

  loading: boolean = false;
  ngOnInit() {
    this.onLoadFilter();
    //GET request for audio users
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }
  // This function handles the routing for when a table row is clicked
 
  // This function filters the list when certain Live Stat Buttons are clicked
  onFilter(event) {
    const query: string = event[0];
    const cat: string = event[1];
    const pausedQuery: string = '0'
    const modifier: string = event[2] || "";

    // These are the filter settings
    // if (this.router.url == '/video/pauseddevices') {
    //   this.videoListData.setFilter([
    //     {
    //       field: cat,
    //       search: query,
    //       filter: (value: any, query: string) => {
    //         return +value == +query;
    //       }
    //     },
    //   ], false);
    // } else {
    //   if (!query) {
    //     this.videoListData.setFilter([]);
    //   } else {
    //     this.videoListData.setFilter([
    //       {
    //         field: cat,
    //         search: query,
    //         filter: (value: any, query: string) => {
    //           if (modifier === "midHealth") {
    //             return 31 < +value && +value < +query
    //           }
    //           if (modifier === "onlyHealthy") {
    //             return +value >= +query;
    //           }
    //           if (cat == "health") {
    //             return +value < +query;
    //           } else if (cat == 'oncall') {
    //             return +value >= +query;
    //           } else if (cat == 'paused') {
    //             return +value == +query;
    //           }
    //         }
    //       },
    //       {
    //         field: 'paused',
    //         search: pausedQuery,
    //         filter: (value: any, pausedQuery: string) => {
    //           return +value == +pausedQuery;
    //         }
    //       }
    //     ], true);
    //   }
    // }
  }

  onLoadFilter() {
    if (this.router.url == '/video/impacteddevices') {
      let arr = ['31', 'health']
      this.onFilter(arr);
    } else if (this.router.url == '/video/warningdevices') {
      let arr = ['76', 'health', "midHealth"]
      this.onFilter(arr);
    } else if (this.router.url == '/video/pauseddevices') {
      let arr = ['1', 'paused']
      this.onFilter(arr);
    } else if (this.router.url == '/video/healthydevices') {
      let arr = ['76', 'health', 'onlyHealthy']
      this.onFilter(arr);
    }
  }

}
