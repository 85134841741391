<div *ngIf="isLoading" style="height: 600px; margin-left: -80px !important">
  <app-loader></app-loader>
</div>
<div class="wrap" *ngIf="!isLoading">
  <div class="row justify-center">
    <div class="form-group" style="width: 10%; margin-right: 1%" *ngIf="currentChart === '7d'">
      <label for="startDate">Start Date</label>
      <div class="input-group">
        <input class="form-control" name="d1" [(ngModel)]="searchObject.startDate" ngbDatepicker [minDate]="minDate"
          [maxDate]="maxDate" id="startDate" #d1="ngbDatepicker" />
        <button class="btn btn-outline-secondary fa fa-calendar" (click)="d1.toggle()" type="button"></button>
      </div>
    </div>
    <div class="form-group w-12" style="margin-right: 1%">
      <label for="startTime">Start Time</label>
      <ngb-timepicker [(ngModel)]="searchObject.startTime" id="startTime" [spinners]="showSpinner"
        [meridian]="true"></ngb-timepicker>
    </div>
    <div class="form-group" style="width: 10%; margin-right: 1%" *ngIf="currentChart === '7d'">
      <label for="endDate">End Date</label>
      <div class="input-group">
        <input class="form-control" name="d2" [(ngModel)]="searchObject.endDate" ngbDatepicker [minDate]="minDate"
          [maxDate]="maxDate" id="endDate" #d2="ngbDatepicker" />
        <button class="btn btn-outline-secondary fa fa-calendar" (click)="d2.toggle()" type="button"></button>
      </div>
    </div>
    <div class="form-group w-12" style="margin-right: 1%">
      <label for="endTime">End Time</label>
      <ngb-timepicker [(ngModel)]="searchObject.endTime" id="endTime" [spinners]="showSpinner"
        [meridian]="true"></ngb-timepicker>
    </div>
    <div class="form-group w-17" style="margin-right: 1%">
      <label for="search_input">Search...</label>
      <input class="form-control" id="search_input" #myInputFilter type="text" [(ngModel)]="searchObject.searchValue" />
    </div>
    <div class="form-group w-10" style="margin-right: 1%">
      <label for="incident_id">Incident ID</label>
      <input class="form-control" id="incident_id" #myInputIncidentFilter type="text"
        [(ngModel)]="searchObject.searchIncident" />
    </div>
    <div class="form-group w-5" style="margin-right: 1%; margin-top: 39px">
      <button type="button" style="
          width: 100%;
          background: #4a81c7 !important;
          color: white;
          border-radius: 5% !important;
          font-size: 18px;
        " (click)="submit()">
        Submit
      </button>
    </div>
    <div class="form-group" style="width: 5%; margin-top: 39px">
      <button type="button" style="width: 100%; border-radius: 5% !important; font-size: 18px" (click)="clearFilters()">
        Clear
      </button>
    </div>
  </div>
  <cdk-virtual-scroll-viewport itemSize="0" style="height: 600px">
    <br />
    <table class="head">
      <tr style="font-weight: bold">
        <td class="w-10">Time Stamp</td>
        <ng-container *ngFor="let flow of columnsFlow.headers">
          <td class="w-10 details">{{ flow }}</td>
          <td class="w-5 details">Rx</td>
          <td class="w-5 details">Tx</td>
        </ng-container>
        <td class="w-10">Incidents Id</td>
      </tr>
    </table>
    <div class="inner_table">
      <table *ngFor="
          let data of videoInsData
            | videoIncidentsFilter : searchObject : currentChart : applyFilter
        " style="border-bottom-style: solid; border-bottom-width: 1px"
        [style.color]="data.incident_id ? 'red' : 'black'">
        <tr>
          <td class="w-10">
            {{ data.timestamp | date : "MM-dd-yyyy, hh:mm a" }}
          </td>
          <ng-container *ngFor="let flow of columnsFlow.cols">
            <td class="w-9 details">
              {{ data.totalSectionData[flow] | longnumbers }}
            </td>
            <td class="w-5 details">
              {{ data.totalRx[flow] | longnumbers }}
            </td>
            <td class="w-5 details">
              {{ data.totalTx[flow] | longnumbers }}
            </td>
          </ng-container>
          <td class="w-9">{{ data.incident_id }}</td>
        </tr>
        <tr>
          <td>Video</td>
          <ng-container *ngFor="let flow of columnsFlow.cols">
            <td class="details">{{ data.videoTotal[flow] | longnumbers }}</td>
            <td class="details">{{ data.videoRx[flow] | longnumbers }}</td>
            <td class="details">{{ data.videoTx[flow] | longnumbers }}</td>
          </ng-container>
          <td></td>
        </tr>
        <tr>
          <td>Audio</td>
          <ng-container *ngFor="let flow of columnsFlow.cols">
            <td class="details">{{ data.audioTotal[flow] | longnumbers }}</td>
            <td class="details">{{ data.audioRx[flow] | longnumbers }}</td>
            <td class="details">{{ data.audioTx[flow] | longnumbers }}</td>
          </ng-container>
          <td></td>
        </tr>
        <tr>
          <td>Content</td>
          <ng-container *ngFor="let flow of columnsFlow.cols">
            <td class="details">{{ data.contentTotal[flow] | longnumbers }}</td>
            <td class="details">{{ data.contentRx[flow] | longnumbers }}</td>
            <td class="details">{{ data.contentTx[flow] | longnumbers }}</td>
          </ng-container>
          <td></td>
        </tr>
      </table>
    </div>
  </cdk-virtual-scroll-viewport>
</div>