<div class="latestevent">
  <div class="latestevent__title_btn_ctr">
    <div class="latestevent__title">
      <h5>30 Days Event List</h5>
    </div>
    <div class="latestevent__btn {{ dynamicClass }}" (click)="viewMore()">
      View More
    </div>
  </div>

  <div class="latestevent__box">
    <div *ngFor="let item of lastEventForFilter">
      <div class="latestevent__box_item {{
          item.type !== 'DOWN' ? 'latestevent__box_item--success' : 'latestevent__box_item--error'
        }} ">
        <div class="latestevent__box_item_ctr">
          <div class="latestevent__box_item_title  {{
              item.type !== 'DOWN'
                ? 'latestevent__box_item_title--success'
                : 'latestevent__box_item_title--error'
            }} ">
            {{ determineStringToDisplay(item.type) }}
          </div>
          <div class="latestevent__box_item_date">
            {{ formatDate(item.timestamp) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>