import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IntegratorService } from '../services/integrator.service';

import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class GetDesktopPluginService {
  getUrlPath: Function = environment.apiServicePathBuilder('desktop-plugin');
  // getUrlPath: Function = environment.apiServicePathBuilder('dev');
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient, private integrator: IntegratorService) { }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const { company_id } = helper.decodeToken(token);
    return company_id;
  };

  createAction(desktop_plugin_id: number, action_params: any, action_name: string) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let postData = {
      desktop_plugin_id,
      action_params,
      action_name,
      user_id: decodedToken.user_id,
    };
 
 
    const url: string = this.getUrlPath('createAction');
    return this.http.put<any>(url, postData,  this.httpOptions);
  }

  getDesktopPluginAction(action_id: number) {
    const url: string = this.getUrlPath(`getActionById/${action_id}`);
    return this.http.get<any>(url)
  }

  getActionListByPluginId(desktop_plugin_id: number, action: string) {
    const url: string = this.getUrlPath(`getActionListByPluginId/${desktop_plugin_id}/${action}`);
    return this.http.get<any>(url)
  }

  getDesktopPluginList() {
    const company_id = this.integrator.isIntegrator()
      ? this.getCompanyId() * -1
      : this.getCompanyId();
    const url: string = this.getUrlPath(`getDesktopPluginList/${company_id}`);
    return this.http.get<any>(url)
  }

  getUsersToLink(postData) {
    const company_id = this.integrator.isIntegrator() ? this.getCompanyId() * -1 : this.getCompanyId();

    const data = {
      company_id,
      ...postData
    }

    const url: string = this.getUrlPath(`getUsersToLink/`);
    return this.http
      .post<any>(url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  linkDesktopPlugin({ desktop_plugin_id, zoom_user_id, service_type }) {
    const _url: string = this.getUrlPath('link');
    const data = {
      desktop_plugin_id,
      zoom_user_id,
      service_type,
    };
    return this.http
      .post<any>(_url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }

  unlinkDesktopPlugin(desktop_plugin_id, service_type) {
    const _url: string = this.getUrlPath('unlink');
    const data = {
      desktop_plugin_id,
      service_type,
    };
    return this.http
      .post<any>(_url, data, this.httpOptions)
      .pipe(map(this.extractData))
  }


  //why is this the same?
  downloadDesktopClient() {
    const company_id = this.getCompanyId();
    const url: string = this.getUrlPath(`download/${company_id}`);
    return this.http.get<any>(url);
  }

  downloadPlugin() {
    const company_id = this.getCompanyId();
    const url: string = this.getUrlPath(`downloadPlugin/${company_id}`);
    return this.http.get<any>(url);
  }

  downloadMacPlugin() {
    const company_id = this.getCompanyId();
    const url: string = this.getUrlPath(`downloadMacPlugin/`);
    return this.http.get<any>(url);
  }
  //why is this the same?
}
