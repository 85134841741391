import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GetUsStatesService } from '../../../../app/services/get-us-states.service';

@Component({
  selector: 'app-new-user-registration-invite',
  templateUrl: './new-user-registration-invite.component.html',
  styleUrls: ['./new-user-registration-invite.component.scss'],
})
export class NewUserRegistrationInviteComponent implements OnInit, OnChanges {
  @Output() public data = new EventEmitter<{
    firstname: string;
    lastname: string;
    password: string;
  }>();
  @Input() registerInfo: any;

  links = environment.config.links;
  dTerms = environment.config.dynamicText.dTerms;
  backgroundImage = environment.config.dynamicImage.bgImageNew2;
  dashboardLogo = environment.config.dynamicImage.dashboardLogo;

  formControl: any;
  setTimer: any;
  register: any = {
    firstname: '',
    lastname: '',
    email: '',
    company: '',
    password: '',
    confirm: '',
    checkbox: false,
  };

  startValidateCompany: boolean = false;
  startValidateFirstname: boolean = false;
  startValidateLastname: boolean = false;
  startValidatePassword: boolean = false;
  startValidateConfirm: boolean = false;
  disableBtn: boolean = false;

  isLinkValid: any;
  isLinkNotValid: any;

  visibilityBackground = {
    'background-image': 'url(' + this.backgroundImage + ')',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
    'background-attachment': 'fixed',
    'background-size': 'cover',
    height: '92vh',
  };

  constructor(
    public http: HttpClient,
    public Auth: AuthService,
    private router: Router,
    private getStates: GetUsStatesService
  ) {}

  ngOnInit() {
    this.setFormControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.registerInfo && this.registerInfo) {
      const { email, company_name, firstname, lastname, link } = this.registerInfo;
      this.register.email = email;
      this.register.company = company_name;
      this.register.firstname = firstname || '';
      this.register.lastname = lastname || '';
      this.isLinkValid = link;
      this.isLinkNotValid = link ? false : true;
    }
  }

  setFormControl = () => {
    const deepCopy = {
      firstname: {
        style: 'default',
        message: ' ',
        flag: false,
      },
      lastname: {
        style: 'default',
        message: ' ',
        flag: false,
      },
      password: {
        style: 'default',
        message: ' ',
        flag: false,
      },
      confirm: {
        style: 'default',
        message: ' ',
        flag: false,
      },
      checkbox: {
        style: 'default',
        message: ' ',
        flag: false,
      },
    };

    this.formControl = { ...deepCopy };
  };

  validateForm = () => {
    const validation = this.validate();

    if (validation) {
      this.emitEvent();
    }
  };

  isEmpty = (params) => {
    if (params.length === 0) {
      return true;
    }
    return false;
  };

  setErrorMessageAndStatus = (propname, bool, style, message) => {
    this.formControl[propname].flag = bool;
    this.formControl[propname].style = style;
    this.formControl[propname].message = message;
  };

  checkboxEvent = (event) => {
    this.register.checkbox = event;
    this.validate();
  };

  emitEvent = () => {
    this.data.emit({
      firstname: this.register.firstname,
      lastname: this.register.lastname,
      password: this.register.password,
    });
  };

  validate = () => {
    let errCount = 0;
    this.setFormControl();

    if (this.isEmpty(this.register.firstname)) {
      this.startValidateFirstname = true;
      this.setErrorMessageAndStatus('firstname', true, 'error', 'Required Field');
      errCount++;
    }

    if (this.isEmpty(this.register.lastname)) {
      this.startValidateLastname = true;
      this.setErrorMessageAndStatus('lastname', true, 'error', 'Required Field');
      errCount++;
    }

    if (this.isEmpty(this.register.password)) {
      this.startValidatePassword = true;
      this.setErrorMessageAndStatus('password', true, 'error', 'Required Field');
      errCount++;
    } else if (this.register.password.length < 8) {
      this.startValidatePassword = true;
      this.setErrorMessageAndStatus('password', true, 'error', 'Minimum of 8 character password');
      errCount++;
    }
    if (this.isEmpty(this.register.confirm)) {
      this.startValidateConfirm = true;
      this.setErrorMessageAndStatus('confirm', true, 'error', 'Required Field');
      errCount++;
    } else if (this.register.confirm !== this.register.password) {
      this.startValidateConfirm = true;
      this.setErrorMessageAndStatus('confirm', true, 'error', 'Password mismatch');
      errCount++;
    }

    if (!this.register.checkbox) {
      this.setErrorMessageAndStatus('checkbox', true, 'error', 'Required Field');
      errCount++;
    }

    this.disableBtn = errCount !== 0;
    return errCount === 0;
  };

  keyEvent = (event, type) => {
    if (type === 'firstname') {
      if (this.startValidateFirstname) {
        this.validate();
      }
    }

    if (type === 'lastname') {
      if (this.startValidateLastname) {
        this.validate();
      }
    }

    if (type === 'password') {
      this.validate();
    }
    if (type === 'confirm') {
      this.validate();
    }
  };
}
