import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-btn-mic',
  templateUrl: './btn-mic.component.html',
  styleUrls: ['./btn-mic.component.scss'],
})
export class BtnMicComponent implements OnInit, OnChanges {
  @Input() muteStatus: any;
  @Input() deviceStatus: any;

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {}

  constructor() {}

  ngOnInit() {
    this.muteStatus = this.muteStatus === null ? false : this.muteStatus;
  }
}
