import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GetPluginService } from 'src/app/services/get-plugin.service';
import { PopupTypeService } from '../../services/popupType.service';
import { ZoomPluginStatusService } from '../../services/zoom-plugin-status.service';
import { ZoomPluginActionService } from '../../services/zoom-plugin-action.service';
@Component({
  selector: 'app-audio-mic-list',
  templateUrl: './audio-mic-list.component.html',
  styleUrls: ['./audio-mic-list.component.scss'],
})
export class AudioMicListComponent implements OnChanges {
  @Input() connectedAudioMic: any;
  @Input() pluginId: number;
  @Input() serviceType: any = 'zoom';

  micList: any;
  audioMicStatus: string;
  selectedAudioMicId: string;
  deviceStatus: number;

  constructor(
    private getPluginService: GetPluginService,
    public ds: PopupTypeService,
    public zoomPluginStatus: ZoomPluginStatusService,
    public zoomPluginAction: ZoomPluginActionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.connectedAudioMic && this.connectedAudioMic) {
      this.arrangeMicList(this.connectedAudioMic);

    }
  }

  arrangeMicList = (list) => {
    console.log(this.serviceType)
    const copiedArr = [...list];
    let micInuse = [];
    let otherMic = [];
    let newArry = [];


    if( this.serviceType == 'teams') {
      micInuse = copiedArr.filter((c) => c.inuse_teams);
      otherMic = copiedArr.filter((c) => !c.inuse_teams);
      newArry = micInuse.concat(otherMic);
    } else {
      micInuse = copiedArr.filter((c) => c.inuse);
      otherMic = copiedArr.filter((c) => !c.inuse);
      newArry = micInuse.concat(otherMic);
    }
    
    this.micList = newArry;
    console.log('this.miclist: ', this.micList)
    if (!this.isEmpty(this.micList)) {
      this.audioSelect(this.micList[0].id);
    }
  };

  isEmpty = (arr) => {
    return arr.length === 0;
  };

  audioSelect = (id) => {
    const filterData = this.connectedAudioMic.filter((val) => val.id === id);
    this.audioMicStatus = this.zoomPluginStatus.pluginStatus[filterData[0].status];
    this.deviceStatus = filterData[0].status;

    this.selectedAudioMicId = id;
  };

  setDevice = () => {
    const actionParams = JSON.stringify({ selectedId: this.selectedAudioMicId });
    const actionName = 'SetConfigurationAudioInputSelectedId';
    this.zoomPluginAction.visibility_one_loader = true;
    this.getPluginService
      .createAction(this.pluginId, actionParams, actionName)
      .subscribe((result) => {
        if (result.response[0].status === 'OK') {
          this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
          this.zoomPluginAction.setActionCount();
        } else {
          this.zoomPluginAction.visibility_one_loader = false;
        }
      });
  };
}
