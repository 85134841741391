<div class="cloud-user-actions">
  <div *ngIf="!isActionListEmpty" class="mat-elevation-z8 child mat-table">
    <table class="data-table" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="requestStamp">
        <th mat-header-cell *matHeaderCellDef><strong>Date and Time</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.requestStamp }}
        </td>
      </ng-container>

      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef><strong>Message</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.message }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef><strong>Status</strong></th>
        <td mat-cell *matCellDef="let row" [ngClass]="{
            'mat-cell--good': row.status === 'OK',
            'mat-cell--bad': row.status !== 'OK'
          }">
          {{ row.status }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div *ngIf="isActionListEmpty" class="cloud-user-actions__no-remote-data">No Remote Heal Actions Found</div>
  <div *ngIf="shouldShowLoaderBtn" class="loader-child">
    <img src="{{ loader }}" alt="" />
  </div>
</div>