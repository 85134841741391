import { environment } from './../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-register-confirm',
  templateUrl: './new-register-confirm.component.html',
  styleUrls: ['./new-register-confirm.component.scss'],
})
export class NewRegisterConfirmComponent implements OnInit {
  id: '';
  complete: boolean = false;
  shouldShowError: boolean = false;
  shouldShowActivated: boolean = false;
  message: string = '';

  backgroundImage = environment.config.dynamicImage.bgImageNew2;
  visibilityBackground = {
    'background-image': 'url(' + this.backgroundImage + ')',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
    'background-attachment': 'fixed',
    'background-size': 'cover',
    height: '100vh',
  };

  constructor(private Auth: AuthService, private router: Router, private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    this.activateRegistration();
    localStorage.setItem('successReg', '0');
  }
  activateRegistration() {
    this.Auth.activeUserRegistration({ code: this.id }).subscribe((data) => {
      // console.log(data);
      this.complete = true;
      if (data['response'][0].status === 'Error') {
        this.shouldShowError = true;
        this.message = data['response'][0].message;
      } else {
        this.shouldShowActivated = true;
        this.message = data['response'][0].message;
      }
    });
  }
}
