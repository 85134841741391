import { trigger, state, style, transition,
    animate, group, query, stagger, keyframes
} from '@angular/animations';

export const HealthblockUpperRight = [
    trigger('slideUpperRight', 
    [
        state('home', style({
            transform: 'scale(1)'
            // top: '200px',
            // left: '600px'
        })),
        state('left', style({
            transform: 'scale(0.7)',
            left: '15px',
            top: '240px'
        })),
        // transition('close => open', animate('1500ms ease-in-out')),
        transition('home => left', animate('100ms', keyframes([
            style({ transform: 'scale(.9)'}),
            // style({ transform: 'scale(.7)'}),
            // style({ transform: 'scale(.5)'}),
            // style({ top: '170px'}),
            // style({ top: '200px'}),
            // style({ left: '600px'}),
            style({ left: '350px'})
        ]))),
        // transition('open => close', animate('1800ms ease-in-out'))
        transition('left => home', animate('100ms', keyframes([
            style({ left: '150px'}),
            // style({ left: '750px'}),
            // style({ top: '170px'}),
            // style({ transform: 'scale(.5)'}),
            // style({ transform: 'scale(.7)'}),
            style({ transform: 'scale(.9)'}),
      ])))
])]