import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GetCloudService } from '../../services/get-cloud.service';
import { GetDesktopPluginService } from '../../services/get-desktop-plugin.service';
import { AuthService } from '../../services/auth.service';
import { PopupTypeService } from '../../services/popupType.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-zoom-popup',
  templateUrl: './zoom-popup.component.html',
  styleUrls: ['./zoom-popup.component.scss'],
})
export class ZoomPopupComponent implements OnInit, OnDestroy {
  @Input() runTutorial: any;
  @Input() popupState: any;
  @Input() desktopClientDownloadLink: any;
  getUrlPath: Function = environment.apiServicePathBuilder('zoom');
  limit: number = 50;
  page: number = 1;
  zoomAccountInfo: any;
  zoomRoomList: any;
  zoomUserList: any;
  timer:any
  zoomNotification: boolean = false;
  zoomService: boolean = false;

  activePanel: number = 1;
  notification: any = 0;

  enableNotification;

  shouldShowMsg: boolean = false;
  enableCheckbox: boolean = false;

  siteSummary: any = [];
  siteList: any = [];
  //service
  //rooms
  //users
  tableSelected: any = 'service';
  componentName: any = 'zoom';
  // zoomDynamicProps: any;

  //new
  searchKey: string = '';
  zoomInfoResult: any;
  hostToLink: any;
  showLoader: boolean = false;
  constructor(
    private getCloudService: GetCloudService,
    private getCloudUserService: GetCloudService,
    private Auth: AuthService,
    private getCloudDataService: GetCloudService,
    private desktopPlugin: GetDesktopPluginService,
    private ds: PopupTypeService,
    private router: Router
  ) { }

  ngOnInit() {
    //delete zoom room list component
    //delete user list component
    this.getListCollector();
    this.radioChange(this.tableSelected)
  }
  reset(){
    this.zoomUserList = [];
    this.zoomRoomList = [];
    this.searchKey = '';
    this.page = 1;
  }
  ngOnDestroy(): void {
  }

  getListCollector = async () => {
    this.Auth.getListCollector().subscribe((data: any) => {
      const { site_list, site_summary } = data.response;

      this.siteList = site_list;
      this.siteSummary = site_summary[0] || [];
    });
  };

  fetchApiCloudUsers = () => {
    this.showLoader = true;
    this.getCloudDataService
      .fetchApiCloudUsers({
        list_limit: this.limit,
        off_set: (this.page - 1) * this.limit,
        search_key: this.searchKey,
        service_type: 'zoom',
        isImpactedUsers: this.router.url === '/cloud/impactedvipuser',
      })
      .pipe(finalize(()=> this.showLoader = false))
      .subscribe((data) => {
        const result = [...this.zoomUserList,...data.response];
        // console.log(result)
        this.zoomUserList = result
          .map((e) => {
            return {
              ...e,
              assigned_user: e.firstname + ' ' + e.lastname,
            };
          })
        // console.log('zoom userlink: ', this.zoomUserList);
        this.getDesktopPluginList();
      });
  };

  getDesktopPluginList = () => {
    this.desktopPlugin.getDesktopPluginList().subscribe((data: any) => {
      const zoomUserArr = data;
      this.hostToLink = zoomUserArr.filter((e) => !e.is_link_zoom);

    });
  };

  fetchApiCloudAllRooms = () => {
    this.showLoader = true;
    const postData = {
      list_limit: this.limit,
      off_set: (this.page - 1) * this.limit,
      search_key: this.searchKey,
      service_type: 'zoom'
    }
    this.getCloudDataService.fetchApiCloudAllRooms(postData)
    .pipe(finalize(()=>this.showLoader = false))
    .subscribe((data) => {
      const res = [...this.zoomRoomList,...data.response];
      this.zoomRoomList = res;
    });
  };

  sendPopupType = (emiterEvent) => {
    if (emiterEvent === 'desktopClientList') {
      this.fetchApiCloudUsers();
    }

    if (emiterEvent === 'getZoomInfo') {
      this.getZoomInfo();
    }

    if (emiterEvent === 'getListCollector') {
      this.getListCollector();
    }

    if (emiterEvent === 'zoomRoomList') {
      this.fetchApiCloudAllRooms();
    }
    if (emiterEvent === 'resetPagination') {
      this.resetPagination();
    }
    if (emiterEvent === 'scrollPagination'){
      this.onScrollPagination();
    }
  };

  getZoomInfo = () => {
    this.getCloudService.getZoomInfo().subscribe(
      (data: any) => {
        const result = data.response;
        if (result) {
          this.getAuthorizationUrl(result);
        }
      },
      (error) => { }
    );
  };

  getAuthorizationUrl = (result) => {
    this.getCloudService.getAuthorizationUrl().subscribe(
      (data: any) => {
        let res = {...result, ...data};
        this.zoomInfoResult = res;

        console.log(this.zoomInfoResult)
      },
      (error) => { }
    );
  };


  radioChange(event) {
    // this.zoomDynamicProps = event;
    this.reset();
    if(event === 'service') this.getZoomInfo();
    else if(event === 'rooms') this.fetchApiCloudAllRooms();
    else if(event === 'users') this.fetchApiCloudUsers();
  }
  search(){
    if(this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(()=>{
      this.resetPagination();
      if(this.tableSelected === 'rooms') this.fetchApiCloudAllRooms();
      else if(this.tableSelected === 'users') this.fetchApiCloudUsers();
    },1500)
  }
  resetPagination(){
    this.page = 1;
    this.zoomUserList = [];
    this.zoomRoomList = [];
  }
  onScrollPagination(){
    this.page++;
    if(this.tableSelected === 'rooms') this.fetchApiCloudAllRooms();
    else if(this.tableSelected === 'users') this.fetchApiCloudUsers();
  }
}