<div class="btn_volume">
  <div style="display: flex; justify-content: center; padding-top: 20px; padding-left: 50px;height:
    1px;">{{masterVolume === -1? 'N/A': masterVolume}}</div>
  <div class="btn_volume__ctr">

    <!-- <div (click)="ds.sendPopupType(47, { data: data })">
      <img class="btn_volume__icon" src="{{ icon }}" (mouseenter)="iconOnHover()" (mouseleave)="iconOnLeave()" />
    </div> -->

    <div>
      <img class="btn_volume__icon" src="{{ icon }}" />
    </div>

    <span class="btn_volume__status">
      <img src="{{ getStatusIcon(inuseSpeaker) }}" />
    </span>
    <div (click)="sliderDecrement()" class="btn_volume__i">
      <i class="fa fa-minus"></i>
    </div>
    <input #sliderInput class="slider1" type="range" id="btnVolumeSlider" min="0" max="100"
      value="{{masterVolume === -1? 0: masterVolume}}" (change)="handleSliderValue(sliderInput.value)" />
    <div (click)="sliderIncrement()" class="btn_volume__i">
      <i class="fa fa-plus"></i>
    </div>
  </div>
  <div class="btn_volume__ctr_2">
    <span (click)="ds.sendPopupType(50, { data: data })">
      {{ inuseSpeaker.name }}
    </span>
  </div>
</div>