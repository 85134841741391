import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-calls-month',
  templateUrl: './cloud-calls-month.component.html',
  styleUrls: ['./cloud-calls-month.component.scss']
})
export class CloudCallsMonthComponent implements OnInit {
  title: string = "feature not available";
  constructor() { }

  ngOnInit() {
  }

}
