import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-btn-device-camera-health',
  templateUrl: './btn-device-camera-health.component.html',
  styleUrls: ['./btn-device-camera-health.component.scss'],
})
export class BtnDeviceCameraHealthComponent implements OnChanges {
  @Input() count: number = 0;
  @Input() label: string = 'Not-Active';
  @Input() camera_impacted: number = 0;
  @Input() camera_connected: number = 0;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.count) {
      this.count = changes.count.currentValue;
      if (this.count === 0) {
        this.camera_connected = 0;
        this.camera_impacted = 0;
      }
    }
    if (changes.total) {
      this.label = changes.label.currentValue;
    }
  }
}
