<div class="row">
  <div class="col-4">
    <h5>Analytics</h5>
    <h6>People in Room</h6>
  </div>
  <div class="col-4 pl-0 pr-1 text-center">
    <img src="../../../assets/img/cloud/panacast.png" style="width: 95px;">
  </div>
  <div class="col-4 pl-0 pr-1">
    <app-btn-reg-stats [count]="count" [label]="label" [color]="color" (click)="sendPopupType(1)"></app-btn-reg-stats>
  </div>
</div>