<div class="all-room-plugins">
  <div style="overflow: auto; height: 230px;">
    <table class="table table-stripped">
      <thead>
        <tr class="black">
          <th scope="col">Room name</th>
          <th scope="col">Host name</th>
          <th scope="col">MAC Address</th>
          <th scope="col">Host IP</th>
          <th scope="col">Cloud Service</th>
          <th scope="col">{{ dPlugin }} Status</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row" *ngFor="let item of roomPluginList | mainMenuFilter: searchKey:'roomPlugin'">
          <td>
            {{ item.room_name | displayEmpty }}
          </td>

          <td>
            {{ item.host_name | displayEmpty }}
          </td>

          <td>
            {{ item.mac_address | displayEmpty }}
          </td>

          <td>
            {{ item.ip_address | displayEmpty }}
          </td>

          <td>
            <img src="{{ getPluginTypeImage(item.plugin_type) }}" alt="" />
          </td>

          <td>
            <div class="all-room-plugins__status {{
                getRoomStatus(item.plugin_status, item.plugin_enabled)
              }}"></div>
          </td>

          <td *ngIf="isShowCollectorEdit">
            <div class="fa fa-trash fa-lg" (click)="openDeleteModal(item)"></div>
          </td>

        </tr>
      </tbody>
    </table>
  </div>

  <mat-form-field appearance="fill">
    <mat-label>Select an option</mat-label>
    <mat-select [(value)]="selected" (selectionChange)="pluginListSelect($event.value)">
      <mat-option value="allPlugin">All Plugin</mat-option>
      <mat-option value="unassigned">Unassigned</mat-option>
      <mat-option value="assigned">Assigned</mat-option>
    </mat-select>
  </mat-form-field>
</div>