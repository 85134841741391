import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';
import { GetPluginService } from 'src/app/services/get-plugin.service';
import { PopupTypeService } from '../../services/popupType.service';
import { ZoomPluginStatusService } from '../../services/zoom-plugin-status.service';
import { ZoomPluginActionService } from '../../services/zoom-plugin-action.service';

@Component({
  selector: 'app-audio-mic-plugin-status',
  templateUrl: './audio-mic-plugin-status.component.html',
  styleUrls: ['./audio-mic-plugin-status.component.scss'],
})
export class AudioMicPluginStatusComponent implements OnChanges {
  @Input() cloudRoomAudioStatsData: any;
  @Input() pluginId: number;
  @Input() micFailOver: boolean;
  failOverIdList: any;
  audioMicList = [];
  constructor(
    private getPluginService: GetPluginService,
    public ds: PopupTypeService,
    public zoomPluginStatus: ZoomPluginStatusService,
    public zoomPluginAction: ZoomPluginActionService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cloudRoomAudioStatsData) {
      const copiedArray = [...this.cloudRoomAudioStatsData];
      this.audioMicList = copiedArray;
      if (!this.micFailOver) {
        this.micFailOver = false;
        this.zoomPluginAction.visibility_one_loader = false;
        this.resetMicFailOver();
      }
      this.checkList(this.audioMicList);
    }
  }

  checkList = (params) => {
    const primary = params.filter((e) => e.primary);
    const backup = params.filter((e) => e.backup === 1);
    const otherDevices = params.filter((e) => !e.primary && e.backup !== 1);
    const newArr = primary.concat(backup, otherDevices);

    if (!this.isEmpty(primary)) {
      // call failover list so backup will be called
      // make in use primary
      // or something wrong with the plugin
    }
    this.audioMicList = newArr;
  };

  isEmpty = (arr) => {
    return arr.length === 0;
  };

  listOnChange = (index, isPrimary, events) => {
    let bool = true;
    let type = 'primary';

    if (!isPrimary) {
      bool = false;
      type = 'backup';
    }

    const copiedArray = [...this.audioMicList];
    for (let i = 0; i < copiedArray.length; i++) {
      if (i === index) {
        copiedArray[i].primary = bool;

        copiedArray[i].backup = events ? 1 : 0;
      } else {
        copiedArray[i][type] = isPrimary ? false : 0;
      }
    }
    this.audioMicList = copiedArray;
  };

  enableOrDisableMicFailOver = (bool) => {
    this.micFailOver = !bool;
    this.updateFailOverValue(this.micFailOver);
    this.zoomPluginAction.visibility_one_loader = true;
    if (!this.micFailOver) {
      this.resetMicFailOver();
      this.zoomPluginAction.visibility_one_loader = true;
    }
  };

  resetMicFailOver = () => {
    const copiedArray = [...this.audioMicList];
    for (let i = 0; i < copiedArray.length; i++) {
      copiedArray[i].backup = 0;
    }
  };

  saveFailOver = () => {
    const primary = this.audioMicList.filter((p) => p.primary);
    const backup = this.audioMicList.filter((b) => b.backup > 0);
    this.failOverIdList = primary;
    if (!this.isEmpty(backup)) {
      this.failOverIdList = primary.concat(backup);
    }

    this.failOverIdList = this.failOverIdList.map((i) => i.id);
    this.updateFailOverList(this.failOverIdList, this.pluginId);
    this.setDevice(this.failOverIdList[0]);
    this.zoomPluginAction.visibility_one_loader = true;
  };

  resetCloudRoomfields = () => {
    // const failOverList = this.cloudRoomAudioStatsData.map((device) => {
    //   return device.id;
    // });
    // this.updateFailOverList(failOverList, this.pluginId);

    // we remove the cancel
  };

  updateFailOverList = (failOverList, pluginId) => {
    const actionParams = JSON.stringify({ failoverList: failOverList });
    const actionName = 'SetAudioInputFailoverList';
    this.getPluginService.createAction(pluginId, actionParams, actionName).subscribe((result) => {
      if (result.response[0].status === 'OK') {
        this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
        this.zoomPluginAction.setActionCount();
      } else {
        this.zoomPluginAction.visibility_one_loader = false;
      }
    });
  };

  updateFailOverValue = (flag) => {
    const actionParams = JSON.stringify({ isEnabled: flag });
    const actionName = 'SetFailoverEnabled';
    this.getPluginService
      .createAction(this.pluginId, actionParams, actionName)
      .subscribe((result) => {
        if (result.response[0].status === 'OK') {
          this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
          this.zoomPluginAction.setActionCount();
        } else {
          this.zoomPluginAction.visibility_one_loader = false;
        }
      });
  };

  setDevice = (audioId) => {
    const actionParams = JSON.stringify({ selectedId: audioId });
    const actionName = 'SetConfigurationAudioInputSelectedId';
    this.zoomPluginAction.visibility_one_loader = true;
    this.getPluginService
      .createAction(this.pluginId, actionParams, actionName)
      .subscribe((result) => {
        if (result.response[0].status === 'OK') {
          this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
          this.zoomPluginAction.setActionCount();
        } else {
          this.zoomPluginAction.visibility_one_loader = false;
        }
      });
  };
}
