import moment from 'moment-timezone'
import { AuthService } from 'src/app/services/auth.service'
import { GetCollectorService } from 'src/app/services/get-collector.service'
import { PopupTypeService } from 'src/app/services/popupType.service'
import { environment } from 'src/environments/environment'

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'

export enum CollectorAction {
	OPEN = 'OPEN',
	PROCESSING = 'PROCESSING',
	COMPLETE = 'COMPLETE',
	ABORTED = 'ABORTED',
	FAILED = 'FAILED',
	EXPIRED = 'EXPIRED',
}
@Component({
	selector: 'app-collector-list',
	templateUrl: './collector-list.component.html',
	styleUrls: ['./collector-list.component.scss'],
})
export class CollectorListComponent implements OnInit, OnChanges {
	timeout: any
	collectorsData: MatTableDataSource<any>
	baseFilter: string
	@ViewChild(MatSort, { static: true }) sort: MatSort
	loading: boolean
	loader = environment.config.dynamicImage.loader
	isSelectAll: boolean = false
	now = new Date().getTime()
	@Input() latestCollector: any
	@Output() collectorEvent: EventEmitter<any> = new EventEmitter<any>()
	@Output() reTryEvent: EventEmitter<any> = new EventEmitter<any>()
	collectorLatestVersion: string = ''
	displayedColumns: string[] =
		this.Auth.accessLevel == 'SUPERADMIN'
			? [
					'select',
					'company',
					'name',
					'health',
					'ip',
					'cpu',
					'memory',
					'lastConnected',
					'version',
					'siteType',
					'collectorUpdate',
			  ]
			: ['select', 'name', 'health', 'ip', 'cpu', 'memory', 'lastConnected', 'version', 'siteType', 'collectorUpdate']

	constructor(
		private router: Router,
		public Auth: AuthService,
		private getCollectorService: GetCollectorService,
		private route: ActivatedRoute,
		private ds: PopupTypeService
	) {}

	ngOnInit(): void {
		this.sendPopupType(0)
		this.collectorsData = new MatTableDataSource()
		this.getCollectorList()
		this.setTableSorting()
		this.route.params.subscribe(params => {
			if (params?.state !== this.baseFilter) {
				this.isSelectAll = false
				this.baseFilter = params.state
				this.setTableFilter()
			}
		})
		this.collectorsData.connect().subscribe((data: any[]) => {
			// Handle changes in filtered data here
			this.collectorEvent.emit(data)
		})
		this.getCollectorService.isRefresh.subscribe(x => {
			this.getCollectorList()
		})
	}
	get collectorAction() {
		return CollectorAction
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.latestCollector && this.latestCollector) {
			this.collectorLatestVersion = this.latestCollector.version
		}
	}

	sendPopupType(p) {
		this.ds.sendPopupType(p)
	}

	getCollectorList(status?: boolean) {
		this.loading = true
		this.getCollectorService.getCollectors().subscribe(
			response => {
				this.loading = false
				this.isSelectAll = status ? this.isSelectAll : false
				this.collectorsData.data = response?.data?.map((collector, index) => ({
					isUpdate: status ? this.collectorsData.data[index].isUpdate : false,
					...collector,
					version: String(collector.version.replace(/\.0/g, '')),
				}))
				// this.collectorsData.data = response?.data?.map(collector => ({
				// 	...collector,
				// 	cpu_used: collector.cpu_used > 100 ? 100 : collector.cpu_used,
				// }))
			},
			err => {
				this.loading = false
			}
		)

		this.timeout = setTimeout(() => {
			this.getCollectorList(true)
		}, 30_000)
	}

	setTableSorting() {
		this.collectorsData.sortingDataAccessor = (item, property) => {
			switch (property) {
				case 'company': {
					return item.company_name.toLocaleLowerCase()
				}
				case 'name': {
					return item.site_name.toLocaleLowerCase()
				}
				case 'health': {
					return item.health
				}
				case 'ip': {
					return item.ip_address
				}
				case 'cpu': {
					return item.cpu_used
				}
				case 'memory': {
					return item.memory_used
				}
				case 'lastConnected': {
					return item.last_connect
				}
				case 'version': {
					return item.version
				}
				case 'siteType': {
					return item.virtual
				}
				default:
					return item[property]
			}
		}
		this.collectorsData.sort = this.sort
	}

	setTableFilter() {
		this.collectorsData.filterPredicate = (data: any, filter: string) => {
			var dataStr =
				data.site_name +
				data.health +
				data.ip_address +
				data.cpu_used +
				data.memory_used +
				data.last_connect +
				data.version +
				data.virtual

			if (this.baseFilter == 'impacted') {
				if (filter == '-' || filter == '' || filter == null) {
					return data.health <= 50 && data.paused === 0
				} else {
					return (
						data.health <= 50 &&
						data.paused === 0 &&
						dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
					)
				}
			} else if (this.baseFilter == 'warning') {
				if (filter == '-' || filter == '' || filter == null) {
					return data.health >= 51 && data.health < 76 && data.paused === 0
				} else {
					return (
						data.health >= 51 &&
						data.health < 76 &&
						data.paused === 0 &&
						dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
					)
				}
			} else if (this.baseFilter == 'paused') {
				if (filter == '-' || filter == '' || filter == null) {
					return data.paused == 1
				} else {
					return data.paused == 1 && dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
				}
			} else if (this.baseFilter == 'healthy') {
				if (filter == '-' || filter == '' || filter == null) {
					return data.health >= 76 && data.paused === 0
				} else {
					return (
						data.health >= 76 &&
						data.paused === 0 &&
						dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
					)
				}
			} else if (this.baseFilter == 'all') {
				if (filter == '-' || filter == '' || filter == null) {
					return true
				} else {
					return dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
				}
			}
			// else if (this.baseFilter == 'oncall') {
			// 	if (filter == '-' || filter == '' || filter == null) {
			// 		return data.oncall == 1
			// 	} else {
			// 		return data.oncall == 1 && dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
			// 	}
			// } else if (this.baseFilter == 'impactedOnly') {
			// 	if (filter == '-' || filter == '' || filter == null) {
			// 		return data.health < 76
			// 		// && data.paused != 1
			// 	} else {
			// 		return (
			// 			data.health < 76 &&
			// 			// data.paused != 1 &&
			// 			dataStr.trim().toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) != -1
			// 		)
			// 	}
			// }
		}

		this.collectorsData.filter = '-'
	}

	applySearchFilter(filterValue: string) {
		if (filterValue == '') this.collectorsData.filter = '-'.trim().toLowerCase()
		else this.collectorsData.filter = filterValue.trim().toLowerCase()
	}

	clickRow(row) {
		this.router.navigate(['collector/site/', row.site_id])
	}

	ngOnDestroy() {
		if (this.timeout) clearTimeout(this.timeout)
	}
	selectAll(event) {
		const { checked } = event.target
		this.collectorsData.filteredData = this.collectorsData.filteredData?.map(x => {
			const schedule = this.checkScheduleDate(x.collector_update_schedule)
			const isVersion = this.compareVersion(x.version, this.collectorLatestVersion)
			if (
				!isVersion &&
				!schedule &&
				!x.virtual &&
				x.status == 1 &&
				![CollectorAction.PROCESSING, CollectorAction.OPEN].includes(x.action_status) &&
				!(!isVersion&&x.action_status == CollectorAction.COMPLETE && x.last_updated < 60)

			)
				x.isUpdate = checked
			return x
		})
		this.collectorEvent.emit(this.collectorsData.filteredData)
	}
	disableCheckbox(element) {
		const isVersion = this.compareVersion(element.version, this.collectorLatestVersion)
		return (
			isVersion ||
			element.virtual ||
			this.checkScheduleDate(element.collector_update_schedule) ||
			element.status !== 1 ||
			[CollectorAction.PROCESSING, CollectorAction.OPEN].includes(element.action_status) ||
			(!isVersion&&element.action_status == CollectorAction.COMPLETE && element.last_updated < 60)
		)
	}
	selectSingle(event, index) {
		const { checked } = event.target
		this.collectorsData.filteredData[index].isUpdate = checked
		this.isSelectAll = this.collectorsData.filteredData.every(x => x.isUpdate === true)
		this.collectorEvent.emit(this.collectorsData.filteredData)
	}
	getStatus(element) {
		const schedularTime = new Date(element.collector_update_schedule).getTime()
		const isVersion = this.compareVersion(element.version, this.collectorLatestVersion)
		const actionVersion = this.compareVersion(element?.params?.version, this.collectorLatestVersion)
		const isScheduled = schedularTime > this.now
		if (element.virtual) return '-'
		else if (element.status == -1) return 'Offline'
		else if (isVersion) return 'Up To Date'
		else if (element.action_status == CollectorAction.FAILED) return CollectorAction.FAILED
		else if ([CollectorAction.PROCESSING, CollectorAction.OPEN].includes(element.action_status) || (element.action_status == CollectorAction.COMPLETE && element.last_updated < 60))
			return CollectorAction.PROCESSING
		if (!isVersion) {
			if (element.collector_update_schedule && isScheduled) {
				return `<div class="text-grey">
                        <i class="fa fa-calendar dropdown-icon mr-2"></i><strong>Scheduled</strong><br>
                        <span>${moment(element.collector_update_schedule).format('MMMM Do YYYY, h:mm:ss a')}</span>
                    </div>`
			}
			return 'Update Available'
		}

		return 'Up To Date'
	}
	checkScheduleDate(date) {
		if (!date) return false
		const schedular_time = new Date(date).getTime()
		if (schedular_time > this.now) return true
		else return false
	}
	compareVersion(olderVersion: string, newVersion: string) {
		if (olderVersion && newVersion) {
			if (olderVersion === newVersion) return true
			const oldV = olderVersion.split('.')
			const newV = newVersion.split('.')
			const maxLen = Math.max(oldV.length, newV.length)
			for (let i = 0; i < maxLen; i++) {
				if (+oldV[i] < +newV[i]) return false
			}

			return true
		} else return false
	}
	reTry(element, status) {
		this.reTryEvent.emit({
			data: element,
			status: status,
		})
	}
}
//11012 -> 210
