import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-table-zoom-on-call',
  templateUrl: './table-zoom-on-call.component.html',
  styleUrls: ['./table-zoom-on-call.component.scss'],
})
export class TableZoomOnCallComponent implements OnChanges {
  renderValue: any;
  @Input() value: any;
  constructor() {}

  faIcon: any = faPhone;
  ngOnChanges(changes: SimpleChanges) {
    if (changes.value && this.value) {
      // // console.log(this.value);
    }
  }
}
