import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-video-remote-support-integration',
  templateUrl: './video-remote-support-integration.component.html',
  styleUrls: ['./video-remote-support-integration.component.scss']
})
export class VideoRemoteSupportIntegrationComponent implements OnInit, OnChanges {
  @Input() logitechData: any;
  Image1: string = '../../../../assets/img/gotoresolve.jpg';
  
  activationState: any; 
  isEnabled: any; 
  isToCAccepted: any; 
  
  constructor() { }

  ngOnInit(): void {
  }
  
    
	ngOnChanges(changes:SimpleChanges) {
    if(changes.logitechData && this.logitechData) {
      this.setData();

    }
  }
  
  setData = () => {
    const { settings: { goToResolve } } = this.logitechData
    
    if(goToResolve) {
      this.activationState = goToResolve.activationState
      this.isEnabled = goToResolve.isEnabled
      this.isToCAccepted = goToResolve.isToCAccepted
    
    }
    
  }


}
