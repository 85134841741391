import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-teams-user-stats',
  templateUrl: './teams-user-stats.component.html',
  styleUrls: ['./teams-user-stats.component.scss'],
})
export class TeamsUserStatsComponent implements OnInit {
  @Input() teamsDetails: any;
  @Input() isTeamsEnabled: any;

  serviceType: any;
  cloudUserLivestats: any;
  cloudUserUtilization: any;

  cloudUserId: any;
  accessLevel: any;
  isNotButton: string = 'not';
  hasAccess: boolean;
  isSuperAdmin: boolean;

  isZoomPluginEnabled: boolean = false;
  deskPlugin: String = environment.config.dynamicText.deskPlugin;
  desktopPluginMessage: string;
  deskPluginStatus: number;
  isShowPeopleCount: boolean;
  isUserOnCall: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.teamsDetails && this.teamsDetails) {
      // // console.log('teamsDetails',this.teamsDetails);
      if (this.teamsDetails.liveMeetings == 1) {
        this.isUserOnCall = true;
      }
      this.setData();
    }
  }

  setData = () => {
    const {
      zoom_user_id,
      liveMeetings,
      scheduledMeetings,
      scheduled,
      trigger_limits,
      total_bitrate,
      total_jitter,
      avg_packet_loss,
      frame_rate,
      triggers,
      totalMeetings,
      past_meetings,
      totalRecordings,
      recordings,
      zoom_plugin_connected_devices,
      plugin_application,
      service_type,
      service,
      plugin_host_info,
      zoom_plugin_data,
      total_teams_meetings_count,
      plugin_id,
      plugin_status,
      zoom_plugin_enabled,
      calculatedMbpsTeams,
      wifiRange,
      id,
      wifiSsid
    } = this.teamsDetails;

    this.cloudUserLivestats = {
      liveMeetings,
      scheduledMeetings,
      scheduled,
      cloudUserId: zoom_user_id,
      hasAccess: this.hasAccess,
      trigger_limits: trigger_limits || 0,
      total_bitrate: totalRecordings || 0,
      total_jitter: total_jitter || 0,
      avg_packet_loss: avg_packet_loss || 0,
      frame_rate,
      triggers: triggers || 0,
      zoom_plugin_connected_devices,
      plugin_application,
      zoom_user_id,
      service_type,
      plugin_host_info: plugin_host_info,
      zoom_plugin_data,
      plugin_id,
      plugin_status,
      zoom_plugin_enabled,
      calculatedMbpsTeams,
      wifiRange,
      isTeamsEnabled: this.isTeamsEnabled,
      id,
      wifiSsid

    };

    this.cloudUserUtilization = {
      totalMeetings,
      past_meetings,
      totalRecordings,
      recordings,
      total_teams_meetings_count,
      cloudUserId: zoom_user_id,
    };
    
    console.log('this.cloudUserUtilization: ', this.cloudUserUtilization)
  };

  ngOnInit() { }
}
