import { Component, OnInit, Input } from '@angular/core';
import { faCog } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-table-cog-icon',
  templateUrl: './table-cog-icon.component.html',
  styleUrls: ['./table-cog-icon.component.scss']
})
export class TableCogIconComponent implements OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  faIcon: any = faCog;
  constructor() { }

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

}
