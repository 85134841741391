<!-- needs to be fix -->
<div class="card" style="width: 18rem;">
  <div class="card-body d-flex flex-column justify-content-between">
    <ng-container *ngIf="isSuperAdmin">
      <img id="company-logo" [src]="mspLogo" class="px-2" style="padding-top: 50px; padding-bottom: 66px;" />
    </ng-container>
    <ng-container *ngIf="!isSuperAdmin">
      <img id="company-logo" [src]="companyLogo" class="px-2" style="padding-top: 50px; padding-bottom: 66px;" />
    </ng-container>
    <div class="card-detail-bm">
      <ng-container *ngIf="!isSuperAdmin">
        <h4 class="card-title">{{ Auth.companyName }}</h4>
      </ng-container>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
      </div>
    </div>
  </div>
</div>