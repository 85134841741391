import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { PopupTypeService } from '../../../services/popupType.service'
import { TableFilterService } from '../../../services/table-filter.service'

@Component({
  selector: 'app-audio-all-devices-stats',
  templateUrl: './audio-all-devices-stats.component.html',
  styleUrls: ['./audio-all-devices-stats.component.scss']
})
export class AudioAllDevicesStatsComponent implements OnInit {
  data: any;
  @Input() audioDeviceData: any;
  @Output() onFilter: EventEmitter<number> = new EventEmitter();
  currentFilter: string;
  activeFilter: any = "";

  constructor(private ds: PopupTypeService, private tableFilterService: TableFilterService) { }

  setFilter(filter) {
    if (filter == this.activeFilter) {
      this.activeFilter = "";
      this.clickFilter("");
    } else {
      this.activeFilter = filter;
      switch (filter) {
        case 'on_call':
          this.clickFilter('on_call');
          break;
        case 'impacted':
          this.clickFilter('impactedOnly');
          break;
        case 'teams_nc':
          this.clickFilter('teams_nc');
          break;
        case 'zoom_nc':
          this.clickFilter('zoom_nc');
          break;
        case 'webex_nc':
          this.clickFilter('webex_nc');
          break;
      }
    }
  }

  clickFilter(filter):void {
    this.tableFilterService.filter(filter);
  }

  ngOnInit(){
    // send message to subscribers via observable subject
    this.ds.sendPopupType(0);
  }

  sendPopupType(p){
    this.ds.sendPopupType(p);
  }

  ngOnDestroy(){
    this.ds.clearPopupType();
  }

}
