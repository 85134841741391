import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetTeamsService } from 'src/app/services/get-teams.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teams-room-delete-room',
  templateUrl: './teams-room-delete-room.component.html',
  styleUrls: ['./teams-room-delete-room.component.scss']
})
export class TeamsRoomDeleteRoomComponent implements OnInit {

  setTimer: any;
  roomName: String = "";
  zoomUserId: any;
  actionResultMessage: String = '';
  btnLoader: string = environment.config.dynamicImage.loader;
  doneAction: boolean = false;
  isLoading: boolean = false;
  sendPopupType: EventEmitter<any>;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TeamsRoomDeleteRoomComponent>,
    private teamsService: GetTeamsService) {
    if (data) {
      this.zoomUserId = data.zoom_user_id;
      this.roomName = data.room_name;
      this.sendPopupType = data.sendPopupType;
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

  closeDialog = () => {
    this.isLoading = false;
    this.dialogRef.close(true);
  };

  deleteTeamsRoom = () => {
    this.isLoading = true;
    this.teamsService.deleteTeamsRoom(this.zoomUserId).subscribe((data: any) => {
      if (data.status === 'success') {
        this.actionResultMessage = 'Successful in deleting ' + this.roomName + ' room';
      } else {
        this.actionResultMessage = 'Deleting ' + this.roomName + '  failed.';
      }
      this.doneAction = true;
      this.setTimer = setTimeout(() => {
        this.sendPopupType.emit('teamsRoomList');
        this.closeDialog();
      }, 2000);
    })
  };

  onNoClick(): void {
    this.dialogRef.close();
  }

}
