import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-info-uptime',
  templateUrl: './info-uptime.component.html',
  styleUrls: ['./info-uptime.component.scss'],
})
export class InfoUptimeComponent implements OnChanges {
  @Input() uptimeDays: number;
  @Input() uptimeHours: number;
  @Input() uptimeMins: number;
  @Input() status: string;

  days: number = 0;
  hours: number = 0;
  minutes: number = 0;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.status !== 'DOWN' && this.status !== 'OFFLINE') {
      if (changes.uptimeDays) {
        if (isNaN(changes.uptimeDays.currentValue)) {
          if (isNaN(changes.uptimeDays.previousValue)) {
            this.days = 0;
          } else {
            this.days = changes.uptimeDays.previousValue;
          }
        } else {
          this.days = changes.uptimeDays.currentValue;
        }
      }

      if (changes.uptimeHours) {
        if (isNaN(changes.uptimeHours.currentValue)) {
          if (isNaN(changes.uptimeHours.previousValue)) {
            this.hours = 0;
          } else {
            this.hours = changes.uptimeHours.previousValue;
          }
        } else {
          this.hours = changes.uptimeHours.currentValue;
        }
      }

      if (changes.uptimeMins) {
        if (changes.uptimeMins && isNaN(changes.uptimeMins.currentValue)) {
          if (isNaN(changes.uptimeMins.previousValue)) {
            this.minutes = 0;
          } else {
            this.minutes = changes.uptimeMins.previousValue;
          }
        } else {
          this.minutes = changes.uptimeMins.currentValue;
        }
      }
    }
  }
}
