import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mainMenuFilter',
})
export class MainMenuFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, paramType: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    searchText = !searchText ? searchText : searchText.toLocaleLowerCase();

    return items.filter((e) => {
      return this.searchKeyPerColumn(e, searchText, paramType);
    });
  }

  searchKeyPerColumn = (items = {}, searchText, paramType) => {
    if (paramType === 'collector') {
      if (items['site_name'] === null ) items['site_name'] = '';
      if (items['ip_address'] === null ) items['ip_address'] = '';
      if (items['audio_device_count'] === null ) items['audio_device_count'] = '';
      if (items['cloud_rooms_count'] === null ) items['cloud_rooms_count'] = '';
      if (items['iot_domotz_count'] === null ) items['iot_domotz_count'] = '';
      if (items['video_device_count'] === null ) items['video_device_count'] = '';
      return (
        (items['site_name'] || '').toLocaleLowerCase().includes(searchText) ||
        (items['ip_address'] || '').toLocaleLowerCase().includes(searchText) ||
        (items['audio_device_count'] || '').toString().includes(searchText) ||
        (items['cloud_rooms_count'] || '').toString().includes(searchText) ||
        (items['iot_domotz_count'] || '').toString().includes(searchText) ||
        (items['video_device_count'] || '').toString().includes(searchText)
      );
    }

    if (paramType === 'roomPlugin') {
      if (items['room_name'] === null ) items['room_name'] = '';
      if (items['host_name'] === null ) items['host_name'] = '';
      if (items['mac_address'] === null ) items['mac_address'] = '';
      if (items['ip_address'] === null ) items['ip_address'] = '';
      return (
        items['room_name'].toString().toLocaleLowerCase().includes(searchText) ||
        items['host_name'].toString().toLocaleLowerCase().includes(searchText) ||
        items['mac_address'].toString().toLocaleLowerCase().includes(searchText) ||
        items['ip_address'].toString().toLocaleLowerCase().includes(searchText)
      );
    }

    if (paramType === 'zoomRooms') {
      if (items['room_name'] === null ) items['room_name'] = '';
      if (items['site_name'] === null ) items['site_name'] = '';
      if (items['plugin_ip_address'] === null ) items['plugin_ip_address'] = '';
      return (
        items['room_name'].toString().toLocaleLowerCase().includes(searchText) ||
        items['site_name'].toString().toLocaleLowerCase().includes(searchText) ||
        items['plugin_ip_address'].toString().toLocaleLowerCase().includes(searchText)
      );
    }

    if (paramType === 'zoomUsers') {
      if (items['assigned_user'] === null ) items['assigned_user'] = '';
      if (items['email'] === null ) items['email'] = '';
      return (
        items['assigned_user'].toString().toLocaleLowerCase().includes(searchText) ||
        items['email'].toString().toLocaleLowerCase().includes(searchText)
      );
    }

    if (paramType === 'desktopPlugin') {
      if (items['host_name'] === null ) items['host_name'] = '';
      if (items['assigned_user'] === null ) items['assigned_user'] = '';
      if (items['mac_address'] === null ) items['mac_address'] = '';
      if (items['ip_address'] === null ) items['ip_address'] = '';
      return (
        items['host_name'].toString().toLocaleLowerCase().includes(searchText) ||
        items['assigned_user'].toString().toLocaleLowerCase().includes(searchText) ||
        items['mac_address'].toString().toLocaleLowerCase().includes(searchText) ||
        items['ip_address'].toString().toLocaleLowerCase().includes(searchText)
      );
    }

    if (paramType === 'linkDialog') {
      if (items['firstname'] === null ) items['firstname'] = '';
      if (items['email'] === null ) items['email'] = '';
      return (
        items['firstname'].toString().toLocaleLowerCase().includes(searchText) ||
        items['email'].toString().toLocaleLowerCase().includes(searchText)
      );
    }

    if (paramType === 'linkDialogHost') {
      if (items['host_name'] === null ) items['host_name'] = '';
      return items['host_name'].toString().toLocaleLowerCase().includes(searchText);
    }
  };
}
