<div class="panel">
  <div class="panel__header">
    <app-panel-tools [PopupTitle]="iotDeviceName"></app-panel-tools>
    <div *ngIf="iotDeviceDetails?.agent_status === 'OFFLINE'" class="panel__banner-secondary">
      {{ dCollector }} is offline. The information below is from the last successful sync at
      {{ syncTime }}
    </div>
  </div>
  <div class="panel__body">
    <div class="panel__body_info">
      <app-iot-device-info [iotDeviceDetails]="iotDeviceDetails" (eventEmitter)="ngOnChange($event)">
      </app-iot-device-info>
    </div>
    <div class="panel__body_stats_health">
      <div class="health-container">
        <div (click)="openIotDeviceHealthHistory()" class="incident-button">
          Incidents
        </div>
        <app-system-health [isCursorPointer]="true"
          [healthNum]="(iotDeviceDetails?.paused === 1 || ['OFFLINE','DOWN'].includes(iotDeviceDetails?.status)) ? 0 : iotDeviceDetails?.health" [showBlackPointer]="true"
          (click)="openIotDeviceHealthHistory()"></app-system-health>
      </div>
      <app-iot-device-stats [iotDeviceDetails]="iotDeviceDetails" (eventEmitter)="ngOnChange($event)">
      </app-iot-device-stats>
    </div>
  </div>
</div>