import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { environment } from '../../../environments/environment';
import { PopupTypeService } from '../../services/popupType.service';
import { GetPluginService } from 'src/app/services/get-plugin.service'

@Component({
  selector: 'app-teams-room-device-health',
  templateUrl: './teams-room-device-health.component.html',
  styleUrls: ['./teams-room-device-health.component.scss'],
})
export class TeamsRoomDeviceHealthComponent implements OnInit, OnChanges {
  @Input() cloudUserStats: any;
  @Input() logitechSync: boolean;

  plugin: String = environment.config.dynamicText.dPlugin; // Should be plugin and not desktop plugin since room uses plugin and users use the desktop plugin
  isZoomPluginEnabled: boolean = false;
  pluginMessage: string;
  pluginStatus: number;
  pluginTextStatus: string;
  isPluginIntegrated: boolean = false;

 constructor(public ds: PopupTypeService, private getPluginService: GetPluginService ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cloudUserStats && this.cloudUserStats) {
      this.isZoomPluginEnabled = this.cloudUserStats.zoom_plugin_enabled === 1;
      this.isPluginIntegrated = this.cloudUserStats.plugin_id !== null;
      this.pluginMessage = this.getPluginMessage();
      this.pluginStatus = this.cloudUserStats.plugin_status;
      this.pluginTextStatus = this.getPluginTextStatus();
    }
  }

  getPluginMessage = () => {
    if (this.cloudUserStats.plugin_id === null) {
      return `To enable advance features, please download and install the ${this.plugin}.`;
    }
    return '';
  };

  getPluginTextStatus = () => {
    if (this.cloudUserStats) {
      if (!this.isPluginIntegrated || !this.isZoomPluginEnabled) {
        return `Disabled`;
      }
      if (this.isZoomPluginEnabled) {
        if (this.pluginStatus === 1) {
          return 'Online';
        } else {
          return 'Offline';
        }
      }
      return 'Offine';
    }
    return '';
  };

  displayDisableMessage() {
    this.ds.sendPopupType(59, { data: this.pluginMessage });
  }

  displayPluginInfo() {
    const newData = {
      ...this.cloudUserStats,
      plugin_host_info: this.cloudUserStats.plugin_host_info[0],
      plugin_memo_info: this.cloudUserStats.plugin_memo_info[0],
      plugin_network_adapters: this.cloudUserStats.plugin_network_adapters[0],
    };
    this.ds.sendPopupType(43, { data: newData });
  }
  checkSelfHealing(cloudRoomStats) {
    if (cloudRoomStats) {
      if (cloudRoomStats.plugin_application !== null && cloudRoomStats.plugin_id !== null) {
        return (
          cloudRoomStats.plugin_application[1].v1plugin_selfhealing &&
          cloudRoomStats.zoom_plugin_enabled === 1
        );
      }
      return false;
    }
    return false;
  }
  updateSelfHealing(values: any) {
    if (this.cloudUserStats.plugin_id === null) {
      this.ds.sendPopupType(52);
    } else {
      const actionParams = JSON.stringify({
        isEnabled: values.currentTarget.checked,
      });
      const actionName = 'SetSelfHealingEnabled';
      this.getPluginService
        .createAction(this.cloudUserStats.plugin_id, actionParams, actionName)
        .subscribe((result) => { });
    }
  }
}
