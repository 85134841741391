<div class="teams-service">

  <div *ngIf="!shouldShowAcceptanceLink" class="teams-service__left-column">
    <div class="teams-service__btn-and-other-info">
      <div [ngClass]="{ 'teams-service__disable': isTenantIdEmpty }" class="teams-service__btn">
        <button>
          <a href="{{ authLink }}" target="_blank">ACCEPTANCE LINK</a>
        </button>
      </div>
      <div class="teams-service__other-info">
        <strong>Note: </strong> Only Azure Administrator can enable this link
      </div>
    </div>
  </div>

  <div *ngIf="shouldShowAcceptanceLink" class="teams-service__left-column">
    <div class="teams-service__btn-and-other-info">
      <div [ngClass]="{ 'teams-service__disable': shouldShowMonitoringLink }" class="teams-service__btn">
        <button>
          <a href="{{ monitoringLink }}" target="_blank">MONITORING LINK</a>
        </button>
      </div>
      <div class="teams-service__other-info">
        <strong>Note: </strong> Only Azure Administrator can enable this link
      </div>
    </div>
  </div>

  <div class="teams-service__right-column">
    <div class="teams-service__success-msg {{ msgColor }}">
      <h3 *ngIf="shouldShowMsg">
        <!-- <h3 *ngIf="true"> -->
        {{ msg }}
      </h3>
    </div>

    <div class="teams-service__separator">
      <div class="teams-service__container">
        <div class="teams-service__label">
          TENANT ID
          <span>
            <a href="{{ href2 }}" target="_blank">
              <img src="../../../../assets/img/vs_img/eye.png" alt="" /> </a></span>
        </div>
        <input class="teams-service__input" value="tenantId" [(ngModel)]="tenantId" type="password"
          placeholder="Tenant ID" />
      </div>

      <div class="teams-service__button-blank-container">
        <div class="teams-service__blank-container"></div>
        <div class="teams-service__button-container">
          <!-- <div class="teams-service__btn">
            <button (click)="updateTenantId(false)">
              Disable Service
            </button>
          </div> -->

          <div class="teams-service__btn" [ngClass]="{ 'teams-service__disable': !isTenantIdEmpty }">
            <button (click)="updateTenantId()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>