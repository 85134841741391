<div class="collector-path-test">
  <div class="collector-path-test__header">
    <h4>Path Test</h4>
    <img *ngIf="inProgress" class="collector-path-test__header_loader" src="{{ loader }}" alt="" />
  </div>

  <div class="collector-path-test__input-container">
    <div>
      Originating IP: {{origIp}}
    </div>

    <div class="collector-path-test__input_text-with-button">
      Destination IP: <span><input type="text" [(ngModel)]="destIp" /></span>
      <button [disabled]="inProgress" (click)="run()">Run</button>
    </div>
  </div>


  <div *ngIf="gotOutput" class="cloud-user-path-detection__status-button-container">

    <div class="cloud-user-path-detection__status">
      Network Path Status
    </div>
    <div [ngClass]="{
              'cloud-user-path-detection__button--good': pathStatus === 'Good',
              'cloud-user-path-detection__button--warning': pathStatus === 'Warning',
              'cloud-user-path-detection__button--bad': pathStatus === 'Alert'
            }">
      {{ pathStatus }}
    </div>
  </div>

  <mat-expansion-panel *ngIf="gotOutput" hideToggle (opened)="expandPanel = true" [expanded]="expandPanel">

    <mat-expansion-panel-header *ngIf="!expandPanel" (click)="null">
      <div class="cloud-user-path-detection__arrow-down">
        <img src="{{ sliderDownImg }}" alt="" />
      </div>
    </mat-expansion-panel-header>

    <div class="cloud-user-path-detection__panel-content">
      <mat-tab-group>
        <mat-tab label="Path">
          <div *ngFor="let h of hops">
            <div title="{{h.tooltip}}" [ngStyle]="{'background-color':
              (h.packetLoss==0)?PathPingerEnum.GOOD:
              (h.packetLoss==100)?PathPingerEnum.BAD:
              (h.packetLoss>=1&&h.packetLoss<=5)?PathPingerEnum.WARNING:
              PathPingerEnum.ERROR}" class="cloud-user-path-detection__panel-content-item">
              {{ h.address }}
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <mat-action-row>
      <div class="cloud-user-path-detection__arrow-up" (click)="expandPanel = false">
        <img src="{{ sliderUpImg }}" alt="" />
      </div>
    </mat-action-row>

  </mat-expansion-panel>

  <hr *ngIf="!gotOutput" />
</div>