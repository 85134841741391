<div class="webportal-protocol-config">
  <div class="webportal-protocol-config__header">
    <div class="webportal-protocol-config__title">
      {{ !isLoading ? 'Available connection types' : 'Connecting...' }}
    </div>
    <img
      class="webportal-protocol-config__device-button"
      src="../../../assets/img/iot/delete-btn.png"
      alt=""
      autofocus
      (click)="onCancel()"
    />
  </div>

  <div *ngIf="!isLoading" class="webportal-protocol-config__body">
    <div class="webportal-protocol-config__item-container">
      <div
        [ngClass]="{
          'webportal-protocol-config__item': enableHttp,
          'webportal-protocol-config__item--disabled': !enableHttp
        }"
        (click)="onHttp()"
      >
        <i class="fa fa-globe"></i>
        HTTP
      </div>
      <div
        [ngClass]="{
          'webportal-protocol-config__item': enableHttps,
          'webportal-protocol-config__item--disabled': !enableHttps
        }"
        class="webportal-protocol-config__item-3"
        (click)="onHttps()"
      >
        <i class="fa fa-globe"></i>
        HTTPS
      </div>
    </div>

    <div class="webportal-protocol-config__item-container">
      <div
        [ngClass]="{
          'webportal-protocol-config__item': enableSsh,
          'webportal-protocol-config__item--disabled': !enableSsh
        }"
        class="webportal-protocol-config__item-2"
        (click)="onSsh()"
      >
        <i class="fa fa-code"></i>
        SSH
      </div>
      <div
        [ngClass]="{
          'webportal-protocol-config__item': enableRdp,
          'webportal-protocol-config__item--disabled': !enableRdp
        }"
        class="webportal-protocol-config__item-2"
        (click)="onRdp()"
      >
        <i class="fa fa-desktop"></i>
        RDP
      </div>
    </div>
  </div>

  <div *ngIf="isLoading" class="webportal-protocol-config__body-2">
    <div *ngIf="isShowConnectingText" class="webportal-protocol-config__loading-text">
      Please wait while we are establishing a connection. Request can take up to 30 seconds.
    </div>

    <div class="webportal-protocol-config__loading-img">
      <img src="{{ btnLoader }}" alt="" />
    </div>
  </div>
  <!-- <label class="webportal-protocol-config__label">Choose Connection:</label>
  <div class="webportal-protocol-config__buttons">
    <button [disabled]="isLoading" (click)="onCancel()">Cancel</button>
    <button [disabled]="isLoading" (click)="onHttp()">HTTP</button>
    <button [disabled]="isLoading" (click)="onHttps()">HTTPS</button>
    <img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
  </div>
  <label *ngIf="isLoading" class="webportal-protocol-config__delay-label"
    >Please wait while we are establishing a connection. Request can take up to 30 seconds.</label
  > -->
</div>
