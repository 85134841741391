import { Component, OnInit, Input } from '@angular/core';
import { PopupTypeService } from '../../../services/popupType.service';
import { environment } from './../../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/services/auth.service';
import { faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-info-details',
  templateUrl: './info-details.component.html',
  styleUrls: ['./info-details.component.scss'],
})
export class InfoDetailsComponent implements OnInit {
  @Input() siteInformation: any;

  constructor(private ds: PopupTypeService, private Auth: AuthService) { }
  server_url: string = environment.serverUrl;
  mspLogo: string = environment.config.logo;
  decodedToken: any;
  isSuperAdmin: any;
  companyLogo: any;
  faIcon = faUser;
  ngOnInit() {
    const token = localStorage.getItem('token');
    this.getCompanyInfo();
    const helper = new JwtHelperService();
    this.decodedToken = helper.decodeToken(token);
    this.isSuperAdmin = this.decodedToken.accesslevel == 'SUPERADMIN';
    this.ds.sendPopupType(0);
  }

  getDecodedtoken = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    return helper.decodeToken(token);
  };

  getCompanyInfo() {
    const decodedToken = this.getDecodedtoken();
    this.Auth.getCompanyInfo({ company_id: decodedToken.company_id }).subscribe((data) => {
      const [newData] = data.response;
      this.companyLogo = this.server_url + '/api/v1/images/companylogo/' + newData.logo;
    });
  }


  nullCheck(value) {
    return value ? value : '--';
  }

  sendPopupType(p) {
    if (this.decodedToken.accesslevel.toLowerCase() !== 'admin') {
      return;
    }

    this.ds.sendPopupType(p);
  }

  ngOnDestroy() {
    // clear message
    this.ds.clearPopupType();
  }
}
