import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { GetCompanyService } from '../../services/get-company.service'
import { GetIotService } from '../../services/get-iot.service'

@Component({
  selector: 'app-iot-summary-panel',
  templateUrl: './iot-summary-panel.component.html',
  styleUrls: ['./iot-summary-panel.component.scss'],
})
export class IotSummaryPanelComponent implements OnInit {
  filterRoute: String;
  filterStatus: String;
  filterAgent: number;
  agentName: String;
  summaryTitle: String;
  shouldShowAgent: Boolean;
  companyInfo: any;
  agentSummaryList: any = false;
  iotLiteEnabled: any = false;
  showIotLiteSite: any = false;
  showIotLiteSummary: any = false;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private getCopmany: GetCompanyService,
    private getIot: GetIotService
  ) {
    this.route.params.subscribe((params) => {
      this.filterAgent = params['agentId'];
      this.agentName = params['agentName'];
    });
    this.setSummaryConfig();
  }

  setSummaryConfig() {
    if (this.router.url === '/iot/allagents') {
      this.filterRoute = '0';
      this.summaryTitle = 'IoT Agents';
      this.shouldShowAgent = true;
      return;
    }

    if (this.router.url === '/iot/impactedagents') {
      this.filterRoute = '1';
      this.summaryTitle = 'IoT Agents';
      this.shouldShowAgent = true;
      return;
    }

    // MINE

    if (this.router.url === '/iot/impactedsites') {
      this.filterRoute = '1';
      this.summaryTitle = 'IoT Impacted Site';
      // this.showIotLiteSite = true;
      this.showIotLiteSummary = true;
      return;
    }

    if (this.router.url === '/iot/allsites') {
      this.filterStatus = 'ALL'; //test

      this.filterRoute = '1';
      this.summaryTitle = 'IoT All Sites';
      // this.showIotLiteSite = true;
      this.showIotLiteSummary = true;
      return;
    }


    // MINE 

    if (this.router.url === '/iot/alldevices') {
      this.filterStatus = 'ALL';
      this.shouldShowAgent = false;
      this.summaryTitle = 'IoT Devices';
      // this.showIotLiteSite = true;
      return;
    }
    
    if (this.router.url === '/iot/impacteddevices') {
      this.filterStatus = 'IMPACTED';
      this.shouldShowAgent = false;
      this.summaryTitle = 'IoT Devices';
      // this.showIotLiteSite = true;
      return;
    }
    
    
    if (this.router.url === '/iot/onlinedevices') {
      this.filterStatus = 'ONLINE';
      this.summaryTitle = 'IoT Devices';
      this.shouldShowAgent = false;
      return;
    }
    if (this.router.url === '/iot/downdevices') {
      this.filterStatus = 'OFFLINE';
      this.summaryTitle = 'IoT Device';
      this.shouldShowAgent = false;
      return;
    }
    if (this.router.url === '/iot/offlinedevices') {
      this.filterStatus = 'OFFLINE';
      this.summaryTitle = 'IoT Device';
      this.shouldShowAgent = false;
      return;
    }
    if (this.router.url === '/iot/pauseddevices') {
      this.filterStatus = 'PAUSED';
      this.summaryTitle = 'IoT Device';
      this.shouldShowAgent = false;
      return;
    }

    if (this.router.url.indexOf('/iot/agentdevices/') >= 0) {
      this.filterStatus = 'ALL';
      this.summaryTitle = `Iot Agent: ${this.agentName}`;
      this.shouldShowAgent = false;
      return;
    }
  }

  ngOnInit() {
    this.getIotLiteStatus();
    this.getCompanyInfo();
  }

  getCompanyInfo = () => {
    this.getCopmany.apiGetCompanyInfo().subscribe((data) => {
      if (data.status === 200) {
        this.companyInfo = data.response;
      }
    });
  };

  getAllIotAgentsSummary = () => {
    const filterAgent = this.filterAgent ? this.filterAgent : -1;
    this.getIot.getAllIotAgentsSummary(filterAgent).subscribe((data) => {
      this.agentSummaryList = data;
      // console.log('this.agentSummaryList: ', this.agentSummaryList)
    });
  };

  getAllIotLiteSummary = () => {
    this.getIot.getAllIotLiteSummary().subscribe((data) => {
      this.agentSummaryList = data;
      // console.log('this.agentSummaryList: ', this.agentSummaryList)
    });
  };


  onSummaryEvent = ($event) => {
    const { eventType, data } = $event;
    if (eventType === 'device') {
      // console.log('data: ', data);
      this.filterStatus = data;
    }
  };


  getIotLiteStatus = () => {
    this.getIot.getIotLiteStatus().subscribe((data) => {
      let [res] = data.response;
      this.iotLiteEnabled = res.is_iot_lite;

      if (this.iotLiteEnabled) {
        this.getAllIotLiteSummary();
      } else {
        this.getAllIotAgentsSummary();
      }
    });
  }
}
