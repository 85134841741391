import { Component, Inject, EventEmitter } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { GetVideoService } from '../../../services/get-video.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventEmitterGlobalService } from '../../../services/event-emitter-global.service';
import * as moment from 'moment-timezone';




@Component({
  selector: 'app-video-device-update-dialog',
  templateUrl: './video-device-update-dialog.component.html',
  styleUrls: ['./video-device-update-dialog.component.scss']
})
export class VideoDeviceUpdateDialogComponent {
  actionDetails: any
  actionCount: any;
  videoDetails: any;

  headerMessage: any = ''

  testarr1: any = {
    "status": "UPDATING",
    "message": "update firmware is ongoing",
    "action_id": 13062,
    "fimware_info": [
      {
        "modUid": "3679748592",
        "progressState": "LOCAL_UPDATING",
        "softUpdateStage": "STAGEUNKNOWN",
        "softUpdateStatus": "UPDATE_COMPLETE",
        "percentageComplete": 0
      },
      {
        "modUid": "local-camera",
        "progressState": "LOCAL_UPDATING",
        "softUpdateStage": "STAGEUNKNOWN",
        "softUpdateStatus": "NO_UPDATE",
        "percentageComplete": 0
      },
      {
        "modUid": "local-remote",
        "progressState": "LOCAL_UPDATING",
        "softUpdateStage": "STAGEUNKNOWN",
        "softUpdateStatus": "NO_UPDATE",
        "percentageComplete": 0
      },
      {
        "modUid": "local-system",
        "progressState": "LOCAL_UPDATING",
        "softUpdateStage": "DOWNLOADING_PACKAGE",
        "softUpdateStatus": "UPDATING",
        "percentageComplete": 1
      }
    ]
  }

  test2: any = [
    [
      {
        "modUid": "3679748592",
        "version": "3.3.2.210446",
        "updateRequired": "1",
        "softwareKeyRequired": "0"
      },
      {
        "modUid": "local-camera",
        "version": "",
        "updateRequired": "0",
        "softwareKeyRequired": "0"
      },
      {
        "modUid": "local-remote",
        "version": "",
        "updateRequired": "0",
        "softwareKeyRequired": "0"
      },
      {
        "modUid": "local-system",
        "version": "3.3.2-286154",
        "updateRequired": "0",
        "softwareKeyRequired": "0"
      }
    ]
  ]


  isShowUpdate: any = false;
  isShowComplete: any = false;
  isShowFail: any = false
  isShowOpen: any = false;

  localCamera: any;
  localRemote: any;
  localSystem: any
  localSystem2: any



  constructor(
    private EventEmitterGlobal: EventEmitterGlobalService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<VideoDeviceUpdateDialogComponent>,
    private Auth: AuthService,
    private GetVideo: GetVideoService,

  ) {
    if (data) {
      const {
        config,
      } = data;
      this.videoDetails = config
      this.checkDeviceState(config)
    }
  }

  convertTimeStamp = (time) => {
    return time === null ? '--' : moment(time).format('l, hh:mm a');
  }


  checkDeviceState = (params) => {
    const { device_state, update_action_id, firmwares } = params;
    let checkForUpdate = '';

    if (firmwares.length !== 0) {
      let { updateRequired } = firmwares.filter(e => e.modUid === 'local-system')[0];
      checkForUpdate = updateRequired;

    }


    if (device_state === 'UPDATING') {
      this.headerMessage = 'Device update in progress'
      this.resetButton();
      this.isShowUpdate = true;
      // console.log('isShowUpdate', this.isShowUpdate)
      // console.log('headerMessage', this.headerMessage)
      this.checkStatus(update_action_id)
    } else {
      if (checkForUpdate == '0') {
        this.headerMessage = 'Device up to date'
        this.resetButton();
        this.isShowUpdate = false;
        this.checkUpdatingState
      } else {
        this.headerMessage = 'Device update available'
        this.resetButton();
        this.isShowUpdate = false;
      }
    }

    // }

  }



  checkParams = (params) => {
    return params === "STAGEUNKNOWN" || params === "NO_UPDATE" ? '--' : params
  }

  setTimer: any;
  checkStatus = (id) => {
    const idData = {
      action_id: id,
    };
    this.Auth.postDeviceActionId(idData).subscribe((actionData) => {
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 100) {
        this.actionCount = 0;

        // console.log('clear time out from action count')
        this.clearTimeout();
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'PROCESSING' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus(id);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === 'PROCESSING') {
        const [newData] = actionData.response
        // console.log(newData)
        const rk = this;
        this.resetButton()
        this.isShowUpdate = true;

        const { fimware_info } = newData.results;
        this.checkUpdatingState(fimware_info)

        this.setTimer = setTimeout(function () {
          rk.checkStatus(id);
        }, 1000);
        this.actionCount++;
        return;

      } else if (actionData.response[0].status === 'FAILED') {

        this.resetButton();
        this.isShowUpdate = false;
        this.updateActionStatus();
        // console.log('clear time out from faile')
        this.EventEmitterGlobal.watcherParams('callVideoApiData')
        this.clearTimeout();
        //hard part

        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        this.EventEmitterGlobal.watcherParams('callVideoApiData')
        this.resetButton();
        const [newData] = actionData.response

        this.updateActionStatus();
        this.headerMessage = 'Device up to date'
        this.resetButton();
        this.isShowUpdate = false;
        // console.log('clear time out from complete')

        this.clearTimeout();

        return;
      }
    });
  }

  getUserInfo = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    // console.log(helper.decodeToken(token))
    const { company_id, user_id } = helper.decodeToken(token);
    return { company_id, user_id };
  };


  updateActionStatus = () => {
    // console.log('calling updateActionStatus')
    const { company_id } = this.getUserInfo()
    this.GetVideo.updateActionStatus({ company_id, video_device_id: this.videoDetails.video_device_id }).subscribe(data => {
      // console.log('updateActionStatus: ', data)
    }, err => {

    })
  }
  resetButton = () => {
    this.isShowUpdate = false;

  }

  checkUpdatingState = (params) => {
    if (params.length !== 0) {
      this.localSystem = params.filter(e => e.modUid === 'local-system')[0]
      this.localSystem2 = params[0];
      this.localCamera = params.filter(e => e.modUid === 'local-camera')[0]
      this.localRemote = params.filter(e => e.modUid === 'local-remote')[0]
    }
  }

  // ngOnDestroy(): void {
  //   // console.log('clear time out from destroy')

  //   this.clearTimeout();
  // }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

  closeDialog = () => {
    this.dialogRef.close(true);
  };

  // end
}
