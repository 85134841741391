<div class="popup-content">
  <div class="container-fluid" style="padding-top: 30px; padding-bottom: 30px;">
    <div class="row lower-row">
      <div
        *ngIf="meetingsResponseData.length > 0"
        class=".col-md-10 .offset-md-2"
        style="width: 100% !important;"
      >
        <div
          style="
            width: 1500px;
            height: 220px;
            overflow-y: hidden;
            overflow-x: scroll;
            white-space: nowrap;
          "
        >
          <ng-container>
            <div
              class="card mr-1 ml-1"
              style="
                min-height: 200px;
                width: 300px !important;
                display: inline-block;
                padding: 5px;
              "
              *ngFor="let recording of meetingsResponseData; index as n"
            >
              <div style="text-align: center;">
                <a>
                  <img
                    class="video-icon"
                    style="width: 120px; height: auto; margin-top: 5px;"
                    (click)="openNewWindow(recording.files[0].play_url)"
                    alt="Video Recordings"
                    src="../../../assets/img/cloud/film_strip.png"
                  />
                </a>
                <p class="card-title" style="margin-top: 5px;">Topic: {{ recording.topic }}</p>
                <!-- <p>Record Date: {{ printTime(recording.start_time) }}</p> -->
                <p>Record Date: {{ recording.start_time | dateTimeZone : 'MM-DD-YYYY hh:mma' }}</p>
                <p>Duration: {{ printDuration(recording.duration) }}</p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="meetingsResponseData.length <= 0" class="col-12 d-flex justify-content-center">
        <h5>No Recordings</h5>
      </div>
    </div>
  </div>
</div>
