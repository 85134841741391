import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PopupTypeService } from '../../services/popupType.service';
import { Subscription } from 'rxjs/Subscription';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

@Component({
  selector: 'app-enter-pin',
  templateUrl: './enter-pin.component.html',
  styleUrls: ['./enter-pin.component.scss'],
})
export class EnterPinComponent implements OnInit {
  @Input() popUpEmitter: any;

  siteId: any;
  subscription: Subscription;
  codeForm: FormGroup;
  submitted = false;
  pinCodeResponse: any = [];

  company_id: number;
  pairCode: number;
  passfeedback: any;
  inputbox1: any = '';
  inputbox2: any;
  inputbox3: any;
  inputbox4: any;
  inputbox5: any;
  inputbox6: any;
  coloralert: any;

  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private router: Router,
    private ds: PopupTypeService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.checkForm();
  }

  checkForm() {
    this.codeForm = this.formBuilder.group({
      value1: ['', Validators.required],
      value2: ['', Validators.required],
      value3: ['', Validators.required],
      value4: ['', Validators.required],
      value5: ['', Validators.required],
      value6: ['', Validators.required],
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.codeForm.invalid) {
      return;
    }
  }
  goNextInput(n) {
    (<HTMLInputElement>document.getElementById('value' + n)).focus();
  }

  model: any = {};
  get f() {
    return this.codeForm.controls;
  }

  async delay(ms: number) {
    await new Promise((resolve) => setTimeout(() => resolve(''), ms));
  }

  verifiyCode(event) {
    event.preventDefault();
    const target = event.target;

    this.siteId = localStorage.getItem('site_id');

    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    this.inputbox1 = target.querySelector('#value1');
    this.pairCode =
      target.querySelector('#value1').value +
      target.querySelector('#value2').value +
      target.querySelector('#value3').value +
      target.querySelector('#value4').value +
      target.querySelector('#value5').value +
      target.querySelector('#value6').value;

    const postData = {
      company_id: companyId,
      site_id: parseInt(this.siteId),
      pair_code: Number(this.pairCode),
    };

    this.Auth.verifyCollector(postData).subscribe((data) => {
      this.passfeedback = <HTMLInputElement>document.getElementById('feedback');
      this.inputbox1 = <HTMLInputElement>document.getElementById('value1');
      this.inputbox2 = <HTMLInputElement>document.getElementById('value2');
      this.inputbox3 = <HTMLInputElement>document.getElementById('value3');
      this.inputbox4 = <HTMLInputElement>document.getElementById('value4');
      this.inputbox5 = <HTMLInputElement>document.getElementById('value5');
      this.inputbox6 = <HTMLInputElement>document.getElementById('value6');
      this.coloralert = <HTMLInputElement>document.getElementById('colorAlert');
      this.pinCodeResponse = data.response[0].status;
      if (data.response[0] == undefined) {
      } else if (data.response[0].status === 'OK') {
        this.inputbox1.style.color = 'green';
        this.inputbox2.style.color = 'green';
        this.inputbox3.style.color = 'green';
        this.inputbox4.style.color = 'green';
        this.inputbox5.style.color = 'green';
        this.inputbox6.style.color = 'green';
        this.coloralert.style.backgroundColor = 'green';
        this.passfeedback.innerHTML = '';
        this.coloralert.innerHTML = 'Pin accepted, thank you.';
        localStorage.setItem('reloadSiteData', '1');
        localStorage.setItem('keyDownload', '1');
        this.popUpEmitter('refreshGetSiteApiData');

        this.delay(3000).then((any) => {
          this.ds.sendPopupType(99);
          localStorage.setItem('overLayMembersMove', '1');
          localStorage.setItem('reloadSiteData', '1');
        });
      } else {
        if (data.response[0].status === 'Error') {
          this.passfeedback.innerHTML = data.response[0].message;
          this.inputbox1.style.color = 'red';
          this.inputbox2.style.color = 'red';
          this.inputbox3.style.color = 'red';
          this.inputbox4.style.color = 'red';
          this.inputbox5.style.color = 'red';
          this.inputbox6.style.color = 'red';
          this.coloralert.style.backgroundColor = 'red';
          this.coloralert.innerHTML = 'Pin error, please re-try.';
          localStorage.setItem('reloadSiteData', '1');
        } else {
          // console.log('login in');
        }
      }
    });
  }
  sendPopupType(p) {
    this.ds.sendPopupType(p);
    // console.log(p);
  }

  ngOnDestroy() {
    // clear message
    this.ds.clearPopupType();
  }
}
