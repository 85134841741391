<div class="info-chartSite pt-3 mb-3 chart-container">
  <div style="text-align: center">
      <button (click)="set60m();" [disabled]="currentChart=='60m'">
          60m
      </button>
      <button (click)="set24h();" [disabled]="currentChart=='24h'">
          24h
      </button>
      <button (click)="set7d();" [disabled]="currentChart=='7d'">
          7d
      </button>
      
      <label class="switch">
          <input type="checkbox" checked (click)="toggleView(1)">
          <span class="slider round">
              <span class="paused">chart</span>
              <span class="enabled">list</span>
          </span>
      </label>
  </div>
  <canvas id="myChartSite" width="1150px" height="350px" [ngStyle]="{display: toggleValue==0 ? 'block' : 'none'}"></canvas>
  <div class="list-view" style="display:none;" [ngStyle]="{display: toggleValue==1 ? 'block' : 'none'}">
      <div class="row">
          <div class="col-12 px-0 filter-table">
              <app-cloud-all-rooms-list [cloudRoomData]="cloudRoomSummary"></app-cloud-all-rooms-list>
          </div>
      </div>
  </div>
  <!-- <div style="font-size: .5em">*** Chart contains sample data</div> -->
</div>