import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
 
@Injectable()
export class MainMenuTypeService {
    private subject = new Subject<any>();
 
    sendMainMenuType(message: number) {
        this.subject.next(message);
    }
 
    clearMainMenuType() {
        this.subject.next();
    }
 
    getMainMenuType(): Observable<any> {
        return this.subject.asObservable();
    }
}