<!-- stats buttons -->
<div class="row">
	<div class="col-12 pl-1">
		<h5>Live Stats</h5>
	</div>
	<div class="col-3 btn-col pl-0 pr-1">
		<app-btn-reg-stats [count]="audioDeviceData?.impacted" label='Impacted' color='gray'
			(click)="setFilter('impacted')" [ngClass]="{'outlineBtn': activeFilter == 'impacted'}"></app-btn-reg-stats>
	</div>
	<div class="col-3 btn-col pl-0 pr-1">
		<app-btn-reg-stats [count]="audioDeviceData?.on_call" label='On Call' color='gray'
			(click)="setFilter('on_call')" [ngClass]="{'outlineBtn': activeFilter == 'on_call'}"></app-btn-reg-stats>
	</div>
	<div class="col-3 btn-col pl-0 pr-1">
		<app-btn-reg-stats-twodec [count]="audioDeviceData?.total_mos" label='MOS' color='gray' isbutton='not'>
		</app-btn-reg-stats-twodec>
	</div>
	<div class="col-3 btn-col pl-0 pr-1">
		<app-btn-reg-stats [count]="audioDeviceData?.all_devices" label='All Devices' color='gray' isbutton='not'>
		</app-btn-reg-stats>
	</div>
</div>
<!-- hr line space -->
<div class="row">
	<div class="col-12 pl-0 pr-1 my-2">
		<hr>
	</div>
</div>
<!-- stats buttons -->
<div class="row">
	<div class="col-9 pl-0 pr-1">
		<app-chart-audio-twentyfourhours-user [isOn]="true"></app-chart-audio-twentyfourhours-user>
	</div>
	<div class="col-3">
		<div class="row">
			<div class="col-12 btn-col pl-0 pr-1">
				<app-btn-reg-stats [count]="audioDeviceData?.total_calls" label='Total Calls' color='gray'
					isbutton='not'></app-btn-reg-stats>
			</div>
		</div>
		<div class="row">
			<div class="col-12 btn-col pl-0 pr-1">
				<app-btn-reg-stats [count]="audioDeviceData?.total_incidents" label='Incidents' color='gray'
					isbutton='not'></app-btn-reg-stats>
			</div>
		</div>
	</div>
</div>
<!-- hr line space -->
<div class="row">
	<div class="col-12 pl-0 pr-1">
		<hr>
	</div>
</div>
<!-- stats buttons -->
<div class="row">
	<div class="col-4 pl-0 pr-1">
		<img src="../../assets/img/audio/team.png" alt="team" class="non-compatibility-icons" />
		<app-btn-reg-stats [count]="audioDeviceData?.total_teams" label="Devices Noncompliant" color='gray'
			style="margin-top:5px;" (click)="setFilter('teams_nc')"
			[ngClass]="{'outlineBtn': activeFilter == 'teams_nc'}"></app-btn-reg-stats>
	</div>

	<div class="col-4 pl-0 pr-1 text-center">
		<img src="../../assets/img/zoom_logo.png@1X.png" alt="zoom" class="non-compatibility-icons--zoom" />
		<app-btn-reg-stats [count]="audioDeviceData?.total_zoom" label="Devices Noncompliant" color='gray'
			style="margin-top:5px;" (click)="setFilter('zoom_nc')"
			[ngClass]="{'outlineBtn': activeFilter == 'zoom_nc'}"></app-btn-reg-stats>
	</div>

	<div class="col-4 pl-0 pr-1">
		<img src="../../assets/img/audio/webex_teams.png" alt="webex" class="non-compatibility-icons" />
		<app-btn-reg-stats [count]="audioDeviceData?.total_webex" label="Devices Noncompliant" color='gray'
			style="margin-top:5px;" (click)="setFilter('webex_nc')"
			[ngClass]="{'outlineBtn': activeFilter == 'webex_nc'}"></app-btn-reg-stats>
	</div>
</div>