import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment-timezone'
import { AuthService } from '../services/auth.service'

@Pipe({
	name: 'dateTimeZone',
	pure: false,
})
export class DateTimeZonePipe implements PipeTransform {
	constructor(private auth: AuthService) {}

	// transform(value: any): any {
	//   if (value === null) {
	//     return '--';
	//   }
	//   return moment(value)
	//     .utc()
	//     .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
	//     .format('MM-DD-YYYY hh:mma');
	// }

	transform(value: any, format?: string, showZone = true): any {
    if (!value || !this.auth?.timezone) return ''
    
    if (value === '--') return value

		let momentDate
		// Check if input is a Unix timestamp e.g. '1706896800' in scheduled meetings pop-up
		if (/^\d+$/.test(value))
			momentDate = moment.unix(value)
		else
			momentDate = value // other formats e.g. '2019-08-14 20:56:48'

		if (showZone)
			return (
				moment(momentDate)
					.tz(this.auth?.timezone)
					.format(format ?? 'MMM D, YYYY') + ` (${this.auth.timezone})`
			)
		else
			return moment(momentDate)
				.tz(this.auth?.timezone)
				.format(format ?? 'MMM D, YYYY')
	}
}
