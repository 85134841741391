import { Component, AfterViewInit, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
// import { DataService } from './../data.service';
import * as Chart from 'chart.js';
import { AuthService } from '../../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PopupSendDataService } from '../../../services/popup-send-data.service'
import { GetCloudService } from '../../../services/get-cloud.service';
import { RefreshApiService } from '../../../services/refresh-api.service';
import { Subscription } from 'rxjs'
import * as moment from 'moment-timezone';
import { TableColorRowComponent } from '../../table-icons/table-color-row/table-color-row.component'

@Component({
  selector: 'app-chart-audio-dna',
  templateUrl: './chart-audio-dna.component.html',
  styleUrls: ['./chart-audio-dna.component.scss']
})
export class ChartAudioDnaComponent implements AfterViewInit, OnChanges {
  id: number;
  @Input() deviceId: any;
  @Input() popupState: any;
  @Input() data: any;
  @Input() deviceType: any;
  chartApiData: any;
  cloudInsTableSetting: any;
  printTableData: any = [];
  printMode: boolean = false;
  incidentType:boolean = false;
  canvas: any;
  ctx: any;
  audioAllDataChart: any;
  currentChart:string = "24h";
  chartData:any;
  allInsListData=[];
  toggleValue: any = 0;
  activeBtn:any = 0;
  filterListner: Subscription;
  duration:number= 1;
  chartHideData:any = {
    incident: false,
    mos: true,
    jitter: true,
    latency: true,
    packetLoss: true
  };
  chartUnit:string = 'minute';
  chartUnitStepSize: number = 2;

  constructor(
    private getCloudDataService: GetCloudService,
    private sendPopupDataService: PopupSendDataService,
    public http: HttpClient,
    private Auth: AuthService,
    private refreshApiService: RefreshApiService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.popupState.currentValue == 'open') {
      let isFirstLoad = true; 
      this.showData(this.data.section);
      this.createChart();
      this.fetchChartData();
      this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
        if(m && !isFirstLoad){
          this.fetchChartData();
        }
        isFirstLoad = false;
      });
    } else {
      setTimeout(() => {
        this.showData('reset');
        this.audioAllDataChart.destroy();
        this.filterListner.unsubscribe();
      }, 500);
    }
  }
  createChart():void{
    this.chartData = {
      datasets: [
        {
          label: 'incident',
          data: [],
          backgroundColor:'rgb(184, 58, 36, 1)',
          pointBackgroundColor: 'rgb(184, 58, 36, 1)',
          pointBorderColor: 'rgb(184, 58, 36, 1)',
          borderWidth: 1,
          showLine: false,
          hidden: this.chartHideData.incident
        },
        {
          label: 'mos',
          data: [],
          pointBackgroundColor: 'rgb(184, 58, 36, 0)',
          pointBorderColor: 'rgb(184, 58, 36, 0)',
          backgroundColor: 'rgb(255, 184, 0, .5)',
          borderWidth: 1,
          hidden: this.chartHideData.mos
        },
        {
          label: 'jitter (msec)',
          data: [],
          pointBackgroundColor: 'rgb(184, 58, 36, 0)',
          pointBorderColor: 'rgb(184, 58, 36, 0)',
          backgroundColor: 'rgb(0, 128, 255, .5)',
          borderWidth: 0,
          hidden: this.chartHideData.jitter
        },
        {
          label: 'latency (msec)',
          data: [],
          pointBackgroundColor: 'rgb(184, 58, 36, 0)',
          pointBorderColor: 'rgb(184, 58, 36, 0)',
          backgroundColor: 'rgb(142, 204, 80, .5)'
          ,
          borderColor:'rgb(142, 204, 80, 1)',
          borderWidth: 1,
          hidden: this.chartHideData.latency
        },
        {
          label: 'packet loss (%)',
          data: [],
          backgroundColor: 'rgb(142, 104, 80, .5)',
          pointBackgroundColor: 'rgb(184, 58, 36, 0)',
          pointBorderColor: 'rgb(184, 58, 36, 0)',
          borderWidth: 1,
          hidden: this.chartHideData.packetLoss
        }
      ]
    };
    this.canvas = document.getElementById('audioAllDataChart');
    this.ctx = this.canvas.getContext('2d');
    this.audioAllDataChart = new Chart(this.ctx, {
      type: 'line',
      // data: this.data24h,
      options: {
        responsive: false,
        display: true,
        fontSize: '6px',
        padding: '0px',
        maintainAspectRatio: false,
        elements: {
          line: {
              tension: 0, // disables bezier curves
          }
        }
      }
    });
    
    this.currentChart = "60m";
    this.activeBtn = 0;
    this.toggleValue = 0;
    this.toggleBtn(1);
}

  ngAfterViewInit() {
  }

  showData(section):void{
    switch(section){
    case 'mos':
      this.chartHideData.mos = false;
      break;
    case 'jitter':
      this.chartHideData.jitter = false;
      break;
    case 'packetloss':
      this.chartHideData.packetLoss = false;
      break;
    case 'latency':
      this.chartHideData.latency = false;
      break;
    default:
      this.chartHideData = {
        incident: false,
        mos: true,
        jitter: true,
        latency: true,
        packetLoss: true
      };
      break;
    }

  }

  fetchChartData() {
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    let deviceDetails = {
      company_id: decodedToken.company_id,
      device_id: this.data.deviceId,
      duration: this.duration
    }

    // this.chartApiData = [];
    this.chartApiData = [];
    this.Auth.getAllAudioDeviceChartData(deviceDetails).subscribe((data) => { 
      let latency = data['response'][0].total_latency;
      latency.sort(this.custom_sort);
      let jitter = data['response'][0].total_jitter;
      jitter.sort(this.custom_sort);
      let mos = data['response'][0].total_mos;
      mos.sort(this.custom_sort);
      let packetloss = data['response'][0].total_packetloss;
      packetloss.sort(this.custom_sort);
      let incidents = data['response'][0].incidents;
      this.chartData.datasets[0].data = incidents;
      this.chartData.datasets[1].data = mos;
      this.chartData.datasets[2].data = jitter;
      this.chartData.datasets[3].data = latency;
      this.chartData.datasets[4].data = packetloss;
      this.loadChart();
    });
  
  
    // this.allInsListData = [];
    this.Auth.getAllAudioChartListData(deviceDetails).subscribe((data) => {
      this.allInsListData = data['response'];
      const arr3 = [];
      for (let k = 0; k < this.allInsListData.length; k++) {
        arr3.push(this.allInsListData[k]);
        let timestamp = arr3[k].timestamp;
        arr3[k].timestamp = moment(timestamp).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
      }
    
      // this.cloudInsData.load(arr3);
      this.printTableData = arr3;
    });
  }

  custom_sort(a, b) {
    return new Date(a.x).getTime() - new Date(b.x).getTime();
  }
  loadChart() {
    this.audioAllDataChart.options.scales = {
      xAxes: [{
        type: "time",
        display: true,
        scaleLabel: {
          display: true,
          labelString: this.chartUnit === 'day' ? 'Date' : 'Time',
        },
        time: {
          unit: this.chartUnit
        }
      }],
      yAxes: [{
          ticks: {
              beginAtZero:true
          }
      }],
    };
    this.audioAllDataChart.options.tooltips = {
      callbacks: {
        label: function(tooltipItem, chart) {
          if (tooltipItem.datasetIndex == 0) {
            return "ID: " + chart.datasets[0].data[tooltipItem.index].incident_id;
          }
          else {
            return tooltipItem.yLabel
          }
        },
        beforeLabel: function(tooltipItem, chart) {
          switch(tooltipItem.datasetIndex) {
            case 0: 
              return "Incidents";
            case 1: 
              return "Mos";
            case 2: 
              return "Jitter";
            case 3: 
              return "Latency";
            case 3: 
              return "Packetloss";
          }
        },
        afterLabel: function(tooltipItem, chart) {
          if (tooltipItem.datasetIndex == 0) {
            let triggers = chart.datasets[0].data[tooltipItem.index].triggers.split(" - ");
            triggers.splice(0,1);
            return triggers;
          }
          else {
            return ""
          }
        },
        title: function(tooltipItem, chart) {
          return moment(tooltipItem[0].xLabel).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
        },
      }
    }

    this.audioAllDataChart.data.datasets = this.chartData.datasets;
    this.audioAllDataChart.update({duration: 500});
  }

  set60m() {
    if (this.currentChart != "60m") {
      this.currentChart = "60m";
      this.chartUnit = 'minute';
      this.chartUnitStepSize = 1;
      this.duration = 1;
      this.toggleBtn(1);
      this.fetchChartData();
    }
  }
  set24h() {
    if (this.currentChart != "24h") {
      this.currentChart = "24h";
      this.toggleBtn(2);
      this.duration = 24;
      this.chartUnit = 'hour';
      this.chartUnitStepSize = 1;
      this.fetchChartData();
    }
  }
  set7d() {
    if (this.currentChart != "7d") {
      this.currentChart = "7d";
      this.toggleBtn(3);
      this.duration = 7;
      this.chartUnit = 'day';
      this.chartUnitStepSize = 1;
      this.fetchChartData();
    }
  }

  toggleView(n) {
    if (this.toggleValue == n) {
      this.toggleValue = 0;
    } else {
      this.toggleValue = n;
    }
  }

  toggleBtn(n) {
    if (this.activeBtn == n) {
      this.activeBtn = 0;
    } else {
      this.activeBtn = n;
    }
  }

  // print codes here
  print() {
    var divId
    if (this.toggleValue == 0) { // chart
      this.printCanvas()
    } else { // list
      this.printList();
    }
  }

  printCanvas() {
    let printContents = (<HTMLCanvasElement>document.getElementById('audioAllDataChart')).toDataURL();
    let popupWin;

    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print Chart</title>
        </head>
        <body onload="window.print(); window.close()">
          <img style='width: 100%;' src='${printContents}'/>
        </body>
      </html>
    `);
    popupWin.document.close();
  }

  printList() {
    this.printMode = true;
    setTimeout(() => {
      let printContents, stylesHtml, linksHtml, popupWin;

      printContents = document.getElementById('printTable').innerHTML;
      stylesHtml = this.getTagsHtml('style');
      linksHtml = this.getTagsHtml('link');

      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Print Report</title>
            ${stylesHtml}
            ${linksHtml}

          </head>
          <body onload="window.print(); window.close()">
            ${printContents}
          </body>
        </html>
      `);
      popupWin.document.close();
      this.printMode = false;
    }, 500);
  }

  // Helper for printDivV2 - used to preserve styles and links for printed div
  private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string {
    const htmlStr: string[] = [];
    const elements = document.getElementsByTagName(tagName);

    for (let idx = 0; idx < elements.length; idx++) {
        htmlStr.push(elements[idx].outerHTML);
    }

    return htmlStr.join('\r\n');
  }
}
