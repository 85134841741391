import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { GetIotService } from '../../services/get-iot.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IotLiteDomotzErrorMessageComponent } from '../../../app/iot/dialogs/iot-lite-domotz-error-message/iot-lite-domotz-error-message.component';
import { JwtHelperService } from '@auth0/angular-jwt'

@Component({
  selector: 'app-logitech-main-menu',
  templateUrl: './logitech-main-menu.component.html',
  styleUrls: ['./logitech-main-menu.component.scss']
})
export class LogitechMainMenuComponent implements OnInit {
  activePanel: Number = 1;
  iotLiteEnabled: any = false;
  isDomotzEnabled: any = false;
  compId: any = "";
  logitechAlertMonitorDtls = [];
  logitechAlert: any;
  logitechMonitor: any;
	disableToggle: boolean = false;
	disableNotification: boolean = false;
  constructor(
    private Auth: AuthService,
    private GetIot: GetIotService,
    public dialog: MatDialog,
  ) {

  }

  ngOnInit() {
    const token = localStorage.getItem("token");
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.compId = decodedToken.company_id;
    this.getAlertMonitorStatus();
  }

  selectActivePanel = (opt) => (this.activePanel = opt);

  getAlertMonitorStatus() {
		const cmpData = {
			company_id: this.compId,
		}

		this.logitechAlertMonitorDtls = []
		this.Auth.getDeviceAlertMonitorStatus(cmpData).subscribe(data => {
			this.logitechAlertMonitorDtls = data["response"];
      this.logitechAlert = +this.logitechAlertMonitorDtls[0].logitech_alerts;
      this.logitechMonitor = 1 - +this.logitechAlertMonitorDtls[0].logitech_paused;
			if (this.disableNotification) this.disableNotification = false;
			if (this.disableToggle) this.disableToggle = false;
		})
	}
  toggleLogitechMonitor() {
		this.disableToggle = true
		this.logitechMonitor = 1 - this.logitechMonitor

		const cmpData2 = {
			company_id: this.compId,
			device_type: 'logitech',
		}

		if (this.logitechMonitor) {
			this.Auth.setDeviceTypeMonitorOn(cmpData2).subscribe(() => {
				this.getAlertMonitorStatus()
			})
		} else {
			this.Auth.setDeviceTypeMonitorOff(cmpData2).subscribe(() => {
				this.getAlertMonitorStatus()
			})
		}
	}

	toggleLogitechAlerts() {
		this.disableNotification = true;
		this.logitechAlert = 1 - this.logitechAlert

		const cmpData1 = {
			company_id: this.compId,
			device_type: 'logitech',
		}

		if (this.logitechAlert) {
			this.Auth.setDeviceTypeNotifyOn(cmpData1).subscribe(() => {
				this.getAlertMonitorStatus()
			})
		} else {
			this.Auth.setDeviceTypeNotifyOff(cmpData1).subscribe(() => {
				this.getAlertMonitorStatus()
			})
		}
	}

}