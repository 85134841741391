import { Injectable, Input } from '@angular/core';
import { AuthService } from './auth.service';
import { GetPluginService } from './get-plugin.service';
import { GetDesktopPluginService } from './get-desktop-plugin.service';

@Injectable({
  providedIn: 'root',
})
export class ZoomPluginActionService {
  visibility_one_loader: boolean = false;
  deviceButtonLoader: boolean = false;
  actionCount: number = 0;
  actionResult: any;

  constructor(
    private Auth: AuthService,
    public GetPlugin: GetPluginService,
    public GetDesktopPlugin: GetDesktopPluginService
  ) { }

  checkActionStatus(actionId, cb?: Function) {
    const idData = {
      action_id: actionId,
    };

    this.GetPlugin.getPluginAction(idData).subscribe((actionData) => {
      if (this.actionCount === 75) {
        this.visibility_one_loader = false;
        if(cb) cb('OPEN')
        return;
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        setTimeout(function () {
          rk.checkActionStatus(actionId, cb);
        }, 1000);
        this.actionCount++;
      } else if (
        actionData.response[0].status === 'FAILED' ||
        actionData.response[0].status === 'COMPLETE'
      ) {
        this.actionResult = {};
        if (actionData.response[0].results) {
          this.actionResult = actionData.response[0].results;
        }
        if (cb) cb(actionData.response[0].status, this.actionResult)
        this.visibility_one_loader = false;
        return 1;
      }
    });
  }

  checkDesktopPluginActionStatus(actionId) {
    this.GetDesktopPlugin.getDesktopPluginAction(actionId).subscribe((actionData) => {
      if (this.actionCount === 75) {
        this.visibility_one_loader = false;
        this.deviceButtonLoader = false;
        return;
      }
      if (actionData.status !== 'COMPLETE' && actionData.status !== 'FAILED') {
        const rk = this;
        setTimeout(function () {
          rk.checkDesktopPluginActionStatus(actionId);
        }, 1000);
        this.actionCount++;
      } else if (actionData.status === 'FAILED' || actionData.status === 'COMPLETE') {
        this.actionResult = {};
        if (actionData.results) {
          this.actionResult = actionData.results;
        }

        this.visibility_one_loader = false;
        this.deviceButtonLoader = false;

        return;
      }
    });
  }

  setActionCount = () => {
    this.actionCount = 0;
  };

  clearActionResult = () => {
    this.actionResult = {};
  };
}
