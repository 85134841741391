import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { GetTeamsService } from 'src/app/services/get-teams.service';

@Component({
  selector: 'app-teams-call-records',
  templateUrl: './teams-call-records.component.html',
  styleUrls: ['./teams-call-records.component.scss']
})
export class TeamsCallRecordsComponent implements OnInit {
  @Input() deviceId: any;
  @Input() data: any;
  records: any[];
  beingLoaded: boolean;

  constructor(
    private teams: GetTeamsService
  ) { }

  ngOnInit(): void {
    this.getRecords();
  }
  async getRecords() {
    try {
      const urlObj = new URL(window.location.href);
      const roomId = (urlObj.pathname || '').split("/").pop();
      console.log("Before fetching data", roomId);
      this.beingLoaded = true;
      const results: any = await this.teams.getMeetingRecords(roomId).toPromise();
      this.records = results.data;
      console.log("Get records", results);
    } catch (error) {
      console.log("An error occured", error)
    } finally {
      this.beingLoaded = false;
    }
  }
}
