import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-system-temp',
  templateUrl: './system-temp.component.html',
  styleUrls: ['./system-temp.component.scss']
})
export class SystemTempComponent implements OnInit {
 @Input() manufacturer: string;
 @Input() temperature: number;
  constructor() { }

  ngOnInit() {
  }

  isDisplayTemperature(deviceType){
    let isDisplay = false;
    
    switch(deviceType){
      case 'cisco': 
        isDisplay = true;
        break;
      case 'hdx': 
        isDisplay = true;
        break;
      default: 
        isDisplay = false;
        break;
    }

    return isDisplay;
  }

}
