<!-- <div class="d-flex" *ngIf="!isLoading"> -->
<div class="d-flex" *ngIf="mspDomotzEnabled !== undefined">
  <div class="iotform">
    <div class="iotform__container">
      <div class="iotform__label">API KEY</div>
      <input class="iotform__input" value="apiKey" [(ngModel)]="apiKey" type="password" [disabled]="!isParentCompany && mspDomotzEnabled" />
    </div>
    <div class="iotform__container">
      <div class="iotform__label">API NAME</div>
      <input class="iotform__input" value="apiKey" [(ngModel)]="apiName" type="text" [disabled]="!isParentCompany && mspDomotzEnabled" />
    </div>
    <div class="iotform__container">
      <div class="iotform__label">ACCOUNT URL</div>
      <input class="iotform__input" value="apiKey" [(ngModel)]="accountUrl" type="text" [disabled]="!isParentCompany && mspDomotzEnabled" />
    </div>
    <div *ngIf="isParentCompany || !mspDomotzEnabled" class="iotform__btn">
      <button (click)="submitInput()">{{ btnName }}</button>
      <button *ngIf="isShowSync" (click)="syncDomotzAccount()" [disabled]="isDisable()">Sync Account</button>
    </div>
    <div class="iotform__other-info">
      <p><b>Webhook URL:</b> {{ webHookUrl }}</p>
      <p><b>Last Pulling Cycle:</b> {{ lastPullingCycle }}</p>
    </div>
  </div>
  <div *ngIf="domotzMessage" [ngClass]="'domotz-message domotz-message__' + msgClass">
    {{domotzMessage}}
  </div>
</div>
<!-- <div class="loader-child" *ngIf="isLoading"> -->
<div class="loader-child" *ngIf="mspDomotzEnabled === undefined">
  <img src="{{ loader }}" alt="" />
</div>