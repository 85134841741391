import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { GetDesktopPluginService } from 'src/app/services/get-desktop-plugin.service';
import { GetTeamsService } from 'src/app/services/get-teams.service';
import { environment } from 'src/environments/environment';
import { TeamsRoomWarningModalComponent } from '../../teams/dialogs/teams-room-warning-modal/teams-room-warning-modal.component';
import { TeamsRoomDeleteRoomComponent } from '../dialogs/teams-room-delete-room/teams-room-delete-room.component';
import { TeamsRoomLinkDesktopPluginComponent } from '../dialogs/teams-room-link-desktop-plugin/teams-room-link-desktop-plugin.component';

@Component({
  selector: 'app-teams-rooms',
  templateUrl: './teams-rooms.component.html',
  styleUrls: ['./teams-rooms.component.scss']
})
export class TeamsRoomsComponent implements OnInit {
  @Output() sendPopupType: EventEmitter<any> = new EventEmitter<any>();
  @Input() hostToLink: any;
  @Input() searchKey: any;
  @Input() showLoader: any;

  @Input() teamsRoomList: any;
  @Input() spaceStatus: any;
  @Input() getRoomsList: any;
  hostToLinkNew: any;
  tableSelected: any = 'rooms';
  dPlugin = environment.config.dynamicText.dPlugin;
  // teamsRoomList: any = [];
  loader = environment.config.dynamicImage.loader;
  desktopIcon = environment.config.dynamicImage.desktopIcon;
  crestronIcon = './assets/img/vs_img/crestron_icon.jpg';
  pageCount: any = 0;
  shouldShowTh: boolean = false;
  constructor(
    public dialog: MatDialog,
    private desktopPlugin: GetDesktopPluginService,
    private teamsService: GetTeamsService, 
    private Auth: AuthService
    ) { }

  ngOnInit() {
    // this.getRoomsList();
  }



  ngOnChanges(changes: SimpleChanges) {
    if (changes.teamsRoomList && this.teamsRoomList) {
      this.setData()
      // console.log('test')
    }
  }

  setData = () => {
    this.pageCount = this.teamsRoomList.length

    for (let trl of this.teamsRoomList) {
      if (trl.plugin_host_info !== null && typeof (trl.plugin_host_info) !== 'object') {
        trl.plugin_host_info = JSON.parse(trl.plugin_host_info);
      }

      if (trl.zoom_plugin_enabled === 1 && trl.plugin_ip_address === null) {
        if (trl.plugin_host_info !== null) {
          trl.plugin_ip_address = trl.plugin_host_info[0].ip_address;
        }
      }
      let checkTeamsRoomList = this.teamsRoomList.filter(e => e.plugin_id == null);
      this.shouldShowTh = checkTeamsRoomList.length > 0 ? true : false;

    }
    this.Auth.getListPluginToLink().subscribe((data: any) => {
      this.hostToLinkNew = data.response;
    });
    this.teamsRoomList = this.teamsRoomList.map((e) => {
      return {
        ...e,
        ip_address: e.ip_address !== null ? e.ip_address : '--',
      };
    });
  };

  getRoomStatus = (online, plugin_id) => {
    if (+online === 1 && plugin_id !== null)
      return 'teams-rooms__status--green';

    if (+online === 0 && plugin_id !== null)
      return 'teams-rooms__status--red';

    return '';
  };

  getDesktopStatus = (status) => {
    if (status === 1) {
      return 'teams-rooms__status--green';
    }

    if (status === -1) {
      return 'teams-rooms__status--red';
    }

    return '';
  };

  getPluginIcon = (plugin_active) => {
    if (plugin_active !== null) {
      return this.crestronIcon;
    }
    return '--';
  };

  openTeamsRoomsWarning = () => {
    const config = this.hostToLink;
    const webportal = this.dialog.open(TeamsRoomWarningModalComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        config,
        sendPopupType: this.sendPopupType,
      },
    });
  };

  openDesktopLinkModalTeamsRooms = (items) => {
    const config = this.hostToLinkNew
    const plugin_hostname = items.plugin_host_info !== null ? items.plugin_host_info[0].hostname : "--";
    const webportal = this.dialog.open(TeamsRoomLinkDesktopPluginComponent, {
			panelClass: 'custom-dialog-container',
			data: {
				config,
				user: items,
				sendPopupType: this.sendPopupType,
				user_plugin_host_name: plugin_hostname,
				getRoomsList: this.getRoomsList,
			},
		})
  };

  openDeleteTeamsRoom = (zoom_user_id, room_name) => {

    const webportal = this.dialog.open(TeamsRoomDeleteRoomComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        zoom_user_id,
        room_name,
        sendPopupType: this.sendPopupType,
      },
    });
  };

  setMonitored = (zoom_id, isChecked) => {
    let flag = 0;
    if (isChecked) {
      flag = 1;
    }
    this.teamsService.setTeamsMonitoring(zoom_id, flag).subscribe((data: any) => {
    }, (error) => {
      // console.log('getError error', error)
    })
  }


  getLinkIcon = (params) => {
    const { plugin_status, plugin_id } = params;
    return plugin_id !== null ? './assets/img/vs_img/teams_unlink.png' : './assets/img/vs_img/teams_link.png';
  };

  getHostName = (params) => {
    const host_name = params;
    return host_name !== null ? host_name[0].hostname : '--';
  };

}
