<button type="button" class="not-button btn-long d-flex py-2 zoom-camera">
  <!-- <div class="zoom-camera-image" (click)="ds.sendPopupType(44, { data: data })" (mouseenter)="iconOnHover()"
    (mouseleave)="iconOnLeave()">
    <img src="{{ icon }}" />
  </div> -->
  <div class="zoom-camera-image">
    <img src="{{ icon }}" />
  </div>
  <div class="zoom-camera_details">
    <div class="zoom-camera_mute">
      Primary
      <img src="{{ getStatusIcon(inuseCam) }}" alt="" />
    </div>
    <div class="zoom-camera-device-name">
      <span (click)="ds.sendPopupType(49, { data: data })">
        {{ inuseCam.name }}
      </span>
    </div>
  </div>
</button>