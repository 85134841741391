<div class="cloud-user-live-stats">
  <div class="cloud-user-live-stats__title">
    <h5>Live Call Stats</h5>
  </div>
  <!-- Previous Implementation where call status and scheduled meetings is displayed as two columns for teams,zoom,video,audio -->
  <!-- <div class="cloud-user-live-stats__two-column"> -->
  <div
    [ngClass]="[ shouldShowScheduledMeetings ? 'cloud-user-live-stats__two-column' : 'cloud-user-live-stats__one-column']">
    <app-btn-stat-call [count]="cloudUserLivestats?.liveMeetings" label="Status" color="gray"
      [serviceType]="serviceType"></app-btn-stat-call>
    <app-btn-reg-stats *ngIf="shouldShowScheduledMeetings" [count]="cloudUserLivestats?.scheduledMeetings"
      label="Scheduled Meetings" style="cursor: pointer !important;" color="gray" (click)="
        ds.sendPopupType(
          38,
          {
            deviceId: cloudUserLivestats?.cloudUserId,
            meetings: cloudUserLivestats?.scheduled
          },
          cloudUserLivestats?.hasAccess
        )
      " [isbutton]="isNotButton"></app-btn-reg-stats>
  </div>

  <div *ngIf="cloudUserLivestats?.service_type === 'zoom'" class="cloud-user-live-stats__four-column">
    <app-btn-bitrate [count]="calMbps()" label="Live Mbps"
      (click)="ds.sendPopupType(40, { deviceId: id, section: 'mbps' })" color="{{ colorBit() }}"></app-btn-bitrate>
    <app-btn-reg-stats [count]="cloudUserLivestats?.total_jitter"
      (click)="ds.sendPopupType(40, { deviceId: id, section: 'jitter' })" label="Jitter" color="{{ colorJit() }}">
    </app-btn-reg-stats>
    <app-btn-reg-stats [count]="cloudUserLivestats?.avg_packet_loss"
      (click)="ds.sendPopupType(40, { deviceId: id, section: 'packetloss' })" label="Packet Loss (%)"
      color="{{ colorPac() }}"></app-btn-reg-stats>
    <app-btn-reg-stats [count]="cloudUserLivestats?.frame_rate"
      (click)="ds.sendPopupType(40, { deviceId: id, section: 'framerate' })" label="Frame Rate"
      color="{{ colorFrame() }}"></app-btn-reg-stats>
  </div>

  <div *ngIf="cloudUserLivestats?.service_type === 'teams'" class="cloud-user-live-stats__two-column">
    <div class="cloud-user-live-stats__two-column">
      <div class="cloud-user-live-stats__four-column-item cloud-user-live-stats__four-column-item--clickable"
        (click)="showPerfomanceGraph('mbps')">
        <div [ngClass]="{'cloud-user-live-stats__normal-font': cloudUserLivestats?.calculatedMbpsTeams == null}"
          class="cloud-user-live-stats__mbps-text">{{
          cloudUserLivestats?.calculatedMbpsTeams | displayEmpty }}
        </div>
        <div>
          Mbps
        </div>
      </div>

      <div class="cloud-user-live-stats__four-column-item  cloud-user-live-stats__four-column-item--clickable"
        (click)="showPerfomanceGraph('wifi')">
        <img src="{{ getWifiSignalRange(cloudUserLivestats?.wifiRange) }}" alt="" />

        <div>WiFi</div>
      </div>
    </div>

    <div class="cloud-user-live-stats__two-column-item">
      <div [ngClass]="{'cloud-user-live-stats__normal-font': cloudUserLivestats?.wifiSsid == null}"
        class="cloud-user-live-stats__wifi-ssid-text">
        {{ cloudUserLivestats?.wifiSsid | displayEmpty }}

      </div>
      <div>WiFi SSID</div>
    </div>
  </div>

  <div class="cloud-user-live-stats__two-column"
    *ngIf="isShowPeopleCount && cloudUserLivestats?.service_type === 'zoom'">
    <div>
      <img src="../../assets/img/video/jabra_logo.png@1X.png" />
    </div>
    <app-btn-reg-stats [count]="cloudUserLivestats?.people_count"
      (click)="ds.sendPopupType(40, { deviceId: id, section: 'people_count' })" label="People Count">
    </app-btn-reg-stats>
  </div>
</div>