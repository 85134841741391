<div class="zoom-service">
  <div class="zoom-service__left-column">
    <div class="zoom-service__checkbox">
      <div class="checkbox_label">
        <!-- Zoom Service -->
        Monitoring Enabled
      </div>
      <div class="checkbox_switch">
        <!-- <div>disabled</div> -->
        <label class="switch" [ngClass]="{ 'disable': !enableCheckbox }">
          <input type="checkbox" [(checked)]="enabled" (click)="setEnabled()" />
          <span class="slider round"></span>
        </label>
        <!-- <div>enabled</div> -->
      </div>
      <div class="checkbox_label">
        <!-- Zoom Notification -->
        Alert Notification Enabled
      </div>
      <div class="checkbox_switch">
        <!-- <div class="">disabled</div> -->
        <label class="switch" [ngClass]="{ 'disable': !enableCheckbox }">
          <input type="checkbox" [(checked)]="notification" (click)="setNotification()" />
          <span class="slider round"></span>
        </label>
        <!-- <div>enabled</div> -->
      </div>
    </div>
  </div>
  <div class="zoom-service__right-column">
    <div class="zoom-service__success-msg {{ msgColor }}">
      <h3 *ngIf="shouldShowMsg">
        {{ msg }}
      </h3>
    </div>
    <app-zoom-enable-service (boolEvent)="emitterEvent($event)" [zoomAccountInfo]="zoomInfoResult">
    </app-zoom-enable-service>
  </div>
</div>