<div id="site-overlay" [class.tut-show]="setoverlay == 'create'"></div>
<div class="tut-create col-4" [class.tut-show]="setoverlay == 'create'">
  Please complete the form to create your first virtual site.
</div>
<div class="container-fluid" id="main-popup" [class.tut-overlay]="setoverlay == 'create'">
  <div class="row">
    <div class="col-12">
      <form role="form" class="signup" name="siteForm" [formGroup]="siteForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="form-group">
          <select #selectInputSetSiteIndex (change)="setSiteIndex(selectInputSetSiteIndex.value)"
            [ngClass]="{ 'is-invalid': submitted && siteIndex == -1 }" [ngStyle]="{
              'background-image': 'url(' + scrollCollector + ')'
            }" class="form-control" id="site-collector" required>
            <option value="" disabled selected>Site Collector</option>
            <option *ngFor="let siteList of allSiteListData; index as n" value="{{ n }}">
              {{ siteList.site_name }}
              <!-- {{siteList.site_id}} -->
            </option>
          </select>
          <div *ngIf="submitted && siteIndex == -1" class="invalid-feedback">
            <div id="confirmMessage">!</div>
          </div>
        </div>
        <!-- <input type="text" [(ngModel)]="parent_id" formControlName="parent_id" class="form-control" id="parent_id" placeholder="Parent ID" required hidden/> -->
        <div class="form-group">
          <input type="text" formControlName="sitename" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.sitename.errors }" placeholder="Sitename" name="sitename"
            id="sitename" autocomplete="additional-name" required />
          <div *ngIf="submitted && f.sitename.errors" class="invalid-feedback">
            <div *ngIf="f.sitename.errors.maxlength" class="phonetext alert alert-danger p-2 m-0">
              Exceed Maximum 30 character
            </div>
            <div *ngIf="f.sitename.errors.required" id="confirmMessage">!</div>
          </div>
        </div>


        <!-- <div *ngIf="showWellnessInProd" style="margin-bottom: 1rem;">
          <div class="wellness-check">
            <div>
              WELLNESS CHECK
            </div>
            <div>
              <input type="checkbox" [checked]="wellnessCheckEnable" (change)="onChangeWellnessCheckValue($event)">
            </div>
            <div>
              <ngb-timepicker [ngClass]="{'componentDisable':  !wellnessCheckEnable}" class="changeSize"
                [(ngModel)]="wellnessCheckTime" id="endTime" [spinners]="false" [meridian]="true"
                [ngModelOptions]="{standalone: true}"></ngb-timepicker>
            </div>
          </div>
        </div>
 -->



        <div class="form-group">
          <input type="text" [(ngModel)]="parent_id" formControlName="parent_id" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.parent_id.errors }" placeholder="Parent ID" name="parent_id"
            id="parent_id" autocomplete="additional-name" required hidden />
          <div *ngIf="submitted && f.parent_id.errors" class="invalid-feedback">
            <!-- <div *ngIf="f.parent_id.errors.required">parent_id is required</div> -->
            <!-- <div *ngIf="f.parent_id.errors.required" id="confirmMessage">!</div> -->
          </div>
        </div>
        <h4 class="left">
          <span>
            Add Contact
          </span>
        </h4>

        <div class="form-group">
          <select #setVirtualSiteIndex (change)="setIndex(setVirtualSiteIndex.value)" class="form-control"
            id="add_contact" required>
            <option value="-1">Add New User</option>
            <option *ngFor="let contactList of userApiData; index as n" value="{{ n }}">
              {{ contactList.first_name }} {{ contactList.last_name }}
            </option>
          </select>
        </div>
        <!-- FORM TO CREATE NEW USER -->
        <div>
          <input type="text" [(ngModel)]="user_id" formControlName="user_id" class="form-control" id="user_id"
            placeholder="user id" required hidden />
          <div class="form-group">
            <div class="row">
              <div class="col-6">
                <!-- <input type="text" class="form-control" id="firstname" placeholder="contact first name" required /> -->
                <input type="text" [(ngModel)]="firstname" formControlName="firstname" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" placeholder="First Name"
                  name="firstname" id="firstname" autocomplete="given-name" required />
                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                  <!-- <div *ngIf="f.firstname.errors.required">firstname is required</div> -->
                  <div *ngIf="f.firstname.errors.required" id="confirmMessage">
                    !
                  </div>
                </div>
              </div>
              <div class="col-6">
                <!-- <input type="text" class="form-control" id="lastname" placeholder="contact last name" required /> -->
                <input type="text" [(ngModel)]="lastname" formControlName="lastname" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" placeholder="Last Name" name="lastname"
                  id="lastname" autocomplete="family-name" value="{{ userLastname }}" required />
                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                  <!-- <div *ngIf="f.lastname.errors.required">lastname is required</div> -->
                  <div *ngIf="f.lastname.errors.required" id="confirmMessage">
                    !
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="form-group"> -->
          <!-- <input type="email" class="form-control" id="contact-email" placeholder="contact email" required /> -->
          <div class="form-group">
            <input type="email" [(ngModel)]="email" formControlName="email" class="form-control" placeholder="email"
              name="email" id="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email"
              autocomplete="email" required />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.maxlength" class="phonetext alert alert-danger p-2 m-0">
                Exceed Maximum 50 character
              </div>
              <div *ngIf="f.email.errors.required" id="confirmMessage">!</div>
            </div>
          </div>
          <!-- ERROR MESSAGE -->
          <p id="feedback" class="feedback text-center text-danger"></p>
          <div class="form-group">
            <div class="row">
              <div class="col-6">
                <input type="text" [(ngModel)]="phone" formControlName="phone" class="form-control" name="phone"
                  id="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" placeholder="User Phone"
                  autocomplete="tel-national" required />
                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                  <div *ngIf="f.phone.errors" class="phonetext alert alert-danger p-2 m-0">
                    Please enter valid phone number
                  </div>
                  <div *ngIf="f.phone.errors.required" id="confirmMessage">
                    !
                  </div>
                </div>
              </div>
              <div class="col-6">
                <button type="submit" class="btn-primary" style="width:100%;">
                  Create a Virtual Site
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="showSuccessMessage" style="color: green; text-align: center">
            <h3>
              Update Success
            </h3>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- <div [ngStyle]="{'display':getIndex == -1 ? 'block':'none'}"> -->