<div class="video-live-stats">
  <div class="video-live-stats__title">
    <h5>Live Stats</h5>
  </div>

  <div class="video-live-stats__stats-container">
    <app-btn-stat-call [count]="videoDeviceData?.oncall" label="Impacted" color="gray"
      class="video-live-stats__call-style"></app-btn-stat-call>

    <app-btn-reg-stats class="video-live-stats__call-style"
      (click)="ds.sendPopupType(1, { deviceId: id, meetings: videoDeviceData?.meetings }, hasAccess)"
      [count]="videoDeviceData?.scheduled_meetings" label="Scheduled Meetings" color="gray" [isbutton]="isNotButton">
    </app-btn-reg-stats>
  </div>

  <mat-expansion-panel *ngFor="let lstat of liveStats" hideToggle="false" [expanded]="lstat.expanded">
    <mat-expansion-panel-header>
      <span class="header-status" [class]="lstat.color"></span>
      <h6>{{lstat.label}}</h6>
    </mat-expansion-panel-header>
    <div class="video-live-stats__stats-container">
      <div *ngFor="let stats of lstat.rx" class="video-live-stats__stats-item" (click)="openChart(stats.chart)">
        <div class="video-live-stats__stats-item-data">
          <h3 *ngIf="stats.value" [class]="stats.color" tooltip="{{stats.value}}">
            {{ stats.value | longnumber}}
          </h3>
          <div *ngIf="!stats.value">--</div>
        </div>
        <div class="video-live-stats__stats-item-label">{{stats.label}}</div>
        <div class="video-live-stats__stats-item-label">{{stats.sub}}</div>
      </div>
    </div>
    <div class="video-live-stats__stats-container">
      <div *ngFor="let stats of lstat.tx" class="video-live-stats__stats-item" (click)="openChart(stats.chart)">
        <div class="video-live-stats__stats-item-data">
          <h3 *ngIf="stats.value" [class]="stats.color" tooltip="{{stats.value}}">
            {{ stats.value | longnumber}}
          </h3>
          <div *ngIf="!stats.value">--</div>
        </div>
        <div class="video-live-stats__stats-item-label">{{stats.label}}</div>
        <div class="video-live-stats__stats-item-label">{{stats.sub}}</div>
      </div>
    </div>
  </mat-expansion-panel>
</div>