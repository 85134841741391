<div class="container-sm zoom-monitoring">
  <h3 class="text-center title">Monitoring</h3>
  <img class="banner" src="../../../../assets/img/teleportivity_img/logo.png" alt="" />

  <app-support-contact [directAccessInfo]="zoomData?.direct_access_info"></app-support-contact>

  <div class="col-sm zoom-monitoring-content">
    <p class="text-sm-left site-name">
      Site: <span>{{ siteName }}</span>
    </p>

    <p class="text-sm-left room-name">
      Room name: <span>{{ roomName }}</span>
    </p>

    <app-zoom-status
      [pluginId]="pluginId"
      [pluginEnabled]="pluginEnabled"
      [pluginStatus]="pluginStatus"
      [status]="zoomData.status"
      [pluginApp]="zoomData.plugin_application"
      [pause]="zoomData.pause"
    >
    </app-zoom-status>

    <app-device-action
      *ngIf="pluginId !== null && pluginEnabled"
      [deviceData]="zoomData"
      deviceType="zoom"
    >
    </app-device-action>

    <div class="system-status-container {{ hasIssues ? 'has-incidents' : 'no-incidents' }}">
      <mat-expansion-panel
        class="system-status-expansion"
        hideToggle="true"
        [expanded]="isExpandSystemStatusConnectedDevices"
      >
        <mat-expansion-panel-header
          class="expansion-header-top"
          (click)="toggleSystemStatusConnectedDevices()"
          *ngIf="isShowSystemStatusConnectedDevicesHeader"
        >
        </mat-expansion-panel-header>
        <app-zoom-system-status
          [zMics]="mics"
          [zMicsImpacted]="micsImpacted"
          [zCameras]="cameras"
          [zCamerasImpacted]="camerasImpacted"
          [zSpeakers]="speakers"
          [zSpeakersImpacted]="speakersImpacted"
        >
        </app-zoom-system-status>
      </mat-expansion-panel>

      <mat-expansion-panel hideToggle="true" [expanded]="isExpandSystemStatusCall">
        <mat-expansion-panel-header
          class="expansion-header-bottom"
          (click)="toggleSystemStatusCall()"
          *ngIf="isShowSystemStatusCallHeader"
        >
        </mat-expansion-panel-header>
        <app-device-call-status
          *ngIf="isShowSystemStatusCallBody"
          deviceType="zoom"
          [callData]="callStatusData"
          [isShowSip]="isShowSip"
          [connectionType]="connection.connectionType"
          [connectionTypeColor]="connection.connectionTypeColor"
        ></app-device-call-status>
      </mat-expansion-panel>
    </div>

    <app-recommended-actions type="zoom" [incidentSummary]="incidentSummary">
    </app-recommended-actions>
  </div>
</div>
