import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
// import { decode } from '@angular/router/src/url_tree';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-token-login-admin',
  templateUrl: './token-login-admin.component.html',
  styleUrls: ['./token-login-admin.component.scss'],
})
export class TokenLoginAdminComponent implements OnInit {
  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.userToken = params['id'];
      this.loginType = params['loginType'];
    });
  }
  userToken: any;
  loginType: string;
  pass1: any;

  ngOnInit() {
    this.loginTokenUser(this.userToken, this.loginType);
  }

  // This function grab the user token from the url params to login as that user
  loginTokenUser(userToken, loginType: string = null) {
    localStorage.clear();
    sessionStorage.clear();

    // Grabs token from route to login as the user
    this.Auth.loginTokenAdmin(userToken, loginType).subscribe((data) => {
      if (data.response[0] == undefined) {
        // Grabs Tokens for User from Server and Stores it in Local Storage
        localStorage.setItem('token', data.response.token);
        const token = localStorage.getItem('token');
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(token);
        const expirationDate = helper.getTokenExpirationDate(token);
        const isExpired = helper.isTokenExpired(token);
        localStorage.setItem('verified', 'true');
        this.Auth.decodedToken = decodedToken;

        if (!helper.isTokenExpired(token)) {
          // redirect to Admin homepage
          this.router.navigate(['/']);
          // this.Auth.setLoggedIn(true);
        }
      } else {
        this.router.navigate(['/login']);
      }
    });
  }
}
