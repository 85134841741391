import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { PopupTypeService } from '../../services/popupType.service';
import { GetIotService } from '../../services/get-iot.service';
import { GetCloudService } from '../../services/get-cloud.service';
import { LinkDesktopPluginDialogComponent } from '../../iot/dialogs/link-desktop-plugin-dialog/link-desktop-plugin-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetDesktopPluginService } from '../../services/get-desktop-plugin.service';
import { environment } from '../../../environments/environment';
import { VsMenuDesktopClientService } from '../../services/vs-menu-desktop-client.service';


@Component({
  selector: 'app-vs-menu-desktop-clients',
  templateUrl: './vs-menu-desktop-clients.component.html',
  styleUrls: ['./vs-menu-desktop-clients.component.scss'],
})
export class VsMenuDesktopClientsComponent implements OnInit, OnChanges, OnDestroy {
  @Output() sendPopupType: EventEmitter<any> = new EventEmitter<any>();

  @Input() desktopClientList: any;
  @Input() isShowCollectorEdit: any;
  @Input() isShowCollectorInfo: any;
  @Input() runTutorial: any;
  @Input() keyDown: any;
  @Input() searchKey: any;
  @Input() domotzEnabled: any;
  @Input() unlinkedIotDevice: any;
  @Input() unlinkedPlugin: any;

  viewTutorial = environment.config.dynamicImage.viewTutorial;
  downloadCollector = environment.config.dynamicImage.downloadCollector;
  createNewSite = environment.config.dynamicImage.createNewSite;
  createVirtualSite = environment.config.dynamicImage.createVirtualSite;
  pluginZoom = environment.config.dynamicImage.pluginZoom;
  desktopPlugin = environment.config.dynamicImage.desktopPlugin;
  iconAddSite = environment.config.dynamicImage.iconAddSite;
  iconVirtual = environment.config.dynamicImage.iconVirtual;
  iconPlugin = environment.config.dynamicImage.iconPlugin;
  vsOnePopup = environment.config.dynamicText.vsOnePopup;

  collectorParams: any;
  collectorLatestVersion: any;
  confirmationInput: any = '';

  cid: any = 0;
  sid: any = 0;
  zoomRoom: any;
  zoomRoomName: any = '';
  usersToLinkListTeams: any = [];
  usersToLinkListZoom: any = [];

  compId: number;
  showStatConfirmation: boolean = false;
  showPluginConfirmation: Boolean = false;
  showPluginConfirmationError: Boolean = false;
  showScheduleModal: boolean = false;

  // roomListFiltered: any;
  modalServiceType: any;

  subscription: any;

  totalTeamsCount: any;
  totalZoomCount: any;

  totalPageLimit = 250;
  // pageToLoadTeams = 0
  // pageToLoadZoom = 0

  isDoneGettingCount: boolean = true;
  btnLoader: string = environment.config.dynamicImage.loader;

  private dialogRef: MatDialogRef<LinkDesktopPluginDialogComponent>

  constructor(
    private desktopPluginService: GetDesktopPluginService,
    private getCloudUserService: GetCloudService,
    public dialog: MatDialog,
    public vsMenuDesktopClientService: VsMenuDesktopClientService,
    private Auth: AuthService,
    private ds: PopupTypeService
  ) { }

  ngOnInit() {
    // this.getUsersToLink();
    // this.getUsersToLinkTeams();
    // this.getUsersToLinkZoom();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.desktopClientList) {
      this.setData();
    }
  }

  setData = () => {
    const newArr = this.desktopClientList.map((e) => {
      return {
        ...e,
        mac_address: this.arrangeMacAddress(e.mac_address),
      };
    });
    this.desktopClientList = newArr;
  };

  getUsersToLinkTeams = (offset?: number, limit?: number, search_key?: string, isLoadingMore?: boolean) => {
    const postData = {
			limit: limit || this.totalPageLimit,
			offset: offset || 0,
			search_key: search_key || '',
			service_type: 'teams',
		}

    this.subscription = this.desktopPluginService.getUsersToLink(postData).subscribe((value: any) => {
      this.totalTeamsCount = value.total_teams_count;
      this.totalZoomCount = value.total_zoom_count;
      let newArr = value.response;
      if (isLoadingMore) {
        this.usersToLinkListTeams = this.usersToLinkListTeams.concat(newArr)
			} else {
				this.usersToLinkListTeams = newArr
			}

      this.vsMenuDesktopClientService.watchMe$.next({
        users: this.usersToLinkListTeams,
        totalTeamsCount: this.totalTeamsCount,
        totalZoomCount: this.totalZoomCount,
      })
    });
  };

  getUsersToLinkZoom = (offset?: number, limit?: number, search_key?: string, isLoadingMore?: boolean) => {
    const postData = {
      limit: limit || this.totalPageLimit,
      offset: offset || 0,
      search_key: search_key || "",
      service_type: 'zoom'
    }

    this.subscription = this.desktopPluginService.getUsersToLink(postData).subscribe((value: any) => {
      this.totalTeamsCount = value.total_teams_count
			this.totalZoomCount = value.total_zoom_count
      let newArr = value.response;
      if (isLoadingMore) {
        this.usersToLinkListZoom = this.usersToLinkListZoom.concat(newArr)
      } else {
        this.usersToLinkListZoom = newArr
      }

      this.vsMenuDesktopClientService.watchMe$.next({
        users: this.usersToLinkListZoom,
        totalTeamsCount: this.totalTeamsCount,
        totalZoomCount: this.totalZoomCount
      });
    });
  };

  getPluginTypeImage = (type) => {
    switch (type) {
      case 'zoom':
        return '../../../assets/img/zoom_logo.png';
      case 'teams':
        return '../../../assets/img/logo-microsoft-teams-2.png';
      default:
        return '';
    }
  };

  getDesktopStatus = (status) => {
    if (status === 1) {
      return 'desktop-client__status--green';
    }

    if (status === -1) {
      return 'desktop-client__status--red';
    }

    return '';
  };

  desktopClientChange = (bool, zoom_user_id) => {
    this.getCloudUserService.setEnableDesktopPlugin(zoom_user_id, bool).subscribe((data) => { });
  };

  alertsChange = (bool, zoom_user_id) => {
    if (bool) {
      this.getCloudUserService.enableAlerts(zoom_user_id).subscribe((data) => { });
    } else {
      this.getCloudUserService.disableAlerts(zoom_user_id).subscribe((data) => { });
    }
  };

  arrangeMacAddress = (mac) => {
    return mac
      .toUpperCase()
      .match(/.{1,2}/g)
      .join(':');
  };


  getAssignedUser = (name) => {
    const splitName = name.split(' ').includes('null');
    return splitName ? '--' : name;
  };

  openDesktopLinkModal = (items, serviceType, action) => {
    this.modalServiceType = serviceType;
    
    let config;
    if (serviceType === 'teams') {
      this.getUsersToLinkTeams()
      config = this.usersToLinkListTeams
    }
    else {
      this.getUsersToLinkZoom()
      config = this.usersToLinkListZoom
    }
    this.dialogRef = this.dialog.open(LinkDesktopPluginDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        config,
        user: items,
        sendPopupType: this.sendPopupType,
        serviceType,
        eventEmitterDesktop: (params) => {         
          if (params.event === 'searchUser') {
            this.modalServiceType === 'teams'
              ? this.getUsersToLinkTeams(null, null, params.searchKey)
              : this.getUsersToLinkZoom(null, null, params.searchKey)
          } else if (params.event === 'loadMoreUsers') {
            this.modalServiceType === 'teams'
              ? this.getUsersToLinkTeams(this.usersToLinkListTeams.length, null, params.searchKey, true)
              : this.getUsersToLinkZoom(this.usersToLinkListZoom.length, null, params.searchKey, true)
          }
        },
        action,
        totalZoomCount: this.totalZoomCount,
        totalTeamsCount: this.totalTeamsCount,
      },
    });
  };

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

}
