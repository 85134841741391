<div class="collector-alert-panel">
  <div class="collector-alert-panel__container">

    <div class="collector-alert-panel__header">
      <h5>Logitech Alert Panel</h5>
    </div>



    <div class="collector-alert-panel__item">
      <div>
        <h6>Offline</h6>
      </div>
      <label>
        <input type="checkbox" [(ngModel)]="offline" />
        Enabled
      </label>
    </div>

    <div class="collector-alert-panel__item">
      <div>
        <h6>Packet loss</h6>
      </div>
      <label>
        <input type="checkbox" [(ngModel)]="packetloss" />
        Enabled
      </label>
    </div>

    <div class="collector-alert-panel__item">
      <div>
        <h6>Ports</h6>
      </div>
      <label>
        <input type="checkbox" [(ngModel)]="ports" />
        Enabled
      </label>
    </div>

    <div class="collector-alert-panel__item">
      <div>
        <h6>Logitech Tap</h6>
      </div>
      <label>
        <input type="checkbox" [(ngModel)]="tap" />
        Enabled
      </label>
    </div>

    <div class="collector-alert-panel__item">
      <div>
        <h6>Remote</h6>
      </div>
      <label>
        <input type="checkbox" [(ngModel)]="remote" />
        Enabled
      </label>
    </div>



    <div class="collector-alert-panel__item">

      <button class="collector-alert-panel__button-save" (click)="setAlert(true)">Save</button>
      <button class="collector-alert-panel__button-cancel" (click)="setAlert(false)">Reset</button>

    </div>

    <div *ngIf="showSucessMessage" class="collector-alert-panel__success-message">
      Update Success!
    </div>

  </div>

</div>