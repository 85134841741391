import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { PopupTypeService } from '../../services/popupType.service';
import { MembersTypeService } from '../../services/membersType.service';
import { AuthService } from '../../services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GetDesktopPluginService } from '../../services/get-desktop-plugin.service';
import { CompileShallowModuleMetadata } from '@angular/compiler';

@Component({
  selector: 'app-download-icons',
  templateUrl: './download-icons.component.html',
  styleUrls: ['./download-icons.component.scss'],
})
export class DownloadIconsComponent implements OnInit, OnChanges {
  constructor(
    private dsM: MembersTypeService,
    private Auth: AuthService,
    private ds: PopupTypeService,
    private desktopPluginService: GetDesktopPluginService
  ) { }
  @Input() componentName: any;
  @Input() cloudRadioProp: any;
  @Input() siteSummary: any;
  @Input() desktopClientDownloadLink: any;

  @Output() sendPopupType: EventEmitter<any> = new EventEmitter<any>();

  links = environment.config.links;

  viewTutorial = environment.config.dynamicImage.viewTutorial;
  downloadCollector = environment.config.dynamicImage.downloadCollector;
  downloadCollectorMac = environment.config.dynamicImage.downloadCollectorMac;
  createNewSite = environment.config.dynamicImage.createNewSite;
  createVirtualSite = environment.config.dynamicImage.createVirtualSite;
  pluginZoom = environment.config.dynamicImage.pluginZoom;
  pluginMac = environment.config.dynamicImage.pluginMac;
  desktopPlugin = environment.config.dynamicImage.desktopPlugin;
  iconAddSite = environment.config.dynamicImage.iconAddSite;
  iconVirtual = environment.config.dynamicImage.iconVirtual;
  iconPlugin = environment.config.dynamicImage.iconPlugin;
  vsOnePopup = environment.config.dynamicText.vsOnePopup;
  envTitle = environment.config.title;
  enableProduction = environment.config.enableInProduction;
  loader = environment.config.dynamicImage.loader;

  activePanel = 0;

  siteTutorial: string;
  setTutorial: any;
  siteApiData: any = [];

  componentNameTest: any = 'zoom';

  totalZoomRooms: any;
  totalZoomRoomsNoPlugin: any;
  totalZoomUsers: any;
  totalZoomUsersNoPlugin: any;

  totalTeamsRooms: any;
  totalTeamsRoomsNoPlugin: any;
  totalTeamsUsers: any;
  totalTeamsUsersNoPlugin: any;

  pluginDownloadUrl: any;
  pluginMacDownloadUrl: any;

  displayMacPlugin: boolean = false;
  pluginBeingDownloaded : boolean = false;
  desktopPluginBeingDownloaded : boolean = false;

  ngOnInit() {
    this.getPluginUrl();
    this.getPMacluginUrl();

    // temporarily hiding mac plugin
    // if (this.enableProduction) {
    //   this.displayMacPlugin = true
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.siteSummary && this.siteSummary) {
      this.setData();
    }
  }

  getPluginUrl = () => {
    this.desktopPluginService.downloadPlugin().subscribe((data: any) => {
      const { download_url } = data;
      this.pluginDownloadUrl = download_url;
      // // console.log('downloadPlugin: ', this.pluginDownloadUrl)
    });
  }

  getPMacluginUrl = () => {
    this.desktopPluginService.downloadMacPlugin().subscribe((data: any) => {
      const { download_url } = data;
      this.pluginMacDownloadUrl = download_url;
      // // console.log('pluginMacDownloadUrl: ', this.pluginMacDownloadUrl)
    });
  }

  setData = () => {
    const {
      total_zoom_users,
      total_zoom_rooms,
      total_unassigned_zoom_rooms,
      total_unassigned_zoom_users,
      //
      total_teams_rooms,
      total_teams_users,
      total_unassigned_teams_rooms,
      total_unassigned_teams_users,
    } = this.siteSummary;
    this.totalZoomRooms = total_zoom_rooms;
    this.totalZoomUsers = total_zoom_users;
    this.totalZoomRoomsNoPlugin = total_unassigned_zoom_rooms;
    this.totalZoomUsersNoPlugin = total_unassigned_zoom_users;

    this.totalTeamsRooms = total_teams_rooms;
    this.totalTeamsUsers = total_teams_users;
    this.totalTeamsRoomsNoPlugin = total_unassigned_teams_rooms;
    this.totalTeamsUsersNoPlugin = total_unassigned_teams_users;

  };

  sendPopupTypeEmitter = (number) => {
    this.sendPopupType.emit(number);
  };

  checkDownload(n) {
    this.setTutorialapi(n);
    localStorage.setItem('keyDownload', n);
    this.siteTutorial = 'verify';
  }

  setTutorialapi(n) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const tutData = {
      company_id: decodedToken.company_id,
      user_id: decodedToken.user_id,
      tutorial_download: n,
    };

    this.Auth.setTutorialDownload(tutData).subscribe((data) => {
      this.setTutorial = data['response'];
    });
  }
  showPreloader(device_type){	
    if(device_type == 'zoom'){	
      this.pluginBeingDownloaded = true;	
    }	
    if(device_type == 'desktop'){	
      this.desktopPluginBeingDownloaded = true;	
    }	
  }	
  hidePreloader(device_type){	
    if(device_type == 'zoom'){	
      this.pluginBeingDownloaded = false;	
    }	
    if(device_type == 'desktop'){	
      this.desktopPluginBeingDownloaded = false;	
    }	
  }	
  async handleRespectiveFileDownload(device_type){	
    let downloadUrl = '';	
    if(device_type == 'zoom'){	
      let waitingDownload = await this.desktopPluginService.downloadPlugin().toPromise()	
      // Extracting the download url	
      downloadUrl = waitingDownload.download_url;	
    }	
    if(device_type == 'desktop'){	
      let waitingDownload = await this.desktopPluginService.downloadDesktopClient().toPromise();	
      // Extracting the download url	
      downloadUrl = waitingDownload.download_url;	
    }	
    return downloadUrl;	
  }	
  async downloadPlugin(url : string, device_type : string){	
    try{	
      this.showPreloader(device_type);	
      let downloadUrl = await this.handleRespectiveFileDownload(device_type);	
      this.downloadFile(downloadUrl);	
      console.log("Download plugin" , url , downloadUrl );	
    }catch(error){	
    }finally{	
      this.hidePreloader(device_type)	
    }	
  }
  downloadFile(url : string ){
      var a = document.createElement("a") as HTMLAnchorElement;
      document.body.appendChild(a);
      a.style.display= "none";
      a.href = url;
      a.target = "_blank";
      a.download = url.split("/").pop();
      a.click();
  }
}