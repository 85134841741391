<div class="monitoring-disabled">
    <div class="monitoring-disabled__header">
        <div>
            Monitoring Disabled
        </div>
        <div (click)="closeDialog()" class="monitoring-disabled__header-close-button">
            <img src="./assets/img/iot/delete-btn.png" alt="" />
        </div>
    </div>

    <div class="monitoring-disabled__body monitoring-disabled__body-2">
        <div class="monitoring-disabled__image">
            <img src="../../../../assets/img/vs_img/delete-icon.png" alt="" />
        </div>
        <div class="monitoring-disabled__text-button-container">
            <div class="">
                Monitoring is disabled! <br>
                Please link teams room to a {{dPlugin}} first to enable monitoring.
            </div>
        </div>
    </div>
</div>