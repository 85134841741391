<!-- TUTORIAL DEVICE OVERLAY -->
<div id="visone-overlay" class="tut-show" *ngIf="runTutorial == 1"></div>
<!-- TUTORIAL ALERT -->
<div *ngIf="showAddDevice == 1 && runTutorial == 1" class="tut-polycom col-6 tut-show">
  Please complete the form to add your first device.
</div>
<div *ngIf="showTryAgain == 1 && runTutorial == 1" class="tut-reenter col-6 tut-show">
  Re-enter device information to add a new device.
</div>
<div *ngIf="showConfirm == 1 && runTutorial == 1" class="tut-addDevice col-6 tut-show">
  Please confirm this is the device want to add.
</div>
<div class="container-fluid" (click)="toogleDeleteForm()" style="padding-top: 50px; padding-bottom: 160px;">
  <!-- <div class="row upper-row">
    <div class="col-12">
      <div class="row">
        <div class="col-1 pl-5">
          <img src="../../assets/img/poly_logo.png" alt="polycom" style="width: 140px;" />
        </div>
      </div>
    </div>
  </div> -->
  <div class="row lower-row">
    <div class="col-2 center px-0">
      <h3 class="add-device pl-4" (click)="setHighlight(1)" [class.btn-background]="highlightNum == 1"
        (click)="toEdit(0)" (click)="toDelete(0)">
        add a device
      </h3>
      <h3 class="list-device pl-4" (click)="setHighlight(2)" [class.btn-background]="highlightNum == 2"
        (click)="toEdit(0)" (click)="toDelete(0)">
        list of devices
      </h3>

      <div class="row pt-3 pl-4" style="top: 20px;" *ngIf="hasAccess">
        <h5 class="pl-4">polycom monitoring</h5>
        <div class="row pt-3 px-3" style="top: 20px;">
          <div class="disabled float-left">disabled</div>
          <label class="switch mx-2">
            <input type="checkbox" (change)="polycomMonitor == 1 ? togglePolycomMonitor(0) : togglePolycomMonitor(1)"
              [checked]="polycomMonitor == 0" />
            <span class="slider round"></span>
          </label>
          <div class="enabled float-right">enabled</div>
        </div>
      </div>

      <div class="row pt-3 pl-4" style="top: 20px;" *ngIf="hasAccess">
        <h5 class="pl-4">polycom notifications</h5>
        <div class="row pt-3 px-3" style="top: 20px;">
          <div class="disabled float-left">disabled</div>
          <label class="switch mx-2">
            <input type="checkbox" (change)="polycomAlert == 1 ? togglePolycomAlerts(0) : togglePolycomAlerts(1)"
              [checked]="polycomAlert == 1" />
            <span class="slider round"></span>
          </label>
          <div class="enabled float-right">enabled</div>
        </div>
      </div>
    </div>

    <div style="border-left: 2px solid black;"></div>
    <div class="col-4" *ngIf="highlightNum == 1" [ngClass]="{
        'white-back':
          (findDevice == 1 && runTutorial == 1) || (showTryAgain == 1 && runTutorial == 1)
            ? true
            : false
      }" style="height: 400px;">
      <div class="row pt-3 px-3" style="top: 20px;">
        <div class="disabled float-left">Video</div>
        <label class="switch mx-2">
          <input type="checkbox" (change)="toggleMediaType()" />
          <span class="slider round"></span>
        </label>
        <div class="enabled float-right">Audio</div>
      </div>

      <form role="form" name="siteForm" class="mt-2" name="siteForm" [formGroup]="siteForm" (ngSubmit)="onSubmit()"
        novalidate>
        <div class="form-group">
          <select #selectPolycomInput (change)="setIndex(selectPolycomInput.value)" id="index" formControlName="index"
            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.index.errors }" required>
            <option value="" selected disabled>Select a site</option>
            <option *ngFor="let siteList of allSiteListData; index as n" value="{{ n }}">
              {{ siteList.site_name }}</option>
          </select>

          <div *ngIf="submitted && f.index.errors" class="invalid-feedback">
            <!-- <div *ngIf="f.index.errors" class="invalid-alert alert alert-danger p-2 m-0">Please select a site.</div> -->
            <div *ngIf="f.index.errors.required" id="confirmMessage">!</div>
          </div>
        </div>

        <div class="form-group">
          <input type="text" [(ngModel)]="siteId" formControlName="siteId" class="form-control" id="siteId"
            name="siteId" placeholder="Site id" hidden required />
        </div>
        <div class="form-group">
          <input type="text" [(ngModel)]="collectorId" formControlName="collectorId" class="form-control"
            id="collectorId" name="collectorId" placeholder="Collector id" hidden required />
        </div>

        <div class="form-group">
          <input type="text" formControlName="roomName" placeholder="Room Name" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.roomName.errors }" id="roomName" name="roomName" required />
          <div *ngIf="submitted && f.roomName.errors" class="invalid-feedback">
            <!-- <div *ngIf="f.roomName.errors" class="invalid-alert alert alert-danger p-2 m-0">Please enter ip Address.</div> -->
            <div *ngIf="f.roomName.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        <div class="form-group">
          <input type="text" id="device-ip" formControlName="ipAddress" placeholder="{{ ipPlaceholder }}"
            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.ipAddress.errors }" id="ipAddress"
            name="ipAddress" required />
          <div *ngIf="submitted && f.ipAddress.errors" class="invalid-feedback">
            <!-- <div *ngIf="f.ipAddress.errors" class="invalid-alert alert alert-danger p-2 m-0">Please enter ip Address.</div> -->
            <div *ngIf="f.ipAddress.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        <!-- <div *ngIf="mediaType === 'audio'" class="form-group">
          <input type="text" formControlName="visualPro" placeholder="For Visual Pro" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.visualPro.errors }" id="visualPro" name="visualPro" />
          <div *ngIf="submitted && f.visualPro.errors" class="invalid-feedback">
            <div *ngIf="f.visualPro.errors.required" id="confirmMessage">!</div>
          </div>
        </div> -->
        <div *ngIf="mediaType !== 'audio'" class="form-group">
          <input type="text" id="device-name" formControlName="username" placeholder="{{ namePlaceholder }}"
            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" id="username"
            name="username" required />
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <!-- <div *ngIf="f.ipAddress.errors" class="invalid-alert alert alert-danger p-2 m-0">Please enter ip Address.</div> -->
            <div *ngIf="f.username.errors.required" id="confirmMessage">!</div>
          </div>
        </div>

        <div class="form-group">
          <input type="password" id="password" placeholder="{{ passwordPlaceholder }}" class="form-control" required />
        </div>
        <div class="form-group">
          <!-- <button type="submit" class="button float-right" style="font-size:8px;height:30px;width:100px;">Find Device</button> -->
          <button type="submit" class="btn btn-primary float-right">
            Find Device
          </button>
        </div>
      </form>
    </div>
    <div class="col-4 search" *ngIf="highlightNum == 1" [ngClass]="{
        'white-back': showConfirm == 1 && runTutorial == 1 ? true : false
      }">
      <!-- [ngClass]="{'white-back': showTryAgain==1&&runTutorial==1 ? true : false }" -->
      <ng-container *ngIf="showData == true">
        <ng-container *ngIf="addDeviceResponse.length == 0 && !iotTryAgain">
          <ng-container *ngIf="
              deviceActionData.status !== 'Error' &&
              deviceDetails?.status !== 'COMPLETE' &&
              deviceDetails?.status !== 'FAILED' &&
              !errorMessage
              && !searchComplete
            ">
            <ul class="bottom">
              <li>
                <h3>Searching</h3>
              </li>
              <li>
                <div class="dot1"></div>
              </li>
              <li>
                <div class="dot2"></div>
              </li>
              <li>
                <div class="dot3"></div>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="deviceActionData.status == 'Error'">
            <h3 *ngIf="deviceActionData.status == 'Error'" style="color: red;" class="send-text">
              {{ deviceActionData.message }}
            </h3>
          </ng-container>
          <!-- <ng-container *ngIf="deviceDetails?.status === 'COMPLETE' && searchComplete" class="success-form"> -->
          <ng-container *ngIf="deviceDetails?.status === 'COMPLETE'" class="success-form">
            <div class="showLama">
              <img src="../../../assets/img/icon_llama.png" />
            </div>
            <div class="success-form">
              <h3>Device Details</h3>
              <p *ngIf="mediaType !== 'audio'" class="device-text">
                {{ 'Device Name: ' + deviceDetails?.results.device_name }}
              </p>
              <p class="device-text">
                {{ 'Model: ' + deviceDetails?.results.model }}
              </p>
              <p *ngIf="mediaType !== 'audio'" class="device-text">
                {{ 'Serial Number: ' + deviceDetails?.results.serialnumber }}
              </p>
              <p class="device-text">
                {{ 'IPV4: ' + deviceDetails?.results.ipv4 }}
              </p>
              <p class="device-text">
                {{ 'MAC: ' + deviceDetails?.results.mac }}
              </p>
              <p class="device-text">
                {{
                'Software: ' +
                (mediaType !== 'audio'
                ? deviceDetails?.results.softwareinfo
                : deviceDetails?.results.firmware)
                }}
              </p>
              <p *ngIf="mediaType !== 'audio'" class="device-text">
                {{ 'Active Mic: ' + deviceDetails?.results.active_mic }}
              </p>
              <p *ngIf="mediaType !== 'audio'" class="device-text">
                {{ 'Active Camera: ' + deviceDetails?.results.active_camera }}
              </p>
              <div
                *ngIf="mediaType === 'audio' && siteForm.controls['visualPro'].value.length > 0 && iotDeviceData !== undefined"
                style="margin-top: 10px">
                <h5>Visual Pro Details</h5>
                <p class="device-text" *ngIf="mediaType === 'audio'">
                  <!-- {{ 'Model: ' + deviceDetails?.results.model }} -->
                  MAC: {{iotDeviceData.deviceMac}}
                </p>
                <p class="device-text">
                  <!-- {{ 'Serial Number: ' + deviceDetails?.results.serialnumber }} -->
                  IPV4: {{iotDeviceData.deviceIP}}
                </p>
                <p class="device-text">
                  <!-- {{ 'IPV4: ' + deviceDetails?.results.ipv4 }} -->
                  Device Make: {{iotDeviceData.deviceMake}}
                </p>
              </div>

              <div *ngIf="mediaType === 'audio' && siteForm.controls['visualPro'].value.length > 0 && showIotError"
                style="margin-top: 10px">
                <h5>Visual Pro Details</h5>
                <div class="row">
                  <div class="col-6">
                    <p style="color: red !important; margin-left: 20px !important">{{errorMessageIOT}}</p>
                  </div>
                  <div class="col-6">
                    <button type="button" class="btn btn-dark btn-sm" style="margin-left: 5px; width: 100px !important;"
                      (click)="searchComplete = false; getIOTDevices()">Retry</button>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="form-group" *ngIf="siteForm.controls['visualPro'].value.length > 0 && !showIotError">
                  <button (click)="tutClose()" (click)="sendAddAndLinkDeviceData()"
                    class="form-control btn btn-primary float-right" style="margin-top: 10px;">
                    Add Both Devices
                  </button>
                </div>
                <div class="form-group">
                  <button (click)="tutClose()" (click)="sendAddDeviceData()"
                    *ngIf="siteForm.controls['visualPro'].value.length == 0"
                    class="form-control btn btn-primary float-right" style="margin-top: 10px;">
                    Add Device
                  </button>
                </div>
                <div class="form-group">
                  <button (click)="tutClose()" (click)="sendAddDeviceData()"
                    *ngIf="siteForm.controls['visualPro'].value.length > 0 && showIotError"
                    class="form-control btn btn-primary float-right" style="margin-top: 10px;">
                    Add Device (Without VPro)
                  </button>
                </div>
                <div class="form-group pl-2">
                  <button (click)="cancelPressed()" class="form-control btn btn-dark float-left"
                    style="margin-top: 10px;">
                    Cancel
                  </button>
                </div>
              </div>
            </div>

          </ng-container>
          <ng-container>
            <p *ngIf="deviceDetails?.status === 'FAILED'">
              {{ deviceDetails?.results_msssage }}
            </p>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="addDeviceResponse.length > 0 && !iotTryAgain">
          <h3 *ngIf="addDeviceResponse[0].status == 'Error' && isDoneAddingDevice" style="color: red;"
            class="send-text">
            {{ addDeviceResponse[0].message }}
          </h3>
          <h3 *ngIf="addDeviceResponse[0].status == 'Ok' && isDoneAddingDevice" style="color: green;" class="send-text">
            {{ addDeviceResponse[0].message }}
          </h3>
        </ng-container>
        <ng-container *ngIf="errorMessage">
          <div class="d-flex" style="flex-grow: 0.5;">
            <h3 style="color: red; text-align: center;">{{ errorMessage }}</h3>
          </div>
        </ng-container>
        <!-- <ng-container *ngIf="iotTryAgain">
          <form role="form" name="siteForm" class="mt-2" name="siteForm" [formGroup]="siteForm" novalidate>
            <div *ngIf="mediaType === 'audio'" class="form-group">
              <input type="text" formControlName="visualPro" placeholder="For Visual Pro"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.visualPro.errors }" id="visualPro"
                name="visualPro" />
                <div *ngIf="submitted && f.visualPro.errors" class="invalid-feedback">
                  <div *ngIf="f.visualPro.errors.required" id="confirmMessage">!</div>
                </div>
            </div>
            <div class="form-group">
              
              <button (click)="cancelPressed()" class="form-control btn btn-dark float-left"
                style="margin-top: 10px;">
                Cancel
              </button>
              <button  (click)="retryIOT()"
                class="form-control btn btn-primary float-left" style="margin-top: 10px; margin-left: 10px">
                Retry
              </button>
            </div>
          </form>
        </ng-container> -->
      </ng-container>
    </div>
    <div class="col-9" *ngIf="highlightNum == 2" style="overflow: auto; height: 400px;">
      <div class="row">
        <table class="table table-hover table-sm bg-white mt-1" style="padding-bottom: 100px;">
          <thead>
            <tr>
              <th colspan="2" scope="col">Site</th>
              <th scope="col">Device Name</th>
              <th scope="col">Room Name</th>
              <th scope="col">Site Name</th>
              <th scope="col">IP</th>
              <th scope="col">Model</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr scope="row" *ngFor="let alldevices of allDeviceListData">
              <td colspan="2">
                <ng-template [ngIf]="alldevices.virtual == 0">
                  <img [src]="iconAddSite" style="height: 30px;" />
                </ng-template>
                <ng-template [ngIf]="alldevices.virtual == 1">
                  <img [src]="iconVirtual" style="height: 30px;" />
                </ng-template>
                <!-- {{ allsites.site_type }} -->
              </td>
              <td>{{ alldevices.device_name }}</td>
              <td>{{ alldevices.room_name }}</td>
              <td>{{ alldevices.site_name }}</td>
              <td>{{ alldevices.ip_address }}</td>
              <td>{{ alldevices.model }}</td>
              <td>
                <ng-template [ngIf]="alldevices.paused == 1">
                  <div class="status-blue"></div>
                </ng-template>
                <ng-template [ngIf]="alldevices.paused == 0 && alldevices.collector_status == -1">
                  <div class="status-grey"></div>
                </ng-template>
                <ng-template [ngIf]="
                    alldevices.paused == 0 &&
                    alldevices.collector_status == 1 &&
                    alldevices.status == 1
                  ">
                  <div class="status-green"></div>
                </ng-template>
                <ng-template [ngIf]="
                    alldevices.paused == 0 &&
                    alldevices.collector_status == 1 &&
                    alldevices.status == 0
                  ">
                  <div class="status-red"></div>
                </ng-template>
              </td>

              <!-- <td><span class="fa fa-trash fa-lg" (click)="toDelete(1)" (click)= "device_name = alldevices.device_name" (click)= "setDeviceId(alldevices)" ></span></td> -->
              <!-- <td><span class="fa fa-edit fa-lg" (click)="toEdit(1); setDeviceId(alldevices)"></span></td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>