import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TableFilterService } from '../../../services/table-filter.service'
import { PopupTypeService } from '../../../services/popupType.service';


@Component({
  selector: 'app-audio-allsites-stats-panel',
  templateUrl: './audio-allsites-stats-panel.component.html',
  styleUrls: ['./audio-allsites-stats-panel.component.scss']
})

export class AudioAllsitesStatsPanelComponent implements OnInit {
  data: any;
  @Input() audioSitesSummary: any;
  @Output() onFilter: EventEmitter<number> = new EventEmitter();
  currentFilter: string;
  activeFilter: any = "";

  constructor(private ds: PopupTypeService, private tableFilterService: TableFilterService) { }

  setFilter(filter) {
    if (filter == this.activeFilter) {
      this.activeFilter = "";
      this.clickFilter("");
    } else {
      
    this.activeFilter = filter;
      switch (filter) {
        case 'on_call':
          this.clickFilter("on_call");
          break;
        case 'impacted':
          this.clickFilter("impacted")
          break;
        case 'teams_nc':
          this.clickFilter('teams_nc');
          break;
        case 'zoom_nc':
          this.clickFilter('zoom_nc');
          break;
        case 'webex_nc':
          this.clickFilter('webex_nc');
          break;
      }
    }
  }

  clickFilter(filter):void {
    this.tableFilterService.filter(filter);
  }

  ngOnInit(){
    // send message to subscribers via observable subject
    this.ds.sendPopupType(0);
  }

  sendPopupType(p){
    this.ds.sendPopupType(p);
    // console.log(p)
  }

  ngOnDestroy(){
    // clear message
    this.ds.clearPopupType();
  }

}
