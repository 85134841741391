import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-re-authorize',
  templateUrl: './re-authorize.component.html',
  styleUrls: ['./re-authorize.component.scss']
})
export class ReAuthorizeComponent implements OnInit {

  constructor(private _dialogRef:MatDialogRef<ReAuthorizeComponent>) { }

  ngOnInit(): void {
  }

  onClose(status:boolean){
    this._dialogRef.close(status);
  }

}
