import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-btn-qos-disabled',
  templateUrl: './btn-qos-disabled.component.html',
  styleUrls: ['./btn-qos-disabled.component.scss']
})
export class BtnQosDisabledComponent implements OnInit {
  @Input() count: number;
  @Input() label: string = "Not-Active";
  @Input() color: string;
  @Input() backcolorbtn: any;
  @Input() isbutton: string;
  prevcount: string;

  constructor() { }

  ngOnInit() {
  }

}
