import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { TableFilterService } from '../../../services/table-filter.service';

import { PopupTypeService } from '../../../services/popupType.service';

@Component({
  selector: 'app-cloud-all-rooms-stats',
  templateUrl: './cloud-all-rooms-stats.component.html',
  styleUrls: ['./cloud-all-rooms-stats.component.scss']
})
export class CloudAllRoomsStatsComponent implements OnInit {
  data: any;
  @Input() cloudRoomSummary: any;
  @Output() onFilter: EventEmitter<number> = new EventEmitter();
  currentFilter: string;
  calculatedMbps: number;
  activeFilter: any = "";
  constructor(private ds: PopupTypeService, private tableFilterService: TableFilterService, private router: Router) {
  }


  setFilter(filter) {
    if (filter == this.activeFilter) {
      this.activeFilter = "";
      this.clickFilter("");
    } else {

      this.activeFilter = filter;
      switch (filter) {
        case 'on_call':
          this.clickFilter('liveMeetings');
          break;
        case 'impacted':
          this.clickFilter('impacted')
          break;
        case 'online':
          this.clickFilter('online');
          break;
        case 'all':
          this.clickFilter('all');
          break;
      }
    }

  }




  // This function filters the list when certain Live Stat Buttons are clicked
  clickFilter(filter): void {
    this.tableFilterService.filter(filter);
  }

  calMbps() {
    if (!this.cloudRoomSummary) return 0;
    
    const mbps = (this.cloudRoomSummary.bitrate / (1024 * 1024)).toFixed(2)
    this.calculatedMbps = parseFloat(mbps);
    return this.calculatedMbps
  }

  ngOnInit() {
    // send message to subscribers via observable subject
    this.ds.sendPopupType(0);
    var routeType = this.router.url;
    this.clickFilter("");
    this.activeFilter = routeType.includes("impacted") ? "impacted" : "";
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
    // console.log(p)
  }

  ngOnDestroy() {
    // clear message
    this.ds.clearPopupType();
  }

}
