<div class="lastevent">
  <div class="lastevent__stats_ctr">
    <div class="lastevent__title">
      <h5>Last</h5>
      <h5>Event</h5>
    </div>
    <!-- <div class="lastevent__stats_item">
            <div class="lastevent__stats_item_data">
                <h4>{{ timestamp }}</h4>
            </div>
            <div class="lastevent__stats_item_label">{{ message }}</div>
        </div> -->

    <div class="lastevent__stats_item" (click)="openIotDeviceHealthHistory()">
      <div class="lastevent__stats_item_data">
        <h4>{{ latestEvents24h }}</h4>
      </div>
      <div class="lastevent__stats_item_label">events in 24h</div>
    </div>
  </div>
</div>