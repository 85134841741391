import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-audio-allsites-list-table',
  templateUrl: './audio-allsites-list-table.component.html',
  styleUrls: ['./audio-allsites-list-table.component.scss']
})
export class AudioAllsitesListTableComponent implements OnInit {

  constructor(
    private router: Router,
    public Auth: AuthService) {
  }

  @Input() audioSitesListData: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  displayedColumns: string[] = this.Auth.accessLevel == "SUPERADMIN"?['logo','company','site', 'health', 'impacted','devices', 'oncall','mos','totalcalls','totalincidents']:['logo','site', 'health', 'impacted','devices', 'oncall' ,'mos','totalcalls','totalincidents'];
  Math: any = Math;

  ngOnInit(): void {}

  applyFilter(filterValue: string) {

    if (filterValue == "") this.audioSitesListData.filter = "-".trim().toLowerCase();
    else this.audioSitesListData.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {
    this.audioSitesListData.filter = "-".trim().toLocaleLowerCase();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.audioSitesListData.sortingDataAccessor = (item, property) => {
      
      switch (property) {
        case 'company': {
          return item.company_name.toLocaleLowerCase();
        }
        case 'site': {
          return item.site_name.toLocaleLowerCase();
        }
        case 'health': {
          return item.site_health;
        }
        case 'impacted': {
          return item.impacted;
        }
        case 'devices': {
          return item.all_devices;
        }
        case 'oncall': {
          
          return item.on_call;
        }
        case 'mos': {
          return item.total_mos;
        }
        case 'totalcalls': {
          return item.total_calls;
        }
        case 'totalincidents': {
          return item.total_incidents
        }
        default: return item[property];
      }
    };
    
    this.audioSitesListData.sort = this.sort;
  }

  clickRow(row) {

    this.router.navigate(['audio/site/', row.site_id]);
  }
}