<div class="time-box">
  <h5 class="text-center">up time</h5>
  <div class="d-flex flex-row flex-wrap justify-content-around">
    <h4>{{ days }}:</h4>
    <h4>{{ hours }}:</h4>
    <h4>{{ minutes }}</h4>
  </div>
  <div class="d-flex flex-row flex-wrap justify-content-around">
    <p>days</p>
    <p>hrs.</p>
    <p>min.</p>
  </div>
</div>