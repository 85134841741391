import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../services/auth.service'
import { PopupTypeService } from '../../../services/popupType.service'

@Component({
  selector: 'app-cloud-user-stats',
  templateUrl: './cloud-user-stats.component.html',
  styleUrls: ['./cloud-user-stats.component.scss'],
})
export class CloudUserStatsComponent implements OnInit, OnChanges {
  @Input() cloudUserStats: any;
  @Input() liveParticipants: any;
  @Input() dataReceived: any;
  serviceType: any;
  cloudUserLivestats: any;
  cloudUserUtilization: any;

  cloudUserId: any;
  accessLevel: any;
  isNotButton: string;
  hasAccess: boolean;
  isSuperAdmin: boolean;

  isZoomPluginEnabled: boolean = false;
  deskPlugin: String = environment.config.dynamicText.deskPlugin;
  desktopPluginMessage: string;
  deskPluginStatus: number;
  isShowPeopleCount: boolean;
  liveQosTrigger: any = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public ds: PopupTypeService,
    private Auth: AuthService
  ) {
    this.route.params.subscribe((params) => {
      this.cloudUserId = +params['id'];
    });
  }

  ngOnInit() {
    this.getAccessLevels();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cloudUserStats && this.cloudUserStats) {
      this.setData();

      // desktop plugin display message
      this.desktopPluginMessage = this.getDesktopPluginMessage();
      this.isZoomPluginEnabled = this.cloudUserStats.zoom_plugin_enabled === 1;
      this.deskPluginStatus = this.cloudUserStats.plugin_status;
    }
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }

  getAccessLevels = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.accessLevel = decodedToken.accesslevel;
    this.isSuperAdmin = this.Auth.accessLevel === 'SUPERADMIN';
    this.hasAccess = this.getHasAccess();
    this.isNotButton = !this.hasAccess ? 'not' : 'yes';
  };
  setData = () => {
    const {
      zoom_user_id,
      liveMeetings,
      scheduledMeetings,
      scheduled,
      trigger_limits,
      total_bitrate,
      total_jitter,
      avg_packet_loss,
      frame_rate,
      triggers,
      totalMeetings,
      past_meetings,
      totalRecordings,
      recordings,
      zoom_plugin_connected_devices,
      zoom_plugin_enabled,
      plugin_application,
      service_type,
      plugin_status,
    } = this.cloudUserStats;
    
    console.log('this..clouduserstats: stats', this.cloudUserStats )

    this.cloudUserLivestats = {
      liveMeetings,
      scheduledMeetings,
      scheduled,
      cloudUserId: this.cloudUserId,
      hasAccess: this.hasAccess,
      trigger_limits,
      total_bitrate,
      total_jitter,
      avg_packet_loss,
      frame_rate,
      triggers,
      zoom_plugin_connected_devices,
      zoom_plugin_enabled,
      plugin_application,
      zoom_user_id,
      service_type,
      plugin_status,
    };

    this.cloudUserUtilization = {
      totalMeetings,
      past_meetings,
      totalRecordings,
      recordings,
      cloudUserId: this.cloudUserId,
    };
    this.liveQosTrigger = this.cloudUserStats.triggers;
  };

  getHasAccess = () => {
    if (this.accessLevel) {
      if (this.accessLevel === 'USER' || this.isSuperAdmin) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  getDesktopPluginMessage = () => {
    if (this.cloudUserStats.plugin_id === null) {
      return `To enable advance features, please download and install the ${this.deskPlugin}.`;
    }
    return '';
  };

  isShowPanacast() {
    if (this.cloudUserStats) {
      if (
        this.cloudUserStats.zoom_plugin_connected_devices &&
        this.cloudUserStats.zoom_plugin_enabled === 1
      ) {
        if (this.cloudUserStats.plugin_application[0].panacast === -1) {
          return false;
        }
        const cameraList = [...this.cloudUserStats.zoom_plugin_connected_devices.video];
        const panacast = cameraList.filter((cam) => {
          const camName = cam.name.toLowerCase();
          if (camName.search('panacast') !== -1) {
            return cam.primary;
          }
          return false;
        });
        return panacast.length > 0;
      }
      return false;
    }
    return false;
  }
}
