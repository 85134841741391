import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { GetCloudService } from '../../../services/get-cloud.service';
import { environment } from '../../../../environments/environment';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ReAuthorizeComponent } from 'src/app/dialogs/re-authorize/re-authorize.component';

@Component({
  selector: 'app-zoom-enable-service',
  templateUrl: './zoom-enable-service.component.html',
  styleUrls: ['./zoom-enable-service.component.scss'],
})
export class ZoomEnableServiceComponent implements OnInit, OnChanges {
  @Output() boolEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() zoomAccountInfo;

  getUrlPath: Function = environment.apiServicePathBuilder('zoom');
  envTitle: any = environment.config.title;
  loader = environment.config.dynamicImage.loader
  href1: string =
    'https://www.visibility.one/post/zoom-api-how-to-get-your-api-from-the-zoom-marketplace';
  href2: string = 'https://www.visibility.one/post/zoom-verification-token-from-zoom-marketplace';
  apiKey: string = '';
  apiSecret: string = '';
  verificationToken: string = '';
  authorizationLink: any = '';
  btnName: string = 'Add Api Key';
  zoom_status: boolean = false;
  isLoading: boolean = true;
  isSaveLoader: boolean = false;
  // zoomWebHook =
  //   environment.config.webhookUrl !== null
  //     ? environment.config.webhookUrl + 'zoom/zoomWebhook'
  //     : this.getUrlPath('zoomWebhook');

  zoomWebHook: any = '--';
  oAuthRedirectURL: any = '--';

  showApiKeyIcon: any = false;
  showTokenIcon: any = true;

  constructor(private getCloudService: GetCloudService,
              private _dialog: MatDialog
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.zoomAccountInfo && this.zoomAccountInfo) {
      this.setData();
      this.resetLoading();
    }
  }
  resetLoading(){
    this.isLoading = false;
    this.isSaveLoader = false;
  }

  showButtonForAuthLink: any = false

  setData = () => {
    const { APIKey, APISeceret, web_hook_token_verification, active, authorization_link, zoom_status } = this.zoomAccountInfo;
    this.apiKey = APIKey;
    this.apiSecret = APISeceret;
    this.verificationToken = web_hook_token_verification;
    this.authorizationLink = authorization_link;
    this.zoom_status = zoom_status;
  
    console.log('zoomAccountInfo: ', this.zoomAccountInfo)
    console.log('authorization_link: ', authorization_link)

    if (authorization_link == null) {
      // this.btnName = 'Update API Key';
      this.showButtonForAuthLink = true;
    } else {
      this.showButtonForAuthLink = false;
      // window.open(this.authorizationLink);

    }

    console.log('this.showButtonForAuthLink: ', this.showButtonForAuthLink)


    if (this.envTitle === 'VisibilityOne') {
      // this.showTokenIcon = true;
      this.showApiKeyIcon = true;
    }

    this.setZoomWebHook();
  };

  setZoomWebHook = () => {
    const companyId =  this.getCloudService.getCompanyId();
    const zoomWebHook =  this.getUrlPath('zoomWebhook') + '/' + companyId;
    this.zoomWebHook = zoomWebHook;
    this.oAuthRedirectURL = this.getUrlPath('authorizeZoomApp') + '/' + companyId;
  }

  updateApiKey = () => {
    if(this.zoom_status){
      alert('Are you want ')
    }
    this.isSaveLoader = true;
    this.getCloudService
      .udpateApiKey(this.apiKey, this.apiSecret, this.verificationToken)
      .pipe(finalize(()=>this.resetLoading()))
      .subscribe(
        (data: any) => {
          // depends on error then this will emit 
          const { success,authorization_link } = data;

          // window.open(authorization_link);

          if (success) {
            this.authorizationLink = authorization_link;
            this.showButtonForAuthLink = false;
            this.boolEvent.emit('boolSuccess');
          }
        },
        (error) => {
          this.boolEvent.emit('boolErr');
        }
      );
  };

  openAuthLink = () => {
    window.open(this.authorizationLink);

  }
  reAuthorize(){
    const dialog = this._dialog.open(ReAuthorizeComponent,{
      width: '30%'
    })
    dialog.afterClosed().subscribe((x)=>{
      if(x){
        this.openAuthLink();
      }
    })
  }
}