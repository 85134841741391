import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GetIotService } from '../../services/get-iot.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-iot-device-latest-event',
  templateUrl: './iot-device-latest-event.component.html',
  styleUrls: ['./iot-device-latest-event.component.scss'],
})
export class IotDeviceLatestEventComponent implements OnInit, OnChanges {
  @Input() deviceHisoryParams: any;
  lastEventList: any;
  lastEventForFilter: any;
  shouldShowMore: Boolean = true;
  dynamicClass: String = 'latestevent__noevent';
  constructor(private getIot: GetIotService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.deviceHisoryParams) {
      if (this.deviceHisoryParams) {
        // dont get data when paused is 1
        if (this.deviceHisoryParams.paused === 0) {
          this.getDeviceStatusHistory(this.deviceHisoryParams);
        }
      }
    }
  }

  getDateFromNowToLast30Days = () => {
    const dateNow = moment().utcOffset(0, false).format();
    const dateTo = moment(dateNow).utcOffset(0, false).format().split('Z')[0];
    const dateFrom = moment(dateNow)
      .subtract(30, 'days')
      .utcOffset(0, false)
      .format()
      .split('Z')[0];
    return {
      to: dateTo,
      from: dateFrom,
    };
  };

  getDeviceStatusHistory = (params) => {
    const { from, to } = this.getDateFromNowToLast30Days();

    const { iot_device_id, agent_id, account_url, api_key } = params;
    this.getIot
      .getDeviceStatusHistory(iot_device_id, agent_id, account_url, api_key, from, to)
      .subscribe((data) => {
        const deepCopy = data;
        this.dynamicClass = '';
        this.lastEventList = deepCopy;
        this.lastEventForFilter = deepCopy;
        this.lastDeviceDown(data);
        this.filterDataList();
      });
  };

  filterDataList = () => {
    if (this.lastEventList.length > 6) {
      const deepCopy = this.lastEventList;
      const filterArray = deepCopy.slice(0, 6);
      this.lastEventForFilter = filterArray;
    }
  };

  viewMore = () => {
    const deepCopy = this.lastEventList;
    if (this.shouldShowMore) {
      this.lastEventForFilter = deepCopy;
      this.shouldShowMore = false;
    } else {
      this.filterDataList();
      this.shouldShowMore = true;
    }
  };

  formatDate = (date) => {
    return moment(date).format('llll');
  };

  determineStringToDisplay = (string) => {
    switch (string) {
      case 'IP_CHANGE':
        return 'IP Changed';
        break;
      case 'UP':
        return 'Device Went Up';
        break;
      case 'DOWN':
        return 'Device Went Down';
        break;
      default:
        return 'Device Went Up';
    }
  };
  lastDeviceDown(data){
    const result = data?.length ? data[0] : null;
    this.getIot.deviceLastSync.next(result);
  }
}
