import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-btn-signal',
  templateUrl: './btn-signal.component.html',
  styleUrls: ['./btn-signal.component.scss']
})
export class BtnSignalComponent implements OnChanges {
  @Input() signal:any;
  isEnabled:boolean = false;
  blueToothTitle:string = '';
  blueTooth4BarsIcon:string = '../../../../assets/img/audio/icon_bluetooth_green2.png';
  blueTooth3BarsIcon:string = '../../../../assets/img/audio/icon_bluetooth_green1.png';
  blueTooth2BarIcon:string = '../../../../assets/img/audio/icon_bluetooth_yellow.png';
  blueTooth1BarIcon:string = '../../../../assets/img/audio/icon_bluetooth_red.png';
  blueToothDisabled:string = '../../../../assets/img/audio/icon_bluetooth_unavailable1.png';
  blueToothIcon:string = this.blueToothDisabled;
  constructor() { }

  
  ngOnChanges(changes: SimpleChanges):void {
    if (changes.signal.currentValue !== changes.signal.previousValue) {
      if(this.signal !== null && this.signal){
        this.blueToothIcon = this.blueToothDisabled;
        this.blueToothTitle = '';
        if(this.signal.bluetooth){
          this.isEnabled = parseInt(this.signal.bluetooth.enabled) === 1 ? true : false;
        }
        else{
          this.isEnabled = false;
        }
  
        if(this.isEnabled){
          const activeDevice = this.signal.bluetooth.devices.map(d=> {
            if(d.connected === "1"){
              return d;
            }
          });
  
          const signalStrength = parseInt(activeDevice[0].signal_strength);
          this.blueToothTitle = activeDevice[0].signal_strength;
          this.blueToothIcon = this.getWirelessIcon(signalStrength);
        }
      }
    }
  }

  getWirelessIcon(signalStrength: number):string{
    if(signalStrength  < 0 && signalStrength >= -60){
      return this.blueTooth4BarsIcon;
    }
    else if( signalStrength < -61 && signalStrength >= -70 ){
      return this.blueTooth3BarsIcon;
    }
    else if( signalStrength < -71 && signalStrength >= -90 ){
      return this.blueTooth2BarIcon;
    }
    else{
      return this.blueTooth1BarIcon;
    }
  }
}
