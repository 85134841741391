<!-- <div class="container-fluid main-report-panel">
  <strong style="DISPLAY: FLEX; justify-content: center; padding-bottom: 100px;">
    REPORTS CENTER COMING SOON
  </strong>
</div> -->
<div class="container-fluid main-report-panel">
  <div class="row main-report-row">
    <div *ngIf="loading == 1" class="loading-img">
      <img src="loader" />
    </div>
    <div class="col-3">
      <form role="form" (change)="reportUserChange()" class="report-form" name="report-form" id="report-form"
        novalidate>
        <div class="border mb-2" style="background: #f8f8f8; padding: 10px">
          <h5>Report User</h5>
          <label class="container">Management
            <input type="radio" name="radio" id="mangement" />
            <span class="checkmark"></span>
          </label>
          <label class="container">Staff
            <input type="radio" name="radio" id="staff" />
            <span class="checkmark"></span>
          </label>
        </div>
      </form>
      <form role="form" (change)="reportTypeChange()" *ngIf="isManagment || isStaff" class="report-form"
        name="report-form" id="report-form3" novalidate>
        <div class="border mb-2" style="background: #f8f8f8; padding: 10px">
          <h5>Report Type</h5>
          <label class="container">Top 5 Trends
            <input type="radio" name="radio2" id="top-five-trends" />
            <span class="checkmark2"></span>
          </label>
          <label class="container">Video Conferencing
            <input type="radio" name="radio2" id="video-conf" />
            <span class="checkmark2"></span>
          </label>
          <label class="container" *ngIf="isStaff">Inventory
            <input type="radio" name="radio2" id="inventory" />
            <span class="checkmark2"></span>
          </label>
        </div>
      </form>
      <form role="form" (change)="reportDetailsChange()" *ngIf="isTopFiveTrend || isVideoConferencing"
        class="report-form" name="report-form" id="report-form3" novalidate>
        <div class="border mb-2" style="background: #f8f8f8; padding: 10px">
          <h5>Report Details</h5>
          <label class="container">Sites
            <input type="radio" name="radio2" id="sites" />
            <span class="checkmark2"></span>
          </label>
          <!-- 
            <label class="container">Huddle Rooms
              <input type="radio" name="radio2" id="huddle-rooms">
              <span class="checkmark2"></span>
            </label>
 -->
        </div>
      </form>
      <form role="form" *ngIf="isHuddleRooms" class="report-form" name="report-form" id="report-form4" novalidate>
        <div class="border mb-2" style="background: #f8f8f8; padding: 10px; height: 200px; overflow: auto">
          <h5>Huddle Rooms</h5>
          <label class="container">All Huddle Rooms
            <input (change)="huddleIdIndex($event)" id="huddle-list1" type="radio" name="radio4" value="-1" />
            <span class="checkmark4"></span>
          </label>
          <!-- <div *ngIf = "!isTopFiveTrend" >
            <label  *ngFor="let allData of huddleApiData; index as n;" class="container" >{{allData.room_name}}
              <input (change)="huddleIdIndex($event)" id="huddle-list2" type="radio" name="radio4" value={{n}}>
              <span  class="checkmark4"></span>
            </label> 
            </div> -->
        </div>
      </form>
      <form role="form" *ngIf="isSites" class="report-form" name="report-form" id="report-form4" novalidate>
        <div class="border mb-2" style="background: #f8f8f8; padding: 10px; height: 200px; overflow: auto">
          <h5>Sites</h5>
          <label class="container">All Sites
            <input (change)="siteIdIndex($event)" id="site-list1" type="radio" name="radio4" value="-1" />
            <span class="checkmark4"></span>
          </label>
          <label *ngFor="let allData of siteApiData; index as n" class="container" style="word-wrap: break-word">{{
            allData.site_name }}
            <input (change)="siteIdIndex($event)" id="site-list2" type="radio" name="radio4" value="{{ n }}" />
            <span class="checkmark4"></span>
          </label>
        </div>
      </form>
      <form role="form" (change)="reportDeviceChange()"
        *ngIf="isStaff && isVideoConferencing && isSites && isSingleSite" class="report-form" name="report-form"
        id="report-form5" novalidate>
        <div class="border mb-2" style="background: #f8f8f8; padding: 10px; height: 200px; overflow: auto">
          <h5>Devices</h5>
          <label class="container">All Devices
            <input (change)="deviceIdIndex($event)" id="device-list1" type="radio" name="radio5" value="-1" />
            <span class="checkmark4"></span>
          </label>
          <label *ngFor="let allDData of deviceApiData; index as m" class="container" style="word-wrap: break-word">{{
            allDData.device_name }}
            <input (change)="deviceIdIndex($event)" id="device-list2" type="radio" name="radio5" value="{{ m }}" />
            <span class="checkmark4"></span>
          </label>
        </div>
      </form>
      <form role="form" *ngIf="isShowDateRange" class="report-form" name="report-form" id="report-form6" novalidate>
        <div class="border mb-2" style="background: #f8f8f8; padding: 10px">
          <h5>Date Range</h5>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <select (change)="setShowCalender($event)" class="form-control" id="date-range" required>
                  <option value="" selected disabled>Select Range</option>
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                  <option value="quarter">Quarter</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">From:{{ fromDate.month }}/{{ fromDate.day }}/{{ fromDate.year }}</div>
            </div>
            <div class="col-6">
              <div class="form-group">To:{{ toDate.month }}/{{ toDate.day }}/{{ toDate.year }}</div>
            </div>
          </div>
          <ngb-datepicker *ngIf="isShowCalender == 1" style="width: 100%" #dp (select)="onDateSelection($event)"
            [maxDate]="today" [displayMonths]="1" [dayTemplate]="t" outsideDays="hidden">
          </ngb-datepicker>
          <ng-template #t let-date let-focused="focused">
            <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
              {{ date.day }}
            </span>
          </ng-template>
        </div>
      </form>
      <div>
        <!-- ERROR MESSAGE -->
        <p id="feedback" *ngIf="showMissingMsg == 1" class="feedback text-center text-danger">
          Please Select one option under each category before generating report and try again!
        </p>
      </div>
      <form role="form" *ngIf="isDateSelected || isInventory" class="report-form" name="report-form" id="report-form7"
        novalidate>
        <div class="text-center mb-3">
          <button type="submit" class="btn btn-primary" (click)="genReport()">Generate</button>
        </div>
      </form>
    </div>
    <div *ngIf="showRepFlg == 2" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3 mr-0" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
          [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="siteHealthApiData[0].data"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div class="col-12 m-2">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
            [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
            [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="1" [listTitle]="listAllTitle[0]"
                [listColTitle]="colAllTitle[0]"
                [allSiteList]="topFiveSiteApiData[0].data.most_incidents"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="1" [listTitle]="listAllTitle[1]"
                [listColTitle]="colAllTitle[0]"
                [allSiteList]="topFiveSiteApiData[0].data.least_incidents"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="2" [listTitle]="listAllTitle[2]"
                [listColTitle]="colAllTitle[1]"
                [allSiteList]="topFiveSiteApiData[0].data.most_utilized"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="2" [listTitle]="listAllTitle[3]"
                [listColTitle]="colAllTitle[1]"
                [allSiteList]="topFiveSiteApiData[0].data.least_utilized"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="1" [listTitle]="listAllTitle[4]"
                [listColTitle]="colAllTitle[2]"
                [allSiteList]="topFiveSiteApiData[0].data.most_network_incidents"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="1" [listTitle]="listAllTitle[5]"
                [listColTitle]="colAllTitle[2]"
                [allSiteList]="topFiveSiteApiData[0].data.least_network_incidents"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="3" [listTitle]="listAllTitle[6]"
                [listColTitle]="colAllTitle[3]" [trendTitle]="''"
                [allSiteList]="topFiveSiteApiData[0].data.most_uptime"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="3" [listTitle]="listAllTitle[7]"
                [listColTitle]="colAllTitle[3]" [trendTitle]="''"
                [allSiteList]="topFiveSiteApiData[0].data.least_uptime"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div *ngIf="showPoweredBy" class="poweredby m-2 top-five-body">Powered by VisibilityOne</div>
        <div class="pagebreak"></div>
        <div class="col-12 mr-2 mt-5" style="margin-top: 100px !important">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
            [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white pr-3 mr-0" style="height: auto">
          <app-reports-incident-sheet [incidentList]="incidentDeviceApiData"></app-reports-incident-sheet>
        </div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 1" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3 mr-0" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
          [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="siteHealthApiData[0].data"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div class="col-12 m-2">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
            [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
            [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="1" [listTitle]="listAllTitle[0]"
                [listColTitle]="colAllTitle[0]"
                [allSiteList]="topFiveSiteApiData[0].data.most_incidents"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="1" [listTitle]="listAllTitle[1]"
                [listColTitle]="colAllTitle[0]"
                [allSiteList]="topFiveSiteApiData[0].data.least_incidents"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="2" [listTitle]="listAllTitle[2]"
                [listColTitle]="colAllTitle[1]"
                [allSiteList]="topFiveSiteApiData[0].data.most_utilized"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="2" [listTitle]="listAllTitle[3]"
                [listColTitle]="colAllTitle[1]"
                [allSiteList]="topFiveSiteApiData[0].data.least_utilized"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="1" [listTitle]="listAllTitle[4]"
                [listColTitle]="colAllTitle[2]"
                [allSiteList]="topFiveSiteApiData[0].data.most_network_incidents"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="1" [listTitle]="listAllTitle[5]"
                [listColTitle]="colAllTitle[2]"
                [allSiteList]="topFiveSiteApiData[0].data.least_network_incidents"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="3" [listTitle]="listAllTitle[6]"
                [listColTitle]="colAllTitle[3]" [trendTitle]="''"
                [allSiteList]="topFiveSiteApiData[0].data.most_uptime"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[0]" [reportType]="3" [listTitle]="listAllTitle[7]"
                [listColTitle]="colAllTitle[3]" [trendTitle]="''"
                [allSiteList]="topFiveSiteApiData[0].data.least_uptime"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div *ngIf="showPoweredBy" class="poweredby m-2 top-five-body">Powered by VisibilityOne</div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 4" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3 mr-0" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
          [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="siteHealthApiData[0].data"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div class="col-12 m-2">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
            [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
            [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="1"
                [listTitle]="listSingleTitle[0]" [listColTitle]="colSingleTitle[0]"
                [allSiteList]="topFiveDeviceApiData[0].data.most_incidents"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="1"
                [listTitle]="listSingleTitle[1]" [listColTitle]="colSingleTitle[0]"
                [allSiteList]="topFiveDeviceApiData[0].data.least_incidents"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="2"
                [listTitle]="listSingleTitle[2]" [listColTitle]="colSingleTitle[1]"
                [allSiteList]="topFiveDeviceApiData[0].data.most_utilized"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="2"
                [listTitle]="listSingleTitle[3]" [listColTitle]="colSingleTitle[1]"
                [allSiteList]="topFiveDeviceApiData[0].data.least_utilized"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="1"
                [listTitle]="listSingleTitle[4]" [listColTitle]="colSingleTitle[2]"
                [allSiteList]="topFiveDeviceApiData[0].data.most_network_incidents"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="1"
                [listTitle]="listSingleTitle[5]" [listColTitle]="colSingleTitle[2]"
                [allSiteList]="topFiveDeviceApiData[0].data.least_network_incidents"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="3"
                [listTitle]="listSingleTitle[6]" [listColTitle]="colSingleTitle[3]" [trendTitle]="''"
                [allSiteList]="topFiveDeviceApiData[0].data.most_uptime"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="3"
                [listTitle]="listSingleTitle[7]" [listColTitle]="colSingleTitle[3]" [trendTitle]="''"
                [allSiteList]="topFiveDeviceApiData[0].data.least_uptime"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div *ngIf="showPoweredBy" class="poweredby m-2 top-five-body">Powered by VisibilityOne</div>
        <div class="pagebreak"></div>
        <div class="col-12 mr-2 mt-5" style="margin-top: 100px !important">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
            [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white pr-3 mr-0" style="height: auto">
          <app-reports-incident-sheet [incidentList]="incidentDeviceApiData"></app-reports-incident-sheet>
        </div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 3" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3 mr-0" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
          [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="siteHealthApiData[0].data"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div class="col-12 m-2">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
            [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
            [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="1"
                [listTitle]="listSingleTitle[0]" [listColTitle]="colSingleTitle[0]"
                [allSiteList]="topFiveDeviceApiData[0].data.most_incidents"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="1"
                [listTitle]="listSingleTitle[1]" [listColTitle]="colSingleTitle[0]"
                [allSiteList]="topFiveDeviceApiData[0].data.least_incidents"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="2"
                [listTitle]="listSingleTitle[2]" [listColTitle]="colSingleTitle[1]"
                [allSiteList]="topFiveDeviceApiData[0].data.most_utilized"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="2"
                [listTitle]="listSingleTitle[3]" [listColTitle]="colSingleTitle[1]"
                [allSiteList]="topFiveDeviceApiData[0].data.least_utilized"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="1"
                [listTitle]="listSingleTitle[4]" [listColTitle]="colSingleTitle[2]"
                [allSiteList]="topFiveDeviceApiData[0].data.most_network_incidents"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="1"
                [listTitle]="listSingleTitle[5]" [listColTitle]="colSingleTitle[2]"
                [allSiteList]="topFiveDeviceApiData[0].data.least_network_incidents"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="3"
                [listTitle]="listSingleTitle[6]" [listColTitle]="colSingleTitle[3]" [trendTitle]="''"
                [allSiteList]="topFiveDeviceApiData[0].data.most_uptime"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[1]" [reportType]="3"
                [listTitle]="listSingleTitle[7]" [listColTitle]="colSingleTitle[3]" [trendTitle]="''"
                [allSiteList]="topFiveDeviceApiData[0].data.least_uptime"></app-reports-top-five-list>
            </div>
          </div>
          <div *ngIf="showPoweredBy" class="poweredby m-2 top-five-body">Powered by VisibilityOne</div>
        </div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 6" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
          [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="siteHealthApiData[0].data"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div class="col-12 m-2">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
            [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
            [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferSiteApiData[0].data"></app-reports-video-conf-health>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-stats
            [videoConferSite]="videoConferSiteApiData[0].data"></app-reports-video-conf-stats>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-rooms
            [videoConferSite]="videoConferSiteApiData[0].data"></app-reports-video-conf-rooms>
        </div>
        <hr />
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferSiteApiData[0].data"></app-reports-video-conf-devices>
        </div>
        <div *ngIf="showPoweredBy" class="poweredby">Powered by VisibilityOne</div>
        <div class="pagebreak"></div>
        <div class="col-12 m-2" style="margin-top: 100px !important">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
            [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white pr-3 mr-0" style="height: auto">
          <app-reports-incident-sheet [incidentList]="incidentDeviceApiData"></app-reports-incident-sheet>
        </div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 5" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
          [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="siteHealthApiData[0].data"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div class="col-12 m-2">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
            [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
            [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferSiteApiData[0].data"></app-reports-video-conf-health>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-stats
            [videoConferSite]="videoConferSiteApiData[0].data"></app-reports-video-conf-stats>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-rooms
            [videoConferSite]="videoConferSiteApiData[0].data"></app-reports-video-conf-rooms>
        </div>
        <hr />
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferSiteApiData[0].data"></app-reports-video-conf-devices>
        </div>
        <div *ngIf="showPoweredBy" class="poweredby">Powered by VisibilityOne</div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 8" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3 mr-0" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Inventory Report'"
          [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="siteHealthApiData[0].data"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div class="col-12 m-2">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Inventory Report'" [siteType]="genSitetype"
            [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white m-2" style="height: auto">
          <app-reports-inventory *ngIf="inventoryDeviceApiData?.length"
            [reportInventory]="inventoryDeviceApiData[0].data"></app-reports-inventory>
        </div>
        <div *ngIf="showPoweredBy" class="poweredby">Powered by VisibilityOne</div>
        <div class="pagebreak"></div>
        <div class="col-12 m-2" *ngIf="report_user == 2 && isPrint == 1" style="margin-top: 100px !important">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'" [siteType]="genSitetype"
            [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div *ngIf="report_user == 2 && isPrint == 1" class="col-12 rounded bg-white pr-3 mr-0" style="height: auto">
          <app-reports-incident-sheet [incidentList]="incidentDeviceApiData"></app-reports-incident-sheet>
        </div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 7" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3 mr-0" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Inventory Report'"
          [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="siteHealthApiData[0].data"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div class="col-12 m-2">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Inventory Report'" [siteType]="genSitetype"
            [siteName]="genSitename" [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white m-2" style="height: auto">
          <app-reports-inventory *ngIf="inventoryDeviceApiData?.length"
            [reportInventory]="inventoryDeviceApiData[0].data"></app-reports-inventory>
        </div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 9" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [isAllList]="true" [siteTitle]="reportUser"
          [siteSubTitle]="'Video Conferencing'" [siteName]="siteNameData" [fromdate]="genFromDate" [todate]="genToDate"
          [companyInfo]="companyApiData" [letterGrade]="siteHealthApiData[0].data"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div *ngFor="let vodConf of videoConferAllSiteApiData; let i = index">
          <div class="pagebreak"></div>
          <div class="col-12 m-2">
            <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
              [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
              [todate]="genToDate"></app-reports-title-panel>
            <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
              [siteType]="siteNameData[i - 1].virtual" [siteName]="siteNameData[i - 1].site_name"
              [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>
          </div>
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-health
              [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-health>
          </div>
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-stats
              [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-stats>
          </div>
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-rooms
              [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-rooms>
          </div>
          <hr />
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-devices
              [videoConferSite]="videoConferAllSiteApiData[i]"></app-reports-video-conf-devices>
          </div>
          <div *ngIf="showPoweredBy" class="poweredby">Powered by VisibilityOne</div>
          <div class="pagebreak"></div>
          <div class="col-12 m-2" style="margin-top: 100px !important">
            <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
              [siteType]="genSitetype" [siteName]="genSitename" [fromdate]="genFromDate"
              [todate]="genToDate"></app-reports-title-panel>
            <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
              [siteType]="siteNameData[i - 1].virtual" [siteName]="siteNameData[i - 1].site_name"
              [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>
          </div>
          <div class="col-12 rounded bg-white pr-3 mr-0" style="height: auto">
            <app-reports-incident-sheet [incidentList]="incidentAllDeviceApiData[i].data"></app-reports-incident-sheet>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 10" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [isAllDeviceList]="true" [siteTitle]="reportUser"
          [siteSubTitle]="'Video Conferencing'" [siteName]="genSitename" [deviceName]="deviceNameData"
          [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="siteHealthApiData[0].data"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div *ngFor="let vodConf of videoConferDeviceApiData; let i = index">
          <div class="pagebreak"></div>
          <div class="col-12 m-2">
            <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
              [siteType]="genSitetype" [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename"
              [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>
            <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
              [siteType]="genSitetype" [siteName]="genSitename" [isDevice]="true" [deviceName]="deviceNameData[i - 1]"
              [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>
          </div>
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-health
              [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-health>
          </div>
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-stats
              [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-stats>
          </div>
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-rooms
              [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-rooms>
          </div>
          <hr />
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-devices
              [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-devices>
          </div>
          <div *ngIf="showPoweredBy" class="poweredby">Powered by VisibilityOne</div>
          <div class="pagebreak"></div>
          <div class="col-12 m-2" style="margin-top: 100px !important">
            <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
              [siteType]="genSitetype" [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename"
              [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>
            <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
              [siteType]="genSitetype" [siteName]="genSitename" [isDevice]="true" [deviceName]="deviceNameData[i - 1]"
              [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>
          </div>
          <div class="col-12 rounded bg-white pr-3 mr-0" style="height: auto">
            <app-reports-incident-sheet
              [incidentList]="incidentSingleDeviceApiData[i].data"></app-reports-incident-sheet>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 11" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [isSingleDevice]="true" [siteTitle]="reportUser"
          [siteSubTitle]="'Video Conferencing'" [siteName]="genSitename" [deviceName]="genDevicename"
          [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="siteHealthApiData[0].data"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div *ngFor="let vodConf of videoConferDeviceApiData; let i = index">
          <div class="pagebreak"></div>
          <div class="col-12 m-2">
            <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
              [siteType]="genSitetype" [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename"
              [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>
          </div>
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-health
              [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-health>
          </div>
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-stats
              [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-stats>
          </div>
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-rooms
              [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-rooms>
          </div>
          <hr />
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-devices
              [videoConferSite]="videoConferDeviceApiData[i]"></app-reports-video-conf-devices>
          </div>
          <div *ngIf="showPoweredBy" class="poweredby">Powered by VisibilityOne</div>
          <div class="pagebreak"></div>
          <div class="col-12 m-2" style="margin-top: 100px !important">
            <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
              [siteType]="genSitetype" [siteName]="genSitename" [isDevice]="true" [deviceName]="genDevicename"
              [fromdate]="genFromDate" [todate]="genToDate"></app-reports-title-panel>
          </div>
          <div class="col-12 rounded bg-white pr-3 mr-0" style="height: auto">
            <app-reports-incident-sheet
              [incidentList]="incidentSingleDeviceApiData[i].data"></app-reports-incident-sheet>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 12" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3 mr-0" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
          [siteName]="genHuddleName" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="huddleHealth[0].data[0]"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div class="col-12 m-2">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
            [siteType]="genHuddleType" [siteName]="genHuddleName" [fromdate]="genFromDate"
            [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="1"
                [listTitle]="listAllHuddlesTitle[0]" [listColTitle]="colAllTitle[0]"
                [allSiteList]="topFiveHuddleApiData[0].data.most_incidents"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="1"
                [listTitle]="listAllHuddlesTitle[1]" [listColTitle]="colAllTitle[0]"
                [allSiteList]="topFiveHuddleApiData[0].data.least_incidents"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="2"
                [listTitle]="listAllHuddlesTitle[2]" [listColTitle]="colAllTitle[1]"
                [allSiteList]="topFiveHuddleApiData[0].data.most_utilized"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="2"
                [listTitle]="listAllHuddlesTitle[3]" [listColTitle]="colAllTitle[1]"
                [allSiteList]="topFiveHuddleApiData[0].data.least_utilized"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="1"
                [listTitle]="listAllHuddlesTitle[4]" [listColTitle]="colAllTitle[2]"
                [allSiteList]="topFiveHuddleApiData[0].data.most_network_incidents"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="1"
                [listTitle]="listAllHuddlesTitle[5]" [listColTitle]="colAllTitle[2]"
                [allSiteList]="topFiveHuddleApiData[0].data.least_network_incidents"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="3"
                [listTitle]="listAllHuddlesTitle[6]" [listColTitle]="colAllTitle[3]" [trendTitle]="''"
                [allSiteList]="topFiveHuddleApiData[0].data.most_uptime"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="3"
                [listTitle]="listAllHuddlesTitle[7]" [listColTitle]="colAllTitle[3]" [trendTitle]="''"
                [allSiteList]="topFiveHuddleApiData[0].data.least_uptime"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div *ngIf="showPoweredBy" class="poweredby m-2 top-five-body">Powered by VisibilityOne</div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 14" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
          [siteName]="genHuddleName" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="videoConferAllHuddleApiData[0].data[0]"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div class="col-12 m-2">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
            [siteType]="genHuddleType" [siteName]="genHuddleName" [fromdate]="genFromDate"
            [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferAllHuddleApiData[0].data[0]"></app-reports-video-conf-health>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-stats
            [videoConferSite]="videoConferAllHuddleApiData[0].data[0]"></app-reports-video-conf-stats>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-rooms
            [videoConferSite]="videoConferAllHuddleApiData[0].data[0]"></app-reports-video-conf-rooms>
        </div>
        <hr />
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferAllHuddleApiData[0].data[0]"></app-reports-video-conf-devices>
        </div>
        <div *ngIf="showPoweredBy" class="poweredby">Powered by VisibilityOne</div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 15" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
          [siteName]="genHuddleName" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="videoConferSingleHuddleApiData[0].data"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div class="col-12 m-2">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
            [siteType]="genHuddleType" [siteName]="genHuddleName" [fromdate]="genFromDate"
            [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferSingleHuddleApiData[0].data"></app-reports-video-conf-health>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-stats
            [videoConferSite]="videoConferSingleHuddleApiData[0].data"></app-reports-video-conf-stats>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-rooms
            [videoConferSite]="videoConferSingleHuddleApiData[0].data"></app-reports-video-conf-rooms>
        </div>
        <hr />
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferSingleHuddleApiData[0].data"></app-reports-video-conf-devices>
        </div>
        <div *ngIf="showPoweredBy" class="poweredby">Powered by VisibilityOne</div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 16" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3 mr-0" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
          [siteName]="genHuddleName" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="huddleHealth[0].data[0]"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div class="col-12 m-2">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Top 5 High/Low Report'"
            [siteType]="genHuddleType" [siteName]="genHuddleName" [fromdate]="genFromDate"
            [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="1"
                [listTitle]="listAllHuddlesTitle[0]" [listColTitle]="colAllTitle[0]"
                [allSiteList]="topFiveHuddleApiData[0].data.most_incidents"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="1"
                [listTitle]="listAllHuddlesTitle[1]" [listColTitle]="colAllTitle[0]"
                [allSiteList]="topFiveHuddleApiData[0].data.least_incidents"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="2"
                [listTitle]="listAllHuddlesTitle[2]" [listColTitle]="colAllTitle[1]"
                [allSiteList]="topFiveHuddleApiData[0].data.most_utilized"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="2"
                [listTitle]="listAllHuddlesTitle[3]" [listColTitle]="colAllTitle[1]"
                [allSiteList]="topFiveHuddleApiData[0].data.least_utilized"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="1"
                [listTitle]="listAllHuddlesTitle[4]" [listColTitle]="colAllTitle[2]"
                [allSiteList]="topFiveHuddleApiData[0].data.most_network_incidents"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="1"
                [listTitle]="listAllHuddlesTitle[5]" [listColTitle]="colAllTitle[2]"
                [allSiteList]="topFiveHuddleApiData[0].data.least_network_incidents"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div class="col-12 rounded bg-white m-2 top-five-body">
          <div class="row">
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="3"
                [listTitle]="listAllHuddlesTitle[6]" [listColTitle]="colAllTitle[3]" [trendTitle]="''"
                [allSiteList]="topFiveHuddleApiData[0].data.most_uptime"></app-reports-top-five-list>
            </div>
            <div class="col-6">
              <app-reports-top-five-list [col1Title]="listCol1Title[2]" [reportType]="3"
                [listTitle]="listAllHuddlesTitle[7]" [listColTitle]="colAllTitle[3]" [trendTitle]="''"
                [allSiteList]="topFiveHuddleApiData[0].data.least_uptime"></app-reports-top-five-list>
            </div>
          </div>
        </div>
        <div *ngIf="showPoweredBy" class="poweredby m-2 top-five-body">Powered by VisibilityOne</div>
        <div class="pagebreak"></div>
        <div class="col-12 mr-2 mt-5" style="margin-top: 100px !important">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
            [siteType]="genHuddleType" [siteName]="genHuddleName" [fromdate]="genFromDate"
            [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white pr-3 mr-0" style="height: auto">
          <app-reports-incident-sheet [isZoom]="true"
            [incidentList]="incidentAllHuddlesApiData[0].data[0]"></app-reports-incident-sheet>
        </div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 18" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
          [siteName]="genHuddleName" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="videoConferAllHuddleApiData[0].data[0]"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div *ngFor="let vodConf of videoConferAllHuddleApiData[0].data; let i = index">
          <div class="pagebreak"></div>
          <div class="col-12 m-2">
            <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
              [siteType]="genHuddleType" [siteName]="genHuddleName" [fromdate]="genFromDate"
              [todate]="genToDate"></app-reports-title-panel>
            <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
              [siteType]="genHuddleType" [siteName]="huddleApiData[i - 1].room_name" [fromdate]="genFromDate"
              [todate]="genToDate"></app-reports-title-panel>
          </div>
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-health
              [videoConferSite]="videoConferAllHuddleApiData[0].data[i]"></app-reports-video-conf-health>
          </div>
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-stats
              [videoConferSite]="videoConferAllHuddleApiData[0].data[i]"></app-reports-video-conf-stats>
          </div>
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-rooms
              [videoConferSite]="videoConferAllHuddleApiData[0].data[i]"></app-reports-video-conf-rooms>
          </div>
          <hr />
          <div class="col-12 rounded bg-white m-2">
            <app-reports-video-conf-devices
              [videoConferSite]="videoConferAllHuddleApiData[0].data[i]"></app-reports-video-conf-devices>
          </div>
          <div *ngIf="showPoweredBy" class="poweredby">Powered by VisibilityOne</div>
          <div class="pagebreak"></div>
          <div class="col-12 m-2" style="margin-top: 100px !important">
            <app-reports-title-panel *ngIf="i == 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
              [siteType]="genHuddleType" [siteName]="genHuddleName" [fromdate]="genFromDate"
              [todate]="genToDate"></app-reports-title-panel>
            <app-reports-title-panel *ngIf="i != 0" [siteTitle]="reportUser" [siteSubTitle]="'Incident Report'"
              [siteType]="genHuddleType" [siteName]="huddleApiData[i - 1].room_name" [fromdate]="genFromDate"
              [todate]="genToDate"></app-reports-title-panel>
          </div>
          <div class="col-12 rounded bg-white pr-3 mr-0" style="height: auto">
            <app-reports-incident-sheet [isZoom]="true"
              [incidentList]="incidentAllHuddlesApiData[0].data[i]"></app-reports-incident-sheet>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showRepFlg == 19" class="col-9 pr-0" id="report-main-panel" style="height: auto">
      <div class="main-background-report pb-5 px-3" style="background: silver">
        <div class="row">
          <div class="col-12">
            <div class="printer-border mb-2 float-right mt-2">
              <i class="fa fa-print fa-2x menu-icon float-right" (click)="setPrintMode()"></i>
            </div>
          </div>
        </div>
        <app-reports-cover-sheet *ngIf="isPrint == 1" [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
          [siteName]="genHuddleName" [fromdate]="genFromDate" [todate]="genToDate" [companyInfo]="companyApiData"
          [letterGrade]="videoConferSingleHuddleApiData[0].data"></app-reports-cover-sheet>
        <div class="pagebreak"></div>
        <div class="col-12 m-2">
          <app-reports-title-panel [siteTitle]="reportUser" [siteSubTitle]="'Video Conferencing'"
            [siteType]="genHuddleType" [siteName]="genHuddleName" [fromdate]="genFromDate"
            [todate]="genToDate"></app-reports-title-panel>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-health
            [videoConferSite]="videoConferSingleHuddleApiData[0].data"></app-reports-video-conf-health>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-stats
            [videoConferSite]="videoConferSingleHuddleApiData[0].data"></app-reports-video-conf-stats>
        </div>
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-rooms
            [videoConferSite]="videoConferSingleHuddleApiData[0].data"></app-reports-video-conf-rooms>
        </div>
        <hr />
        <div class="col-12 rounded bg-white m-2">
          <app-reports-video-conf-devices
            [videoConferSite]="videoConferSingleHuddleApiData[0].data"></app-reports-video-conf-devices>
        </div>
        <div *ngIf="showPoweredBy" class="poweredby">Powered by VisibilityOne</div>
        <div class="col-12 rounded bg-white pr-3 mr-0" style="height: auto">
          <app-reports-incident-sheet [isZoom]="true"
            [incidentList]="incidentSingleHuddlepiData[0].data"></app-reports-incident-sheet>
        </div>
      </div>
    </div>
  </div>
</div>