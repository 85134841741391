<div class="info-chartSite pt-3 mb-3 chart-container" style="cursor: auto !important;">
  <div class="print-btn">
    <i class="fa fa-print fa-2x menu-icon" (click)="print()"></i>
  </div>

  <div style="text-align: center;">
    <button (click)="set60m()" [ngClass]="{ 'btnpressed': activeBtn == 1 }">
      60m
    </button>
    <button (click)="set24h()" [ngClass]="{ 'btnpressed': activeBtn == 2 }">
      24h
    </button>
    <button (click)="set7d()" [ngClass]="{ 'btnpressed': activeBtn == 3 }">
      7d
    </button>
    <div style="padding-left: 50px !important; display: inline;">
      <label class="switch">
        <input type="checkbox" [checked]="toggleValue == 0" (click)="toggleView(1)" />
        <span class="slider round">
          <span class="paused">chart</span>
          <span class="enabled" style="padding-right: 200px;">list</span>
        </span>
      </label>
    </div>
  </div>

  <!-- CHART  -->

  <div id="print_this">
    <canvas id="myChartSite" width="1150px" height="350px"
      [ngStyle]="{ display: toggleValue == 0 ? 'block' : 'none' }"></canvas>
  </div>

  <!-- LIST  -->
  <div class="list-view" *ngIf="toggleValue == 1" style="display: none; cursor: pointer !important;"
    [ngStyle]="{ display: toggleValue == 1 ? 'block' : 'none' }">
    <div class="row">
      <app-cloud-all-incidents-list [data]="data" [cloudInsListData]="printTableData"
        [cloudInsTableSettings]="cloudInsTableSetting"></app-cloud-all-incidents-list>
    </div>
  </div>

  <!-- this gets printed insted of cloud all incident -->
  <div class="wrap" id="printTable" *ngIf="printMode">
    <table class="head">
      <tr style="font-weight: bold;">
        <td>Time Stamp</td>
        <td>Live Mbps</td>
        <td>Rx</td>
        <td>Tx</td>
        <td>Frame Rate</td>
        <td>Rx</td>
        <td>Tx</td>
        <td>Jitter</td>
        <td>Rx</td>
        <td>Tx</td>
        <td>Packet Loss</td>
        <td>Rx</td>
        <td>Tx</td>
        <td>People Count</td>
        <td>Incidentss Id</td>
      </tr>
    </table>
    <div class="inner_table">
      <table *ngFor="let data of printTableData" style="border-bottom-style: solid; border-bottom-width: 1px;"
        [style.color]="data.zoom_incident_id ? 'red' : 'black'">
        <tr>
          <td>{{ data.timestamp }}</td>
          <td>{{ data.total_mbps }}</td>
          <td>
            {{
            !data.audio_input || !data.video_input || !data.as_input
            ? 0
            : ((data.audio_input.bitrate + data.as_input.bitrate + data.video_input.bitrate) /
            1000000 | twoDecimals)
            }}
          </td>
          <td>
            {{
            !data.audio_output || !data.video_output || !data.as_output
            ? 0
            : ((data.audio_output.bitrate +
            data.as_output.bitrate +
            data.video_output.bitrate) /
            1000000 | twoDecimals)
            }}
          </td>
          <td>
            {{
            !data.video_input || !data.video_output || !data.as_input || !data.as_output
            ? 0
            : data.video_input.frame_rate +
            data.as_input.frame_rate +
            data.video_output.frame_rate +
            data.as_output.frame_rate
            }}
          </td>
          <td>
            {{
            !data.video_input || !data.as_input
            ? 0
            : data.video_input.frame_rate + data.as_input.frame_rate
            }}
          </td>
          <td>
            {{
            !data.video_output || !data.as_output
            ? 0
            : data.video_output.frame_rate + data.as_output.frame_rate
            }}
          </td>
          <td>{{ data.total_jitter }}</td>
          <td>
            {{
            !data.video_input || !data.as_input || !data.audio
            ? 0
            : data.video_input.jitter + data.audio_input.jitter + data.as_input.jitter
            }}
          </td>
          <td>
            {{
            !data.video_output || !data.as_output || !data.audio_output
            ? 0
            : data.video_output.jitter + data.audio_output.jitter + data.as_output.jitter
            }}
          </td>
          <td>{{ data.total_packetloss }}</td>
          <td>
            {{
            !data.video_input || !data.as_input || !data.audio
            ? 0
            : (data.video_input.avg_loss + data.audio_input.avg_loss + data.as_input.avg_loss
            | twoDecimals)
            }}
          </td>
          <td>
            {{
            !data.video_output || !data.as_output || !data.audio_output
            ? 0
            : (data.video_output.avg_loss +
            data.audio_output.avg_loss +
            data.as_output.avg_loss | twoDecimals)
            }}
          </td>
          <td>{{ data.people_count }}</td>
          <td>{{ data.zoom_incident_id }}</td>
        </tr>
        <tr>
          <td>Video</td>
          <td>
            {{
            !data.video_input || !data.video_output
            ? 0
            : ((data.video_input.bitrate + data.video_output.bitrate) / 1000000 | twoDecimals)
            }}
          </td>
          <td>{{ !data.video_input ? 0 : (data.video_input.bitrate / 1000000 | twoDecimals) }}</td>
          <td>
            {{ !data.video_output ? 0 : (data.video_output.bitrate / 1000000 | twoDecimals) }}
          </td>
          <td>
            {{
            !data.video_input || !data.video_output
            ? 0
            : data.video_input.latency + data.video_output.latency
            }}
          </td>
          <td>{{ !data.video_input ? 0 : data.video_input.latency }}</td>
          <td>{{ !data.video_output ? 0 : data.video_output.latency }}</td>
          <td>
            {{
            !data.video_input || !data.video_output
            ? 0
            : data.video_input.jitter + data.video_output.jitter
            }}
          </td>
          <td>{{ !data.video_input ? 0 : data.video_input.jitter }}</td>
          <td>{{ !data.video_output ? 0 : data.video_output.jitter }}</td>
          <td>
            {{ !data.video_input ? 0 : data.video_input.avg_loss + data.video_input.avg_loss }}
          </td>
          <td>{{ !data.video_input ? 0 : data.video_input.avg_loss }}</td>
          <td>{{ !data.video_output ? 0 : data.video_output.avg_loss }}</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Audio</td>
          <td>
            {{
            !data.audio_input || !data.audio_output
            ? 0
            : ((data.audio_input.bitrate + data.audio_output.bitrate) / 1000000 | twoDecimals)
            }}
          </td>
          <td>{{ !data.audio_input ? 0 : (data.audio_input.bitrate / 1000000 | twoDecimals) }}</td>
          <td>
            {{ !data.audio_output ? 0 : (data.audio_output.bitrate / 1000000 | twoDecimals) }}
          </td>
          <td>
            {{
            !data.audio_input || !data.audio_output
            ? 0
            : data.audio_input.latency + data.audio_output.latency
            }}
          </td>
          <td>{{ !data.audio_input ? 0 : data.audio_input.latency }}</td>
          <td>{{ !data.audio_output ? 0 : data.audio_output.latency }}</td>
          <td>
            {{
            !data.audio_input || !data.audio_output
            ? 0
            : data.audio_input.jitter + data.audio_output.jitter
            }}
          </td>
          <td>{{ !data.audio_input ? 0 : data.audio_input.jitter }}</td>
          <td>{{ !data.audio_output ? 0 : data.audio_output.jitter }}</td>
          <td>
            {{
            !data.audio_input || !data.audio_output
            ? 0
            : data.audio_input.avg_loss + data.audio_output.avg_loss
            }}
          </td>
          <td>{{ !data.audio_input ? 0 : data.audio_input.avg_loss }}</td>
          <td>{{ !data.audio_output ? 0 : data.audio_output.avg_loss }}</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Content</td>
          <td>
            {{
            !data.as_input || !data.as_output
            ? 0
            : ((data.as_input.bitrate + data.as_output.bitrate) / 1000000 | twoDecimals)
            }}
          </td>
          <td>{{ !data.as_input ? 0 : (data.as_input.bitrate / 1000000 | twoDecimals) }}</td>
          <td>{{ !data.as_output ? 0 : (data.as_output.bitrate / 1000000 | twoDecimals) }}</td>
          <td>
            {{
            !data.as_input || !data.as_output ? 0 : data.as_input.latency + data.as_output.latency
            }}
          </td>
          <td>{{ !data.as_input ? 0 : data.as_input.latency }}</td>
          <td>{{ !data.as_output ? 0 : data.as_output.latency }}</td>
          <td>
            {{
            !data.as_input || !data.as_output ? 0 : data.as_input.jitter + data.as_output.jitter
            }}
          </td>
          <td>{{ !data.as_input ? 0 : data.as_input.jitter }}</td>
          <td>{{ !data.as_output ? 0 : data.as_output.jitter }}</td>
          <td>
            {{
            !data.as_input || !data.as_output
            ? 0
            : data.as_input.avg_loss + data.as_output.avg_loss
            }}
          </td>
          <td>{{ !data.as_input ? 0 : data.as_input.avg_loss }}</td>
          <td>{{ !data.as_output ? 0 : data.as_output.avg_loss }}</td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
</div>