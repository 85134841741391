<div class="info">
  <div class="info__title text-break">
    <h2>{{ make }}</h2>
  </div>
  <div class="info__text">
    <div class="info__text_item" *ngIf="spaces&&spaces.length">
      <strong>Space: </strong>
      <span *ngFor="let s of spaces;let i=index;">
       <a  [routerLink]="['/iot/spaces/',  s.space_id]">{{
          s.space_name }}</a>
          <span *ngIf="spaces.length-1 != i">,</span>
      </span>
    </div>
    <div class="info__text_item"><strong>Agent:</strong> {{ agentName | displayEmpty }}</div>
    <div class="info__text_item">
      <strong>Device Name:</strong> {{ displayName | displayEmpty }}
    </div>
    <div class="info__text_item"><strong>Model:</strong> {{ model | displayEmpty }}</div>
    <div class="info__text_item">
      <strong>IP address:</strong>{{ ip | displayEmpty }}
      <fa-icon [icon]="faCogIcon" size="2x" class="cog-icon" (click)="openWebPortalOptions()"></fa-icon>
    </div>
    <div class="info__text_item"><strong>MAC:</strong> {{ mac | displayEmpty }}</div>
    <div *ngIf="linkDevice?.device_name" class="info__text_item" (click)="navigateToDnaPage()">
      <strong>Associated with:</strong>
      <span class="info__text-link"> {{ linkDevice.device_name | displayEmpty }}</span>
    </div>
  </div>

  <div class="info__uptime_btn_ctr">
    <div class="info__uptime">
      <div class="info__{{ statusColor }}">{{ statusText }}</div>
    </div>
    <div class="info__uptime info__btn">
      <app-btn-info label="reboot" [faName]="faIcon" *ngIf="isShowReboot()" (click)="openRebootModal()"></app-btn-info>
      <app-btn-info label="{{ paused === 1 ? 'unpause' : 'pause' }}" [faName]="paused == 1 ? faPauseIcon : faPlayIcon"
        (click)="setPaused()"></app-btn-info>

      <app-btn-info label="admin" [faName]="faIcon" (click)="sendPopupType(58)"></app-btn-info>
      <app-btn-info label="wakeup" [faName]="faIcon" *ngIf="isShowWakeUp()" (click)="wakeDevice()"></app-btn-info>
    </div>
  </div>

  <hr />
  <div class="info__chart_title">
    <h4>Historic Ping Range</h4>
  </div>
  <app-chart-historic-ping-range [device_id]="iotDeviceID" [agent_id]="agentId" [account_url]="accountUrl"
    [api_key]="apiKey" [paused]="paused"></app-chart-historic-ping-range>
</div>

<div class="outer-info">
  <div class="outer-info__additional">
    <h4>Additional</h4>
    <div><strong>Timezone:</strong> {{ timezone }}</div>
  </div>
</div>