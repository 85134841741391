import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { PopupTypeService } from '../../services/popupType.service';
import { HandleSpeakerService } from '../../services/handle-speaker.service';
import { GetPluginService } from 'src/app/services/get-plugin.service';


@Component({
  selector: 'app-speaker-confirmation',
  templateUrl: './speaker-confirmation.component.html',
  styleUrls: ['./speaker-confirmation.component.scss']
})
export class SpeakerConfirmationComponent implements OnInit {
  @Input() sliderValue: any;
  @Input() popupState: any;
  @Input() pluginId: number;

  showWarningMessage: boolean = true;

  constructor(public ds: PopupTypeService, public speakerVolumeService: HandleSpeakerService, private getPluginService: GetPluginService) { }

  ngOnInit() {
    // this.ds.sendPopupType(0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sliderValue && this.sliderValue.roomsSliderService) {
      if (this.sliderValue.roomsSliderService === 'teams') {
        this.showWarningMessage = false;
      }
    }
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }

  confirmAddVolume = () => {
    this.speakerVolumeService.defineSpeakerValueForCallBack = true
    this.ds.sendPopupType(0);
    const actionParams = JSON.stringify({ value: this.sliderValue.sliderValue });
    const actionName = 'SetConfigurationAudioOutputVolume';
    this.getPluginService.createAction(this.pluginId, actionParams, actionName).subscribe(result => {
    });

  }

  cancelAddVolume = () => {
    this.speakerVolumeService.defineSpeakerValueForCallBack = false
    this.ds.sendPopupType(0);
  }

}
