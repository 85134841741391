<div class="teams-room-device-health">
  <div class="teams-room-device-health__self-healing-container">
    <h5 class="teams-room-device-health__label-h5">Device(s) Health</h5>
    <div class="teams-room-device-health__self-healing-switch">
      <label class="switch">
        <input type="checkbox" [checked]="checkSelfHealing(cloudUserStats)" (change)="updateSelfHealing($event)" />
        <span class="slider round">
          <span class="paused"></span>
        </span>
      </label>
      <strong class="self-healing-on">Self-Healing {{ checkSelfHealing(cloudUserStats) ? 'On' : 'Off' }}</strong>
    </div>
  </div>
  <div class="teams-room-device-health__desktop-plugin-status" (click)="displayPluginInfo()">
    <div class="teams-room-device-health__text-button-container">
      <img src="../../../../assets/img/video/icon_screen_phone.png" alt="" />
      <div>
        {{ pluginTextStatus }}
        <!-- Offline -->
      </div>
    </div>
    <div class="teams-room-device-health__text-button-container">
      <div>{{ plugin }} Status</div>
      <div class="teams-room-device-health__status"></div>
      <div [ngClass]="{
            'teams-room-device-health__status-offline':
              isPluginIntegrated && pluginStatus === -1,
            'teams-room-device-health__status-warning':
              isPluginIntegrated && pluginStatus === 2,
            'teams-room-device-health__status-online':
              isPluginIntegrated && pluginStatus === 1,
            'teams-room-device-health__status-disabled':
              !isPluginIntegrated || pluginStatus === null
          }"></div>
    </div>
  </div>
  <!--  -->
  <div class="teams-room-device-health__devices-container">
    <div class="teams-room-device-health__two-columns">
      <app-teams-room-device-health-button [quadrantType]="'mic'" [cloudUserStats]="cloudUserStats"
        [logitechSync]="logitechSync" class="teams-room-device-health__device-health-button--short">
      </app-teams-room-device-health-button>

      <app-teams-room-device-health-button [quadrantType]="'camera'" [cloudUserStats]="cloudUserStats"
        [logitechSync]="logitechSync" class="teams-room-device-health__device-health-button--short">
      </app-teams-room-device-health-button>
    </div>

    <div class="teams-room-device-health__two-columns">
      <app-teams-room-device-health-button [quadrantType]="'speaker'" [cloudUserStats]="cloudUserStats"
        [logitechSync]="logitechSync" class="teams-room-device-health__device-health-button--long">
      </app-teams-room-device-health-button>
    </div>
  </div>
</div>