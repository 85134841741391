<!-- needs to be fix -->
<div class="row pt-2 pb-2 right-panel">
  <div class="col-12">
    <app-panel-tools [PopupTitle]="PopupTitle"></app-panel-tools>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-6 pl-1">
        <app-cloud-all-users-info [cloudUserSummary]="cloudUserSummary"></app-cloud-all-users-info>
      </div>
      <div class="col-6">
        <div class="col-12 px-0">
          <app-system-health [healthNum]="cloudUserSummary?.health"></app-system-health>
        </div>
        <app-cloud-all-users-stats [cloudUserSummary]="cloudUserSummary" [loading]="loading">
        </app-cloud-all-users-stats>
      </div>
    </div>

    <div class="row">
      <div class="col-12 px-1 filter-table">
        <app-cloud-all-users-list [userTotalCount]="cloudUserSummary?.total_users"></app-cloud-all-users-list>
      </div>
    </div>
  </div>
</div>