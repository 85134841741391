import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-reports-video-conf-huddle',
  templateUrl: './reports-video-conf-huddle.component.html',
  styleUrls: ['./reports-video-conf-huddle.component.scss']
})
export class ReportsVideoConfHuddleComponent implements OnInit {
  @Input() videoConferSite: any;
  left: number = 0;
  @Input() healthNum: any;
  @Input() fullBarStatus: number = 0;
  val: number = 90;
  curval: number = 0;
  preval: number = 0;
  reportCircle: any = 'red';
  incidents_trend: any = 2;
  restore_time_trend:any = -3;
  
  constructor() { }

  ngOnInit() {
  }

}
