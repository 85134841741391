import { Component, OnInit, Input } from '@angular/core';
import { PopupTypeService } from '../../services/popupType.service';
import { GetDesktopPluginService } from '../../services/get-desktop-plugin.service';

@Component({
  selector: 'app-cloud-user-reboot',
  templateUrl: './cloud-user-reboot.component.html',
  styleUrls: ['./cloud-user-reboot.component.scss'],
})
export class CloudUserRebootComponent implements OnInit {
  @Input() data: any;
  succesMessage: string = 'Reboot Success';
  showRebootMessage: boolean = false;
  showConfirmation: boolean = false;
  messageclass: any = 'cloud-user-reboot__sync-message--success';
  constructor(public ds: PopupTypeService, private desktopPlugin: GetDesktopPluginService) { }

  ngOnInit() { }

  hideMessage = () => {
    setTimeout(() => {
      this.showRebootMessage = false;
    }, 2000);
  };

  rebootDesktopPlugin = () => {
    // console.log(this.data);

    this.showRebootMessage = true;
    this.succesMessage = 'Reboot Success!';
    this.messageclass = 'cloud-user-reboot__sync-message--success';

    this.hideMessage();
    if (!this.data.zoom_plugin_enabled) {
      this.ds.sendPopupType(59, { data: this.data.desktop_plugin_message });
    } else {
      const actionParams = null;
      const actionName = 'Reboot';
      this.showConfirmation = false;
      this.desktopPlugin.createAction(this.data.plugin_id, actionParams, actionName).subscribe(
        (data) => {
          this.showRebootMessage = true;
          this.succesMessage = 'Reboot Success!';
          this.messageclass = 'cloud-user-reboot__sync-message--success';
          this.hideMessage();
        },
        (error) => {
          this.showRebootMessage = true;
          this.succesMessage = 'Reboot Failed!';
          this.messageclass = 'cloud-user-reboot__sync-message--error';
          this.hideMessage();
        }
      );
    }
  };
}
