import { trigger, state, style, transition,
  animate, group, query, stagger, keyframes
} from '@angular/animations';

export const HealthblockUpperLeft = [
  trigger('slideUpperLeft', 
  [
      state('home', style({
        //   transform: 'scale(1)',
        //   top: '200px',
        //   left: '200px'
      })),
      state('left', style({
          transform: 'scale(0.7)',
          left: '15px',
          top: '50px'
      })),
      // transition('close => open', animate('1500ms ease-in-out')),
      transition('home => left', animate('100ms', keyframes([
          style({ transform: 'scale(.9)'}),
        //   style({ transform: 'scale(.7)'}),
        //   style({ transform: 'scale(.5)'}),
        //   style({ left: '100px'}),
        //   style({ left: '70px'}),
          style({ top: '100px'})
      ]))),
      // transition('open => close', animate('1800ms ease-in-out'))
      transition('left => home', animate('100ms', keyframes([
          style({ top: '100px'}),
        //   style({ left: '60px'}),
        //   style({ left: '90px'}),
        //   style({ transform: 'scale(.5)'}),
        //   style({ transform: 'scale(.7)'}),
          style({ transform: 'scale(.9)'}),
    ])))
])]