import { HttpClient } from '@angular/common/http'
import { EventEmitter, Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { AuthService } from './auth.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

export class Space {
	space_id: number
	space_name: string
	agent_id?: number
	agent_name: string
	active: number
	is_monitored: number
	is_paused: number

	constructor(obj: Space) {
		Object.assign(this, obj)
	}
}

@Injectable({
	providedIn: 'root',
})
export class GetSpaceService {
	SpaceChangedEvent: EventEmitter<any> = new EventEmitter()
	constructor(private http: HttpClient, private auth: AuthService) {}

	getAllSpaces(searchKey: string = null, isDomotz: boolean): Observable<Space[]> {
		const body = {
			company_id: this.auth.companyId,
			search_key: searchKey,
			isDomotz: !!isDomotz,
		}
		const url = environment.serverUrl + `/api/v1/spaces/get-spaces`
		return this.http.post<any>(url, body).pipe(
			map(data => {
				return data?.result?.map(space => new Space(space))
			})
		)
	}

	addSpace(body: any) {
		const url = environment.serverUrl + `/api/v1/spaces/add-space`
		return this.http.post<any>(url, body)
	}

	editSpace(space: Space): Observable<any> {

		// space_id: number, space_name?: string, agent_id?: number, isDomotz?: boolean
		// const body = {
		// 	space_id,
		// }

		// if (space_name !== null) body['space_name'] = space_name
		// if (agent_id !== null) body['agent_id'] = agent_id
		// if (isDomotz !== null) body['isDomotz'] = isDomotz

		const url = environment.serverUrl + `/api/v1/spaces/update-space`
		return this.http.put<any>(url, space)
	}

	deleteSpace(space_id: number, isDomotz: boolean): Observable<any> {
		const body = {
			space_id,
			isDomotz,
		}

		const url = environment.serverUrl + `/api/v1/spaces/delete-space`
		return this.http.post<any>(url, body)
	}

	monitorUnmonitorSpace(space_id: number, is_monitored: number, isDomotz: boolean): Observable<any> {
		const body = {
			space_id,
			is_monitored,
			isDomotz,
		}

		const url = environment.serverUrl + `/api/v1/spaces/monitor-unmonitor-space`
		return this.http.put<any>(url, body)
	}

	pauseUnpauseSpace(space_id: number, is_paused: number, isDomotz: boolean): Observable<any> {
		const body = {
			space_id,
			is_paused,
			isDomotz
		}

		const url = environment.serverUrl + `/api/v1/spaces/pause-unpause-space`
		return this.http.put<any>(url, body)
	}
  
	linkDeviceToSpace(body:any){
		const url = environment.serverUrl + `/api/v1/spaces/link-device-to-space`
		return this.http.post<any>(url, body)
	}
}
