<!-- stats buttons -->
<div class="row">
    <div class="col-12">
      <h5>Live Stats</h5>
    </div>
    <div class="col-4 btn-col pl-0 pr-1">
      <app-btn-reg-stats [count]="audioSitesData?.impacted_devices" label='Impacted' (click)="setFilter('impacted')" [ngClass]="{'outlineBtn': activeFilter == 'impacted'}" color='gray'></app-btn-reg-stats>
    </div>
    <div class="col-4 btn-col pl-0 pr-1">
      <app-btn-reg-stats [count]="audioSitesData?.on_call" label='On Call' (click)="setFilter('on_call')" [ngClass]="{'outlineBtn': activeFilter == 'on_call'}"  color='gray'></app-btn-reg-stats>
    </div>
    <div class="col-4 btn-col pl-0 pr-1">
      <app-btn-bitrate [count]="calMbps()" label='Live Mbps' color='gray' isbutton="not" ></app-btn-bitrate>
      <!-- (click)="sendPopupType(6)" -->
    </div>
  </div>
  <!-- hr line space -->
  <div class="row">
    <div class="col-12 pl-0 pr-1">
      <hr>
    </div>
  </div>
    <!-- stats buttons -->
  <div class="row">
    <div class="col-12 pl-0 pr-1">
        <h5>Today</h5>
        <app-btn-long-meetings [isButton] = "isButton"  (click)="ds.sendPopupType(35, {meetings: audioSitesData?.meetings})" [count]="audioSitesData?.scheduled_meetings"  color='gray'></app-btn-long-meetings>
    </div>
  </div>
  <!-- hr line space -->
  <div class="row">
    <div class="col-12 pl-0 pr-1">
      <hr>
    </div>
  </div>
  <!-- stats buttons -->
  <div class="row">
      <div class="col-12">
        <h5>Company Information</h5>
      </div>
      <div *ngIf="isVirtualSite != 1 && audioSitesData?.physical_sites_count >1;else disableQosButton" class="col-4 pl-0 pr-1">
        <app-btn-qos  (click)="ds.sendPopupType(3, siteId)" ></app-btn-qos> 
      </div>
      <ng-template #disableQosButton>
        <div class="col-4 pl-0 pr-1">
          <app-btn-qos-disabled class="Disabled" label='Qos Not Avaliable' ></app-btn-qos-disabled> 
      </div>
      </ng-template>
    
      <div class="col-4 pl-0 pr-1">
        <app-btn-reg-stats [count]="audioSitesData?.infrastructure" label="Infrastructure" color='gray' isbutton="not"></app-btn-reg-stats>
      </div>
      <div class="col-4 pl-0 pr-1">
        <app-btn-reg-stats [count]="audioSitesData?.total_devices" label="Devices" color='gray' isbutton="not"></app-btn-reg-stats>
      </div>
  </div>
  