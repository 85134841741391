<div class="iot-component">
  <div class="iot-component__header">
    <h5>IoT Component(s)</h5>
    <div class=" {{ addComponentClass }}" (click)="showAddDevice()">
      Add a Component
      <img class="iot-component__add-button" *ngIf="!shouldShowAddDevice" src="../../../assets/img/iot/add-btn.png"
        alt="" />
      <img *ngIf="shouldShowAddDevice" src="../../../assets/img/iot/delete-btn.png" alt="" />
    </div>
  </div>

  <div *ngIf="shouldShowAddDevice" class="iot-component__add-component">
    <div class="iot-component__type">
      <div>Type</div>
      <div class="iot-component__dropdown">
        <div class="dropdown-first-child">
          <img src="{{ selectDevice }}" alt="" />
          <div class="select-arrow"></div>
        </div>
        <div class="dropdown-content">
          <div *ngFor="let item of selectData">
            <div (click)="selectMainItem(item)">
              <img src="{{ item.value }}" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="iot-component__device-name">
      <div>Device Name</div>
      <input type="text" value="selectDeviceName" [(ngModel)]="selectDeviceName"
        [ngClass]="{ 'input-error': showNameErrorMessage }" />
    </div>

    <div class="iot-component__device-ip">
      <div>Device IP</div>
      <input type="text" value="selectDeviceIP" [(ngModel)]="selectDeviceIP"
        [ngClass]="{ 'input-error': showIpErrorMessage }" />
    </div>

    <div class="iot-component__add-component-inner clickable">
      <img *ngIf="!shouldShowLoaderBtn" src="../../../assets/img/iot/add-btn.png" alt="" />
      <img *ngIf="shouldShowLoaderBtn" src="{{ iotLoader }}" alt="" />
    </div>
  </div>

  <div class="iot-component__message" [ngClass]="{ 'error': showErrorMessage }" *ngIf="showErrorMessage">
    {{ iotMessage }}
  </div>

  <div class="iot-component__component">
    <table class="table">
      <thead>
        <tr></tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of iotComponents">
          <td>
            <!-- <div class="circle-stat {{ getStatus(item.status, isDomotzEnabled, item.paused) }}"></div> -->
            <div class="circle-stat {{ getStatus(item) }}"></div>
          </td>
          <td><img src="{{ getImageType(item.iot_type) }}" alt="" /></td>
          <td>
            <div [ngClass]="{ 'iot-component__link-name': true }" class="iot-component__elipsis-text"
              (click)="navigateToIotDna(item)">
              {{ item.iot_name }}
            </div>
          </td>
          <td>
            <div class="iot-component__elipsis-text">
              {{ item.iot_ip }}
            </div>
          </td>
          <td>
            <div class="iot-component__device-button">
              <img (click)="openWebPortalOptions(item)" src="{{ getSettingStatus(1) }}" alt="" />
            </div>
          </td>
          <td class="clickable" (click)="showDeleteModal(item)">
            <img src="../../../assets/img/iot/trash.png" alt="" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="iot-component__delete-modal-container" *ngIf="shouldShowDeleteModal">
    <div class="iot-component__delete-modal">
      <div class="delete-modal-1-child">
        <p>Are you sure you want to delete "{{ iotName }}" ?</p>
        <button class="btn-confirm" (click)="confirmDelete()">Yes</button>
        <button class="btn-cancel" (click)="cancelDelete()">Cancel</button>
      </div>
    </div>
  </div>
</div>