import { Component, Input, SimpleChanges, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { PopupTypeService } from '../../../services/popupType.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-btn-device-camera-zoom',
  templateUrl: './btn-device-camera-zoom.component.html',
  styleUrls: ['./btn-device-camera-zoom.component.scss'],
})
export class BtnDeviceCameraZoomComponent implements OnChanges, OnInit, OnDestroy {
  @Input() connectedCameras: any;
  @Input() pluginId: number;
  @Input() failOver: boolean;
  @Input() defaultDevice: string;
  @Input() pluginStatus: any;
  data: any;
  cameraBackup: any;
  icon: String = '../../assets/img/cloud/icon_camera.png';
  iconTriangleGreen: String = '../../assets/img/video/triangle_green.png';
  iconTriangleRed: String = '../../assets/img/video/triangle_red.png';
  iconTriangleGrey: String = '../../assets/img/video/triangle_grey.png';
  iconTriangleOrange: String = '../../assets/img/video/traingle_orange.png';
  inuseCam = {
    status: -1,
    name: '', //wrong implementation
  };
  defaultName: string;

  constructor(public ds: PopupTypeService) { }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }

  ngOnInit() {
    this.ds.sendPopupType(0);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.connectedCameras && this.connectedCameras) {
        this.setCamera();
    }
  }

  setCamera = () => {
    const copiedArray = [...this.connectedCameras];
    this.defaultName = this.checkDefaultIfEmpty(this.defaultDevice);
    if (copiedArray.length !== 0) {
      this.inuseCam = this.getInuseCam(copiedArray);
      if (this.inuseCam.status === 1) {
        this.cameraBackup = copiedArray.filter((item) => item.backup !== 0);
        this.data = {
          connectedCameras: copiedArray,
          pluginId: this.pluginId,
          failOver: this.failOver,
        };
        
      } else {
        this.inuseCam = {
          status: -1,
          name: this.defaultName,
        };
        this.data = {
          connectedCameras: {},
          pluginId: this.pluginId,
          failOver: this.failOver,
        };
      }

    } else {
      this.inuseCam = {
        status: -1,
        name: this.defaultName,
      };
    }
  }


  checkDefaultIfEmpty = (name) => {
    if (name === null || typeof name === 'undefined' || name.trim() === '') {
      return 'No Data';
    }
    return name;
  };

  getInuseCam = (cam) => {
    const inuseCam = cam.filter((c) => c.inuse);
    if (!this.isEmpty(inuseCam)) {
      return inuseCam[0];
    }
    return {
      status: -1,
      name: this.defaultDevice,
    };
  };

  iconOnHover() {
    this.icon = '../../assets/img/cloud/icon_camera_blue.png';
  }

  iconOnLeave() {
    this.icon = '../../assets/img/cloud/icon_camera.png';
  }

  getStatusIcon(primary) {
    const { status, name } = primary;
    if (status === 1 || name !== 'No Data') {
      return this.iconTriangleGreen;
    } else {
      return this.iconTriangleRed;
    }
  }

  isEmpty = (arr) => {
    return arr.length === 0;
  };
}
