<div class="row right-panel">
  <div class="col-12">
    <app-panel-tools [PopupTitle]="PopupTitle"></app-panel-tools>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-6 pl-1">
        <app-video-site-info-panel [videoSiteInfo]="videositesInfo"></app-video-site-info-panel>
      </div>
      <div class="col-6">
        <div class="col-12">
          <app-system-health [healthNum]="videoSitesData?.health"></app-system-health>
        </div>
        <app-video-site-stats-panel
          [isVirtualSite]="videositesInfo ? videositesInfo[0]?.virtual : 0"
          [videoSitesData]="videoSitesData"
        ></app-video-site-stats-panel>
      </div>
    </div>
    <div class="row" style="top: -40px; position: relative; margin-bottom: -40px; padding-top: 2%;">
      <div class="col-12 px-1 filter-table">
        <app-video-alldevices-list-table
          [videoDevicesListData]="videoDevicesListData"
        ></app-video-alldevices-list-table>
      </div>
    </div>
  </div>
</div>
