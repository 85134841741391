import { Component, AfterViewInit } from '@angular/core';
// import { DataService } from './../data.service';
import * as Chart from 'chart.js';
import { Observable } from 'rxjs/Rx';

@Component({
  selector: 'app-chart-audio-twentyfourhours',
  templateUrl: './chart-audio-twentyfourhours.component.html',
  styleUrls: ['./chart-audio-twentyfourhours.component.scss'],
})
export class ChartAudioTwentyfourhoursComponent implements AfterViewInit {
  canvas: any;
  ctx: any;
  myChartSite: any;
  currentChart = '24h';
  data60m = {
    datasets: [
      {
        label: 'Calls',
        data: [
          { 'y': 18, 'x': '6/1/18 12:14' },
          { 'y': 15, 'x': '6/1/18 12:13' },
          { 'y': 16, 'x': '6/1/18 12:12' },
          { 'y': 15, 'x': '6/1/18 12:11' },
          { 'y': 12, 'x': '6/1/18 12:10' },
          { 'y': 13, 'x': '6/1/18 12:09' },
          { 'y': 16, 'x': '6/1/18 12:08' },
          { 'y': 17, 'x': '6/1/18 12:07' },
          { 'y': 15, 'x': '6/1/18 12:06' },
          { 'y': 17, 'x': '6/1/18 12:05' },
          { 'y': 18, 'x': '6/1/18 12:04' },
          { 'y': 21, 'x': '6/1/18 12:03' },
          { 'y': 24, 'x': '6/1/18 12:02' },
          { 'y': 25, 'x': '6/1/18 12:01' },
          { 'y': 27, 'x': '6/1/18 12:00' },
          { 'y': 27, 'x': '6/1/18 11:59' },
          { 'y': 27, 'x': '6/1/18 11:58' },
          { 'y': 24, 'x': '6/1/18 11:57' },
          { 'y': 21, 'x': '6/1/18 11:56' },
          { 'y': 24, 'x': '6/1/18 11:55' },
          { 'y': 23, 'x': '6/1/18 11:54' },
          { 'y': 22, 'x': '6/1/18 11:53' },
          { 'y': 21, 'x': '6/1/18 11:52' },
          { 'y': 24, 'x': '6/1/18 11:51' },
          { 'y': 21, 'x': '6/1/18 11:50' },
          { 'y': 21, 'x': '6/1/18 11:49' },
          { 'y': 19, 'x': '6/1/18 11:48' },
          { 'y': 19, 'x': '6/1/18 11:47' },
          { 'y': 16, 'x': '6/1/18 11:46' },
          { 'y': 18, 'x': '6/1/18 11:45' },
          { 'y': 19, 'x': '6/1/18 11:44' },
          { 'y': 21, 'x': '6/1/18 11:43' },
          { 'y': 23, 'x': '6/1/18 11:42' },
          { 'y': 22, 'x': '6/1/18 11:41' },
          { 'y': 19, 'x': '6/1/18 11:40' },
          { 'y': 18, 'x': '6/1/18 11:39' },
          { 'y': 20, 'x': '6/1/18 11:38' },
          { 'y': 23, 'x': '6/1/18 11:37' },
          { 'y': 22, 'x': '6/1/18 11:36' },
          { 'y': 23, 'x': '6/1/18 11:35' },
          { 'y': 22, 'x': '6/1/18 11:34' },
          { 'y': 21, 'x': '6/1/18 11:33' },
          { 'y': 19, 'x': '6/1/18 11:32' },
          { 'y': 21, 'x': '6/1/18 11:31' },
          { 'y': 21, 'x': '6/1/18 11:30' },
          { 'y': 18, 'x': '6/1/18 11:29' },
          { 'y': 16, 'x': '6/1/18 11:28' },
          { 'y': 17, 'x': '6/1/18 11:27' },
          { 'y': 19, 'x': '6/1/18 11:26' },
          { 'y': 17, 'x': '6/1/18 11:25' },
          { 'y': 18, 'x': '6/1/18 11:24' },
          { 'y': 15, 'x': '6/1/18 11:23' },
          { 'y': 17, 'x': '6/1/18 11:22' },
          { 'y': 14, 'x': '6/1/18 11:21' },
          { 'y': 13, 'x': '6/1/18 11:20' },
          { 'y': 13, 'x': '6/1/18 11:19' },
          { 'y': 12, 'x': '6/1/18 11:18' },
          { 'y': 15, 'x': '6/1/18 11:17' },
          { 'y': 16, 'x': '6/1/18 11:16' },
        ],
        backgroundColor: ['rgb(100, 120, 183, .5)'],
        borderWidth: 0,
      },
      {
        label: 'Quick disconnects',
        data: [
          { 'y': 2, 'x': '6/1/18 12:14' },
          { 'y': 2, 'x': '6/1/18 12:13' },
          { 'y': 2, 'x': '6/1/18 12:12' },
          { 'y': 2, 'x': '6/1/18 12:11' },
          { 'y': 1, 'x': '6/1/18 12:10' },
          { 'y': 1, 'x': '6/1/18 12:09' },
          { 'y': 1, 'x': '6/1/18 12:08' },
          { 'y': 1, 'x': '6/1/18 12:07' },
          { 'y': 1, 'x': '6/1/18 12:06' },
          { 'y': 2, 'x': '6/1/18 12:05' },
          { 'y': 1, 'x': '6/1/18 12:04' },
          { 'y': 1, 'x': '6/1/18 12:03' },
          { 'y': 2, 'x': '6/1/18 12:02' },
          { 'y': 1, 'x': '6/1/18 12:01' },
          { 'y': 3, 'x': '6/1/18 12:00' },
          { 'y': 2, 'x': '6/1/18 11:59' },
          { 'y': 2, 'x': '6/1/18 11:58' },
          { 'y': 2, 'x': '6/1/18 11:57' },
          { 'y': 2, 'x': '6/1/18 11:56' },
          { 'y': 2, 'x': '6/1/18 11:55' },
          { 'y': 2, 'x': '6/1/18 11:54' },
          { 'y': 2, 'x': '6/1/18 11:53' },
          { 'y': 2, 'x': '6/1/18 11:52' },
          { 'y': 1, 'x': '6/1/18 11:51' },
          { 'y': 2, 'x': '6/1/18 11:50' },
          { 'y': 2, 'x': '6/1/18 11:49' },
          { 'y': 1, 'x': '6/1/18 11:48' },
          { 'y': 1, 'x': '6/1/18 11:47' },
          { 'y': 1, 'x': '6/1/18 11:46' },
          { 'y': 2, 'x': '6/1/18 11:45' },
          { 'y': 2, 'x': '6/1/18 11:44' },
          { 'y': 2, 'x': '6/1/18 11:43' },
          { 'y': 2, 'x': '6/1/18 11:42' },
          { 'y': 2, 'x': '6/1/18 11:41' },
          { 'y': 2, 'x': '6/1/18 11:40' },
          { 'y': 1, 'x': '6/1/18 11:39' },
          { 'y': 2, 'x': '6/1/18 11:38' },
          { 'y': 2, 'x': '6/1/18 11:37' },
          { 'y': 1, 'x': '6/1/18 11:36' },
          { 'y': 2, 'x': '6/1/18 11:35' },
          { 'y': 1, 'x': '6/1/18 11:34' },
          { 'y': 1, 'x': '6/1/18 11:33' },
          { 'y': 2, 'x': '6/1/18 11:32' },
          { 'y': 2, 'x': '6/1/18 11:31' },
          { 'y': 2, 'x': '6/1/18 11:30' },
          { 'y': 2, 'x': '6/1/18 11:29' },
          { 'y': 1, 'x': '6/1/18 11:28' },
          { 'y': 2, 'x': '6/1/18 11:27' },
          { 'y': 1, 'x': '6/1/18 11:26' },
          { 'y': 2, 'x': '6/1/18 11:25' },
          { 'y': 1, 'x': '6/1/18 11:24' },
          { 'y': 1, 'x': '6/1/18 11:23' },
          { 'y': 1, 'x': '6/1/18 11:22' },
          { 'y': 1, 'x': '6/1/18 11:21' },
          { 'y': 1, 'x': '6/1/18 11:20' },
          { 'y': 2, 'x': '6/1/18 11:19' },
          { 'y': 1, 'x': '6/1/18 11:18' },
          { 'y': 2, 'x': '6/1/18 11:17' },
          { 'y': 1, 'x': '6/1/18 11:16' },
        ],
        backgroundColor: 'rgb(184, 58, 36, 1)',
        pointBackgroundColor: 'rgb(184, 58, 36, 1)',
        pointBorderColor: ['rgb(184, 58, 36, 1)'],
        borderWidth: 0,
        showLine: false,
      },
    ],
  };

  data24h = {
    datasets: [
      {
        label: 'Calls',
        data: [
          { 'y': 32, 'x': '6/1/18 12:14' },
          { 'y': 30, 'x': '6/1/18 11:44' },
          { 'y': 31, 'x': '6/1/18 11:14' },
          { 'y': 34, 'x': '6/1/18 10:44' },
          { 'y': 34, 'x': '6/1/18 10:14' },
          { 'y': 37, 'x': '6/1/18 9:44' },
          { 'y': 36, 'x': '6/1/18 9:14' },
          { 'y': 37, 'x': '6/1/18 8:44' },
          { 'y': 39, 'x': '6/1/18 8:14' },
          { 'y': 38, 'x': '6/1/18 7:44' },
          { 'y': 38, 'x': '6/1/18 7:14' },
          { 'y': 38, 'x': '6/1/18 6:44' },
          { 'y': 35, 'x': '6/1/18 6:14' },
          { 'y': 37, 'x': '6/1/18 5:44' },
          { 'y': 37, 'x': '6/1/18 5:14' },
          { 'y': 34, 'x': '6/1/18 4:44' },
          { 'y': 31, 'x': '6/1/18 4:14' },
          { 'y': 32, 'x': '6/1/18 3:44' },
          { 'y': 29, 'x': '6/1/18 3:14' },
          { 'y': 28, 'x': '6/1/18 2:44' },
          { 'y': 28, 'x': '6/1/18 2:14' },
          { 'y': 28, 'x': '6/1/18 1:44' },
          { 'y': 31, 'x': '6/1/18 1:14' },
          { 'y': 31, 'x': '6/1/18 0:44' },
          { 'y': 31, 'x': '6/1/18 0:14' },
          { 'y': 30, 'x': '5/31/18 23:44' },
          { 'y': 29, 'x': '5/31/18 23:14' },
          { 'y': 27, 'x': '5/31/18 22:44' },
          { 'y': 27, 'x': '5/31/18 22:14' },
          { 'y': 29, 'x': '5/31/18 21:44' },
          { 'y': 30, 'x': '5/31/18 21:14' },
          { 'y': 30, 'x': '5/31/18 20:44' },
          { 'y': 32, 'x': '5/31/18 20:14' },
          { 'y': 32, 'x': '5/31/18 19:44' },
          { 'y': 29, 'x': '5/31/18 19:14' },
          { 'y': 29, 'x': '5/31/18 18:44' },
          { 'y': 28, 'x': '5/31/18 18:14' },
          { 'y': 29, 'x': '5/31/18 17:44' },
          { 'y': 29, 'x': '5/31/18 17:14' },
          { 'y': 28, 'x': '5/31/18 16:44' },
          { 'y': 31, 'x': '5/31/18 16:14' },
          { 'y': 33, 'x': '5/31/18 15:44' },
          { 'y': 31, 'x': '5/31/18 15:14' },
          { 'y': 34, 'x': '5/31/18 14:44' },
          { 'y': 34, 'x': '5/31/18 14:14' },
          { 'y': 31, 'x': '5/31/18 13:44' },
          { 'y': 31, 'x': '5/31/18 13:14' },
          { 'y': 29, 'x': '5/31/18 12:44' },
          { 'y': 32, 'x': '5/31/18 12:14' },
        ],
        backgroundColor: ['rgb(100, 120, 183, .5)'],
        borderWidth: 1,
      },
      {
        label: 'Quick disconnects',
        data: [
          { 'y': 3, 'x': '6/1/18 12:14' },
          { 'y': 1, 'x': '6/1/18 11:44' },
          { 'y': 3, 'x': '6/1/18 11:14' },
          { 'y': 1, 'x': '6/1/18 10:44' },
          { 'y': 2, 'x': '6/1/18 10:14' },
          { 'y': 1, 'x': '6/1/18 9:44' },
          { 'y': 1, 'x': '6/1/18 9:14' },
          { 'y': 3, 'x': '6/1/18 8:44' },
          { 'y': 3, 'x': '6/1/18 8:14' },
          { 'y': 1, 'x': '6/1/18 7:44' },
          { 'y': 3, 'x': '6/1/18 7:14' },
          { 'y': 3, 'x': '6/1/18 6:44' },
          { 'y': 4, 'x': '6/1/18 6:14' },
          { 'y': 2, 'x': '6/1/18 5:44' },
          { 'y': 3, 'x': '6/1/18 5:14' },
          { 'y': 3, 'x': '6/1/18 4:44' },
          { 'y': 2, 'x': '6/1/18 4:14' },
          { 'y': 1, 'x': '6/1/18 3:44' },
          { 'y': 3, 'x': '6/1/18 3:14' },
          { 'y': 1, 'x': '6/1/18 2:44' },
          { 'y': 1, 'x': '6/1/18 2:14' },
          { 'y': 2, 'x': '6/1/18 1:44' },
          { 'y': 1, 'x': '6/1/18 1:14' },
          { 'y': 1, 'x': '6/1/18 0:44' },
          { 'y': 1, 'x': '6/1/18 0:14' },
          { 'y': 1, 'x': '5/31/18 23:44' },
          { 'y': 3, 'x': '5/31/18 23:14' },
          { 'y': 2, 'x': '5/31/18 22:44' },
          { 'y': 3, 'x': '5/31/18 22:14' },
          { 'y': 2, 'x': '5/31/18 21:44' },
          { 'y': 3, 'x': '5/31/18 21:14' },
          { 'y': 3, 'x': '5/31/18 20:44' },
          { 'y': 3, 'x': '5/31/18 20:14' },
          { 'y': 3, 'x': '5/31/18 19:44' },
          { 'y': 1, 'x': '5/31/18 19:14' },
          { 'y': 2, 'x': '5/31/18 18:44' },
          { 'y': 3, 'x': '5/31/18 18:14' },
          { 'y': 1, 'x': '5/31/18 17:44' },
          { 'y': 1, 'x': '5/31/18 17:14' },
          { 'y': 3, 'x': '5/31/18 16:44' },
          { 'y': 3, 'x': '5/31/18 16:14' },
          { 'y': 1, 'x': '5/31/18 15:44' },
          { 'y': 1, 'x': '5/31/18 15:14' },
          { 'y': 3, 'x': '5/31/18 14:44' },
          { 'y': 1, 'x': '5/31/18 14:14' },
          { 'y': 3, 'x': '5/31/18 13:44' },
          { 'y': 3, 'x': '5/31/18 13:14' },
          { 'y': 2, 'x': '5/31/18 12:44' },
          { 'y': 3, 'x': '5/31/18 12:14' },
        ],
        backgroundColor: 'rgb(184, 58, 36, 1)',
        pointBackgroundColor: 'rgb(184, 58, 36, 1)',
        pointBorderColor: [
          'rgb(184, 58, 36, 1)', //red
        ],
        borderWidth: 1,
        showLine: false,
      },
    ],
  };

  data7d = {
    datasets: [
      {
        label: 'Calls',
        data: [
          { 'y': 45, 'x': '6/1/18 12:14' },
          { 'y': 45, 'x': '6/1/18 10:14' },
          { 'y': 44, 'x': '6/1/18 8:14' },
          { 'y': 41, 'x': '6/1/18 6:14' },
          { 'y': 43, 'x': '6/1/18 4:14' },
          { 'y': 46, 'x': '6/1/18 2:14' },
          { 'y': 49, 'x': '6/1/18 0:14' },
          { 'y': 49, 'x': '5/31/18 22:14' },
          { 'y': 50, 'x': '5/31/18 20:14' },
          { 'y': 50, 'x': '5/31/18 18:14' },
          { 'y': 51, 'x': '5/31/18 16:14' },
          { 'y': 53, 'x': '5/31/18 14:14' },
          { 'y': 52, 'x': '5/31/18 12:14' },
          { 'y': 52, 'x': '5/31/18 10:14' },
          { 'y': 55, 'x': '5/31/18 8:14' },
          { 'y': 56, 'x': '5/31/18 6:14' },
          { 'y': 59, 'x': '5/31/18 4:14' },
          { 'y': 56, 'x': '5/31/18 2:14' },
          { 'y': 55, 'x': '5/31/18 0:14' },
          { 'y': 53, 'x': '5/30/18 22:14' },
          { 'y': 52, 'x': '5/30/18 20:14' },
          { 'y': 51, 'x': '5/30/18 18:14' },
          { 'y': 48, 'x': '5/30/18 16:14' },
          { 'y': 49, 'x': '5/30/18 14:14' },
          { 'y': 48, 'x': '5/30/18 12:14' },
          { 'y': 49, 'x': '5/30/18 10:14' },
          { 'y': 50, 'x': '5/30/18 8:14' },
          { 'y': 52, 'x': '5/30/18 6:14' },
          { 'y': 55, 'x': '5/30/18 4:14' },
          { 'y': 54, 'x': '5/30/18 2:14' },
          { 'y': 51, 'x': '5/30/18 0:14' },
          { 'y': 51, 'x': '5/29/18 22:14' },
          { 'y': 54, 'x': '5/29/18 20:14' },
          { 'y': 57, 'x': '5/29/18 18:14' },
          { 'y': 58, 'x': '5/29/18 16:14' },
          { 'y': 57, 'x': '5/29/18 14:14' },
          { 'y': 59, 'x': '5/29/18 12:14' },
          { 'y': 58, 'x': '5/29/18 10:14' },
          { 'y': 59, 'x': '5/29/18 8:14' },
          { 'y': 57, 'x': '5/29/18 6:14' },
          { 'y': 59, 'x': '5/29/18 4:14' },
          { 'y': 58, 'x': '5/29/18 2:14' },
          { 'y': 57, 'x': '5/29/18 0:14' },
          { 'y': 54, 'x': '5/28/18 22:14' },
          { 'y': 56, 'x': '5/28/18 20:14' },
          { 'y': 55, 'x': '5/28/18 18:14' },
          { 'y': 56, 'x': '5/28/18 16:14' },
          { 'y': 56, 'x': '5/28/18 14:14' },
          { 'y': 57, 'x': '5/28/18 12:14' },
          { 'y': 60, 'x': '5/28/18 10:14' },
          { 'y': 60, 'x': '5/28/18 8:14' },
          { 'y': 61, 'x': '5/28/18 6:14' },
          { 'y': 64, 'x': '5/28/18 4:14' },
          { 'y': 61, 'x': '5/28/18 2:14' },
          { 'y': 64, 'x': '5/28/18 0:14' },
          { 'y': 67, 'x': '5/27/18 22:14' },
          { 'y': 65, 'x': '5/27/18 20:14' },
          { 'y': 67, 'x': '5/27/18 18:14' },
          { 'y': 69, 'x': '5/27/18 16:14' },
          { 'y': 69, 'x': '5/27/18 14:14' },
          { 'y': 67, 'x': '5/27/18 12:14' },
          { 'y': 64, 'x': '5/27/18 10:14' },
          { 'y': 62, 'x': '5/27/18 8:14' },
          { 'y': 63, 'x': '5/27/18 6:14' },
          { 'y': 60, 'x': '5/27/18 4:14' },
          { 'y': 63, 'x': '5/27/18 2:14' },
          { 'y': 60, 'x': '5/27/18 0:14' },
          { 'y': 63, 'x': '5/26/18 22:14' },
          { 'y': 60, 'x': '5/26/18 20:14' },
          { 'y': 63, 'x': '5/26/18 18:14' },
          { 'y': 62, 'x': '5/26/18 16:14' },
          { 'y': 62, 'x': '5/26/18 14:14' },
          { 'y': 64, 'x': '5/26/18 12:14' },
          { 'y': 66, 'x': '5/26/18 10:14' },
          { 'y': 65, 'x': '5/26/18 8:14' },
          { 'y': 63, 'x': '5/26/18 6:14' },
          { 'y': 65, 'x': '5/26/18 4:14' },
          { 'y': 64, 'x': '5/26/18 2:14' },
          { 'y': 62, 'x': '5/26/18 0:14' },
          { 'y': 62, 'x': '5/25/18 22:14' },
          { 'y': 60, 'x': '5/25/18 20:14' },
          { 'y': 63, 'x': '5/25/18 18:14' },
          { 'y': 64, 'x': '5/25/18 16:14' },
          { 'y': 62, 'x': '5/25/18 14:14' },
          { 'y': 63, 'x': '5/25/18 12:14' },
        ],
        backgroundColor: ['rgb(100, 120, 183, .5)'],
        borderWidth: 1,
      },
      {
        label: 'Quick disconnects',
        data: [
          { 'y': 2, 'x': '6/1/18 12:14' },
          { 'y': 3, 'x': '6/1/18 10:14' },
          { 'y': 4, 'x': '6/1/18 8:14' },
          { 'y': 2, 'x': '6/1/18 6:14' },
          { 'y': 2, 'x': '6/1/18 4:14' },
          { 'y': 2, 'x': '6/1/18 2:14' },
          { 'y': 5, 'x': '6/1/18 0:14' },
          { 'y': 2, 'x': '5/31/18 22:14' },
          { 'y': 5, 'x': '5/31/18 20:14' },
          { 'y': 5, 'x': '5/31/18 18:14' },
          { 'y': 1, 'x': '5/31/18 16:14' },
          { 'y': 3, 'x': '5/31/18 14:14' },
          { 'y': 6, 'x': '5/31/18 12:14' },
          { 'y': 3, 'x': '5/31/18 10:14' },
          { 'y': 3, 'x': '5/31/18 8:14' },
          { 'y': 4, 'x': '5/31/18 6:14' },
          { 'y': 2, 'x': '5/31/18 4:14' },
          { 'y': 3, 'x': '5/31/18 2:14' },
          { 'y': 3, 'x': '5/31/18 0:14' },
          { 'y': 5, 'x': '5/30/18 22:14' },
          { 'y': 5, 'x': '5/30/18 20:14' },
          { 'y': 4, 'x': '5/30/18 18:14' },
          { 'y': 4, 'x': '5/30/18 16:14' },
          { 'y': 3, 'x': '5/30/18 14:14' },
          { 'y': 1, 'x': '5/30/18 12:14' },
          { 'y': 2, 'x': '5/30/18 10:14' },
          { 'y': 4, 'x': '5/30/18 8:14' },
          { 'y': 5, 'x': '5/30/18 6:14' },
          { 'y': 5, 'x': '5/30/18 4:14' },
          { 'y': 0, 'x': '5/30/18 2:14' },
          { 'y': 5, 'x': '5/30/18 0:14' },
          { 'y': 3, 'x': '5/29/18 22:14' },
          { 'y': 3, 'x': '5/29/18 20:14' },
          { 'y': 2, 'x': '5/29/18 18:14' },
          { 'y': 3, 'x': '5/29/18 16:14' },
          { 'y': 6, 'x': '5/29/18 14:14' },
          { 'y': 6, 'x': '5/29/18 12:14' },
          { 'y': 0, 'x': '5/29/18 10:14' },
          { 'y': 5, 'x': '5/29/18 8:14' },
          { 'y': 1, 'x': '5/29/18 6:14' },
          { 'y': 5, 'x': '5/29/18 4:14' },
          { 'y': 2, 'x': '5/29/18 2:14' },
          { 'y': 2, 'x': '5/29/18 0:14' },
          { 'y': 5, 'x': '5/28/18 22:14' },
          { 'y': 4, 'x': '5/28/18 20:14' },
          { 'y': 2, 'x': '5/28/18 18:14' },
          { 'y': 5, 'x': '5/28/18 16:14' },
          { 'y': 2, 'x': '5/28/18 14:14' },
          { 'y': 3, 'x': '5/28/18 12:14' },
          { 'y': 5, 'x': '5/28/18 10:14' },
          { 'y': 5, 'x': '5/28/18 8:14' },
          { 'y': 6, 'x': '5/28/18 6:14' },
          { 'y': 5, 'x': '5/28/18 4:14' },
          { 'y': 4, 'x': '5/28/18 2:14' },
          { 'y': 6, 'x': '5/28/18 0:14' },
          { 'y': 1, 'x': '5/27/18 22:14' },
          { 'y': 4, 'x': '5/27/18 20:14' },
          { 'y': 6, 'x': '5/27/18 18:14' },
          { 'y': 2, 'x': '5/27/18 16:14' },
          { 'y': 4, 'x': '5/27/18 14:14' },
          { 'y': 0, 'x': '5/27/18 12:14' },
          { 'y': 3, 'x': '5/27/18 10:14' },
          { 'y': 5, 'x': '5/27/18 8:14' },
          { 'y': 4, 'x': '5/27/18 6:14' },
          { 'y': 2, 'x': '5/27/18 4:14' },
          { 'y': 5, 'x': '5/27/18 2:14' },
          { 'y': 5, 'x': '5/27/18 0:14' },
          { 'y': 4, 'x': '5/26/18 22:14' },
          { 'y': 3, 'x': '5/26/18 20:14' },
          { 'y': 3, 'x': '5/26/18 18:14' },
          { 'y': 4, 'x': '5/26/18 16:14' },
          { 'y': 3, 'x': '5/26/18 14:14' },
          { 'y': 4, 'x': '5/26/18 12:14' },
          { 'y': 6, 'x': '5/26/18 10:14' },
          { 'y': 6, 'x': '5/26/18 8:14' },
          { 'y': 2, 'x': '5/26/18 6:14' },
          { 'y': 6, 'x': '5/26/18 4:14' },
          { 'y': 3, 'x': '5/26/18 2:14' },
          { 'y': 1, 'x': '5/26/18 0:14' },
          { 'y': 4, 'x': '5/25/18 22:14' },
          { 'y': 1, 'x': '5/25/18 20:14' },
          { 'y': 4, 'x': '5/25/18 18:14' },
          { 'y': 2, 'x': '5/25/18 16:14' },
          { 'y': 4, 'x': '5/25/18 14:14' },
          { 'y': 2, 'x': '5/25/18 12:14' },
        ],
        backgroundColor: 'rgb(184, 58, 36, 1)',
        pointBackgroundColor: 'rgb(184, 58, 36, 1)',
        pointBorderColor: [
          'rgb(184, 58, 36, 1)', //red
        ],
        borderWidth: 0,
        showLine: false,
      },
    ],
  };

  ngAfterViewInit() {
    this.canvas = document.getElementById('myChartSite');
    this.ctx = this.canvas.getContext('2d');
    this.myChartSite = new Chart(this.ctx, {
      type: 'line',
      // data: this.data24h,
      options: {
        responsive: false,
        display: true,
        fontSize: '6px',
        padding: '0px',
        maintainAspectRatio: false,
      },
    });
    this.currentChart = '24h';
    this.loadChart();
  }

  loadChart() {
    this.myChartSite.reset();
    this.myChartSite.data.datasets = {};
    switch (this.currentChart) {
      case '24h':
        this.myChartSite.data.datasets = this.data24h.datasets;
        this.myChartSite.options.scales = {
          xAxes: [
            {
              type: 'time',
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Time',
              },
            },
          ],
        };
        break;
      case '60m':
        this.myChartSite.data.datasets = this.data60m.datasets;
        this.myChartSite.options.scales = {
          xAxes: [
            {
              type: 'time',
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Time',
              },
            },
          ],
        };
        break;
      case '7d':
        this.myChartSite.data.datasets = this.data7d.datasets;
        this.myChartSite.options.scales = {
          xAxes: [
            {
              type: 'time',
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Date',
              },
            },
          ],
        };
        break;
    }
    this.myChartSite.update({ duration: 1000 });
  }

  set60m() {
    this.currentChart = '60m';
    this.loadChart();
  }
  set24h() {
    this.currentChart = '24h';
    this.loadChart();
  }
  set7d() {
    this.currentChart = '7d';
    this.loadChart();
  }
}
