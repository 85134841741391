import { GetCloudService } from 'src/app/services/get-cloud.service'

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import { environment } from '../../../environments/environment'
import { GetTeamsService } from '../../services/get-teams.service'
import { PopupTypeService } from '../../services/popupType.service'

@Component({
  selector: 'app-teams-user-info',
  templateUrl: './teams-user-info.component.html',
  styleUrls: ['./teams-user-info.component.scss'],
})
export class TeamsUserInfoComponent implements OnInit, OnChanges {
  @Input() teamsDetails: any;

  isDisableDesktopClientPluginToggle: boolean = true; //disable for now
  isDisableAlertsToggle: boolean = true;
  userStatus: any = 'Online';
  isZoomPluginEnabled: boolean = false;
  deskPlugin: String = environment.config.dynamicText.deskPlugin;
  desktopPluginMessage: string;
  deskPluginStatus: number;
  faIcon = faUser;
  constructor(
    public ds: PopupTypeService,
    private getTeams: GetTeamsService,
    private getCloudService: GetCloudService,
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.teamsDetails && this.teamsDetails) {
      // this.setData();
      if (this.teamsDetails.plugin_id !== null) {
        this.isDisableDesktopClientPluginToggle = false;
        this.isDisableAlertsToggle = false
      } else {
        this.isDisableDesktopClientPluginToggle = true;
        this.isDisableAlertsToggle = true;
      }

      this.isZoomPluginEnabled =
        !this.isDisableDesktopClientPluginToggle && this.teamsDetails.zoom_plugin_enabled === 1;
      this.deskPluginStatus = this.teamsDetails.plugin_status;

      // desktop plugin display message
      this.desktopPluginMessage = this.getDesktopPluginMessage();
      // if (this.isZoomPluginEnabled && this.deskPluginStatus === 1) {
      // this.userStatus =
      //   this.teamsDetails.plugin_application[0].teams === 1 ? 'Online' : 'Offline';
      // }

      this.userStatus = this.teamsDetails.status
    }
  }

  openRebootPopUp = () => {
    const data = {
      zoom_plugin_enabled: this.isZoomPluginEnabled,
      desktop_plugin_message: this.desktopPluginMessage,
      plugin_id: this.teamsDetails.plugin_id,
    };
    this.ds.sendPopupType(72, { data });
  };



  getDesktopPluginMessage = () => {
    if (this.isDisableDesktopClientPluginToggle) {
      return `To enable advance features, please download and install the ${this.deskPlugin}.`;
    }
    if (
      !this.isDisableDesktopClientPluginToggle &&
      this.isZoomPluginEnabled &&
      this.deskPluginStatus !== 1
    ) {
      return `${this.deskPlugin} is offline.`;
    }
    return '';
  };

  getUserProfilePicture = (params) => {
    return '../../../../assets/img/user-in-a-square.png';
  };

  // alertsEvent = (flag) => {
  //   const { id } = this.teamsDetails;
  //   this.getTeams.activateAlert(id, flag).subscribe((data) => {
  //     // console.log('alertsEvent: ', data)
  //   });

  // }

  alertsChange(e) {
    const usrData = this.teamsDetails.zoom_user_id;
    if (this.isDisableAlertsToggle) {
      e.currentTarget.checked = !e.currentTarget.checked;
    } else {
      if (e.currentTarget.checked) {
        this.getCloudService.enableAlerts(usrData).subscribe((data) => {
        });
      } else {
        this.getCloudService.disableAlerts(usrData).subscribe((data) => { });
      }
    }
  }


  desktopClientChange(e) {
    const usrData = this.teamsDetails.zoom_user_id
    const flag = e.currentTarget.checked;
    this.getCloudService.setEnableDesktopPlugin(usrData, flag).subscribe(
      (data) => {
      },
      (e) => {
        // console.log(e);
      }
    );
  }
}
