import { Subscription } from 'rxjs'

import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { GetIotService } from '../../services/get-iot.service'
import { PopupTypeService } from '../../services/popupType.service'
import { RefreshApiService } from '../../services/refresh-api.service'
import { environment } from 'src/environments/environment'
import moment from 'moment-timezone'

@Component({
  selector: 'app-iot-lite-device-panel',
  templateUrl: './iot-lite-device-panel.component.html',
  styleUrls: ['./iot-lite-device-panel.component.scss']
})
export class IotLiteDevicePanelComponent implements OnInit {
  @Output() eventEmitter: EventEmitter<any> = new EventEmitter<any>();

  iotDeviceDetails: any;
  iotDevicePortsDetails: any;
  iotDeviceName: String = '--';
  routeId: any;
  filterListner: Subscription;
  refreshData: Boolean;
  syncTime: string = '';
  dCollector: String = environment.config.dynamicText.dCollector;

  constructor(
    private route: ActivatedRoute,
    private getIot: GetIotService,
    private refreshApiService: RefreshApiService,
    public ds: PopupTypeService
  ) {
    this.route.params.subscribe((params) => {
      this.routeId = params['id'];
    });

    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if (m) {
        this.refreshData = m;
        this.getApiData();
      }
    });
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  getApiData() {
    if (this.refreshData) {
      this.getIotDeviceDetails();
    }
  }

  ngOnInit() {
    this.getIotDeviceDetails();
  }

  getIotDeviceDetails = () => {
    this.getIotLitePortsByIotId();
    this.getIot.getIotDeviceDetails(this.routeId).subscribe((data: any) => {
      // const [res] = data;
      this.getDeviceRtdStatsIotLite(data);
    }, (err) => {
      // console.log(err)
    });
  };

  getDeviceRtdStatsIotLite = (params) => {
    this.getIot.getDeviceRtdStatsIotLite(this.routeId).subscribe((data: any) => {
      this.iotDeviceDetails = { ...params, ...data };
      console.log('iotDeviceDetails: ', this.iotDeviceDetails);
      if(this.iotDeviceDetails){
        this.syncTime = moment(this.iotDeviceDetails.last_connect)
          .utc()
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format("MM-DD-YYYY hh:mma");
      }
      this.setData();
    }, (err) => {
      this.iotDeviceDetails = { ...params };
      // console.log('iotDeviceDetails: ', this.iotDeviceDetails)
      this.setData();
      // console.log(err)
    });
  };

  getIotLitePortsByIotId = () => {
    this.getIot.getIotLitePortsByIotId(this.routeId).subscribe((data) => {
      const res = data.response;
      this.iotDevicePortsDetails = res;
    });
  };

  setData = () => {
    this.iotDeviceName = 'IoT - ' + this.iotDeviceDetails.iot_name;
  };

  openIotDeviceHealthHistory = () => {
    const data = {
      iot_id: this.iotDeviceDetails.iot_id,
      iot_name: this.iotDeviceDetails.iot_name,
      days: 30
    };
    this.ds.sendPopupType(74, {
      data,
    });
  };

  ngOnChange($event) {
    if ($event === 'callGetIotDeviceDetails') {
      this.getIotDeviceDetails();
    }
  }
}