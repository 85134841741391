<div class="delete-site">
  <div class="delete-site__header" *ngIf="dialogRef">
    DELETE SITE
    <div class="delete-site__close px-2" (click)="closeDialog()">
      X
    </div>
  </div>

  <div class="delete-site__body">
    <div class="delete-site__image">
      <img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
      <img *ngIf="!isLoading && !hasDeviceOrSpaceConnectedMsg" src="../../../../assets/img/vs_img/delete-icon.png" alt="" />
      <i *ngIf="!isLoading && hasDeviceOrSpaceConnectedMsg" class="fa fa-exclamation-circle fa-5x p-4 text-danger" alt="" ></i>
    </div>

    <!-- <div *ngIf="!isSiteWitPlugin" class="delete-site__text-button-container">
      <div class="">
        Are you sure you want to delete <strong>{{ site_name }}</strong>?
        <br />
        This action can take up to 3 minutes to complete in the background.
      </div>


      <div *ngIf="hasDeviceOrSpaceConnected">
        <h6>{{hasDeviceOrSpaceConnectedMsg}}</h6>

        <div>
          Please <strong>DELETE</strong> devices to proceed.
        </div>
      </div>

      <div *ngIf="!hasDeviceOrSpaceConnected" class="delete-site__button-container">
        <div class="delete-site__button-item delete-site__button-item--yes" (click)="deleteSite()">
          Yes
        </div>
        <div class="delete-site__button-item delete-site__button-item--cancel" (click)="closeDialog()">
          No
        </div>
      </div>
    </div> -->

    <div class="delete-site__text-button-container">
      <ng-container *ngIf="!attemptedToDeleteSite">
        <div>
          Are you sure you want to delete <strong>{{ site_name }}</strong>?
          <br />
          This action can take up to 3 minutes to complete in the background.
        </div>

        <div class="delete-site__button-container">
          <div
            class="delete-site__button-item delete-site__button-item--yes" (click)="deleteSite()">
            Yes
          </div>
          <div class="delete-site__button-item delete-site__button-item--cancel" (click)="closeDialog()">
            No
          </div>
        </div>
      </ng-container>
    

      <ng-container *ngIf="attemptedToDeleteSite">
          <p *ngIf="hasDeviceOrSpaceConnectedMsg">{{hasDeviceOrSpaceConnectedMsg}}</p>
          <p *ngIf="connectedDevices?.length">
            Site <b>{{ site_name }}</b> is linked to the device(s):
            <!-- <span class="delete-site__link" (click)="clickZoomRoom()">{{ zoomRoomObj.name }}</span> -->
            <b *ngFor="let device of connectedDevices; last as isLast">{{device.name}}<span *ngIf="!isLast">, </span>
            </b>. Please unlink the device(s) first in order to delete the site.
          </p>
          <p *ngIf="connectedSpaces?.length">
            Site <b>{{ site_name }}</b> is linked to the space(s):
            <b *ngFor="let space of connectedSpaces; last as isLast">{{space.name}}<span *ngIf="!isLast">, </span>
            </b>. Please unlink the space(s) first in order to delete the site.
          </p>
      </ng-container>
    </div>

  </div>
</div>