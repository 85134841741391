import { AlertService } from 'src/app/_alert'
import { AuthService } from 'src/app/services/auth.service'
import { environment } from 'src/environments/environment'

import { Component } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'

@Component({
  selector: 'integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent {
  webhookUrl: string;
  loading: boolean;
  secretLoading : boolean;
  result: string;
  loader: String = environment.config.dynamicImage.loader;
	generateNote: any;
	showGenerateNote: any = false;
  submitting: boolean;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  
  setTimer: any;

  firstFormGroup = this._formBuilder.group({
    urlFormControl: ['', [Validators.required , Validators.pattern(
      // Regular expression for URL validation
      // You can adjust it based on your specific requirements
      // This regex checks for http, https, or www followed by valid domain patterns
      // /^(http[s]?:\/\/www\.|http[s]?:\/\/|www\.)+[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}(\S*)?$/
      // This regex accepts both the domain and ip address:
      /^(http[s]?:\/\/(?:www\.|)?|(?:www\.|))(?:(?:[0-9]{1,3}\.){3}[0-9]{1,3}|(?:[a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,})(?::\d+)?(?:\/\S*)?$/
    ) ] ],
    tokenValue: ['', Validators.required]
  });
  secondFormGroup = this._formBuilder.group({
    apiSecret: ['', Validators.required],
  });
    
  constructor(public Auth: AuthService , private _formBuilder: FormBuilder , protected alertService: AlertService ) {
  }
  ngAfterViewInit(){
    this.getIntegrationInfo();
    setInterval(()=>{
      console.log("The form group value" , this.firstFormGroup.invalid )
    } , 5000)
  }
  
   submitPublicIntegration = async () => {
    try{
        this.submitting = true;
        if(!this.secondFormGroup.controls.apiSecret.valid){
          throw "Please generate an api secret";
        }
        let dataToPost = {
            publicToken: this.secondFormGroup.controls.apiSecret.value
        }
        let dataPosted = await this.Auth.updatePublicIntegrationInfo(dataToPost).toPromise();
        this.alertService.success('Integration info updated', this.options);

    }catch(error){
      console.log("An error occured" , error );
      if(typeof error == 'string'){
        this.alertService.error(error, this.options);
      }else{
        this.alertService.error("Something went wrong while updating info", this.options);
      }
    }
      this.submitting = false;
  }
  
   submitIntegration = async () => {
    try{
        this.submitting = true;
        if((this.firstFormGroup.controls.tokenValue.value || '').trim().length == 0){
            throw "Please select a token";
        }
        if(!this.firstFormGroup.controls.urlFormControl.valid || this.checkUrl()){
            throw "Please enter a valid url";
        }

        let dataToPost = {
            token : this.firstFormGroup.controls.tokenValue.value ,
            url : this.firstFormGroup.controls.urlFormControl.value,
        }
        
        let dataPosted = await this.Auth.updateIntegrationInfo(dataToPost).toPromise();
        console.log("Data posted" , dataPosted );
        this.alertService.success('Integration info updated', this.options);

    }catch(error){
      console.log("An error occured" , error );
      if(typeof error == 'string'){
        this.alertService.error(error, this.options);
      }else{
        this.alertService.error("Something went wrong while updating info", this.options);
      }
    }
      this.submitting = false;
  }
  
  checkUrl = () => {
  
  const str = this.firstFormGroup.controls.urlFormControl.value  || '';
  if( str.trim().length == 0 ) {
    return true;
  }
  
  const substr1 = 'localhost';
  const substr2 = '127.0.0.1';
  const substr3 = '8080';
  const substr4 = '4200';
  


  if(str.includes(substr1) ||  str.includes(substr2) ||  str.includes(substr3)  ||  str.includes(substr3)) {
    return true;
  
  }
  
  return false;
  
  }
  
  generateApiSecret = () => {
    this.secretLoading = true;
    // Simulating an asynchronous operation
    this.setTimer = setTimeout(() => { 
      // Generate a random token
      this.secondFormGroup.controls.apiSecret.setValue( this.generateRandomToken() );
      this.secretLoading = false;
    }, 1000); // Simulating a 2-second delay
  }

  generateToken = () => {
    this.loading = true;
    // this.showGenerateNote = true;
    // Simulating an asynchronous operation
  this.setTimer =  setTimeout(() => {
      // Generate a random token
      this.firstFormGroup.controls.tokenValue.setValue( this.generateRandomToken() );

      this.loading = false;
    }, 1000); // Simulating a 2-second delay
  }
  private generateRandomToken(): string {
    this.showGenerateNote = true;
  
    // Replace with your actual token generation logic
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
    const length = 44;
    let token = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters[randomIndex];
    }

    return token;
  }

  private async getIntegrationInfo(){
    let data : any = await this.Auth.getIntegrationInfo().toPromise();
    console.log('data: ', data)
      
    if(data.newData) {
        const { webhook_url,  public_api_key, secret_token  }  = data.newData;
      if(webhook_url  && secret_token) {
        this.firstFormGroup.controls.tokenValue.setValue('***************************************');
        this.firstFormGroup.controls.urlFormControl.setValue(webhook_url)
      }
      
      if(webhook_url && public_api_key  && secret_token) {
        this.firstFormGroup.controls.tokenValue.setValue('***************************************');
        this.firstFormGroup.controls.urlFormControl.setValue(webhook_url)
        this.secondFormGroup.controls.apiSecret.setValue('***************************************')
      }
    }
  }
  
  test123 = () => {
    this.showGenerateNote = false;
  }
  
  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };
  
}