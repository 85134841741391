import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-btn-audio-live-stats',
  templateUrl: './btn-audio-live-stats.component.html',
  styleUrls: ['./btn-audio-live-stats.component.scss']
})
export class BtnAudioLiveStatsComponent implements OnChanges {
  @Input() mute: number = 1;
  @Input() name: string = "--";
  @Input() activeDevice: any = 1;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeDevice.currentValue != changes.activeDevice.previousValue || changes.mute.currentValue != changes.mute.previousValue) {
      // this.mute = changes.mute.currentValue;
      // this.name = changes.name.currentValue;
      // this.activeDevice = changes.activeDevice.currentValue;
    } else {
      // console.log("nothing to see here");
    }
  }
}