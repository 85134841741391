<div class="row right-panel">
  <div class="col-12">
    <app-panel-tools [PopupTitle]="PopupTitle"></app-panel-tools>
  </div>
  <div class="col-12">

    <div class="row">
      <div class="col-6 pl-1">
        <app-video-allsites-info-panel [videoSitesSummary]="videositesInfo"></app-video-allsites-info-panel>
      </div>
      <div class="col-6">
        <div class="col-12 px-0">
          <app-system-health [healthNum]="videoSitesSummary?.health"></app-system-health>
        </div>
        <app-video-allsites-stats-panel [videoSitesSummary]="videoSitesSummary"></app-video-allsites-stats-panel>
      </div>
    </div>
    <div class="row" id="all-video-test" style="top: -45px;position: relative;margin-bottom: -50px;">
      <div class="col-12 px-1 filter-table">
        <!-- <app-video-alldevices-list [videoDevicesListData]="videoSitesListData" [videoListData]="listData"></app-video-alldevices-list> -->
        <app-video-allsites-list [videoSitesListData]="videoSitesListData"></app-video-allsites-list>
      </div>
    </div>
    <div *ngIf="loading" style="position: absolute;top: 50%; z-index: 7;width: 100%;">
      <div class="loader-icon">
        <img [src]="loaderPath">
      </div>
    </div>

  </div>
</div>