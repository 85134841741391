<div class="newlogin">
  <div class="newlogin__row-1">
    <img [src]="dashboardLogo" routerLink="/login" (click)="enableCreateAccount()" />
    <div class="newlogin__col-1">
      <div class="newlogin__input-container">
        <input class="{{ formControl.email.style }}" type="text" placeholder="Email" value="email" [(ngModel)]="email"
          (keyup)="emailKeyEvent($event.key)" />

        <div *ngIf="formControl.email.flag" class="error-msg">
          {{ formControl.email.message }}
        </div>
      </div>

      <div class="newlogin__input-container">
        <input class="{{ formControl.password.style }}" type="password" placeholder="Password" value="password"
          [(ngModel)]="password" (keyup)="passwordKeyEvent($event.key)" />
        <div class="newlogin__links">
          <div (click)="linkToForgotPassword()">
            <a>Forgot password?</a>
          </div>
          <div *ngIf="shouldShowCreateAccount" (click)="linkToRegister()">
            <a>Create a new account</a>
          </div>
        </div>
      </div>

      <button class="newlogin__btn-primary" (click)="validateForm()">
        Login
      </button>
    </div>
  </div>
</div>