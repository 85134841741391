import { Component, OnInit, Input } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { PopupTypeService } from '../../../services/popupType.service';
import { AuthService } from '../../../services/auth.service';
import { environment } from './../../../../environments/environment';
import { GetCloudService } from '../../../services/get-cloud.service';

@Component({
  selector: 'app-cloud-all-users-info',
  templateUrl: './cloud-all-users-info.component.html',
  styleUrls: ['./cloud-all-users-info.component.scss'],
})
export class CloudAllUsersInfoComponent implements OnInit {
  @Input() cloudUserSummary: Array<any> = [];
  data: any;
  isSuperAdmin: any;
  currentFilter: string;
  mspLogo: string = environment.config.logo;
  server_url: string = environment.serverUrl;
  companyLogo: any;

  constructor(private ds: PopupTypeService, public Auth: AuthService,
    private getCloudDataService: GetCloudService,
  ) { }


  ngOnInit() {
    // send message to subscribers via observable subject
    this.isSuperAdmin = this.Auth.accessLevel == 'SUPERADMIN';
    this.ds.sendPopupType(0);
    this.getCompanyInfo()
    this.checkForEnabledService()
  }

  checkForEnabledService = () => {
    // console.log('check for enabled service')
  }

  getCompanyInfo() {
    const decodedToken = this.getDecodedtoken();
    this.Auth.getCompanyInfo({ company_id: decodedToken.company_id }).subscribe((data) => {
      const [newData] = data.response
      this.companyLogo = this.server_url + '/api/v1/images/companylogo/' + newData.logo;
      // // console.log(this.companyLogo)
    });
  }

  getDecodedtoken = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    return helper.decodeToken(token);
  };


  sendPopupType(p) {
    this.ds.sendPopupType(p);
    // console.log(p);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }
}
