import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { SSOService } from 'src/app/services/sso.service'
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-old-login',
  templateUrl: './old-login.component.html',
  styleUrls: ['./old-login.component.scss'],
})
export class OldLoginComponent implements OnInit, OnChanges {
  @Output() public data = new EventEmitter<{ email: string; password: string }>();
  @Input() message: any;
  @Input() message2: any;
  setTimer: any;
  dashboardLogo = environment.config.dynamicImage.dashboardLogo;
  email: string = '';
  password: string = '';

  formControl: any;

  links = environment.config.links;
  dTerms = environment.config.dynamicText.dTerms;
  backgroundImage = environment.config.dynamicImage.bgImage;

  visibilityBackground = {
    'background-image': 'url(' + this.backgroundImage + ')',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
    'background-attachment': 'fixed',
    'background-size': 'cover',
    height: '100vh',
  };

  shouldShowForget: boolean = true;
  shouldShowCreateAccount: boolean = true;
  startValidateEmail: boolean = false;
  startValidatePassword: boolean = false;
  disableBtn: boolean = false;
  showLoader: boolean = false;
  sso: {
    enabled: boolean // for now it is enabled for cxdetect only
    // FOR SSO CALLBACK:
    code?: string 
    state?: string
    errorMsg?: string
  } = {
    enabled: environment.config['SSO']?.enabled || false
  }
  loader = environment.config.dynamicImage.loader

  constructor(
    public http: HttpClient,
    private route: ActivatedRoute,
    private ssoService: SSOService) { }


	ngOnInit() {
		this.setFormControl()
		// FOR SSO CALLBACK:
		this.route.queryParams.subscribe(params => {
			this.sso.code = params['code']
			this.sso.state = params['state']
      console.log('ssss')

			if (this.sso.state && this.sso.code) this.matchTheState()
		})
	}

	matchTheState() {
		const storedState = localStorage.getItem('ssoGAMLoginState')
		if (storedState === this.sso.state) {
			// localStorage.removeItem('ssoGAMLoginState')
			this.getSSOAccessToken(this.sso.code)
		} else this.sso.errorMsg = 'Invalid state'
	}
  
  getSSOAccessToken(code: string) {
    this.showLoader = true;
		this.ssoService
			.getTokenFromGAMCode(code)
      .pipe(finalize(()=> this.showLoader = false))
			.subscribe(
				() => {},
        error => {
					this.sso.errorMsg = JSON.stringify(error?.error)
				}
			)
	}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.message) {
    }
    if (changes.message2) {
    }
  }

  setFormControl = () => {
    const deepCopy = {
      email: {
        style: 'default',
        message: '',
      },
      password: {
        style: 'default',
        message: '',
      },
    };

    this.formControl = { ...deepCopy };
  };

  validateForm = () => {
    const validation = this.validate();

    if (validation) {
      this.emitEvent();
    }
  };

  validate = () => {
    let errCount = 0;
    this.setFormControl();
    if (this.isEmpty(this.email)) {
      this.startValidateEmail = true;
      this.setErrorMessageAndStatus('email', true, 'error', '');
      errCount++;
    } else if (!this.validateEmail(this.email)) {
      this.startValidateEmail = true;
      this.setErrorMessageAndStatus('email', true, 'error', 'format (ex. email@domain.com) ');
      errCount++;
    }

    if (this.isEmpty(this.password)) {
      this.startValidatePassword = true;
      this.setErrorMessageAndStatus('password', true, 'error', '');
      errCount++;
    } else if (this.password.length < 8) {
      this.startValidatePassword = true;
      this.setErrorMessageAndStatus('password', true, 'error', '');
      errCount++;
    }
    this.disableBtn = errCount !== 0;
    return errCount === 0;
  };

  isEmpty = (params) => {
    if (params.length === 0) {
      return true;
    }
    return false;
  };

  setErrorMessageAndStatus = (propname, bool, style, message) => {
    this.formControl[propname].flag = bool;
    this.formControl[propname].style = style;
    this.formControl[propname].message = message;
  };

  emitEvent = () => {
    this.data.emit({ email: this.email, password: this.password });
  };

  emailKeyEvent = (events) => {
    if (this.startValidateEmail) {
      this.validate();
    }
  };

  passwordKeyEvent = (events) => {
    if (this.startValidatePassword) {
      this.validate();
    }
  };

  validateEmail = (email) => {
    // tslint:disable-next-line: max-line-length
    const regexEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEx.test(String(email).toLowerCase());
  };

  onClickSSOLogin = () => {
    this.ssoService.signIn()
  }
}
