import { Component, Input, OnInit, SimpleChanges } from '@angular/core'

import { environment } from '../../../environments/environment'
import { PopupTypeService } from '../../services/popupType.service'

@Component({
	selector: 'app-collector-live-stats',
	templateUrl: './collector-live-stats.component.html',
	styleUrls: ['./collector-live-stats.component.scss'],
})
export class CollectorLiveStatsComponent implements OnInit {
	@Input() collectorLiveStats: any = '--'

	collectorLastConnect: any = '--'
	collectorVersion: any = '--'
	collectorSiteType: any = '--'
	collectorLastReboot: any = '--'

	iconAddSite = environment.config.dynamicImage.iconAddSite
	iconVirtual = environment.config.dynamicImage.iconVirtual

	constructor(private ds: PopupTypeService) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.collectorLiveStats && this.collectorLiveStats) {
			this.setData()
		}
	}

	setData = () => {
		const { last_connect, version, virtual, last_reboot, cpu_used, memory_used, drive_details } =
			this.collectorLiveStats

		this.collectorLastConnect = last_connect ? new Date(last_connect) : this.collectorLastConnect
    this.collectorVersion = String(version).replace(/\.0/g, '')
		this.collectorSiteType = this.getSiteTypeIcon(virtual)
		this.collectorLastReboot = last_reboot ? new Date(last_reboot) : this.collectorLastReboot
	}

	getSiteTypeIcon = bool => {
		return bool ? this.iconVirtual : this.iconAddSite 
	}
}
