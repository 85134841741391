import { trigger, state, style, transition,
  animate, group, query, stagger, keyframes
} from '@angular/animations';

export const HealthblockLowerRight = [
  trigger('slideLowerRight', 
  [
      state('home', style({
        // transform: 'scale(1)',
        // left: '720px',
        // top: '550px'
      })),
      state('left', style({
          transform: 'scale(0.7)',
          left: '15px',
          top: '620px'
      })),
      // transition('close => open', animate('1500ms ease-in-out')),
      transition('home => left', animate('100ms', keyframes([
          style({ transform: 'scale(.9)'}),
        //   style({ transform: 'scale(.7)'}),
        //   style({ transform: 'scale(.5)'}),
        //   style({ top: '560px'}),
        //   style({ top: '595px'}),
        //   style({ left: '350px'}),
          style({ left: '150px'})
      ]))),
      // transition('open => close', animate('1800ms ease-in-out'))
      transition('left => home', animate('100ms', keyframes([
        style({ top: '595px'}),
        // style({ top: '560px'}),
        // style({ left: '550px'}),
        // style({ left: '650px'}),
        // style({ transform: 'scale(.5)'}),
        // style({ transform: 'scale(.7)'}),
        style({ transform: 'scale(.9)'}),
    ])))
])]