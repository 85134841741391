import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ZoomPluginStatusService {
  pluginStatus = {
    0: 'Unknown Status',
    1: 'OK',
    2: 'Error',
    3: 'Degraded',
    4: 'Unknown',
    5: 'PredFail',
    6: 'Starting',
    7: 'Stopping',
    8: 'Service',
    9: 'Stressed',
    10: 'NonRecover',
    11: 'NoContact',
    12: 'LostComm',
  };

  constructor() {}
}
