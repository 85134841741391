import * as Chart from 'chart.js'
import * as moment from 'moment-timezone'
import { AuthService } from 'src/app/services/auth.service'

import { DatePipe } from '@angular/common'
import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'

import { GetIotService } from '../../services/get-iot.service'
import { PrintDataService } from '../../services/print-data.service'
import { RefreshApiService } from '../../services/refresh-api.service'

export interface historicPingStatusInterface {
  median: number;
  min: number;
  max: number;
  lost_packet_count: number;
  timestamp: string;
}
@Component({
  selector: 'app-iot-lite-device-chart',
  templateUrl: './iot-lite-device-chart.component.html',
  styleUrls: ['./iot-lite-device-chart.component.scss']
})
export class IotLiteDeviceChartComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() popupState: any;
  @ViewChild('valueTooltip', { static: true })
  
  // @Input() agentId: number;
  // @Input() accountUrl: string;
  // @Input() apiKey: string;
  // @Input() paused: number;
  // @Input() dateRange: number;
  // @Input() viewType: string;
  dataSource: MatTableDataSource<historicPingStatusInterface>;
  displayedColumns: any[] = [
    'median',
    'min',
    'max',
    'lost_packet_count',
    'timestamp',
  ];
  public valueTooltip: TemplateRef<object>;
  
  historicPingStatusList: any = [];
  isDisplayChart = true;
  HOUR_RANGE = 1;
  DAY_RANGE = 2;
  WEEK_RANGE = 3;
  MEDIAN_VIEW = 'pingRtd';
  PACKET_VIEW = 'packetRtd';
  MEDIAN_TEXT = 'Ping RTD';
  PACKETLOSS_TEXT = 'Packetloss';
  isHideMedianView = true;
  isHideBestView = true;
  isHideWorstView = true;
  isHidePacketlossView = true;
  canvas: any;
  canvasWidth: number = 1150;
  canvasHeight: number = 300;
  ctx: any;
  historicPingStatusChart: any;
  dateTo: String;
  dateFrom: String;
  myChartSite: any;
  tempPopupState: any;
  toggleValue: any;
  
  paused: any;
  viewType: any;
  dateRange: any;
  
  currentChart = "24h";
  activeBtn: any;
  
  
  datasetFirstAllFilter: any;
  
  iotId: any;
  
  chartData = {
    datasets: [
      {
        label: 'incident',
        data: [],
        backgroundColor: 'rgb(184, 58, 36, 1)',
        pointBackgroundColor: 'rgb(184, 58, 36, 1)',
        pointBorderColor: 'rgb(184, 58, 36, 1)',
        borderWidth: 1,
        showLine: false,
        hidden: false
      },
      {
        label: 'Ping Rtd',
        data: [],
        backgroundColor: 'rgb(255, 184, 0, 1)',
        pointBackgroundColor: 'rgb(211,211,211, 1)',
        pointBorderColor: 'rgb(211,211,211, 1)',
        borderColor: 'rgb(211,211,211, 1)',
        borderWidth: 1,
        hidden: false,
        fill: false,
        showLine: true,
        
      },
      {
        label: 'Packet Loss',
        data: [],
        backgroundColor: 'rgb(173,216,230, 1)',
        pointBackgroundColor: 'rgb(173,216,230, 1)',
        pointBorderColor: 'rgb(173,216,230, 1)',
        borderColor: 'rgb(173,216,230, 1)',
        borderWidth: 0,
        showLine: true,
        fill: false,
        hidden: false
      },

    ],
    labels: []
  }

  constructor(
    private getIot: GetIotService,
    private refreshApiService: RefreshApiService,
    private printData: PrintDataService,
    private Auth: AuthService,
    private datePipe: DatePipe , 
    
  ) {}

  ngOnInit() {}


  ngOnChanges(changes: SimpleChanges) {
    if (changes.popupState) {
      this.tempPopupState = changes;
    } else {
      changes = this.tempPopupState;
      this.myChartSite.destroy();
    }
    
    if (changes.popupState.currentValue == 'open') {
      this.canvas = document.getElementById('myChartSite');
      this.ctx = this.canvas.getContext('2d');
      this.myChartSite = new Chart(this.ctx, {
        type: 'line',
        // data: this.data24h,
        // data: {
        //   labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        //   datasets: [{
        //     label: '# of Votes',
        //     data: [12, 19, 3, 5, 2, 3],
        //     borderWidth: 1
        //   }]
        // },
        options: {
          responsive: false,
          display: true,
          fontSize: '6px',
          padding: '0px',
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0, // disables bezier curves
            }
          }
        }
      });
      
        // this.chartData.datasets[1].label = 'mbps Total';
        // this.chartData.datasets[2].label = 'mbps RX';
        // this.chartData.datasets[3].label = 'mbps TX';
      
      const { dateRange, paused, viewType, iotDeviceID } = this.data;
      
      console.log('this.data: ', this.data)
      
      this.dateRange = dateRange;
      this.paused = paused;
      this.viewType = viewType;
      this.iotId = iotDeviceID;
      this.currentChart = "60m";
      this.activeBtn = 0;
      this.toggleValue = 0;
      this.toggleBtn(1);
      
      this.fetchChartData(1);
    } else {
      setTimeout(() => {
        this.myChartSite.destroy();
      }, 500);
    }
  }

  
  fetchChartData = (duration) => {
    console.log('this iotId: ', this.iotId)
    console.log('this duration: ', duration)
    let datasetFirst;
    let datasetSecond;
    let datasetThird;
    
    this.getIot.getIotLiteDeviceChart(this.iotId, duration).subscribe( (data: any) => {
      const [res] = data.response;
      
      console.log('res: ', res)
      
      datasetFirst = res.incidentJson;
      datasetSecond = res.minRttJson;
      datasetThird = res.lostJson;

  
      this.chartData.datasets[0].data = datasetFirst;
      this.chartData.datasets[1].data = datasetSecond
      this.chartData.datasets[2].data = datasetThird
    // this.chartData.labels = test1;
      if(datasetFirst) {
       datasetFirst.sort(this.custom_sort);  
       
         for (let x = 0; x < datasetFirst.length; x++) {
          if (datasetFirst[x].y < 0) {
            datasetFirst[x].y = null;
          }
        }
    
      }
      
      if (datasetSecond) {
       datasetSecond.sort(this.custom_sort);  
       for (let x = 0; x < datasetSecond.length; x++) {
        if (datasetSecond[x].y === 0) {
          datasetSecond[x].y = null;
        }
        }
      }
      
      if (datasetSecond) {
       datasetThird.sort(this.custom_sort);  
       for (let x = 0; x < datasetThird.length; x++) {
        if (datasetSecond[x].y === 0) {
            datasetSecond[x].y = null;
          }
        }
      }
      
      
      
    
    console.log(' this.chartData: ',  this.chartData)
    
    
    
    
    //FUNCTION
    
    
    
   
    // //FUNCTION
    
    
    this.datasetFirstAllFilter = datasetFirst;
  
    if (this.viewType == 'pingRtd') {
      console.log('hide data set for pingRtd')
      // this.chartData.datasets[1].hidden = !('pingRtd' == this.viewType);
      // this.chartData.datasets[2].hidden = !('pingRtd' == this.viewType);
    }
    
    this.loadChart();
      
      //FUNCTION
    }, (err) => {
    } )
    
   
    
  }
    
  
  loadChart = () => {
  
    this.myChartSite.options.scales = {
      xAxes: [{
        type: "time",
        display: true,
        scaleLabel: {
          display: true,
          labelString: this.currentChart == "7d" ? "Date" : "Time",
        },
        time: {
          unit: this.currentChart == "7d" ? "day" : this.currentChart == "24h" ? "hour" : "minute"
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }],
    };
    
     this.myChartSite.options.tooltips = {
      callbacks: {
        label: function (tooltipItem, chart) {
          if (tooltipItem.datasetIndex == 0) {
            return "ID: " + chart.datasets[0].data[tooltipItem.index].incident_id;
          }
          else {
            return tooltipItem.yLabel
          }
        },
        beforeLabel: function (tooltipItem, chart) {
        
        console.log('beforeLabel tooltipItemL:  ', tooltipItem)
        console.log(' beforeLabelchart:  ', chart)
        
          switch (tooltipItem.datasetIndex) {
            case 0:
              return "Incidents";
            case 1:
              return chart.datasets[1].label;
            // return "mbps";
            case 2:
              return chart.datasets[2].label;

            case 3:
              return "Packetloss";
          }
        },
        afterLabel: function (tooltipItem, chart) {
        
        console.log('tooltipItemL:  ', tooltipItem)
        console.log('chart:  ', chart)
        
          if (tooltipItem.datasetIndex == 0 && chart.datasets[0].data[tooltipItem.index].triggers) {
            let triggers = chart.datasets[0].data[tooltipItem.index].triggers.split(" - ");
            triggers.splice(0, 1);
            return triggers;
          }
          else {
            return ""
          }
        },
        title: function (tooltipItem, chart) {
          return moment(tooltipItem[0].xLabel).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
        },
      }
    }
    
    
    
    this.myChartSite.data.datasets = this.chartData.datasets;
    // this.myChartSite.data.labels = this.chartData.labels;
    
    console.log('myChartSite update: ',this.myChartSite)
    
    this.myChartSite.update({ duration: 500 });
  }
  
  custom_sort = (a, b) => {
    return new Date(a.x).getTime() - new Date(b.x).getTime();
  }
  
  //NEW IMPLEMENTATION
  initializeChart = () => {
    this.canvas = document.getElementById('myChartSite');
    this.ctx = this.canvas.getContext('2d');
    this.myChartSite = new Chart(this.ctx, {
      type: 'line',
      // data: this.data24h,
      options: {
        responsive: false,
        display: true,
        fontSize: '6px',
        padding: '0px',
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0, // disables bezier curves
          }
        }
      }
    });
  }

  getHistoricPingStatustData = () => {
    if (this.paused === 0) {

      this.historicPingStatusList = [

      {
          "timestamp": "2023-12-23T11:27:05+00:00",
          "median": "1.0",
          "lost_packet_count": 5,
          "sent_packet_count": 50
      },
      {
          "timestamp": "2023-12-23T11:17:23+00:00",
           "median": "2.0",
          "lost_packet_count": 4,
          "sent_packet_count": 50
      },
      {
          "timestamp": "2023-12-23T11:07:05+00:00",
          "median": "5.0",
          "lost_packet_count": 3,
          "sent_packet_count": 50
      },
      {
          "timestamp": "2023-12-23T10:57:01+00:00",
          "median": "2.0",
          "lost_packet_count": 2,
          "sent_packet_count": 50
      },
      {
          "timestamp": "2023-12-23T10:46:58+00:00",
          "median": "1.0",
          "lost_packet_count": 1,
          "sent_packet_count": 50
      }
     
    ];
    
    if (this.isDisplayChart) {
      // chart data
      this.setChartData(this.historicPingStatusList);
    } else {
      // list data
      this.dataSource = new MatTableDataSource(
        this.historicPingStatusList
      );
    }
    // });
}
  };


  //NEW IMPLEMENTATION
  setChartData = (rtdList) => {
    const medianList = [];
    const packetLostList = [];
    const timeLabels = [];
    for (let i = 0; i < rtdList.length; i++) {
      const {  median, lost_packet_count, timestamp } = rtdList[i];
      medianList.unshift(parseFloat(median));
      packetLostList.unshift(parseFloat(lost_packet_count));
      timeLabels.unshift(timestamp);
    }

    this.historicPingStatusChart.reset();
    this.historicPingStatusChart.data.labels = timeLabels;
    this.historicPingStatusChart.data.datasets[0].data = medianList;
    this.historicPingStatusChart.data.datasets[1].data = packetLostList;
    
    console.log(this.historicPingStatusChart.data.datasets[1].data)
    console.log(this.historicPingStatusChart.data.datasets[0].data)
    
    if (rtdList.length == 0) {
      this.historicPingStatusChart.data.labels = this.getYaxis();
    }
    this.historicPingStatusChart.update({ duration: 1000 });
  };

  setDateRangeValues = () => {
    const dateNow = moment().utcOffset(0, false).format();
    switch (this.dateRange) {
      case this.HOUR_RANGE:
        this.dateTo = moment(dateNow)
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        this.dateFrom = moment(dateNow)
          .subtract(1, 'hours')
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        break;
      case this.DAY_RANGE:
        this.dateTo = moment(dateNow)
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        this.dateFrom = moment(dateNow)
          .subtract(1, 'days')
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        break;
      case this.WEEK_RANGE:
        this.dateTo = moment(dateNow)
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        this.dateFrom = moment(dateNow)
          .subtract(7, 'days')
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        break;
      default:
        this.dateRange = this.HOUR_RANGE;
        this.dateTo = moment(dateNow)
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        this.dateFrom = moment(dateNow)
          .subtract(1, 'hours')
          .utcOffset(0, false)
          .format()
          .split('Z')[0];
        break;
    }
  };

  switchDateRange = (range) => {
    this.dateRange = range;
    // this.init();
  };

  switchDisplayType = () => {
    this.isDisplayChart = !this.isDisplayChart;
    // this.init();
  };

  // init = () => {
  //   this.setDateRangeValues();
  //   if (this.isDisplayChart) {
  //     // this.initializeChart();
  //   } else {
  //     this.historicPingStatusChart.destroy();
  //   }

  //   this.getHistoricPingStatustData();
  // };
  print() {
    var divId
    if (this.toggleValue == 0) { // chart
      this.printCanvas()
    } else { // list
      this.printList();
    }
  }

  printCanvas() {
    let printContents = (<HTMLCanvasElement>document.getElementById('myChartSite')).toDataURL();
    let popupWin;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print Chart</title>
        </head>
        <body onload="window.print(); window.close()">
          <img style='width: 100%;' src='${printContents}'/>
        </body>
      </html>
    `);
    popupWin.document.close();
  }

  printList() {
    this.printMode = true;
    setTimeout(() => {
      let printContents, stylesHtml, linksHtml, popupWin;
      printContents = document.getElementById('printTable').innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Print Report</title>
            ${stylesHtml}
            ${linksHtml}
          </head>
          <body onload="window.print(); window.close()">
            ${printContents}
          </body>
        </html>
      `);
      popupWin.document.close();
      this.printMode = false;
    }, 500);
  }
  
  printMode: boolean = false;
  

  // setView = (viewType) => {
  //     console.log('viewType',viewType)
  
  //   switch (viewType) {
  //     case this.MEDIAN_VIEW:
  //       this.isHideMedianView = false;
  //       break;
  //     case this.PACKET_VIEW:
  //       this.isHidePacketlossView = false;
  //     case this.MEDIAN_TEXT:
  //       this.isHideMedianView = !this.isHideMedianView;
  //       break;
  //     case this.PACKETLOSS_TEXT:
  //       this.isHidePacketlossView = !this.isHidePacketlossView;
  //       break;
  //   }
  // };

  getYaxis() {
    if (this.dateRange === this.HOUR_RANGE) {
      return this.getLast60MinYaxis();
    }

    if (this.dateRange === this.DAY_RANGE) {
      return this.getLast24HoursYaxis();
    }

    if (this.dateRange === this.WEEK_RANGE) {
      return this.getLast7DaysYaxis();
    }
  }

  getLast60MinYaxis = () => {
    const timeSeries = [];
    const dateNow = moment().utcOffset(0, true).format();
    timeSeries.unshift(moment(dateNow).format().split('Z')[0]);
    for (let i = 1; i <= 20; i++) {
      timeSeries.unshift(
        moment(dateNow)
          .subtract(i * 3, 'minutes')
          .format()
          .split('+')[0]
      );
    }

    return timeSeries;
  };

  getLast24HoursYaxis = () => {
    const timeSeries = [];
    const dateNow = moment().utcOffset(0, true).format();
    timeSeries.unshift(moment(dateNow).format().split('Z')[0]);
    for (let i = 1; i <= 24; i++) {
      timeSeries.unshift(
        moment(dateNow).subtract(i, 'hours').format().split('+')[0]
      );
    }

    return timeSeries;
  };

  getLast7DaysYaxis = () => {
    const timeSeries = [];
    const dateNow = moment().utcOffset(0, true).format();
    timeSeries.unshift(moment(dateNow).format().split('Z')[0]);
    for (let i = 1; i <= 14; i++) {
      timeSeries.unshift(
        moment(dateNow)
          .subtract(i * 12, 'hours')
          .format()
          .split('+')[0]
      );
    }

    return timeSeries;
  };
  
  
  //OLD 
  
   public onSeriesAdded(e: any) {
    if (e.args.series) {
        e.args.series.tooltipTemplate = this.valueTooltip;
    }
  }
  
  getDateObject(object: string, chartData: string) {
    let date = new Date(object);
    if (chartData === '7d') return this.datePipe.transform(date,'MMM d');
    else if (chartData === '24h') return this.datePipe.transform(date, 'hh:mm a');
    else return this.datePipe.transform(date, 'hh:mm a');
  }
  
  set60m() {
    if (this.currentChart != "60m") {
      this.currentChart = "60m";
      this.toggleBtn(1);
      this.fetchChartData(1);
    }
  }
  
  set24h() {
    if (this.currentChart != "24h") {
      this.currentChart = "24h";
      this.toggleBtn(2);
      this.fetchChartData(24);
    }
  }
  
  set7d() {
    if (this.currentChart != "7d") {
      this.currentChart = "7d";
      this.toggleBtn(3);
      this.fetchChartData(7);
    }
  }
  
  
    toggleBtn(n) {
    if (this.activeBtn == n) {
      this.activeBtn = 0;
    } else {
      this.activeBtn = n;
    }
  }
}
