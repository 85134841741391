<div class="delete-site">
  <div class="delete-site__header">Deactive {{ dPlugin }}</div>
  <p class="delete-site__err" *ngIf="errorMessage">{{errorMessage}}</p>
  <div class="delete-site__body">
    <div class="delete-site__image">
      <img *ngIf="!isLoading" src="../../../../assets/img/vs_img/delete-icon.png" alt="" />
      <img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
    </div>
    <div class="delete-site__text-button-container">
      <div class="">
        Are you sure you want to deactive {{ dPlugin }} for <strong>{{ hostName }}</strong
        >?
      </div>
      <div class="delete-site__button-container">
        <div
          class="delete-site__button-item delete-site__button-item--yes"
          (click)="deactivateRoomPlugin()"
        >
          Yes
        </div>
        <div
          class="delete-site__button-item delete-site__button-item--cancel"
          (click)="closeDialog()"
        >
          No
        </div>
      </div>
    </div>
  </div>
</div>
