import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-cloud-room-analytics',
  templateUrl: './cloud-room-analytics.component.html',
  styleUrls: ['./cloud-room-analytics.component.scss']
})
export class CloudRoomAnalyticsComponent implements OnChanges {
  @Input() count: number;
  @Input() label: string = "Not-Active";
  @Input() color: string;
  @Input() backcolorbtn: any;
  @Input() isbutton: string;
  prevcount: string;

  constructor() { }

  activeBtn = 0;

  setActive(n){
    if (this.activeBtn == n) {
      this.activeBtn = 0;
      // console.log(n);
    } else {
      this.activeBtn = n;
      // console.log(n);
    }
  }
  sendPopupType(p) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.count.currentValue != changes.count.previousValue ) {
        // // console.log(changes.count.currentValue + ", pre" + changes.count.previousValue)
        // 1 second delay to display the first set of values
        // setTimeout(() => {
          this.backcolorbtn = "btnbacklite"
          // // console.log(this.backcolorbtn + "backcolorbtn")
        //   }, 100);
        setTimeout(() => {
          this.backcolorbtn = "btnbacklite-default"
          // // console.log(this.backcolorbtn + "backcolorbtn")
          }, 2500);
    }else{
      setTimeout(() => {
        this.backcolorbtn = "btnbacklite-default"
        // // console.log(this.backcolorbtn + "backcolorbtn")
        }, 1000);
    }
  }
}