<div class="cloud-user-stats">
  <app-cloud-user-live-stats [isTeamsUser]="true" [cloudUserLivestats]="cloudUserLivestats" [isNotButton]="isNotButton">
  </app-cloud-user-live-stats>
  <hr />
  <app-cloud-user-path-detection [cloudUserStats]="teamsDetails" [isUserOnCall]="isUserOnCall">
  </app-cloud-user-path-detection>
  <!-- <app-cloud-live-call-list [liveParticipants]="s"></app-cloud-live-call-list> -->
  <hr *ngIf="!isUserOnCall" />
  <app-cloud-user-device-health [cloudUserStats]="teamsDetails"></app-cloud-user-device-health>
  <hr />
  <app-teams-user-utilization [cloudUserUtilization]="cloudUserUtilization" [isNotButton]="isNotButton"
    [hasAccess]="hasAccess"></app-teams-user-utilization>
  <hr />
  <app-cloud-user-notes [notes]="teamsDetails?.notes" [zoom_user_id]="teamsDetails?.zoom_user_id">
  </app-cloud-user-notes>
</div>