import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { IntegratorService } from '../services/integrator.service';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class GetAudioService {

  constructor(private http: HttpClient, private integrator: IntegratorService) { }
  public settingsVideo = {
    health: {
      title: 'Health',
      type: 'custom',
    },
  };
  
  server_url: string = environment.serverUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }

  private extractData(res: Response) {
    // // console.log('response: ', res)
    let body = res;
    return body || {};
  }

  private extractAudioDeviceDetails(res: any) {
    let body = res;
    // const helper = new JwtHelperService();
    // if (body.response) {
    //   try {
    //     body.response.password = helper.decodeToken(body.response.password);
    //   }
    //   catch (Error) {
    //     // // console.log('cant decode password', Error)
    //     return body;
    //   }
    // }

    return body || {};
  }
  
  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    return decodedToken.company_id;
  }


  apiGetAudioCompanyInfo(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token') as any;
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: companyId,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/company/getCompanyInfo', postData, this.httpOptions)
  }

  apiGetAllAudioSites(): Observable<Object> {
    // // console.log("Calling GET to pull all audio sites detailed list from API Server");
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
    };
    return this.http
      .post<any>(this.server_url + '/api/v1/audio/getAllSites', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }

  apiGetAudioSitesSummary(): Observable<Object> {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/audio/getAllSiteSummary', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }



  apiGetAudioDeviceDetails(deviceId: any): Observable<Object> {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
      device_id: deviceId,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/audio/getDeviceDetails', postData, this.httpOptions)
      .pipe(map(this.extractAudioDeviceDetails));
  }

  // Load the JSON file for the Site Panel
  fetchAudioDevices(): Observable<Object> {
    // // console.log("Calling fetch Audio Devices");
    return null;
    return this.http.get('/assets/db/audio/devices.json');
  }

  fetchAudioSites(): Observable<Object> {
    return this.http.get('/assets/db/audio/sites.json');
  }

  fetchAudioDeviceSummary(): Observable<Object> {
    return null;
    // return this.http.get('/assets/db/audio/all_device_summary.json');
  }

  fetchAudioSummary(): Observable<Object> {
    return this.http.get('/assets/db/audio/company.json');
  }

  fetchAudioUserSummary(): Observable<Object> {
    return null;
    // return this.http.get('/assets/db/audio/all_user_summary.json');
  }

  fetchAudioSitesSummary(): Observable<Object> {
    return null;
    // return this.http.get('/assets/db/audio/all_user_summary.json');
  }

  noData = (cellData) => {
    if (!cellData) {
      return '--';
    }
    return cellData;
  };

  rebootAudioDevice(deviceId: any): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
      device_id: deviceId,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/audio/rebootDevice', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }

  apiGetAudioCompanySiteInfo(siteId): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    let postData = {
      company_id: companyId,
      site_id: siteId,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/company/getSiteInfo', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }
  apiGetAudioSiteDeviceSummary(siteId): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
      site_id: siteId,
    };

    return this.http
      .post<any>(
        this.server_url + '/api/v1/audio/getDeviceSummaryBySite',
        postData,
        this.httpOptions
      )
      .pipe(map(this.extractData))
  }

  apiGetAudioSiteDevicebySite(siteId): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
      site_id: siteId,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/audio/getDevicesBySite', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }

  apiGetAllAudioDevices(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/audio/getAllDevices', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }

  apiGetAudioDeviceSummary(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/audio/getSummary', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }

  getAllDeviceSummary(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/audio/getAllDeviceSummary', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }

  audioMuteUnMute(deviceId: any, isMuted: any): Observable<Object> {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const postData = {
      company_id: companyId,
      device_id: deviceId,
      isMuted: isMuted ? 1 : 0,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/audio/mute', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }

  updateAlertTriggers(postData): Observable<Object> {
    return this.http.post<any>(
      this.server_url + '/api/v1/audio/updateTriggers',
      postData,
      this.httpOptions
    );
  }

  resetAlertTriggers(postData): Observable<Object> {
    return this.http.post<any>(
      this.server_url + '/api/v1/audio/resetTriggers',
      postData,
      this.httpOptions
    );
  }

  rescanDeviceData = (device_id: any) => {
    const params = {
      company_id: this.getCompanyId(),
      device_id,
    }
    
    return this.http.post<any>(
      this.server_url + '/api/v1/audio/syncDevice',
      params,
      this.httpOptions
    );
    // .catch(this.handleError);
  }

  /**
   *  Note: I've scan the code and this function is never called.
   * For the meantime, I will copy the implementation of the same function name for video service
   **/
  getDeviceLoginActionId(user: any, userPassword: any, deviceId: any) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    let postData = {
      company_id: companyId,
      username: user,
      password: userPassword,
      device_id: deviceId,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/audio/deviceLoginTest',
      postData,
      this.httpOptions
    );
    // .map(this.extractData)
    // .catch(this.handleError);
  }

  pauseAudioDevice = (deviceId: any, pause: number): Observable<Object>  =>{
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: companyId,
      device_id: deviceId,
    };
    const apiUrl = pause === 1 ? '/api/v1/audio/pauseDevice' : '/api/v1/audio/unpauseDevice';

    return this.http
      .post<any>(this.server_url + apiUrl, postData, this.httpOptions)
      .pipe(map(this.extractData))
  }
}
