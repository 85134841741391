import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class IntegratorService {
  decodedToken: any;

  isIntegrator = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    this.decodedToken = helper.decodeToken(token);
    if (this.decodedToken && this.decodedToken.loginType) {
      return this.decodedToken.loginType === 'integrator';
    }
    return false;
  };
}
