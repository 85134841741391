

import { GetCollectorService } from 'src/app/services/get-collector.service'

import { Component, Input, OnInit, SimpleChanges } from '@angular/core'

import { PopupTypeService } from '../../services/popupType.service'

@Component({
  selector: 'app-collector-reboot',
  templateUrl: './collector-reboot.component.html',
  styleUrls: ['./collector-reboot.component.scss']
})
export class CollectorRebootComponent  implements OnInit {
  @Input() data: any;

  constructor(
    private ds: PopupTypeService,
    private getCollector : GetCollectorService,
    
  ) {

  }

  ngOnInit() {}
  
  
	ngOnChanges(changes: SimpleChanges) {
		if (changes.data && this.data) {
			console.log('data info: ', this.data)
		}
	}


  rebootCollector = ( ) =>  {
    const { collector_id } = this.data;
    this.getCollector.rebootCollector(collector_id).subscribe((data: any) => { 
      
      console.log(data)
        const res = data;
      if(res.status == 200) {
        
      }
        this.sendPopupType(99)
      },
      (err) => { }
    );
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
    // console.log(p);
  }

  ngOnDestroy() {
    // clear message
    this.ds.clearPopupType();
  }
}
