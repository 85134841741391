import * as moment from 'moment-timezone'
import { Subscription } from 'rxjs'

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'

import { environment } from '../../../../environments/environment'
import { GetAudioService } from '../../../services/get-audio.service'
import { PopupTypeService } from '../../../services/popupType.service'
import { RefreshApiService } from '../../../services/refresh-api.service'

@Component({
  selector: 'app-audio-device-panel',
  templateUrl: './audio-device-panel.component.html',
  styleUrls: ['./audio-device-panel.component.scss'],
})
export class AudioDevicePanelComponent implements OnInit {
  PopupTitle: string = 'Audio Device - ';
  public audioDeviceData;
  public audioDeviceType: any = '';
  id: any;
  apiTimers: any;
  refreshData: any;
  filterListner: Subscription;
  two$: Subscription;
  syncTime: any;
  dataError: any;

  dCollector: String = environment.config.dynamicText.dCollector;

  constructor(
    private route: ActivatedRoute,
    public ds: PopupTypeService,
    private router: Router,
    private getAudioDataService: GetAudioService,
    private refreshApiService: RefreshApiService
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });

    // This recieves the update data boolean to start the timer
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      this.refreshData = m;
      this.getApiData();
    });
  }

  getApiData() {
    if (this.refreshData) {
      this.getAudioApiData();
    }
  }

  openHealthHistory() {
    this.ds.sendPopupType(36, { 'deviceId': this.id, 'hType': 'audio' });
  }

  ngOnInit() {
    this.two$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.getAudioApiData();
      }
    });
    this.getAudioApiData();
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
    if (this.two$) this.two$.unsubscribe();

    // document.getElementById("rt-panel").style.backgroundColor = "#d3d3d3";
    // This will clear timers that are pulling data from API Server
    // clearInterval(this.apiTimers);
  }

  // This will Video Device Data from server API
  getAudioApiData = () => {
    //GET Request for Video Device Detailed Stats Summary
    this.getAudioDataService.apiGetAudioDeviceDetails(this.id).subscribe((data: any) => {
      const res = data.response;
      this.audioDeviceData = {
        ...res,
        mr_pair:this.checkMrPair(res.mr_pair)
      };
      
      console.log(' this.audioDeviceData: ', this.audioDeviceData)

      if (this.audioDeviceData == null) {
        this.dataError = true;
      } else {
        this.dataError = false;
      }

      if (this.audioDeviceType == '') {
        this.audioDeviceType = this.audioDeviceData.device_type;
      }

      this.PopupTitle = 'Audio Device - ' + this.audioDeviceData.room_name;
      this.syncTime = moment(this.audioDeviceData.sync_stamp)
        .utc()
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format('MM-DD-YYYY hh:mma');
    });
  }
  
  checkMrPair = (mrPair) => {
    if(mrPair) {
      return JSON.parse(mrPair)
    }
    return null
  }

  getStats() {
    this.getAudioDataService.fetchAudioDevices().subscribe((data) => {
      let arr = [];
      for (let d in data) {
        arr.push(data[d]);
      }
      this.audioDeviceData = arr.filter((obj) => {
        return obj.device_id === this.id;
      })[0];
    });
  }

  isPolycom(deviceType: string): boolean {
    switch (deviceType) {
      case 'polycom':
        return true;
      case 'trioDevice':
        return true;
      case 'vvx':
        return true;
    }
    return false;
  }

  onAudioEvent = ($event) => {
    if ($event === 'refreshAudioData') {
      this.getAudioApiData();
    }
  };
}
