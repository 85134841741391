import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-btn-health',
  templateUrl: './btn-health.component.html',
  styleUrls: ['./btn-health.component.scss']
})
export class BtnHealthComponent implements OnChanges {
  // @Input() count: number = 0;
  @Input() count: any = 0;
  @Input() label: string = "Not-Active";
  @Input() color: string;
  @Input() backcolorbtn: any;
  setTimer: any;
  prevcount: string;

  constructor() { }

  @Input() activeBtn: number;

  ngOnChanges(changes: SimpleChanges) {

    // if(this.ch

    if (changes.count.currentValue != changes.count.previousValue ) {
    // if (changes.count && this.count ) {

      
      this.backcolorbtn = "btnbacklite"

      this.setTimer = setTimeout(() => {
      this.backcolorbtn = "btnbacklite-default"
      // // console.log(this.backcolorbtn + "backcolorbtn")
      }, 2500);
    }else{
      this.setTimer = setTimeout(() => {
        this.backcolorbtn = "btnbacklite"
        }, 1000);
    }

    if( changes.label && this.label) {
      if(this.label == 'All Sites' && this.count == null ||
      this.label == 'Impacted Sites' && this.count == null 
      ) {
        this.setTimer = setTimeout(() => {
          this.backcolorbtn = "btnbacklite-default"
          }, 2500);
          this.count = '--';
      }
    }

  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

}