import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-recommended-actions',
  templateUrl: './recommended-actions.component.html',
  styleUrls: ['./recommended-actions.component.scss'],
})
export class RecommendedActionsComponent implements OnInit, OnChanges {
  @Input() type;
  @Input() incidentSummary: any;
  constructor() {}

  isShowQosRecommendedActions = false;
  isShowDevicesRecommendedActions = false;
  devicesWithIssues: any;

  ngOnChanges(changes: SimpleChanges): void {
    this.setIsShowQosRecommendedActions();
    this.setIsShowDevicesRecommendedActions();
  }

  setIsShowQosRecommendedActions = () => {
    if (this.type === 'video' || this.type === 'audio') {
      if (
        this.incidentSummary.jitterAlert ||
        this.incidentSummary.packetlossAlert ||
        this.incidentSummary.latencyAlert
      ) {
        this.isShowQosRecommendedActions = true;
      } else {
        this.isShowQosRecommendedActions = false;
      }
    } else if (this.type === 'zoom') {
      if (
        this.incidentSummary.jitterAlert ||
        this.incidentSummary.packetlossAlert ||
        this.incidentSummary.frame_rate
      ) {
        this.isShowQosRecommendedActions = true;
      } else {
        this.isShowQosRecommendedActions = false;
      }
    }
  };

  setIsShowDevicesRecommendedActions = () => {
    if (this.type === 'video') {
      const devices = [];
      if (this.incidentSummary.cameraDown) {
        devices.push('Camera');
      }
      if (this.incidentSummary.micDown) {
        devices.push('Microphone');
      }

      this.isShowDevicesRecommendedActions = devices.length > 0;
      this.devicesWithIssues = devices.toString();
    } else if (this.type === 'zoom') {
      const devices = [];
      if (this.incidentSummary.cameraDown) {
        devices.push('Camera');
      }
      if (this.incidentSummary.micDown) {
        devices.push('Microphone');
      }
      if (this.incidentSummary.speakerDown) {
        devices.push('Speaker');
      }

      this.isShowDevicesRecommendedActions = devices.length > 0;
      this.devicesWithIssues = devices.toString();
    }
  };

  ngOnInit() {}
}
