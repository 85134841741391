import { Component, OnInit, Input } from '@angular/core';
import * as Chart from 'chart.js';
import { AuthService } from  '../../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';
import { RefreshApiService } from '../../../services/refresh-api.service';

@Component({
  selector: 'app-chart-audio-twentyfourhours-user',
  templateUrl: './chart-audio-twentyfourhours-user.component.html',
  styleUrls: ['./chart-audio-twentyfourhours-user.component.scss']
})
export class ChartAudioTwentyfourhoursUserComponent implements OnInit {
  @Input() isOn: boolean;
  canvas: any;
  ctx: any;
  audio60MinCallChart: any;
  id: number = 0;
  chartApiData:any;
  loading: boolean = true;
  chartData: any = {};
  filterListner: Subscription;

  constructor(
    public http: HttpClient, 
    private Auth: AuthService,
    private refreshApiService: RefreshApiService) {
    
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if(m && this.isOn){
        this.fetchChartData();
      }
    });
  }

  ngOnInit() {
    this.canvas = document.getElementById('audio60MinCallChart');
    this.ctx = this.canvas.getContext('2d');
    
    const chartOptions = {
      responsive: false,
      display: true,
      maintainAspectRatio: false,
      elements: {
        line: {
            tension: 0, // disables bezier curves
        }
      },
      scales: {
        xAxes: [{
          // type: "time",
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Time'
          },
          ticks: {
            callback: function(value, index, values) {
              const d = new Date(value);
              return d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}).replace(/\s+/g, '');
            }
          },
          categoryPercentage: 1,
          barPercentage: 1
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true,
                precision: 0
            }
        }],
      },
      tooltips: {
        callbacks: {
          title: function(tooltipItem, chart) {
            const d = new Date(tooltipItem[0].label);
            return d.toLocaleTimeString();
          }
        }
      }
    };

    const chartData = {
      datasets: [
        {
          label: 'Calls',
          data : [],
          backgroundColor: 'rgb(255, 184, 0, .5)',
          borderWidth: 0,
          pointRadius: 1,
          order: 1
        },
        {
          label: 'Incidents',
          backgroundColor:'rgb(184, 58, 36, 1)',
          pointBackgroundColor: 'rgb(184, 58, 36, 1)',
          pointBorderColor: 'rgb(184, 58, 36, 1)',
          pointRadius: 2,
          showLine: false,
          borderWidth: 1,
          order: 2
        }
      ]
    };

    const chartConfig = {
      type: 'line',
      data: chartData,
      options: chartOptions
    };

    this.audio60MinCallChart = new Chart(this.ctx, chartConfig);

    if(this.isOn) {
      this.fetchChartData();
    }
  }

  fetchChartData(){
    this.loading = true;
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const deviceDetails = {
      company_id: decodedToken.company_id
    }

    this.Auth.getCalls60MinChartData(deviceDetails).subscribe((data) => {
      let call_data = data['response'][0].call_count;
      let incidents = data['response'][0].incident_count;
      const xAxes = data['response'][0].xAxes;
      
      // call_data = call_data.filter(c => c.y !== 0);
      incidents = incidents.filter(i => i.y !== 0);
      call_data.sort(this.custom_sort);
      incidents.sort(this.custom_sort);
      xAxes.sort(this.custom_sort2);

      this.audio60MinCallChart.reset();
      this.audio60MinCallChart.data.labels = xAxes;
      this.audio60MinCallChart.data.datasets[0].data = call_data;
      this.audio60MinCallChart.data.datasets[1].data = incidents;
      this.audio60MinCallChart.update({duration: 1000});
      this.loading = false;
    });
  }

  custom_sort(a, b) {
    return new Date(a.x).getTime() - new Date(b.x).getTime();
  }

  custom_sort2(a, b) {
    return new Date(a).getTime() - new Date(b).getTime();
  }

  ngOnDestroy() {
    if(this.audio60MinCallChart){
      this.audio60MinCallChart.destroy();
    }
    this.filterListner.unsubscribe();
  }
}