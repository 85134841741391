import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
import { GetPluginService } from 'src/app/services/get-plugin.service';
import { PopupTypeService } from '../../services/popupType.service';
import { AuthService } from '../../services/auth.service';
import { ZoomPluginStatusService } from '../../services/zoom-plugin-status.service';
import { ZoomPluginActionService } from '../../services/zoom-plugin-action.service';

@Component({
  selector: 'app-camera-plugin-in-status',
  templateUrl: './camera-plugin-in-status.component.html',
  styleUrls: ['./camera-plugin-in-status.component.scss'],
})
export class CameraPluginInStatusComponent implements OnChanges {
  @Input() cloudRoomVideoStatsData: any;
  @Input() pluginId: number;
  @Input() cameraFailOver: boolean;
  failOverIdList: any;
  camList = [];
  actionCount: Number = 0;

  constructor(
    private getPluginService: GetPluginService,
    public ds: PopupTypeService,
    private Auth: AuthService,
    public zoomPluginStatus: ZoomPluginStatusService,
    public zoomPluginAction: ZoomPluginActionService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cloudRoomVideoStatsData && this.cloudRoomVideoStatsData) {
      const copiedArray = [...this.cloudRoomVideoStatsData];
      this.camList = copiedArray;
      if (!this.cameraFailOver) {
        this.cameraFailOver = false;
        this.resetCameraFailOver();
        this.zoomPluginAction.visibility_one_loader = false;
      }
    }
    this.checkList(this.camList);
  }

  checkList = (params) => {
    const primary = params.filter((e) => e.primary);
    const backup = params.filter((e) => e.backup === 1);
    const otherDevices = params.filter((e) => !e.primary && e.backup !== 1);
    const newArr = primary.concat(backup, otherDevices);

    if (!this.isEmpty(primary)) {
    }

    this.camList = newArr;
  };

  isEmpty = (arr) => {
    return arr.length === 0;
  };

  listOnChange = (index, isPrimary, events) => {
    let bool = true;
    let type = 'primary';

    if (!isPrimary) {
      bool = false;
      type = 'backup';
    }

    const copiedArray = [...this.camList];
    for (let i = 0; i < copiedArray.length; i++) {
      if (i === index) {
        copiedArray[i].primary = bool;
        copiedArray[i].backup = events ? 1 : 0;
      } else {
        copiedArray[i][type] = isPrimary ? false : 0;
      }
    }
    this.camList = copiedArray;
  };

  enableOrDisableCameraFailOver = (bool) => {
    this.cameraFailOver = !bool;
    this.updateFailOverValue(this.cameraFailOver);
    this.zoomPluginAction.visibility_one_loader = true;
    if (!this.cameraFailOver) {
      this.resetCameraFailOver();
    }
  };

  resetCameraFailOver = () => {
    const copiedArray = [...this.camList];
    for (let i = 0; i < copiedArray.length; i++) {
      copiedArray[i].backup = 0;
    }
  };

  saveFailOver = () => {
    const primary = this.camList.filter((p) => p.primary);
    const backup = this.camList.filter((b) => b.backup > 0);
    this.failOverIdList = primary;
    if (!this.isEmpty(backup)) {
      this.failOverIdList = primary.concat(backup);
    }

    this.failOverIdList = this.failOverIdList.map((i) => i.id);
    this.updateFailOverList(this.failOverIdList, this.pluginId);
    this.zoomPluginAction.visibility_one_loader = true;
  };

  resetCloudRoomfields = () => {
    const failOverList = this.cloudRoomVideoStatsData.map((device) => {
      return device.id;
    });
    this.updateFailOverList(failOverList, this.pluginId);
    this.zoomPluginAction.visibility_one_loader = true;
  };

  updateFailOverList = (failOverList, pluginId) => {
    const actionParams = JSON.stringify({ failoverList: failOverList });
    const actionName = 'SetVideoCamerasFailoverList';
    this.getPluginService.createAction(pluginId, actionParams, actionName).subscribe((result) => {
      if (result.response[0].status === 'OK') {
        this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
        this.zoomPluginAction.setActionCount();
      } else {
        this.zoomPluginAction.visibility_one_loader = false;
      }
    });
  };

  updateFailOverValue = (flag) => {
    const actionParams = JSON.stringify({ isEnabled: flag });
    const actionName = 'SetFailoverEnabled';
    this.getPluginService
      .createAction(this.pluginId, actionParams, actionName)
      .subscribe((result) => {
        if (result.response[0].status === 'OK') {
          this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
          this.zoomPluginAction.setActionCount();
        } else {
          this.zoomPluginAction.visibility_one_loader = false;
        }
      });
  };
}
