import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-teams-room-path-detection',
  templateUrl: './teams-room-path-detection.component.html',
  styleUrls: ['./teams-room-path-detection.component.scss']
})
export class TeamsRoomPathDetectionComponent implements OnInit, OnChanges {
  @Input() cloudUserStats: any;
  callSimulatorData: any = [];
  shouldShowHeader: boolean = true;
  expandPanel: boolean = false;
  sliderUpImg: string = '../../../../../assets/img/vs_img/slide-up-black.png';
  sliderDownImg: string = '../../../../../assets/img/vs_img/slide-down-black.png';
  pathStatus: string = 'N/A';
  isReady: boolean = false;
  pathIp: string = null;
  serviceType: string;
  computerHop = [
    {
      address: 'computer',
      name: 'computer',
      color: 'green',
    },
  ];

  zoomHop = [
    {
      address: 'zoom',
      name: 'zoom',
      color: 'green',
    },
  ];

  constructor() { }

  ngOnInit() { }

  openPanel = () => {
    this.shouldShowHeader = false;
  };

  collapsePanel = () => {
    this.expandPanel = false;
    this.shouldShowHeader = true;
  };

  ngOnChanges(changes: SimpleChanges) {
    if (this.cloudUserStats && this.cloudUserStats.zoom_plugin_data && this.cloudUserStats.plugin_status == 1) {
      this.serviceType = this.cloudUserStats.service_type;
      this.formatCallSimulatorData();
      if (
        this.pathIp === null &&
        (this.cloudUserStats.liveMeetings === 1 || this.cloudUserStats.liveMeetings === 2) &&
        this.callSimulatorData.length > 0
      ) {
        this.pathIp = this.callSimulatorData[0].ip_address;
        this.tabOnChange(this.pathIp);
      }
    }
  }

  formatCallSimulatorData() {


    if (this.serviceType === 'teams') {
      if (this.cloudUserStats.zoom_plugin_data.callSimulator.teams !== null) {
        const callSimulator = this.cloudUserStats.zoom_plugin_data.callSimulator.teams.results || []
        this.callSimulatorData = this.arrangeCallSimulatorData(callSimulator)
      } else {
        this.pathStatus = 'N/A';
        this.callSimulatorData = [];
      }
    }

    if (this.serviceType === 'zoom') {
      if (this.cloudUserStats.zoom_plugin_data.callSimulator.zoom !== null) {
        const callSimulator = this.cloudUserStats.zoom_plugin_data.callSimulator.zoom.results || []
        this.callSimulatorData = this.arrangeCallSimulatorData(callSimulator)
      } else {
        this.pathStatus = 'N/A';
        this.callSimulatorData = [];
      }
    }
    if (this.cloudUserStats.liveMeetings === 0) {
      this.pathStatus = 'N/A';
      this.callSimulatorData = [];
    }
  }

  arrangeCallSimulatorData = (params) => {
    let addIp = params?.map(e => {
      return {
        ...e,
        ip_address: Object.keys(e)[1],
        name: null,
        path_detection_status: "Good" // hard to calculate
      }
    })

    let newArr2 = addIp?.map(e => {
      return {
        ...e,
        data: this.getDataForSimulator(e)
      }
    })

    return newArr2 || []
  }

  getDataForSimulator = (params) => {
    const objKey = params.ip_address

    const arrangeData = params[`${objKey}`].map(e => {
      //can calculate later
      return {
        ...e,
        color: this.getDataSimulatorColor(e),
        text: "Latency[" + e.latencyVal + "] Jitter[" + e.jitterVal + "] Packetloss[" + e.lossVal + "]",
        name: null
      }
    })
    return arrangeData;
  }

  getDataSimulatorColor = (params) => {
    const { latencyVal,jitterVal, lossVal } = params;
    if( lossVal == 100) {
      return "grey"
    }

    if( lossVal >= 6 ) {
      return "red"
    }

    if( lossVal >= 2 &&   lossVal <=5 ) {
      return "yellow"
    }

    return "green"
  }

  sliderDown = (status) => {
    if (status === 'Warning') {
      this.sliderDownImg = '../../../../../assets/img/vs_img/slide-down-yellow.png';
    }

    if (status === 'Alert') {
      this.sliderDownImg = '../../../../../assets/img/vs_img/slide-down-red.png';
    }

    if (status === 'Good') {
      this.sliderDownImg = '../../../../../assets/img/vs_img/slide-down-black.png';
    }
  };

  sliderUp = (status) => {
    if (status === 'Warning') {
      this.sliderUpImg = '../../../../../assets/img/vs_img/slide-up-yellow.png';
    }

    if (status === 'Alert') {
      this.sliderUpImg = '../../../../../assets/img/vs_img/slide-up-red.png';
    }

    if (status === 'Good') {
      this.sliderUpImg = '../../../../../assets/img/vs_img/slide-up-black.png';
    }
  };

  tabOnChange(ip) {
    this.pathIp = ip;
    const status = this.callSimulatorData.filter((e) => e.ip_address === ip);
    if (status) {
      this.pathStatus = status[0].path_detection_status;
      this.sliderDown(status[0].path_detection_status);
      this.sliderUp(status[0].path_detection_status);
    }
  }
}
