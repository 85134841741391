<div class="ping">
  <div class="ping__title">
    <h5>Last 24hrs Historic Ping Status</h5>
  </div>

  <div class="ping__stats_ctr">
    <div class="ping__stats_item" (click)="openHistoricPingStatusChart('worstRtd')">
      <div class="ping__stats_item_data">
        <h4>{{ worstRtd }}</h4>
        ms
      </div>
      <div class="ping__stats_item_label">Worst RTD</div>
    </div>

    <div class="ping__stats_item">
      <div class="ping__stats_item_data">
        <h4>{{ medianRtd }}</h4>
        ms
      </div>
      <div class="ping__stats_item_label">Median RTD</div>
    </div>
    <div class="ping__stats_item">
      <div class="ping__stats_item_data">
        <h4>{{ bestRtd }}</h4>
        ms
      </div>
      <div class="ping__stats_item_label">Best RTD</div>
    </div>
    <div class="ping__stats_item">
      <div class="ping__stats_item_data">
        <h4>{{ packetLossRtd }}</h4>
        %
      </div>
      <div class="ping__stats_item_label">Packet Loss</div>

    </div>
  </div>
</div>

<!-- 
<div class="ping">
  <div class="ping__title">
    <h5>Last 24hrs Historic Ping Status</h5>
  </div>

  <div class="ping__stats_ctr">
    <div class="ping__stats_item" (click)="openHistoricPingStatusChart('worstRtd')">
      <div class="ping__stats_item_data">
        <h4>{{ worstRtd }}</h4>
        ms
      </div>
      <div class="ping__stats_item_label">Worst RTD</div>
    </div>

    <div class="ping__stats_item" (click)="openHistoricPingStatusChart('medianRtd')">
      <div class="ping__stats_item_data">
        <h4>{{ medianRtd }}</h4>
        ms
      </div>
      <div class="ping__stats_item_label">Median RTD</div>
    </div>
    <div class="ping__stats_item" (click)="openHistoricPingStatusChart('bestRtd')">
      <div class="ping__stats_item_data">
        <h4>{{ bestRtd }}</h4>
        ms
      </div>
      <div class="ping__stats_item_label">Best RTD</div>
    </div>
    <div class="ping__stats_item" (click)="openHistoricPingStatusChart('packetRtd')">
      <div class="ping__stats_item_data">
        <h4>{{ packetLossRtd }}</h4>
        %
      </div>
      <div class="ping__stats_item_label">Packet Loss</div>
    </div>
  </div>
</div> -->