import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { GetCompanyService } from '../../services/get-company.service';
import { PopupTypeService } from '../../services/popupType.service';
import { AlertService } from 'src/app/_alert/alert.service'
import { InputValidationService } from 'src/app/services/input-validation.service'

@Component({
  selector: 'app-create-site',
  templateUrl: './create-site.component.html',
  styleUrls: ['./create-site.component.scss'],
})
export class CreateSiteComponent implements OnInit, OnDestroy {
  @Input() popUpEmitter: any;
  userIndex: any = -1;
  popupType: number;
  setoverlay: String = '';
  firstname: any = '';
  lastname: any = '';
  email: any = '';
  phone: any = '';
  user_id: any = -1;
  state: any = '';
  siteDetails: any;
  allCountryListData: any = [];
  allStatesListData: any = [];

  siteForm: FormGroup;
  submitted = false;
  model: any = {};

  company_id: number;
  passfeedback: any;
  passfeedback2: any;
  stateIndex;

  getId: any;
  getValue: any;
  userFirstname: any;
  userLastname: any;
  userEmail: any;
  userPhone: any;
  getIndex: any = -1;
  fname: any;

  userApiData: any = [];

  wellnessCheckTime: any = { hour: 1, minute: 30, second: 0 };
  wellnessCheckEnable: any = false;
  wellnessConvertedTime: any;
  showWellnessComponent: any = false;
  enableInProduction: any = environment.config.enableInProduction;
  showWellnessInProd: any = false;
  companyId: any;

  searchObject = {
    searchValue: '',
    searchIncident: '',
    startDate: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() - 7,
    },
    endDate: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    },
    startTime: { hour: 0, minute: 0, second: 0 },
    endTime: { hour: 23, minute: 59, second: 0 },
  };

  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private router: Router,
    private ds: PopupTypeService,
    private formBuilder: FormBuilder,
    private getCompanyService: GetCompanyService,
    protected alertService: AlertService,
    private inputValidationService: InputValidationService
  ) {}

  ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // this.ds.sendPopupType(0);
    this.getCountryData();
    this.getUserApiData();
    this.checkForm();
    this.onChanges();
    this.getCompanyId();
  }

  setWellnessProd = (enableInProd, companyId) => {
    return enableInProd && (companyId == 15 || companyId == 36);
  };

  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.companyId = decodedToken.company_id;
  };

  getCountryData() {
    this.Auth.getAllCountryList().subscribe((data) => {
      this.allCountryListData = data['response'];

      // console.log('this.allCountryListData: ', this.allCountryListData)
    });
  }

  setCountry(n) {
    this.getStatesData(n);
    (<HTMLInputElement>document.getElementById('zip')).value = '';
  }

  getStatesData(n) {
    this.Auth.getAllStatesList(n).subscribe((data) => {
      this.allStatesListData = data['response'];
    });
  }

  get f() {
    return this.siteForm.controls;
  }

  checkForm() {
    this.siteForm = this.formBuilder.group({
      sitename: ['', [Validators.required, Validators.maxLength(30)]],
      country: ['', [Validators.required, Validators.maxLength(30)]],
      address1: ['', [Validators.required, Validators.maxLength(50)]],
      city: [
        '',
        [
          Validators.required,
          Validators.pattern('[a-zA-Z ]*'),
          Validators.maxLength(30),
        ],
      ],
      state: ['', [Validators.required, Validators.maxLength(20)]],
      zip: ['', [Validators.required , Validators.maxLength(11) , Validators.minLength(3), this.inputValidationService.postalCodeValidator]],

      firstname: ['', [Validators.required, Validators.maxLength(30)]],
      lastname: ['', [Validators.required, Validators.maxLength(30)]],
      email: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(50)],
      ],
      phone: ['', [Validators.required, Validators.maxLength(25), this.inputValidationService.customPhoneValidator]],
      user_id: ['', [Validators.required, Validators.maxLength(30)]],
    });
  }

  onSubmit() {
    (<HTMLInputElement>document.getElementById('feedback')).innerHTML = '';
    this.submitted = true;
    this.siteForm.updateValueAndValidity;
    // stop here if form is invalid
    if (this.siteForm.invalid) {
      return;
    } else {
      this.registerSite();
    }
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }

  // This will Cloud User Data from server API
  getUserApiData() {
    // this.userApiData = [];
    this.Auth.fetchContactList().subscribe((data) => {
      this.userApiData = data['response'];

      let arr = [];
      for (let d in this.userApiData) {
        arr.push(this.userApiData[d]);
      }
    });
  }

  registerSite() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    this.wellnessConvertedTime = this.wellnessCheckTime;
    const propertyNames = Object.values(this.wellnessConvertedTime).join(':');
    this.wellnessConvertedTime = propertyNames;

    const registrationData = {
      company_id: companyId,
      data: {
        contact: {
          user_id: (<HTMLInputElement>document.getElementById('user_id')).value,
          firstname: (<HTMLInputElement>document.getElementById('firstname'))
            .value,
          lastname: (<HTMLInputElement>document.getElementById('lastname'))
            .value,
          email: (<HTMLInputElement>document.getElementById('email')).value,
          phone: (<HTMLInputElement>document.getElementById('phone')).value,
        },
        site: {
          sitename: (<HTMLInputElement>document.getElementById('sitename'))
            .value,
          country: (<HTMLInputElement>document.getElementById('country')).value,
          address1: (<HTMLInputElement>document.getElementById('address1'))
            .value,
          address2: (<HTMLInputElement>document.getElementById('address2'))
            .value,
          city: (<HTMLInputElement>document.getElementById('city')).value,
          state: (<HTMLInputElement>document.getElementById('state')).value,
          zip: (<HTMLInputElement>document.getElementById('zip')).value,
          wellnessCheck: this.wellnessCheckEnable
            ? this.wellnessConvertedTime
            : null,
        },
      },
    };

    // console.log('registrationData: ', registrationData)

    this.Auth.createNewSite(registrationData).subscribe((data: any) => {
      console.log('data: ', data);
      this.passfeedback = <HTMLInputElement>document.getElementById('feedback');
      if (data.response[0] === undefined) {
      } else if (data.response[0].status === 'OK') {
        this.ds.sendPopupType(99);
        this.popUpEmitter('refreshGetSiteApiData');
        this.passfeedback.innerHTML = data.response[0].message;
        this.alertService.success(data.response[0].message , {
            autoClose: true,
            keepAfterRouteChange: false
          })
      } else {
        if (data.response[0].status === 'Error') {
          this.passfeedback.innerHTML = data.response[0].message;
        }
      }
    });
  }

  onChangeWellnessCheckValue = (event) => {
    var value = 0;
    if (event?.target?.checked) {
      value = 1;
    }
    this.wellnessCheckEnable = value;
  };

  setIndex(n) {
    this.clearInputs();
    this.userIndex = n;
    this.getIndex = (<HTMLInputElement>(
      document.getElementById('add_contact')
    )).value;
    if (n == 'add') {
      this.userIndex = -1;
      this.user_id = -1;
    } else if (n >= 0) {
      this.getValue = (<HTMLInputElement>(
        document.getElementById('add_contact')
      )).value;

      this.firstname = this.userApiData[n].first_name;
      this.lastname = this.userApiData[n].last_name;
      this.email = this.userApiData[n].email;
      this.phone = this.userApiData[n].phone;
      this.user_id = this.userApiData[n].user_id;
    }
  }

  clearInputs() {
    this.firstname = '';
    this.lastname = '';
    this.email = '';
    this.phone = '';
    this.user_id = -1;
    this.passfeedback = '';
  }

  //weird
  onChanges() {
    this.siteForm.get('user_id').valueChanges.subscribe((selectedUserId) => {
      if (selectedUserId == -1) {
        this.siteForm.get('firstname').enable();
        this.siteForm.get('lastname').enable();
        this.siteForm.get('email').enable();
        this.siteForm.get('phone').enable();
        // console.log('selectedUserId enable', selectedUserId);
      } else {
        // this.siteForm.get('firstname').reset();
        this.siteForm.get('firstname').disable();
        this.siteForm.get('lastname').disable();
        this.siteForm.get('email').disable();
        this.siteForm.get('phone').disable();
        // console.log('selectedUserId disable', selectedUserId);
      }
    });
  }
}
