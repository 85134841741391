<h4 class="text-center mt-2 mb-0">
    {{ listTitle }}
  </h4>
  <div class="rainBot"></div>
  <div *ngIf = "!isZoom">
    <table class="table table-hover table-sm bg-white mt-2 mb-2" style="padding-bottom:100px;">
      <thead>
        <tr>
          <th class="text-left">Date</th>
          <th class="text-center">Time</th>
          <th class="text-center">Site</th>
          <th class="text-center">Impacted</th>
          <th class="text-center">Description</th>
          <th class="text-center">Duration(Mins)</th>
          <th class="text-center">IP</th>
          <th class="text-center">Incident ID</th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row" *ngFor="let reportIncident of incidentList" style="background:none;">
          <td class="text-left">{{reportIncident.date}}</td>
          <td class="text-left">{{reportIncident.time}}</td>
          <td class="text-center">{{reportIncident.site}}</td>
          <td class="text-center">{{reportIncident.impacted}}</td>
          <td class="text-center">{{reportIncident.description}}</td>
          <td class="text-center" step='0.01' >{{reportIncident.duration/60 | twoDecimals}}</td>
          <td class="text-center">{{reportIncident.ip}}</td>
          <td class="text-center">{{reportIncident.alert_id}}</td>
        </tr>
      </tbody>
    </table>
  </div>
      <div *ngIf = "isZoom">
        <table class="table table-hover table-sm bg-white mt-2 mb-2" style="padding-bottom:100px;">
          <thead>
            <tr>
              <th class="text-left">Date</th>
              <th class="text-center">Time</th>
              <!-- <th class="text-center">Huddle</th> -->
              <th class="text-center">Impacted Room</th>
              <th class="text-center">Description</th>
              <th class="text-center">Duration</th>
              <!-- <th class="text-center">IP</th> -->
              <th class="text-center">Incident ID</th>
            </tr>
          </thead>
          <tbody>
            <tr scope="row" *ngFor="let reportIncident of incidentList" style="background:none;">
              <td class="text-left">{{reportIncident.date}}</td>
              <td class="text-center">{{reportIncident.time}}</td>
              <!-- <td class="text-center">{{reportIncident.site}}</td> -->
              <td class="text-center">{{reportIncident.room_name}}</td>
              <td class="text-center">{{reportIncident.description}}</td>
              <td class="text-center">{{reportIncident.duration/60}}</td>
              <!-- <td class="text-center">{{reportIncident.ip}}</td> -->
              <td class="text-center">{{reportIncident.zoom_incident_id}}</td>
            </tr>
          </tbody>
        </table>
  
  </div>