<div class="cloud-user-path-detection">
  <div class="cloud-user-path-detection__header">
    <h5>Path Detection</h5>

    <div class="cloud-user-path-detection__status-button-container">
      <div class="cloud-user-path-detection__status">
        Network Path Status
      </div>
      <div [ngClass]="{
          'cloud-user-path-detection__button--good': pathStatus === 'Good',
          'cloud-user-path-detection__button--bad': pathStatus === 'Alert',
          'cloud-user-path-detection__button--warning': pathStatus === 'Warning',
          'cloud-user-path-detection__button--na': pathStatus === 'N/A'
        }">
        {{ pathStatus }}
      </div>
    </div>
  </div>
  <!-- <hr *ngIf="isUserOnCall" /> -->
  <mat-expansion-panel *ngIf="callSimulatorData.length > 0" hideToggle (opened)="expandPanel = true"
    [expanded]="expandPanel">
    <mat-expansion-panel-header *ngIf="shouldShowHeader" (click)="openPanel()">
      <div class="cloud-user-path-detection__arrow-down">
        <img src="{{ sliderDownImg }}" alt="" />
      </div>
    </mat-expansion-panel-header>
    <div class="cloud-user-path-detection__panel-content">
      <mat-tab-group (selectedTabChange)="tabOnChange($event.tab.textLabel)">
        <mat-tab [label]="c.ip_address" *ngFor="let c of callSimulatorData; let i = index">
          <div *ngFor="let h of c.data">
            <div title="{{ h.text }}" [ngClass]="{
                'content-item-error': h.color === 'red',
                'content-item-warning': h.color === 'yellow',
                'content-item-na': h.color === 'na'
              }" class="cloud-user-path-detection__panel-content-item">
              {{ h.name !== null ? h.name : h.address }}
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <mat-action-row>
      <div class="cloud-user-path-detection__arrow-up" (click)="collapsePanel()">
        <img src="{{ sliderUpImg }}" alt="" />
      </div>
    </mat-action-row>
  </mat-expansion-panel>
</div>