<div class="link-desktop">
	<div class="link-desktop__header">
		<div>Link device to space</div>
		<div (click)="closeDialog(false)" class="link-desktop__header-close-button">
			<img src="./assets/img/iot/delete-btn.png" alt="" />
		</div>
	</div>

	<div class="link-desktop__body link-desktop__body-2">
		<form role="form" class="signup" name="siteForm" [formGroup]="form" (ngSubmit)="onSubmit()">
			<div class="text-center mb-2" [ngClass]="color" *ngIf="message">{{message}}</div>

			<div class="form-group position-relative">
				<!-- <select
					type="text"
					formControlName="space_id"
					class="form-control"
					placeholder="Space"
					name="space_id"
					id="space_id"
					[ngClass]="{ 'is-invalid': f.space_id.touched && f.space_id.errors }"
					autocomplete="space_id"
					required
				>
					<option value="" selected disabled>Select Space</option>
					<option *ngFor="let space of spaces; let i = index" value="{{ space.space_id }}">
						{{
            space.space_name }}
					</option>
				</select>
				<i class="fa fa-times position-absolute cross text-danger" *ngIf="f.space_id.value" (click)="clear()"></i> -->
				<mat-form-field>
					<mat-label>Select Space</mat-label>
					<mat-select formControlName="space_ids" multiple>
							<mat-option *ngFor="let space of spaces; let i = index" [value]="space.space_id" [disabled]="disable(space)">{{
								space.space_name }}</mat-option>
					</mat-select>
				</mat-form-field>
				<!-- <div *ngIf="f.space_ids.touched && f.space_ids.errors" class="invalid-feedback">
					<div *ngIf="f.space_ids.touched && f.space_ids.errors.required" id="confirmMessage">!</div>
				</div> -->
			</div>
			<div class="link-desktop__button-container">
				<button type="submit" class="link-desktop__button-item link-desktop__button-item--yes" 
				[ngClass]="isButtonDisabled?'link-desktop__no-event':''">Submit</button>
				<div class="link-desktop__button-item link-desktop__button-item--cancel" (click)="closeDialog(false)">X Cancel</div>
				<div class="link-desktop" *ngIf="loading">
					<img class="image" src="{{ btnLoader }}" alt="" />
				</div>
			</div>
		</form>
	</div>
</div>
