import { Component, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import {
  faUser, IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-btn-info',
  templateUrl: './btn-info.component.html',
  styleUrls: ['./btn-info.component.scss'],
})
export class BtnInfoComponent implements OnChanges, OnDestroy {
  @Input() label: string = 'Default';
  @Input() faName: any = faUser;
  @Input() isbutton: string;
  @Input() showLoader: any;
  icon: any = faUser;
  constructor() {
  }

  ngOnDestroy() {
    // this.showLoader;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showLoader) {
      this.showLoader = changes.showLoader.currentValue === '1' ? true : false;
    }
    if (changes.count) {
      this.faName = changes.faName.currentValue;
      this.icon = ['far', this.faName]
      // console.log(changes);
    }
    if (changes.total) {
      this.label = changes.label.currentValue;
    }
  }
}
