import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GetCloudService } from '../../../services/get-cloud.service';
import { RefreshApiService } from '../../../services/refresh-api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cloud-main',
  templateUrl: './cloud-main.component.html',
  styleUrls: ['./cloud-main.component.scss'],
})
export class CloudMainComponent implements OnInit, OnDestroy {
  id: number;
  companyId: number;
  cloudDeviceData: any;
  filterListner: Subscription;
  isShowContent = false;
  displayStringForTesting: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getCloudDataService: GetCloudService,
    private refreshApiService: RefreshApiService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });

    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.companyId = decodedToken.company_id;

    this.getCloudApiData();
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if (m) {
        this.getCloudApiData();
      }
    });
  }

  getCloudApiData() {
    this.getCloudDataService.fetchApiCloudRoomDetails(this.id).subscribe((data) => {
      this.cloudDeviceData = data['response'][0];
      this.displayStringForTesting = JSON.stringify(this.cloudDeviceData);
      this.isShowContent = true;
    });
  }

  triggerEvent($event) {
    if ($event === 'refreshDeviceDetails') {
      this.getCloudApiData();
    }
  }

  ngOnDestroy() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
  }
}
