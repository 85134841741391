<div class="add-iot-domotz">
    <div class="add-iot-domotz__header">
        <div class="add-iot-domotz__title">
            Add IoT Devices
        </div>
        <img class="add-iot-domotz__device-button" src="../../../assets/img/iot/delete-btn.png" alt="" autofocus
            (click)="onCancel()" />
    </div>

    <div class="add-iot-domotz__search-bar">
        <input #searchKey type="text" class="search-default" type="text" placeholder="Search ..." />
        <div class="add-iot-domotz__icon">
            <i class="fa fa-search"></i>
        </div>
    </div>

    <div class="add-iot-domotz__device">
        <div class="add-iot-domotz__device-button-container" *ngFor="let c of iotLiteList | appFilter: searchKey.value">
            <div class="add-iot-domotz__device-item">
                {{ c.mac_address == "null" ? "" : c.mac_address }} -- {{ c.iot_name }} -- {{ c.make == "Unknown" ? "" :
                c.mac_address }}
            </div>
            <img src="{{ getIotDeviceSelectedListStatus(c.selected) }}" alt=""
                (click)="getIotDeviceNameAndId(c.iot_id, c.mac_address)" />
        </div>
    </div>

    <div class="add-iot-domotz__footer">
        <div class="add-iot-domotz__footer__buttons-container">
            <button mat-button (click)="onCancel()">Cancel</button>
            <button mat-button (click)="linkDevice()">Save</button>
            <img src="{{ btnLoader }}" alt="" *ngIf="isLoading" />
        </div>
        <div class="add-iot-domotz__footer__message-container">
            {{ displayMessage }}
        </div>
    </div>
</div>