import { PopupTypeService } from 'src/app/services/popupType.service'

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { JwtHelperService } from '@auth0/angular-jwt'

import { AuthService } from '../../services/auth.service'
import { GetIotService } from '../../services/get-iot.service'
import { IotLiteDomotzErrorMessageComponent } from '../dialogs/iot-lite-domotz-error-message/iot-lite-domotz-error-message.component'

@Component({
  selector: 'app-iot-device-account-config',
  templateUrl: './iot-device-account-config.component.html',
  styleUrls: ['./iot-device-account-config.component.scss'],
})
export class IotDeviceAccountConfigComponent implements OnInit, OnChanges, OnDestroy {
  @Input() popupState: any;
  activePanel: Number = 1;
  shouldShowMsg: Boolean = false;
  msg: String = 'IoT API/Name/URL Key Updated.';
  msgColor: String = 'success_msg';
  domotzAccount: any;
  enabled: any = 0;
  notification: any = 0;
  account_id: 0;
  mspDomotzEnabled: any;
  companyDetails: any;
  iotLiteEnabled: any = false;


  constructor(
    private GetIot: GetIotService,
    public Auth: AuthService,
    public dialog: MatDialog,
    public ds: PopupTypeService,
  ) { }

  ngOnInit() {
    this.getIotLiteStatus();
    this.getMSPDomotzStatus();
    this.getCompanyInfo();
    this.getDomotzAccountByCompanyId();
  }

  getCompanyInfo() {
    this.Auth.getCompanyInfo({ company_id: this.getCompanyId() }).subscribe((data) => {
      let [res] = data.response;
      this.companyDetails = res;
    });
  }

  ngOnDestroy() { }

  getCompanyId() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    return decodedToken.company_id;
  }

  disableDomotzSlider: any = false;

  getDomotzAccountByCompanyId = () => {
    this.GetIot.getDomotzAccountByCompanyId().subscribe((data: any) => {
      // console.log('getDomotzAccountByCompanyId: ', data)
      this.domotzAccount = data;
      // console.log(this.domotzAccount.domotz_account_id)
      this.enabled = this.domotzAccount.enabled;
      this.notification = this.domotzAccount.send_notification;
      this.account_id = this.domotzAccount.domotz_account_id;
      if(this.account_id) {
        this.disableDomotzSlider = true;
      }
    });
  };

  getCompanyIdDetails = () => {

    const { company_parent } = this.companyDetails;
    if (company_parent !== null) {
      let JsonRes = JSON.parse(company_parent)
      // console.log(JsonRes)
      return JsonRes.company_id
    } else {
      return this.getCompanyId();
    }
  }

  ngOnChanges(changes: SimpleChanges) { 
    
  }

  getMSPDomotzStatus = () => {
    this.GetIot.getMSPDomotzStatus().subscribe(
      (data) => {
        this.mspDomotzEnabled = data;
      },
      (error) => { }
    );
  };

  ngOnChange({ eventType, cb }) {
    if (eventType === 'boolSuccess') {
			this.msgConfig('IoT API/Name/URL Key Updated.', 'success_msg')
			return
		}
    if (eventType === 'boolErr') {
			this.msgConfig('IoT API/Name/URL Key Invalid.', 'err_msg')
			return
		}
    if (eventType === 'getDomotzAccount') {
			this.getDomotzAccountByCompanyId()
			return
		}

    if (eventType === 'openModal') {
			// this.enabled = false
			this.openModalWarnMessage(cb)
			return
		}
  }

  msgConfig = (text, color) => {
    this.shouldShowMsg = true;
    this.msg = text;
    this.msgColor = color;
    setTimeout(() => {
      this.shouldShowMsg = false;
    }, 2000);
  };

  // setEnabled = () => {
  //   if (this.iotLiteEnabled) {
  //     this.enabled = false;
  //     this.openModalErrorMessage();
  //   } else {
  //     const flag = this.setFlag(this.enabled);
  //     this.GetIot.setDomotzAccountEnabled(this.account_id, flag).subscribe(
  //       (data: any) => { 
  //         this.getDomotzAccountByCompanyId();
  //       },
  //       (error) => { }
  //     );
  //   }

  // };
  
  setEnabled = (e) => {
    e.preventDefault()
		const flag = this.enabled == 1 ? '0' : '1'
    if (this.companyDetails?.has_selected_iot_not_domotz === null && this.enabled === 0)
      this.openModalWarnMessage(this.updateDomotzEnabledStatus.bind(this, '1'))
    else
      this.updateDomotzEnabledStatus(flag)
	}

  updateDomotzEnabledStatus(flag) {
    this.GetIot.setDomotzAccountEnabled(this.account_id, flag).subscribe((data: any) => {
      this.enabled = +flag
    },
    error => { })
  }

  setNotification = () => {
    const flag = this.setFlag(this.notification);
    this.GetIot.setDomotzAccountNotification(this.account_id, flag).subscribe(
      (data) => { },
      (error) => { }
    );
  };

  setFlag = (flag) => (flag ? 0 : 1);

  selectActivePanel = (opt) => (this.activePanel = opt);

  getIotLiteStatus = () => {
    this.GetIot.getIotLiteStatus().subscribe((data) => {
      let [res] = data.response;
      this.iotLiteEnabled = res.is_iot_lite;

      // console.log('ioenlb: ', this.iotLiteEnabled);
    });
  }

  // openModalErrorMessage = () => {
  //   const config = 'Disable IoT to enable Domotz Account'
  //   const webportal = this.dialog.open(IotLiteDomotzErrorMessageComponent, {
  //     panelClass: 'custom-dialog-container',
  //     data: {
  //       config,
  //     },
  //   });
  // };

  openModalWarnMessage = (cb) => {
		// const config = 'Disable Domotz account to enable IoT'
		const config = 'Enabling Domotz will disable the IoT service permanently. You will no longer be able to use IoT. Do you want to proceed?'
		const dialogRef = this.dialog.open(IotLiteDomotzErrorMessageComponent, {
			panelClass: 'custom-dialog-container',
			data: {
        config,
        showButton: true
			},
		})

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
        this.GetIot.setHasSelectedIotNotDomotz('0').subscribe(() => {
          this.companyDetails.has_selected_iot_not_domotz = 0
          cb()
        })
			}
		})
  }
  openPopup(p) {
    this.ds.sendPopupType(p,{
			isDomotz:true
		});
  }

}
