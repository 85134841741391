<!-- <div class="container-fluid" id="main-box" [ngStyle]="visibilityBackground">
  <div class="row">
    <div class="col-12">
      <form
        role="form"
        class="signup"
        name="resetForm"
        [formGroup]="resetForm"
        (ngSubmit)="onSubmit()"
      >
        <img [src]="dashboardLogo" />

        <div *ngIf="valid == 0">
          <p><br />Invalid Link</p>
        </div>
        <div *ngIf="valid == 1">
          <ng-container *ngIf="!sentRequest">
            <p><br />Password reset</p>
            <div class="form-group">
              <input
                readonly
                type="email"
                formControlName="email"
                class="form-control"
                placeholder="email"
                name="email"
                id="email"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                placeholder="Email"
                autocomplete="email"
                [(ngModel)]="email"
                required
              />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div
                  *ngIf="f.email.errors.maxlength"
                  class="password-text alert alert-danger p-2 m-0"
                >
                  Exceed Maximum 50 character
                </div>
                <div *ngIf="f.email.errors.required">email is required</div>
                <div *ngIf="f.email.errors.required">!</div>
              </div>
            </div>
            <div class="form-group">
              <input
                type="password"
                formControlName="password"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                id="password"
                placeholder="Password"
                autocomplete="new-password"
                (keyup)="checkPass(false)"
                required
              />
              <div *ngIf="f.password.errors && matched == false" class="alert-danger">
                Minimum of 8 character password
              </div>
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">!</div>
              </div>
            </div>
            <div class="form-group">
              <input
                type="password"
                formControlName="password2"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.password2.errors }"
                id="password2"
                placeholder="Confirm password"
                autocomplete="new-password"
                (keyup)="checkPass()"
                style="color: black !important;"
                required
              />
              <div
                *ngIf="submitted && matched == false"
                class="alert-danger"
                style="left: 135px; bottom: 180px;"
              >
                Password mismatch
              </div>
              <div *ngIf="submitted && f.password2.errors" class="invalid-feedback">
                <div *ngIf="f.password2.errors.required" id="confirmMessage">
                  !
                </div>
              </div>
            </div>
            <p id="feedback" class="feedback text-center text-danger"></p>
            <button type="submit" class="btn-primary" style="width: 100%;">
              submit
            </button>
          </ng-container>
        </div>
        <ng-container *ngIf="sentRequest">
          <div>
            <div *ngIf="resetResponseData?.status == 200 && resetResponseFailed == 0">
              <p style="text-align: center; font-size: 20px; color: green !important;">
                <br />Password has been reset
              </p>
              <a routerLink="{{ loginLink }}">Click here to Login</a>
            </div>
            <p
              *ngIf="resetResponseData?.status != 200"
              style="text-align: center; font-size: 20px; color: red !important;"
            >
              <br />Invalid request
            </p>
            <p
              *ngIf="resetResponseFailed == 1 && resetResponseData?.status == 200"
              style="text-align: center; font-size: 20px; color: red !important;"
            >
              <br />{{ resetResponseMessage }}
            </p>
          </div>
        </ng-container>
        <br />
        <div *ngIf="showPoweredBy" class="poweredby">
          Powered by VisibilityOne
        </div>
      </form>
    </div>
  </div>
</div> -->

<div class="reset" [ngStyle]="visibilityBackground">
  <div class="reset__col">
    <div class="reset__box" *ngIf="resetEmail.flag">
      <img [src]="dashboardLogo" />
      <div>
        <div class="reset__title">
          Password reset
        </div>

        <div>
          <input
            class="default disable-btn"
            type="text"
            placeholder="email"
            value="{{ resetEmail.value }}"
          />
        </div>

        <div>
          <input
            class="{{ formControl.password.style }}"
            type="password"
            placeholder="password"
            value="password"
            [(ngModel)]="password"
            (keyup)="keyEvent($event, 'password')"
          />

          <div *ngIf="formControl.password.flag" class="error-msg">
            {{ formControl.password.message }}
          </div>
        </div>

        <div>
          <input
            class="{{ formControl.confirm.style }} "
            type="password"
            placeholder="confirm password"
            value=" confirm"
            [(ngModel)]="confirm"
            (keyup)="keyEvent($event, 'confirm')"
          />

          <div *ngIf="formControl.confirm.flag" class="error-msg">
            {{ formControl.confirm.message }}
          </div>
        </div>

        <button
          class="reset__btn-primary"
          [ngClass]="{
            'disable-btn': disableBtn
          }"
          (click)="validateForm()"
        >
          Submit
        </button>
      </div>
    </div>

    <div class="reset__box" *ngIf="!resetEmail.flag">
      <div *ngIf="!resetEmail.success">
        <img [src]="dashboardLogo" />

        <div class="reset__invalid-link">
          {{ resetEmail.value }}
        </div>
      </div>

      <div *ngIf="resetEmail.success">
        <img [src]="dashboardLogo" />

        <div class="reset__password-reset">
          Password has been reset
        </div>

        <button class="reset__btn-primary" routerLink="/login">
          Return to login
        </button>
      </div>
    </div>
  </div>
</div>
