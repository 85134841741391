<div class="cloud-user-utilization">
  <h5>Utilization</h5>
  <div class="cloud-user-utilization__two-column">
    <app-btn-reg-stats [count]="cloudUserUtilization?.total_teams_meetings_count" label="Total Calls This Month"
      color="gray" (click)="
        ds.sendPopupType(
          39,
          {
            deviceId: cloudUserUtilization.cloudUserId,
            meetings: cloudUserUtilization?.past_meetings
          },
          hasAccess
        )
      " [isbutton]="isNotButton"></app-btn-reg-stats>
    <app-btn-reg-stats [count]="cloudUserUtilization?.totalRecordings" label="Recordings" color="gray" (click)="
        ds.sendPopupType(
          10,
          {
            deviceId: cloudUserUtilization.cloudUserId,
            meetings: cloudUserUtilization?.recordings
          },
          hasAccess
        )
      " [isbutton]="isNotButton"></app-btn-reg-stats>
  </div>
</div>