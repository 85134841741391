import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MembersTypeService } from '../../services/membersType.service';
import { PopupTypeService } from './../../services/popupType.service';
import { RefreshApiService } from './../../services/refresh-api.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';
import { AuthService } from './../../services/auth.service';
import { environment } from '../../../environments/environment';

import * as _ from 'lodash';

@Component({
  selector: 'app-collector-offline-notifications',
  templateUrl: './collector-offline-notifications.component.html',
  styleUrls: ['./collector-offline-notifications.component.scss'],
})
export class CollectorOfflineNotificationsComponent implements OnInit, OnDestroy {
  @Output() closeReleaseNotesEvent: EventEmitter<any> = new EventEmitter<any>();
  dCollector = environment.config.dynamicText.dCollector;

  filterListner: Subscription;
  loading: boolean = false;
  notification: any;
  lastUpTime: any;

  constructor(
    private ds: MembersTypeService,
    public ds2: PopupTypeService,
    private Auth: AuthService,
    private refreshApiService: RefreshApiService
  ) {
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if (m) {
        this.getOfflineCollectors();
      }
    });
  }

  ngOnInit() {
    this.notification = {
      isClose: true,
      message: '',
    };

    this.getOfflineCollectors();
  }

  getOfflineCollectors() {
    this.loading = true;
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    if (decodedToken) {
      const params = {
        company_id: decodedToken.company_id,
      };

      this.Auth.getOfflineCollector(params).subscribe((data) => {
        const resultDataList = data['response'];
        // // console.log('resultDataList: ', resultDataList)

        if (resultDataList.length > 0) {
          this.setSessionNotifData(resultDataList);
          this.notification = this.getSessionNotifData();
        }
      });
    }
  }


  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  removeItem(event: Event): void {
    event.stopPropagation();
    
    this.notification.isClose = true;
    sessionStorage.setItem('collectorOfflineNotification', JSON.stringify(this.notification)); // set new token as close true :)
  }

  setSessionNotifData(collectorList: any): void {
    const sessionNotification = this.getSessionNotifData();
    const sessionOfflineMsg = _.get(sessionNotification, 'message');
    const isCurrentOfflineCollectorsIsMany = collectorList.length > 1;
    let currentCollectorOfflineMsg = '';
    let isClose = false;

    if (isCurrentOfflineCollectorsIsMany) {
      currentCollectorOfflineMsg = this.getMessageForMutipleOfflineCollectors();
    } else if (collectorList.length === 1) {
      currentCollectorOfflineMsg = this.getMessageForSingleOfflineCollector(
        collectorList[0].site_name,
        collectorList[0].last_connect
      );
    } else {
      isClose = true;
    }
    if (sessionOfflineMsg !== currentCollectorOfflineMsg) {
      const notification = {
        isClose,
        message: currentCollectorOfflineMsg,
      };
      sessionStorage.setItem('collectorOfflineNotification', JSON.stringify(notification));
    }
  }

  getSessionNotifData(): any {
    return JSON.parse(sessionStorage.getItem('collectorOfflineNotification'));
  }

  getMessageForSingleOfflineCollector(site_name: string, last_connect: string) {
    // const dataCon = new Date(last_connect);
    // return `Collector at ${site_name} is offline. Last uptime ${dataCon}.`;
    this.lastUpTime = last_connect
    return `Collector at ${site_name} is offline.`;
  }

  getMessageForMutipleOfflineCollectors() {
    return 'Multiple collectors are offline. Please click this banner for more details.';
  }
}
