<div class="collector-logging">
  <div class="text-center">
    <ul class="bottom">
      <li [innerHTML]="snapshotMessage">
      </li>
      <ng-container *ngIf="showDownloading">
        <li>
          <div class="dot1"></div>
        </li>
        <li>
          <div class="dot2"></div>
        </li>
        <li>
          <div class="dot3"></div>
        </li>
      </ng-container>
    </ul>
  </div>

</div>