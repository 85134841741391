<div class="cloud-user-info">
  <div class="cloud-user-info__zoom-img">
    <!-- <img src="{{ getCloudUserLogo(cloudUserInfo) }}" /> -->
    <img src="../../assets/img/vs_img/zoom_logo.png" />
  </div>

  <div class="cloud-user-info__user-img">
    <img [src]="getUserProfilePicture(cloudUserInfo.pic_url)" alt="Cloud User" />
  </div>
  <div class="cloud-user-info__text">
    <div class="cloud-user-info__text-item">
      <strong>User:</strong> {{ cloudUserInfo?.firstname }} {{ cloudUserInfo?.lastname }}
    </div>
    <div class="cloud-user-info__text-item">
      <strong>Email:</strong> {{ nullCheck(cloudUserInfo?.email) }}
    </div>
    <div class="cloud-user-info__text-item">
      <strong>Dial in Details:</strong> {{ nullCheck(cloudUserInfo?.personal_meeting_url) }}
    </div>
  </div>

  <div class="cloud-user-info__button-container">
    <div class="cloud-user-info__switch">
      <label class="switch" [ngStyle]="{ 'opacity': isDisableDesktopClientPluginToggle ? '0.5' : '1' }">
        <input type="checkbox" (change)="desktopClientChange($event)" [checked]="cloudUserInfo?.zoom_plugin_enabled"
          [disabled]="isDisableDesktopClientPluginToggle" />
        <span class="slider round"></span>
      </label>
      <div class="cloud-user-info__switch-text">enable desktop client</div>
    </div>

    <div class="cloud-user-info__switch">
      <label class="switch" [ngStyle]="{ 'opacity': isDisableAlertsToggle ? '0.5' : '1' }">
        <input type="checkbox" (change)="alertsChange($event)" [checked]="cloudUserInfo?.alerts"
          [disabled]="isDisableAlertsToggle" />
        <span class="slider round"></span>
      </label>
      <div class="cloud-user-info__switch-text">enable alerts</div>
    </div>

    <div class="cloud-user-info__gray-button-container">
      <app-btn-info label="admin" (click)="
          ds.sendPopupType(32, {
            alertsId: cloudUserInfo.zoom_user_id,
            alertType: 'cloud',
            data: cloudUserInfo
          })
        " [faName]="faIcon"></app-btn-info>
      <app-btn-info label="reboot" (click)="openRebootPopUp()" [faName]="faIcon"></app-btn-info>
    </div>

    <div class="cloud-user-info__button--" [ngClass]="{
        'cloud-user-info__button-online': userStatus === 'Online',
        'cloud-user-info__button-offline': userStatus === 'Offline'
      }" *ngIf="isZoomPluginEnabled && deskPluginStatus === 1">
      {{ userStatus }}
    </div>
  </div>

  <div class="cloud-user-info__network-utilization">
    <h5>Network Utilization</h5>
    <hr />
    <app-chart-cloud-mbps></app-chart-cloud-mbps>
  </div>

  <div class="cloud-user-info-outer">
    <app-cloud-user-console [isDisable]="!isZoomPluginEnabled || deskPluginStatus !== 1"
      [pluginId]="cloudUserInfo?.plugin_id" [desktopPluginMessage]="desktopPluginMessage"
      [originationIp]="cloudUserInfo?.plugin_ip_address">
    </app-cloud-user-console>
    <div class="cloud-user-info-outer__additional">
      <h4>Additional</h4>
      <div>
        <strong>Timezone:</strong> {{ cloudUserInfo?.timezone ? cloudUserInfo?.timezone : '--' }}
      </div>
    </div>
    <!-- <app-cloud-user-notes></app-cloud-user-notes> -->
  </div>
</div>