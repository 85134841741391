<div class="card">
  <div class="card-body justify-content-between">
    <ng-container>
      <img src="../../assets/img/poly_logo.png" class="img-fluid audio-device-logo" alt="audio-device-brand" />
    </ng-container>
    <div class="row">
      <div class="col-7">
        <p *ngIf="isSuperUser" class="card-text" style="font-weight: bold !important">
          Company Name: {{ audioDeviceData.company_name }}
        </p>
        <p class="card-text">
          Site:
          <a *ngIf="audioDeviceData" [routerLink]="['/audio/site/', audioDeviceData?.site_id]">{{
            audioDeviceData?.site_name }}</a>
          <a *ngIf="!audioDeviceData">{{ audioDeviceData?.location }}</a>
        </p>
        <p class="card-text">Name: {{ audioDeviceData?.room_name }}</p>
        <p class="card-text">User: {{ audioDeviceData?.sip_info?.user }}</p>
        <p class="card-text">Model: {{ audioDeviceData?.model }}</p>
        <p class="card-text">
          IP address: {{ audioDeviceData?.ipv4 }}
          <fa-icon [icon]="faCogIcon" size="2x" class="cog-icon" (click)="openRemoteIp()"></fa-icon>
        </p>
      </div>

      <div class="col-5">
        <ng-container>
          <img *ngIf="audioDeviceData?.device_type === 'trioDevice'" src="../../assets/img/audio/icon_trio.png"
            class="img-fluid audio-device-logo" alt="audio-device-brand" style="width: auto" />
        </ng-container>
      </div>
    </div>

    <div class="row">
      <div class="col-7">
        <app-info-uptime [uptimeDays]="days" [uptimeHours]="hrs" [uptimeMins]="mins"></app-info-uptime>
        <div [ngClass]="{
                'audio-device-info__button-online': audioDeviceStatus === 'Online' || audioDeviceStatus === 'Sleep',
                'audio-device-info__button-offline': audioDeviceStatus === 'Offline' ||  audioDeviceStatus === 'Collector Offline',
                'audio-device-info__button-warning': showWarningStatus && audioDeviceStatus === 'Collector Offline',
                'audio-device-info__button-loading': audioDeviceStatus === 'Teleportivity',
                'audio-device-info__button-pause': audioDeviceStatus === 'Paused'
              }">
              {{ audioDeviceStatus }}
        </div>

      </div>
      <div *ngIf="hasAccess" class="col-5">
        <app-btn-info label="reboot" [faName]="faIcon" (click)="sendPopupType(7)"></app-btn-info>
        <app-btn-info label="{{ audioDeviceData?.paused == 1 ? 'unpause' : 'pause' }}"
          [faName]="audioDeviceData?.paused == 1 ? faPauseIcon : faPlayIcon"
          (click)="setDevicePause(audioDeviceData?.paused)">
        </app-btn-info>
        <app-btn-info label="admin" (click)="
            ds.sendPopupType(32, {
              alertsId: audioDeviceData?.audio_device_id,
              alertType: 'audio',
              siteId: audioDeviceData?.site_id,
              data: audioDeviceData,
              deviceId: audioDeviceData?.audio_device_id
            })
          " [faName]="faIcon"></app-btn-info>
        <!-- <app-btn-info *ngIf="
            audioDeviceData?.online == -1 &&
            (audioDeviceData?.collector_status == 1 || audioDeviceData?.collector_status == 2)
          " label="wakeup" [faName]="faIcon" (click)="
            audioDeviceData?.online == 1
              ? (audioDeviceData.online = 0)
              : (audioDeviceData.online = 1)
          ">
         </app-btn-info> -->
      </div>
    </div>

    <hr style="margin-top: 10px" />
    <app-iot [iotData]="iotData" [iotEvent]="audioEvent"></app-iot>
    <hr style="margin-bottom: 10px" />
    <app-chart-audio60-min-view [audioDeviceId]="audioDeviceData?.audio_device_id" [isOn]="true">
    </app-chart-audio60-min-view>
  </div>
  <div id="additional-info" class="card-body">
    <div class="card-detail">
      <h4>Additional</h4>
      <p>
        <strong>SIP info: </strong>
        {{ sipInfoTrim(audioDeviceData?.sip_info?.server) }}
      </p>
      <p>
        <strong>Registration Status: </strong>
        {{
        audioDeviceData?.sip_info?.status
        ? audioDeviceData?.sip_info?.status
        : "--"
        }}
      </p>
      <p>
        <strong>VLAN ID: </strong>
        {{
        audioDeviceData?.network_info?.vlan_id
        ? audioDeviceData?.network_info?.vlan_id
        : "--"
        }}
      </p>
      <p>
        <strong>Firmware: </strong>
        {{ audioDeviceData?.firmware ? audioDeviceData?.firmware : "--" }}
      </p>
      <p>
        <strong>MAC Address: </strong>
        {{ audioDeviceData?.mac ? audioDeviceData?.mac : "--" }}
      </p>
      <p>
        <strong>DNS Server: </strong>
        {{
        audioDeviceData?.running_config?.dns_primary_server
        ? audioDeviceData?.running_config?.dns_primary_server
        : "--"
        }}
      </p>
      <p>
        <strong>Time Server: </strong>{{
        audioDeviceData?.running_config?.sntp_server
        ? audioDeviceData?.running_config?.sntp_server
        : "--"
        }}
      </p>
      <p>
        <strong>Offset: </strong>{{
        audioDeviceData?.running_config?.gmt_offset_hours
        ? audioDeviceData?.running_config?.gmt_offset_hours
        : "--"
        }}
      </p>
      <p>
        <strong>Provisioning Server: </strong>{{
        audioDeviceData?.running_config?.provisioning_server
        ? audioDeviceData?.running_config?.provisioning_server
        : "--"
        }}
      </p>
      <p>
        <strong>Provisioning Server Type: </strong>{{
        audioDeviceData?.running_config?.provisioning_server_type
        ? audioDeviceData?.running_config?.provisioning_server_type
        : "--"
        }}
      </p>
    </div>
  </div>
</div>