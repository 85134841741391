import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; 
import { Router } from '@angular/router';  
import { TableFilterService } from '../../../services/table-filter.service'

import { PopupTypeService } from '../../../services/popupType.service';

@Component({
  selector: 'app-video-alldevices-stats',
  templateUrl: './video-alldevices-stats.component.html',
  styleUrls: ['./video-alldevices-stats.component.scss']
})
export class VideoAlldevicesStatsComponent implements OnInit {
  @Output() not: any = 'not';
  @Input() videoSitesSummary: any;
  @Output() onFilter: EventEmitter<number> = new EventEmitter();
  currentFilter: string;
  data: any;
  activeBtn1:any;
  activeBtn2:any;
  calculatedMbps: number; 
  activeFilter: any = "";


  constructor(private ds: PopupTypeService, private tableFilterService: TableFilterService) { 
  }

  //Calculates the live mbps and turns into whole numbers
  calMbps(){
    if(this.videoSitesSummary){
      var roundnum =  (this.videoSitesSummary.bitrate/ (1024*1024))
      this.calculatedMbps =  (Math.round(roundnum * 100) / 100);
      return this.calculatedMbps
    }
  }
  
  setFilter(filter) {
    if (filter == this.activeFilter) {
      this.activeFilter = "";
      this.clickFilter("");
    } else {

      this.activeFilter = filter;
      switch (filter) {
        case 'oncall':
          this.clickFilter('oncall');
          break;
        case 'impacted':
          this.clickFilter("impactedOnly")
          break;
      }
    }
  }

  // This function filters the list when certain Live Stat Buttons are clicked
  clickFilter(filter):void {
  
    this.tableFilterService.filter(filter);
  }

  ngOnInit(){
    // send message to subscribers via observable subject
    this.ds.sendPopupType(0);
  }
  

  sendPopupType(p){
    this.ds.sendPopupType(p);
    // // console.log(p)
  }

  ngOnDestroy(){
    // clear message
    this.ds.clearPopupType();
  }

  activeBtn:any = 0;
  toggleBtn() {
    if(this.data[1]=='health'){
      this.activeBtn1 = 1;
    }else if(this.data[1]=='oncall'){
      this.activeBtn2 = 1;
    }else{
      this.activeBtn1 = '';
      this.activeBtn2 = '';
    }
  }

}
