import { Component, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

import { AuthService } from '../../../services/auth.service'
import { GetVideoService } from '../../../services/get-video.service'
import { PopupSendDataService } from '../../../services/popup-send-data.service'
import { PopupTypeService } from '../../../services/popupType.service'

@Component({
  selector: 'app-video-device-stats',
  templateUrl: './video-device-stats.component.html',
  styleUrls: ['./video-device-stats.component.scss'],
})
export class VideoDeviceStatsComponent implements OnInit {
  @Input() videoDeviceData: any;
  @Input() iotDeviceDetails: any = [];
  @Output() not: any = 'not';
  latColor: any;
  pacColor: any;
  jitColor: any = 'gray';
  id: number;
  accessLevel: any;
  isNotButton: string;
  hasAccess: boolean;
  liveMpbs: any;

  contentRXParsed: any;
  contentTXParsed: any;
  audioRXParsed: any;
  audioTXParsed: any;
  videoRXParsed: any;
  videoTXParsed: any;
  mbpsRX: any;
  mbpsTX: any;
  jitterRX: any;
  jitterTX: any;
  packetlossRX: any;
  packetlossTX: any;
  latencyRX: any;
  latencyTX: any;
  
  deviceType: any = 'poly';
  
  	pingRtd: any = '--'
	packetLoss: any = '--'

	worstRtd: any = '--'
	medianRtd: any = '--'
	bestRtd: any = '--'
	packetLossRtd: any = '--'

	latestEventsMins: any = '--'
	latestEvents24h: any = '0'

	deviceID: any
	deviceName: any
	notes: any

	deviceHisoryParams: any

	medianAlert: Number = 10
	medianWarning: Number = 9

	packetLossAlert: Number = 15
	packetLossWarning: Number = 10
  logitechData: any;


  constructor(
    public ds: PopupTypeService,
    private sendPopupDataService: PopupSendDataService,
    private Auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private getVideoService: GetVideoService
  ) {
    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });
  }

  ngOnInit() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.accessLevel = decodedToken.accesslevel;
    this.hasAccess = this.getHasAccess();
    this.isNotButton = !this.hasAccess ? 'not' : 'yes';

    this.ds.sendPopupType(0);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes)
    
    if(changes.iotDeviceDetails && this.iotDeviceDetails) {
      this.setDataIot()
    }
    
    if (this.videoDeviceData && changes.videoDeviceData) {
      // console.log("I am in if now")
     this.liveMpbs = this.videoDeviceData.total_mbps / (1024 * 1024);
      this.deviceType = this.videoDeviceData.device_type;

      this.contentRXParsed = this.videoDeviceData.contentRx !== null ? JSON.parse(this.videoDeviceData.contentRx) : null;
      this.contentTXParsed = this.videoDeviceData.contentTx !== null ? JSON.parse(this.videoDeviceData.contentTx) : null;
      this.audioRXParsed = this.videoDeviceData.audioRx !== null ? JSON.parse(this.videoDeviceData.audioRx) : null;
      this.audioTXParsed = this.videoDeviceData.audioRx !== null ? JSON.parse(this.videoDeviceData.audioTx) : null;
      this.videoRXParsed = this.videoDeviceData.videoRx !== null ? JSON.parse(this.videoDeviceData.videoRx) : null;
      this.videoTXParsed = this.videoDeviceData.videoRx !== null ? JSON.parse(this.videoDeviceData.videoTx) : null;

      if (this.contentRXParsed !== null && this.audioRXParsed !== null && this.videoRXParsed !== null) {
        this.mbpsRX = (this.contentRXParsed.mbps + this.audioRXParsed.mbps + this.videoRXParsed.mbps) / (1024 * 1024);
        this.jitterRX = this.contentRXParsed.jitter + this.audioRXParsed.jitter + this.videoRXParsed.jitter;
        this.packetlossRX = this.contentRXParsed.packetsloss + this.audioRXParsed.packetsloss + this.videoRXParsed.packetsloss;
        this.latencyRX = this.contentRXParsed.latency + this.audioRXParsed.latency + this.videoRXParsed.latency;
      } else {
        this.mbpsRX = null;
        this.jitterRX = null;
        this.packetlossRX = null;
        this.latencyRX = null;
      }

      if (this.contentTXParsed !== null && this.audioTXParsed !== null && this.videoTXParsed !== null) {
        this.mbpsTX = (this.contentTXParsed.mbps + this.audioTXParsed.mbps + this.videoTXParsed.mbps) / (1024 * 1024);
        this.jitterTX = this.contentTXParsed.jitter + this.audioTXParsed.jitter + this.videoTXParsed.jitter;
        this.packetlossTX = this.contentTXParsed.packetsloss + this.audioTXParsed.packetsloss + this.videoTXParsed.packetsloss;
        this.latencyTX = this.contentTXParsed.latency + this.audioTXParsed.latency + this.videoTXParsed.latency;
      } else {
        this.mbpsTX = null;
        this.jitterTX = null;
        this.packetlossTX = null;
        this.latencyTX = null;
      }
      
      this.setData()

    }
  }
  
  setData = () => {
  	const { logitech_data } = this.videoDeviceData
    this.logitechData = logitech_data;
  }

  formatLifetimeUsage(str) {
    if (!str) return;
    let splits = str.split(':');

    let ret = [];
    let nr = [];

    if (splits[0] >= 24) {
      nr.push(Math.floor(splits[0] / 24));
      splits[0] = splits[0] % 24;
    } else {
      nr.push('00');
    }

    if (splits.length > 3) {
      ret.push(splits[0]);
      ret.push(splits[1]);
      ret.push(splits[2]);
    } else if (splits.length == 3) {
      ret.push(nr[0]);
      //ret.push('00')
      ret.push(splits[0]);
      ret.push(splits[1]);
    } else {
      return str;
    }
    return ret.join(':');
  }

  paramData() {
    this.sendPopupDataService.sendPopupData(this.id);
  }
  openCallLog(t) {
    this.ds.sendPopupType(37, { deviceId: this.id, type: t }, this.hasAccess);
  }

  lowAsGood(total, warning, alert) {
    if (total >= alert) {
      return 'red';
    }
    if (total >= warning && total < alert) {
      return 'yellow';
    }

    return 'gray'
  }
  highAsGood(total, warning, alert) {
    if (total <= alert) {
      return 'red';
    }
    if (total > alert && total <= warning) {
      return 'yellow';
    }
    return 'gray'
  }

  colorLat() {
    this.latColor =
      this.videoDeviceData.trigger_limits.latency.good == 'low'
        ? this.lowAsGood(
          this.videoDeviceData.total_latency,
          this.videoDeviceData.triggers.latency.warning,
          this.videoDeviceData.triggers.latency.alert
        )
        : this.highAsGood(
          this.videoDeviceData.total_latency,
          this.videoDeviceData.triggers.latency.warning,
          this.videoDeviceData.triggers.latency.alert
        );
    return this.latColor;
  }
  colorJit() {
    this.jitColor =
      this.videoDeviceData.trigger_limits.jitter.good == 'low'
        ? this.lowAsGood(
          this.videoDeviceData.total_jitter,
          this.videoDeviceData.triggers.jitter.warning,
          this.videoDeviceData.triggers.jitter.alert
        )
        : this.highAsGood(
          this.videoDeviceData.total_jitter,
          this.videoDeviceData.triggers.jitter.warning,
          this.videoDeviceData.triggers.jitter.alert
        );
    return this.jitColor;
  }
  colorPac() {
    this.pacColor =
      this.videoDeviceData.trigger_limits.packetsloss.good == 'low'
        ? this.lowAsGood(
          this.videoDeviceData.total_packetloss,
          this.videoDeviceData.triggers.packetsloss.warning,
          this.videoDeviceData.triggers.packetsloss.alert
        )
        : this.highAsGood(
          this.videoDeviceData.total_packetloss,
          this.videoDeviceData.triggers.packetsloss.warning,
          this.videoDeviceData.triggers.packetsloss.alert
        );
    return this.pacColor;
  }


  ngOnDestroy() {
    // clear message
    this.ds.clearPopupType();
  }

  notesInfo = [];
  saveNotes() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const companyId = {
      company_id: decodedToken.company_id,
    };
    const notesDtls = {
      company_id: decodedToken.company_id,
      device_id: this.videoDeviceData.video_device_id,
      notes: (<HTMLInputElement>document.getElementById('exampleFormControlTextarea1')).value,
    };

    this.Auth.updateNotes(notesDtls).subscribe((data) => {
      this.notesInfo = data.response;
    });
  }

  getHasAccess = () => {
    if (this.accessLevel) {
      if (this.accessLevel === 'USER') {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };
  
  	formatValue = v => ([null, undefined].includes(v) ? '--' : v)

	setDataIot = () => {
		const { iot_id, notes, api_key, account_url, agent_id, paused, minrtt } = this.iotDeviceDetails
		const { lost, worstRtd, bestRtd, medianRtd, packetloss, iot_name } = this.iotDeviceDetails

		this.deviceID = iot_id
		this.notes = notes

		this.pingRtd = this.formatValue(minrtt)
		this.packetLoss = this.formatValue(lost)
		this.worstRtd = this.formatValue(worstRtd)
		this.medianRtd = this.formatValue(medianRtd)
		this.bestRtd = this.formatValue(bestRtd)
		this.packetLossRtd = this.formatValue(packetloss)
		this.deviceName = this.formatValue(iot_name)
		
		
		this.deviceHisoryParams = {
			iot_id,
		}

		// this.getIotIncidentList()
	}

	checkForTriggerProp = (params = {}) => 'triggers' in params

	setTriggeralue = (alert, warning, alert2, warning2) => {
		this.medianAlert = alert
		this.medianWarning = warning

		this.packetLossAlert = alert2
		this.packetLossWarning = warning2
	}

	// getIotIncidentList = () => {
	// 	this.getIot.getIotLiteIncidents(this.deviceID, 1).subscribe(
	// 		(data: any) => {
	// 			const res = data.response

	// 			if (res && res.length > 0) {
	// 				this.latestEvents24h = res.length
	// 			}
	// 		},
	// 		err => {}
	// 	)
	// }
}
