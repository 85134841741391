import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class TwoFactorAuthComponent implements OnInit {
  dashboardLogo = environment.config.logo;
  errorMessage: string = '';
  message: string = 'Please check your phone or email for the authentication code.';
  showErrorMessage: boolean = false;
  isBusy: boolean = false;
  code: string = '';
  inputClass: string = 'form-control';

  constructor(
    private router: Router,
    private Auth: AuthService
  ) {
    if (this.Auth.authOption) {
      const option = this.Auth.authOption === 'sms' ? 'phone' : 'email';
      this.message = `Please check your ${option} for the authentication code.`;
    }
  }

  ngOnInit() {
  }

  async cancel() {
    try {
      this.isBusy = true;
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
      localStorage.setItem('verified', 'false');
      localStorage.removeItem('token');
      localStorage.setItem('keyDownload', '0');
      localStorage.setItem('reloadSiteData', '0');
      localStorage.setItem('showCongrats', '0');
      localStorage.setItem('runTut', '0');
      localStorage.setItem('successReg', '0');
      localStorage.setItem('overLayMembersMove', '0');
      this.isBusy = false;
    } catch (e) {
      this.errorMessage = e;
      // console.log(e);
    }
  }

  async resendCode() {
    try {
      this.isBusy = true;
      const token = localStorage.getItem('token');
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      const sendAddress = decodedToken.two_factor_auth_option === 'email' ? decodedToken.email : decodedToken.phone;
      const channel: any = decodedToken.two_factor_auth_option === 'email' ? 'email' : 'sms';
      await this.Auth.sendVerificationCode(environment.config.twilioServiceId, sendAddress, channel);
      this.isBusy = false;
      this.message = 'Resend Verification Successful. Please check your email again.';
    } catch (e) {
      this.isBusy = false;
      this.errorMessage = e;
      // console.log(e);
    }
  }

  async verifyCode() {
    try {
      this.isBusy = true;
      const code = this.code;
      const token = localStorage.getItem('token');
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      const sendAddress = decodedToken.two_factor_auth_option === 'email' ? decodedToken.email : localStorage.getItem('phone');
      const response = await this.Auth.checkVerificationCode(environment.config.twilioServiceId, sendAddress, code);
      if (typeof response === 'string' && response === 'Verification Successful') {
        this.errorMessage = '';
        this.showErrorMessage = false;
        localStorage.setItem('verified', 'true');
        this.router.navigate(['/'])
      } else {
        this.showErrorMessage = true;
        this.inputClass = 'form-control red-border'
        this.errorMessage = 'Invalid Code';
        localStorage.setItem('verified', 'false');
      }
      this.isBusy = false;
    } catch (e) {
      this.isBusy = false;
      this.errorMessage = e;
      // console.log(e);
    }
    
  };

}
