import { HttpClient } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'
import { MainMenuTypeService } from '../../services/mainmenuType.service'
import { MembersTypeService } from '../../services/membersType.service'
import { PopupTypeService } from '../../services/popupType.service'

@Component({
	selector: 'app-cisco-popup',
	templateUrl: './cisco-popup.component.html',
	styleUrls: ['./cisco-popup.component.scss'],
})
export class CiscoPopupComponent implements OnInit {
	constructor(
		public http: HttpClient,
		private Auth: AuthService,
		private formBuilder: FormBuilder,
		private ds: PopupTypeService,
		private dsMm: MainMenuTypeService,
		private dsM: MembersTypeService
	) {}

	actionCount: number = 0
	deviceActionData: any
	deviceDetails: any
	allSiteListData: any
	deviceActionId: number
	addDeviceResponse = []
	errorMessage: String
	showData: boolean
	allDeviceListData = []
	compId: any = ''
	collectorId: ''
	siteId: -1
	index: ''
	ipAddress: ''
	userIndex: any
	runTut = localStorage.getItem('runTut')
	@Input() runTutorial: any
	tutDeviceCheck = []
	showAddDevice: any = 1
	showTryAgain: any = 0
	findDevice: any = 0
	showConfirm: any = 0
	popupType: number
	addDeviceInProgress: number = 0
	iconAddSite = environment.config.dynamicImage.iconAddSite
	iconVirtual = environment.config.dynamicImage.iconVirtual
	accessLevel: string
	totalDevices: number = 0
	hasAccess: boolean
	showSearching = false
    showMsg30Sec: boolean = false;
	errorMsg: any = 'Device Not Found'
	showErrMsg: any = false
	showErrorMessageIot: any = false
	ngOnInit() {
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService()
		const decodedToken = helper.decodeToken(token)
		this.compId = decodedToken.company_id
		this.accessLevel = decodedToken.accesslevel
		this.hasAccess = this.getHasAccess()

		if (this.runTutorial == 1) {
			this.findDevice = 1
		} else {
			this.runTutorial = 0
			this.showAddDevice = 0
			this.showTryAgain = 0
			this.findDevice = 0
			this.showConfirm = 0
		}
		this.getAllSiteDetail()
		this.getAllDeviceList()
		this.getAlertMonitorStatus()
		this.checkForm()
	}
	control: FormControl
	siteForm: FormGroup
	submitted = false
	model: any = {}
	// convenience getter for easy access to form fields
	get f() {
		return this.siteForm.controls
	}

	checkForm() {
		// if(this.userIndex == -1){
		this.siteForm = this.formBuilder.group({
			collectorId: ['', [Validators.required]],
			siteId: ['', [Validators.required]],
			index: ['', [Validators.required]],
			ipAddress: ['', [Validators.required]],
			roomName: ['', [Validators.required]],
			username: ['', [Validators.required]],
			password: [''],
		})
	}

	onSubmit() {
		// (<HTMLInputElement>document.getElementById('feedback')).innerHTML = "";
		this.submitted = true

		// stop here if form is invalid
		if (this.siteForm.invalid) {
			return
		} else {
			this.addDevice()
		}
	}
  showSiteListErrMsg: any = false;
	

	// This function gets a list of sites for this company
	getAllSiteDetail = () => {


		// this.allSiteListData = [];

    this.Auth.getAllSiteList().subscribe((data) => {
      let res = data.response;
      console.log('res: ', res)
      this.allSiteListData = res.map(e => {
        return {
          collector_id: e.collector.collector_id,
          version: e.collector.version,
          status: e.collector_status,
          site_name: e.site_name,
          site_id: e.site_id,
        }
      }).filter(e => e.status === 1)

      console.log('siteList: ', this.allSiteListData)

      if (this.allSiteListData.length < 1) {
        this.showSiteListErrMsg = true;
      }
    });
		



	}
	
	getAllDeviceList = () => {
		const deviceData = {
			company_id: this.compId,
			type: 'cisco',
		}
		this.Auth.getAllDeviceList(deviceData).subscribe((data:any) => {
			const res = data.response;
			this.allDeviceListData = res
			this.totalDevices = res.length
		// this.tutDeviceCheck = data['count']
			console.log('this.allDeviceListData: ', this.allDeviceListData)
		})
	}
	
			// this.allDeviceListData = [];


	tutClose() {
		if (this.runTutorial == 1) {
			this.runTutorial = 0
			this.showAddDevice = 0
			this.showTryAgain = 0
			this.findDevice = 0
			this.showConfirm = 0

			const token = localStorage.getItem('token')
			const helper = new JwtHelperService()
			const decodedToken = helper.decodeToken(token)
			const settut = {
				company_id: decodedToken.company_id,
				user_id: decodedToken.user_id,
				tutorial: 0,
			}
			this.Auth.setTutorial(settut).subscribe(data => {
				this.sendPopupType(0)
				this.sendMainMenuType(0)
				this.sendMemberType(0)
				localStorage.setItem('showCongrats', '1')
				this.Auth.runTutorial = 0
			})
		}
	}
	videoDeviceId = 0
	isEdit: number = 0

	setDeviceId(id) {
		this.videoDeviceId = id
	}
	async delay(ms: number) {}

	toEdit(n) {
		if (this.isEdit == n) {
			this.isEdit = 0
			this.isDelete = 0
		} else {
			this.isEdit = n
			this.isDelete = 0
		}
		event.stopPropagation()
	}
	device_name = ''
	isDelete: number = 0
	toDelete(n) {
		if (this.isDelete == n) {
			this.isDelete = 0
			this.isEdit = 0
		} else {
			this.isDelete = n
			this.isEdit = 0
		}

		event.stopPropagation()
	}

	toogleDeleteForm() {
		this.isDelete = 0
		this.isEdit = 0
	}

	deleteDevice() {
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService()
		const decodedToken = helper.decodeToken(token)

		const data = {
			company_id: decodedToken.company_id,
			device_id: this.videoDeviceId,
		}
		this.Auth.deactivateDevice(data).subscribe(data => {
			if (data['response'][0]['status'] == 'OK') {
				this.getAllSiteDetail()
			}
		})
		this.isDelete = 0
	}
	highlightNum: number = 1
	setHighlight(n) {
		this.checkForm()
		this.highlightNum = n
		this.toDisable(0)
	}

	isdisable: number = 0
	toDisable(n) {
		if (this.isdisable == n) {
			this.isdisable = 0
		} else {
			this.isdisable = n
		}
	}

	//This function continues to check wether the found device process status is  completed or failed
	checkStatus = id => {
		const idData = {
			action_id: id,
		}

		this.Auth.postDeviceActionId(idData).subscribe(actionData => {
			// this function terminates searching when user re selecting index of the site.+
			console.log('postDeviceActionId: ', actionData)
			console.log('actionCount: ', this.actionCount)
			if (this.actionCount == -1) {
				this.actionCount = 0

				return
			}
			if (this.actionCount == 120) {
				this.showSearching = false;
				this.actionCount = 0
				this.errorMessage = 'Could not find device. Please try again.'
				this.showAddDevice = 0
				this.showTryAgain = 1
				this.findDevice = 0
				this.showConfirm = 0
				return
			}
			if (actionData.response[0].status !== 'COMPLETE' && actionData.response[0].status !== 'FAILED') {
				const rk = this
				setTimeout(function () {
					rk.checkStatus(id)
				}, 1000)
				this.actionCount++
				if (this.actionCount == 30) {
					this.showMsg30Sec = true;
		  
					// Since total time of waiting is 120 seconds and after 30 we show It iss taking longer then usual
					setTimeout(() => {
					  this.showMsg30Sec = false;
					}, 70*1000); 
				  }
			} else if (actionData.response[0].status === 'FAILED') {
				console.log(actionData.response[0])
				// this.deviceDetails.push(actionData.response[0]);

				this.resetFields()
				const [newData] = actionData.response
				this.errorMsg = 'Could not find device. Please try again.'
				if (newData.results.message != 'device not found') {
					this.errorMsg = newData.results.message
				}
				this.showErrMsg = true

				return
			} else if (actionData.response[0].status === 'COMPLETE') {
				// this.deviceDetails.push(actionData.response[0]);
				// this.resetForm();
				const [newData] = actionData.response
				//for audio
				this.resetFields()
				this.showAddDevice = true
				this.setData(newData.results)

				// this.showTryAgain = 0;
				// this.findDevice = 0;
				// this.showConfirm = 1;
				return
			}
		})
	}

	resetFields = () => {
		this.actionCount = 0
		this.showSearching = false
		this.showAddDevice = false
		this.showErrMsg = false
		this.showMsg30Sec = false
	}

	deviceName: any
	deviceModel: any
	serialNumber: any
	activeCamera: any
	activeMic: any
	deviceMac: any
	firmware: any
	softwareRelease: any
	deviceIpAddress: any

	setData = params => {
		const { device_name, model, ipv4, serialnumber, mac, softwareinfo, active_camera, active_mic, status_load } = params

		this.deviceName = this.checkValueIfEmpty(device_name)
		this.deviceModel = model
		this.serialNumber = this.checkValueIfEmpty(serialnumber)
		this.deviceIpAddress = this.checkValueIfEmpty(ipv4)
		this.deviceMac = mac
		this.softwareRelease = this.checkValueIfEmpty(softwareinfo)
		this.activeMic = this.checkValueIfEmpty(active_mic)
		this.activeCamera = this.checkValueIfEmpty(active_camera)

		let isModelCisco = model.toLowerCase().indexOf('cisco')
		let isCiscoVersionReady = softwareinfo?.split('.')[0]

		console.log('getNewModel: ', isModelCisco)
		console.log('isCiscoVersionReady: ', isCiscoVersionReady)

		if (isModelCisco > -1) {
			if (
				isCiscoVersionReady == 'ce10' ||
				isCiscoVersionReady == 'ce11' ||
				isCiscoVersionReady == 'ce12' ||
				isCiscoVersionReady == 'ce13' ||
				isCiscoVersionReady == 'ce14'
			) {
				this.activeMic = this.getNewCiscoMic(status_load)
				this.activeCamera = this.getNewCiscoCamera(status_load)
			}
		}
	}

	getNewCiscoMic = params => {
		let newMic = 0
		let i = 0
		const getMic = params.Status.Audio.Input.Connectors.Microphone

		if (getMic.length > 0) {
			while (i < getMic.length) {
				console.log(getMic[i].ConnectionStatus.Value)
				if (getMic[i].ConnectionStatus.Value == 'Connected') {
					newMic++
				}
				i++
			}
			console.log('newMic: ', newMic)
			return newMic
		} else {
			return 0
		}
	}

	getNewCiscoCamera = params => {
		let newCam = 0
		let i = 0

		const getCam = params.Status.Cameras.Camera

		if (getCam.length > 0) {
			while (i < getCam.length) {
				console.log(getCam[i].Connected.Value)
				if (getCam[i].Connected.Value == 'True') {
					newCam++
				}
				i++
			}
			console.log('newCam: ', newCam)
			return newCam
		} else {
			return 0
		}
	}

	checkValueIfEmpty = value => (value ? value : '--')
	resetForm() {
		;(<HTMLInputElement>document.getElementById('roomName')).value = ''
		;(<HTMLInputElement>document.getElementById('ipAddress')).value = ''
	}

	cancelPressed() {
		this.siteForm.reset()
		this.checkForm()

		if (this.runTutorial == 1) {
			this.findDevice = 1
		} else {
			this.runTutorial = 0
			this.showAddDevice = 0
			this.showTryAgain = 0
			this.findDevice = 0
			this.showConfirm = 0
		}

		this.showData = false
		this.deviceActionData = []
		this.deviceDetails = []
		this.showConfirm = 0
		this.submitted = false
		this.showAddDevice = 1
		this.findDevice = 1
		this.actionCount = 0
	}

	// This function sends the processed and competed device to the database
	sendAddDeviceData() {
		if (this.addDeviceInProgress == 0) {
			this.addDeviceInProgress = 1
			this.delay(1000).then(any => {
				this.addDeviceInProgress = 0
			})

			const token = localStorage.getItem('token')
			const helper = new JwtHelperService()
			const decodedToken = helper.decodeToken(token)
			const deviceData = {
				company_id: decodedToken.company_id,
				action_id: this.deviceActionId,
				// action_id: 105
			}
			this.resetForm()
			this.Auth.sendAddDeviceData(deviceData).subscribe(data => {
				this.addDeviceResponse = data.response;
				if(this.addDeviceResponse?.length && this.addDeviceResponse[0].status == 'Ok'){
					this.addDeviceResponse[0].message = 'Device Successfully Added'
				}
			})
		}
	}

	// This find the device that will be added to the database
	addDevice = () => {
		this.deviceActionData = []
		this.deviceDetails = []
		this.deviceActionId = undefined
		this.addDeviceResponse = []
		this.errorMessage = undefined

		this.showData = false
		event.preventDefault()
		const target = event.target
		const token = localStorage.getItem('token')
		const helper = new JwtHelperService()
		const decodedToken = helper.decodeToken(token)

		const deviceData = {
			company_id: decodedToken.company_id,
			collector_id: (<HTMLInputElement>document.getElementById('collectorId')).value,
			site_id: (<HTMLInputElement>document.getElementById('siteId')).value,
			username: (<HTMLInputElement>document.getElementById('username')).value,
			password: (<HTMLInputElement>document.getElementById('password')).value,
			room_name: (<HTMLInputElement>document.getElementById('roomName')).value,
			type: 'cisco',
			// ip: target.querySelector('#ipAddress').value,
			ip: (<HTMLInputElement>document.getElementById('ipAddress')).value,
			user_id: decodedToken.user_id,
		}

		this.Auth.findDevice(deviceData).subscribe(data => {
			this.resetFields()
			this.deviceActionData = data.response[0]
			this.deviceActionId = data.response[0].action_id
			// this.deviceActionId = 105;
			const actionData = {
				// action_id: data.response[0].action_id
				action_id: this.deviceActionId,
			}
			if (this.deviceActionData['status'] !== 'Error') {
				this.Auth.postDeviceActionId(actionData).subscribe(reponseData => {
					this.checkStatus(reponseData.response[0].action_id)
					this.showSearching = true
				})
			}
		})
		this.showData = true
	}

	showCoords(event) {
		var x = event.target.X
		var y = event.Y
		var coords = 'X coords: ' + x + ', Y coords: ' + y
		document.getElementById('demo').innerHTML = coords
	}

	setIndex(n) {
		//this.clearInputs();

		this.userIndex = n
		//this.getIndex = (<HTMLInputElement>document.getElementById('add_contact')).value;
		// this.fname = (<HTMLInputElement>document.getElementById('firstname'));
		if (n >= 0) {
			this.collectorId = this.allSiteListData[n].collector_id
			this.siteId = this.allSiteListData[n].site_id

			// (<HTMLInputElement>document.getElementById('add_contact')).value =
		}

		if (this.actionCount > 0) this.actionCount = -1
		this.errorMessage = ''
		this.showAddDevice = 0
		this.showTryAgain = 0
		// this.findDevice = 0;
		this.showConfirm = 0
		this.showData = false
	}

	clearPopupType() {
		// clear message
		this.ds.clearPopupType()
	}

	sendPopupType(p) {
		this.ds.sendPopupType(p)
	}

	sendMainMenuType(p) {
		this.dsMm.sendMainMenuType(p)
	}

	sendMemberType(p) {
		this.dsM.sendMemberType(p)
	}
	// this.sendMainMenuType(9);
	ngOnDestroy() {
		this.ds.clearPopupType()
	}

	cisAlertMonitorDtls = []
	ciscoAlert: any
	ciscoMonitor: any
	disableToggle: boolean = false
	getAlertMonitorStatus() {
		const cmpData = {
			company_id: this.compId,
		}

		// this.cisAlertMonitorDtls = [];
		this.Auth.getDeviceAlertMonitorStatus(cmpData).subscribe(data => {
			this.cisAlertMonitorDtls = data['response']
			this.ciscoAlert = +this.cisAlertMonitorDtls[0].cisco_alerts
			this.ciscoMonitor = 1 - +this.cisAlertMonitorDtls[0].cisco_paused
			this.disableToggle = false
		})
	}

	toggleCiscoAlerts() {
		this.disableToggle = true
		this.ciscoAlert = 1 - this.ciscoAlert

		const cmpData1 = {
			company_id: this.compId,
			device_type: 'cisco',
		}

		if (this.ciscoAlert) {
			this.Auth.setDeviceTypeNotifyOn(cmpData1).subscribe(() => {
				this.getAlertMonitorStatus()
			})
		} else {
			this.Auth.setDeviceTypeNotifyOff(cmpData1).subscribe(() => {
				this.getAlertMonitorStatus()
			})
		}
	}

	toggleCiscoMonitor() {
		this.disableToggle = true
		this.ciscoMonitor = 1 - this.ciscoMonitor

		const cmpData2 = {
			company_id: this.compId,
			device_type: 'cisco',
		}

		if (this.ciscoMonitor) {
			this.Auth.setDeviceTypeMonitorOn(cmpData2).subscribe(() => {
				this.getAlertMonitorStatus()
			})
		} else {
			this.Auth.setDeviceTypeMonitorOff(cmpData2).subscribe(() => {
				this.getAlertMonitorStatus()
			})
		}
	}

	getHasAccess = () => {
		if (this.accessLevel) {
			if (this.accessLevel === 'USER') {
				return false
			}
			return true
		} else {
			return false
		}
	}
}
