<div class="devicetable">
  <input #searchDataIOT (keyup)="searchData(searchDataIOT.value)" placeholder="Search..." class="input-class" />

  <div class="mat-elevation-z8 child mat-table">
    <table class="devicetable__data-table" mat-table [dataSource]="dataSource" matSort>
      <ng-container *ngIf="isShowCompanyName()" matColumnDef="company_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Company</strong>
        </th>
        <td mat-cell *matCellDef="let row">
          <span (click)="clickRow(row)">
            {{ row.company_name | displayEmpty }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="display_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Space Name</strong>
        </th>
        <td mat-cell *matCellDef="let row" title="{{ row.display_name | displayEmpty }}">
          <span (click)="clickRow(row)">
            {{ row.space_name | displayEmpty }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="device_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Device Name</strong>
        </th>
        <td mat-cell *matCellDef="let row" title="{{ row.display_name | displayEmpty }}">
          <span (click)="clickRow(row)">
            {{ row.display_name | displayEmpty }}
          </span>
        </td>
      </ng-container>


      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <i class="fa fa-pause blue m-1" *ngIf="row.paused"></i>
          <img src="{{ row.status == 'ONLINE' ? greenIcon : redIcon }}" *ngIf="!row.paused" />
        </td>
      </ng-container>

      <ng-container matColumnDef="agent_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Agent Name</strong>
        </th>
        <td style="text-align: left;" mat-cell *matCellDef="let row">
          <span (click)="clickRow(row)">
            {{ row.agent_name | displayEmpty }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="ip_address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>IP</strong>
        </th>
        <td mat-cell *matCellDef="let row">{{ row.ip_address | displayEmpty }}</td>
      </ng-container>

      <ng-container matColumnDef="mac">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Mac</strong>
        </th>
        <td mat-cell *matCellDef="let row">{{ row.mac | displayEmpty }}</td>
      </ng-container>


      <ng-container matColumnDef="make">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Make</strong>
        </th>
        <td mat-cell *matCellDef="let row">{{ row.make | displayEmpty }}</td>
      </ng-container>

      <ng-container matColumnDef="model">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <strong>Model</strong>
        </th>
        <td mat-cell *matCellDef="let row">{{ row.model | displayEmpty }}</td>
      </ng-container>

      <!-- 
      <ng-container matColumnDef="webportal">
        <th mat-header-cell *matHeaderCellDef><strong>Remote Control</strong></th>
        <td mat-cell *matCellDef="let row">
          <fa-icon [icon]="faCogIcon" (click)="openWebPortalOptions(row.iot_device_id)"
            class="cog-icon fa-2x"></fa-icon>
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [colSpan]="displayedColumns.length">No data found in table</td>
      </tr>
    </table>
  </div>
</div>