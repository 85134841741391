import { Component, OnInit, Input } from '@angular/core'
import { GetCloudService } from '../../../../services/get-cloud.service'
import { GetCollectorService } from 'src/app/services/get-collector.service'

@Component({
	selector: 'app-cloud-user-notes',
	templateUrl: './cloud-user-notes.component.html',
	styleUrls: ['./cloud-user-notes.component.scss'],
})
export class CloudUserNotesComponent implements OnInit {
	@Input() notes?: string
	@Input() zoom_user_id: any
	@Input() type?: string

	constructor(private getCloudService: GetCloudService, private getCollector: GetCollectorService) {}

	ngOnInit() {}

	saveNotes() {
		if (this.type === 'COLLECTOR') {
			this.getCollector.updateNotes(this.zoom_user_id, this.notes).subscribe()
		} else {
			this.getCloudService.updateNotes(this.zoom_user_id, this.notes).subscribe()
		}
	}
}
