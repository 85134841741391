import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CallHelperService {
  constructor() {}

  lowAsGood = (total, warning, alert) => {
    if (total >= alert) {
      return 'red';
    }
    if (total >= warning && total < alert) {
      return 'yellow';
    }
  };

  highAsGood = (total, warning, alert) => {
    if (total <= alert) {
      return 'red';
    }
    if (total > alert && total <= warning) {
      return 'yellow';
    }
  };

  getColor(good, val, warningLimit, alertLimit) {
    const color =
      good === 'low'
        ? this.lowAsGood(val, warningLimit, alertLimit)
        : this.highAsGood(val, warningLimit, alertLimit);
    return color;
  }

  getSipGateKeeperStatus(deviceType, sip, gateKeeper = []) {
    let isShowSip = true;
    let sipGateColor;
    let sipGateText;
    //Video
    if (deviceType === 'video') {
      if (sip.value.toLowerCase() === 'up') {
        return {
          sipGateColor: 'green',
          sipGateText: 'SIP',
          isShowSip,
        };
      }

      const upGateKeeperfound = gateKeeper.find((g) => {
        return g.value.toLowerCase() === 'up';
      });

      if (upGateKeeperfound) {
        return {
          sipGateColor: 'green',
          sipGateText: 'Gatekeeper',
          isShowSip,
        };
      }

      if (sip.value.toLowerCase() !== 'up' && sip.value.toLowerCase() !== 'null') {
        return {
          sipGateColor: 'red',
          sipGateText: 'SIP',
          isShowSip,
        };
      }

      const downGateKeeperfound = gateKeeper.find((g) => {
        return g.value.toLowerCase() !== 'up' && g.value.toLowerCase() !== 'null';
      });

      if (downGateKeeperfound) {
        return {
          sipGateColor: 'red',
          sipGateText: 'Gatekeeper',
          isShowSip,
        };
      }

      const nullGateKeeperfound = gateKeeper.find((g) => {
        return g.value.toLowerCase() === 'null';
      });

      if (nullGateKeeperfound && sip.value.toLowerCase() === 'null') {
        return {
          sipGateColor: 'gray',
          sipGateText: 'No Show',
          isShowSip,
        };
      }
    } else if (deviceType === 'audio') {
      if (sip) {
        if (sip.server.length !== 0) {
          isShowSip = true;
          sipGateText = 'SIP';
          if (sip.status.toLowerCase() === 'registered') {
            return {
              sipGateColor: 'green',
              sipGateText: 'SIP',
              isShowSip,
            };
          } else {
            return {
              sipGateColor: 'red',
              sipGateText: 'SIP',
              isShowSip,
            };
          }
        }
      }
      return {
        sipGateColor: null,
        sipGateText: null,
        isShowSip: false,
      };
    } else {
    }
  }

  getConnectionType = (connectionType) => {
    let connectionTypeColor = 'green';
    if (!connectionType) {
      connectionType = '--';
      connectionTypeColor = 'red';
    }

    return {
      connectionType,
      connectionTypeColor,
    };
  };
}
