<!-- 
  <span *ngIf="value">
    <span>
      <img  style="width: 13px" src="../../../assets/img/video/hang-up.png"/>
    </span>
  </span>
  <span *ngIf="value">
    <span><fa name="phone" class="pr-1"></fa>{{ renderValue }}</span>
  </span> -->

<div class="table-zoom-call">
  <img *ngIf="!value" style="width: 13px;" src="../../../assets/img/video/hang-up.png" />
  <span *ngIf="value"><fa-icon [icon]="faIcon" class="pr-1"></fa-icon>{{ value }}</span>
</div>
