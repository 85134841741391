<div class="info-chartSite pt-3 mb-3 chart-container">
    <div style="text-align: center">
        <button (click)="set60m();" [disabled]="currentChart=='60m'">
            60m
        </button>
        <button (click)="set24h();" [disabled]="currentChart=='24h'">
            24h
        </button>
        <button (click)="set7d();" [disabled]="currentChart=='7d'">
            7d
        </button>
    </div>
    <canvas id="myChartSite" width="1150px" height="350px"></canvas>
    <!-- <div style="font-size: .5em">*** Chart contains sample data</div> -->
</div>
