import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { RefreshApiService } from '../../services/refresh-api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-health-block',
  templateUrl: './health-block.component.html',
  styleUrls: ['./health-block.component.scss'],
})
export class HealthBlockComponent implements OnInit, OnDestroy, OnChanges {
  @Input() healthBlockSummary: any;
  @Input() skin: number;
  @Input() active: boolean;
  @Input() polycomDevices: any;
  @Input() ciscoDevices: any;
  @Input() isZoom: any = false;

  healthtitle = '--';
  healthBarGreenTotal = 30;
  healthBarGreenValue = 10;
  healthBarYellowTotal = 33;
  healthBarYellowValue = 3;
  healthBarRedTotal = 100;
  healthBarRedValue = 20;
  healthBarBlueTotal = 50;
  healthBarBlueValue = 25;
  //health panel btn
  healthBarBtnCount1 = 0;
  healthBarBtnLabel1 = '--';
  healthBarBtnColor1 = 'gray';
  healthBarBtnCount2 = 0;
  healthBarBtnLabel2 = '--';
  healthBarBtnColor2 = 'gray';
  healthBarBtnCount3 = 0;
  healthBarBtnLabel3 = '--';
  healthBarBtnColor3 = 'gray';
  //3 buttons router links
  healthBarRouteBtn1: string;
  healthBarRouteBtn2: string;
  healthBarRouteBtn3: string;
  healthBarRouteBtn4: string;
  healthBarRouteBtn5: string;
  healthBarRouteBtn6: string;
  healthBarRouteBtn7: string;
  healthBarRouteGoodDevices: string;
  healthBarRouteImpactedDevices: string;
  healthBarRouteRedDevices: string;
  healthBarRoutePauseDevices: string;

  cloudHealthBarRouteGoodRooms: string;
  cloudHealthBarRouteWarningRooms: string;
  cloudHealthBarRouteRedRoom: string;
  cloudHealthBarRoutePauseRoom: string;

  cloudSideButtonImpactedVipUsers: string;

  vid1: number;
  mainTitle: string;
  subTitle: string;

  iconLink: string;
  iconLink2: string;
  iconLink3: string;

  soon: any;
  enabled: boolean = false;
  deviceIndex: any = 0;

  removeClickable: any;
  refreshData: any;
  refreshListner: Subscription;

  constructor(private refreshApiService: RefreshApiService) {
    // This recieves the update data boolean to start the timer
    this.refreshListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      this.refreshData = m;
      this.getApiData();
    });
  }

  ngOnInit() {
    // console.log('i am inside health block component')
    this.startHealthBlocks();
  }
  ngOnDestroy() {
    // This will clear timers that are pulling data from API Server
    // clearInterval(this.apiTimers);
    this.refreshListner.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.healthBlockSummary &&
      changes.healthBlockSummary.currentValue &&
      changes.healthBlockSummary.currentValue.hasOwnProperty('enabled')
    ) {
      this.enabled = changes.healthBlockSummary.currentValue.enabled;
      // // console.log('enabled: ', this.enabled);
    } else {
      this.enabled = false;
    }
  }

  getApiData() {
    if (this.refreshData) {
      this.startHealthBlocks();
    }
  }

  startHealthBlocks() {
    if (this.skin == 1) {
      this.soon = false;
      this.mainTitle = 'Video Devices';
      this.subTitle = 'Health of all video devices';
      //type health panel icon
      this.iconLink = './assets/img/icon_video_default.png@1X.png';
      //health bar values
      this.healthBarGreenTotal = 0;
      this.healthBarGreenValue = 0;
      this.healthBarYellowTotal = 0;
      this.healthBarYellowValue = 0;
      this.healthBarRedTotal = 0;
      this.healthBarRedValue = 0;
      this.healthBarBlueTotal = 0;
      this.healthBarBlueValue = 0;
      //health button values
      this.healthBarBtnCount1 = 1;
      this.healthBarBtnLabel1 = 'Impacted Sites';
      this.healthBarBtnColor1 = 'gray';
      this.healthBarBtnCount2 = 7;
      this.healthBarBtnLabel2 = 'All Sites';
      this.healthBarBtnColor2 = 'gray';
      this.healthBarBtnCount3 = 120;
      this.healthBarBtnLabel3 = 'All Devices';
      this.healthBarBtnColor3 = 'gray';
      //type health panel icon
      this.iconLink = './assets/img/icon_video_default.png@1X.png';
      //type health devices icon
      this.iconLink2 = './assets/img/poly_logo.png';
      this.iconLink3 = './assets/img/cisco_logo.png@1X.png';
      this.healthBarRouteBtn1 = '/video/warningdevices';
      this.healthBarRouteBtn2 = '/video/allsites';
      this.healthBarRouteBtn3 = '/video/healthydevices';
      this.healthBarRouteBtn4 = '/video/impacteddevices';
      this.healthBarRouteBtn5 = '/video/pauseddevices';
      this.healthBarRouteBtn6 = '/video/alldevices';
      this.healthBarRouteBtn7 = '/video/impactedsites';
    } else if (this.skin == 2) {
      this.soon = true;
      this.mainTitle = 'Audio Devices';
      this.subTitle = 'Health of all audio devices';
      //health bar values
      this.healthBarGreenTotal = 0;
      this.healthBarGreenValue = 0; //this.healthBarGreenTotal - (this.healthBarYellowValue + this.healthBarRedValue);
      this.healthBarYellowTotal = 0;
      this.healthBarYellowValue = 0;
      this.healthBarRedTotal = this.healthBarGreenTotal;
      this.healthBarRedValue = 0;
      this.healthBarBlueTotal = this.healthBarGreenTotal;
      this.healthBarBlueValue = 0;
      //health button values
      this.healthBarBtnCount1 = 0;
      this.healthBarBtnLabel1 = 'Impacted Sites';
      this.healthBarBtnColor1 = 'gray';
      this.healthBarBtnCount2 = 0;
      this.healthBarBtnLabel2 = 'All Sites';
      this.healthBarBtnColor2 = 'gray';
      this.healthBarBtnCount3 = this.healthBarGreenTotal;
      this.healthBarBtnLabel3 = 'All Devices';
      this.healthBarBtnColor3 = 'gray';
      //type health panel icon
      this.iconLink = './assets/img/icon_audio_default.png@1X.png';
      //type health devices icon
      this.iconLink2 = null;
      this.iconLink3 = null;
      this.healthBarRouteBtn1 = '/audio/impactedsites';
      this.healthBarRouteBtn2 = '/audio/allsites';
      this.healthBarRouteBtn3 = '/audio/alldevices';
      this.healthBarRouteGoodDevices = '/audio/healthydevices';
      this.healthBarRouteImpactedDevices = '/audio/warningdevices';
      this.healthBarRouteRedDevices = '/audio/impacteddevices';
      this.healthBarRoutePauseDevices = '/audio/pauseddevices';
    } else if (this.skin == 3) {
      this.soon = false;
      this.mainTitle = 'Cloud Services';
      this.subTitle = 'Health of all Cloud Rooms';
      //type health panel icon
      this.iconLink = './assets/img/icon_cloud_services_default.png@1X.png';
      // if (this.enabled) {
      //health bar values
      this.healthBarGreenTotal = 0;
      this.healthBarGreenValue = 0;
      this.healthBarYellowTotal = 0;
      this.healthBarYellowValue = 0;
      this.healthBarRedTotal = 0;
      this.healthBarRedValue = 0;
      this.healthBarBlueTotal = 0;
      this.healthBarBlueValue = 0;
      //health button values
      this.healthBarBtnCount1 = 0;
      this.healthBarBtnLabel1 = 'Impacted VIP Users';
      this.healthBarBtnColor1 = 'gray';
      this.healthBarBtnCount2 = 0;
      this.healthBarBtnLabel2 = 'All Rooms';
      this.healthBarBtnColor2 = 'gray';
      this.healthBarBtnCount3 = this.healthBarGreenTotal;
      this.healthBarBtnLabel3 = 'All Users';
      this.healthBarBtnColor3 = 'gray';
      //type health devices icon
      this.iconLink2 = null;
      this.iconLink3 = null;
      // this.healthBarRouteBtn1 = "/cloud/warningusers";
      // this.healthBarRouteBtn4 = "/cloud/impactedusers";
      this.healthBarRouteBtn2 = '/cloud/allrooms';
      this.healthBarRouteBtn6 = '/cloud/allusers';
      this.healthBarRouteBtn7 = '/cloud/impactedvipuser';

      this.cloudHealthBarRouteGoodRooms = '/cloud/healthyrooms';
      this.cloudHealthBarRouteWarningRooms = '/cloud/warningrooms';
      this.cloudHealthBarRouteRedRoom = '/cloud/redrooms';
      this.cloudHealthBarRoutePauseRoom = '/cloud/pausedrooms';

      this.cloudSideButtonImpactedVipUsers = '/cloud/impactedvipuser';
    } else if (this.skin == 4) {
      this.soon = true;
      this.mainTitle = 'Network Services';
      this.subTitle = 'Health of all network devices';
      //health bar values
      this.healthBarGreenTotal = 0;
      this.healthBarGreenValue = 0;
      this.healthBarYellowTotal = 0;
      this.healthBarYellowValue = 0;
      this.healthBarRedTotal = 0;
      this.healthBarRedValue = 0;
      this.healthBarBlueTotal = 0;
      this.healthBarBlueValue = 0;
      //health button values
      this.healthBarBtnCount1 = 0;
      this.healthBarBtnCount2 = 0;
      this.healthBarBtnCount3 = this.healthBarGreenTotal;
      //type health panel icon
      this.iconLink = './assets/img/icon_network_devices_default.png@1X.png';
      //type health devices icon
      this.iconLink2 = null;
      this.iconLink3 = null;
      this.healthBarRouteBtn1 = '';
      this.healthBarRouteBtn2 = '';
      this.healthBarRouteBtn3 = '';
    } else if (this.skin == 5) {
      // iot devices
      this.soon = true;
      this.mainTitle = 'IoT Devices';
      this.subTitle = 'Health of all monitored IoT devices';
      //health bar values
      this.healthBarGreenTotal = 0;
      this.healthBarGreenValue = 0;
      this.healthBarYellowTotal = 0;
      this.healthBarYellowValue = 0;
      this.healthBarRedTotal = 0;
      this.healthBarRedValue = 0;
      this.healthBarBlueTotal = 0;
      this.healthBarBlueValue = 0;
      //health button values
      //health button values
      this.healthBarBtnCount1 = 0;
      this.healthBarBtnLabel1 = 'Impacted Agents';
      this.healthBarBtnColor1 = 'gray';
      this.healthBarBtnCount2 = 0;
      this.healthBarBtnLabel2 = 'All Agents';
      this.healthBarBtnColor2 = 'gray';
      this.healthBarBtnCount3 = 0;
      this.healthBarBtnLabel3 = 'All Devices';
      this.healthBarBtnColor3 = 'gray';
      //type health panel icon
      this.iconLink = './assets/img/icon_network_devices_default.png@1X.png';
      //type health devices icon
      this.iconLink2 = null;
      this.iconLink3 = null;
      this.healthBarRouteBtn1 = '/iot/allagents';
      this.healthBarRouteBtn2 = '/iot/impactedagents';
      this.healthBarRouteBtn3 = '/iot/alldevices';
    }
  }

  disableRouter() {}
}
