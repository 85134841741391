<div class="polycom-main-menu">
  <div class="polycom-main-menu__left-container">
    <div class="polycom-main-menu__list-items polycom-main-menu__list-items--active" (click)="selectActivePanel(1)"
      [class.active]="activePanel === 1">
      add a device
    </div>

    <div class="polycom-main-menu__list-items polycom-main-menu__list-items--active" (click)="selectActivePanel(2)"
      [class.active]="activePanel === 2" [ngClass]="{'polycom-main-menu__disable': !polyMonitor}">
      list of all device
    </div>

    <div class="polycom-main-menu__switch-toggle">
      <div class="polycom-main-menu__switch-toggle-label">
        polycom monitoring
      </div>
      <div class="polycom-main-menu__switch">
        <div>disabled</div>
        <label class="switch">
          <input type="checkbox" [checked]="polyMonitor" (click)="togglePolyMonitor()" [disabled]="disableToggle" />
          <span class="slider round" [ngClass]="{'toggleDisabled': disableToggle}"></span>
        </label>
        <div>enabled</div>
      </div>

      <div class="polycom-main-menu__switch-toggle-label">
        polycom notification
      </div>
      <div class="polycom-main-menu__switch">
        <div class="">disabled</div>
        <label class="switch">
          <input type="checkbox" [checked]="polyAlert" (click)="togglePolyAlerts()" [disabled]="disableToggle" />
          <span class="slider round" [ngClass]="{'toggleDisabled': disableToggle, 'polycom-main-menu__disable': !polyMonitor}"></span>
        </label>
        <div>enabled</div>
      </div>
    </div>

  </div>
  <div class="polycom-main-menu__right-container">
    <div *ngIf="activePanel === 1" class="components_item">
      <app-polycom-add-device [iotLiteEnabled]="iotLiteEnabled" [polyMonitor]="polyMonitor"></app-polycom-add-device>

    </div>
    <div *ngIf="activePanel === 2" class="components_item">
      <app-polycom-device-list></app-polycom-device-list>
    </div>
  </div>
</div>