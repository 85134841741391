import { Component, Input, OnInit, SimpleChanges } from '@angular/core'

import { environment } from '../../../environments/environment'
import { PopupTypeService } from '../../services/popupType.service'

@Component({
	selector: 'app-collector-host-utilization',
	templateUrl: './collector-host-utilization.component.html',
	styleUrls: ['./collector-host-utilization.component.scss'],
})
export class CollectorHostUtilizationComponent implements OnInit {
	@Input() collectorHostUtilization: any = '--'

	collectorCpu: any
	collectorRam: any
	collectorDrives: any = []

	collectorLiveStats: any

	iconAddSite = environment.config.dynamicImage.iconAddSite
	iconVirtual = environment.config.dynamicImage.iconVirtual

	constructor(private ds: PopupTypeService) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.collectorHostUtilization && this.collectorHostUtilization) {
			this.setData()
		}
	}

	setData = () => {
		const { cpu_used, memory_used, drive_details } = this.collectorHostUtilization

		this.collectorCpu = cpu_used !== null ? cpu_used : '--'
		this.collectorRam = memory_used

		if (drive_details?.length > 0) {
			this.collectorDrives = drive_details
				.map(drive => ({
					drive: drive.drive.split(':')[0].toUpperCase(),
					free: Math.round((drive.free / drive.total) * 100),
					total: Math.round(drive.total / Math.pow(1024, 3)),
				}))
				.filter(drive => drive.total > 0)
		}
	}

	getSiteTypeIcon = bool => {
		return bool ? this.iconAddSite : this.iconVirtual
	}
}
