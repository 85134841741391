import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wholeNumber'
})
export class WholeNumberPipe implements PipeTransform {

  transform(value: any): any {
    return Math.trunc(parseInt(value));
  }

}