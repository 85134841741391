import { RefreshApiService } from './../../services/refresh-api.service';
import { Component, OnInit, Input, OnDestroy, DoCheck, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';

import { GetNotificationNiaService } from '../../services/get-notification-nia.service';
import { NotificationsService } from '../../services/notifications.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import * as moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from '../../../environments/environment';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from 'src/app/_alert'
@Component({
  selector: 'app-slack-slideout',
  templateUrl: './slack-slideout.component.html',
  styleUrls: ['./slack-slideout.component.scss'],
})
export class SlackSlideoutComponent implements OnInit, OnDestroy {
  // slackState: string = this.Auth.accessLevel == 'SUPERADMIN' ? 'open' : 'close';
  @Input() slackState;
  @Output() slackStateChange = new EventEmitter()
  @Output() newNotificationsCountChange= new EventEmitter();
  opacity: any = this.Auth.accessLevel == 'SUPERADMIN' ? 0 : 0.5;
  auto: any = this.Auth.accessLevel == 'SUPERADMIN' ? 'none' : 'auto';
  isSuperAdmin: any = this.Auth.accessLevel == 'SUPERADMIN' ? true : false;
  spanelState: string = 'close';
  notify: number = 0;
  ranNum: number = Math.floor(Math.random() * 10 + 1);
  allsite$;
  notificationsData$;
  urlChangeState = 'open';
  refreshData: any;
  refreshListner: Subscription;
  slackCount: any;
  oldSlackCount: any;
  backcolorbtn: any;
  changeDetected: boolean;
  // newestStamp = 0;
  notifImage = environment.config.dynamicImage.notifImage;
  dCollector = environment.config.dynamicText.dCollector;
  dPlugin = environment.config.dynamicText.dPlugin;
  desktopPlugin = environment.config.dynamicText.deskPlugin;
  faIconTimes: any = faTimesCircle;
   beingDeleted = false;
  options = {
      autoClose: true,
      keepAfterRouteChange: false
    };
  constructor(
    private allsitesService: GetNotificationNiaService,
    private router: Router,
    protected alertService: AlertService,
    private notificationService: NotificationsService,
    private refreshApiService: RefreshApiService,
    private Auth: AuthService
  ) {
    router.events.subscribe((val) => {
      this.urlChangeState = 'close';
    });
    this.refreshListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      this.refreshData = m;
      this.getNotifications();
    });
  }

  ngOnInit() {
    this.getNotifications();
    this.fetchVisoneSites();
  }

  fetchVisoneSites() {
    this.allsite$ = this.allsitesService.fetchVisoneSites();
  }

  toggleSlackClose() {
    this.setSlackState('close')
    this.urlChangeState = 'close';
  }

  // toggleSlack() {
  //   this.notify = 0;
  //   this.setSlackState(this.slackState === 'open' ? 'close' : 'open')
  // }

  setSlackState(value) {
    this.slackState = value
		this.slackStateChange.emit(this.slackState)
  }

  setnewNotificationsCount(value) {
		this.newNotificationsCountChange.emit(value)
	}

  getNotifications() {
    this.notificationService.apiGetNotificationList().subscribe((data) => {
      this.notificationsData$ = data['response'] || [];
       this.beingDeleted = data['isBeingDeleted'];
      // console.log('notificationService$: ', this.notificationsData$)
      // if (this.notificationsData$.length > 0) {
      //   if (this.newestStamp < this.notificationsData$[0].timestamp) {
      //     let newNotificationsCount = 0
      //     for (let notification of this.notificationsData$) {
      //       if (this.newestStamp < notification.timestamp)
      //         newNotificationsCount++
      //     }
      //     this.setnewNotificationsCount(newNotificationsCount)
      //     this.newestStamp = this.notificationsData$[0].timestamp;
      //     this.notify = 1;
      //   } else {
      //     this.newestStamp = this.notificationsData$[0].timestamp;
      //     setTimeout(() => { }, 2500);
      //   }
      // } else {
      //   this.newestStamp = 0;
      // }
      if (this.notificationsData$.length > 0) {
        const newNotificationsCount = this.notificationsData$.filter(notification => notification.seen === 0).length
        this.setnewNotificationsCount(newNotificationsCount)
			}
    });
  }

  markAsRead(notification: any) {
    const { company_id, notification_id, read } = notification
    if (!read) {
      this.notificationService.markNotificationAsRead(company_id, notification_id).subscribe(data => {
        notification.read = 1
			})
    }
  }

  deleteNotification(event: any, company_id, notification_id) {
    event.preventDefault();
    this.notificationService.deleteNotification(company_id, notification_id).subscribe((data) => {
      this.getNotifications();
    });
  }

  deleteAllNotifications() {
      if (this.beingDeleted) {
      console.log("Being deleted already");
      this.alertService.error("A delete notifications request is already being processed", this.options);
      return;
    }
    this.beingDeleted = true;
    this.notificationService.deleteAllNotifications().subscribe((data) => {
      this.alertService.success("Notifications are being deleted at the moment", this.options);   
      this.getNotifications();
    });
  }

  // printTime(t) {
  //   let a = moment(t).utc();
  //   return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
  // }

  checkIfNotifIsRead = (notif) => (notif === 1 ? 'remove-bold' : '');

  getZoomIncidentTitle = (is_room) => {
    if (is_room === 1) {
      return 'Room';
    }
    if (is_room === -1) {
      return 'Zoom Account';
    }
    if (is_room === 0) {
      return 'User';
    }
  };

  isIotDomotzAgent = (message) => {
    if (message.toUpperCase().search('AGENT') !== -1) {
      return true;
    }
    return false;
  };

  checkIfNotificationIsNotEmptyForCollector = (message) => {
    return message !== null ? message.replace('Collector', this.dCollector) : '';
  };

  checkIfNotificationIsNotEmptyForPlugin = (message) => {
    return message !== null ? message.replace('Plugin', this.dPlugin) : '';
  };
  
  ngOnDestroy() {
    this.refreshListner.unsubscribe();
  }
}
