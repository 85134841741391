// import { Component, Inject, EventEmitter, AfterViewInit } from '@angular/core';
import { Component, OnInit, Inject, AfterViewInit, EventEmitter, ViewChild, ElementRef } from '@angular/core'
import { GetDesktopPluginService } from '../../../services/get-desktop-plugin.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil, throttleTime } from "rxjs/operators";
import { VsMenuDesktopClientService } from '../../../services/vs-menu-desktop-client.service';
declare var $: any;

@Component({
  selector: 'app-link-desktop-plugin-dialog',
  templateUrl: './link-desktop-plugin-dialog.component.html',
  styleUrls: ['./link-desktop-plugin-dialog.component.scss'],
})

export class LinkDesktopPluginDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('searchElement', { static: false }) searchElement: ElementRef;
  modalList: any;
  showLinkClientUser: boolean = false;
  clientToLink: any = '';
  hostName: any = '';
  desktopPluginId: any;
  zoomUserId: any;
  isLoading: any = false;
  userActive: any;
  sendPopupType: EventEmitter<any>;
  searchKey: any;
  btnLoader: string = environment.config.dynamicImage.loader;
  serviceType: any = 'zoom';
  action: string;
  eventEmitterDesktop: any;
  totalTeamsCount: any;
  totalZoomCount: any;
  numbers: any;
  subscription = new Subscription;
  usersCount: number = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();
  usersLoading: boolean;

  @ViewChild('scrollBarElement', { static: true }) public scrollBarElement: ElementRef;
  constructor(
    private desktopPluginService: GetDesktopPluginService,
    public vsMenuDesktopClientService: VsMenuDesktopClientService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LinkDesktopPluginDialogComponent>
  ) {
    if (data) {
      const {
        config,
        user: { assigned_user, host_name, desktop_plugin_id, zoom_user_id, active },
        sendPopupType,
        eventEmitterDesktop,
        serviceType,
        action,
        totalZoomCount,
        totalTeamsCount
      } = data;
      this.clientToLink = assigned_user;
      this.hostName = host_name;
      this.desktopPluginId = desktop_plugin_id;
      this.zoomUserId = zoom_user_id;
      this.sendPopupType = sendPopupType;
      this.userActive = active;
      this.serviceType = serviceType;
      this.action = action;
      this.modalList =
        config || [];      
      this.usersCount = this.modalList.length
      this.totalZoomCount = totalZoomCount
      this.totalTeamsCount = totalTeamsCount
      this.eventEmitterDesktop = eventEmitterDesktop;
    }
  }

  ngOnInit() {
    this.subscription.add(
      this.vsMenuDesktopClientService.watchMe$.subscribe((item: any) => {
        const { users, totalTeamsCount, totalZoomCount} = item
        this.modalList = users || [];
        this.usersCount = this.modalList.length
        this.totalTeamsCount = totalTeamsCount
        this.totalZoomCount = totalZoomCount
        this.usersLoading = false
      })
    )
  }

  ngAfterViewInit() {
    fromEvent(this.searchElement.nativeElement, 'input')
      .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
      .pipe(debounceTime(300))
      .pipe(distinctUntilChanged())
      .subscribe(data => this.searchNotTyping());

    // if ((this.usersCount < this.totalTeamsCount) || (this.usersCount < this.totalZoomCount)) {
      this.initOnScrollEvents()
    // }
  }

  searchNotTyping = () => {
    let emitterData = {
      event: "searchUser",
      searchKey: this.searchKey,
    };
    this.usersLoading = true
    this.eventEmitterDesktop(emitterData);
  }

  unlinkDesktopPlugin = () => {
    this.isLoading = true;
    // // console.log(this.desktopPluginId, this.serviceType)
    this.desktopPluginService.unlinkDesktopPlugin(this.desktopPluginId, this.serviceType).subscribe(
      (data) => {
        this.sendPopupType.emit('desktopClientList');
        this.closeDialog();
      },
      (err) => { }
    );
  };

  linkDesktopPlugin = () => {
    const params = {
      desktop_plugin_id: this.desktopPluginId,
      zoom_user_id: this.zoomUserId,
      service_type: this.serviceType,
    };
    this.isLoading = true;
    this.desktopPluginService.linkDesktopPlugin(params).subscribe(
      (data) => {
        this.sendPopupType.emit('desktopClientList');
        this.closeDialog();
      },
      (err) => { }
    );
  };

  getPluginTypeImage = (type) => {
    return type === 'zoom'
      ? './assets/img/zoom_logo.png'
      : './assets/img/logo-microsoft-teams-2.png';
  };

  openLinkClient = ({ zoom_user_id, firstname, service_type }) => {
    this.clientToLink = firstname;
    this.showLinkClientUser = true;
    this.zoomUserId = zoom_user_id;
    this.serviceType = service_type;
  };

  closeLinkClient = () => {
    this.showLinkClientUser = false;
  };

  closeDialog = () => {
    this.isLoading = false;
    this.dialogRef.close(true);
  };

  getModalTitle = () => {
    if (this.action === 'link') {
      return `LINK ${this.serviceType.toUpperCase()} CLIENT TO USER`;
    }

    if (this.action === 'unlink') {
      return `UNLINK ${this.serviceType.toUpperCase()} CLIENT TO USER`;
    }

    return 'title';
  };

  getUsersToLinkName = ({ firstname }) => {
    return firstname;
  };

  initOnScrollEvents(){
    const elementContainer = document.querySelector("#scroll-bar");
    console.log("Whats in element container" , elementContainer );
    
    const observable = fromEvent( elementContainer , 'scroll');
    const subscriber = observable
    .pipe(
      throttleTime(500 /* ms */),
      debounceTime(1000),
      takeUntil(this.destroy$)
    )
    .subscribe(
      (x) => {
        const totalUsersCount = this.serviceType === 'teams' ? this.totalTeamsCount : this.totalZoomCount
        if (this.usersCount < totalUsersCount) {
          var a = elementContainer.scrollTop
          var b = elementContainer.scrollHeight - elementContainer.clientHeight
          var c = (a / b) * 100

          if (~~c > 80) {
            let emitterData = {
							event: 'loadMoreUsers',
							searchKey: this.searchKey,
            }
            this.usersLoading = true
            this.eventEmitterDesktop(emitterData)
          }
        }
        // else {
        //   this.destroy$.next()
        // }
      }
    );
  }

  myScroller = () => {
    const triggerHeight = this.scrollBarElement.nativeElement.scrollTop + this.scrollBarElement.nativeElement.clientHeight;
    if (Math.trunc(triggerHeight) == this.scrollBarElement.nativeElement.scrollHeight) {
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  reduceEmailLength = (string) => {
    return string
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
