<button type="button" [routerLink] = "['login']" class="btn-long btn-block">
<!-- <button type="button" [routerLink] = "['login']" class="btn-long btn-block" (click)="gotoSite(activeDevice.device_id)"> -->
  <ng-container *ngIf="activeDevice == 1; else unMuted">
      <img *ngIf="mute == 0; else Muted" src="../../../../assets/img/audio/icon_mute.png" alt="User Status"/>
  </ng-container>
  <ng-template #Muted>
      <img *ngIf="mute == 1; else unMuted" src="../../../../assets/img/icon_unmute.png" alt="User Status"/>
  </ng-template>
  <ng-template #unMuted>
      <h3 class="float-right pr-4">--</h3>
  </ng-template>
  <h3 *ngIf="activeDevice == 1; else noData">{{ name }}</h3>
  <p>Active Device</p>
  <ng-template #noData>
    <h3>--</h3>
  </ng-template>
</button>