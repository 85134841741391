<div class="livestats">
  <div class="livestats__title">
    <h5>Live Stats</h5>
  </div>

  <div class="livestats__stats_ctr">
    <div class="livestats__stats_item">
      <div class="livestats__stats_item_data {{ checkRtdAlert() }}">
        <h3>{{ pingRtd | displayEmpty }}</h3>
        ms
      </div>
      <div class="livestats__stats_item_label">Ping RTD</div>
    </div>

    <div class="livestats__stats_item">
      <div class="livestats__stats_item_data {{ checkPacketLossAlert() }}">
        <h3>
          {{ packetLoss | displayEmpty}}
        </h3>
        %
      </div>
      <div class="livestats__stats_item_label">Packet Loss</div>
    </div>
  </div>
</div>