<div class="register" [ngStyle]="visibilityBackground">
  <!-- <alert></alert> -->

  <div class="register__col">
    <div class="register__box">
      <h2>Free 30-day trial!</h2>
      <hr />
      <h2>Access to all features.</h2>
      <h2>Monitor an unlimited</h2>
      <h2>number of devices and</h2>
      <h2>services.</h2>
    </div>
    <div class="register__box">
      <div class="register__box-title">
        <h2>Sign Up</h2>
      </div>

      <div class="register__input-container">
        <input class="{{ formControl.company.style }}" type="text" placeholder="Company Name" value="register.company"
          [(ngModel)]="register.company" (keyup)="keyEvent($event, 'company')" />
        <div class="register__i">
          <i class="fa fa-building-o register__icon"></i>
        </div>
      </div>

      <div class="register__container-input-sm">
        <input class="margin-left {{ formControl.firstname.style }}" type="text" placeholder="First Name"
          value="register.firstname" [(ngModel)]="register.firstname" (keyup)="keyEvent($event, 'firstname')" />

        <input class="{{ formControl.lastname.style }}" type="text" placeholder="Last Name" value="register.lastname"
          [(ngModel)]="register.lastname" (keyup)="keyEvent($event, 'lastname')" />
        <div class="register__i">
          <i class="fa fa-user register__icon-sm"></i>
        </div>
      </div>

      <div class="register__input-container">
        <input class="{{ formControl.email.style }}" type="text" placeholder="Your Work Email Address"
          value="register.email" [(ngModel)]="register.email" (keyup)="keyEvent($event, 'email')" />
        <div class="register__i">
          <i class="fa fa-user register__icon-sm"></i>
        </div>
      </div>
      <div *ngIf="formControl.email.flag" class="error-msg">
        {{ formControl.email.message }}
      </div>

      <div class="register__input-container">
        <input class="{{ formControl.phone.style }}" type="text" placeholder="User Phone" value="register.phone"
          [(ngModel)]="register.phone" (keyup)="keyEvent($event, 'phone')" />
        <div class="register__i register__tooltip">
          <div class="register__tooltip-container">
            <i class="fa fa-info-circle register__icon"></i>
            <span class="register__tooltiptext">
              <p>Your personal mobile or office</p>
              <p>
                desk number.
              </p>
            </span>
          </div>
        </div>
      </div>

      <div *ngIf="formControl.phone.flag" class="error-msg">
        {{ formControl.phone.message }}
      </div>

      <div class="register__input-container">
        <input class="{{ formControl.password.style }} register__input-sm" type="password" placeholder="Password"
          value="register.password" [(ngModel)]="register.password" (keyup)="keyEvent($event, 'password')" />
        <div class="register__i">
          <i class="fa fa-key register__icon"></i>
        </div>
      </div>
      <div *ngIf="formControl.password.flag" class="error-msg">
        {{ formControl.password.message }}
      </div>

      <div class="register__input-container">
        <input class="{{ formControl.confirm.style }}  register__input-sm" type="Password" placeholder="Repeat Password"
          value="register.confirm" [(ngModel)]="register.confirm" (keyup)="keyEvent($event, 'confirm')" />
        <div class="register__i">
          <i class="fa fa-key register__icon"></i>
        </div>
      </div>

      <div *ngIf="formControl.confirm.flag" class="error-msg">
        {{ formControl.confirm.message }}
      </div>

      <div class="register__terms">
        <input class="{{ formControl.checkbox.style }}" type="checkbox" [(ngModel)]="register.checkbox"
          #registerCheckbox (change)="checkboxEvent(registerCheckbox.checked)" />
        <a href="{{ links.terms }}" target="_blank" [ngClass]="{
            'error': formControl.checkbox.style == 'error'
          }">
          I agree to the terms and conditions.</a>
      </div>

      <div class="register__message" *ngIf="message.flag">
        {{ message.text }}
      </div>

      <button [ngClass]="{
          'disable-btn': disableBtn
        }" class="register__btn-primary" (click)="validateForm()">
        Sign{{beingRegistrationFormSubmitted ? 'ing ' : ' '}}Up
      </button>
    </div>
  </div>
</div>