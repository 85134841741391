<div class="row pt-2 pb-2 right-panel">
  <div class="col-12">
    <app-panel-tools [PopupTitle]="PopupTitle"></app-panel-tools>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-6 pl-1">
        <app-cloud-all-rooms-info [cloudRoomSummary]="cloudRoomSummary"></app-cloud-all-rooms-info>
      </div>
      <div class="col-6">
        <div class="col-12 px-0">
          <app-system-health [healthNum]="cloudRoomSummary?.health"></app-system-health>
        </div>
        <app-cloud-all-rooms-stats [cloudRoomSummary]="cloudRoomSummary"></app-cloud-all-rooms-stats>
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-1 filter-table">
        <app-cloud-all-rooms-list [cloudRoomData]="cloudRoomsListData">
        </app-cloud-all-rooms-list>
      </div>
    </div>

    <div *ngIf="loading" style="position: absolute; top: 50%; z-index: 7; width: 100%;">
      <div class="loader-icon">
        <img [src]="loader" />
      </div>
    </div>
  </div>
</div>