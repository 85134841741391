<div class="video-remote">
  <h5>Display Settings</h5>
  <div class="video-remote__image-and-text">
    <img src="{{ touchIcon }}" alt="polycom_touch">
    <div class="video-remote__text">
      <div class="video-remote__text-item">
        <h6>Name: </h6>
        <div>{{displayName | displayEmpty}}</div>

      </div>
      <div class="video-remote__text-item">
        <h6>MAC: </h6>
        <div>{{displayMac | displayEmpty}}</div>

      </div>
      <div class="video-remote__text-item">
        <h6>Battery Level: </h6>
        <div>{{displayBatteryLevel | displayEmpty}}</div>

      </div>
      <!-- <div class="video-remote__text-item">
        <h6>Display Width:</h6>
        <div>{{displayName | displayEmpty}}</div>

      </div> -->

    </div>
  </div>
</div>