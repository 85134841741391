import { Component, OnInit, Input } from '@angular/core';
import * as Chart from 'chart.js';
import { AuthService } from  '../../../services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';
import { RefreshApiService } from '../../../services/refresh-api.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-chart-audio60-min-view',
  templateUrl: './chart-audio60-min-view.component.html',
  styleUrls: ['./chart-audio60-min-view.component.scss']
})
export class ChartAudio60MinViewComponent implements OnInit {
  @Input() audioDeviceId: number;
  @Input() isOn: boolean;
  canvas: any;
  ctx: any;
  mos60MinChart: any;
  id: number = 0;
  totaCalls: number = 0;
  chartApiData:any;
  loading: boolean = true;
  chartData: any = {};
  filterListner: Subscription;

  constructor(
    private Auth: AuthService,
    private refreshApiService: RefreshApiService) {
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if(m && this.audioDeviceId &&  this.isOn) {
        this.fetchChartData();
      }
    });
  }

  ngOnInit() {
    this.canvas = document.getElementById('mos60MinChart');
    this.ctx = this.canvas.getContext('2d');
    
    const chartOptions = {
      responsive: false,
      display: true,
      maintainAspectRatio: false,
      elements: {
        line: {
            tension: 0, // disables bezier curves
        }
      },
      tooltips: {
        callbacks: {
          title: function(tooltipItem, chart) {
            const d = new Date(tooltipItem[0].label);
            return d.toLocaleTimeString();
          },
          label: function(tooltipItem, chart) {
            if (tooltipItem.datasetIndex == 1) {
              const incident = chart.datasets[1].data[tooltipItem.index];
              return "Incident ID: " + incident.incident_id;
            }
            else {
              return tooltipItem.yLabel
            }
          },
          beforeLabel: function(tooltipItem, chart) {
            switch(tooltipItem.datasetIndex) {
              case 0: 
                return "Mos";
              case 1: 
                return "Incidents";
            }
          },
          afterLabel: function(tooltipItem, chart) {
            if (tooltipItem.datasetIndex == 1) {
              let triggers = chart.datasets[1].data[tooltipItem.index].triggers.split(" - ");
              triggers.splice(0,1);
              return triggers;
            }
            else {
              return ""
            }
          },
        }
      },
      scales: {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Time'
          },
          ticks: {
            callback: function(value, index, values) {
              const d = new Date(value);
              return d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}).replace(/\s+/g, '');
            }
          }
        }],
        yAxes: [{
            ticks: {
                beginAtZero:true,
                min: 0,
                max: 5
            },     
        }],
      }
    };

    const chartData = {
      datasets: [
        {
          label: 'MOS',
          data : [],
          backgroundColor: 'rgb(255, 184, 0, .5)',
          pointRadius: 1,
          borderWidth: 1
        },
        {
          label: 'Incidents',
          data : [],
          backgroundColor:'rgb(184, 58, 36, 1)',
          pointBackgroundColor: 'rgb(184, 58, 36, 1)',
          pointBorderColor: 'rgb(184, 58, 36, 1)',
          pointRadius: 2,
          showLine: false,
          borderWidth: 1,
        }
      ]
    };

    const chartConfig = {
      type: 'line',
      data: chartData,
      options: chartOptions
    };

    this.mos60MinChart = new Chart(this.ctx, chartConfig);

    if(this.audioDeviceId && this.isOn) {
      this.fetchChartData();
    }
  }

  fetchChartData(){
    this.loading = true;
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const deviceDetails = {
      company_id: decodedToken.company_id,
      device_id: this.audioDeviceId,
      duration: 1
    }

    this.Auth.getMosCharData(deviceDetails).subscribe((data) => { 
      let total_mos = [];
      let incidents = [];
      this.totaCalls =  data['response'][0].total_calls;
      if(data['response'][0].total_mos !== null && data['response'][0].total_mos){
        total_mos = data['response'][0].total_mos;
        // total_mos = total_mos.filter(m => m.y !== 0);
        total_mos.sort(this.custom_sort);
      }

      if(data['response'][0].incidents !== null && data['response'][0].incidents){
        incidents = data['response'][0].incidents;
        // incidents = incidents.filter(i => i.y !== 0);
        incidents.sort(this.custom_sort2);
      }
      
      let xAxes = _.union(total_mos.map(m => m.x), incidents.map(i => i.x));
      xAxes.sort(this.custom_sort2);
      
      this.mos60MinChart.reset();
      this.mos60MinChart.data.labels = this.getXaxes(xAxes);
      this.mos60MinChart.data.datasets[0].data = total_mos;
      this.mos60MinChart.data.datasets[1].data = incidents;
      this.mos60MinChart.update({duration: 1000});
      this.loading = false;
    });
  }

  custom_sort(a, b) {
    return new Date(a.x).getTime() - new Date(b.x).getTime();
  }

  custom_sort2(a, b) {
    return new Date(a).getTime() - new Date(b).getTime();
  }

  getXaxes(xAxes:any[]):any[]{
    if(xAxes.length === 0){
      let xStart = new Date();
      let xEnd = new Date();
      xEnd.setMinutes(xEnd.getMinutes() + 5);

      return [xStart, xEnd];
    }

    const size = xAxes.length;
    let xStart = new Date(xAxes[0]);
    xStart.setMinutes(xStart.getMinutes() - 5);
    let xEnd = new Date(xAxes[size-1]);
    xEnd.setMinutes(xEnd.getMinutes() + 5);
    
    return [
      xStart,
      ...xAxes,
      xEnd
    ];
  }

  ngOnDestroy() {
    if(this.mos60MinChart){
      this.mos60MinChart.destroy();
    }
    this.filterListner.unsubscribe();
  }
}