import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teams-room-monitoring-disabled',
  templateUrl: './teams-room-monitoring-disabled.component.html',
  styleUrls: ['./teams-room-monitoring-disabled.component.scss']
})
export class TeamsRoomMonitoringDisabledComponent implements OnInit {
  dPlugin = environment.config.dynamicText.dPlugin;
  constructor(
    public dialogRef: MatDialogRef<TeamsRoomMonitoringDisabledComponent>
  ) { }

  ngOnInit() {
  }

  closeDialog = () => {
    this.dialogRef.close(true);
  };

}
