import { phone } from 'phone'

import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  email: any;
  password: any;

  message: any = { text: 'Email address not found', flag: false };
  message2: any = { text: 'Thankyou for register', flag: false, type: 1 };
  setTImer: any;
  loginEnv: any;

  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.checkRouterUrl();
  }

  ngOnInit() {
    this.setData();
  }

  setData = () => {
    if (
      environment.config.title === 'VisibilityOne' ||
      environment.config.title === 'vCare' ||
      environment.config.title === 'Vision Point' ||
      environment.config.title === 'Videlio' ||
      environment.config.title === 'DEKOM'
    ) {
      this.loginEnv = true;
    } else {
      this.loginEnv = false;
    }
  };

  checkRouterUrl = () => {
    this.route.queryParams.subscribe((params) => {
      const message = params.message
      const flag = params.flag
      const type = params.type
      if (flag) {
        this.setMessageForLogin({ message }, false, type)
      }
    });
  };

  loginUser = async (username, password) => {
    this.Auth.getUserDetails(username, password).subscribe((data: any) => {
			// moved the logic here to 'onSuccessfullLogin' method of Auth service so that it can be reused in login with SSO
		}, error => {
      this.setMessageForLogin(error, true)
    });
  };

  loginEmitEvent = ({ email, password }) => {
    this.loginUser(email, password);
  };

  setMessageForLogin = ({ message }, flag, type = 1) => {
    if (message) {
      if (flag) {
        this.message = {
          text: message,
          flag: true,
        };
      } else {
        this.message2 = {
          flag: true,
          type,
        };
      }
    }
  };
}