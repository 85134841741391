import { Component, AfterViewInit } from '@angular/core';
import * as Chart from 'chart.js';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PopupTypeService } from '../../../services/popupType.service';
import { GetVideoService } from '../../../services/get-video.service';
import { PopupSendDataService } from '../../../services/popup-send-data.service';
import { RefreshApiService } from '../../../services/refresh-api.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-chart-cloud-mbps',
  templateUrl: './chart-cloud-mbps.component.html',
  styleUrls: ['./chart-cloud-mbps.component.scss'],
})
export class ChartCloudMbpsComponent implements AfterViewInit {
  canvas: any;
  ctx: any;
  myChartSite: any;
  currentChart = '24h';
  id: number;
  chartApiData: any;
  filterListner: Subscription;

  chartData = {
    datasets: [
      {
        label: 'Mbps',
        data: [],
        backgroundColor: ['rgb(0, 128, 255, .5'],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)', //red
        borderWidth: 0,
        stepped: true,
      },
      {
        label: 'Mbps-In',
        data: [],
        backgroundColor: ['rgb(3, 173, 20, .5'],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)', //red
        borderWidth: 0,
      },
      {
        label: 'Mbps-Out',
        data: [],
        backgroundColor: ['rgb(188, 71, 3, .5'],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)', //red
        borderWidth: 0,
      },
    ],
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ds: PopupTypeService,
    private sendPopupDataService: PopupSendDataService,
    public http: HttpClient,
    private Auth: AuthService,
    private refreshApiService: RefreshApiService
  ) {
    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });

    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if (m && this.id) {
        this.fetchChartData();
      }
    });
  }

  ngAfterViewInit() {
    this.canvas = document.getElementById('myChartSite');
    this.ctx = this.canvas.getContext('2d');
    this.myChartSite = new Chart(this.ctx, {
      type: 'line',
      // data: this.data24h,
      options: {
        responsive: true,
        display: true,
        fontSize: '6px',
        padding: '0px',
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0, // disables bezier curves
          },
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, chart) {
              const d = new Date(tooltipItem[0].label);
              return d.toLocaleTimeString();
            },
          },
        },
      },
    });
    this.currentChart = '24h';
    this.fetchChartData();
  }

  fetchChartData() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    // // console.log("inside the fetchChartData for deviceID & company Id::",this.id, decodedToken.company_id);

    const deviceDetails = {
      company_id: decodedToken.company_id,
      user_id: this.id,
      duration: 1,
    };

    this.chartApiData = [];
    this.Auth.getZoomMbpsChartData(deviceDetails).subscribe((data) => {
      let mbps = data['response'][0].mbps_total.map((d) => {
        if (d.y === 0) {
          delete d.y;
        }
        return d;

      });
      mbps.sort(this.custom_sort);
      let mbps_out = data['response'][0].mbps_out.map((d) => {
        if (d.y === 0) {
          delete d.y;
        }
        return d;

      });
      mbps_out.sort(this.custom_sort);
      let mbps_in = data['response'][0].mbps_in.map((d) => {
        if (d.y === 0) {
          delete d.y;
        }
        return d;
        // return { x: "05/26/2021 01:35:52Z", y: 10.07 };

      });
      mbps_in.sort(this.custom_sort);

      this.chartData.datasets[0].data = mbps;
      this.chartData.datasets[1].data = mbps_in;
      this.chartData.datasets[2].data = mbps_out;

      this.loadChart();
    });
  }

  custom_sort(a, b) {
    return new Date(a.x).getTime() - new Date(b.x).getTime();
  }

  loadChart() {
    this.myChartSite.reset();
    this.myChartSite.data.datasets = this.chartData.datasets;
    this.myChartSite.options.scales = {
      xAxes: [
        {
          type: 'time',
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Time',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    };
    this.myChartSite.update({ duration: 1000 });
  }

  ngOnDestroy() {
    if (this.myChartSite) {
      this.myChartSite.destroy();
    }
    this.filterListner.unsubscribe();
  }
}
