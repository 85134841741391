<div id="site-overlay" [class.tut-show]="setoverlay == 'create'"></div>
<div class="tut-create col-4" [class.tut-show]="setoverlay == 'create'">
  Please complete the form to create your first site.
</div>
<div class="container-fluid" id="main-popup" [class.tut-overlay]="setoverlay == 'create'">
  <div class="row" style="height: 90%; overflow: scroll;">
    <div class="col-12">
      <form role="form" class="signup" name="siteForm" [formGroup]="siteForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="form-group"></div>
        <div class="form-group">
          <input type="text" formControlName="sitename" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.sitename.errors }" placeholder="Sitename" name="sitename"
            id="sitename" autocomplete="additional-name" required />
          <div *ngIf="submitted && f.sitename.errors" class="invalid-feedback">
            <div *ngIf="f.sitename.errors.required" id="confirmMessage">!</div>
          </div>
        </div>

        <div class="form-group">
          <input type="text" formControlName="address1" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" placeholder="Address1" name="address1"
            id="address1" autocomplete="street-address" required />
          <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
            <div *ngIf="f.address1.errors.maxlength" class="phonetext alert alert-danger p-2 m-0">
              Exceed Maximum 50 character
            </div>
            <div *ngIf="f.address1.errors.required" id="confirmMessage">!</div>
          </div>
        </div>

        <div class="form-group">
          <input type="text" class="form-control" id="address2" placeholder="Site Address2"
            autocomplete="address-line2" />
        </div>

        <div class="form-group">
          <input type="text" formControlName="city" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.city.errors }" [ngModel]="siteDetails?.city" placeholder="City"
            name="city" id="city" autocomplete="city" required />
          <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
            <div *ngIf="f.city.errors.required" id="confirmMessage">!</div>
          </div>
        </div>

        <!-- <div *ngIf="showWellnessInProd" style="margin-bottom: 1rem;">
          <div class="wellness-check">
            <div>
              WELLNESS CHECK
            </div>
            <div>
              <input type="checkbox" [checked]="wellnessCheckEnable" (change)="onChangeWellnessCheckValue($event)">
            </div>
            <div>
              <ngb-timepicker [ngClass]="{'componentDisable':  !wellnessCheckEnable}" class="changeSize"
                [(ngModel)]="wellnessCheckTime" id="endTime" [spinners]="false" [meridian]="true"
                [ngModelOptions]="{standalone: true}"></ngb-timepicker>
            </div>
          </div>

          <div>
            Interval: Everyday
          </div>
        </div> -->

        <div class="form-group">
          <select #createSitSetCountry (change)="setCountry(createSitSetCountry.value)" type="text"
            formControlName="country" class="form-control" placeholder="Country" name="country" id="country"
            autocomplete="country" required>
            <option value="" selected disabled>Select Country</option>
            <option *ngFor="let countries of allCountryListData; let i = index" value="{{ countries.id }}">{{
              countries.name }}</option>
          </select>
          <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
            <div *ngIf="f.country.errors.required" id="confirmMessage">!</div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-9">
              <select type="text" formControlName="state" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.state.errors }" placeholder="State/Province/Region" id="state"
                autocomplete="state" required>
                <option value="" disabled selected>State</option>
                <option *ngFor="let states of allStatesListData; let i = index" value="{{ states.name }}">{{ states.name
                  }}</option>
              </select>
              <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                <div *ngIf="f.state.errors.required" class="phonetext alert alert-danger p-2 m-0">
                  Select State
                </div>
                <div *ngIf="f.state.errors.required" id="confirmMessage">!</div>
              </div>
            </div>
            <div class="col-3">
              <input type="text" formControlName="zip" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.zip.errors }" [ngModel]="siteDetails?.zip" placeholder="Zip"
                id="zip" autocomplete="postal-code" required />
              <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
                <div *ngIf="f.zip.errors" class="zip-text alert alert-danger p-2 m-0">
                  Please enter a valid Zipcode of the country
                </div>
                <div *ngIf="f.zip.errors.required" id="confirmMessage">!</div>
              </div>
            </div>
          </div>
        </div>

        <h4 class="left">
          <span>
            Add Contact
          </span>
        </h4>
        <div class="form-group">
          <select #selectInputSetIndex (change)="setIndex(selectInputSetIndex.value)" class="form-control"
            id="add_contact" required>
            <option value="-1">Add New User</option>
            <option *ngFor="let contactList of userApiData; index as n" value="{{ n }}">
              {{ contactList.first_name }} {{ contactList.last_name }}
            </option>
          </select>
        </div>
        <!-- FORM TO CREATE NEW USER -->
        <div>
          <input type="text" [(ngModel)]="user_id" formControlName="user_id" class="form-control" id="user_id"
            placeholder="user id" required hidden />
          <div class="form-group">
            <div class="row">
              <div class="col-6">
                <input type="text" [(ngModel)]="firstname" formControlName="firstname" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" placeholder="First Name"
                  name="firstname" id="firstname" autocomplete="given-name" required />
                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                  <div *ngIf="f.firstname.errors.required" id="confirmMessage">!</div>
                </div>
              </div>
              <div class="col-6">
                <input type="text" [(ngModel)]="lastname" formControlName="lastname" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" placeholder="Last Name" name="lastname"
                  id="lastname" autocomplete="family-name" value="{{ userLastname }}" required />
                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                  <div *ngIf="f.lastname.errors.required" id="confirmMessage">!</div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <input type="email" [(ngModel)]="email" formControlName="email" class="form-control" placeholder="email"
              name="email" id="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email"
              autocomplete="email" required />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.maxlength" class="phonetext alert alert-danger p-2 m-0">
                Exceed Maximum 50 character
              </div>
              <div *ngIf="f.email.errors.required" id="confirmMessage">!</div>
            </div>
          </div>
          <!-- ERROR MESSAGE -->
          <p id="feedback" class="feedback text-center text-danger"></p>
          <div class="form-group">
            <div class="row">
              <div class="col-6">
                <input type="text" [(ngModel)]="phone" formControlName="phone" class="form-control" name="phone"
                  id="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" placeholder="Contact Phone"
                  autocomplete="tel-national" required />
                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                  <div *ngIf="f.phone.errors" class="phonetext alert alert-danger p-2 m-0">
                    Please enter valid phone number
                  </div>
                  <div *ngIf="f.phone.errors.required" id="confirmMessage">!</div>
                </div>
              </div>
              <div class="col-6">
                <button type="submit" class="btn-primary" style="width: 100%;">
                  Create Site
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>