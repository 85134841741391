import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-video-alldevices-info',
  templateUrl: './video-alldevices-info.component.html',
  styleUrls: ['./video-alldevices-info.component.scss']
})
export class VideoAlldevicesInfoComponent implements OnInit {

  @Input() videoSitesSummary: any;
  loader = environment.config.dynamicImage.loader;

  constructor() { }

  ngOnInit() {
  }

}
