import { Component, OnInit, Input } from '@angular/core';
import { GetIotService } from '../../services/get-iot.service';
import { PopupTypeService } from '../../services/popupType.service';

@Component({
  selector: 'app-iot-reboot-device',
  templateUrl: './iot-reboot-device.component.html',
  styleUrls: ['./iot-reboot-device.component.scss'],
})
export class IotRebootDeviceComponent implements OnInit {
  @Input() rebootData: any;
  constructor(private getIot: GetIotService, private ds: PopupTypeService) {}

  ngOnInit() {}

  rebootDevice = () => {
    const { iotDeviceID, agentId, accountUrl, apiKey } = this.rebootData;

    this.getIot.rebootIotDevice(iotDeviceID, agentId, accountUrl, apiKey).subscribe((data) => {
      this.sendPopupType(99);
    });
  };

  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }
}
