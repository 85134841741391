import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-reports-top-five-list',
  templateUrl: './reports-top-five-list.component.html',
  styleUrls: ['./reports-top-five-list.component.scss']
})
export class ReportsTopFiveListComponent implements OnInit {
  @Input() allSiteList = [];
  @Input() reportType:any;
  @Input() listTitle:any = 'none';
  @Input() listColTitle:any = 'none';
  @Input() col1Title:any = 'none';
  @Input() trendTitle:any = 'Trend';

  constructor() { }

  ngOnInit() {
    
  }

  secToTime(sec) {
    let t = sec;
    let days = Math.floor(t / (3600*24));
    t  -= days*3600*24;
    let hrs = Math.floor(t / 3600);
    t  -= hrs*3600;
    let mins = Math.floor(t / 60);
    t  -= mins*60;
    let ret = days + 'd ' + hrs + 'h ' + mins + 'm';
    return ret;
  }

}
