<div class="devicetypes">
  <div class="devicetypes__title">
    <h5>Device Type(s)</h5>
  </div>
  <div class="devicetypes__item_ctr">
    <div class="devicetypes__ctr">
      <div class="devicetypes__item" [ngClass]="{ 'active': activeGroupType === 'AV_DEVICES' }"
        (click)="filterByGroupType('AV_DEVICES')">
        <div class="devicetypes__icon">
          <img src="../../../assets/img/iot/avdevice.png" alt="" />
        </div>
        <div class="devicetypes__label_ctr">
          <div class="devicetypes__label_1">
            <h4>{{ avDevices | displayEmpty }}</h4>
          </div>
          <p class="devicetypes__label_2">AV Devices(s)</p>
        </div>
      </div>

      <div class="devicetypes__item" [ngClass]="{ 'active': activeGroupType === 'OFFICE_DEVICES' }"
        (click)="filterByGroupType('OFFICE_DEVICES')">
        <div class="devicetypes__icon">
          <img src="../../../assets/img/iot/officeequip.png" alt="" />
        </div>
        <div class="devicetypes__label_ctr">
          <div class="devicetypes__label_1">
            <h4>{{ officeDevices | displayEmpty }}</h4>
          </div>
          <p class="devicetypes__label_2">Office Equipment</p>
        </div>
      </div>
    </div>

    <div class="devicetypes__ctr">
      <div class="devicetypes__item" [ngClass]="{ 'active': activeGroupType === 'NETWORK_DEVICES' }"
        (click)="filterByGroupType('NETWORK_DEVICES')">
        <div class="devicetypes__icon">
          <img src="../../../assets/img/iot/networkdevice.png" alt="" />
        </div>
        <div class="devicetypes__label_ctr">
          <div class="devicetypes__label_1">
            <h4>{{ networkDevices | displayEmpty }}</h4>
          </div>
          <p class="devicetypes__label_2">Network Devices(s)</p>
        </div>
      </div>

      <div class="devicetypes__item" [ngClass]="{ 'active': activeGroupType === 'SMART_DEVICES' }"
        (click)="filterByGroupType('SMART_DEVICES')">
        <div class="devicetypes__icon">
          <img src="../../../assets/img/iot/smartdevice.png" alt="" />
        </div>
        <div class="devicetypes__label_ctr">
          <div class="devicetypes__label_1">
            <h4>{{ smartDevices | displayEmpty }}</h4>
          </div>
          <p class="devicetypes__label_2">Smart Devices(s)</p>
        </div>
      </div>
    </div>

    <div class="devicetypes__ctr">
      <div class="devicetypes__item" [ngClass]="{ 'active': activeGroupType === 'MOBILE_DEVICES' }"
        (click)="filterByGroupType('MOBILE_DEVICES')">
        <div class="devicetypes__icon">
          <img src="../../../assets/img/iot/mobiledevice.png" alt="" />
        </div>
        <div class="devicetypes__label_ctr">
          <div class="devicetypes__label_1">
            <h4>{{ mobileDevices | displayEmpty }}</h4>
          </div>
          <p class="devicetypes__label_2">Mobile Devices(s)</p>
        </div>
      </div>

      <div class="devicetypes__item" [ngClass]="{ 'active': activeGroupType === 'SERVERS' }"
        (click)="filterByGroupType('SERVERS')">
        <div class="devicetypes__icon">
          <img src="../../../assets/img/iot/servers.png" alt="" />
        </div>
        <div class="devicetypes__label_ctr">
          <div class="devicetypes__label_1">
            <h4>{{ servers | displayEmpty }}</h4>
          </div>
          <p class="devicetypes__label_2">Server(s)</p>
        </div>
      </div>
    </div>

    <!-- NEWSPACES -->
    <div *ngIf="!isSpacesEnabled" class="devicetypes__ctr">
      <div class="devicetypes__item_2" [ngClass]="{ 'active': activeGroupType === 'OTHER_DEVICES' }"
        (click)="filterByGroupType('OTHER_DEVICES')">
        <div class="devicetypes__icon">
          <img src="../../../assets/img/iot/otherdevice.png" alt="" />
        </div>
        <div class="devicetypes__label_ctr">
          <div class="devicetypes__label_1">
            <h4>{{ otherDevices | displayEmpty }}</h4>
          </div>
          <p class="devicetypes__label_2">Other Devices(s)</p>
        </div>
      </div>
    </div>


    <div *ngIf="isSpacesEnabled" class="devicetypes__ctr">
      <div class="devicetypes__item" [ngClass]="{ 'active': activeGroupType === 'OTHER_DEVICES' }"
        (click)="filterByGroupType('OTHER_DEVICES')">
        <div class="devicetypes__icon">
          <img src="../../../assets/img/iot/otherdevice.png" alt="" />
        </div>
        <div class="devicetypes__label_ctr">
          <div class="devicetypes__label_1">
            <h4>{{ otherDevices | displayEmpty }}</h4>
          </div>
          <p class="devicetypes__label_2">Other Devices(s)</p>
        </div>
      </div>

      <div class="devicetypes__item" [ngClass]="{ 'active': activeGroupType === 'OTHER_DEVICES' }"
        (click)="filterByGroupType('OTHER_DEVICES')">
        <div class="devicetypes__icon">
          <img src="../../../assets/img/iot/avdevice.png" alt="" />
        </div>
        <div class="devicetypes__label_ctr">
          <div class="devicetypes__label_1">
            <h4>{{ otherDevices | displayEmpty }}</h4>
          </div>
          <p class="devicetypes__label_2">Spaces</p>
        </div>
      </div>
    </div>
    <!-- NEWSPACES -->


  </div>
</div>