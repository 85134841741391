import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { AlertService } from 'src/app/_alert'
import { GetSpaceService, Space } from 'src/app/services/get-space.service'
import { PopupTypeService } from 'src/app/services/popupType.service'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-iot-space-list',
	templateUrl: './iot-space-list.component.html',
	styleUrls: ['./iot-space-list.component.scss'],
})
export class IotSpaceListComponent implements OnInit {
	@Input() isDomotz: boolean;
	// spaces: Space[]
	dataSource: MatTableDataSource<Space>
	loader = environment.config.dynamicImage.loader
	shouldShowLoaderBtn: Boolean = false
	displayedColumns: string[] = ['space_name', 'agent_name', 'linked_devices_count', 'is_monitored', 'is_paused', 'edit', 'delete']
	searchSubject: Subject<string> = new Subject<string>()
	editIndex: number

	constructor(
		private getSpaceService: GetSpaceService,
		public ds: PopupTypeService,
		private alertService: AlertService
	) {}

	ngOnInit(): void {
		this.fetchSpaces()
		this.searchSubject
			.pipe(
				debounceTime(300), // 300ms delay
				distinctUntilChanged() // Ignore if the next value is the same as the previous
			)
			.subscribe(searchKey => {
				this.fetchSpaces(searchKey)
			})
		this.getSpaceService.SpaceChangedEvent.subscribe((space: Space) => {
			if (this.editIndex !== null) {
				this.dataSource.data[this.editIndex] = space
				this.dataSource = new MatTableDataSource(this.dataSource.data)
				this.editIndex = null
			}
		})
	}

	fetchSpaces(searchKey?: string) {
		this.shouldShowLoaderBtn = true
		this.getSpaceService.getAllSpaces(searchKey, this.isDomotz).subscribe(
			(spacesData: Space[]) => {
				this.shouldShowLoaderBtn = false
				this.dataSource = new MatTableDataSource(spacesData)
			},
			err => {
				this.shouldShowLoaderBtn = false
			}
		)
	}

	searchData = key => {
		this.searchSubject.next(key)
	}

	setIsMonitored = (space: Space, event) => {
		event.preventDefault()
		const { space_id, is_monitored } = space
		const flag = is_monitored ? 0 : 1
		this.getSpaceService.monitorUnmonitorSpace(space_id, flag, this.isDomotz).subscribe(
			() => {
				space.is_monitored = flag
			},
			err => {
				this.alertService.error(err?.error?.message || err?.error || 'Something went wrong', {
					autoClose: true,
					keepAfterRouteChange: false,
				})
			}
		)
	}

	setIsPaused = (space: Space, event) => {
		event.preventDefault()
		const { space_id, is_paused } = space
		const flag = is_paused ? 0 : 1
		this.getSpaceService.pauseUnpauseSpace(space_id, flag, this.isDomotz).subscribe(
			() => {
				space.is_paused = flag
			},
			err => {
				this.alertService.error(err?.error?.message || err?.error || 'Something went wrong', {
					autoClose: true,
					keepAfterRouteChange: false,
				})
			}
		)
	}

	openPopup(p: number, space: Space, editIndex: number) {
		this.editIndex = editIndex
		this.ds.sendPopupType(p, {
			isDomotz: this.isDomotz,
			spaceToBeEdited: space,
		})
	}

	onDeleteSpace(_id: number, deleteIndex: number) {
		this.getSpaceService.deleteSpace(_id, this.isDomotz).subscribe(
			() => {
				this.dataSource.data.splice(deleteIndex, 1)
				this.dataSource = new MatTableDataSource(this.dataSource.data)
				const successMsg = 'Space deleted successfully'
				this.alertService.success(successMsg, {
					autoClose: true,
					keepAfterRouteChange: false,
				})
			},
			err => {
				// const deviceNames = err?.error?.result.map((device, index) => index === 0? device.name : `, ${device.name}`)
				const deviceNames = err?.error?.result.map(device => ` ${device.name}`)
				const errMsg = `Space is linked to the device(s):${deviceNames}. Please unlink the device(s) first in order to delete the site.`
				this.alertService.error(errMsg, {
					autoClose: false,
					keepAfterRouteChange: false,
				})
			}
		)
	}
}
