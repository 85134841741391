<div class="collector_list">
	<input #myFilterInput (keyup)="applySearchFilter(myFilterInput.value)" placeholder="Search..." />

	<div class="mat-elevation-z8 mat-table">
		<table class="data_table" mat-table [dataSource]="collectorsData" matSort>
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef>
					<input
						class="checkbox"
						type="checkbox"
						[disabled]="!collectorsData.filteredData.length"
						(change)="selectAll($event)"
						[(ngModel)]="isSelectAll"
					/>
				</th>
				<td mat-cell *matCellDef="let element; let idx = index;">
					<input
						class="checkbox"
						type="checkbox"
						[checked]="!!element.isUpdate"
						[disabled]="disableCheckbox(element)"
						(change)="selectSingle($event,idx)"
					/>
				</td>
			</ng-container>

			<ng-container *ngIf="this.Auth.accessLevel == 'SUPERADMIN'" matColumnDef="company">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<strong>Company</strong>
				</th>
				<td mat-cell *matCellDef="let element">{{ element.company_name }}</td>
			</ng-container>

			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<strong>Name</strong>
				</th>
				<td mat-cell *matCellDef="let element">
					<span (click)="clickRow(element)" class="clickable">{{ element.site_name }}</span>
				</td>
			</ng-container>

			<ng-container matColumnDef="health">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<strong>Health</strong>
				</th>
				<td mat-cell *matCellDef="let element">
					<app-table-health-icon [value]="element.health" [paused]="element.paused"></app-table-health-icon>
				</td>
			</ng-container>

			<ng-container matColumnDef="ip">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<strong>IP</strong>
				</th>
				<td mat-cell *matCellDef="let element" style>
					{{ element.ip_address }}
				</td>
			</ng-container>

			<ng-container matColumnDef="cpu">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<strong>CPU</strong>
				</th>
				<td mat-cell *matCellDef="let element">
					{{ element.cpu_used || "--" }}
				</td>
			</ng-container>

			<ng-container matColumnDef="memory">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<strong>Memory</strong>
				</th>
				<td mat-cell *matCellDef="let element">{{ element.memory_used }}%</td>
			</ng-container>

			<ng-container matColumnDef="lastConnected">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<strong class="margin-left-20">Last Connected</strong>
				</th>
				<td mat-cell *matCellDef="let element">
					{{ element.last_connect | dateTimeZone : 'MM-DD-YYYY hh:mma' }}
				</td>
			</ng-container>

			<ng-container matColumnDef="version">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<strong>Version</strong>
				</th>
				<td mat-cell *matCellDef="let element">
					{{ element.version }}
				</td>
			</ng-container>

			<ng-container matColumnDef="siteType">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<strong>Site Type</strong>
				</th>
				<td mat-cell *matCellDef="let element">
					<app-table-sitetype-icon [value]="element.virtual ? element.virtual : 0"></app-table-sitetype-icon>
				</td>
			</ng-container>

			<ng-container matColumnDef="collectorUpdate">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<strong>Collector Update</strong>
				</th>
				<td mat-cell *matCellDef="let element">
					<div [ngSwitch]="getStatus(element)">
						<div *ngSwitchCase="collectorAction.PROCESSING">
							<div class="progress">
								<div class="progress-bar"></div>
							</div>
							<strong>In Progress</strong>
						</div>
						<div *ngSwitchCase="collectorAction.FAILED">
							<span
								style="cursor:pointer"
								[ngbTooltip]="tooltipContent"
								[autoClose]="false"
								triggers="manual"
								#t="ngbTooltip"
								(click)="t.open()"
							>
								<strong style="color:#dc3545 !important">Failed <i class="fa fa-rotate-right mr-2"></i></strong>
							</span>
							<ng-template #tooltipContent>
								<a class="d-flex align-items-center dd-item" (click)="t.close();reTry(element,false)"
									><img src="assets/img/sync-now.svg" class="dropdown-icon mr-2" />Update</a
								>
								<a class="d-flex align-items-center dd-item" (click)="t.close();reTry(element,true)"
									><i class="fa fa-calendar dropdown-icon mr-2"></i>ReSchedule</a
								>
							</ng-template>
						</div>
						<div *ngSwitchDefault>
							<div [innerHTML]="getStatus(element)"></div>
						</div>
					</div>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
			<tr class="mat-row" *matNoDataRow>
				<td class="mat-cell text-center" [colSpan]="displayedColumns.length">No data found in table</td>
			</tr>
		</table>
	</div>
</div>
<div *ngIf="loading" style="position: absolute; /* top: 50%;  */ z-index: 7; width: 100%">
	<div class="loader-icon">
		<img [src]="loader" />
	</div>
</div>
