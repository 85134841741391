<div class="teams-room-warning">
    <div class="teams-room-warning__header">
        <div>
            <!-- Teams Rooms -->
        </div>
        <div (click)="closeDialog()" class="teams-room-warning__header-close-button">
            <img src="./assets/img/iot/delete-btn.png" alt="" />
        </div>
    </div>

    <div class="teams-room-warning__body teams-room-warning__body-2">
        <div class="teams-room-warning__image">
            <img src="../../../../assets/img/vs_img/delete-icon.png" alt="" />
        </div>
        <div class="teams-room-warning__text-button-container">
            To enable monitoring please link a Teams Room Plugin
        </div>
    </div>
</div>