<div class="popup-content">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="card bg-default" style="padding-bottom: 10px; padding-top: 10px; margin-bottom: 10px;">
        <div class="col-md-12" *ngIf="meetingsResponseData.length > 0">
          <div class="card bg-default" style="margin-bottom: 10px;"
            *ngFor="let meeting of meetingsResponseData; index as n">
            <h5 class="card-header">
              {{ printTime(meeting.start_time) }} -
              {{ printEndTime(meeting.start_time, meeting.duration) }}
            </h5>
            <div class="card-body">
              <table>
                <thead>
                  <tr>
                    <td>Title: {{ meeting.topic }}</td>
                  </tr>
                  <tr>
                    <td>
                      Join Url: <a href="{{ meeting.join_url }}">{{ meeting.join_url }}</a>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="meetingsResponseData.length == 0">
          <div class="col-md-12">
            <div class="card bg-default">
              <h5 class="card-header">
                No Meetings Scheduled
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>