import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { CheckDeviceStatusService } from '../../../services/check-device-status.service';

@Component({
  selector: 'app-device-status',
  templateUrl: './device-status.component.html',
  styleUrls: ['./device-status.component.scss'],
})
export class DeviceStatusComponent implements OnInit, OnChanges {
  @Input() paused: number;
  @Input() online: number;
  @Input() collector_status: number;
  statusText: string;
  statusBtnColor: string;

  constructor(private checkDeviceStatus: CheckDeviceStatusService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isOnline()) {
      this.statusText = 'Online';
      this.statusBtnColor = 'online-btn';
    } else if (this.isSleep()) {
      this.statusText = 'Sleep';
      this.statusBtnColor = 'online-btn';
    } else if (this.isOffline()) {
      this.statusText = 'Offline';
      this.statusBtnColor = 'offline-btn';
    } else if (this.isCollectorOffline()) {
      this.statusText = 'Collector Offline';
      this.statusBtnColor = 'offline-btn';
    } else if (this.isPaused()) {
      this.statusText = 'Pause';
      this.statusBtnColor = 'pause-btn';
    } else {
      this.statusText = 'Offline';
      this.statusBtnColor = 'offline-btn';
    }
  }

  isOnline() {
    return this.checkDeviceStatus.isOnline(this.online, this.paused, this.collector_status);
  }

  isSleep() {
    return this.checkDeviceStatus.isSleep(this.online, this.paused, this.collector_status);
  }

  isOffline() {
    return this.checkDeviceStatus.isOffline(this.online, this.paused, this.collector_status);
  }

  isCollectorOffline() {
    return this.checkDeviceStatus.isCollectorOffline(this.collector_status);
  }

  isPaused() {
    return this.checkDeviceStatus.isPaused(this.paused, this.collector_status);
  }
}
