import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }

  //server global variable;
	server_url: string = environment.serverUrl;
		httpOptions = {
		headers: new HttpHeaders({
			'Content-Type':  'application/json'
		})
  };

  	private handleError (error: Response | any) {
		console.error('ApiService::handleError', error);
		return Observable.throw(error);
	}

	private extractData(res: Response) {
		let body = res;
		return body || { };
  	}
  
  //API Calls

	//GET Request for Company Info
	sendSupportRequestEmail(sData): Observable<Object> {
		
		//Grabs Token from Local Storage
		const token = localStorage.getItem('token');
		const helper = new JwtHelperService();
		
		const decodedToken = helper.decodeToken(token);
		const companyId = decodedToken.company_id;
		
		// let postData = {
		// 	company_id: companyId,
		// 	...supportData
		// }

		// console.log(sData);

		// // console.log("Here is the email support ticket data: ", postData);
		return this.http.post<any>( this.server_url + '/api/v1/email/supportemail', sData, this.httpOptions).pipe(map(this.extractData))
		// return this.http.post<any>( 'http://127.0.0.1:8080/api/v1/email/supportemail', sData, this.httpOptions).map(this.extractData)
		// .catch(this.handleError);
	}

}
