import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { GetDocumentCenterService } from '../../../services/get-document-center.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment-timezone';
import { forkJoin } from 'rxjs';
import { AlertService } from 'src/app/_alert/alert.service'

@Component({
  selector: 'app-document-center-dialog',
  templateUrl: './document-center-dialog.component.html',
  styleUrls: ['./document-center-dialog.component.scss'],
})
export class DocumentCenterDialogComponent {
  CHUNK_SIZE = 10000000; // 5MB
  FILE_EXISTS = 'File name and type already exists.';
  isCopy: boolean = false;
  btnLoader: string = environment.config.dynamicImage.loader;
  isLoading: boolean = false;
  selectedFile: File = null;
  isDocumentShared: boolean = false;
  // shouldShowNotShared: any = false;
  document: any;
  panelType: any;
  objectEmitter: any;
  loader: String = environment.config.dynamicImage.loader;
  shareExpiration: any;
  shareDateMin: any;
  showMsg: boolean = false;
  message: String;
  docSharePath: String;
  uploadMessage: String = 'TESTTT';
  isShowUploadMessage: boolean = false;
  isShowOverwrite: boolean = false;
  isOverwrite: boolean = false;
  overWriteDocumentId: number;
  documentUploadId: any;
  progressBarPercentage: number = 0;
  error = '';
  setTimer: any;
	folderName: string = 'Untitled Folder';
  isSelectedFileEmpty: boolean = false;
  emptyFileMsg: string = 'Please Choose a File . . .';
    options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DocumentCenterDialogComponent>,
    private GetDcocumentCenter: GetDocumentCenterService,
    private _alertService: AlertService,
  ) {
    if (data) {
      const {
        config: { type, documentData, objectEmitter },
      } = data;
      this.panelType = type;
      this.document = documentData;
      this.objectEmitter = objectEmitter;
      this.isDocumentShared = this.document.shared_by_user_id !== null;

      if (this.panelType === 'share') {
        const sDate = this.document.share_expiration;
        // min = '2018-06-07T00:00';
        this.shareDateMin = `${moment().add(1, 'day').format('YYYY-MM-DD')}T00:00`;
        if (sDate !== null && sDate.trim().length !== 0) {
          this.docSharePath = `${location.origin}/document-center-view/${this.document.document_id}/${this.document.file_type}`;
          const momentData = moment(sDate)
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format();
          this.shareExpiration = momentData.split(':')[0] + ':' + momentData.split(':')[1];
        }
      }
    }
  }

  closeDialog = () => {
    this.isLoading = false;
    this.dialogRef.close(true);
  };

  deleteDocument = () => {
    const { document_id } = this.document;
    this.isLoading = true;

    this.GetDcocumentCenter.deleteDocument(document_id).subscribe(
      (data) => {
        this.isLoading = false;
        this.objectEmitter('getDocumentsByDevice');
        this.closeDialog();
      },
      (error) => {
        this.isLoading = false;
        this.closeDialog();
      }
    );
  };

  deleteFolder = () => {
    const { document_id } = this.document;
    this.isLoading = true;

    this.GetDcocumentCenter.deleteFolder(document_id).subscribe(
      (resp:any) => {
        if (resp.success) {
          this.isLoading = false;
          this._alertService.success(resp.message , this.options);
          this.objectEmitter('getDocumentsByDevice');
          this.closeDialog();
        }
        else {
          this._alertService.error(resp.message , this.options);
          this.isLoading = false;
          this.closeDialog();
        }
      },
      (error) => {
        this.isLoading = false;
        this.closeDialog();
      }
    );
  };

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  shareDocument = () => {
    this.showMsg = false;
    const { document_id, file_type } = this.document;
    this.isLoading = true;

    if (this.isDocumentShared) {
      this.GetDcocumentCenter.unshareDocument(document_id).subscribe(
        (data) => {
          this.isLoading = false;
          this.isDocumentShared = false;
          this.docSharePath = null;
          this.objectEmitter('getDocumentsByDevice');
          this.closeDialog();
        },
        (error) => {
          this.isLoading = false;
          this.closeDialog();
        }
      );
    } else {
      if (this.shareExpiration) {
        const sDate = this.shareExpiration.split('T');
        const share_expiration = moment(sDate[0] + ' ' + sDate[1])
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format();
        this.GetDcocumentCenter.shareDocument(document_id, share_expiration).subscribe(
          (data) => {
            this.isLoading = false;
            this.isDocumentShared = true;
            this.showMsg = false;
            this.docSharePath = `${location.origin}/document-center-view/${document_id}/${file_type}`;
            this.objectEmitter('getDocumentsByDevice');
          },
          (error) => {
            this.isLoading = false;
            this.closeDialog();
          }
        );
      } else {
        this.showMsg = true;
        this.isLoading = false;
        this.message = 'Please set document expiration date.';
      }
    }
  };

  copyAccesUrl = (t) => {
    t.open();
    setTimeout(() => {
      t.close();
    },3000)
    let range = document.createRange();
    range.selectNode(document.getElementById('file-path'));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  };

  saveDocument = () => {
    if (!this.selectedFile) {
      this.isSelectedFileEmpty = true;
      setTimeout(() => {
          this.isSelectedFileEmpty = false;
      }, 2500);
    return;
    }
    this.isLoading = true;
    this.isShowUploadMessage = false;
    this.isOverwrite = false;
    this.overWriteDocumentId = null;
    this.progressBarPercentage = 0;
    const { device_id, quadrant_group } = this.document;
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const { company_id } = helper.decodeToken(token);
    const tmpFile = this.selectedFile.name.split('.');
    const file_name = this.getFileName();
    const file_type = tmpFile[tmpFile.length - 1];
    const params = {
      company_id,
      quadrant_group,
      device_id,
      file_name,
      file_type,
    };

    if (!this.isFileSizeLimit()) {
      this.GetDcocumentCenter.checkDocumentExists(params).subscribe(
        (documentExistsResult: any) => {
          this.isOverwrite = documentExistsResult.flag;
          this.overWriteDocumentId = documentExistsResult.document_id;

          if (this.isOverwrite) {
            this.isShowOverwrite = true;
            this.isShowUploadMessage = true;
            this.uploadMessage =
              this.FILE_EXISTS + ' ' + 'If you want to overwrite file. Click "Overwrite" button.';
            this.isLoading = false;
          } else {
            this.isLoading = true;
            this.uploadDocument();
          }
        },
        (error) => {
          this.isLoading = false;
          this.closeDialog();
        }
      );
    } else {
      this.isShowOverwrite = false;
      this.isShowUploadMessage = true;
      this.uploadMessage = 'File size limit exceeded.';
      this.isLoading = false;
    }
  };

  uploadDocument = () => {
    this.isLoading = true;
    this.isShowUploadMessage = false;
    this.documentUploadId = null;
    this.progressBarPercentage = 10;
    const { documentData } = this.data?.config;
    const { device_id, quadrant_group } = this.document;
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const { company_id } = helper.decodeToken(token);
    let file_name = this.selectedFile.name;
    const file_type = this.selectedFile.type;
    const file_size = this.selectedFile.size;
    file_name = documentData?.root_path ? documentData.root_path + '|' + file_name : file_name;
    const params = {
      company_id,
      quadrant_group,
      device_id,
      file_name,
      file_type,
      file_size,
    };

    this.GetDcocumentCenter.startDocumentUpload(params).subscribe(
      (startDocumentUploadResult: any) => {
       
           if (startDocumentUploadResult.status == "Error") {
          this.error = startDocumentUploadResult.message;
          setTimeout(() => {
            this.error = '';
          } , 15000)
        }
        if (startDocumentUploadResult.UploadId) {
          this.documentUploadId = startDocumentUploadResult.UploadId;
          this.progressBarPercentage = 20;
          this.uploadMultipartFile();
        } else {
          this.isLoading = false;
          this.progressBarPercentage = 0;
        }
      },
      (error) => {
        this.progressBarPercentage = 0;
        this.isLoading = false;
        this.closeDialog();
      }
    );
  };

  uploadMultipartFile = () => {
    let { device_id, quadrant_group, description, root_id } = this.document;
    const { documentData } = this.data?.config;
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const { company_id, user_id } = helper.decodeToken(token);
    const tmpFile = this.selectedFile.name.split('.');
    let file_name = this.selectedFile.name;
    const file_type = tmpFile[tmpFile.length - 1];
    const fileSize = this.selectedFile.size;
    file_name = documentData?.root_path ? documentData.root_path + '|' + file_name : file_name;
    const params = {
      company_id,
      quadrant_group,
      device_id,
      file_name,
      index: 0,
      upload_id: this.documentUploadId,
    };
		description = description ? description : file_name;
    const completeUploadParams = {
      company_id,
      quadrant_group,
      device_id,
      file_name: this.getFileName(),
      file_type,
      file_size: fileSize,
      upload_id: this.documentUploadId,
      is_overwrite: this.isOverwrite,
      description,
      owner_user_id: user_id,
      document_id: this.overWriteDocumentId,
      parts: [],
      root_id
    };

    const CHUNKS_COUNT = Math.floor(fileSize / this.CHUNK_SIZE) + 1;
    let start, end;
    let getDocumentUploadUrlPromises = [];
    const fileData = [];

    this.progressBarPercentage = 25;

    for (let index = 1; index < CHUNKS_COUNT + 1; index++) {
      start = (index - 1) * this.CHUNK_SIZE;
      end = index * this.CHUNK_SIZE;
      fileData.push(
        index < CHUNKS_COUNT ? this.selectedFile.slice(start, end) : this.selectedFile.slice(start)
      );

      const tmpParams = {
        ...params,
        index,
      };

      getDocumentUploadUrlPromises.push(this.GetDcocumentCenter.getDocumentUploadUrl(tmpParams));
    }
    this.progressBarPercentage = 35;
    forkJoin(getDocumentUploadUrlPromises).subscribe((presignedUrls) => {
      let index = 0;
      const presignedUrlsPromises = presignedUrls.map((url) => {
        return this.GetDcocumentCenter.presignedUrl(url, fileData[index++], this.selectedFile);
      });
      this.progressBarPercentage = 65;

      forkJoin(presignedUrlsPromises).subscribe((presignedUrlsPromisesResults: any[]) => {
        let uploadPartsArray = [];
        presignedUrlsPromisesResults.forEach((resolvedPromise, index) => {
          uploadPartsArray.push({
            ETag: resolvedPromise.headers.get('etag'),
            PartNumber: index + 1,
          });
        });

        this.progressBarPercentage = 85;
        completeUploadParams.parts = uploadPartsArray;
        completeUploadParams.root_id = root_id;
        this.GetDcocumentCenter.completeDocumentUpload(completeUploadParams).subscribe(
          (data: any) => {
            this.progressBarPercentage = 100;
            this._alertService.success('file uploaded successfully' , this.options);
            this.objectEmitter('getDocumentsByDevice');
            this.closeDialog();
            this.progressBarPercentage = 100;
            this.isLoading = false;
            this.setTimer = setTimeout(() => { //destroy this
              this.objectEmitter('getDocumentsByDevice');
              this.closeDialog();
            }, 5000);
          },
          (error) => {
            this._alertService.error('Something went wrong while uploading file' , this.options);
            this.isLoading = false;
            this.progressBarPercentage = 0;
            this.closeDialog();
          }
        );
      });
    });
  };

  isFileSizeLimit = () => {
    const fileSize = this.selectedFile.size;
    const fileLimit = 250 * 1024 * 1024;
    return fileSize > fileLimit;
  };
  
  addDocument() {
    if (!this.folderName.trim().length) {
      this._alertService.error('Please enter a folder name' , this.options);
    }
    else {
      const { device_id, root_id, quadrant_group } = this.document;
      const token = localStorage.getItem('token');
      const helper = new JwtHelperService();
      const { company_id, user_id } = helper.decodeToken(token);
      const body = {
        deviceId: device_id,
        companyId: company_id,
        ownerId: user_id,
        fileName: this.folderName,
        root_id: root_id,
        quadrant_group:quadrant_group
      }
      this.GetDcocumentCenter.addDocument(body).subscribe((resp) => {
        if (resp.success) {
          this._alertService.success(resp.message, this.options);
          this.objectEmitter('getDocumentsByDevice');
          this.closeDialog();
        }
        else {
          this._alertService.error(resp.message , this.options);
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

  getFileName = () => {
    const tempFile = this.selectedFile.name.split('.');
    tempFile.pop();
    const { documentData } = this.data?.config;
    return documentData?.root_path?documentData.root_path+'|'+tempFile.join('.'):tempFile.join('.');
  };
}
