import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-new-reset-password',
  templateUrl: './new-reset-password.component.html',
  styleUrls: ['./new-reset-password.component.scss'],
})
export class NewResetPasswordComponent implements OnInit, OnChanges {
  @Output() public data = new EventEmitter<{ email: string }>();
  @Input() message: any;
  @Input() message2: any;
  setTimer: any;
  dashboardLogo = environment.config.dynamicImage.dashboardLogo;
  email: string = '';

  formControl: any;
  responseMessage = 'Minimum of 8 character password';
  links = environment.config.links;
  dTerms = environment.config.dynamicText.dTerms;
  backgroundImage = environment.config.dynamicImage.bgImageNew2;

  visibilityBackground = {
    'background-image': 'url(' + this.backgroundImage + ')',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
    'background-attachment': 'fixed',
    'background-size': 'cover',
    height: '100vh',
  };

  startValidateEmail: boolean = false;
  disableBtn: boolean = false;

  getInfoData: any;
  valid = -1;
  id: number;
  sentRequest: any = false;
  resetResponseData: any;
  resetResponseFailed: any;
  resetResponseMessage: any;
  loginLink: any;

  startValidatePassword: boolean = false;
  startValidateConfirm: boolean = false;
  password: any = '';
  confirm: any = '';
  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    this.setFormControl();
    // this.Auth.shouldShowCreateNewAccount = true;
    this.getResetInfo();
    this.loginLink = this.Auth.isAuthenticated() ? '/logout' : '/login';
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.message) {
    //   if (this.formControl) {
    //     this.setErrorMessageAndStatus('email', this.message.flag, 'error', this.message.text);
    //     this.disableBtn = true;
    //     this.startValidateEmail = true;
    //   }
    // }
  }

  getResetInfo() {
    this.Auth.getResetCodeInfo(this.id).subscribe((data) => {
      this.getInfoData = data;
      if (this.getInfoData.response[0].status == 'Ok') {
        this.valid = 1;

        this.email = this.getInfoData.response[0].email;
      } else {
        this.valid = 0;
      }
    });
  }

  resetPassword() {
    if (this.getInfoData.response[0].status === 'Ok') {
      const resetData = {
        password: this.password,
        code: this.id,
        email: this.getInfoData.response[0].email,
      };

      this.Auth.changePassword(resetData).subscribe((data) => {
        if (data['response'][0].data.status.toLowerCase() === 'ok') {
          this.sentRequest = true;
          this.resetResponseData = data;
          this.resetResponseFailed = 0;
        } else if (data['response'][0].data.status.toLowerCase() === 'failure') {
          if (data['response'][0].data.message.includes("old")) {
            this.responseMessage = data['response'][0].data.message;
          } else {           
            this.sentRequest = true;
            this.resetResponseData = data;
            this.resetResponseFailed = 1;
            this.resetResponseMessage = data['response'][0].data.message;
          }
        }
      });
    }
  }

  setFormControl = () => {
    const deepCopy = {
      email: {
        flag: false,
        style: 'default',
        message: '',
      },
      password: {
        style: 'default',
        message: ' ',
        flag: false,
      },
      confirm: {
        style: this.password === this.confirm && this.password.length !== 0 ? 'success' : 'default',
        message: ' ',
        flag: false,
      },
    };

    this.formControl = { ...deepCopy };
  };

  validateForm = () => {
    const validation = this.validate();

    if (validation) {
      this.resetPassword();
    }
  };

  validate = () => {
    let errCount = 0;
    this.setFormControl();

    if (this.isEmpty(this.password)) {
      this.startValidatePassword = true;
      this.setErrorMessageAndStatus('password', true, 'error', 'Required Field');
      errCount++;
    } else if (this.password.length < 8) {
      this.startValidatePassword = true;
      this.setErrorMessageAndStatus('password', true, 'error', 'Minimum of 8 character password');
      errCount++;
    }
    if (this.isEmpty(this.confirm)) {
      this.startValidateConfirm = true;
      this.setErrorMessageAndStatus('confirm', true, 'error', 'Required Field');
      errCount++;
    } else if (this.confirm !== this.password) {
      this.startValidateConfirm = true;
      this.setErrorMessageAndStatus('confirm', true, 'error', 'Password mismatch');
      errCount++;
    }

    this.disableBtn = errCount !== 0;
    return errCount === 0;
  };

  isEmpty = (params) => {
    if (params.length === 0) {
      return true;
    }
    return false;
  };

  setErrorMessageAndStatus = (propname, bool, style, message) => {
    this.formControl[propname].flag = bool;
    this.formControl[propname].style = style;
    this.formControl[propname].message = message;
  };

  keyEvent = (event, type) => {
    if (type === 'password') {
      this.validate();
    }
    if (type === 'confirm') {
      this.validate();
    }
  };

  emailKeyEvent = (events) => {
    if (this.startValidateEmail) {
      this.validate();
    }
  };
}
