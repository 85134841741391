import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'

import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../services/auth.service'
import { GetCollectorService } from 'src/app/services/get-collector.service'

@Component({
	selector: 'app-delete-site-dialog',
	templateUrl: './delete-site-dialog.component.html',
	styleUrls: ['./delete-site-dialog.component.scss'],
})
export class DeleteSiteDialogComponent implements OnInit {
	site_id: any
	cloud_rooms_count: any
	zoom_room: any
	site_name: any
	company_id: any
	sendPopupType: EventEmitter<any>
	btnLoader: string = environment.config.dynamicImage.loader
	isLoading: any = false
	zoomRoomObj: any = {
		name: '',
		id: '',
	}
	// isSiteWitPlugin: any = false
	// confirmationInput: any = ''

	hasDeviceOrSpaceConnected: any = false
	hasDeviceOrSpaceConnectedMsg: any = ''
	attemptedToDeleteSite: boolean = false
	connectedDevices: {
		id: string
		name: string
	}[]
	connectedSpaces: {
		id: string
		name: string
	}[]
	setTimer: any

	public data
	public dialogRef
	@Input() dataWhenComponentIsNotUsedAsDialog
	@Output() hideComponent: EventEmitter<void> = new EventEmitter()

	constructor(
		private Auth: AuthService,
		private router: Router,
		// @Inject(MAT_DIALOG_DATA) private data: any,
		// private dialogRef: MatDialogRef<DeleteSiteDialogComponent>,
		private injector: Injector,
		private collectorService: GetCollectorService
	) {}

	ngOnInit(): void {
		this.dialogRef = this.injector.get(MatDialogRef, null)
		this.data = this.injector.get(MAT_DIALOG_DATA, this.dataWhenComponentIsNotUsedAsDialog)
		this.attemptedToDeleteSite = false
		if (this.data) {
			const { site_id, cloud_rooms_count, zoom_room, site_name, sendPopupType, company_id } = this.data.config

			this.site_name = site_name
			this.sendPopupType = sendPopupType
			this.site_id = site_id
			this.company_id = company_id
			// this.zoom_room = zoom_room
			// this.cloud_rooms_count = cloud_rooms_count
			// this.checkForDeleteSiteMethod()
		}
	}

	// checkForDeleteSiteMethod = () => {
	// 	if (this.cloud_rooms_count !== 0) {
	// 		const parse = JSON.parse(this.zoom_room)
	// 		const name = Object.keys(parse).toString()
	// 		const [id] = Object.values(parse)
	// 		this.isSiteWitPlugin = true
	// 		this.zoomRoomObj = {
	// 			name,
	// 			id,
	// 		}
	// 	}
	// }

	deleteSite = () => {
		// this.closeDialog()
		const params = {
			company_id: this.company_id,
			site_id: this.site_id,
		}
		this.isLoading = true
		this.Auth.deactivateSite(params).subscribe(data => {
			this.isLoading = false
			this.attemptedToDeleteSite = true
			// // console.log('deactivateSite: ', data)
			if (data['response'][0]['status'] === 'OK') {
				this.collectorService.collectorSiteChangeEvent.emit()
				this.sendPopupType?.emit('collectorList')
				this.closeDialog()
			} else if (data['response'][0]['status'] === 'Error') {
				this.hasDeviceOrSpaceConnected = true
				this.hasDeviceOrSpaceConnectedMsg = data['response'][0]['message']
				this.connectedDevices = data['response'][0]['devices'] ? JSON.parse(data['response'][0]['devices']) : []
				this.connectedSpaces = data['response'][0]['spaces'] ? JSON.parse(data['response'][0]['spaces']) : []
				// this.setTimer = setTimeout(() => {
				// 	this.closeDialog()
				// }, 4000)
			} else {
				this.closeDialog()
			}
		})
		// this.sendPopupType?.emit('collectorList')
	}

	// checkConfirmation = () => {
	// 	return this.confirmationInput.toLowerCase() === 'yes'
	// }

	clickZoomRoom() {
		this.closeDialog()
		this.router.navigate(['cloud/room/', this.zoomRoomObj.id])
	}

	closeDialog = () => {
		this.isLoading = false
		this.dialogRef?.close(true)
		this.hideComponent?.emit()
	}

	// ngOnInit() {}

	ngOnDestroy(): void {
		this.clearTimeout()
	}

	clearTimeout = () => {
		clearTimeout(this.setTimer)
	}
}
