import { AuthService } from 'src/app/services/auth.service'
import { GetPluginService } from 'src/app/services/get-plugin.service'
import { environment } from 'src/environments/environment'

import { Component, EventEmitter, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-zoom-rooms-link-plugin',
  templateUrl: './zoom-rooms-link-plugin.component.html',
  styleUrls: ['./zoom-rooms-link-plugin.component.scss']
})
export class ZoomRoomsLinkPluginComponent implements OnInit {

  dPlugin = environment.config.dynamicText.dPlugin;
  modalList: any;
  showLinkClientUser: boolean = false;
  clientToLink: any = '';
  hostName: any = '';
  pluginId: any;
  zoomUserId: any;
  isLoading: any = false;
  sendPopupType: EventEmitter<any>;
  btnLoader: string = environment.config.dynamicImage.loader;
  pluginEnabled: any;
  setTimer: any;
  zoomRoomPassword: String = '';
  serviceType: any = 'zoom';
  actionCount: number = 0;
  pluginMessage: string = '';
  showPluginMessage: boolean = false;
  constructor(
    private Auth: AuthService,
    private getPluginService: GetPluginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ZoomRoomsLinkPluginComponent>
  ) {
    if (data) {
      const {
        config,
        user_plugin_host_name,
        user: {
          plugin_active,
          room_name,
          plugin_id,
          service_type,
          zoom_user_id,
        },
        sendPopupType,

      } = data;
      this.pluginEnabled = plugin_active === 1;
      this.clientToLink = room_name;
      this.hostName = user_plugin_host_name;
      this.pluginId = plugin_id;
      this.zoomUserId = zoom_user_id;
      this.sendPopupType = sendPopupType;
      this.serviceType = service_type;
      this.modalList = config.map((e) => {
        return {
          ...e,
          host_name: this.getHostName(e.host_info) || '--',
        }
      });
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

  unlinkPlugin = () => {
    this.isLoading = true;
    this.Auth.unlinkPluginToRoom(this.pluginId, this.serviceType).subscribe((data: any) => {
      this.pluginMessage = 'Unlinking of room to plugin successful!';
      this.showPluginMessage = true;
      this.resetPluginMessage();
      this.sendPopupType.emit('resetPagination');
      this.sendPopupType.emit('zoomRoomList');
    });
  };

  linkPlugin = () => {
    this.isLoading = true;
    const password = this.zoomRoomPassword;
    if (password.length > 0) {
      const actionParams = JSON.stringify({
        host: 'localhost',
        password,
      });
      const actionName = 'SetZoomSettings';
      this.getPluginService.createAction(this.pluginId, actionParams, actionName)
        .subscribe((result) => {
          if (result.response[0].status === 'OK') {
            this.checkSetZoomSettings(result.response[0].action_id);
          } else {
            this.isLoading = false;
          }
        });
    } else {
      this.pluginMessage = 'Please provide zoom room password';
      this.isLoading = false;
      this.showPluginMessage = true;
      this.setTimer = setTimeout(() => {
        this.showPluginMessage = false;
      }, 1000);
    }
  };

  openLinkClient = ({ host_name, plugin_id }) => {
    this.hostName = host_name;
    this.pluginId = plugin_id;
    this.showLinkClientUser = true;
  };

  closeLinkClient = () => {
    this.showLinkClientUser = false;
    this.dialogRef.close(true);
  };

  closeDialog = () => {
    this.isLoading = false;
    this.dialogRef.close(true);
  };

  getModalTitle = () => {
    if (this.pluginEnabled) {
      return 'UN-LINK ROOM TO ' + this.dPlugin.toUpperCase();
    }
    if (!this.pluginEnabled) {
      return 'LINK ROOM TO ' + this.dPlugin.toUpperCase();
    }
    return 'title';
  };

  getHostName = (params) => {
    if (params) {
      const hostname = JSON.parse(params);
      return hostname[0].hostname;
    }
    return "--";
  };

  getUsersToLinkName = ({ firstname }) => {
    return firstname;
  };

  onNoClick(): void {
    // this.dialogRef.close();
  }

  resetPluginMessage = () => {
    this.setTimer = setTimeout(() => {
      this.closeDialog();
    }, 3000);
    this.setTimer = setTimeout(() => {
      this.showPluginMessage = false;
    }, 4000);
    this.isLoading = false;
  }

  completeLinkPlugin = () => {
    this.Auth.linkPluginToRoom(this.pluginId, this.zoomUserId).subscribe((data: any) => {
      this.sendPopupType.emit('resetPagination');
      this.sendPopupType.emit('zoomRoomList');
      this.pluginMessage = 'Linking room to plugin successful!';
      this.showPluginMessage = true;
      this.resetPluginMessage();
    });

  };

  checkSetZoomSettings(actionId) {
    const idData = {
      action_id: actionId,
    };
    this.isLoading = true;
    this.getPluginService.getPluginAction(idData).subscribe((actionData) => {
      if (this.actionCount == 90) {
        this.pluginMessage = 'Unable to update zoom room password';
        this.showPluginMessage = true;
        this.sendPopupType.emit('resetPagination');
        this.sendPopupType.emit('zoomRoomList');
        this.resetPluginMessage();
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        setTimeout(function () {
          rk.checkSetZoomSettings(actionId);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === 'FAILED') {
        this.pluginMessage = 'Failed to update zoom room password';
        this.showPluginMessage = true;
        this.sendPopupType.emit('resetPagination');
        this.sendPopupType.emit('zoomRoomList');
        this.resetPluginMessage();
      } else if (actionData.response[0].status === 'COMPLETE') {
        this.completeLinkPlugin();
      }
    });
  }
}
