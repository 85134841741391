<div class="iot-agent-list">
  <p class="text-danger" *ngIf="message">{{message}}</p>
  <input #searchDataInput (keyup)="searchData(searchDataInput.value)" placeholder="Search..." class="input-class" />

  <div class="mat-elevation-z8 child mat-table">
    <table class="data-table" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="agent_name">
        <th mat-header-cell *matHeaderCellDef><strong>Agent Name</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.agent_name | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="space_name">
        <th mat-header-cell *matHeaderCellDef><strong>Space(s) Name</strong></th>
        <td mat-cell *matCellDef="let row">
          <ng-container *ngFor="let space of row.spaces; let last = last;">
            {{ space.space_name | displayEmpty }} 
            {{last? '' : ', '}}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="ip">
        <th mat-header-cell *matHeaderCellDef><strong>IP</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.ip | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="mac">
        <th mat-header-cell *matHeaderCellDef><strong>MAC</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.mac | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="device_count">
        <th mat-header-cell *matHeaderCellDef><strong>Number of all devices</strong></th>
        <td mat-cell *matCellDef="let row">
          {{ row.device_count | displayEmpty }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef><strong>Agent Status</strong></th>
        <td mat-cell *matCellDef="let row">
          <div>
            <div class="text-center {{ getStatusColor(row.status) }}"></div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="is_monitored">
        <th mat-header-cell *matHeaderCellDef><strong>Monitoring</strong></th>
        <td mat-cell *matCellDef="let row">
          <div class="checkbox_switch" >
            <label class="switch">
              <input
                type="checkbox"
                [checked]="row.is_monitored === 1"
                (click)="setIsMonitored(row)"
                [disabled]="isGreyFilledOut"
              />
              <span [ngClass]="{'toggleDisabled': isGreyFilledOut}" class="slider round"></span>
            </label>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [colSpan]="displayedColumns.length">No data found in table</td>
      </tr>
    </table>
  </div>
  <div *ngIf="shouldShowLoaderBtn" class="loader-child">
    <img src="{{ loader }}" alt="" />
  </div>
</div>
