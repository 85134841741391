<div class="teams-room-stats">
  <app-cloud-user-live-stats [isTeamsUser]="true" [cloudUserLivestats]="cloudUserLivestats" [isNotButton]="isNotButton">
  </app-cloud-user-live-stats>
  <hr />
  <app-teams-room-path-detection [cloudUserStats]="teamsDetails">
  </app-teams-room-path-detection>
  <hr *ngIf="!isUserOnCall" />

  <app-teams-room-device-health [cloudUserStats]="teamsDetails"
    [logitechSync]="logitechSync"></app-teams-room-device-health>
  <hr />

  <app-logitech-scribe-panel *ngIf="logitechScribe" [logitechScribe]="logitechScribe"></app-logitech-scribe-panel>
  <hr *ngIf="logitechScribe" />

  <!-- still waiting for the calls data if this is needed to be recreated -->
  <div *ngIf="!spaceStatus">
    <app-teams-user-utilization [cloudUserUtilization]="cloudUserUtilization" [isNotButton]="isNotButton"
      [hasAccess]="hasAccess"></app-teams-user-utilization>
    <hr />
  </div>

  <app-cloud-user-notes [notes]="teamsDetails?.notes" [zoom_user_id]="teamsDetails?.zoom_user_id">
  </app-cloud-user-notes>

</div>