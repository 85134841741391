<div class="not-button btn-long- d-flex properties">
  <div class="mr-auto">
    <img src="../../assets/img/video/icon_screen_phone.png" class="video-btn-icon" />
    <span>{{ statusLabel }} </span>
  </div>
  <div class="ml-auto d-flex align-items-center">
    <span style="margin-right: 5px;">{{ dPlugin }} Status</span>
    <span style="margin-right: 10px;" class="{{ statusColor }}"></span>
  </div>
</div>
