import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  SimpleChange,
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { TableFilterService } from '../../../services/table-filter.service';

import { PopupTypeService } from '../../../services/popupType.service';

@Component({
  selector: 'app-cloud-all-users-stats',
  templateUrl: './cloud-all-users-stats.component.html',
  styleUrls: ['./cloud-all-users-stats.component.scss'],
})
export class CloudAllUsersStatsComponent implements OnInit, OnChanges {
  data: any;
  @Input() cloudUserSummary: any;
  @Input() loading: any;

  @Output() onFilter: EventEmitter<number> = new EventEmitter();
  currentFilter: string;
  activeBtn: number;
  calculatedMbps: number;
  activeFilter: any = '';
  activeSelection = 0;
  activeN1: number = 0;
  activeN2: number = 0;
  activeN: number;

  constructor(
    private ds: PopupTypeService,
    private tableFilterService: TableFilterService,
    private router: Router,
  ) { }

  calMbps() {
    if (this.cloudUserSummary) {
      var roundnum = this.cloudUserSummary.bitrate / (1024 * 1024);
      this.calculatedMbps = Math.round(roundnum * 100) / 100;
      return this.calculatedMbps;
    }
  }

  setActive(n) {
    if (this.activeSelection == n) {
      this.activeSelection = 0;
    } else {
      this.activeSelection = n;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.activeSelection = 0;
    if (changes.cloudUserSummary && this.cloudUserSummary) {
      // // console.log('cloudUserSummary: ', this.cloudUserSummary)
      //not sure why i need this
    }
  }



  setFilter = (filter) => {
    this.router.navigate([`cloud/${filter}/`])
    this.clickFilter(filter)
  }

  clickFilter = (filter) => {
    this.tableFilterService.filter(filter);
  }

  ngOnInit() {
    this.ds.sendPopupType(0); // ??
    this.setActiveFilter()
  }

  setActiveFilter = () => {
    this.activeFilter = ''
    if (this.router.url == '/cloud/livemeetings') {
      this.activeFilter = 'liveMeetings'
    }

    if (this.router.url == '/cloud/impacteduser') {
      this.activeFilter = 'impacted'
    }

  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }
}
