import 'rxjs/Rx'

import { phone } from 'phone'
import { Subscription } from 'rxjs/Subscription'

import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

import { GetAudioService } from '../app/services/get-audio.service'
import { GetCloudService } from '../app/services/get-cloud.service'
import { GetVideoService } from '../app/services/get-video.service'
import { environment } from '../environments/environment'
import { AuthService } from './services/auth.service'
import { GetIotService } from './services/get-iot.service'
import { NotificationsService } from './services/notifications.service'
import { RefreshApiService } from './services/refresh-api.service'
import { TableFilterService } from './services/table-filter.service'
import { TutCloseService } from './services/tut-close.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public videoSitesSummary;
  public audioUserSummary;
  public cloudRoomSummary: any = [];
  public cloudRoomSummaryCard: any;
  public videoDeviceSummary: any = [];
  public videoDeviceSummaryCard: any;
  public audioDeviceSummary: any = [];
  public audioDeviceSummaryCard: any;
  public iotDeviceSummaryCard: any;
  showPoweredBy: boolean = false;
  @Output() runTutorial: any = 0;
  @Output() keyDown: any = 0;
  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  @ViewChild('container', { static: false }) container: ElementRef;
  HealthBlockStateUpLt = 'home';
  HealthBlockStateUpRt = 'home';
  HealthBlockStateLwLt = 'home';
  HealthBlockStateLwRt = 'home';
  ScaleRouterOutletState = 'hide';
  activeSection: string;
  activeBtn: string;
  id: number;
  loginClass: string;
  authLoggedon: any;
  apiTimers: any;
  videoDeviceApiSummary: any = [];
  videoApiData: any = [];
  updateAPI: any;
  loginType: string;

  iotDataSrc: any;
  ciscoDevices: any = 0;
  polycomDevices: any = 0;
  polycomListLength: any = '';
  ciscoListLength: any = '';
  logitechDevices: number = 0;
  logitechListLength: number = 0;

  getTutorial: any;
  setTutorial: any;
  subscription: Subscription;
  filterListner: Subscription;
  closeTutType: any;
  isShowDomotzIntegration = environment.config.domotzIntegration;
  environmentConfigTitle = environment.config.title;

  shouldShowZoomImg: boolean = false;
  domotzAccountEnabled: boolean = false;

  zoomEnabled: boolean = false;
  teamsEnabled: boolean = false;
  verifiedCode: string = 'false';
  isInAddPhoneNumberRoute: boolean = false;
  isTwoFactorAuthRoute: boolean = false;
  iotLiteEnabled: any;
  slackState: string = this.Auth.accessLevel == 'SUPERADMIN' ? 'open' : 'close';
  newNotificationsCount: number;
  iotQuadrantMainTitle: any = "IoT Devices";
  
  constructor(
    public http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private getVideoDataService: GetVideoService,
    private getAudioDataService: GetAudioService,
    private getCloudDataService: GetCloudService,
    private getIot: GetIotService,
    public Auth: AuthService,
    public tableService: TableFilterService,
    public refreshApiData: RefreshApiService,
    public TutClose: TutCloseService,
    private notificationService: NotificationsService
  ) {
    this.verifiedCode = localStorage.getItem('verified');
    this.filterListner = this.refreshApiData
      .refreshAPIListen()
      .subscribe((m: any) => {
        this.refreshData = m;
        const token = localStorage.getItem('token');
        if (token) {
          this.getApiData();
        } else {
          this.filterListner.unsubscribe();
        }
      });

    // subscribe to home component messages
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isInAddPhoneNumberRoute =
          event.url === '/add-phone-number' ? true : false;
        this.isTwoFactorAuthRoute =
          event.url === '/two-factor-auth' ? true : false;
        const checkUrl = event.url.split(/[\/?]/) // Split by '/' or '?'
        const token = localStorage.getItem('token');
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(token);
        if (this.Auth.isAuthenticated()) {
          this.getCompanyInfo();
        }
        if (checkUrl[1].includes('admin_consent')) {
          this.router.navigate(['/']);
        }
        if (event.url === '/') {
          this.verifiedCode = localStorage.getItem('verified');
          if (this.verifiedCode === 'true') {
            this.authLoggedon = this.Auth.isAuthenticated();
            this.ScaleRouterOutletState = 'hide';
            this.activeSection = '';
            this.activeBtn = '';
            if (this.loginType === 'directAccess') {
              this.router.navigate(['/logout']);
            }
          } else {
            //this.router.navigate(['/']);
            if (this.Auth.isAuthenticated()) {
              const phoneLib = phone(localStorage.getItem('phone'), {
                validateMobilePrefix: true,
              });
              if (
                decodedToken.two_factor_auth_option === 'sms' &&
                phoneLib.isValid
              ) {
                this.router.navigate(['/two-factor-auth']);
              } else {
                this.router.navigate(['/add-phone-number']);
              }
            } else {
              this.router.navigate(['/login']);
            }
          }
        } else if (event.url === '/two-factor-auth') {
          this.verifiedCode = localStorage.getItem('verified');
          const phoneLib = phone(localStorage.getItem('phone'), {
            validateMobilePrefix: true,
          });
          if (this.verifiedCode === 'true') {
            this.router.navigate(['/']);
          } else if (!phoneLib.isValid) {
            this.router.navigate(['/add-phone-number']);
          }
        } else if (
          [
            'login',
            'newuserregistration',
            'activateRegistration',
            'forgotpassword',
            'registration',
          ].includes(checkUrl[1]) &&
          this.Auth.isAuthenticated()
        ) {
          this.router.navigate(['/']);
        } else if (['tokenlogin', 'tokenloginadmin', 'instantLogin'].includes(checkUrl[1])) {
          localStorage.clear();
          sessionStorage.clear();
          this.ScaleRouterOutletState = 'hide';
          localStorage.setItem('verified', 'true');
        } else if (
          ['resetpassword', 'document-center-view'].includes(checkUrl[1])
        ) {
          this.ScaleRouterOutletState = 'hide';
        } else {
          this.ScaleRouterOutletState = 'show';
          this.authLoggedon = this.Auth.isAuthenticated();
          let a = event.url.split('/');
          this.activeSection = a[1];
          // will allow you to highlight active panel?
          this.activeBtn = a[2];
        }
      }
    });

    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });

    this.subscription = this.TutClose.getOverlay().subscribe((x) => {
      this.closeTutType = x;
      if (this.closeTutType != null && this.closeTutType != 0) {
        this.closeTutType = 1;
      } else {
        this.closeTutType = 0;
      }
    });
  }

  getApiData() {
    if (this.refreshData) {
      this.getIotLiteStatus();
      this.getStats()
    }
  }

  getApiDataTimer() {
    this.apiTimers = setInterval(() => {
      this.refreshApiData.updateApiData(true);
    }, 30000);
  }
  getCompanyInfo() {
    const decodedToken = this.getDecodedtoken();

    this.Auth.getCompanyInfo({ company_id: decodedToken.company_id }).subscribe(
      (data) => {
        this.Auth.logo = data.response[0].logo;
        this.Auth.companyName = data.response[0].company_name;
      }
    );
  }

  ngOnInit() {
  //rebase 091724
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // const checkUrl = event.url.split('/');
        const checkUrl = event.url.split(/[\/?]/) // Split by '/' or '?'
        if (checkUrl[1] === 'resetpassword') {
          if (this.Auth.isAuthenticated()) {
            this.ScaleRouterOutletState = 'hide';
          }
        } else if (
          this.Auth.isAuthenticated() !== true &&
          ![
            'newuserregistration',
            'activateRegistration',
            'forgotpassword',
            'registration',
            'tokenlogin',
            'tokenloginadmin',
            'instantLogin',
            'direct-access-login',
            'document-center-view',
            'login',
          ].includes(checkUrl[1])
        ) {
          this.router.navigate(['login']);
        } else if (
          this.Auth.isAuthenticated() &&
          ['tokenlogin', 'tokenloginadmin', 'instantLogin'].includes(checkUrl[1])
        ) {
          localStorage.clear();
          sessionStorage.clear();
          localStorage.setItem('verified', 'true');
        } else {
          if (this.Auth.isAuthenticated()) {
            const decodedToken = this.getDecodedtoken();

            this.loginType = decodedToken.loginType;
            this.Auth.accessLevel = decodedToken.accesslevel;
            this.Auth.companyId = decodedToken.company_id;
            this.Auth.userId = decodedToken.user_id;
            clearInterval(this.apiTimers);
            if (this.isShowDomotzIntegration) {
              // if cxdetect
              this.getDomotzAccountByCompanyId();
              this.iotQuadrantMainTitle = "IoT Spaces"
              
            }
            this.getIotLiteStatus();
            this.getStats()

            this.getApiDataTimer();
            // temp solution
            if (this.loginType !== 'directAccess') {
              this.getTutorialapi();
            }
          }
        }
      }
    });

    this.refreshApiData.refreshStatsEvent.subscribe((statType: string) => {
      switch (statType) {
				case 'video':
					this.getVideoStats()
					break
				case 'cloud':
					this.getCloudStats()
					break
				case 'audio':
					this.getAudioStats()
					break
				case 'iot':
					this.getIotStats()
					break
			}
    })
  }

  getDomotzAccountByCompanyId = () => {
    this.getIot.getDomotzAccountByCompanyId().subscribe((data: any) => {
      const { enabled } = data;
      this.domotzAccountEnabled = enabled === 1;
    });
  };

  getIotLiteStatus = () => {
    if (this.getIot.getCompanyId() === -1) {
      this.iotLiteEnabled = 1;
      this.getIotStats()
    } else {
      this.getIot.getIotLiteStatus().subscribe((data: any) => {
        let [res] = data.response;
        if (res !== undefined) {
          this.iotLiteEnabled = res.is_iot_lite;
          this.getIotStats()
        }
      });
    }
  };

  ngOnDestroy() {
    clearInterval(this.apiTimers);
  }

  getTutorialapi = () => {};

  getStats = () => {
    this.getZoomInfo();
    this.getVideoStats()
    this.getCloudStats()
    this.getAudioStats()
    // this.getIotStats()
  };

  getVideoStats = () => {
    const companyId = {
      company_id: this.Auth.companyId
    }
    this.getVideoDataService.getVideoDeviceSummary(companyId).subscribe(data => {
      let temp = data['response']
      // console.log('getVideoDataService: ', temp)
      if (temp[0].device_logos != null) {
        for (let i = 0; i < temp[0].device_logos.length; i++) {
          if (temp[0].device_logos[i] === 'polycom' || temp[0].device_logos[i] === 'polystudio') {
            this.polycomDevices = 1
            this.polycomListLength = 1
          } else if (temp[0].device_logos[i] === 'cisco') {
            this.ciscoDevices = 1
            this.ciscoListLength = 1
          } else if (temp[0].device_logos[i] === 'logitech') {
            this.logitechDevices = 1
            this.logitechListLength = 1
          }
        }
      } else {
        this.polycomDevices = 0
        this.ciscoDevices = 0
        this.logitechDevices = 0
      }

      if (this.runTutorial === 1) {
        if (temp[0].device_logos != null) {
          for (let i = 0; i < temp[0].device_logos.length; i++) {
            if (
              temp[0].device_logos[i] === 'polycom' ||
              temp[0].device_logos[i] === 'polystudio'
            ) {
              this.polycomDevices = 1
              this.polycomListLength = 1
            } else if (temp[0].device_logos[i] === 'cisco') {
              this.ciscoDevices = 1
              this.ciscoListLength = 1
            } else if (temp[0].device_logos[i] === 'logitech') { 
              this.logitechDevices = 1;
              this.logitechListLength = 1;
            }
          }
        } else {
          this.polycomDevices = 0;
          this.ciscoDevices = 0;
          this.logitechDevices = 0;
        }
      }

      this.videoDeviceSummary = data['response']
      const [srcData] = data['response']
      const { enabled, all_devices } = srcData
      this.videoDeviceSummaryCard = {
        enabled,
        bars: [
          {
            color: 'green',
            routerLink: srcData.good_devices > 0 ? '/video/healthydevices' : null,
            total: all_devices,
            value: srcData.good_devices,
          },
          {
            color: 'yellow',
            routerLink: srcData.impacted_devices > 0 ? '/video/warningdevices' : null,
            total: all_devices,
            value: srcData.impacted_devices,
          },
          {
            color: 'red',
            routerLink: srcData.red_devices > 0 ? '/video/impacteddevices' : null,
            total: all_devices,
            value: srcData.red_devices,
          },
          {
            color: 'blue',
            routerLink: srcData.paused_devices > 0 ? '/video/pauseddevices' : null,
            total: all_devices,
            value: srcData.paused_devices,
          },
        ],
        buttons: [
          {
            key: 'vbtn1',
            routerLink: '/video/impactedsites',
            color: 'gray',
            count: srcData.impacted_sites,
            label: 'Impacted Sites',
          },
          {
            key: 'vbtn2',
            routerLink: '/video/allsites',
            color: 'gray',
            count: srcData.all_sites,
            label: 'All Sites',
          },
          {
            key: 'vbtn3',
            routerLink: '/video/alldevices',
            color: 'gray',
            count: all_devices,
            label: 'All Devices',
          },
        ],
      }
    })
  }

  getCloudStats = () => {
    this.getCloudDataService.fetchApiCloudSummary().subscribe(data => {
			this.cloudRoomSummary = data['response']
			const [srcData] = data['response']
			let {
				good_rooms,
				warning_rooms,
				red_rooms,
				all_huddle_rooms,
				paused_rooms,
				impacted_vip_users,
				all_users,
				zoom_enabled,
				teams_all_users,
				zoom_all_users,
			} = srcData
			//fix this someday
			this.teamsEnabled = teams_all_users > 0
			this.zoomEnabled = zoom_enabled === 1 && zoom_all_users > 0
			this.cloudRoomSummaryCard = {
				enabled: this.zoomEnabled || this.teamsEnabled,
				bars: [
					{
						color: 'green',
						routerLink: good_rooms > 0 ? '/cloud/healthyrooms' : null,
						total: all_huddle_rooms,
						value: good_rooms,
					},
					{
						color: 'yellow',
						routerLink: warning_rooms > 0 ? '/cloud/warningrooms' : null,
						total: all_huddle_rooms,
						value: warning_rooms,
					},
					{
						color: 'red',
						routerLink: red_rooms > 0 ? '/cloud/redrooms' : null,
						total: all_huddle_rooms,
						value: red_rooms,
					},
					{
						color: 'blue',
						routerLink: paused_rooms > 0 ? '/cloud/pausedrooms' : null,
						total: all_huddle_rooms,
						value: paused_rooms,
					},
				],
				buttons: [
					{
						key: 'cbtn1', // just a unique identifier for the element so that it will not be annoying when it keeps refreshing
						routerLink: '/cloud/allrooms',
						color: 'gray',
						count: all_huddle_rooms,
						label: 'All Rooms',
					},
					{
						key: 'cbtn2',
						routerLink: '/cloud/impactedvipuser',
						color: 'gray',
						count: impacted_vip_users,
						label: 'Impacted VIP Users',
					},
					{
						key: 'cbtn3',
						routerLink: '/cloud/allusers',
						color: 'gray',
						count: all_users,
						label: 'All Users',
					},
				],
			}
		})
  }

  getAudioStats = () => {
    this.getAudioDataService.apiGetAudioDeviceSummary().subscribe(data => {
			this.audioDeviceSummary = data['response'][0]

			const [srcData] = data['response']

			const { enabled, all_devices } = srcData
			this.audioDeviceSummaryCard = {
				enabled,
				bars: [
					{
						color: 'green',
						routerLink: srcData.good_devices > 0 ? '/audio/healthydevices' : null,
						total: all_devices,
						value: srcData.good_devices,
					},
					{
						color: 'yellow',
						routerLink: srcData.impacted_devices > 0 ? '/audio/warningdevices' : null,
						total: all_devices,
						value: srcData.impacted_devices,
					},
					{
						color: 'red',
						routerLink: srcData.red_devices > 0 ? '/audio/impacteddevices' : null,
						total: all_devices,
						value: srcData.red_devices,
					},
					{
						color: 'blue',
						routerLink: srcData.paused_devices > 0 ? '/audio/pauseddevices' : null,
						total: all_devices,
						value: srcData.paused_devices,
					},
				],
				buttons: [
					{
						key: 'abtn1',
						routerLink: '/audio/impactedsites',
						color: 'gray',
						count: srcData.impacted_sites,
						label: 'Impacted Sites',
					},
					{
						key: 'abtn2',
						routerLink: '/audio/allsites',
						color: 'gray',
						count: srcData.all_sites,
						label: 'All Sites',
					},
					{
						key: 'abtn3',
						routerLink: '/audio/alldevices',
						color: 'gray',
						count: all_devices,
						label: 'All Devices',
					},
				],
			}
		})
  }
  
  allSpaces: any = 0;
  healthySpaces: any = 0;
  warningSpaces: any = 0;
  impactedSpaces: any = 0;
  pauseSpaces: any = 0;

  getIotStats = () => {
    this.getIot.getAllIotDeviceSummary().subscribe((data) => {
      this.iotDataSrc = data;
      // console.log('iotlite: ',this.iotLiteEnabled)
      console.log('iotDataSrc: ',this.iotDataSrc)
      const {
        all_impacted_agents,
        is_enabled: enabled,
        all_devices,
        pause_devices,
        online_devices,
        down_devices,
        all_agents,
        impacted_devices,
        //spaces
        all_spaces,
        warning_spaces,
        healthy_spaces,
        impacted_spaces,
        pause_spaces
      } = this.iotDataSrc;
      
        this.allSpaces = all_spaces || 0;
        this.healthySpaces = healthy_spaces || 0;;
        this.warningSpaces = warning_spaces || 0;;
        this.impactedSpaces = impacted_spaces || 0;;
        this.pauseSpaces = pause_spaces || 0;

      const buttonsDomotz = [
        {
          key: 'ibtn1',
          routerLink: '/iot/allagents',
          color: 'gray',
          count: all_agents,
          label: 'All Agents',
        },
        {
          key: 'ibtn2',
          routerLink: '/iot/impactedagents',
          color: 'gray',
          count: all_impacted_agents,
          label: 'Impacted Agents',
        },
        {
          key: 'ibtn3',
          routerLink: '/iot/alldevices',
          color: 'gray',
          count: all_devices,
          label: 'All Devices',
        },
      ];

      const buttonsIotLite = [
        {
          key: 'ibtn1',
          routerLink: '/iot/alldevices',
          color: 'gray',
          count: null,
          label: 'All Sites',
        },
        {
          key: 'ibtn2',
          routerLink: '/iot/alldevices',
          color: 'gray',
          count: null,
          label: 'Impacted Sites',
        },
        {
          key: 'ibtn3',
          routerLink: '/iot/alldevices',
          color: 'gray',
          count: all_devices,
          label: 'All Devices',
        },
      ];

      const buttonsForIot = this.iotLiteEnabled
        ? buttonsIotLite
        : buttonsDomotz;
      const impactedBarForIot = this.iotLiteEnabled ? impacted_devices : 0;
      const impactedBarRouterForIot = this.iotLiteEnabled
        ? '/iot/impacteddevices'
        : null;

      this.iotDeviceSummaryCard = {
        enabled,
        bars: [
          {
            color: 'green',
            routerLink: online_devices > 0 ? '/iot/onlinedevices' : null,
            total: all_devices,
            value: online_devices,
          },
          {
            color: 'yellow',
            routerLink: impactedBarRouterForIot,
            total: all_devices,
            value: impactedBarForIot,
          },
          {
            color: 'red',
            routerLink: down_devices > 0 ? '/iot/downdevices' : null,
            total: all_devices,
            value: down_devices,
          },
          {
            color: 'blue',
            routerLink: pause_devices > 0 ? '/iot/pauseddevices' : null,
            total: all_devices,
            value: pause_devices,
          },
        ],
        buttons: buttonsForIot,
      };
    });
    
  };
  
  navigateToSpaces = (spaces) => {
  
    // this.allSpaces = all_spaces;
    //     this.healthySpaces = healthy_spaces;
    //     this.warningSpaces = warning_spaces;
    //     this.impactedSpaces = impacted_spaces;
    //     this.pauseSpaces = 0;
    // console.log('navigateToSpaces: ', spaces)
    console.log('spaces!', spaces)
    
    if(spaces == 'all' && this.allSpaces > 0) {
      this.router.navigate(['/iot/allspaces']);
    }
    
    if(spaces == 'healthy' && this.healthySpaces > 0) {
     this.router.navigate(['/iot/healthy']);
    }

    if(spaces == 'warning' && this.warningSpaces > 0) {
       this.router.navigate(['/iot/warning']);
    }

    if(spaces == 'impacted' && this.impactedSpaces > 0) {
         this.router.navigate(['/iot/impacted']);
    }

    if(spaces == 'pause' && this.pauseSpaces > 0) {
        this.router.navigate(['/iot/pause']);
    }
  }

  getZoomInfo = () => {
    this.getCloudDataService.getZoomInfo().subscribe((data) => {
      const response = data.response;
      if (response) {
        this.shouldShowZoomImg = response.active === 1;
      }
    });
  };

  getDecodedtoken = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    return helper.decodeToken(token);
  };

  onChangeSlackState = () => {
    this.slackState = this.slackState === 'open' ? 'close' : 'open'
    if (this.slackState === 'open') {
      this.notificationService.markAllNotificationsAsSeen(this.Auth.companyId).subscribe(() => {
        this.newNotificationsCount = 0
      })
    }
  }
  
  routeToIotSpaces = () => {
    this.router.navigate(['/iot/allspaces']);
  }
  
  
  
  
}
