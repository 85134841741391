import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iot-all-agents',
  templateUrl: './iot-all-agents.component.html',
  styleUrls: ['./iot-all-agents.component.scss']
})
export class IotAllAgentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
