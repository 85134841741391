<div class="video-device-stats">
  <!--  delete other stuff -->
  <div *ngIf="deviceType != 'logitech'">
    <app-video-device-live-stats [videoDeviceData]="videoDeviceData"></app-video-device-live-stats>
    <hr />
    <app-video-last-disconnect [videoDeviceData]="videoDeviceData"></app-video-last-disconnect>
    <hr />
    <app-video-device-health [videoDeviceData]="videoDeviceData"></app-video-device-health>
    <hr />
    <app-system-temp [manufacturer]="videoDeviceData?.device_type" [temperature]="videoDeviceData?.temprature">
    </app-system-temp>
    <hr />
    <app-video-life-time-usage [videoDeviceData]="videoDeviceData" [hasAccess]="hasAccess"></app-video-life-time-usage>
    <app-video-device-update *ngIf="videoDeviceData?.device_type === 'polystudio'" [videoDeviceData]=" videoDeviceData">
    </app-video-device-update>
  </div>


  <div *ngIf="deviceType == 'logitech'">
    <app-video-live-stat-logitech [pingRtd]="pingRtd" [packetLoss]="packetLoss"
      [iotDeviceDetails]="deviceHisoryParams"></app-video-live-stat-logitech>
    <hr />
    <app-video-historic-ping [worstRtd]="worstRtd" [medianRtd]="medianRtd" [bestRtd]="bestRtd"
      [packetLossRtd]="packetLossRtd" [iotDeviceDetails]="deviceHisoryParams"></app-video-historic-ping>
    <hr />
    <app-video-service-provider-status [logitechData]="logitechData"></app-video-service-provider-status>
    <hr />
    <app-video-remote-support-integration [logitechData]="logitechData"></app-video-remote-support-integration>
    <hr />
  </div>




  <app-video-notes [videoDeviceData]="videoDeviceData" [hasAccess]="hasAccess"></app-video-notes>

</div>
<!-- Note Section -->