import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-collector-list-container',
	templateUrl: './collector-list-container.component.html',
	styleUrls: ['./collector-list-container.component.css'],
})
export class CollectorListContainerComponent implements OnInit {
	PopupTitle = 'Collectors'
	latestCollector:any;
	collectorList:any[] = [];
	retryAgain:any;
	constructor() {}

	ngOnInit(): void {}
	getLatest(data){
		this.latestCollector = data;
	}
	getCollectorList(data){
		this.collectorList = [...data];
	}
	reTryEvent($event){
		this.retryAgain = {...$event};
	}
}
