<div class="container py-5">
  <div class="row">
    <div class="col-12 text-center">
      <p>Are you sure you want Reboot?</p>
    </div>
  </div>
  <div class="row text-center">
    <div class="col-6 float-right">
      <button (click)="rebootDevice()" class="px-4 py-1">Yes</button>
    </div>
    <div class="col-6 float-left">
      <button class="px-4 py-1" (click)="sendPopupType(99)">No</button>
    </div>
  </div>
</div>