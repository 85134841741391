<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <img [src]="dashboardLogo" style="width:350px;">
        </div>
        <div class="col-12">
          <img *ngIf="siteType==0"  [src]="iconAddSite" style="height: 20px;">
          <img *ngIf="siteType==1" [src]="iconVirtual" style="height: 30px;">
          <h3 class="font-weight-bold pl-3">{{siteName}}</h3>
          <h3 class="text-left font-weight-bold pl-3" *ngIf="isDevice==true">{{deviceName}}</h3>
        </div>
      </div>
    </div>
    <div class="col-6">
      <h4 class="text-right">{{siteTitle}}</h4>
      <h5 class="text-right">{{siteSubTitle}}</h5>
      <h5 class="text-right">{{months[fromdate.month-1]}} {{fromdate.day}}, {{fromdate.year}} - {{months[todate.month-1]}} {{todate.day}}, {{todate.year}}</h5>
    </div>
  </div>
</div>