import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { GetIotService } from '../../services/get-iot.service';
import { MatDialog } from '@angular/material/dialog';
import { LogitechAddDeviceDialogComponent } from '../dialog/logitech-add-device-dialog/logitech-add-device-dialog.component'



@Component({
  selector: 'app-logitech-add-device',
  templateUrl: './logitech-add-device.component.html',
  styleUrls: ['./logitech-add-device.component.scss']
})
export class LogitechAddDeviceComponent implements OnInit {
  @Input() iotLiteEnabled: any;
  @Input() logitechMonitor: any;
  siteList: any = [];
  collectorID: any = "";
  siteId: any = "";

  deviceRoomName: any = '';
  deviceIp: any = '';
  iotIp: any = '';
  iotName: any = '';
  deviceName: any = '';
  devicePassword: any = '';

  disableSelectAndSubmit: any = false;
  collectorLatestVersion: any = '';
  showSiteListErrMsg: any = false;
  setTimer: any
  deviceFlag: any = false;

  constructor(
    private Auth: AuthService,
    private GetIot: GetIotService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    // this.getCollectorVersionData();
    this.getSiteList();
    this.collectorLatestVersion = '2.1.0';

    this.resetFields();
  }

  resetFields = () => {
    this.deviceName = 'Admin';
    this.devicePassword = '';
  }

  disableAllFields: any = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.iotLiteEnabled) {
      if (this.iotLiteEnabled) {
        this.checkIfIotDisabled();
      } else {
        this.disableAllFields = true;
      }
    }
  }

  checkIfIotDisabled = () => {
    this.disableAllFields = true;
    if (this.iotLiteEnabled) {
      this.disableAllFields = false;
    }
  }

  getSiteList = () => {
    this.Auth.getAllSiteList().subscribe((data) => {
      let res = data.response;
      console.log('res: ', res)
      this.siteList = res.map(e => {
        return {
          collector_id: e.collector.collector_id,
          version: e.collector.version,
          status: e.collector_status,
          site_name: e.site_name,
          site_id: e.site_id,
        }
      }).filter(e => e.status === 1)

      console.log('siteList: ', this.siteList)

      if (this.siteList.length < 1) {
        this.showSiteListErrMsg = true;
      }
    });
  }

  siteSelectorEvent = (event: any) => {
    const siteId = this.siteId;
    const [siteDetails] = this.siteList.filter(e => e.site_id == siteId);
    console.log(siteDetails)
    const { version, collector_id } = siteDetails;
    this.disableSelectAndSubmit = this.collectorLatestVersion > version;
    this.collectorID = collector_id;
  }



  checkIfEmptyField = (params) => {
    return params == '';
  }

  deviceRoomNameEmpty: any = false;
  deviceIpEmpty: any = false;
  devicePasswordEmpty: any = false;
  deviceAdminEmpty: any = false;
  errorCount: any = 0;

  //improve this T_T
  validation = () => {
    if (this.checkIfEmptyField(this.collectorID)) {
      this.disableSelectAndSubmit = true;
    } else {
      this.disableSelectAndSubmit = false;
    }

    if (this.checkIfEmptyField(this.deviceRoomName)) {
      this.deviceRoomNameEmpty = true;
    } else {
      this.deviceRoomNameEmpty = false;
    }

    if (this.checkIfEmptyField(this.deviceIp)) {
      this.deviceIpEmpty = true;
    } else {
      this.deviceIpEmpty = false;

    }

    if (!this.deviceFlag) {
      if (this.checkIfEmptyField(this.deviceName)) {
        this.deviceAdminEmpty = true;
      } else {
        this.deviceAdminEmpty = false;
      }

    }

    if (this.checkIfEmptyField(this.devicePassword)) {
      this.devicePasswordEmpty = true;
    } else {
      this.devicePasswordEmpty = false;
    }

    if (this.disableSelectAndSubmit || this.deviceRoomNameEmpty || this.deviceIpEmpty || this.devicePasswordEmpty) {
      if (!this.deviceFlag && this.deviceAdminEmpty) {
        return true
      }
      return true
    }

    return false;

  }

  openPolycomAddDeviceDialog = () => {
    if (this.validation()) {
      // this.disableSelectAndSubmit = true;
    } else {
      const config = {
        collector_id: this.collectorID,
        deviceName: this.deviceName,
        deviceRoomName: this.deviceRoomName,
        deviceIp: this.deviceIp,
        iotIp: this.iotIp,
        iotName: this.iotName,
        devicePassword: this.devicePassword,
        deviceFlag: this.deviceFlag,
        siteId: this.siteId,
      };

      this.dialog.open(LogitechAddDeviceDialogComponent, {
        panelClass: 'custom-dialog-container',
        data: {
          config,
        },
      });
    }




  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };


  toggleSwitch = (event) => {
    this.deviceFlag = event.target.checked;
    console.log(' this.deviceFlag: ', this.deviceFlag)
  }

}