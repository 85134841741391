import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

@Component({
  selector: 'app-registration',
  templateUrl: './newuserregistration.component.html',
  styleUrls: ['./newuserregistration.component.scss'],
})
export class NewUserRegistrationComponent implements OnInit {
  id: any;
  registerInfo: any;
  registerUser: any;

  dashboardLogo = environment.config.dynamicImage.dashboardLogo;
  showPoweredBy = environment.config.showPoweredBy;
  links = environment.config.links;
  backgroundImage = environment.config.dynamicImage.bgImage;
  loginEnv: any;

  visibilityBackground = {
    'background-image': 'url(' + this.backgroundImage + ')',
    'background-repeat': 'no-repeat',
    'background-size': 'cover',
    height: '100vh',
  };

  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    this.getRegisterationUserInfo();

    // console.log('this is getRegisterationUserInfo!')

    this.setData()
  }

  setData = () => {
    if (environment.config.title === 'VisibilityOne'
      || environment.config.title === 'vCare'
      || environment.config.title === 'Vision Point'
      || environment.config.title === 'Videlio'
      || environment.config.title === 'DEKOM') {
      this.loginEnv = true
    } else {
      this.loginEnv = false;
    }
    // console.log(this.loginEnv, 'register')
  }


  registerEmitEvent = ({ firstname, lastname, password }) => {
    this.registerNewUser(firstname, lastname, password);
  };

  registerNewUser = (firstname, lastname, password) => {
    const registrationData = {
      data: {
        firstname,
        lastname,
        password,
        registration_code: this.id,
      },
    };
    this.Auth.registerUser(registrationData).subscribe((data) => {
      if (data.response[0].status.toLowerCase() === 'ok') {
        this.router.navigate(['login', { message: data.response[0].message, flag: true, type: 2 }]);
      }
    });
  };

  getRegisterationUserInfo = () => {
    this.Auth.getUserRegistrationInfo({ code: this.id }).subscribe((data: any) => {
      const [response] = data.response;
      if (response) {
        response.link = true;
      }
      this.registerInfo = response || { link: false };
    });
  };
}
