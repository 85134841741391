<div class="row right-panel">
  <div class="col-12">
    <app-panel-tools [PopupTitle]="PopupTitle" [isCollectorUpdate]="true" (lastestCollectorEvent)="getLatest($event)" [collector]="collectorList" [retryAgain]="retryAgain"></app-panel-tools>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-12 px-1 filter-table">
        <app-collector-list [latestCollector]="latestCollector"
        (collectorEvent)="getCollectorList($event)"
        (reTryEvent)="reTryEvent($event);"
        ></app-collector-list>
      </div>
    </div>
  </div>
</div>
