import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GetUsStatesService {

  constructor(
    private http: HttpClient
  ) { }

   // Load the JSON file for the Site Panel
   fetchUsStates() {
    return this.http.get('/assets/db/us_states.json');
  }
}