import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ZoomIncidentTrackerService } from '../../services/zoom-incident-tracker.service';
import { CallHelperService } from '../../services/call-helper.service';
import { ZoomHelperService } from '../../services/zoom-helper.service';

@Component({
  selector: 'app-zoom-monitoring',
  templateUrl: './zoom-monitoring.component.html',
  styleUrls: ['./zoom-monitoring.component.scss'],
})
export class ZoomMonitoringComponent implements OnInit, OnChanges {
  @Input() zoomData: any;
  onCall = 0;
  incidentSummary: any;
  isShowSip: boolean = false;
  hasIssues = false;
  roomName: string;
  siteName: string;
  connection: any;
  mics: number;
  micsImpacted: number;
  cameras: number;
  camerasImpacted: number;
  speakers: number;
  speakersImpacted: number;

  pluginId: number;
  pluginEnabled: number;
  pluginStatus: number;

  isShowSystemStatusConnectedDevicesHeader: boolean = false;
  isShowSystemStatusConnectedDevicesBody: boolean = true;
  isShowSystemStatusCallHeader: boolean = true;
  isShowSystemStatusCallBody: boolean = false;
  isExpandSystemStatusConnectedDevices: boolean = true;
  isExpandSystemStatusCall: boolean = false;

  callStatusData: any;

  constructor(
    private zoomIncident: ZoomIncidentTrackerService,
    private callHelper: CallHelperService,
    private zoomHelper: ZoomHelperService
  ) {
    this.zoomIncident.resetZoomIncidents();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.zoomData) {
      const { room_name, site_name, plugin_id, zoom_plugin_enabled, plugin_status } = this.zoomData;
      this.onCall = this.zoomData.liveMeetings === null ? 0 : this.zoomData.liveMeetings;
      this.pluginStatus = plugin_status;
      this.pluginEnabled = zoom_plugin_enabled;
      this.pluginId = plugin_id;

      this.roomName = room_name;
      this.siteName = site_name;

      this.iniSystemStatus();
      this.iniCallStatus();
      this.gatherIncidents();
    }
  }

  toggleSystemStatusConnectedDevices = () => {
    this.isShowSystemStatusConnectedDevicesHeader = false;
    this.isShowSystemStatusConnectedDevicesBody = true;
    this.isShowSystemStatusCallHeader = true;
    this.isShowSystemStatusCallBody = false;
    this.isExpandSystemStatusConnectedDevices = true;
    this.isExpandSystemStatusCall = false;
  };

  toggleSystemStatusCall = () => {
    this.isShowSystemStatusConnectedDevicesHeader = true;
    this.isShowSystemStatusConnectedDevicesBody = false;
    this.isShowSystemStatusCallHeader = false;
    this.isShowSystemStatusCallBody = true;
    this.isExpandSystemStatusConnectedDevices = false;
    this.isExpandSystemStatusCall = true;
  };

  iniSystemStatus = () => {
    if (this.pluginId === null || this.pluginEnabled === 0) {
      const { connected_devices } = this.zoomData;
      this.mics = connected_devices.microphone.trim().length === 0 ? 0 : 1;
      this.micsImpacted = connected_devices.microphone.trim().length === 0 ? 1 : 0;
      this.cameras = connected_devices.camera.trim().length === 0 ? 0 : 1;
      this.camerasImpacted = connected_devices.camera.trim().length === 0 ? 1 : 0;
      this.speakers = connected_devices.speaker.trim().length === 0 ? 0 : 1;
      this.speakersImpacted = connected_devices.speaker.trim().length === 0 ? 1 : 0;
    } else {
      const { zoom_plugin_connected_devices } = this.zoomData;
      this.mics = this.zoomHelper.countInuseDevice(zoom_plugin_connected_devices.audio_mic);
      this.micsImpacted = zoom_plugin_connected_devices.audio_mic_disconnected.length;
      this.cameras = this.zoomHelper.countInuseDevice(zoom_plugin_connected_devices.video);
      this.camerasImpacted = zoom_plugin_connected_devices.video_disconnected.length;
      this.speakers = this.zoomHelper.countInuseDevice(zoom_plugin_connected_devices.audio_speaker);
      this.speakersImpacted = zoom_plugin_connected_devices.audio_speaker_disconnected.length;
    }
  };

  iniCallStatus = () => {
    const {
      total_jitter,
      avg_packet_loss,
      frame_rate,
      trigger_limits,
      triggers,
      connection_type,
    } = this.zoomData;

    this.callStatusData = {
      jitter: {
        value: '--',
        color: 'gray',
      },
      packetloss: {
        value: '--',
        color: 'gray',
      },
      frame_rate: {
        value: '--',
        color: 'gray',
      },
    };

    if (this.onCall) {
      // jitter
      this.callStatusData.jitter.value = total_jitter;
      this.callStatusData.jitter.color = this.callHelper.getColor(
        trigger_limits.jitter.good,
        total_jitter,
        triggers.jitter.warning,
        triggers.jitter.alert
      );

      // packetloss
      this.callStatusData.packetloss.value = avg_packet_loss;
      this.callStatusData.packetloss.color = this.callHelper.getColor(
        trigger_limits.packetsloss.good,
        avg_packet_loss,
        triggers.packetsloss.warning,
        triggers.packetsloss.alert
      );

      // frame_rate
      this.callStatusData.frame_rate.value = frame_rate;
      this.callStatusData.frame_rate.color = this.callHelper.getColor(
        trigger_limits.frame_rate.good,
        frame_rate,
        triggers.frame_rate.warning,
        triggers.frame_rate.alert
      );
    }

    // connection_type
    this.connection = this.callHelper.getConnectionType(connection_type);
  };

  gatherIncidents = () => {
    this.zoomIncident.resetZoomIncidents();
    this.zoomIncident.setJitterIncident(this.callStatusData.jitter.color);
    this.zoomIncident.setPacketlossIncident(this.callStatusData.packetloss.color);
    this.zoomIncident.setFramerateIncident(this.callStatusData.frame_rate.color);
    this.zoomIncident.setCameraIncident(this.camerasImpacted);
    this.zoomIncident.setMicIncident(this.micsImpacted);
    this.zoomIncident.setSpeakerIncident(this.speakersImpacted);

    this.zoomIncident.gatherIncidents();

    this.hasIssues = this.zoomIncident.hasIncidents;
    this.incidentSummary = this.zoomIncident.getIncidents();
  };
}
