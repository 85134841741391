<div class="link-desktop">
  <div class="link-desktop__header">
    <div>
      {{ getModalTitle() }}
    </div>
    <div (click)="closeDialog()" class="link-desktop__header-close-button">
      <img src="./assets/img/iot/delete-btn.png" alt="" />
    </div>
  </div>
  <ng-container *ngIf="!showLinkClientUser && action === 'link'" >
    <div class="link-desktop__body">
      <div class="link-desktop__search">
        <div class="link-desktop__icon">
          <i class="fa fa-search"></i>
        </div>
        <input #searchElement type="text" class="search-default" type="text" placeholder="" [(ngModel)]="searchKey" />
      </div>
      <div id="scroll-bar" #scrollBarElement style="overflow: auto; overflow-x: hidden; height: 280px;">
        <table class="table">
          <thead>
            <tr class="black">
              <th scope="col">Name</th>
              <th scope="col">Service</th>
              <th scope="col">Email</th>
              <th scope="col" style="width: 50px;">Select User</th>
            </tr>
          </thead>
          <tbody>
            <!-- commenting FE filter because FE doesn't have full list of users. It should be BE search -->
            <!-- <tr scope="row" *ngFor="let item of modalList | mainMenuFilter: searchKey:'linkDialog'"> -->
              <tr scope="row" *ngFor="let item of modalList">
              <td>
                {{ item.firstname || '--' }} {{ item.lastname || '--' }}
              </td>
              <td>
                <img src="{{ getPluginTypeImage(item.service_type) }}" alt="" />
              </td>
              <td>
                <div class="elipsis">
                  {{ item.email? reduceEmailLength(item.email) : '--' }}
                </div>
              </td>
              <td>
                <div (click)="openLinkClient(item)" class="link-desktop__body-add-button">
                  <img src="./assets/img/vs_img/dialog_add_button.png" alt="" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="link-desktop__footer" *ngIf="totalTeamsCount || totalZoomCount">
      <span *ngIf="!usersLoading">Users {{ usersCount }} of {{ serviceType === 'teams' ? totalTeamsCount : totalZoomCount }}</span>
      <span *ngIf="usersLoading">Users<img src="{{ btnLoader }}" alt="" />of {{ serviceType === 'teams' ? totalTeamsCount : totalZoomCount }}
      </span>
    </div>
  </ng-container>

  <div *ngIf="showLinkClientUser && action === 'link'" class="link-desktop__body link-desktop__body-2">
    <div class="link-desktop__image">
      <img *ngIf="!isLoading" src="../../../../assets/img/vs_img/delete-icon.png" alt="" />
      <img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
    </div>
    <div class="link-desktop__text-button-container">
      <div class="">
        Link <strong>{{ clientToLink }}</strong> to <br />
        Host: <strong>{{ hostName }}</strong> ?
      </div>
      <div class="link-desktop__button-container">
        <div class="link-desktop__button-item link-desktop__button-item--yes" (click)="linkDesktopPlugin()">
          Yes, Link Them!
        </div>
        <div class="link-desktop__button-item link-desktop__button-item--cancel" (click)="closeLinkClient()">
          X Cancel
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!showLinkClientUser && action === 'unlink'" class="link-desktop__body link-desktop__body-2">
    <div class="link-desktop__image">
      <img *ngIf="!isLoading" src="../../../../assets/img/vs_img/delete-icon.png" alt="" />
      <img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
    </div>
    <div class="link-desktop__text-button-container">
      <div class="">
        Un-Link <strong>{{ clientToLink }}</strong> to <br />
        Host: <strong>{{ hostName }}</strong> ?
      </div>
      <div class="link-desktop__button-container">
        <div class="link-desktop__button-item link-desktop__button-item--yes-unlink" (click)="unlinkDesktopPlugin()">
          Yes, Un-Link Them!
        </div>
        <div class="link-desktop__button-item link-desktop__button-item--cancel-unlink" (click)="closeDialog()">
          X Cancel
        </div>
      </div>
    </div>
  </div>
</div>