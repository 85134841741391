<button type="button" class="btn-reg not-button">
  <div class="row justify-content-center">
    <div class="col-6">
      <img class="float-right talktime" src="../../../../assets/img/audio/icon_total_talk.png" alt="audio"/>
      <!-- <h3 style="padding-top:10px;">{{audioUser?.devices[0].total_talk_time}}</h3> -->
    </div>
    <div class="col-6 mt-2">
      <h3>{{countHrs}} : {{countMin}}</h3>
      <p>{{labelHrs}} : {{labelMin}}</p>
    </div>
  </div>
</button>
