<div class="video-device-incidents">
  <div *ngIf="isShowTableData" id="incident-history-list-container">
    <table class="table table-fixed device-health-history-table">
      <thead>
        <tr>
          <th class="device-health-history-thead">Incident ID</th>
          <th class="device-health-history-thead">Start Time</th>
          <th class="device-health-history-thead">End Time</th>
          <th class="device-health-history-thead-events">Details</th>
        </tr>
      </thead>
      <tbody *ngFor="let item of incidentsData">
        <tr scope="row">
          <td>
            {{item.incident_id}}
          </td>
          <td>
            {{item.start_time}}
          </td>
          <td>
            {{item.end_time}}
          </td>

          <td class="details-container">
            <div class="details-container__summary">
              <label>Summary: </label> {{ item.summary }}
            </div>
            <div>
              <div>Task: </div>
              <div>Codec Response: {{item.wakeup_status}}</div>
              <div>Camera(s): {{item.camera_status}} </div>
              <div>Mic(s): {{item.mic_status}} </div>
              <div>Exchange: {{item.exchange_status}} </div>
              <div>Gatekeeper: {{item.gatekeeper_status}} </div>
              <div>Reboot: {{item.reboot_status}} </div>
            </div>
          </td>

        </tr>
      </tbody>
    </table>
    <div *ngIf="shouldShowLoaderBtn" class="loader">
      <img src="../../../assets/img/vs_img/btn_loader.gif" alt="" />
    </div>
  </div>

  <div *ngIf="!isShowTableData && !shouldShowLoaderBtn" class="device-health-history-nodata">
    <h1>No Data Available</h1>
  </div>
  <div class="print-btn-container" *ngIf="isShowTableData">
    <div class="printer-btn">
      <i class="fa fa-print fa-2x menu-icon" (click)="printIncidentsToPdf()"></i>
    </div>
  </div>
</div>