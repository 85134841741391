

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'

import { GetIotService } from '../../services/get-iot.service'
import { PopupTypeService } from '../../services/popupType.service'

@Component({
  selector: 'app-iot-admin-alerts',
  templateUrl: './iot-admin-alerts.component.html',
  styleUrls: ['./iot-admin-alerts.component.scss'],
})
export class IotAdminAlertsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() iotDeviceDetails: any;
  @Input() popUpEmitter: any;

  medianAlert: number = 10;
  medianWarning: number = 9;
  medianMax: number = 70;
  medianMin: number = 0;

  packetLossAlert: number = 15;
  packetLossWarning: number = 10;
  packetLossMax: number = 25;
  packetLossMin: number = 0;

  deviceId: number;
  showSuccesMessage: boolean = false;
  setTimer: any;
  triggerData: any;

  constructor(private getIot: GetIotService, public ds: PopupTypeService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.iotDeviceDetails) {
      if (this.iotDeviceDetails) {
        console.log('this.iotDeviceDetails info ', this.iotDeviceDetails)
      
        this.setData();
      }
    }
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

  setData = () => {
    const {
      iot_device_id,
      live_stats,
      triggers,
      trigger_limits: {
        median: { min, max },
        packetloss: { min: min2, max: max2 },
      },
    } = this.iotDeviceDetails;
    
    this.medianMin = min;
    this.medianMax = max;
    this.packetLossMax = max2;
    this.packetLossMin = min2;
    this.deviceId = iot_device_id;
    
    
    
    const triggers1 = triggers !== null ? JSON.parse(triggers) : {};
    
    this.triggerData = triggers1;
    
    console.log(' this.triggerData: ',  this.triggerData);
    // console.log('parseLiveStats: ', parseLiveStats);
    
    const {
      median: { alert, warning },
      packetloss: { alert: alert2, warning: warning2 },
    } = triggers1;

    
     this.setTriggeralue(alert, warning, alert2, warning2);

    // if (this.checkForTriggerProp(parseLiveStats)) {
    //   const {
    //     triggers: 
    //   } = parseLiveStats;
    //   this.setTriggeralue(alert, warning, alert2, warning2);
    // } else {
    //   const {
    //     median: { alert, warning },
    //     packetloss: { alert: alert2, warning: warning2 },
    //   } = triggers;

    //  
    // }
  };

  // checkForTriggerProp = (params = {}) => {
  //   // use for the
  //   if ('triggers' in params) {
  //     return true;
  //   }
  //   return false;
  // };

  setTriggeralue = (alert, warning, alert2, warning2) => {
    this.medianAlert = alert;
    this.medianWarning = warning;

    this.packetLossAlert = alert2;
    this.packetLossWarning = warning2;
  };

  medianEvent = (event, type) => {
    console.log('event: ', event);
    console.log('type: ', type);
    

		if(type == 'warning') {
		  this.medianWarning = event
		  this.medianAlert = this.medianAlert - event < 1 ? event + 1 : this.medianAlert
		}
		
		if(type == 'alert') {
		  this.medianAlert = event
		  if(this.medianWarning > this.medianAlert) {
		    this.medianWarning =  event - 1
		  }
		}
		
  };

  packetLossEvent = (event, type) => {
    // console.log('event: ', event, type);
    		if(type == 'warning') {
		  this.packetLossWarning = event
		  this.packetLossAlert = this.packetLossAlert - event < 1 ? event + 1 : this.packetLossAlert
		}
		
		if(type == 'alert') {
		  this.packetLossAlert = event
		  if(this.packetLossWarning > this.packetLossAlert) {
		    this.packetLossWarning =  event - 1
		  }
		}
  };

  saveAlert = () => {
    const trigger = {
      median: {
        alert: this.medianAlert,
        warning: this.medianWarning,
      },
      packetloss: {
        alert: this.packetLossAlert,
        warning: this.packetLossWarning,
      },
    };
    this.ds.loader = true;
    this.setIotDeviceTriggerAlerts(trigger);
  };

  resetAlerts = () => {
    this.ds.loader = true;
    const trigger = null;
    this.setIotDeviceTriggerAlerts({
      median: {
        alert: 10,
        warning: 9
      },
      packetloss: {
        alert: 15,
        warning: 10
      }
    });
  };

  setIotDeviceTriggerAlerts = (triggers) => {
  
    console.log('triggers: ', triggers)
    console.log('this.deviceId: ', this.deviceId)
  
    this.getIot.setIotDeviceTriggerAlerts(this.deviceId, triggers).subscribe((data: any) => {
      console.log('data: ', data)
      
      const [res] = data; 
      console.log(res)
      if (res) {
        this.popUpEmitter();
        this.successMessage();
        this.ds.loader = false;
      }
    });
  };

  successMessage = () => {
    this.showSuccesMessage = true;
    this.setTimer = setTimeout(() => {
      this.showSuccesMessage = false;
    }, 3000);
  };
}
