import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class AudioHelperService {
  lanIconConnected: string = '../../../../assets/img/teleportivity_img/enternet.png';
  lanIconNotConnected: string = '../../../../assets/img/teleportivity_img/enternet2.png';
  wireLess4BarsIcon = '../../../../assets/img/audio/wifi_4.png';
  wireLess3BarsIcon = '../../../../assets/img/audio/wifi_3.png';
  wireLess2BarIcon = '../../../../assets/img/audio/wifi_2.png';
  wireLess1BarIcon = '../../../../assets/img/audio/wifi_1.png';
  micNotOnCallIcon = '../../../../assets/img/audio/icon_mic.png';
  micMutedIcon = '../../../../assets/img/audio/icon_mute.png';
  micNotMutedIcon = './../../../assets/img/audio/icon_unmute.png';

  constructor() {}

  getNetworkIcon(networkInfo) {
    let networkIcon = this.lanIconNotConnected;
    if (networkInfo !== null && networkInfo) {
      networkIcon = this.lanIconNotConnected;
      if (_.get(networkInfo, 'lan_port_status', 'inactive') === 'active') {
        networkIcon = this.lanIconConnected;
      } else {
        const signalPercent = networkInfo.wifi.signal_strength;
        const signalStrength = parseInt(signalPercent.slice(0, signalPercent.length - 1));
        networkIcon = this.getWirelessIcon(signalStrength);
      }
    }
    return networkIcon;
  }

  getMicIconAndStatus(onCall, isMuted) {
    let micIcon = this.micNotOnCallIcon;
    let micStatus = 'Not in Call';
    if (onCall === 1) {
      if (isMuted) {
        micIcon = this.micMutedIcon;
        micStatus = 'Muted';
      } else {
        micIcon = this.micNotMutedIcon;
        micStatus = 'Un Muted';
      }
    }

    return { micIcon, micStatus };
  }

  getIsDisplayPresence(presense) {
    return presense.length > 0;
  }

  getWirelessIcon(signalStrength: number): string {
    if (signalStrength >= 75) {
      return this.wireLess4BarsIcon;
    } else if (signalStrength < 75 && signalStrength >= 50) {
      return this.wireLess3BarsIcon;
    } else if (signalStrength < 50 && signalStrength >= 25) {
      return this.wireLess2BarIcon;
    } else {
      return this.wireLess1BarIcon;
    }
  }
}
