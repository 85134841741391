import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bluejeans-popup',
  templateUrl: './bluejeans-popup.component.html',
  styleUrls: ['./bluejeans-popup.component.scss']
})
export class BluejeansPopupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
