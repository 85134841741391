import { Component, OnInit, Input } from '@angular/core';
import { GetDirectAccessService } from '../../../services/get-direct-access.service';
import { GetVideoService } from '../../../services/get-video.service';
import { GetAudioService } from '../../../services/get-audio.service';
import { GetPluginService } from 'src/app/services/get-plugin.service';

@Component({
  selector: 'app-device-action',
  templateUrl: './device-action.component.html',
  styleUrls: ['./device-action.component.scss'],
})
export class DeviceActionComponent implements OnInit {
  @Input() deviceData: any;
  @Input() deviceType: string;
  id: number;
  showPinInput: boolean = false;
  showEmailInput: boolean = false;
  actionLabel: string;
  isShowLoader: boolean = false;
  actionResult = {
    'message': '',
    'isError': false,
  };

  constructor(
    private getDirectAccessService: GetDirectAccessService,
    private getVideoService: GetVideoService,
    private getAudioService: GetAudioService,
    private getPluginService: GetPluginService
  ) {}

  ngOnInit() {
    if (this.deviceType === 'video') {
      this.id = this.deviceData.video_device_id;
    } else if (this.deviceType === 'audio') {
      this.id = this.deviceData.audio_device_id;
    } else if (this.deviceType === 'zoom') {
      this.id = this.deviceData.plugin_id;
    }
  }

  showPinInputDef = () => {
    this.closeInputAndEmailDef();
    this.showPinInput = true;
    this.actionResult = {
      'message': '',
      'isError': false,
    };
  };

  wakeUp = () => {
    this.closeInputAndEmailDef();
    this.showEmailInput = true;
    this.actionLabel = 'wakeDevice';
  };

  reboot = () => {
    this.closeInputAndEmailDef();
    this.showEmailInput = true;
    this.actionLabel = 'rebootDevice';
  };

  rebootPc = () => {
    this.closeInputAndEmailDef();
    this.showEmailInput = true;
    this.actionLabel = 'rebootPc';
  };

  restartZoom = () => {
    this.closeInputAndEmailDef();
    this.showEmailInput = true;
    this.actionLabel = 'restartZoom';
  };

  closeInputAndEmailDef = () => {
    this.showPinInput = false;
    this.showEmailInput = false;
    this.actionLabel = '';
    this.actionResult = {
      'message': '',
      'isError': false,
    };
  };

  closeActionStatus = () => {
    this.actionResult.message = '';
    this.actionResult.isError = false;
    this.isShowLoader = false;
  };

  wakeupDevice(device_id) {
    if (this.deviceType === 'video') {
      this.getVideoService.wakeupVideoDevice(device_id).subscribe((data) => {});
      return;
    }
  }

  rebootDevice(device_id) {
    if (this.deviceType === 'video') {
      this.getVideoService.rebootVideoDevice(device_id).subscribe((data) => {});
      return;
    }
    if (this.deviceType === 'audio') {
      this.getAudioService.rebootAudioDevice(device_id).subscribe((data) => {});
      return;
    }
  }

  zoomRebootPc = (pluginId) => {
    const actionParams = {};
    const actionName = 'Reboot';
    this.getPluginService.createAction(pluginId, actionParams, actionName).subscribe((data) => {});
  };

  zoomRestartApp = (pluginId) => {
    const actionParams = {};
    const actionName = 'RestartZoomRoomsApp';
    this.getPluginService.createAction(pluginId, actionParams, actionName).subscribe((data) => {});
  };

  createPin = () => {
    this.isShowLoader = true;
    const requestorEmail = (<HTMLInputElement>document.getElementById('requestorEmail')).value;
    const supportEmail = this.deviceData.direct_access_info.supportUser.email;
    const support_name =
      this.deviceData.direct_access_info.supportUser.first_name +
      ' ' +
      this.deviceData.direct_access_info.supportUser.last_name;

    if (this.areEmailsProvided(supportEmail, requestorEmail)) {
      const { company_id, room_name } = this.deviceData;

      let device_name = '';
      if (this.deviceType === 'zoom') {
        device_name = this.deviceData.room_name;
      } else if (this.deviceType === 'audio') {
        device_name = this.deviceData.model;
      } else {
        device_name = this.deviceData.device_name;
      }

      const postData = {
        device_id: this.id,
        support_email: supportEmail,
        support_name,
        requestor_email: requestorEmail,
        company_id,
        type: this.deviceType,
        device_name,
        room_name,
        action: this.actionLabel,
      };

      this.getDirectAccessService.createPin(postData).subscribe((data) => {
        if (data.error === null) {
          this.actionResult.message = 'Pin created. Support will contact you shortly.';
          this.actionResult.isError = false;
        } else {
          this.actionResult.message = 'Unable to create pin.';
          this.actionResult.isError = true;
        }
        this.isShowLoader = false;
      });
    } else {
      this.isShowLoader = false;
    }
  };

  areEmailsProvided = (supportEmail, requestorEmail) => {
    if (supportEmail.length <= 0) {
      this.actionResult.message = 'Device has no provided support user.';
      this.actionResult.isError = true;
      return false;
    }

    if (requestorEmail.length <= 0) {
      this.actionResult.message = 'Please provide email.';
      this.actionResult.isError = true;
      return false;
    }

    return true;
  };

  executePin = () => {
    this.isShowLoader = true;
    const pinCode = (<HTMLInputElement>document.getElementById('pin')).value;

    if (pinCode.length > 0) {
      const { company_id, room_name } = this.deviceData;
      const support_name =
        this.deviceData.direct_access_info.supportUser.first_name +
        ' ' +
        this.deviceData.direct_access_info.supportUser.last_name;

      let device_name = '';
      if (this.deviceType === 'zoom') {
        device_name = this.deviceData.room_name;
      } else if (this.deviceType === 'audio') {
        device_name = this.deviceData.model;
      } else {
        device_name = this.deviceData.device_name;
      }

      const postData = {
        device_id: this.id,
        support_email: this.deviceData.direct_access_info.supportUser.email,
        support_name,
        company_id,
        type: this.deviceType,
        device_name,
        room_name,
        pin: parseInt(pinCode),
      };

      this.getDirectAccessService.executePin(postData).subscribe((data) => {
        const actionData = data.response;
        if (actionData.status === 'OK') {
          switch (actionData.dAction) {
            case 'rebootDevice':
              this.rebootDevice(actionData.deviceId);
              break;
            case 'wakeDevice':
              this.wakeupDevice(actionData.deviceId);
              break;
            case 'rebootPc':
              this.zoomRebootPc(actionData.deviceId);
              break;
            case 'restartZoom':
              this.zoomRestartApp(actionData.deviceId);
              break;
          }
          this.actionResult.message = `Initiating action:  ${actionData.dAction}.`;
          this.actionResult.isError = false;
          this.isShowLoader = false;
        } else {
          this.actionResult.message = actionData.message;
          this.actionResult.isError = true;
          this.isShowLoader = false;
        }
      });
    } else {
      this.actionResult.message = 'Please provide pin.';
      this.actionResult.isError = true;
      this.isShowLoader = false;
    }
  };

  isShowWakeUpBtn = () => {
    return this.deviceType === 'video';
  };

  isShowRebootBtn = () => {
    return this.deviceType === 'video' || this.deviceType === 'audio';
  };

  isShowRebootPcBtn = () => {
    return this.deviceType === 'zoom';
  };

  isShowRestartZoomBtn = () => {
    return this.deviceType === 'zoom';
  };
}
