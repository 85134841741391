<!-- TUTORIAL DEVICE OVERLAY -->
<div id="visone-overlay" class="tut-show" *ngIf="runTutorial == 1"></div>
<!-- TUTORIAL ALERT -->
<div *ngIf="showAddDevice == 1 && runTutorial == 1" class="tut-polycom col-6 tut-show">
  Please complete the form to add your first device.
</div>
<div *ngIf="showTryAgain == 1 && runTutorial == 1" class="tut-reenter col-6 tut-show">
  Re-enter device information to add a new device.
</div>
<div *ngIf="showConfirm == 1 && runTutorial == 1" class="tut-addDevice col-6 tut-show">
  Please confirm this is the device want to add.
</div>
<div class="container-fluid" (click)="toogleDeleteForm()" style="padding-top: 30px; padding-bottom: 160px">
  <!-- <div class="row upper-row"> -->
  <!-- <div class="col-12">
      <div class="row">
        <div class="col-1 pl-5">
          <img src="../../../assets/img/cisco_logo.png@1X.png" alt="cisco" style="width: 140px;" />
        </div>
      </div>
    </div> -->
  <!-- </div> -->
  <div class="row lower-row">
    <div class="col-2 center px-0">
      <h3 class="add-device pl-4" (click)="setHighlight(1)" [class.btn-background]="highlightNum == 1"
        (click)="toEdit(0)" (click)="toDelete(0)">
        add a device
      </h3>
      <h3 class="list-device pl-4" [ngClass]="{'cisco__disable': !ciscoMonitor}" (click)="setHighlight(2)"
        [class.btn-background]="highlightNum == 2" (click)="toEdit(0)" (click)="toDelete(0)">
        list of devices
      </h3>

      <div class="row pt-3 pl-4" style="top: 20px" *ngIf="hasAccess">
        <h5 class="pl-4">cisco monitoring</h5>
        <div class="row pt-3 px-3" style="top: 20px">
          <div class="disabled float-left">disabled</div>
          <label class="switch mx-2">
            <input type="checkbox" (change)="toggleCiscoMonitor()" [checked]="ciscoMonitor"
              [disabled]="disableToggle" />
            <span class="slider round" [ngClass]="{'toggleDisabled': disableToggle}"></span>
          </label>
          <div class="enabled float-right">enabled</div>
        </div>
      </div>

      <div class="row pt-3 pl-4" style="top: 20px" *ngIf="hasAccess">
        <h5 class="pl-4">cisco notifications</h5>
        <div class="row pt-3 px-3" style="top: 20px">
          <div class="disabled float-left">disabled</div>
          <label class="switch mx-2">
            <input type="checkbox" (change)="toggleCiscoAlerts()" [checked]="ciscoAlert"
              [disabled]="disableToggle||!ciscoMonitor" />
            <span class="slider round"
              [ngClass]="{'toggleDisabled': disableToggle,'cisco__disable':!ciscoMonitor}"></span>
          </label>
          <div class="enabled float-right">enabled</div>
        </div>
      </div>
    </div>

    <div style="border-left: 2px solid black"></div>
    <div class="col-4" *ngIf="highlightNum == 1" [ngClass]="{
        'white-back':
          (findDevice == 1 && runTutorial == 1) ||
          (showTryAgain == 1 && runTutorial == 1)
            ? true
            : false
      }" style="height: 400px">
      <form role="form" name="siteForm" class="mt-2" name="siteForm" [formGroup]="siteForm" (ngSubmit)="onSubmit()"
        novalidate>
        <div class="form-group">
          <select #selectCiscoPopup (change)="setIndex(selectCiscoPopup.value)" formControlName="index" id="index"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.index.errors,'cisco__disable':!ciscoMonitor }" required>
            <option value="" selected disabled>Select a site</option>
            <option *ngFor="let siteList of allSiteListData; index as n" value="{{ n }}">
              {{ siteList.site_name }}
            </option>
          </select>
          <div *ngIf="submitted && f.index.errors" class="invalid-feedback">
            <!-- <div *ngIf="f.index.errors" class="invalid-alert alert alert-danger p-2 m-0">Please select a site.</div> -->
            <div *ngIf="f.index.errors.required" id="confirmMessage">!</div>
          </div>
        </div>

        <div *ngIf="showSiteListErrMsg" class="cisco__error-message" st>
          Error: No Online Collectors
        </div>


        <div class="form-group">
          <input type="text" [(ngModel)]="siteId" formControlName="siteId" class="form-control" id="siteId"
            name="siteId" placeholder="Site id" hidden required />
        </div>
        <div class="form-group">
          <input type="text" [(ngModel)]="collectorId" formControlName="collectorId" class="form-control"
            id="collectorId" name="collectorId" placeholder="Collector id" hidden required />
        </div>

        <div class="form-group">
          <input type="text" formControlName="roomName" placeholder="Room Name" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.roomName.errors,'cisco__disable':!ciscoMonitor }" id="roomName"
            name="roomName" required />
          <div *ngIf="submitted && f.roomName.errors" class="invalid-feedback">
            <!-- <div *ngIf="f.roomName.errors" class="invalid-alert alert alert-danger p-2 m-0">Please enter ip Address.</div> -->
            <div *ngIf="f.roomName.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        <div class="form-group">
          <input type="text" formControlName="ipAddress" placeholder="Video device ip address" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.ipAddress.errors,'cisco__disable':!ciscoMonitor }" id="ipAddress"
            name="ipAddress" required />
          <div *ngIf="submitted && f.ipAddress.errors" class="invalid-feedback">
            <!-- <div *ngIf="f.ipAddress.errors" class="invalid-alert alert alert-danger p-2 m-0">Please enter ip Address.</div> -->
            <div *ngIf="f.ipAddress.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        <div class="form-group">
          <input type="text" formControlName="username" placeholder="Video Admin Name" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.username.errors,'cisco__disable':!ciscoMonitor }" id="username"
            name="username" required />
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <!-- <div *ngIf="f.ipAddress.errors" class="invalid-alert alert alert-danger p-2 m-0">Please enter ip Address.</div> -->
            <div *ngIf="f.username.errors.required" id="confirmMessage">!</div>
          </div>
        </div>

        <div class="form-group">
          <input type="password" id="password" placeholder="Video Device Password" class="form-control" required />
        </div>
        <div class="form-group">
          <!-- <button type="submit" class="button float-right" style="font-size:8px;height:30px;width:100px;">Find Device</button> -->
          <button type="submit" class="btn btn-primary float-right" [ngClass]="{'cisco__disable':!ciscoMonitor}">
            Find Device
          </button>
        </div>
      </form>
    </div>
    <div class="col-4 search" *ngIf="highlightNum == 1" [ngClass]="{
        'white-back': showConfirm == 1 && runTutorial == 1 ? true : false
      }">
      <ng-container *ngIf="showData == true">
        <ng-container *ngIf="addDeviceResponse.length == 0">
          <ng-container *ngIf="showSearching">
            <ul class="bottom">
              <li>
                <h3>Searching</h3>
              </li>
              <li>
                <div class="dot1"></div>
              </li>
              <li>
                <div class="dot2"></div>
              </li>
              <li>
                <div class="dot3"></div>
              </li>
            </ul>
          </ng-container>

          <ng-container *ngIf="deviceActionData.status == 'Error'">
            <h3 *ngIf="deviceActionData.status == 'Error'" style="color: red !important;" class="send-text">
              {{ deviceActionData.message }}
            </h3>
          </ng-container>

          <ng-container *ngIf="showAddDevice">
            <div class="showLama">
              <img src="../../../assets/img/icon_llama.png" />
            </div>
            <div class="success-form">
              <h3>Device Details</h3>
              <p class="device-text">
                Device Name: {{ deviceName }}
              </p>
              <p class="device-text">
                Model: {{ deviceModel }}
              </p>
              <p class="device-text">
                Serial Number: {{ serialNumber }}
              </p>
              <p class="device-text">
                IPV4: {{ deviceIpAddress }}
              </p>
              <p class="device-text">
                MAC: {{ deviceMac}}
              </p>
              <p class="device-text">
                Software: {{ softwareRelease }}
              </p>
              <p class="device-text">
                Active Mic: {{ activeMic }}
              </p>
              <p class="device-text">
                Active Camera: {{ activeCamera
                }}
              </p>
              <div class="row mt-2">
                <div class="form-group">
                  <button (click)="tutClose()" (click)="sendAddDeviceData()"
                    class="form-control btn btn-primary float-right" style="margin-top: 10px">
                    Add Device
                  </button>
                </div>
                <div class="form-group pl-2">
                  <button (click)="cancelPressed()" class="form-control btn btn-dark float-left"
                    style="margin-top: 10px">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container>
            <div *ngIf="showMsg30Sec && !showErrMsg">
              <p style="font-size: larger;">It’s taking longer than expected, but we’ll get there as fast as we
                  can...</p>
            </div>
            <p *ngIf="showErrMsg" style="color: red !important;" class="send-text">
              {{ errorMsg }}
            </p>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="addDeviceResponse.length > 0">
          <h3 *ngIf="addDeviceResponse[0].status == 'Error'" style="color: red !important;" class="send-text">
            {{ addDeviceResponse[0].message }}
          </h3>
          <h3 *ngIf="addDeviceResponse[0].status == 'Ok'" style="color: green !important;" class="send-text">
            {{ addDeviceResponse[0].message }}
          </h3>
        </ng-container>
        <ng-container *ngIf="errorMessage">
          <div class="d-flex" style="flex-grow: 1">
            <h3 style="color: red !important; text-align: center">{{ errorMessage }}</h3>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-9" *ngIf="highlightNum == 2" style="overflow: auto; height: 400px">
      <!-- here is total count of devices is set  -->
      <div class="counter">
        <p>
          Total # of devices are <strong>{{ totalDevices }}</strong>
        </p>
      </div>
      <div class="row">
        <table class="table table-hover table-sm bg-white mt-1" style="padding-bottom: 100px">
          <thead>
            <tr>
              <th colspan="2" scope="col">Site</th>
              <th scope="col">Device Name</th>
              <th scope="col">Room Name</th>
              <th scope="col">Site Name</th>
              <th scope="col">IP</th>
              <th scope="col">Model</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr scope="row" *ngFor="let alldevices of allDeviceListData">
              <td colspan="2">
                <ng-template [ngIf]="alldevices.virtual == 0">
                  <img [src]="iconAddSite" style="height: 30px" />
                </ng-template>
                <ng-template [ngIf]="alldevices.virtual == 1">
                  <img [src]="iconVirtual" style="height: 30px" />
                </ng-template>
                <!-- {{ allsites.site_type }} -->
              </td>
              <td>{{ alldevices.device_name }}</td>
              <td>{{ alldevices.room_name }}</td>
              <td>{{ alldevices.site_name }}</td>
              <td>{{ alldevices.ip_address }}</td>
              <td>{{ alldevices.model }}</td>
              <td>
                <ng-template [ngIf]="alldevices.paused == 1">
                  <div class="status-blue"></div>
                </ng-template>
                <ng-template [ngIf]="
                    alldevices.paused == 0 && alldevices.collector_status == -1
                  ">
                  <div class="status-grey"></div>
                </ng-template>
                <ng-template [ngIf]="
                    alldevices.paused == 0 &&
                    alldevices.collector_status == 1 &&
                    alldevices.status == 1
                  ">
                  <div class="status-green"></div>
                </ng-template>
                <ng-template [ngIf]="
                    alldevices.paused == 0 &&
                    alldevices.collector_status == 1 &&
                    alldevices.status == 0
                  ">
                  <div class="status-red"></div>
                </ng-template>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>