import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AudioIncidentTrackerService } from '../../services/audio-incident-tracker.service';
import { CallHelperService } from '../../services/call-helper.service';
import { AudioHelperService } from '../../services/audio-helper.service';

@Component({
  selector: 'app-audio-monitoring',
  templateUrl: './audio-monitoring.component.html',
  styleUrls: ['./audio-monitoring.component.scss'],
})
export class AudioMonitoringComponent implements OnInit, OnChanges {
  @Input() audioDeviceData: any;
  onCall = 0;
  sipGate: any;
  incidentSummary: any;
  hasIssues = false;
  roomName: string;
  siteName: string;
  networkIcon: string;
  lanPortStatus: string;
  micData: any;
  isDisplayPresence: boolean;
  isShowSystemStatusConnectedDevicesHeader: boolean = false;
  isShowSystemStatusConnectedDevicesBody: boolean = true;
  isShowSystemStatusCallHeader: boolean = true;
  isShowSystemStatusCallBody: boolean = false;
  isExpandSystemStatusConnectedDevices: boolean = true;
  isExpandSystemStatusCall: boolean = false;

  callStatusData: any;

  constructor(
    private audioIncidents: AudioIncidentTrackerService,
    private callHelper: CallHelperService,
    private audioHelper: AudioHelperService
  ) {
    this.audioIncidents.resetAudioIncidents();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.audioDeviceData) {
      const { room_name, site_name, on_call } = this.audioDeviceData;
      this.onCall = on_call;
      this.roomName = room_name;
      this.siteName = site_name;

      this.iniSystemStatus();
      this.iniCallStatus();
      this.gatherIncidents();
    }
  }

  toggleSystemStatusConnectedDevices = () => {
    this.isShowSystemStatusConnectedDevicesHeader = false;
    this.isShowSystemStatusConnectedDevicesBody = true;
    this.isShowSystemStatusCallHeader = true;
    this.isShowSystemStatusCallBody = false;
    this.isExpandSystemStatusConnectedDevices = true;
    this.isExpandSystemStatusCall = false;
  };

  toggleSystemStatusCall = () => {
    this.isShowSystemStatusConnectedDevicesHeader = true;
    this.isShowSystemStatusConnectedDevicesBody = false;
    this.isShowSystemStatusCallHeader = false;
    this.isShowSystemStatusCallBody = true;
    this.isExpandSystemStatusConnectedDevices = false;
    this.isExpandSystemStatusCall = true;
  };

  iniSystemStatus = () => {
    const { on_call, network_info, mic_muted, presence } = this.audioDeviceData;

    this.networkIcon = this.audioHelper.getNetworkIcon(network_info);
    this.lanPortStatus = network_info.lan_port_status;
    this.micData = this.audioHelper.getMicIconAndStatus(on_call, mic_muted);
    this.isDisplayPresence = this.audioHelper.getIsDisplayPresence(presence);
  };

  iniCallStatus = () => {
    const {
      total_jitter,
      total_packetloss,
      total_latency,
      total_mos,
      sip_info,
      trigger_limits,
      triggers,
    } = this.audioDeviceData;

    // call status data
    this.callStatusData = {
      jitter: {
        value: '--',
        color: 'gray',
      },
      packetloss: {
        value: '--',
        color: 'gray',
      },
      latency: {
        value: '--',
        color: 'gray',
      },
      mos: {
        value: '--',
        color: 'gray',
      },
    };

    if (this.onCall) {
      // jitter
      this.callStatusData.jitter.value = total_jitter;
      this.callStatusData.jitter.color = this.callHelper.getColor(
        trigger_limits.jitter.good,
        total_jitter,
        triggers.jitter.warning,
        triggers.jitter.alert
      );

      // packetloss
      this.callStatusData.packetloss.value = total_packetloss;
      this.callStatusData.packetloss.color = this.callHelper.getColor(
        trigger_limits.packetsloss.good,
        total_packetloss,
        triggers.packetsloss.warning,
        triggers.packetsloss.alert
      );

      // latency
      this.callStatusData.latency.value = total_latency;
      this.callStatusData.latency.color = this.callHelper.getColor(
        trigger_limits.latency.good,
        total_latency,
        triggers.latency.warning,
        triggers.latency.alert
      );

      this.callStatusData.mos.value = total_mos;
    }

    // sip gate
    this.sipGate = this.callHelper.getSipGateKeeperStatus('audio', sip_info);
  };

  gatherIncidents = () => {
    this.audioIncidents.resetAudioIncidents();
    this.audioIncidents.setJitterIncident(this.callStatusData.jitter.color);
    this.audioIncidents.setPacketlossIncident(this.callStatusData.packetloss.color);
    this.audioIncidents.setLatencyIncident(this.callStatusData.latency.color);

    this.audioIncidents.gatherIncidents();

    this.hasIssues = this.audioIncidents.hasIncidents;
    this.incidentSummary = this.audioIncidents.getIncidents();
  };
}
