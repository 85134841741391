<div class="register" [ngStyle]="visibilityBackground">
  <div class="register__col">
    <div class="register__box" *ngIf="isLinkValid">
      <div class="register__box-title">
        <h2>Sign Up</h2>
      </div>

      <div class="register__container-input-sm">
        <input class="margin-left {{ formControl.firstname.style }}" type="text" placeholder="First Name"
          value="register.firstname" [(ngModel)]="register.firstname" (keyup)="keyEvent($event, 'firstname')" />

        <input class="{{ formControl.lastname.style }}" type="text" placeholder="Last Name" value="register.lastname"
          [(ngModel)]="register.lastname" (keyup)="keyEvent($event, 'lastname')" />
        <div class="register__i">
          <i class="fa fa-user register__icon-sm"></i>
        </div>
      </div>

      <div class="register__input-container">
        <input disabled="true" class="default" type="text" placeholder="Email" value="{{ register.email }}" />
        <div class="register__i">
          <i class="fa fa-user register__icon-sm"></i>
        </div>
      </div>

      <div class="register__input-container">
        <input disabled="true" class="default" type="text" placeholder="Company" value="{{ register.company }}" />
        <div class="register__i">
          <i class="fa fa-user register__icon-sm"></i>
        </div>
      </div>

      <div class="register__input-container">
        <input class="{{ formControl.password.style }} register__input-sm" type="password" placeholder="Password"
          value="register.password" [(ngModel)]="register.password" (keyup)="keyEvent($event, 'password')" />
        <div class="register__i">
          <i class="fa fa-key register__icon"></i>
        </div>
      </div>
      <div *ngIf="formControl.password.flag" class="error-msg">
        {{ formControl.password.message }}
      </div>

      <div class="register__input-container">
        <input class="{{ formControl.confirm.style }}  register__input-sm" type="Password" placeholder="Repeat Password"
          value="register.confirm" [(ngModel)]="register.confirm" (keyup)="keyEvent($event, 'confirm')" />
        <div class="register__i">
          <i class="fa fa-key register__icon"></i>
        </div>
      </div>

      <div *ngIf="formControl.confirm.flag" class="error-msg">
        {{ formControl.confirm.message }}
      </div>

      <div class="register__terms">
        <input class="{{ formControl.checkbox.style }}" type="checkbox" [(ngModel)]="register.checkbox"
          #checkboxxRegister (change)="checkboxEvent(checkboxxRegister.checked)" />
        <a href="{{ links.terms }}" target="_blank" [ngClass]="{
            'error': formControl.checkbox.style == 'error'
          }">
          I agree to the terms and conditions.</a>
      </div>

      <button [ngClass]="{
          'disable-div': disableBtn
        }" class="register__btn-primary" (click)="validateForm()">
        Sign Up
      </button>

      <div class="register__login-link">
        do you already have an account?
        <a href="" routerLink="/login">
          Click here to login
        </a>
      </div>
    </div>

    <div class="register__box" *ngIf="isLinkNotValid">
      <div class="register__text">
        <h3>Oh, no!</h3>
      </div>
      <hr />
      <div class="register__text register__link-fa">
        <i class="fa fa-frown-o"></i>
        <h5>Invalid Link</h5>
      </div>
      <div class="register__btn-ctr">
        <button routerLink="/login" class="register__btn-primary">
          Return to Login
        </button>
      </div>
    </div>
  </div>
</div>