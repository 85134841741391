<div class="video-notes">
  <form action="">
    <div class="form-group">
      <textarea class="form-control" id="exampleFormControlTextarea1" rows="7 " maxlength="4000" placeholder="Notes:"
        [disabled]="!hasAccess">{{ videoDeviceData?.notes }}</textarea>
      <span>maximum 4000 characters</span>
    </div>
    <div>
      <input type="button" *ngIf="hasAccess" class="btn btn-primary float-right doNotPrint" value="Save"
        (click)="saveNotes()" />
    </div>
  </form>

</div>

<!-- 
<div class="row mt-2">
  <div class="col-12 px-0">
    <form action="">
      <div class="form-group">
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="7 " maxlength="4000" placeholder="Notes:"
          [disabled]="!hasAccess">{{ videoDeviceData?.notes }}</textarea>
        <span>maximum 4000 characters</span>
      </div>
      <div>
        <input type="button" *ngIf="hasAccess" class="btn btn-primary float-right doNotPrint" value="Save"
          (click)="saveNotes()" />
      </div>
    </form>
  </div>
</div> -->