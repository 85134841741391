import { Component, AfterViewInit } from '@angular/core';
import * as Chart from 'chart.js';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PopupTypeService } from '../../../services/popupType.service';
import { GetVideoService } from '../../../services/get-video.service';
import { PopupSendDataService } from '../../../services/popup-send-data.service';

@Component({
  selector: 'app-chart-audio-twentyfourhours-dna',
  templateUrl: './chart-audio-twentyfourhours-dna.component.html',
  styleUrls: ['./chart-audio-twentyfourhours-dna.component.scss'],
})
export class ChartAudioTwentyfourhoursDnaComponent implements AfterViewInit {
  canvas: any;
  ctx: any;
  myChartSite: any;
  currentChart = '24h';
  id: Number = 0;
  chartApiData: any;
  loading: Boolean = false;

  chartData = {
    datasets: [
      {
        label: 'Mbps',
        data: [],
        backgroundColor: ['rgb(0, 128, 255, .5'],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)', //red
        borderWidth: 0,
        stepped: true,
      },
      {
        label: 'Mbps-In',
        data: [],
        backgroundColor: ['rgb(3, 173, 20, .5'],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)', //red
        borderWidth: 0,
      },
      {
        label: 'Mbps-Out',
        data: [],
        backgroundColor: ['rgb(188, 71, 3, .5'],
        pointBackgroundColor: 'rgb(184, 58, 36, 0)',
        pointBorderColor: 'rgb(184, 58, 36, 0)', //red
        borderWidth: 0,
      },
    ],
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ds: PopupTypeService,
    private sendPopupDataService: PopupSendDataService,
    public http: HttpClient,
    private Auth: AuthService,
    private getVideoService: GetVideoService
  ) {
    this.route.params.subscribe((params) => {
      if (+params['id'] == this.id) {
      } else {
        this.id = +params['id'];
        if (!this.loading) {
          this.fetchChartData();
        }
      }
    });
  }

  ngAfterViewInit() {
    this.canvas = document.getElementById('myChartSite');
    this.ctx = this.canvas.getContext('2d');
    this.myChartSite = new Chart(this.ctx, {
      type: 'line',
      // data: this.data24h,
      options: {
        responsive: true,
        display: true,
        fontSize: '6px',
        padding: '0px',
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0, // disables bezier curves
          },
        },
      },
    });
    this.currentChart = '24h';
  }

  fetchChartData() {
    this.loading = true;
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const deviceDetails = {
      company_id: decodedToken.company_id,
      device_id: this.id,
      duration: 1,
    };

    this.Auth.getMbpsChartData(deviceDetails).subscribe((data) => {
      let mbps = data['response'][0].mbps_total;
      mbps.sort(this.custom_sort);
      let mbps_out = data['response'][0].mbps_out;
      mbps_out.sort(this.custom_sort);
      let mbps_in = data['response'][0].mbps_in;
      mbps_in.sort(this.custom_sort);

      this.chartData.datasets[0].data = mbps;
      this.chartData.datasets[1].data = mbps_out;
      this.chartData.datasets[2].data = mbps_in;
      this.loadChart();
      this.loading = false;
    });
  }

  custom_sort(a, b) {
    return new Date(a.x).getTime() - new Date(b.x).getTime();
  }

  loadChart() {
    if (this.myChartSite) {
      this.myChartSite.reset();
      this.myChartSite.data.datasets = this.chartData.datasets;
      this.myChartSite.options.scales = {
        xAxes: [
          {
            type: 'time',
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      };
      this.myChartSite.update({ duration: 1000 });
    }
  }
}
