import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-audio-site-devices-list-table',
  templateUrl: './audio-site-devices-list-table.component.html',
  styleUrls: ['./audio-site-devices-list-table.component.scss']
})
export class AudioSiteDevicesListTableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
