import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../services/auth.service'
import { GetIotService } from '../../../services/get-iot.service'

@Component({
  selector: 'app-polycom-add-device-dialog',
  templateUrl: './polycom-add-device-dialog.component.html',
  styleUrls: ['./polycom-add-device-dialog.component.scss']
})
export class PolycomAddDeviceDialogComponent {
  collectorID: any = "";
  deviceIP: any;
  iotDevicePorts: any;
  deviceRoomName: any;
  deviceName: any = '';
  devicePassword: any = '';
  deviceFlag: any;
  siteId: any;
  iotIp: any;
  iotName: any;

  setTimer: any
  actionCount: any = 0;
  aDeviceIsFound: any = false;



  showSuccessMsg: any = false;
  showSuccessMsgIot: any = false;
  successMessage: any = "Device Successfully Added";

  errorMsg: any = "Device Not Found";
  showErrMsg: any = false;
  showErrorMessageIot: any = false;
  errorMessage: any = 'Could not find device. Please try again.';
  showMsg30Sec: boolean = false;
  errorMessageIot: any = 'Could not find Ports. Please try again.';

  showSearching: any = false;
  showSearchingIot: any = false;

  showAddDevice: any = false;
  showAddDeviceIot: any = false;
  showErrorMessage: any = false;
  closeButtonImage: any = './assets/img/iot/delete-btn.png';

  deviceModel: any;
  serialNumber: any;
  deviceMac: any;
  softwareRelease: any;
  activeMic: any;
  activeCamera: any;
  isLoading: any = false;
  iotDeviceIP: any;
  iotDeviceMac: any;
  iotDeviceName: any;
  iotDeviceMake: any;

  discoverIpFromIot: any = false
  deviceActionId: any;
  iotPortsHolder: any;
  firmware: any;
  iotDeviceIPFound: any = false;
  isDomotzIntegration = environment.config.domotzIntegration;


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private Auth: AuthService,
    private dialogRef: MatDialogRef<PolycomAddDeviceDialogComponent>,
    private getIot: GetIotService
  ) {
    if (data) {
      const config = { ...data.config };
      this.deviceIP = config.deviceIp;
      this.collectorID = config.collector_id;
      this.devicePassword = config.devicePassword;
      this.deviceName = config.deviceName;
      this.deviceFlag = config.deviceFlag;
      this.deviceRoomName = config.deviceRoomName;
      this.iotIp = config.iotIp
      this.iotName = config.iotName
      this.siteId = config.siteId;

      console.log('deviceIP: ', this.deviceIP)

      if (this.collectorID && this.deviceIP) {
        this.discoverDevice();
      } else {
        //we can say no device was found
      }

    }
  }

  getDeviceUserName = (name) => this.deviceFlag ? 'Polycom' : name;

  getMediaType = () => this.deviceFlag ? 'audio' : 'polycom';

  getMediaTypeAgain = () => this.deviceFlag ? 'audio' : 'video';


  discoverDevice = () => {
    const params = {
      collector_id: this.collectorID,
      site_id: this.siteId,
      username: this.getDeviceUserName(this.deviceName),
      password: this.devicePassword,
      room_name: this.deviceRoomName,
      type: this.getMediaType(),
      ip: this.deviceIP,
    }
    this.Auth.findDevice(params).subscribe((data: any) => {
      let [res] = data.response;
      if (res.status == "OK") {
        this.deviceActionId = res.action_id;
        this.checkStatus(res.action_id);
        this.showSearching = true;
      } else {
        this.errorMsg = res.message;
        this.showErrMsg = true;
      }
    },
      (err) => {
        this.showSearching = false;
      })
  }

  checkStatus = (action_id) => {
    console.log('action_id: ', action_id);
    this.Auth.postDeviceActionId({ action_id }).subscribe((actionData) => {
      console.log('postDeviceActionId: ', actionData)
      console.log('actionCount: ', this.actionCount)
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 120) {
        //resets field if device not found 
        if (!this.aDeviceIsFound) {
          console.log('reset!')
          this.resetFields();
          this.showErrMsg = true;
        }
        else{
          this.resetFieldsForIot();
          this.showErrorMessageIot = true;
        }

        return;
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus(action_id);
        }, 1000);
        this.actionCount++;
        if (this.actionCount == 30) {
          this.showMsg30Sec = true;

          // Since total time of waiting is 120 seconds and after 30 we show It iss taking longer then usual
          setTimeout(() => {
            this.showMsg30Sec = false;
          }, 70*1000); 
        }
      } else if (actionData.response[0].status === 'FAILED') {

        this.resetFields();
        const [newData] = actionData.response;
        this.errorMsg = 'Could not find device. Please try again.';
        if (newData.results.message != 'device not found') {
          this.errorMsg = newData.results.message;
        }
        this.showErrMsg = true;
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        console.log('COMPLETE!!!')
        const [newData] = actionData.response;

        if (!this.discoverIpFromIot) {
          //for audio
          this.resetFields();
          this.showAddDevice = true;
          this.aDeviceIsFound = true;
          this.setData(newData.results);
          if (this.iotIp && !this.iotDeviceIPFound) {
            this.discoverIotDevice(this.iotIp);
          } else {
            console.log('no ports is present');
            return;
          }

        } else {
          this.resetFieldsForIot();
          console.log('ports found')
          this.setDataIot(newData.results);
          return;

        }
      }
    });
  }


  resetFieldsForIot = () => {
    this.actionCount = 0;
    this.showSearchingIot = false;
    this.showErrorMessageIot = false;
    this.showSearchingIot = false;
    this.showAddDeviceIot = false;
    this.showMsg30Sec = false;
  }

  resetFields = () => {
    this.actionCount = 0;
    this.showSearching = false;
    this.showErrorMessage = false;
    this.showAddDevice = false;
    this.showMsg30Sec = false;
  }

  iotHostName: any

  setDataIot = (params) => {
    const validJSON = params?.results?.replace(/,(?=\s*})/, '').replace(/\\\\"/g,"'")
    const res2 = JSON.parse(validJSON);
    // const { nmap: { host } } = res2;
    let host = res2?.nmap?.host
    console.log('res2: ', res2)
    console.log('host: ', host)

    if (host) {
      // if (host.hostnames == "") {
      //   this.iotDeviceIP = host.address[0].addr;
      //   this.iotDeviceMac = host.address[1].addr;
      //   this.iotDeviceName = this.iotName || 'VISUAL+';
      //   this.iotDeviceMake = host.address[1].vendor;

      // } else {
      //   this.iotDeviceName = host.hostnames.hostname.name;
      //   this.iotDeviceIP = host.address.addr;
      //   this.iotDeviceMac = "--";
      //   this.iotDeviceMake = host.hostnames.hostname.name;
      // }

      this.iotDeviceIP = this.getDeviceIp(host);
      this.iotDeviceMac = this.getDeviceMac(host);
      this.iotDeviceName = `${this.deviceRoomName} VISUAL+`;
      this.iotDeviceMake = this.getDeviceMake(host);
      this.iotHostName = this.getHostname(host);
      // else we can also say that no ports available?

      if (host.ports) {
        if (host.ports.port) {
          this.iotPortsHolder = host.ports.port.map(e => {
            return {
              portid: e.portid,
              portname: e.service.name,
              is_monitored: 1
            }
          }); //ask if its no empty?
        } else {
          this.iotPortsHolder = [];
        }
      } else {
        this.iotPortsHolder = [];
      }

      // this.iotPortsHolder = [] // remove

      this.iotDevicePorts = JSON.stringify(this.iotPortsHolder);


      this.showAddDeviceIot = true;
    } else {
      //display device not found
      this.resetFieldsForIot();
      this.iotIp = 0;
      this.showErrorMessageIot = true;
    }
  }

  getDeviceIp = (host: any) => {
    if (Array.isArray(host.address)) {
      if (host.address[0].addr) {
        return host.address[0].addr;
      }
    } else {
      if (host.address.addr) {
        return host.address.addr;
      }
    }
    return 'null';
  }

  getDeviceMac = (host: any) => {
    if (Array.isArray(host.address)) {
      if (host.address[1].addr) {
        return host.address[1].addr;
      }
    }
    return 'null';
  }

  getHostname = (host: any) => {
    if (host.hostnames) {
      if (host.hostnames.hostname) {
        if (host.hostnames.hostname.name) {
          return host.hostnames.hostname.name;
        }
      }
    }
    return 'null';
  }

  getDeviceMake = (host: any) => {
    //check if its in the address
    if (Array.isArray(host.address)) {
      if (host.address[1].vendor) {
        return host.address[1].vendor;
      }
    }
    return 'Unknown';
  }


  discoverIotDevice = (iotIp) => {
    //edit later
    console.log('discoverIotDevice: ', iotIp)
    this.Auth.getNmap(this.collectorID, iotIp, 'add', this.siteId).subscribe((data) => {
      let [res] = data.response;
      if (res.status == "OK") {
        this.iotDeviceIPFound = true;
        this.discoverIpFromIot = true;
        this.checkStatus(res.action_id);
        this.showSearchingIot = true;
      } else {
        this.errorMessageIot = res.message;
        console.log('this.errorMessageIot: ', this.errorMessageIot)
        if(res.message == 'A IOT with an IP address 172.19.3.117 is already registered to this collector') {
         this.iotIp = 0;
        }
        this.showErrorMessageIot = true;
      }
    },
      (err) => {
        this.showSearching = false;
      })
  }


  setData = (params) => {
    console.log('setData for video: ', params )
    var mrPairDeviceList;
    if (params) {
      const { model, serialnumber, mac, softwareinfo, active_mic, active_camera, mrpairinfo, firmware, device_name } = params
      
      if (mrpairinfo) {
        if (mrpairinfo.mrpairinfo_raw_data) {
          if (mrpairinfo.mrpairinfo_raw_data.data) {
            // if (Array.isArray(mrpairinfo.mrpairinfo_raw_data.data.ConnectedDeviceList)) {
             [mrPairDeviceList] = mrpairinfo.mrpairinfo_raw_data.data.ConnectedDeviceList
            console.log('ConnectedDeviceList: ', mrPairDeviceList)
            if (mrPairDeviceList) {
              if(mrPairDeviceList.mrIpAddress) {
                this.iotIp = mrPairDeviceList.mrIpAddress;
              }
              // }
            }
          }
        }
      }
      
      let deviceFirmware = this.deviceFlag ? firmware : softwareinfo;
      console.log('ConnectedDeviceList: ', mrPairDeviceList)
      console.log('deviceFlag: ',this.deviceFlag)
      
      let deviceSerial = this.deviceFlag ? this.getAudioSerial(mrPairDeviceList) : serialnumber;
      let deviceActiveCamera = this.deviceFlag ? this.getAudioCamCount(this.iotIp) : active_camera;
      let deviceName = this.deviceFlag ? this.getAudioCamCount(this.iotIp) : active_camera;

      
      this.deviceModel = model;
      this.serialNumber = this.checkValueIfEmpty(deviceSerial);
      
      this.firmware = this.checkValueIfEmpty(deviceFirmware);
      this.deviceMac = mac;
      this.softwareRelease = this.checkValueIfEmpty(softwareinfo);
      this.activeMic = this.checkValueIfEmpty(active_mic);
      this.activeCamera = this.checkValueIfEmpty(deviceActiveCamera);
      // IF IOT Domotz integration is enabled we have to disable searching and auto adding
      this.deviceName = this.checkValueIfEmpty(device_name);
      
      // if(!this.isDomotzIntegration){

      // }
    }
    //  return error
  }
  
  getDeviceName = (name) => {
    if(name.ComponentList ) {
      const [,res] = name.ComponentList;
        if (res) {
        return res.componentSerialNumber;
       }
     }
     return;
  }
  
  getAudioCamCount = (cam) => {
    return cam ? 1 : 0;
  }

  getAudioSerial = (serial) => {
    if(serial.ComponentList ) {
     const [res] = serial.ComponentList;
       if (res) {
       return res.componentSerialNumber;
      }
    }
    return;
  }

  checkValueIfEmpty = (value) => value ? value : "--";

  closeDialog = () => {
    this.isLoading = false;
    this.dialogRef.close(true);
  };

  cancelAddDevice = () => {
    this.showAddDevice = false;
  }

  addDevice = () => {
    //video and audio
    this.Auth.sendAddDeviceData({
      action_id: this.deviceActionId,
      type: this.getMediaTypeAgain()
    }).subscribe((data: any) => {
      // console.log('addDevice: ', data)
      this.resetFields();
      this.resetFieldsForIot();
      this.showSuccessMsg = true;
      this.aDeviceIsFound = false;
    });
  }

  audioDeviceId: any;
  iotDeviceId: any;

  addDeviceAndLink = () => {
    this.Auth.sendAddDeviceData({
      action_id: this.deviceActionId,
      type: this.getMediaTypeAgain()
    }).subscribe((data: any) => {
      const [res] = data.response;
      this.audioDeviceId = res.deviceId;

      console.log(' this.audioDeviceId: ', this.audioDeviceId);
      console.log(' this.res: ', res);

      this.resetFields();
      //after adding the audio we can add iot
      this.resetFieldsForIot();
      this.addIotDeviceAndLink();
      // this.showSuccessMsg = true;
    });
  }

  addIotDeviceAndLink = () => {
    this.getIot.iotLiteAdd(
      this.collectorID,
      this.iotDeviceName,
      "host",
      this.iotIp,
      this.iotDevicePorts,
      this.iotDeviceMac,
      "8",
      this.iotDeviceMake,
      this.siteId
    ).subscribe((data: any) => {
      console.log('this.data: ', data)
      const res = data.response;
      this.iotDeviceId = res.iot_id
      if (this.audioDeviceId && this.iotDeviceId) {
        console.log(this.audioDeviceId)
        console.log(this.iotDeviceId)
        this.linkIotToAudioDevice();
      } else {
        this.showErrorMessageIot = true;
        this.errorMessageIot = 'Device & Ports are Added but Device Linking failed';
      }
    });
  }

  linkIotToAudioDevice = () => {
    this.getIot
      .iotLiteLinkDevice(
        this.iotDeviceId,
        this.audioDeviceId,
        'audio',
      )
      .subscribe(
        (data: any) => {
          console.log('linkIotToAudioDevice: ', data)
          this.successMessage = "Device & Ports Successfully Added and Linked"
          this.showSuccessMsg = true;
          this.iotIp = 0;
        },
        (error) => {
          // this.displayMessage = 'Someting went wrong when linking IoT Domotz Device.';
          //another error
          this.showErrorMessageIot = true;
          console.log('showErrorMessageIot: ', this.showErrorMessageIot)
          this.errorMessageIot = 'Device & Ports are Added but Device Linking failed';
        }
      );
  };

}
