import * as moment from 'moment-timezone'

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'
import { faPause, faPlay, faUser } from '@fortawesome/free-solid-svg-icons'

import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../services/auth.service'
import { GetCloudService } from '../../../services/get-cloud.service'
import { GetIotService } from '../../../services/get-iot.service'
import { PopupTypeService } from '../../../services/popupType.service'
import { RefreshApiService } from 'src/app/services/refresh-api.service'

@Component({
  selector: 'app-cloud-room-info',
  templateUrl: './cloud-room-info.component.html',
  styleUrls: ['./cloud-room-info.component.scss'],
})
export class CloudRoomInfoComponent implements OnInit, OnChanges {
  @Output() cloudEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() cloudRoomInfo: any;
  @Input() upTimeDays: number;
  @Input() upTimeHours: number;
  @Input() upTimeMins: number;

  rebootDate = {};
  uptimeday = 0;
  displayHub: string;
  tableHub: string;
  connectionType: string;
  networkType: string;
  hasAccess: boolean;
  accessLevel: any;
  isSuperAdmin: any;
  iotData: any;
  shouldShowIot: Boolean;
  shouldShowDisplayAndTableHub: Boolean = false;
  
	faPauseIcon = faPause
	faPlayIcon = faPlay

  isDisableDesktopClientPluginToggle: boolean = true; //disable for now
  userStatus: any = 'Online';
  isZoomPluginEnabled: boolean = false;
  deskPlugin: String = environment.config.dynamicText.deskPlugin;
  desktopPluginMessage: string;
  deskPluginStatus: number;

  hostname: any;
  ipAddress: any;
  mac: any
  faIcon = faUser;
  
  isShowUptime: any;
  
  iotLiteEnabled: any;
  iotDeviceIdList: any = [];
  dCollector: String = environment.config.dynamicText.dCollector;
  iotLoader: String = environment.config.dynamicImage.iotLoader;
  iotDomotzList: any;
  iotDomotzDeviceId: any;
  iotUnlinkedDeviceList: any;
  isDomotzEnabled: boolean = false;
  
  
  constructor(
    public Auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public ds: PopupTypeService,
    private getCloudService: GetCloudService,
    private GetIot: GetIotService,
    private refreshApiData: RefreshApiService,
  ) { }

  ngOnInit() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.accessLevel = decodedToken.accesslevel;
    this.isSuperAdmin = this.Auth.accessLevel === 'SUPERADMIN';
    this.hasAccess = this.getHasAccess();
    this.shouldShowIot = environment.config.showIoT;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cloudRoomInfo && this.cloudRoomInfo) {
      this.setData();
      this.checkForPluginEnabled();
    }
  }
  
  setData = () => {
      const {
        collector_id,
        zoom_user_id,
        iot_components,
        iot_domotz_components,
        zoom_plugin_data,
        plugin_host_info,
        network_type,
        connection_type,
        collector_version,
        plugin_id,
        site_id,
        collector_status,
        room_name,
      } = this.cloudRoomInfo;
      
        
      if (plugin_host_info !== null) {
        const { last_reboot } = plugin_host_info;
        this.getlastRebootDate(last_reboot);
      }
      
      if (zoom_plugin_data !== null) {
        this.getLogitechRally(zoom_plugin_data);
      }
      
      this.connectionType = connection_type;
      this.networkType = network_type;
      this.ipAddress = this.getCloudRoomIP() //pc ip
      this.iotData = {
        collector_id,
        device_id: zoom_user_id,
        quadrant: 3,
        iot_components,
        iot_domotz_components,
        collector_version,
        status: this.checkPluginEnabledAndStatus(collector_status, site_id),
        room_name,
        site_id,
        mac: '',
        ipv4: this.getPluginHostInfoIp(plugin_host_info),
      };
      
      this.isShowUptime = this.cloudRoomInfo.zoom_plugin_enabled == 1 && !this.checkOffline(this.cloudRoomInfo);
      this.getDomotzAccountByCompanyId();
      
  }
  
  getDomotzAccountByCompanyId = () => {
    this.GetIot.getDomotzAccountByCompanyId().subscribe((data: any) => {
      const { enabled } = data;
      this.isDomotzEnabled = enabled === 1;
      if (this.isDomotzEnabled) {
        this.setIoTComponenets(true);
      } else {
        this.getIotLiteStatus();
      }
    });
  };
  
  getIotLiteStatus = () => {
    this.GetIot.getIotLiteStatus().subscribe((data: any) => {
      let [res] = data.response;
      this.iotLiteEnabled = res.is_iot_lite;
      this.setIoTComponenets(false);
    });
  }
  
  setIoTComponenets = (isdomotzEnabled) => {
      if (isdomotzEnabled) {
        this.getLinkedIotDomotzDevices();
      } else {
        if (this.iotLiteEnabled) {
          this.getLinkedIotDevices();
        } 
      }
  };
  
  getLinkedIotDevices = () => {
    if (this.iotData.iot_components !== null) {
      this.iotDeviceIdList = [...this.iotData.iot_components].map( e => e.iot_id);
    }
  };

  getLinkedIotDomotzDevices = () => {
    const { iot_domotz_components } = this.iotData;
    if (iot_domotz_components !== null) {
      this.iotDeviceIdList = iot_domotz_components.map((i) => {
        const status = i.status;
        const paused = i.paused;
        const iot_device_id = i.iot_id;
        const iot_type = i.group_type;
        const iot_name = i.display_name;
        const iot_ip = i.ip_address;

        return { iot_device_id, status, iot_type, iot_name, iot_ip, paused };
      });
    } else {
      this.iotDeviceIdList = [];
    }
  };
  
  setRoomPause = (flag) => {
    const pauseFlag = flag ? 0 : 1;
    this.cloudRoomInfo.pause = pauseFlag;
    let i = 0;
    
    this.getCloudService.pauseZoom(this.cloudRoomInfo.zoom_user_id, pauseFlag).subscribe((data: any) => {
    const {status}= data;
    if(status == 200) {
      console.log(status)
      while ( i < this.iotDeviceIdList.length) {
        console.log(i)
        this.setPaused(this.iotDeviceIdList[i], pauseFlag);
        i++;
      }
      this.refreshCloud();
      this.refreshApiData.refreshStatsEvent.emit('cloud')
    }
    });
  }
  
  setPaused = (iot_id,flag) => {
    this.GetIot.setIotDevicePaused(iot_id, flag).subscribe(
      (data: any) => {
        // this.eventEmitter.emit('callGetIotDeviceDetails');
        console.log('setIotDevicePaused: ', data)
        
      },
      (error) => { }
    );
  };


  getPluginHostInfoIp = (plugin_host_info) => {
    if (plugin_host_info != null) {
      return plugin_host_info.ip_address
    }
    return null;
  }


  checkForPluginEnabled = () => {
    if (this.cloudRoomInfo.plugin_id !== null) {
      this.isDisableDesktopClientPluginToggle = false;
    } else {
      this.isDisableDesktopClientPluginToggle = true;
    }

    this.isZoomPluginEnabled = !this.isDisableDesktopClientPluginToggle && this.cloudRoomInfo.zoom_plugin_enabled == 1;
    this.deskPluginStatus = this.cloudRoomInfo.plugin_status;
    this.desktopPluginMessage = this.getDesktopPluginMessage();
    this.userStatus = this.cloudRoomInfo.status;
  }

  getDesktopPluginMessage = () => {
    if (this.isDisableDesktopClientPluginToggle) {
      return `To enable advance features, please download and install the ${this.deskPlugin}.`;
    }
    if (
      !this.isDisableDesktopClientPluginToggle &&
      this.isZoomPluginEnabled &&
      this.deskPluginStatus != 1
    ) {
      return `${this.deskPlugin} is offline.`;
    }
    return '';
  };

  checkPluginEnabledAndStatus = (collector_status, site_id) => {
    if (collector_status != null && site_id != null) {
      if (collector_status == 1) {
        return true;
      }
    }
    return false;
  };

  getLogitechRally = (zoom_plugin_data) => {
    const { logitech } = zoom_plugin_data;
    if (logitech != null) {
      const logictechDevices = [...logitech.AllProducts.Products];
      const findRallyArr = logictechDevices.find((e) => e.Name === 'Rally');
      if (findRallyArr) {
        const { Devices } = findRallyArr;
        const findTableAndDisplay = Devices.filter(
          (e) => e.Name === 'Rally Table Hub' || e.Name === 'Rally Display Hub'
        );
        const findTable = findTableAndDisplay.find((e) => e.Name === 'Rally Table Hub');
        const findDisplay = findTableAndDisplay.find((e) => e.Name === 'Rally Display Hub');

        if (findTable) {
          this.tableHub = findTable.ConnectionState === 10 ? 'Not Connected' : 'Connected';
          this.shouldShowDisplayAndTableHub = true;
        } else {
          this.tableHub = 'Not Connected';
        }

        if (findDisplay) {
          this.displayHub = findDisplay.ConnectionState === 10 ? 'Not Connected' : 'Connected';
          this.shouldShowDisplayAndTableHub = true;
        } else {
          this.displayHub = 'Not Connected';
        }
      }
    }
  };

  getlastRebootDate = (date) => {
    const last_reboot = moment(date).utcOffset(0, false);
    const date_now = moment().utcOffset(0, false);
    const diff_minutes = date_now.diff(last_reboot, 'minutes');
    this.upTimeDays = Math.floor(diff_minutes / 1440);
    this.upTimeHours = Math.floor((diff_minutes % 1440) / 60);
    this.upTimeMins = Math.floor((diff_minutes % 1440) % 60);
  };

  checkIfOnline = (cloudRoomInfo) => {
    if (cloudRoomInfo) {
      if (cloudRoomInfo.plugin_id == null || cloudRoomInfo.zoom_plugin_enabled == 0) {
        return cloudRoomInfo.status == 'Available' && cloudRoomInfo.pause == 0;
      }

      if (cloudRoomInfo.plugin_status == 1) {
        return cloudRoomInfo.plugin_application[0].zoom_room == 1 && cloudRoomInfo.pause == 0;
      } else {
        return cloudRoomInfo.status == 'Available' && cloudRoomInfo.pause == 0;
      }
    }
    return false;
  }

  checkOffline = (cloudRoomInfo) => {
    if (cloudRoomInfo) {
      if (cloudRoomInfo.plugin_id == null || cloudRoomInfo.zoom_plugin_enabled == 0) {
        const flag1 = cloudRoomInfo.status != 'Available' && cloudRoomInfo.pause == 0;
        // console.log('checkOffline: ', flag1)
        return flag1
      }

      if (cloudRoomInfo.plugin_status == 1) {
        const flag1 = cloudRoomInfo.plugin_application[0].zoom_room != 1 && cloudRoomInfo.pause == 0;
        // console.log('checkOffline: ', flag1)
        return flag1
        
      } else {
        const flag1 = cloudRoomInfo.status != 'Available' && cloudRoomInfo.pause == 0;
        // console.log('checkOffline: ', flag1)
        return flag1
      }
    }
    return true;
  }

  checkPause = (cloudRoomInfo) => {
    if (cloudRoomInfo) {
      return cloudRoomInfo.pause == 1;
    }
    return false;
  }

  getCloudRoomIP = () => {
    if (this.cloudRoomInfo?.connected_devices?.device_ip) {
      return this.getComputerIp(this.cloudRoomInfo.connected_devices.device_ip);
    }
    return '--';
  }

  getComputerIp = (deviceIp) => {
    const len = deviceIp?.length;
    let searhKey = 'Computer';
    if (len > 0) {
      const cPosition = deviceIp.search(searhKey);
      if (cPosition >= 0) {
        const startIndex = cPosition + searhKey.length + 2;
        const ips = deviceIp.substring(startIndex).split(';');
        return ips[0].trim();
      }

      searhKey = 'Digital Signage Only';
      const dPosition = deviceIp.search(searhKey);
      if (dPosition >= 0) {
        const startIndex = cPosition + searhKey.length + 2;
        const ips = deviceIp.substring(startIndex).split(';');
        return ips[0].trim();
      }
      return '--';
    }
    return '--';
  }

  getCloudRoomSerialNumber = () => {
    if (this.cloudRoomInfo) {
      if (this.cloudRoomInfo.plugin_host_info) {
        return this.cloudRoomInfo.plugin_host_info.bios_sn;
      }
    }
    return '--';
  }

  refreshCloud = () => {
    this.cloudEvent.emit('refreshCloudData');
  };

  getHasAccess = () => {
    if (this.accessLevel) {
      if (this.accessLevel == 'USER' || this.isSuperAdmin) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };


  

  
}