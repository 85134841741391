<div class="container-fluid" id="main-box">
  <div class="row">
    <div class="col-12">
      <form
        role="form"
        class="signup"
        name="editProfileForm"
        [formGroup]="editProfileForm"
        (ngSubmit)="onSubmit()"
      >
        <h4 class="left">
          <span>
            Company Information
          </span>
        </h4>
        <div class="form-group">
          <input
            type="text"
            formControlName="companyname"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.companyname.errors }"
            placeholder="Company Name *"
            id="companyname"
            name="companyname"
            autocomplete="organization"
            required
          />
          <div *ngIf="submitted && f.companyname.errors" class="invalid-feedback">
            <div
              *ngIf="f.companyname.errors.maxlength"
              class="phonetext alert alert-danger p-2 m-0"
            >
              Exceed Maximum 30 character
            </div>
            <!-- <div *ngIf="f.firstname.errors.required">First Name is required</div> -->
            <div *ngIf="f.companyname.errors.required" id="confirmMessage">
              !
            </div>
          </div>
        </div>
        <div class="form-group">
          <!-- <input type="text" class="form-control" id="address1" placeholder="company address" required /> -->
          <input
            type="text"
            formControlName="address1"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.address1.errors }"
            placeholder="Company Address 1 *"
            id="address1"
            name="address1"
            autocomplete="street-address"
            required
          />
          <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
            <div *ngIf="f.address1.errors.maxlength" class="phonetext alert alert-danger p-2 m-0">
              Exceed Maximum 50 character
            </div>
            <div *ngIf="f.address1.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        <div class="form-group">
          <input
            type="text"
            formControlName="address2"
            class="form-control"
            id="address2"
            placeholder="Company Address 2"
            autocomplete="address-line2"
          />
        </div>
        <div class="form-group">
          <!-- <input type="text" class="form-control" id="city" placeholder="city" required /> -->
          <input
            type="text"
            formControlName="city"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
            placeholder="City *"
            id="city"
            name="city"
            autocomplete="city"
            required
          />
          <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
            <div *ngIf="f.city.errors.maxlength" class="phonetext alert alert-danger p-2 m-0">
              Exceed Maximum 30 character
            </div>
            <!-- <div *ngIf="f.address1.errors.required">First Name is required</div> -->
            <div *ngIf="f.city.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        <div class="form-group">
          <select
            #selectSetCountryTemplate
            type="text"
            (change)="setCountry(selectSetCountryTemplate.value)"
            formControlName="country"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.country.errors }"
            placeholder="Country *"
            name="country"
            id="country"
            autocomplete="country"
            required
          >
            <option value="companyProfileData[0].country" selected disabled>{{
              countryName
            }}</option>
            <option
              *ngFor="let countries of allCountryListData; let i = index"
              value="{{ countries.id }}"
            >
              {{ countries.name }}</option
            >
          </select>
          <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
            <div *ngIf="f.country.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        <div class="row">
          <div class="col-9">
            <div class="form-group">
              <select
                type="text"
                formControlName="state"
                class="form-control"
                placeholder="State/Province/Region *"
                name="state"
                id="state"
                autocomplete="state"
                required
              >
                <option value="{{ companyProfileData[0]?.state }}" selected disabled
                  >{{ companyProfileData[0]?.state }}
                </option>
                <option
                  *ngFor="let states of allStatesListData; let i = index"
                  value="{{ states.name }}"
                >
                  {{ states.name }}</option
                >
              </select>
              <!-- <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                <div *ngIf="f.state.errors.required" class="phonetext alert alert-danger p-2 m-0">Select State</div>
                <div *ngIf="f.state.errors.required" id="confirmMessage">!</div>
              </div> -->
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <!-- <input type="text" class="form-control" id="zip" placeholder="zip" required /> -->
              <input
                type="text"
                formControlName="zip"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.zip.errors }"
                placeholder="Zip *"
                name="zip"
                id="zip"
                autocomplete="postal-code"
                required
              />
              <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
                <div *ngIf="f.zip.errors" class="zip-text alert alert-danger p-2 m-0">
                  Please enter a valid Zipcode of the country
                </div>
                <div *ngIf="f.zip.errors.required" id="confirmMessage">!</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <input
              type="text"
              [(ngModel)]="compphonecode"
              formControlName="compphonecode"
              class="form-control"
              name="compphonecode"
              id="compphonecode"
              placeholder="Code *"
              autocomplete="tele-code"
              readonly
            />
          </div>
          <div class="col-8">
            <div class="form-group">
              <!-- <input type="text" class="form-control" id="companyphone" placeholder="phone number" /> -->
              <input
                type="text"
                [(ngModel)]="companyphone"
                formControlName="companyphone"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.companyphone.errors }"
                placeholder="Company Phone *"
                name="companyphone"
                id="companyphone"
                autocomplete="tel-national"
                required
              />
              <div *ngIf="submitted && f.companyphone.errors" class="invalid-feedback">
                <div *ngIf="f.companyphone.errors" class="phonetext alert alert-danger p-2 m-0">
                  Please enter valid phone number
                </div>
                <div *ngIf="f.companyphone.errors.required" id="confirmMessage">
                  !
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="text-center text-success" *ngIf="successMsg">{{ successMsg }}</p>
        <p class="text-center text-danger" *ngIf="errorMsg">{{ errorMsg }}</p>
        <button type="submit" class="btn-primary" style="width: 100%;">
          Save Profile
        </button>
      </form>
    </div>
  </div>
</div>
