import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; 
import { Router } from '@angular/router';

@Component({
  selector: 'app-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss']
})
export class FilterTableComponent implements OnInit {

  @Input() tableSettings: Array<any>=[];
  @Input() tableSeedData: Array<any>=[];

  constructor(private route: ActivatedRoute, private router: Router,) { 
  }


  ngOnInit() {
   // console.log(this.tableSettings);
   // console.log(this.tableSeedData);
  }

}
