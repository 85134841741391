<div class="row">
  <div class="col-12">
    <form
      (submit)="verifiyCode($event)"
      role="form"
      id="codeForm"
      name="codeForm"
      class="pin-form"
      [formGroup]="codeForm"
      (ngSubmit)="onSubmit()"
      novalidate
    >
      <h4>Please enter your pin below:</h4>
      <div class="row">
        <div class="col-2">
          <input
            type="text"
            formControlName="value1"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.value1.errors }"
            placeholder=""
            name="value1"
            id="value1"
            maxlength="1"
            (keyup)="goNextInput(2)"
            required
          />
          <div *ngIf="submitted && f.value1.errors" class="invalid-feedback">
            <div *ngIf="f.value1.errors.required" id="confirmMessage"></div>
          </div>
        </div>
        <div class="col-2">
          <input
            type="text"
            formControlName="value2"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.value2.errors }"
            placeholder=""
            name="value2"
            id="value2"
            maxlength="1"
            (keyup)="goNextInput(3)"
            required
          />
          <div *ngIf="submitted && f.value2.errors" class="invalid-feedback">
            <div *ngIf="f.value2.errors.required" id="confirmMessage"></div>
          </div>
        </div>
        <div class="col-2">
          <input
            type="text"
            formControlName="value3"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.value3.errors }"
            placeholder=""
            name="value3"
            id="value3"
            maxlength="1"
            (keyup)="goNextInput(4)"
            required
          />
          <div *ngIf="submitted && f.value3.errors" class="invalid-feedback">
            <div *ngIf="f.value3.errors.required" id="confirmMessage"></div>
          </div>
        </div>
        <div class="col-2">
          <input
            type="text"
            formControlName="value4"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.value4.errors }"
            placeholder=""
            name="value4"
            id="value4"
            maxlength="1"
            (keyup)="goNextInput(5)"
            required
          />
          <div *ngIf="submitted && f.value4.errors" class="invalid-feedback">
            <div *ngIf="f.value4.errors.required" id="confirmMessage"></div>
          </div>
        </div>
        <div class="col-2">
          <input
            type="text"
            formControlName="value5"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.value5.errors }"
            placeholder=""
            name="value5"
            id="value5"
            maxlength="1"
            (keyup)="goNextInput(6)"
            required
          />
          <div *ngIf="submitted && f.value5.errors" class="invalid-feedback">
            <div *ngIf="f.value5.errors.required" id="confirmMessage"></div>
          </div>
        </div>
        <div class="col-2">
          <input
            type="text"
            formControlName="value6"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.value6.errors }"
            placeholder=""
            name="value6"
            id="value6"
            maxlength="1"
            required
          />
          <div *ngIf="submitted && f.value6.errors" class="invalid-feedback">
            <div *ngIf="f.value6.errors.required" id="confirmMessage"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p id="feedback" class="feedback text-center text-danger"></p>
        </div>
      </div>
      <div class="row">
        <div class="col-10 p-0" id="colorAlert"></div>
        <div class="col-2 p-0">
          <button
            *ngIf="pinCodeResponse != 'OK'"
            type="submit"
            name="submit"
            class="float-right"
            style="width: 100%;"
          >
            Enter
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
