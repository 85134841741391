<div class="chart-historic-ping-status">
  <div class="chart-historic-ping-status__head">
    <div class="chart-historic-ping-status__head__print">
      <i class="fa fa-print fa-2x menu-icon" (click)="print()"></i>
    </div>
    <div class="chart-historic-ping-status__head__options">
      <button class="chart-historic-ping-status__head__options__btn" [ngClass]="{ 'active': dateRange === HOUR_RANGE }"
        (click)="switchDateRange(HOUR_RANGE)">
        60m
      </button>
      <button class="chart-historic-ping-status__head__options__btn" [ngClass]="{ 'active': dateRange === DAY_RANGE }"
        (click)="switchDateRange(DAY_RANGE)">
        24h
      </button>
      <button class="chart-historic-ping-status__head__options__btn" [ngClass]="{ 'active': dateRange === WEEK_RANGE }"
        (click)="switchDateRange(WEEK_RANGE)">
        7d
      </button>
      <div class="chart-historic-ping-status__head__options__switch">
        <label class="chart-historic-ping-status__head__options__switch__label">List</label>
        <label class="switch">
          <input type="checkbox" [(ngModel)]="isDisplayChart" (click)="switchDisplayType()" />
          <span class="slider round"></span>
        </label>
        <label class="chart-historic-ping-status__head__options__switch__label">Chart</label>
      </div>
    </div>
  </div>
  <div class="chart-historic-ping-status__body">
    <canvas [hidden]="!isDisplayChart" id="historic-ping-status" [width]="canvasWidth" [height]="canvasHeight"></canvas>
    <div *ngIf="!isDisplayChart" id="historic-ping-status-list"
      class="chart-historic-ping-status__body__table__container">
      <table mat-table [dataSource]="dataSource" class="chart-historic-ping-status__body__table">
        <ng-container matColumnDef="median">
          <th mat-header-cell *matHeaderCellDef><strong>Median RTD</strong></th>
          <td mat-cell *matCellDef="let row">
            {{ row.median }}
          </td>
        </ng-container>

        <ng-container matColumnDef="min">
          <th mat-header-cell *matHeaderCellDef><strong>Best RTD</strong></th>
          <td mat-cell *matCellDef="let row">
            {{ row.min }}
          </td>
        </ng-container>

        <ng-container matColumnDef="max">
          <th mat-header-cell *matHeaderCellDef><strong>Worst RTD</strong></th>
          <td mat-cell *matCellDef="let row">
            {{ row.max }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lost_packet_count">
          <th mat-header-cell *matHeaderCellDef><strong>Packet Loss</strong></th>
          <td mat-cell *matCellDef="let row">
            {{ row.lost_packet_count }}
          </td>
        </ng-container>

        <ng-container matColumnDef="timestamp">
          <th mat-header-cell *matHeaderCellDef><strong>Timestamp</strong></th>
          <td mat-cell *matCellDef="let row">
            {{ row.timestamp }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>