<div class="forgot" [ngStyle]="visibilityBackground">
  <div class="forgot__col">
    <div class="forgot__box">
      <div class="forgot__row-1" *ngIf="!message.flag || (message.flag && message.status === 'error')">
        <div class="forgot__text">
          <h3>Oh no.. don't worry, let's</h3>
          <h3>get your password reset.</h3>
        </div>
        <hr />
        <div class="forgot__text" *ngIf="message.status !== 'error'">
          <h5>Enter your email and we'll send you</h5>
          <h5>Instructions on how to reset it.</h5>
        </div>

        <div class="forgot__text forgot__text-img" *ngIf="message.status === 'error'">
          <img src="../../../../assets/img/vs_img/redcheck.png" alt="" />
          <div>
            <h5>Email address not found. Re-enter your email and we'll</h5>
            <h5>send you instructions on how to reset your password.</h5>
          </div>
        </div>

        <input class="{{ formControl.email.style }}" type="text" placeholder="Email" value="email" [(ngModel)]="email"
          (keyup)="emailKeyEvent($event)" />

        <button [ngClass]="{
            'disable-btn': disableBtn
          }" class="forgot__btn-primary" (click)="validateForm()">
          Submit
        </button>
      </div>

      <div class="forgot__message" *ngIf="message.flag && message.status !== 'error'">
        <div class="forgot__message-text">
          <div class="forgot__text">
            <h3>Email has been sent with</h3>
            <h3>password reset instructions</h3>
          </div>
          <hr />
          <div class="forgot__text forgot__text-with-img">
            <img src="../../../../assets/img/vs_img/greencheck.png" alt="" />

            <h5>Link will expire in 24 hours</h5>
          </div>
        </div>

        <button class="forgot__btn-primary" routerLink="/login">
          Return to login
        </button>
      </div>

      <div class="forgot__links">
        <a href="{{ links.terms }}" target="_blank">
          {{ dTerms }}
        </a>
      </div>
    </div>
  </div>
</div>