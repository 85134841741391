<div class="teams-past-meetings__radio-button-and-search">

  <div class="teams-past-meetings__search">
    <div class="teams-past-meetings__icon">
      <i class="fa fa-search"></i>
    </div>
    <input type="text" class="search-default" type="text" placeholder="" [(ngModel)]="searchKey" />
  </div>
</div>

<div class="teams-past-meetings">

  <div style="overflow: auto; height: 350px; width: 90%;">
    <table *ngIf="!shouldShowParticipants" class="table-1">
      <thead>
        <tr class="black">
          <th>
            <div style="display: flex;">
              Meeting or call ID
              <!-- <div style="cursor: pointer; margin-left: 5px;" (click)="sortId()">
                <i class="fa {{ sortFLag ? 'fa-arrow-up' : 'fa-arrow-down' }}"></i>
              </div> -->
            </div>
          </th>
          <th>External Call</th>
          <th>
            <div style="display: flex;">
              Start time
              <div style="cursor: pointer; margin-left: 5px;" (click)="sortStamp()">
                <i class="fa {{ sortFLag ? 'fa-arrow-up' : 'fa-arrow-down' }}"></i>
              </div>
            </div>
          </th>
          <th>When</th>
          <th>Participants</th>
          <th>Duration</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of teamsMeetings | filterTeamsMeetings: searchKey:'meetings'; let i = index">
          <td>
            <div class="teams-past-meetings__clickable" (click)="getTeamMeetingDetails(item.uuid)">
              {{ item.uuid }}
            </div>
          </td>
          <td>0</td>
          <td>{{ convertTimeStamp(item.start_time) }}</td>
          <td>{{ convertTimeDifference(item.start_time) }}</td>
          <td>{{ item.participant_count }}</td>
          <td>{{ convertSeconds(item.duration) }}</td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="shouldShowLoader" class="teams-past-meetings__loader-container">
      <div class="teams-past-meetings__loader">
        <img src="{{loader}}" alt="">
      </div>
    </div>

    <!-- 2nd page -->
    <div *ngIf="shouldShowParticipants" class="teams-past-meetings__participant-page">
      <div class="teams-past-meetings__back-button-container">
        <div class="teams-past-meetings__button-item teams-past-meetings__button-item--back"
          (click)="displayMeetingList()">
          Back
        </div>
      </div>
      <!-- if peer to peer show this -->
      <div class="teams-past-meetings__participants-head-container" style="display: flex;">
        <div>
          <div class="teams-past-meetings__button-item-container">
            <div class="teams-past-meetings__button-item {{deviceClass}}" (click)="selectButton('device')">
              Device
            </div>
            <div class="teams-past-meetings__button-item {{systemClass}}" (click)="selectButton('system')">
              System
            </div>
            <div class="teams-past-meetings__button-item {{connectivityClass}}" (click)="selectButton('connectivity')">
              Connectivity
            </div>
            <div class="teams-past-meetings__button-item {{networkClass}}" (click)="selectButton('network')">
              Network
            </div>
            <div class="teams-past-meetings__button-item {{participantClass}}" (click)="selectButton('participants')">
              Participants
            </div>
          </div>
        </div>
      </div>
      <!-- Body -->
      <div style="margin-top: 20px;" class="teams-past-meetings__participants-body-container">

        <div *ngIf="panelToShow == 'participants'" class="teams-past-meetings__device">
          <table class="table-2">
            <thead>
              <tr>
                <th>Participant</th>
                <th>Session Type</th>
                <th>Duration</th>
                <th>Audio Quality</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of meetingDetails?.participants; let i = index">
                <td>
                  <div (click)="getCurrentParticipant(item)"
                    class="teams-past-meetings__participants-clickable {{checkActiveParticipant(item?.displayName)}}">
                    {{ checkForUnavailable(item?.displayName) }}
                  </div>
                </td>
                <td>{{checkForModalities(item?.modalities)}}</td>
                <td>Data isn't available</td>
                <td>Data isn't available</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="panelToShow == 'device'" class="teams-past-meetings__device">
          <table class="table-2">
            <thead>
              <tr>
                <th>Microphone</th>
                <th>Speaker</th>
                <th>Camera</th>
                <th>Display</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ checkForUnavailable(currentParticipant.microphone) }}
                </td>
                <td>{{ checkForUnavailable(currentParticipant.speaker) }}</td>
                <td>{{ checkForUnavailable(currentParticipant.camera) }}</td>
                <td>{{ checkForUnavailable(currentParticipant.camera) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="panelToShow == 'system'" class="teams-past-meetings__system">
          <table class="table-2">
            <thead>
              <tr>
                <th>System</th>
                <th>Operating System</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ checkForUnavailable(currentParticipant.camera) }}</td>
                <td>{{ checkForUnavailable(currentParticipant.userOS) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="panelToShow == 'connectivity'" class="teams-past-meetings__connectivity">
          <div class="teams-past-meetings__connectivity-header">
            <h5>Connectivity</h5>
          </div>
          <table class="table-2">
            <thead>
              <tr>
                <th>Connection Type</th>
                <th>Driver Description</th>
                <th>Driver Version</th>
                <th>Signal Strength</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ getStreams(mainAudioConnectivity,'connectionType', 'caller') }}</td>
                <td>{{ getStreams(mainAudioConnectivity,'driverDescription', 'caller') }}</td>
                <td>{{ getStreams(mainAudioConnectivity,'driverVersion', 'caller') }}</td>
                <td>{{ getStreams(mainAudioConnectivity,'signalStrength', 'caller') }}</td>
              </tr>
            </tbody>
          </table>

          <div *ngIf="isArrayEmpty(appSharingConnectivity)" class="teams-past-meetings__connectivity-header">
            <h5>Connectivity (desktop sharing)</h5>
          </div>
          <table *ngIf="isArrayEmpty(appSharingConnectivity)" class="table-2">
            <thead>
              <tr>
                <th>Connection Type</th>
                <th>Driver Description</th>
                <th>Driver Version</th>
                <th>Signal Strength</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ getStreams(appSharingConnectivity,'connectionType', 'caller') }}</td>
                <td>{{ getStreams(appSharingConnectivity,'driverDescription', 'caller') }}</td>
                <td>{{ getStreams(appSharingConnectivity,'driverVersion', 'caller') }}</td>
                <td>{{ getStreams(appSharingConnectivity,'signalStrength', 'caller') }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="panelToShow == 'network'" class="teams-past-meetings__network">

          <!-- MAIN AUDIO DATA -->
          <div *ngIf="isArrayEmpty(mainAudioStream) || isArrayEmpty(desktopSharingStream)">
            <div *ngIf="isArrayEmpty(mainAudioStream[0])">
              <div class="teams-past-meetings__network-header">
                <h5>{{ checkMainAudioLabel(currentParticipant.displayName, meetingDetails.type )}}</h5>
              </div>
              <table class="table-2">
                <thead>
                  <tr>
                    <th>Avg Round-Trip</th>
                    <th>Max RTT</th>
                    <th>Avg Jitter</th>
                    <th>Max Jitter</th>
                    <th>Avg Packet Loss</th>
                    <th>Max Packet Loss</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ getStreams(mainAudioStream[0],'avgRoundTrip', 'caller') }}</td>
                    <td>{{ getStreams(mainAudioStream[0],'maxRtt' , 'caller') }}</td>
                    <td>{{ getStreams(mainAudioStream[0],'avgJitter' , 'caller') }}</td>
                    <td>{{ getStreams(mainAudioStream[0],'maxJitter' , 'caller') }}</td>
                    <td>{{ getStreams(mainAudioStream[0],'avgPacketLoss' , 'caller') }}</td>
                    <td>{{ getStreams(mainAudioStream[0],'maxPacketLoss' , 'caller') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- MAIN AUDIO ADDITIONAL DATA -->
            <div *ngIf="isArrayEmpty(mainAudioStream[1])">
              <div class="teams-past-meetings__network-header">
                <h5>{{ checkMainAudioLabel(currentParticipant.displayName, meetingDetails.type, 'service' )}}</h5>
              </div>
              <table class="table-2">
                <thead>
                  <tr>
                    <th>Avg Round-Trip</th>
                    <th>Max RTT</th>
                    <th>Avg Jitter</th>
                    <th>Max Jitter</th>
                    <th>Avg Packet Loss</th>
                    <th>Max Packet Loss</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ getStreams(mainAudioStream[1],'avgRoundTrip', 'caller') }}</td>
                    <td>{{ getStreams(mainAudioStream[1],'maxRtt' , 'caller') }}</td>
                    <td>{{ getStreams(mainAudioStream[1],'avgJitter' , 'caller') }}</td>
                    <td>{{ getStreams(mainAudioStream[1],'maxJitter' , 'caller') }}</td>
                    <td>{{ getStreams(mainAudioStream[1],'avgPacketLoss' , 'caller') }}</td>
                    <td>{{ getStreams(mainAudioStream[1],'maxPacketLoss' , 'caller') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- DESKTOP SHARING DATA -->
            <div *ngIf="isArrayEmpty(desktopSharingStream[0])">
              <div class="teams-past-meetings__network-header">
                <h5>{{ checkDesktopSharingStream(currentParticipant.displayName, meetingDetails.type )}}</h5>
              </div>
              <table class="table-2">
                <thead>
                  <tr>
                    <th>Avg Round-Trip</th>
                    <th>Max RTT</th>
                    <th>Avg Jitter</th>
                    <th>Max Jitter</th>
                    <th>Avg Packet Loss</th>
                    <th>Max Packet Loss</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ getStreams(desktopSharingStream[0],'avgRoundTrip', 'caller') }}</td>
                    <td>{{ getStreams(desktopSharingStream[0],'maxRtt' , 'caller') }}</td>
                    <td>{{ getStreams(desktopSharingStream[0],'avgJitter' , 'caller') }}</td>
                    <td>{{ getStreams(desktopSharingStream[0],'maxJitter' , 'caller') }}</td>
                    <td>{{ getStreams(desktopSharingStream[0],'avgPacketLoss' , 'caller') }}</td>
                    <td>{{ getStreams(desktopSharingStream[0],'maxPacketLoss' , 'caller') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- DESKTOP SHARING ADDITIONAL DATA -->
            <div *ngIf="isArrayEmpty(desktopSharingStream[1])">
              <div class="teams-past-meetings__network-header">
                <h5>{{ checkDesktopSharingStream(currentParticipant.displayName, meetingDetails.type , 'service')}}</h5>
              </div>
              <table class="table-2">
                <thead>
                  <tr>
                    <th>Avg Round-Trip</th>
                    <th>Max RTT</th>
                    <th>Avg Jitter</th>
                    <th>Max Jitter</th>
                    <th>Avg Packet Loss</th>
                    <th>Max Packet Loss</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ getStreams(desktopSharingStream[1],'avgRoundTrip', 'caller') }}</td>
                    <td>{{ getStreams(desktopSharingStream[1],'maxRtt' , 'caller') }}</td>
                    <td>{{ getStreams(desktopSharingStream[1],'avgJitter' , 'caller') }}</td>
                    <td>{{ getStreams(desktopSharingStream[1],'maxJitter' , 'caller') }}</td>
                    <td>{{ getStreams(desktopSharingStream[1],'avgPacketLoss' , 'caller') }}</td>
                    <td>{{ getStreams(desktopSharingStream[1],'maxPacketLoss' , 'caller') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

        </div>

        <div *ngIf="shouldShowLoader2" class="teams-past-meetings__loader-container">
          <div class="teams-past-meetings__loader">
            <img src="{{loader}}" alt="">
          </div>
        </div>
      </div>