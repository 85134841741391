<div class="row pt-2 pb-2 right-panel">
  <div class="col-12">
    <app-panel-tools [PopupTitle]="PopupTitle"></app-panel-tools>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-6 pl-1">
        <app-audio-all-devices-info [audioDeviceSummary]="audiositesInfo"></app-audio-all-devices-info>
      </div>
      <div class="col-6">
        <div class="col-12 px-0">
          <app-system-health [healthNum]="audioDeviceSummary?.health"></app-system-health>
        </div>
        <app-audio-all-devices-stats [audioDeviceData]="audioDeviceSummary"></app-audio-all-devices-stats>
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-1 filter-table">
        <app-audio-all-devices-list [audioDevicesListData]="audioDevicesListData"></app-audio-all-devices-list>
      </div>
    </div>
  </div>
</div>