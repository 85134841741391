import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

import { environment } from '../../../../../environments/environment'
import { PopupTypeService } from '../../../../services/popupType.service'

@Component({
  selector: 'app-cloud-user-device-health',
  templateUrl: './cloud-user-device-health.component.html',
  styleUrls: ['./cloud-user-device-health.component.scss'],
})
export class CloudUserDeviceHealthComponent implements OnInit, OnChanges {
  @Input() cloudUserStats: any;
  isZoomPluginEnabled: boolean = false;
  deskPlugin: String = environment.config.dynamicText.deskPlugin;
  desktopPluginMessage: string;
  deskPluginStatus: number;
  deskPluginTextStatus: string;
  isDesktopPluginIntegrated: boolean = false;
  constructor(public ds: PopupTypeService) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cloudUserStats && this.cloudUserStats) {
    
      this.isZoomPluginEnabled = this.cloudUserStats.zoom_plugin_enabled == 1;
      this.isDesktopPluginIntegrated = this.cloudUserStats.plugin_id !== null;
      // desktop plugin display message
      this.desktopPluginMessage = this.getDesktopPluginMessage();

      // Plugin status
      this.deskPluginStatus = this.cloudUserStats.plugin_status;
      this.deskPluginTextStatus = this.getDesktopPluginTextStatus();
    }
  }

  getDesktopPluginMessage = () => {
    if (this.cloudUserStats.plugin_id == null) {
      return `To enable advance features, please download and install the ${this.deskPlugin}.`;
    }
    return '';
  };

  getDesktopPluginTextStatus = () => {
    if (this.cloudUserStats) {
      if (!this.isDesktopPluginIntegrated || !this.isZoomPluginEnabled) {
        return `Disabled`;
      }
      if (this.isZoomPluginEnabled) {
        if (this.deskPluginStatus == 1) {
          return 'Online';
        } else {
          return 'Offline';
        }
      }
      return 'Offine';
    }
    return '';
  };

  displayDisableMessage() {
    this.ds.sendPopupType(59, { data: this.desktopPluginMessage });
  }

  displayPluginInfo() {
    this.ds.sendPopupType(60, { data: this.cloudUserStats });
  }
}
