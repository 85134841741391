import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  email: any;
  message: any = { text: 'Email address not found', flag: false, status: 'success' };
  loginEnv: any;
  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.setData();
  }

  setData = () => {
    if (environment.config.title === 'VisibilityOne'
      || environment.config.title === 'vCare'
      || environment.config.title === 'Vision Point'
      || environment.config.title === 'Videlio'
      || environment.config.title === 'DEKOM') {
      this.loginEnv = true
    } else {
      this.loginEnv = false;
    }
  }

  resetPassword = (email) => {
    this.Auth.sendPasswordReset(email).subscribe((data: any) => {
      // console.log('sendPassword: ', data);
      const [result] = data.response;
      this.setResponseMessage(result);
    });
  };

  forgotEmitEvent = ({ email }) => {
    this.resetPassword(email);
  };

  setResponseMessage = ({ message, status }) => {
    if (message) {
      this.message = {
        text: message,
        flag: true,
        status: status === 'Error' ? 'error' : 'success',
      };
    }
  };
}
