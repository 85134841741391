<div class="collectorOfflineNotifications" *ngIf="!notification.isClose">
  <div class="row">
    <div class="col-12 collector-status">
      <p>
        {{ notification.message.replace('Collector', dCollector) }}
        <span *ngIf="lastUpTime">Last uptime {{ lastUpTime | dateTimeZone : 'LLLL' }}</span>
      </p>
      <img class="collector-status__close-btn" src="./assets/img/white_close.png" (click)="removeItem($event)" />
    </div>
  </div>
</div>