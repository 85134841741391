<div class="cloud-user-utilization">
  <h5>Utilization</h5>



  <div *ngIf="!spaceStatus" class="cloud-user-utilization__two-column">

    <div class="cloud-user-utilization__call-this-month"
      (click)="ds.sendPopupType(64, { deviceId: cloudUserUtilization?.cloudUserId })">
      <div class="cloud-user-live-stats__wifi-ssid-text"><strong>{{teamsMeetings ? teamsMeetings : '--'}}</strong></div>
      <div>Total Calls Since Activation</div>
    </div>
  </div>


  <div *ngIf="spaceStatus" class="cloud-user-utilization__two-column">

    <div class="cloud-user-utilization__call-this-month"
      (click)="ds.sendPopupType(78, { deviceId: cloudUserUtilization?.cloudUserId })">
      <div class="cloud-user-live-stats__wifi-ssid-text"><strong>{{teamsMeetings ? teamsMeetings : '--'}}</strong></div>
      <div>Total Calls History</div>
    </div>
  </div>

  <!-- <div class="cloud-user-utilization">
  <h5>Utilization</h5>
  <div class="cloud-user-utilization__two-column"> -->
  <!-- <app-btn-reg-stats
      [count]="cloudUserUtilization?.totalMeetings"
      label="Total Calls This Month"
      color="gray"
      (click)="
        ds.sendPopupType(
          39,
          {
            deviceId: cloudUserUtilization.cloudUserId,
            meetings: cloudUserUtilization?.past_meetings
          },
          hasAccess
        )
      "
      [isbutton]="isNotButton"
    ></app-btn-reg-stats> -->

  <!-- <div class="cloud-user-utilization__call-this-month"
      (click)="ds.sendPopupType(64, { deviceId: cloudUserUtilization?.cloudUserId })">
      <div class="cloud-user-live-stats__wifi-ssid-text"><strong>{{teamsMeetings ? teamsMeetings : '--'}}</strong></div>
      <div>Total Calls Since Activation</div>
    </div>
  </div>
</div> -->