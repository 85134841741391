<button type="button" class="not-button btn-long d-flex py-2 zoom-micrphone">
  <!-- <div class="zoom-micrphone_image" (click)="ds.sendPopupType(46, { data: data })">
    <img src="{{ icon }}" (mouseenter)="iconOnHover()" (mouseleave)="iconOnLeave()" />
  </div> -->

  <div class="zoom-micrphone_image">
    <img src="{{ icon }}" />
  </div>

  <div class="zoom-micrphone_details">
    <div class="zoom-micrphone_mute">
      Primary
      <img src="{{ getStatusIcon(inuseMic) }}" />
    </div>
    <div class="zoom-micrphone_device-name">
      <span (click)="ds.sendPopupType(48, { data: data })">
        {{ inuseMic.name }}
      </span>
    </div>
  </div>
</button>