<div class="teams-user-info">
  <div class="teams-user-info__zoom-img">
    <img src="../../assets/img/vs_img/teams_logo.png" />
  </div>

  <div class="teams-user-info__user-img">
    <img [src]="getUserProfilePicture('test')" alt="Cloud User" />
  </div>
  <div class="teams-user-info__text">
    <div class="teams-user-info__text-item">
      <strong>User:</strong> {{ teamsDetails?.firstname }} {{ teamsDetails?.lastname }}
    </div>
    <div class="teams-user-info__text-item">
      <strong>Email:</strong> {{ teamsDetails?.email | displayEmpty }}
    </div>
    <div class="teams-user-info__text-item">
      <strong>Dial in Details:</strong> {{ teamsDetails?.personal_meeting_url | displayEmpty }}
    </div>
  </div>

  <div class="teams-user-info__button-container">
    <div class="teams-user-info__switch">
      <label class="switch" [ngStyle]="{ 'opacity': isDisableDesktopClientPluginToggle ? '0.5' : '1' }">
        <input type="checkbox" (change)="desktopClientChange($event)" [checked]="teamsDetails?.zoom_plugin_enabled"
          [disabled]="isDisableDesktopClientPluginToggle" />
        <span class="slider round"></span>
      </label>
      <div class="teams-user-info__switch-text">enable desktop client</div>
    </div>


    <div class="teams-user-info__switch">
      <label class="switch" [ngStyle]="{'opacity': isDisableAlertsToggle ? '0.5' : '1' }">
        <input type="checkbox" (change)="alertsChange($event)" [checked]="teamsDetails?.alerts"
          [disabled]="isDisableAlertsToggle" />
        <span class="slider round"></span>
      </label>
      <div class="teams-user-info__switch-text">enable alerts</div>
    </div>


    <!-- Will disable later -->
    <div class="teams-user-info__gray-button-container">
      <app-btn-info label="admin" [faName]="faIcon" class="disable" (click)="ds.sendPopupType(66)"></app-btn-info>
      <app-btn-info label="reboot" [faName]="faIcon" (click)="openRebootPopUp()"></app-btn-info>
    </div>


    <!-- temp solution -->
    <div class="teams-user-info__button--" [ngClass]="{
        'teams-user-info__button-online': userStatus === 'Online',
        'teams-user-info__button-offline': userStatus === 'Offline'
      }">
      {{ userStatus }}
    </div>

    <div class="teams-user-info__network-utilization">
      <h5>Network Utilization</h5>
      <hr />
      <app-chart-teams-mbps [teamsDetails]="teamsDetails"></app-chart-teams-mbps>
    </div>
  </div>

  <div class="teams-user-info-outer">
    <app-cloud-user-console [isDisable]="!isZoomPluginEnabled || deskPluginStatus !== 1"
      [pluginId]="teamsDetails?.plugin_id" [desktopPluginMessage]="desktopPluginMessage"
      [originationIp]="teamsDetails?.plugin_ip_address">
    </app-cloud-user-console>
    <div class="teams-user-info-outer__additional">
      <h4>Additional</h4>
      <div><strong>Timezone:</strong> {{ teamsDetails?.timezone | displayEmpty }}</div>
    </div>
  </div>