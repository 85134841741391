<div class="reports-video-conf-devices">

  <!-- <div class="reports-video-conf-devices__image-text">
    <img src="../../../../assets/img/zoom_logo.png@1X.png" *ngIf="videoConferSite.rooms_per_device_type.zoom>0">
    <h3 *ngIf="videoConferSite.rooms_per_device_type.zoom>0">{{videoConferSite.total_huddle_rooms}} Rooms</h3>

  </div> -->

  <div class="reports-video-conf-devices__image-text">
    <img src="../../../../assets/img/polycom_logo.png@1X.png" *ngIf="videoConferSite.rooms_per_device_type.polycom>0">
    <h3 *ngIf="videoConferSite.rooms_per_device_type.polycom>0">{{videoConferSite.rooms_per_device_type.polycom}} Rooms
    </h3>

  </div>

  <div class="reports-video-conf-devices__image-text">
    <img src="../../../../assets/img/cisco_logo.png@1X.png" *ngIf="videoConferSite.rooms_per_device_type.cisco>0">
    <h3 *ngIf="videoConferSite.rooms_per_device_type.cisco>0">{{videoConferSite.rooms_per_device_type.cisco}} Rooms</h3>

  </div>


  <div class="reports-video-conf-devices__image-text">
    <img src="../../../../assets/img/logitechh.png" *ngIf="videoConferSite.rooms_per_device_type.cisco>0">
    <h3 *ngIf="videoConferSite.rooms_per_device_type.cisco>0">{{videoConferSite.rooms_per_device_type.cisco}} Rooms</h3>

  </div>

</div>