import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { first } from 'rxjs-compat/operator/first';
import { GetTeamsService } from 'src/app/services/get-teams.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teams-spaces-management-service',
  templateUrl: './teams-spaces-management-service.component.html',
  styleUrls: ['./teams-spaces-management-service.component.scss']
})
export class TeamsSpacesManagementServiceComponent implements OnInit {


  shouldShowMsg: boolean = false;
  shouldShowUserMsg: boolean = false;
  msg: string = 'Teams Room is successfully added.';
  msgUser: string = 'Teams User is successfully added.';
  msgColor: string = ' ';
  msgUserColor: string = '';
  setTimer: any;
  isAddingRoom: boolean = false;
  isAddingUser: boolean = false;
  btnLoader: string = environment.config.dynamicImage.loader;

  toggleRoomOrUser: string = 'Room';

  add_user_form: FormGroup;
  add_rooms_form: FormGroup;
  constructor(private getTeams: GetTeamsService, fb: FormBuilder) {
    this.add_user_form = fb.group({
      'firstName': [null, Validators.required],
      'lastName': [null, Validators.required],
      // 'email': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      'email': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12}))$/)])],
    });

    this.add_rooms_form = fb.group({
      // 'bookingEmail': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      'bookingEmail': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12}))$/)])],
      'teamsRoomName': [null, Validators.required],
    });
  }

  ngOnInit() {
  }

  msgConfig = (text, color) => {
    if (this.toggleRoomOrUser == 'Room') {
      this.shouldShowMsg = true;
      this.msg = text;
      this.msgColor = color;
      this.setTimer = setTimeout(() => {
        this.shouldShowMsg = false;
        this.clearTimeout();
      }, 5000);
    } else if (this.toggleRoomOrUser == 'User') {
      this.shouldShowUserMsg = true;
      this.msgUser = text;
      this.msgUserColor = color;
      this.setTimer = setTimeout(() => {
        this.shouldShowUserMsg = false;
        this.clearTimeout();
      }, 5000);
    }
  };

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

  manualAddTeamsRoom = () => {
    this.markFormTouched(this.add_rooms_form);
    if (this.add_rooms_form.valid) {

      const { bookingEmail, teamsRoomName } = this.add_rooms_form.value;
      this.isAddingRoom = true;
      this.getTeams.insertTeamsRoom(bookingEmail, teamsRoomName).subscribe(
        (data: any) => {
          this.isAddingRoom = false;
          if(data.status!='success'){
            this.msgConfig(data.status, 'teams-spaces-service__success-msg--red');
          }
          else {
            this.add_rooms_form.reset()
            this.msgConfig('Teams Room is successfully added.', '');
          }
        },
        (err) => {
          this.isAddingRoom = false;
          this.msgConfig('Something went wrong in adding Teams Room', 'teams-spaces-service__success-msg--red');
        }
      );

    }

  };

  manualAddTeamsUser = () => {
    this.markFormTouched(this.add_user_form);
    if (this.add_user_form.valid) {

      const { firstName, lastName, email } = this.add_user_form.value;

      this.isAddingUser = true;
      this.getTeams.insertTeamsUserManual(firstName, lastName, email).subscribe(
        (data: any) => {
          this.isAddingUser = false;
          this.msgConfig('Teams User is successfully added.', '');
        },
        (err) => {
          this.isAddingUser = false;
          this.msgConfig(err?.error ?? 'Something went wrong in adding Teams User', 'teams-spaces-service__success-msg--red');
        }
      );

    }
  };

  resetForm = () => {
    this.add_user_form.reset();
    this.add_rooms_form.reset();
  };


  toggleTypeToBeAdded = () => {
    this.toggleRoomOrUser = this.toggleRoomOrUser == 'Room' ? 'User' : 'Room';
    this.resetForm();
  };


  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) { control.markAsTouched(); this.markFormTouched(control); }
      else { control.markAsTouched(); };
    });
  };

}
