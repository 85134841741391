import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iot-device-console',
  templateUrl: './iot-device-console.component.html',
  styleUrls: ['./iot-device-console.component.scss'],
})
export class IotDeviceConsoleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
