import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

import { PopupTypeService } from '../../services/popupType.service'

@Component({
  selector: 'app-video-historic-ping',
  templateUrl: './video-historic-ping.component.html',
  styleUrls: ['./video-historic-ping.component.scss']
})
export class VideoHistoricPingComponent implements OnInit, OnChanges {
  @Input() iotDeviceDetails: any;
  @Input() worstRtd: any = '--';
  @Input() medianRtd: any = '--';
  @Input() bestRtd: any = '--';
  @Input() packetLossRtd: any = '--';
  
  constructor(private ds: PopupTypeService) {}


  ngOnInit(): void {
  }
  
  	ngOnChanges(changes: SimpleChanges) {
		if (changes.iotDeviceDetails && this.iotDeviceDetails) {
			// console.log('videoDeviceData last call: ', this.videoDeviceData)
			this.setData()
		}
	}
	
	setData = () => {
	
	}
	
	
	  openHistoricPingStatusChart = (viewType) => {
    const data = {
      iotDeviceID: this.iotDeviceDetails.iot_device_id,
      agentId: this.iotDeviceDetails.agent_id,
      accountUrl: this.iotDeviceDetails.account_url,
      apiKey: this.iotDeviceDetails.api_key,
      paused: this.iotDeviceDetails.paused,
      dateRange: 1,
      viewType,
    };
    this.ds.sendPopupType(57, {
      data,
    });
  };


}
