import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RefreshApiService } from './../../services/refresh-api.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-audio-device-call-history',
  templateUrl: './audio-device-call-history.component.html',
  styleUrls: ['./audio-device-call-history.component.scss']
})
export class AudioDeviceCallHistoryComponent implements OnInit, OnChanges {
  filterListner: Subscription;
  @Input() popData: any;
  @Input() popupState: any;
  heading = "";
  beingFetched = false;
  callData: any = [];
  constructor(
    private Auth: AuthService,
    private refreshApiService: RefreshApiService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.popupState.currentValue == 'open') {
      let isFirstLoad = true;
      if(this.popData != undefined){
        if (this.popData.audio_device_id == null) {
        } else {
          this.getData();
          this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
            if(m && !isFirstLoad){
              this.getData();
            }
            isFirstLoad = false;
          });
        }
      }
    } else {
      this.filterListner.unsubscribe();
      setTimeout(() => {
        this.callData = [];
      }, 500);
    }
  }

  // printTime(t) {
  //   if (t== null) {
  //     return "--";
  //   } else {
  //     return moment(t).utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma');
  //   }
  // }

  printDuration(seconds){
    seconds = Number(seconds);
    let h = Math.floor(seconds / 3600);
    let m = Math.floor(seconds % 3600 / 60);
    let s = Math.floor(seconds % 3600 % 60);

    let hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
    let mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
    let sDisplay = s > 0 ? s + (s == 1 ? " sec " : " secs ") : "";
    return hDisplay + mDisplay + sDisplay; 
  }

  // printEndTime(t) {
  //   let a = moment.unix(t)

  //   return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mma');
  // }

  async getData(){
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.heading ="30 Days Call Log"
    try {
      this.beingFetched = true;
      const data: any = await this.Auth.getAudioDeviceCallLogs({ 'company_id': decodedToken.company_id, 'device_id': this.popData.audio_device_id }).toPromise();
      console.log("Call data " , data )
      this.callData = data.response;
    } catch (error) {
      console.log("An error occured" , error )
    } finally {
      this.beingFetched = false;
    }
  }

  ngOnInit() {}

  getSip(address:string):string {
    let sip = '--';
    if(address.length === 0){
      return sip;
    }

    const searhKey = 'sip:';
    const position = address.search(searhKey);
    if(position >= 0){
      const startIndex = position + searhKey.length;
      return  address.substring(startIndex).split(";")[0];
    }

    return address;
  }
}
