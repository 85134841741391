import * as moment from 'moment-timezone'
import { Subscription } from 'rxjs/Subscription'

import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'
import { GetCloudService } from '../../services/get-cloud.service'
import { GetDesktopPluginService } from '../../services/get-desktop-plugin.service'
import { GetIotService } from '../../services/get-iot.service'
import { VisoneSiteService } from '../../services/get-visone-installedsites.service'
import { MembersTypeService } from '../../services/membersType.service'
import { PopupSendDataService } from '../../services/popup-send-data.service'
import { PopupTypeService } from '../../services/popupType.service'

@Component({
  selector: 'app-visone-popup',
  templateUrl: './visone-popup.component.html',
  styleUrls: ['./visone-popup.component.scss'],
})
export class VisonePopupComponent implements OnInit, OnChanges {
  subscription: Subscription;
  popupType: number;
  siteTutorial: string;
  allsite$;

  @Input() runTutorial: any;
  @Input() keyDown: any;
  @Input() isShowCollectorInfo: any;
  @Input() showScheduleModal: any;
  @Input() desktopClientDownloadLink: any;
  @Output() isShowCollectorInfoChange: EventEmitter<any> =
    new EventEmitter<any>();
  allSitesAndDataCollector: number = 0;
  allRoomsPlugin: number = 0;
  allDesktopClients: number = 0;
  allSiteListData: any = [];
  siteList: any = [];
  desktopClientList: any = [];
  roomPluginList: any = [];
  siteSummary: any = [];
  siteSummaryLoading: boolean = false;
  siteDetailsLoading: boolean = false;
  siteListCopy: any = [];
  reloadSiteApiData: any = 0;
  reloadPopup: any = 0;
  getTutorial: any;
  setTutorial: any;
  setTutDownload: any;
  tut3: any;
  links = environment.config.links;
  compId: number;
  accessLevel: string;
  isShowCollectorEdit: boolean;
  dCollector = environment.config.dynamicText.dCollector;
  dPlugin = environment.config.dynamicText.dPlugin;
  popUpCollectorData: any;
  activePanel = 0;
  activeMenu = 0;
  isEdit: number = 0;
  showStatConfirmation: Boolean = false;
  showPluginConfirmation: Boolean = false;
  showPluginConfirmationError: Boolean = false;
  cid = 0;
  sid = 0;
  site_name;
  isDeleteButtonPressed;
  collectorInfo = [];
  cSiteName = '';
  cHostName = '';
  cVersion = '';
  lastlogin = '';
  memTotal: any;
  memUsed: any;
  memorystatus: any;
  cpu = 0;
  hdddtls = [];
  collectorLatestVersion: any;
  collectorVersionData: any;
  collectorParams: any;
  zoomRoom: any;
  zoomRoomName = '';
  confirmationInput: any = '';
  shouldShowCollectorsWithPlugin: boolean = false;
  siteApiDataCopy: any;
  zoomRoomDetails: any = [];
  unlinkedIotDevice: any = 0;
  unassignedPluginRoom: any;
  domotzEnabled: boolean = false;

  //---- collector
  //---- roomPlugin
  // ---- desktopPlugin
  tableSelected: any = 'collector';
  searchKey: any;
  showUpdateCenter: Boolean = false;
  componentName: any = 'vsone';

  constructor(
    private ds: PopupTypeService,
    private dsM: MembersTypeService,
    private route: ActivatedRoute,
    private allsitesService: VisoneSiteService,
    private sendPopupDataService: PopupSendDataService,
    public http: HttpClient,
    private Auth: AuthService,
    private router: Router,
    private getCloudUserService: GetCloudService,
    private GetIot: GetIotService,
    private desktopPlugin: GetDesktopPluginService
  ) {}

  ngOnInit() {
    localStorage.setItem('keyDownload', '0');
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.compId = decodedToken.company_id;
    this.accessLevel = decodedToken.accesslevel;
    this.isShowCollectorEdit = this.hasAccess();
    if (token) {
      this.getDomotzAccountByCompanyId();
      this.getUnlinkedIotDevice();
      this.getListCollector();
      this.getListPlugin();
      this.getDesktopPluginList();
    }
  }

  getDesktopPluginList = () => {
    this.desktopPlugin.getDesktopPluginList().subscribe((data: any) => {
      this.desktopClientList = data;
      this.allDesktopClients = this.desktopClientList.length;
      //   // console.log(this.desktopClientList, 'desktopClientList')
    });
  };

  getListCollector = async () => {
    this.Auth.getSiteListCollector().subscribe(async (data) => {
      this.siteList = data.response.map((e) => {
        const wellness_check_time = this.formatTime(e.wellness_check_time);
        return { ...e, wellness_check_time };
      });

      this.allSitesAndDataCollector = this.siteList.length;
      const siteIds = data.response.map((e) => e.site_id);

      this.siteDetailsLoading = true;
      this.Auth.getAllSitesDetailsCollector(siteIds).subscribe(async (o) => {
        const details = o.response;
        this.siteList = data.response.map((e) => {
          const x = details[String(e.site_id)];
          return Object.assign({}, e, x);
        });
        this.siteDetailsLoading = false;
      });
    });

    this.siteSummaryLoading = true;
    this.Auth.getSitesSummaryCollector().subscribe((data) => {
      this.siteSummary = data.response;
      this.siteSummaryLoading = false;
    });
  };

  getListPlugin = async (filter = 'all') => {
    this.Auth.getListPlugin(filter).subscribe((data: any) => {
      this.roomPluginList = data.response;
      this.allRoomsPlugin = this.roomPluginList.length;
      // console.log('roomPluginList: ', this.roomPluginList)
    });
  };

  sendPopupType = (emiterEvent) => {
    if (
      emiterEvent === 'allPlugin' ||
      emiterEvent === 'unassigned' ||
      emiterEvent === 'assigned'
    ) {
      this.getListPlugin(emiterEvent);
    }

    if (emiterEvent === 'collectorList') {
      // console.log('getListCollector');
      this.getListCollector();
    }

    if (emiterEvent === 'roomPluginList') {
      this.getListPlugin();
    }

    if (emiterEvent === 'desktopClientList') {
      this.getDesktopPluginList();
    }
    if (emiterEvent === 20 || emiterEvent === 21 || emiterEvent == 11) {
      this.ds.sendPopupType(emiterEvent, (params) => {
        this.popUpEvent(params);
      });
    } else {
      this.ds.sendPopupType(emiterEvent);
    }
  };

  objectEmitter = (params: any) => {
    const { eventEmitter } = params;
    if (eventEmitter === 'showCollectorInfo') {
      this.toggleCollectorInfo(params);
    }

    if (eventEmitter === 'showScheduleModal') {
      this.openNewUpdateModal(params);
    }
  };

  openNewUpdateModal = ({ site_id, site_name, collector_update_schedule }) => {
    this.popUpCollectorData = {
      site_id,
      site_name,
      date: collector_update_schedule,
    };
    // // console.log('openNewUpdateModal');
    this.showScheduleModal = true;
    this.isShowCollectorInfoChange.emit('showScheduleModal');

    this.isShowCollectorInfo = false;
  };

  toggleCollectorInfo = ({ site_id }) => {
    this.collectorParams = {
      company_id: this.compId,
      site_id,
    };
    // // console.log('toggleCollectorInfo');

    this.showScheduleModal = false;
    this.isShowCollectorInfo = true;
    this.isShowCollectorInfoChange.emit('isShowCollectorInfo');
  };

  radioChange(event) {
    // // console.log(event);
  }

  //

  getColletorUpdateStatus = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const company_id = decodedToken.company_id;

    this.Auth.collectorCheckScheduleUpdateByCompany(company_id).subscribe(
      (data) => {
        if (data.response) {
          const { count } = data.response;
          this.showUpdateCenter = true;
        }
      }
    );
  };

  // ------------------end

  getDomotzAccountByCompanyId = () => {
    this.GetIot.getDomotzAccountByCompanyId().subscribe((data: any) => {
      this.domotzEnabled = data.enabled === 1;
    });
  };

  ngOnChanges() {
    this.getCollectorVersionData();
  }

  //moved
  setTutorialapi(n) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const tutData = {
      company_id: decodedToken.company_id,
      user_id: decodedToken.user_id,
      tutorial_download: n,
    };

    this.Auth.setTutorialDownload(tutData).subscribe((data) => {
      this.setTutorial = data['response'];
    });

    this.Auth.getTutorial(tutData).subscribe((data) => {
      this.getTutorial = data['response'];
      this.runTutorial = this.getTutorial[0].tutorial;
      this.keyDown = n;
    });
  }

  //moved
  checkDownload(n) {
    this.setTutorialapi(n);
    localStorage.setItem('keyDownload', n);
    this.siteTutorial = 'verify';
  }

  activeMenuSet(p) {
    if (this.activeMenu === p) {
      this.activeMenu = 0;
    } else {
      this.activeMenu = p;
    }
  }
  sendMemberType(p) {
    if (this.activePanel === p) {
      this.activePanel = 0;
    } else {
      this.activePanel = p;
    }
    this.dsM.sendMemberType(p);
  }

  clearPopupType() {
    this.ds.clearPopupType();
  }

  //remove?

  //remove?
  sendData(p, n) {
    this.sendPopupType(11);
    //review
    localStorage.setItem('site_id', p);
    localStorage.setItem('reloadSiteData', n);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }

  async delay(ms: number) {
    await new Promise((resolve) => setTimeout(() => resolve(''), ms));
  }

  toEdit(n) {
    this.isEdit = 1;
  }

  //
  toggleDeleteForm() {
    this.showStatConfirmation = false;
  }

  clickZoomRoom() {
    this.router.navigate(['cloud/room/', this.zoomRoom[this.zoomRoomName]]);
  }

  toggleSiteMonitor(sid, pauseState) {
    this.Auth.toggleSiteMonitor(sid, pauseState).subscribe(data => {
			// if (data.error === null) {
			// }
		})
  }

  hasAccess = () => {
    if (this.accessLevel) {
      if (this.accessLevel === 'USER') {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  closeNewUpdateModal = () => {
    this.showScheduleModal = false;
  };

  getCollectorVersionData = () => {
    this.Auth.getCollectorLatestVersion('collector').subscribe((data) => {
      if (data.response) {
        if (data.response[0].version)
          data.response[0].version = String(data.response[0].version).replace(/\.0/g, '')

        this.collectorVersionData = data.response[0];
        this.collectorLatestVersion = data.response[0].version;
      }
    });
  };

  popUpEvent = ($event) => {
    if ($event === 'refreshGetSiteApiData') {
      this.getListCollector();
      this.getListPlugin();
      this.getDesktopPluginList();
    }
  };

  calculateIotDeviceCount = (audio, video, cloud) => +audio + +video + +cloud;

  pluginCollectorSwitch = (events) => {
    this.shouldShowCollectorsWithPlugin = events;
    this.filterCollectorConnectedToPlugin(this.shouldShowCollectorsWithPlugin);
  };

  filterCollectorConnectedToPlugin = async (flag) => {
    this.siteList = [];
    const siteList = flag ? await this.getPluginInfo() : this.siteApiDataCopy;
    this.siteList = siteList;
  };

  getPluginInfo = async () => {
    const zoomRoomId = this.siteApiDataCopy
      .filter((e) => e.collector.zoom_room !== null)
      .map((e) => e.collector.zoom_room[Object.keys(e.collector.zoom_room)[0]]);

    const getcloudApiData = await Promise.all(
      zoomRoomId.map(async (e) => await this.getcloudApiData(e))
    );
    return getcloudApiData;
  };

  getcloudApiData = async (id) => {
    return new Promise((resolve) => {
      this.getCloudUserService
        .fetchApiCloudRoomDetails2(id)
        .subscribe((data: any) => {
          const [response] = data.response;
          resolve(response);
        });
    });
  };

  getPluginHostname = (items) => {
    //temp solution doesnt cause any errors for the await
    return items ? items[0].hostname : '--';
  };

  getPluginIp = (items) => {
    return items ? items[0].ipv4_addr : '--';
  };

  getPluginStatus = (pluginEnabled, pluginStatus) => {
    let statusColor = '';
    if (pluginEnabled === 0) {
      statusColor = 'status-grey';
    } else if (pluginStatus === 1) {
      statusColor = 'status-green';
    } else if (pluginStatus === -1) {
      statusColor = 'status-red';
    } else if (pluginStatus === 2) {
      statusColor = 'status-yellow';
    } else {
      statusColor = 'status-grey';
    }
    return statusColor;
  };

  getPluginConnectedDevice = (items, device) => {
    const {
      zoom_plugin_connected_devices,
      zoom_plugin_enabled,
      plugin_status,
      connected_devices,
    } = items;
    let connectedMicCount = this.isDefaultDeviceEmpty(
      connected_devices.microphone
    );
    let connectedCamCount = this.isDefaultDeviceEmpty(connected_devices.camera);
    let connectedSpeakerCount = this.isDefaultDeviceEmpty(
      connected_devices.speaker
    );
    const zoomConnectedDevices = zoom_plugin_connected_devices;

    const zoomConnectedDeviceFlag =
      this.checkIfZoomPluginHasAnyData(zoomConnectedDevices);
    const disablePluginFunction = this.checkPluginEnabledAndStatus(
      zoom_plugin_enabled,
      plugin_status
    );

    if (disablePluginFunction && zoomConnectedDeviceFlag) {
      const { audio_mic, video, audio_speaker } = zoomConnectedDevices;
      connectedMicCount = audio_mic.length;
      connectedCamCount = video.length;
      connectedSpeakerCount = audio_speaker.length;
    }

    return device === 1
      ? connectedMicCount
      : device === 2
      ? connectedCamCount
      : connectedSpeakerCount;
  };

  isDefaultDeviceEmpty = (device) => {
    if (device && device.length !== 0 && device.trim() !== '') {
      return 1;
    }
    return 0;
  };

  checkIfZoomPluginHasAnyData = (devices) => {
    if (devices == null) {
      return false;
    }
    const { audio_mic, video, audio_speaker } = devices;
    if (
      audio_mic.length === 0 &&
      video.length === 0 &&
      audio_speaker.length === 0
    ) {
      return false;
    }
    return true;
  };

  checkPluginEnabledAndStatus = (enabled, status) => {
    if (enabled === 1 && status === 1) {
      return false;
    }
    return true;
  };

  getUnlinkedIotDevice = () => {
    this.GetIot.getUnlinkedIotDevice().subscribe((data: any) => {
      this.unlinkedIotDevice = data.filter((e) => e.is_monitored === 1).length;

      const token = localStorage.getItem('token');
      const helper = new JwtHelperService();
      const { company_id } = helper.decodeToken(token);
      this.unlinkedIotDevice = data.filter(
        (e) =>
          e.is_monitored === 1 && e.company_id === company_id && e.active === 1
      ).length;
    });
  };

  getUnlinkedPluginToRoomCount = async () => {
    return new Promise((resolve) => {
      this.GetIot.getUnlinkedPluginToRoomCount().subscribe((data: any) => {
        const { zoom_rooms } = data;
        resolve(zoom_rooms);
      });
    });
  };

  formatTime = (time) => {
    if (time == null || !time) {
      return '--';
    } else {
      const convertTime = `Mon Mar 27 2023 ${time}`;
      return moment(convertTime).format('LT');
    }
  };
}
