import * as moment from 'moment-timezone'
import { Subscription } from 'rxjs'

import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../../../environments/environment'
import { EventEmitterGlobalService } from '../../../services/event-emitter-global.service'
import { GetIotService } from '../../../services/get-iot.service'
import { GetVideoService } from '../../../services/get-video.service'
import { PopupTypeService } from '../../../services/popupType.service'
import { RefreshApiService } from '../../../services/refresh-api.service'

@Component({
  selector: 'app-video-device-panel',
  templateUrl: './video-device-panel.component.html',
  styleUrls: ['./video-device-panel.component.scss'],
})
export class VideoDevicePanelComponent implements OnInit, OnDestroy {
  public videoDeviceData;
  public videoDeviceType: any = '';
  popup = {
    'logitech': 83,
    'video' : 65
  }
  PopupTitle: String = 'Video Device - ';
  id: any;
  apiTimers: any;
  refreshData: any;
  filterListner: Subscription;
  watcher: Subscription;
  two$: Subscription;
  syncTime: any;
  dataError: any;

  dCollector: String = environment.config.dynamicText.dCollector;

  showWellnessComponent: any = false;
  enableInProduction: any = environment.config.enableInProduction;
  showWellnessInProd: any = false;
  companyId: any;
  
  iotDeviceDetails: any = {};
  iotDevicePortsDetails: any;
  iotDeviceName: String = '--';
  routeId: any;
  
  monitoredPorts: any = 0;
  unmonitoredPorts: any = 0;
  
  constructor(
    private route: ActivatedRoute,
    public ds: PopupTypeService,
    private router: Router,
    private getVideoDataService: GetVideoService,
    private refreshApiService: RefreshApiService,
    private EventEmitterGlobal: EventEmitterGlobalService,
    private getIot: GetIotService,
    
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });

    this.filterListner = this.refreshApiService
      .refreshAPIListen()
      .subscribe((m: any) => {
        this.refreshData = m;
        this.getApiData();
      });

    this.watcher = this.EventEmitterGlobal.listen().subscribe(
      (data) => {
        if (data == 'callVideoApiData') {
          this.getVideoApiData();
        }
      },
      (err) => {}
    );
  }

  getApiData() {
    if (this.refreshData) {
      this.getVideoApiData();
    }
  }

  openHealthHistory() {
    const type = this.videoDeviceData?.device_type == 'logitech' ? 'logitech': 'video';
    this.ds.sendPopupType(this.popup[type], { deviceId: this.id, hType: type }); 
  }

  ngOnInit() {
    this.two$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.getVideoApiData();
      }
    });
    this.getVideoApiData();
    this.getCompanyId();
  }

  setWellnessProd = (enableInProd, companyId) => {
    return enableInProd && (companyId == 15 || companyId == 36);
  };

  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.companyId = decodedToken.company_id;
  };

  ngOnDestroy() {
    this.filterListner.unsubscribe();
    if (this.two$) this.two$.unsubscribe();
  }

  getVideoApiData() {
  
    this.getVideoDataService
      .apiGetVideoDeviceDetails(this.id)
      .subscribe((data: any) => {
        const [newData] = data.response
        

        if (newData.device_type == 'logitech') {
          const logitechData =  JSON.parse(newData.logitech_data)
        
          this.videoDeviceData = {
            ...newData,
            firmwares: JSON.parse(newData.firmwares),
            logitech_data: logitechData,
          }
          
          if ( logitechData != null ) {
            console.log('logitechData: ', logitechData)
            const { iot_id } = logitechData;
            if ( iot_id && iot_id != null) {
              this.getIotDeviceDetails(iot_id)
            }
          }
        
        } else {
          this.videoDeviceData = {
            ...newData,
            firmwares: JSON.parse(newData.firmwares),
          };
        }
        // AXE
        
        // console.log('this.videoDeviceDataL ', this.videoDeviceData)
        
        if (
          this.videoDeviceData.firmware &&
          this.videoDeviceData.firmwares.length !== 0
        ) {
          const { updateRequired: checkForUpdate } =
            this.videoDeviceData.firmwares.filter(
              (e) => e.modUid === 'local-system'
            )[0];
        }
        this.videoDeviceData.licenses = this.formatLicenses(
          this.videoDeviceData.licenses
        );
        if (this.videoDeviceData == null) {
          this.dataError = true;
        } else {
          this.dataError = false;
        }
        if (this.videoDeviceType === '') {
          this.videoDeviceType = this.videoDeviceData.device_type;
        }

        if (this.videoDeviceType != 'cisco' && this.videoDeviceType != 'logitech') {
          this.showWellnessComponent = true;
        }

        this.PopupTitle = 'Video Device - ' + this.videoDeviceData.room_name;
        this.syncTime = moment(this.videoDeviceData.sync_stamp)
          .utc()
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format('MM-DD-YYYY hh:mma');
      });
  }

  formatLicenses(licenses: any): any {
    if (licenses !== null || licenses) {
      let mplusValue = null;
      const filteredLicenses = licenses.filter((l) => {
        if (l.tag === 'MPplus 8 Port') {
          mplusValue = l.value;
          return false;
        }
        if (l.value && l.value.toLowerCase() === 'false') {
          return false;
        }
        return true;
      });

      const licences = filteredLicenses.map((l) => {
        if (l.tag === 'MPplus 4 Port') {
          const v = mplusValue !== null ? mplusValue : l.value;
          return l.tag + ' : ' + this.formatLicensesDisplayValue(v);
        }
        return l.tag + ' : ' + 'Enabled';
      });

      return licences.filter((l) => {
        if (l.includes('Disabled')) {
          return false;
        }
        return true;
      });
    } else {
      return [];
    }
  }

  formatLicensesDisplayValue(val: string): string {
    return val.toLowerCase() === 'true' ? 'Enabled' : 'Disabled';
  }

  onVideoEvent = ($event) => {
    if ($event === 'refreshVideoData') {
      this.getVideoApiData();
    }
  };

  showWellnessCheck = () => {
    this.ds.sendPopupType(77, { deviceId: this.id, hType: 'video' });
  };
  
  
  getIotDeviceDetails = (iotId) => {
    this.getIotLitePortsByIotId(iotId);
    
    this.getIot.getIotDeviceDetails(iotId).subscribe((data: any) => {
      // const [res] = data;
    }, (err) => {
      // console.log(err)
    });
  };
  

  getDeviceRtdStatsIotLite = (iotId ) => {
    this.getIot.getDeviceRtdStatsIotLite(iotId).subscribe((data: any) => {
      // console.log('getDeviceRtdStatsIotLite: ',data)
      this.iotDeviceDetails = { ...data };
      this.monitoredPorts = this.iotDevicePortsDetails?.filter(port => port.is_monitored === 1).length
      this.unmonitoredPorts = this.iotDevicePortsDetails?.filter(port => port.is_monitored === 0).length
      
      // console.log('iotDeviceDetails: ', this.iotDeviceDetails)
      // console.log('monitoredPorts: ', this.monitoredPorts)
      // console.log('unmonitoredPorts: ', this.monitoredPorts)
      
      
      
    }, (err) => {
      // this.iotDeviceDetails = { ...params };
      // // console.log('iotDeviceDetails: ', this.iotDeviceDetails)
      this.monitoredPorts = this.iotDevicePortsDetails?.filter(port => port.is_monitored === 1).length
      this.unmonitoredPorts = this.iotDevicePortsDetails?.filter(port => port.is_monitored === 0).length
      // console.log(err)
    });
  };


  getIotLitePortsByIotId = (iotId) => {
    this.getIot.getIotLitePortsByIotId(iotId).subscribe((data) => {
      const res = data.response;
      this.iotDevicePortsDetails = res;
      this.getDeviceRtdStatsIotLite( iotId);
      
    });
  };
}
