<div class="device-call-status">
  <h3 class="text-center title">System Status</h3>
  <div class="row device-call-status-content">
    <div class="col display-value">
      <p class="{{ callStatsData.jitter.color }}">{{ callStatsData.jitter.value | qosRound }}</p>
    </div>
    <div class="col display-label">
      <p class="{{ callStatsData.jitter.color }}">Jitter (msec)</p>
    </div>
  </div>
  <div class="row device-call-status-content">
    <div class="col display-value">
      <p class="{{ callStatsData.packetloss.color }}">
        {{ callStatsData.packetloss.value | qosRound }}
      </p>
    </div>
    <div class="col display-label">
      <p class="{{ callStatsData.packetloss.color }}">Packet Loss (%)</p>
    </div>
  </div>
  <div class="row device-call-status-content" *ngIf="deviceType !== 'zoom'">
    <div class="col display-value">
      <p class="{{ callStatsData.latency.color }}">{{ callStatsData.latency.value | qosRound }}</p>
    </div>
    <div class="col display-label">
      <p class="{{ callStatsData.latency.color }}">Latency</p>
    </div>
  </div>
  <div class="row device-call-status-content" *ngIf="deviceType === 'zoom'">
    <div class="col display-value">
      <p class="{{ callStatsData.frame_rate.color }}">
        {{ callStatsData.frame_rate.value | qosRound }}
      </p>
    </div>
    <div class="col display-label">
      <p class="{{ callStatsData.frame_rate.color }}">Frame Rate</p>
    </div>
  </div>
  <div class="row device-call-status-content" *ngIf="deviceType === 'audio'">
    <div class="col display-value">
      <p class="{{ callStatsData.mos.color }}">
        {{ callStatsData.mos.value | qosRound }}
      </p>
    </div>
    <div class="col display-label">
      <p class="{{ callStatsData.mos.color }}">MOS</p>
    </div>
  </div>
  <div class="row device-call-status-content" *ngIf="isShowSip && deviceType !== 'zoom'">
    <div class="col display-value">
      <div class="sip-gate-status {{ this.sipGateColor }}"></div>
    </div>
    <div class="col display-label">
      <p>{{ this.sipGateText }} Status</p>
    </div>
  </div>
  <div class="row device-call-status-content" *ngIf="deviceType === 'zoom'">
    <div class="col display-value">
      <p class="{{ connectionTypeColor }}">
        {{ connectionType }}
      </p>
    </div>
    <div class="col display-label">
      <p>Connection Type</p>
    </div>
  </div>
</div>
