import { Subscription } from 'rxjs'

import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { GetCloudService } from '../../../services/get-cloud.service'
import { PopupTypeService } from '../../../services/popupType.service'
import { RefreshApiService } from '../../../services/refresh-api.service'
import moment from 'moment-timezone'

@Component({
  selector: 'app-cloud-user-panel',
  templateUrl: './cloud-user-panel.component.html',
  styleUrls: ['./cloud-user-panel.component.scss'],
})
export class CloudUserPanelComponent implements OnInit, OnDestroy {
  PopupTitle: string = 'CLOUD USER';
  id: any;
  cloudApiData: any = [];
  apiTimers: any;
  refreshData: any;
  filterListner: Subscription;
  liveParticipants: any;
  dataReceived = false;
  dataError: any;
  lastSync: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getCloudUserService: GetCloudService,
    private refreshApiService: RefreshApiService,
    public ds: PopupTypeService
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });

    // This recieves the update data boolean to start the timer
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      this.refreshData = m;
      this.getcloudApiData();
    });
  }

  ngOnInit() {
    this.getcloudApiData();
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  // This will Cloud User Data from server API
  getcloudApiData = () => {
    this.getCloudUserService.fetchApiCloudUserDetails(this.id).subscribe((data: any) => {
      this.cloudApiData = data;
      console.log('cloudApiData: ', this.cloudApiData);
      this.getlastSync();
      if (this.cloudApiData === '' || this.cloudApiData === undefined) {
        this.dataError = true;
      } else {
        this.dataError = false;
      }

      this.liveParticipants = this.cloudApiData.participants;
      this.PopupTitle = 'User: ' + this.cloudApiData.firstname + ' ' + this.cloudApiData.lastname;
      this.dataReceived = true;
    });
  };
  getlastSync(){
    if(this.cloudApiData?.plugin_last_connect){
      this.lastSync = moment(this.cloudApiData?.plugin_last_connect).format('MMMM Do YYYY, h:mm:ss a')
    }
  }
}
