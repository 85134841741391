<div class="card" style="width: 18rem;">
  <div class="card-body d-flex flex-column justify-content-between">
    <ng-container *ngIf="siteInformation">
      <img [src]="companyLogo" class="px-2" style="padding-top: 50px; padding-bottom: 66px;" />
    </ng-container>
    <div class="card-detail-bm">
      <ng-container *ngIf="siteInformation">
        <h4 class="card-title">
          <img *ngIf="siteInformation[0]?.virtual !== 1" [src]="iconAddSite" style="height: 20px !important;" />
          <img *ngIf="siteInformation[0]?.virtual == 1" [src]="iconVirtual" style="height: 30px !important;" />
          {{ siteInformation[0]?.site_name }}
        </h4>
        <p *ngIf="isSuperAdmin" class="card-text" style="font-weight: bold !important;">
          Company Name: {{ nullCheck(siteInformation[0]?.company_name) }}
        </p>
        <p class="card-text">
          Address: {{ nullCheck(siteInformation[0]?.address1) }},
          {{ nullCheck(siteInformation[0]?.address2) }}
        </p>
        <p class="card-text">City: {{ nullCheck(siteInformation[0]?.city) }}</p>
        <p class="card-text">State: {{ nullCheck(siteInformation[0]?.state) }}</p>
        <p class="card-text">
          Zip Code:
          {{ siteInformation[0] && siteInformation[0].zip ? siteInformation[0]?.zip : '--' }}
        </p>
        <p class="card-text">
          Phone:
          {{
          siteInformation[0] && siteInformation[0].contact && siteInformation[0].contact.phone
          ? siteInformation[0].contact.phone.replace('$$', '').replace('$$', '')
          : '--'
          }}
        </p>
        <p class="card-text">Email: {{ nullCheck(siteInformation[0]?.contact.email) }}</p>
        <app-btn-info *ngIf="!isSuperAdmin" class="float-right" style="padding-bottom: 5% !important;"
          label="Edit Site Information" [faName]="faIcon" (click)="
            ds.sendPopupType(34, {
              data: {
                siteId: siteInformation[0]?.site_id,
                styleProp: 'signup2'
              }
            })
          "></app-btn-info>
      </ng-container>
    </div>
  </div>
</div>