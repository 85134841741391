import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { GetIotService } from '../../services/get-iot.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { PopupTypeService } from '../../services/popupType.service';
import { AlertService } from 'src/app/_alert'

@Component({
  selector: 'app-iot-lite-admin',
  templateUrl: './iot-lite-admin.component.html',
  styleUrls: ['./iot-lite-admin.component.scss']
})
export class IotLiteAdminComponent implements OnInit, OnChanges {
  @Input() iotDeviceDetails: any;
  @Input() oldPorts: any;
  isShowSync = false;
  apiKey: String = '';
  apiName: String = '';
  accountUrl: String = '';
  active: number;
  domotz_account_id: number;
  company_id: number;
  shouldShowAlerts: Boolean = false;
  syncMessage: any = 'Device Sync Success!';
  showSyncMessage: boolean = false;
  messageclass: any = 'iotadmin__sync-message--success';
  shouldShowDocuments: boolean = false;
  showRescanDevice: any = false;
  propData: any;
  
  disableUpdatePortButton: any = false;
  showUpdatePortsMessage: any = "Updating will replace your old ports to the new ports";

  errorMessage: any = 'Could not find device. Please try again.';
  showSearching: any = false;
  showAddDevice: any = false;
  showErrorMessage: any = false;

  siteList: any = [];
  nmapResult: any;
  deviceMac: any;
  deviceMake: any;
  collectorId: any = "";
  iotType: any = "";
  showMsg30Sec: boolean = false;
  iotDeviceId: any = "";
  deviceName: any;
  deviceIP: any;
  devicePorts: any;
  setTimer: any

  actionDeviceName: any = '';
  actionDeviceIP: any = '';
  showSuccessMsg:any =false;
  showSiteListErrMsg: any = false;
  
  errorMsg: any = "Device Not Found";
  showErrMsg: any = false;

  actionCount: any = 0;
  showDeleteDevice: boolean = false;
  siteId: any;

  showRenameDevice: boolean = false;
  alreadyEditingInProcess: boolean= false;
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  
  constructor(
    private GetIot: GetIotService,
    private Auth: AuthService,
    private router: Router,
    private ds: PopupTypeService,
     private _alertService: AlertService,
    ) {}

  ngOnInit() {
    this.getDomotzAccountByCompanyId();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.iotDeviceDetails && this.iotDeviceDetails) {
      this.setProps();
      this.checkPort();
    }
  }
  checkPort() {
    if (this?.iotDeviceDetails?.pause && this.devicePorts?.length) {
      this.devicePorts.map((x) => x.is_monitored = 0);
    }
  }
  setProps = () => {
    const { collector_id,iot_ip,iot_id, site_id} = this.iotDeviceDetails;
    this.collectorId = collector_id;
    this.actionDeviceIP = iot_ip;
    this.iotDeviceId = iot_id;
    this.siteId = site_id;
    
    console.log('this.siteId: ',this.siteId);
  }

  getDomotzAccountByCompanyId = () => {
    this.GetIot.getDomotzAccountByCompanyId().subscribe((data: any) => {
      const { company_id, api_name, api_key, account_url } = data;
      this.company_id = company_id;
      this.apiName = api_name;
      this.apiKey = api_key;
      this.accountUrl = account_url;
    });
  };
  showAlerts = () => {
    this.shouldShowAlerts = !this.shouldShowAlerts;
  };

  hideMessage = () => {
    setTimeout(() => {
      this.showSyncMessage = false;
    }, 2000);
  };

  // syncDomotzAccount = () => {
  //   this.GetIot.syncDomotz(this.company_id, this.apiName, this.apiKey, this.accountUrl).subscribe(
  //     (data) => {
  //       this.showSyncMessage = true;
  //       this.syncMessage = 'Device Sync Success!';
  //       this.messageclass = 'iotadmin__sync-message--success';
  //       this.hideMessage();
  //     },
  //     (error) => {
  //       this.showSyncMessage = true;
  //       this.syncMessage = 'Device Sync Failed!';
  //       this.messageclass = 'iotadmin__sync-message--error';
  //       this.hideMessage();
  //     }
  //   );
  // };

  showDocumentCenter = () => {
    this.propData = {
      quadrant_group: 'DOMOTZ_IOT',
      device_id: this.iotDeviceDetails.device_id,
      description: this.iotDeviceDetails.display_name,
      offset: 0,
    };
    this.shouldShowDocuments = true;
  };


  resetAll = () => {
    this.showRescanDevice = false;
    this.showAddDevice = false;
    this.shouldShowDocuments = false;
    this.showRenameDevice = false;
    this.showDeleteDevice = false;

  }

  showPanel = (params) => {
    this.resetAll();
    if(params == 1) {
      this.showDocumentCenter();
    }

    if(params == 2) {
      this.showRescanDevice = true
      this.discoverDevice();
    }

    if(params == 3) {
      this.showDeleteDevice = true
    }
    if (params == 4) {
      this.showRenameDevice = true
    }
  }

  cancelDelete = () => {
    this.showDeleteDevice = false;
  }
  
  deleteDevice = () => {
    const data = {
      iot_id: this.iotDeviceDetails.iot_id,
    };
    this.GetIot.iotLiteDeactivateDevice(data).subscribe((data) => {
      console.log("Data is API response : ", data);
      if (data['response'][0]['result_status'] == 'Ok') {
        this.router.navigate(['iot/alldevices']);
        this.sendPopupType(99);
      }
    });
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }
  
  discoverDevice = () => {
    // edit later
    this.Auth.getNmap(this.collectorId, this.actionDeviceIP,'update', 700).subscribe((data) => {
      // console.log('getNmap: ', data)
      let [res] = data.response;

      if(res.status == "OK") {
        this.checkStatus(res.action_id);
        this.showSearching = true;
      } else {
        this.errorMsg = res.message;
        this.showErrMsg = true;
        this.setTimer = setTimeout( () => {
          this.showErrMsg = false;
        }, 2000);
      }

    }, 
    (err) => {
      this.showSearching = false;
     })
  }

  checkStatus = (action_id) => {
    this.Auth.postDeviceActionId({ action_id }).subscribe((actionData) => {
      // console.log('postDeviceActionId: ', actionData)
      // console.log('actionCount: ', this.actionCount)
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 120) {
        this.resetFields();
        this.showErrMsg = true;
        // this.setTimer = setTimeout( () => {
        //   this.showErrMsg = false;
        // }, 2000);
        return;
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus(action_id);
        }, 1000);
        this.actionCount++;
        if (this.actionCount == 30) {
          this.showMsg30Sec = true;

          // Since total time of waiting is 120 seconds and after 30 we show It iss taking longer then usual
          setTimeout(() => {
            this.showMsg30Sec = false;
          }, 70*1000); 
        }
      } else if (actionData.response[0].status === 'FAILED') {
        const [newData] = actionData.response
        // console.log('FAILED: ', actionData.response)
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        const [newData] = actionData.response;
        this.resetFields();
        this.showAddDevice = true;
        this.setData(newData.results);
        this.checkPortsAreIdentical();

        return;
      }
    });
  }

  resetFields = () => {
    this.actionCount = 0;
    this.showSearching = false;
    this.showErrorMessage = false;
    this.showAddDevice = false;
    this.disableUpdatePortButton = false;
    this.showUpdatePortsMessage = "Updating will replace your old ports to the new ports"
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };

  setData = (params) => {
    const res2 = JSON.parse(params.results);
    // console.log('res2: ',res2)
    const { nmap: { host } } = res2;
    if(host) {
      if(host.ports) {
        if(host.ports.port) {
          if(host.ports.port.length > 1) {
            this.devicePorts = host.ports.port 
          } else {
            console.log('only 1 port')
            this.devicePorts = [];
          }
        } else {
          this.devicePorts = [];
        }
      } else {
        this.devicePorts = [];
      }
    } else {
      this.devicePorts = [];
    }
    this.devicePorts.map((item) => {
      const index = this.oldPorts.findIndex((port) => port.port_id == item.portid);
      if(index > -1) {
        item.service.name = this.replaceSpecialCharacther(this.oldPorts[index].port_name);
        item.existsInOldPorts = true;
        item.is_monitored = this.iotDeviceDetails&&this.iotDeviceDetails.pause?0:this.oldPorts[index].is_monitored;
      } else {
        item.existsInOldPorts = false;
        item.is_monitored = this.iotDeviceDetails&&this.iotDeviceDetails.pause?0:1;
      }
    })
  }
  
  replaceSpecialCharacther = (portname) => {
    return  portname.replace(/\W/g, "_");;
  }

  updatePorts = () => {
  if(this.devicePorts.length > 1) {
    this.devicePorts.map((item) => {
      item.portname = item.service.name;
    })
  }

    const newPorts = JSON.stringify(this.devicePorts);
    this.GetIot.updateIotLitePorts(
      this.iotDeviceId,
      newPorts
      ).subscribe((data) => {
        // console.log('updateIotLitePorts: ', data);
        this.disableUpdatePortButton = true;
        this.showUpdatePortsMessage = "Device Ports Successfully Updated!";
        this.setTimer = setTimeout(() => {
        this.disableUpdatePortButton = false;
          this.resetAll();
          this.showUpdatePortsMessage = "Updating will replace your old ports to the new ports";
        }, 2500);
    });
  }

  getOldPortsValues = (port) => {
    this.oldPorts.findIndex((item) => {
      if (item.port_id == port.portid) {
        port.service.name = item.portname;
      }
    });
    return port;
  }

  onChangePortName = (value, index) => {
    this.devicePorts[index].service.name = value;
    this.disableUpdatePortButton = false;
    this.showUpdatePortsMessage = "";
    this.checkPortsAreIdentical();
  }
  onChangePortMonitored = (event, index) => {
    var value = 0;
    if(event?.target?.checked) {
      value = 1;
    }
    this.devicePorts[index].is_monitored = value;
    this.disableUpdatePortButton = false;
    this.showUpdatePortsMessage = "";
    this.checkPortsAreIdentical();
  }

  checkPortsAreIdentical = () => {
    // const oldPortsNew = JSON.stringify(this.oldPorts.map(e =>  e.port_id ));
    // const newPortsNew = JSON.stringify(this.devicePorts.map(e =>  parseInt(e.portid) ));
    // if(oldPortsNew == newPortsNew) {
    //   this.disableUpdatePortButton = true;
    //   this.showUpdatePortsMessage = "Note: Cant update because ports are identical";
    // }
    if(this.devicePorts.length < 1){
      return false;
    }
    
    if (this.oldPorts.length !== this.devicePorts.length) {
      return false;
    }
    this.oldPorts.sort((a, b) => a.port_id - b.port_id);
    this.devicePorts.sort((a, b) => a.portid - b.portid);

    for (let i = 0; i < this.oldPorts.length; i++) {
      if (this.oldPorts[i].port_id !== parseInt(this.devicePorts[i].portid) || this.oldPorts[i].port_name !== this.devicePorts[i].service.name || this.oldPorts[i].is_monitored !== this.devicePorts[i].is_monitored) {
        return false;
      }
    }
    this.disableUpdatePortButton = true;
    this.showUpdatePortsMessage = "No new port found.";
    return true;
  }

   async  editIotName(val: any) {
    console.log(val);
    if (this.alreadyEditingInProcess == true) {
      return;
    }
    if ((val || '').trim().length == 0) {
      this._alertService.error('Please Provide a name to Update', this.options);
      return;
      }
    try {
      this.alreadyEditingInProcess = true;
      if ((val || '').trim().length > 70) {
        throw "Room name can't be more then 70 characters long";
      }

      let payload = {
        iotId: this.iotDeviceDetails.iot_id,
        companyId: this.iotDeviceDetails.company_id,
        iotName:val.trim()
      }
      console.log("payload",payload);
      this.GetIot.setIotName(payload).subscribe((res) => {     
        if (res.status == 200) {
          this._alertService.success(res.message, this.options);
        } else {
         this._alertService.error(res.error,this.options)
        }
      })
    } catch (error) {
      console.log(error);
      this._alertService.error(error, this.options)
    } finally {
       setTimeout(() => {
        this.alreadyEditingInProcess = false;
       }, 2000);
    }
  }

}
