import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { GetCloudService } from '../../../services/get-cloud.service';
import { PopupTypeService } from '../../../services/popupType.service';
import { PopupSendDataService } from '../../../services/popup-send-data.service';
import { AuthService } from '../../../services/auth.service';
import { GetDesktopPluginService } from '../../../services/get-desktop-plugin.service';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../../environments/environment';
import { faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-cloud-user-info',
  templateUrl: './cloud-user-info.component.html',
  styleUrls: ['./cloud-user-info.component.scss'],
})
export class CloudUserInfoComponent implements OnInit, OnChanges {
  @Input() cloudUserInfo: any;
  id: number;
  hasAccess: boolean;
  accessLevel: any;
  isSuperAdmin: any;
  isDisableDesktopClientPluginToggle: boolean = false;
  isDisableAlertsToggle: boolean = false;
  isZoomPluginEnabled: boolean = false;
  deskPlugin: String = environment.config.dynamicText.deskPlugin;
  desktopPluginMessage: string;
  deskPluginStatus: number;
  userStatus: String = '';
  faIcon = faUser;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public ds: PopupTypeService,
    private sendPopupDataService: PopupSendDataService,
    public http: HttpClient,
    private Auth: AuthService,
    private getCloudService: GetCloudService,
    private desktopPlugin: GetDesktopPluginService
  ) {
    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });
  }
  ngOnChanges(changes: SimpleChanges): void {

    // console.log('this.cloudUserInfo: ',this.cloudUserInfo)

    if (changes.cloudUserInfo && this.cloudUserInfo) {
      // check if there is desktop plugin
      if (this.cloudUserInfo.plugin_id !== null) {
        this.isDisableDesktopClientPluginToggle = false;
        this.isDisableAlertsToggle = false;
      } else {
        this.isDisableDesktopClientPluginToggle = true;
        this.isDisableAlertsToggle = true;
      }

      this.isZoomPluginEnabled =
        !this.isDisableDesktopClientPluginToggle && this.cloudUserInfo.zoom_plugin_enabled === 1;
      this.deskPluginStatus = this.cloudUserInfo.plugin_status;

      // desktop plugin display message
      this.desktopPluginMessage = this.getDesktopPluginMessage();
      if (this.isZoomPluginEnabled && this.deskPluginStatus === 1) {
        this.userStatus =
          this.cloudUserInfo.plugin_application[0].zoom === 1 ? 'Online' : 'Offline';
      }
      // // console.log('cloudUserInfo: : ',this.cloudUserInfo);
    }
  }

  paramData() {
    this.sendPopupDataService.sendPopupData(this.id);
  }

  nullCheck(value) {
    return value ? value : '--';
  }

  ngOnInit() {
    // send message to subscribers via observable subject
    this.ds.sendPopupType(0);
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.accessLevel = decodedToken.accesslevel;
    this.isSuperAdmin = this.Auth.accessLevel == 'SUPERADMIN';
    this.hasAccess = this.getHasAccess();
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }

  sendPopupType(p?) {
    this.paramData();
    this.ds.sendPopupType(p);
  }

  alertsChange(e) {
    const usrData = this.cloudUserInfo.zoom_user_id;
    if (this.isDisableAlertsToggle) {
      e.currentTarget.checked = !e.currentTarget.checked;
    } else {
      if (e.currentTarget.checked) {
        this.getCloudService.enableAlerts(usrData).subscribe((data) => { });
      } else {
        this.getCloudService.disableAlerts(usrData).subscribe((data) => { });
      }
    }
  }

  desktopClientChange(e) {
    const usrData = this.cloudUserInfo.zoom_user_id;
    const flag = e.currentTarget.checked;
    this.getCloudService.setEnableDesktopPlugin(usrData, flag).subscribe(
      (data) => {
        // // console.log('desktop');
      },
      (e) => {
        // console.log(e);
      }
    );
  }

  openRebootPopUp = () => {
    const data = {
      zoom_plugin_enabled: this.isZoomPluginEnabled,
      desktop_plugin_message: this.desktopPluginMessage,
      plugin_id: this.cloudUserInfo.plugin_id,
    };
    this.ds.sendPopupType(63, { data });
  };

  getHasAccess = () => {
    if (this.accessLevel) {
      if (this.accessLevel === 'USER' || this.isSuperAdmin) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  getUserProfilePicture = (params) => {
    return params ? params : '../../../../assets/img/user-in-a-square.png';
  };

  getDesktopPluginMessage = () => {
    if (this.isDisableDesktopClientPluginToggle) {
      return `To enable advance features, please download and install the ${this.deskPlugin}.`;
    }
    if (
      !this.isDisableDesktopClientPluginToggle &&
      this.isZoomPluginEnabled &&
      this.deskPluginStatus !== 1
    ) {
      return `${this.deskPlugin} is offline.`;
    }
    return '';
  };

  // getCloudUserLogo = ({ service_type }) => {
  //   return service_type === 'teams'
  //     ? '../../assets/img/vs_img/teams_logo.png'
  //     : '../../assets/img/vs_img/zoom_logo.png';
  // };
}
