import * as moment from 'moment-timezone'
import { Subscription } from 'rxjs'

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { EventEmitterGlobalService } from '../../services/event-emitter-global.service'
import { GetCloudService } from '../../services/get-cloud.service'
import { GetTeamsService } from '../../services/get-teams.service'
import { PopupTypeService } from '../../services/popupType.service'
import { RefreshApiService } from '../../services/refresh-api.service'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-teams-room-panel',
	templateUrl: './teams-room-panel.component.html',
	styleUrls: ['./teams-room-panel.component.scss'],
})
export class TeamsRoomPanelComponent implements OnInit {
	PopupTitle: string = 'CLOUD ROOM'
	teamsDetails: any // re,pve the default object test again when on call
	teamsID: any
	dataError: any
	refreshData: any
	filterListner: Subscription
	isMonitoringEnabled: boolean = true
	isTeamsEnabled: any // can be pass to stas and info
	wifiRange: any
	teamsMbps: any
	networkMonitorTeams: any
	isDisableDesktopClientPluginToggle: boolean = true //disable for now
	isShowPeopleCount: boolean
	isTeamsPluginEnabled: boolean = false
	deskPluginStatus: number
	lastSync: any
	teamsUserHealth: number = 100
	spaceStatus: boolean = false
	showLoader: boolean = false
	logitechSync: boolean = false
	logitechTap: any
	logitechScribe: any = null
	logitechScribeTmp: any = null
  	dCollector: String = environment.config.dynamicText.dCollector;
	constructor(
		private getTeams: GetTeamsService,
		private route: ActivatedRoute,
		private refreshApiService: RefreshApiService,
		private eventEmmit: EventEmitterGlobalService,
		private getCloudUserService: GetCloudService,
		public ds: PopupTypeService
	) {
		this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
			this.getRoomDetails()
		})
	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			this.teamsID = params['id']
			this.getTeamsSpaceStatus()
			this.getRoomDetails()
			this.getLastSync()
		})
	}

	getLastSync = () => {
		this.getTeams.getLastSync().subscribe(
			(data: any) => {
				const newDate = moment(data.last_sync_stamp).format('MMMM Do YYYY, h:mm:ss a')
				this.lastSync = newDate
			},
			err => {}
		)
	}

	getMonitoringDetails = () => {
		this.getTeams.getMonitoringDetails().subscribe(
			(data: any) => {
				this.isMonitoringEnabled = data !== true ? false : data
			},
			err => {}
		)
	}

	getRoomDetails = () => {
		//change this i guess?
		this.showLoader = true
		this.getCloudUserService.fetchApiCloudRoomDetails(this.teamsID).subscribe(
			(data: any) => {
				this.showLoader = false
				let [res] = data.response
				res = res ?? {}
				
				this.teamsDetails = {
					...res,
					zoom_plugin_connected_devices: this.filterDevices(res.zoom_plugin_connected_devices) || [],
				}

				this.teamsDetails = this.injectLogitechData(this.teamsDetails)

				if (this.teamsDetails === '' || this.teamsDetails === undefined) {
					this.dataError = true
				} else {
					this.dataError = false
				}
				if (this.teamsDetails) {
					this.teamsUserHealth = this.changeHealth();
				}
				this.getTeamsPluginEnabled()
				this.getMonitoringDetails()
				this.checkIfUserIsOnCall()

				this.PopupTitle = 'Room: ' + this.ifRoomNameIsNull(this.teamsDetails.room_name)
				
			console.log('this.teamsDetails: ', this.teamsDetails)
				
			},
			err => {}
		)
	}

	injectLogitechData = teamsDetails => {
		if ([null, undefined, []].includes(teamsDetails.plugin_application)) return teamsDetails

		const logitech_sync = teamsDetails.plugin_application[0].logitech_sync === 1
		if (!logitech_sync) return teamsDetails

		const products = teamsDetails.zoom_plugin_data?.logitech?.AllProducts?.Products
		if (!products) return teamsDetails

		const product = products[0]
		if (!product) return teamsDetails

		const devices = product.Devices.filter(({ Name }) => Name !== '')

		this.logitechTap = products[1]
		this.logitechSync = true
		this.logitechScribe = this.logitechScribeTmp

		const transform = e => ({
			id: e.Uuid,
			name: e.Name,
			inuse: false,
			backup: 0,
			status: e.ConnectionState > 10 ? 1 : 0,
			primary: false,
			inuse_teams: true,
		})

		const cameras = devices.filter(e => e.FormFactor === 22).map(transform)
		const mics = devices.filter(e => e.FormFactor === 23).map(transform)
		const speakers = devices.filter(e => e.FormFactor === 25).map(transform)

		const { video, audio_mic, audio_speaker } = teamsDetails.zoom_plugin_connected_devices

		return {
			...teamsDetails,
			zoom_plugin_connected_devices: {
				video: [...cameras, ...video],
				audio_mic: [...mics, ...audio_mic],
				audio_speaker: [...speakers, ...audio_speaker],
			},
		}
	}

	filterDevices = zoomDevices => {
		const devices = zoomDevices
		if (devices && devices.video) {
			if (Array.isArray(devices.video) && devices.video.length > 0) {
				const scribe = devices.video.find(e => e.name === 'Logitech Scribe')
				if (scribe) this.logitechScribeTmp = scribe
				const filterDeviceVideo = devices.video.filter(
					e =>
						this.devicesToFilter(e.name)
				)
				devices.video = filterDeviceVideo
			}
		}

		if (devices && devices.audio_mic) {
			if (Array.isArray(devices.audio_mic) && devices.audio_mic.length > 0) {
				const filterDeviceVideo = devices.audio_mic.filter(e => 
					this.devicesToFilter(e.name)
				)
				devices.audio_mic = filterDeviceVideo
			}
		}

		if (devices && devices.audio_speaker) {
			if (Array.isArray(devices.audio_speaker) && devices.audio_speaker.length > 0) {
				const filterDeviceVideo = devices.audio_speaker.filter(e => 
					this.devicesToFilter(e.name)
				)
				devices.audio_speaker = filterDeviceVideo
			}
		}
		return devices
	}
	
	devicesToFilter = (devices) => {
		if ( devices.match( /(Logitech Tap|Crestron HD-CONV-USB|Logitech Scribe)/ )) {
		    return false
		} else {
		  return true
		}
	}
	

	ifRoomNameIsNull = roomname => (roomname == null ? 'Default' : roomname)

	checkIfUserIsOnCall = () => {
		const { liveMeetings, plugin_host_info, id } = this.teamsDetails
		if (liveMeetings && this.isTeamsEnabled && plugin_host_info) {
			this.networkMonitorTeams = this.getNetworkMonitorTeams(this.teamsDetails)
			this.wifiRange = plugin_host_info[0].wifi_signal_strength.split('%')[0] || '0'

			if (this.networkMonitorTeams) {
				this.calculatedMbpsTeams(this.networkMonitorTeams)
			}

			this.teamsDetails = {
				...this.teamsDetails,
				calculatedMbpsTeams: this.teamsMbps,
				wifiRange: this.wifiRange,
				wifiSsid: plugin_host_info[0].wifi_ssid,
				isTeamsEnabled: this.isTeamsEnabled,
			}
			
		}
	}

	calculatedMbpsTeams = ({ receive_speed, send_speed }) => {
		if (receive_speed != null && send_speed != null && receive_speed != '' && send_speed != '') {
			const newMbps = parseFloat(receive_speed.split('K')[0]) + parseFloat(send_speed.split('K')[0])
			this.teamsMbps = (newMbps / 122).toFixed(2)
		} else {
			this.teamsMbps = '--'
		}
	}

	getNetworkMonitorTeams = ({ service_type, zoom_plugin_data }) => {
		if (service_type === 'teams' && zoom_plugin_data !== null && zoom_plugin_data.callSimulator.teams) {
			if (zoom_plugin_data.callSimulator.teams && zoom_plugin_data.callSimulator.teams.network_monitor !== null) {
				const {
					zoom_plugin_data: {
						callSimulator: {
							teams: { network_monitor },
						},
					},
				} = this.teamsDetails
				return network_monitor
			}
		}
		return false
	}

	getTeamsPluginEnabled = () => {
		this.isTeamsEnabled = this.teamsDetails.zoom_plugin_enabled === 1 && this.teamsDetails.plugin_status
	}

	onCloudEvent = $event => {
		if ($event === 'refreshCloudData') {
			this.getRoomDetails()
		}
	}

	getTeamsSpaceStatus = () => {
		this.getTeams.getTeamsSpaceStatus().subscribe(
			(data: any) => {
				if (data.is_teams_space) {
					this.spaceStatus = true
				}
			},
			err => {}
		)
	}

	ngOnDestroy() {
		this.filterListner.unsubscribe()
	}
	changeHealth(){
    const status = String(this.teamsDetails.status).toLowerCase()
    const plugin_status = +this.teamsDetails.plugin_status
		return status === 'offline' || (status === 'unknown' && plugin_status !== 1) || this.teamsDetails.pause
			? 0
			: this.teamsDetails.health
	}
}
