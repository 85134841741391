import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-wellness-check',
  templateUrl: './wellness-check.component.html',
  styleUrls: ['./wellness-check.component.scss']
})
export class WellnessCheckComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
