<div class="cloud-user-notes">
  <h5>Notes</h5>
  <textarea
    class="cloud-user-notes__textarea"
    name=""
    id=""
    cols="30"
    rows="10"
    [(ngModel)]="notes"
  ></textarea>

  <div class="cloud-user-notes__btn_ctr1">
    <div class="cloud-user-notes__text">
      Maximum 4000 characters
    </div>
    <div class="cloud-user-notes__btn" (click)="saveNotes()">Save</div>
  </div>
</div>
