<div class="collector-console">

  <div class="collector-console__header-container">
    <h3>Console</h3>

    <div class="collector-console__button-container">
      <div class="collector-console__buttons collector-console__buttons--one" (click)="changeTab(true)">
        Network Diagnostics
      </div>
      <div class="collector-console__buttons collector-console__buttons--two" (click)="changeTab(false)">
        Debugging Console
      </div>
    </div>
  </div>

  <div class=" collector-console__input-container">
    <div *ngIf="showNetworkTab" class="collector-console__tab-items-1">
      <div class="collector-console__input_text">
        Origination IP: <span>{{ originationIp || 0.0.0.0 }}</span>
      </div>
      <div class="collector-console__input-text">
        Destination IP: <span><input type="text" class="destination-ip" [(ngModel)]="host" /></span>
      </div>
      <div class="collector-console__radio-run-container">
        <div class="collector-console__radio-container">
          <mat-radio-group [(ngModel)]="actionNameRadio">
            <mat-radio-button value="Ping">Ping</mat-radio-button>
            <mat-radio-button style="margin-left: 10px;" value="Traceroute">Traceroute</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="collector-console__run-download-button-container">
          <div class="collector-console__button-short collector-console__button-short--one"
            [ngClass]="{'collector-console__middle-container-2': getLoader() }" (click)="run()">
            <span *ngIf="!getLoader()">Run</span>
            <img *ngIf="getLoader()" src="{{ loader }}" alt="" />
          </div>
          <div *ngIf="showFailedProccess" class="collector-console__button-short collector-console__button-short--two"
            (click)="downloadLogs()">
            <span>Download Logs</span>
          </div>
        </div>

      </div>
    </div>

    <div *ngIf="!showNetworkTab" class="collector-console__tab-items-2">
      <div class="collector-console__title">
        <div class="collector-console__select-container">
          <select class="form-control" (change)="intervalEveryWeekOnChange($event)" [(ngModel)]="collectorSelectActions"
            [ngModelOptions]="{ standalone: true }" style="width: 350px !important; margin-bottom
            :15px">
            <option value="1" selected> EVENT LOGS</option>
            <option value="2"> INSTALLED SOFTWARE REGISTRY</option>
            <option value="3"> INSTALLED SOFTWARE CONTROL PANEL</option>
            <option value="4"> NETSTAT</option>
            <option value="5"> RUNNING PROCCESES</option>
            <option value="6"> SERVICES</option>
            <option value="7"> SCHEDULETASK</option>
          </select>
        </div>
      </div>

      <div class="collector-console__input-text-container">
        <div *ngIf="collectorSelectActions == '1'" class="collector-console__input-text">
          LogName: <span><input type="text" class="destination-ip" [(ngModel)]="logName" /></span>
        </div>
        <div *ngIf=" collectorSelectActions == '1'" class=" collector-console__input-text">
          ProviderName: <span><input type="text" class="destination-ip" [(ngModel)]="providerName" /></span>
        </div>
        <div *ngIf=" collectorSelectActions == '1'" class=" collector-console__input-text">
          Id: <span><input type="text" class="destination-ip" [(ngModel)]="logId" /></span>
        </div>
      </div>

      <div class=" collector-console__run-download-button-container">
        <div class="collector-console__button-short collector-console__button-short--one"
          [ngClass]="{'collector-console__middle-container-2': getLoader() }" (click)="run()">
          <span *ngIf="!getLoader()">Run</span>
          <img *ngIf="getLoader()" src="{{ loader }}" alt="" />
        </div>
        <div *ngIf="showFailedProccess" class="collector-console__button-short collector-console__button-short--two"
          (click)="downloadLogs()">
          <span>Download Logs</span>
        </div>
      </div>

    </div>
  </div>

  <div class="collector-console__middle-container"
    [ngClass]="{'collector-console__middle-container-2': !showNetworkTab }">
  </div>

  <div class="collector-console__lower-button-container">
    <!-- <div class="collector-console__lower-button collector-console__disabled">
      Packet Capture Files
    </div>
    <div class=" collector-console__lower-button collector-console__disabled">
      Ping Files
    </div>
    <div class="collector-console__lower-button collector-console__disabled">
      Traceroute Files
    </div> -->
    <div>

    </div>
  </div>


  <div class="collector-console__console-container">
    <pre *ngIf="isRunButtonClicked; else runButtonNotClicked" class="collector-console__output">
      <div>Terminal>_</div>
      {{actionResult}}
    </pre>
    <ng-template #runButtonNotClicked>
      <pre class=collector-console__output>Terminal>_</pre>
    </ng-template>

  </div>


</div>


<!-- <div class="collector-console">
  <div class="collector-console__title">
    <h4>Console</h4>

    <button (click)="run()" [disabled]="getLoader()">
      <span *ngIf="!getLoader()">Run</span>
      <img *ngIf="getLoader()" src="{{ loader }}" alt="" />
    </button>
  </div>



  <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)">
    <mat-tab label="Network Diagnostics">
      <div class="collector-console__tab-items-1">
        <div class="collector-console__input_text">
          Origination IP: <span>{{ originationIp }}</span>
        </div>
        <div class="collector-console__input_text">
          Destination IP: <span><input type="text" class="destination-ip" [(ngModel)]="host" /></span>
        </div>

        <div class="collector-console__btn_ctr">
          <mat-radio-group [(ngModel)]="actionNameRadio">
            <mat-radio-button value="Ping">Ping</mat-radio-button>
            <mat-radio-button value="Traceroute">Traceroute</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Debugging Console">
      <div class="collector-console__tab-items-2">
        <div class="collector-console__title" style="margin:15px 0px">
          <div class="collector-console__select-container">
            <select class="form-control" (change)="intervalEveryWeekOnChange($event)"
              [(ngModel)]="collectorSelectActions" [ngModelOptions]="{ standalone: true }"
              style="width: 150px !important">
              <option value="1" selected> event logs</option>
              <option value="2"> installed software registry</option>
              <option value="3"> getwin32product </option>
              <option value="4"> netstat </option>
              <option value="5"> ps </option>
              <option value="6"> getservices </option>
              <option value="7"> scheduletask </option>
            </select>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div *ngIf="isRunButtonClicked; else runButtonNotClicked" class="collector-console__output">
    {{actionResult}}
  </div>
  <ng-template #runButtonNotClicked>
    <div class=collector-console__output></div>
  </ng-template>


  <button (click)="downloadLogs()" class="collector-console__download-button"
    [ngClass]="{'collector-console__disabled': disabledDownload}" [disabled]="disabledDownload">
    Download Logs
  </button>
</div> -->