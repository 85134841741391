<div class="container-fluid" style="height: 74vh" id="main-popup">
  <div class="row">
    <div class="col-12">
      <form role="form" class="signup" name="siteForm" [formGroup]="spaceForm" (ngSubmit)="onSubmit()">
        <div class="text-center" [ngClass]="color" *ngIf="message">{{message}}</div>
        <div class="form-group"></div>
        <div class="form-group">
          <input type="text" formControlName="space_name" class="form-control"
            [ngClass]="{ 'is-invalid': f.space_name.touched && f.space_name.errors }" placeholder="Space Name" name="space_name"
            id="space_name" autocomplete="additional-name" required />
          <div *ngIf="f.space_name.touched && f.space_name.errors" class="invalid-feedback">
            <div *ngIf="f.space_name.touched && f.space_name.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        

        <div class="form-group">
          <select type="text" formControlName="agent_id" class="form-control" placeholder="Agent" name="agent_id"
            id="agent_id" [ngClass]="{ 'is-invalid': f.agent_id.touched && f.agent_id.errors }" autocomplete="agent_id"
            required>
            <option value="" selected>Select {{data&&!data.isDomotz?'a Site':'Agent'}}</option>
            <option *ngIf="isShowLoader" class="skeleton-loader">
              Loading...
            </option>
            <option *ngFor="let agent of iotAgentList; let i = index" value="{{ agent.agent_id }}" [hidden]="agent.status === -1 || agent.status === 'OFFLINE' ">{{
              agent.agent_name }}</option>
          </select>
          <div *ngIf="f.agent_id.touched && f.agent_id.errors" class="invalid-feedback">
            <div *ngIf="f.agent_id.touched && f.agent_id.errors.required" id="confirmMessage">!</div>
          </div>
        </div>
        <div class="col-6">
            <button type="submit" class="btn-primary" style="width: 100%;">
             {{data?.spaceToBeEdited? 'Update': 'Create'}} Space
            </button>
        </div>
      </form>
    </div>
  </div>
</div>