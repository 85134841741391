<div class="delete-room">
    <div class="delete-room__header">
        <div>
            DELETE ROOM
        </div>
        <div (click)="closeDialog()" class="delete-room__header-close-button">
            <img src="./assets/img/iot/delete-btn.png" alt="" />
        </div>
    </div>

    <div class="delete-room__body delete-room__body-2">
        <div class="delete-room__image">
            <img *ngIf="!isLoading" src="../../../../assets/img/vs_img/delete-icon.png" alt="" />
            <img *ngIf="isLoading" src="{{ btnLoader }}" alt="" />
        </div>
        <div class="delete-room__text-button-container">
            <div *ngIf="!doneAction" class="">
                Delete <strong>{{roomName}}</strong> Teams Room?
            </div>
            <div *ngIf="doneAction" class="">
                <strong>{{actionResultMessage}}</strong>
            </div>
            <div *ngIf="!isLoading && !doneAction" class="delete-room__button-container">
                <div class="delete-room__button-item delete-room__button-item--yes" (click)="deleteTeamsRoom()">
                    Yes, Delete it!
                </div>
                <div class="delete-room__button-item delete-room__button-item--cancel" (click)="closeDialog()">
                    X Cancel
                </div>
            </div>
        </div>
    </div>
</div>