import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
// import { build } from '../../../../environments/build';

@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.scss'],
})
export class NewLoginComponent implements OnInit, OnChanges {
  @Output() public data = new EventEmitter<{ email: string; password: string }>();
  @Input() message;

  setTimer: any;
  dashboardLogo = environment.config.dynamicImage.dashboardLogo;
  envTitle: any = environment.config.title;
  email: string = '';
  password: string = '';
  formControl: any;

  links = environment.config.links;
  dTerms = environment.config.dynamicText.dTerms;
  bgImageNew = environment.config.dynamicImage.bgImageNew;
  
  darkTheme: any = true;
  

  visibilityBackground = {
    'background-image': 'url(' + this.bgImageNew + ')',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
    'background-attachment': 'fixed',
    'background-size': 'cover',
    height: '88.5vh',
  };



  shouldShowForget: boolean = true;
  shouldShowCreateAccount: boolean = true;

  startValidateEmail: boolean = false;
  startValidatePassword: boolean = false;
  disableBtn: boolean = false;
  constructor(public http: HttpClient, public Auth: AuthService, private router: Router) {
    this.checkUrl();
  }

  checkUrl = () => {
    if (this.router.url === '/registration') {
      this.shouldShowCreateAccount = false;
    }
    if (this.router.url === '/login') {
      // this.shouldShowForget = false;
      this.shouldShowCreateAccount = true;
    }
  };

  ngOnInit() {
    this.setFormControl();
    this.disableCreateAccount();

  }

  disableCreateAccount = () => {
    if (this.envTitle == 'Vision Point' || this.envTitle == 'vCare' || this.envTitle == 'Videlio') {
      this.shouldShowCreateAccount = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.message) {
      if (this.formControl) {
        this.setErrorMessageAndStatus('email', this.message.flag, 'error', this.message.text);
        this.setErrorMessageAndStatus('password', this.message.flag, 'error', this.message.text);
        this.disableBtn = true;
        this.startValidateEmail = true;
        this.startValidatePassword = true;
        if (this.envTitle == 'Vision Point') {
          this.shouldShowCreateAccount = false;
        }

      }
    }
  }

  enableCreateAccount = () => {
    this.shouldShowCreateAccount = true;
    this.disableCreateAccount();
  }

  setFormControl = () => {
    const deepCopy = {
      email: {
        flag: false,
        style: 'default',
        message: '',
      },
      password: {
        flag: false,
        style: 'default',
        message: '',
      },
    };

    this.formControl = { ...deepCopy };
  };

  validateForm = () => {
    const validation = this.validate();

    if (validation) {
      this.emitEvent();
    }
  };

  validate = () => {
    let errCount = 0;
    this.setFormControl();
    if (this.isEmpty(this.email)) {
      this.startValidateEmail = true;
      this.setErrorMessageAndStatus('email', true, 'error', '');
      errCount++;
    } else if (!this.validateEmail(this.email)) {
      this.startValidateEmail = true;
      this.setErrorMessageAndStatus('email', true, 'error', 'format (ex. email@domain.com) ');
      errCount++;
    }

    if (this.isEmpty(this.password)) {
      this.startValidatePassword = true;
      this.setErrorMessageAndStatus('password', true, 'error', '');
      errCount++;
    } else if (this.password.length < 8) {
      this.startValidatePassword = true;
      this.setErrorMessageAndStatus('password', true, 'error', '');
      errCount++;
    }
    this.disableBtn = errCount !== 0;
    return errCount === 0;
  };

  isEmpty = (params) => {
    if (params.length === 0) {
      return true;
    }
    return false;
  };

  setErrorMessageAndStatus = (propname, bool, style, message) => {
    this.formControl[propname].flag = bool;
    this.formControl[propname].style = style;
    this.formControl[propname].message = message;
  };

  emitEvent = () => {
    this.data.emit({ email: this.email, password: this.password });
  };

  emailKeyEvent = (events) => {
    if (this.startValidateEmail) {
      this.validate();
    }
    if (events === 'Enter') {
      this.validateForm();
    }
  };

  passwordKeyEvent = (events) => {
    if (this.startValidatePassword) {
      this.validate();
    }
    if (events === 'Enter') {
      this.validateForm();
    }
  };

  validateEmail = (email) => {
    // tslint:disable-next-line: max-line-length
    const regexEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEx.test(String(email).toLowerCase());
  };

  linkToForgotPassword = () => {

    this.shouldShowCreateAccount = true;
    this.disableCreateAccount();
    this.router.navigate(['forgotpassword']);
  };

  linkToRegister = () => {
    this.shouldShowCreateAccount = false;
    this.router.navigate(['registration']);
  };
}
