import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-btn-wire',
  templateUrl: './btn-wire.component.html',
  styleUrls: ['./btn-wire.component.scss']
})
export class BtnWireComponent implements OnChanges {
  @Input() networkInfo;
  lanIconConnected:string = '../../../../assets/img/audio/icon_ethernet_02.png';
  lanIconNotConnected:string = '../../../../assets/img/audio/icon_ethernet_01.png';
  wireLess4BarsIcon= '../../../../assets/img/audio/wifi_4.png';
  wireLess3BarsIcon= '../../../../assets/img/audio/wifi_3.png';
  wireLess2BarIcon= '../../../../assets/img/audio/wifi_2.png';
  wireLess1BarIcon= '../../../../assets/img/audio/wifi_1.png';
  networkIcon:string = this.lanIconNotConnected;
  networkTitle:string = '';
  constructor() { }

  ngOnChanges(changes: SimpleChanges):void {
    if (changes.networkInfo.currentValue !== changes.networkInfo.previousValue) {
      if(this.networkInfo !== null && this.networkInfo){
        this.networkTitle = '';
        this.networkIcon = this.lanIconNotConnected;
        if( _.get(this.networkInfo, 'lan_port_status', 'inactive') === 'active') {
          this.networkIcon = this.lanIconConnected;
          this.networkTitle = 'Lan Speed: ' + this.networkInfo.lan_speed + '\nVLAN ID: ' + this.networkInfo.vlan_id;
        }
        else{
          let signalPercent = this.networkInfo.wifi.signal_strength;
          const signalStrength = parseInt(signalPercent.slice(0, signalPercent.length-1));
          this.networkIcon =  this.getWirelessIcon(signalStrength);
          this.networkTitle = 'SSID: ' + this.networkInfo.wifi.ssid + '\nSignal Strength: ' + this.networkInfo.wifi.signal_strength;
        }
      }
    }
  }

  getWirelessIcon(signalStrength: number):string{
    if(signalStrength >= 75){
      return this.wireLess4BarsIcon;
    }
    else if( signalStrength < 75 && signalStrength >= 50 ){
      return this.wireLess3BarsIcon;
    }
    else if( signalStrength < 50 && signalStrength >= 25 ){
      return this.wireLess2BarIcon;
    }
    else{
      return this.wireLess1BarIcon;
    }
  }
}
