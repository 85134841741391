import { GetIotService } from 'src/app/services/get-iot.service'

import { HttpClient } from '@angular/common/http'
import { Component, DoCheck, Input, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'
import { GetVideoService } from '../../services/get-video.service'
import { MainMenuTypeService } from '../../services/mainmenuType.service'
import { MembersTypeService } from '../../services/membersType.service'
import { PopupTypeService } from '../../services/popupType.service'

@Component({
  selector: 'app-polycom-popup',
  templateUrl: './polycom-popup.component.html',
  styleUrls: ['./polycom-popup.component.scss'],
})
export class PolycomPopupComponent implements OnInit, DoCheck {
  constructor(
    public http: HttpClient,
    private Auth: AuthService,
    private formBuilder: FormBuilder,
    private ds: PopupTypeService,
    private dsMm: MainMenuTypeService,
    private dsM: MembersTypeService,
    private getVideoService: GetVideoService,
    private GetIot: GetIotService
  ) { }
  iotTryAgain: boolean = false;
  actionCount: number = 0;
  deviceActionData: any;
  deviceDetails: any;
  videodeviceDetails: any;
  allSiteListData: any;
  deviceActionId: number;
  addDeviceResponse = [];
  errorMessage: String;
  showData: boolean;
  allDeviceListData = [];
  showLoginDetails: boolean;
  deviceLogindetails: any;
  compId: any = '';
  collectorId: '';
  iotDevicePresent = false;
  siteId: -1;
  index: '';
  ipAddress: '';
  userIndex: any;
  runTut = localStorage.getItem('runTut');
  @Input() runTutorial: any;
  audio_device_id: number = 0;
  tutDeviceCheck = [];
  showAddDevice: any = 1;
  showTryAgain: any = 0;
  findDevice: any = 0;
  showConfirm: any = 0;
  popupType: number;
  isAddDeviceInProgress: number = 0;
  highlightNum: number = 1;
  oldHighlightNum: number;
  mediaType: string = 'video';
  ipPlaceholder: string = 'VideoDevice IP';
  namePlaceholder: string = 'Video Admin Name';
  passwordPlaceholder: string = 'Video Device Password';
  iotDeviceData: any;
  iconAddSite = environment.config.dynamicImage.iconAddSite;
  iconVirtual = environment.config.dynamicImage.iconVirtual;
  accessLevel: string;
  hasAccess: boolean;
  searchComplete: boolean = true;
  isDoneAddingDevice: boolean = false;
  iotDevices = [];
  errorMessageIOT = 'Device Not Found';

  // 
  showIotError = false;
  ngOnInit() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.compId = decodedToken.company_id;
    this.accessLevel = decodedToken.accesslevel;
    this.hasAccess = this.getHasAccess();

    if (this.runTutorial == 1) {
      this.findDevice = 1;
    } else {
      this.runTutorial = 0;
      this.showAddDevice = 0;
      this.showTryAgain = 0;
      this.findDevice = 0;
      this.showConfirm = 0;
    }
    this.getAllSiteDetail();
    this.getAlertMonitorStatus();
    this.checkForm();
  }
  control: FormControl;
  siteForm: FormGroup;
  submitted = false;
  model: any = {};
  setTimer: any;
  // convenience getter for easy access to form fields
  get f() {
    return this.siteForm.controls;
  }

  checkForm() {
    const flds = {
      collectorId: ['', [Validators.required]],
      siteId: ['', [Validators.required]],
      index: ['', [Validators.required]],
      ipAddress: ['', [Validators.required]],
      roomName: ['', [Validators.required]],
      username: ['', [Validators.required]],
      visualPro: [''],
      password: ['']
    };

    if (this.mediaType === 'audio') {
      delete flds['username'];
    }

    this.siteForm = this.formBuilder.group(flds);
  }

  onSubmit() {
    console.log('heyy')
    this.submitted = true;
    // stop here if form is invalid
    // if (this.siteForm.invalid) {
    //   return;
    // } else {
      this.addDevice();
    // }
  }
  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };


  // This function gets a list of sites for this company
  getAllSiteDetail() {
    const companyId = {
      company_id: this.compId,
    };
    const deviceData = {
      company_id: this.compId,
      type: 'polycom',
    };

    const otherPolyDevices = ['polycomInf', 'polystudio'];

    this.Auth.getAllSiteList().subscribe((data) => {
      this.allSiteListData = data.response;
      // console.log(this.allSiteListData)
    });

    this.Auth.getAllSiteList().subscribe((data) => {
      this.allSiteListData = data['response'];

      let arr = [];
      for (let d in this.allSiteListData) {
        arr.push(this.allSiteListData[d]);
      }
    });

    this.Auth.getAllDeviceList(deviceData).subscribe((data) => {
      this.allDeviceListData = data['response'];

      console.log('this.allDeviceListData: ', this.allDeviceListData)

      this.tutDeviceCheck = data['count'];
      let arr = [];
      for (let d in this.allDeviceListData) {
        arr.push(this.allDeviceListData[d]);
      }
    });

    //get other devices
    for (let i of otherPolyDevices) {
      this.Auth.getAllDeviceList({ company_id: this.compId, type: i }).subscribe((data) => {
        this.allDeviceListData = this.allDeviceListData.concat(data['response']);
      });
    }
  }

  tutClose() {
    if (this.runTutorial == 1) {
      this.runTutorial = 0;
      this.showAddDevice = 0;
      this.showTryAgain = 0;
      this.findDevice = 0;
      this.showConfirm = 0;

      const token = localStorage.getItem('token');
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      const settut = {
        company_id: decodedToken.company_id,
        user_id: decodedToken.user_id,
        tutorial: 0,
      };
      this.Auth.setTutorial(settut).subscribe((data) => {
        this.sendPopupType(0);
        this.sendMainMenuType(0);
        this.sendMemberType(0);
        this.Auth.runTutorial = 0;
        localStorage.setItem('showCongrats', '1');
      });
    }
  }

  isEdit: number = 0;

  toEdit(n) {
    this.resetForm();
    if (this.isEdit == n) {
      this.isEdit = 0;
      this.isDelete = 0;
    } else {
      this.isEdit = n;
      this.isDelete = 0;
    }
    event.stopPropagation();
  }

  async delay(ms: number) {
    await new Promise((resolve) => setTimeout(() => resolve(''), ms));
  }
  device_name = '';
  videoDeviceId = 0;
  videoDeviceName: any;
  isDelete: number = 0;
  videoDeviceRoomName: any;
  iot_id: number = 0;
  // setDeviceId(deviceDetails) {
  //   this.videoDeviceId = deviceDetails.video_device_id;
  //   this.videoDeviceName = deviceDetails.device_name;
  //   this.videoDeviceRoomName = deviceDetails.room_name;
  // }

  toDelete(n) {
    this.resetForm();
    if (this.isDelete == n) {
      this.isEdit = 0;
      this.isDelete = 0;
    } else {
      this.isDelete = n;
      this.isEdit = 0;
    }
    event.stopPropagation();
  }

  toogleDeleteForm() {
    this.isDelete = 0;
    this.isEdit = 0;
  }
  deleteDevice() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const data = {
      company_id: decodedToken.company_id,
      device_id: this.videoDeviceId,
    };
    this.Auth.deactivateDevice(data).subscribe((data) => {
      if (data['response'][0]['status'] == 'OK') {
        this.getAllSiteDetail();
      }
    });
    this.isDelete = 0;
  }

  setHighlight(n) {
    this.checkForm();
    this.highlightNum = n;
    this.toDisable(0);
  }

  isdisable: number = 0;
  toDisable(n) {
    if (this.isdisable == n) {
      this.isdisable = 0;
    } else {
      this.isdisable = n;
    }
  }

  //This function continues to check wether the found device process status is  completed or failed

  sendAddAndLinkDeviceData() {
    if (this.isAddDeviceInProgress == 0) {
      this.isAddDeviceInProgress = 1;
      this.delay(1000).then((any) => {
        this.isAddDeviceInProgress = 0;
      });

      const token = localStorage.getItem('token');
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      const deviceData = {
        company_id: decodedToken.company_id,
        action_id: this.deviceActionId,
        type: this.mediaType,
      };

      console.log('deviceData: ',deviceData)

      // this.Auth.sendAddDeviceData(deviceData).subscribe((data) => {
      //   // this.isDoneAddingDevice = true;
      //   this.addDeviceResponse = data.response;
      //   // if (this.iot_id !== 0) {
      //   //   this.addIOT(this.iot_id);
      //   // } else {
      //     this.addIOTDevice(deviceData);
      //   // }
      // });
    }
  }

  
  checkStatusIOT = (action_id) => {
    this.Auth.postDeviceActionId({ action_id }).subscribe((actionData) => {
      // console.log('postDeviceActionId: ', actionData)
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 90) {
        this.showIotError = true;
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatusIOT(action_id);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === 'FAILED') {
        this.searchComplete = true;
        this.showIotError = true;
        // console.log('FAILED: ', actionData.response)
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        this.searchComplete = true;
        const [newData] = actionData.response;
        const res2 = JSON.parse(newData.results.results);
        const { nmap: { host } } = res2;
        if (host !== undefined) {
          this.setDataIOT(newData.results);
        } else {
          this.showIotError = true;
          this.errorMessageIOT = "Device Not Found";
        }
        return;
      }
    });
  }
  setDataIOT = (params) => {
    const res2 = JSON.parse(params.results);
    const { nmap: { host } } = res2;
    this.iotDeviceData = {};
    this.iotDeviceData.deviceIP = host.address[0].addr;
    this.iotDeviceData.deviceMac = host.address[1].addr;
    this.iotDeviceData.deviceMake = host.address[1].vendor;
    this.iotDeviceData.devicePorts = JSON.stringify(host.ports.port); //ask if its no empty?
  }
  getIOTDevices() {
    this.GetIot
      .getIotLiteDeviceList(
        'unlinked',
        this.collectorId
      )
      .subscribe((data) => {
        this.iot_id = 0;
        this.iotDevices = data.response;
        let index = this.iotDevices.findIndex(d => d.iot_ip === this.siteForm.controls['visualPro'].value);
        console.log('iot devices index', index);
        if (index !== -1) {
          // this.audio_device_id = this.addDeviceResponse[0].ID;
          this.iot_id = this.iotDevices[index].iot_id;
          this.isDoneAddingDevice = true;
          this.iotDeviceData = {};
          this.iotDeviceData.deviceIP = this.iotDevices[index].iot_ip;
          this.iotDeviceData.deviceMac = this.iotDevices[index].mac_address;
          this.iotDeviceData.deviceMake = this.iotDevices[index].make;
          this.iotDeviceData.devicePorts = null;
          this.showIotError = false;
          this.searchComplete = true;
        } else {
          // this.Auth.getNmap(this.siteForm.controls['collectorId'].value, this.siteForm.controls['visualPro'].value, 'add').subscribe((data) => {
          //   let [res] = data.response;
          //   if(res.status == "OK") {
          //     this.checkStatusIOT(res.action_id);
          //   } else {
          //     this.showIotError = false;
          //     this.searchComplete = true;
          //   }
          // });
        }
      });
  }
  // addIOTDevice = (deviceData) => {
  //   this.GetIot.iotLiteAdd(
  //     this.siteForm.controls['collectorId'].value,
  //     this.siteForm.controls['roomName'].value,
  //     "host",
  //     this.iotDeviceData.deviceIP,
  //     this.iotDeviceData.devicePorts,
  //     this.iotDeviceData.deviceMac,
  //     8,
  //     this.iotDeviceData.deviceMake
  //     ).subscribe((data) => {
  //       this.addIOT(data.response.iot_id);
  //       this.resetForm();
        
  //   });
  // }
  
  addIOT(iot_id) {
    this.GetIot
      .iotLiteLinkDevice(
        iot_id,
        this.addDeviceResponse[0].ID,
        'audio',
      )
      .subscribe(
        (data: any) => {
        this.isDoneAddingDevice = true;
        this.addDeviceResponse[0].message = 'Linking IoT Device successful.';
        this.iotTryAgain = false;
        this.errorMessage = '';
        this.setTimer =  setTimeout(() => {
            this.cancelPressed();
          }, 5000);
        },
        (error) => {
          this.errorMessage = 'Someting went wrong when linking IoT Domotz Device';
        }
      );
  }

  
  sendAddDeviceData() {
    if (this.isAddDeviceInProgress == 0) {
      this.isAddDeviceInProgress = 1;
      this.delay(1000).then((any) => {
        this.isAddDeviceInProgress = 0;
      });

      const token = localStorage.getItem('token');
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      const deviceData = {
        action_id: this.deviceActionId,
        type: this.mediaType,
      };

      console.log('deviceData: ', deviceData)
      this.resetForm();

      this.Auth.sendAddDeviceData(deviceData).subscribe((data) => {
        this.isDoneAddingDevice = true;
        this.addDeviceResponse = data.response;
        setTimeout(() => {
          this.isDoneAddingDevice = false;
        }, 3000);
      });
    } else {
    }
  }


  ngDoCheck() {
    if (this.highlightNum !== this.oldHighlightNum) {
      //Repulls the device data
      if (this.highlightNum === 2) {
        this.getAllSiteDetail();
      }

      //Clears add device form
     this.setTimer= setTimeout(() => {
        this.resetForm();
      }, 300);
      this.oldHighlightNum = this.highlightNum;
    }
  }


 

  resetForm() {
    const roomName = <HTMLInputElement>document.getElementById('roomName');
    const ipAddress = <HTMLInputElement>document.getElementById('ipAddress');
    if (roomName) {
      roomName.value = '';
    }
    if (ipAddress) {
      ipAddress.value = '';
    }
  }

  cancelPressed() {
    this.siteForm.reset();
    this.checkForm();
    this.iotTryAgain = false;
    if (this.runTutorial == 1) {
      this.findDevice = 1;
    } else {
      this.runTutorial = 0;
      this.showAddDevice = 0;
      this.showTryAgain = 0;
      this.findDevice = 0;
      this.showConfirm = 0;
    }

    this.showData = false;
    this.deviceActionData = [];
    this.deviceDetails = [];
    this.showConfirm = 0;
    this.submitted = false;
    this.showAddDevice = 1;
    this.findDevice = 1;
    this.actionCount = 0;
  }

  // This find the device that will be added to the database
  addDevice() {
    this.deviceActionData = [];
    this.deviceDetails = [];
    this.deviceActionId = undefined;
    this.addDeviceResponse = [];
    this.errorMessage = undefined;

    this.showData = false;
    event.preventDefault();
    const target = event.target;
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    const deviceData = {
      company_id: decodedToken.company_id,
      collector_id: (<HTMLInputElement>document.getElementById('collectorId')).value,
      site_id: (<HTMLInputElement>document.getElementById('siteId')).value,
      username:
        this.mediaType !== 'video'
          ? 'Polycom'
          : (<HTMLInputElement>document.getElementById('username')).value,
      password: (<HTMLInputElement>document.getElementById('password')).value,
      room_name: (<HTMLInputElement>document.getElementById('roomName')).value,
      type: this.mediaType === 'audio' ? this.mediaType : 'polycom',
      ip: (<HTMLInputElement>document.getElementById('ipAddress')).value,
      user_id: decodedToken.user_id,
    };


    console.log('deviceData: ', deviceData)



    // console.log(deviceData, 'deviceData')
    this.searchComplete = false; //added
    this.Auth.findDevice(deviceData).subscribe((data) => {
      this.searchComplete = false; //added
      this.deviceActionData = data.response[0];
      this.deviceActionId = data.response[0].action_id;
      // this.deviceActionId = 95;
      const actionData = {
        // action_id: data.response[0].action_id
        action_id: this.deviceActionId,
      };
      if (this.deviceActionData['status'] !== 'Error') {
        // ??
        this.Auth.postDeviceActionId(actionData).subscribe((reponseData) => {
          this.checkStatus(reponseData.response[0].action_id);
          
          // this.getIOTDevices(); // added
        });
      }
    });
    this.showData = true;
  }




  cancelIot() {
    this.iotTryAgain = false;
  }
  checkStatus(id) {
    const idData = {
      action_id: id,
    };
    // console.log(idData)
    this.Auth.postDeviceActionId(idData).subscribe((actionData) => {
      console.log(actionData)
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 90) {
        this.actionCount = 0;
        this.errorMessage = 'Could not find device. Please try again.';
        this.showAddDevice = 0;
        this.showTryAgain = 1;
        this.findDevice = 0;
        this.showConfirm = 0;
        return;
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        setTimeout(function () {
          rk.checkStatus(id);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === 'FAILED') {
        const [newData] = actionData.response
        this.deviceDetails = newData
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        const [newData] = actionData.response
        this.deviceDetails = newData
        this.showAddDevice = 0;
        this.showTryAgain = 0;
        this.findDevice = 0;
        this.showConfirm = 1;

        console.log('here')
        return;
      }
    });
  }

  setIndex(n) {
    //this.clearInputs();
    this.userIndex = n;
    //this.getIndex = (<HTMLInputElement>document.getElementById('add_contact')).value;
    // this.fname = (<HTMLInputElement>document.getElementById('firstname'));
    if (n >= 0) {
      this.collectorId = this.allSiteListData[n].collector_id;
      this.siteId = this.allSiteListData[n].site_id;
      // (<HTMLInputElement>document.getElementById('add_contact')).value =
    }

    if (this.actionCount > 0) this.actionCount = -10;

    this.errorMessage = '';
    this.showAddDevice = 0;
    this.showTryAgain = 0;
    this.showConfirm = 0;
    this.showData = false;
  }

  showLoginMessage: any = false;
  showUpdatedLoginMessage: any = false;
  updatedLoginMessage: any;
  searchingLogin: any = false;
  showTimeoutError: any = false;
  loginErrorMessage: any;

  checkDeviceLoginStatus(id) {
    const idData = {
      action_id: id,
    };

    this.Auth.postDeviceActionId(idData).subscribe((actionData) => {
      this.showLoginMessage = true;
      this.searchingLogin = true;

      if (this.actionCount == 90) {
        this.searchingLogin = false;
        this.actionCount = 0;
        this.showTimeoutError = true;
        this.loginErrorMessage = 'Could not find device. Please try again.';
        return;
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        setTimeout(function () {
          rk.checkDeviceLoginStatus(id);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === 'FAILED') {
        this.searchingLogin = false;
        this.deviceLogindetails = actionData.response[0];
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        this.searchingLogin = false;
        this.deviceLogindetails = actionData.response[0];
        this.getVideoService
          .updateDeviceLogin(this.deviceLogindetails.action_id)
          .subscribe((reponseData) => {
            this.showUpdatedLoginMessage = true;
            this.updatedLoginMessage = reponseData.response[0];
          });
        return;
      }
    });
  }

  changeDeviceDetails(event) {
    event.preventDefault();
    this.showLoginDetails = true;
    const target = event.target;
    const username = target.querySelector('#deviceUsername').value;
    const password = target.querySelector('#devicePassword').value;
    const deviceId = this.videoDeviceId;
    this.getVideoService.getDeviceLoginActionId(username, password, deviceId).subscribe((data) => {
      this.videodeviceDetails = data.response[0];
      this.deviceActionId = data.response[0].action_id;
      const actionData = {
        action_id: data.response[0].action_id,
      };
      if (this.videodeviceDetails['status'] !== 'Error') {
        this.checkDeviceLoginStatus(data.response[0].action_id);
      }
    });
  }

  clearPopupType() {
    this.ds.clearPopupType();
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }

  sendMainMenuType(p) {
    this.dsMm.sendMainMenuType(p);
  }

  sendMemberType(p) {
    this.dsM.sendMemberType(p);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
    this.clearTimeout();

  }

  plycAlertMonitorDtls = [];
  polycomAlert: any;
  polycomMonitor: any;
  getAlertMonitorStatus() {
    const cmpData = {
      company_id: this.compId,
    };

    // this.plycAlertMonitorDtls = [];
    this.Auth.getDeviceAlertMonitorStatus(cmpData).subscribe((data) => {
      this.plycAlertMonitorDtls = data['response'];
      this.polycomAlert = this.plycAlertMonitorDtls[0].polycom_alerts;
      this.polycomMonitor = this.plycAlertMonitorDtls[0].polycom_paused;
    });
  }

  togglePolycomAlerts(notifyflg) {
    const cmpData1 = {
      company_id: this.compId,
      device_type: 'polycom',
    };

    if (notifyflg == 0) {
      this.Auth.setDeviceTypeNotifyOff(cmpData1).subscribe((data) => {
        if (data['response'][0]['status'] == 'OK') {
        }
      });
    } else if (notifyflg == 1) {
      this.Auth.setDeviceTypeNotifyOn(cmpData1).subscribe((data) => {
        if (data['response'][0]['status'] == 'OK') {
        }
      });
    }
    this.getAlertMonitorStatus();
  }

  togglePolycomMonitor(pausedState) {
    const cmpData2 = {
      company_id: this.compId,
      device_type: 'polycom',
    };

    if (pausedState == 0) {
      this.Auth.setDeviceTypeMonitorOn(cmpData2).subscribe((data) => {
        if (data['response'][0]['status'] == 'OK') {
        }
      });
    } else if (pausedState == 1) {
      this.Auth.setDeviceTypeMonitorOff(cmpData2).subscribe((data) => {
        if (data['response'][0]['status'] == 'OK') {
        }
      });
    }
    this.getAlertMonitorStatus();
  }

  toggleMediaType() {
    this.mediaType = this.mediaType == 'video' ? 'audio' : 'video';
    if (this.mediaType == 'video') {
      this.checkForm();
      this.ipPlaceholder = 'Video Device IP';
      this.namePlaceholder = 'Video Device Name';
      this.passwordPlaceholder = 'Video Device Password';
    } else {
      this.checkForm();
      this.ipPlaceholder = 'Audio Device IP';
      this.namePlaceholder = 'Audio Device Name';
      this.passwordPlaceholder = 'Audio Device Password';
    }
  }

  getHasAccess = () => {
    if (this.accessLevel) {
      if (this.accessLevel === 'USER') {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };
}
