import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-install-zoom-plugin',
  templateUrl: './install-zoom-plugin.component.html',
  styleUrls: ['./install-zoom-plugin.component.scss'],
})
export class InstallZoomPluginComponent implements OnChanges {
  @Input() message: string;
  msg: string;
  dPlugin: String = environment.config.dynamicText.dPlugin;
  defaultMessage: string = `Enable advanced features by linking a ${this.dPlugin} in the Admin section of the DNA page.`;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.message) {
      this.msg = this.message;
    } else {
      this.msg = this.defaultMessage;
    }
  }
}
