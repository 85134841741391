import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-health-bar',
  templateUrl: './health-bar.component.html',
  styleUrls: ['./health-bar.component.scss'],
})
export class HealthBarComponent implements OnChanges {
  width: number = 300;

  @Input() total: number;
  @Input() value: number;
  @Input() color: any;
  minMargin: number = 0.15;
  val: number = this.minMargin;
  displayVal: number = 0;
  barLength: number = 0;
  @Input() removeClickable: any;
  @Input() enabled: boolean = true;
  constructor() {}

  calculateBarLength() {
    if (this.value != 0 && this.total != 0 && this.value != null && this.total != null) {
      this.barLength = 100 * (this.value / this.total);
      this.barLength = this.barLength <= 15 ? 15 : this.barLength;
    } else {
      this.barLength = 0;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.value == 0) {
      this.minMargin = 0;
    }
    if (changes.value) {
      this.value = changes.value.currentValue;
    }
    if (changes.total) {
      this.total = changes.total.currentValue;
    }

    if (changes.value || changes.total) {
      if (changes.value && changes.value.firstChange) {
        if ((this.value / this.total) * 100 > 10) {
          // // console.log((this.value/this.total) * 100 + " test")
          this.val = Math.round((this.value / this.total) * 100);
        } else {
          this.val = Math.round((this.minMargin + this.value / this.total) * 100);
        }
        // // console.log(this.val + ": val1")
        this.displayVal = this.value;
      } else {
        this.val = (this.minMargin + this.value / this.total) * 100;
        this.displayVal = this.value;
        // // console.log(this.val + ": val2")
      }
      this.calculateBarLength();
    }
  }
}
