import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'
import { GetVideoService } from '../../services/get-video.service'
import { PopupQosAnimation } from '../../services/qostest.service'

@Component({
  selector: 'app-qos-test',
  templateUrl: './qos-test.component.html',
  styleUrls: ['./qos-test.component.scss'],
  animations: [PopupQosAnimation],
})
export class QosTestComponent implements OnInit {
  popupQosState: string = 'hide';
  @Input() siteId: any;
  qosListData: any;
  @Input() data: any;
  @Input() deviceId: any;
  @Input() isShowQosChartandList: any;
  @Output() isShowQosChartandListChange: EventEmitter<any> = new EventEmitter<any>();
  siteActionData: any;
  siteActionId: any;
  actionCount: number = 0;
  siteQosDetails: any = [];
  testSiteName1: any;
  testSiteName2: any;
  errorMessage: any;
  remoteSiteId: any;
  loader = environment.config.dynamicImage.loader;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private videoService: GetVideoService,
    private authService: AuthService
  ) {
    this.route.params.subscribe((params) => {
      this.siteId = params['id'];
    });
  }

  ngOnInit() {
    this.getQosList();
  }

  calMbps(rawData) {
    var roundnum = rawData / (1024 * 1024);
    let calculatedMbps = Math.round(roundnum * 100) / 100;
    return calculatedMbps;
  }

  getQosList() {
    // console.log(this.deviceId);
    this.videoService.getQOSSiteList(this.deviceId).subscribe((data) => {
      // console.log(data);
      this.qosListData = data['response'];
      // console.log('this is the qos site list: ', this.qosListData);
      // }
    });
  }

  testSiteName(site1, site2) {
    this.testSiteName1 = site1;
    this.testSiteName2 = site2;
  }

  currentActionId;
  getQosActionId(remoteId) {
    // this.siteQosDetails = [];
    this.errorMessage = '';
    let tos =
      (<HTMLInputElement>document.getElementById('tos')).value == ''
        ? 0
        : (<HTMLInputElement>document.getElementById('tos')).value;
    let params =
      '{"tos":"' +
      tos +
      '","mbps":"' +
      (<HTMLInputElement>document.getElementById('mbps')).value +
      '","time":"' +
      (<HTMLInputElement>document.getElementById('time')).value +
      '"}';

    this.videoService.getQOSSiteActionId(this.deviceId, remoteId, params).subscribe((data) => {
      // console.log(data);
      this.siteActionData = data.response[0];
      this.siteActionId = data.response[0].action_id;

      const actionData = {
        action_id: this.siteActionId,
      };

      if (this.siteActionData['status'] !== 'Error') {
        this.authService.postDeviceActionId(actionData).subscribe((resData) => {
          // console.log('Site action res data: ', resData);
          this.currentActionId = resData.response[0].action_id;
          this.checkStatus(resData.response[0].action_id);
        });
      }
    });
  }

  print() {
    let printContents, stylesHtml, linksHtml, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    stylesHtml = this.getTagsHtml('style');
    linksHtml = this.getTagsHtml('link');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          ${stylesHtml}
          ${linksHtml}
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string {
    const htmlStr: string[] = [];
    const elements = document.getElementsByTagName(tagName);

    for (let idx = 0; idx < elements.length; idx++) {
      htmlStr.push(elements[idx].outerHTML);
    }

    return htmlStr.join('\r\n');
  }
  //This function continues to check wether the found device process status is  completed or failed
  checkStatus(id) {
    if (this.currentActionId != id) {
      this.actionCount = 0;
      return;
    }
    const idData = {
      action_id: id,
    };

    this.authService.postDeviceActionId(idData).subscribe((actionData) => {
      // console.log('action count' + this.actionCount);
      if (this.actionCount == 90) {
        this.errorMessage = 'Qos test failed';
        this.popupQosState = this.popupQosState === 'show' ? 'hide' : 'show';
        this.activeSelection = 0;
        this.actionCount = 0;
        this.currentActionId = 0;
        return // console.log('Action count function complete');
      }
      // console.log('Status call: ', actionData.response[0].status);
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        // console.log('Calling action call', actionData.response[0]);
        const rk = this;
        setTimeout(function () {
          rk.checkStatus(id);
        }, 1000);

        this.actionCount++;
      } else if (actionData.response[0].status === 'FAILED') {
        this.siteQosDetails.push(actionData.response[0]);
        this.popupQosState = this.popupQosState === 'show' ? 'hide' : 'show';
        this.activeSelection = 0;
        this.actionCount = 0;
        this.currentActionId = 0;
        // console.log('failed');
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        this.siteQosDetails.push(actionData.response[0]);

        this.popupQosState = this.popupQosState === 'show' ? 'hide' : 'show';
        this.activeSelection = 0;
        this.actionCount = 0;
        this.currentActionId = 0;
        // console.log('complete', actionData.response[0]);
        return;
      }
    });
  }

  currentRemoteSite: any = '';
  isTestButtonPressed: any = false;
  testButtonPressed(e, remoteSite) {
    if (e.textContent == 'test') {
      this.isTestButtonPressed = true;
      if (this.currentRemoteSite != remoteSite && this.currentRemoteSite != '') {
        (<HTMLInputElement>(
          document.getElementById('testButton' + this.currentRemoteSite)
        )).textContent = 'test';
        for (let site of this.qosListData) {
          if (site.remote_site_name != remoteSite) {
            (<HTMLInputElement>(
              document.getElementById('pauseButton' + site.remote_site_name)
            )).textContent = site.remote_qos_paused == 0 ? 'pause' : 'unpause';
          }
        }
        this.errorMessage = '';
        this.siteQosDetails = [];
        this.popupQosState = 'hide';
        this.activeSelection = 0;
        this.actionCount = 0;
        this.currentRemoteId = 0;
        this.currentActionId = 0;
      }

      (<HTMLInputElement>document.getElementById('testButton' + remoteSite)).textContent = 'test';
      e.textContent = 'cancel';
      (<HTMLInputElement>document.getElementById('pauseButton' + remoteSite)).textContent = 'run';
      this.currentRemoteSite = remoteSite;
    } else if (e.textContent == 'cancel') {
      this.isTestButtonPressed = false;
      this.errorMessage = '';
      this.siteQosDetails = [];
      this.popupQosState = 'hide';
      e.textContent = 'test';
      this.currentRemoteId = 0;
      this.actionCount = 0;
      this.activeSelection = 0;
      this.currentRemoteSite = '';
      this.currentActionId = 0;
      for (let site of this.qosListData) {
        (<HTMLInputElement>(
          document.getElementById('pauseButton' + site.remote_site_name)
        )).textContent = site.remote_qos_paused == 0 ? 'pause' : 'unpause';
      }
    } else {
      e.textContent = 'cancel';
    }
  }

  toggleChart(rsid) {
    this.remoteSiteId = rsid;

    if (!this.isShowQosChartandList) {
      this.isShowQosChartandList = !this.isShowQosChartandList;
      this.isShowQosChartandListChange.emit(this.isShowQosChartandList);
    } else {
    }
  }

  activeSelection: number = 0;
  currentRemoteId: number = 0;
  pauseButtonPressed(e, s, r, id) {
    if (e.textContent == 'pause') {
      this.videoService.pauseQosSite(id).subscribe((data) => {
        // console.log(data);
        e.textContent = 'unpause';
        this.getQosList();
      });
    } else if (e.textContent == 'unpause') {
      this.videoService.unPauseQosSite(id).subscribe((data) => {
        // console.log(data);
        e.textContent = 'pause';
        this.getQosList();
      });
    } else if (e.textContent == 'run') {
      // console.log(e.value);
      var version = parseInt(e.value.split('.').join(''));
      // console.log(version);
      if (version < 131) {
        if (confirm('Please update collector')) {
        }
        return;
      }
      if (this.currentRemoteId == id) {
        return;
      }

      this.activeSelection = 1;
      this.testSiteName(s, r);
      this.getQosActionId(id);
      this.currentRemoteId = id;
    } else {
      e.textContent = 'unpause';
    }
  }
}
