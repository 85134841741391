import { GetCollectorService } from 'src/app/services/get-collector.service'

import { Component, Input, OnInit, ViewChild } from '@angular/core'

import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'
import { PopupTypeService } from '../../services/popupType.service'
import { ZoomPluginActionService } from '../../services/zoom-plugin-action.service'

@Component({
  selector: 'app-collector-console',
  templateUrl: './collector-console.component.html',
  styleUrls: ['./collector-console.component.scss']
})
export class CollectorConsoleComponent implements OnInit {
  @Input() siteId: number;
  @Input() data: any;
  @Input() originationIp: string = '0.0.0.0';
  
  actionName: any = 'Ping';
  actionNameRadio: any = 'Ping';
  host: string;
  dscp: number;
  shouldShowLoader: boolean = false;
  loader: String = environment.config.dynamicImage.iotLoader;
  isRunButtonClicked: boolean = false;
  
  setTimer: any
  actionCount: any = 0;
  aDeviceIsFound: any = false;
  showSuccessMsg: any = false;
  successMessage: any = "Device Successfully Added";

  errorMsg: any = "Device Not Found";
  showErrMsg: any = false;
  errorMessage: any = 'Could not find device. Please try again.';

  showDownloading: any = false;

  showAddDevice: any = false;
  showErrorMessage: any = false;

  snapshotMessage: any;
  documentList: any;
  showSnapshotLoader: false;

  isLoading: any = false;
  
  actionResult: any = [];
  collectorSelectActions: any = "1";
  
  disabledDownload: any = false;
  tabIndex: any = 0;
  
  logName: any = '';
  providerName: any = '';
  logId: any = '';
  
  showNetworkTab: any = true;
  checkResultLength: any = false;
  
  @ViewChild('tabGroup') tabGroup;

  constructor(
    public zoomPluginAction: ZoomPluginActionService,
    public ds: PopupTypeService,
    private Auth: AuthService,
    private getCollector : GetCollectorService,

  ) { }
  
  
  ngOnInit() {

    
  }
  
  changeTab = (flag) => {
    this.showNetworkTab = flag;
    console.log('this.showNetworkTab: ', this.showNetworkTab)
    
    if(this.showNetworkTab) {
      this.actionName = "Ping";
    } else {
     this.collectorSelectActions = "1";
    }
  }  
  
  run = () => {
    this.isRunButtonClicked = true;
    this.actionResult = []
  
    if( this.showNetworkTab) {
      this.actionName = this.actionNameRadio;
      let host = this.host;
    
    
      if (!this.host) {
        host = '';
        console.log('no host')
        return;
      }
    
      console.log('this.actionName: ', this.actionName)
      if (this.actionName == 'Ping') {
        this.pingCollector();
      } else {
        this.traceRouteCollector()
      }
      
    } else {
      this.actionName = this.getActionForSelect( this.collectorSelectActions)
      
      console.log('this.actionName: ', this.actionName)
      
      
      if (this.actionName == 'getevents') {
        this.collectorGetEvents();
      } 
      
      if (this.actionName == 'getuninstallfromreg') {
        this.collectorUninstallFromReg();
      } 
      
      if (this.actionName == 'getwin32product') {
        this.collectorGetWin32();
      } 
      
     if (this.actionName == 'netstat') {
        this.collectorNetStat();
      } 
      
      if (this.actionName == 'ps') {
        this.collectorPs();
      } 
      
     if (this.actionName == 'getservices') {
        this.collectorGetServices();
      } 
      
      if (this.actionName == 'scheduletask') {
        this.collectorScheduleTask();
      } 
    }

  }
  
  getActionForSelect = (index) => {
    
    
    const action = [
    'getevents',
    'getuninstallfromreg',
    'getwin32product',
    'netstat',
    'ps',
    'getservices',
    'scheduletask',
    ]
    
    return action[parseInt(index) -1]
  
  }
  
  
  intervalEveryWeekOnChange = (event: any) => {
    let nameOfDays = event.target.value;
    this.collectorSelectActions = nameOfDays;
    
    
    console.log('set ping to disabled')
  };
  
  
  
  pingCollector = ( ) =>  {
    const { collector_id } = this.data;
    this.getCollector.pingCollector(collector_id, this.host).subscribe((data: any) => { 
      const [res] = data.response;
      
      if(data.status == 200) {
      this.zoomPluginAction.visibility_one_loader = true;
      const  { action_id }= res;
      this.checkStatus(action_id)
        
      }
      },
      (err) => { }
    );
  }
  
   traceRouteCollector = ( ) =>  {
    const { collector_id } = this.data;
    this.getCollector.traceRouteCollector(collector_id, this.host).subscribe((data: any) => { 
      const [res] = data.response;
      
      if(data.status == 200) {
      this.zoomPluginAction.visibility_one_loader = true;
      
      const  { action_id }= res;
      this.checkStatus(action_id)
        
      }
      },
      (err) => { }
    );
  }
  
  //NEW
   collectorScheduleTask = ( ) =>  {
    const { collector_id } = this.data;
    this.getCollector.collectorScheduleTask(collector_id).subscribe((data: any) => { 
      const [res] = data.response;
      
      if(data.status == 200) {
      this.zoomPluginAction.visibility_one_loader = true;
      const  { action_id }= res;
      this.checkStatus(action_id)
        
      }
      },
      (err) => { }
    );
  }
  
   collectorGetEvents = ( ) =>  {
    const { collector_id } = this.data;
    
   
    
    this.getCollector.collectorGetEvents(collector_id, this.logName, this.providerName,this.logId).subscribe((data: any) => { 
      const [res] = data.response;
      
      if(data.status == 200) {
      this.zoomPluginAction.visibility_one_loader = true;
      const  { action_id }= res;
      this.checkStatus(action_id)
        
      }
      },
      (err) => { }
    );
  }
  
   collectorUninstallFromReg = ( ) =>  {
    const { collector_id } = this.data;
    this.getCollector.collectorUninstallFromReg(collector_id).subscribe((data: any) => { 
      const [res] = data.response;
      
      if(data.status == 200) {
      this.zoomPluginAction.visibility_one_loader = true;
      const  { action_id }= res;
      this.checkStatus(action_id)
        
      }
      },
      (err) => { }
    );
  }
  
   collectorGetWin32 = ( ) =>  {
    const { collector_id } = this.data;
    this.getCollector.collectorGetWin32(collector_id).subscribe((data: any) => { 
      const [res] = data.response;
      
      if(data.status == 200) {
      this.zoomPluginAction.visibility_one_loader = true;
      const  { action_id }= res;
      this.checkStatus(action_id)
        
      }
      },
      (err) => { }
    );
  }
  
   collectorNetStat = ( ) =>  {
    const { collector_id } = this.data;
    this.getCollector.collectorNetStat(collector_id).subscribe((data: any) => { 
      const [res] = data.response;
      
      if(data.status == 200) {
      this.zoomPluginAction.visibility_one_loader = true;
      const  { action_id }= res;
      this.checkStatus(action_id)
        
      }
      },
      (err) => { }
    );
  }
  
   collectorGetServices = ( ) =>  {
    const { collector_id } = this.data;
    this.getCollector.collectorGetServices(collector_id).subscribe((data: any) => { 
      const [res] = data.response;
      
      if(data.status == 200) {
      this.zoomPluginAction.visibility_one_loader = true;
      const  { action_id }= res;
      this.checkStatus(action_id)
        
      }
      },
      (err) => { }
    );
  }
  
   collectorPs = ( ) =>  {
    const { collector_id } = this.data;
    this.getCollector.collectorPs(collector_id).subscribe((data: any) => { 
      const [res] = data.response;
      
      if(data.status == 200) {
      this.zoomPluginAction.visibility_one_loader = true;
      const  { action_id }= res;
      this.checkStatus(action_id)
        
      }
      },
      (err) => { }
    );
  }
  
  
  //NEW
  
  showFailedProccess: any = false;
	
	checkStatus = (action_id) => {
    console.log('action_id: ', action_id);
    this.Auth.postDeviceActionId({ action_id }).subscribe((actionData) => {
      console.log('postDeviceActionId: ', actionData)
      console.log('actionCount: ', this.actionCount)
      if (this.actionCount < 0) {
        this.actionCount = 0;
      }
      if (this.actionCount == 60) {
        //resets field if device not found 
        this.resetFields();
        this.actionResult =  ' Something went wrong. Please try again. Try running the action again';

        return;
      }
      if (
        actionData.response[0].status !== 'COMPLETE' &&
        actionData.response[0].status !== 'FAILED'
      ) {
        const rk = this;
        this.setTimer = setTimeout(function () {
          rk.checkStatus(action_id);
        }, 1000);
        this.actionCount++;
      } else if (actionData.response[0].status === 'FAILED') {
      
        this.resetFields();
        const [newData] = actionData.response;
         this.actionResult =  ' Something went wrong. Please try again. Try running the action again';
        return;
      } else if (actionData.response[0].status === 'COMPLETE') {
        const [newData] = actionData.response;
        console.log('COMPLETE!!!', newData)
        this.resetFields();
        this.showFailedProccess = true;
        this.actionResult = newData.results.results;
        
      }
    });
  }
  
   resetFields = () => {
    this.actionCount = 0;
    this.showDownloading = false; 
    // this.toggleCollectorLogLoader(false)
    this.zoomPluginAction.visibility_one_loader = false;
    this.actionResult = [];
     this.showFailedProccess = false;
  }

  getActionResults = () => {
  
  
    const actionResult = this.actionResult;
    if (
      actionResult &&
      actionResult.length
    ) {
      const results = [...actionResult];
      return results.map((m) => {
      });
    } 
   

    return [];
  }

  getLoader = () => {
    if (this.zoomPluginAction.visibility_one_loader) {
      return true;
    }
    return false;
  }
  
  downloadLogs = () => {
    console.log('downloadLogs')
    console.log('actionResult: ', this.actionResult)
    
    const date = new Date();
    
    this.downloadString(this.actionResult,"text/plain", this.actionName + date)
  }

 downloadString = (text, fileType, fileName) => {
  var blob = new Blob([text], { type: fileType });

  var a = document.createElement('a');
  a.download = fileName;
  a.href = URL.createObjectURL(blob);
  a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function() { URL.revokeObjectURL(a.href); }, 1500);
}




}
