import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-available',
  templateUrl: './not-available.component.html',
  styleUrls: ['./not-available.component.scss']
})
export class NotAvailableComponent implements OnInit {
  // title: string = "Feature not available.";
  title: string = "Coming soon.";
  constructor() { }

  ngOnInit() {
    // console.log("ON NOT AVAIBLE");
  }

}
