import { Component, OnInit, Input, SimpleChanges, ViewChildren } from '@angular/core';
import { findIndex } from 'lodash';

@Component({
  selector: 'app-health-card',
  templateUrl: './health-card.component.html',
  styleUrls: ['./health-card.component.scss'],
})
export class HealthCardComponent implements OnInit {
  @Input() data: any;
  @Input() mainTitle: string;
  @Input() subTitle: string;
  @Input() iconLink: string;
  enabled: boolean = false;

  bars: Array<any> = [];
  buttons: Array<any> = [];

  getData: Function;
  
  darkTheme: any = true;
  

  constructor() {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      const { bars, buttons, enabled } = changes.data.currentValue;
      this.enabled = enabled || false;
      if (this.enabled) {
        this.bars = bars;
        if (this.buttons && this.buttons.length) {
          buttons.forEach((button) => {
            const i = findIndex(this.buttons, { key: button.key });
            this.buttons[i].count = button.count;
          });
        } else {
          this.buttons = buttons;
        }
      } else {
        this.bars = [1, 2, 3, 4].map((i) => ({ color: 'gray', total: 0, value: 0 }));
        this.buttons = [];
      }
    } else {
      this.bars = [1, 2, 3, 4].map((i) => ({ color: 'gray', total: 0, value: 0 }));
    }
  }

  ngOnInit() {}
}
