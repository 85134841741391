import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class TutCloseService {
  private subject = new Subject<any>();
  constructor() { }

  closeOverlay(message: number) {
    this.subject.next(message);
  }

  clearOverlay() {
    this.subject.next();
  }

  getOverlay(): Observable<any> {
    return this.subject.asObservable();
  }
}
