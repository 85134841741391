import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-get-collector-info',
  templateUrl: './get-collector-info.component.html',
  styleUrls: ['./get-collector-info.component.scss'],
})
export class GetCollectorInfoComponent implements OnInit, OnChanges {
  @Input() collectorParams: any;
  iconAddSite = environment.config.dynamicImage.iconAddSite;
  networkImg = '../../../assets/img/icon_ethernet_02.png';
  driveImg = '../../../assets/img/icon_harddrive.png';

  siteName: string;
  hostName: string;
  lastDataConnect: any;
  network: string;
  version: string;
  cpuUsage: any = 0;
  memoryUtilization: any;
  hdDetails: any;
  collectorInfo: any;

  memoryStatus: any = 0;
  memoryTotal: any;
  memoryUsed: any;

  clrGreen: string = '#90c95a';
  clrRed: string = '#b63c2b';

  constructor(private Auth: AuthService) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.collectorParams) {
      if (this.collectorParams) {
        this.getCollectorInfo(this.collectorParams);
      }
    }
  }

  getCollectorInfo = (params) => {
    this.Auth.getCollectorInfo(params).subscribe((data) => {
      if (data.response) {
        const [
          {
            host_name,
            site_name,
            last_connect,
            version,
            stats_mem_free,
            stats_mem_total,
            stats_cpu_usage,
            stats_hd_details,
          },
        ] = data.response;

        const ldate = new Date(last_connect);
        const mySQLDate = [ldate.getFullYear(), ldate.getMonth() + 1, ldate.getDate()].join('-');
        const mySQLTime = [ldate.getHours(), ldate.getMinutes(), ldate.getSeconds()].join(':');
        this.siteName = site_name;
        this.hostName = host_name;
        this.lastDataConnect = [mySQLDate, mySQLTime].join(' ');
        this.version = String(version).replace(/\.0/g, '');
        this.cpuUsage = stats_cpu_usage > 100 ? 100 : stats_cpu_usage;
        this.memoryStatus = (((stats_mem_total - stats_mem_free) / stats_mem_total) * 100).toFixed(
          1
        );
        this.memoryTotal = (stats_mem_total / Math.pow(1024, 3)).toFixed(2);
        this.memoryUsed = ((stats_mem_total - stats_mem_free) / Math.pow(1024, 3)).toFixed(2);

        if (stats_hd_details) {
          const filterDetails = JSON.parse(stats_hd_details).filter((items) => items.total !== -1);
          this.hdDetails = filterDetails.map((items) => {
            const { drive, free, total } = items;
            return {
              drive,
              free: (free / Math.pow(1024, 3)).toFixed(2),
              total: (total / Math.pow(1024, 3)).toFixed(2),
              percent: (((total - free) / total) * 100).toFixed(1),
            };
          });
        }
      }
    });
  };
}
