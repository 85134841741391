import { Component, OnInit, Input } from '@angular/core';
import { GetDesktopPluginService } from '../../../../services/get-desktop-plugin.service';
import { ZoomPluginActionService } from '../../../../services/zoom-plugin-action.service';
import { PopupTypeService } from '../../../../services/popupType.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-cloud-user-console',
  templateUrl: './cloud-user-console.component.html',
  styleUrls: ['./cloud-user-console.component.scss'],
})
export class CloudUserConsoleComponent implements OnInit {
  @Input() pluginId: number;
  @Input() isDisable: boolean;
  @Input() desktopPluginMessage: string;
  @Input() originationIp: string;
  actionName: string = 'Ping';
  host: string;
  dscp: number;
  shouldShowLoader: boolean = false;
  loader: String = environment.config.dynamicImage.iotLoader;
  isRunButtonClicked: boolean = false;

  constructor(
    private desktopPlugin: GetDesktopPluginService,
    public zoomPluginAction: ZoomPluginActionService,
    public ds: PopupTypeService
  ) { }

  run() {
    this.isRunButtonClicked = true;
    this.zoomPluginAction.clearActionResult();
    let actionParams = {};
    const actionName = this.actionName;
    let host = this.host;
    let dscp = this.dscp;
    if (!this.host) {
      host = '';
    }
    if (!this.dscp) {
      dscp = 46;
    }
    if (this.actionName === 'Ping' || this.actionName === 'Traceroute') {
      actionParams = { host };
    } else if (this.actionName === 'SimulateCallQoS') {
      actionParams = { host, dscp };
    } else if (this.actionName === 'SimulatePortTest') {
      actionParams = { host, port: dscp };
    }
    this.zoomPluginAction.visibility_one_loader = true;
    this.shouldShowLoader = this.zoomPluginAction.visibility_one_loader;
    this.desktopPlugin.createAction(this.pluginId, actionParams, actionName).subscribe((data) => {
      if (data.status === 'Ok') {
        this.zoomPluginAction.checkDesktopPluginActionStatus(data.action_id);
        this.zoomPluginAction.setActionCount();
      } else {
        this.zoomPluginAction.clearActionResult();
        this.zoomPluginAction.visibility_one_loader = false;
      }
    });
  }

  getActionResults() {
    const actionResult = this.zoomPluginAction.actionResult;

    if (
      (this.actionName === 'Ping' || this.actionName === 'Traceroute') &&
      actionResult &&
      actionResult.StdOut &&
      actionResult.StdOut.length
    ) {
      const results = [...actionResult.StdOut];
      return results.map((m) => {
        return m.trim();
      });
    } else if (actionResult && this.actionName === 'SimulateCallQoS') {
      if (actionResult.status === 'OK' && actionResult.CallQosData && actionResult.DscpLossResult) {
        const callQosData = [...actionResult.CallQosData];
        const dscpLoss = [...actionResult.DscpLossResult.Data];

        const callQosDataResult = callQosData.map((q) => {
          const { name, address, lossVal, jitterVal, latencyVal } = q;
          return `Call Qos Data ${name}[${address}]: jitter[${jitterVal === '' ? 0 : jitterVal
            }] latency[${latencyVal === '' ? 0 : latencyVal}] packetloss[${lossVal === '' ? 0 : lossVal
            }] `;
        });

        const dscpLossResult = dscpLoss.map((q) => {
          const { Ip, Hop, Tag, DSCP, Name } = q;
          return `Dscp Loss ${Ip} Hop[${Hop}]${Name}: Tag[${Tag}] DSCP[${DSCP}] `;
        });

        return [...callQosDataResult, ...dscpLossResult];
      } else if (actionResult.status === 'Failed' && actionResult.message) {
        return [actionResult.message];
      }
    } else if (actionResult && this.actionName === 'SimulatePortTest') {
      if (actionResult.status === 'OK' && actionResult.Data && actionResult.Data.length) {
        const results = [...actionResult.Data];

        return results.map((m) => {
          const { Ip, Hop, Name, HasUdpResponse } = m;

          if (!HasUdpResponse) {
            return `Hop [${Hop}] IP: ${Ip} ${Name} | No UPD: ${this.dscp} response. `;
          }

          return `Hop [${Hop}] IP: ${Ip} ${Name}`;
        });
      } else if (actionResult.status === 'Failed' && actionResult.message) {
        return [actionResult.message];
      }
    }
    return [];
  }

  getLoader() {
    if (this.zoomPluginAction.visibility_one_loader) {
      return true;
    }

    return false;
  }

  displayDisableMessage() {
    this.ds.sendPopupType(59, { data: this.desktopPluginMessage });
  }

  isShowDscp() {
    return this.actionName === 'SimulateCallQoS' || this.actionName === 'SimulatePortTest';
  }

  ngOnInit() { }
}
