import { Component, ElementRef, OnInit, Input, SimpleChanges, OnChanges, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { GetCloudService } from '../../../services/get-cloud.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { TableFilterService } from '../../../services/table-filter.service';
import { GetTeamsService } from '../../../services/get-teams.service';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { debounceTime, distinctUntilChanged, map, takeUntil, throttleTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

export interface CloudData {
  companyname: string;
  name: string;
  email: string;
  health: string;
  qos: string;
  livembps: any;
  oncall: string;
  duration: any;
  calendar: any;
  service: string;
}

@Component({
  selector: 'app-cloud-all-users-list',
  templateUrl: './cloud-all-users-list.component.html',
  styleUrls: ['./cloud-all-users-list.component.scss'],
})
export class CloudAllUsersListComponent implements OnInit, OnChanges , OnDestroy , AfterViewInit{
  private subject: Subject<any> = new Subject();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() userTotalCount: any = 0;
  loader = environment.config.dynamicImage.loader;
  @ViewChild('cloudUsersScrollElement', { static: true }) public scrollBarElement: ElementRef;

  public userSearch: string;
  userSearchUpdate = new Subject<string>();

  displayedColumns: string[] = [
    'name',
    // 'email',
    'health',
    'qos',
    'livembps',
    'oncall',
    'duration',
    'calendar',
    'service',
  ];
  filterListner: Subscription;
  pageSizeSelect: any[] = [];
  totalPageLimit = 500;
  totalUserCount: any;
  pageToLoad = 0;
  dataSource: MatTableDataSource<CloudData>;
  totalCount: number;
  totalSearchCount: number;
  pageSelected: any = 0;
  searchkey = '';
  filterSubject: any = '';
  showLoader: any = false;
  cloudUserList: any = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  observable : any;
  lastEventOn: number = 0;

  constructor(
    private getCloudData: GetCloudService,
    public Auth: AuthService,
    private router: Router,
    private getTeams: GetTeamsService,
    private tableFilterService: TableFilterService
  ) {
    this.filterListner = this.tableFilterService.listen().subscribe(
      (x) => {
        this.filterSubject = x;
        // this.filterData();
      },
      (err) => { }
    );

    this.userSearchUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.showLoader = true;
        console.log(value);
        this.callSearch(value);
        this.showLoader = false;
      });
  }
  ngAfterViewInit(): void {

  }
  initOnScrollEvents(){
    const elementContainer = document.querySelector("#cloud-all-user-list");
    console.log("Whats in element container" , elementContainer );
    this.observable = fromEvent( elementContainer , 'scroll');
    const subscriber = this.observable
    .pipe(
      throttleTime(500 /* ms */),
      debounceTime(1000),
      takeUntil(this.destroy$)
    )
    .subscribe(
      (x) => {
        console.log("Scrolled ", x );
        const listsContainerElement = document.querySelector("#cloud-all-user-list")
        var a = listsContainerElement.scrollTop;

        var b = listsContainerElement.scrollHeight - listsContainerElement.clientHeight;
        
        var c = (a / b)*100;

        if(!this.lastEventOn && ~~c > 80){
          this.lastEventOn = ~~c;
          this.loadMoreUsers();
        }
      }
    );
  }
  ngOnInit() {
    this.totalUserCount = this.totalPageLimit;
    this.getData();

    if (this.Auth.accessLevel === 'SUPERADMIN') {
      this.displayedColumns.push('company');
    }
    console.log("On init")

  }

  // myScroller = () => {
  //   const triggerHeight = this.scrollBarElement.nativeElement.scrollTop + this.scrollBarElement.nativeElement.clientHeight;
  //   if (Math.trunc(triggerHeight) == this.scrollBarElement.nativeElement.scrollHeight) { // change to >= because of the 
  //     this.loadMoreUsers();
  //   }
  // }

  searchEvent = (value) => {
    var timer = null;
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.callSearch(value);
    }, 1000);
  };
  handleSearch(event: KeyboardEvent, value) {
    if (!event.key.includes('Arrow')) {
      this.subject.next(value);
    }
  }
  callSearch = (keyValue) => {
    this.searchkey = keyValue;
    if (this.searchkey == '') {
      this.cloudUserList = [];
    }
    this.getData();
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userTotalCount && this.userTotalCount) {
      if (this.userTotalCount > 500) {
        // this.scrollEvent();
        // this.scrollBarElement.nativeElement.addEventListener('scroll', this.myScroller);
        setTimeout(() =>this.initOnScrollEvents(), 1000);
      }
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getData = () => {
    this.populatePage(this.totalPageLimit, this.pageToLoad);
  };

  removeSpaceAndToLowerSearch = (key) => {
    return key !== '' ? key.replace(/\s+/g, '').toLowerCase() : key;
  }

  populatePage = (endIndex = 500, startIndex) => {
    this.showLoader = true;
    const newData = {
      list_limit: this.checkIfSearchKeyIsEmpty() ? endIndex : this.userTotalCount,
      off_set: this.checkIfSearchKeyIsEmpty() ? startIndex : 0,
      search_key: this.removeSpaceAndToLowerSearch(this.searchkey),
      service_type: 'all',
      isImpactedVipUsers: this.router.url == '/cloud/impactedvipuser',
      isImpactedUsers: this.router.url == '/cloud/impacteduser',
      isUserOnCall: this.router.url == '/cloud/livemeetings',
      isVipUsers: this.router.url == '/cloud/vipusers',
      IsUsersWIthDPlugin: this.router.url == '/cloud/userswithdesktopplugin',
    };



    this.getCloudData
      .fetchApiCloudUsers(newData)
      .subscribe((data: any) => {
        this.lastEventOn = 0;
        let newArr = data.response.map((x) => {
          return {
            company: x.company_name,
            zoom_user_id: x.zoom_user_id,
            name: this.setName(x.firstname, x.lastname),
            email: x.email,
            health: x.health,
            qos: x.qos,
            livembps: x.service == 'zoom' ? this.calculateZoomBitrate(x.total_bitrate) : this.checkIfUserIsOnCall(x),
            oncall: x.liveMeetings,
            duration: x.service == 'zoom' ? this.calculateZoomDuration(x.duration) : this.calculateTeamsDuration(x.start_call),
            calendar: x.scheduledMeetings === 0 || x.scheduledMeetings === null ? '--' : x.scheduledMeetings,
            service: x.service,
          };
        });

        if (this.checkIfSearchKeyIsEmpty()) {
          if (this.cloudUserList.length == 0) {
            this.cloudUserList = newArr;
          } else {
            console.log("Concatenating")
            this.cloudUserList = this.cloudUserList.concat(newArr);
          }
        } else {
          this.cloudUserList = newArr;
          this.pageToLoad = 0;
        }
        this.dataSource = new MatTableDataSource(this.cloudUserList);
        this.dataSource.sort = this.sort;

        if (!this.checkIfSearchKeyIsEmpty()) {
          this.totalSearchCount = data.count;
        }
        this.showLoader = false;
      });
  };

  calculateZoomBitrate = (bitrate) => {
    return bitrate !== null ? (bitrate / (1024 * 1024)).toFixed(2) : '--';
  }

  calculateZoomDuration = (duration) => {
    return duration ? duration + 'm' : '--';
  }

  calculateTeamsDuration = (duration) => {
    if (duration == null) {
      return '--';
    }
    let getSeconds = moment().diff(moment(duration), 'seconds');
    return this.convertSeconds(getSeconds);
  }

  convertSeconds = (d) => {
    if (d === 0) {
      return '--';
    }
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    var hDisplay = h > 0 ? h : 0
    var mDisplay = m > 0 ? m : 0
    var sDisplay = s > 0 ? s : 0
    return this.formatSeconds(hDisplay) + ":" + this.formatSeconds(mDisplay) + ":" + this.formatSeconds(sDisplay);
  }

  formatSeconds = (time) => {
    return time === 0 ? "00" : time > 9 ? time : "0" + time;
  }

  getSortOrderDesc = (prop) => {
    return function (a, b) {
      if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] > b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  setName = (firstname, lastname) => {
    return firstname === null ? '--' : firstname + ' ' + lastname;
  };

  clickRow({ zoom_user_id, service }) {
    if (service === 'teams') {
      this.router.navigate(['teams/user/', zoom_user_id]);
    } else {
      this.router.navigate(['cloud/user/', zoom_user_id]);
    }
  }

  getServiceImage = (servicetype) => {
    return servicetype === 'zoom'
      ? '../../../../assets/img/vs_img/zoom_logo.png'
      : '../../../../assets/img/vs_img/teams_logo.png';
  };

  checkIfUserIsOnCall = (params) => {
    const { liveMeetings } = params;
    if (liveMeetings && this.getTeamsPluginEnabled(params)) {
      let networkMonitorTeams = this.getNetworkMonitorTeams(params);
      if (networkMonitorTeams) {
        return this.calculatedMbpsTeams(networkMonitorTeams)
      }
    }
    return '--';
  }

  getTeamsPluginEnabled = (params) => {
    return params.plugin_id != null && params.zoom_plugin_enabled && params.plugin_status == 1
  }

  calculatedMbpsTeams = ({ receive_speed, send_speed }) => {
    if ((receive_speed != null && send_speed != null) && (receive_speed != '' && send_speed != '')) {
      const newMbps = parseFloat(receive_speed.split("K")[0]) + parseFloat(send_speed.split("K")[0]);
      return (newMbps / 122).toFixed(2);
    } else {
      return 0;
    }
  }

  getNetworkMonitorTeams = (params) => {
    const { service_type, zoom_plugin_data } = params;
    let parseZoomData = JSON.parse(zoom_plugin_data);
    if (service_type === 'teams' && parseZoomData !== null) {
      const {
        networkMonitor: { networkMonitorTeams },
      } = parseZoomData;
      return networkMonitorTeams || '--';
    }
    return false;
  };


  loadMoreUsers = () => {
    this.pageToLoad = (this.pageToLoad + 1)*this.totalPageLimit;
    console.log("Fetch next page " , this.pageToLoad );
    this.totalUserCount = this.totalUserCount + this.totalPageLimit
    this.populatePage(this.totalPageLimit, this.pageToLoad);
  }

  checkIfSearchKeyIsEmpty = () => {
    if (this.searchkey === '') {
      return true;
    }
    return false;
  }
}