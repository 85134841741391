import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { GetPluginService } from 'src/app/services/get-plugin.service';
import { GetCloudService } from 'src/app/services/get-cloud.service';
import { PopupTypeService } from '../../../services/popupType.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../../../services/auth.service';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-cloud-room-stats',
  templateUrl: './cloud-room-stats.component.html',
  styleUrls: ['./cloud-room-stats.component.scss'],
})
export class CloudRoomStatsComponent implements OnInit, OnChanges {
  @Input() cloudRoomStats: any;
  @Input() dataReceived: any;
  pluginEnabled: number;
  calculatedMbps: number;
  id: any;
  frameColor: any = 'gray';
  pacColor: any;
  jitColor: any;
  bitColor: any;
  accessLevel: any;
  isNotButton: string;
  hasAccess: boolean;
  isSuperAdmin: boolean;

  connectedMic: any;
  connectedCam: any;
  connectedSpeaker: any;
  pluginStatus: any;

  defaultMic: any;
  defaultCam: any;
  defaultSpeaker: any;

  disablePluginFunction: Boolean = true;

  dPlugin: String = environment.config.dynamicText.dPlugin;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public ds: PopupTypeService,
    private getPluginService: GetPluginService,
    private getCloudService: GetCloudService,
    private Auth: AuthService
  ) {
    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });
  }

  ngOnInit() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.accessLevel = decodedToken.accesslevel;
    this.isSuperAdmin = this.Auth.accessLevel === 'SUPERADMIN';
    this.hasAccess = this.getHasAccess();
    this.isNotButton = !this.hasAccess ? 'not' : 'yes';

    this.ds.sendPopupType(0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cloudRoomStats && this.cloudRoomStats) {
      const zoomConnectedDevices = this.cloudRoomStats.zoom_plugin_connected_devices;
      this.setConnectedDevices(this.cloudRoomStats.connected_devices);
      this.pluginEnabled = this.cloudRoomStats.zoom_plugin_enabled;
      this.pluginStatus = this.cloudRoomStats.plugin_status;
      const zoomConnectedDeviceFlag = this.checkIfZoomPluginHasAnyData(zoomConnectedDevices);
      this.disablePluginFunction = this.checkPluginEnabledAndStatus(
        this.pluginEnabled,
        this.pluginStatus
      );

      // // console.log(this.disablePluginFunction, 'disablePluginFunction')
      if (!this.disablePluginFunction && zoomConnectedDeviceFlag) {
        const { audio_mic, video, audio_speaker } = zoomConnectedDevices;
        this.connectedMic = audio_mic;
        this.connectedCam = video;
        this.connectedSpeaker = audio_speaker;
      } else {
        this.connectedMic = [];
        this.connectedCam = [];
        this.connectedSpeaker = [];
      }
    }
  }
  setConnectedDevices(connected_devices){
    if(connected_devices){
      this.defaultMic = connected_devices.microphone;
      this.defaultCam = connected_devices.camera;
      this.defaultSpeaker = connected_devices.speaker;
    }
  }

  checkIfZoomPluginHasAnyData = (devices) => {
    if (devices == null) {
      return false;
    }
    const { audio_mic, video, audio_speaker } = devices;
    if (audio_mic.length === 0 && video.length === 0 && audio_speaker.length === 0) {
      return false;
    }
    return true;
  };

  checkPluginEnabledAndStatus = (enabled, status) => {
    if (enabled === 1 && status === 1) {
      return false;
    }
    return true;
  };

  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }

  calMbps() {
    if (this.cloudRoomStats) {
      if (this.cloudRoomStats.liveMeetings === 0) {
        return 0;
      } else if (this.cloudRoomStats.liveMeetings === 1) {
        return this.calculateMbpsFromPlugin(this.cloudRoomStats.zoom_plugin_data.callSimulator.zoom.network_monitor);
      } else {
        var roundnum = this.cloudRoomStats.total_bitrate / (1024 * 1024);
        this.calculatedMbps = Math.round(roundnum * 100) / 100;
        return this.calculatedMbps;
      }
    }
  }

  calculateMbpsFromPlugin = (network_monitor) => {
    if ((network_monitor.receive_speed != null && network_monitor.send_speed != null) && (network_monitor.receive_speed != '' && network_monitor.send_speed != '')) {
      const newMbps = parseFloat(network_monitor.receive_speed.split("K")[0]) + parseFloat(network_monitor.send_speed.split("K")[0]);
      return (newMbps / 122).toFixed(2);
    } else {
      return '--';
    }
  };

  lowAsGood = (total, warning, alert) => {
    if (total >= alert) {
      return 'red';
    }
    if (total >= warning && total < alert) {
      return 'yellow';
    }

    return 'gray'
  }

  highAsGood = (total, warning, alert) => {
    if (total <= alert) {
      return 'red';
    }
    if (total > alert && total <= warning) {
      return 'yellow';
    }
    return 'gray'
  }

  colorBit() {
    if (this.cloudRoomStats) {
      this.bitColor =
        this.cloudRoomStats.trigger_limits.bitrate.good == 'low'
          ? this.lowAsGood(
            this.cloudRoomStats.total_bitrate,
            this.cloudRoomStats.triggers.bitrate.warning,
            this.cloudRoomStats.triggers.bitrate.alert
          )
          : this.highAsGood(
            this.cloudRoomStats.total_bitrate,
            this.cloudRoomStats.triggers.bitrate.warning,
            this.cloudRoomStats.triggers.bitrate.alert
          );
      return this.bitColor;
    }
  }

  colorFrame() {
    if (this.cloudRoomStats) {
      return this.frameColor;
    }
  }

  colorJit() {
    if (this.cloudRoomStats) {
      this.jitColor =
        this.cloudRoomStats.trigger_limits.jitter.good == 'low'
          ? this.lowAsGood(
            this.cloudRoomStats.total_jitter,
            this.cloudRoomStats.triggers.jitter.warning,
            this.cloudRoomStats.triggers.jitter.alert
          )
          : this.highAsGood(
            this.cloudRoomStats.total_jitter,
            this.cloudRoomStats.triggers.jitter.warning,
            this.cloudRoomStats.triggers.jitter.alert
          );
      return this.jitColor;
    }
  }

  colorPac() {
    if (this.cloudRoomStats) {
      this.pacColor = this.cloudRoomStats.trigger_limits.packetsloss.good = 'low'
        ? this.lowAsGood(
          this.cloudRoomStats.avg_packet_loss,
          this.cloudRoomStats.triggers.packetsloss.warning,
          this.cloudRoomStats.triggers.packetsloss.alert
        )
        : this.highAsGood(
          this.cloudRoomStats.avg_packet_loss,
          this.cloudRoomStats.triggers.packetsloss.warning,
          this.cloudRoomStats.triggers.packetsloss.alert
        );
      return this.pacColor;
    }
  }

  saveNotes() {
    const notes = (<HTMLInputElement>document.getElementById('exampleFormControlTextarea1')).value;
    const zoomUserId = this.cloudRoomStats.zoom_user_id;
    this.getCloudService.updateNotes(zoomUserId, notes).subscribe((data) => { });
  }

  checkSelfHealing(cloudRoomStats) {
    if (cloudRoomStats) {
      if (cloudRoomStats.plugin_application !== null && cloudRoomStats.plugin_id !== null) {
        return (
          cloudRoomStats.plugin_application[1].v1plugin_selfhealing &&
          cloudRoomStats.zoom_plugin_enabled === 1
        );
      }
      return false;
    }
    return false;
  }

  getFailOver() {
    if (this.cloudRoomStats) {
      if (this.cloudRoomStats.plugin_application !== null) {
        return (
          this.cloudRoomStats.plugin_application[1].v1plugin_failover &&
          this.cloudRoomStats.zoom_plugin_enabled === 1
        );
      }
      return false;
    }
    return false;
  }

  updateSelfHealing(values: any) {
    if (this.cloudRoomStats.plugin_id === null) {
      this.ds.sendPopupType(52);
    } else {
      const actionParams = JSON.stringify({
        isEnabled: values.currentTarget.checked,
      });
      const actionName = 'SetSelfHealingEnabled';
      this.getPluginService
        .createAction(this.cloudRoomStats.plugin_id, actionParams, actionName)
        .subscribe((result) => { });
    }
  }

  isShowPanacast() {
    if (this.cloudRoomStats) {
      if (
        this.cloudRoomStats.zoom_plugin_connected_devices &&
        this.cloudRoomStats.zoom_plugin_enabled === 1
      ) {
        if (
          this.cloudRoomStats.plugin_application &&
          this.cloudRoomStats.plugin_application[0].panacast === -1
        ) {
          return false;
        }
        const cameraList = [...this.cloudRoomStats.zoom_plugin_connected_devices.video];
        const panacast = cameraList.filter((cam) => {
          const camName = cam.name.toLowerCase();
          if (camName.search('panacast') !== -1) {
            return cam.primary;
          }
          return false;
        });
        return panacast.length > 0;
      }
      return false;
    }
    return false;
  }

  isPluginDisableByZaapi() {
    if (this.cloudRoomStats) {
      if (this.cloudRoomStats.plugin_application !== null) {
        return this.cloudRoomStats.plugin_application[0].zaapi !== 1;
      }
      return false;
    }
    return false;
  }

  getZaapiIssueMessage() {
    if (this.cloudRoomStats) {
      if (
        this.cloudRoomStats.zoom_plugin_enabled === 1 &&
        this.cloudRoomStats.plugin_status !== 1
      ) {
        return `${this.dPlugin} is offline. Please check with your Administrator for assistance.`;
      }
    }
    return "Zoom's ZAAPI.exe process is not running. Please check with your Administrator for assistance.";
  }

  getHasAccess = () => {
    if (this.accessLevel) {
      if (this.accessLevel === 'USER' || this.isSuperAdmin) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };
}
