<div class="video-additional">
  <div class="video-additional__text">
    <div class="video-additional__text-item">
      <strong>WiFi MAC:</strong> {{
      wifiMac | displayEmpty
      }}
    </div>

    <!-- <div class="video-additional__text-item">
      <strong>Device Time:</strong>{{
      currentTime | displayEmpty
      }}
    </div> -->

    <div class="video-additional__text-item">
      <strong>Default NTP Server:</strong> {{
      defaultNtpServer | displayEmpty
      }}
    </div>

    <div class="video-additional__text-item">
      <strong>24 Hr Format:</strong> {{
      is24HoursFormat | displayEmpty
      }}
    </div>

    <div class="video-additional__text-item">
      <strong>Auto Date:</strong> {{
      isAutoDate| displayEmpty
      }}
    </div>

    <!-- <div class="video-additional__text-item">
      <strong>NTP Server:</strong> {{
      ntpServer | displayEmpty
      }}
    </div> -->



  </div>
</div>