import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';

import { PopupTypeService } from '../../services/popupType.service';
import { GetTeamsService } from '../../services/get-teams.service';


@Component({
  selector: 'app-teams-user-utilization',
  templateUrl: './teams-user-utilization.component.html',
  styleUrls: ['./teams-user-utilization.component.scss'],
})
export class TeamsUserUtilizationComponent implements OnInit, OnChanges {
  @Input() cloudUserUtilization: any;
  @Input() hasAccess: any;
  @Input() isNotButton: any;
  @Input() spaceStatus: any;
  id: any;
  deviceId: any;
  teamsMeetings: any;

  constructor(public ds: PopupTypeService, private getTeams: GetTeamsService) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cloudUserUtilization && this.cloudUserUtilization) {
      // // console.log(this.cloudUserUtilization)
      this.getTeamsMeetings()
    }
  }

  getTeamsMeetings = () => {
    this.getTeams.getTeamsMeetings(this.cloudUserUtilization.cloudUserId).subscribe(
      (data: any) => {
        this.teamsMeetings = data.length > 0 ? data.length : '--';
        // // console.log('getTeamsMeetings: ', this.teamsMeetings)
        // // console.log('getTeamsMeetings: ', data)
      },
      (err) => { }
    );
  };


}
