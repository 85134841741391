<div class="teams-rooms">
    <div style="overflow: auto; height: 250px;">
        <table class="table table-stripped">
            <thead>
                <tr class="black">
                    <th scope="col">Plugin</th>
                    <th scope="col">Room name</th>
                    <th scope="col">Host name</th>
                    <th scope="col">IP</th>
                    <th scope="col">Room Status</th>
                    <th scope="col">{{ dPlugin }} Status</th>
                    <th scope="col">Monitoring</th>
                    <th scope="col">Link</th>
                    <th *ngIf="shouldShowTh" scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of teamsRoomList">
                    <td>
                        <div *ngIf="item.zoom_plugin_enabled === 0">
                            --
                        </div>
                        <img *ngIf="item.zoom_plugin_enabled === 1" src="{{ getPluginIcon(item.zoom_plugin_enabled) }}"
                            alt="" />
                    </td>
                    <td>
                        {{item.room_name | displayEmpty}}
                    </td>

                    <td>
                        {{getHostName(item.plugin_host_info)}}
                    </td>
                    <td>
                        {{item?.plugin_ip_address !== null ? item.plugin_ip_address : '--'}}
                    </td>
                    <td>
                        <div class="teams-rooms__status {{ getRoomStatus(item.online, item.plugin_id) }}"></div>
                    </td>
                    <td>
                        <div class="teams-rooms__status {{ getDesktopStatus(item.plugin_status) }}"></div>
                    </td>
                    <td>
                        <div class="checkbox_switch">
                            <label class="switch" [ngClass]="{ 'disable-checkbox': item.plugin_id === null}">
                                <input #teamRoomsCheckboxSetMonitored type="checkbox" [checked]="item.is_monitored === 1"
                                    (change)="setMonitored(item.zoom_user_id, teamRoomsCheckboxSetMonitored.checked)" />
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </td>
                    <td>
                        <div (click)="openDesktopLinkModalTeamsRooms(item)">
                            <div>
                                <img src="{{ getLinkIcon(item) }}">
                            </div>
                        </div>
                    </td>
                    <td *ngIf="shouldShowTh">
                        <div *ngIf="spaceStatus === true && item.plugin_id === null" class="fa fa-trash fa-lg"
                            (click)="openDeleteTeamsRoom(item.zoom_user_id, item.room_name)"></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="teams-rooms__separator">
        <div class="teams-rooms__user-count">Room Count:
            <span class="teams-rooms__loader-img" *ngIf="showLoader">
                <img *ngIf="showLoader" [src]="loader" />
            </span>
            <span *ngIf="!showLoader">
                {{ pageCount }}
            </span>
        </div>
    </div>
</div>