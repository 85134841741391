import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GetPluginService } from 'src/app/services/get-plugin.service';
import { ZoomPluginActionService } from '../../services/zoom-plugin-action.service';

@Component({
  selector: 'app-cloud-reboot-plug-in-button',
  templateUrl: './cloud-reboot-plug-in-button.component.html',
  styleUrls: ['./cloud-reboot-plug-in-button.component.scss']
})
export class CloudRebootPlugInButtonComponent implements OnChanges {
  @Input() popupState: any;
  @Input() pluginId: number;
  @Input() checkTeamsRoom: any;
  showRebootPC: boolean;
  showRebootZoom: boolean;
  showRebootTeams: boolean;
  showMessage: boolean;
  message: string;
  isTeamsRoom: boolean = false;

  constructor(
    private getPluginService: GetPluginService,
    public zoomPluginAction: ZoomPluginActionService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.popupState.currentValue == 'open') {
      this.setRebootButton(false);
    }
    if (changes.checkTeamsRoom && this.checkTeamsRoom) {
      this.isTeamsRoom = this.checkTeamsRoom;
    }
  }

  showRebootPCFunc = bool => {
    this.setRebootButton(!bool)
    this.showRebootPC = bool;
    this.showMessage = false;
  }

  showRebootTeamsFunc = bool => {
    this.setRebootButton(!bool)
    this.showRebootTeams = bool;
    this.showMessage = false;
  }

  showRebootZoomFunc = bool => {
    this.setRebootButton(!bool)
    this.showRebootZoom = bool;
    this.showMessage = false;
  }

  setRebootButton = bool => {
    this.showRebootPC = bool;
    this.showRebootZoom = bool;
    this.showMessage = false;
  }

  cancel = () => {
    this.showRebootPC = false;
    this.showRebootZoom = false;
    this.showMessage = false;
  }

  rebootPc = () => {
    this.showMessage = false;
    this.showRebootPC = false;
    const actionParams = {};
    const actionName = 'Reboot';
    this.zoomPluginAction.visibility_one_loader = true;
    this.getPluginService.createAction(this.pluginId, actionParams, actionName).subscribe(result => {
      this.showMessage = true;
      if (result.response[0].status === 'OK') {
        this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
        this.zoomPluginAction.setActionCount();
      }
      else {
        this.zoomPluginAction.visibility_one_loader = false
      }
    });
  }

  restartZoom = () => {
    this.showMessage = false;
    this.showRebootZoom = false;
    const actionParams = {};
    const actionName = 'RestartZoomRoomsApp';
    this.zoomPluginAction.visibility_one_loader = true;

    this.getPluginService.createAction(this.pluginId, actionParams, actionName).subscribe(result => {
      this.showMessage = true;
      if (result.response[0].status === 'OK') {
        this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
        this.zoomPluginAction.setActionCount();
      }
      else {
        this.zoomPluginAction.visibility_one_loader = false;
      }
    });
  }

  restartTeams = () => {
    this.showMessage = false;
    this.showRebootTeams = false;
    const actionParams = {};
    const actionName = 'RestartTeamApp';
    this.zoomPluginAction.visibility_one_loader = true;

    this.getPluginService.createAction(this.pluginId, actionParams, actionName).subscribe(result => {
      this.showMessage = true;
      if (result.response[0].status === 'OK') {
        this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
        this.zoomPluginAction.setActionCount();
        console.log("ZOOM PLUGIN ACTION : ", this.zoomPluginAction.checkActionStatus(result.response[0].action_id))
      }
      else {
        this.zoomPluginAction.visibility_one_loader = false;
      }
    });
  }

}
