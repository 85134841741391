<div class="iot-account-config-ctr">
  <img class="space-button" src="assets/img/audio/Spaces_button.png" (click)="openPopup(84)">
  <div class="title">
    <div class="res-message {{ msgColor }}">
      <h3 *ngIf="shouldShowMsg">
        {{ msg }}
      </h3>
    </div>
  </div>

  <div class="nav-components-ctr">
    <div class="navoptions">
      <div class="navoptions__item navoptions__item--active " (click)="selectActivePanel(1)"
        [class.active]="activePanel === 1">
        enable service
      </div>
      <div class="navoptions__item navoptions__item--active " (click)="selectActivePanel(2)"
        [ngClass]="{'disable': !enabled}" [class.active]="activePanel === 2">
        list of all agents
      </div>
      <div class="navoptions__item navoptions__item--active" (click)="selectActivePanel(3)"
        [ngClass]="{'disable': !enabled}" [class.active]="activePanel === 3">
        list of all devices
      </div>
      <div class="navoptions__item navoptions__item--active" (click)="selectActivePanel(4)"
        [ngClass]="{'disable': !enabled}" [class.active]="activePanel === 4">
        list of monitored devices
      </div>
      <div class="navoptions__item navoptions__item--active" (click)="selectActivePanel(5)"
        [ngClass]="{'disable': !enabled}" [class.active]="activePanel === 5">
        list of all spaces
      </div>
      <div [ngClass]="{'disable': !disableDomotzSlider}" class=" navoptions__checkbox">
        <div class="checkbox_label">
          IoT Service
        </div>
        <div class="checkbox_switch">
          <div>disabled</div>
          <label class="switch">
            <input type="checkbox" [checked]="enabled === 1? true: false" (click)="setEnabled($event)" />
            <span class="slider round"></span>
          </label>
          <div>enabled</div>
        </div>
        <div [ngClass]="{'disable': !disableDomotzSlider}" class=" checkbox_label">
          IoT Notification
        </div>
        <div class="checkbox_switch">
          <div class="">disabled</div>
          <label class="switch">
            <input type="checkbox" [checked]="notification == 1" (click)="setNotification()" />
            <span class="slider round"></span>
          </label>
          <div>enabled</div>
        </div>
      </div>
    </div>
    <div class="vl"></div>
    <div class="components">
      <div *ngIf="activePanel === 1" class="components_item">
        <app-iot-device-enable-service (boolEvent)="ngOnChange($event)" [domotzAccount]="domotzAccount"
          [mspDomotzEnabled]="mspDomotzEnabled" [iotLiteEnabled]="iotLiteEnabled" [companyDetails]="companyDetails">
        </app-iot-device-enable-service>
      </div>
      <div *ngIf="activePanel === 2" class="components_item">
        <app-iot-agent-list [mspDomotzEnabled]="mspDomotzEnabled" [companyDetails]="companyDetails">
        </app-iot-agent-list>
      </div>
      <div *ngIf="activePanel === 3" class="components_item">
        <app-iot-device-list [displayOnlyMonitored]="0" [mspDomotzEnabled]="mspDomotzEnabled"></app-iot-device-list>
      </div>
      <div *ngIf="activePanel === 4" class="components_item">
        <app-iot-device-list [displayOnlyMonitored]="1" [mspDomotzEnabled]="mspDomotzEnabled"></app-iot-device-list>
      </div>
      <div *ngIf="activePanel === 5" class="components_item">
        <app-iot-space-list [isDomotz]="true"></app-iot-space-list>
      </div>
    </div>
  </div>
</div>