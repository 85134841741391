import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-btn-check-cross',
  templateUrl: './btn-check-cross.component.html',
  styleUrls: ['./btn-check-cross.component.scss']
})
export class BtnCheckCrossComponent implements OnInit {
  @Input() isCompatible: boolean = false;
  compatibleUrlImg:string = '../../../../assets/img/compatible.png';
  nonCompatibleUrlImg:string = '../../../../assets/img/non-compatible.png';

  constructor() { }

  ngOnInit() {
  }

  getImgUrl():string {
    return this.isCompatible ? this.compatibleUrlImg : this.nonCompatibleUrlImg;
  }
}
