<div class="row pDiv">
  <div class="col-lg-12 card-margin">
    <!-- <div class="card search-form">
      <div class="card-body p-0"> -->
    <div class="search-form">
      <div>
        <form id="search-form">
          <div class="row">
            <div class="col-12">
              <div class="row no-gutters">
                <div class="col-lg-1 col-md-2">
                  <!-- <select #selectElement class="form-control input-size" id="exampleFormControlSelect1">
                    <option value="all" selected>All</option>
                    <option value="video">Video</option>
                    <option value="audio">Audio</option>
                    <option value="iot">IOT</option>
                    <option value="cloud">Cloud</option>
                    <option value="site">SITE</option>
                  </select> -->
                <div ngbDropdown class="d-inline-block">
                  <img src="../../../assets/img/menu-bar.png" class="w-75" ngbDropdownToggle>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <span ngbDropdownItem *ngFor="let quadrant of searchQuadrants" (click)="selectedQuadrant = quadrant" [ngClass]="{'dropdown-item-checked': selectedQuadrant === quadrant}">
                      {{ quadrant | titlecase}}
                    </span>
                  </div>
                </div>
                </div>
                <div class="col-lg-8 col-md-6">
                  <input type="text" placeholder="Search here" class="form-control input-size bg-light" id="search" name="search"
                    #searchInput />
                </div>
                <div class="col-lg-3 col-md-4">
                  <!-- <button type="submit" class="btn btn-base" (click)="search(searchInput.value , selectElement.value)"> -->
                  <button type="submit" class="btn btn-block bg-blue text-white h-100" (click)="search(searchInput.value, selectedQuadrant)">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-search" *ngIf="!searching">
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                      style="margin: auto; display: block" width="200px" height="200px" viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid" *ngIf="searching">
                      <circle cx="50" cy="50" r="0" fill="none" stroke="#c4bebc" stroke-width="2">
                        <animate attributeName="r" repeatCount="indefinite" dur="1s" values="0;40" keyTimes="0;1"
                          keySplines="0 0.2 0.8 1" calcMode="spline" begin="0s"></animate>
                        <animate attributeName="opacity" repeatCount="indefinite" dur="1s" values="1;0" keyTimes="0;1"
                          keySplines="0.2 0 0.8 1" calcMode="spline" begin="0s"></animate>
                      </circle>
                      <circle cx="50" cy="50" r="0" fill="none" stroke="#774023" stroke-width="2">
                        <animate attributeName="r" repeatCount="indefinite" dur="1s" values="0;40" keyTimes="0;1"
                          keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.5s"></animate>
                        <animate attributeName="opacity" repeatCount="indefinite" dur="1s" values="1;0" keyTimes="0;1"
                          keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.5s"></animate>
                      </circle>
                    </svg> -->
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="toaster" toastContainer>

  </div>
  <search-results></search-results>
</div>