import { Component, Input, SimpleChanges, OnChanges } from "@angular/core";
import { environment } from '../../../../environments/environment';

@Component({
  selector: "app-btn-reg-stats",
  templateUrl: "./btn-reg-stats.component.html",
  styleUrls: ["./btn-reg-stats.component.scss"]
})
export class BtnRegStatsComponent implements OnChanges {
  @Input() count: number;
  @Input() label: string = "Not-Active";
  @Input() color: string;
  @Input() backcolorbtn: any;
  @Input() isbutton: string;
  @Input() active: boolean;
  @Input() activeN: any;
  @Input() activeBtn: number;
  @Input() loading: any;

  setTimer: any;
  prevcount: string;
  loader = environment.config.dynamicImage.loader;

  constructor() { }

  activeSelection = 0;
  setActive(n) {
    if (this.activeBtn == n) {
      this.activeBtn = 0;
    } else {
      this.activeBtn = n;
    }
  }



  ngOnChanges (changes: SimpleChanges) {
    if (changes.count) {
      if (changes.count.currentValue != changes.count.previousValue) {
        this.backcolorbtn = "btnbacklite";
        this.setTimer = setTimeout(() => {
          this.backcolorbtn = "btnbacklite-default";
        }, 2500);
      } else {
        this.setTimer = setTimeout(() => {
          this.backcolorbtn = "btnbacklite-default";
        }, 1000);
      }
    }
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    clearTimeout(this.setTimer);
  };
}
