<!-- <div class="row justify-content-end mb-3" >
  <div class="col-6">
    <input #search class="search mt-2 form-control"  style="margin-top: 3.5rem!important;" type="text" placeholder="Search..." 
    (keydown.enter)="onSearch(search.value)"
    />
  </div>
</div> -->
<!-- <app-filter-table 
  [tableSettings]="videoSitesTableSetting"
  [tableSeedData]="videoListData"
  [clickEvent]="onClickTable"
  >
</app-filter-table>		 -->
<app-video-allsites-list-table  [videoSitesListData] = "videoSitesListData"></app-video-allsites-list-table>

