import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { GetCloudService } from '../../../services/get-cloud.service';
import { environment } from '../../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-video-all-incidents-list',
  templateUrl: './video-all-incidents-list.component.html',
  styleUrls: ['./video-all-incidents-list.component.scss']
})
export class VideoAllIncidentsListComponent implements OnChanges, OnInit {
  @Input() currentChart: string = '60m';
  @Input() backcolorbtn: any;
  @Input() data:any;
  @Input() deviceType:any;
  @Output() printData = new EventEmitter();
  loading: boolean = false;
  minDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate() - 7
  };
  maxDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  isLoading = false;
  searchObject = {
    searchValue: '',
    searchIncident: '',
    startDate: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() - 7 },
    endDate: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() },
    startTime: { hour: 0, minute: 0, second: 0 },
    endTime: { hour: 23, minute: 59, second: 0 }
  }
  applyFilter = false;
  videoInsData: any;
  showSpinner = false;
  loader = environment.config.dynamicImage.loader;
  columnsFlow = {
    headers: [],
    cols: []
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getCloudDataService: GetCloudService,
    private Auth: AuthService) { }
    
  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    
    if (this.data.section.includes('mbps')) {
      this.columnsFlow = {
        headers: ['Live Mbps', 'Latency', 'Jitter', 'Packet Loss'],
        cols: ['mbps', 'latency', 'jitter', 'packetloss']
      }
    } else if (this.data.section.includes('latency')) {
      this.columnsFlow = {
        headers: ['Latency', 'Jitter', 'Packet Loss', 'Live Mbps'],
        cols: [ 'latency', 'jitter', 'packetloss', 'mbps']
      };
    } else if (this.data.section.includes('jitter')) {
      this.columnsFlow = {
        headers: ['Jitter', 'Packet Loss', 'Live Mbps', 'Latency'],
        cols: ['jitter', 'packetloss', 'mbps', 'latency']
      }
    } else if (this.data.section.includes('packetloss')) {
      this.columnsFlow = {
        headers: ['Packet Loss', 'Live Mbps', 'Latency', 'Jitter'],
        cols: ['packetloss', 'mbps', 'latency', 'jitter']
      }
    }
    this.isDescendingOrder = true;
    this.getDataFromApi();
  }
  
  getDataFromApi =() => {
    let duration = 0;
    if (this.currentChart === '60m') duration = 1;
    if (this.currentChart === '24h') duration = 24;
    if (this.currentChart === '7d') duration = 7;
    const token = localStorage.getItem('token')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    let deviceDetails = {
      company_id: decodedToken.company_id,
      device_id: this.data.deviceId,
      duration: duration
    };
    this.isLoading = true;
    console.log('deviceDetails: ', deviceDetails)
    this.Auth.getAllInsChartListDataNoRound(deviceDetails).subscribe((data) => {
      let myResponse = data['response'];
      console.log('myResponse: ', myResponse)
      let arr3 = [];
      for (let k = 0; k < myResponse.length; k++) {
        let sectionData = this.getSectionDataForTable(myResponse[k]);
        arr3.push({
          timestamp: new Date(myResponse[k].timestamp),
          totalSectionData: sectionData,
          totalRx: this.getRxForTable(myResponse[k]),
          totalTx: this.getTxForTable(myResponse[k]),
          videoTotal: this.getTypeContentTotal(myResponse[k], 'video_'),
          videoRx: this.getTypeContent(myResponse[k], 'video_', 'rx'),
          videoTx: this.getTypeContent(myResponse[k], 'video_', 'tx'),
          audioTotal: this.getTypeContentTotal(myResponse[k], 'audio_'),
          audioRx: this.getTypeContent(myResponse[k], 'audio_', 'rx'),
          audioTx: this.getTypeContent(myResponse[k], 'audio_', 'tx'),
          contentTotal: this.getTypeContentTotal(myResponse[k], 'content_'),
          contentRx: this.getTypeContent(myResponse[k], 'content_', 'rx'),
          contentTx: this.getTypeContent(myResponse[k], 'content_', 'tx'),
          incident_id: myResponse[k].video_incident_id ? 'V-'+myResponse[k].video_incident_id : ''
        });
      }
      //Loads the API json data into the company list data source
      this.videoInsData = arr3;
      this.printData.emit(this.videoInsData);
      this.isLoading = false;
    });
  }
  getTypeContent (myData, type: string, param: string) {
    let sectionData = {
      mbps: '',
      latency: '',
      jitter : '',
      packetloss: '',
    };
    sectionData.mbps = this.fixDecimalPoint(myData[type + param].mbps);
    sectionData.latency = this.deviceType == 'cisco' ? '--' : this.fixDecimalPoint(myData[type + param].latency);
    sectionData.jitter = this.fixDecimalPoint(myData[type + param].jitter);
    sectionData.packetloss = this.fixDecimalPoint(myData[type + param].packetloss);
    return sectionData;
  }
  getTypeContentTotal(myData, type: string) {
    let sectionData = {
      mbps: '',
      latency: '',
      jitter : '',
      packetloss: '',
    };
    sectionData.mbps = this.fixDecimalPoint(myData[type + 'rx'].mbps + myData[type + 'tx'].mbps);
    sectionData.latency = this.deviceType == 'cisco' ? '--' : this.fixDecimalPoint(myData[type + 'rx'].latency + myData[type + 'tx'].latency);
    sectionData.jitter = myData[type + 'rx'].jitter + myData[type + 'tx'].jitter;
    sectionData.packetloss = myData[type + 'rx'].packetloss + myData[type + 'tx'].packetloss;
    return sectionData;
  }
  getSectionDataForTable (myData) {
    let sectionData = {
      mbps: '',
      latency: '',
      jitter : '',
      packetloss: '',
    };
    sectionData.mbps = this.fixDecimalPoint(myData.total_mbps);
    sectionData.latency = this.deviceType == 'cisco'? '--': this.fixDecimalPoint(myData.total_latency);
    sectionData.jitter = this.fixDecimalPoint(myData.total_jitter);
    sectionData.packetloss = myData.total_packetloss;
    return sectionData;
  }
  getRxForTable (myData) {
    let rx = {
      mbps: '',
      latency: '',
      jitter : '',
      packetloss: '',
    };
    rx.mbps = this.fixDecimalPoint(myData.rx_mbps);
    rx.latency = this.deviceType == 'cisco'? '--': this.fixDecimalPoint(myData.video_rx.latency + myData.audio_rx.latency + myData.content_rx.latency);
    rx.jitter = this.fixDecimalPoint(myData.video_rx.jitter + myData.audio_rx.jitter + myData.content_rx.jitter);
    rx.packetloss = myData.video_rx.packetloss + myData.audio_rx.packetloss + myData.content_rx.packetloss;
    return rx;
  }
  getTxForTable (myData) {
    let tx = {
      mbps: '',
      latency: '',
      jitter : '',
      packetloss: '',
    };
    tx.mbps = this.fixDecimalPoint(myData.tx_mbps);
    tx.latency = this.deviceType == 'cisco'? '--': this.fixDecimalPoint(myData.video_tx.latency + myData.audio_tx.latency + myData.content_tx.latency);
    tx.jitter = this.fixDecimalPoint(myData.video_tx.jitter + myData.video_tx.jitter + myData.content_tx.jitter);
    tx.packetloss = myData.video_tx.packetloss + myData.audio_tx.packetloss + myData.content_tx.packetloss;
    return tx;
  }
  fixDecimalPoint = (dec) => {
    if(dec % 1 !== 0) {
      if(dec.toString().length > 5) {
        // return dec.toFixed(2);
      }
    }
    return dec;
  }
  clearFilters() {
    if (this.currentChart === '60m') {
      this.searchObject.startTime = {
        hour: new Date().getHours() - 1,
        minute: new Date().getMinutes(),
        second: 0
      };
      this.searchObject.endTime = {
        hour: new Date().getHours(),
        minute: new Date().getMinutes(),
        second: 0,
      };
    } else if (this.currentChart === '24h' || this.currentChart === '7d') {
      this.searchObject.startTime = {
        hour: 0,
        minute: 0,
        second: 0
      };
      this.searchObject.endTime = {
        hour: 23,
        minute: 59,
        second: 0
      };
    }
    this.searchObject.startDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() - 7 };
    this.searchObject.endDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.searchObject.searchIncident = '';
    this.searchObject.searchValue = '';
    this.applyFilter = !this.applyFilter;
  }
  submit() {
    this.applyFilter = !this.applyFilter;
  }
  ngOnInit() {
    //GET request for audio users
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }
  isDescendingOrder = true;

  sort() {
    this.isDescendingOrder = !this.isDescendingOrder;
    function compareAscending(a, b) {
      if (a.timestamp < b.timestamp)
        return -1;
      if (a.timestamp > b.timestamp)
        return 1;
      return 0;
    }
    function compareDescending(a, b) {
      if (a.timestamp < b.timestamp)
        return 1;
      if (a.timestamp > b.timestamp)
        return -1;
      return 0;
    }

    if (this.isDescendingOrder) {
      let element = document.getElementById('arrow').innerHTML = "Time Stamp▾";
      this.videoInsData.sort(compareDescending)

    }
    else {
      let element = document.getElementById('arrow').innerHTML = "Time Stamp▴";
      this.videoInsData.sort(compareAscending)
    }

  }
}