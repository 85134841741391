import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GetPluginService } from 'src/app/services/get-plugin.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-logitech-firmware-update',
  templateUrl: './logitech-firmware-update.component.html',
  styleUrls: ['./logitech-firmware-update.component.scss'],
})
export class LogitechFirmwareUpdateComponent implements OnChanges {
  @Input() logitechData: any;
  progressBarPercentage: number = 0;
  pluginStatus: number;
  pluginId: number;
  dPlugin: String = environment.config.dynamicText.dPlugin;
  message: any = {
    text: '',
    status: 'ok',
  };

  logitechRally: any = {
    uuid: '',
  };
  rallyDevices: any = {};

  constructor(private getPluginService: GetPluginService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.logitechData) {
      // console.log('logitechData:', this.logitechData);
      this.init();
      this.firmwareUpdateStatusTracker();
    }
  }

  init = () => {
    this.pluginStatus = this.logitechData.pluginStatus;
    this.pluginId = this.logitechData.pluginId;
    const logictechDevices = [...this.logitechData.AllProducts.Products];
    const latestFirmwareByProductId = [...this.logitechData.LatestFirmwareByProductId];
    const rally = logictechDevices.find((e) => e.Name === 'Rally');
    this.logitechRally = {
      uuid: rally.Uuid,
      firmwarePackageVersion: rally.FirmwarePackageVersion,
    };

    // rally latest firmware
    const rallyLatestFirmware = latestFirmwareByProductId.find(
      (e) => e.ProductUuid === this.logitechRally.uuid
    );
    this.logitechRally.latestFirmwarePackageVersion =
      rallyLatestFirmware.LatestFirmwarePackageVersion;
    let t = moment.unix(rallyLatestFirmware.LatestFirmwarePublishedDate);
    this.logitechRally.latestFirmwarePublishedDate = t;
    this.logitechRally.latestDeviceFirmwareVersions =
      rallyLatestFirmware.LatestDeviceFirmwareVersions;
    this.logitechRally.errors = rallyLatestFirmware.Errors;

    // getting rally devices
    rally.Devices.forEach((device) => {
      if (
        device.Name === 'Rally Table Hub' ||
        device.Name === 'Rally Camera' ||
        device.Name === 'Rally Display Hub' ||
        device.Name === 'Rally Mic Pod'
      ) {
        this.rallyDevices[device.Name] = {
          name: device.Name,
          uuid: device.Uuid,
          connectionState: device.ConnectionState,
          formFactor: device.FormFactor,
          type: device.Information[0].Type,
          firmwareVersion: device.Information[0].FirmwareVersion,
          availableFirmwareVersion: this.getLatestAvailableFirmware(
            device.Information[0].Type,
            device.FormFactor
          ),
        };
      }
    });
    // console.log('this.rallyDevices:', this.rallyDevices);
  };

  firmwareUpdateStatusTracker = () => {
    const firmwareUpdateProgress = { ...this.logitechData.FirmwareUpdateProgress };
    const updates = [...firmwareUpdateProgress.Updates];

    if (updates.length > 0) {
      const update = updates.find((e) => e.ProductUuid === this.logitechRally.uuid);
      if (update) {
        this.progressBarPercentage = update.OverallProgress;
      }
    }
  };

  getLatestAvailableFirmware = (type, formFactor) => {
    for (let firmware of this.logitechRally.latestDeviceFirmwareVersions) {
      if (firmware.Type === type && firmware.DeviceFormFactor === formFactor) {
        return firmware.LatestFirmwareVersion;
      }
    }
    return null;
  };

  updateRallyFirmware = () => {
    this.message = {
      text: '',
      status: 'ok',
    };
    if (this.pluginStatus === 1) {
      if (
        this.logitechRally.firmwarePackageVersion ===
        this.logitechRally.latestFirmwarePackageVersion
      ) {
        this.message = {
          text: `Firmware is up to date...`,
          status: 'error',
        };
      } else {
        const actionParams = JSON.stringify({
          productUuid: this.logitechRally.uuid,
        });
        const actionName = 'UpdateFirmwareByProductId';
        this.getPluginService
          .createAction(this.pluginId, actionParams, actionName)
          .subscribe((result) => {});
      }
    } else {
      this.message = {
        text: `${this.dPlugin} is offline. Unable to update firmware.`,
        status: 'error',
      };
    }
  };
}
