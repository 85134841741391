

import { Component, OnInit, Input, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import { GetIotService } from '../../services/get-iot.service';
import { RefreshApiService } from '../../services/refresh-api.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';



export interface DeviceListInterface {
  mac_address: string;
  iot_name: string;
  iot_ip: string;
  device_id: number;
  collector_id: number;
  quadrant_group: string;
  make: string;
  is_monitored: string;
}


@Component({
  selector: 'app-logitech-device-list',
  templateUrl: './logitech-device-list.component.html',
  styleUrls: ['./logitech-device-list.component.scss']
})
export class LogitechDeviceListComponent implements OnInit {
  iotDeviceList: any = [];
  @Input() displayOnlyMonitored: any;

  /* pagination variables */
  pageSizeSelect: any[] = [];
  totalPageLimit = 500;
  paginateOjb: any = {
    currentPage: 1,
    endIndex: 4,
    pageSize: 0,
    pages: [],
    startIndex: 0,
    totalItems: 0,
    totalPages: 0,
  };
  totalCount: number;
  totalDeviceCount = 0;
  pageSelected: any = 0;
  searchKey: any = 'null';
  shouldShowLoaderBtn: Boolean = false;
  filterListner: Subscription;
  refreshData: Boolean;
  iconAddSite = environment.config.dynamicImage.iconAddSite;


  displayedColumns: any[] = [
    'site_id',
    'device_name',
    'room_name',
    'site_name',
    'ip_address',
    'status',
  ];

  dataSource: MatTableDataSource<DeviceListInterface>;
  loader = environment.config.dynamicImage.loader;

  constructor(
    private getIot: GetIotService,
    private refreshApiService: RefreshApiService,
    private Auth: AuthService
     ) {
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if (m) {
        this.refreshData = m;
        this.getApiData();
      }
    });
  }
  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  getApiData() {
    if (this.refreshData) {
      // this.refreshtIotDeviceList();
    }
  }

  ngOnInit() {
    // this.getIotLiteDeviceList();
    this.getCompanyId();
    
  }

  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.compId = decodedToken.company_id;
    this.getAllDeviceList();
  }

  allSiteListData: any;
  allDeviceListData: any;
  tutDeviceCheck: any;
  compId: any;

  //fix this code
  getAllDeviceList = () => {
    // const companyId = {
    //   company_id: this.compId,
    // };
    const deviceData = {
      company_id: this.compId,
      type: 'logitech',
    };

    // const otherPolyDevices = ['polycomInf', 'polystudio'];

    // this.Auth.getAllSiteList().subscribe((data) => {
    //   this.allSiteListData = data.response;
    //   // console.log(this.allSiteListData)
    // });

    // this.Auth.getAllSiteList().subscribe((data) => {
    //   this.allSiteListData = data['response'];

    //   let arr = [];
    //   for (let d in this.allSiteListData) {
    //     arr.push(this.allSiteListData[d]);
    //   }
    // });

    this.Auth.getAllDeviceList(deviceData).subscribe((resp) => {
      if (resp.status == 200) {
        this.dataSource = new MatTableDataSource(resp['response']);
        this.totalDeviceCount = resp['count'];
      }
      
      // let arr = [];
      // for (let d in this.allDeviceListData) {
      //   arr.push(this.allDeviceListData[d]);
      // }
    });

    //get other devices
    // for (let i of otherPolyDevices) {
    //   this.Auth.getAllDeviceList({ company_id: this.compId, type: i }).subscribe((data) => {
    //     this.allDeviceListData = (this.allDeviceListData || []).concat(data['response']);
    //      this.totalDeviceCount = this.allDeviceListData.length;
    //     this.dataSource = new MatTableDataSource(this.allDeviceListData);
    //     this.dataSource.filterPredicate = function (record: any, filter) {
    //       return (record.device_name || '' ).toLocaleLowerCase().includes( (filter || '').toLocaleLowerCase());
    //     }
    //   });

    // }

  }

  getIotLiteDeviceList = () => {
    // this.shouldShowLoaderBtn = true;
    // this.getIot
    //   .getIotLiteDeviceList(
    //     'all',
    //     1
    //   )
    //   .subscribe((data) => {
    //     this.shouldShowLoaderBtn = false;
    //     const res = data.response
    //     // console.log(res,'res')
    //     this.iotDeviceList = res;
    //     this.dataSource = new MatTableDataSource(this.iotDeviceList);
    //   },
    //    (err) => {
    //     this.shouldShowLoaderBtn = false;
    //     }
    //   );
  };

 
  getStatusColor = ({ status, agent_status }) => {
    if (agent_status === 'OFFLINE') {
      return ' ';
    } else {
      if (status === 'ONLINE') {
        return 'iot-device-list__status-color--green';
      } else {
        return 'iot-device-list__status-color--red';
      }
    }
  };

  setIsMonitored = (_id, _flag) => {
    const flag = this.setFlag(_flag);
    this.getIot.iotLiteSetMonitor(_id, flag).subscribe(
      (data) => {
        // console.log('setIsMonitored: ', data)
       },
      (error) => { }
    );
  };

  setFlag = (flag) => (flag ? 0 : 1);

  searchData = (key) => {
    this.searchKey = key;
  };
  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
