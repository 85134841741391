<div id="site-overlay" [class.tut-show]="setoverlay == 'create'"></div>
<div class="tut-create col-4" [class.tut-show]="setoverlay == 'create'">
  Please complete the form to create your first site.
</div>
<div class="container-fluid edit-site" id="main-popup" [class.tut-overlay]="setoverlay == 'create'">
  <div class="row">
    <div class="col-12">
      <form role="form" class="signup site {{ styleProp }}" name="siteForm" [formGroup]="siteForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="form-group">
          <input type="text" formControlName="sitename" class="form-control" [(ngModel)]="siteName"
            [ngClass]="{ 'is-invalid': submitted && f.sitename.errors }" placeholder="Sitename" name="sitename"
            id="sitename" autocomplete="additional-name" required />
          <div *ngIf="submitted && f.sitename.errors" class="invalid-feedback">
            <div *ngIf="f.sitename.errors.required" id="confirmMessage">!</div>
          </div>
        </div>

        <div class="form-group">
          <input type="text" formControlName="address1" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" [(ngModel)]="siteAddress1" placeholder="Address1"
            name="address1" id="address1" autocomplete="street-address" required />
          <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
            <div *ngIf="f.address1.errors.maxlength" class="phonetext alert alert-danger p-2 m-0">
              Exceed Maximum 50 character
            </div>
            <div *ngIf="f.address1.errors.required" id="confirmMessage">!</div>
          </div>
        </div>

        <div class="form-group">
          <input type="text" class="form-control" formControlName="address2" id="address2" [(ngModel)]="siteAddress2"
            placeholder="Address2" autocomplete="address-line2" />
        </div>

        <div class="form-group">
          <input type="text" formControlName="city" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.city.errors }" [(ngModel)]="siteCity" placeholder="City" name="city"
            id="city" autocomplete="city" required />
          <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
            <div *ngIf="f.city.errors.required" id="confirmMessage">!</div>
            <div *ngIf="f.city.errors.maxlength" class="phonetext alert alert-danger p-2 m-0">
              Exceed Maximum 30 character
            </div>
          </div>
        </div>

        <!-- NEW WELLNESS -->
        <!-- MAKE THIS A COMPONENT NEXT TIME -->
        <div class="edit-site__wellness-check-container">
          <div class="edit-site__confirm-message-container">
            <div class="edit-site__confirm-message {{ firmwareSuccessMessageClass }}">
              {{ firmwareSuccessMessage }}
            </div>

            <div class="edit-site__confirm-container-wellness">
              <div [ngClass]="{ 'edit-site__disable': !wellnessCheckEnable }"
                class="edit-site__confirm-container-wellness-items wellness-select">
                <label for="">Schedule Interval :</label>
                <select id="index" (change)="intervalOnChange($event)" [(ngModel)]="wellnessInterval"
                  [ngModelOptions]="{ standalone: true }" style="width: 150px !important">
                  <option value="1" selected>Daily</option>
                  <option value="7">Weekly</option>
                  <option value="14"> Bi-Weekly</option>
                  <!-- <option value="30">Every Month</option> -->
                </select>
              </div>

              <div *ngIf="wellnessInterval == 7 || wellnessInterval == 14"
                class="edit-site__confirm-container-wellness-items"
                [ngClass]="{ 'edit-site__disable': !wellnessCheckEnable }">
                <label for="">Select Day :</label>
                <select id="index" (change)="intervalEveryWeekOnChange($event)" [(ngModel)]="wellnessNameOfDay"
                  [ngModelOptions]="{ standalone: true }" style="width: 150px !important">
                  <option value="1" selected> Sunday</option>
                  <option value="2"> Monday</option>
                  <option value="3"> Tuesday </option>
                  <option value="4"> Wednesday </option>
                  <option value="5"> Thursday </option>
                  <option value="6"> Friday </option>
                  <option value="7"> Saturday </option>
                </select>
              </div>

              <!-- <div *ngIf="wellnessInterval == 30" class="edit-site__confirm-container-wellness-items"
                style="display: flex; justify-content: center">
                <label for="start">Select Date:</label>
                <input type="date" class="form-control" id="schedule-date" [(ngModel)]="wellnessDate"
                  style="width: 150px !important" [ngModelOptions]="{ standalone: true }" />
              </div> -->


              <div class="edit-site__confirm-container-wellness-items" style="display: flex; justify-content: center">
                <ngb-timepicker [ngClass]="{ 'edit-site__disable': !wellnessCheckEnable }" class="changeSize"
                  [(ngModel)]="wellnessCheckTime" (ngModelChange)="onWellnessTimeChange()" id="endTime" [spinners]="false" [meridian]="true"
                  [ngModelOptions]="{ standalone: true }"></ngb-timepicker>
              </div>


              <div class="edit-site__confirm-container-wellness-items">
                <input class="default" type="checkbox" [(ngModel)]="wellnessCheckEnable"
                  [ngModelOptions]="{ standalone: true }" (change)=" onChangeWellnessCheckValue($event)"
                  #checkboxEnableWellness />
                <span style="color: #686868"> Enable wellness check </span>

              </div>

              <div class="edit-site__wellness-label">
                Wellness Timezone: {{wellnessLocaltimezone}} UTC
              </div>

              <div class="edit-site__wellness-label" *ngIf="wellnessCheckEnable">
                Wellness Time (Local): {{wellnessCheckTimeWithColon | transformTimeInGivenZoneToAnother : wellnessLocaltimezone}}
              </div>

              <div *ngIf="showWellnessSuccess" class="edit-site__success-msg" style="margin-top: 10px;">Welness Schedule
                Updated
              </div>

            </div>
          </div>
        </div>



        <!-- NEW WELLNESS -->


        <div class="form-group">
          <select #setCountryEditSiteTemp (change)="setCountry(setCountryEditSiteTemp.value)" type="text"
            formControlName="country" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.country.errors }"
            [(ngModel)]="siteCountry" placeholder="Country" id="country" autocomplete="country" required>
            <option value="" disabled selected>Select Country</option>
            <option *ngFor="let countries of allCountryListData; let i = index" value="{{ countries.id }}">{{
              countries.name }}</option>
          </select>
          <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
            <div *ngIf="f.country.errors.required" id="confirmMessage">!</div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-9">
              <select type="text" formControlName="state" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.state.errors }" [(ngModel)]="siteState"
                placeholder="State/Province/Region" id="state" autocomplete="state" required>
                <option value="" disabled selected>State</option>
                <option *ngFor="let states of allStatesListData; let i = index" value="{{ states.name }}">{{ states.name
                  }}</option>
              </select>
              <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                <div *ngIf="f.state.errors.required" class="phonetext alert alert-danger p-2 m-0">
                  Select State
                </div>
                <div *ngIf="f.state.errors.required" id="confirmMessage">!</div>
              </div>
            </div>
            <div class="col-3">
              <input type="text" formControlName="zip" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.zip.errors }" [(ngModel)]="siteZip" placeholder="Zip" id="zip"
                autocomplete="postal-code" required />
              <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
                <div *ngIf="f.zip.errors" class="zip-text alert alert-danger p-2 m-0">
                  Please enter a valid Zipcode of the country
                </div>
                <div *ngIf="f.zip.errors.required" id="confirmMessage">!</div>
              </div>
            </div>
          </div>
        </div>

        <h4 class="left">
          <span>
            Add Contact
          </span>
        </h4>


        <div class="form-group">
          <select #selectEditSiteIndex (change)="siteContactOnchange(selectEditSiteIndex.value)" class="form-control"
            id="add_contact" required>
            <option value="-2" selected disabled hidden>{{ siteDetails?.contact.firstname }} {{
              siteDetails?.contact.lastname }}</option>
            <option value="-1">Add New User</option>
            <option *ngFor="let contactList of userApiData; index as n" value="{{ n }}">
              {{ contactList.first_name }} {{ contactList.last_name }}
            </option>
          </select>
        </div>
        <div>
          <input type="text" [(ngModel)]="userId" formControlName="user_id" class="form-control" id="user_id"
            placeholder="user id" required hidden />
          <div class="form-group">
            <div class="row">
              <div class="col-6">
                <input type="text" [(ngModel)]="firstname" formControlName="firstname" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" placeholder="First Name"
                  name="firstname" id="firstname" autocomplete="given-name" required />
                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                  <div *ngIf="f.firstname.errors.required" id="confirmMessage">!</div>
                </div>
              </div>
              <div class="col-6">
                <input type="text" [(ngModel)]="lastname" formControlName="lastname" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" placeholder="Last Name" name="lastname"
                  id="lastname" autocomplete="family-name" value="{{ userLastname }}" required />
                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                  <div *ngIf="f.lastname.errors.required" id="confirmMessage">!</div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <input type="email" [(ngModel)]="email" formControlName="email" class="form-control" placeholder="email"
              name="email" id="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email"
              autocomplete="email" required />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.maxlength" class="phonetext alert alert-danger p-2 m-0">
                Exceed Maximum 50 character
              </div>
              <div *ngIf="f.email.errors.required" id="confirmMessage">!</div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-6">
                <input type="text" [(ngModel)]="phone" formControlName="phone" class="form-control" name="phone"
                  id="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" placeholder="User Phone"
                  autocomplete="tel-national" required />
                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                  <div *ngIf="f.phone.errors" class="phonetext alert alert-danger p-2 m-0">
                    Please enter valid phone number
                  </div>
                  <div *ngIf="f.phone.errors.required" id="confirmMessage">!</div>
                </div>
              </div>
              <div class="col-6">
                <ng-container *ngIf="!showClosebtn">
                  <button type="submit" class="btn-primary" style="width: 100%;">
                    Save
                  </button>
                </ng-container>
                <ng-container *ngIf="showClosebtn">
                  <button type="submit" (click)="sendPopupType(0)" class="btn-primary" style="width: 100%;">
                    Close
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
          <p id="successMessage" class="feedback text-center text-success"></p>
          <p id="feedback" class="feedback text-center text-danger"></p>
          <div *ngIf="showSuccessMessage" style="color: green; text-align: center">
            <h3>
              Update Success
            </h3>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>