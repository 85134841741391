<div class="audio-system-status">
  <h3 class="text-center title">System Status</h3>
  <div class="row audio-system-status-content">
    <div class="col-2">
      <img src="{{ networkIcon }}" alt="audio" />
    </div>
    <div class="col-10 status">
      <p *ngIf="lanPortStatus">Connected</p>
      <p *ngIf="!lanPortStatus || lanPortStatus == ''">No Connection</p>
    </div>
  </div>
  <div class="row audio-system-status-content">
    <div class="col-2">
      <img src="{{ micIcon }}" alt="audio" />
    </div>
    <div class="col-10 status">
      <p>{{ micStatus }}</p>
    </div>
  </div>
  <div class="row audio-system-status-content" *ngIf="isDisplayPresence">
    <div class="col-2">
      <img src="../../../../../assets/img/teleportivity_img/services.png" alt="" />
    </div>
    <div class="col-10 status">
      <p>Services</p>
    </div>
  </div>
</div>
