import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GetPluginService } from 'src/app/services/get-plugin.service';
import { PopupTypeService } from '../../services/popupType.service';
import { ZoomPluginStatusService } from '../../services/zoom-plugin-status.service';
import { ZoomPluginActionService } from '../../services/zoom-plugin-action.service';

@Component({
  selector: 'app-audio-speaker-plugin-status',
  templateUrl: './audio-speaker-plugin-status.component.html',
  styleUrls: ['./audio-speaker-plugin-status.component.scss'],
})
export class AudioSpeakerPluginStatusComponent implements OnChanges {
  @Input() cloudRoomSpeakerStatsData: any;
  @Input() pluginId: number;
  @Input() speakerFailOver: boolean;
  failOverIdList: any;
  speakerList = [];

  constructor(
    private getPluginService: GetPluginService,
    public ds: PopupTypeService,
    public zoomPluginStatus: ZoomPluginStatusService,
    public zoomPluginAction: ZoomPluginActionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cloudRoomSpeakerStatsData) {
      const copiedArray = [...this.cloudRoomSpeakerStatsData];
      this.speakerList = copiedArray;
      if (!this.speakerFailOver) {
        this.speakerFailOver = false;
        this.resetSpeakerFailOver();
      }
    }
    this.checkList(this.speakerList);
  }

  checkList = (params) => {
    const primary = params.filter((e) => e.primary);
    const backup = params.filter((e) => e.backup === 1);
    const otherDevices = params.filter((e) => !e.primary && e.backup !== 1);
    const newArr = primary.concat(backup, otherDevices);

    if (!this.isEmpty(primary)) {
    }
    this.speakerList = newArr;
  };

  listOnChange = (index, isPrimary, events) => {
    let bool = true;
    let type = 'primary';

    if (!isPrimary) {
      bool = false;
      type = 'backup';
    }

    const copiedArray = [...this.speakerList];
    for (let i = 0; i < copiedArray.length; i++) {
      if (i === index) {
        copiedArray[i].primary = bool;
        copiedArray[i].backup = events ? 1 : 0;
      } else {
        copiedArray[i][type] = isPrimary ? false : 0;
      }
    }
    this.speakerList = copiedArray;
  };
  isEmpty = (arr) => {
    return arr.length === 0;
  };

  enableOrDisableSpeakerFailOver = (bool) => {
    this.speakerFailOver = !bool;
    this.updateFailOverValue(this.speakerFailOver);
    this.zoomPluginAction.visibility_one_loader = true;
    if (!this.speakerFailOver) {
      this.resetSpeakerFailOver();
      this.zoomPluginAction.visibility_one_loader = true;
    }
  };

  resetSpeakerFailOver = () => {
    const copiedArray = [...this.speakerList];
    for (let i = 0; i < copiedArray.length; i++) {
      copiedArray[i].backup = 0;
    }
  };

  saveFailOver = () => {
    const primary = this.speakerList.filter((p) => p.primary);
    const backup = this.speakerList.filter((b) => b.backup > 0);
    this.failOverIdList = primary;
    if (!this.isEmpty(backup)) {
      this.failOverIdList = primary.concat(backup);
    }

    this.failOverIdList = this.failOverIdList.map((i) => i.id);
    this.updateFailOverList(this.failOverIdList, this.pluginId);
    this.zoomPluginAction.visibility_one_loader = true;
  };

  resetCloudRoomfields = () => {
    const failOverList = this.cloudRoomSpeakerStatsData.map((device) => {
      return device.id;
    });
    this.updateFailOverList(failOverList, this.pluginId);
  };

  updateFailOverList = (failOverList, pluginId) => {
    const actionParams = JSON.stringify({ failoverList: failOverList });
    const actionName = 'SetAudioOutputFailoverList';
    this.getPluginService.createAction(pluginId, actionParams, actionName).subscribe((result) => {
      if (result.response[0].status === 'OK') {
        this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
        this.zoomPluginAction.setActionCount();
      } else {
        this.zoomPluginAction.visibility_one_loader = false;
      }
    });
  };

  updateFailOverValue = (flag) => {
    const actionParams = JSON.stringify({ isEnabled: flag });
    const actionName = 'SetFailoverEnabled';
    this.getPluginService
      .createAction(this.pluginId, actionParams, actionName)
      .subscribe((result) => {
        if (result.response[0].status === 'OK') {
          this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
          this.zoomPluginAction.setActionCount();
        } else {
          this.zoomPluginAction.visibility_one_loader = false;
        }
      });
  };
}
