import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-reports-inventory',
  templateUrl: './reports-inventory.component.html',
  styleUrls: ['./reports-inventory.component.scss']
})
export class ReportsInventoryComponent implements OnInit {
  @Input() reportInventory:any;
  @Input() listTitle: any;
  constructor() { }

  ngOnInit() {
  }

}
