<span *ngIf="rowData?.video_incident_id == null && rowData?.zoom_incident_id == null">
  <span>
      {{ renderValue }}
  </span>
</span>

<span *ngIf="rowData?.video_incident_id || rowData?.zoom_incident_id  ">
  <span class="incidentRow">
      {{ renderValue }}
  </span>
</span>

  