import { Injectable } from '@angular/core';
const audioIssues = {
  jitterAlert: false,
  packetlossAlert: false,
  latencyAlert: false,
  sip: false,
};

@Injectable({
  providedIn: 'root',
})
export class AudioIncidentTrackerService {
  private audioIncidents: any;
  hasIncidents: boolean = false;

  constructor() {
    this.audioIncidents = { ...audioIssues };
  }

  resetAudioIncidents = () => {
    this.audioIncidents = { ...audioIssues };
  };

  setJitterIncident = (color = '') => {
    this.audioIncidents.jitterAlert = color === 'red';
  };

  setPacketlossIncident = (color = '') => {
    this.audioIncidents.packetlossAlert = color === 'red';
  };

  setLatencyIncident = (color = '') => {
    this.audioIncidents.latencyAlert = color === 'red';
  };

  setSipIncident = (color = '') => {
    this.audioIncidents.sip = color === 'red';
  };

  gatherIncidents() {
    let flag = false;
    const incidentValues = Object.values(this.audioIncidents);
    for (let i = 0; i < incidentValues.length; i++) {
      if (incidentValues[i]) {
        flag = true;
        break;
      }
    }
    this.hasIncidents = flag;
  }

  getIncidents() {
    return { ...this.audioIncidents };
  }
}
