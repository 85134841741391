import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from '../../../environments/environment';
import { ZoomRoomsLinkPluginComponent } from './dialogs/zoom-rooms-link-plugin/zoom-rooms-link-plugin.component';
import { GetTeamsService } from 'src/app/services/get-teams.service';

@Component({
  selector: 'app-zoom-rooms',
  templateUrl: './zoom-rooms.component.html',
  styleUrls: ['./zoom-rooms.component.scss'],
})
export class ZoomRoomsComponent implements OnInit, OnChanges {
  @Output() sendPopupType: EventEmitter<any> = new EventEmitter<any>();
  @Input() zoomRoomList: any;
  @Input() searchKey: any;
  @Input() showLoader: any;
  dPlugin = environment.config.dynamicText.dPlugin;
  iconPlugin = environment.config.dynamicImage.iconPlugin;
  hostToLink: any;
  loader = environment.config.dynamicImage.loader;
  constructor(
    public dialog: MatDialog,
    private Auth: AuthService,
    private teamsService: GetTeamsService, 
    ) { }

  ngOnInit() {

  }



  ngOnChanges(changes: SimpleChanges) {
    if (changes.zoomRoomList && this.zoomRoomList) {
      for (let zrl of this.zoomRoomList) {
        if (zrl.plugin_host_info !== null && typeof (zrl.plugin_host_info) !== 'object') {
          zrl.plugin_host_info = JSON.parse(zrl.plugin_host_info);
        }
      }
      this.setData();
      // console.log(this.zoomRoomList)
    }
  }

  setData = () => {
    this.Auth.getListPluginToLink().subscribe((data: any) => {
      this.hostToLink = data.response;
    });
    this.zoomRoomList = this.zoomRoomList.map((e) => {
      return {
        ...e,
        plugin_ip_address: this.getComputerIp(e.connected_devices),
        site_name: e.site_name || '--',
      };
    });
  };

  getPluginIcon = (plugin_id) => {
    if (plugin_id !== null) {
      return this.iconPlugin;
    }
  };

  getRoomStatus = (status) => {
    if (status === 1) {
      return 'zoom-rooms__status--green';
    }

    if (status === 0) {
      return 'zoom-rooms__status--red';
    }

    return '';
  };

  getPluginStatus = (status, enabled) => {
    if (enabled === 0) {
      return '';
    } else if (status === 1) {
      return 'zoom-rooms__status--green';
    } else if (status === -1) {
      return 'zoom-rooms__status--red';
    } else if (status === 2) {
      return 'zoom-rooms__status--yellow';
    } else {
      return 'zoom-rooms__status--green';
    }
  };

  getLinkIcon = (params) => {
    const { plugin_status, plugin_id } = params;
    return plugin_id !== null ? './assets/img/vs_img/zoom_unlink.png' : './assets/img/vs_img/zoom_link.png';
  };

  openPluginLinkModalZoomROoms = (items) => {
    const config = this.hostToLink;
    const plugin_hostname = items.plugin_host_info !== null ? items.plugin_host_info[0].hostname : "--";
    const webportal = this.dialog.open(ZoomRoomsLinkPluginComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        config,
        user: items,
        sendPopupType: this.sendPopupType,
        user_plugin_host_name: plugin_hostname,
      },
    });
  };

  getComputerIp = (connected_devices) => {

    if (connected_devices !== null) {
      const parsoObj = JSON.parse(connected_devices);
      const { device_ip } = parsoObj;

      const len = device_ip.length;
      let searhKey = 'Computer';
      if (len > 0) {
        const cPosition = device_ip.search(searhKey);
        if (cPosition >= 0) {
          const startIndex = cPosition + searhKey.length + 2;
          const ips = device_ip.substring(startIndex).split(';');
          return ips[0].trim();
        }

        searhKey = 'Digital Signage Only';
        const dPosition = device_ip.search(searhKey);
        if (dPosition >= 0) {
          const startIndex = cPosition + searhKey.length + 2;
          const ips = device_ip.substring(startIndex).split(';');
          return ips[0].trim();
        }
        return '--';
      }
    }
    return '--';
  };

  setMonitored = (zoom_id, isChecked) => {
    let flag = 0;
    if (isChecked) {
      flag = 1;
    }
    this.teamsService.setTeamsMonitoring(zoom_id, flag).subscribe((data: any) => {
    }, (error) => {
      // console.log('getError error', error)
    })
  }
  onScroll() {
    this.sendPopupType.emit('scrollPagination');
  }
}
