import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-audio-all-devices-info',
  templateUrl: './audio-all-devices-info.component.html',
  styleUrls: ['./audio-all-devices-info.component.scss']
})
export class AudioAllDevicesInfoComponent implements OnInit {

  @Input() audioDeviceSummary: any;

  constructor() { }

  ngOnInit() {
  }

}
