import { Component, OnInit } from '@angular/core';
import { GetDocumentCenterService } from '../../services/get-document-center.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.scss'],
})
export class ViewDocumentComponent implements OnInit {
  documentId: number;
  documentType: string;
  documentUrl: string;
  documentName: string;
  loader: String = environment.config.dynamicImage.loader;
  logo: String = environment.config.logo;
  shouldShowLoader: boolean = true;
  iframeHeight: number;
  isError: boolean = false;
  errorMessage: String;
  isOnlyDownloadable: boolean = false;
  docPath: string = environment.serverUrl + '/api/v1/files/get-file/';
  constructor(private getDocumentCenter: GetDocumentCenterService, private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      this.documentId = params['id'];
      this.documentType = params['type'];
      this.isOnlyDownloadable = this.checkIfOnlyDownloadable(this.documentType);
      if (this.isOnlyDownloadable) {
        this.downloadDocument();
      } else {
        this.getDocumentPath();
      }
    });
  }

  ngOnInit() {}

  getDocumentPath = () => {
    this.getDocumentCenter.getDocumentById(this.documentId).subscribe((data: any) => {
      const { file_path, file_name, shared_by_user_id, share_expiration } = data;
      this.documentName = file_name;
      this.documentUrl = `https://docs.google.com/gview?url=${file_path}&embedded=true`;
      this.iframeHeight = window.innerHeight;
      this.isError = false;
      if (shared_by_user_id !== null) {
        const isNotExpired = moment(share_expiration).isAfter(moment());
        if (!isNotExpired) {
          this.isError = true;
          this.errorMessage = 'Document share date expired.';
        }
      } else {
        this.isError = true;
        this.errorMessage = 'Document is not shared.';
      }

      this.shouldShowLoader = false;
    });
  };

  downloadDocument = () => {
    this.getDocumentCenter.getDocumentById(this.documentId).subscribe((data: any) => {
      const { file_path, shared_by_user_id, share_expiration } = data;
      if(typeof data == 'object'&&!Object.keys(data).length){
        this.isError = true;
        this.errorMessage = 'Document not found';
        this.shouldShowLoader = false;
        return;
      }
      this.documentUrl = this.docPath+this.documentId+'/0';
      this.iframeHeight = window.innerHeight;
      this.isError = false;
      if (shared_by_user_id !== null) {
        const isNotExpired = moment(share_expiration).isAfter(moment());
        if (!isNotExpired) {
          this.isError = true;
          this.errorMessage = 'Document share date expired.';
          this.shouldShowLoader = false;
        } else {
          const downloadLink = document.createElement('a');
          downloadLink.setAttribute('href', this.documentUrl);
          downloadLink.download;

          this.isError = true;
          this.errorMessage = 'File is only downloadable.';
          this.shouldShowLoader = false;
          downloadLink.click();
        }
      } else {
        this.isError = true;
        this.errorMessage = 'Document is not shared.';
        this.shouldShowLoader = false;
      }
    });
  };

  checkIfOnlyDownloadable = (fileType) => {
    if (
      fileType === 'doc' ||
      fileType === 'docx' ||
      fileType === 'pdf' ||
      fileType === 'ppt' ||
      fileType === 'pptx' ||
      fileType === 'xls' ||
      fileType === 'xlsx'
    ) {
      return false;
    }
    return true;
  };
}
