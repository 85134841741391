import { Observable } from 'rxjs/Observable'
import { environment } from 'src/environments/environment'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { EventEmitter, Injectable } from '@angular/core'

import { AuthService } from './auth.service'
import { IntegratorService } from './integrator.service'
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class GetCollectorService {
	collectorSiteChangeEvent: EventEmitter<any> = new EventEmitter()
	isRefresh:Subject<boolean> = new Subject<boolean>();
	server_url: string = environment.serverUrl
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	}

	constructor(private http: HttpClient, private auth: AuthService, private integrator: IntegratorService) {}

	private handleError(error: Response | any) {
		// console.error('ApiService::handleError', error);
		return Observable.throwError(error)
	}

	// getCompanyId = () => {
	// 	const token = localStorage.getItem('token')
	// 	const helper = new JwtHelperService()
	// 	const { company_id } = helper.decodeToken(token)
	// 	return company_id
	// }

	// private extractData(res: Response) {
	// 	let body = res
	// 	return body || {}
	// }

	getCollectors = () => {
		const postData = {
			company_id: this.integrator.isIntegrator() ? this.auth?.companyId * -1 : this.auth?.companyId,
		}
		return this.http.post<any>(
			this.server_url + '/api/v1/collector/getCollectorsList',
			postData,
			this.httpOptions
		)
	}

	rebootCollector = collector_id => {
		const postData = {
			company_id: this.auth?.companyId,
			collector_id,
		}
		return this.http.post<any>(this.server_url + '/api/v1/collector/collectorReboot', postData, this.httpOptions)
	}
	
	
	pingCollector = (collector_id, ip_address) => {
		const postData = {
			company_id: this.auth?.companyId,
			collector_id,
			ip_address
		}
		
		console.log('pingCollector: ', postData)
		
		return this.http.post<any>(this.server_url + '/api/v1/collector/collectorPing', postData, this.httpOptions)
	}
	
	traceRouteCollector = (collector_id, ip_address) => {
		const postData = {
			company_id: this.auth?.companyId,
			collector_id,
			ip_address
		}
		return this.http.post<any>(this.server_url + '/api/v1/collector/collectorTraceroute', postData, this.httpOptions)
	}
	
	setAlertsJson = (collector_id,params) => {
		const postData = {
		
			collector_id,
			alerts: {
				...params
			}
		}
		return this.http.post<any>(this.server_url + '/api/v1/collector/setAlertsJson', postData, this.httpOptions)
	}
	
	collectorLogging = collector_id => {
		const postData = {
			company_id: this.auth?.companyId,
			collector_id,
		}
		return this.http.post<any>(this.server_url + '/api/v1/collector/collectorLogging', postData, this.httpOptions)
	}
	
	collectorScheduleTask = collector_id => {
		const postData = {
			company_id: this.auth?.companyId,
			collector_id,
		}
		return this.http.post<any>(this.server_url + '/api/v1/collector/collectorScheduleTask', postData, this.httpOptions)
	}
	
	collectorGetEvents = (collector_id,log_name,provider_name,log_id) => {
		const postData = {
			company_id: this.auth?.companyId,
			collector_id,
			log_name,
			provider_name,
			log_id
		}
		
		console.log(postData)
		
		return this.http.post<any>(this.server_url + '/api/v1/collector/collectorGetEvents', postData, this.httpOptions)
	}


	collectorUninstallFromReg = collector_id => {
		const postData = {
			company_id: this.auth?.companyId,
			collector_id,
		}
		return this.http.post<any>(this.server_url + '/api/v1/collector/collectorUninstallFromReg', postData, this.httpOptions)
	}


	collectorGetWin32 = collector_id => {
		const postData = {
			company_id: this.auth?.companyId,
			collector_id,
		}
		return this.http.post<any>(this.server_url + '/api/v1/collector/collectorGetWin32', postData, this.httpOptions)
	}


	collectorNetStat = collector_id => {
		const postData = {
			company_id: this.auth?.companyId,
			collector_id,
		}
		return this.http.post<any>(this.server_url + '/api/v1/collector/collectorNetStat', postData, this.httpOptions)
	}


	collectorGetServices = collector_id => {
		const postData = {
			company_id: this.auth?.companyId,
			collector_id,
		}
		return this.http.post<any>(this.server_url + '/api/v1/collector/collectorGetServices', postData, this.httpOptions)
	}
	
	collectorPs = collector_id => {
		const postData = {
			company_id: this.auth?.companyId,
			collector_id,
		}
		return this.http.post<any>(this.server_url + '/api/v1/collector/collectorPs', postData, this.httpOptions)
	}


	getCollectorDetails = site_id => {
		const postData = {
			site_id,
		}
		return this.http.post<any>(this.server_url + '/api/v1/collector/getCollectorDetails', postData, this.httpOptions)
	}

	getCollectorStats = () => {
		const postData = {
			company_id: this.integrator.isIntegrator() ? this.auth?.companyId * -1 : this.auth?.companyId,
		}
		return this.http.post<any>(this.server_url + '/api/v1/collector/getCollectorsSummary', postData, this.httpOptions)
	}

	updateNotes = (site_id, notes) => {
		const postData = {
			site_id,
			notes,
		}

		return this.http.post<any>(this.server_url + '/api/v1/collector/updateNotes', postData, this.httpOptions)
	}

	updateSiteName = (site_id, name) => {
		const postData = {
			site_id,
			name,
		}

		return this.http.post<any>(this.server_url + '/api/v1/collector/updateSiteName', postData, this.httpOptions)
	}

	pathPing = (collector_id, ip) => {
		const postData = {
			collector_id,
			ip,
		}

		return this.http.post<any>(this.server_url + '/api/v1/collector/pathPing', postData, this.httpOptions)
	}
}
