import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
import { GetPluginService } from 'src/app/services/get-plugin.service';
import { PopupTypeService } from '../../services/popupType.service';
import { ZoomPluginStatusService } from '../../services/zoom-plugin-status.service';
import { ZoomPluginActionService } from '../../services/zoom-plugin-action.service';

@Component({
  selector: 'app-video-camera-list',
  templateUrl: './video-camera-list.component.html',
  styleUrls: ['./video-camera-list.component.scss'],
})
export class VideoCameraListComponent implements OnChanges {
  @Input() connectedCameras: any;
  @Input() pluginId: number;

  camList: any;
  cameraStatus: string;
  deviceStatus: number;
  selectedCameraId: string;

  constructor(
    private getPluginService: GetPluginService,
    public ds: PopupTypeService,
    public zoomPluginStatus: ZoomPluginStatusService,
    public zoomPluginAction: ZoomPluginActionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.connectedCameras) {
      if (this.connectedCameras) {
        // console.log(this.connectedCameras);
        this.arrangeCameraList(this.connectedCameras);
      }
    }
  }

  arrangeCameraList = (list) => {
    const copiedArr = [...list];
    const camInuse = copiedArr.filter((c) => c.inuse);
    const otherCam = copiedArr.filter((c) => !c.inuse);
    const newArry = camInuse.concat(otherCam);
    this.camList = newArry;
    if (!this.isEmpty(this.camList)) {
      this.cameraSelect(this.camList[0].id);
    }
  };

  cameraSelect = (id) => {
    const filterData = this.connectedCameras.filter((val) => val.id === id);
    this.cameraStatus = this.zoomPluginStatus.pluginStatus[filterData[0].status];
    this.deviceStatus = filterData[0].status;
    this.selectedCameraId = id;
  };

  isEmpty = (arr) => {
    return arr.length === 0;
  };

  setDevice = () => {
    const actionParams = JSON.stringify({ selectedId: this.selectedCameraId });
    const actionName = 'SetConfigurationVideoCameraSelectedId';
    this.zoomPluginAction.visibility_one_loader = true;
    this.getPluginService
      .createAction(this.pluginId, actionParams, actionName)
      .subscribe((result) => {
        if (result.response[0].status === 'OK') {
          this.zoomPluginAction.checkActionStatus(result.response[0].action_id);
          this.zoomPluginAction.setActionCount();
        } else {
          this.zoomPluginAction.visibility_one_loader = false;
        }
      });
  };
}
