<div class="card" style="width: 18rem;">
  <div class="card-body d-flex flex-column justify-content-between">
    <ng-container *ngIf="siteInformation && isSuperAdmin">
      <img [src]="mspLogo" class="px-2" style="padding-top: 50px; padding-bottom: 66px;" />
    </ng-container>
    <ng-container *ngIf="siteInformation && !isSuperAdmin">
      <img [src]="companyLogo" class="px-2" style="padding-top: 50px; padding-bottom: 66px;" />
    </ng-container>
    <div class="card-detail-bm">
      <ng-container *ngIf="siteInformation">
        <h4 *ngIf="!isSuperAdmin" class="card-title">
          {{ nullCheck(siteInformation[0]?.company_name) }}
        </h4>
        <p *ngIf="!isSuperAdmin" class="card-text">
          Address: {{ nullCheck(siteInformation[0]?.address1) }},
          {{ nullCheck(siteInformation[0]?.address2) }}
        </p>
        <p *ngIf="!isSuperAdmin" class="card-text">
          City: {{ nullCheck(siteInformation[0]?.city) }}
        </p>
        <p *ngIf="!isSuperAdmin" class="card-text">
          State: {{ nullCheck(siteInformation[0]?.state) }}
        </p>
        <p *ngIf="!isSuperAdmin" class="card-text">
          Zip Code: {{ nullCheck(siteInformation[0]?.zip) }}
        </p>
        <p *ngIf="!isSuperAdmin && siteInformation[0].phone != null" class="card-text">
          Phone: {{ siteInformation[0]?.phone.replace('$$', '').replace('$$', '') }}
        </p>
        <p *ngIf="siteInformation[0].phone == null" class="card-text">Phone: --</p>
        <app-btn-info *ngIf="!isSuperAdmin" style="padding-bottom: 5% !important;" class="float-right"
          label="Edit company profile" [faName]="faIcon" (click)="sendPopupType(2)"></app-btn-info>
      </ng-container>
    </div>
  </div>
</div>