import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root',
})
export class EventEmitterGlobalService {

  private watcher = new Subject<any>();

  listen(): Observable<any> {
    return this.watcher.asObservable();
  }

  watcherParams(params: any) {
    this.watcher.next(params);
  }
}
