import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { PopupTypeService } from '../../services/popupType.service';
import { PopupOpenClose } from '../../services/popupContainer.animation';
import { PopupSendDataService } from '../../services/popup-send-data.service';
import { ZoomPluginActionService } from '../../services/zoom-plugin-action.service';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-popup-container',
  templateUrl: './popup-container.component.html',
  styleUrls: ['./popup-container.component.scss'],
  animations: [PopupOpenClose],
})
export class PopupContainerComponent implements OnInit {
  @Output() popUpEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() popupTypeState: string = 'close';
  @Input() title: string = 'Title';
  @Input() callBackFunc: Function;
  popupType: number = 0;
  subscription: Subscription;
  @Input() runTutorial;
  isShowQosChartandList: boolean = false;
  deviceId: any;
  pluginId: any;
  data: any;
  oldPorts:any;
  alertsId: any;
  alertType: any;
  popUpTitle: any;
  deviceType: any;
  mediaType: any;
  isShouldShowLinkSite: boolean = true;

  dPlugin: String = environment.config.dynamicText.dPlugin;
  deskPlugin: String = environment.config.dynamicText.deskPlugin;

  constructor(
    public ds: PopupTypeService,
    private sendPopupDataService: PopupSendDataService,
    public zoomPluginAction: ZoomPluginActionService
  ) {
    this.subscription = this.ds.getPopupType().subscribe((x) => {
      if (x) {
        const inputs = x;
        this.popupType = inputs.popupId;
        this.popUpEmitter = inputs.popUpEmitter;
        this.data = inputs.data;
        this.oldPorts = inputs.oldPorts;
        this.callBackFunc = inputs.callBackFunc;
        this.alertsId = inputs.alertsId;
        this.alertType = inputs.alertType;
        this.deviceId = inputs.deviceId;
        this.pluginId = inputs.pluginId;
        this.deviceType = inputs.type;
        this.mediaType = inputs.mediaType;
        if (typeof inputs.popUpEmitter === 'function') {
          this.popUpEmitter = inputs.popUpEmitter;
        }

        switch (this.alertType) {
          case 'video': {
            this.popUpTitle = 'Device';
            break;
          }
          case 'cloud': {
            this.popUpTitle = 'Zoom';
            break;
          }
        }
        if (this.popupType == 0) {
          this.popupTypeState = 'close';
        } else {
          this.popupTypeState = 'open';
        }
      }
    });

    //This function passes the device params Id to the reboot video device function. Can be used for funciton as well
    this.sendPopupDataService.popupDataListen().subscribe((paramsData: any) => {
      this.deviceId = paramsData;
    });
  }
  closePopup() {
    this.popupTypeState = 'close';
  }
  togglePopupType() {
    // 1-line if statement that toggles the value:
    this.popupTypeState = this.popupTypeState === 'open' ? 'close' : 'open';
  }

  ngOnInit() {
    this.popupTypeState === 'open';
  }

  linkSiteEvent(value: boolean) {
    this.isShouldShowLinkSite = value;
  }

  receiveMessage($event) {
    this.isShowQosChartandList = $event;
  }
}
