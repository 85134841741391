<div class="collector-alert-panel">
  <div class="collector-alert-panel__container">

    <div class="collector-alert-panel__header">
      <h5>Collector Alert Panel</h5>

    </div>



    <div class="collector-alert-panel__item">
      <div>
        <h6>Low RAM</h6>
      </div>
      <label>
        <input type="checkbox" [(ngModel)]="lowRam" />
        Enabled
      </label>
    </div>

    <div class="collector-alert-panel__item">
      <div>
        <h6>High CPU usage</h6>
      </div>
      <label>
        <input type="checkbox" [(ngModel)]="highCpu" />
        Enabled
      </label>
    </div>

    <div class="collector-alert-panel__item">
      <div>
        <h6>Full storage</h6>
      </div>
      <label>
        <input type="checkbox" [(ngModel)]="congestedDrives" />
        Enabled
      </label>
    </div>

    <div class="collector-alert-panel__item">
      <div>
        <h6>Online status</h6>
      </div>
      <label>
        <input type="checkbox" [(ngModel)]="collectorOffline" />
        Enabled
      </label>
    </div>


    <div class="collector-alert-panel__item">

      <button class="collector-alert-panel__button-save" (click)="setAlert(true)">Save</button>
      <button class="collector-alert-panel__button-cancel" (click)="setAlert(false)">Reset</button>

    </div>

    <div *ngIf="showSucessMessage" class="collector-alert-panel__success-message">
      Update Success!
    </div>

  </div>

</div>