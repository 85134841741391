<div class="zoomuser">
  <table class="table table-hover table-sm bg-white mt-1">
    <thead>
      <tr>
        <th scope="col">User Name</th>
        <th scope="col">Email</th>
        <th scope="col">Last Login</th>
        <th scope="col">Status</th>
      </tr>
    </thead>
    <tbody>
      <tr scope="row" *ngFor="let item of clourUserList">
        <td>{{ item.firstname }} {{ item.lastname }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.sync_stamp }}</td>

        <td>
          <span *ngIf="item.liveMeetings == null || item.liveMeetings == 0">
            <span>
              <img style="width: 13px;" src="../../../assets/img/video/hang-up.png" />
            </span>
          </span>
          <span *ngIf="item.liveMeetings > 0">
            <span> <fa-icon [icon]="faIconPhone" class="pr-1"></fa-icon>{{ item.liveMeetings }} </span>
          </span>
          <ng-template [ngIf]="item.online == 1">
            <div class="status-green"></div>
          </ng-template>

          <ng-template [ngIf]="item.online == 0">
            <div class="status-red"></div>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
