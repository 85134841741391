<!-- <h2>{{title}}</h2> -->
<div class="popup-content">
  <div class="container-fluid">
    <div class="row pt-3" *ngIf="meetingsResponseData.length>0">
      <div class="card bg-default"  *ngFor="let rooms of meetingsResponseData; index as m;">
        <div style="padding-bottom:10px;margin-bottom:10px;" *ngIf="rooms.meetings.length>0" >
          <h5 style="line-height:2.5em; padding-left: 20px">
            {{ rooms.room_name }}
          </h5>
          <div class="col-md-12" *ngIf="rooms.meetings.length>0">
            <div class="card bg-default" style="margin-bottom:10px;"
              *ngFor="let meeting of rooms.meetings; index as n;">
              <h5 class="card-header">
                <!-- {{ printTime(meeting.start) }} - {{ printEndTime(meeting.end) }} -->
                {{ meeting.start | dateTimeZone : 'MM-DD-YYYY hh:mma' : false}} - {{ meeting.end | dateTimeZone : 'hh:mma' }}
              </h5>
              <div class="card-body">
                <table>
                  <thead>
                    <tr>
                      <td>Title: {{meeting.title}}</td>
                      <!-- <td> Product Meeting Follow-up</td> -->
                    </tr>
                    <tr>
                      <td>Meeting Organizer: {{meeting.organizer}}</td>
                      <!-- <td>Jane Williams </td> -->
                    </tr>
                  </thead>
                </table>
              </div>
              <!-- <div class="card-footer">
          Card footer
          </div> -->
            </div>

          </div>
          <!-- <div *ngIf="rooms.meetings.length==0">
            <div class="col-md-12">
              <div class="card bg-default">
                <h5 class="card-header">
                  No Meetings Scheduled
                </h5>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div *ngIf="meetingsResponseData.length==0">
      <div class="col-md-12">
        <div class="card bg-default">
          <h5 class="card-header">
            No Devices
          </h5>
        </div>
      </div>
    </div> -->
  </div>
</div>