<div
  class="d-flex align-items-center justify-content-between w-100 dash-heading"
>
  <div class="w-75">
    <div class="h4 font-weight-semibold">Schedule</div>
  </div>
  <div class="d-flex align-items-center ml-3">
    <span class="btn close" (click)="onClose(false)">X</span>
  </div>
</div>
<div class="container mt-8">
  <form autocomplete="off" class="mt-8" [formGroup]="form">
    <div class="row mt-4">
      <div class="col-lg-6 mb-3">
        <div class="form-group">
          <label for="type">Type</label>
          <select id="type" class="form-control" [formControlName]="'type'" (change)="onChange($event.target)" [ngClass]="{'is-invalid': hasError('type', 'required')}">
            <option *ngFor="let item of typeList" [value]="item">
              {{ item }}
            </option>
          </select>
          <div
            *ngIf="hasError('type','required')"
            class="invalid-feedback"
          >
            Type is required
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-3" *ngIf="type === 'WEEKLY'">
        <div class="form-group">
          <label for="type">Select Week</label>
          <div class="schedule-recurrence">
            <div class="circle-days" *ngFor="let item of dayList" (click)="item.isSet = !item.isSet" [ngStyle]="{'background-color':!item.isSet?'grey':''}">
                <span class="circle-text">{{item.day}}</span>
              </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-6 mb-3" *ngIf="type === 'MONTHLY'">
        <div class="form-group">
          <label for="type">Select Day</label>
          <input
            id="start_time"
            type="number"
            class="form-control"
          />
        </div>
      </div> -->
      <div class="col-lg-6 mb-3">
        <div class="form-group">
          <label for="start_time">Start Time</label>
          <input
            id="start_time"
            type="time"
            class="form-control"
            [formControlName]="'start_time'"
            [ngClass]="{'is-invalid': hasError('type', 'start_time')}"
          />
          <div
            *ngIf="hasError('start_time','required')"
            class="invalid-feedback"
          >
            Start time is required
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-3">
        <div class="form-group">
          <label for="end_time">End Time</label>
          <input
            id="end_time"
            type="time"
            class="form-control"
            [formControlName]="'end_time'"
            [ngClass]="{'is-invalid': hasError('end_time', 'required') || hasFormError('startTimeBeforeEndTime')}"
          />
          <div
            *ngIf="hasError('end_time','required')"
            class="invalid-feedback"
          >
            End time is required
          </div>
          <div *ngIf="hasFormError('startTimeBeforeEndTime')" class="invalid-feedback">
            End time should be greater than Start Time
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-end mb-3">
          <span class="btn btn-primary mr-2" [ngClass]="form.invalid || type === 'WEEKLY' && !isDaySelect ?'disabled-btn':''" (click)="submitSchedule()">Set Schedule</span>
          <span class="btn btn-secondary" (click)="onClose(false)">Cancel</span>
        </div>
      </div>
    </div>
  </form>
</div>
