<div class="collector-live-stats">
  <div class="collector-live-stats__title">
    <h5>Live Stats</h5>
  </div>

  <div class="collector-live-stats__container">
    <div class="collector-live-stats__one-column">
      <div class="collector-live-stats__stats_item_data ">
        <h3>Last Connected</h3>
      </div>
      <div class="collector-live-stats__stats_item_label">{{collectorLastConnect | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
      </div>
    </div>
  </div>


  <div class="collector-live-stats__container">
    <div class="collector-live-stats__small-column-container">
      <div class="collector-live-stats__small-column">
        <div class=" collector-live-stats__stats_item_data">
          <div>
            {{collectorVersion}}
          </div>
        </div>
        <div class="collector-live-stats__item-label">Version</div>
      </div>

      <div class="collector-live-stats__small-column">
        <div class=" collector-live-stats__stats_item_data ">
          <div>
            <img src="{{ collectorSiteType }}" style="height: 30px;" />
          </div>
        </div>
        <div class="collector-live-stats__item-label" style="word-wrap: normal;">Site Type</div>
      </div>
    </div>

    <div class="collector-live-stats__half-column">
      <div class="collector-live-stats__stats_item_data ">
        <div>
          {{collectorLastReboot | dateTimeZone : 'MM-DD-YYYY hh:mma'}}
        </div>
      </div>
      <div class="collector-live-stats__stats_item_label">Last Reboot</div>
    </div>
  </div>
</div>