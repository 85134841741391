import { Component, OnInit, OnChanges, Output, EventEmitter, Input, SimpleChanges } from '@angular/core'
import { GetIotService } from '../../services/get-iot.service'
import * as moment from 'moment-timezone'
import { environment } from '../../../environments/environment'
import { GetCompanyService } from 'src/app/services/get-company.service'
import { finalize } from 'rxjs/operators'

@Component({
	selector: 'app-iot-device-enable-service',
	templateUrl: './iot-device-enable-service.component.html',
	styleUrls: ['./iot-device-enable-service.component.scss'],
})
export class IotDeviceEnableServiceComponent implements OnInit, OnChanges {
	getUrlPath: Function = environment.apiServicePathBuilder('iot')
	apiKey: String = ''
	apiName: String = ''
	accountUrl: String = ''
	active: number
	domotz_account_id: number
	company_id: number
	btnName: String = 'Add Api Key'
	isShowSync = false
	lastPullingCycle: string
	isParentCompany: boolean = false
	@Output() boolEvent: EventEmitter<any> = new EventEmitter<any>()
	@Input() domotzAccount: any
	@Input() mspDomotzEnabled: any
	@Input() iotLiteEnabled: any
	@Input() companyDetails: any
	domotzMessage: string = ''
	msgClass: string = 'success'
	// isLoading: boolean = true
	loader = environment.config.dynamicImage.loader

	webHookUrl =
		environment.config.webhookUrl !== null
			? environment.config.webhookUrl + 'iot/domotzWebhook'
			: this.getUrlPath('domotzWebhook')

	constructor(private GetIot: GetIotService, private GetComapny: GetCompanyService) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.domotzAccount && this.domotzAccount) {
			// this.isLoading = false
			this.setData()
		}
	}

	setData = () => {
		const company = this.GetComapny?.company?.response.length ? this.GetComapny?.company?.response[0] : null
		this.isParentCompany = company?.company_parent === null ? true : false
		const { api_key, api_name, account_url, domotz_account_id, active, company_id, last_pulling_cycle } =
			this.domotzAccount
		this.apiKey = api_key
		this.apiName = api_name
		this.accountUrl = account_url
		this.domotz_account_id = domotz_account_id
		this.company_id = company_id
		this.active = active
		this.lastPullingCycle = last_pulling_cycle ? moment(last_pulling_cycle).format('LLLL') : ''
		if (this.apiKey) {
			this.btnName = 'Update Api Key'
			this.isShowSync = true
		}
	}

	isDisable() {
		if (this.apiKey && this.accountUrl && this.apiKey) return false
		return true
	}

	// submitInput = () => {
	//   if (this.btnName === 'Add Api Key') {
	//     if(this.iotLiteEnabled) {
	//       this.boolEvent.emit('openModal');
	//     } else {
	//       this.addDomotzAccount();
	//     }
	//   } else {
	//     if(this.iotLiteEnabled) {
	//       this.boolEvent.emit('openModal');
	//     } else {
	//       this.updateDomotzAccount();
	//     }
	//   }
	// };

	submitInput = () => {
		if (this.apiKey && this.apiName && this.accountUrl) {
			if (this.btnName === 'Add Api Key') {
				if (this.companyDetails?.has_selected_iot_not_domotz === null)
					this.boolEvent.emit({ eventType: 'openModal', cb: this.addDomotzAccount })
				else this.addDomotzAccount()
			} else {
				this.updateDomotzAccount()
			}
		}
	}

	addDomotzAccount = () => {
		this.GetIot.addDomotzAccount(this.apiKey, this.apiName, this.accountUrl).subscribe(
			data => {
				this.boolEvent.emit({ eventType: 'boolSuccess' })
				this.boolEvent.emit({ eventType: 'getDomotzAccount' })
			},
			error => {
				this.boolEvent.emit({ eventType: 'boolErr' })
			}
		)
	}

	updateDomotzAccount = () => {
		this.GetIot.updateDomotzAccount(
			this.domotz_account_id,
			this.apiName,
			this.accountUrl,
			this.apiKey,
			this.active
		).subscribe(
			data => {
				if (data || data !== null) {
					this.boolEvent.emit({ eventType: 'boolSuccess' })
					this.boolEvent.emit({ eventType: 'getDomotzAccount' })
				}
			},
			error => {
				this.boolEvent.emit({ eventType: 'boolErr' })
			}
		)
	}

	syncDomotzAccount = () => {
		this.domotzMessage = 'Syncing... This can take up to 10 minutes. Please be patient.'
		this.msgClass = 'success'
		this.GetIot.syncDomotz(this.company_id, this.apiName, this.accountUrl).subscribe(
			data => {
				this.domotzMessage = 'Synced Sucessfully'
				this.msgClass = 'success'
				this.boolEvent.emit({ eventType: 'getDomotzAccount' })
			},
			error => {
				// this.domotzMessage = 'IoT API/Name/URL Key Invalid.';
				// this.msgClass = 'error';
			}
		)
	}
}
