<div class="card" style="width: 18rem;">
  <div class="card-body d-flex flex-column justify-content-between">
    <ng-container *ngIf="isSuperAdmin">
      <img id="company-logo" [src]="mspLogo" class="px-2" style="padding-top: 50px; padding-bottom: 66px;" />
    </ng-container>
    <ng-container *ngIf="!isSuperAdmin">
      <img id="company-logo" [src]="companyLogo" class="px-2" style="padding-top: 50px; padding-bottom: 66px;" />
    </ng-container>
    <div class="card-detail-bm">
      <ng-container *ngIf="!isSuperAdmin">
        <h4 class="card-title">{{ Auth.companyName }}</h4>
      </ng-container>
    </div>
    <!-- <div class="row service-header align-items-center">
      <h4 class="text-light">Service(s)</h4>
    </div>
    <div class="row" style="height: 20%;">
      <div class="col-12 d-flex justify-content-center">
        <img id="room-logo" class="img-fluid room-logo" src="../../assets/img/zoom_logo.png" alt="zoom"
          style="padding: 0px; margin-top: 20px; width: 75% !important; height: 75% !important;" />
      </div>
    </div> -->
  </div>
</div>