

import { Component, OnInit, Input ,OnChanges, SimpleChanges} from '@angular/core';
import { GetIotService } from '../../services/get-iot.service';
import { RefreshApiService } from '../../services/refresh-api.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../environments/environment';
import { LinkDeviceSpaceDialogComponent } from '../dialogs/link-device-space-dialog/link-device-space-dialog.component'
import { MatDialog } from '@angular/material/dialog'

export interface DeviceListInterface {
  mac_address: string;
  iot_name: string;
  iot_ip: string;
  device_id: number;
  collector_id: number;
  quadrant_group: string;
  make: string;
  is_monitored: string;
}

@Component({
  selector: 'app-iot-lite-device-list',
  templateUrl: './iot-lite-device-list.component.html',
  styleUrls: ['./iot-lite-device-list.component.scss']
})
export class IotLiteDeviceListComponent implements OnInit, OnChanges {
  iotDeviceList: any = [];
  @Input() displayOnlyMonitored: any;
  @Input() iotLiteEnabled: any;
  isShowDomotzIntegration = environment.config.domotzIntegration;

  /* pagination variables */
  pageSizeSelect: any[] = [];
  totalPageLimit = 500;
  paginateOjb: any = {
    currentPage: 1,
    endIndex: 4,
    pageSize: 0,
    pages: [],
    startIndex: 0,
    totalItems: 0,
    totalPages: 0,
  };
  totalIotLiteDeviceCount = 0;	
  totalCount: number;
  pageSelected: any = 0;
  searchKey: any = 'null';
  shouldShowLoaderBtn: Boolean = false;
  filterListner: Subscription;
  refreshData: Boolean;

  // displayedColumns: any[] = [
  //   'display_name',
  //   'make',
  //   'ip_address',
  //   'mac_address',
  //   'agent_name',
  //   'status',
  //   'is_monitored',
  // ];

    displayedColumns: any[] = [
    'mac_address',
    ...(this.isShowDomotzIntegration ? ['spaces'] : []),
    'iot_name',
    'iot_ip',
    'device_id',
    'collector_id',
    'quadrant_group',
    'make',
    'is_monitored',
    ...(this.isShowDomotzIntegration ? ['link_to_space'] : [])
  ];

  dataSource: MatTableDataSource<DeviceListInterface>;
  loader = environment.config.dynamicImage.loader;

  constructor(private getIot: GetIotService, 
              private refreshApiService: RefreshApiService,
              private dialog: MatDialog

  ) {
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if (m) {
        this.refreshData = m;
        this.getApiData();
      }
    });
  }
  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  getApiData() {
    if (this.refreshData) {
      // this.refreshtIotDeviceList();
    }
  }

  ngOnInit() {
    // this.getIotLiteDeviceList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.iotLiteEnabled && this.iotLiteEnabled) {
      this.checkIfIotDisabled();
    }
  }

  checkIfIotDisabled = () => {
    this.iotDeviceList = [];
    if(this.iotLiteEnabled) {
      this.getIotLiteDeviceList();
    } 
  }

  getIotLiteDeviceList = () => {
    this.shouldShowLoaderBtn = true;
    this.getIot
      .getIotLiteDeviceList(
        'all',
        1
      )
      .subscribe((data) => {
        this.shouldShowLoaderBtn = false;
        const res = data.response
        // console.log(res,'res')
        this.iotDeviceList = res;
        this.totalIotLiteDeviceCount =this.iotDeviceList .length;	
        this.dataSource = new MatTableDataSource(this.iotDeviceList);
        this.dataSource.filterPredicate = function (record: any, filter) {
          return (record.iot_name || '' ).toLocaleLowerCase().includes( (filter || '').toLocaleLowerCase());
        }
      },
       (err) => {
        this.shouldShowLoaderBtn = false;
        }
      );
  };

 
  getStatusColor = ({ status, agent_status }) => {
    if (agent_status === 'OFFLINE') {
      return ' ';
    } else {
      if (status === 'ONLINE') {
        return 'iot-device-list__status-color--green';
      } else {
        return 'iot-device-list__status-color--red';
      }
    }
  };

  setIsMonitored = (_id, _flag) => {
    const flag = this.setFlag(_flag);
    this.getIot.iotLiteSetMonitor(_id, flag).subscribe(
      (data) => {
        // console.log('setIsMonitored: ', data)
       },
      (error) => { }
    );
  };

  setFlag = (flag) => (flag ? 0 : 1);

  searchData = (key) => {
    this.searchKey = key;
    this.dataSource.filter = key || ''.trim().toLowerCase();  
  };
  linkUnlinkSpace(row){
    const dialog = this.dialog.open(LinkDeviceSpaceDialogComponent,{
      panelClass: 'custom-dialog-container',
      data:{
        ...row,
        isDomotz:false
      }
    })
    dialog.afterClosed().subscribe((x)=>{
      if(x){
        this.getIotLiteDeviceList();
      }
    })
  }
  getSpaceName(spaces){
    if(spaces){
      return JSON.parse(spaces).map((x)=>x.space_name)
    }
    return "--"
  }

}
