import moment from 'moment'
import { Subscription } from 'rxjs/Subscription'
import { AlertService } from 'src/app/_alert'
import { GetCollectorService } from 'src/app/services/get-collector.service'
import { SearchService } from 'src/app/services/search.service'

import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

import { build } from '../../../environments/build'
import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'
import { EventEmitterGlobalService } from '../../services/event-emitter-global.service'
import { GetCompanyService } from '../../services/get-company.service'
import { MainMenuPopup } from '../../services/MainMenuPopup.animation'
import { MainMenuTypeService } from '../../services/mainmenuType.service'
import { MembersTypeService } from '../../services/membersType.service'
import { PopupOpenClose } from '../../services/popupContainer.animation'
import { PopupTypeService } from '../../services/popupType.service'
import { TutCloseService } from '../../services/tut-close.service'

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  animations: [MainMenuPopup, PopupOpenClose],
})
export class MainMenuComponent implements OnInit, OnChanges {
  @Output() mainmenuState: string = 'close';
  @Output() membersPopupState: string = 'close';
  @Output() slackStateEvent: EventEmitter<string> = new EventEmitter();
  @Input() newNotificationsCount: number;
  @Input() title: string = 'Title';
  @Input() runTutorial: any = -1;
  @ViewChild('searchBar', { static: false }) searchBarElem: ElementRef;

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement) {
    if (!this.elementRef.nativeElement) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      //Do something.
      this._search.close();
    }
  }

  timeout: any

  firstname: string;
  server_url: string = environment.serverUrl;
  companyLogo: string;
  topTitle: string; //= "Hello..." + this.firstname + "!";

  mainmenuType: number;
  popupType: number;
  subscription: Subscription;
  allSiteListData: any;
  allDeviceListData: any;
  showCongrats: any = 0;
  dev_server = environment.dev_server;
  show_build_version = environment.show_build_version;
  buildVersion = build.version;
  buildStamp = build.stamp;
  keyDownloaded: any;
  getTutorial: any;
  setTutorial: any;
  setTutDownload: any;
  overLayMembersMove: any;
  companyInfoDetails: any;
  // trialEndDay: any;
  accessLevel: any;

  showUpdateCenter: Boolean = false;
  activeMenu = 0;
  activePanel: any;

  siteApiData: any = [];
  reloadSiteApiData: any = 0;

  loginType: any;
  shouldShowAccessLevel: boolean = false;

  isVcare: any = false;
  isVspoint: any = false;
  isVidelio: any = false;

  dashboardLogo = environment.config.dynamicImage.dashboardLogo;
  enableInDevelopment = environment.config.enableInDevelopment;
  membershipSubscriptionLogo =
    environment.config.dynamicImage.membershipSubscriptionLogo;
  isShowDomotzIntegration: any = false;
  mainMenuIotLogo = environment.config.dynamicImage.mainMenuIotLogo;
  iconMenu = environment.config.dynamicImage.navImage;
  iconPressed = environment.config.dynamicImage.navImageHover;

  icon_menu = {
    'background-image': 'url(' + this.iconMenu + ')',
    'background-repeat': 'no-repeat',
    height: '52px',
    width: '52px',
    margin: '10px',
    position: 'relative',
    top: '-5px',
    cursor: 'pointer',
  };

  icon_menu_pressed = {
    'background-image': 'url(' + this.iconPressed + ')',
    'background-repeat': 'no-repeat',
    height: '52px',
    width: '52px',
    margin: '10px',
    position: 'relative',
    top: '-5px',
    cursor: 'pointer',
  };

  collectorStats: {
    critical: number,
    healthy: number,
    total: number,
    warning: number,
    paused: number
  }

  constructor(
    private ds: MembersTypeService,
    private dsM: MainMenuTypeService,
    public http: HttpClient,
    public ds2: PopupTypeService,
    private router: Router,
    public Auth: AuthService,
    public TutClose: TutCloseService,
    private getCompanyService: GetCompanyService,
    private eventEmmit: EventEmitterGlobalService,
    private _search: SearchService,
    private elementRef: ElementRef,
    private getCollectorService: GetCollectorService,
    private alertService: AlertService
  ) {
    this.subscription = this.dsM.getMainMenuType().subscribe((x) => {
      this.mainmenuType = x;
      if (this.mainmenuType != null && this.mainmenuType !== 0) {
        if (this.mainmenuType === 99) {
          this.mainmenuState = 'close';
          this.membersPopupState = 'close';
        } else {
          this.toggleMembersPopup();
          this.togglePopup();
        }
      } else {
        this.mainmenuState = 'close';
        this.membersPopupState = 'close';
      }
    });

    this.eventEmmit.listen().subscribe(
      (emitter: any) => {
        if (emitter == 'teams') {
          this.openTeamsMainMenu();
        }

        if (emitter == 'iotlitecloud') {
          this.openMainMenuSiteList();
        }
      },
      (err) => { }
    );
    this.getCompanyService.companyChangeEvent.subscribe((data: any) => {
        if (data?.logo) {
					this.Auth.logo = data.logo
					this.companyLogo = this.server_url + '/api/v1/images/companylogo/' + data.logo
				}
        if (data?.profile_picture) {
          this.Auth.profilePicture = data.profile_picture;
        }
    })
  }

  Logout() {
    this.Auth.logout();
    // this.router.navigate(['login']);
    // localStorage.removeItem('token');
    // localStorage.removeItem('phone')
    // localStorage.setItem('verified', 'false')
    // localStorage.setItem('keyDownload', '0');
    // localStorage.setItem('reloadSiteData', '0');
    // localStorage.setItem('showCongrats', '0');
    // localStorage.setItem('runTut', '0');
    // localStorage.setItem('successReg', '0');
    // localStorage.setItem('overLayMembersMove', '0');
    // sessionStorage.removeItem('collectorOfflineNotification');
    // sessionStorage.removeItem('teamsBanner');
  }

  // loginUser(event) {
  //   event.preventDefault();

  //   const target = event.target;
  //   const username = target.querySelector('#username').value;
  //   const password = target.querySelector('#password').value;

  //   this.Auth.getUserDetails(username, password).subscribe((data) => {
  //     localStorage.setItem('token', data.response.token);

  //     const token = localStorage.getItem('token');

  //     const helper = new JwtHelperService();
  //     const decodedToken = helper.decodeToken(token);
  //     const expirationDate = helper.getTokenExpirationDate(token);
  //     const isExpired = helper.isTokenExpired(token);
  //     this.accessLevel = decodedToken.accesslevel;
  //     this.companyLogo = decodedToken.logo;
  //     if (!helper.isTokenExpired(token)) {
  //       this.router.navigate(['video/allsites']);
  //     }
  //   });
  // }

  togglePopup() {
    this.mainmenuState = this.mainmenuState === 'open' ? 'close' : 'open';
  }
  toggleMembersPopup() {
    this.membersPopupState =
      this.membersPopupState === 'open' ? 'close' : 'open';
  }

  ngOnInit() {
    //remove
    this.keyDownloaded = localStorage.getItem('keyDownload');

    // const decodedToken = this.Auth.getDecodedToken();
    const decodedToken = this.Auth?.decodedToken
    // const userData = {
    //   company_id: decodedToken.company_id,
    //   user_id: decodedToken.user_id,
    // };
    if (decodedToken.company_id === -1) {
      this.Auth.firstName = decodedToken.first_name;
    } else {
      this.Auth.getUserInfo().subscribe((data: any) => {
        const [res] = data.response;
        this.Auth.firstName = 'User';
        if (res) {
          this.Auth.firstName = res.first_name || 'User';
          this.Auth.profilePicture = res.profile_picture;
        }
      });
    }
    this.topTitle = 'Hello...' + this.Auth.firstName + '!';
    this.getAllSiteDetail();
    this.getSiteApiData();
    this.getCompanyInfo();
    this.getColletorUpdateStatus();
    this.getCollectorStats();

    this.setLoginType();
    this.setEnvirontment();
    
    // reflect change when a site is deleted
    this.getCollectorService.collectorSiteChangeEvent.subscribe(data => {
      this.getCollectorStats()
    })
  }

  setEnvirontment = () => {
    if (environment.config.title === 'vCare') {
      this.isVcare = true;
    }

    if (environment.config.title === 'Vision Point') {
      this.isVspoint = true;
    }

    if (environment.config.title === 'Videlio') {
      this.isVidelio = true;
    }

    if (environment.config.title === 'CXDetect' || this.enableInDevelopment) {
      this.isShowDomotzIntegration = true;
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    // this.membersPopupState = 'open'
    // this.mainmenuState = 'open';
    // this.sendMemberType(9); // visibility
    // this.sendMemberType(1); // poly
    // this.sendMemberType(6); // teams
    // this.sendMemberType(34); // document
    // this.sendMemberType(33); // iot
    // this.sendMemberType(35); // iot lite
    // this.sendMemberType(3); // zoom
    // this.sendMemberType(11); // account
    // if (changes.runTutorial.currentValue === 1) {
    // this.sendMemberType(9);
    // }
  }

  openTeamsMainMenu = () => {
    this.membersPopupState = 'open';
    this.mainmenuState = 'open';
    this.sendMemberType(6); // teams
  };

  openMainMenuSiteList = () => {
    this.membersPopupState = 'open';
    this.mainmenuState = 'open';
    this.sendMemberType(9); // visibility
  };

  // getDecodedToken = () => {
  //   const token = localStorage.getItem('token');
  //   const helper = new JwtHelperService();
  //   return helper.decodeToken(token);
  // };

  setLoginType = () => {
    // const decodedToken = this.Auth.getDecodedToken()
    const decodedToken = this.Auth?.decodedToken
    this.loginType = decodedToken.loginType;
    this.shouldShowAccessLevel =
      this.loginType === 'superAdmin' || this.loginType === 'integrator';
  };

  ngAfterContentChecked(): void {
    this.showCongrats = localStorage.getItem('showCongrats');
    this.overLayMembersMove = localStorage.getItem('overLayMembersMove');
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.topTitle = 'Hello...' + this.Auth.firstName + '!';
  }

  getCompanyInfo() {
    this.getCompanyService.apiGetCompanyInfo().subscribe((data: any) => {
      const [newData] = data.response;
      this.companyInfoDetails = newData;
      // this.calTrialDate();

      this.companyLogo =
        this.server_url + '/api/v1/images/companylogo/' + newData.logo;
    });
  }

  // calTrialDate() {
  //   if (this.companyInfoDetails?.status == 'TRIAL' && !this.shouldShowAccessLevel) {
	// 		let trialInfo = this.companyInfoDetails.trial_info
	// 		let nowDate = moment().format()
	// 		let nowDateM = moment(nowDate)
	// 		let endTrailDate = moment(trialInfo.end_date)
	// 		this.trialEndDay = endTrailDate.diff(nowDateM, 'days')
	// 		const options = {
	// 			// autoClose: true,
	// 			keepAfterRouteChange: false,
	// 		}
	// 		let message
  //     if (this.trialEndDay < 0)
  //       message = 'Trial expired'
  //     else
  //       message=`Trial expires in ${this.trialEndDay} day(s)`
        
  //     this.alertService.error(message, options)
	// 	}
  // }

  setTutorialapi(n) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const settut = {
      company_id: decodedToken.company_id,
      user_id: decodedToken.user_id,
      tutorial: n,
    };
    const tutData = {
      company_id: decodedToken.company_id,
      user_id: decodedToken.user_id,
      tutorial_download: 1,
    };

    localStorage.setItem('showCongrats', '0');
    this.Auth.setTutorial(settut).subscribe((data) => {
      this.setTutorial = data['response'];
      this.runTutorial = n;
    });

    this.Auth.setTutorialDownload(tutData).subscribe((data) => {
      this.setTutDownload = data['response'];
    });

    this.Auth.getTutorial(tutData).subscribe((data) => {
      this.getTutorial = data['response'];
      this.runTutorial = this.getTutorial[0].tutorial;
    });

    if (n == 0) {
      localStorage.setItem('runTut', '0');
      this.runTutorial = 0;
    } else if (n == 1) {
      localStorage.setItem('runTut', '1');
      this.runTutorial = 1;
    }
  }

  getAllSiteDetail() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = {
      company_id: decodedToken.company_id,
    };
    const deviceData = {
      company_id: decodedToken.company_id,
      type: 'polycom',
    };

    this.Auth.getAllSiteList().subscribe((data) => {
      this.allSiteListData = data.response;
    });

    this.Auth.getAllSiteList().subscribe((data) => {
      this.allSiteListData = data['response'];

      let arr = [];
      for (let d in this.allSiteListData) {
        arr.push(this.allSiteListData[d]);
      }
    });

    this.Auth.getAllDeviceList(deviceData).subscribe((data) => {
      this.allDeviceListData = data['response'];

      let arr = [];
      for (let d in this.allDeviceListData) {
        arr.push(this.allDeviceListData[d]);
      }
    });
  }

  getSiteApiData() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = {
      company_id: decodedToken.company_id,
    };

    this.Auth.getSiteList().subscribe((data) => {
      this.siteApiData = data['response'];

      let arr = [];
      for (let d in this.siteApiData) {
        arr.push(this.siteApiData[d]);

        if (this.siteApiData[0].verified >= 1 && this.runTutorial === 1) {
          localStorage.setItem('overLayMembersMove', '1');
          this.overLayMembersMove = 1;
        } else {
          localStorage.setItem('overLayMembersMove', '0');
          this.overLayMembersMove = 0;
        }
      }
    });

    this.Auth.getAllSiteList().subscribe((data) => {
      this.allSiteListData = data.response;
    });
  }

  activeMenuSet(p) {
    this.activeMenu = p;
  }

  sendMemberType = (p) => {
    this.activePanel = p;

    if (this.isVcare) {
      if (p == 'yeahlink') {
        p = 6;
        this.activePanel = 11;
      }
      if (p == 10) {
        p = 0;
        window.open('https://support.vcareanz.com/');
      }
    }

    if (this.isVspoint) {
      if (p == 10) {
        p = 0;
        window.open('http://unifyplus-support.visionpointllc.com');
      }
    }

    if (this.isVspoint) {
      if (p == 10) {
        p = 0;
        window.open('http://support-client@videlio.com');
      }
    }

    this.ds.sendMemberType(p);
    this.ds2.sendPopupType(0);
  };

  ngOnDestroy() {
    this.ds.clearMemberType();

    this.TutClose.clearOverlay();

		if (this.timeout) clearTimeout(this.timeout)
  }

  clearMemberType() {
    this.ds.clearMemberType();
  }

  closeOverlay(p) {
    this.TutClose.closeOverlay(p);
  }

  clearOverlay() {
    this.TutClose.clearOverlay();
  }

  viewCollectors = () => {
    this.sendMemberType(9);
    this.membersPopupState = 'open';
    this.mainmenuState = 'open';
  };

  getColletorUpdateStatus = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const company_id = decodedToken.company_id;

    this.Auth.collectorCheckScheduleUpdateByCompany(company_id).subscribe(
      (data) => {
        if (data.response) {
          const { count } = data.response;
          this.showUpdateCenter = parseInt(count) !== 0;
        }
      }
    );
  };

  viewDocumentCenter = () => {
    this.activeMenu = 6;
    this.membersPopupState = 'close';
    this.ds.sendMemberType(34);
    this.ds2.sendPopupType(0);
  };

  getCollectorStats = () => {
    this.getCollectorService.getCollectorStats().subscribe(resp => {
      this.collectorStats = resp?.data
    })

    this.timeout = setTimeout(() => {
			this.getCollectorStats()
		}, 30_000)
  }

  onClickCollectorStat = (state) => {
    this.router.navigate([`collectors/${state}`])
  }
  
  // axe test 
  
  axeTest = () => {
    console.log('go there')
    //  this.router.navigate(['collector/site/', 5727]);
    this.router.navigate(['video/site/', 819, 'videodevice', 45283928]);
  }

  onClickBellIcon = () => {
    this.slackStateEvent.emit()
  }
  
}
