import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { GetVideoService } from 'src/app/services/get-video.service';
import { AuthService } from 'src/app/services/auth.service';
import { TableFilterService } from 'src/app/services/table-filter.service';

@Component({
  selector: 'app-audio-allsites-list',
  templateUrl: './audio-allsites-list.component.html',
  styleUrls: ['./audio-allsites-list.component.scss']
})
export class AudioAllsitesListComponent implements OnInit {

  @Input() audioSitesListData:MatTableDataSource<any>;
  
  // Settings and Parameters for the the Smart Filter Tables
  loading: boolean = false;
  constructor(
    
    private route: ActivatedRoute,
    private router: Router,
    private Auth: AuthService) { 
      /* This function passes the filter click data globaly 
         to any component subscribe to this service */
      
  }


  ngOnInit() {
  }


  ngOnDestroy(){
  }

  // This function filters the list when certain Live Stat Buttons are clicked
  onFilter(event) {
    const query: string = event[0];
    const cat: string = event[1]
    // if (!query) {
    //   this.audioListData.setFilter([]);
    // } else {
    //   this.audioListData.setFilter([
    //     {
    //       field: cat,
    //       search: query,
    //       filter: (value: any, query: string) =>{
    //         if(cat == "health"){
    //           return +value < +query;
    //         }  else if(cat == 'oncall'){
    //           return +value >= +query;
    //         } else if(cat == 'impacted'){
    //           return +value === +query
    //         }
    //       }
    //     },
    //   ], false);
    // } 
  }
  onLoadFilter() {
    if (this.router.url == '/video/impactedsites') {
      let arr = ['1', 'impacted']
        this.onFilter(arr);
    }
  }


}
