<div class="document-center-dialog">
  <div class="document-center-dialog__header" (click)="closeDialog()">
    <div class="document-center-dialog__close">
      X
    </div>
  </div>

  <div class="document-center-dialog__body">
    <div *ngIf="isLoading" class="document-center-dialog__loader">
      <img src="{{ loader }}" alt="" />
    </div>
    <!-- UPLOAD -->
    <div class="document-center-dialog__upload" *ngIf="panelType === 'upload'">
      <div class="document-center-dialog__upload-text">
        Please Upload a Document
      </div>
      <div class="document-center-dialog__upload-input-file">
        <input type="file" name="" id="" (change)="onFileSelected($event)" [disabled]="isLoading"
          class="document-center-dialog__input" />
      </div>
      <div [class]="'document-center-dialog__upload__disclaimer'+(error ? ' shake' : '')">
        {{error ? error : 'Upload limit 250 MB.'}}
      </div>
      <div class="document-center-dialog__upload-button-container">
        <div *ngIf="isShowOverwrite" class="document-center-dialog__button" (click)="uploadDocument()"
          [ngClass]="{'disabled': isLoading}">
          Overwrite
        </div>
        <div *ngIf="!isShowOverwrite" class="document-center-dialog__button" (click)="saveDocument() "
          [ngClass]="{'disabled': isLoading}">
          Save
        </div>
        <div class="document-center-dialog__button" (click)="closeDialog()">
          Cancel
        </div>
      </div>
      <div *ngIf="isSelectedFileEmpty" class="document-center-dialog__upload__message">
        {{emptyFileMsg}}
      </div>
      <div *ngIf="isShowUploadMessage" class="document-center-dialog__upload__message">
        {{ uploadMessage }}
      </div>
      <div *ngIf="progressBarPercentage > 0" class="document-center-dialog__upload__progress-bar">
        <span>{{ progressBarPercentage }} %</span>
        <mat-progress-bar mode="determinate" [value]="progressBarPercentage"></mat-progress-bar>
      </div>
    </div>
    <!-- UPLOAD -->

    <!-- FlODER -->
    <div class="document-center-dialog__upload" *ngIf="panelType === 'folder'">
      <div class="document-center-dialog__upload-text">
        Please Add a Floder
      </div>
      <div class="document-center-dialog__upload-input-file">
        <input type="text" name="" id="" placeholder="Untitled Floder" [(ngModel)]="folderName"
          class="document-center-dialog__input" />
      </div>
      <!-- <div [class]="'document-center-dialog__upload__disclaimer'+(error ? ' shake' : '')">
        {{error ? error : 'Upload limit 250 MB.'}}
      </div> -->
      <div class="document-center-dialog__upload-button-container">
        <!-- <div *ngIf="isShowOverwrite" class="document-center-dialog__button" (click)="uploadDocument()"
          [ngClass]="{'disabled': isLoading}">
          Overwrite
        </div>
        <div *ngIf="!isShowOverwrite" class="document-center-dialog__button" (click)="saveDocument() "
          [ngClass]="{'disabled': isLoading}">
          Save
        </div> -->
        <div class="document-center-dialog__button" (click)="addDocument() " [ngClass]="{'disabled': isLoading}">
          Create
        </div>
        <div class="document-center-dialog__button" (click)="closeDialog()">
          Cancel
        </div>
      </div>
      <div *ngIf="isSelectedFileEmpty" class="document-center-dialog__upload__message">
        {{emptyFileMsg}}
      </div>
      <div *ngIf="isShowUploadMessage" class="document-center-dialog__upload__message">
        {{ uploadMessage }}
      </div>
      <div *ngIf="progressBarPercentage > 0" class="document-center-dialog__upload__progress-bar">
        <span>{{ progressBarPercentage }} %</span>
        <mat-progress-bar mode="determinate" [value]="progressBarPercentage"></mat-progress-bar>
      </div>
    </div>
    <!-- FlODER -->

    <!-- DELETE -->
    <div class="document-center-dialog__delete" *ngIf="panelType === 'delete'">
      <div class="document-center-dialog__delete-text">
        Are you sure want to delete this document
        <br />
        with file name: {{ document.file_name }} ?
      </div>
      <div class="document-center-dialog__delete-button-container">
        <div class="document-center-dialog__button" (click)="deleteDocument()">
          Proceed
        </div>
        <div class="document-center-dialog__button" (click)="closeDialog()">
          Cancel
        </div>
      </div>
    </div>
    <!-- DELETE -->

    <!-- DELETE FOLDER-->
    <div class="document-center-dialog__delete" *ngIf="panelType === 'dfolder'">
      <div class="document-center-dialog__delete-text">
        Are you sure want to delete this folder
        <br />
        with file name: {{ document.file_name }} ?
      </div>
      <div class="document-center-dialog__delete-button-container">
        <div class="document-center-dialog__button" (click)="deleteFolder()">
          Proceed
        </div>
        <div class="document-center-dialog__button" (click)="closeDialog()">
          Cancel
        </div>
      </div>
    </div>
    <!-- DELETE -->

    <!-- NOT SHARED -->
    <div class="document-center-dialog__not-shared" *ngIf="panelType === 'share'">
      <!-- <strong *ngIf="isCopy">link has been successfully copied.</strong> -->
      <div *ngIf="isDocumentShared" class="document-center-dialog__not-shared-text">
        The following file will be shared.. anyone
      </div>
      <div *ngIf="isDocumentShared" class="document-center-dialog__not-shared-text">
        with the link will be able to view it
      </div>

      <div class="document-center-dialog__not-shared-text-and-button">
        <div class="document-center-dialog__shared-date">
          <label>File: {{ document.file_name }}</label>
          <label>Document share expiration date:</label>
          <input type="datetime-local" id="share-expiration" [(ngModel)]="shareExpiration" [min]="shareDateMin"
            [disabled]="isDocumentShared" />
        </div>
        <div class="document-center-dialog__button" (click)="shareDocument()">
          {{ !isDocumentShared ? 'Share' : 'Unshare' }}
        </div>
      </div>

      <div *ngIf="isDocumentShared" class="document-center-dialog__not-shared-text-and-button position-relative">
        <div class="document-center-dialog__not-shared-text">
          <strong>
            Sharing, the link is:
          </strong>
          <div id="file-path" class="document-center-dialog__not-shared-link">
            <a href="{{ docSharePath }}" target="_blank">{{ docSharePath }}</a>
          </div>
        </div>
        <div class="document-center-dialog__button" (click)="copyAccesUrl(t)" ngbTooltip="link copied!"
          [autoClose]="false" triggers="manual" #t="ngbTooltip">
          Copy link
        </div>
      </div>
    </div>
    <!-- NOT SHARED -->
  </div>
  <div *ngIf="showMsg" class="document-center-dialog__message">
    {{ message }}
  </div>
</div>