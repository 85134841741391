import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-btn-stat-call',
  templateUrl: './btn-stat-call.component.html',
  styleUrls: ['./btn-stat-call.component.scss']
})
export class BtnStatCallComponent implements OnChanges {
  @Input() count: number = 0;
  @Input() serviceType: any;
  @Input() label: string = "Not-Active";

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.count) {
      this.count = changes.count.currentValue
    }
    if (changes.total) {
      this.label = changes.label.currentValue
    }
  }

}
