<div class="motes">
  <div class="notes__title">
    <h5>Notes</h5>
  </div>
  <textarea class="notes__textarea" name="" id="" cols="30" rows="10" value="notes" [(ngModel)]="notes"></textarea>
  <div class="notes__text">
    Maximum 4000 characters
  </div>
  <div class="{{ btnCtrClass }}">
    <div *ngIf="shouldShowMessage" class="notes__message">Save Success!!!</div>
    <div class="notes__btn" (click)="saveNotes()">
      Save
    </div>
  </div>
</div>