<div class="polycom-main-menu">
    <div class="polycom-main-menu__left-container">
        <div class="polycom-main-menu__list-items polycom-main-menu__list-items--active" (click)="selectActivePanel(1)"
            [class.active]="activePanel === 1">
            add a device
        </div>

        <div class="polycom-main-menu__list-items polycom-main-menu__list-items--active" (click)="selectActivePanel(2)"
            [ngClass]="{'polycom-main-menu__disable': !logitechMonitor}" [class.active]="activePanel === 2">
            list of all device
        </div>

        <div class="polycom-main-menu__switch-toggle">
      <div class="polycom-main-menu__switch-toggle-label">
        logitech monitoring
      </div>
      <div class="polycom-main-menu__switch">
        <div>disabled</div>
        <label class="switch">
          <input type="checkbox" [checked]="logitechMonitor" (click)="toggleLogitechMonitor()" [disabled]="disableToggle" />
          <span class="slider round" [ngClass]="{'toggleDisabled': disableToggle}"></span>
        </label>
        <div>enabled</div>
      </div>

      <div class="polycom-main-menu__switch-toggle-label">
        logitech notification
      </div>
      <div class="polycom-main-menu__switch">
        <div class="">disabled</div>
        <label class="switch">
          <input type="checkbox" [checked]="logitechAlert" (click)="toggleLogitechAlerts()" [disabled]="disableNotification || !logitechMonitor" />
          <span class="slider round" [ngClass]="{'toggleDisabled': disableNotification , 'polycom-main-menu__disable': !logitechMonitor}"></span>
        </label>
        <div>enabled</div>
      </div>
    </div>

    </div>
    <div class="polycom-main-menu__right-container">
        <div *ngIf="activePanel === 1" class="components_item">
            <app-logitech-add-device [iotLiteEnabled]="iotLiteEnabled" [logitechMonitor]="logitechMonitor"></app-logitech-add-device>

        </div>
        <div *ngIf="activePanel === 2" class="components_item">
            <app-logitech-device-list></app-logitech-device-list>
        </div>
    </div>
</div>