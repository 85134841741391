import { finalize } from 'rxjs/operators'
import { ScheduleRecurrenceComponent } from 'src/app/components/schedule-recurrence/schedule-recurrence.component'
import { ResetNotificationScheduleComponent } from 'src/app/dialogs/reset-notification-schedule/reset-notification-schedule.component'
import { AuthService } from 'src/app/services/auth.service'
import { environment } from 'src/environments/environment'

import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { NotificationScheduleService } from 'src/app/services/notification-schedule.service'

type Tab = {
	label: string
	columns: any[]
	type: string
}
@Component({
	selector: 'app-notification-schedule',
	templateUrl: './notification-schedule.component.html',
	styleUrls: ['./notification-schedule.component.scss'],
})
export class NotificationScheduleComponent implements OnInit {
	weekList: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
	isShowDomotzIntegration = environment.config.domotzIntegration;
	tabList: Tab[] = [
		{
			label: 'SITES & COLLECTOR',
			columns: [
				{ columnDef: 'site_name', header: 'Site Name', getCell: (row, keyName) => row[keyName] },
				{ columnDef: 'alert', header: 'Alert Status', getCell: (row, keyName) => row[keyName] },
				{ columnDef: 'schedule_date', header: 'Schedule Date', getCell: (row, keyName) => this.formatDate(row) },
			],
			type: 'SITE',
		},
		{
			label: 'VIDEO DEVICES',
			columns: [
				{ columnDef: 'room_name', header: 'Device Name', getCell: (row, keyName) => row[keyName] },
				{ columnDef: 'alert', header: 'Alert Status', getCell: (row, keyName) => row[keyName] },
				{ columnDef: 'schedule_date', header: 'Schedule Date', getCell: (row, keyName) => this.formatDate(row) },
			],
			type: 'VIDEO',
		},
		{
			label: 'CLOUD SERVICES',
			columns: [
				{ columnDef: 'room_name', header: 'Room Name', getCell: (row, keyName) => row[keyName] },
				{ columnDef: 'alert', header: 'Alert Status', getCell: (row, keyName) => row[keyName] },
				{ columnDef: 'schedule_date', header: 'Schedule Date', getCell: (row, keyName) => this.formatDate(row) },
			],
			type: 'CLOUD',
		},
		{
			label: 'HYBRID DEVICES',
			columns: [
				{ columnDef: 'room_name', header: 'Device Name', getCell: (row, keyName) => row[keyName] },
				{ columnDef: 'alert', header: 'Alert Status', getCell: (row, keyName) => row[keyName] },
				{ columnDef: 'schedule_date', header: 'Schedule Date', getCell: (row, keyName) => this.formatDate(row) },
			],
			type: 'AUDIO',
		},
		{
			label: 'IOT',
			columns: [
				{
					columnDef: 'display_name',
					header: 'Device Name',
					getCell: (row, keyName) => row.display_name || row.iot_name,
				},
				{ columnDef: 'alert', header: 'Alert Status', getCell: (row, keyName) => row[keyName] },
				{ columnDef: 'schedule_date', header: 'Schedule Date', getCell: (row, keyName) => this.formatDate(row) },
			],
			type: 'IOT',
		},
	{
			label: 'SPACES',
			columns: [
				{ columnDef: 'space_name', header: 'Space Name', getCell: (row, keyName) => row[keyName] },
				{ columnDef: 'alert', header: 'Alert Status', getCell: (row, keyName) => row[keyName] },
				{ columnDef: 'schedule_date', header: 'Schedule Date', getCell: (row, keyName) => this.formatDate(row)  },
			],
			type: 'SPACE',
		},
	]
	loader = environment.config.dynamicImage.loader
	@Input('isForCompany') isForCompany: boolean = false
	dataSource: any[] = []
	tabIndex: number = 0
	displayedColumns: any[] = []
	page: number = 0
	limit: number = 10
	search: string = ''
	showLoader: boolean = false
	constructor(
		private _authService: AuthService,
		private _notificationScheduleService: NotificationScheduleService,
		private _dialog: MatDialog
	) {}

	ngOnInit(): void {
		if(!this.isShowDomotzIntegration) this.tabList.pop()
		this.onTabChange()
	}
	onTabChange() {
		this.reset()
		this.search = ''
		this.getAllAlerts()
		const columns = this.tabList[this.tabIndex].columns.map(x => x.columnDef)
		this.displayedColumns = [...columns]
	}
	getAllAlerts() {
		this.showLoader = true
		const body = {
			type: this.tabList[this.tabIndex].type,
			search: this.search,
			page: this.page,
			limit: this.limit,
			isForCompany: this.isForCompany,
		}
		this._notificationScheduleService
			.getAllAlertsByDevice(body)
			.pipe(finalize(() => (this.showLoader = false)))
			.subscribe(resp => {
				if (resp?.success) {
					this.dataSource = [...this.dataSource, ...resp.result]
				} else {
				}
			})
	}
	onScroll() {
		this.page++
		this.getAllAlerts()
	}
	reset() {
		this.dataSource = []
		this.page = 1
	}
	onSearch() {
		this.reset()
		this.getAllAlerts()
	}
	open(element) {
		const dialog = this._dialog.open(ScheduleRecurrenceComponent, {
			data: element,
			width: '50%',
		})
		dialog.componentInstance.submit.subscribe(x => {
			element.schedule_start_time = x.start_time
			element.schedule_end_time = x.end_time
			element.schedule_type = x.type
			element.schedule_days = x.schedule_days ? JSON.stringify(x.schedule_days) : null
			element.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
		})
	}
	formatDate(element) {
		if (element.schedule_start_time && element.schedule_end_time) {
			const startTime = this.convertTo12HourFormat(element.schedule_start_time)
			const endTime = this.convertTo12HourFormat(element.schedule_end_time)
			const days = this.getDaysByNumber(element)
			return `${element.schedule_type} - ${days} ${startTime} to ${endTime} (${element.timezone})`
		}
		return null
	}
	convertTo12HourFormat(time: string): string {
		const [hours, minutes] = time.split(':').map(Number)
		const period = hours >= 12 ? 'PM' : 'AM'
		const formattedHours = hours % 12 || 12
		return `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`
	}
	getDaysByNumber(element) {
		if (element.schedule_days && element.schedule_type === 'WEEKLY') {
			const days = JSON.parse(element.schedule_days)
			return days.map(x => this.weekList[x]).join() + ' - '
		}
		return ''
	}
	onSubmit(element) {
		this.showLoader = true
		const body: any = {
			isForCompany: this.isForCompany,
			device_id: element.ns_device_id, 
			device_type: this.tabList[this.tabIndex].type,
			schedule_type: element.schedule_type,
			...(element.schedule_days && {
				schedule_days: JSON.parse(element.schedule_days),
			}),
			schedule_start_time: element.schedule_start_time,
			schedule_end_time: element.schedule_end_time,
			timezone: element.timezone,
		}
		if (element.schedule_id) {
			body.schedule_id = element.schedule_id
			body.is_enabled = element.is_enabled
			delete body.device_id
			delete body.device_type
			delete body.timezone
		}
		this._notificationScheduleService
			.notificationSchedule(body)
			.pipe(finalize(() => (this.showLoader = false)))
			.subscribe(resp => {
				if (resp.success) {
					element.schedule_id = resp?.result?.schedule_id || null
				} else {
				}
			})
	}
	resetData(element){
		element.schedule_type = null
		element.schedule_days = null
		element.schedule_start_time = null
		element.schedule_end_time = null
		element.schedule_id = null
		element.is_enabled = null

	}
	resetAlert(element) {
		if (!element.schedule_id) {
			this.resetData(element)
			return
		}
		const dialog = this._dialog.open(ResetNotificationScheduleComponent)
		dialog.afterClosed().subscribe(x => {
			if (x) this.deleteNotificationSchedule(element)
		})
	}
	deleteNotificationSchedule(element) {
		const body = {
			schedule_id: element.schedule_id,
			isForCompany: this.isForCompany,
		}
		this._notificationScheduleService
			.notificationSchedule(body, true)
			.pipe(finalize(() => (this.showLoader = false)))
			.subscribe(resp => {
				if (resp.success) {
					this.resetData(element)
				} else {
				}
			})
	}
}
