<div class="row right-panel">
  <div class="col-12">
    <app-panel-tools [PopupTitle]="PopupTitle"></app-panel-tools>
  </div>
  <div class="col-12 pl-0">
    <div class="row">
      <div class="col-6">
        <app-cloud-room-info [cloudRoomInfo]="zoomRoomDetails"
          (cloudEvent)="onCloudEvent($event)"></app-cloud-room-info>
      </div>
      <div class="col-6">
        <div class="col-12 px-0">
          <div class="health-container">
            <div (click)="
                this.ds.sendPopupType(54, {
                  'deviceId': this.id,
                  'is_room': true
                })
              " class="incident-button">
              Incidents
            </div>
            <app-system-health [isCursorPointer]="true"
              [healthNum]="zoomRoomDetails?.pause == 1 ? 0 : zoomRoomDetails?.health"
              (click)="this.ds.sendPopupType(54, { 'deviceId': this.id })"></app-system-health>
          </div>
        </div>
        <app-cloud-room-stats [cloudRoomStats]="zoomRoomDetails" [dataReceived]="dataReceived"></app-cloud-room-stats>
      </div>
    </div>
  </div>
</div>