import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { PopupTypeService } from '../../../services/popupType.service';
import { PopupSendDataService } from '../../../services/popup-send-data.service';
import { TableFilterService } from 'src/app/services/table-filter.service';

@Component({
  selector: 'app-video-site-stats-panel',
  templateUrl: './video-site-stats-panel.component.html',
  styleUrls: ['./video-site-stats-panel.component.scss'],
})
export class VideoSiteStatsPanelComponent implements OnInit {
  data: any;
  @Input() videoSitesData: any;
  @Input() isVirtualSite: any;
  @Input() siteSize: any;
  calculatedMbps: any;
  currentFilter: string = '';
  activeFilter: any = '';
  isButton: boolean = true;
  siteId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public ds: PopupTypeService,
    private sendPopupDataService: PopupSendDataService,
    private tableFilterService: TableFilterService
  ) {
    this.route.params.subscribe((params) => {
      this.siteId = params['id'];
    });
  }

  setFilter(filter) {
    if (filter === this.activeFilter) {
      this.activeFilter = '';
      this.clickFilter('');
    } else {
      switch (filter) {
        case 'oncall':
          this.clickFilter('oncall');
          break;
        case 'impacted':
          this.clickFilter('impacted');
          break;
      }
    }
  }

  clickFilter(filter): void {
    this.tableFilterService.filter(filter);
  }

  calMbps() {
    if (this.videoSitesData) {
      let roundnum = this.videoSitesData.bitrate / (1024 * 1024);
      this.calculatedMbps = Math.round(roundnum * 100) / 100;
      return this.calculatedMbps;
    }
  }

  ngOnInit() {
    this.ds.sendPopupType(0);
    this.paramData();
  }

  paramData() {
    this.sendPopupDataService.sendPopupData(this.siteId);
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
  }

  ngOnDestroy() {
    this.ds.clearPopupType();
  }
}
