<div class="forgot" [ngStyle]="visibilityBackground">
  <div class="forgot__col">
    <div class="forgot__box">
      <img [src]="dashboardLogo" style="cursor: pointer;" (click)="routeToHomePage()" />
      <div *ngIf="!message.flag">
        <div class="forgot__text">
          Forgot your password? Enter your email and we’ll send you instructions on how to reset it.
        </div>
        <input class="{{ formControl.email.style }}" type="text" placeholder="email" value="email" [(ngModel)]="email"
          (keyup)="emailKeyEvent($event)" />

        <div *ngIf="formControl.email.flag" class="error-msg">
          {{ formControl.email.message }}
        </div>
        <button class="forgot__btn-primary" [ngClass]="{
            'disable-btn': disableBtn
          }" (click)="validateForm()">
          Submit
        </button>

        <div class="forgot__links">
          <a href="{{ links.terms }}" target="_blank">
            {{ dTerms }}
          </a>
        </div>
      </div>

      <div class="forgot__message {{ message.status }}" *ngIf="message.flag">
        <div class="forgot__message-text" *ngIf="message.status === 'error'">
          {{ message.text }}
        </div>
        <div class="forgot__message-text" *ngIf="message.status !== 'error'">
          Email has been sent with Password reset instructions Link will expire in 24 hours
        </div>

        <a href="/">Return to login</a>
      </div>
    </div>
  </div>
</div>