import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-iot-device-live-stats',
  templateUrl: './iot-device-live-stats.component.html',
  styleUrls: ['./iot-device-live-stats.component.scss'],
})
export class IotDeviceLiveStatsComponent implements OnInit {
  @Input() pingRtd: any = '--';
  @Input() packetLoss: any = '--';

  @Input() medianAlert: Number;
  @Input() medianWarning: Number;

  @Input() packetLossAlert: Number;
  @Input() packetLossWarning: Number;
  constructor() {}

  ngOnInit() {}
  checkRtdAlert = () => {
    if (this.pingRtd > this.medianAlert) {
      return 'livestats__rtd-alert';
    }
    if (this.pingRtd > this.medianWarning) {
      return 'livestats__rtd-warning';
    }

    return '';
  };
  checkPacketLossAlert = () => {
    if (this.packetLoss > this.packetLossAlert) {
      return 'livestats__packetloss-alert';
    }
    if (this.packetLoss > this.packetLossWarning) {
      return 'livestats__packetloss-warning';
    }

    return '';
  };
}
