import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GetPluginService } from 'src/app/services/get-plugin.service';
import { ZoomPluginActionService } from 'src/app/services/zoom-plugin-action.service';

@Component({
  selector: 'app-flush-dns',
  templateUrl: './flush-dns.component.html',
  styleUrls: ['./flush-dns.component.scss']
})
export class FlushDnsComponent implements OnInit, OnChanges {
  @Input('pluginId') pluginId: any;
  @Input('refresh') refresh: any;
  constructor(private _pluginService: GetPluginService,
              private _pluginAction: ZoomPluginActionService,
  ) { }
  message: string = '';
  showLoader: boolean = false;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.refresh && this.pluginId){
      this.flushDNS();
    }
  }
  setFlushDNS(loader:boolean,message?:string){
    this.message = message?.replace('zoom','teams') || `<h5>Something went wrong. Please try again later</h5>`;
    this.showLoader= loader
  }

  flushDNS = () => {
    const actionParams = {};
    const actionName = 'FlushDNS';
    this.setFlushDNS(true,'<h5>Flushing DNS<h5/>')
    this._pluginService.createAction(this.pluginId, actionParams, actionName).subscribe(result => {
      if (result.status === 200) {
        this._pluginAction.checkActionStatus(result.response[0].action_id, this.processActionResults)
        this._pluginAction.setActionCount();
      }
      else {
        this.setFlushDNS(false,result?.error);
      }
    }, err => {
      this.setFlushDNS(false);
    });
  }
  processActionResults = (status, results) => {
    if (status === 'COMPLETE'){
      this.setFlushDNS(false,'<h5> DNS flushed sucessfully!</h5>');
    }
    else {
      this.setFlushDNS(false);
    }
  }

}
