<div class="container-fluid" id="main-box" [ngStyle]="visibilityBackground">
  <div class="row">
    <div class="col-12">
      <form class="signup">
        <div class="form-group">
          <img [src]="dashboardLogo" />
        </div>
        <div class="dot1"></div>
        <br />
        <br />
        <div *ngIf="complete" class="text-center pt-2">
          <p>
            {{ message }}
            <br /><br />
          </p>
          <div *ngIf="!error" class="form-group terms-container text-center pt-2">
            <a routerLink="/login">Click here to login</a>
          </div>
        </div>
        <div *ngIf="showPoweredBy" class="poweredby">
          Powered by VisibilityOne
        </div>
      </form>
      <div class="dot1"></div>
      <div class="dot2"></div>
      <div class="dot3"></div>
      <div class="dot1"></div>
      <div class="dot2"></div>
      <div class="dot3"></div>
      <div class="dot3"></div>
    </div>
  </div>
</div>
