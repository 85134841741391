import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { GetCloudService } from '../../../services/get-cloud.service';
import { RefreshApiService } from '../../../services/refresh-api.service';
import { Subscription } from 'rxjs';
import { TableFilterService } from 'src/app/services/table-filter.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-cloud-all-users-panel',
  templateUrl: './cloud-all-users-panel.component.html',
  styleUrls: ['./cloud-all-users-panel.component.scss'],
})
export class CloudAllUsersPanelComponent implements OnInit, OnDestroy {
  PopupTitle: string = 'CLOUD USERS';
  public cloudUserSummary;
  id: number;
  cloudApiData: any = [];
  cloudUserApiSummary: any = [];
  apiTimers: any;
  refreshData: any;
  filterListner: Subscription;
  cloudUsersListData: any;
  cloudUserListDataLen: any;
  baseFilter: String = 'all';
  loading: any = true;
  loader = environment.config.dynamicImage.loader;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getCloudDataService: GetCloudService,
    private tableFilterService: TableFilterService,
    private refreshApiService: RefreshApiService
  ) {


   this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      this.refreshData = m;
      this.getApiData();
    });
  }

  ngOnInit() {
    this.getcloudApiData();
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }

  getApiData() {
    if (this.refreshData) {
      this.getcloudApiData();
    }
  }

  getcloudApiData() {
    this.getCloudDataService.fetchApiCloudAllUserSummary().subscribe((data) => {
      this.cloudUserSummary = data.response[0];
      // console.log('this.cloudUserSummary: ', this.cloudUserSummary)
      this.loading = false;

    });
  }
}
