import { Component, OnInit, Input } from '@angular/core';
import { GetIotService } from '../../services/get-iot.service';
import * as moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { RefreshApiService } from '../../services/refresh-api.service';

@Component({
  selector: 'app-iot-device-history',
  templateUrl: './iot-device-history.component.html',
  styleUrls: ['./iot-device-history.component.scss'],
})
export class IotDeviceHistoryComponent implements OnInit {
  @Input() deviceId: number;
  @Input() agentId: number;
  @Input() accountUrl: string;
  @Input() apiKey: string;
  filterListner: Subscription;
  eventHistoryList: any;
  isLoading: Boolean;
  isShowTableData: Boolean;

  constructor(private getIot: GetIotService, private refreshApiService: RefreshApiService) {
    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      if (m) {
        this.getDeviceStatusHistory();
      }
    });
  }

  ngOnInit() {
    this.getDeviceStatusHistory();
    this.isLoading = true;
    this.isShowTableData = true;
  }

  getDeviceStatusHistory = () => {
    const dateNow = moment().utcOffset(0, false).format();
    const dateTo = moment(dateNow).utcOffset(0, false).format().split('Z')[0];
    const dateFrom = moment(dateNow)
      .subtract(30, 'days')
      .utcOffset(0, false)
      .format()
      .split('Z')[0];

    this.getIot
      .getDeviceStatusHistory(
        this.deviceId,
        this.agentId,
        this.accountUrl,
        this.apiKey,
        dateFrom,
        dateTo
      )
      .subscribe((data) => {
        this.eventHistoryList = data;

        this.isLoading = false;
        if (this.eventHistoryList.length === 0) {
          this.isShowTableData = false;
        }
      });
  };

  // getEventWithIssues = (events) => {
  //   return events.filter((e) => {
  //     return e.type === 'DOWN';
  //   });
  // };

  formatDate = (date) => {
    return moment(date).format('llll');
  };

  getEventColor = (event) => {
    return event === 'DOWN' ? 'bad-event' : 'good-event';
  };

  ngOnDestroy() {
    this.filterListner.unsubscribe();
  }
}
