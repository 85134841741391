<div class="add-iot-domotz">
  <div class="add-iot-domotz__header">
    <div class="add-iot-domotz__title">
      Add IoT Domotz Devices
    </div>
    <img class="add-iot-domotz__device-button" src="../../../assets/img/iot/delete-btn.png" alt="" autofocus
      (click)="onCancel()" />
  </div>

  <div class="add-iot-domotz__search-bar">
    <input type="text" class="search-default" type="text" placeholder="Search ..." [(ngModel)]="searchKey" />
    <div class="add-iot-domotz__icon">
      <i class="fa fa-search"></i>
    </div>
  </div>

  <div class="add-iot-domotz__device">
    <div class="add-iot-domotz__device-button-container" *ngFor="let c of iotDomotzList | appFilter: searchKey">
      <div class="add-iot-domotz__device-item">
        {{ c.display_name }}
      </div>
      <img src="{{ getIotDeviceSelectedListStatus(c.selected) }}" alt=""
        (click)="getIotDeviceNameAndId(c.iot_device_id, c.display_name)" />
    </div>
  </div>

  <div class="add-iot-domotz__footer">
    <div class="add-iot-domotz__footer__buttons-container">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-button (click)="linkDevice()">Save</button>
      <img src="{{ btnLoader }}" alt="" *ngIf="isLoading" />
    </div>
    <div class="add-iot-domotz__footer__message-container">
      {{ displayMessage }}
    </div>
  </div>
</div>