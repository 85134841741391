import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-zoom-status',
  templateUrl: './zoom-status.component.html',
  styleUrls: ['./zoom-status.component.scss'],
})
export class ZoomStatusComponent implements OnInit, OnInit, OnChanges {
  @Input() pluginId: number;
  @Input() pluginEnabled: number;
  @Input() pluginStatus: number;
  @Input() status: string;
  @Input() pluginApp: any;
  @Input() pause: number;

  statusText: string;
  statusBtnColor: string;
  pluginBtnColor: string;
  pluginLabel: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.checkIfOnline()) {
      this.statusText = 'Online';
      this.statusBtnColor = 'online-btn';
    } else if (this.checkOffline()) {
      this.statusText = 'Offline';
      this.statusBtnColor = 'offline-btn';
    } else if (this.checkPause()) {
      this.statusText = 'Pause';
      this.statusBtnColor = 'pause-btn';
    } else {
      this.statusText = 'Offline';
      this.statusBtnColor = 'offline-btn';
    }

    this.checkPluginIfOnline();
  }

  checkIfOnline() {
    if (this.pluginId === null || this.pluginEnabled === 0) {
      return this.status === 'Available' && this.pause === 0;
    }

    if (this.pluginStatus === 1) {
      return this.pluginApp[0].zoom_room === 1 && this.pause === 0;
    } else {
      return this.status === 'Available' && this.pause === 0;
    }
  }

  checkOffline() {
    if (this.pluginId === null || this.pluginEnabled === 0) {
      return this.status !== 'Available' && this.pause === 0;
    }

    if (this.pluginStatus === 1) {
      return this.pluginApp[0].zoom_room !== 1 && this.pause === 0;
    } else {
      return this.status !== 'Available' && this.pause === 0;
    }
  }

  checkPause() {
    if (this.pluginApp !== null) {
      return this.pause === 1 && this.pluginApp[0].zoom_room === 1;
    }
    return false;
  }

  checkPluginIfOnline = () => {
    if (this.pluginEnabled === 0) {
      this.pluginLabel = 'Plugin Offline';
      this.pluginBtnColor = 'grey-btn';
    } else if (this.pluginStatus === 1) {
      this.pluginLabel = 'Plugin Online';
      this.pluginBtnColor = 'online-btn';
    } else if (this.pluginStatus === -1) {
      this.pluginLabel = 'Plugin Offline';
      this.pluginBtnColor = 'offline-btn';
    } else {
      this.pluginLabel = 'Plugin Offline';
      this.pluginBtnColor = 'grey-btn';
    }
  };
}
