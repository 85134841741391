import { Component, Input, OnInit, SimpleChanges } from '@angular/core'

import { environment } from '../../../environments/environment'

@Component({
	selector: 'app-teams-room-stats',
	templateUrl: './teams-room-stats.component.html',
	styleUrls: ['./teams-room-stats.component.scss'],
})
export class TeamsRoomStatsComponent implements OnInit {
	@Input() teamsDetails: any
	@Input() isTeamsEnabled: any
	@Input() spaceStatus: any
	@Input() logitechSync: boolean
	@Input() logitechScribe: any

	isUserOnCall: boolean = false

	isNotButton: any
	cloudUserLivestats: any
	cloudUserUtilization: any
	hasAccess: boolean

	cloudUserId: any
	accessLevel: any
	// isNotButton: string;
	// hasAccess: boolean;
	isSuperAdmin: boolean

	isZoomPluginEnabled: boolean = false
	deskPlugin: String = environment.config.dynamicText.deskPlugin
	desktopPluginMessage: string
	deskPluginStatus: number
	isShowPeopleCount: boolean

	constructor() {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.teamsDetails && this.teamsDetails) {
			if (this.teamsDetails.liveMeetings == 1) {
				this.isUserOnCall = true
			}
			this.setData()

			// desktop plugin display message
			this.desktopPluginMessage = this.getDesktopPluginMessage()
			this.isZoomPluginEnabled = this.teamsDetails.zoom_plugin_enabled === 1
			this.deskPluginStatus = this.teamsDetails.plugin_status
		}
	}

	setData = () => {
		const {
			zoom_user_id,
			liveMeetings,
			scheduledMeetings,
			scheduled,
			trigger_limits,
			total_bitrate,
			total_jitter,
			avg_packet_loss,
			frame_rate,
			triggers,
			totalMeetings,
			past_meetings,
			totalRecordings,
			recordings,
			zoom_plugin_connected_devices,
			plugin_application,
			service_type,
			service,
			plugin_host_info,
			zoom_plugin_data,
			total_teams_meetings_count,
			plugin_id,
			plugin_status,
			zoom_plugin_enabled,
			calculatedMbpsTeams,
			wifiRange,
			id,
			wifiSsid,
			isTeamsEnabled,
		} = this.teamsDetails

		this.cloudUserLivestats = {
			liveMeetings,
			scheduledMeetings,
			scheduled,
			cloudUserId: zoom_user_id,
			hasAccess: this.hasAccess,
			trigger_limits: trigger_limits || 0,
			total_bitrate: totalRecordings || 0,
			total_jitter: total_jitter || 0,
			avg_packet_loss: avg_packet_loss || 0,
			frame_rate,
			triggers: triggers || 0,
			zoom_plugin_connected_devices,
			plugin_application,
			zoom_user_id,
			service_type,
			plugin_host_info: plugin_host_info,
			zoom_plugin_data,
			plugin_id,
			plugin_status,
			zoom_plugin_enabled,
			calculatedMbpsTeams,
			wifiRange,
			isTeamsEnabled,
			id,
			wifiSsid,
		}

		// // console.log(' cloudUserLivestats: ', this.cloudUserLivestats)
	}

	getHasAccess = () => {
		if (this.accessLevel) {
			if (this.accessLevel === 'USER' || this.isSuperAdmin) {
				return false
			}
			return true
		} else {
			return false
		}
	}

	getDesktopPluginMessage = () => {
		if (this.teamsDetails.plugin_id === null) {
			return `To enable advance features, please download and install the ${this.deskPlugin}.`
		}
		return ''
	}
}
