<button type="button" style="color:gray"
  [ngClass]="{'btnbacklite': backcolorbtn == 'btnbacklite', 'not-button': !isButton }" class="btn btn-long not-button">
  <div class="row">
    <div class="col-6 text-left">
      <h2 class="">Scheduled <br>Meetings</h2>
    </div>
    <div class="col-6 text-right">
      <!-- <h1 class="mr-5">{{ siteStats?.meetings}}</h1> -->
      <h1>{{count}}</h1>
    </div>
  </div>
</button>