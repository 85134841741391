<div class="teams-room-device-list-popup">
    <div id="scroll-bar" style="overflow: auto; height: 280px;">
        <table class="table table-stripped">
            <thead>
                <tr class="black">
                    <th scope="col">Device</th>
                    <th scope="col">Status</th>
                    <!-- <th scope="col">User Name</th> -->

                </tr>
            </thead>
            <tbody>
                <!-- <tr scope="row"> -->
                <tr scope="row" *ngFor="let item of deviceList">
                    <td> {{item.name}} </td>
                    <td>{{ zoomPluginStatus.pluginStatus[item.status] }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>