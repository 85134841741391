<div class="camera">
  <table>
    <thead>
      <tr>
        <th>Camera List</th>
        <th>Primary</th>
        <th>Backup</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody *ngFor="let data of camList; let i = index">
      <tr scope="row">
        <td>{{ data.name }}</td>
        <td>
          <div>
            <label class="switch" [ngClass]="{
                'camera__disable':
                  !cameraFailOver ||
                  data.primary ||
                  data.status == 4 ||
                  data.status == 11 ||
                  data.status == 12
              }">
              <input type="checkbox" [checked]="data.primary ? true : false" (click)="listOnChange(i, true, false)" />
              <span class="slider round">
                <span class="paused"></span>
              </span>
            </label>
          </div>
        </td>
        <td>
          <div>
            <label class="switch" [ngClass]="{
                'camera__disable':
                  !cameraFailOver ||
                  data.primary ||
                  data.status == 4 ||
                  data.status == 11 ||
                  data.status == 12
              }">
              <input #inputCheckboxList type="checkbox" [checked]="data.backup !== 0 ? true : false"
                (change)="listOnChange(i, false, inputCheckboxList.checked)" />
              <span class="slider round">
                <span class="paused"></span>
              </span>
            </label>
          </div>
        </td>
        <td>{{ zoomPluginStatus.pluginStatus[data.status] }}</td>
      </tr>
    </tbody>
  </table>
  <div class="camera__enable-failover">
    <div>
      <label class="switch">
        <input type="checkbox" [checked]="cameraFailOver" (click)="enableOrDisableCameraFailOver(cameraFailOver)" />
        <span class="slider round">
          <span class="paused"></span>
        </span>
      </label>
    </div>
    <span>Enable Camera Failover</span>
    <div *ngIf="cameraFailOver">
      <button (click)="saveFailOver()">Save</button>
      <!-- <button (click)="resetCloudRoomfields()">Cancel</button> -->
    </div>
    <div class="row alert alert-danger" style="margin-top: 10px;">
      Warning: Controller will go offline while your setting is changed.
    </div>
  </div>
</div>