<alert></alert>
<div class="integrations">
  <h2>Integrations</h2>
</div>

<mat-stepper orientation="vertical" labelPosition="end" [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Webhooks</ng-template>
      <mat-form-field>
        <mat-label>Webhook Url:</mat-label>
        <input matInput placeholder="Webhook url" id="url" type="text" formControlName="urlFormControl" required />
      </mat-form-field>
      <mat-form-field class="formfield-large">
        <mat-label>Secret Token:</mat-label>
        <div class="integrations-btn-container">
          <input matInput id="token" type="text" formControlName="tokenValue" required />
          <!-- <button (click)="generateToken()" [disabled]="loading" id="gen-token">
            <span *ngIf="!loading"> Generate </span>
            <img src="{{ loader }}" *ngIf="loading" id="generating-token-btn" />
          </button> -->
        </div>
      </mat-form-field>
      <div (click)="test123()">
        <button matStepperNext class="pull-right">Next</button>
      </div>
      <button (click)="submitIntegration()" class="pull-right" style="margin-right: 7px;">
        Sav{{ submitting ? "ing..." : "e" }}
      </button>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Public Apis</ng-template>
      <mat-label>Api Secret:</mat-label>
      <mat-form-field class="formfield-large">
        <div class="integrations-btn-container">
          <input matInput id="api-secret" type="text" formControlName="apiSecret" readonly />
          <button (click)="generateApiSecret()" [disabled]="secretLoading" id="gen-secret">
            <span *ngIf="!secretLoading"> Generate </span>
            <img src="{{ loader }}" *ngIf="secretLoading" id="generating-secret-btn" />
          </button>
        </div>
      </mat-form-field>
      <div class="generate-style">

        <button (click)="submitPublicIntegration()" class="pull-right">
          Sav{{ submitting ? "ing..." : "e" }}
        </button>
      </div>
    </form>
  </mat-step>
</mat-stepper>


<div>
  Note: Please copy the newly generated API key before hitting save
</div>