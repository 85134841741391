<button type="button" class="not-button firmware-devices" id="btn">
  <div class="row">
    <div class="col-12">
      <h3 class="count">{{count}}</h3>
      <p>{{label}}</p>
    </div>
    <div class="col-12" *ngIf="device=='jabra'">
      <img src="../../../../assets/img/audio/jabra_logo.png@1X.png" alt="firmware updates">
    </div>
    <div class="col-12" *ngIf="device=='plan'">
      <img src="../../../../assets/img/audio/plantronics_logo.png@sm.png" alt="firmware updates">
    </div>
  </div>
</button>