<div class="logitech-scribe-panel-container">
  <h5>Logitech Scribe</h5>
  <div class="logitech-scribe-image-and-text">
    <img src="{{ icon }}" alt="scribe_status">
    <div class="logitech-scribe-text">
      <div>
        <h6>Status</h6>
        <p>{{status}}</p>
      </div>
    </div>
  </div>
</div>