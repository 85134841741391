<div class="zoom">
  <app-download-icons [componentName]="componentName" [cloudRadioProp]="tableSelected"
    [desktopClientDownloadLink]="desktopClientDownloadLink" [siteSummary]="siteSummary"
    (sendPopupType)="sendPopupType($event)"></app-download-icons>

  <div [ngClass]="{ 'zoom__margin-top': tableSelected === 'service' }" class="zoom__radio-button-and-search">
    <div class="zoom__radio-button">
      <mat-radio-group [(ngModel)]="tableSelected" (change)="radioChange($event.value)">
        <mat-radio-button value="service">Integrate & Manage Service</mat-radio-button>
        <mat-radio-button value="rooms">List of Rooms</mat-radio-button>
        <mat-radio-button value="users">List of Users</mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="tableSelected !== 'service'" class="zoom__search">
      <div class="zoom__icon">
        <i class="fa fa-search"></i>
      </div>
      <input type="text" class="search-default" type="text" placeholder="" [(ngModel)]="searchKey" (ngModelChange)="search()" />
    </div>
  </div>
  <div class="zoom__table-container">
    <app-zoom-manage-service *ngIf="tableSelected === 'service'" (sendPopupType)="sendPopupType($event)"
      [zoomInfoResult]="zoomInfoResult"></app-zoom-manage-service>

    <app-zoom-rooms *ngIf="tableSelected === 'rooms'" [zoomRoomList]="zoomRoomList"
      (sendPopupType)="sendPopupType($event)" [showLoader]="showLoader">
    </app-zoom-rooms>

    <app-zoom-users *ngIf="tableSelected === 'users'" [zoomUserList]="zoomUserList"
      (sendPopupType)="sendPopupType($event)" [hostToLink]="hostToLink" [showLoader]="showLoader">
    </app-zoom-users>
  </div>
</div>