import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-iot-device-last-event',
  templateUrl: './iot-device-last-event.component.html',
  styleUrls: ['./iot-device-last-event.component.scss'],
})
export class IotDeviceLastEventComponent implements OnInit, OnChanges {
  @Input() latestEventsMins: any = '--';
  @Input() latestEvents24h: any = '--';
  message: any = '--';
  timestamp: any = '--';

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.latestEventsMins) {
      if (this.latestEventsMins) {
        const { timestamp, message } = this.latestEventsMins;
        this.message = message;
        this.timestamp = timestamp;
      }
    }
  }
}
