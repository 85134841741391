<div class="iotadmin">
  <div class="iotadmin__btn_ctr">
    <button class="iotadmin__btn" (click)="showAlerts()">
      Alerts
    </button>

    <button class="iotadmin__btn" (click)="showDocumentCenter()">
      Documents
    </button>

    <button class="iotadmin__btn" (click)="syncDomotzAccount()">
      Sync Device
    </button>
  </div>
  <div class="iotadmin__comp_ctr">
    <app-iot-admin-alerts *ngIf="shouldShowAlerts" [iotDeviceDetails]="iotDeviceDetails" [popUpEmitter]="popUpEmitter">
    </app-iot-admin-alerts>
    <div *ngIf="showSyncMessage" class="iotadmin__sync-message {{ messageclass }}">
      {{ syncMessage }}
    </div>
    <app-documents class="admin__components" *ngIf="shouldShowDocuments" [propData]="propData"></app-documents>
  </div>
</div>