<div class="video-remote">
  <h5>Touch Control</h5>
  <div class="video-remote__image-and-text">
    <img src="{{ touchIcon }}" alt="polycom_touch">
    <div class="video-remote__text">
      <div class="video-remote__text-item">
        <h6>Name: </h6>
        <div>{{tapName | displayEmpty}}</div>
      </div>
      <div class="video-remote__text-item">
        <h6>Orientation: </h6>
        <div>{{tapOrientation | displayEmpty}}</div>

      </div>
      <div class="video-remote__text-item">
        <h6>Firmware: </h6>
        <div>{{tapFirmware | displayEmpty}}</div>

      </div>
      <!-- <div class="video-remote__text-item">
        <h6>Power Saving:</h6>
        <div>{{tapPowerSaving | displayEmpty}}</div>

      </div> -->

    </div>
  </div>
</div>