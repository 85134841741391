import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-video-touch-panel',
  templateUrl: './video-touch-panel.component.html',
  styleUrls: ['./video-touch-panel.component.scss']
})
export class VideoTouchPanelComponent implements OnInit, OnChanges {
@Input() pairingsData: any;
  onlineIcon: string = '../../../../assets/img/touch_icon/polycom_online.png';
  offlineIcon:string = '../../../../assets/img/touch_icon/polycom_offline.png';
  touchIcon: string = this.offlineIcon;
  
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges) {
    if(changes.pairingsData) {
      if(this.pairingsData.location !== 'unknown'){
        this.touchIcon = this.onlineIcon;
      }
      else{
        this.touchIcon = this.offlineIcon;
      }
    }
  }

}
