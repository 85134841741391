import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  ActivatedRoute,
  Event,
  RouterEvent,
  NavigationStart,
  NavigationEnd,
} from '@angular/router';
import { Router } from '@angular/router';
import { GetVideoService } from '../../../services/get-video.service';
import { TableFilterService } from '../../../services/table-filter.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-video-allsites-list',
  templateUrl: './video-allsites-list.component.html',
  styleUrls: ['./video-allsites-list.component.scss'],
})
export class VideoAllsitesListComponent implements OnInit {
  @Input() videoSitesListData: MatTableDataSource<any>;

  // Settings and Parameters for the the Smart Filter Tables
  videoSitesTableSetting =
    this.Auth.accessLevel == 'SUPERADMIN'
      ? this.getVideoDataService.videoSitesTableSettingsSuperAdmin
      : this.getVideoDataService.videoSitesTableSettings;
  loading: boolean = false;
  loader = environment.config.dynamicImage.loader;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getVideoDataService: GetVideoService,
    private tableFilterService: TableFilterService,
    private Auth: AuthService
  ) {
    /* This function passes the filter click data globaly 
         to any component subscribe to this service */
  }

  ngOnInit() {}

  // This function filters the list when certain Live Stat Buttons are clicked
  onFilter(event) {
  }
  onLoadFilter() {
    if (this.router.url == '/video/impactedsites') {
      let arr = ['1', 'impacted'];
      this.onFilter(arr);
    }
  }
}
