import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { TableFilterService } from 'src/app/services/table-filter.service';
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { GetCloudService } from 'src/app/services/get-cloud.service';

@Component({
  selector: 'app-cloud-all-rooms-list',
  templateUrl: './cloud-all-rooms-list.component.html',
  styleUrls: ['./cloud-all-rooms-list.component.scss'],
})
export class CloudAllRoomsListComponent implements OnInit, OnChanges {
  @Input() backcolorbtn: any;
  @Input() cloudRoomData: any;

  cloudRoomTableSettings =
    this.Auth.accessLevel == 'SUPERADMIN'
      ? this.getCloudDataService.cloudRoomTableSettingsSuperAdmin
      : this.getCloudDataService.cloudRoomTableSettings;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getCloudDataService: GetCloudService,
    private tableFilterService: TableFilterService,
    private Auth: AuthService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.cloudListData && changes.cloudListData.currentValue != changes.cloudListData.previousValue) {
      this.backcolorbtn = 'btnbacklite';
      setTimeout(() => {
        this.backcolorbtn = 'btnbacklite-default';
      }, 2500);
    } else {
      setTimeout(() => {
        this.backcolorbtn = 'btnbacklite-default';
      }, 1000);
    }
  }


  onLoadFilter() {
    if (this.router.url == '/cloud/impactedrooms') {
      let arr = ['76', 'health'];
    }
  }

  ngOnInit() {
    this.onLoadFilter();
  }

}
