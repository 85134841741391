import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service';
import { environment } from '../../../../environments/environment';

import { A11yModule } from '@angular/cdk/a11y';
@Component({
  selector: 'app-delete-room-dialog',
  templateUrl: './delete-room-dialog.component.html',
  styleUrls: ['./delete-room-dialog.component.scss'],
})
export class DeleteRoomDialogComponent {
  pluginId: any;
  hostName: any;
  roomName: any;
  isLoading: any = false;
  btnLoader: string = environment.config.dynamicImage.loader;
  dPlugin: String = environment.config.dynamicText.dPlugin;
  sendPopupType: EventEmitter<any>;
  errorMessage: String = '';

  constructor(
    private Auth: AuthService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DeleteRoomDialogComponent>
  ) {
    if (data) {
      const { plugin_id, host_name, room_name, sendPopupType } = data.config;
      this.pluginId = plugin_id;
      this.hostName = host_name;
      this.roomName = room_name;
      this.sendPopupType = sendPopupType;
    }
  }

  closeDialog = () => {
    this.isLoading = false;
    this.dialogRef.close(true);
  };

  deactivateRoomPlugin = () => {
    this.isLoading = true;
    this.Auth.deactivateRoomPlugin(this.pluginId).subscribe((data) => {
      if (data['response'][0]['status'] === 'OK') {
        this.sendPopupType.emit('roomPluginList');
        this.closeDialog();
      } else {
        this.errorMessage = data?.error  || 'Something went wrong while deleting plugin';
        this.isLoading = false;
      }
    });
  };
}
