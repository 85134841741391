<div class="row" *ngIf="!beingFetched && isShown">
  <div class="col-12">
    <div class="card card-margin">
      <div class="card-body">
        <div class="col-lg-12">
          <div class="result-body">
            <div class="table-responsive">
              <div *ngIf="showResultEmpty" style="text-align: center;">
                <span class="null-result"> No result found!</span>
              </div>
              <table class="table widget-26">
                <tbody>
                  <tr *ngFor="let vid of videos | slice:0:3">
                    <td>
                      <div class="widget-26-job-title">
                        <a [routerLink]="'/video/site/' + vid.site_id + '/videodevice/' + vid.video_device_id"
                          (click)="closeModal()">{{
                          vid.device_name }}</a>
                        <p class="m-0">
                          <label class="employer-name fw-bold">Device Type:
                          </label>
                          <span class="text-muted time">{{
                            vid.device_type
                            }}</span>
                        </p>
                        <div *ngFor="let meta of vid.meta">
                          <p class="m-0" *ngIf="meta == 'room_name'">
                            <label class="employer-name fw-bold">Room Name:
                            </label>
                            <span class="text-muted time highlight">{{
                              vid.room_name
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'mac'">
                            <label class="employer-name fw-bold">Mac Address:
                            </label>
                            <span class="text-muted time highlight highlight">{{
                              vid.mac
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'ipv4'">
                            <label class="employer-name fw-bold">IP:
                            </label>
                            <span class="text-muted time highlight highlight">{{
                              vid.ipv4
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'device_name'">
                            <label class="employer-name fw-bold">Device Name:
                            </label>
                            <span class="text-muted time highlight highlight">{{
                              vid.device_name
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'site_name'">
                            <label class="employer-name fw-bold">Site :
                            </label>
                            <span class="text-muted time highlight highlight">{{
                              vid.site_name
                              }}</span>
                          </p>

                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="widget-26-job-info">
                        <span class="type m-0 fw-bold">Site: </span>
                        <span class="text-muted m-0">
                          <span class="location">{{ vid.site_name }}</span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="widget-26-job-category bg-soft-warning">
                        <span>Video</span>
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let site of sites | slice:0:3">
                    <td>
                      <div class="widget-26-job-title">
                        <a [routerLink]="'/video/site/'+ site.site_id" (click)="closeModal()">{{ site.site_name
                          }}</a>
                      </div>
                      <div *ngFor="let meta of site.meta">
                        <p class="m-0" *ngIf="meta == 'site_name'">
                          <label class="employer-name fw-bold">Site:
                          </label>
                          <span class="text-muted time highlight">{{
                            site.site_name
                            }}</span>
                        </p>
                      </div>
                    </td>
                    <td>
                      <span class="fw-bold"> Devices: </span>
                      {{ site.devices }}
                    </td>
                    <td>
                      <div class="widget-26-job-category bg-soft-success">
                        <span>Site</span>
                      </div>
                    </td>
                  </tr>

                  <tr *ngFor="let user of user_only | slice:0:3">
                    <td>
                      <div class="widget-26-job-title">
                        <a [routerLink]="'/cloud/user/'+ user.zoom_user_id" (click)="closeModal()">
                          {{ user.firstname +' '+ user.lastname }}</a>
                      </div>
                      <div *ngFor="let meta of user.meta">
                        <p class="m-0" *ngIf="meta == 'email'">
                          <label class="employer-name fw-bold">Email:
                          </label>
                          <span class="text-muted time highlight">{{
                            user.email
                            }}</span>
                        </p>
                        <p class="m-0" *ngIf="meta == 'firstname' || meta == 'lastname'">
                          <label class="employer-name fw-bold">User Name:
                          </label>
                          <span class="text-muted time highlight">{{
                            user.firstname + ' ' + user.lastname
                            }}</span>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="widget-26-job-info">
                        <span class="type m-0 fw-bold">Email: </span>
                        <span class="text-muted m-0">
                          <span class="location">{{ user.email }}</span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="widget-26-job-category bg-soft-danger">
                        <span>User</span>
                      </div>
                    </td>
                  </tr>


                  <tr *ngFor="let user of rooms_only | slice:0:3">
                    <td>
                      <div class="widget-26-job-title">
                        <a [routerLink]="'/teams/room/'+ user.zoom_user_id" (click)="closeModal()">
                          {{user.room_name}}</a>
                      </div>
                      <div *ngFor="let meta of user.meta">
                        <p class="m-0" *ngIf="meta == 'email'">
                          <label class="employer-name fw-bold">Email:
                          </label>
                          <span class="text-muted time highlight">{{
                            user.email
                            }}</span>
                        </p>
                        <p class="m-0" *ngIf="meta == 'room_name'">
                          <label class="employer-name fw-bold">Room Name:
                          </label>
                          <span class="text-muted time highlight">{{
                            user.room_name
                            }}</span>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="widget-26-job-info">
                        <span class="type m-0 fw-bold">Email: </span>
                        <span class="text-muted m-0">
                          <span class="location">{{ user.email }}</span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="widget-26-job-category bg-soft-danger">
                        <span>Room</span>
                      </div>
                    </td>
                  </tr>



                  <tr *ngFor="let iot of iot | slice:0:3">
                    <td>
                      <div class="widget-26-job-title">
                        <a [routerLink]="'/iot/lite/device/'+iot.iot_id" (click)="closeModal()">{{ iot.iot_name
                          }}</a>
                      </div>
                      <div *ngFor="let meta of iot.meta">
                        <p class="m-0" *ngIf="meta == 'mac_address'">
                          <label class="employer-name fw-bold">Mac Address:
                          </label>
                          <span class="text-muted time highlight">{{
                            iot.mac_address
                            }}</span>
                        </p>
                        <p class="m-0" *ngIf="meta == 'iot_ip'">
                          <label class="employer-name fw-bold">IP:
                          </label>
                          <span class="text-muted time highlight">{{
                            iot.iot_ip
                            }}</span>
                        </p>
                        <p class="m-0" *ngIf="meta == 'iot_name'">
                          <label class="employer-name fw-bold">IOT Name:
                          </label>
                          <span class="text-muted time highlight">{{
                            iot.iot_name
                            }}</span>
                        </p>
                        <p class="m-0" *ngIf="meta == 'make'">
                          <label class="employer-name fw-bold">Make:
                          </label>
                          <span class="text-muted time highlight">{{
                            iot.make
                            }}</span>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="widget-26-job-info">
                        <span class="type m-0 fw-bold">Make: </span>
                        <span class="text-muted m-0">
                          <span class="location">{{ iot.make }}</span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="widget-26-job-category bg-soft-info">
                        <span>Iot</span>
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let aud of audio | slice:0:3">
                    <td>
                      <div class="widget-26-job-title">
                        <a [routerLink]="'/audio/site/' + aud.site_id + '/audiodevice/' + aud.audio_device_id"
                          (click)="closeModal()">{{
                          aud.room_name }}</a>
                        <p class="m-0">
                          <label class="employer-name fw-bold">Device Type:
                          </label>
                          <span class="text-muted time">{{
                            aud.device_type
                            }}</span>
                        </p>
                      </div>
                      <div *ngFor="let meta of aud.meta">
                        <p class="m-0" *ngIf="meta == 'room_name'">
                          <label class="employer-name fw-bold">Room Name:
                          </label>
                          <span class="text-muted time highlight">{{
                            aud.room_name
                            }}</span>
                        </p>
                        <p class="m-0" *ngIf="meta == 'mac'">
                          <label class="employer-name fw-bold">Mac Address:
                          </label>
                          <span class="text-muted time highlight highlight">{{
                            aud.mac
                            }}</span>
                        </p>
                        <p class="m-0" *ngIf="meta == 'ipv4'">
                          <label class="employer-name fw-bold">IP:
                          </label>
                          <span class="text-muted time highlight highlight">{{
                            aud.ipv4
                            }}</span>
                        </p>
                        <p class="m-0" *ngIf="meta == 'site_name'">
                          <label class="employer-name fw-bold">Site :
                          </label>
                          <span class="text-muted time highlight highlight">{{
                            aud.site_name
                            }}</span>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="widget-26-job-info">
                        <span class="type m-0 fw-bold">Site: </span>
                        <span class="text-muted m-0">
                          <span class="location">{{ aud.site_name }}</span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="widget-26-job-category bg-soft-warning">
                        <span>Audio</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="m-3">
        <button type="button" class="btn-sm" (click)="openDialog()" *ngIf="!showResultEmpty">Show All
        Results</button>
      </div>
    </div>
  </div>
</div>

<ng-template #allResults>
  <div class="d-flex justify-content-end">
    <button matDialogClose class="btn-self2">
      <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="home">X</mat-icon>
    </button>
  </div>
  <div class="dialog-content">
    <div class="card-body">
      <div class="row search-body">
        <div class="col-lg-12">
          <div class="search-result">
            <div class="result-body">
              <div class="table-responsive">
                <table class="table widget-26">
                  <tbody>
                    <tr *ngFor="let vid of videos">
                      <td>
                        <div class="widget-26-job-title">
                          <a [routerLink]="'/video/site/' + vid.site_id + '/videodevice/' + vid.video_device_id"
                            mat-dialog-close>{{
                            vid.device_name }}</a>
                          <p class="m-0">
                            <label class="employer-name fw-bold">Device Type:
                            </label>
                            <span class="text-muted time">{{
                              vid.device_type
                              }}</span>
                          </p>

                        </div>
                        <div *ngFor="let meta of vid.meta">
                          <p class="m-0" *ngIf="meta == 'room_name'">
                            <label class="employer-name fw-bold">Room Name:
                            </label>
                            <span class="text-muted time highlight">{{
                              vid.room_name
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'mac'">
                            <label class="employer-name fw-bold">Mac Address:
                            </label>
                            <span class="text-muted time highlight highlight">{{
                              vid.mac
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'ipv4'">
                            <label class="employer-name fw-bold">IP:
                            </label>
                            <span class="text-muted time highlight highlight">{{
                              vid.ipv4
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'device_name'">
                            <label class="employer-name fw-bold">Device Name:
                            </label>
                            <span class="text-muted time highlight highlight">{{
                              vid.device_name
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'site_name'">
                            <label class="employer-name fw-bold">Site :
                            </label>
                            <span class="text-muted time highlight highlight">{{
                              vid.site_name
                              }}</span>
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="widget-26-job-info">
                          <span class="type m-0 fw-bold">Site: </span>
                          <span class="text-muted m-0">
                            <span class="location">{{ vid.site_name }}</span>
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="widget-26-job-category bg-soft-warning">
                          <span>Video</span>
                        </div>
                      </td>
                    </tr>
                    <tr *ngFor="let site of sites ">
                      <td>
                        <div class="widget-26-job-title">
                          <a [routerLink]="'/video/site/'+ site.site_id" mat-dialog-close>{{ site.site_name }}</a>
                        </div>
                        <div *ngFor="let meta of site.meta">
                          <p class="m-0" *ngIf="meta == 'site_name'">
                            <label class="employer-name fw-bold">Site:
                            </label>
                            <span class="text-muted time highlight">{{
                              site.site_name
                              }}</span>
                          </p>
                        </div>
                      </td>
                      <td>
                        <span class="fw-bold"> Devices: </span>
                        {{ site.devices }}
                      </td>
                      <td>
                        <div class="widget-26-job-category bg-soft-success">
                          <span>Site</span>
                        </div>
                      </td>
                    </tr>

                    <tr *ngFor="let user of user_only ">
                      <td>
                        <div class="widget-26-job-title">
                          <a [routerLink]="'/cloud/user/'+ user.zoom_user_id" (click)="closeModal()">
                            {{ user.firstname +' '+ user.lastname }}</a>
                        </div>
                        <div *ngFor="let meta of user.meta">
                          <p class="m-0" *ngIf="meta == 'email'">
                            <label class="employer-name fw-bold">Email:
                            </label>
                            <span class="text-muted time highlight">{{
                              user.email
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'firstname' || meta == 'lastname'">
                            <label class="employer-name fw-bold">User Name:
                            </label>
                            <span class="text-muted time highlight">{{
                              user.firstname + ' ' + user.lastname
                              }}</span>
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="widget-26-job-info">
                          <span class="type m-0 fw-bold">Email: </span>
                          <span class="text-muted m-0">
                            <span class="location">{{ user.email }}</span>
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="widget-26-job-category bg-soft-danger">
                          <span>User</span>
                        </div>
                      </td>
                    </tr>


                    <tr *ngFor="let user of rooms_only ">
                      <td>
                        <div class="widget-26-job-title">
                          <a [routerLink]="'/teams/room/'+ user.zoom_user_id" (click)="closeModal()">
                            {{user.room_name}}</a>
                        </div>
                        <div *ngFor="let meta of user.meta">
                          <p class="m-0" *ngIf="meta == 'email'">
                            <label class="employer-name fw-bold">Email:
                            </label>
                            <span class="text-muted time highlight">{{
                              user.email
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'room_name'">
                            <label class="employer-name fw-bold">Room Name:
                            </label>
                            <span class="text-muted time highlight">{{
                              user.room_name
                              }}</span>
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="widget-26-job-info">
                          <span class="type m-0 fw-bold">Email: </span>
                          <span class="text-muted m-0">
                            <span class="location">{{ user.email }}</span>
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="widget-26-job-category bg-soft-danger">
                          <span>Room</span>
                        </div>
                      </td>
                    </tr>


                    <tr *ngFor="let iot of iot">
                      <td>
                        <div class="widget-26-job-title">
                          <a [routerLink]="'/iot/lite/device/'+iot.iot_id" mat-dialog-close>{{ iot.iot_name }}</a>
                        </div>
                        <div *ngFor="let meta of iot.meta">
                          <p class="m-0" *ngIf="meta == 'mac_address'">
                            <label class="employer-name fw-bold">Mac Address:
                            </label>
                            <span class="text-muted time highlight">{{
                              iot.mac_address
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'iot_ip'">
                            <label class="employer-name fw-bold">IP:
                            </label>
                            <span class="text-muted time highlight">{{
                              iot.iot_ip
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'iot_name'">
                            <label class="employer-name fw-bold">IOT Name:
                            </label>
                            <span class="text-muted time highlight">{{
                              iot.iot_name
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'make'">
                            <label class="employer-name fw-bold">Make:
                            </label>
                            <span class="text-muted time highlight">{{
                              iot.make
                              }}</span>
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="widget-26-job-info">
                          <span class="type m-0 fw-bold">Make: </span>
                          <span class="text-muted m-0">
                            <span class="location">{{ iot.make }}</span>
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="widget-26-job-category bg-soft-info">
                          <span>Iot</span>
                        </div>
                      </td>
                    </tr>
                    <tr *ngFor="let aud of audio">
                      <td>
                        <div class="widget-26-job-title">
                          <a [routerLink]="'/audio/site/' + aud.site_id + '/audiodevice/' + aud.audio_device_id"
                            mat-dialog-close>{{
                            aud.room_name }}</a>
                          <p class="m-0">
                            <label class="employer-name fw-bold">Device Type:
                            </label>
                            <span class="text-muted time">{{
                              aud.device_type
                              }}</span>
                          </p>
                        </div>
                        <div *ngFor="let meta of aud.meta">
                          <p class="m-0" *ngIf="meta == 'room_name'">
                            <label class="employer-name fw-bold">Room Name:
                            </label>
                            <span class="text-muted time highlight">{{
                              aud.room_name
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'mac'">
                            <label class="employer-name fw-bold">Mac Address:
                            </label>
                            <span class="text-muted time highlight highlight">{{
                              aud.mac
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'ipv4'">
                            <label class="employer-name fw-bold">IP:
                            </label>
                            <span class="text-muted time highlight highlight">{{
                              aud.ipv4
                              }}</span>
                          </p>
                          <p class="m-0" *ngIf="meta == 'site_name'">
                            <label class="employer-name fw-bold">Site :
                            </label>
                            <span class="text-muted time highlight highlight">{{
                              aud.site_name
                              }}</span>
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="widget-26-job-info">
                          <span class="type m-0 fw-bold">Site: </span>
                          <span class="text-muted m-0">
                            <span class="location">{{ aud.site_name }}</span>
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="widget-26-job-category bg-soft-warning">
                          <span>Audio</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>