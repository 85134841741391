import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-old-reset-password',
  templateUrl: './old-reset-password.component.html',
  styleUrls: ['./old-reset-password.component.scss'],
})
export class OldResetPasswordComponent implements OnInit, OnChanges {
  @Output() public data = new EventEmitter<{ password: string }>();
  @Input() resetEmail: any;
  @Input() resetSent: any;
  links = environment.config.links;
  dTerms = environment.config.dynamicText.dTerms;
  backgroundImage = environment.config.dynamicImage.bgImage;
  dashboardLogo = environment.config.dynamicImage.dashboardLogo;
  visibilityBackground = {
    'background-image': 'url(' + this.backgroundImage + ')',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
    'background-attachment': 'fixed',
    'background-size': 'cover',
    height: '100vh',
  };

  password: string = '';
  confirm: string = '';
  startValidatePassword: boolean = false;
  startValidateConfirm: boolean = false;
  disableBtn: boolean = false;
  formControl: any;

  constructor(public http: HttpClient, private Auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.setFormControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetEmail) {
    }

    if (changes.resetSent) {
      // console.log(this.resetSent);
    }
  }

  setFormControl = () => {
    const deepCopy = {
      email: {
        flag: false,
        style: 'default',
        message: '',
      },
      password: {
        style: 'default',
        message: ' ',
        flag: false,
      },
      confirm: {
        style: 'default',
        message: ' ',
        flag: false,
      },
    };

    this.formControl = { ...deepCopy };
  };

  validateForm = () => {
    const validation = this.validate();

    if (validation) {
      this.emitEvent();
      // console.log('ready for emit!');
    } else {
    }
  };

  validate = () => {
    let errCount = 0;
    this.setFormControl();

    if (this.isEmpty(this.password)) {
      this.startValidatePassword = true;
      this.setErrorMessageAndStatus('password', true, 'error', 'Required Field');
      errCount++;
    } else if (this.password.length < 8) {
      this.startValidatePassword = true;
      this.setErrorMessageAndStatus('password', true, 'error', 'Minimum of 8 character password');
      errCount++;
    }
    if (this.isEmpty(this.confirm)) {
      this.startValidateConfirm = true;
      this.setErrorMessageAndStatus('confirm', true, 'error', 'Required Field');
      errCount++;
    } else if (this.confirm !== this.password) {
      this.startValidateConfirm = true;
      this.setErrorMessageAndStatus('confirm', true, 'error', 'Password mismatch');
      errCount++;
    }

    this.disableBtn = errCount !== 0;
    return errCount === 0;
  };

  isEmpty = (params) => {
    if (params.length === 0) {
      return true;
    }
    return false;
  };

  setErrorMessageAndStatus = (propname, bool, style, message) => {
    this.formControl[propname].flag = bool;
    this.formControl[propname].style = style;
    this.formControl[propname].message = message;
  };

  keyEvent = (event, type) => {
    if (type === 'password') {
      this.validate();
    }
    if (type === 'confirm') {
      this.validate();
    }
  };

  validateEmail = (email) => {
    // tslint:disable-next-line: max-line-length
    const regexEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEx.test(String(email).toLowerCase());
  };

  emitEvent = () => {
    this.data.emit({ password: this.password });
  };
}
