import { Subscription } from 'rxjs'
import { GetCollectorService } from 'src/app/services/get-collector.service'

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'

import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'
import { EventEmitterGlobalService } from '../../services/event-emitter-global.service'
import { PopupTypeService } from '../../services/popupType.service'
import { RefreshApiService } from '../../services/refresh-api.service'
import { CollectorAction } from '../collector-list/collector-list.component'

@Component({
  selector: 'app-collector-panel',
  templateUrl: './collector-panel.component.html',
  styleUrls: ['./collector-panel.component.scss']
})
export class CollectorPanelComponent implements OnInit {
  timeout: any

  PopupTitle: string = 'Site Information';
  filterListner: Subscription;
  collectorDetails: any; // re,pve the default object test again when on call
  siteId: any;
  dataError: any;
  refreshData: any;
  companyLogo: any =  environment.serverUrl + '/api/v1/images/companylogo/' + '2024-02-16T15:18:55.403Zimage';
  server_url: string = environment.serverUrl;
  get collectorAction() {
		return CollectorAction
	}
  constructor(
    private getCollector : GetCollectorService,
    private route: ActivatedRoute,
    private refreshApiService: RefreshApiService,
    public Auth: AuthService,
    private eventEmmit: EventEmitterGlobalService,
    public ds: PopupTypeService,

  ) {
    this.route.params.subscribe((params) => {
      this.siteId = params['id'];
    });

    this.filterListner = this.refreshApiService.refreshAPIListen().subscribe((m: any) => {
      // this.getUserDetails();
    });

  }

  ngOnInit() {
    this.getCollectorDetails()
    this.getCompanyInfo()
  }
  
  getCollectorDetails = () => {
    this.getCollector.getCollectorDetails(this.siteId).subscribe(
			(data: any) => {
				const res = data
				if (res.status == 200) {
					this.collectorDetails = res.data

					this.PopupTitle = `Site: ${this.collectorDetails.site_name}`
				}
			},
			err => {}
		)
    
    this.timeout = setTimeout(() => {
			this.getCollectorDetails()
		}, 30_000)
  }
  
  getCompanyInfo = () => {
    this.Auth.getCompanyInfo().subscribe((data) => {
      const [newData] = data.response;
      this.companyLogo = this.server_url + '/api/v1/images/companylogo/' + newData.logo;
      console.log(newData)
    });
  }
  
  openHealthHistory = () => {
    this.ds.sendPopupType(80, { deviceId: this.siteId });
  }

  ngOnDestroy() {
    this.filterListner.unsubscribe();

		if (this.timeout) clearTimeout(this.timeout)
  }

}
