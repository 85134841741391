import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { LinkDesktopPluginHostDialogComponent } from '../../iot/dialogs/link-desktop-plugin-host-dialog/link-desktop-plugin-host-dialog.component';
import { GetDesktopPluginService } from '../../services/get-desktop-plugin.service';
import { GetCloudService } from '../../services/get-cloud.service';

import { environment } from '../../../environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-zoom-users',
  templateUrl: './zoom-users.component.html',
  styleUrls: ['./zoom-users.component.scss'],
})
export class ZoomUsersComponent implements OnInit, OnChanges {
  @Output() sendPopupType: EventEmitter<any> = new EventEmitter<any>();
  @Input() zoomUserList: any;
  @Input() hostToLink: any;
  @Input() showLoader: any;

  dPlugin = environment.config.dynamicText.dPlugin;
  desktopIcon = environment.config.dynamicImage.desktopIcon;
  usersToLinkList: any;
  loader = environment.config.dynamicImage.loader;

  setTimer: any;

  constructor(
    private desktopPluginService: GetDesktopPluginService,
    public dialog: MatDialog,
    private getCloudUserService: GetCloudService
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.zoomUserList && this.zoomUserList) {
      // this.getUsersToLink();
    }
  }

  setData = () => {
    // // console.log(this.zoomUserList);
  };

  getPluginIcon = (plugin_active) => {
    if (plugin_active !== null) {
      return this.desktopIcon;
    }
    return '--';
  };

  getLinkIcon = (params) => {
    // // console.log(params);
    const { plugin_active } = params;
    return plugin_active !== null ? './assets/img/vs_img/zoom_unlink.png' : './assets/img/vs_img/zoom_link.png';
  };

  openDesktopLinkModal = (items) => {
    const config = this.hostToLink;
    //// console.log('openDesktopLinkModal')
    const webportal = this.dialog.open(LinkDesktopPluginHostDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        config,
        user: items,
        sendPopupType: this.sendPopupType,
      },
    });
  };


  getDesktopStatus = (status) => {
    if (status === 1) {
      return 'zoom-users__status--green';
    }

    if (status === -1) {
      return 'zoom-users__status--red';
    }

    return '';
  };

  getPhoneCallStatus = (status) => {
    if (status === 1) {
      return '../../../assets/img/vs_img/green-phone.png';
    }

    return '../../../assets/img/vs_img/black-phone.png';
  };

  desktopClientChange = (bool, zoom_user_id) => {
    this.getCloudUserService.setEnableDesktopPlugin(zoom_user_id, bool).subscribe((data) => {
      // // console.log(data);
    });
  };

  alertsChange = (bool, zoom_user_id) => {
    if (bool) {
      this.getCloudUserService.enableAlerts(zoom_user_id).subscribe((data) => {
        if (data) {
          // this.sendPopupType.emit('desktopClientList');
        }
      });
    } else {
      this.getCloudUserService.disableAlerts(zoom_user_id).subscribe((data) => {
        if (data) {
          // this.sendPopupType.emit('desktopClientList');
        }
      });
    }
  };
  onScroll() {
    this.sendPopupType.emit('scrollPagination');
  }
}
