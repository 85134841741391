import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { finalize } from 'rxjs/operators'
import { GetSpaceService } from 'src/app/services/get-space.service'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-link-device-space-dialog',
  templateUrl: './link-device-space-dialog.component.html',
  styleUrls: ['./link-device-space-dialog.component.scss']
})
export class LinkDeviceSpaceDialogComponent implements OnInit {
  btnLoader: string = environment.config.dynamicImage.loader;
  form: FormGroup;
  message: string = '';
  loading: boolean = false;
  color: string = '';
  spaces: any[] = [];
  type: string = '';
  initialFormValues: any;
  isButtonDisabled: boolean = true;
  @Output('refresh') refresh = new EventEmitter();
  constructor(private dialogRef: MatDialogRef<LinkDeviceSpaceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) @Optional() public data: any,
              private formBuilder: FormBuilder,
              private _spaceServcie:GetSpaceService) { }

  ngOnInit(): void {
    this.checkForm();
    this.getAllSpaces();
    if(this.data && this.data.spaces){
      this.form.patchValue({space_ids:this.getSpaces(this.data.spaces)});
    }
    this.type = this.data.group_type || this.data.iot_type;
    this.initialFormValues = this.form.value;
    this.form.valueChanges.subscribe(values => {
      this.isButtonDisabled = this.areValuesSame(this.initialFormValues, values);
    });
  }
  closeDialog = (status) => {
    this.dialogRef.close(status);
  };
  checkForm() {
    this.form = this.formBuilder.group({
      space_ids: [[]],
    });
    
  }
  get f() {
    return this.form.controls;
  }
  onSubmit(){
    const spaces = this.f['space_ids'].value;

    const body = {
      iot_device_id : this.data.iot_device_id || this.data.iot_id,
      spaces : spaces.map((x)=>{
        return {
          space_id : x
        }
      }),
      isDomotz : this.data.isDomotz
    }
    this.loading = true;
    this._spaceServcie
    .linkDeviceToSpace(body)
    .pipe(finalize(()=>this.loading = false))
    .subscribe((resp) => {
        if(resp.success){
          this.color = 'text-success';
          this.message = this.data && !spaces.length ? 'Device Unlinked Sucessfully' :resp.message;
          this.refreshSpaceListing(spaces);
          this.reset(true);
        }
        else{
          this.color = 'text-danger';
          this.message = resp.message;
          this.reset(false);
        }
    }, (err) => {
      this.color = 'text-danger';
      this.message = 'Something went wrong while adding space';
      this.reset(false);
    })
  }
  reset(status:boolean) {
    this.form.reset();
    setTimeout(() => {
      this.message = '';
      this.closeDialog(status)
    },2000)
  }

  getAllSpaces(){
    this._spaceServcie.getAllSpaces('',this.data?.isDomotz).subscribe((resp:any)=>{
      this.spaces = [...resp];
    })
  }
  getSpaces(spaces){
    return spaces?JSON.parse(spaces).map((x)=>x.space_id):[];
  }
  disable(space){
    if(!["NETWORK", "NETWORK_DEVICES"].includes(this.type) && this.f.space_ids?.value?.length == 1){
      if(space?.space_id == this.f.space_ids?.value[0]) return false;
      return true;
    }
    return false
  }
  areValuesSame(initialValues: any, currentValues: any): boolean {
    return JSON.stringify(initialValues) === JSON.stringify(currentValues);
  }
  refreshSpaceListing(spaces){
    const space = spaces?.map((x)=>{
      const oldSpace = this.spaces.find((s)=>s.space_id == x);
     return {
       space_id : x,
       space_name: oldSpace.space_name,
       device: this.data.iot_device_id || this.data.iot_id
     }
   })
   this.refresh.emit(JSON.stringify(space));
  }


}
