import { Component, OnInit, Input } from '@angular/core';
import { PopupTypeService } from '../../../services/popupType.service';

@Component({
  selector: 'app-plugin-cloud-zoom-disable',
  templateUrl: './plugin-cloud-zoom-disable.component.html',
  styleUrls: ['./plugin-cloud-zoom-disable.component.scss']
})
export class PluginCloudZoomDisableComponent implements OnInit {
  @Input() isDisabled:boolean;
  @Input() message:string;
  constructor(public ds: PopupTypeService) { }

  ngOnInit() {
  }

}
