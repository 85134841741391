import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-video-service-provider-status',
  templateUrl: './video-service-provider-status.component.html',
  styleUrls: ['./video-service-provider-status.component.scss']
})
export class VideoServiceProviderStatusComponent implements OnInit, OnChanges {
  @Input() logitechData: any;
  teamsLogo: string = '../../../../assets/img/vs_img/team.png';
  zoomsLogo: string = '../../../../assets/img/vs_img/zoom.png';
  meetLogo: string = '../../../../assets/img/vs_img/meet.png';
  ringCentralLogo: string = '../../../../assets/img/vs_img/ringcentral.png';
  tencentLogo: string = '../../../../assets/img/vs_img/tencent.png';
  byodLogo: string = '../../../../assets/img/vs_img/byod.png';

  teamsLogoBlack: string = '../../../../assets/img/vs_img/teamBlack.png';
  zoomsLogoBlack: string = '../../../../assets/img/vs_img/zoomBlack.png';
  meetLogoBlack: string = '../../../../assets/img/vs_img/meetBlack.png';
  ringCentralLogoBlack: string = '../../../../assets/img/vs_img/ringcentralBlack.png';
  tencentLogoBlack: string = '../../../../assets/img/vs_img/tencentBlack.png';
  byodLogoBlack: string = '../../../../assets/img/vs_img/byodBlack.png';
  
  
  
  
  constructor() { }

  ngOnInit(): void {
  }
  
	ngOnChanges(changes:SimpleChanges) {
    if(changes.logitechData && this.logitechData) {
      this.setData();

    }
  }
  
  setData = () => {
    const { settings: { serviceProviders} } = this.logitechData
    
    if(serviceProviders) {
      const [getTeams] = serviceProviders.filter(e => e.dpmName == 'MS_TEAMS');
      const [getZoom] = serviceProviders.filter(e => e.dpmName == 'ZOOM');
      const [getMeet] = serviceProviders.filter(e => e.dpmName == 'MEET');
      const [getRingCentral] = serviceProviders.filter(e => e.dpmName == 'RING_CENTRAL');
      const [getTencent] = serviceProviders.filter(e => e.dpmName == 'TENCENT');
      const [getByod] = serviceProviders.filter(e => e.dpmName == 'BYOD');
      
      if(getTeams.isSelected) {
        this.teamsLogo = this.teamsLogo;      
      } else {
        this.teamsLogo = this.teamsLogoBlack;      
      }
      
     if(getZoom.isSelected) {
        this.zoomsLogo = this.zoomsLogo;      
      } else {
        this.zoomsLogo = this.zoomsLogoBlack;      
      }
      
     if(getMeet.isSelected) {
        this.meetLogo = this.meetLogo;      
      } else {
        this.meetLogo = this.meetLogoBlack;      
      }
      
     if(getRingCentral.isSelected) {
        this.ringCentralLogo = this.ringCentralLogo;      
      } else {
        this.ringCentralLogo = this.ringCentralLogoBlack;      
      }
      
     if(getTencent.isSelected) {
        this.tencentLogo = this.tencentLogo;      
      } else {
        this.tencentLogo = this.tencentLogoBlack;      
      }
      
     if(getByod.isSelected) {
        this.byodLogo = this.byodLogo;      
      } else {
        this.byodLogo = this.byodLogoBlack;      
      }
 
    }
    
  }


}
