// import { Component, Inject, EventEmitter, AfterViewInit } from '@angular/core';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  Inject,
  AfterViewInit,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-iot-lite-domotz-error-message',
  templateUrl: './iot-lite-domotz-error-message.component.html',
  styleUrls: ['./iot-lite-domotz-error-message.component.scss']
})
export class IotLiteDomotzErrorMessageComponent implements OnInit {
  message: any = "Hello World";
  showButton: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<IotLiteDomotzErrorMessageComponent>
  ) {
    if (data) {
      const { config, showButton } = data;
      this.message = config;
      this.showButton = showButton
    }
  }

  ngOnInit() {

  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog = () => {
    this.dialogRef.close();
  };

  onProceed = () => {
    this.dialogRef.close(true)
  }

}
