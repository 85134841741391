<div class="cloud-user-device-health">
  <div class="cloud-user-device-health__disable" *ngIf="!isDesktopPluginIntegrated || !isZoomPluginEnabled"
    (click)="displayDisableMessage()"></div>
  <h5 class="cloud-user-device-health__label-h5">Device(s) Health</h5>
  <div class="cloud-user-device-health__desktop-plugin-status" (click)="displayPluginInfo()">
    <div class="cloud-user-device-health__text-button-container">
      <img src="../../../../assets/img/video/icon_screen_phone.png" alt="" />
      <div>
        {{ deskPluginTextStatus }}
      </div>
    </div>
    <div class="cloud-user-device-health__text-button-container">
      <div>{{ deskPlugin }} Status</div>
      <div class="cloud-user-device-health__status"></div>
      <div [ngClass]="{
          'cloud-user-device-health__status-offline':
            isDesktopPluginIntegrated && deskPluginStatus === -1,
          'cloud-user-device-health__status-warning':
            isDesktopPluginIntegrated && deskPluginStatus === 2,
          'cloud-user-device-health__status-online':
            isDesktopPluginIntegrated && deskPluginStatus === 1,
          'cloud-user-device-health__status-disabled':
            !isDesktopPluginIntegrated || deskPluginStatus === null
        }"></div>
    </div>
  </div>
  <!--  -->
  <div class="cloud-user-device-health__devices-container">
    <div class="cloud-user-device-health__two-columns">
      <app-cloud-user-device-health-button [quadrantType]="'mic'" [cloudUserStats]="cloudUserStats">
      </app-cloud-user-device-health-button>
      <app-cloud-user-device-health-button [quadrantType]="'camera'" [cloudUserStats]="cloudUserStats">
      </app-cloud-user-device-health-button>
    </div>
    <div class="cloud-user-device-health__two-columns">
      <app-cloud-user-device-health-button [quadrantType]="'speaker'" [cloudUserStats]="cloudUserStats">
      </app-cloud-user-device-health-button>
      <app-cloud-user-device-health-button [quadrantType]="cloudUserStats?.service_type"
        [cloudUserStats]="cloudUserStats"></app-cloud-user-device-health-button>
    </div>
  </div>
</div>