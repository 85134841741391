import { TableQosIconComponent } from './../components/table-icons/table-qos-icon/table-qos-icon.component';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TableHealthIconComponent } from '../components/table-icons/table-icon/table-health-icon.component';
import { TableOnCallIconComponent } from '../components/table-icons/table-oncall-icon/table-oncall-icon.component';
import { environment } from '../../environments/environment';
import { TableZoomOnCallComponent } from '../components/table-icons/table-zoom-on-call/table-zoom-on-call.component';
import { IntegratorService } from '../services/integrator.service';

@Injectable({
  providedIn: 'root',
})
export class GetCloudService {
  getUrlPath: Function = environment.apiServicePathBuilder('zoom');

  constructor(private http: HttpClient, private integrator: IntegratorService) { }
  server_url: string = environment.serverUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  public settingsCloud = {
    health: {
      title: 'Health',
      type: 'custom',
    },
  };


  getCompanyId = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    return decodedToken.company_id;
  }


  private handleError(error: Response | any) {
    return Observable.throw(error);
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  fetchApiCloudUsers(config): Observable<any> {
    const requiredKeys = ['list_limit', 'off_set', 'search_key'];
    for (let k of requiredKeys) {
      if (typeof config[k] === 'undefined') {
        throw new Error(`${k} is required!`);
      }
    }

    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const company_id = this.integrator.isIntegrator()
      ? decodedToken.company_id * -1
      : decodedToken.company_id;
    const userId = decodedToken.user_id;

    const postData = {
      company_id,
      ...config,
    };

    return this.http
      .post<any>(this.server_url + '/api/v1/zoom/getActiveUserList', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }
  updateRoomName(postData: any): Observable<any> {
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/room-update',
      postData,
      this.httpOptions
    )
  }

  fetchApiCloudActiveUsersCount(searchkey, isImpactedUsers): Observable<any> {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const companyId = this.integrator.isIntegrator()
      ? decodedToken.company_id * -1
      : decodedToken.company_id;
    const userId = decodedToken.user_id;

    const postData = {
      company_id: companyId,
      search_key: searchkey,
      isImpactedUsers,
    };


    return this.http
      .post<any>(this.server_url + '/api/v1/zoom/getActiveUserCount', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }

  fetchApiCloudUserDetails(zoom_user_id: any): Observable<Object> {
    const url: string = this.getUrlPath(`getZoomUserDetails`);
    return this.http
      .get<any>(`${url}/${zoom_user_id}`)
      .pipe(map(this.extractData))
  }

  //Fetches specific Cloud User Details
  fetchApiCloudRoomDetails(userId: number): Observable<Object> {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      // company_id: 1,
      company_id: companyId,
      zoom_user_id: userId,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/getRoomDetails',
      postData,
      this.httpOptions
    );
  }

  fetchApiCloudRoomDetails2(userId: number): Observable<Object> {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      // company_id: 1,
      company_id: companyId,
      zoom_user_id: userId,
    };
    return this.http
      .post<any>(this.server_url + '/api/v1/zoom/getRoomDetails', postData, this.httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }


  // unpauseZoom(userId: number): Observable<Object> {
  //   const token = localStorage.getItem('token');
  //   const helper = new JwtHelperService();

  //   const decodedToken = helper.decodeToken(token);
  //   const companyId = decodedToken.company_id;
  //   //const userId = decodedToken.user_id;

  //   let postData = {
  //     // company_id: 1,
  //     company_id: companyId,
  //     zoom_user_id: userId,
  //   };
  //   return this.http.post<any>(
  //     this.server_url + '/api/v1/zoom/unpause',
  //     postData,
  //     this.httpOptions
  //   );
  // }
  //   let postData = {
  //     // company_id: 1,
  //     company_id: companyId,
  //     zoom_user_id: userId,
  //   };
  //   return this.http.post<any>(this.server_url + '/api/v1/zoom/pause', postData, this.httpOptions);
  // }

  unpauseZoom(userId: number): Observable<Object> {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    //const userId = decodedToken.user_id;

    let postData = {
      // company_id: 1,
      company_id: companyId,
      zoom_user_id: userId,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/unpause',
      postData,
      this.httpOptions
    );
  }

  //Fetches specific Cloud User Details
  fetchApiCloudRoomSummary(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = this.integrator.isIntegrator()
      ? decodedToken.company_id * -1
      : decodedToken.company_id;
    //const userId = decodedToken.user_id;

    let postData = {
      // company_id: 1,
      company_id: companyId,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/getRoomSummary',
      postData,
      this.httpOptions
    );
  }

  fetchApiCloudSummary(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    //const userId = decodedToken.user_id;

    let postData = {
      // company_id: 1,
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
    };

    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/getSummary',
      postData,
      this.httpOptions
    );
  }

  //Fetches specific Cloud User Details
  fetchApiCloudAllUserSummary() {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    //const userId = decodedToken.user_id;

    let postData = {
      company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/getUserSummary',
      postData,
      this.httpOptions
    );
  }

  //Fetches specific Cloud Rooms List
  fetchApiCloudAllRooms(params) {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    //const userId = decodedToken.user_id;
    let postData;
    if (params.service_type == 'zoom' || params.service_type == 'teams') {
      postData = {
        // service_type: params.service_type,
        ...params,
        company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
      };
    } else {
      postData = {
        ...params,
        company_id: this.integrator.isIntegrator() ? companyId * -1 : companyId,
      };
    }

    return this.http.post<any>(this.server_url + '/api/v1/zoom/getActiveRoomList', postData, this.httpOptions).pipe(
			map(response => ({
				...response,
        response: response.response.map(room => {
          const status = String(room.status).toLowerCase()
          const plugin_status = +room.plugin_status
          if (['offline', 'pause'].includes(status) || (status === 'unknown' && plugin_status !== 1)) {
						console.log(room.room_name, String(room.status).toLowerCase())
						room.health = 0
					}
					return room
				}),
			}))
		)
  }

  fetchApiCloudLiveParticipants(): Observable<Object> {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
      user_id: userId,
    };
    return this.http
      .post<any>(this.server_url + '/api/v1/zoom/userLiveParticipants', postData, this.httpOptions)
      .pipe(map(this.extractData))
  }
  sendApiKey(apiKey, secret, _verify_token) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    const postData = {
      company_id: companyId,
      APIKey: apiKey,
      APISeceret: secret,
      web_hook_token_verification: _verify_token,
    };
    const url: string = this.getUrlPath(`info`);
    return this.http.put<any>(url, postData, this.httpOptions);
  }

  udpateApiKey(apiKey, secret, _verify_token) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    const postData = {
      company_id: companyId,
      APIKey: apiKey,
      APISeceret: secret,
      web_hook_token_verification: _verify_token,
    };
    const url: string = this.getUrlPath(`info`);
    return this.http.put<any>(url, postData, this.httpOptions);
  }

  updateNotes(zoom_user_id: number, notes: string) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const company_id = decodedToken.company_id;

    let postData = {
      zoom_user_id,
      notes,
      company_id,
    };

    const apiUrl = '/api/v1/zoom/updateNotes';

    return this.http.post<any>(this.server_url + apiUrl, postData, this.httpOptions);
  }

  getZoomInfo() {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: companyId,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/getInfo',
      postData,
      this.httpOptions
    );
  }

  enableZoomService() {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    //const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/enableService',
      postData,
      this.httpOptions
    );
  }
  disableZoomService() {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    //const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/disableService',
      postData,
      this.httpOptions
    );
  }

  enableZoomNotification() {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    //const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/enableNotification',
      postData,
      this.httpOptions
    );
  }
  disableZoomNotification() {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;
    //const userId = decodedToken.user_id;

    let postData = {
      company_id: companyId,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/disableNotification',
      postData,
      this.httpOptions
    );
  }

  disableAlerts(userId) {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: companyId,
      zoom_user_id: userId,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/disableAlerts',
      postData,
      this.httpOptions
    );
  }

  enableAlerts(userId) {
    //Grabs Token from Local Storage
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    let postData = {
      company_id: companyId,
      zoom_user_id: userId,
    };
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/enableAlerts',
      postData,
      this.httpOptions
    );
  }

  setEnableDesktopPlugin(zoom_user_id, val) {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);
    const companyId = decodedToken.company_id;

    const postData = {
      company_id: companyId,
      zoom_user_id,
      val,
    };
    const url: string = this.getUrlPath(`setEnableDesktopPlugin`);
    return this.http.put<any>(url, postData, this.httpOptions);
  }

  // Load the JSON file for the Site Panel
  fetchCloudRooms(): Observable<Object> {
    return this.http.get('/assets/db/cloud/cloud_rooms.json');
  }

  fetchCloudUsers(): Observable<Object> {
    return this.http.get('/assets/db/cloud/cloud_users.json');
  }

  fetchCloudRoomSummary(): Observable<Object> {
    return this.http.get('/assets/db/cloud/cloud_room_summary.json');
  }

  fetchCloudUserSummary(): Observable<Object> {
    return this.http.get('/assets/db/cloud/cloud_user_summary.json');
  }

  updateAlertTriggers(postData): Observable<Object> {
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/updateTriggers',
      postData,
      this.httpOptions
    );
  }
  resetAlertTriggers(postData): Observable<Object> {
    return this.http.post<any>(
      this.server_url + '/api/v1/zoom/resetTriggers',
      postData,
      this.httpOptions
    );
  }

  noData = (cellData) => {
    if (!cellData) {
      return '--';
    }
    return cellData;
  };

  // Table Settings for Filter Table
  // Audio User List Settings
  cloudRoomTableSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: false,
    },
    columns: {
      room_name: {
        title: 'Name',
      },
      health: {
        title: 'Health',
        type: 'custom',
        renderComponent: TableHealthIconComponent,
      },
      qos: {
        title: 'QoS',
        type: 'custom',
        renderComponent: TableQosIconComponent,
      },
      total_bitrate: {
        title: 'Live Mbps',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          var roundnum = instance / (1024 * 1024);
          return Math.round(roundnum * 100) / 100;
        },
      },
      liveMeetings: {
        title: 'On Call',
        type: 'custom',
        renderComponent: TableOnCallIconComponent,
      },
      duration: {
        title: 'Call Duration',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return Math.floor(instance / 60) + 'm';
        },
      },
      scheduledMeetings: {
        title: 'Calender',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
      service: {
        title: 'Service',
      },
      // online: {
      //   title: 'online',
      //   valuePrepareFunction(instance){
      //     if(!instance){
      //       return "--"
      //     }
      //     return instance;
      //   }
      // },
    },
  };
  cloudRoomTableSettingsSuperAdmin = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: true,
      perPage: 50,
    },
    columns: {
      company_name: {
        title: 'Company',
      },
      room_name: {
        title: 'Name',
      },
      health: {
        title: 'Health',
        type: 'custom',
        renderComponent: TableHealthIconComponent,
      },
      qos: {
        title: 'QoS',
        type: 'custom',
        renderComponent: TableQosIconComponent,
      },
      total_bitrate: {
        title: 'Live Mbps',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          var roundnum = instance / (1024 * 1024);
          return Math.round(roundnum * 100) / 100;
        },
      },
      liveMeetings: {
        title: 'On Call',
        type: 'custom',
        renderComponent: TableOnCallIconComponent,
      },
      duration: {
        title: 'Call Duration',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return Math.floor(instance / 60) + 'm';
        },
      },
      scheduledMeetings: {
        title: 'Calender',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
      service: {
        title: 'Service',
      },

    },
  };



  cloudInsTableSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: false,
    },
    columns: {
      timestamp: {
        title: 'Time Stamp',
        valuePrepareFunction(instance) {
          if (instance == null) {
            return '--';
          } else {
            var ldate = new Date(instance);
            var mySQLDate = [ldate.getFullYear(), ldate.getMonth() + 1, ldate.getDate()].join('-');
            var mySQLTime = [ldate.getHours(), ldate.getMinutes(), ldate.getSeconds()].join(':');
            instance = [mySQLDate, mySQLTime].join(' ');
          }
          return instance;
        },
      },
      total_mbps: {
        title: 'Live Mbps',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
      total_jitter: {
        title: 'Jitter',
      },
      total_latency: {
        title: 'Latency',
      },
      total_packetloss: {
        title: 'Packetloss',
      },
      video_incident_id: {
        title: 'Incident Id',
        valuePrepareFunction(instance) {
          if (instance == null) {
            return '--';
          }
          return instance;
        },
      },
    },
  };

  // Audio User List Settings
  cloudUserTableSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: false,
    },
    columns: {
      firstname: {
        title: 'Name',
        valuePrepareFunction(cell, row) {
          return cell + ' ' + row.lastname;
        },
      },
      health: {
        title: 'Health',
        type: 'custom',
        renderComponent: TableHealthIconComponent,
      },
      qos: {
        title: 'QoS',
        type: 'custom',
        renderComponent: TableQosIconComponent,
      },
      total_bitrate: {
        title: 'Live Mbps',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          var roundnum = instance / (1024 * 1024);
          return Math.round(roundnum * 100) / 100;
        },
      },
      liveMeetings: {
        title: 'On Call',
        type: 'custom',
        renderComponent: TableZoomOnCallComponent,
      },
      duration: {
        title: 'Call Duration',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return Math.floor(instance / 60) + 'm';
        },
      },
      scheduledMeetings: {
        title: 'Calender',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
      service: {
        title: 'Service',
      },
    },
  };

  cloudUserTableSettingsSuperAdmin = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    attr: {
      class: 'filterTable',
    },
    hideSubHeader: true,
    pager: {
      display: true,
      perPage: 50,
    },
    columns: {
      company_name: {
        title: 'Company',
      },
      firstname: {
        title: 'Name',
        valuePrepareFunction(cell, row) {
          return cell + ' ' + row.lastname;
        },
      },
      health: {
        title: 'Health',
        type: 'custom',
        renderComponent: TableHealthIconComponent,
      },
      qos: {
        title: 'QoS',
        type: 'custom',
        renderComponent: TableQosIconComponent,
      },
      total_bitrate: {
        title: 'Live Mbps',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          var roundnum = instance / (1024 * 1024);
          return Math.round(roundnum * 100) / 100;
        },
      },
      liveMeetings: {
        title: 'On Call',
        type: 'custom',
        renderComponent: TableZoomOnCallComponent,
      },
      duration: {
        title: 'Call Duration',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return Math.floor(instance / 60) + 'm';
        },
      },
      scheduledMeetings: {
        title: 'Calender',
        valuePrepareFunction(instance) {
          if (!instance) {
            return '--';
          }
          return instance;
        },
      },
      service: {
        title: 'Service',
      },
    },
  };

  getAuthorizationUrl = () => {
    const url: string = this.getUrlPath(`getAuthorizationUrl`);
    const company_id = this.getCompanyId();

    return this.http
      .get<any>(`${url}/${company_id}`)
      .pipe(map(this.extractData));
  }
  
  pauseZoom  = (zoom_user_id, flag) => {
    let postData = {
      flag,
      company_id: this.getCompanyId(),
      zoom_user_id
    };
    return this.http.post<any>(this.server_url + '/api/v1/zoom/pause', postData, this.httpOptions);
  }


}
