import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core'
import { GetVideoService } from '../../services/get-video.service'
import * as moment from 'moment-timezone'

@Component({
	selector: 'app-scheduled-meetings-zoom',
	templateUrl: './scheduled-meetings-zoom.component.html',
	styleUrls: ['./scheduled-meetings-zoom.component.scss'],
})
export class ScheduledMeetingsZoomComponent implements OnInit, OnChanges {
	// @Input() date: string = '--';
	// @Input() time: string = '--';
	// @Input() title: string = 'Scheduled Meetings';
	// @Input() site: string = '--';
	// @Input() organizer: string = '--';
	meetingsResponseData: any = []
	@Input() popData: any
	@Input() popupState: any

	constructor() {}
	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.popData && this.popData) {
			this.setData()
		}
	}

	// TODO: need to comment it and use dateTimeZone instead. But I can't test it rn so leaving it as it is
	printTime(t) {
		if (isNaN(t)) {
			let a = moment(t)
			return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma')
		} else {
			let a = moment.unix(t)
			return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM-DD-YYYY hh:mma')
		}
	}

	// TODO: need to comment it and use dateTimeZone instead. But I can't test it rn so leaving it as it is
	printEndTime(t, duration) {
		if (isNaN(t)) {
			let a = moment(t).add(duration, 'minutes')
			return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mma')
		} else {
			let a = moment.unix(t).add(duration, 'minutes')
			return a.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mma')
		}
	}

	setData = () => {
		const { deviceId, meetings } = this.popData
		this.meetingsResponseData = meetings || []
	}
}
