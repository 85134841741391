import { trigger, state, style, transition,
  animate, group, query, stagger, keyframes
} from '@angular/animations';

export const HealthblockLowerLeft = [
  trigger('slideLowerLeft', 
  [
      state('home', style({
        // transform: 'scale(1)',
        // left: '90px',
        // top: '480px'
      })),
      state('left', style({
          transform: 'scale(0.7)',
          left: '15px',
          top: '430px'
      })),
      // transition('close => open', animate('1500ms ease-in-out')),
      transition('home => left', animate('100ms', keyframes([
          style({ transform: 'scale(.8)'}),
        //   style({ transform: 'scale(.6)'}),
        //   style({ transform: 'scale(.5)'}),
        //   style({ top: '430px'}),
        //   style({ top: '420px'}),
        //   style({ left: '90px'}),
          style({ left: '60px'})
      ]))),
      // transition('open => close', animate('1800ms ease-in-out'))
      transition('left => home', animate('100ms', keyframes([
          style({ top: '430px'}),
        //   style({ top: '420px'}),
        //   style({ left: '60px'}),
        //   style({ left: '70px'}),
        //   style({ transform: 'scale(.5)'}),
        //   style({ transform: 'scale(.6)'}),
          style({ transform: 'scale(.8)'}),
    ])))
])]