import { Component, OnInit, Input, Output } from '@angular/core';

import { PopupTypeService } from '../../../services/popupType.service';
import { AuthService } from '../../../services/auth.service';
import { environment } from './../../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-cloud-all-rooms-info',
  templateUrl: './cloud-all-rooms-info.component.html',
  styleUrls: ['./cloud-all-rooms-info.component.scss']
})
export class CloudAllRoomsInfoComponent implements OnInit {
  @Input() cloudRoomSummary: any;
  data: any;

  currentFilter: string;

  constructor(private ds: PopupTypeService, public Auth: AuthService) {

  }
  isSuperAdmin: any;
  server_url: string = environment.serverUrl;
  mspLogo: string = environment.config.logo;
  companyLogo: any;


  ngOnInit() {
    // send message to subscribers via observable subject
    this.ds.sendPopupType(0);
    this.getCompanyInfo();
    this.isSuperAdmin = this.Auth.accessLevel == "SUPERADMIN";
  }


  getDecodedtoken = () => {
    const token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    return helper.decodeToken(token);
  };

  getCompanyInfo() {
    const decodedToken = this.getDecodedtoken();
    this.Auth.getCompanyInfo({ company_id: decodedToken.company_id }).subscribe((data) => {
      const [newData] = data.response;
      this.companyLogo = this.server_url + '/api/v1/images/companylogo/' + newData.logo;
    });
  }

  sendPopupType(p) {
    this.ds.sendPopupType(p);
    // console.log(p)
  }

  ngOnDestroy() {
    // clear message
    this.ds.clearPopupType();
  }

}