<div class="main-menu">
  <!-- <alert></alert> -->
  <div *ngIf="showScheduleModal">
    <div class="pop-site-name">{{ popUpCollectorData.site_name }}</div>
    <app-vis-one-popup-two [collectorVersionData]="collectorVersionData" [popUpCollectorData]="popUpCollectorData">
    </app-vis-one-popup-two>
    <hr />
  </div>

  <div class="row top-btn-row" *ngIf="isShowCollectorInfo">
    <app-get-collector-info [collectorParams]="collectorParams"></app-get-collector-info>
  </div>

  <app-download-icons [componentName]="'vsone'" [desktopClientDownloadLink]="desktopClientDownloadLink"
    (sendPopupType)="sendPopupType($event)"></app-download-icons>

  <div class="main-menu__radio-button-and-search">
    <div class="main-menu__radio-button">
      <mat-radio-group [(ngModel)]="tableSelected" (change)="radioChange($event.value)">
        <mat-radio-button value="collector">All Sites & Data {{ dCollector }}</mat-radio-button>
        <mat-radio-button value="roomPlugin">All Room {{ dPlugin }}</mat-radio-button>
        <mat-radio-button value="desktopPlugin">All Desktop Clients</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="main-menu__search">
      <div class="main-menu__icon">
        <i class="fa fa-search"></i>
      </div>
      <input type="text" class="search-default" type="text" placeholder="" [(ngModel)]="searchKey" />

      <div class="counter" *ngIf="tableSelected === 'collector'">
        <p>
          Total # of all sites and data collectors are
          <strong>{{ allSitesAndDataCollector }}</strong>
        </p>
      </div>
      ​
      <div class="counter" *ngIf="tableSelected === 'roomPlugin'">
        <p>
          Total # of all rooms plugin <strong>{{ allRoomsPlugin }}</strong>
        </p>
      </div>
      ​
      <div class="counter" *ngIf="tableSelected === 'desktopPlugin'">
        <p>
          Total # of all desktop clients
          <strong>{{ allDesktopClients }}</strong>
        </p>
      </div>
    </div>
  </div>

  <div class="main-menu__table-container">
    <div *ngIf="tableSelected === 'collector'" class="main-menu__table">
      <app-vs-menu-all-site-and-data-collectors [siteList]="siteList" [siteSummary]="siteSummary"
        [siteDetailsLoading]="siteDetailsLoading" [siteSummaryLoading]="siteSummaryLoading"
        [isShowCollectorEdit]="isShowCollectorEdit" [isShowCollectorInfo]="isShowCollectorInfo" [keyDown]="keyDown"
        [runTutorial]="runTutorial" [domotzEnabled]="domotzEnabled" [searchKey]="searchKey"
        [unlinkedIotDevice]="unlinkedIotDevice" [unlinkedPlugin]="" (sendPopupType)="sendPopupType($event)"
        (objectEmitter)="objectEmitter($event)"></app-vs-menu-all-site-and-data-collectors>
    </div>
    <div *ngIf="tableSelected === 'roomPlugin'" class="main-menu__table">
      <app-vs-menu-all-room-plugins [roomPluginList]="roomPluginList" [isShowCollectorEdit]="isShowCollectorEdit"
        [isShowCollectorInfo]="isShowCollectorInfo" [keyDown]="keyDown" [runTutorial]="runTutorial"
        [domotzEnabled]="domotzEnabled" [searchKey]="searchKey" [unlinkedIotDevice]="unlinkedIotDevice"
        [unlinkedPlugin]="" (sendPopupType)="sendPopupType($event)">
      </app-vs-menu-all-room-plugins>
    </div>

    <div *ngIf="tableSelected === 'desktopPlugin'" class="main-menu__table">
      <app-vs-menu-desktop-clients [desktopClientList]="desktopClientList" [isShowCollectorEdit]="isShowCollectorEdit"
        [isShowCollectorInfo]="isShowCollectorInfo" [keyDown]="keyDown" [runTutorial]="runTutorial"
        [domotzEnabled]="domotzEnabled" [searchKey]="searchKey" [unlinkedIotDevice]="unlinkedIotDevice"
        [unlinkedPlugin]="" (sendPopupType)="sendPopupType($event)">
      </app-vs-menu-desktop-clients>
    </div>
  </div>
</div>