<div class="row">
  <div class="col-4 pt-3">
    <h4>Total number of incidents</h4>
    <div class="row" style="border-bottom: 3px solid silver;">
      <div class="col-6">
        <h1 class="float-right report-bold">{{videoConferSite?.total_incidents.total_incidents}}</h1>
      </div>
      <div class="col-6">
        <div *ngIf="videoConferSite?.total_incidents.difference <= -100" class="report-oval float-right text-success">
              <h5 class="text-center report-oval-number">{{videoConferSite?.total_incidents.difference}}</h5>
        </div>
        <div *ngIf="videoConferSite?.total_incidents.difference<0 && videoConferSite?.total_incidents.difference > -100 " class="report-circle float-right text-success">
          <h5 class="text-center report-circle-number">{{videoConferSite?.total_incidents.difference}}</h5>
        </div>
        <div *ngIf="videoConferSite?.total_incidents.difference>0 && videoConferSite?.total_incidents.difference < 100" class="report-circle float-right text-danger">
          <h5 class="text-center report-circle-number">+{{videoConferSite?.total_incidents.difference}}</h5>
        </div>
        <div *ngIf="videoConferSite?.total_incidents.difference >= 100" class="report-oval float-right text-danger">
          <h5 class="text-center report-oval-number">+{{videoConferSite?.total_incidents.difference}}</h5>
        </div>
        <div *ngIf="videoConferSite?.total_incidents.difference==0" class="report-circle float-right text-dark">
          <h5 class="text-center report-circle-number">--</h5>
        </div>
      </div>
    </div>
    <h4 class="pt-3">Avg. time to restore service</h4>
    <div class="row">
      <div class="col-7">
        <div class="row">
          <div class="col-10 text-right">
            <h1 class="report-bold">{{videoConferSite?.avg_restore_time}}</h1>
          </div>
          <div class="col-1">
            <h6 class="font-weight-bold">min</h6>
          </div>
        </div>
      </div>
      <div class="col-5">
        <div *ngIf="(videoConferSite?.avg_restore_time) > 0 && videoConferSite?.restore_time_trend <= -100" class="report-circle float-right text-success">
            <h5 class="text-center report-circle-number">{{videoConferSite?.restore_time_trend}}</h5>
        </div>
        <div *ngIf="(videoConferSite?.avg_restore_time) > 0 && videoConferSite?.restore_time_trend < 0 && videoConferSite?.restore_time_trend  >-100" class="report-circle float-right text-success">
          <h5 class="text-center report-circle-number">{{videoConferSite?.restore_time_trend}}</h5>
        </div>
        <div *ngIf="(videoConferSite?.avg_restore_time) > 0 && videoConferSite?.restore_time_trend > 0 && videoConferSite?.restore_time_trend < 100" class="report-circle float-right text-danger">
          <h5 class="text-center report-circle-number">+{{videoConferSite?.restore_time_trend}}</h5>
        </div>
        <div *ngIf="(videoConferSite?.avg_restore_time) > 0 && videoConferSite?.restore_time_trend >= 100" class="report-oval float-right text-danger">
            <h5 class="text-center report-oval-number">+{{videoConferSite?.restore_time_trend}}</h5>
          </div>
        <div *ngIf="(videoConferSite?.avg_restore_time) > 0 && videoConferSite?.restore_time_trend == 0" class="report-circle float-right text-dark">
          <h5 class="text-center report-circle-number">--</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="col-8 pt-3" style="border-left:3px solid silver;">
    <h4>Health SLA score</h4>
    <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <h2 class="float-left">Calls: {{videoConferSite?.total_calls.calls}}</h2>
            </div>
            <div class="col-12">
              <h2 class="float-left">Usage (d/h/m): {{videoConferSite?.total_usage.usage}}</h2>
            </div>
          </div>
        </div>
        <div class="col-3">
          <h4 *ngIf="videoConferSite?.total_calls?.difference > 0" class="text-center" style="border: 4px solid green;color:black;border-radius: 20px;">{{videoConferSite?.total_calls?.difference}}</h4>
          <h4 *ngIf="videoConferSite?.total_calls?.difference < 0" class="text-center" style="border: 4px solid red;color:black;border-radius: 20px;">{{videoConferSite?.total_calls?.difference}}</h4>
          <h4 *ngIf="videoConferSite?.total_calls?.difference == 0" class="text-center" style="border: 4px solid black;color:black;border-radius: 20px;">--</h4>
          <h4 *ngIf="videoConferSite?.total_usage?.difference.indexOf('+') !== -1 && videoConferSite?.total_usage?.difference != '+00:00:00'" class="text-center" style="border: 4px solid green;color:black;border-radius: 20px;">{{videoConferSite?.total_usage?.difference}}</h4>
          <h4 *ngIf="videoConferSite?.total_usage?.difference.indexOf('-') !== -1" class="text-center" style="border: 4px solid red;color:black;border-radius: 20px;">{{videoConferSite?.total_usage?.difference}}</h4>
          <h4 *ngIf="videoConferSite?.total_usage?.difference == '+00:00:00'" class="text-center" style="border: 4px solid black;color:black;border-radius: 20px;">--</h4>
        </div>
        <div class="col-3">
          <div style="border: 5px solid gray;
          width: 7em;
          text-align: center;
          height: 8em;
          margin-bottom: 15px;">
            <h1 *ngIf="videoConferSite?.avg_health >= 90 || (videoConferSite?.avg_health === 0 && videoConferSite?.total_incidents.total_incidents === 0)" class="grade-letter text-success">A {{videoConferSite?.videoConferSite}}</h1>
            <h1 *ngIf="videoConferSite?.avg_health >= 41 && videoConferSite?.avg_health <= 89" class="grade-letter text-warning">B {{videoConferSite?.videoConferSite}}</h1>
            <h1 *ngIf="videoConferSite?.avg_health <= 40 && videoConferSite?.total_incidents.total_incidents !== 0" class="grade-letter text-danger">C {{videoConferSite?.videoConferSite}}</h1>
          </div>
        </div>
    </div>
    <div class="progress">
      <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <ng-container >
      <div *ngIf="videoConferSite?.avg_health >= 95" class="pnt-group bar-move" [style.left]="videoConferSite?.avg_health-8 + '%'"  >
        <div class="pnt">
          <div class="pnt-n">{{videoConferSite?.avg_health | wholeNumber }}%</div>
        </div>
      </div>
      <div *ngIf="videoConferSite?.avg_health <= 10" class="pnt-group bar-move" [style.left]="videoConferSite?.avg_health+1 + '%'"  >
        <div class="pnt">
          <div class="pnt-n">{{videoConferSite?.avg_health | wholeNumber }}%</div>
        </div>
      </div>
      <div *ngIf="videoConferSite?.avg_health > 10 && videoConferSite?.avg_health < 95" class="pnt-group bar-move" [style.left]="videoConferSite?.avg_health + '%'"  >
          <div class="pnt">
            <div class="pnt-n">{{videoConferSite?.avg_health | wholeNumber }}%</div>
          </div>
        </div>
    </ng-container>
  </div>
</div>