import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-audio-site-info-panel',
  templateUrl: './audio-site-info-panel.component.html',
  styleUrls: ['./audio-site-info-panel.component.scss']
})
export class AudioSiteInfoPanelComponent implements OnInit {

  @Input() audioSiteInfo;

  constructor() { }

  ngOnInit() {
  }

}
