<app-chart-qos *ngIf= "isShowQosChartandList" [siteId] = "siteId" [remoteSiteId] = "remoteSiteId"></app-chart-qos>
<div class="printer-border print-btn">
  <i class="fa fa-print fa-2x menu-icon" (click)="print()"></i>
</div>

<div class="container-fluid"  id="print-section" style="max-width: 95% !important; ">
  <div class="row">
    <div class="col-6" style="overflow: scroll; height: 400px;">
      
      <table>
        <tr scope="row" *ngFor="let site of qosListData; index as i" role="form" class="form-inline">
          <td style="width:15% !important; word-wrap: break-word;">{{site.site_name.length > 15 ?
            site.site_name.substring(0, 15) + "..." : site.site_name}}</td>
          <td>
             <!-- <img *ngIf="site.qos==1;else second" src="../../../assets/img/qos/green-qostest.png"
              style="width:200px !important" [class.lt-side-50]="popupQosState=='show'" alt="qos site to site test" /> -->
             <div *ngIf="site.qos==1;else second"  (click) = "toggleChart(site.remote_site_id)"  src="../../../assets/img/qos/green-qostest.png"  class="status-green"></div>

              <ng-template #second>
                  <ng-container *ngIf="site.qos==.5;else third">
                    <!-- <img src="../../../assets/img/qos/yellow-qostest.png" style="width:200px !important"
                    [class.lt-side-50]="popupQosState=='show'" alt="qos site to site test" /> -->
                    <div  (click) = "toggleChart(site.remote_site_id)" class="status-yellow"></div>
.
                  </ng-container>
                </ng-template>
                    
                <ng-template #third>
                  <ng-container *ngIf="site.qos==0;else fourth">
                    <!-- <img *ngIf="site.qos==0" src="../../../assets/img/qos/red-qostest.png" style="width:200px !important"
                      [class.lt-side-50]="popupQosState=='show'" alt="qos site to site test" /> -->
                      <div  (click) = "toggleChart(site.remote_site_id)"  class="status-red"></div>

                  </ng-container>
                </ng-template>
                
                <ng-template #fourth>
                  <ng-container *ngIf="site.qos==-1 || site.qos==null">
                    <!-- <img src="../../../assets/img/qos/grey-qostest.png"
                      style="width:200px !important" [class.lt-side-50]="popupQosState=='show'" alt="qos site to site test" /> -->
                      <div (click) = "toggleChart(site.remote_site_id)" style = "cursor:pointer" class="status-grey"></div>
                      <!-- <button class = "btn btn-primary status-grey" ></button> -->

                  </ng-container>
                </ng-template>
          </td>
          
          <td style="width:10% !important;word-wrap: break-word;">{{site.remote_site_name}} </td>
          <td id="tableButton" style="width:20% !important"><button id="testButton{{site.remote_site_name}}"
              type="button" class="btn btn-primary"
              (click)="testButtonPressed($event.target,site.remote_site_name);">test</button></td>
          <td id="tableButton" style="width:20% !important"><button id="pauseButton{{site.remote_site_name}}"
              value="{{site.collector_version}}" type="button" class="btn btn-primary"
              (click)="pauseButtonPressed($event.target,site.site_name,site.remote_site_name,site.remote_site_id)">{{site.remote_qos_paused==0?"pause":"unpause"}}</button>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-6">
      <div class="row" *ngIf="isTestButtonPressed">
        <div class="col">
          TOS/DSCP<br>
          <input id="tos" style="width:150px !important;">
        </div>
        <div class="col">
          Mbps<br>
          <select id="mbps" style="width:150px !important;">
            <option value="500k" selected disabled hidden>500k</option>
            <option value="500k">500k</option>
            <option value="1m">1 Mbps</option>
            <option value="2m">3 Mbps</option>
            <option value="3m">4 Mbps</option>
            <option value="4m">10 Mbps</option>
            <option value="5m">1000 Mbps</option>
          </select>

        </div>
        <div class="col">
          Delivery Time<br>
          <select id="time" style="width:150px !important;">
            <option value="5" selected disabled hidden>5 Sec.</option>
            <option value="5">5 Sec.</option>
            <option value="10">10 Sec.</option>
            <option value="20">20 Sec.</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="loading" [class.loading-show]="activeSelection==1">
          <span style="padding-left: 20px !important;">running...</span>
          <span style="padding-left: 50px !important;"><img src="loader"></span>
        </div>

        <div>
          <ng-container *ngIf="errorMessage">
            <div class="rt-side d-flex align-content-center" style="padding-top: 10px;"
              [@PopupQosSlide]="popupQosState">
              <div class="form-inline">
                <h3 style="color: red!important; text-align: center; font-size: 25px !important">{{errorMessage}}</h3>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="siteQosDetails[0]?.status === 'COMPLETE' ">
            <div class="rt-side" style="padding-top: 20px" [@PopupQosSlide]="popupQosState">
              <table style="text-align: center !important ; font-size: 25px !important">
                <tr>
                  <td>{{siteQosDetails[0]?.results.jitter_ms}}</td>
                  <td>{{siteQosDetails[0]?.results.packets}}</td>
                  <td>{{siteQosDetails[0]?.results.lost_packets}}</td>
                  <td>{{(siteQosDetails[0]?.results.lost_packets*100/siteQosDetails[0]?.results.packets).toFixed(2)}}
                  </td>
                </tr>
                <tr>
                  <td>Jitter Avg.</td>
                  <td>Packets sent</td>
                  <td>packets loss#</td>
                  <td>packets loss%</td>
                </tr>
              </table>
            </div>
          </ng-container>
          <ng-container>
            <div class="rt-side d-flex align-content-center" [@PopupQosSlide]="popupQosState">
              <div class="form-inline">
                <h3 *ngIf='siteQosDetails[0]?.status === "FAILED"' style="color: red!important" class="send-text">
                  {{siteQosDetails[0]?.results_msssage}}</h3>
              </div>
            </div>
          </ng-container>
        </div>
      </div>



    </div>
  </div>
</div>